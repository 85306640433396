import { Box, Divider, Typography } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { getRevenueSummary } from '../api/sales';
import { formatNumberToCurrency } from '../utils/currency';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const MobileSalesDashboard = () => {
  const [revenueSummary, setRevenueSummary] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({ datasets: [] });

  const fetchRevenueSummary = async () => {
    const chart = chartRef.current;

    try {
      setIsLoading(true);
      const {
        data: { data: summary },
      } = await getRevenueSummary();

      setRevenueSummary(summary);

      if (chart) {
        setChartData({
          labels: ['DSS', 'Lunoar'],
          datasets: [
            {
              label: 'Revenue',
              data: [
                summary?.currentYearDSSRevenue,
                summary?.currentYearLunoarRevenue,
              ],
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
              ],
              borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
              borderWidth: 1,
            },
          ],
        });
      }
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRevenueSummary();
  }, []);

  return (
    <Box sx={{ mt: 1, bgcolor: 'background.default', p: 2.5, pt: 1.5 }}>
      <Box ref={chartRef}>
        <Doughnut data={chartData} />
      </Box>

      <Divider sx={{ mt: 2 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
          <Typography
            sx={{
              color: 'rgba(255, 99, 132, 1)',
              fontWeight: 600,
              fontSize: '14px',
            }}
          >
            PT Diskon Sekali Saja
          </Typography>
          <Typography
            sx={{
              color: 'rgba(255, 99, 132, 1)',
              fontWeight: 600,
              fontSize: '14px',
            }}
          >
            {formatNumberToCurrency(revenueSummary?.currentYearDSSRevenue || 0)}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
          <Typography
            sx={{
              color: 'rgba(54, 162, 235, 1)',
              fontWeight: 600,
              fontSize: '14px',
            }}
          >
            PT Lunoar Raksasa Indonesia
          </Typography>
          <Typography
            sx={{
              color: 'rgba(54, 162, 235, 1)',
              fontWeight: 600,
              fontSize: '14px',
            }}
          >
            {formatNumberToCurrency(
              revenueSummary?.currentYearLunoarRevenue || 0
            )}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
          <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
            Total Sales
          </Typography>
          <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
            {formatNumberToCurrency(revenueSummary?.currentYearRevenue || 0)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default MobileSalesDashboard;
