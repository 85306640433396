import { createSlice } from '@reduxjs/toolkit';
import { getAllDataAdjustment } from '../../api/adjustment';
import { getAllHubs, getAllMiniHub, getAllSubHub } from '../../api/hubs';

export const DataAdjustmentState = createSlice({
  name: 'dataAdjustment',
  initialState: {
    data: [],
    isLoading: false,

    hub: [],
    subHub: [],
    miniHub: [],

    // filter
    startDate: '',
    endDate: '',
    location: [],
    status: [],
    user: [],
  },
  reducers: {
    loadData: (state, action) => {
      state.data = action.payload;
    },
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    loadAllDataHub: (state, action) => {
      state.hub = action.payload;
    },
    loadAllDataSubHub: (state, action) => {
      state.subHub = action.payload;
    },
    loadAllDataMiniHub: (state, action) => {
      state.miniHub = action.payload;
    },

    // filter
    changeStartDateAdjustment: (state, action) => {
      state.startDate = action.payload;
    },
    changeEndDateAdjustment: (state, action) => {
      state.endDate = action.payload;
    },
    changeLocationFilter: (state, action) => {
      state.location = action.payload;
    },
    changeStatusFilter: (state, action) => {
      state.status = action.payload;
    },
    changeUserFilter: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const {
  loadData,
  changeIsLoading,
  loadAllDataHub,
  loadAllDataSubHub,
  loadAllDataMiniHub,

  changeStartDateAdjustment,
  changeEndDateAdjustment,
  changeLocationFilter,
  changeStatusFilter,
  changeUserFilter,
} = DataAdjustmentState.actions;

export const getDataAdjusment = (state) => state.dataAdjustment.data;
export const isLoadingFetchAdjusment = (state) =>
  state.dataAdjustment.isLoading;
export const getDataHubAdjustment = (state) => state.dataAdjustment.hub;
export const getDataSubHubAdjustment = (state) => state.dataAdjustment.subHub;
export const getDataMiniHubAdjustment = (state) => state.dataAdjustment.miniHub;

export const startDateAdjustment = (state) => state.dataAdjustment.startDate;
export const endDateAdjustment = (state) => state.dataAdjustment.endDate;
export const locationFilterAdjustment = (state) =>
  state.dataAdjustment.location;
export const statusFilterAdjustment = (state) => state.dataAdjustment.status;
export const userFilterAdjustment = (state) => state.dataAdjustment.user;

// ! -------------------- API -----------------
// get all data adjustment

export const fetchDataAdjustment = (params) => (dispatch) => {
  const fetchData = async () => {
    dispatch(changeIsLoading(true));
    try {
      const {
        data: { data },
      } = await getAllDataAdjustment(params);
      dispatch(loadData(data?.adjustments));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoading(false));
    }
  };

  fetchData();
};

// ! -------------------- API -----------------
// get hub
export const getAllMasterHubAdjustment = (params) => (dispatch) => {
  const fetchDataHub = async () => {
    // dispatch(changeIsLoadingAdjustment(true));
    try {
      const {
        data: {
          data: { hubs },
        },
      } = await getAllHubs(params);
      let sliceData = hubs.map((hub, _) => {
        return { ...hub, typeHub: 'hub' };
      });

      let fixDatas = sliceData?.map((data, _) => {
        return {
          hub_id: data?.idToko,
          label: data?.namaToko,
          hub_type: data?.typeHub,
        };
      });
      dispatch(loadAllDataHub(fixDatas));
    } catch (err) {
      console.error(err);
    } finally {
      // dispatch(changeIsLoadingAdjustment(false));
    }
  };
  fetchDataHub();
};

// get sub hub
export const getAllSubHubAdjustment = (params) => (dispatch) => {
  const fetchDataSubHub = async () => {
    // dispatch(changeIsLoadingAdjustment(true));
    try {
      const {
        data: {
          data: { subHubs },
        },
      } = await getAllSubHub(params);
      let sliceDataSubHub = subHubs.map((subHub, _) => {
        return { ...subHub, typeHub: 'subhub' };
      });

      let fixDatasSubHub = sliceDataSubHub?.map((data, _) => {
        return {
          hub_id: data?.id,
          label: data?.name,
          hub_type: data?.typeHub,
        };
      });
      dispatch(loadAllDataSubHub(fixDatasSubHub));
    } catch (err) {
      console.error(err);
    } finally {
      // dispatch(changeIsLoadingAdjustment(false));
    }
  };
  fetchDataSubHub();
};

// get mini hub
export const getAllMiniHubAdjustment = (params) => (dispatch) => {
  const fetchDataMiniHub = async () => {
    // dispatch(changeIsLoadingAdjustment(true));
    try {
      const {
        data: {
          data: { subHubs },
        },
      } = await getAllMiniHub(params);
      let sliceDataMiniHub = subHubs.map((miniHub, _) => {
        return { ...miniHub, typeHub: 'minihub' };
      });

      let fixDatasMiniHub = sliceDataMiniHub?.map((data, _) => {
        return {
          hub_id: data?.id,
          label: data?.name,
          hub_type: data?.typeHub,
        };
      });
      dispatch(loadAllDataMiniHub(fixDatasMiniHub));
    } catch (err) {
      console.error(err);
    } finally {
      // dispatch(changeIsLoadingAdjustment(false));
    }
  };
  fetchDataMiniHub();
};
export default DataAdjustmentState.reducer;
