import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../../../../../../components/shared/CustomButton";
import useGetExcelNumberFormat from "../../../../../../../../../hooks/useGetExcelNumberFormat";
import {
  getDetailRackCreateMap,
  unSeeDetailRack,
} from "../../../../../../../../../store/hub/masterHub/masterHubAdd";
import ListAlphabetExcelFormat from "./ListAlphabetExcelFormat";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

const DetailRackPreview = () => {
  const dispatch = useDispatch();
  const dataDetailRack = useSelector(getDetailRackCreateMap);

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ width: "80%", pb: "100px" }}>
        <Box sx={{ overflow: "auto" }}>
          <Box sx={{ py: "20px" }}>
            {[...Array(dataDetailRack.row)]
              .map((row, rowIndex) => (
                <Box sx={{ display: "flex", maxHeight: "36px" }} key={rowIndex}>
                  <Box
                    sx={{
                      minWidth: "40px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ color: "#EACD57" }}>
                      {rowIndex + 1}
                    </Typography>
                  </Box>
                  <Box key={rowIndex} sx={{ display: "flex" }}>
                    {[...Array(dataDetailRack.column)].map((cell, colIndex) => (
                      <Box
                        key={colIndex}
                        sx={[
                          {
                            minWidth: "36px",
                            height: "36px",
                            borderLeft: 2,
                            borderBottom: 2,
                          },
                          colIndex === dataDetailRack.column - 1 && {
                            borderRight: 2,
                          },
                          rowIndex === dataDetailRack.row - 1 && {
                            borderTop: 2,
                          },
                        ]}
                      ></Box>
                    ))}
                  </Box>
                </Box>
              ))
              .reverse()}
            <Box sx={{ pl: "40px", display: "flex", pt: "20px" }}>
              {[...Array(dataDetailRack.column)].map((_, colIndex) => (
                <Box
                  sx={[
                    {
                      minWidth: "36px",
                      minHeight: "36px",
                      display: "flex",
                      justifyContent: "center",
                    },
                  ]}
                  key={colIndex}
                >
                  <Typography sx={{ color: "#4339F2" }}>
                    <ListAlphabetExcelFormat angka={colIndex} />
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            width: "270px",
            bgcolor: "white",
            height: "100%",
            top: 1,
            right: 1,
            pt: "50px",
            pl: "16px",
          }}
        >
          {/* //* Name */}
          <Box sx={{ mb: "14px" }}>
            <Typography sx={{ fontWeight: "600", mb: "4px", fontSize: "12px" }}>
              Name
            </Typography>
            <Box
              sx={{
                bgcolor: "#FAFAFA",
                width: "200px",
                p: "12px ",
                borderRadius: "4px",
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                {dataDetailRack.name}
              </Typography>
            </Box>
          </Box>
          {/* //* Column */}
          <Box sx={{ mb: "14px" }}>
            <Typography
              sx={{
                fontWeight: "600",
                mb: "4px",
                fontSize: "12px",
                color: "#4339F2",
              }}
            >
              Column(s)
            </Typography>
            <Box
              sx={{
                bgcolor: "#FAFAFA",
                width: "200px",
                p: "12px ",
                borderRadius: "4px",
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                {dataDetailRack.column}
              </Typography>
            </Box>
          </Box>
          {/* //* Level */}
          <Box sx={{ mb: "14px" }}>
            <Typography
              sx={{
                fontWeight: "600",
                mb: "4px",
                fontSize: "12px",
                color: "#EACD57",
              }}
            >
              Level(s)
            </Typography>
            <Box
              sx={{
                bgcolor: "#FAFAFA",
                width: "200px",
                p: "12px ",
                borderRadius: "4px",
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                {dataDetailRack.row}
              </Typography>
            </Box>
          </Box>
          {/* //* Level */}
          <Box sx={{ mb: "14px" }}>
            <Typography
              sx={{
                fontWeight: "600",
                mb: "4px",
                fontSize: "12px",
              }}
            >
              Description
            </Typography>
            <Box
              sx={{
                bgcolor: "#FAFAFA",
                width: "200px",
                p: "12px ",
                borderRadius: "4px",
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                {dataDetailRack.description ? dataDetailRack.description : "-"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          bgcolor: "#F5F5F5",
          top: 1,
          right: 1,
          width: "44px",
          height: "44px",
          borderRadius: "8px",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          zIndex: "+1",
        }}
        onClick={() => dispatch(unSeeDetailRack())}
      >
        <ZoomOutIcon sx={{ color: "#323232" }} />
      </Box>
    </Box>
  );
};
export default DetailRackPreview;
