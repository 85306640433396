import { Box } from '@mui/material';
import RackItemTable from './RackItemTable';

const RackHubItem = () => {
  return (
    <Box>
      <RackItemTable />
    </Box>
  );
};
export default RackHubItem;
