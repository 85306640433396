import {
  Autocomplete,
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Draggable, Map, Marker, ZoomControl } from "pigeon-maps";
import CustomButton from "../../../../../../../../../components/shared/CustomButton";
import PinDropIcon from "@mui/icons-material/PinDrop";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import LocationSearchingRoundedIcon from "@mui/icons-material/LocationSearchingRounded";
import { ReactComponent as CameraLocationToMarker } from "../../../../../../../../../assets/location-to-marker.svg";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAddress,
  changeCenter,
  changeCoordinate,
  changeMapType,
  changePlaceGetByName,
  fetchPlacesSearchByCoordinate,
  fetchPlacesSearchByName,
  getAddres,
  getCenterMasterHubAdd,
  getCoordinateeMasterHubAdd,
  getLaodingPlaceGetByCoorninate,
  getLoadingPlacesSearchByName,
  getMapTypeMasterHubAdd,
  getPlaceGetByCoorninate,
  getPlaceGetByName,
  getPlacesSearchByName,
  submitAddresMap,
} from "../../../../../../../../../store/hub/masterHub/masterHubAdd";
import { useGeolocated } from "react-geolocated";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import CustomInput from "../../../../../../../../../components/shared/CustomInput";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect } from "react";
import debounce from "lodash.debounce";

const SetupAddress = ({ setIsOpen }) => {
  const dispatch = useDispatch();
  const [isMapOpen, setIsMapOpen] = useState(false);
  const mapInputType = useSelector(getMapTypeMasterHubAdd);
  // const [anchor, setAnchor] = useState([3.546958571415798, 98.6901696840363]);
  const anchor = useSelector(getCoordinateeMasterHubAdd);
  const [anchorByMapFix, setAnchorByMapFix] = useState([]);
  // const [center, setCenter] = useState([3.546958571415798, 98.6901696840363]);
  const center = useSelector(getCenterMasterHubAdd);
  const [zoom, setZoom] = useState(17);

  const [currentCoordinateLat, setCurrentCoordinateLat] = useState(0);
  const [currentCoordinateLong, setCurrentCoordinateLong] = useState(0);

  const [isDetailMarker, setIsDetailMarker] = useState(false);

  const [manualSearch, setManualSearch] = useState("");
  const [manualSearchFix, setManualSearchFix] = useState("");

  const placesSearchByName = useSelector(getPlacesSearchByName);
  const isPlacesSerachByNameLoading = useSelector(getLoadingPlacesSearchByName);
  const placeGetByName = useSelector(getPlaceGetByName);

  const placeGetByCoordinate = useSelector(getPlaceGetByCoorninate);
  const isPlaceGetByCoordinateLoading = useSelector(
    getLaodingPlaceGetByCoorninate
  );

  const desAddress = useSelector(getAddres);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
    });

  const debouncedManualSearch = useRef(
    debounce((value) => {
      setManualSearchFix(value);
    }, 1000)
  ).current;

  const debouncedGetByMap = useRef(
    debounce((value) => {
      setAnchorByMapFix(value);
    }, 2000)
  ).current;

  useEffect(() => {
    if (!manualSearchFix) {
      dispatch(fetchPlacesSearchByName("medan"));
    } else {
      dispatch(fetchPlacesSearchByName(manualSearchFix));
    }
  }, [manualSearchFix]);

  useEffect(() => {
    dispatch(
      fetchPlacesSearchByCoordinate({
        lat: anchorByMapFix[0],
        lon: anchorByMapFix[1],
      })
    );
  }, [anchorByMapFix]);

  // useEffect(() => {
  //   if (anchor.length === 0 && isGeolocationAvailable) {
  //     return dispatch(changeCoordinate([coords.latitude, coords.longitude]));
  //   }
  // }, [anchor, coords.latitude, coords.longitude, isGeolocationAvailable]);

  function handleGetCurrentCoordinate() {
    if (isGeolocationAvailable) {
      setCurrentCoordinateLat(coords.latitude);
      setCurrentCoordinateLong(coords.longitude);
      dispatch(changeCoordinate([coords.latitude, coords.longitude]));
      dispatch(changeCenter([coords.latitude, coords.longitude]));
      if (mapInputType !== "manual") {
        debouncedGetByMap([coords.latitude, coords.longitude]);
      }
    }
  }

  const handleResetMarker = () => {
    dispatch(changeCoordinate(center));
    if (mapInputType !== "manual") {
      debouncedGetByMap(center);
    }
  };

  const handleResetZoom = () => {
    dispatch(changeCenter(anchor));
  };

  const handleClickMarker = () => {
    setIsDetailMarker(true);
  };
  const handleEnter = () => {
    dispatch(submitAddresMap({ address: desAddress, coordinate: anchor }));
    setIsOpen(false);
  };

  // !----------- Valiedais -----------
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);

  useEffect(() => {
    if (desAddress) {
      if (anchor.length !== 0) {
        return setIsSubmitAvailable(true);
      } else {
        return setIsSubmitAvailable(false);
      }
    } else {
      return setIsSubmitAvailable(false);
    }
  }, [desAddress, anchor]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItem: "center",
          gap: 1,
          p: "8px",
          borderRadius: "8px",
          bgcolor: "#F5F5F5",
        }}
      >
        <Box
          onClick={() => dispatch(changeMapType("manual"))}
          sx={[
            {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "270px",
              height: "44px",
              borderRadius: "8px",
              cursor: "pointer",
            },
            mapInputType === "manual"
              ? {
                  bgcolor: "#FFFFFF",
                }
              : {
                  bgcolor: "#F5F5F5",
                },
          ]}
        >
          <Typography
            sx={[
              mapInputType === "manual"
                ? {
                    color: "#51B15C",
                  }
                : {
                    color: "black",
                  },
            ]}
          >
            Manual input
          </Typography>
        </Box>
        <Box
          onClick={() => dispatch(changeMapType("bymap"))}
          sx={[
            {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "#FFFFFF",
              width: "270px",
              height: "44px",
              borderRadius: "8px",
              cursor: "pointer",
            },
            mapInputType === "bymap"
              ? {
                  bgcolor: "#FFFFFF",
                }
              : {
                  bgcolor: "#F5F5F5",
                },
          ]}
        >
          <Typography
            sx={[
              mapInputType === "bymap"
                ? {
                    color: "#51B15C",
                  }
                : {
                    color: "black",
                  },
            ]}
          >
            Select by map
          </Typography>
        </Box>
      </Box>
      {/* //* ISI */}
      <Box>
        {mapInputType === "manual" ? (
          <Box>
            {/* //* City n distric */}
            <Box sx={{ mt: "12px" }}>
              <Typography>City & District*</Typography>
              {/* <CustomInput
                value={manualSearch}
                onChange={(e) => {
                  setManualSearch(e.target.value);
                  debouncedManualSearch(e.target.value);
                }}
                placeholder="Select city"
                sx={{ width: '100%', bgcolor: '#FAFAFA', mt: '12px' }}
              /> */}
              <Autocomplete
                value={placeGetByName}
                onChange={(_, newValue) => {
                  dispatch(changePlaceGetByName(newValue));
                }}
                inputValue={manualSearch}
                onInputChange={(_, newInputValue) => {
                  setManualSearch(newInputValue);
                  debouncedManualSearch(newInputValue);
                }}
                options={placesSearchByName}
                loading={
                  isPlacesSerachByNameLoading ||
                  manualSearch !== manualSearchFix
                }
                getOptionLabel={(option) => option.formatted}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select City"
                    sx={{ width: "100%", bgcolor: "#FAFAFA", mt: "12px" }}
                  />
                )}
              />
            </Box>
            {/* //* Address */}
            <Box sx={{ mt: "12px" }}>
              <Typography>Address*</Typography>
              <CustomInput
                value={desAddress}
                onChange={(e) => dispatch(changeAddress(e.target.value))}
                placeholder="Enter address"
                sx={{ width: "100%", bgcolor: "#FAFAFA", mt: "12px" }}
              />
            </Box>
            <Typography sx={{ mt: "12px" }}>Pinpoint</Typography>
            {/* //* MAP */}
            <Box sx={{ position: "relative", mt: "12px" }}>
              <Box
                sx={{
                  position: "absolute",
                  zIndex: "+5",
                  bottom: 4,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    mb: "8px",
                    ml: "8px",
                    p: "4px",
                    borderRadius: "8px",
                  }}
                >
                  <Box
                    onClick={handleGetCurrentCoordinate}
                    sx={{
                      width: "24px",
                      height: "24px",
                      bgcolor: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      cursor: "pointer",
                      boxShadow: "8px",
                    }}
                  >
                    <LocationSearchingRoundedIcon
                      sx={{
                        fontSize: "20px",
                        color: "#51B15C",
                      }}
                    />
                  </Box>
                  <Box
                    onClick={handleResetZoom}
                    sx={{
                      width: "24px",
                      height: "24px",
                      bgcolor: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      cursor: "pointer",
                      boxShadow: "8px",
                    }}
                  >
                    <CameraLocationToMarker
                      style={{ fontSize: "20px", color: "#51B15C" }}
                    />
                  </Box>
                  <Box
                    onClick={handleResetMarker}
                    sx={{
                      width: "24px",
                      height: "24px",
                      bgcolor: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      cursor: "pointer",
                      boxShadow: "8px",
                    }}
                  >
                    <PinDropIcon sx={{ fontSize: "20px", color: "#F64444" }} />
                  </Box>
                </Box>
              </Box>
              <Map
                height={300}
                center={center}
                zoom={zoom}
                onBoundsChanged={({ center, zoom }) => {
                  dispatch(changeCenter(center));
                  setZoom(zoom);
                }}
              >
                <ZoomControl />
                {anchor.length !== 0 && (
                  <Draggable
                    anchor={anchor}
                    onDragEnd={(anchor) => dispatch(changeCoordinate(anchor))}
                  >
                    <LocationOnRoundedIcon
                      color="error"
                      sx={{ fontSize: "30px" }}
                    />
                  </Draggable>
                )}
              </Map>
            </Box>

            {/* //* Submit */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                mt: "24px",
              }}
            >
              <CustomButton
                disabled={!isSubmitAvailable || !placeGetByName}
                variant="contained"
                onClick={handleEnter}
                sx={{ width: "198px", height: "48px" }}
              >
                Enter
              </CustomButton>
            </Box>
          </Box>
        ) : (
          <Box>
            {/* <Box sx={{ mt: '12px' }}>
              <CustomInput
                placeholder="Search"
                sx={{ width: '100%', bgcolor: '#FAFAFA', mt: '12px' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: '#9E9D9D', ml: '12px' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box> */}
            {/* //* MAP */}
            <Box sx={{ position: "relative", mt: "12px" }}>
              {/* {isDetailMarker && (
                <Box
                  sx={{
                    position: 'absolute',
                    bgcolor: 'white',
                    right: 1,
                    zIndex: '+5',
                    mt: '8px',
                    mr: '8px',
                    p: '4px',
                    borderRadius: '8px',
                    width: '132px',
                    height: '44px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography color="primary" sx={{ fontSize: '12px' }}>
                    Hub Lulu
                  </Typography>
                </Box>
              )} */}
              <Box
                sx={{
                  position: "absolute",
                  zIndex: "+5",
                  bottom: 4,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    mb: "8px",
                    ml: "8px",
                    p: "4px",
                    borderRadius: "8px",
                  }}
                >
                  <Box
                    onClick={handleGetCurrentCoordinate}
                    sx={{
                      width: "24px",
                      height: "24px",
                      bgcolor: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      cursor: "pointer",
                      boxShadow: "8px",
                    }}
                  >
                    <LocationSearchingRoundedIcon
                      sx={{
                        fontSize: "20px",
                        color: "#51B15C",
                      }}
                    />
                  </Box>
                  <Box
                    onClick={handleResetZoom}
                    sx={{
                      width: "24px",
                      height: "24px",
                      bgcolor: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      cursor: "pointer",
                      boxShadow: "8px",
                    }}
                  >
                    <CameraLocationToMarker
                      style={{ fontSize: "20px", color: "#51B15C" }}
                    />
                  </Box>
                  <Box
                    onClick={handleResetMarker}
                    sx={{
                      width: "24px",
                      height: "24px",
                      bgcolor: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      cursor: "pointer",
                      boxShadow: "8px",
                    }}
                  >
                    <PinDropIcon sx={{ fontSize: "20px", color: "#F64444" }} />
                  </Box>
                </Box>
              </Box>

              <Map
                height={300}
                center={center}
                zoom={zoom}
                onBoundsChanged={({ center, zoom }) => {
                  dispatch(changeCenter(center));
                  setZoom(zoom);
                }}
              >
                {/* {zoom > 15 && (
                  <Marker anchor={[3.5465302399564416, 98.68839406187627]}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <LocationOnRoundedIcon
                        color="primary"
                        sx={{
                          fontSize: `${30 - (20 - Math.floor(zoom))}px`,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleClickMarker()}
                      />
                      {zoom >= 17 && (
                        <Typography
                          color="primary"
                          sx={{ fontSize: '12px', textAlign: 'center' }}
                        >
                          hub lulu
                        </Typography>
                      )}
                    </Box>
                  </Marker>
                )} */}

                <ZoomControl />
                {anchor.length !== 0 && (
                  <Draggable
                    anchor={anchor}
                    onDragEnd={(anchor) => {
                      dispatch(changeCoordinate(anchor));
                      debouncedGetByMap(anchor);
                    }}
                  >
                    <LocationOnRoundedIcon
                      color="error"
                      sx={{ fontSize: "40px" }}
                    />
                  </Draggable>
                )}

                {/* <Marker anchor={[3.5468407802842656, 98.69077586320272]}>
                  <LocationOnRoundedIcon
                    color="primary"
                    sx={{ fontSize: '30px' }}
                  />
                </Marker> */}
              </Map>
            </Box>
            {/* //* City n distric */}
            <Box sx={{ mt: "12px" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography>City & District*</Typography>
                {anchor !== anchorByMapFix ||
                  (isPlaceGetByCoordinateLoading && (
                    <CircularProgress size={12} />
                  ))}
              </Box>
              <CustomInput
                placeholder="Select city"
                value={
                  placeGetByCoordinate ? placeGetByCoordinate?.formatted : ""
                }
                disabled={true}
                sx={{
                  width: "100%",
                  bgcolor: placeGetByCoordinate?.formatted
                    ? "#FAFAFA"
                    : "#E4E4E4",
                  mt: "12px",
                }}
              />
              {/* <Box
                sx={{
                  width: '100%',
                  bgcolor: '#FAFAFA',
                  mt: '12px',
                  p: '20px 12px',
                  borderRadius: '8px',
                }}
              >
                {placeGetByCoordinate ? (
                  <Typography>{placeGetByCoordinate?.formatted}</Typography>
                ) : (
                  <Typography sx={{ color: '#9E9D9D' }}>
                    Selected by map
                  </Typography>
                )}
              </Box> */}
            </Box>
            {/* //* Address */}
            <Box sx={{ mt: "12px" }}>
              <Typography>Address*</Typography>
              <CustomInput
                value={desAddress}
                onChange={(e) => dispatch(changeAddress(e.target.value))}
                placeholder="Enter address"
                sx={{ width: "100%", bgcolor: "#FAFAFA", mt: "12px" }}
              />
            </Box>
            {/* //* Submit */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                mt: "24px",
              }}
            >
              <CustomButton
                disabled={!isSubmitAvailable || !placeGetByCoordinate}
                variant="contained"
                onClick={handleEnter}
                sx={{ width: "198px", height: "48px" }}
              >
                Enter
              </CustomButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default SetupAddress;
