import { createSlice } from '@reduxjs/toolkit';

export const purchaseRoutingState = createSlice({
  name: 'purchaseRouting',
  initialState: {
    role: '',
    page: '', //* '', detail, detailSend, qrCode, request, requestAdd, requestDetail, -requestEdit,
    idPurchase: 0,
    idRequest: 0,
    keyNotif: '',
    textNotif: '',
    subsidiaryName: '',
    hubName: '',
    statusPurchase: '', 
  },
  reducers: {
    changePage: (state, action) => {
      state.page = action.payload;
    },
    changePurchaseRoutingState: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setNotifPurchase: (state, action) => {
      const { key, text } = action.payload;
      state.keyNotif = key;
      state.textNotif = text;
    },
    setCloseNotif: (state) => {
      state.keyNotif = '';
      state.textNotif = '';
    },
    setHirarkiPurchase: (state, action) => {
      const { subsidiary, hub } = action.payload;
      state.subsidiaryName = subsidiary;
      state.hubName = hub;
    },
    changeStatusForPurchase: (state, action) => {
      state.statusPurchase = action.payload;
    },
  },
});

export const {
  changePage,
  changePurchaseRoutingState,
  setNotifPurchase,
  setCloseNotif,
  setHirarkiPurchase,
  changeStatusForPurchase,
} = purchaseRoutingState.actions;

export const getAllPurchaseRouting = (state) => state.purchaseRouting;

export default purchaseRoutingState.reducer;
