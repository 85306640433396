import { createSlice } from '@reduxjs/toolkit';

export const purchaseProcessSupplierState = createSlice({
  name: 'purchaseProcessSupplier',
  initialState: {
    description: '',
    supplier: '',
    paymentMethod: '',
    productsTempo: '',
    products: '',
    invoice: '',
    invoiceDate: '',
    discount: 0,
    discountFormat: 'percentage',
    idHub: 0,
    idsupplier: 0,
    idsubsidiary: 0,
    idtransaksi: 0,
  },
  reducers: {
    goToPurchaseProcess: (state, action) => {
      const {
        products,
        description,
        idPurchase,
        supplier,
        paymentMethod,
        idHub,
        idsupplier,
        idsubsidiary,
        idtransaksi,
        discount,
        discountFormat,
      } = action.payload;

      const collect = products.map((list) => {
        return {
          idProduk: list?.product?.idProduk,
          name: list?.product?.namaProduk,
          qty: list?.qty,
          unit: list?.unit,
          purchasePrice: list?.purchasePrice || 0,
          discount: list?.discount || 0,
          discountFormat: list?.discountFormat || 'currency',
        };
      });

      state.products = collect;
      state.productsTempo = collect;
      state.deskripsi = description;
      state.idPurchase = idPurchase;
      state.supplier = supplier;
      state.idHub = idHub;
      state.idsubsidiary = idsubsidiary;
      state.idsupplier = idsupplier;
      state.idtransaksi = idtransaksi;
      state.paymentMethod = paymentMethod;
      state.discount = discount;
      state.discountFormat = discountFormat || 'percentage';
    },
    changeProduct: (state, action) => {
      let collect = state.products;
      state.products = collect.filter(
        (item) => item.idProduk !== action.payload
      );
    },
    changePrice: (state, action) => {
      const { index, value } = action.payload;
      let collect = state.products;
      collect[index].purchasePrice = value;
      state.products = collect;
    },
    changeQty: (state, action) => {
      const { index, value } = action.payload;
      let collect = state.products;
      collect[index].qty = value;
      state.products = collect;
    },
    changeDiscount: (state, action) => {
      const { index, value } = action.payload;
      let collect = state.products;
      collect[index].discount = value;
      state.products = collect;
    },
    changeDiscountFormat: (state, action) => {
      const { index, value } = action.payload;
      let collect = state.products;
      collect[index].discountFormat = value;
      state.products = collect;
    },
    changeValuePruchasProcess: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});

export const {
  goToPurchaseProcess,
  changeProduct,
  changePrice,
  changeQty,
  changeDiscount,
  changeDiscountFormat,
  changeValuePruchasProcess,
} = purchaseProcessSupplierState.actions;
export const getAllPurchaseProcessSupplier = (state) =>
  state.purchaseProcessSupplier;
export default purchaseProcessSupplierState.reducer;
