import {
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import CustomCard from "../../../../../../../components/shared/CustomCard";
import {
  ChevronRightIcon,
  Different,
  Dolar,
  ExportIcon,
  FilterAltIcon,
  SearchIcon,
} from "../../../../../../../assets/Icons";
import CustomButton from "../../../../../../../components/shared/CustomButton";
import DialogFilterStockMobile from "../DialogFilter";
import { useState, useRef } from "react";
import { useEffect } from "react";
import { getStockAlertList } from "../../../../../../../api/stock";
import { ChevronLeft } from "@mui/icons-material";
import debounce from "lodash.debounce";
import { useDispatch } from "react-redux";
import { changePageStock } from "../../../../../../../store/Routing/stockRouting";

const StockAlertMobile = () => {
  const [dataAlertStock, setDataAlertStock] = useState([]);
  const [search, setSearch] = useState("");
  const [purchaseKeyword, setPurchaseKeyword] = useState("");
  const dispatch = useDispatch();

  const debounceOnChange = useRef(
    debounce((value) => {
      setPurchaseKeyword(value);
    }, 1000)
  ).current;

  const fetchDataAlertStock = async (params) => {
    try {
      const {
        data: { data },
      } = await getStockAlertList(params);
      setDataAlertStock(data?.stocks);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const params = {};
    params.page_size = 4;
    params.subsidiary_id = [];
    params.id_toko = [];
    params.sub_hub_id = [];
    params.mini_hub_id = [];
    params.search = purchaseKeyword;
    fetchDataAlertStock(params);
  }, [purchaseKeyword]);

  const toolbar = (
    <Box
      sx={{
        display: "flex",
        flexFlow: "row-reverse",
        gap: 2,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CustomButton
        sx={{
          minWidth: "22px",
          width: "22px",
          minHeight: "22px",
          height: "22px",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "4px",
          bgcolor: "#FFFFFF",
          color: "#000000",
          "&:hover": {
            bgcolor: "#FFFFFF",
            color: "#000000",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <ExportIcon />
      </CustomButton>

      <CustomButton
        sx={{
          minWidth: "22px",
          width: "22px",
          minHeight: "22px",
          height: "22px",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "4px",
          bgcolor: "#FFFFFF",
          color: "#000000",
          "&:hover": {
            bgcolor: "#FFFFFF",
            color: "#000000",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <FilterAltIcon />
      </CustomButton>
      <DialogFilterStockMobile />
    </Box>
  );

  return (
    <Grid container columns={12} spacing={2} sx={{ pt: "30px" }}>
      <Grid item xs={12}>
        {/* button stokAlert */}

        <Box
          sx={{
            display: "flex",
            pb: "12px",
            alignItems: "center",
            gap: 1,
          }}
        >
          <ChevronLeft
            sx={{
              background: " #51B15C",
              color: "#FFFFFF",
              borderRadius: "50%",
            }}
            onClick={() => dispatch(changePageStock(""))}
          />
          <Typography
            sx={{
              fontSize: "14px",
              color: "#000000",
              fontWeight: 600,
            }}
          >
            Stock Alert
          </Typography>
        </Box>

        <CustomCard sx={{ padding: "16px" }}>
          <Grid container columns={12} spacing={2}>
            <Grid item xs={3}>
              <Typography
                sx={{
                  color: " #000000",
                  display: "inline-flex",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                Shortage List
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                placeholder="Search"
                size="small"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  debounceOnChange(e.target.value);
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    paddingLeft: "6px",
                    fontSize: "8px",
                  },
                  bgcolor: "#FAFAFA",
                  minHeight: "22px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ width: "12px" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              items
              xs={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                pt: "10px",
              }}
            >
              {toolbar}
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                    <Dolar />

                    <Typography
                      sx={{
                        fontSize: "8px",
                        display: "flex",
                        fontWeight: 600,
                      }}
                    >
                      100
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                    <Different />

                    <Typography
                      sx={{
                        fontSize: "8px",
                        display: "flex",
                        fontWeight: 600,
                      }}
                    >
                      35
                    </Typography>
                  </Box>
                </Box>

                <Typography
                  sx={{
                    fontSize: "8px",
                    fontWeight: 600,
                  }}
                >
                  *000
                </Typography>
              </Box>
            </Grid>

            {/* DatastockList */}
            {dataAlertStock?.map((items, index) => (
              <Grid item xs={6}>
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    padding: "10px",
                    borderRadius: "8px",
                    background: "#FAFAFA",
                  }}
                >
                  <Grid container columns={12} spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        Height: "147px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <Box
                          component="img"
                          src={
                            process.env.REACT_APP_API_IMAGE_BASE_URL +
                            "/" +
                            items?.gambar
                          }
                          sx={{
                            minWidth: "28px",
                            width: "28px",
                            height: "28px",
                            borderRadius: "8px",
                          }}
                        />

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            height: "28px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "8px",
                              display: "flex",
                              fontWeight: 600,
                            }}
                          >
                            {items?.subsidiary}
                            <ChevronRightIcon style={{ fontSize: "8px" }} /> (
                            {items?.hub})
                            <ChevronRightIcon style={{ fontSize: "8px" }} /> (
                            {items?.location})
                          </Typography>
                          <Typography
                            sx={{ fontSize: "10px", fontWeight: 600 }}
                          >
                            {items?.namaProduk}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        sx={{ display: "flex", gap: 1, alignItems: "center" }}
                      >
                        <Box>
                          <Dolar />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            height: "28px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "8px",
                              display: "flex",
                              fontWeight: 600,
                            }}
                          >
                            100
                          </Typography>
                        </Box>

                        <Box>
                          <Different />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            height: "28px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "8px",
                              display: "flex",
                              fontWeight: 600,
                              color: "red",
                            }}
                          >
                            35
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
          {/* akhir */}

          {dataAlertStock.length === 0 && (
            <Grid
              container
              columns={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "red",
              }}
            >
              <Typography>data no found</Typography>
            </Grid>
          )}
        </CustomCard>
      </Grid>
    </Grid>
  );
};

export default StockAlertMobile;
