import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import BackButtonHub from '../../../../../../components/shared/BackButtonHub';
import { useDispatch } from 'react-redux';
import { changePageTransaction } from '../../../../../../store/Routing/transactionRouting';
import { ArrowForwardIosIcon } from '../../../../../../assets/Icons';
import CustomCard from '../../../../../../components/shared/CustomCard';
import TableRequestTransaction from './Table';
import ChartPerfomanceStock from '../../Stock/Component/Chart';
import dayjs from 'dayjs';
import { useState } from 'react';
import RequestTransactionMobile from '../TtansactionMobile/Request';

const TransactionRequest = () => {
  const matchTablet = useMediaQuery('(min-width:832px');
  const dispatch = useDispatch();

  const handleBackButton = () => {
    dispatch(changePageTransaction(''));
  };

  // ! grafik & table

  const [timeFrame, setTimeFrame] = useState('daily');

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  return (
    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      {!matchTablet ? <RequestTransactionMobile /> :
        <>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <BackButtonHub onClick={handleBackButton} />
        <Typography
          sx={{
            color: '#000000',
            fontSize: '24px',
            fontWeight: 600,
          }}
        >
          Stock Request
        </Typography>
          </Box>

      <CustomCard sx={{ mt: 2, p: 2 }}>
        <Grid container columns={12} spacing={3}>
          <Grid item xs={7}>
            <ChartPerfomanceStock
              {...{
                timeFrame,
                setTimeFrame,
                dateRange,
                setDateRange,
                saveDateRange,
                setSaveDateRange,
              }}
            />
          </Grid>
          <Grid item xs={5}>
            table
          </Grid>
        </Grid>
      </CustomCard>

      {/* Table list  */}
      <Box sx={{ mt: 4 }}>
        <TableRequestTransaction />
      </Box>
        </>

      }
    </Box>
  );
};
export default TransactionRequest;
