import React, { useState, useEffect } from "react";
import { useSearchParams, createSearchParams } from "react-router-dom";
import CustomTable from "../../../../../../../../components/shared/CustomTable";
import ExampleImage from "../../../../../../../../assets/asset.svg";
import {
  Checkbox,
  Divider,
  IconButton,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import {
  ExitToApp as ExitToAppIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRightAlt as ArrowRightAltIcon,
  SyncAlt as SyncAltIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import CustomButton from "../../../../../../../../components/shared/CustomButton";
import { ReactComponent as ColumnFilterIcon } from "../../../../../../../../assets/colum-filter-icon.svg";
import CustomMenu from "../../../../../../../../components/shared/CustomMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMasterHubRacks,
  getRacksMasterHub,
} from "../../../../../../../../store/hub/masterHub/masterHub";
import { getIdMasterHub } from "../../../../../../../../store/hub/hirarkiHub";
import { ReactComponent as NoImage } from "../../../../../../../../assets/no-image.svg";
import { inputIdMasterRack } from "../../../../../../../../store/racks/masterHubRack/masterHubRack";

const CustomTableCells = styled(TableCell)({
  color: "#000000",
  fontWeight: 500,
  fontSize: "0.75rem",
  textTransform: "capitalize",
});

const headCells = [
  {
    id: "number",
    title: "No",
    icon: false,
  },
  {
    id: "photo",
    title: "Photo",
    icon: false,
  },
  {
    id: "code",
    title: "Code",
    icon: true,
    sortName: "code",
  },
  {
    id: "lane",
    title: "Lane",
    icon: true,
    sortName: "lane",
  },
  {
    id: "row",
    title: "Row",
    icon: true,
    sortName: "row",
  },
  {
    id: "column",
    title: "Column",
    icon: true,
    sortName: "column",
  },
  {
    id: "sku",
    title: "SKU",
    icon: true,
    sortName: "sku",
  },
  {
    id: "cogs",
    title: "COGS(Rp)*",
    icon: true,
    sortName: "cogs",
  },
];

const dummyData = [
  {
    id: 1,
    photo: ExampleImage,
    code: "REK132",
    lane: "B-1",
    row: "B5",
    column: "L2",
    sku: 8,
    cogs: 500,
  },
];

const MasterHubRacksTable = ({
  masterHubRack,
  setMasterHubRack,
  rowPerPageMasterHubRack,
  setRowPerPageMasterHubRack,
  totalTableItemsMasterHubRack,
  setTotalTableItemsMasterHubRack,
  currentPageTableMasterHubRack,
  setCurrentPageTableMasterHubRack,
  isLoadingMasterHubRack,
  setIsLoadingMasterHubRack,
  masterHubRackKeyword,
  setMasterHubRackKeyword,
  sortTableMasterHubRack,
  setSortTableMasterHubRack,
  isSortAscMasterHubRack,
  setIsSortAscMasterHubRack,
  rowPerPageValues,
  setMasterHubIdRack,
  setMasterHubIdRackDetail,
}) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);
  const [openColumnFilter, setOpenColumnFilter] = useState(null);
  const isOpenColumnFilter = Boolean(openColumnFilter);
  const [columnArray, setColumnArray] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState([]);
  const [openExport, setOpenExport] = useState(null);
  const isOpenExport = Boolean(openExport);

  const masterHubId = useSelector(getIdMasterHub);
  const racks = useSelector(getRacksMasterHub);

  useEffect(() => {
    setColumnArray(headCells);

    setSelectedColumn(
      columnArray
        .slice(0, searchParams.get("s") || 7)
        .map((headCell) => headCell.id)
    );
  }, [columnArray, searchParams, setMasterHubRack]);

  const handleSelectedColumn = (id) => {
    if (selectedColumn.includes(id)) {
      setSelectedColumn([...selectedColumn].filter((value) => value !== id));
    } else {
      setSelectedColumn([...selectedColumn, id]);
    }
  };

  useEffect(() => {
    dispatch(fetchMasterHubRacks(masterHubId));
  }, [masterHubId]);

  const isSelected = (name) => selectedColumn.indexOf(name) !== -1;

  const [dataTypeSelected, setDataTypeSelected] = useState([
    "level",
    "column",
    "sku",
    "cogs",
  ]);

  const [sortTable, setSortTable] = useState("code");
  const [isSortAsc, setIsSortAsc] = useState(true);

  const theme = useTheme();

  const toolbar = (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        flexDirection: "row-reverse",
        alignItems: "center",
      }}
    >
      <IconButton
        sx={{
          border: "1.5px solid #51B15C",
          borderRadius: "8px",
          p: 1,
        }}
        onClick={(e) => setOpenColumnFilter(e.currentTarget)}
        aria-haspopup="true"
      >
        <ColumnFilterIcon fill="#51B15C" />
      </IconButton>
      <CustomMenu
        open={isOpenColumnFilter}
        openMenu={openColumnFilter}
        setOpenMenu={setOpenColumnFilter}
        sx={{
          ".MuiMenuItem-root": {
            paddingLeft: 0,
            paddingRight: "20px",
            paddingTop: 0,
            paddingBottom: 0,
            borderRadius: "8px",
          },
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", p: "12px" }}
        >
          <Typography sx={{ fontWeight: "400", fontSize: "12px" }}>
            Max 7
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "12px",
              color: theme.palette.primary.main,
              cursor: "pointer",
            }}
          >
            Reset
          </Typography>
        </Box>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem>
          <Checkbox checked={true} />
          Code*
        </MenuItem>
        <MenuItem>
          <Checkbox checked={true} />
          Photo*
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes("level")) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== "level")
              );
            } else {
              if (dataTypeSelected.length < 5) {
                setDataTypeSelected((prev) => [...prev, "level"]);
              }
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes("level")} />
          Level
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes("column")) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== "column")
              );
            } else {
              if (dataTypeSelected.length < 5) {
                setDataTypeSelected((prev) => [...prev, "column"]);
              }
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes("column")} />
          Column
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes("sku")) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== "sku")
              );
            } else {
              if (dataTypeSelected.length < 5) {
                setDataTypeSelected((prev) => [...prev, "sku"]);
              }
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes("sku")} />
          SKU
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes("cogs")) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== "cogs")
              );
            } else {
              if (dataTypeSelected.length < 5) {
                setDataTypeSelected((prev) => [...prev, "cogs"]);
              }
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes("cogs")} />
          COGS
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes("width")) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== "width")
              );
            } else {
              if (dataTypeSelected.length < 5) {
                setDataTypeSelected((prev) => [...prev, "width"]);
              }
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes("width")} />
          Width
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes("length")) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== "length")
              );
            } else {
              if (dataTypeSelected.length < 5) {
                setDataTypeSelected((prev) => [...prev, "length"]);
              }
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes("length")} />
          Length
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes("weight")) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== "weight")
              );
            } else {
              if (dataTypeSelected.length < 5) {
                setDataTypeSelected((prev) => [...prev, "weight"]);
              }
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes("weight")} />
          Weight
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes("height")) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== "height")
              );
            } else {
              if (dataTypeSelected.length < 5) {
                setDataTypeSelected((prev) => [...prev, "height"]);
              }
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes("height")} />
          Height
        </MenuItem>
      </CustomMenu>
      <CustomButton
        startIcon={<ExitToAppIcon />}
        endIcon={<ArrowDropDownIcon />}
        onClick={(e) => setOpenExport(e.currentTarget)}
        aria-haspopup="true"
        sx={{
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          bgcolor: "#FFFFFF",
          color: "#000000",
          fontSize: "0.875rem",
          "&:hover": {
            bgcolor: "#FFFFFF",
            color: "#000000",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          },
          px: 2,
          py: 0.8,
        }}
      >
        Export
      </CustomButton>
      <CustomMenu
        open={isOpenExport}
        openMenu={openExport}
        setOpenMenu={setOpenExport}
        PaperProps={{ sx: { minWidth: "121px" } }}
      >
        <MenuItem
          onClick={() => {
            setOpenExport(null);
          }}
        >
          PDF
        </MenuItem>
        {/* <CSVLink
          style={{ color: '#000000', textDecoration: 'none' }}
          data={hubsToExport}
        >
          <MenuItem onClick={() => setOpenExport(null)}>CSV</MenuItem>
        </CSVLink> */}
        <MenuItem onClick={() => setOpenExport(null)}>CSV</MenuItem>
      </CustomMenu>
    </Box>
  );

  return (
    <CustomTable
      rowPerPage={rowPerPageMasterHubRack}
      handleRowPerPage={(event) => {
        setRowPerPageMasterHubRack(event.target.value);
        setCurrentPageTableMasterHubRack(1);
      }}
      currentPage={currentPageTableMasterHubRack}
      rowPerPageValues={rowPerPageValues}
      totalValueItems={totalTableItemsMasterHubRack}
      handleInputCurrentPage={(event) => {
        if (
          event.target.value >=
          Math.ceil(totalTableItemsMasterHubRack / rowPerPageMasterHubRack)
        ) {
          setCurrentPageTableMasterHubRack(
            Math.ceil(totalTableItemsMasterHubRack / rowPerPageMasterHubRack)
          );
        } else if (event.target.value <= 1) {
          setCurrentPageTableMasterHubRack(1);
        } else {
          setCurrentPageTableMasterHubRack(event.target.value);
        }
      }}
      handleBackArrow={() =>
        setCurrentPageTableMasterHubRack(
          currentPageTableMasterHubRack <= 1
            ? currentPageTableMasterHubRack
            : currentPageTableMasterHubRack - 1
        )
      }
      handleForwardArrow={() =>
        setCurrentPageTableMasterHubRack(
          currentPageTableMasterHubRack >=
            Math.ceil(totalTableItemsMasterHubRack / rowPerPageMasterHubRack)
            ? currentPageTableMasterHubRack
            : currentPageTableMasterHubRack + 1
        )
      }
      name="Rack List"
      isLoadingTable={isLoadingMasterHubRack}
      searchValue={masterHubRackKeyword}
      setSearchValue={setMasterHubRackKeyword}
      isNoData={
        !isLoadingMasterHubRack && dummyData.length === 0 ? true : false
      }
      noDataText="No Rack Data"
      toolbar={toolbar}
    >
      <TableHead>
        <TableRow sx={{ bgcolor: "#FAFAFA" }}>
          {/* {columnArray
            .filter((value) => selectedColumn.includes(value.id))
            .map((headCell) => (
              <CustomTableCells
                sx={{
                  ':last-child': {
                    pr: 0.5,
                  },
                  color: headCell.color ? headCell.color : '#000000',
                  fontWeight: 500,
                }}
                key={headCell.id}
              >
                {headCell.icon === true ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setSortTableMasterHubRack(headCell.sortName);
                      setIsSortAscMasterHubRack(!isSortAscMasterHubRack);
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '0.75rem',
                      }}
                    >
                      {headCell.title}
                    </Typography>

                    {sortTableMasterHubRack === headCell.sortName ? (
                      isSortAscMasterHubRack ? (
                        <ArrowRightAltIcon
                          sx={{
                            fontSize: '12px',
                            transform: 'rotate(90deg)',
                            color: theme.palette.primary.main,
                          }}
                        />
                      ) : (
                        <ArrowRightAltIcon
                          sx={{
                            fontSize: '12px',
                            transform: 'rotate(270deg)',
                            color: theme.palette.primary.main,
                          }}
                        />
                      )
                    ) : (
                      <SyncAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(90deg)',
                          color: '#9E9D9D',
                        }}
                      />
                    )}
                  </Box>
                ) : (
                  headCell.title
                )}
              </CustomTableCells>
            ))} */}
          <CustomTableCells>No.</CustomTableCells>
          <CustomTableCells>Photo</CustomTableCells>
          <CustomTableCells>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                cursor: "pointer",
              }}
              onClick={() => {
                setSortTable("code");
                setIsSortAsc(!isSortAsc);
                // setTableCurrentPage(1);
              }}
            >
              <Typography
                sx={{ fontSize: "12px", fontWeight: "500", mr: "2px" }}
              >
                Code
              </Typography>
              {sortTable === "code" ? (
                isSortAsc ? (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: "12px",
                      transform: "rotate(90deg)",
                      color: theme.palette.primary.main,
                    }}
                  />
                ) : (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: "12px",
                      transform: "rotate(270deg)",
                      color: theme.palette.primary.main,
                    }}
                  />
                )
              ) : (
                <SyncAltIcon
                  sx={{
                    fontSize: "12px",
                    transform: "rotate(90deg)",
                    color: "#9E9D9D",
                  }}
                />
              )}
            </Box>
          </CustomTableCells>
          {dataTypeSelected.includes("level") && (
            <CustomTableCells align="left">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSortTable("level");
                  setIsSortAsc(!isSortAsc);
                  // setTableCurrentPage(1);
                }}
              >
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "500", mr: "2px" }}
                >
                  Level
                </Typography>
                {sortTable === "level" ? (
                  isSortAsc ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(90deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(270deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: "12px",
                      transform: "rotate(90deg)",
                      color: "#9E9D9D",
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
          )}
          {dataTypeSelected.includes("column") && (
            <CustomTableCells align="left">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSortTable("column");
                  setIsSortAsc(!isSortAsc);
                  // setTableCurrentPage(1);
                }}
              >
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "500", mr: "2px" }}
                >
                  Column
                </Typography>
                {sortTable === "column" ? (
                  isSortAsc ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(90deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(270deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: "12px",
                      transform: "rotate(90deg)",
                      color: "#9E9D9D",
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
          )}
          {dataTypeSelected.includes("sku") && (
            <CustomTableCells align="right">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSortTable("sku");
                  setIsSortAsc(!isSortAsc);
                  // setTableCurrentPage(1);
                }}
              >
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "500", mr: "2px" }}
                >
                  SKU
                </Typography>
                {sortTable === "sku" ? (
                  isSortAsc ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(90deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(270deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: "12px",
                      transform: "rotate(90deg)",
                      color: "#9E9D9D",
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
          )}
          {dataTypeSelected.includes("cogs") && (
            <CustomTableCells align="right">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSortTable("cogs");
                  setIsSortAsc(!isSortAsc);
                  // setTableCurrentPage(1);
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    mr: "2px",
                  }}
                >
                  COGS(RP)*
                </Typography>
                {sortTable === "cogs" ? (
                  isSortAsc ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(90deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(270deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: "12px",
                      transform: "rotate(90deg)",
                      color: "#9E9D9D",
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
          )}
          {dataTypeSelected.includes("width") && (
            <CustomTableCells align="left">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSortTable("width");
                  setIsSortAsc(!isSortAsc);
                  // setTableCurrentPage(1);
                }}
              >
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "500", mr: "2px" }}
                >
                  Width
                </Typography>
                {sortTable === "width" ? (
                  isSortAsc ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(90deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(270deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: "12px",
                      transform: "rotate(90deg)",
                      color: "#9E9D9D",
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
          )}
          {dataTypeSelected.includes("length") && (
            <CustomTableCells align="left">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSortTable("length");
                  setIsSortAsc(!isSortAsc);
                  // setTableCurrentPage(1);
                }}
              >
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "500", mr: "2px" }}
                >
                  Length
                </Typography>
                {sortTable === "length" ? (
                  isSortAsc ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(90deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(270deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: "12px",
                      transform: "rotate(90deg)",
                      color: "#9E9D9D",
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
          )}
          {dataTypeSelected.includes("weight") && (
            <CustomTableCells align="left">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSortTable("weight");
                  setIsSortAsc(!isSortAsc);
                  // setTableCurrentPage(1);
                }}
              >
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "500", mr: "2px" }}
                >
                  Weight
                </Typography>
                {sortTable === "weight" ? (
                  isSortAsc ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(90deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(270deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: "12px",
                      transform: "rotate(90deg)",
                      color: "#9E9D9D",
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
          )}
          {dataTypeSelected.includes("height") && (
            <CustomTableCells align="left">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSortTable("height");
                  setIsSortAsc(!isSortAsc);
                  // setTableCurrentPage(1);
                }}
              >
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "500", mr: "2px" }}
                >
                  Height
                </Typography>
                {sortTable === "height" ? (
                  isSortAsc ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(90deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(270deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: "12px",
                      transform: "rotate(90deg)",
                      color: "#9E9D9D",
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
          )}
        </TableRow>
      </TableHead>

      <TableBody>
        {racks?.length > 0 &&
          racks?.map((rack, index) => {
            // const { photo, code, lane, row, column, sku, cogs } = rack;
            return (
              <TableRow
                key={index}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  // setMasterHubIdRack(null);
                  // setMasterHubIdRackDetail(rack?.id);
                  // updatedSearchParams.delete('masterhub-idrack');
                  // updatedSearchParams.set('masterhub-idrack-detail', rack?.id);
                  // setSearchParams(updatedSearchParams);
                  dispatch(inputIdMasterRack(rack.id));
                }}
              >
                <CustomTableCells>{index + 1}</CustomTableCells>
                <CustomTableCells sx={{ width: "40px" }}>
                  {rack.gambar ? (
                    <Box>
                      <img
                        src={
                          process.env.REACT_APP_API_IMAGE_BASE_URL +
                          "/" +
                          rack.gambar.split(",")[0]
                        }
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "8px",
                        }}
                        alt="pic"
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#FAFAFA",
                        borderRadius: "2px",
                      }}
                    >
                      <NoImage />
                      <Typography sx={{ color: "#9E9D9D", fontSize: "6px" }}>
                        No photo
                      </Typography>
                    </Box>
                  )}
                </CustomTableCells>

                <CustomTableCells
                  onClick={(event) => {
                    // setSubHubDetailSelected(id);
                    // setSubHubSelected(null);
                    // updatedSearchParams.set('subhub-detail', id);
                    // updatedSearchParams.delete('subhub-overview');
                    // setSearchParams(updatedSearchParams);
                    // event.stopPropagation();
                    // handleClickName(event)
                  }}
                >
                  {rack?.name || "-"}
                </CustomTableCells>

                {dataTypeSelected.includes("level") && (
                  <CustomTableCells align="left">
                    {!rack.row ? "-" : rack.row}
                  </CustomTableCells>
                )}

                {dataTypeSelected.includes("column") && (
                  <CustomTableCells align="left">
                    {!rack.column ? "-" : rack.column}
                  </CustomTableCells>
                )}
                {dataTypeSelected.includes("sku") && (
                  <CustomTableCells align="right">
                    {!rack.SKU ? "-" : rack.SKU}
                  </CustomTableCells>
                )}
                {dataTypeSelected.includes("cogs") && (
                  <CustomTableCells align="right">
                    {!rack.COGS ? "-" : rack.COGS}
                  </CustomTableCells>
                )}
                {dataTypeSelected.includes("width") && (
                  <CustomTableCells align="left">
                    {!rack.width ? "-" : rack.width}
                  </CustomTableCells>
                )}
                {dataTypeSelected.includes("length") && (
                  <CustomTableCells align="left">
                    {!rack.length ? "-" : rack.length}
                  </CustomTableCells>
                )}
                {dataTypeSelected.includes("weight") && (
                  <CustomTableCells align="left">
                    {!rack.weight ? "-" : rack.weight}
                  </CustomTableCells>
                )}
                {dataTypeSelected.includes("height") && (
                  <CustomTableCells align="left">
                    {!rack.height ? "-" : rack.height}
                  </CustomTableCells>
                )}
              </TableRow>
            );
          })}
      </TableBody>

      {/* {!isLoadingMasterHubRack ? (
      
      ) : (
        <TableBody>
          {[...Array(rowPerPageMasterHubRack)].map((_, x) => (
            <TableRow key={x}>
              {selectedColumn.map((_, i) => (
                <TableCell key={i}>-</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      )} */}
    </CustomTable>
  );
};

export default MasterHubRacksTable;
