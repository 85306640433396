import {
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import LineDiv from "../../../../../../components/shared/LineDiv";
import CustomCard from "../../../../../../components/shared/CustomCard";
import CustomInputWithMin from "../../../../../../components/shared/CustomInputWithMin";
import BackButtonEditPurchase from "./BackButtonEditPurchase";
import BottomMenu from "../../../../../../components/shared/BottomMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  changePrice,
  changeValuePruchasEdit,
  getEditPurchaseAddFinance,
} from "../../../../../../store/purchase/purchaseFinance/purchaseEditFinance";
import { useEffect, useState } from "react";
import { getSupplierForAutocomplete } from "../../../../../../api/Supplier";
import { getPaymentMethodForAutocomplete } from "../../../../../../api/PaymentMethod";
import {
  AutocompleteWithDebounce,
  FillText,
  LoadingComponent,
} from "../../../../../../components/shared/shared2";
import { AddIcon } from "../../../../../../assets/Icons";
import CustomInput from "../../../../../../components/shared/CustomInput";
import CustomEditConfirm from "../../../../../../components/shared/CustomEditConfirm";
import CustomDeleteConfirm from "../../../../../../components/shared/CustomDeleteConfirm";
import { updatePurchaseRequest } from "../../../../../../api/purchase";
import {
  changePage,
  setNotifPurchase,
} from "../../../../../../store/Routing/purchaseRouting";

const PurchaseEditFinance = () => {
  const dispatch = useDispatch();
  const {
    idPurchase,
    deskripsi: description,
    products,
    supplierChoose,
    paymentMethodChoose,
  } = useSelector(getEditPurchaseAddFinance);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  // !----------------------- Supplier ------------------------------------
  const [supplierInputvalue, setSupplierInputValue] = useState("");
  const [supplierInputvalueFix, setSupplierInputValueFix] = useState("");
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [supplierLoading, setSupplierLoading] = useState(false);

  const fetchSupplier = async (search) => {
    setSupplierLoading(true);
    try {
      const {
        data: {
          data: { suppliers },
        },
      } = await getSupplierForAutocomplete(search);
      const collect = suppliers.map((list, idx) => {
        return {
          ...list,
          index: idx,
        };
      });
      setSupplierOptions(collect);
    } catch (error) {
      console.error(error);
    } finally {
      setSupplierLoading(false);
    }
  };

  useEffect(() => {
    fetchSupplier(supplierInputvalueFix);
  }, [supplierInputvalueFix]);

  const [isAddSupplier, setIsAddSupplier] = useState(false);
  const handleAddSupplier = () => {
    setIsAddSupplier(true);
  };

  const handleCloseAddSupplier = () => {
    setIsAddSupplier(false);
  };

  // !----------------------- Payment Method ------------------------------------
  const [paymentMethodInputvalue, setPaymentMethodInputValue] = useState("");
  const [paymentMethodInputvalueFix, setPaymentMethodInputValueFix] =
    useState("");
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
  const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);

  const fetchPaymentMethod = async (search) => {
    setPaymentMethodLoading(true);
    try {
      const {
        data: {
          data: { paymentMethods },
        },
      } = await getPaymentMethodForAutocomplete(search);

      setPaymentMethodOptions(paymentMethods);
    } catch (error) {
      console.error(error);
    } finally {
      setPaymentMethodLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentMethod(paymentMethodInputvalueFix);
  }, [paymentMethodInputvalueFix]);
  // !----------------------- -------------- ------------------------------------

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const totalss = products.reduce((accum, next) => {
      return accum + Number(next.purchasePrice) * Number(next.qty);
    }, 0);

    setTotalPrice(totalss);
  }, [products]);

  const [isEditConfirm, setIsEditConfirm] = useState(false);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  const handleSave = () => {
    setIsEditConfirm(true);
  };

  const fetchUpdate = async (id, data) => {
    setIsLoading(true);
    try {
      await updatePurchaseRequest(id, data);
      dispatch(
        setNotifPurchase({
          key: "editFinancePurchase",
          text: "purchase has been success edited",
        })
      );
      console.log("berhasil");
      dispatch(changePage("purchaseDetailFinance"));
      setIsEditConfirm(false);
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveFix = () => {
    let collect = {
      status: "finance bid",
      idSuppliers: supplierChoose.idKustomer,
      idtransaksi: paymentMethodChoose.idTransaksi,
      deskripsi: description,
    };
    collect["products"] = products.map((list) => {
      return {
        idProduk: list.idProduk,
        qty: list.qty,
        unit: list.unit,
        purchasePrice: list.purchasePrice,
        discount: 0,
        discountFormat: "currency",
      };
    });
    fetchUpdate(idPurchase, collect);
  };

  const handleDelete = () => {
    setIsDeleteConfirm(true);
  };
  const handleDeleteFix = () => {};

  const desktopMode = useMediaQuery("(min-width:832px)");

  return (
    <Box sx={{ mt: "16px" }}>
      <BackButtonEditPurchase />
      {desktopMode && (
        <Grid container columns={2} spacing={4} sx={{ mt: "16px", pb: "54px" }}>
          <Grid item xs={1}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                mb: "12px",
              }}
            >
              Supplier*
            </Typography>
            <AutocompleteWithDebounce
              inputValue={supplierInputvalue}
              onInputChange={(value) => setSupplierInputValue(value)}
              debounceInputChange={(value) => setSupplierInputValueFix(value)}
              value={supplierChoose}
              onChange={(_, newValue) => {
                dispatch(
                  changeValuePruchasEdit({
                    key: "supplierChoose",
                    value: newValue,
                  })
                );
              }}
              options={supplierOptions}
              loading={supplierLoading}
              getOptionLabel={(option) => option.namaLengkap}
              renderOption={(props, option) => {
                return (
                  <Box>
                    {option.index === 0 && (
                      <Box
                        sx={{
                          color: "#51B15C",
                          display: "flex",
                          gap: 0.5,
                          alignItems: "center",
                          pl: "8px",
                          py: "4px",
                          cursor: "pointer",
                          "&:hover": {
                            bgcolor: "#FAFAFA",
                          },
                        }}
                        onClick={handleAddSupplier}
                      >
                        <AddIcon />
                        <Typography>Add New Supplier</Typography>
                      </Box>
                    )}
                    <li {...props}>{option.namaLengkap}</li>
                  </Box>
                );
              }}
              renderInput={(params) => (
                <CustomInput
                  {...params}
                  placeholder="Select Supplier"
                  sx={{ width: "100%", bgcolor: "#FAFAFA" }}
                />
              )}
              noOptionsText={
                <Box>
                  <Box
                    sx={{
                      color: "#51B15C",
                      display: "flex",
                      gap: 0.5,
                      alignItems: "center",
                      py: "4px",
                      cursor: "pointer",
                      "&:hover": {
                        bgcolor: "#FAFAFA",
                      },
                    }}
                    onClick={handleAddSupplier}
                  >
                    <AddIcon />
                    <Typography>Add New Supplier</Typography>
                  </Box>
                  <Box sx={{ textAlign: "center" }}>No Option</Box>
                </Box>
              }
            />
          </Grid>
          <Grid item xs={1}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                mb: "12px",
              }}
            >
              Payment Method*
            </Typography>
            <AutocompleteWithDebounce
              inputValue={paymentMethodInputvalue}
              onInputChange={(value) => setPaymentMethodInputValue(value)}
              debounceInputChange={(value) =>
                setPaymentMethodInputValueFix(value)
              }
              value={paymentMethodChoose}
              onChange={(_, newValue) => {
                dispatch(
                  changeValuePruchasEdit({
                    key: "paymentMethodChoose",
                    value: newValue,
                  })
                );
              }}
              options={paymentMethodOptions}
              loading={paymentMethodLoading}
              getOptionLabel={(option) => option.transaksi}
              renderInput={(params) => (
                <CustomInput
                  {...params}
                  placeholder="Select payment method"
                  sx={{ width: "100%", bgcolor: "#FAFAFA" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                mb: "12px",
              }}
            >
              Description
            </Typography>
            <LineDiv value={description || "-"} />
          </Grid>
          <Grid item xs={2}>
            <CustomCard>
              <CustomCard sx={{ p: "20px" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
                  Item(s)
                </Typography>
              </CustomCard>
              <Grid container columns={1} rowSpacing={4} sx={{ p: "20px" }}>
                {products.map((product, idx) => {
                  return (
                    <Grid item xs={1} key={idx}>
                      <Grid container columns={5} columnSpacing={4}>
                        <Grid item xs={2}>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              mb: "12px",
                            }}
                          >
                            Product
                          </Typography>
                          <LineDiv value={product?.name || "-"} />
                        </Grid>
                        <Grid item xs={1}>
                          <Box
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              mb: "12px",
                              display: "flex",
                              gap: 0.5,
                            }}
                          >
                            <Typography>Qty </Typography>
                            <Typography sx={{ color: "#51B15C" }}>
                              (Pcs)
                            </Typography>
                          </Box>
                          <LineDiv value={product?.qty || "-"} />
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              mb: "12px",
                            }}
                          >
                            Price*
                          </Typography>
                          <CustomInputWithMin
                            isMinSeen={false}
                            placeholder="Enter Qty"
                            isWithTitle={false}
                            type="number"
                            value={product?.purchasePrice || 0}
                            onChange={(e) =>
                              dispatch(
                                changePrice({
                                  index: idx,
                                  value: e.target.value,
                                })
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              mb: "12px",
                            }}
                          >
                            Subtotal*
                          </Typography>
                          <LineDiv
                            value={`Rp. ${
                              product?.purchasePrice * product?.qty
                            }`}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: "20px",
                }}
              >
                <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>
                  Grand Total
                </Typography>
                <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>
                  Rp. {totalPrice}
                </Typography>
              </Box>
            </CustomCard>
          </Grid>
        </Grid>
      )}
      {!desktopMode && (
        <Box sx={{ minHeight: "100vh", bgcolor: "white" }}>
          <Grid
            container
            columns={1}
            spacing={2}
            sx={{ mt: "16px", pb: "54px" }}
          >
            <Grid item xs={1}>
              <AutocompleteWithDebounce
                inputValue={supplierInputvalue}
                onInputChange={(value) => setSupplierInputValue(value)}
                debounceInputChange={(value) => setSupplierInputValueFix(value)}
                value={supplierChoose}
                onChange={(_, newValue) => {
                  dispatch(
                    changeValuePruchasEdit({
                      key: "supplierChoose",
                      value: newValue,
                    })
                  );
                }}
                options={supplierOptions}
                loading={supplierLoading}
                getOptionLabel={(option) => option.namaLengkap}
                renderOption={(props, option) => {
                  return (
                    <Box>
                      {option.index === 0 && (
                        <Box
                          sx={{
                            color: "#51B15C",
                            display: "flex",
                            gap: 0.5,
                            alignItems: "center",
                            pl: "8px",
                            py: "4px",
                            cursor: "pointer",
                            "&:hover": {
                              bgcolor: "#FAFAFA",
                            },
                          }}
                          onClick={handleAddSupplier}
                        >
                          <AddIcon />
                          <Typography>Add New Supplier</Typography>
                        </Box>
                      )}
                      <li {...props}>{option.namaLengkap}</li>
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    id="filled-basic"
                    label="Supplier*"
                    variant="filled"
                    sx={{
                      ".MuiFilledInput-root": {
                        bgcolor: "#FAFAFA",
                        height: "54px",
                      },
                      ".MuiInputLabel-root": {
                        color: "#9E9E9E",
                      },
                    }}
                    {...params}
                  />
                )}
                noOptionsText={
                  <Box>
                    <Box
                      sx={{
                        color: "#51B15C",
                        display: "flex",
                        gap: 0.5,
                        alignItems: "center",
                        py: "4px",
                        cursor: "pointer",
                        "&:hover": {
                          bgcolor: "#FAFAFA",
                        },
                      }}
                      onClick={handleAddSupplier}
                    >
                      <AddIcon />
                      <Typography>Add New Supplier</Typography>
                    </Box>
                    <Box sx={{ textAlign: "center" }}>No Option</Box>
                  </Box>
                }
              />
            </Grid>
            <Grid item xs={1}>
              <AutocompleteWithDebounce
                inputValue={paymentMethodInputvalue}
                onInputChange={(value) => setPaymentMethodInputValue(value)}
                debounceInputChange={(value) =>
                  setPaymentMethodInputValueFix(value)
                }
                value={paymentMethodChoose}
                onChange={(_, newValue) => {
                  dispatch(
                    changeValuePruchasEdit({
                      key: "paymentMethodChoose",
                      value: newValue,
                    })
                  );
                }}
                options={paymentMethodOptions}
                loading={paymentMethodLoading}
                getOptionLabel={(option) => option.transaksi}
                renderInput={(params) => (
                  <TextField
                    id="filled-basic"
                    label="Payment Method*"
                    variant="filled"
                    sx={{
                      ".MuiFilledInput-root": {
                        bgcolor: "#FAFAFA",
                        height: "54px",
                      },
                      ".MuiInputLabel-root": {
                        color: "#9E9E9E",
                      },
                    }}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <FillText title={"Description*"} value={description || "-"} />
            </Grid>
            <Grid item xs={2}>
              <CustomCard>
                <CustomCard sx={{ p: "16px" }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                    Item(s)
                  </Typography>
                </CustomCard>
                <Grid
                  container
                  columns={1}
                  rowSpacing={2}
                  sx={{ p: "20px 16px" }}
                >
                  {products.map((product, idx) => {
                    return (
                      <Grid item xs={1} key={idx}>
                        <Grid
                          container
                          columns={6}
                          columnSpacing={2}
                          rowSpacing={2}
                        >
                          <Grid item xs={4}>
                            <FillText
                              title={"Product"}
                              value={product?.name || "-"}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <FillText
                              title={"Qty"}
                              value={product?.qty || "-"}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              id="filled-basic"
                              label="Price*"
                              variant="filled"
                              sx={{
                                ".MuiFilledInput-root": {
                                  bgcolor: "#FAFAFA",
                                  height: "54px",
                                },
                                ".MuiInputLabel-root": {
                                  color: "#9E9E9E",
                                },
                              }}
                              type="number"
                              value={product?.purchasePrice || ""}
                              onChange={(e) =>
                                dispatch(
                                  changePrice({
                                    index: idx,
                                    value: e.target.value,
                                  })
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FillText
                              title={
                                <Box sx={{ color: "#51B15C" }}>Subtotal</Box>
                              }
                              value={`Rp. ${
                                product?.purchasePrice * product?.qty
                              }`}
                            />
                          </Grid>
                        </Grid>
                        <Divider sx={{ mt: "16px" }} />
                      </Grid>
                    );
                  })}
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: "20px",
                  }}
                >
                  <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
                    Grand Total
                  </Typography>
                  <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
                    Rp. {totalPrice}
                  </Typography>
                </Box>
              </CustomCard>
            </Grid>
          </Grid>
        </Box>
      )}
      <BottomMenu
        isGreen={true}
        isRed={true}
        redText={"Delete"}
        greenText="Save"
        handleGreen={handleSave}
        handleRed={handleDelete}
      />
      <CustomEditConfirm
        open={isEditConfirm}
        onClose={() => setIsEditConfirm(false)}
        handleClickCancel={() => setIsEditConfirm(false)}
        handleClickEdit={handleSaveFix}
        title="Edit Purchase"
        body={
          <>
            {isLoading && <LoadingComponent />}
            {!isLoading && !isError && (
              <Box sx={{ textAlign: "center", mb: "40px", mt: "8px" }}>
                <Typography>Are you sure you want to save changes?</Typography>
              </Box>
            )}
          </>
        }
      />
      <CustomDeleteConfirm
        open={isDeleteConfirm}
        onClose={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickCancel={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Purchase"
        body={
          <Box sx={{ textAlign: "center" }}>
            <Typography>
              Are you sure you want to delete “
              <Typography component="span" sx={{ fontWeight: "600" }}>
                INVOCE ID
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: "52px" }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />
    </Box>
  );
};
export default PurchaseEditFinance;
