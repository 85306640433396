import { Box, Typography } from '@mui/material';

const FillText = ({ title, value, ...props }) => {
  return (
    <Box
      sx={{
        minHeight: '54px',
        borderRadius: '8px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 0.4,
        alignItems: 'start',
        padding: '14px 16px',
        bgcolor: '#FAFAFA',
      }}
      {...props}
    >
      <Typography sx={{ fontSize: '12px', color: '#9E9E9E' }}>
        {title}
      </Typography>
      <Typography sx={{ fontSize: '14px' }}>{value || '-'}</Typography>
    </Box>
  );
};
export default FillText;
