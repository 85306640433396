import { createSlice } from "@reduxjs/toolkit";

export const editProdukSupplierState = createSlice({
  name: "editProdukSupplier",
  initialState: {
    idProduk: 0,
    datas: [],
    isLoading: false,
    status: 0,
  },
  reducers: {
    changeIdProduk: (state, action) => {
      state.idProduk = action.payload;
    },
    loadDataEditProduk: (state, action) => {
      state.datas = action.payload;
    },
    editDataEditProduk: (state, action) => {
      state.datas = {
        ...state.datas,
        detail: action.payload,
      };
    },
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    changeStatusEdit: (state, action) => {
      state.status = action.payload;
    },
    clearDataEdit: (state) => {
      state.idProduk = 0;
      state.datas = [];
      state.isLoading = false;
      state.status = 0;
    },
  },
});

export const {
  changeIdProduk,
  loadDataEditProduk,
  editDataEditProduk,
  changeIsLoading,
  changeStatusEdit,
  clearDataEdit,
} = editProdukSupplierState.actions;

export const getDataEditProduk = (state) => state.editProdukSupplier;

export default editProdukSupplierState.reducer;
