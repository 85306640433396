import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import CustomCard from "../../../../../components/shared/CustomCard";
import ChartPurchase from "./Component/Chart";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TablePurchase from "./Table";
import { getDasboardPurchaseRequest } from "../../../../../api/purchase";
import { useDispatch } from "react-redux";
import { changePage } from "../../../../../store/Routing/purchaseRouting";
import { getAllDataChartPurchase } from "../../../../../store/purchase/dataTable";
import PurchaseMobile from "./PurchaseMobile";

const color = {
  lesstwo: "#000000",
  lesssix: "#FD7900",
  mostseven: "#F33A3A",
};

function InventoryPurchase() {
  const matchTablet = useMediaQuery("(min-width:832px)");
  const dispatch = useDispatch();
  // ---- Grafik ----
  const [timeFrame, setTimeFrame] = useState("daily");

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);

  const startDateFilter = dayjs(saveDateRange[0].startDate).format(
    "YYYY-MM-DD"
  );
  const endDateFilter = dayjs(saveDateRange[0].endDate).format("YYYY-MM-DD");

  // data chart
  useEffect(() => {
    let datas = {};
    datas.startDate = startDateFilter;
    datas.endDate = endDateFilter;
    datas.timeframe = timeFrame;
    dispatch(getAllDataChartPurchase(datas));
  }, [startDateFilter, endDateFilter, timeFrame]);

  // purchase request
  const [dataPurchase, setDataPurchase] = useState([]);
  // fetch api request purchase
  useEffect(() => {
    const fetchPuerchaseRequest = async () => {
      let params = {};
      params.page = 1;
      params.pageSize = 5;
      params.invoice = "desc";

      try {
        const {
          data: { data },
        } = await getDasboardPurchaseRequest(params);
        setDataPurchase(data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchPuerchaseRequest();
  }, []);

  const handleToRequest = () => {
    dispatch(changePage("request"));
  };

  return (
    <>
      {!matchTablet ? (
        <PurchaseMobile />
      ) : (
        <Box>
          {/* grafik  list reqest */}
          <Box
            sx={{
              mt: 3,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {/* Grafik & card purchase request*/}
            <Grid container columns={12} spacing={2}>
              <Grid item xs={8}>
                <CustomCard sx={{ p: "10px 20px" }}>
                  <ChartPurchase
                    {...{
                      timeFrame,
                      setTimeFrame,
                      dateRange,
                      setDateRange,
                      saveDateRange,
                      setSaveDateRange,
                    }}
                  />
                </CustomCard>
              </Grid>
              <Grid item xs={4}>
                <CustomCard
                  sx={{ p: "10px 20px", height: "100%", cursor: "pointer" }}
                  onClick={() => {
                    handleToRequest();
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Purchase Request
                    <ChevronRightIcon sx={{ color: "#00FF00" }} />
                  </Typography>

                  {/* list produk */}
                  {dataPurchase.map((item, index) => (
                    <Box sx={{ mt: 2 }} key={index}>
                      <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                        {dataPurchase[index]?.keterangan}
                      </Typography>
                      <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                        <Typography sx={{ color: "#aaa", fontSize: "12px" }}>
                          by stock{" "}
                          {dataPurchase[index]?.name !== ""
                            ? dataPurchase[index]?.name
                            : "_"}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color:
                              dataPurchase[index]?.dateDays <= 2
                                ? color.lesstwo
                                : dataPurchase[index]?.dateDays <= 6
                                ? color.lesssix
                                : color.mostseven,
                          }}
                        >
                          {dataPurchase[index]?.dateDays} days ago
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </CustomCard>
              </Grid>
            </Grid>
          </Box>

          {/* table list purchase */}
          <Box sx={{ mt: 5 }}>
            <TablePurchase />
          </Box>
        </Box>
      )}
    </>
  );
}
export default InventoryPurchase;
