import CustomCard from '../../../../../../components/shared/CustomCard';
import ChartPerformanceRackList from './PerformanceComponent/ChartPerformanceRackList';
import TablePerformanceRackList from './PerformanceComponent/TablePerformanceRackList';

const PerformanceRackItemQr = () => {
  return (
    <CustomCard sx={{ width: '100%', p: '20px' }}>
      <ChartPerformanceRackList />
      <TablePerformanceRackList />
    </CustomCard>
  );
};
export default PerformanceRackItemQr;
