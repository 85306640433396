import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  IconInvoice,
  IconNotFound,
  IconSearch,
} from "../../../../../../assets";
import SearchStockOut from "../Component/SearchStockout";
import { DataNull } from "../Data/dataNull";
import { SearchFound, SearchNotFound } from "./Dashboard";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSearchStockout } from "../../../../../../store/stockout/stockout";

/**
 * Components for stockout inventory on mobile devices.
 *
 * @returns {React.Element} InventoryStockOutMobile component.
 */

const InventoryStockOutMobile = ({ setSearch, search, invoice, isLoading }) => {
  const dispatch = useDispatch();
  const [isFound, setIsFound] = useState(false);
  const [serachInv, setSearchInv] = useState("");

  useEffect(() => {
    if (invoice.length === 0) {
      setIsFound(true);
    } else {
      setIsFound(false);
    }
  }, [invoice]);

  const handleSearchInv = () => {
    dispatch(setSearchStockout(serachInv));
    setSearch(serachInv);
  };

  return (
    <Box>
      {/* Search button to do searching */}
      <Grid
        container
        columns={12}
        spacing={2}
        display={"flex"}
        alignItems={"center"}
      >
        <Grid item xs={10}>
          <SearchStockOut
            placeholder={"Enter invoice ID"}
            {...{ setSearchInv }}
          />
        </Grid>

        <Grid item xs={2}>
          <Box
            sx={{
              width: "41px",
              height: "35px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#51B15C",
              borderRadius: "8px",
            }}
            onClick={handleSearchInv}
          >
            <IconSearch />
          </Box>
        </Grid>
      </Grid>

      {/* Content */}
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            my: "50px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            mt: "30px",
            background: "#fff",
            boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.05)",
            borderRadius: "10px",
          }}
        >
          {search === null || search === "" ? (
            <SearchNotFound
              title="Please enter invoice ID first"
              icon={<IconInvoice />}
            />
          ) : !isFound ? (
            <SearchFound
              invoice={invoice}
              search={search}
              setSearch={setSearch}
            />
          ) : (
            <SearchNotFound
              title="Oops! The invoce ID might be invalid."
              icon={<IconNotFound />}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default InventoryStockOutMobile;
