import { Autocomplete } from '@mui/material';
import CustomInput from '../../CustomInput';
import { useRef } from 'react';
import debounce from 'lodash.debounce';

const AutocompleteWithDebounce = (prop) => {
  const {
    inputValue, //* value hasil
    onInputChange, //* onchange hasil : functin (value)
    debounceInputChange, //* hasil value debounce : function (value)
    debounceTime = 1000,
    placeholder,
    ...props
  } = prop;
  const debouncedInput = useRef(
    debounce((valueAfterDebounce) => {
      debounceInputChange(valueAfterDebounce);
    }, debounceTime)
  ).current;
  return (
    <Autocomplete
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        onInputChange(newInputValue);
        debouncedInput(newInputValue);
      }}
      {...props}
    />
  );
};
export default AutocompleteWithDebounce;
