import { useDispatch, useSelector } from "react-redux";
import {
  changeEmailSupplierEdit,
  changePhoneNumberSupplierEdit,
  changePublishEdit,
  changeSupplierCodeEdit,
  changeSupplierNameEdit,
  getDataStateEditSupplier,
} from "../../../../../../store/supplier/editSupplier";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import BackButtonHub from "../../../../../../components/shared/BackButtonHub";
import {
  changeIsEditSupplier,
  getIsEditSupplier,
} from "../../../../../../store/supplier/detailSupplier";
import CustomButton from "../../../../../../components/shared/CustomButton";
import CustomInput from "../../../../../../components/shared/CustomInput";
import { useState } from "react";
import {
  CloseIcon,
  KeyboardArrowDownIcon,
} from "../../../../../../assets/Icons";
import { Map } from "pigeon-maps";
import CustomCard from "../../../../../../components/shared/CustomCard";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SetupAddressEditSupplier from "./SetupAddress";
import { deleteSupplier, updateSupplier } from "../../../../../../api/Supplier";
import {
  changePageSupplier,
  setNotifSupplier,
} from "../../../../../../store/Routing/supplierRouting";
import CustomDeleteConfirm from "../../../../../../components/shared/CustomDeleteConfirm";

const EditSupplier = () => {
  const {
    idSupplier,
    supplierName,
    supplierNameOld,
    supplierCode,
    email,
    coordinate,
    mapInputType,
    placeGetByName,
    placeGetByCoordinate,
    phoneNumber,
    joinDate,
    publish,
    address,
    cityDistrict,
    center,
    oldEmail,
    oldPhoneNumber,
    oldCode,
  } = useSelector(getDataStateEditSupplier);
  const dispatch = useDispatch();
  const isEditSupplier = useSelector(getIsEditSupplier);
  const [availableCode, setAvailableCode] = useState(false);
  const [availableEmail, setAvailableEmail] = useState(false);
  const [availablePhone, setAvailablePhone] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [isMapOpen, setIsMapOpen] = useState(false);
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  // ! handler

  // ! validation
  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    dispatch(changeEmailSupplierEdit(inputEmail));

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setEmailError(!emailPattern.test(inputEmail));
  };

  const handleEdit = async () => {
    const params = {
      nama_suppliers: supplierName,
      lat: coordinate[0] || center[0],
      lon: coordinate[1] || center[1],
      status: publish ? "active" : "inactive",
      type_input: mapInputType === "manual" ? "manual" : "map",
      address: address || "",
    };

    if (oldCode !== supplierCode) {
      params["supplier_kode"] = supplierCode;
    }
    if (Number(oldPhoneNumber) !== Number(phoneNumber)) {
      params["telpon"] = phoneNumber;
    }
    if (oldEmail !== email) {
      params["email"] = email;
    }
    if (mapInputType === "manual") {
      const { formatted: citydistric } = placeGetByName || "";
      params["city_district"] = citydistric ? citydistric : cityDistrict;
    } else {
      if (!placeGetByCoordinate?.statusCode) {
        const { formatted: citydistric } = placeGetByCoordinate || "";
        params["city_district"] = citydistric ? citydistric : cityDistrict;
      }
    }

    setLoadingSubmit(true);
    try {
      const data = await updateSupplier(idSupplier, params);
      setLoadingSubmit(false);
      dispatch(
        setNotifSupplier({
          key: "editSupplier",
          text: "supplier successful updated",
        })
      );
      dispatch(changePageSupplier(""));
      dispatch(changeIsEditSupplier(false));
    } catch (err) {
      console.error(err);
      const errors = err.response.data.meta.message;
      errors?.email ? setAvailableEmail(true) : setAvailableEmail(false);
      errors?.supplier_kode ? setAvailableCode(true) : setAvailableCode(false);
      errors?.telpon ? setAvailablePhone(true) : setAvailablePhone(false);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const handleDeleteFix = async () => {
    setLoadingDelete(true);
    try {
      await deleteSupplier(idSupplier);
      dispatch(
        setNotifSupplier({
          key: "deleteSupplier",
          text: "supplier deleted",
        })
      );
      dispatch(changePageSupplier(""));
      dispatch(changeIsEditSupplier(false));
      setLoadingDelete(false);
      setConfirmDelete(!confirmDelete);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingDelete(false);
    }
  };

  return (
    <Box>
      <Box sx={{ minHeight: "80vh" }}>
        <Grid container columns={12} spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 3 }}>
              <BackButtonHub
                onClick={() => dispatch(changeIsEditSupplier(!isEditSupplier))}
              />
              <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                {supplierNameOld} Edit
              </Typography>
            </Box>
          </Grid>

          {/* suppllier name */}
          <Grid item xs={6} sx={{ mt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                Supplier Name
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 500, color: "#F33A3A" }}
              >
                *
              </Typography>
            </Box>
            <CustomInput
              fullWidth
              sx={{
                with: "100%",
                bgcolor: "#fafafa",
                "& .MuiOutlinedInput-input": {
                  padding: "17px 16px",
                  borderRadius: "10px",
                  "&::placeholder": {
                    color: "#111",
                    fontSize: "16px",
                  },
                },
                borderRadius: "5px",
              }}
              placeholder="enter description"
              value={supplierName}
              onChange={(e) => dispatch(changeSupplierNameEdit(e.target.value))}
            />
          </Grid>

          {/* supplier code */}
          <Grid item xs={6} sx={{ mt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                Supplier Code
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 500, color: "#F33A3A" }}
              >
                *
              </Typography>
            </Box>
            <CustomInput
              fullWidth
              sx={{
                with: "100%",
                bgcolor: "#fafafa",
                "& .MuiOutlinedInput-input": {
                  padding: "17px 16px",
                  borderRadius: "10px",
                  "&::placeholder": {
                    color: "#111",
                    fontSize: "16px",
                  },
                },
                borderRadius: "5px",
              }}
              placeholder="enter supplier id"
              value={supplierCode}
              onChange={(e) => dispatch(changeSupplierCodeEdit(e.target.value))}
            />
            {availableCode && (
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 400,
                  mt: 1,
                  color: "#F33A3A",
                }}
              >
                Supplier code has been taken
              </Typography>
            )}
          </Grid>

          {/* email */}
          <Grid item xs={6} sx={{ mt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                Email
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 500, color: "#F33A3A" }}
              >
                *
              </Typography>
            </Box>
            <CustomInput
              type="email"
              fullWidth
              sx={{
                with: "100%",
                bgcolor: "#fafafa",
                "& .MuiOutlinedInput-input": {
                  padding: "17px 16px",
                  borderRadius: "10px",
                  "&::placeholder": {
                    color: "#111",
                    fontSize: "16px",
                  },
                },
                borderRadius: "5px",
              }}
              placeholder="enter description"
              value={email}
              onChange={(e) => {
                handleEmailChange(e);
                dispatch(changeEmailSupplierEdit(e.target.value));
              }}
              error={emailError}
            />
            {emailError && (
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 400,
                  color: "#F33A3A",
                  mt: 1,
                }}
              >
                Email not valid
              </Typography>
            )}
            {availableEmail && (
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 400,
                  mt: 1,
                  color: "#F33A3A",
                }}
              >
                email has been taken
              </Typography>
            )}
          </Grid>

          {/* phone number */}
          <Grid item xs={6} sx={{ mt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                Phone Number
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 500, color: "#F33A3A" }}
              >
                *
              </Typography>
            </Box>
            <CustomInput
              type="number"
              fullWidth
              sx={{
                with: "100%",
                bgcolor: "#fafafa",
                "& .MuiOutlinedInput-input": {
                  padding: "17px 16px",
                  borderRadius: "10px",
                  "&::placeholder": {
                    color: "#111",
                    fontSize: "16px",
                  },
                },
                borderRadius: "5px",
              }}
              placeholder="enter description"
              value={phoneNumber}
              onChange={(e) =>
                dispatch(changePhoneNumberSupplierEdit(e.target.value))
              }
            />
            {availablePhone && (
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 400,
                  mt: 1,
                  color: "#F33A3A",
                }}
              >
                Phone number has been taken
              </Typography>
            )}
          </Grid>

          {/* join date */}
          <Grid item xs={6} sx={{ mt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                Join Date
              </Typography>
            </Box>
            <Box
              sx={{
                background: "#F5F5F5",
                padding: "8px 16px",
                borderRadius: "8px 8px 0px 0px",
                color: "#000",
                height: "56px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                {joinDate || "-"}
              </Typography>
            </Box>
          </Grid>

          {/* address */}
          <Grid item xs={6}>
            <CustomCard
              sx={{ p: "14px 8px", cursor: "pointer" }}
              onClick={() => setIsMapOpen(!isMapOpen)}
            >
              <Box
                sx={{
                  display: "center",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box
                    sx={{
                      width: "54px",
                      height: "54px",
                      bgcolor: "gray",
                      borderRadius: "8px",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        bgcolor: "white",
                        width: "100%",
                        height: "100%",
                        zIndex: "+2",
                        opacity: 0.5,
                      }}
                    ></Box>
                    <Box
                      sx={{
                        maxHeight: "54px",
                        overflow: "hidden",
                        borderRadius: "8px",
                      }}
                    >
                      <Map
                        defaultCenter={[3.546958571415798, 98.6901696840363]}
                        height={130}
                        defaultZoom={17}
                        width={54}
                        style={{
                          borderRadius: "8px",
                        }}
                      ></Map>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                      Address
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.4,
                      }}
                    >
                      <LocationOnIcon
                        color="primary"
                        sx={{ fontSize: "16px" }}
                      />
                      <Typography sx={{ fontSize: "16px", color: "#9E9D9D" }}>
                        {address ? address : "Click to enter address"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <KeyboardArrowDownIcon />
              </Box>
            </CustomCard>
          </Grid>
        </Grid>
      </Box>

      {/* button event  */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "32px 0px 0px",
          alignItems: "center",
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={publish}
              onChange={() => dispatch(changePublishEdit(!publish))}
              color="primary"
            />
          }
          label="Publish"
          labelPlacement="start"
        />
        <Box sx={{ display: "flex", gap: 3 }}>
          <CustomButton
            color="error"
            variant="contained"
            onClick={() => setConfirmDelete(!confirmDelete)}
            sx={{ width: "140px", height: "36px", background: "#F33A3A" }}
          >
            <Typography
              sx={{ color: "white", fontSize: "16px", fontWeight: "500" }}
            >
              Delete
            </Typography>
          </CustomButton>

          <CustomButton
            variant="contained"
            onClick={handleEdit}
            sx={{ width: "140px", height: "36px" }}
          >
            {loadingSubmit ? (
              <CircularProgress size={18} sx={{ color: "#FFF" }} />
            ) : (
              <Typography
                sx={{ color: "white", fontSize: "16px", fontWeight: "500" }}
              >
                Save
              </Typography>
            )}
          </CustomButton>
        </Box>
      </Box>

      {/* dialog address */}
      <Dialog
        open={isMapOpen}
        onClose={() => {
          setIsMapOpen(!isMapOpen);
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minWidth: "200px",
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
            Input Address
          </Typography>
          <CloseIcon
            sx={{ cursor: "pointer" }}
            onClick={() => setIsMapOpen(false)}
          />
        </DialogTitle>
        <DialogContent dividers>
          <SetupAddressEditSupplier setIsOpen={setIsMapOpen} />
        </DialogContent>
      </Dialog>

      {/* dialog delete */}
      <CustomDeleteConfirm
        isLoading={loadingDelete}
        open={confirmDelete}
        onClose={() => setConfirmDelete(!confirmDelete)}
        handleClickCancel={() => setConfirmDelete(!confirmDelete)}
        handleClickDelete={handleDeleteFix}
        title="Delete Supplier"
        body={
          <Box sx={{ textAlign: "center" }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: 600 }}>
                {supplierName}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: "52px" }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />
    </Box>
  );
};
export default EditSupplier;
