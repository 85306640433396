import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useGeolocated } from "react-geolocated";
import { useLocation, useNavigate } from "react-router-dom";
import { login } from "../api/auth";
import logoImage from "../assets/logo.png";
import officeImage from "../assets/office.jpg";
import useAuth from "../hooks/useAuth";
import { useDispatch } from "react-redux";
import { changeSubsidiaryId } from "../store/purchase/addRequestPurchase";

const Login = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "dashboard";
  const { setAuth } = useAuth();

  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState("");
  const [errorUsername, setErrorUsername] = useState(null);

  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(null);

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
    });

  useEffect(() => {
    if (coords) {
      setLatitude(coords.latitude);
      setLongitude(coords.longitude);
    }
  }, [coords]);

  const handleSubmit = async () => {
    if (!loading) {
      if (username !== "" && password !== "" && coords) {
        setLoading(true);
        setErrorUsername(null);
        setErrorPassword(null);

        const data = {
          email: username,
          password,
          lat: latitude,
          long: longitude,
        };

        try {
          const {
            data: {
              data: {
                accessToken,
                company,
                userRole: role,
                email,
                role: { name, id: idRole },
                namaLengkap,
              },
            },
          } = await login(data).then((response) => {
            if (!response.data.meta.success) {
              setErrorPassword(response.data.meta.message);
            }
            return response;
          });

          const companyId = company?.iDPerusahaan || 7;
          setAuth({ accessToken, companyId, role });
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("companyId", companyId);
          localStorage.setItem("name", namaLengkap);
          localStorage.setItem("userRole", role);
          localStorage.setItem("role", idRole);
          localStorage.setItem("roleName", name);
          localStorage.setItem("email", email);
          navigate(from, { replace: true });

          setTimeout(function () {
            localStorage.clear();
            window.location.reload();
            alert("Your session has expired");
          }, 10800000);

          // dispatch(changeSubsidiaryId(subsidiaries[0].id));
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      } else {
        username === ""
          ? setErrorUsername("Please enter your username")
          : setErrorUsername(null);
        password === ""
          ? setErrorPassword("Please enter your password")
          : setErrorPassword(null);
        if (typeof coords == "undefined")
          alert("Please enable your location access!");
      }
    }
  };

  return (
    <Grid
      container
      sx={{
        height: "100%",
        minHeight: "100vh",
        width: 1,
        backgroundImage: `url(${officeImage})`,
        backgroundPosition: "right",
        backgroundSize: "cover",
        boxShadow: (theme) =>
          `inset 0 0 0 100vmax ${theme.palette.primary.main}66`,
      }}
    >
      <Grid
        item
        sx={{
          bgcolor: "background.default",
          p: 4,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          gap: 2,
        }}
        sm={5}
        xs={12}
      >
        <Box>
          <Box
            sx={{
              height: "4rem",
              width: "4rem",
              borderRadius: 1,
            }}
            component="img"
            src={logoImage}
          />
          <Typography
            sx={{
              fontSize: "1.5rem",
              color: "primary.main",
              fontWeight: "bold",
              mt: 9,
            }}
          >
            Hello,
          </Typography>
          <Typography
            sx={{
              fontSize: "1.5rem",
              color: "primary.main",
              fontWeight: "bold",
              mt: 2,
            }}
          >
            Welcome Back
          </Typography>
          <FormControl variant="standard" sx={{ mt: 4 }} fullWidth>
            <InputLabel
              shrink
              htmlFor="username-input"
              sx={{
                fontWeight: "medium",
                fontSize: "1.33rem",
                width: "100%",
              }}
            >
              Username
            </InputLabel>
            <OutlinedInput
              id="username-input"
              sx={{
                mt: 4,
                "& .MuiOutlinedInput-notchedOutline": {
                  boxShadow: Boolean(errorUsername) && "0px 0px 4px #F26C6D",
                },
                "&.Mui-focused": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    boxShadow: Boolean(errorUsername) && "0px 0px 8px #F26C6D",
                  },
                },
              }}
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
                setErrorUsername(null);
              }}
            />
            {Boolean(errorUsername) && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errorUsername}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant="standard" sx={{ mt: 4 }} fullWidth>
            <InputLabel
              shrink
              htmlFor="password-input"
              sx={{
                fontWeight: "medium",
                fontSize: "1.33rem",
              }}
            >
              Password
            </InputLabel>
            <OutlinedInput
              id="password-input"
              type="password"
              sx={{
                mt: 4,
                "& .MuiOutlinedInput-notchedOutline": {
                  boxShadow: Boolean(errorPassword) && "0px 0px 4px #F26C6D",
                },
                "&.Mui-focused": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    boxShadow: Boolean(errorPassword) && "0px 0px 8px #F26C6D",
                  },
                },
              }}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrorPassword(null);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
            {Boolean(errorPassword) && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errorPassword}
              </FormHelperText>
            )}
          </FormControl>
          {/* <Box sx={{ textAlign: 'right', mt: 2 }}>
        <Link component={RouterLink} to="">
          Forgot Password?
        </Link>
      </Box> */}
          <Button
            type="submit"
            onClick={handleSubmit}
            sx={{ mt: 4, py: 1.25, fontSize: "1.25rem", height: "55px" }}
            fullWidth
            variant="contained"
          >
            {!loading ? (
              "Login"
            ) : (
              <Box
                sx={{
                  transform: "scaleX(-1)",
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress size={28} sx={{ color: "#fff" }} />
              </Box>
            )}
          </Button>
        </Box>
        <Typography
          align="center"
          sx={{
            color: "#000",
            fontSize: "1.2rem",
            fontWeight: "bold",
            left: 0,
            width: "100%",
            display: !matches && "none",
          }}
        >
          &copy; PT Raksasa Indonesia
        </Typography>
      </Grid>
      <Grid
        item
        sm={7}
        xs={12}
        sx={{
          display: matches ? "none" : "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          p: 6,
        }}
      >
        <Typography
          sx={{ color: "common.white", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          &copy; PT Raksasa Indonesia
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Login;
