import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  TextField,
  Dialog,
  Autocomplete,
  useTheme,
  styled,
  Switch,
} from "@mui/material";
import CustomInput from "../../../../../../components/shared/CustomInput";
import BackButton from "../../../../../../components/shared/BackButton";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import CustomButton from "../../../../../../components/shared/CustomButton";
import Star from "../../../../../../components/shared/Star";

const CategoryMenuEditDesktop = (props) => {
  const {
    theme,
    today,
    AntSwitch,
    // *--- cata value
    categoryEditInputDatas,
    setCategoryEditInputDatas,
    // *--- Data
    subsidiaryCategory,
    subsidiaryCategoryLoading,
    typeCategory,
    // *--- Handler
    handleBackButton,
    handleDelete,
    handleEdit,
    handleAddImage,
    handleDeleteImage,
    handlePreviewImage,
    handleClosePreviewImage,
    // *--- Validation
    isSubmitAvailable,
    setIsSubmitAvailable,
  } = props;
  return (
    <Grid container columns={12} spacing={2}>
      {/* //* Back */}
      <Grid item xs={10} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        <Typography
          sx={{
            ml: "8px",
            fontSize: "24px",
            fontWeight: "600",
          }}
        >
          Add Category
        </Typography>
      </Grid>
      {/* //* Subsidiary */}
      <Grid item xs={12}>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ mb: "16px", fontSize: "16px", fontWeight: "500" }}>
            Subsidiary
          </Typography>
          <Star />
        </Box>
        <FormControl>
          <RadioGroup
            row
            value={categoryEditInputDatas.subsidiaryId}
            onChange={(e) => {
              setCategoryEditInputDatas({
                ...categoryEditInputDatas,
                subsidiaryId: e.target.value,
              });
            }}
          >
            {!subsidiaryCategoryLoading && (
              <>
                {subsidiaryCategory.map((data, index) => (
                  <FormControlLabel
                    value={data.id}
                    control={<Radio />}
                    label={data.name}
                    key={index}
                  />
                ))}
              </>
            )}
          </RadioGroup>
        </FormControl>
      </Grid>
      {/* //* Name */}
      <Grid item xs={6}>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ mb: "16px", fontSize: "16px", fontWeight: "500" }}>
            Name
          </Typography>
          <Star />
        </Box>
        <CustomInput
          sx={{ width: "100%", bgcolor: "#FAFAFA" }}
          placeholder="Enter description"
          value={categoryEditInputDatas.name}
          onChange={(e) => {
            if (e.target.value.length <= 30) {
              setCategoryEditInputDatas({
                ...categoryEditInputDatas,
                name: e.target.value,
              });
            }
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#9E9D9D",
              mt: "4px",
            }}
          >
            Enter at least 5 character
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#9E9D9D",
              mt: "4px",
            }}
          >
            {categoryEditInputDatas.name.length}/30
          </Typography>
        </Box>
      </Grid>
      {/* //* Photo */}
      <Grid item xs={6} sx={{ minHeight: "100%" }}>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            pb: `${categoryEditInputDatas.photo.length === 0 ? "16px" : "9px"}`,
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
            Photo
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#9E9D9D",
            }}
          >
            ( max size : 2MB )
          </Typography>
        </Box>
        {categoryEditInputDatas.isMaxSizePhoto && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ErrorOutlineIcon
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: theme.palette.warning.main,
                mr: "4px",
              }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                color: theme.palette.warning.main,
              }}
            >
              The photo can't be more than 2MB
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {categoryEditInputDatas.photoUrls.length === 0 && (
              <Box
                sx={{
                  height: "54px",
                  width: "54px",
                  aspectRatio: "1/1",
                  borderRadius: "8px",
                  bgcolor: "#F5F5F5",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderStyle: "dashed",
                  borderColor: "#9E9D9D",
                  cursor: "pointer",
                }}
                onClick={() => {
                  document.getElementById("input_img").click();
                }}
              >
                <ImageOutlinedIcon
                  sx={{ color: "#9E9D9D", fontSize: "28px" }}
                />
              </Box>
            )}
            {categoryEditInputDatas.photoUrls.map((input, index) => (
              <Box
                sx={{
                  position: "relative",
                  overflow: "visible",
                  padding: "7px",
                }}
                key={index}
              >
                <CancelIcon
                  sx={{
                    position: "absolute",
                    fontSize: "20px",
                    top: 1,
                    right: 1,
                    zIndex: +1,
                    cursor: "pointer",
                  }}
                  onClick={() => handleDeleteImage(input)}
                />
                <Box
                  component="img"
                  src={process.env.REACT_APP_API_IMAGE_BASE_URL + "/" + input}
                  sx={{
                    height: "54px",
                    width: "54px",
                    aspectRatio: "1/1",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => handlePreviewImage(input)}
                />
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              mb: "7px",
              ml: "20px",
              alignItems: "center",
            }}
          >
            {categoryEditInputDatas.photoUrls.length < 3 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: theme.palette.primary.main,
                  cursor: "pointer",
                }}
                onClick={() => {
                  document.getElementById("input_img").click();
                }}
              >
                <input
                  accept="image/*"
                  id="input_img"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleAddImage}
                />
                <AddIcon />
                <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                  Add More
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      {/* //* Dialog Photo */}
      {categoryEditInputDatas.linkPreviewPhoto !== "" && (
        <Dialog
          open={categoryEditInputDatas.isPreviewPhoto}
          onClose={handleClosePreviewImage}
        >
          <Box
            component="img"
            src={
              process.env.REACT_APP_API_IMAGE_BASE_URL +
              "/" +
              categoryEditInputDatas.linkPreviewPhoto
            }
            sx={{ width: "600px", height: "600px", aspectRation: "1/1" }}
          />
        </Dialog>
      )}
      {/* //* Type */}
      <Grid item xs={6}>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ mb: "16px", fontSize: "16px", fontWeight: "500" }}>
            Type
          </Typography>
          <Star />
        </Box>
        <Autocomplete
          options={typeCategory}
          getOptionLabel={(option) => option.nmOpsi}
          inputValue={categoryEditInputDatas.typeInput}
          onInputChange={(_, newValue) => {
            setCategoryEditInputDatas({
              ...categoryEditInputDatas,
              typeInput: newValue,
            });
          }}
          value={categoryEditInputDatas.type}
          onChange={(_, newValue) => {
            setCategoryEditInputDatas({
              ...categoryEditInputDatas,
              type: newValue,
              typeId: newValue.iDopsi,
              typeName: newValue.nmOpsi,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ bgcolor: "#FAFAFA" }}
              placeholder="Select Type"
            />
          )}
          // loading={typeCategoryLoading}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#9E9D9D",
              mt: "4px",
            }}
          >
            Enter at least 20 character
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#9E9D9D",
              mt: "4px",
            }}
          >
            {categoryEditInputDatas.description.length}/250
          </Typography>
        </Box>
      </Grid>
      {/* //* Schedule */}
      <Grid item xs={6} sx={{}}>
        <Grid container>
          <Grid item alignItems="center" sx={{ height: "100%", mr: "20px" }}>
            <Typography
              sx={{
                mb: "16px",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Schedule
            </Typography>
          </Grid>
          <Grid item alignItems="center" sx={{ height: "100%" }}>
            <RadioGroup
              row
              value={categoryEditInputDatas.scheduleType}
              onChange={(e) => {
                setCategoryEditInputDatas({
                  ...categoryEditInputDatas,
                  schedule: today,
                  scheduleType: e.target.value,
                });
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: "16px",
                },
                ".MuiRadio-root": {
                  padding: "0 8px",
                },
                ".MuiTypography-root": {
                  fontSize: "12px",
                },
              }}
            >
              <FormControlLabel value="now" control={<Radio />} label="Now" />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", minWidth: "100%" }}>
          <DesktopDatePicker
            inputFormat="DD/MM/YYYY"
            value={categoryEditInputDatas.schedule}
            onChange={(newValue) => {
              if (categoryEditInputDatas.scheduleType !== "now") {
                setCategoryEditInputDatas({
                  ...categoryEditInputDatas,
                  schedule: newValue,
                });
              } else {
                setCategoryEditInputDatas({
                  ...categoryEditInputDatas,
                  schedule: today,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ width: "100%", bgcolor: "#FAFAFA" }}
              />
            )}
            disabled={
              categoryEditInputDatas.scheduleType === "now" ? true : false
            }
          />
        </Box>
      </Grid>
      {/* //* Description */}
      <Grid item xs={12}>
        <Typography sx={{ mb: "16px", fontSize: "16px", fontWeight: "500" }}>
          Description
        </Typography>
        <CustomInput
          sx={{ width: "100%", bgcolor: "#FAFAFA" }}
          placeholder="Enter category name"
          value={categoryEditInputDatas.description}
          onChange={(e) => {
            if (e.target.value.length <= 250) {
              setCategoryEditInputDatas({
                ...categoryEditInputDatas,
                description: e.target.value,
              });
            }
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#9E9D9D",
              mt: "4px",
            }}
          >
            Enter at least 20 character
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#9E9D9D",
              mt: "4px",
            }}
          >
            {categoryEditInputDatas.description.length}/250
          </Typography>
        </Box>
      </Grid>
      {/* //* Submit FOrm */}
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          position: "fixed",
          bottom: "0px",
          right: "0px",
          display: "flex",
          justifyContent: "space-between",
          padding: "18px 32px 18px 240px",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
            Publish
          </Typography>
          <AntSwitch
            checked={categoryEditInputDatas.publish}
            onChange={(e) => {
              setCategoryEditInputDatas({
                ...categoryEditInputDatas,
                publish: !categoryEditInputDatas.publish,
              });
            }}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <CustomButton
            variant="contained"
            // disabled={!isSubmitAvailable}
            onClick={handleDelete}
            color="error"
            sx={{ width: "140px", height: "36px" }}
          >
            <Typography
              sx={{ color: "white", fontSize: "12px", fontWeight: "500" }}
            >
              Delete
            </Typography>
          </CustomButton>
          <CustomButton
            variant="contained"
            disabled={!isSubmitAvailable}
            onClick={handleEdit}
            sx={{ width: "140px", height: "36px" }}
          >
            <Typography
              sx={{ color: "white", fontSize: "12px", fontWeight: "500" }}
            >
              Save
            </Typography>
          </CustomButton>
        </Box>
      </Box>
    </Grid>
  );
};

export default CategoryMenuEditDesktop;
