import { useDispatch, useSelector } from 'react-redux';
import BackButton from '../../../../../../components/shared/BackButton';
import {
  changePage,
  getAllPurchaseRouting,
} from '../../../../../../store/Routing/purchaseRouting';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { ArrowRightAltIcon, QrCodeIcon } from '../../../../../../assets/Icons';
import { changeIdPurchaseDetail } from '../../../../../../store/purchase/detailPurchase';

const BackButtonPurchaseProcessDetailSupplier = () => {
  const dispatch = useDispatch();
  const { idPurchase, subsidiaryName, hubName, page } = useSelector(
    getAllPurchaseRouting
  );

  const handleBackButton = () => {
    dispatch(changePage(''));
  };

  const desktopMode = useMediaQuery('(min-width:832px)');

  const handleToQrCode = () => {
    dispatch(changePage('qrCode'));
    dispatch(changeIdPurchaseDetail(idPurchase));
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        <Box>
          <Typography
            sx={{ fontSize: desktopMode ? '24px' : '14px', fontWeight: '600' }}
          >
            Process Purchase
          </Typography>
          <Box
            sx={{
              display: 'flex',
              color: '#9E9D9D',

              alignItems: 'center',
              mt: '4px',
              gap: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: desktopMode ? '16px' : '12px',
                fontWeight: '100',
              }}
            >
              {subsidiaryName || '-'}
            </Typography>
            <ArrowRightAltIcon sx={{ fontSize: '16px' }} />
            <Typography
              sx={{
                fontSize: desktopMode ? '16px' : '12px',
                fontWeight: '100',
              }}
            >
              {hubName || '-'}
            </Typography>
          </Box>
        </Box>
      </Box>
      {page === 'purchaseProcessDetailSupplier' && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '36px',
            height: '36px',
            borderRadius: '8px',
            bgcolor: '#51B15C1A',
            cursor: 'pointer',
          }}
          onClick={handleToQrCode}
        >
          <QrCodeIcon />
        </Box>
      )}
    </Box>
  );
};
export default BackButtonPurchaseProcessDetailSupplier;
