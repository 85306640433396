import React from 'react';
import { useSearchParams, createSearchParams, Link } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const CustomTab = styled(Tab)({
  textTransform: 'capitalize',
  color: '#9E9D9D',
  fontWeight: 500,
  fontFamily: 'Poppins',
  textAlign: 'left',
});

export default function BasicTabs({
  labelTabsArray,
  children,
  value,
  setValue,
  setRowSelected,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    updatedSearchParams.set('tab', newValue);
    updatedSearchParams.delete('detail');
    setRowSelected(null);
    setSearchParams(updatedSearchParams);
  };

  return (
    <Box sx={{ width: '100%', maxWidth: { xs: '100%', sm: '100%' } }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          {labelTabsArray.map((item, index) => {
            return (
              <CustomTab
                label={item.label}
                key={index}
                // component={Link}
                // to={item.to}
              />
            );
          })}

          {/* <CustomTab label="Stock" />
          <CustomTab label="Hub" />
          <CustomTab label="Product" />
          <CustomTab label="Menu" />
          <CustomTab label="Purchases" />
          <CustomTab label="Transaction" />
          <CustomTab label="Audit" />
          <CustomTab label="Adjustment" /> */}
        </Tabs>
      </Box>

      {children}
    </Box>
  );
}
