import { Box, Grid, useMediaQuery } from '@mui/material';
import BackButtonPurchaseQrCode from './BackButtonPurchaseQrCode';
import { useState } from 'react';
import TablePurchaseQrCode from './TablePurchaseQrCode';
import { LoadingComponent } from '../../../../../../components/shared/shared2';
import PurchaseQrCodeMobile from '../PurchaseMobile/PurchaseQrCode/PurchaseQrCodeMobile';

const PurchaseQrCode = () => {
  const matchTablet = useMediaQuery('(min-width:832px)');
  const roleUser = localStorage.getItem('role');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <Box sx={{ mt: '16px' }}>
      {!isLoading &&
        !isError &&
        (!matchTablet ? (
          <PurchaseQrCodeMobile />
        ) : (
          <>
            <BackButtonPurchaseQrCode />
            <Grid
              container
              columns={1}
              spacing={4}
              sx={{ mt: '16px', pb: '54px' }}
            >
              <Grid item xs={1}>
                <TablePurchaseQrCode />
              </Grid>
            </Grid>
          </>
        ))}
      {isLoading && <LoadingComponent />}
    </Box>
  );
};
export default PurchaseQrCode;
