import { createSlice } from "@reduxjs/toolkit";
import {
  getPlacesByCoordinate,
  getPlacesByName,
} from "../../../api/AddressMap";
import { deleteFile, uploadImage, uploadVideo } from "../../../api/storage";
import { getSubsidiary } from "../../../api/subsidiary";

export const masterHubAddState = createSlice({
  name: "masterHubAdd",
  initialState: {
    isLoading: false,
    subsidiaryId: 0,
    name: "",
    photo: [],
    isMaxSizePhoto: false,
    isUploadImage: false,
    photoPreview: "",
    isPhotoPreview: false,
    video: [],
    isUploadVideo: false,
    isMaxSizeVideo: false,
    videoPreview: "",
    isVideoPreview: false,
    picChoose: null,
    picSearch: "",
    picSearchDebounce: "",
    picNumber: "",
    isCreateMap: false,
    createMapPage: "grid", //? -- grid, racks, preview --
    col: 5,
    row: 5,
    cell: [],
    isEntrance: false,
    entrance: [],
    cellSelectedAll: [],
    cellSelected: [],
    cellSelectedDetails: [],
    cellSelectedMoment: [],
    cellSelectedNameMoment: "",
    cellSelectedColMoment: 5,
    cellSelectedRowMoment: 5,
    cellSelectedDesMoment: "",
    cellSelectedHover: [],
    cellSelectedEditTemporary: {},
    isCellSelectedEdit: false,
    idDetailHover: 0,
    dataDetailRack: null,
    // *--- Address Map
    mapInputType: "manual",
    coordinate: [],
    center: [3.546728899138594, 98.69014558305145],
    placesSearchByName: [],
    placesSearchByNameLoading: false,
    placeGetByName: null,
    placeGetByCoordinate: null,
    placeGetByCoordinateLoading: false,
    address: "",
    adressFix: "",
    coordinateFix: [],
    status: false,
    errorEmpty: "",
    errorMessageVideo: "",
    errorMassageImage: "",
  },
  reducers: {
    changeLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    changeSubsidiaryId: (state, action) => {
      state.subsidiaryId = action.payload;
    },
    changeName: (state, action) => {
      state.name = action.payload;
    },
    inputPhoto: (state, action) => {
      state.photo = [...state.photo, action.payload];
    },
    deletePhoto: (state, action) => {
      state.photo = state.photo.filter((item) => item !== action.payload);
    },
    changeIsUploadImageHub: (state, action) => {
      state.isUploadImage = action.payload;
    },
    changeIsMaxSizePhoto: (state, action) => {
      state.isMaxSizePhoto = action.payload;
    },
    changePhotoPreview: (state, action) => {
      state.photoPreview = action.payload;
    },
    clickIsPreviewPhoto: (state) => {
      state.isPhotoPreview = !state.isPhotoPreview;
    },
    inputVideo: (state, action) => {
      state.video = [...state.video, action.payload];
    },
    changeIsUploadVideoHub: (state, action) => {
      state.isUploadVideo = action.payload;
    },
    deleteVideo: (state, action) => {
      state.video = state.video.filter((item) => item !== action.payload);
    },
    changeIsMaxSizeVideo: (state, action) => {
      state.isMaxSizeVideo = action.payload;
    },
    changeVideoPreview: (state, action) => {
      state.videoPreview = action.payload;
    },
    clickIsPreviewVideo: (state) => {
      state.isVideoPreview = !state.isVideoPreview;
    },
    changePicChoose: (state, action) => {
      state.picChoose = action.payload;
    },
    changePicSearch: (state, action) => {
      state.picSearch = action.payload;
    },
    changePicSearchDebounce: (state, action) => {
      state.picSearchDebounce = action.payload;
    },
    changePicNumber: (state, action) => {
      state.picNumber = action.payload;
    },
    changeIsCreateMap: (state) => {
      state.isCreateMap = !state.isCreateMap;
    },
    changeCreateMapPage: (state, action) => {
      state.createMapPage = action.payload;
    },
    changeColCreateMap: (state, action) => {
      if (action.payload <= 50 && action.payload > 0) {
        state.col = action.payload;
      }
    },
    changeRowCreateMap: (state, action) => {
      if (action.payload <= 50 && action.payload > 0) {
        state.row = action.payload;
      }
    },
    addColCreateMapRight: (state, action) => {
      if (state.col < 50 && state.col > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row + 2)].map((_, idxr) => {
          let v = [...Array(state.col + 2 + action.payload)].map((_, idxc) => {
            return `r${idxr + 1}c${idxc + 1}`;
          });
          return v;
        });

        state.col = state.col + action.payload;
        state.cell = nilai;
      }
    },
    reduceColCreateMapRight: (state, action) => {
      if (state.col <= 50 && state.col > 1) {
        let limit = `c${state.col + 2 - action.payload}`;
        if (
          state.cellSelectedAll.filter((item) => item.includes(limit))
            .length === 0 &&
          state.cellSelectedMoment.filter((item) => item.includes(limit))
            .length === 0
        ) {
          state.entrance = [];
          let nilai = [...Array(state.row + 2)].map((_, idxr) => {
            let v = [...Array(state.col + 2 - action.payload)].map(
              (_, idxc) => {
                return `r${idxr + 1}c${idxc + 1}`;
              }
            );
            return v;
          });

          state.col = state.col - action.payload;
          state.cell = nilai;
        }
      }
    },
    addColCreateMapLeft: (state, action) => {
      if (state.col < 50 && state.col > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row + 2)].map((_, idxr) => {
          let v = [...Array(state.col + 2 + action.payload)].map((_, idxc) => {
            return `r${idxr + 1}c${idxc + 1}`;
          });
          return v;
        });
        state.col = state.col + action.payload;
        state.cell = nilai;
        let dataCell = state.cellSelected.map((item) => {
          let dataChild = item.map((v) => {
            let child = v.split("r")[1].split("c");
            let childValue = `r${Number(child[0])}c${
              Number(child[1]) + action.payload
            }`;
            return childValue;
          });
          return dataChild;
        });
        let dataCellDetails = state.cellSelectedDetails.map((item) => {
          let child = item.columnPosition.map((v) => {
            let cols = v.split("r")[1].split("c");
            let colsValue = `r${Number(cols[0])}c${
              Number(cols[1]) + action.payload
            }`;
            return colsValue;
          });
          return {
            ...item,
            columnPosition: child,
          };
        });
        let dataCellMoment = state.cellSelectedMoment.map((item) => {
          let child = item.split("r")[1].split("c");
          let childValue = `r${Number(child[0])}c${
            Number(child[1]) + action.payload
          }`;
          return childValue;
        });
        state.cellSelectedMoment = dataCellMoment;
        state.cellSelected = dataCell;
        state.cellSelectedDetails = dataCellDetails;
        state.cellSelectedAll = state.cellSelected.flat();
      }
    },
    reduceColCreateMapLeft: (state, action) => {
      if (state.col <= 50 && state.col > 1) {
        if (
          state.cellSelectedAll.filter(
            (item) => item.split("r")[1].split("c")[1] === "2"
          ).length === 0 &&
          state.cellSelectedMoment.filter(
            (item) => item.split("r")[1].split("c")[1] === "2"
          ).length === 0
        ) {
          state.entrance = [];
          let nilai = [...Array(state.row + 2)].map((_, idxr) => {
            let v = [...Array(state.col + 2 - action.payload)].map(
              (_, idxc) => {
                return `r${idxr + 1}c${idxc + 1}`;
              }
            );
            return v;
          });
          state.col = state.col - action.payload;
          state.cell = nilai;
          let dataCell = state.cellSelected.map((item) => {
            let dataChild = item.map((v) => {
              let child = v.split("r")[1].split("c");
              let childValue = `r${Number(child[0])}c${
                Number(child[1]) - action.payload
              }`;
              return childValue;
            });
            return dataChild;
          });
          let dataCellDetails = state.cellSelectedDetails.map((item) => {
            let child = item.columnPosition.map((v) => {
              let cols = v.split("r")[1].split("c");
              let colsValue = `r${Number(cols[0])}c${
                Number(cols[1]) - action.payload
              }`;
              return colsValue;
            });
            return {
              ...item,
              columnPosition: child,
            };
          });
          let dataCellMoment = state.cellSelectedMoment.map((item) => {
            let child = item.split("r")[1].split("c");
            let childValue = `r${Number(child[0])}c${
              Number(child[1]) - action.payload
            }`;
            return childValue;
          });
          state.cellSelectedMoment = dataCellMoment;
          state.cellSelected = dataCell;
          state.cellSelectedDetails = dataCellDetails;
          state.cellSelectedAll = state.cellSelected.flat();
        }
      }
    },
    addRowCreateMapTop: (state, action) => {
      if (state.row < 50 && state.row > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row + 2 + action.payload)].map(
          (_, idxr) => {
            let v = [...Array(state.col + 2)].map((_, idxc) => {
              return `r${idxr + 1}c${idxc + 1}`;
            });
            return v;
          }
        );
        state.row = state.row + action.payload;
        state.cell = nilai;
        let dataCell = state.cellSelected.map((item) => {
          let dataChild = item.map((v) => {
            let child = v.split("r")[1].split("c");
            let childValue = `r${Number(child[0]) + action.payload}c${Number(
              child[1]
            )}`;
            return childValue;
          });
          return dataChild;
        });
        let dataCellDetails = state.cellSelectedDetails.map((item) => {
          let child = item.columnPosition.map((v) => {
            let cols = v.split("r")[1].split("c");
            let colsValue = `r${Number(cols[0]) + action.payload}c${Number(
              cols[1]
            )}`;
            return colsValue;
          });
          return {
            ...item,
            columnPosition: child,
          };
        });
        let dataCellMoment = state.cellSelectedMoment.map((item) => {
          let child = item.split("r")[1].split("c");
          let childValue = `r${Number(child[0]) + action.payload}c${Number(
            child[1]
          )}`;
          return childValue;
        });
        state.cellSelectedMoment = dataCellMoment;
        state.cellSelected = dataCell;
        state.cellSelectedDetails = dataCellDetails;
        state.cellSelectedAll = state.cellSelected.flat();
      }
    },
    reduceRowCreateMapTop: (state, action) => {
      if (state.row <= 50 && state.row > 1) {
        if (
          state.cellSelectedAll.filter(
            (item) => item.split("r")[1].split("c")[0] === "2"
          ).length === 0 &&
          state.cellSelectedMoment.filter(
            (item) => item.split("r")[1].split("c")[0] === "2"
          ).length === 0
        ) {
          state.entrance = [];
          let nilai = [...Array(state.row + 2 - action.payload)].map(
            (_, idxr) => {
              let v = [...Array(state.col + 2)].map((_, idxc) => {
                return `r${idxr + 1}c${idxc + 1}`;
              });
              return v;
            }
          );
          state.row = state.row - action.payload;
          state.cell = nilai;
          let dataCell = state.cellSelected.map((item) => {
            let dataChild = item.map((v) => {
              let child = v.split("r")[1].split("c");
              let childValue = `r${Number(child[0]) - action.payload}c${Number(
                child[1]
              )}`;
              return childValue;
            });
            return dataChild;
          });
          let dataCellDetails = state.cellSelectedDetails.map((item) => {
            let child = item.columnPosition.map((v) => {
              let cols = v.split("r")[1].split("c");
              let colsValue = `r${Number(cols[0]) - action.payload}c${Number(
                cols[1]
              )}`;
              return colsValue;
            });
            return {
              ...item,
              columnPosition: child,
            };
          });
          let dataCellMoment = state.cellSelectedMoment.map((item) => {
            let child = item.split("r")[1].split("c");
            let childValue = `r${Number(child[0]) - action.payload}c${Number(
              child[1]
            )}`;
            return childValue;
          });
          state.cellSelectedMoment = dataCellMoment;
          state.cellSelected = dataCell;
          state.cellSelectedDetails = dataCellDetails;
          state.cellSelectedAll = state.cellSelected.flat();
        }
      }
    },
    addRowCreateMapBottom: (state, action) => {
      if (state.row < 50 && state.row > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row + 2 + action.payload)].map(
          (_, idxr) => {
            let v = [...Array(state.col + 2)].map((_, idxc) => {
              return `r${idxr + 1}c${idxc + 1}`;
            });
            return v;
          }
        );

        state.row = state.row + action.payload;
        state.cell = nilai;
      }
    },
    reduceRowCreateMapBottom: (state, action) => {
      if (state.row <= 50 && state.row > 1) {
        let limit = `r${state.row + 2 - action.payload}`;
        if (
          state.cellSelectedAll.filter((item) => item.includes(limit))
            .length === 0 &&
          state.cellSelectedMoment.filter((item) => item.includes(limit))
            .length === 0
        ) {
          state.entrance = [];
          let nilai = [...Array(state.row + 2 - action.payload)].map(
            (_, idxr) => {
              let v = [...Array(state.col + 2)].map((_, idxc) => {
                return `r${idxr + 1}c${idxc + 1}`;
              });
              return v;
            }
          );
          let entranceNew = state.entrance.map((ent) => {
            let valueArr = ent.split("r")[1].split("c");
            return `r${Number(valueArr[0]) - 1}c${Number(valueArr[1])}`;
          });
          state.entrance = entranceNew;
          state.row = state.row - action.payload;
          state.cell = nilai;
        }
      }
    },
    setUpCell: (state) => {
      let nilai = [...Array(state.row + 2)].map((_, idxr) => {
        let v = [...Array(state.col + 2)].map((_, idxc) => {
          return `r${idxr + 1}c${idxc + 1}`;
        });
        return v;
      });
      state.cell = nilai;
    },
    changeIsEntrance: (state) => {
      if (state.isEntrance) {
        state.entrance = [];
      }
      state.isEntrance = !state.isEntrance;
    },
    addEntrance: (state, action) => {
      if (state.isEntrance) {
        if (state.entrance.includes(action.payload)) {
          state.entrance = state.entrance.filter((v) => v !== action.payload);
        } else {
          state.entrance = [...state.entrance, action.payload];
        }
      }
    },
    addCellSelectedMoment: (state, action) => {
      if (state.cellSelectedAll.includes(action.payload)) {
      } else {
        if (state.cellSelectedMoment.includes(action.payload)) {
          state.cellSelectedMoment = state.cellSelectedMoment.filter(
            (v) => v !== action.payload
          );
        } else {
          state.cellSelectedMoment = [
            ...state.cellSelectedMoment,
            action.payload,
          ];
        }
      }
    },
    cancleSelectedMoment: (state) => {
      state.cellSelectedMoment = [];
    },
    addCellSelectedMomentHold: (state, action) => {
      if (state.cellSelectedMoment.includes(action.payload)) {
      } else {
        if (state.cellSelectedMoment.includes(action.payload)) {
        } else {
          state.cellSelectedMoment = [
            ...state.cellSelectedMoment,
            action.payload,
          ];
        }
      }
    },
    changeNameCellSelectedMoment: (state, action) => {
      state.cellSelectedNameMoment = action.payload;
    },
    changeColCellSelectedMoment: (state, action) => {
      if (action.payload > 0 && action.payload <= 50) {
        state.cellSelectedColMoment = action.payload;
      }
    },
    changeRowCellSelectedMoment: (state, action) => {
      if (action.payload > 0 && action.payload <= 50) {
        state.cellSelectedRowMoment = action.payload;
      }
    },
    changeDesCellSelectedMoment: (state, action) => {
      state.cellSelectedDesMoment = action.payload;
    },
    makeCellFix: (state) => {
      let data = {
        id: state.cellSelectedDetails.length + 1,
        name: state.cellSelectedNameMoment,
        column: state.cellSelectedColMoment,
        row: state.cellSelectedRowMoment,
        description: state.cellSelectedDesMoment,
        columnPosition: state.cellSelectedMoment,
      };
      state.cellSelected = [...state.cellSelected, state.cellSelectedMoment];
      state.cellSelectedDetails = [...state.cellSelectedDetails, data];
      state.cellSelectedNameMoment = "";
      state.cellSelectedColMoment = 5;
      state.cellSelectedRowMoment = 5;
      state.cellSelectedDesMoment = "";
      state.cellSelectedMoment = [];
      state.cellSelectedAll = state.cellSelected.flat();
    },
    deleteCellFix: (state, action) => {
      state.cellSelected = state.cellSelected.filter(
        (_, idx) => idx !== action.payload
      );
      state.cellSelectedDetails = state.cellSelectedDetails.filter(
        (_, idx) => idx !== action.payload
      );
      state.cellSelectedAll = state.cellSelected.flat();
    },
    editCellFix: (state, action) => {
      let data = state.cellSelectedDetails.find(
        (_, idx) => idx === action.payload
      );
      state.isCellSelectedEdit = true;
      state.cellSelectedEditTemporary = data;
      state.cellSelected = state.cellSelected.filter(
        (_, idx) => idx !== action.payload
      );
      state.cellSelectedDetails = state.cellSelectedDetails.filter(
        (_, idx) => idx !== action.payload
      );
      state.cellSelectedAll = state.cellSelected.flat();

      state.cellSelectedNameMoment = data.name;
      state.cellSelectedColMoment = data.column;
      state.cellSelectedRowMoment = data.row;
      state.cellSelectedDesMoment = data.description;
      state.cellSelectedMoment = data.columnPosition;
    },
    submitEditCellFix: (state) => {
      let data = {
        id: state.cellSelectedEditTemporary.id,
        name: state.cellSelectedNameMoment,
        column: state.cellSelectedColMoment,
        row: state.cellSelectedRowMoment,
        description: state.cellSelectedDesMoment,
        columnPosition: state.cellSelectedMoment,
      };
      state.cellSelected = [...state.cellSelected, state.cellSelectedMoment];
      state.cellSelectedDetails = [...state.cellSelectedDetails, data];
      state.cellSelectedNameMoment = "";
      state.cellSelectedColMoment = 5;
      state.cellSelectedRowMoment = 5;
      state.cellSelectedDesMoment = "";
      state.cellSelectedMoment = [];
      state.cellSelectedEditTemporary = {};
      state.isCellSelectedEdit = false;
      state.cellSelectedAll = state.cellSelected.flat();
    },
    cancelEditCellFix: (state) => {
      state.cellSelected = [
        ...state.cellSelected,
        state.cellSelectedEditTemporary.columnPosition,
      ];
      state.cellSelectedDetails = [
        ...state.cellSelectedDetails,
        state.cellSelectedEditTemporary,
      ];
      state.cellSelectedNameMoment = "";
      state.cellSelectedColMoment = 5;
      state.cellSelectedRowMoment = 5;
      state.cellSelectedDesMoment = "";
      state.cellSelectedMoment = [];
      state.cellSelectedEditTemporary = {};
      state.isCellSelectedEdit = false;
      state.cellSelectedAll = state.cellSelected.flat();
    },
    hoverCellFix: (state, action) => {
      let data = state.cellSelected.find((_, idx) => idx === action.payload);
      let detail = state.cellSelectedDetails.find(
        (_, idx) => idx === action.payload
      );
      state.cellSelectedHover = data;
      state.idDetailHover = detail.id;
    },
    unHoverCellFix: (state) => {
      state.cellSelectedHover = [];
      state.idDetailHover = 0;
    },
    hoverRacks: (state, action) => {
      if (state.cellSelectedAll.includes(action.payload)) {
        let data = state.cellSelected.find((item) =>
          item.includes(action.payload)
        );
        let detals = state.cellSelectedDetails.find((item) =>
          item.columnPosition.includes(action.payload)
        );
        state.cellSelectedHover = data;
        state.idDetailHover = detals.id;
      }
    },
    unHoverRacks: (state) => {
      state.cellSelectedHover = [];
      state.idDetailHover = 0;
    },
    seeDetailRack: (state) => {
      if (state.idDetailHover !== 0) {
        state.dataDetailRack = state.cellSelectedDetails.find(
          (detail) => detail.id === state.idDetailHover
        );
      }
    },
    unSeeDetailRack: (state) => {
      state.dataDetailRack = null;
    },
    // *---- Address Map
    changeMapType: (state, action) => {
      state.mapInputType = action.payload;
    },
    changeCoordinate: (state, action) => {
      state.coordinate = action.payload;
    },
    changeCenter: (state, action) => {
      state.center = action.payload;
    },

    loadPlaceSearchByName: (state, action) => {
      state.placesSearchByName = action.payload;
    },
    loadPlaceSearchByNameLoading: (state, action) => {
      state.placesSearchByNameLoading = action.payload;
    },
    changePlaceGetByName: (state, action) => {
      let result = action.payload;
      state.placeGetByName = result;
      state.coordinate = [result.lat, result.lon];
      state.center = [result.lat, result.lon];
    },
    changePlaceGetByCoordinate: (state, action) => {
      let result = action.payload;
      state.placeGetByCoordinate = result;
    },
    changeIsPlaceGetByCoordinateLoading: (state, action) => {
      let result = action.payload;
      state.placeGetByCoordinateLoading = result;
    },
    changeAddress: (state, action) => {
      let result = action.payload;
      state.address = result;
    },
    submitAddresMap: (state, action) => {
      const { address, coordinate } = action.payload;
      state.adressFix = address;
      state.coordinateFix = coordinate;
    },
    changeStatus: (state) => {
      state.status = !state.status;
    },
    changeErrorEmpty: (state, action) => {
      state.errorEmpty = action.payload;
    },
    changeErrorMessageVideo: (state, action) => {
      state.errorMessageVideo = action.payload;
    },
    changeErrorMassageImage: (state, action) => {
      state.errorMassageImage = action.payload;
    },
    // *---- Last
    clearData: (state) => {
      state.isLoading = false;
      state.subsidiaryId = 0;
      state.name = "";
      state.photo = [];
      state.isMaxSizePhoto = false;
      state.photoPreview = "";
      state.isPhotoPreview = false;
      state.video = [];
      state.isMaxSizeVide = false;
      state.videoPreview = "";
      state.isVideoPreview = false;
      state.picChoose = null;
      state.picSearch = "";
      state.picSearchDebounce = "";
      state.picNumber = "";
      state.isCreateMap = false;
      state.createMapPage = "preview"; //? -- grid, racks, preview --
      state.col = 5;
      state.row = 5;
      state.cell = [];
      state.isEntrance = false;
      state.entrance = [];
      state.cellSelectedAll = [];
      state.cellSelected = [];
      state.cellSelectedDetails = [];
      state.cellSelectedMoment = [];
      state.cellSelectedNameMoment = "";
      state.cellSelectedColMoment = 5;
      state.cellSelectedRowMoment = 5;
      state.cellSelectedDesMoment = "";
      state.cellSelectedHover = [];
      state.cellSelectedEditTemporary = {};
      state.isCellSelectedEdit = false;
      state.idDetailHover = 0;
      state.dataDetailRack = null;
      state.mapInputType = "manual";
      state.coordinate = [];
      state.center = [3.546728899138594, 98.69014558305145];
      state.placesSearchByName = [];
      state.placesSearchByNameLoading = false;
      state.placeGetByName = null;
      state.placeGetByCoordinate = null;
      state.placeGetByCoordinateLoading = false;
      state.address = "";
      state.adressFix = "";
      state.coordinateFix = [];
      state.status = false;
      state.errorEmpty = "";
      state.errorMessageVideo = "";
    },
  },
});

export const {
  changeLoading,
  changeSubsidiaryId,
  changeName,
  inputPhoto,
  changeIsUploadImageHub,
  deletePhoto,
  changeIsMaxSizePhoto,
  changePhotoPreview,
  clickIsPreviewPhoto,
  inputVideo,
  changeIsUploadVideoHub,
  deleteVideo,
  changeIsMaxSizeVideo,
  changeVideoPreview,
  clickIsPreviewVideo,
  changePicChoose,
  changePicSearch,
  changePicSearchDebounce,
  changePicNumber,
  changeCreateMapPage,
  changeIsCreateMap,
  changeColCreateMap,
  changeRowCreateMap,
  setUpCell,
  addColCreateMapRight,
  reduceColCreateMapRight,
  addColCreateMapLeft,
  reduceColCreateMapLeft,
  addRowCreateMapTop,
  reduceRowCreateMapTop,
  addRowCreateMapBottom,
  reduceRowCreateMapBottom,
  changeIsEntrance,
  addEntrance,
  addCellSelectedMoment,
  cancleSelectedMoment,
  addCellSelectedMomentHold,
  changeNameCellSelectedMoment,
  changeColCellSelectedMoment,
  changeRowCellSelectedMoment,
  changeDesCellSelectedMoment,
  makeCellFix,
  deleteCellFix,
  editCellFix,
  submitEditCellFix,
  cancelEditCellFix,
  hoverCellFix,
  unHoverCellFix,
  hoverRacks,
  unHoverRacks,
  seeDetailRack,
  unSeeDetailRack,
  // * -- Address map --
  changeMapType,
  changeCoordinate,
  changeCenter,
  loadPlaceSearchByName,
  loadPlaceSearchByNameLoading,
  changePlaceGetByName,
  changePlaceGetByCoordinate,
  changeIsPlaceGetByCoordinateLoading,
  changeAddress,
  submitAddresMap,
  // * -- Status --
  changeStatus,
  changeErrorEmpty,
  clearData,
  changeErrorMessageVideo,
  changeErrorMassageImage,
} = masterHubAddState.actions;

export const getMasterHubAddAllState = (state) => state.masterHubAdd;

export const getSubsidiaryMasterHubAdd = (state) =>
  state.masterHubAdd.subsidiaryId;
export const getNameMasterHubAdd = (state) => state.masterHubAdd.name;
export const getPhotoMasterHubAdd = (state) => state.masterHubAdd.photo;
export const getIsMaxSizePhotoMasterHubAdd = (state) =>
  state.masterHubAdd.isMaxSizePhoto;
export const getPhotoPreviewMasterHubAdd = (state) =>
  state.masterHubAdd.photoPreview;
export const getIsPhotoPreviewMasterHubAdd = (state) =>
  state.masterHubAdd.isPhotoPreview;

export const getVideoMasterHubAdd = (state) => state.masterHubAdd.video;
export const getIsMaxSizeVideoMasterHubAdd = (state) =>
  state.masterHubAdd.isMaxSizeVideo;
export const getVideoPreviewMasterHubAdd = (state) =>
  state.masterHubAdd.videoPreview;
export const getIsVideoPreviewMasterHubAdd = (state) =>
  state.masterHubAdd.isVideoPreview;

// * -- pic --
export const getPichChooseMasterHubAdd = (state) =>
  state.masterHubAdd.picChoose;
export const getPichSearchMasterHubAdd = (state) =>
  state.masterHubAdd.picSearch;
export const getPichSearchDebounceMasterHubAdd = (state) =>
  state.masterHubAdd.picSearchDebounce;

// * -- Number ---
export const getPicNumberMasterHubAdd = (state) => state.masterHubAdd.picNumber;

// * -- Create Map --
export const getIsCreateMap = (state) => state.masterHubAdd.isCreateMap;
export const getCreateMapPage = (state) => state.masterHubAdd.createMapPage;
export const getColCreateMap = (state) => state.masterHubAdd.col;
export const getRowCreateMap = (state) => state.masterHubAdd.row;
export const getCellCreateMap = (state) => state.masterHubAdd.cell;
export const getIsEntranceCreateMap = (state) => state.masterHubAdd.isEntrance;
export const getEntranceCreateMap = (state) => state.masterHubAdd.entrance;
export const getCellSelectedAllCreateMap = (state) =>
  state.masterHubAdd.cellSelectedAll;
export const getCellSelectedMomentCreateMap = (state) =>
  state.masterHubAdd.cellSelectedMoment;
export const getCellSelectedNameMomentCreateMap = (state) =>
  state.masterHubAdd.cellSelectedNameMoment;
export const getCellSelectedColMomentCreateMap = (state) =>
  state.masterHubAdd.cellSelectedColMoment;
export const getCellSelectedRowMomentCreateMap = (state) =>
  state.masterHubAdd.cellSelectedRowMoment;
export const getCellSelectedDesMomentCreateMap = (state) =>
  state.masterHubAdd.cellSelectedDesMoment;
export const getCellSelectedDetailsCreateMap = (state) =>
  state.masterHubAdd.cellSelectedDetails;
export const getIsCellSelectedEditCreateMap = (state) =>
  state.masterHubAdd.isCellSelectedEdit;
export const getCellSelectedHoverCreateMap = (state) =>
  state.masterHubAdd.cellSelectedHover;
export const getDetailHoverCreateMap = (state) =>
  state.masterHubAdd.idDetailHover;
export const getDetailRackCreateMap = (state) =>
  state.masterHubAdd.dataDetailRack;

// * --- Map Address
export const getMapTypeMasterHubAdd = (state) =>
  state.masterHubAdd.mapInputType;
export const getCoordinateeMasterHubAdd = (state) =>
  state.masterHubAdd.coordinate;
export const getCenterMasterHubAdd = (state) => state.masterHubAdd.center;
export const getPlacesSearchByName = (state) =>
  state.masterHubAdd.placesSearchByName;
export const getLoadingPlacesSearchByName = (state) =>
  state.masterHubAdd.placesSearchByNameLoading;
export const getPlaceGetByName = (state) => state.masterHubAdd.placeGetByName;
export const getPlaceGetByCoorninate = (state) =>
  state.masterHubAdd.placeGetByCoordinate;
export const getLaodingPlaceGetByCoorninate = (state) =>
  state.masterHubAdd.placeGetByCoordinateLoading;
export const getAddres = (state) => state.masterHubAdd.address;
export const getAddressFix = (state) => state.masterHubAdd.adressFix;
export const getCoordinateFix = (state) => state.masterHubAdd.coordinateFix;
export const getErrorEmpty = (state) => state.masterHubAdd.errorEmpty;

// * --- STATUS
export const getStatusMasterHubAdd = (state) => state.masterHubAdd.status;

// !------------------- IMAGE N VIDEO ---------------------------
export const inputImage = (e) => (dispatch) => {
  const img = e.target.files[0];
  if (img.size > 2000000) {
    dispatch(changeIsMaxSizePhoto(true));
  } else {
    dispatch(changeIsUploadImageHub(true));
    let datas = {
      image: img,
      resource: "masterHub",
    };
    uploadImage(datas).then((res) => {
      dispatch(inputPhoto(res.data.data.path));
      dispatch(changeIsMaxSizePhoto(false));
      dispatch(changeIsUploadImageHub(false));
    });
  }
};

export const deleteImage = (link) => (dispatch) => {
  deleteFile(link).then(() => {
    dispatch(deletePhoto(link));
  });
};

// export const inputTayangan = (e) => (dispatch) => {
//   const vd = e.target.files[0];
//   if (vd.size > 100000000) {
//     dispatch(changeIsMaxSizeVideo(true));
//   } else {
//     dispatch(changeIsUploadVideoHub(true));
//     let datas = {
//       video: vd,
//       resource: "masterHub",
//     };
//     uploadVideo(datas).then((res) => {
//       dispatch(inputVideo(res.data.data.path));
//       dispatch(changeIsMaxSizeVideo(false));
//       dispatch(changeIsUploadVideoHub(false));
//     });
//   }
// };

export const inputTayangan = (e) => (dispatch) => {
  const vd = e.target.files[0];
  if (vd.size > 100000000) {
    dispatch(changeIsMaxSizeVideo(true));
    dispatch(changeIsUploadVideoHub(false));
  } else {
    dispatch(changeIsUploadVideoHub(true));
    let datas = {
      video: vd,
      resource: "masterHub",
    };
    try {
      uploadVideo(datas)
        .then((res) => {
          dispatch(inputVideo(res.data.data.path));
          dispatch(changeIsMaxSizeVideo(false));
          dispatch(changeIsUploadVideoHub(false));
          dispatch(changeErrorMessageVideo(""));
        })
        .catch((error) => {
          dispatch(
            changeErrorMessageVideo("hanya format .mp4 yang di izinkan")
          );
          dispatch(changeIsUploadVideoHub(false));
        });
    } catch (err) {
      // console.error(err);
    }
  }
};

export const deleteTayangan = (link) => (dispatch) => {
  deleteFile(link).then(() => {
    dispatch(deleteVideo(link));
  });
};

export const fetchPlacesSearchByName = (name) => (dispatch) => {
  const fetchData = async () => {
    dispatch(loadPlaceSearchByNameLoading(true));
    try {
      const { data } = await getPlacesByName(name);
      dispatch(loadPlaceSearchByName(data));
    } catch (error) {
      dispatch(loadPlaceSearchByName([]));
    } finally {
      dispatch(loadPlaceSearchByNameLoading(false));
    }
  };

  fetchData();
};

export const fetchPlacesSearchByCoordinate = (coor) => (dispatch) => {
  const fetchData = async () => {
    dispatch(changeIsPlaceGetByCoordinateLoading(true));
    try {
      const { data } = await getPlacesByCoordinate(coor);
      dispatch(changePlaceGetByCoordinate(data));
    } catch (error) {
      dispatch(changePlaceGetByCoordinate([]));
    } finally {
      dispatch(changeIsPlaceGetByCoordinateLoading(false));
    }
  };

  fetchData();
};

export default masterHubAddState.reducer;
