import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import BackButtonAddFinance from './BackButtonAddFinance';
import LineDiv from '../../../../../../components/shared/LineDiv';
import CustomCard from '../../../../../../components/shared/CustomCard';
import CustomInputWithMin from '../../../../../../components/shared/CustomInputWithMin';
import BottomMenu from '../../../../../../components/shared/BottomMenu';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlaceIcon from '@mui/icons-material/Place';
import {
  changePrice,
  changeValuePruchaseAdd,
  getAllPurchaseAddFinance,
} from '../../../../../../store/purchase/purchaseFinance/purchaseAddFinance';
import { useEffect, useState } from 'react';
import {
  AutocompleteWithDebounce,
  FillText,
  LoadingComponent,
} from '../../../../../../components/shared/shared2';
import CustomInput from '../../../../../../components/shared/CustomInput';
import {
  getRecomendationSupplier,
  getSupplierForAutocomplete,
} from '../../../../../../api/Supplier';
import { AddIcon, Dolar } from '../../../../../../assets/Icons';
import { getPaymentMethodForAutocomplete } from '../../../../../../api/PaymentMethod';
import AddSuppllier from './AddSuppllier';
import {
  rejectPurchaseRequest,
  updatePurchaseRequest,
} from '../../../../../../api/purchase';
import {
  changePage,
  setNotifPurchase,
} from '../../../../../../store/Routing/purchaseRouting';

const PurchaseAddFinance = () => {
  const dispatch = useDispatch();
  const {
    products,
    deskripsi: description,
    supplierChoose,
    paymentMethodChoose,
    idRequest,
  } = useSelector(getAllPurchaseAddFinance);

  const [supplierInputvalue, setSupplierInputValue] = useState('');
  const [supplierInputvalueFix, setSupplierInputValueFix] = useState('');
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [supplierLoading, setSupplierLoading] = useState(false);
  const [supplierFix, setSupplierFix] = useState(null);
  const [supplierChoosen, setSupplierChoosen] = useState(null);
  const [notifPrice, setNotifPrice] = useState('');

  const fetchSupplier = async (idRequest) => {
    setSupplierLoading(true);
    try {
      const {
        data: { data },
      } = await getRecomendationSupplier(idRequest);
      const collect = data.map((list, idx) => {
        return {
          ...list,
          index: idx,
        };
      });
      setSupplierOptions(collect);
    } catch (error) {
      console.error(error);
    } finally {
      setSupplierLoading(false);
    }
  };

  useEffect(() => {
    fetchSupplier(idRequest);
  }, [idRequest]);

  const [paymentMethodInputvalue, setPaymentMethodInputValue] = useState('');
  const [paymentMethodInputvalueFix, setPaymentMethodInputValueFix] =
    useState('');
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
  const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);

  const fetchPaymentMethod = async (search) => {
    setPaymentMethodLoading(true);
    try {
      const {
        data: {
          data: { paymentMethods },
        },
      } = await getPaymentMethodForAutocomplete(search);

      setPaymentMethodOptions(paymentMethods);
    } catch (error) {
      console.error(error);
    } finally {
      setPaymentMethodLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentMethod(paymentMethodInputvalueFix);
  }, [paymentMethodInputvalueFix]);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const totalss = products.reduce((accum, next) => {
      return accum + Number(next.purchasePrice) * Number(next.qty);
    }, 0);

    setTotalPrice(totalss);
  }, [products]);

  const fetchUpdate = async (id, data) => {
    setIsLoading(true);
    try {
      await updatePurchaseRequest(id, data);
      dispatch(
        setNotifPurchase({
          key: 'addPurchasePending',
          text: 'purchase has been success added',
        })
      );
      dispatch(changePage(''));
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchReject = async (id) => {
    setIsLoading(true);
    try {
      await rejectPurchaseRequest(id);
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    let collect = {
      status: 'finance bid',
      idSuppliers: supplierFix.kustomer.idKustomer,
      idtransaksi: paymentMethodChoose.idTransaksi,
      deskripsi: description,
    };
    collect['products'] = products.map((list) => {
      return {
        idProduk: list.idProduk,
        qty: list.qty,
        unit: list.unit,
        purchasePrice: list.purchasePrice,
        discount: 0,
        discountFormat: 'currency',
      };
    });
    fetchUpdate(idRequest, collect);
  };

  const [isAddSupplier, setIsAddSupplier] = useState(false);

  const handleAddSupplier = () => {
    setIsAddSupplier(true);
  };

  const handleCloseAddSupplier = () => {
    setIsAddSupplier(false);
  };

  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);

  useEffect(() => {
    if (supplierFix && paymentMethodChoose) {
      const collect = products.map((list) => {
        return list.purchasePrice;
      });
      if (collect.includes(0) || collect.includes('')) {
        return setIsSubmitAvailable(false);
      } else {
        return setIsSubmitAvailable(true);
      }
    } else {
      return setIsSubmitAvailable(false);
    }
  }, [supplierFix, paymentMethodChoose, products]);

  const desktopMode = useMediaQuery('(min-width:832px)');

  useEffect(() => {
    supplierChoosen !== null && setNotifPrice('');
  }, [supplierChoosen]);

  return (
    <Box sx={{ mt: '16px' }}>
      {!isLoading && !isError && (
        <>
          <BackButtonAddFinance />
          {desktopMode && (
            <>
              <Grid
                container
                columns={2}
                spacing={4}
                sx={{ mt: '16px', pb: '54px' }}
              >
                <Grid item xs={1}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                      mb: '12px',
                    }}
                  >
                    Supplier*
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={supplierFix}
                      onChange={(e) => {
                        setSupplierChoosen(e.target.value.kustomer.namaLengkap);
                        setSupplierFix(e.target.value);
                        products?.map((item, idx) =>
                          dispatch(
                            changePrice({
                              index: idx,
                              value:
                                e.target.value.product[idx].priceRecomendation,
                            })
                          )
                        );
                      }}
                    >
                      {supplierLoading ? (
                        <Box sx={{ textAlign: 'center', padding: '8px' }}>
                          <CircularProgress size={18} />
                        </Box>
                      ) : (
                        supplierOptions?.map((item, index) => {
                          return (
                            <MenuItem value={item}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'start',
                                  alignItems: 'center',
                                  gap: 2,
                                  width: '100%',
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: '14px', fontWeight: 600 }}
                                  >
                                    {item?.kustomer?.namaLengkap}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      gap: 2,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexFlow: 'column',
                                        gap: 1,
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: 1,
                                        }}
                                      >
                                        <CheckCircleIcon
                                          sx={{
                                            color: '#51B15C',
                                            width: '12px',
                                            height: '12px',
                                          }}
                                        />
                                        <Typography
                                          sx={{
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            color: '#000000',
                                          }}
                                        >
                                          {item?.productAvailabel} products
                                          available
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: 1,
                                        }}
                                      >
                                        <Dolar />
                                        <Typography
                                          sx={{
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            color: '#000000',
                                          }}
                                        >
                                          Rp {item?.priceAvailabel}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </MenuItem>
                          );
                        })
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                      mb: '12px',
                    }}
                  >
                    Payment Method*
                  </Typography>
                  <AutocompleteWithDebounce
                    inputValue={paymentMethodInputvalue}
                    onInputChange={(value) => setPaymentMethodInputValue(value)}
                    debounceInputChange={(value) =>
                      setPaymentMethodInputValueFix(value)
                    }
                    value={paymentMethodChoose}
                    onChange={(_, newValue) => {
                      dispatch(
                        changeValuePruchaseAdd({
                          key: 'paymentMethodChoose',
                          value: newValue,
                        })
                      );
                    }}
                    options={paymentMethodOptions}
                    loading={paymentMethodLoading}
                    getOptionLabel={(option) => option.transaksi}
                    renderInput={(params) => (
                      <CustomInput
                        {...params}
                        placeholder="Select payment method"
                        sx={{ width: '100%', bgcolor: '#FAFAFA' }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                      mb: '12px',
                    }}
                  >
                    Description
                  </Typography>
                  <LineDiv value={description || '-'} />
                </Grid>
                <Grid item xs={2}>
                  <CustomCard>
                    <CustomCard sx={{ p: '20px' }}>
                      <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
                        Item(s)
                      </Typography>
                    </CustomCard>
                    <Grid
                      container
                      columns={1}
                      rowSpacing={4}
                      sx={{ p: '20px' }}
                    >
                      {products.map((product, idx) => {
                        return (
                          <Grid item xs={1} key={idx}>
                            <Grid container columns={5} columnSpacing={4}>
                              <Grid item xs={2}>
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    mb: '12px',
                                  }}
                                >
                                  Product
                                </Typography>
                                <LineDiv value={product?.name || '-'} />
                              </Grid>
                              <Grid item xs={1}>
                                <Box
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    mb: '12px',
                                    display: 'flex',
                                    gap: 0.5,
                                  }}
                                >
                                  <Typography>Qty </Typography>
                                  <Typography
                                    sx={{ color: '#51B15C' }}
                                  ></Typography>
                                </Box>
                                <LineDiv
                                  value={product?.qty || '-'}
                                  unit={product?.unit}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    mb: '12px',
                                  }}
                                >
                                  Price*
                                </Typography>
                                <CustomInputWithMin
                                  disabled={
                                    supplierChoosen === null ? true : false
                                  }
                                  isMinSeen={false}
                                  placeholder="Enter Qty"
                                  isWithTitle={false}
                                  type="number"
                                  value={product?.purchasePrice}
                                  onChange={(e) =>
                                    dispatch(
                                      changePrice({
                                        index: idx,
                                        value: e.target.value,
                                      })
                                    )
                                  }
                                />
                              </Grid>

                              <Grid item xs={1}>
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    mb: '12px',
                                  }}
                                >
                                  Subtotal*
                                </Typography>
                                <LineDiv
                                  value={`Rp. ${
                                    product?.purchasePrice * product?.qty
                                  }`}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: '20px',
                      }}
                    >
                      <Typography sx={{ fontWeight: '600', fontSize: '20px' }}>
                        Grand Total
                      </Typography>
                      <Typography sx={{ fontWeight: '600', fontSize: '20px' }}>
                        Rp. {totalPrice}
                      </Typography>
                    </Box>
                  </CustomCard>
                </Grid>
              </Grid>
            </>
          )}
          {!desktopMode && (
            <Box sx={{ minHeight: '100vh', bgcolor: 'white' }}>
              <Grid
                container
                columns={1}
                spacing={2}
                sx={{ mt: '8px', pb: '54px' }}
              >
                <Grid item xs={1}>
                  <AutocompleteWithDebounce
                    inputValue={supplierInputvalue}
                    onInputChange={(value) => setSupplierInputValue(value)}
                    debounceInputChange={(value) =>
                      setSupplierInputValueFix(value)
                    }
                    value={supplierChoose}
                    onChange={(_, newValue) => {
                      dispatch(
                        changeValuePruchaseAdd({
                          key: 'supplierChoose',
                          value: newValue,
                        })
                      );
                    }}
                    options={supplierOptions}
                    loading={supplierLoading}
                    getOptionLabel={(option) => option.namaLengkap}
                    renderOption={(props, option) => {
                      return (
                        <Box>
                          {option.index === 0 && (
                            <Box
                              sx={{
                                color: '#51B15C',
                                display: 'flex',
                                gap: 0.5,
                                alignItems: 'center',
                                pl: '8px',
                                py: '4px',
                                cursor: 'pointer',
                                '&:hover': {
                                  bgcolor: '#FAFAFA',
                                },
                              }}
                              onClick={handleAddSupplier}
                            >
                              <AddIcon />
                              <Typography>Add New Supplier</Typography>
                            </Box>
                          )}
                          <li {...props}>{option.namaLengkap}</li>
                        </Box>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        id="filled-basic"
                        label="Supplier*"
                        variant="filled"
                        sx={{
                          '.MuiFilledInput-root': {
                            bgcolor: '#FAFAFA',
                            height: '54px',
                          },
                          '.MuiInputLabel-root': {
                            color: '#9E9E9E',
                          },
                        }}
                        {...params}
                      />
                    )}
                    noOptionsText={
                      <Box>
                        <Box
                          sx={{
                            color: '#51B15C',
                            display: 'flex',
                            gap: 0.5,
                            alignItems: 'center',
                            py: '4px',
                            cursor: 'pointer',
                            '&:hover': {
                              bgcolor: '#FAFAFA',
                            },
                          }}
                          onClick={handleAddSupplier}
                        >
                          <AddIcon />
                          <Typography>Add New Supplier</Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>No Option</Box>
                      </Box>
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <AutocompleteWithDebounce
                    inputValue={paymentMethodInputvalue}
                    onInputChange={(value) => setPaymentMethodInputValue(value)}
                    debounceInputChange={(value) =>
                      setPaymentMethodInputValueFix(value)
                    }
                    value={paymentMethodChoose}
                    onChange={(_, newValue) => {
                      dispatch(
                        changeValuePruchaseAdd({
                          key: 'paymentMethodChoose',
                          value: newValue,
                        })
                      );
                    }}
                    options={paymentMethodOptions}
                    loading={paymentMethodLoading}
                    getOptionLabel={(option) => option.transaksi}
                    renderInput={(params) => (
                      <TextField
                        id="filled-basic"
                        label="Payment Method*"
                        variant="filled"
                        sx={{
                          '.MuiFilledInput-root': {
                            bgcolor: '#FAFAFA',
                            height: '54px',
                          },
                          '.MuiInputLabel-root': {
                            color: '#9E9E9E',
                          },
                        }}
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1}>
                  <FillText title={'Description*'} value={description || '-'} />
                </Grid>
                <Grid item xs={1}>
                  <CustomCard>
                    <CustomCard sx={{ p: '16px' }}>
                      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
                        Item(s)
                      </Typography>
                    </CustomCard>
                    <Grid
                      container
                      columns={1}
                      rowSpacing={2}
                      sx={{ p: '20px 16px' }}
                    >
                      {products.map((product, idx) => {
                        return (
                          <Grid item xs={1} key={idx}>
                            <Grid
                              container
                              columns={6}
                              columnSpacing={2}
                              rowSpacing={2}
                            >
                              <Grid item xs={4}>
                                <FillText
                                  title={'Product'}
                                  value={product?.name || '-'}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <FillText
                                  title={'Qty'}
                                  value={product?.qty || '-'}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField
                                  id="filled-basic"
                                  label="Price*"
                                  variant="filled"
                                  sx={{
                                    '.MuiFilledInput-root': {
                                      bgcolor: '#FAFAFA',
                                      height: '54px',
                                    },
                                    '.MuiInputLabel-root': {
                                      color: '#9E9E9E',
                                    },
                                  }}
                                  type="number"
                                  value={product?.purchasePrice || ''}
                                  onChange={(e) =>
                                    dispatch(
                                      changePrice({
                                        index: idx,
                                        value: e.target.value,
                                      })
                                    )
                                  }
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <FillText
                                  title={
                                    <Box sx={{ color: '#51B15C' }}>
                                      Subtotal
                                    </Box>
                                  }
                                  value={`Rp. ${
                                    product?.purchasePrice * product?.qty
                                  }`}
                                />
                              </Grid>
                            </Grid>
                            <Divider sx={{ mt: '16px' }} />
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: '20px',
                      }}
                    >
                      <Typography sx={{ fontWeight: '600', fontSize: '16px' }}>
                        Grand Total
                      </Typography>
                      <Typography sx={{ fontWeight: '600', fontSize: '16px' }}>
                        Rp. {totalPrice}
                      </Typography>
                    </Box>
                  </CustomCard>
                </Grid>
              </Grid>
            </Box>
          )}
          <BottomMenu
            isGreen={true}
            greenText="Submit"
            handleGreen={handleSubmit}
            disableGreen={!isSubmitAvailable}
          />
          <AddSuppllier open={isAddSupplier} onClose={handleCloseAddSupplier} />
        </>
      )}
      {isLoading && <LoadingComponent />}
    </Box>
  );
};
export default PurchaseAddFinance;
