import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllPurchaseRouting } from '../../../../../../store/Routing/purchaseRouting';
import { useEffect } from 'react';

const TextNetworkProcess = ({ children }) => {
  return <Typography sx={{ fontSize: '12px' }}>{children}</Typography>;
};

const NetworkProcess = () => {
  const { statusPurchase } = useSelector(getAllPurchaseRouting);

  const lengthStick = 30;
  const [statusCount, setStatusCount] = useState(0);

  useEffect(() => {
    switch (statusPurchase) {
      case 'processed':
        setStatusCount(2);
        break;
      case 'sent':
        setStatusCount(3);
        break;
      default:
        setStatusCount(0);
    }
  }, []);

  return (
    <Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        {/* //* Paid */}
        <Box
          sx={{
            width: `${lengthStick * 2 + 12}px`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextNetworkProcess>Paid</TextNetworkProcess>
        </Box>
        {/* //* Processed */}
        <Box
          sx={{
            width: `${lengthStick * 2 + 12}px`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextNetworkProcess>Processed</TextNetworkProcess>
        </Box>
        {/* //* Sent */}
        <Box
          sx={{
            width: `${lengthStick * 2 + 12}px`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextNetworkProcess>Sent</TextNetworkProcess>
        </Box>
        {/* //* Accepted */}
        <Box
          sx={{
            width: `${lengthStick * 2 + 12}px`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextNetworkProcess>Accepted</TextNetworkProcess>
        </Box>
        {/* //* Finish */}
        <Box
          sx={{
            width: `${lengthStick * 2 + 12}px`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextNetworkProcess>Finish</TextNetworkProcess>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: '12px',
        }}
      >
        {/* //* Paid */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ width: `${lengthStick}px`, height: '2px' }}></Box>
          <Box
            sx={{
              width: '12px',
              height: '12px',
              bgcolor: '#51B15C',
              borderRadius: '50%',
            }}
          ></Box>
        </Box>
        {/* //* Processed */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {statusCount > 1 ? (
            <>
              <Box
                sx={{
                  width: `${lengthStick}px`,
                  height: '2px',
                  bgcolor: '#51B15C',
                }}
              ></Box>
              <Box
                sx={{
                  width: `${lengthStick}px`,
                  height: '2px',
                  bgcolor: '#51B15C',
                }}
              ></Box>
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  bgcolor: '#51B15C',
                  borderRadius: '50%',
                }}
              ></Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  width: `${lengthStick}px`,
                  height: '2px',
                  bgcolor: '#C8C8CC',
                }}
              ></Box>
              <Box
                sx={{
                  width: `${lengthStick}px`,
                  height: '2px',
                  bgcolor: '#C8C8CC',
                }}
              ></Box>
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  bgcolor: '#C8C8CC',
                  borderRadius: '50%',
                }}
              ></Box>
            </>
          )}
        </Box>
        {/* //* Sent */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {statusCount > 2 ? (
            <>
              <Box
                sx={{
                  width: `${lengthStick}px`,
                  height: '2px',
                  bgcolor: '#51B15C',
                }}
              ></Box>
              <Box
                sx={{
                  width: `${lengthStick}px`,
                  height: '2px',
                  bgcolor: '#51B15C',
                }}
              ></Box>
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  bgcolor: '#51B15C',
                  borderRadius: '50%',
                }}
              ></Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  width: `${lengthStick}px`,
                  height: '2px',
                  bgcolor: '#C8C8CC',
                }}
              ></Box>
              <Box
                sx={{
                  width: `${lengthStick}px`,
                  height: '2px',
                  bgcolor: '#C8C8CC',
                }}
              ></Box>
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  bgcolor: '#C8C8CC',
                  borderRadius: '50%',
                }}
              ></Box>
            </>
          )}
        </Box>
        {/* //* Accepted */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {statusCount > 3 ? (
            <>
              <Box
                sx={{
                  width: `${lengthStick}px`,
                  height: '2px',
                  bgcolor: '#51B15C',
                }}
              ></Box>
              <Box
                sx={{
                  width: `${lengthStick}px`,
                  height: '2px',
                  bgcolor: '#51B15C',
                }}
              ></Box>
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  bgcolor: '#51B15C',
                  borderRadius: '50%',
                }}
              ></Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  width: `${lengthStick}px`,
                  height: '2px',
                  bgcolor: '#C8C8CC',
                }}
              ></Box>
              <Box
                sx={{
                  width: `${lengthStick}px`,
                  height: '2px',
                  bgcolor: '#C8C8CC',
                }}
              ></Box>
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  bgcolor: '#C8C8CC',
                  borderRadius: '50%',
                }}
              ></Box>
            </>
          )}
        </Box>
        {/* //* Finish */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {statusCount > 4 ? (
            <>
              <Box
                sx={{
                  width: `${lengthStick}px`,
                  height: '2px',
                  bgcolor: '#51B15C',
                }}
              ></Box>
              <Box
                sx={{
                  width: `${lengthStick}px`,
                  height: '2px',
                  bgcolor: '#51B15C',
                }}
              ></Box>
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  bgcolor: '#51B15C',
                  borderRadius: '50%',
                }}
              ></Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  width: `${lengthStick}px`,
                  height: '2px',
                  bgcolor: '#C8C8CC',
                }}
              ></Box>
              <Box
                sx={{
                  width: `${lengthStick}px`,
                  height: '2px',
                  bgcolor: '#C8C8CC',
                }}
              ></Box>
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  bgcolor: '#C8C8CC',
                  borderRadius: '50%',
                }}
              ></Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default NetworkProcess;
