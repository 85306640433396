import { createSlice } from "@reduxjs/toolkit";

export const adjusmentRoutingState = createSlice({
  name: "adjustmentRouting",
  initialState: {
    page: "",
    keyNotif: "",
    textNotif: "",
  },
  reducers: {
    changePageAdjustment: (state, action) => {
      state.page = action.payload;
    },
    setNotifAdjusment: (state, action) => {
      const { key, text } = action.payload;
      state.keyNotif = key;
      state.textNotif = text;
    },
    setCloseNotifAdjustment: (state, action) => {
      state.keyNotif = "";
      state.textNotif = "";
    },
  }, 
});

export const {
  changePageAdjustment,
  setNotifAdjusment,
  setCloseNotifAdjustment,
} = adjusmentRoutingState.actions;

export const getAllAdjustmentRouting = (state) => state.adjustmentRouting;

export default adjusmentRoutingState.reducer;
