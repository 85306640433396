import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { Box, Typography } from '@mui/material';
import CategoryProduct from './CategoryProduct/CategoryProduct';
import SubCategoryProduct from './SubCategoryProduct/SubCategoryProduct';
import ProductProduct from './ProductProduct/ProductProduct';
import CategoryProductAdd from './CategoryProductAdd/CategoryProductAdd';
import CategoryProductEdit from './CategoryProductEdit/CategoryProductEdit';
import CategoryProductDetails from './CategoryProductDetails/CategoryProductDetails';
import ProductProductAdd from './ProductProductAdd/ProductProductAdd';
import ProductProductEdit from './ProductProductEdit/ProductProductEdit';
import SubCategoryProductAdd from './SubCategoryProductAdd/SubCategoryProductAdd';
import SubCategoryProductEdit from './SubCategoryProductEdit/SubCategoryProductEdit';
import dayjs from 'dayjs';

import {
  fetchCategories,
  fetchSubCategories,
  fetchSubsidiary,
  fetchProducts,
  fetchUnits,
  fetchCategoryDetails,
  fetchProductDetails,
  allCategoryProductName,
  allSubCategoryProductName,
  allMiniSubCategoryProductName,
  fetchSubCategoryDetails,
  fetchMiniSubCategoryDetails,
  allTypeCategoryProduct,
} from '../../../../../api/products';
import ProductProductDetails from './ProductProductDetails/ProductProductDetails';
import SubCategoryProductDetails from './SubCategoryProductDetails/SubCategoryProductDetails';
import MiniSubProductDetails from './MiniSubProductDetails/MiniSubProductDetails';
import MiniSubProductAdd from './MiniSubProductAdd/MiniSubProductAdd';
import MiniSubProductEdit from './MiniSubProductEdit/MiniSubProductEdit';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSubCategoryProduct,
  getAllSubCategoryProduct,
} from '../../../../../store/Product/subCategoryProduct';

// *? -----------------------------  Dummy Data --------------------------
const dummyDataTable = [
  {
    category: 'Category 1',
    subCategory: 'subCategory 1',
    mini: 'mini subcategory 1',
    product: 'product 1',
    photo: 'imContoh',
    subsidiary: 'lulu',
    type: 'kosmetik',
    cogs: 3000,
    rrp: 500,
    sku: 600,
    schedule: '12/12/12',
    limit: 'limit',
    unit: '3 pcs',
    color: 'brown',
  },
  {
    category: 'Category 2',
    subCategory: 'subCategory 2',
    mini: 'mini subcategory 2',
    product: 'product 2',
    photo: 'imContoh',
    subsidiary: 'lulu',
    type: 'kosmetik',
    cogs: 3000,
    rrp: 500,
    sku: 600,
    schedule: '12/12/12',
    limit: 'limit',
    unit: '3 pcs',
    color: 'brown',
  },
  {
    category: 'Category 3',
    subCategory: 'subCategory 3',
    mini: 'mini subcategory 3',
    product: 'product 3',
    photo: 'imContoh',
    subsidiary: 'lulu',
    type: 'kosmetik',
    cogs: 3000,
    rrp: 500,
    sku: 600,
    schedule: '12/12/12',
    limit: 'limit',
    unit: '3 pcs',
    color: 'brown',
  },
  {
    category: 'Category 4',
    subCategory: 'subCategory 4',
    mini: 'mini subcategory 4',
    product: 'product 4',
    photo: 'imContoh',
    subsidiary: 'lulu',
    type: 'kosmetik',
    cogs: 3000,
    rrp: 500,
    sku: 600,
    schedule: '12/12/12',
    limit: 'limit',
    unit: '3 pcs',
    color: 'brown',
  },
  {
    category: 'Category 5',
    subCategory: 'subCategory 5',
    mini: 'mini subcategory 5',
    product: 'product 5',
    photo: 'imContoh',
    subsidiary: 'lulu',
    type: 'kosmetik',
    cogs: 3000,
    rrp: 500,
    sku: 600,
    schedule: '12/12/12',
    limit: 'limit',
    unit: '3 pcs',
    color: 'brown',
  },
];
const InventoryProduct = () => {
  const today = dayjs(new Date());

  // *! -----------------------------  Navigasi --------------------------

  const [page, setPage] = useState('category');
  const [pageCheck, setPageCheck] = useState('category');

  const [statusError, setStatusError] = useState(0);

  const [isCategoryProductAdd, setIsCategoryProductAdd] = useState(false);
  const [isCategoryProductEdit, setIsCategoryProductEdit] = useState(false);
  const [isCategoryProductDetails, setIsCategoryProductDetails] =
    useState(false);

  const [isSubCategoryProductAdd, setIsSubCategoryProductAdd] = useState(false);
  const [isSubCategoryProductEdit, setIsSubCategoryProductEdit] =
    useState(false);
  const [isSubCategoryProductDetails, setIsSubCategoryProductDetails] =
    useState(false);

  const [isProductProductAdd, setIsProductProductAdd] = useState(false);
  const [isProductProductEdit, setIsProductProductEdit] = useState(false);
  const [isProductProductDetails, setIsProductProductDetails] = useState(false);

  const [isMiniSubProductDetails, setIsMiniSubProductDetails] = useState(false);
  const [isMiniSubProductAdd, setIsMiniSubProductAdd] = useState(false);
  const [isMiniSubProductEdit, setIsMiniSubProductEdit] = useState(false);

  const [roadHirarki, setRoadHirarki] = useState({
    subsidiary: '',
    subsidiaryId: 0,
    category: '',
    categoryId: 0,
    subCategory: '',
    subCategoryId: 0,
    product: '',
    productId: 0,
    type: '',
    typeId: 0,
    miniSubCategory: '',
    miniSubCategoryId: 0,
  });

  const dispatch = useDispatch();
  const { update: subCategoryUpdate } = useSelector(getAllSubCategoryProduct);

  // *! -------------------------- Responsive -----------------------------
  // *! -------------------------------------------------------------------

  const [desktopMode, setDesktopMode] = useState(true);

  useEffect(() => {
    if (window.screen.width <= 830) {
      setDesktopMode(false);
    } else {
      setDesktopMode(true);
    }
  }, []);

  // *! -------------------------- NOTIFF -----------------------------
  // *! ---------------------------------------------------------------

  const [notif, setNotif] = useState('');
  const [notifTitle, setNotifTitle] = useState('');

  // *! ----------------------------- Chart -----------------------------
  // *! ------------------------------------------------------------------

  const [timeFrame, setTimeFrame] = useState('daily');

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  // *! ----------------------------- Table ------------------------------
  // *! ------------------------------------------------------------------

  const tableRowPerPageValues = [5, 10, 15, 20, 25];
  const [tableRowPerPage, setTableRowPerPage] = useState(5);
  const [tableCurrentPage, setTableCurrentPage] = useState(1);
  const [tableTotalItems, setTableTotalItems] = useState(0);
  const [tableSearch, setTableSearch] = useState('');
  const [tableDatas, setTableDatas] = useState([]);

  const [sortTable, setSortTable] = useState('name');
  const [isSortAsc, setIsSortAsc] = useState(true);

  // *! ----------------------------- 1. Category ------------------------
  // *! ------------------------------------------------------------------

  const [tableCategoryFilterDatas, setTableCategoryFilterDatas] = useState({
    typeDatas: [],
    subsidiaryDatas: ['lulu', 'lunoar'],
  });

  const [isHasMorePage, setIsHasMorePage] = useState(false);

  const [filterCategorySubsidiary, setFilterCategorySubsidiary] = useState([]);
  const [filterCategoryType, setFilterCategoryType] = useState([]);

  const [categoryAddInputDatas, setCategoryAddInputDatas] = useState({
    name: '',
    subsidiary: { id: 0, name: '' },
    subsidiaryId: null,
    type: { iDopsi: 0, nmOpsi: '' },
    typeInput: '',
    typeId: 0,
    typeName: '',
    typeLoading: false,
    photo: [],
    photoUrls: [],
    gambar: [],
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    subCategoryIndex: [],
    subCategoryFormat: [1, 2, 3],
    publish: true,
  });

  const [categoryEditInputDatas, setCategoryEditInputDatas] = useState({
    name: '',
    subsidiary: '',
    subsidiaryId: 0,
    categoryid: 0,
    type: { iDopsi: 0, nmOpsi: '' },
    typeInput: '',
    typeId: 0,
    typeName: '',
    typeLoading: false,
    photo: [],
    photoUrls: [],
    gambar: [],
    linkPreviewPhoto: '',
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    subCategoryIndex: [],
    subCategoryFormat: [1, 2, 3],
    publish: true,
  });

  // *! ----------------------------- 2. Sub Category --------------------
  // *! ------------------------------------------------------------------

  const [subCategoryAddInputDatas, setSubCategoryAddInputDatas] = useState({
    name: '',
    photo: [],
    photoUrls: [],
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    miniSubCategoryIndex: [],
    miniSubCategoryFormat: [1, 2, 3],
    publish: true,
  });

  const [subCategoryEditInputDatas, setSubCategoryEditInputDatas] = useState({
    name: '',
    photo: [],
    photoUrls: [],
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    linkPreviewPhoto: '',
    description: '',
    scheduleType: 'now',
    schedule: today,
    miniSubCategoryIndex: [],
    miniSubCategoryFormat: [1, 2, 3],
    publish: true,
  });

  // *! ----------------------------- 3. Product -------------------------
  // *! ------------------------------------------------------------------

  const [unitDatas, setUnitDatas] = useState([]);
  const [unitLoading, setUnitLoading] = useState(false);

  const [filterDataAwait, setFilterDataAwait] = useState({
    categoryId: [],
    subCategoryId: [],
    miniSubCategoryId: [],
  });

  const [productAddInputDatas, setProductAddInputDatas] = useState({
    name: '',
    photo: [],
    photoUrls: [],
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    isCategoryOpen: false,
    category: '',
    subCategory: '',
    miniSubCategory: '',
    categoryId: 0,
    subCategoryId: 0,
    miniSubCategoryId: 0,
    categoryInput: '',
    subCategoryInput: '',
    miniSubCategoryInput: '',
    isUnitOpen: false,
    unitLevelNow: 0,
    unitValue1: '',
    unitValue1Id: 0,
    unitValue1name: '',
    unitValue1level: 0,
    unitValue2: '',
    unitValue2Id: 0,
    unitValue2name: '',
    unitValue2level: 0,
    unitValue3: '',
    unitValue3Id: 0,
    unitValue3name: '',
    unitValue3level: 0,
    unitValue4: '',
    unitValue4Id: 0,
    unitValue4name: '',
    unitValue4level: 0,
    unitValueSubmited: [],
    isVariantOpen: false,
    minStock: '',
    minStockUnitId: 0,
    minStockValue: '',
    maxStock: '',
    maxStockUnitId: 0,
    maxStockValue: '',
    isLimitStockOpen: false,
    isCogsOpen: false,
    isLimitOrderOpen: false,
    color: '',
    size: 0,
    metaDescription: '',
    scheduleType: 'now',
    schedule: today,
    description: '',
    publish: true,
  });

  const [productEditInputDatas, setProductEditInputDatas] = useState({
    name: '',
    photo: [],
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    linkPreviePhoto: '',
    isCategoryOpen: false,
    category: '',
    subCategory: '',
    miniSubCategory: '',
    categoryId: 0,
    subCategoryId: 0,
    miniSubCategoryId: 0,
    categoryInput: '',
    subCategoryInput: '',
    miniSubCategoryInput: '',
    isUnitOpen: false,
    unitLevelNow: 0,
    unitValue1: '',
    unitValue1Id: 0,
    unitValue1name: '',
    unitValue1level: 0,
    unitValue2: '',
    unitValue2Id: 0,
    unitValue2name: '',
    unitValue2level: 0,
    unitValue3: '',
    unitValue3Id: 0,
    unitValue3name: '',
    unitValue3level: 0,
    unitValue4: '',
    unitValue4Id: 0,
    unitValue4name: '',
    unitValue4level: 0,
    unitValueSubmited: [],
    isVariantOpen: false,
    minStock: '',
    minStockUnitId: 0,
    minStockValue: '',
    maxStock: '',
    maxStockUnitId: 0,
    maxStockValue: '',
    isLimitStockOpen: false,
    color: '',
    size: 0,
    isCogsOpen: false,
    metaDescription: '',
    scheduleType: 'now',
    schedule: today,
    description: '',
    publish: true,
  });

  // ?-------------- cat sub mini chosed ----------------
  const [catSubMiniProduct, setCatSubMiniProduct] = useState({
    categoryOpen: false,
    subCategoryOpen: false,
    miniSubCategoryOpen: false,
    categoryLoading: false,
    subCategoryLoading: false,
    miniSubCategoryLoading: false,
  });

  // ?-------------- Pemilihan Kategori pada produk ---------------
  const [categoryDataAwait, setCategoryDataAwait] = useState({
    category: '',
    subCategory: '',
    miniSubCategory: '',
    categoryId: '',
    subCategoryId: '',
    miniSubCategoryId: '',
    categoryChoose: null,
    subCategoryChoose: null,
    miniSubCategoryChoose: null,
  });

  const [categoryDataAwaitEdit, setCategoryDataAwaitEdit] = useState({
    category: '',
    subCategory: '',
    miniSubCategory: '',
    categoryId: '',
    subCategoryId: '',
    miniSubCategoryId: '',
    categoryChoose: null,
    subCategoryChoose: null,
    miniSubCategoryChoose: null,
  });

  // ?-------------- Pemilihan unit pada produk ---------------
  const [unitDataAwait, setUnitDataAwait] = useState({
    unitLevelNow: 0,
    unitValue1: '',
    unitValue1Id: 0,
    unitValue1name: '',
    unitValue1level: 0,
    qrCodeStatus1: 0,
    unit1Open: false,
    unitValue2: '',
    unitValue2Id: 0,
    unitValue2name: '',
    unitValue2level: 0,
    qrCodeStatus2: 0,
    uni21Open: false,
    unitValue3: '',
    unitValue3Id: 0,
    unitValue3name: '',
    unitValue3level: 0,
    qrCodeStatus3: 0,
    unit3Open: false,
    unitValue4: '',
    unitValue4Id: 0,
    unitValue4name: '',
    unitValue4level: 0,
    qrCodeStatus4: 0,
    unit4Open: false,
    unitValueSubmited: [],
    unitValueSubmitedCheck: false,
  });

  const [unitDataAwaitEdit, setUnitDataAwaitEdit] = useState({
    unitLevelNow: 0,
    unitValue1: '',
    unitValue1Id: 0,
    unitValue1name: '',
    unitValue1level: 0,
    unitValue2: '',
    unitValue2Id: 0,
    unitValue2name: '',
    unitValue2level: 0,
    unitValue3: '',
    unitValue3Id: 0,
    unitValue3name: '',
    unitValue3level: 0,
    unitValue4: '',
    unitValue4Id: 0,
    unitValue4name: '',
    unitValue4level: 0,
    unitValueSubmited: [],
    unitValueSubmitedCheck: false,
  });

  // ?-------------- Pemilihan variant pada produk ---------------
  const [variatnDataAwait, setVariantDataAwait] = useState({
    color: '',
    size: '',
  });

  const [variatnDataAwaitEdit, setVariantDataAwaitEdit] = useState({
    color: '',
    size: '',
  });

  // ?-------------- Pemilihan stok limit pada produk ---------------
  const [stockLimitDataAwait, setStockLimitDataAwait] = useState({
    minStock: '',
    minStockValue: '',
    maxStock: '',
    maxStockValue: '',
    isCheck: false,
  });

  const [stockLimitDataAwaitEdit, setStockLimitDataAwaitEdit] = useState({
    minStock: '',
    minStockValue: '',
    maxStock: '',
    maxStockValue: '',
    isCheck: false,
  });

  // ?-------------- Pemilihan order limit pada produk ---------------
  const [orderLimitDataAwait, setOrderLimitDataAwait] = useState({
    minOrder: '',
    minOrderValue: '',
    maxOrder: '',
    maxOrderValue: '',
    isCheck: false,
  });

  //TODO : edit order limit belum dibuat

  const [categoryOption, setCategoryOption] = useState([]);
  const [subCategoryOption, setSubCategoryOption] = useState([]);
  const [miniSubCategoryOption, setMiniSubCategoryOption] = useState([]);

  const [categoryOptionLoading, setCategoryOptionLoading] = useState([]);
  const [subCategoryOptionLoading, setSubCategoryOptionLoading] = useState([]);
  const [miniSubCategoryOptionLoading, setMiniSubCategoryOptionLoading] =
    useState([]);

  useEffect(() => {
    const getMiniSubCategoriesName = async () => {
      setMiniSubCategoryOptionLoading(true);
      let datas = {};
      datas.idSubkategori = [categoryDataAwait.subCategoryId];
      if (productAddInputDatas.subCategoryInput !== '') {
        datas['search'] = productAddInputDatas.miniSubCategoryInput;
      }
      try {
        const {
          data: { data: dataNames },
        } = await allMiniSubCategoryProductName(datas);
        setMiniSubCategoryOption(dataNames);
      } catch (err) {
        console.error();
        setStatusError(err.response.request.status);
        setSubCategoryOption([]);
      } finally {
        setMiniSubCategoryOptionLoading(false);
      }
    };

    const getSubCategoriesName = async () => {
      setSubCategoryOptionLoading(true);
      let datas = {};
      datas.idKategori = [categoryDataAwait.categoryId];
      if (productAddInputDatas.subCategoryInput !== '') {
        datas['search'] = productAddInputDatas.categoryInput;
      }
      try {
        const {
          data: { data: dataNames },
        } = await allSubCategoryProductName(datas);
        setSubCategoryOption(dataNames);
      } catch (err) {
        console.error();
        setStatusError(err.response.request.status);
        setSubCategoryOption([]);
      } finally {
        setSubCategoryOptionLoading(false);
      }
    };

    const getCategoriesName = async () => {
      setCategoryOptionLoading(true);
      let datas = {
        subsidiaryId: [],
        opsiKategori: 1,
      };
      // if (productAddInputDatas.categoryInput !== '') {
      //   datas.search = productAddInputDatas.categoryInput;
      // }
      try {
        const {
          data: { data: dataNames },
        } = await allCategoryProductName(datas);
        setCategoryOption(dataNames);
      } catch (err) {
        console.error();
        setStatusError(err.response.request.status);
        setCategoryOption([]);
      } finally {
        setCategoryOptionLoading(false);
      }
    };

    if (isProductProductAdd || isProductProductEdit) {
      getCategoriesName();
      if (categoryDataAwait.categoryId !== 0) {
        getSubCategoriesName();
        if (categoryDataAwait.subCategoryId !== 0) {
          getMiniSubCategoriesName();
        }
      }
    }
  }, [
    isProductProductAdd,
    productAddInputDatas.isCategoryOpen,
    categoryDataAwait.categoryId,
    catSubMiniProduct,
    productAddInputDatas.subCategoryInput,
    productAddInputDatas.categoryInput,
    categoryDataAwait.subCategoryId,
    productAddInputDatas.miniSubCategoryInput,
    categoryDataAwait.subCategoryId,
  ]);

  // *! ----------------------------- 4. Min --------------------------
  // *! ---------------------------------------------------------------

  const [miniSubAddInputDatas, setMiniSubAddInputDatas] = useState({
    name: '',
    photo: [],
    photoUrls: [],
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    publish: true,
  });

  const [miniSubEditInputDatas, setMiniSubEditInputDatas] = useState({
    name: '',
    photo: [],
    photoUrls: [],
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    linkPreviewPhoto: '',
    description: '',
    scheduleType: 'now',
    schedule: today,
    publish: true,
  });

  // *! ----------------------------- DETAILS------------------------
  // *! -------------------------------------------------------------

  const [detailsDatas, setDetailsDatas] = useState([]);

  // *! -----------------------------  API --------------------------
  // *! -------------------------------------------------------------

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoadingDataDetails, setIsLoadingDataDetails] = useState(true);

  const [subsidiaryDatas, setSubsidiaryDatas] = useState([]);
  const [isLoadingSubsidiary, setIsLoadingSubsidiary] = useState(true);

  useEffect(() => {
    if (page !== pageCheck) {
      setTableSearch('');
      setSortTable('name');
      setIsLoadingData(true);
      setPageCheck(page);
    }
  }, [page, pageCheck]);

  useEffect(() => {
    // *-------------- All Product  -------------
    const getAllProducts = async () => {
      setIsLoadingData(true);

      let params = {};
      params.pageSize = tableRowPerPage;
      params.page = tableCurrentPage;

      // params.idKategori = filterDataAwait.categoryId;
      // params.idSubkategori = filterDataAwait.subCategoryId;
      // params.miniCategoryId = filterDataAwait.miniCategoryId;
      if (filterDataAwait.categoryId.length !== 0) {
        params['idKategori'] = filterDataAwait.categoryId;
      }
      if (filterDataAwait.subCategoryId.length !== 0) {
        params['idSubkategori'] = filterDataAwait.subCategoryId;
      }
      if (filterDataAwait.miniSubCategoryId.length !== 0) {
        params['miniCategoryId'] = filterDataAwait.miniSubCategoryId;
      }
      if (tableSearch !== '') {
        params['search'] = tableSearch;
      }
      if (sortTable !== 0) {
        params[sortTable] = isSortAsc ? 'asc' : 'desc';
      }

      try {
        const {
          data: { data: dataFetchProducts },
        } = await fetchProducts(params);

        setTableDatas(dataFetchProducts.products);
        setTableTotalItems(dataFetchProducts.pageInfo.total);
      } catch (err) {
        console.error();
        setStatusError(err.response.request.status);
        setTableDatas([]);
        setTableTotalItems(tableTotalItems);
        setIsLoadingData(true);
      } finally {
        setIsLoadingData(false);
      }
    };

    // *-------------- Product With Sub --------
    const getProducts = async () => {
      setIsLoadingData(true);

      let params = {};
      params.pageSize = tableRowPerPage;
      params.page = tableCurrentPage;

      params.idKategori = [];
      params.idSubkategori = [roadHirarki.subCategoryId];
      params.miniCategoryid = [];

      if (tableSearch !== '') {
        params['search'] = tableSearch;
      }
      if (sortTable !== 0) {
        params[sortTable] = isSortAsc ? 'asc' : 'desc';
      }

      try {
        const {
          data: { data: dataFetchProducts },
        } = await fetchProducts(params);

        setTableDatas(dataFetchProducts.products);
        setTableTotalItems(dataFetchProducts.pageInfo.total);
      } catch (err) {
        console.error();
        setStatusError(err.response.request.status);
        setTableDatas([]);
        setTableTotalItems(tableTotalItems);
        setIsLoadingData(true);
      } finally {
        setIsLoadingData(false);
      }
    };

    // *-------------- All Subs  -------------
    const getAllSubCategories = async () => {
      setIsLoadingData(true);

      let data = {};
      data.pageSize = tableRowPerPage;
      data.page = tableCurrentPage;
      data.categories = [roadHirarki.categoryId];
      if (tableSearch !== '') {
        data['search'] = tableSearch;
      }
      if (sortTable !== 0) {
        data[sortTable] = isSortAsc ? 'asc' : 'desc';
      }

      try {
        const {
          data: { data: dataFetchSubCategories },
        } = await fetchSubCategories(data);

        setTableDatas(dataFetchSubCategories.subCategories);
        setTableTotalItems(dataFetchSubCategories.pageInfo.total);
      } catch (err) {
        console.error();
        setStatusError(err.response.request.status);
        setTableDatas([]);
        setTableTotalItems(tableTotalItems);
        setIsLoadingData(true);
      } finally {
        setIsLoadingData(false);
      }
    };

    // *-------------- All categories  -------------
    const getAllCategories = async () => {
      setIsLoadingData(true);
      let data = {};
      data.pageSize = tableRowPerPage;
      data.page = tableCurrentPage;
      data.subsidiaryId = filterCategorySubsidiary;
      data.opsiKategori = [1];

      if (tableSearch !== '') {
        data['search'] = tableSearch;
      }
      if (sortTable !== 0) {
        data[sortTable] = isSortAsc ? 'asc' : 'desc';
      }

      try {
        const {
          data: { data: dataFetchCategories },
        } = await fetchCategories(data);

        setTableDatas(dataFetchCategories.categories);
        setTableTotalItems(dataFetchCategories.pageInfo.total);
      } catch (err) {
        console.error();
        setStatusError(err.response.request.status);
        setTableDatas([]);
        setTableTotalItems(tableTotalItems);
        setIsLoadingData(true);
      } finally {
        setIsLoadingData(false);
      }
    };

    // !---- MOBILE ----

    // *-------------- All Product Mobile -------------
    const getAllProductsMobile = async () => {
      setIsLoadingData(true);

      let params = {};
      params.pageSize = 12;
      params.page = 1;
      params.name = 'asc';
      if (filterDataAwait.categoryId.length !== 0) {
        params['idKategori'] = filterDataAwait.categoryId;
      }
      if (filterDataAwait.subCategoryId.length !== 0) {
        params['idSubkategori'] = filterDataAwait.subCategoryId;
      }
      if (filterDataAwait.miniSubCategoryId.length !== 0) {
        params['miniCategoryId'] = filterDataAwait.miniSubCategoryId;
      }

      if (tableSearch !== '') {
        params['search'] = tableSearch;
      }

      try {
        const {
          data: { data: dataFetchProducts },
        } = await fetchProducts(params);

        setTableDatas(dataFetchProducts.products);
        setTableTotalItems(dataFetchProducts.pageInfo.total);
      } catch (err) {
        console.error();
        setStatusError(err.response.request.status);
        setTableDatas([]);
        setTableTotalItems(tableTotalItems);
        setIsLoadingData(true);
      } finally {
        setIsLoadingData(false);
      }
    };

    // *-------------- Product with Subs Mobile  -------------
    const getProductsMobile = async () => {
      setIsLoadingData(true);

      let params = {};
      params.pageSize = 12;
      params.page = 1;
      params.opsiKategory = 1;
      params.idKategori = [];
      params.idSubkategori = [roadHirarki.subCategoryId];
      params.miniCategoryid = [];
      params.name = 'asc';

      if (tableSearch !== '') {
        params['search'] = tableSearch;
      }

      try {
        const {
          data: { data: dataFetchProducts },
        } = await fetchProducts(params);

        setTableDatas(dataFetchProducts.products);
        setTableTotalItems(dataFetchProducts.pageInfo.total);
      } catch (err) {
        console.error();
        setStatusError(err.response.request.status);
        setTableDatas([]);
        setTableTotalItems(tableTotalItems);
        setIsLoadingData(true);
      } finally {
        setIsLoadingData(false);
      }
    };

    // *-------------- All Subs Mobile  -------------
    const getAllSubCategoriesMobile = async () => {
      setIsLoadingData(true);

      let data = {};
      data.pageSize = 12;
      data.page = 1;
      data.categories = [roadHirarki.categoryId];
      data.name = 'asc';

      try {
        const {
          data: { data: dataFetchSubCategories },
        } = await fetchSubCategories(data);

        setTableDatas(dataFetchSubCategories.subCategories);
        setTableTotalItems(dataFetchSubCategories.pageInfo.total);
      } catch (err) {
        console.error();
        setStatusError(err.response.request.status);
        setTableDatas([]);
        setTableTotalItems(tableTotalItems);
        setIsLoadingData(true);
      } finally {
        setIsLoadingData(false);
      }
    };

    // *-------------- All Categories Mobile  -------------
    const getAllCategoriesMobile = async () => {
      setIsLoadingData(true);
      let data = {};
      data.name = 'asc';
      data.subsidiaryId = filterCategorySubsidiary;
      data.opsiKategori = filterCategoryType;
      if (tableSearch !== '') {
        data['search'] = tableSearch;
      }

      try {
        const {
          data: { data: dataFetchCategories },
        } = await fetchCategories(data);

        setTableDatas(dataFetchCategories.categories);
        setTableTotalItems(dataFetchCategories.pageInfo.total);

        setIsHasMorePage(
          dataFetchCategories.pageInfo.currentPage <
            dataFetchCategories.pageInfo.lastPage
            ? true
            : false
        );

        // if (
        //   dataFetchCategories.pageInfo.currentPage <
        //   dataFetchCategories.pageInfo.lastPage
        // ) {
        //   return setIsHasMorePage(true);
        // } else {
        //   return setIsHasMorePage(false);
        // }
      } catch (err) {
        console.error();
        setStatusError(err.response.request.status);
        setTableDatas([]);
        setTableTotalItems(tableTotalItems);
        setIsLoadingData(true);
        // setIsHasMorePage(false);
      } finally {
        setIsLoadingData(false);
      }
    };

    if (subCategoryUpdate) {
      console.error(subCategoryUpdate);
      getAllSubCategories();
      dispatch(clearSubCategoryProduct());
    }

    if (desktopMode) {
      if (roadHirarki.categoryId !== 0 && roadHirarki.subCategoryId === 0) {
        getAllSubCategories();
      } else if (
        roadHirarki.categoryId !== 0 &&
        roadHirarki.subCategoryId !== 0
      ) {
        getProducts();
      } else {
        if (page === 'product') {
          getAllProducts();
        } else {
          getAllCategories();
        }
      }
    } else {
      if (roadHirarki.categoryId !== 0 && roadHirarki.subCategoryId === 0) {
        getAllSubCategoriesMobile();
      } else if (
        roadHirarki.categoryId !== 0 &&
        roadHirarki.subCategoryId !== 0
      ) {
        getProductsMobile();
      } else {
        if (page === 'product') {
          getAllProductsMobile();
        } else {
          getAllCategoriesMobile();
        }
      }
    }
  }, [
    desktopMode,
    filterCategorySubsidiary,
    filterCategoryType,
    filterDataAwait.categoryId,
    filterDataAwait.miniSubCategoryId,
    filterDataAwait.subCategoryId,
    isSortAsc,
    page,
    roadHirarki.categoryId,
    roadHirarki.subCategoryId,
    sortTable,
    tableCurrentPage,
    tableRowPerPage,
    tableSearch,
    tableTotalItems,
    subCategoryUpdate,
  ]);

  // !----------------- DETAILS API ----------------
  useEffect(() => {
    // *-------------- Detail Product-------------
    const getDetailProduct = async () => {
      setIsLoadingDataDetails(true);
      try {
        const {
          data: { data: dataFetchCategoriesDetails },
        } = await fetchProductDetails(roadHirarki.productId);
        setDetailsDatas(dataFetchCategoriesDetails);
      } catch (err) {
        console.error();
        setStatusError(err.response.request.status);
        setDetailsDatas([]);
      } finally {
        setIsLoadingDataDetails(false);
      }
    };

    // *-------------- Detail Minis -------------
    const getDetailMiniSubCategory = async () => {
      setIsLoadingDataDetails(true);
      try {
        const {
          data: { data: dataFetchMiniSubCategoriesDetails },
        } = await fetchMiniSubCategoryDetails({
          startDate: '2022-10-25',
          endDate: '2022-12-27',
          id: roadHirarki.miniSubCategoryId,
        });
        setDetailsDatas(dataFetchMiniSubCategoriesDetails);
      } catch (err) {
        console.error();
        setStatusError(err.response.request.status);
        setDetailsDatas([]);
      } finally {
        setIsLoadingDataDetails(false);
      }
    };

    // *-------------- Detail Subs-------------
    const getDetailSubCategory = async () => {
      setIsLoadingDataDetails(true);

      try {
        const {
          data: { data: dataFetchSubCategoriesDetails },
        } = await fetchSubCategoryDetails({
          startDate: '2022-10-25',
          endDate: '2022-12-27',
          id: roadHirarki.subCategoryId,
        });
        setDetailsDatas(dataFetchSubCategoriesDetails);
      } catch (err) {
        console.error();
        setStatusError(err.response.request.status);
        setDetailsDatas([]);
      } finally {
        setIsLoadingDataDetails(false);
      }
    };

    // *-------------- Detail Category-------------
    const getDetailCategory = async () => {
      setIsLoadingDataDetails(true);

      try {
        const {
          data: { data: dataFetchCategoriesDetails },
        } = await fetchCategoryDetails({
          startDate: '2022-10-25',
          endDate: '2022-12-27',
          id: roadHirarki.categoryId,
        });
        setDetailsDatas(dataFetchCategoriesDetails);
      } catch (err) {
        console.error();
        setStatusError(err.response.request.status);
        setDetailsDatas([]);
      } finally {
        setIsLoadingDataDetails(false);
      }
    };

    if (
      page === 'product' &&
      isProductProductDetails === true &&
      isProductProductEdit === false &&
      roadHirarki.productId !== 0
    ) {
      getDetailProduct();
    } else if (
      page === 'product' &&
      isMiniSubProductDetails === true &&
      roadHirarki.miniSubCategoryId !== 0
    ) {
      getDetailMiniSubCategory();
    } else if (page === 'subCategory' && roadHirarki.subCategoryId !== 0) {
      getDetailSubCategory();
    } else if (page === 'category' && roadHirarki.categoryId !== 0) {
      getDetailCategory();
    }
  }, [
    roadHirarki.categoryId,
    roadHirarki.productId,
    roadHirarki.subCategoryId,
    isCategoryProductDetails,
    isMiniSubProductDetails,
    isProductProductEdit,
    roadHirarki.miniSubCategoryId,
    page,
  ]);

  // *--------- type category -------------
  const [typeCategory, setTypeCategory] = useState([]);
  const [typeCategoryLoading, setTypeCategoryLoading] = useState([]);

  useEffect(() => {
    const getAllTypeCategory = async () => {
      setTypeCategoryLoading(true);
      let datas = {};
      datas.pageSize = 100;
      datas.page = 1;
      try {
        const {
          data: {
            data: { categoryOptions: dataType },
          },
        } = await allTypeCategoryProduct(datas);

        setTypeCategory(dataType.filter((item) => item.iDopsi !== 6));
      } catch (err) {
        console.error();
        setStatusError(err.response.request.status);
        setTypeCategory([]);
      } finally {
        setTypeCategoryLoading(false);
      }
    };
    getAllTypeCategory();
  }, []);

  // *----------------- SUBSIDIARY -------------------

  useEffect(() => {
    const getAllSubsidiary = async () => {
      setIsLoadingSubsidiary(true);
      try {
        const {
          data: {
            data: { subsidiaries: dataFetchSubsidiary },
          },
        } = await fetchSubsidiary();

        setSubsidiaryDatas(dataFetchSubsidiary);
      } catch (err) {
        console.error();
        setStatusError(err.response.request.status);
        setSubsidiaryDatas([]);
      } finally {
        setIsLoadingSubsidiary(false);
      }
    };
    getAllSubsidiary();
  }, []);

  // *----------------- UNIT -------------------
  useEffect(() => {
    const getUnits = async () => {
      setUnitLoading(true);
      try {
        const {
          data: { data: dataFetchUnits },
        } = await fetchUnits({
          pageSize: 100,
          page: 1,
        });

        setUnitDatas(dataFetchUnits.units);
      } catch (err) {
        console.error();
        setStatusError(err.response.request.status);
        setUnitDatas([]);
      } finally {
        setUnitLoading(false);
      }
    };

    if (page === 'product') {
      getUnits();
    }
  }, [page]);

  // *! -----------------------------  END --------------------------
  // *! -------------------------------------------------------------

  return (
    <>
      {statusError == 429 ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ mt: '54px' }}>
            <Typography
              align="center"
              color="primary"
              sx={{ fontSize: '50px' }}
            >
              429
            </Typography>
            <Typography align="center">Too Many Request</Typography>
            <Typography align="center">Please Try Again Later</Typography>
          </Box>
        </Box>
      ) : (
        <Box sx={{ mt: '16px' }}>
          {page === 'product' ? (
            <>
              {/* ---------------------------- product ---------------------------- */}
              {isProductProductAdd ? (
                <ProductProductAdd
                  {...{
                    page,
                    setPage,
                    roadHirarki,
                    setRoadHirarki,
                    isProductProductAdd,
                    setIsProductProductAdd,
                    isProductProductEdit,
                    setIsProductProductEdit,
                  }}
                  {...{
                    productAddInputDatas,
                    setProductAddInputDatas,
                    dummyDataTable,
                  }}
                  {...{
                    unitDatas,
                    unitLoading,
                  }}
                  {...{
                    catSubMiniProduct,
                    setCatSubMiniProduct,
                    categoryOption,
                    setCategoryOption,
                    subCategoryOption,
                    setSubCategoryOption,
                    miniSubCategoryOption,
                    setMiniSubCategoryOption,
                    categoryOptionLoading,
                    setCategoryOptionLoading,
                    subCategoryOptionLoading,
                    setSubCategoryOptionLoading,
                    miniSubCategoryOptionLoading,
                    setMiniSubCategoryOptionLoading,
                    categoryDataAwait,
                    setCategoryDataAwait,
                    unitDataAwait,
                    setUnitDataAwait,
                    variatnDataAwait,
                    setVariantDataAwait,
                    stockLimitDataAwait,
                    setStockLimitDataAwait,
                    orderLimitDataAwait,
                    setOrderLimitDataAwait,
                  }}
                  {...{ notif, setNotif, notifTitle, setNotifTitle }}
                />
              ) : isProductProductEdit ? (
                <ProductProductEdit
                  {...{ unitDatas, unitLoading }}
                  {...{
                    page,
                    setPage,
                    roadHirarki,
                    setRoadHirarki,
                    isProductProductAdd,
                    setIsProductProductAdd,
                    isProductProductEdit,
                    setIsProductProductEdit,
                    isProductProductDetails,
                    setIsProductProductDetails,
                  }}
                  {...{
                    productAddInputDatas,
                    setProductAddInputDatas,
                    dummyDataTable,
                  }}
                  {...{
                    productEditInputDatas,
                    setProductEditInputDatas,
                    categoryDataAwaitEdit,
                    setCategoryDataAwaitEdit,
                    unitDataAwaitEdit,
                    setUnitDataAwaitEdit,
                    variatnDataAwaitEdit,
                    setVariantDataAwaitEdit,
                    stockLimitDataAwaitEdit,
                    setStockLimitDataAwaitEdit,
                  }}
                  {...{
                    catSubMiniProduct,
                    setCatSubMiniProduct,
                    categoryOption,
                    setCategoryOption,
                    subCategoryOption,
                    setSubCategoryOption,
                    miniSubCategoryOption,
                    setMiniSubCategoryOption,
                    categoryOptionLoading,
                    setCategoryOptionLoading,
                    subCategoryOptionLoading,
                    setSubCategoryOptionLoading,
                    miniSubCategoryOptionLoading,
                    setMiniSubCategoryOptionLoading,
                  }}
                  {...{ notif, setNotif, notifTitle, setNotifTitle }}
                />
              ) : isProductProductDetails ? (
                <ProductProductDetails
                  {...{ page, setPage, roadHirarki, setRoadHirarki }}
                  {...{ isLoadingDataDetails, detailsDatas }}
                  {...{
                    timeFrame,
                    setTimeFrame,
                    dateRange,
                    setDateRange,
                    saveDateRange,
                    setSaveDateRange,
                  }}
                  {...{ setIsProductProductDetails, setIsProductProductEdit }}
                  {...{
                    productEditInputDatas,
                    setProductEditInputDatas,
                    categoryDataAwaitEdit,
                    setCategoryDataAwaitEdit,
                    unitDataAwaitEdit,
                    setUnitDataAwaitEdit,
                    variatnDataAwaitEdit,
                    setVariantDataAwaitEdit,
                    stockLimitDataAwaitEdit,
                    setStockLimitDataAwaitEdit,
                  }}
                  {...{ notif, setNotif, notifTitle, setNotifTitle }}
                />
              ) : isMiniSubProductAdd ? (
                <MiniSubProductAdd
                  {...{
                    page,
                    setPage,
                    roadHirarki,
                    setRoadHirarki,
                    setIsMiniSubProductAdd,
                    setIsMiniSubProductEdit,
                  }}
                  {...{ notif, setNotif, notifTitle, setNotifTitle }}
                  {...{ miniSubAddInputDatas, setMiniSubAddInputDatas }}
                />
              ) : isMiniSubProductEdit ? (
                <MiniSubProductEdit
                  {...{
                    page,
                    setPage,
                    roadHirarki,
                    setRoadHirarki,
                    setIsMiniSubProductAdd,
                    setIsMiniSubProductEdit,
                    setIsMiniSubProductDetails,
                  }}
                  {...{ miniSubEditInputDatas, setMiniSubEditInputDatas }}
                  {...{ notif, setNotif, notifTitle, setNotifTitle }}
                />
              ) : isMiniSubProductDetails ? (
                <MiniSubProductDetails
                  {...{ page, setPage, roadHirarki, setRoadHirarki }}
                  {...{ isLoadingDataDetails, detailsDatas }}
                  {...{
                    timeFrame,
                    setTimeFrame,
                    dateRange,
                    setDateRange,
                    saveDateRange,
                    setSaveDateRange,
                  }}
                  {...{ setIsMiniSubProductDetails, setIsMiniSubProductEdit }}
                  {...{ miniSubEditInputDatas, setMiniSubEditInputDatas }}
                  {...{ notif, setNotif, notifTitle, setNotifTitle }}
                />
              ) : (
                <ProductProduct
                  {...{
                    page,
                    setPage,
                    roadHirarki,
                    setRoadHirarki,
                    isProductProductAdd,
                    setIsProductProductAdd,
                    isProductProductEdit,
                    setIsProductProductEdit,
                    setIsProductProductDetails,
                    setIsMiniSubProductDetails,
                    setIsMiniSubProductAdd,
                    isLoadingData,
                  }}
                  {...{
                    timeFrame,
                    setTimeFrame,
                    dateRange,
                    setDateRange,
                    saveDateRange,
                    setSaveDateRange,
                  }}
                  {...{
                    tableRowPerPageValues,
                    tableRowPerPage,
                    setTableRowPerPage,
                    tableCurrentPage,
                    setTableCurrentPage,
                    tableTotalItems,
                    setTableTotalItems,
                    tableSearch,
                    setTableSearch,
                    sortTable,
                    setSortTable,
                    isSortAsc,
                    setIsSortAsc,
                    tableDatas,
                    setTableDatas,
                  }}
                  {...{ notif, setNotif, notifTitle, setNotifTitle }}
                  {...{ filterDataAwait, setFilterDataAwait }}
                  {...{ setIsLoadingData, setStatusError }}
                />
              )}
            </>
          ) : page === 'subCategory' ? (
            <>
              {/* ---------------------------- sub category ---------------------------- */}
              {isSubCategoryProductAdd ? (
                <SubCategoryProductAdd
                  {...{
                    page,
                    setPage,
                    roadHirarki,
                    setRoadHirarki,
                    setIsSubCategoryProductAdd,
                    setIsSubCategoryProductEdit,
                  }}
                  {...{ subCategoryAddInputDatas, setSubCategoryAddInputDatas }}
                  {...{ notif, setNotif, notifTitle, setNotifTitle }}
                />
              ) : isSubCategoryProductEdit ? (
                <SubCategoryProductEdit
                  {...{ page, setPage, roadHirarki, setRoadHirarki }}
                  {...{
                    isSubCategoryProductAdd,
                    setIsSubCategoryProductAdd,
                    isSubCategoryProductEdit,
                    setIsSubCategoryProductEdit,
                    isSubCategoryProductDetails,
                    setIsSubCategoryProductDetails,
                  }}
                  {...{
                    subCategoryAddInputDatas,
                    setSubCategoryAddInputDatas,
                  }}
                  {...{
                    subCategoryEditInputDatas,
                    setSubCategoryEditInputDatas,
                  }}
                  {...{ notif, setNotif, notifTitle, setNotifTitle }}
                />
              ) : isSubCategoryProductDetails ? (
                <SubCategoryProductDetails
                  {...{ page, setPage, roadHirarki, setRoadHirarki }}
                  {...{ isLoadingDataDetails, detailsDatas }}
                  {...{
                    timeFrame,
                    setTimeFrame,
                    dateRange,
                    setDateRange,
                    saveDateRange,
                    setSaveDateRange,
                  }}
                  {...{
                    setIsSubCategoryProductDetails,
                    setIsSubCategoryProductEdit,
                  }}
                  {...{
                    subCategoryEditInputDatas,
                    setSubCategoryEditInputDatas,
                  }}
                  {...{ notif, setNotif, notifTitle, setNotifTitle }}
                />
              ) : (
                <SubCategoryProduct
                  {...{
                    page,
                    setPage,
                    roadHirarki,
                    setRoadHirarki,
                    setIsSubCategoryProductAdd,
                    setIsSubCategoryProductEdit,
                    setIsSubCategoryProductDetails,
                    isLoadingData,
                  }}
                  {...{
                    timeFrame,
                    setTimeFrame,
                    dateRange,
                    setDateRange,
                    saveDateRange,
                    setSaveDateRange,
                  }}
                  {...{
                    tableRowPerPageValues,
                    tableRowPerPage,
                    setTableRowPerPage,
                    tableCurrentPage,
                    setTableCurrentPage,
                    tableTotalItems,
                    setTableTotalItems,
                    tableSearch,
                    setTableSearch,
                    sortTable,
                    setSortTable,
                    isSortAsc,
                    setIsSortAsc,
                    tableDatas,
                    setTableDatas,
                  }}
                  {...{ notif, setNotif, notifTitle, setNotifTitle }}
                  {...{ setIsLoadingData, setStatusError }}
                />
              )}
            </>
          ) : (
            <>
              {/* ---------------------------- category ---------------------------- */}
              {isCategoryProductAdd ? (
                <CategoryProductAdd
                  {...{ isLoadingSubsidiary, subsidiaryDatas }}
                  {...{
                    page,
                    setPage,
                    setIsCategoryProductAdd,
                    setIsCategoryProductEdit,
                  }}
                  {...{
                    categoryAddInputDatas,
                    setCategoryAddInputDatas,
                  }}
                  {...{ notif, setNotif, notifTitle, setNotifTitle }}
                  {...{ categoryEditInputDatas, setCategoryEditInputDatas }}
                />
              ) : isCategoryProductEdit ? (
                <CategoryProductEdit
                  {...{ page, setPage, roadHirarki, setRoadHirarki }}
                  {...{ isLoadingSubsidiary, subsidiaryDatas }}
                  {...{ isLoadingDataDetails, detailsDatas, setDetailsDatas }}
                  {...{
                    isCategoryProductAdd,
                    setIsCategoryProductAdd,
                    isCategoryProductEdit,
                    setIsCategoryProductEdit,
                    isCategoryProductDetails,
                    setIsCategoryProductDetails,
                  }}
                  {...{
                    categoryAddInputDatas,
                    setCategoryAddInputDatas,
                  }}
                  {...{ notif, setNotif, notifTitle, setNotifTitle }}
                  {...{ categoryEditInputDatas, setCategoryEditInputDatas }}
                />
              ) : isCategoryProductDetails ? (
                <CategoryProductDetails
                  {...{ page, setPage, roadHirarki, setRoadHirarki }}
                  {...{ isLoadingDataDetails, detailsDatas, setDetailsDatas }}
                  {...{
                    timeFrame,
                    setTimeFrame,
                    dateRange,
                    setDateRange,
                    saveDateRange,
                    setSaveDateRange,
                  }}
                  {...{ setIsCategoryProductDetails, setIsCategoryProductEdit }}
                  {...{ categoryEditInputDatas, setCategoryEditInputDatas }}
                  {...{ notif, setNotif, notifTitle, setNotifTitle }}
                />
              ) : (
                <CategoryProduct
                  {...{
                    page,
                    setPage,
                    roadHirarki,
                    setRoadHirarki,
                    setIsCategoryProductAdd,
                    setIsCategoryProductEdit,
                    setIsCategoryProductDetails,
                    setIsLoadingData,
                    isLoadingData,
                  }}
                  {...{
                    timeFrame,
                    setTimeFrame,
                    dateRange,
                    setDateRange,
                    saveDateRange,
                    setSaveDateRange,
                  }}
                  {...{
                    tableRowPerPageValues,
                    tableRowPerPage,
                    setTableRowPerPage,
                    tableCurrentPage,
                    setTableCurrentPage,
                    tableTotalItems,
                    setTableTotalItems,
                    tableSearch,
                    setTableSearch,
                    sortTable,
                    setSortTable,
                    isSortAsc,
                    setIsSortAsc,
                    tableDatas,
                    setTableDatas,
                    tableCategoryFilterDatas,
                    setTableCategoryFilterDatas,
                    isHasMorePage,
                    setIsHasMorePage,
                  }}
                  {...{
                    typeCategory,
                    setTypeCategory,
                    typeCategoryLoading,
                    setTypeCategoryLoading,
                  }}
                  {...{ isLoadingSubsidiary, subsidiaryDatas }}
                  {...{
                    filterCategorySubsidiary,
                    setFilterCategorySubsidiary,
                    filterCategoryType,
                    setFilterCategoryType,
                  }}
                  {...{ notif, setNotif, notifTitle, setNotifTitle }}
                  {...{ setDetailsDatas }}
                />
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};
export default InventoryProduct;
