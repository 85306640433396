import React from 'react';
import CustomDialog from '../../../../../../components/shared/CustomDialog';
import CustomButton from '../../../../../../components/shared/CustomButton';
import {
  Box,
  Chip,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { CloseIcon } from '../../../../../../assets/Icons';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getSubsidiary } from '../../../../../../api/subsidiary';
import {
  changeSpesifikSubsidiary,
  changeTemporarySubsidiary,
  getTemporarySubsidiary,
} from '../../../../../../store/stock/stock';

const DialogFilterStockMobile = ({ isFilter, setIsFilter }) => {
  const dispatch = useDispatch();
  const [dataSubsidiary, setDataSubsidiary] = useState([]);
  const temporarySubsidiary = useSelector(getTemporarySubsidiary);
  const [spesifikSubsidiary, setSpesifikSubsidiary] = useState([]);

  // ! ---------------------------- API ----------------------------
  // subsidiary

  useEffect(() => {
    const fetchSubsidiary = async () => {
      try {
        const {
          data: { data },
        } = await getSubsidiary();
        setDataSubsidiary(data?.subsidiaries);
      } catch (err) {
        console.error(err);
      }
    };
    fetchSubsidiary();
  }, []);

  const heandleSave = () => {
    dispatch(changeSpesifikSubsidiary(spesifikSubsidiary));
    setIsFilter(false);
  };

  const Reset = () => {
    dispatch(changeTemporarySubsidiary([]));
    setSpesifikSubsidiary([]);
  };

  return (
    <CustomDialog
      isOpen={isFilter}
      setIsOpen={setIsFilter}
      paperprops={{
        sx: {
          borderRadius: '12px',
        },
      }}
    >
      {/* dialog Titele */}
      <DialogTitle
        sx={{
          // boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
          borderRadius: '20px 20px 0px 0px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            direction: '',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '280px',
          }}
        >
          <IconButton
            aria-label="close"
            sx={{
              color: '#323232',
              p: 0,
            }}
            onClick={() => setIsFilter(!isFilter)}
          >
            <CloseIcon sx={{ fontSize: '1.25rem' }} />
          </IconButton>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              color: '#000000',
            }}
            component="span"
          >
            Filter
          </Typography>
          <Typography
            sx={{ color: '#51B15C', fontSize: '16px', fontWeight: 600 }}
            onClick={() => Reset('')}
          >
            Reset
          </Typography>
        </Box>
      </DialogTitle>

      {/* // dialog conten*/}

      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            pt: 2,
          }}
        >
          <Autocomplete
            multiple
            value={temporarySubsidiary}
            onChange={(_, newValue) => {
              dispatch(changeTemporarySubsidiary(newValue));
              setSpesifikSubsidiary(newValue?.map((item, _) => item?.id));
            }}
            options={dataSubsidiary}
            getOptionLabel={(option) => option.name}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Subsidiary"
                placeholder="All"
              />
            )}
          />
        </Box>

        <CustomButton
          sx={{
            display: 'flex',
            justifyContent: 'center',
            bgcolor: '#51B15C',
            color: '#ffffff',
            fontWeight: 600,
            fontSize: '0.875rem',
            borderRadius: '8px',
            width: '100%',
            '&:hover': {
              bgcolor: '#51B15C',
              color: '#ffffff',
            },
            px: 4,
            py: 1,
            mt: 2,
          }}
        >
          <Typography onClick={heandleSave}>Save</Typography>
        </CustomButton>
      </DialogContent>
    </CustomDialog>
  );
};

export default DialogFilterStockMobile;
