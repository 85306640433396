import { useDispatch, useSelector } from "react-redux";
import {
  changeName,
  getIdToko,
  getSubHubAll,
  getSubHubName,
} from "../../../../../../../store/hub/subHub/subHubAdd";
import { Box, Button, Grid, Typography } from "@mui/material";
import BackButtonHub from "../../../../../../../components/shared/BackButtonHub";
import useHub from "../../../../../../../hooks/useHub";
import { createSearchParams, useSearchParams } from "react-router-dom";
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  GridOn,
} from "@mui/icons-material";
import FormAddSubHub from "./FormAddSubHub";
import { useEffect, useState } from "react";
import HubFamily from "../../HubFamily";
import { getHubFamilies } from "../../../../../../../api/generalAPI";
import { async } from "q";
import { getDetailHubs } from "../../../../../../../api/hubs";

function AddSubHub({ setIsOpenNewSubHub, setSubHubSelected, subHubDetail }) {
  const { hubFamilies, setHubFamilies, masterHubData } = useHub();
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);
  const [isLoading, setIsLoading] = useState(true);

  const [subHubName, setSubHubName] = useState("");
  const name = useSelector(getSubHubName);
  const [dataToko, setDataToko] = useState([]);
  const idToko = useSelector(getIdToko);

  useEffect(() => {
    const getDetailToko = async () => {
      try {
        const {
          data: { data },
        } = await getDetailHubs(idToko);
        setDataToko(data);
      } catch (err) {
        console.error(err);
      }
    };
    getDetailToko();
  }, []);

  // useEffect(() => {
  //   const fetchHubFamilies = async () => {
  //     setIsLoading(true);
  //     try {
  //       const {
  //         data: { data },
  //       } = await getHubFamilies();
  //       // setHubFamilies(data);
  //       console.log({ datas: data });
  //     } catch (err) {
  //       console.error(err);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchHubFamilies();
  // }, []);

  useEffect(() => {
    const fetchHubFamilies = async () => {
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await getHubFamilies();
        setHubFamilies(data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchHubFamilies();
  }, [setHubFamilies]);

  return (
    <Box>
      <Box sx={{ mt: 3 }}>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <BackButtonHub
            onClick={() => {
              setIsOpenNewSubHub(false);
              setSubHubSelected(dataToko?.idToko);
              updatedSearchParams.set("subhub-overview", dataToko?.idToko);
              setSearchParams(updatedSearchParams);
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography
              sx={{
                color: "primary.black",
                fontSize: "1.125rem",
                fontWeight: 600,
                textTransform: "capitalize",
              }}
            >
              Add sub hub
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                color: "secondary.light",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "0.875rem", fontWeight: 600 }}>
                {dataToko?.subsidiary?.name || "-"}
              </Typography>
              <ArrowForwardIosIcon
                sx={{ color: "secondary.light", fontSize: "0.75rem" }}
              />
              <Typography sx={{ fontSize: "0.875rem", fontWeight: 600 }}>
                {dataToko?.namaToko || "-"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs="auto" sx={{ minWidth: "150px" }}>
            <Box
              sx={{
                width: "150px",
                height: "98%",
                bgcolor: "#FAFAFA",
                mt: 2,
              }}
            >
              <HubFamily isAddMaster={true} nameAddMaster={name} />
            </Box>
          </Grid>

          <Grid item xs>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 2,
                // height: "100vh",
              }}
            >
              <FormAddSubHub
                subHubName={subHubName}
                setSubHubName={setSubHubName}
                setIsOpenNewSubHub={setIsOpenNewSubHub}
                setSubHubSelected={setSubHubSelected}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
export default AddSubHub;
