import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
} from "@mui/material";
import { ReactComponent as DashboardIcon } from "../assets/dashboard.svg";
import { ReactComponent as AssetsLogo } from "../assets/logo-assets.svg";
import { Logout as LogoutIcon } from "@mui/icons-material";
import logoImage from "../assets/logo.png";
import { useLocation, Outlet, Link, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { ReactComponent as NoImage } from "../assets/no-image.svg";
import BadgeIcon from "@mui/icons-material/Badge";
import { useGeolocated } from "react-geolocated";
import { logout } from "../api/auth";
import { getUserInfo } from "../api/generalAPI";
import { useSelector } from "react-redux";
import { getuserLogin } from "../store/account/account";

const drawerWidth = "13rem";

const DesktopLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);
  const user = useSelector(getuserLogin);

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
    });

  useEffect(() => {
    if (coords) {
      setLatitude(coords.latitude);
      setLongitude(coords.longitude);
    }
  }, [coords]);

  const handleLogout = async () => {
    try {
      await logout({
        lat: latitude,
        long: longitude,
      });
      setAuth(null);
      localStorage.clear();
    } catch (error) {
      console.error(error);
      setAuth(null);
      localStorage.clear();
    }
  };

  const [isOpenAssetsList, setIsOpenAssetsList] = useState(true);
  const [isOpenCurrentAssetsList, setIsOpenCurrentAssetsList] = useState(true);
  const [isOpenNonCurrentAssetsList, setIsOpenNonCurrentAssetsList] =
    useState(true);

  // get user info
  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     try {
  //       const {
  //         data: { data },
  //       } = await getUserInfo();
  //       console.log({ data });
  //       setUser(data);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };

  //   fetchUserData();
  // }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
        }}
        PaperProps={{
          sx: {
            width: drawerWidth,
            boxSizing: "border-box",
            borderColor: "divider",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar disableGutters sx={{ px: 2 }}>
          <Box
            component="img"
            style={{
              width: "1.75rem",
              height: "1.75rem",
              borderRadius: 1,
            }}
            src={logoImage}
          />
          <Typography
            sx={{
              ml: 1.75,
              color: "primary.main",
              fontSize: "1.75rem",
              fontWeight: "semiBold",
            }}
          >
            Alere
          </Typography>
        </Toolbar>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ px: 2, py: 1.5 }}
              to="dashboard"
              component={Link}
            >
              <ListItemIcon sx={{ minWidth: 0 }}>
                <DashboardIcon
                  fill={
                    location?.pathname === "/dashboard" ? "#51B15C" : "#B0C6B5"
                  }
                  width="20px"
                  height="20px"
                />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                sx={{
                  ml: "1rem",
                }}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: "semiBold",
                    color: "primary.main",
                    fontSize: "1.25rem",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ px: 2, py: 1.5 }}
              onClick={() => setIsOpenAssetsList(!isOpenAssetsList)}
            >
              <ListItemIcon sx={{ minWidth: 0 }}>
                <AssetsLogo fill={"#B0C6B5"} width="20px" height="20px" />
              </ListItemIcon>
              <ListItemText
                primary="Assets"
                sx={{
                  ml: "1rem",
                }}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 600,
                    color: "primary.grey",
                    fontSize: "0.875rem",
                  },
                }}
              />
              {isOpenAssetsList ? (
                <ExpandLess
                  sx={{
                    color: "primary.grey",
                  }}
                />
              ) : (
                <ExpandMore
                  sx={{
                    color: "primary.grey",
                  }}
                />
              )}
            </ListItemButton>
          </ListItem>
          <Collapse in={isOpenAssetsList} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ px: 2, py: 1.5 }}
                  onClick={() =>
                    setIsOpenCurrentAssetsList(!isOpenCurrentAssetsList)
                  }
                >
                  {/* <ListItemIcon sx={{ minWidth: 0 }}>
                  <AssetsLogo fill={'#B0C6B5'} width="20px" height="20px" />
                </ListItemIcon> */}
                  <ListItemText
                    primary="Current Assets"
                    sx={{
                      ml: 4.3,
                    }}
                    primaryTypographyProps={{
                      sx: {
                        fontWeight: 600,
                        color: "primary.grey",
                        fontSize: "0.875rem",
                      },
                    }}
                  />
                  {isOpenCurrentAssetsList ? (
                    <ExpandLess
                      sx={{
                        color: "primary.grey",
                      }}
                    />
                  ) : (
                    <ExpandMore
                      sx={{
                        color: "primary.grey",
                      }}
                    />
                  )}
                </ListItemButton>
              </ListItem>

              <Collapse
                in={isOpenCurrentAssetsList}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItemButton component={Link} to="/assets/inventory">
                    <ListItemText
                      sx={{ ml: 6 }}
                      primary="Inventory"
                      primaryTypographyProps={{
                        sx: {
                          fontWeight: 500,
                          color:
                            location?.pathname === "/assets/inventory"
                              ? "#51B15C"
                              : "primary.grey",
                          fontSize: "0.875rem",
                        },
                      }}
                    />
                  </ListItemButton>
                </List>
              </Collapse>
            </List>

            <List component="div">
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ px: 2, py: 1.5 }}
                  onClick={() =>
                    setIsOpenNonCurrentAssetsList(!isOpenNonCurrentAssetsList)
                  }
                >
                  {/* <ListItemIcon sx={{ minWidth: 0 }}>
                  <AssetsLogo fill={'#B0C6B5'} width="20px" height="20px" />
                </ListItemIcon> */}
                  <ListItemText
                    primary="Noncurrent Assets"
                    sx={{
                      ml: 4.3,
                    }}
                    primaryTypographyProps={{
                      sx: {
                        fontWeight: 600,
                        color: "primary.grey",
                        fontSize: "0.875rem",
                      },
                    }}
                  />
                  {isOpenNonCurrentAssetsList ? (
                    <ExpandLess
                      sx={{
                        color: "primary.grey",
                      }}
                    />
                  ) : (
                    <ExpandMore
                      sx={{
                        color: "primary.grey",
                      }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>

          <Box
            sx={{
              position: "fixed",
              bottom: 10,
              width: drawerWidth,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              px: 2,
              py: 1.5,
              alignItems: "flex-start",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                onClick={() => navigate("/login-log")}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: 0.5,
                }}
              >
                <Box>
                  <BadgeIcon sx={{ color: "#303030" }} />
                </Box>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "600", color: "#303030" }}
                >
                  Login Activity
                </Typography>
              </Box>
            </Box>
            <Divider />

            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box
                  sx={{
                    width: "48px",
                    height: "48px",
                    bgcolor: "#F5F5F5",
                    borderRadius: "50%",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderStyle: "dashed",
                    borderWidth: "1px",
                    borderColor: "#9E9D9D",
                  }}
                >
                  <NoImage sx={{ color: "#9E9D9D" }} />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 0.2,
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                  {localStorage.getItem("name")}
                </Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                  {localStorage.getItem("userRole")}
                  {/* {user[0]?.role?.name} */}
                </Typography>
              </Box>
              <Box>
                <IconButton onClick={handleLogout}>
                  <LogoutIcon color="error" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default DesktopLayout;
