import { alereAxios } from '../utils/api';

export const getRackDetail = (id) => alereAxios.get(`hubs/racks/${id}`);

export const getRackPositionList = ({ id, ...params }) =>
  alereAxios.get(`hubs/racks/${id}/position-qr-list`, {
    params,
  });
export const getAllRack = (params) =>
  alereAxios.get(`hubs/racks`, {
    params,
  });
export const updateSentPurchaseSupplier = (params) =>
  alereAxios.put(`purchases/qr-codes-multiple`, params);

export const getSpesifikDimensionRack = (params) =>
  alereAxios.get('hubs/racks/position', { params: params });

// export const getSpesifikDimensionRack = (idRack, params) =>
// alereAxios.get(`hubs/racks/${idRack}/position-qr-list`, { params: params });

export const deleteRack = (id) =>
  alereAxios.delete(`hubs/racks/delete-multiple`, { id });

export const getDataRackForTransaction = (params) =>
  alereAxios.post(`transactions/check-rack`, params);
