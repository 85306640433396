import { createSlice } from '@reduxjs/toolkit';
import { getListSupplier } from '../../api/Supplier';

export const dataSuppliersState = createSlice({
  name: 'dataSupplier',
  initialState: {
    datas: [],
    pageInfo: null,
    isLoading: false,
  },
  reducers: {
    loadDatas: (state, action) => {
      state.datas = action.payload;
    },
    loadPageInfo: (state, action) => {
      state.pageInfo = action.payload;
    },
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { loadDatas, loadPageInfo, changeIsLoading } =
  dataSuppliersState.actions;

export const getAllStateSuppliers = (state) => state.dataSupplier;

export default dataSuppliersState.reducer;

export const fetchListSuppliers = (params) => (dispatch) => {
  const fetchData = async () => {
    dispatch(changeIsLoading(true));
    try {
      const {
        data: { data },
      } = await getListSupplier(params);
      dispatch(loadDatas(data?.suppliers));
      dispatch(loadPageInfo(data?.pageInfo));
      dispatch(changeIsLoading(false));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoading(false));
    }
  };

  fetchData();
};
