import { useDispatch, useSelector } from 'react-redux';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';
import {
  getAllSupplierRouting,
  setCloseNotifSupplier,
} from '../../../../../../store/Routing/supplierRouting';

const NotifSupplier = () => {
  const dispatch = useDispatch();
  const { keyNotif, textNotif } = useSelector(getAllSupplierRouting);

  const keys = ['addSupplier', 'editSupplier', 'deleteSupplier', 'nextAdd'];

  return (
    <>
      {keys.map((key, index) => (
        <CustomNotifSuccess
          text={textNotif}
          open={keyNotif === key}
          keys={keyNotif}
          onClose={() => {
            dispatch(setCloseNotifSupplier());
          }}
          key={index}
          sx={{
            background: key === 'nextAdd' ? '#A33A3A' : '',
            color: key === 'nextAdd' ? '#FFF' : '',
          }}
        />
      ))}
    </>
  );
};
export default NotifSupplier;
