import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import {
  LocalPrintshopRoundedIcon,
  SearchIcon,
} from '../../../../../../../assets/Icons';
import { useState, useRef, useEffect } from 'react';
import debounce from 'lodash.debounce';
import ReactToPrint from 'react-to-print';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import ComponentToPrint from '../../PurchaseQrQode/ComponentToPrint';
import { getAllQrCode } from '../../../../../../../api/qrcode';
import { Height } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { getAllPurchaseRouting } from '../../../../../../../store/Routing/purchaseRouting';
import dayjs from 'dayjs';

const color = {
  processing: '#2191FB',
  finish: '#FF439D',
  accept: '#51B15C',
  sent: '#E8C32C',
  pending: '#FD7900',
  supplier: '#6342E8',
  retur: '#F33A3A',
  returAccept: '#1F10C5',
};

const bgColor = {
  processing: 'rgba(33, 145, 251, 0.1)',
  finish: 'rgba(255, 67, 157, 0.1)',
  accept: 'rgba(81, 177, 92, 0.1)',
  sent: 'rgba(234, 205, 87, 0.1)',
  pending: 'rgba(253, 121, 0, 0.1)',
  supplier: 'rgba(99, 66, 232, 0.1)',
  retur: 'rgba(243, 58, 58, 0.1)',
  returAccept: 'rgba(31, 16, 197, 0.1)',
};

const TablePurchaseQrCodeMobile = ({ id }) => {
  const componentRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [search, setSearch] = useState('');
  const [searchFix, setSearchFix] = useState('');
  const [datasForPrint, setDatasForPrint] = useState([]);
  const [isLoadingPrint, setIsLoadingPrint] = useState(false);
  const [isErrorPrint, setIsErrorPrint] = useState(false);
  const [totalData, setTotalData] = useState(34);
  const [datas, setDatas] = useState([]);
  console.log({ datas });

  const [sortBy, setSortBy] = useState('product');
  const [sortByTempo, setSortByTempo] = useState('product');
  const [isAsc, setIsAsc] = useState(true);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const fetchApi = async (id, page, pageSize, sortBy, isAsc, search) => {
    setIsLoading(true);
    const params = {
      page: page,
      pageSize: pageSize,
      search: search,
    };
    params[sortBy] = isAsc ? 'asc' : 'desc';
    try {
      const {
        data: {
          data: {
            // pageInfo: { total, lastPage },
            withqr,
          },
        },
      } = await getAllQrCode(params, id);
      console.log({ withqr });
      // setTotalData(total);
      setDatas(withqr);
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApi(id, page, pageSize, sortBy, isAsc, searchFix);
  }, [id, page, pageSize, sortBy, isAsc, searchFix]);

  const fetchTotalPrint = async (id) => {
    setIsLoadingPrint(true);
    const params = {
      page: 1,
      pageSize: totalData,
    };
    try {
      const {
        data: {
          data: { withqr },
        },
      } = await getAllQrCode(params, id);
      console.log({ withqr });

      setDatasForPrint(withqr);
    } catch (error) {
      console.error(error);
      setIsErrorPrint(true);
    } finally {
      setIsLoadingPrint(false);
    }
  };

  useEffect(() => {
    if (datas.length !== 0) {
      fetchTotalPrint(id);
    }
  }, [id, datas]);

  const debounceSearch = useRef(
    debounce((value) => {
      setSearchFix(value);
    }, 1000)
  ).current;

  return (
    <CustomCard sx={{ p: 2 }}>
      <Grid container spacing={3} columns={12}>
        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{ color: '#000000', fontWeight: 600, fontSize: '12px' }}
          >
            QR Code List
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <TextField
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              debounceSearch(e.target.value);
            }}
            placeholder={'Search'}
            size="small"
            sx={{
              bgcolor: '#FAFAFA',
              // width: '250px',
              '& .MuiOutlinedInput-root': {
                paddingLeft: '6px',
                fontSize: '8px',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ width: '8px' }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ReactToPrint
            trigger={() => (
              <CustomButton
                variant="contained"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 0.3,
                  p: 0,
                  minHeight: '20px',
                  Height: '20px',
                }}
              >
                <LocalPrintshopRoundedIcon
                  sx={{ color: 'whtie', width: '10px' }}
                />
                <Typography
                  sx={{ color: 'whtie', fontSize: '8px', fontWeight: 600 }}
                >
                  Print
                </Typography>
              </CustomButton>
            )}
            content={() => componentRef.current}
          />
          <Box sx={{ display: 'none' }}>
            <ComponentToPrint ref={componentRef} data={datasForPrint} />
          </Box>
        </Grid>

        {datas.map((item, index) => (
          <Grid item xs={6}>
            <Box
              sx={{
                background: '#FAFAFA',
                borderRadius: '8px',
                p: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                    {item?.namaProduk}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '52px',
                    height: '17px',
                    background: item?.status === 'sent' && bgColor.sent,
                    borderRadius: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 600,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '8px',
                      color: item?.status === 'sent' && color.sent,
                    }}
                  >
                    {item?.status}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mt: '3px' }}>
                <Typography sx={{ fontSize: '8px' }}>
                  {dayjs(item?.createdAt).format('DD/MMM/YY')}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </CustomCard>
  );
};
export default TablePurchaseQrCodeMobile;
