import { createSlice } from '@reduxjs/toolkit';

export const notifHubState = createSlice({
  name: 'notifHub',
  initialState: {
    key: '',
    text: '',
  },
  reducers: {
    inputNotifHub: (state, action) => {
      const { key, text } = action.payload;
      state.key = key;
      state.text = text;
    },
    clearNotifHub: (state) => {
      state.key = '';
      state.text = '';
    },
  },
});

export const { inputNotifHub, clearNotifHub } = notifHubState.actions;
export const getNotifHub = (state) => state.notifHub;

export default notifHubState.reducer;
