import { Grid, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import CustomCard from '../../../../../components/shared/CustomCard';
import ChartSupplier from './Component/Chart';
import TableSupplier from './Component/Table';
import TableListSupplier from './Table';

const InventorySupplier = () => {
  const matchTablet = useMediaQuery('(min-width:832px');
  const dispatch = useDispatch();

  // ! grafik & table

  const [timeFrame, setTimeFrame] = useState('daily');

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);
  return (
    <>
      {!matchTablet ? (
        <>mobile view</>
      ) : (
        <Grid container columns={12} spacing={3}>
          {/* grafik */}
          <Grid item xs={12}>
            <CustomCard sx={{ mt: 2, p: 2 }}>
              <Grid container columns={12} spacing={4}>
                {/* grafik */}
                <Grid item xs={7}>
                  <ChartSupplier
                    {...{
                      timeFrame,
                      setTimeFrame,
                      dateRange,
                      setDateRange,
                      saveDateRange,
                      setSaveDateRange,
                    }}
                  />
                </Grid>

                {/* table */}
                <Grid item xs={5}>
                  <TableSupplier />
                </Grid>
              </Grid>
            </CustomCard>
          </Grid>

          {/* table */}
          <Grid item xs={12}>
            <TableListSupplier />
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default InventorySupplier;
