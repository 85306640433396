import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import CustomCard from '../../../../../components/shared/CustomCard';
import {
  ArrowBackIosIcon,
  ArrowForwardIosIcon,
  ArrowRightAltIcon,
  ExportIcon,
  FilterAltIcon,
  NoImage,
  SearchIcon,
  SyncAltIcon,
} from '../../../../../assets/Icons';
import { useState } from 'react';
import { useRef } from 'react';
import debounce from 'lodash.debounce';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDataAudit } from '../../../../../api/audit';
import { useEffect } from 'react';
import { changePageAudit } from '../../../../../store/Routing/auditRouting';
import {
  changeHubDetailAudit,
  changeIdDetailAudit,
} from '../../../../../store/audit/detailAudit';
import dayjs from 'dayjs';
import DialogFilterAudit from './DialogFilterAudit';
import { getDataFilterHubs } from '../../../../../store/audit/dataTableAudit';

const CustomTableCells = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '12px',
  textTransform: 'capitalize',
});

const TableListAudit = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [dataAudit, setDataAudit] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // !filter
  const hubs = useSelector(getDataFilterHubs);
  // !search
  const [search, setSearch] = useState('');
  const [purchaseKeyword, setPurchaseKeyword] = useState('');

  // ! table
  const [pages, setPages] = useState();
  const rowPerPageValues = [5, 10, 15, 20, 25];
  const [rowPerPage, setRowPerPage] = useState(rowPerPageValues[0]);
  const [currentPageTable, setCurrentPageTable] = useState(1);

  // ! sorting table
  const [sortTable, setSortTable] = useState('');
  const [asc, setAsc] = useState('asc');
  const [sortAscTable, setSortAscTable] = useState(true);

  const debounceOnChange = useRef(
    debounce((value) => {
      setPurchaseKeyword(value);
    }, 1000)
  ).current;

  // ! pagination
  const handleRowPerPage = (e) => {
    setRowPerPage(e.target.value);
    setCurrentPageTable(1);
  };
  const handleBackArrow = () => {
    setCurrentPageTable(
      currentPageTable <= 1 ? currentPageTable : currentPageTable - 1
    );
  };
  const handleForwardArrow = () => {
    setCurrentPageTable(
      currentPageTable >= Math.ceil(pages?.total / rowPerPage)
        ? currentPageTable
        : currentPageTable + 1
    );
  };
  const handleInputCurrentPage = (event) => {
    if (event.target.value >= Math.ceil(pages?.total / rowPerPage)) {
      setCurrentPageTable(Math.ceil(pages?.total / rowPerPage));
    } else if (event.target.value <= 1) {
      setCurrentPageTable(1);
    } else {
      setCurrentPageTable(event.target.value);
    }
  };

  // ! ---------------- fetch API ------------------
  const fetchAllDataAudits = async (params) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await getAllDataAudit(params);
      setDataAudit(data?.audits);
      setPages(data?.pageInfo);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let params = {};
    params.pageSize = rowPerPage;
    params.page = currentPageTable;
    params.startDate = '';
    params.endDate = '';
    // ! filter
    // params.dataHub = hubs;
    // ! search
    params.search = purchaseKeyword;
    // ! sorting
    params.id_sort = sortTable !== 'sortID' ? 'asc' : asc;
    params.sku_sort = sortTable !== 'sortSKU' ? 'asc' : asc;
    params.qty_sort = sortTable !== 'sortQty' ? 'asc' : asc;
    params.date_sort = sortTable !== 'sortDateTime' ? 'asc' : asc;

    fetchAllDataAudits(params);
  }, [purchaseKeyword, rowPerPage, currentPageTable, sortTable, asc, hubs]);
  //! -------------- TOOLBAR -------------------
  const [isFilter, setIsFilter] = useState(false);
  const toolbar = (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'row-reverse' }}>
      {/* export */}
      <CustomCard
        sx={{
          p: '10px 20px',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          cursor: 'pointer',
        }}
        onClick={() => setIsFilter(!isFilter)}
      >
        <ExportIcon sx={{ fontSize: '20px' }} />
        <Typography
          sx={{ fontSize: '14px', fontWeight: 500, color: '#000000' }}
        >
          Export
        </Typography>
      </CustomCard>
      {/* //* Filter */}
      <CustomCard
        sx={{
          p: '10px 20px',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          cursor: 'pointer',
        }}
        onClick={() => setIsFilter(!isFilter)}
      >
        <FilterAltIcon sx={{ fontSize: '20px' }} />
        <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}>
          Filter
        </Typography>
      </CustomCard>
      <DialogFilterAudit {...{ isFilter, setIsFilter }} />
    </Box>
  );

  return (
    <Box sx={{ p: 2 }}>
      {/* search */}
      <Grid container columnSpacing={4} alignItems="center">
        <Grid item xs>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              gap: 2,
            }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
              Audit
            </Typography>
            <TextField
              placeholder={'Search'}
              size="small"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                debounceOnChange(e.target.value);
              }}
              sx={{
                width: '250px',
                '& .MuiOutlinedInput-root': {
                  paddingLeft: '6px',
                  fontSize: '0.875rem',
                },
                bgcolor: '#FAFAFA',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ width: '18px' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>

        <Grid item xs="auto">
          {toolbar}
        </Grid>
      </Grid>

      {/* fetch ddata llist */}
      <Table
        sx={{
          marginTop: '12px',
          mb: 5,
        }}
      >
        <TableHead>
          <TableRow
            sx={{
              background: '#FAFAFA',
              fontWeight: 600,
            }}
          >
            <CustomTableCells>No</CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortID');
                  setSortAscTable(!sortAscTable);
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  ID
                </Typography>
                {sortTable === 'sortID' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
            <CustomTableCells>
              <Typography
                sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
              >
                Hub
              </Typography>
            </CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortSKU');
                  setSortAscTable(!sortAscTable);
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  SKU
                </Typography>
                {sortTable === 'sortSKU' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortQty');
                  setSortAscTable(!sortAscTable);
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  Difference
                </Typography>
                {sortTable === 'sortQty' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortDateTime');
                  setSortAscTable(!sortAscTable);
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  DateTime
                </Typography>
                {sortTable === 'sortDateTime' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
          </TableRow>
        </TableHead>
        {!isLoading && (
          <TableBody>
            {dataAudit?.map((item, index) => (
              <TableRow
                key={index}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(changeIdDetailAudit(item?.id));
                  dispatch(changeHubDetailAudit(item?.hub));
                  dispatch(changePageAudit('detailsAudits'));
                }}
              >
                <CustomTableCells>
                  {(currentPageTable - 1) * rowPerPage + (index + 1)}
                </CustomTableCells>
                <CustomTableCells>{item?.id}</CustomTableCells>
                <CustomTableCells>{item?.hub}</CustomTableCells>
                <CustomTableCells>{item?.sku}</CustomTableCells>
                <CustomTableCells
                  sx={{
                    color:
                      item?.auditQrs?.filter(
                        (item) => Number(item.difference) !== 0
                      ).length === 0
                        ? '#000000'
                        : '#F33A3A',
                  }}
                >
                  {
                    item?.auditQrs?.filter(
                      (item) => Number(item.difference) !== 0
                    ).length
                  }{' '}
                  SKU
                </CustomTableCells>
                <CustomTableCells>
                  {dayjs(item?.createdAt).format('YYYY/MM/DD, HH:mm')}
                </CustomTableCells>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>

      {!isLoading && dataAudit.length === 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <Typography sx={{ color: '#F33A3A' }}>No data found</Typography>
        </Box>
      )}

      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {/* pagination */}
      <Grid
        container
        direction="row"
        coloumn={12}
        alignItems="center"
        sx={{ mt: '30px' }}
      >
        <Grid item xs={6} sx={{ fontSize: '14px', fontWeight: '500' }}>
          Showing {pages?.currentPage * pages?.perPage - (pages?.perPage - 1)}{' '}
          to{' '}
          {pages?.currentPage * pages?.perPage >= pages?.total
            ? pages?.total
            : pages?.currentPage * pages?.perPage}{' '}
          of {pages?.total} entries
        </Grid>
        <Grid
          container
          item
          direction="row"
          xs={6}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item sx={{ fontSize: '14px', fontWeight: '500' }}>
            Rows Per Page :
            <Select
              value={rowPerPage}
              variant="filled"
              onChange={handleRowPerPage}
              sx={{
                fontSize: '14px',
                lineHeight: '-1px',
                '.MuiFilledInput-input': {
                  paddingTop: '7.5px',
                  paddingBottom: '7.5px',
                  paddingLeft: '10px',
                  paddingRight: '0',
                  borderRadius: '5px',
                },
                '&:before': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                '&:after': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                borderRadius: '5px',
                marginLeft: '8px',
              }}
            >
              {rowPerPageValues?.map((rowPerPageValue, idx) => (
                <MenuItem value={rowPerPageValue} key={idx}>
                  {rowPerPageValue}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item sx={{ ml: '35px' }}>
            <Box sx={{ display: 'inline', mr: '10px' }}>
              <IconButton sx={{ p: 0 }} onClick={handleBackArrow}>
                <ArrowBackIosIcon sx={{ color: '#323232', width: '16px' }} />
              </IconButton>
            </Box>
            <Box sx={{ display: 'inline', mr: '20px' }}>
              <OutlinedInput
                variant="outlined"
                type="number"
                value={currentPageTable}
                onChange={handleInputCurrentPage}
                sx={{
                  border: '1px solid #9E9D9D',
                  width: '36px',
                  borderRadius: '5px',
                  textAlign: 'center',
                  '& .MuiOutlinedInput-input': {
                    padding: '5px 8px 5px 8px',
                    fontSize: '0.875rem',
                    boxShadow: 'none',
                    textAlign: 'center',
                    fontWeight: 600,
                    width: '25px',
                  },
                  '&:before': {
                    borderRadius: '5px',
                    borderBottom: 0,
                  },
                  '&:after': {
                    borderRadius: '5px',
                    borderBottom: 0,
                  },
                  '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'inline',
                fontWeight: '600',
                fontSize: '14px',
                color: '#9E9D9D',
              }}
            >
              of {Math.ceil(pages?.total / pages?.perPage)}
            </Box>
            <Box sx={{ display: 'inline', ml: '10px' }}>
              <IconButton sx={{ p: 0 }} onClick={handleForwardArrow}>
                <ArrowForwardIosIcon sx={{ color: '#323232', width: '16px' }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default TableListAudit;
