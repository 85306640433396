import { alereAxios } from '../utils/api';

export const checkInvoice = (id) =>
  alereAxios.get(`stocks-out/check-invoice/${id}`);

export const executeInvoice = (id, params) =>
  alereAxios.post(`stocks-out/${id}`, params);

export const getHistoryStock = (id) =>
  alereAxios.get(`stocks-out/use-history/${id}`);

export const getProductUnitStockOut = (id) => alereAxios.get(`units/${id}`);
export const addProductStockOut = (id, params) =>
  alereAxios.post(`stocks-out/add-product/${id}`, params);

export const editProductStockOut = (id, params) =>
  alereAxios.post(`stocks-out/edit/${id}`, params);

export const getHubsStockOut = (id, params) =>
  alereAxios.post(`stocks-out/checkhub-execute/${id}`, params);

export const executeStockOut = (id, params) =>
  alereAxios.post(`stocks-out/${id}`, params);
