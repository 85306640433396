import { createSlice } from '@reduxjs/toolkit';

export const totalUnscannedState = createSlice({
  name: 'totalUnscanned',
  initialState: {
    total: 0,
  },
  reducers: {
    setTotalUnscanned: (state, action) => {
      state.total = action.payload;
    },
  },
});

export const { setTotalUnscanned } = totalUnscannedState.actions;

export const getTotalUnscanned = (state) => state.totalUnscanned.total;

//! ---------------- API ----------------
export default totalUnscannedState.reducer;
