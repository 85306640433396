import React, { useEffect, useState, useRef } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import BackButtonHub from "../../../../../../components/shared/BackButtonHub";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CustomCard from "../../../../../../components/shared/CustomCard";
import CustomSelect from "../../../../../../components/shared/CustomSelect";
import dayjs from "dayjs";
import CustomDateRange from "../../../../../../components/shared/CustomDateRange";
import MiniHubTable from "./MiniHubTable";
import { getAllMiniHub, getDetailSubHub } from "../../../../../../api/hubs";
import useHub from "../../../../../../hooks/useHub";
import { DataGrid } from "@mui/x-data-grid";
import BarChartIcon from "@mui/icons-material/BarChart";
import LineChartIcon from "@mui/icons-material/SsidChart";
import { Bar, Line } from "react-chartjs-2";
import TableListMiniHub from "./TableListMiniHub";
import { useDispatch, useSelector } from "react-redux";
import {
  changeIdToko,
  getIdToko,
} from "../../../../../../store/hub/miniHub/miniHubAdd";
import { getNameSubHubEdit } from "../../../../../../store/hub/subHub/subHubEdit";
import GrafikComparisonHub from "../Component/GrafikComparison";
import SubHub from "../SubHub";
import { changeIdMiniHub } from "../../../../../../store/hub/miniHub/miniHubDetails";
import { getAllDataMiniHub } from "../../../../../../store/hub/miniHub/miniHubData";

const datas = [
  { month: "August", hub1: 65, hub2: 35 },
  { month: "September", hub1: 59, hub2: 79 },
  { month: "October", hub1: 80, hub2: 40 },
  { month: "November", hub1: 81, hub2: 71 },
  { month: "December", hub1: 56, hub2: 56 },
  { month: "June", hub1: 55, hub2: 45 },
  { month: "July", hub1: 40, hub2: 50 },
];
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
  },
};
const state = {
  data: {
    labels: datas.map((d) => d.month),
    datasets: [
      {
        label: "Hub 1",
        backgroundColor: "rgba(000,255,132,0.2)",
        borderColor: "rgba(000,255,132,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(000,255,132,0.4)",
        hoverBorderColor: "rgba(000,255,132,1)",
        data: datas.map((d) => d.hub1),
      },
      {
        label: "Hub 2",
        backgroundColor: "rgba(000,000,255,0.2)",
        borderColor: "rgba(000,000,255,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(000,000,255,0.4)",
        hoverBorderColor: "rgba(000,000,255,1)",
        data: datas.map((d) => d.hub2),
      },
    ],
  },
};
// data dummy for mini table
const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "Hub", headerName: "Hub" },
  { field: "SKU", headerName: "SKU", type: "number" },
  {
    field: "COGS",
    headerName: "COGS",
    type: "number",
  },
];

const rows = [
  { id: 1, Hub: "Hub 1", SKU: 300, COGS: 3000 },
  { id: 2, Hub: "Hub 2", SKU: 300, COGS: 3000 },
  { id: 3, Hub: "Hub 3", SKU: 300, COGS: 3000 },
  { id: 4, Hub: "Hub 4", SKU: 300, COGS: 3000 },
  { id: 5, Hub: "Hub 5", SKU: 300, COGS: 3000 },
  { id: 6, Hub: "Hub 6", SKU: 300, COGS: 3000 },
  { id: 7, Hub: "Hub 7", SKU: 300, COGS: 3000 },
  { id: 8, Hub: "Hub 8", SKU: 300, COGS: 3000 },
  { id: 9, Hub: "Hub 9", SKU: 300, COGS: 3000 },
];

const MiniHub = ({
  rowSelected,
  setRowSelected,
  miniSubSelected,
  // setMiniSubSelected,
  setMiniSubSelected,
  setSubHubSelected,
  fetchMiniSubHub,
  minisidiaryDatas,
  setIsOpenMiniHub,
  setIsOpenSubHub,
  subsidiaryDatas,
  setFilterMinisidiaryArray,
  currentPageTableMiniHub,
  rowPerPageMiniHub,
  totalTableItemsMiniHub,
  setRowPerPageMiniHub,
  setCurrentPageMiniHub,
  miniHub,
  // isLoadingMiniHub,
  setIsOpenAddNewMiniHub,
  setMiniHubDetailSelected,
  debounceMiniHubKeyword,
}) => {
  const [defaultGrafick, setDefaultGrafik] = useState("line");
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);
  const subHubIdRef = useRef(searchParams.get("minihub-overview") || null);

  const [subHubSelect, setSubHubSelect] = useState("daily");
  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(1, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(1, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);

  // fetch all data mini hub
  const [miniHubData, setMiniHubData] = useState([]);

  const [sortTableMiniHub, setSortTableMiniHub] = useState("name");
  const [isSortAscMiniHub, setIsSortAscMiniHub] = useState(true);
  const rowPerPageValues = [5, 10, 15, 20, 25];
  const [totalTableItems, setTotalTableItems] = useState(1);
  const [isLoadingMiniHub, setIsLoadingMiniHub] = useState(false);
  const [subHubKeyword, setSubHubKeyword] = useState("");
  const [currentPageTable, setCurrentPageTable] = useState(1);
  const [rowPerPageTable, setRowPerPageTable] = useState(rowPerPageValues[0]);
  const [filterSubsidiaryArray, setFilterSubsidiaryArray] = useState([]);

  useEffect(() => {
    const fetchAllMiniHub = async () => {
      const params = {};
      params.search = subHubKeyword;
      params.subsidiary_id = filterSubsidiaryArray;
      setIsLoadingMiniHub(true);
      try {
        const {
          data: {
            data: { subHubs },
          },
        } = await getAllMiniHub(params);
        setMiniHubData(subHubs);
        setIsLoadingMiniHub(false);
        // setTotalTableItems(pageInfo.total);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoadingMiniHub(false);
      }
    };

    fetchAllMiniHub();
  }, [subHubKeyword, filterSubsidiaryArray]);
  // ---------- end -------

  // fetch deatail sub hub
  const [subHubDetailData, setSubHubDetailData] = useState(null);
  const dispatch = useDispatch();
  const idToko = useSelector(getIdToko);
  dispatch(changeIdToko(subHubIdRef.current));

  useEffect(() => {
    const fetchSubHubDetail = async () => {
      try {
        const {
          data: { data },
        } = await getDetailSubHub(idToko);
        setSubHubDetailData(data);
        // setMiniHubData({
        //   subHubName: subHubDetailData?.name,
        //   subHubId: subHubDetailData?.id,
        //   subsidiary: subHubDetailData?.store?.subsidiary,
        //   masterHubName: subHubDetailData?.store?.namaToko,
        // });
      } catch (err) {
        console.error(err);
      }
    };
    fetchSubHubDetail();
  }, [idToko]);
  // ------ end --------

  return (
    <>
      <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <BackButtonHub
            onClick={() => {
              setMiniSubSelected(null);
              updatedSearchParams.delete("minihub-overview");
              setSubHubSelected(subHubDetailData?.idToko);
              updatedSearchParams.set(
                "subhub-overview",
                subHubDetailData?.idToko
              );
              setSearchParams(updatedSearchParams);
            }}
          />
          <Typography
            sx={{ color: "#9E9D9D", fontSize: "1.25rem", fontWeight: 600 }}
          >
            Master Hub
          </Typography>
          <ArrowForwardIosIcon
            sx={{ color: "#9E9D9D", fontSize: "1rem", fontWeight: 600 }}
          />
          <Typography
            sx={{ color: "#9E9D9D", fontSize: "1.25rem", fontWeight: 600 }}
          >
            {subHubDetailData?.store?.namaToko}
          </Typography>
          <ArrowForwardIosIcon
            sx={{ color: "#9E9D9D", fontSize: "1rem", fontWeight: 600 }}
          />
          <Typography
            sx={{ color: "#000000", fontSize: "1.25rem", fontWeight: 600 }}
          >
            {subHubDetailData?.name}
          </Typography>
        </Box>

        {/* grafik & table */}
        <GrafikComparisonHub />

        <Box sx={{ mt: 4 }}>
          {/* <MiniHubTable
              totalTableItems={totalTableItemsMiniHub}
              setRowPerPage={setRowPerPageMiniHub}
              rowPerPageValues={rowPerPageValues}
              setCurrentPageTable={setCurrentPageMiniHub}
              rowPerPage={rowPerPageMiniHub}
              currentPageTable={currentPageTableMiniHub}
              miniSubSelected={miniSubSelected}
              setMiniSubSelected={setMiniSubSelected}
              miniHubTable={miniHub}
              setIsOpenAddNewMiniHub={setIsOpenAddNewMiniHub}
              setMiniHubDetailSelected={setMiniHubDetailSelected}
              miniHubKeyword={miniHubKeyword}
              setMiniHubKeyword={setMiniHubKeyword}
              debounceMiniHubKeyword={debounceMiniHubKeyword}
              sortTableMiniHub={sortTableMiniHub}
              setSortTableMiniHub={setSortTableMiniHub}
              isSortAscMiniHub={isSortAscMiniHub}
              setIsSortAscMiniHub={setIsSortAscMiniHub}
              isLoadingMiniHub={isLoadingMiniHub}
              subHubId={subHubIdRef.current}
            /> */}

          <TableListMiniHub
            rowSelected={rowSelected}
            setRowSelected={setRowSelected}
            hubsData={miniHubData}
            setHubsData={setMiniHubData}
            rowPerPageTable={rowPerPageTable}
            setRowPerPageTable={setRowPerPageTable}
            rowPerPageValues={rowPerPageValues}
            currentPageTable={currentPageTable}
            setCurrentPageTable={setCurrentPageTable}
            totalTableItems={totalTableItems}
            setTotalTableItems={setTotalTableItems}
            setIsOpenMiniHub={setIsOpenMiniHub}
            setIsOpenAddNewMiniHub={setIsOpenAddNewMiniHub}
            setSubHubKeyword={setSubHubKeyword}
            setFilterSubsidiaryArray={setFilterSubsidiaryArray}
            subsidiaryDatas={subsidiaryDatas}
            // subHubSelected={subHubSelected}
            setMiniSubSelected={setMiniSubSelected}
            setMiniHubDetailSelected={setMiniHubDetailSelected}
            minisidiaryDatas={minisidiaryDatas}
            setFilterMinisidiaryArray={setFilterMinisidiaryArray}
            isLoadingMiniHub={isLoadingMiniHub}
            sortTableMiniHub={sortTableMiniHub}
            miniSubSelected={miniSubSelected}
            setSortTableMiniHub={setSortTableMiniHub}
            isSortAscMiniHub={isSortAscMiniHub}
            setIsSortAscMiniHub={setIsSortAscMiniHub}
          />
        </Box>
      </Box>
    </>
  );
};

export default MiniHub;
