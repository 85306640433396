import { createSlice } from '@reduxjs/toolkit';

export const addAdjustmentState = createSlice({
  name: 'addAdjustment',
  initialState: {
    datas: [],
    newDataToSelect: [],
    idDetailAudit: null,
    locatonAudit: '',
    notes: '',
    idQrToAdjustment: null,

    cekSelected: [],
  },
  reducers: {
    goToAddAdjustment: (state, action) => {
      state.datas = action.payload;
    },
    addNewItemAdjustment: (state, action) => {
      state.datas = [...state.datas, action.payload];
    },
    loadNewDataToSelect: (state, action) => {
      state.newDataToSelect = action.payload;
    },
    changeIdAuditDetailToAdjustment: (state, action) => {
      state.idDetailAudit = action.payload;
    },
    changeLocationAuditToAdjustment: (state, action) => {
      state.locatonAudit = action.payload;
    },
    changeAddNotesAdjustment: (state, action) => {
      state.notes = action.payload;
    },
    changeIdQrToAdjustment: (state, action) => {
      state.idQrToAdjustment = action.payload;
    },
  },
});

export const {
  goToAddAdjustment,
  addNewItemAdjustment,
  loadNewDataToSelect,
  changeIdAuditDetailToAdjustment,
  changeLocationAuditToAdjustment,
  changeAddNotesAdjustment,
  changeIdQrToAdjustment,
  changeCekSelected,
} = addAdjustmentState.actions;

export const getDataToAddAdjustment = (state) => state.addAdjustment;
export const getNewDataToSelect = (state) =>
  state.addAdjustment.newDataToSelect;
export const getDataSelectedAdjustment = (state) => state.addAdjustment.datas;
export const getIdDetailAuditToAdjustment = (state) =>
  state.addAdjustment.idDetailAudit;
export const getlocationDetailAuditToAdjustment = (state) =>
  state.addAdjustment.locatonAudit;
export const getNotesAddAdjustment = (state) => state.addAdjustment.notes;
export const getIdQrAddAdjustment = (state) =>
  state.addAdjustment.idQrToAdjustment;

export const getCekSelected = (state) => state.addAdjustment.cekSelected;
export default addAdjustmentState.reducer;
