import { createSlice } from '@reduxjs/toolkit';

export const masterHubRackEditState = createSlice({
  name: 'masterHubRackEdit',
  initialState: {
    rackSelected: [],
    name: '',
    width: 0,
    length: 0,
    height: 0,
    weight: 0,
    photo: [],
    video: [],
    description: '',
    row: 0,
    column: 0,
  },
  reducers: {
    editRack: (state, action) => {
      const {
        rackSelected,
        name,
        width,
        length,
        height,
        weight,
        photo,
        video,
        description,
        row,
        column,
      } = action.payload;

      state.rackSelected = rackSelected;
      state.name = name;
      state.width = width;
      state.length = length;
      state.height = height;
      state.weight = weight;
      state.photo = photo;
      state.video = video;
      state.description = description;
      state.row = row;
      state.column = column;
    },
    changeName: (state, action) => {
      state.name = action.payload;
    },
    changeWidth: (state, action) => {
      state.width = action.payload;
    },
    changeHeight: (state, action) => {
      state.height = action.payload;
    },
    changeLength: (state, action) => {
      state.length = action.payload;
    },
    changeWeight: (state, action) => {
      state.weight = action.payload;
    },
    changeDescription: (state, action) => {
      state.description = action.payload;
    },
    addPhoto: (state, action) => {
      state.photo = [...state.photo, action.payload];
    },
    deletePhoto: (state, action) => {
      state.photo = state.photo.filter((item) => item !== action.payload);
    },
    addVideo: (state, action) => {
      state.video = [...state.video, action.payload];
    },
    deleteVideo: (state, action) => {
      state.video = state.video.filter((item) => item !== action.payload);
    },
  },
});

export const {
  editRack,
  changeName,
  changeWidth,
  changeHeight,
  changeLength,
  changeWeight,
  changeDescription,
  addPhoto,
  deletePhoto,
  addVideo,
  deleteVideo,
} = masterHubRackEditState.actions;

export const getAllMasterHubRackEditState = (state) => state.masterHubRackEdit;

export default masterHubRackEditState.reducer;
