import { createSlice } from "@reduxjs/toolkit";
import { getDataPurchaseRequestList } from "../../../api/purchase";

export const getPurchseRequestState = createSlice({
  name: "purchaseRequest",
  initialState: {
    isLoadingPurchase: false,
    subsidiary: "",
    datas: [],
    startDateFilter: "",
    endDateFilter: "",
    pageInfo: null,
    sortTable: "",
    isSortAsc: false,
  },
  reducers: {
    changeIsLoadingPurchase: (state, action) => {
      state.isLoadingPurchase = action.payload;
    },
    loadDatasPurchaseRequest: (state, action) => {
      state.datas = action.payload;
    },
    changeSubsidiaryDetailRequest: (state, action) => {
      state.subsidiary = action.payload;
    },
    changeStartDateFilterRequest: (state, action) => {
      state.startDateFilter = action.payload;
    },
    changeEndDateFilterRequest: (state, action) => {
      state.endDateFilter = action.payload;
    },
    changePageInfo: (state, action) => {
      state.pageInfo = action.payload;
    },
    changeSortTableRequestPurchase: (state, action) => {
      state.sortTable = action.payload;
    },
    changeIsSortAscRequestPurchase: (state, action) => {
      state.isSortAsc = action.payload;
    },
  },
});

export const {
  loadDatasPurchaseRequest,
  changeIsLoadingPurchase,
  changeSubsidiaryDetailRequest,
  changeStartDateFilterRequest,
  changeEndDateFilterRequest,
  changePageInfo,
  changeSortTableRequestPurchase,
  changeIsSortAscRequestPurchase,
} = getPurchseRequestState.actions;

export const getAllDataPurchaseRequest = (state) => state.purchaseRequest.datas;
export const getIsLoadingPurchase = (state) =>
  state.purchaseRequest.isLoadingPurchase;
export const getSubsidiaryDetailRequest = (state) =>
  state.purchaseRequest.subsidiary;
export const getStartDateFilterFilter = (state) =>
  state.purchaseRequest.startDateFilter;
export const getEndDateFilterFilter = (state) =>
  state.purchaseRequest.endDateFilter;
export const getPageInfoPurchaseRequest = (state) =>
  state.purchaseRequest.pageInfo;
export const getSortTableRequestPurchase = (state) =>
  state.purchaseRequest.sortTable;
export const getIsSortAscRequestPurchase = (state) =>
  state.purchaseRequest.isSortAsc;

// API

export const getListPurchaseRequest = (params) => (dispatch) => {
  const fetchData = async () => {
    dispatch(changeIsLoadingPurchase(true));
    try {
      const {
        data: { data },
      } = await getDataPurchaseRequestList(params);
      dispatch(changePageInfo(data.pageInfo));
      dispatch(loadDatasPurchaseRequest(data.purchases));
      dispatch(changeIsLoadingPurchase(false));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoadingPurchase(false));
    }
  };
  fetchData();
};

export default getPurchseRequestState.reducer;
