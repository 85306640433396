import { createSlice } from '@reduxjs/toolkit';
import { getDetailSubHub } from '../../../api/hubs';
import { uploadImage, uploadVideo } from '../../../api/storage';
import {
  getPlacesByCoordinate,
  getPlacesByName,
} from '../../../api/AddressMap';

export const SubHubEditState = createSlice({
  name: 'subHubEdit',
  initialState: {
    isEdit: false,
    subsidiary: null,
    subsidiaryId: null,
    isLoading: false,
    isLoadingUpdate: false,
    idSubHub: null,
    idMasterHub: null,
    name: '',
    status: false,
    dataSubHub: [],

    // image
    image: [],
    isMaxSizeImage: false,
    imagePreview: '',
    isImagePreview: false,
    isUploadImage: false,

    // video
    video: [],
    isMaxSizeVideo: false,
    isVideoPreview: false,
    videoPreview: '',
    isUploadVideo: false,

    // pic
    pic: '',
    picChoose: null,
    picSearch: '',
    picSearchDebounce: '',
    picPhone: '',

    // rack map
    isCreateMap: false,
    createMapPage: 'preview', //? -- grid, racks, preview --
    cellSelectedDetails: [],
    colBefore: 0,
    rowBefore: 0,
    col: 5,
    row: 5,
    cell: [],
    isEntrance: true,
    entrance: [],
    cellSelectedAll: [],
    cellSelected: [],
    cellSelectedEditTemporary: {},
    isCellSelectedEdit: false,
    cellSelectedMoment: [],
    isDoneEdit: true,
    cellSelectedNew: [],
    cellSelectedDetailsNew: [],
    cellSelectedNewTemporary: {},
    rackDeletes: [],

    // *--Address
    mapInputType: 'manual',
    coordinate: [],
    center: [3.546728899138594, 98.69014558305145],
    placesSearchByName: [],
    placesSearchByNameLoading: false,
    loadPlaceSearchByName: [],
    placeGetByName: null,
    placeGetByCoordinate: null,
    placeGetByCoordinateLoading: false,
    address: '',
    addressFix: '',
    coordinateFix: [],
    errorEmpty: '',
  },
  reducers: {
    changeIsLoadingUpdate: (state, action) => {
      state.isLoadingUpdate = action.payload;
    },
    goToSubHubEdit: (state, action) => {
      const {
        idMasterHub,
        name,
        image,
        video,
        pic,
        picPhone,
        address,
        subsidiary,
        subsidiaryId,
        coordinate,
        citydistrict,
        typeMap,
        col,
        row,
        rackSelected,
        rackDetails,
        entrance,
        status,
      } = action.payload;
      state.idMasterHub = idMasterHub;
      state.name = name;
      state.address = address;
      state.image = image !== null ? image.split(',') : [];
      state.video = video !== null ? video.split(',') : [];
      state.picChoose = pic;
      state.picPhone = picPhone;
      state.status = status;
      state.entrance = entrance;
      state.subsidiary = subsidiary;
      state.subsidiaryId = subsidiaryId;
      state.coordinate = coordinate;
      state.coordinateFix = coordinate;
      state.center = coordinate;
      state.mapInputType = typeMap;
      const manualInput = {
        search: '',
        state: '',
        city: '',
        postcode: '',
        district: '',
        lon: coordinate[1],
        lat: coordinate[0],
        formatted: citydistrict,
      };
      const mapInput = {
        state: '',
        city: '',
        postcode: '',
        district: '',
        lon: coordinate[1],
        lat: coordinate[0],
        formatted: citydistrict,
      };
      if (typeMap === 'manual') {
        state.placeGetByName = manualInput;
      } else {
        state.placeGetByCoordinate = mapInput;
      }
      let newCell = [...Array(row)].map((_, idxr) => {
        let v = [...Array(col)].map((_, idxc) => {
          return `r${idxr + 1}c${idxc + 1}`;
        });
        return v;
      });

      state.cell = newCell;
      state.colBefore = col;
      state.rowBefore = row;
      state.col = col;
      state.row = row;
      state.cellSelected = rackSelected;
      state.cellSelectedDetails = rackDetails;
      state.isEdit = true;
    },
    changeIdSubHubEdit: (state, action) => {
      state.idSubHub = action.payload;
    },
    changeIsEdit: (state) => {
      state.isEdit = !state.isEdit;
    },
    changeNameEdit: (state, action) => {
      state.name = action.payload;
    },
    changeStatus: (state) => {
      state.status = !state.status;
    },
    changeSubsidiaryIdSubHub: (state, action) => {
      state.subsidiaryId = action.payload;
    },

    // image
    changeIsUploadImage: (state, action) => {
      state.isUploadImage = action.payload;
    },
    inputImageEdit: (state, action) => {
      state.image = [...state.image, action.payload];
    },
    deleteImage: (state, action) => {
      state.image = state.image.filter((item) => item !== action.payload);
    },
    changeImagePreviewEdit: (state, action) => {
      state.imagePreview = action.payload;
    },
    clickIsPreviewImageEdit: (state) => {
      state.isImagePreview = !state.isImagePreview;
    },
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    loadDetailDataSubHub: (state, action) => {
      state.dataSubHub = action.payload;
    },
    changeIsMaxSizeImageEdit: (state, action) => {
      state.isMaxSizeImage = action.payload;
    },

    // video
    changeIsUploadVideo: (state, action) => {
      state.isUploadVideo = action.payload;
    },
    inputVideoEdit: (state, action) => {
      state.video = [...state.video, action.payload];
    },
    deleteVideoEdit: (state, action) => {
      state.video = state.video.filter((item) => item !== action.payload);
    },
    changeIsMaxSizeVideoEdit: (state, action) => {
      state.isMaxSizeVideo = action.payload;
    },
    changeVideoPreviewEdit: (state, action) => {
      state.videoPreview = action.payload;
    },
    clickIsVideoPreviewEdit: (state, action) => {
      state.isVideoPreview = !state.isVideoPreview;
    },

    // add PIC
    changePicChooseEdit: (state, action) => {
      state.picChoose = action.payload;
    },
    changePicSearchEdit: (state, action) => {
      state.picSearch = action.payload;
    },
    changePicSearchDebounceEdit: (state, action) => {
      state.picSearchDebounce = action.payload;
    },
    changePicPhoneEdit: (state, action) => {
      state.picPhone = action.payload;
    },

    // Address Map
    changeMapType: (state, action) => {
      state.mapInputType = action.payload;
    },
    loadPlaceSearchByName: (state, action) => {
      state.placesSearchByName = action.payload;
    },
    changePlaceGetByName: (state, action) => {
      let result = action.payload;
      state.placeGetByName = result;
      state.coordinate = [result.lat, result.lon];
      state.center = [result.lat, result.lon];
    },
    changeAddress: (state, action) => {
      let result = action.payload;
      state.address = result;
    },
    submitAddresMap: (state, action) => {
      const { address, coordinate } = action.payload;
      state.addressFix = address;
      state.coordinateFix = coordinate;
    },
    loadPlaceSearchByNameLoading: (state, action) => {
      state.placesSearchByNameLoading = action.payload;
    },
    changePlaceGetByCoordinate: (state, action) => {
      let result = action.payload;
      state.placeGetByCoordinate = result;
    },
    changeIsPlaceGetByCoordinateLoading: (state, action) => {
      let result = action.payload;
      state.placeGetByCoordinateLoading = result;
    },

    // Rack Map
    changeCenter: (state, action) => {
      state.center = action.payload;
    },
    changeCoordinate: (state, action) => {
      state.coordinate = action.payload;
    },
    changeIsCreateMap: (state) => {
      state.isCreateMap = !state.isCreateMap;
    },
    changeCreateMapPage: (state, action) => {
      state.createMapPage = action.payload;
    },
    addNewRack: (state, action) => {
      const { columnPosition, details, id } = action.payload;
      const detail = {
        id_toko: id,
        subHubId: null,
        miniHubId: null,
        name: details.name,
        images: '',
        videos: '',
        column: details.column,
        row: details.row,
        columnPosition: columnPosition.join(','),
        description: details.description,
        width: 0.0,
        height: 0.0,
        length: 0.0,
        weight: 0,
      };

      state.cellSelectedNew = [...state.cellSelectedNew, columnPosition];
      state.cellSelectedDetailsNew = [...state.cellSelectedDetailsNew, detail];
    },
    inputRackTemporary: (state, action) => {
      const { columnPosition } = action.payload;
      let cell = columnPosition.split(',');
      let cellAvoid = state.cellSelected.filter(
        (val) => val.filter((x) => cell.includes(x)).length === 0
      );
      state.cellSelectedEditTemporary = action.payload;
      state.cellSelected = cellAvoid;
      state.cellSelectedDetails = state.cellSelectedDetails.filter(
        (item) => item.columnPosition !== columnPosition
      );
      state.isDoneEdit = false;
    },
    cancelRackTemporary: (state) => {
      let CellBack = state.cellSelectedEditTemporary;
      state.cellSelectedDetails = [...state.cellSelectedDetails, CellBack];
      state.cellSelected = [
        ...state.cellSelected,
        CellBack.columnPosition.split(','),
      ];
    },
    saveRackTemporary: (state, action) => {
      const { name, description, columnPosition } = action.payload;
      const rackTempo = state.cellSelectedEditTemporary;
      const Rack = {
        id: rackTempo.id,
        name: name,
        column: rackTempo.column,
        row: rackTempo.row,
        columnPosition: columnPosition,
        description: description,
      };
      const CellTempo = columnPosition.split(',');

      state.cellSelectedDetails = [...state.cellSelectedDetails, Rack];
      state.cellSelected = [...state.cellSelected, CellTempo];
    },
    deleteCellFix: (state, action) => {
      const { index, id } = action.payload;
      state.cellSelected = state.cellSelected.filter((_, idx) => idx !== index);
      state.cellSelectedDetails = state.cellSelectedDetails.filter(
        (_, idx) => idx !== index
      );
      state.rackDeletes = [...state.rackDeletes, id];
    },

    inputNewRackTemporary: (state, action) => {
      const { columnPosition } = action.payload;
      let cell = columnPosition.split(',');
      let cellAvoid = state.cellSelectedNew.filter(
        (val) => val.filter((x) => cell.includes(x)).length === 0
      );
      state.cellSelectedNewTemporary = action.payload;
      state.cellSelectedNew = cellAvoid;
      state.cellSelectedDetailsNew = state.cellSelectedDetailsNew.filter(
        (item) => item.columnPosition !== columnPosition
      );
    },
    cancelNewRackTemporary: (state) => {
      let CellBack = state.cellSelectedNewTemporary;
      state.cellSelectedDetailsNew = [
        ...state.cellSelectedDetailsNew,
        CellBack,
      ];
      state.cellSelectedNew = [
        ...state.cellSelectedNew,
        CellBack.columnPosition.split(','),
      ];
    },
    saveNewRackTemporary: (state, action) => {
      const { id, columnPosition, data } = action.payload;
      const { name, description, row, column } = data;
      const Rack = {
        id_toko: id,
        subHubId: null,
        miniHubId: null,
        name: name,
        images: '',
        videos: '',
        column: column,
        row: row,
        columnPosition: columnPosition.join(','),
        description: description,
        width: 0.0,
        height: 0.0,
        length: 0.0,
        weight: 0,
      };
      // const CellTempo = columnPosition.split(',');
      state.cellSelectedDetailsNew = [...state.cellSelectedDetailsNew, Rack];
      state.cellSelectedNew = [...state.cellSelectedNew, columnPosition];
    },
    deleteCellNewFix: (state, action) => {
      state.cellSelectedNew = state.cellSelectedNew.filter(
        (_, idx) => idx !== action.payload
      );
      state.cellSelectedDetailsNew = state.cellSelectedDetailsNew.filter(
        (_, idx) => idx !== action.payload
      );
    },
    changeIsEntrance: (state) => {
      if (state.isEntrance) {
        state.entrance = [];
      }
      state.isEntrance = !state.isEntrance;
    },
    inputEntrance: (state, action) => {
      let value = action.payload;
      if (state.entrance.includes(value)) {
        state.entrance = state.entrance.filter((item) => item != value);
      } else {
        state.entrance = [...state.entrance, value];
      }
    },

    // !------------- Add Col or Row Map -----------------
    setUpCell: (state) => {
      let nilai = [...Array(state.row)].map((_, idxr) => {
        let v = [...Array(state.col)].map((_, idxc) => {
          return `r${idxr + 1}c${idxc + 1}`;
        });
        return v;
      });
      state.cell = nilai;
    },
    changeColCreateMap: (state, action) => {
      if (action.payload <= 50 && action.payload >= state.colBefore) {
        state.col = action.payload;
      }
    },
    changeRowCreateMap: (state, action) => {
      if (action.payload <= 50 && action.payload >= state.rowBefore) {
        state.row = action.payload;
      }
    },
    addColCreateMapRight: (state) => {
      if (state.col < 52 && state.col > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row)].map((_, idxr) => {
          let v = [...Array(state.col + 1)].map((_, idxc) => {
            return `r${idxr + 1}c${idxc + 1}`;
          });
          return v;
        });

        state.col = state.col + 1;
        state.cell = nilai;
      }
    },
    reduceColCreateMapRight: (state) => {
      if (state.col <= 52 && state.col > 1) {
        let limit = `c${state.col - 1}`;
        let allBefore = state.cellSelected.flat();
        let allAfter = state.cellSelectedNew.flat();
        let all = [...allBefore, ...allAfter];
        if (all.filter((item) => item.includes(limit)).length === 0) {
          state.entrance = [];
          let nilai = [...Array(state.row)].map((_, idxr) => {
            let v = [...Array(state.col - 1)].map((_, idxc) => {
              return `r${idxr + 1}c${idxc + 1}`;
            });
            return v;
          });

          state.col = state.col - 1;
          state.cell = nilai;
        }
      }
    },
    addColCreateMapLeft: (state) => {
      if (state.col < 52 && state.col > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row)].map((_, idxr) => {
          let v = [...Array(state.col + 1)].map((_, idxc) => {
            return `r${idxr + 1}c${idxc + 1}`;
          });
          return v;
        });
        state.col = state.col + 1;
        state.cell = nilai;
        let dataCell = state.cellSelected.map((item) => {
          let dataChild = item.map((v) => {
            let child = v.split('r')[1].split('c');
            let childValue = `r${Number(child[0])}c${Number(child[1]) + 1}`;
            return childValue;
          });
          return dataChild;
        });
        let dataCellDetails = state.cellSelectedDetails.map((item) => {
          let position = item.columnPosition.split(',');
          let child = position.map((v) => {
            let cols = v.split('r')[1].split('c');
            let colsValue = `r${Number(cols[0])}c${Number(cols[1]) + 1}`;
            return colsValue;
          });
          return {
            ...item,
            columnPosition: child.join(','),
          };
        });
        // * new
        let dataCellNew = state.cellSelectedNew.map((item) => {
          let dataChild = item.map((v) => {
            let child = v.split('r')[1].split('c');
            let childValue = `r${Number(child[0])}c${Number(child[1]) + 1}`;
            return childValue;
          });
          return dataChild;
        });
        let dataCellDetailsNew = state.cellSelectedDetailsNew.map((item) => {
          let position = item.columnPosition.split(',');
          let child = position.map((v) => {
            let cols = v.split('r')[1].split('c');
            let colsValue = `r${Number(cols[0])}c${Number(cols[1]) + 1}`;
            return colsValue;
          });
          return {
            ...item,
            columnPosition: child.join(','),
          };
        });
        // let dataCellMoment = state.cellSelectedMoment.map((item) => {
        //   let child = item.split('r')[1].split('c');
        //   let childValue = `r${Number(child[0])}c${Number(child[1]) + 1}`;
        //   return childValue;
        // });
        // state.cellSelectedMoment = dataCellMoment;
        state.cellSelected = dataCell;
        state.cellSelectedDetails = dataCellDetails;

        state.cellSelectedNew = dataCellNew;
        state.cellSelectedDetailsNew = dataCellDetailsNew;
      }
    },
    reduceColCreateMapLeft: (state) => {
      if (state.col <= 52 && state.col > 1) {
        let allBefore = state.cellSelected.flat();
        let allAfter = state.cellSelectedNew.flat();
        let all = [...allBefore, ...allAfter];
        if (
          all.filter((item) => item.split('r')[1].split('c')[1] === '2')
            .length === 0
        ) {
          state.entrance = [];
          let nilai = [...Array(state.row)].map((_, idxr) => {
            let v = [...Array(state.col - 1)].map((_, idxc) => {
              return `r${idxr + 1}c${idxc + 1}`;
            });
            return v;
          });
          state.col = state.col - 1;
          state.cell = nilai;
          let dataCell = state.cellSelected.map((item) => {
            let dataChild = item.map((v) => {
              let child = v.split('r')[1].split('c');
              let childValue = `r${Number(child[0])}c${Number(child[1]) - 1}`;
              return childValue;
            });
            return dataChild;
          });
          let dataCellDetails = state.cellSelectedDetails.map((item) => {
            let position = item.columnPosition.split(',');
            let child = position.map((v) => {
              let cols = v.split('r')[1].split('c');
              let colsValue = `r${Number(cols[0])}c${Number(cols[1]) - 1}`;
              return colsValue;
            });
            return {
              ...item,
              columnPosition: child.join(','),
            };
          });
          let dataCellNew = state.cellSelectedNew.map((item) => {
            let dataChild = item.map((v) => {
              let child = v.split('r')[1].split('c');
              let childValue = `r${Number(child[0])}c${Number(child[1]) - 1}`;
              return childValue;
            });
            return dataChild;
          });
          let dataCellDetailsNew = state.cellSelectedDetailsNew.map((item) => {
            let position = item.columnPosition.split(',');
            let child = position.map((v) => {
              let cols = v.split('r')[1].split('c');
              let colsValue = `r${Number(cols[0])}c${Number(cols[1]) - 1}`;
              return colsValue;
            });
            return {
              ...item,
              columnPosition: child.join(','),
            };
          });
          // let dataCellMoment = state.cellSelectedMoment.map((item) => {
          //   let child = item.split('r')[1].split('c');
          //   let childValue = `r${Number(child[0])}c${Number(child[1]) - 1}`;
          //   return childValue;
          // });
          // state.cellSelectedMoment = dataCellMoment;
          state.cellSelected = dataCell;
          state.cellSelectedDetails = dataCellDetails;

          state.cellSelectedNew = dataCellNew;
          state.cellSelectedDetailsNew = dataCellDetailsNew;
        }
      }
    },
    addRowCreateMapTop: (state) => {
      if (state.row < 52 && state.row > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row + 1)].map((_, idxr) => {
          let v = [...Array(state.col)].map((_, idxc) => {
            return `r${idxr + 1}c${idxc + 1}`;
          });
          return v;
        });
        state.row = state.row + 1;
        state.cell = nilai;
        let dataCell = state.cellSelected.map((item) => {
          let dataChild = item.map((v) => {
            let child = v.split('r')[1].split('c');
            let childValue = `r${Number(child[0]) + 1}c${Number(child[1])}`;
            return childValue;
          });
          return dataChild;
        });
        let dataCellDetails = state.cellSelectedDetails.map((item) => {
          let position = item.columnPosition.split(',');
          let child = position.map((v) => {
            let cols = v.split('r')[1].split('c');
            let colsValue = `r${Number(cols[0]) + 1}c${Number(cols[1])}`;
            return colsValue;
          });
          return {
            ...item,
            columnPosition: child.join(','),
          };
        });
        let dataCellNew = state.cellSelectedNew.map((item) => {
          let dataChild = item.map((v) => {
            let child = v.split('r')[1].split('c');
            let childValue = `r${Number(child[0]) + 1}c${Number(child[1])}`;
            return childValue;
          });
          return dataChild;
        });
        let dataCellDetailsNew = state.cellSelectedDetailsNew.map((item) => {
          let position = item.columnPosition.split(',');
          let child = position.map((v) => {
            let cols = v.split('r')[1].split('c');
            let colsValue = `r${Number(cols[0]) + 1}c${Number(cols[1])}`;
            return colsValue;
          });
          return {
            ...item,
            columnPosition: child.join(','),
          };
        });

        state.cellSelected = dataCell;
        state.cellSelectedDetails = dataCellDetails;

        state.cellSelectedNew = dataCellNew;
        state.cellSelectedDetailsNew = dataCellDetailsNew;
      }
    },
    reduceRowCreateMapTop: (state) => {
      if (state.row <= 52 && state.row > 1) {
        let allBefore = state.cellSelected.flat();
        let allAfter = state.cellSelectedNew.flat();
        let all = [...allBefore, ...allAfter];
        if (
          all.filter((item) => item.split('r')[1].split('c')[0] === '2')
            .length === 0
        ) {
          state.entrance = [];
          let nilai = [...Array(state.row - 1)].map((_, idxr) => {
            let v = [...Array(state.col)].map((_, idxc) => {
              return `r${idxr + 1}c${idxc + 1}`;
            });
            return v;
          });
          state.row = state.row - 1;
          state.cell = nilai;

          let dataCell = state.cellSelected.map((item) => {
            let dataChild = item.map((v) => {
              let child = v.split('r')[1].split('c');
              let childValue = `r${Number(child[0]) - 1}c${Number(child[1])}`;
              return childValue;
            });
            return dataChild;
          });
          let dataCellDetails = state.cellSelectedDetails.map((item) => {
            let positon = item.columnPosition.split(',');
            let child = positon.map((v) => {
              let cols = v.split('r')[1].split('c');
              let colsValue = `r${Number(cols[0]) - 1}c${Number(cols[1])}`;
              return colsValue;
            });
            return {
              ...item,
              columnPosition: child.join(','),
            };
          });

          let dataCellNew = state.cellSelectedNew.map((item) => {
            let dataChild = item.map((v) => {
              let child = v.split('r')[1].split('c');
              let childValue = `r${Number(child[0]) - 1}c${Number(child[1])}`;
              return childValue;
            });
            return dataChild;
          });
          let dataCellDetailsNew = state.cellSelectedDetailsNew.map((item) => {
            let positon = item.columnPosition.split(',');
            let child = positon.map((v) => {
              let cols = v.split('r')[1].split('c');
              let colsValue = `r${Number(cols[0]) - 1}c${Number(cols[1])}`;
              return colsValue;
            });
            return {
              ...item,
              columnPosition: child.join(','),
            };
          });

          state.cellSelected = dataCell;
          state.cellSelectedDetails = dataCellDetails;

          state.cellSelectedNew = dataCellNew;
          state.cellSelectedDetailsNew = dataCellDetailsNew;
        }
      }
    },
    addRowCreateMapBottom: (state) => {
      if (state.row < 52 && state.row > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row + 1)].map((_, idxr) => {
          let v = [...Array(state.col)].map((_, idxc) => {
            return `r${idxr + 1}c${idxc + 1}`;
          });
          return v;
        });

        state.row = state.row + 1;
        state.cell = nilai;
      }
    },
    reduceRowCreateMapBottom: (state) => {
      if (state.row <= 50 && state.row > 1) {
        let limit = `r${state.row - 1}`;
        let allBefore = state.cellSelected.flat();
        let allAfter = state.cellSelectedNew.flat();
        let all = [...allBefore, ...allAfter];
        if (all.filter((item) => item.includes(limit)).length === 0) {
          state.entrance = [];
          let nilai = [...Array(state.row - 1)].map((_, idxr) => {
            let v = [...Array(state.col)].map((_, idxc) => {
              return `r${idxr + 1}c${idxc + 1}`;
            });
            return v;
          });
          let entranceNew = state.entrance.map((ent) => {
            let valueArr = ent.split('r')[1].split('c');
            return `r${Number(valueArr[0]) - 1}c${Number(valueArr[1])}`;
          });
          state.entrance = entranceNew;
          state.row = state.row - 1;
          state.cell = nilai;
        }
      }
    },
    changeErrorEmptyEditSubHub: (state, action) => {
      state.errorEmpty = action.payload;
    },

    clearData: (state) => {
      state.isEdit = false;
      state.subsidiaryId = null;
      state.isLoading = false;
      state.isLoadingUpdate = false;
      state.idSubHub = null;
      state.idMasterHub = null;
      state.name = '';
      state.status = false;
      state.dataSubHub = [];

      // image
      state.image = [];
      state.isMaxSizeImage = false;
      state.imagePreview = '';
      state.isImagePreview = false;

      // video
      state.video = [];
      state.isMaxSizeVideo = false;
      state.isVideoPreview = false;
      state.videoPreview = '';

      // pic
      state.pic = '';
      state.picChoose = null;
      state.picSearch = '';
      state.picSearchDebounce = '';
      state.picPhone = '';

      // rack map
      state.isCreateMap = true;
      state.createMapPage = 'preview'; //? -- grid, racks, preview -;
      state.cellSelectedDetails = [];
      state.col = 5;
      state.row = 5;
      state.cell = [];
      state.isEntrance = true;
      state.entrance = [];
      state.cellSelectedAll = [];
      state.cellSelected = [];
      state.cellSelectedEditTemporary = {};
      state.isCellSelectedEdit = false;
      state.cellSelectedMoment = [];
      state.isDoneEdit = true;
      state.cellSelectedNew = [];
      state.cellSelectedDetailsNew = [];
      state.cellSelectedNewTemporary = {};
      state.rackDeletes = [];

      // *--Address
      state.mapInputType = 'manual';
      state.coordinate = [];
      state.center = [3.546728899138594, 98.69014558305145];
      state.placesSearchByName = [];
      state.placesSearchByNameLoading = false;
      state.placeGetByName = null;
      state.placeGetByCoordinate = null;
      state.placeGetByCoordinateLoading = false;
      state.address = '';
      state.addressFix = '';
      state.coordinateFix = [];
      state.errorEmpty = '';
    },
  },
});

export const {
  changeIsLoadingUpdate,
  changeIdSubHubEdit,
  goToSubHubEdit,
  changeIsEdit,
  changeNameEdit,
  changeStatus,
  changeSubsidiaryIdSubHub,

  // image
  changeIsUploadImage,
  inputImageEdit,
  deleteImage,
  changeIsMaxSizeImageEdit,
  changeImagePreviewEdit,
  clickIsPreviewImageEdit,

  // video
  changeIsUploadVideo,
  inputVideoEdit,
  deleteVideoEdit,
  changeIsMaxSizeVideoEdit,
  changeVideoPreviewEdit,
  clickIsVideoPreviewEdit,

  // PIC
  changePicChooseEdit,
  changePicSearchEdit,
  changePicSearchDebounceEdit,
  changePicPhoneEdit,

  // Address Map
  changeMapType,
  changePlaceGetByName,
  loadPlaceSearchByName,
  changeAddress,
  submitAddresMap,
  loadPlaceSearchByNameLoading,
  changeIsPlaceGetByCoordinateLoading,
  changePlaceGetByCoordinate,

  // Rack Map
  setUpCell,
  changeColCreateMap,
  changeRowCreateMap,
  changeCoordinate,
  changeCenter,
  changeIsCreateMap,
  changeCreateMapPage,
  addNewRack,
  inputNewRackTemporary,
  cancelNewRackTemporary,
  saveNewRackTemporary,
  deleteCellNewFix,
  inputRackTemporary,
  deleteCellFix,
  saveRackTemporary,
  cancelRackTemporary,
  changeIsEntrance,
  addRowCreateMapTop,
  reduceRowCreateMapTop,
  addRowCreateMapBottom,
  reduceRowCreateMapBottom,
  addColCreateMapRight,
  reduceColCreateMapRight,
  addColCreateMapLeft,
  reduceColCreateMapLeft,
  inputEntrance,

  changeIsLoading,
  loadDetailDataSubHub,
  changeErrorEmptyEditSubHub,
  clearData,
} = SubHubEditState.actions;

export const getIdSubHubEdit = (state) => state.subHubEdit.idSubHub;
export const getNameSubHubEdit = (state) => state.subHubEdit.name;
export const getSubHubEditAll = (state) => state.subHubEdit;
export const getIsSubHubEdit = (state) => state.subHubEdit.isEdit;
export const getLoadingDetailSubHubEdit = (state) => state.subHubEdit.isLoading;
export const getDetailDataSubHubEdit = (state) => state.subHubEdit.dataSubHub;

// image
export const getSubHubImageEdit = (state) => state.subHubEdit.image;
export const getIsMaxSizeImageEdit = (state) => state.subHubEdit.isMaxSizeImage;
export const inputSubHubImageEdit = (e) => (dispatch) => {
  const img = e.target.files[0];
  if (img.size > 2000000) {
    dispatch(changeIsMaxSizeImageEdit(true));
  } else {
    dispatch(changeIsUploadImage(true));
    let datas = {
      image: img,
      resource: 'subHub',
    };
    uploadImage(datas).then((res) => {
      dispatch(inputImageEdit(res.data.data.path));
      dispatch(changeIsMaxSizeImageEdit(false));
      dispatch(changeIsUploadImage(false));
    });
  }
};

export const deleteSubHubImageEdit = (link) => (dispatch) => {
  dispatch(deleteImage(link));
};

// video
export const getVideoSubHubEdit = (state) => state.subHubEdit.video;
export const getIsMaxSizeVideoSubHubEdit = (state) =>
  state.subHubEdit.isMaxSizeVideo;
export const getVideoPreviewSubHubEdit = (state) =>
  state.subHubEdit.videoPreview;
export const getIsVideoPreviewSubHubEdit = (state) =>
  state.subHubEdit.isVideoPreview;

export const inputVideoPreviewEdit = (e) => (dispatch) => {
  const vd = e.target.files[0];
  if (vd.size > 1000000000) {
    dispatch(changeIsMaxSizeVideoEdit(true));
  } else {
    let datas = {
      video: vd,
      resource: 'subHub',
    };
    uploadVideo(datas).then((res) => {
      dispatch(inputVideoEdit(res.data.data.path));
      dispatch(changeIsMaxSizeVideoEdit(false));
    });
  }
};
// export const deleteVideoPreview = (link) => (dispatch) => {
//   deleteFile(link).then(() => {
//     dispatch(deleteVideo(link));
//   });
// };
export const deleteVideoPreviewEdit = (link) => (dispatch) => {
  dispatch(deleteVideoEdit(link));
};
export const inputTayangan = (e) => (dispatch) => {
  const vd = e.target.files[0];
  if (vd.size > 100000000) {
    dispatch(changeIsMaxSizeVideoEdit(true));
  } else {
    dispatch(changeIsUploadVideo(true));
    let datas = {
      video: vd,
      resource: 'subHub',
    };
    uploadVideo(datas).then((res) => {
      dispatch(inputVideoEdit(res.data.data.path));
      dispatch(changeIsMaxSizeVideoEdit(false));
      dispatch(changeIsUploadVideo(false));
    });
  }
};
export const deleteTayangan = (link) => (dispatch) => {
  // deleteFile(link).then(() => {
  //   dispatch(deleteVideo(link));
  // });
  dispatch(deleteVideoEdit(link));
};

// add PIC sub hub
export const getSubHubPic = (state) => state.subHubEdit.pic;
export const getPicChooseSubHubEdit = (state) => state.subHubEdit.picChoose;
export const getPicSearchSubHubEdit = (state) => state.subHubEdit.picSearch;
export const getPicSearchDebounceSubHubEdit = (state) =>
  state.subHubEdit.picSearchDebounce;
export const getPicPhoneSubHubEdit = (state) => state.subHubEdit.picPhone;

// API get detail sub hub
export const fetchDetailDataSubHubEdit = (id) => (dispatch) => {
  const fetchData = async () => {
    dispatch(changeIsLoading(true));
    try {
      const {
        data: { data },
      } = await getDetailSubHub(id);
      dispatch(loadDetailDataSubHub(data));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoading(false));
    }
  };
  fetchData();
};

export const fetchPlacesSearchByName = (name) => (dispatch) => {
  const fetchData = async () => {
    dispatch(loadPlaceSearchByNameLoading(true));
    try {
      const { data } = await getPlacesByName(name);
      dispatch(loadPlaceSearchByName(data));
    } catch (error) {
      dispatch(loadPlaceSearchByName([]));
    } finally {
      dispatch(loadPlaceSearchByNameLoading(false));
    }
  };

  fetchData();
};

export const fetchPlacesSearchByCoordinate = (coor) => (dispatch) => {
  const fetchData = async () => {
    dispatch(changeIsPlaceGetByCoordinateLoading(true));
    try {
      const { data } = await getPlacesByCoordinate(coor);
      dispatch(changePlaceGetByCoordinate(data));
    } catch (error) {
      dispatch(changePlaceGetByCoordinate([]));
    } finally {
      dispatch(changeIsPlaceGetByCoordinateLoading(false));
    }
  };

  fetchData();
};

export default SubHubEditState.reducer;
