import { Box, Grid } from '@mui/material';
import { useState, useEffect } from 'react';

import { LoadingComponent } from '../../../../../../../components/shared/shared2';
import BackButtonQrCodeMobile from './BackButtonQrCodeMobile';
import TablePurchaseQrCodeMobile from './TablePurchaseQrCodeMobile';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import ChartPurchaseMobile from '../Component/Chart';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { getListPurchaseRequest } from '../../../../../../../store/purchase/purchaseAdmin/purchaseRequest';
import { getAllDataChartPurchase } from '../../../../../../../store/purchase/dataTable';
import TablePurchaseDashboardMobile from '../Component/TablePurchase';
import { getIdPurchaseDetail } from '../../../../../../../store/purchase/detailPurchase';

const PurchaseQrCodeMobile = () => {
  const dispatch = useDispatch();
  const roleUser = localStorage.getItem('role');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const id = useSelector(getIdPurchaseDetail);

  // ! grafik
  const [timeFrame, setTimeFrame] = useState('daily');

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  const startDateFilter = dayjs(saveDateRange[0].startDate).format(
    'YYYY-MM-DD'
  );
  const endDateFilter = dayjs(saveDateRange[0].endDate).format('YYYY-MM-DD');

  useEffect(() => {
    let params = {};
    params.page = 1;
    params.pageSize = 100;
    dispatch(getListPurchaseRequest(params));
  }, []);

  // data chart
  useEffect(() => {
    let datas = {};
    datas.startDate = startDateFilter;
    datas.endDate = endDateFilter;
    datas.timeframe = timeFrame;
    dispatch(getAllDataChartPurchase(datas));
  }, [startDateFilter, endDateFilter, timeFrame]);
  return (
    <Box sx={{ mt: '16px' }}>
      {!isLoading && !isError && (
        <>
          <BackButtonQrCodeMobile roleUser={roleUser} />
          <Grid
            container
            columns={12}
            spacing={3}
            sx={{ mt: '16px', pb: '54px' }}
          >
            <Grid item xs={12}>
              <CustomCard sx={{ p: '10px 20px' }}>
                <ChartPurchaseMobile
                  {...{
                    timeFrame,
                    setTimeFrame,
                    dateRange,
                    setDateRange,
                    saveDateRange,
                    setSaveDateRange,
                  }}
                />
              </CustomCard>
            </Grid>
            {/* table */}
            <Grid item xs={12}>
              <TablePurchaseDashboardMobile />
            </Grid>
            <Grid item xs={12}>
              <TablePurchaseQrCodeMobile id={id} />
            </Grid>
          </Grid>
        </>
      )}
      {isLoading && <LoadingComponent />}
    </Box>
  );
};
export default PurchaseQrCodeMobile;
