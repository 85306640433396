import { configureStore } from '@reduxjs/toolkit';
import pagePurchase from './purchase/page';
import tablePurchase from './purchase/dataTable';
import hirarkiHub from './hub/hirarkiHub';
import masterHub from './hub/masterHub/masterHub';
import masterHubAdd from './hub/masterHub/masterHubAdd';
import subsidiary from './subsidiary/subsidiary';
import pic from './pic/pic';
import subHubAdd from './hub/subHub/subHubAdd';
import miniHubAdd from './hub/miniHub/miniHubAdd';
import masterHubEdit from './hub/masterHub/masterHubEdit';
import subHubDetail from './hub/subHub/subHubDetails';
import subHubEdit from './hub/subHub/subHubEdit';
import miniHubDetail from './hub/miniHub/miniHubDetails';
import dataMiniHub from './hub/miniHub/miniHubData';
import miniHubEdit from './hub/miniHub/miniHubEdit';
import masterHubDetail from './hub/masterHub/masterHubDetail';
import notifHub from './hub/notifHub';
import subCategoryProduct from './Product/subCategoryProduct';
import detaildashboard from './purchase/detailPurchase';
import requestpurchase from './purchase/addRequestPurchase';
import purchaseRequestDetail from './purchase/purchaseAdmin/detailRequestPurchase';
import editPurchaseRequest from './purchase/purchaseAdmin/editPurchaseRequest';
import purchaseRequest from './purchase/purchaseAdmin/purchaseRequest';
import purchaseRouting from './Routing/purchaseRouting';
import masterHubRack from './racks/masterHubRack/masterHubRack';
import masterHubRackEdit from './racks/masterHubRack/masterHubRackEdit';
import rackHub from './racks/rackHub';
import rackHubEdit from './racks/rackHubEdit';
import purchaseAddFinance from './purchase/purchaseFinance/purchaseAddFinance';
import purchaseEditFinance from './purchase/purchaseFinance/purchaseEditFinance';
import purchaseProcessSupplier from './purchase/purchaseSupplier/purchaseProcessSupplier';
import purchaseSubmitSupplier from './purchase/purchaseSupplier/purchaseSubmitSupplier';
import purchaseProcessedAdmin from './purchase/purchaseAdmin/purchaseProcessedAdmin';
import purchaseFinishAdmin from './purchase/purchaseAdmin/purchaseFinishAdmin';
import stockRouting from './Routing/stockRouting';
import stock from './stock/stock';
import transactionRouting from './Routing/transactionRouting';
import tableTransaction from './transaction/dataTable';
import transactionRequest from './transaction/request/dataTableRequest';
import addTransaction from './transaction/request/addTransaction';
import detailTransactionRequest from './transaction/request/detailTransactionRequest';
import editTransactionRequest from './transaction/request/editTransactionRequest';
import auditRouting from './Routing/auditRouting';
import addAudit from './audit/addAudit';
import detailAudit from './audit/detailAudit';
import auditTable from './audit/dataTableAudit';
import adjustmentRouting from './Routing/addjustment';
import detailAdjustment from './adjustment/detailAdjustment';
import editAdjustment from './adjustment/editAdjustment';
import addAdjustment from './adjustment/addAdjustment';
import dataAdjustment from './adjustment/dataAdjustment';
import addMissingQrAdjustment from './adjustment/addMissingQrAdjustment';
import hubsRouting from './Routing/hubs';
import subHub from './hub/subHub/subHub';
// import masterHubsRouting from "./Routing/Hubs/masterhub";
import stockOutRouting from './Routing/stockOutRouting';
import stockout from './stockout/stockout';
import totalUnscanned from './stockout/totalUnscanned';
import sendDataTransaction from './transaction/request/sendDataTransaction';
import acceptDataTransaction from './transaction/request/acceptDataTransaction';
import account from './account/account';
import supplierRouting from './Routing/supplierRouting';
import addSupplier from './supplier/addSupplier';
import productSupplierRouting from './Routing/productSupplierRouting';
import dataProductSupplier from './productSupplier/dataProductSupplier';
import addProductSupplier from './productSupplier/addProductSupplier';
import detailProdukSupplier from './productSupplier/detailProductSupplier';
import editProdukSupplier from './productSupplier/editProductSupplier';
import dataSupplier from './supplier/dataSupplier';
import detailSupplier from './supplier/detailSupplier';
import editSupplier from './supplier/editSupplier';

export default configureStore({
  reducer: {
    hirarkiHub: hirarkiHub,
    subsidiary: subsidiary,
    pic: pic,
    masterHub: masterHub,
    masterHubAdd: masterHubAdd,
    pagePurchase: pagePurchase,
    tablePurchase: tablePurchase,
    // *--- Product
    subCategoryProduct: subCategoryProduct,
    // *--- masterHubListHub: masterHubListHub,
    account: account,

    subHub: subHub,
    subHubDetail: subHubDetail,
    subHubEdit: subHubEdit,
    subHubAdd: subHubAdd,
    miniHubAdd: miniHubAdd,
    dataMiniHub: dataMiniHub,
    miniHubDetail: miniHubDetail,
    miniHubEdit: miniHubEdit,
    // *--- masterHubListHub: masterHubListHub,
    notifHub: notifHub,
    // subHubAdd: subHubAdd,
    // miniHubAdd: miniHubAdd,
    masterHubDetail: masterHubDetail,
    masterHubEdit: masterHubEdit,

    //! * ------------ Routing -------------------
    hubsRouting: hubsRouting,
    purchaseRouting: purchaseRouting,
    transactionRouting: transactionRouting,
    stockRouting: stockRouting,
    auditRouting: auditRouting,
    adjustmentRouting: adjustmentRouting,
    stockOutRouting: stockOutRouting,
    supplierRouting: supplierRouting,
    productSupplierRouting: productSupplierRouting,

    // ------------- purchase ---------------------
    detaildashboard: detaildashboard,
    purchaseRequest: purchaseRequest,
    requestpurchase: requestpurchase,
    purchaseRequestDetail: purchaseRequestDetail,
    editPurchaseRequest: editPurchaseRequest,
    // * ---------- PUrchase Finance ----------
    purchaseAddFinance: purchaseAddFinance,
    purchaseEditFinance: purchaseEditFinance,
    // * ---------- PUrchase Supplier ----------
    purchaseProcessSupplier: purchaseProcessSupplier,
    purchaseSubmitSupplier: purchaseSubmitSupplier,

    // * ----------- purchase admin -------------
    purchaseProcessedAdmin: purchaseProcessedAdmin,
    purchaseFinishAdmin: purchaseFinishAdmin,

    // * ------------Stock --------------------
    stock: stock,

    // * ------------StockOut --------------------
    stockOut: stockout,
    totalUnscanned: totalUnscanned,

    //  * -------------- transaction ---------------
    tableTransaction: tableTransaction,
    transactionRequest: transactionRequest,
    addTransaction: addTransaction,
    detailTransactionRequest: detailTransactionRequest,
    editTransactionRequest: editTransactionRequest,
    sendDataTransaction: sendDataTransaction,
    acceptDataTransaction: acceptDataTransaction,

    //  ---------------- audit ----------------
    addAudit: addAudit,
    auditTable: auditTable,
    detailAudit: detailAudit,

    // ----------------- adjustment ------------
    dataAdjustment: dataAdjustment,
    addAdjustment: addAdjustment,
    addMissingQrAdjustment: addMissingQrAdjustment,
    detailAdjustment: detailAdjustment,
    editAdjustment: editAdjustment,

    //  ---------------- supplier -----------------
    dataSupplier: dataSupplier,
    addSupplier: addSupplier,
    detailSupplier: detailSupplier,
    editSupplier: editSupplier,

    //  ---------------- product supplier -----------------
    dataProductSupplier: dataProductSupplier,
    addProductSupplier: addProductSupplier,
    detailProdukSupplier: detailProdukSupplier,
    editProdukSupplier: editProdukSupplier,

    // * Master Hub Rack
    rackHub: rackHub,
    rackHubEdit: rackHubEdit,
    masterHubRack: masterHubRack,
    masterHubRackEdit: masterHubRackEdit,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
