import { createSlice } from '@reduxjs/toolkit';
import {
  getAllHubs,
  getDetailHubs,
  getRackMasterHub,
  getRackMasterHubPreview,
} from '../../../api/hubs';

export const masterHubState = createSlice({
  name: 'masterHub',
  initialState: {
    hubsData: [],
    isloadingHubs: 'false',
    pages: null,

    isLoading: false,
    detailsData: {},
    racks: [],
    racksPreview: [],
    racksPositionPreview: [],
    racksPositionPreviewAll: [],
    racksPositionTemporary: [],
  },
  reducers: {
    loadAllDataHubs: (state, action) => {
      state.hubsData = action.payload;
    },
    loadPageInfo: (state, action) => {
      state.pages = action.payload;
    },
    changeIsLoadingHubs: (state, action) => {
      state.isloadingHubs = action.payload;
    },

    loadDetailsDataMasterHub: (state, action) => {
      state.detailsData = action.payload;
    },
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    loadDataRacks: (state, action) => {
      state.racks = action.payload;
    },
    loadDataRacksPositionPreview: (state, action) => {
      state.racksPositionPreview = action.payload;
      state.racksPositionPreviewAll = action.payload.flat();
    },
    loadDataRacksPreview: (state, action) => {
      state.racksPreview = action.payload;
    },
    hoverRacks: (state, action) => {},
  },
});

export const {
  loadAllDataHubs,
  changeIsLoadingHubs,
  loadPageInfo,

  loadDetailsDataMasterHub,
  changeIsLoading,
  loadDataRacks,
  loadDataRacksPreview,
  loadDataRacksPositionPreview,
} = masterHubState.actions;

export const getAllDataMasterHub = (state) => state.masterHub.hubsData;
export const getIsloadingMasterHub = (state) => state.masterHub.isloadingHubs;
export const getPageInfoMasterHub = (state) => state.masterHub.pages;

export const getDetailsDataMasterHub = (state) => state.masterHub.detailsData;
export const getLoadingDetailsDataMasterHub = (state) =>
  state.masterHub.isLoading;

export const getRacksMasterHub = (state) => state.masterHub.racks;
export const getRacksPositionPreviewMasterHub = (state) =>
  state.masterHub.racksPositionPreview;
export const getRacksPositionPreviewAllMasterHub = (state) =>
  state.masterHub.racksPositionPreviewAll;
export const getRacksPreviewMasterHub = (state) => state.masterHub.racksPreview;

// !------------ API ------------------
export const fetchAllDataMasteHub = (params) => (dispatch) => {
  const fetchData = async () => {
    dispatch(changeIsLoadingHubs(true));
    try {
      const {
        data: { data },
      } = await getAllHubs(params);
      dispatch(loadAllDataHubs(data?.hubs));
      dispatch(loadPageInfo(data?.pageInfo));
    } catch (err) { 
      console.error(err);
    } finally {
      dispatch(changeIsLoadingHubs(false));
    }
  };

  fetchData();
};

export const fetchDetailsDataMasterHub = (id) => (dispatch) => {
  const fetchApi = async () => {
    dispatch(changeIsLoading(true));
    try {
      const {
        data: { data },
      } = await getDetailHubs(id);
      dispatch(loadDetailsDataMasterHub(data));
      // setMasterHubData({
      //   ...masterHubData,
      //   masterHubId: data.idToko,
      //   subsidiary: data.subsidiary,
      // });
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoading(false));
    }
  };

  fetchApi();
};

export const fetchMasterHubRacks = (id) => (dispatch) => {
  const fetchApi = async () => {
    try {
      const {
        data: {
          data: { data },
        },
      } = await getRackMasterHub(id);
      dispatch(loadDataRacks(data));
    } catch (err) {
      dispatch(loadDataRacks([]));
      console.error(err);
    } finally {
    }
  };

  fetchApi();
};

export const fetchMasterHubRacksPreview = (id) => (dispatch) => {
  const fetchApi = async () => {
    try {
      const {
        data: {
          data: { data },
        },
      } = await getRackMasterHubPreview(id);
      let rackPosition = data.map((item) => {
        return item.columnPosition.split(',');
      });
      dispatch(loadDataRacksPositionPreview(rackPosition));
      dispatch(loadDataRacksPreview(data));
    } catch (err) {
      dispatch(loadDataRacksPositionPreview([]));
      dispatch(loadDataRacksPreview([]));
      console.error(err);
    } finally {
    }
  };

  fetchApi();
};

export default masterHubState.reducer;
