import { alereAxios } from '../utils/api';

export const getPlacesByName = (search) =>
  alereAxios.get('maps/autocomplete', {
    params: {
      q: search,
    },
  });
export const getPlacesByCoordinate = ({ lat, lon }) =>
  alereAxios.get('maps/search', {
    params: {
      lat,
      lon,
    },
  });
