import { createSlice } from '@reduxjs/toolkit';

export const subCategoryProductState = createSlice({
  name: 'subCategoryProduct',
  initialState: {
    update: false,
  },
  reducers: {
    updateSubCategoryProduct: (state) => {
      state.update = true;
    },
    clearSubCategoryProduct: (state) => {
      state.update = false;
    },
  },
});

export const { updateSubCategoryProduct, clearSubCategoryProduct } =
  subCategoryProductState.actions;

export const getAllSubCategoryProduct = (state) => state.subCategoryProduct;

export default subCategoryProductState.reducer;
