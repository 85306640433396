import { Grid, Typography } from '@mui/material';
import BackButton from '../../../../../../components/shared/BackButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuPerformance from '../Components/performance/MenuPerformance';
import TableMenuMenu from './Desktop/TableMenuMenu';
import { useTheme } from '@emotion/react';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';

const MenuMenuDesktop = (props) => {
  const {
    theme,
    // *-- Hirarki
    roadHirarki,
    setRoadHirarki,
    // *-- Notif
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
    // *-- Page
    setPage,
    setMenuMenu,
    // *-date & time
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    // *-Tabel
    tableRowPerPageValues,
    tableRowPerPage,
    setTableRowPerPage,
    tableCurrentPage,
    setTableCurrentPage,
    tableTotalItems,
    setTableTotalItems,
    tableSearch,
    setTableSearch,
    sortTable,
    setSortTable,
    isSortAsc,
    setIsSortAsc,
    tableDatas,
    setTableDatas,
    tableCategoryFilterDatas,
    setTableCategoryFilterDatas,
    isLoadingData,
    // *--- Subsidiary
    subsidiaryDatas,
    subsidiaryFilterValue,
    setSubsidiaryFilterValue,
    // * -- Hanlder
    handleBackButton,
    handleClickCategory,
    handleAddMenu,
    handleAddMini,
    handleClickMini,
    handleClickRow,
    handleToSocialMedia,
    // *-- Filter
    filterDataAwait,
    setFilterDataAwait,
  } = props;
  return (
    <Grid container columns={12} spacing={2}>
      {/* //* Back Button  */}
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        {roadHirarki.category === '' || roadHirarki.subCategory === '' ? (
          <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
            Menus
          </Typography>
        ) : (
          <>
            <Typography
              sx={{
                ml: '8px',
                fontSize: '24px',
                fontWeight: '600',
                color: '#9E9D9D',
                cursor: 'pointer',
              }}
              onClick={handleClickCategory}
            >
              Category
            </Typography>
            <ChevronRightIcon sx={{ color: '#9E9D9D' }} />
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: '600',
                cursor: 'pointer',
                color: '#9E9D9D',
              }}
              onClick={handleBackButton}
            >
              {roadHirarki.category}
            </Typography>
            <ChevronRightIcon sx={{ color: '#9E9D9D' }} />
            <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
              {roadHirarki.subCategory}
            </Typography>
          </>
        )}
      </Grid>
      {/* //* Performance */}
      {/* <Grid item xs={12}>
        <MenuPerformance
          {...{
            timeFrame,
            setTimeFrame,
            dateRange,
            setDateRange,
            saveDateRange,
            setSaveDateRange,
          }}
        />
      </Grid> */}
      {/* //* Table */}
      <Grid item xs={12}>
        <TableMenuMenu {...{ ...props }} />
      </Grid>
    </Grid>
  );
};

export default MenuMenuDesktop;
