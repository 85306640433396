import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "../../../../../../../../components/shared/BackButton";
import CustomInput from "../../../../../../../../components/shared/CustomInput";
import {
  changeName,
  changePhotoPreview,
  changePicChoose,
  changePicNumber,
  changePicSearch,
  changePicSearchDebounce,
  changeSubsidiaryId,
  changeVideoPreview,
  clickIsPreviewPhoto,
  clickIsPreviewVideo,
  deleteImage,
  deleteTayangan,
  getIsCreateMap,
  getIsMaxSizePhotoMasterHubAdd,
  getIsMaxSizeVideoMasterHubAdd,
  getIsPhotoPreviewMasterHubAdd,
  getIsVideoPreviewMasterHubAdd,
  getNameMasterHubAdd,
  getPhotoMasterHubAdd,
  getPhotoPreviewMasterHubAdd,
  getPichChooseMasterHubAdd,
  getPichSearchDebounceMasterHubAdd,
  getPichSearchMasterHubAdd,
  getPicNumberMasterHubAdd,
  getSubsidiaryMasterHubAdd,
  getVideoMasterHubAdd,
  getVideoPreviewMasterHubAdd,
  inputImage,
  inputTayangan,
  changeIsCreateMap,
  getMapTypeMasterHubAdd,
  changeMapType,
  getColCreateMap,
  getRowCreateMap,
  getEntranceCreateMap,
  getDetailRackCreateMap,
  getCellSelectedDetailsCreateMap,
  getStatusMasterHubAdd,
  changeStatus,
  getCoordinateFix,
  getAddressFix,
  getMasterHubAddAllState,
  changeErrorMassageImage,
} from "../../../../../../../../store/hub/masterHub/masterHubAdd";
import {
  fetchSubsidiaryList,
  getSubsidiaryList,
} from "../../../../../../../../store/subsidiary/subsidiary";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import pic, {
  fetchPic,
  getPicList,
  getPicLoading,
} from "../../../../../../../../store/pic/pic";
import debounce from "lodash.debounce";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomCard from "../../../../../../../../components/shared/CustomCard";
import CreateMapMasterHubAdd from "./CreateMapMasterHubAdd";
import CustomButton from "../../../../../../../../components/shared/CustomButton";
import CustomAntSwitch from "../../../../../../../../components/shared/CustomAntSwitch";
import CloseIcon from "@mui/icons-material/Close";
import { Draggable, Map, Marker, ZoomControl } from "pigeon-maps";
import { useGeolocated } from "react-geolocated";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import PinDropIcon from "@mui/icons-material/PinDrop";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import LocationSearchingRoundedIcon from "@mui/icons-material/LocationSearchingRounded";
import { ReactComponent as CameraLocationToMarker } from "../../../../../../../../assets/location-to-marker.svg";
import SetupAddress from "./CreateMap/SetupAddress";
import HubFamily from "../../../HubFamily";
import {
  addNewMasterHub,
  addNewMultipleRack,
} from "../../../../../../../../api/hubs";
import { async } from "q";
import { inputNotifHub } from "../../../../../../../../store/hub/notifHub";
import Star from "../../../../../../../../components/shared/Star";

const MasterHubAdd = ({ setIsOpenMasterHub }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const {
    mapInputType,
    placeGetByName,
    placeGetByCoordinate,
    isUploadImage,
    isUploadVideo,
    errorMessageVideo,
  } = useSelector(getMasterHubAddAllState);

  const subsidiaryList = useSelector(getSubsidiaryList);
  const subsidiaryId = useSelector(getSubsidiaryMasterHubAdd);
  const name = useSelector(getNameMasterHubAdd);

  const photo = useSelector(getPhotoMasterHubAdd);
  const isMaxSizePhoto = useSelector(getIsMaxSizePhotoMasterHubAdd);
  const linkPhotoPreview = useSelector(getPhotoPreviewMasterHubAdd);
  const isPhotoPreview = useSelector(getIsPhotoPreviewMasterHubAdd);

  const video = useSelector(getVideoMasterHubAdd);
  const isMaxSizeVideo = useSelector(getIsMaxSizeVideoMasterHubAdd);
  const linkVideoPreview = useSelector(getVideoPreviewMasterHubAdd);
  const isVideoPreview = useSelector(getIsVideoPreviewMasterHubAdd);

  const picList = useSelector(getPicList);
  const picLoading = useSelector(getPicLoading);
  const picChoose = useSelector(getPichChooseMasterHubAdd);
  const picSearch = useSelector(getPichSearchMasterHubAdd);
  const picSearchDebounce = useSelector(getPichSearchDebounceMasterHubAdd);
  const picNumber = useSelector(getPicNumberMasterHubAdd);
  const isCreateMap = useSelector(getIsCreateMap);

  const col = useSelector(getColCreateMap);
  const row = useSelector(getRowCreateMap);
  const entrance = useSelector(getEntranceCreateMap);
  const dataDetailRack = useSelector(getCellSelectedDetailsCreateMap);

  const status = useSelector(getStatusMasterHubAdd);

  const coordinate = useSelector(getCoordinateFix);
  const address = useSelector(getAddressFix);

  useEffect(() => {
    dispatch(fetchSubsidiaryList());
  }, []);

  useEffect(() => {
    dispatch(fetchPic(picSearchDebounce));
  }, [picSearchDebounce]);

  const picSearchInDebounce = useRef(
    debounce((value) => {
      dispatch(changePicSearchDebounce(value));
    }, 1000)
  ).current;

  // !--------------------- Map Address ---------------------------
  const [isMapOpen, setIsMapOpen] = useState(false);

  // !--------------------- HANDLER ---------------------------
  const handleBackButton = () => {
    setIsOpenMasterHub(false);
  };

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleSubmit = () => {
    let datas = {
      subsidiaryId: subsidiaryId,
      namaToko: name,
      mapColumn: col + 2,
      mapRow: row + 2,
      idKustomer: picChoose.idKustomer,
      status: status ? "aktif" : "pending",
      alamat: address,
      nohp: picNumber,
      lat: coordinate[0],
      lon: coordinate[1],
      typeInput: mapInputType === "manual" ? "manual" : "map",
    };
    if (photo.length !== 0) {
      datas["gambar"] = photo.join(",");
    }
    if (video.length !== 0) {
      datas["videos"] = video.join(",");
    }
    if (entrance.length !== 0) {
      datas["entrancePosition"] = entrance.join(",");
    }
    if (mapInputType === "manual") {
      const { formatted: citydistric } = placeGetByName;
      datas["cityDistrict"] = citydistric;
    } else {
      if (!placeGetByCoordinate?.statusCode) {
        const { formatted: citydistric } = placeGetByCoordinate;
        datas["cityDistrict"] = citydistric;
      }
    }

    const createRacks = async (id, nama) => {
      try {
        let racks = dataDetailRack.map((item) => {
          let value = {
            idToko: id,
            subHubId: null,
            miniHubId: null,
            name: item.name,
            images: "",
            videos: "",
            column: item.column,
            row: item.row,
            columnPosition: item.columnPosition.join(","),
            description: item.description,
            width: 0,
            height: 0,
            length: 0,
            weight: 0,
          };

          return value;
        });
        const { data } = await addNewMultipleRack(racks);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(
          inputNotifHub({
            key: "addhub",
            text: `${nama} has been successfully added`,
          })
        );
        setLoadingSubmit(false);
        setIsOpenMasterHub(false);
      }
    };

    const createHub = async () => {
      setLoadingSubmit(true);
      try {
        const {
          data: { data },
        } = await addNewMasterHub(datas);
        createRacks(data.idToko, data.namaToko);
      } catch (error) {
        console.error(error);
      } finally {
      }
    };

    createHub();
  };
  // !------------------------- Validation ---------------------------------
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);

  useEffect(() => {
    if (name.length >= 5) {
      if (subsidiaryId) {
        if (picChoose) {
          if (picNumber) {
            if (address) {
              if (mapInputType === "manual") {
                if (coordinate && placeGetByName?.formatted) {
                  if (dataDetailRack.length !== 0) {
                    return setIsSubmitAvailable(true);
                  } else {
                    return setIsSubmitAvailable(false);
                  }
                } else {
                  return setIsSubmitAvailable(false);
                }
              } else {
                if (coordinate && placeGetByCoordinate?.formatted) {
                  if (dataDetailRack.length !== 0) {
                    return setIsSubmitAvailable(true);
                  } else {
                    return setIsSubmitAvailable(false);
                  }
                } else {
                  return setIsSubmitAvailable(false);
                }
              }
            } else {
              return setIsSubmitAvailable(false);
            }
          } else {
            return setIsSubmitAvailable(false);
          }
        } else {
          return setIsSubmitAvailable(false);
        }
      } else {
        return setIsSubmitAvailable(false);
      }
    } else {
      return setIsSubmitAvailable(false);
    }
  }, [
    name,
    subsidiaryId,
    picChoose,
    picNumber,
    address,
    mapInputType,
    placeGetByCoordinate?.formatted,
    coordinate,
    placeGetByName?.formatted,
    dataDetailRack,
  ]);

  return (
    <Box sx={{ mt: "16px" }}>
      {!isCreateMap ? (
        <>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <BackButton handleClick={handleBackButton} />
            <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
              Add master hub
            </Typography>
          </Box>
          <Box sx={{ display: "flex", mt: "16px", gap: 3 }}>
            <Box sx={{ bgcolor: "#FAFAFA", width: "250px" }}>
              <HubFamily isAddMaster={true} nameAddMaster={name} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                minHeight: "76vh",
              }}
            >
              <Grid container columns={12} spacing={4}>
                {/* //* Subsidiary */}
                <Grid item xs={12}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{ mb: "8px", fontSize: "16px", fontWeight: "500" }}
                    >
                      Subsidiary
                    </Typography>
                    <Star />
                  </Box>

                  <FormControl>
                    <RadioGroup
                      row
                      value={subsidiaryId}
                      onChange={(e) => {
                        dispatch(changeSubsidiaryId(e.target.value));
                      }}
                    >
                      {subsidiaryList.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          value={item.id}
                          label={item.name}
                          control={<Radio />}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {/* //* Name */}
                <Grid item xs={12}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{ mb: "8px", fontSize: "16px", fontWeight: "500" }}
                    >
                      Name
                    </Typography>
                    <Star />
                  </Box>
                  <CustomInput
                    sx={{ width: "100%", bgcolor: "#FAFAFA" }}
                    placeholder="Enter hub name"
                    value={name}
                    onChange={(e) => {
                      if (e.target.value.length <= 30) {
                        dispatch(changeName(e.target.value));
                      }
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#9E9D9D",
                        mt: "4px",
                      }}
                    >
                      Enter at least 5 character
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#9E9D9D",
                        mt: "4px",
                      }}
                    >
                      {name.length}/30
                    </Typography>
                  </Box>
                </Grid>
                {/* //* Photo */}
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      pb: `${photo.length === 0 ? "16px" : "9px"}`,
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography
                          sx={{ fontSize: "16px", fontWeight: "500" }}
                        >
                          Photo
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#9E9D9D",
                          }}
                        >
                          ( max size : 2MB )
                        </Typography>
                      </Box>
                      {isMaxSizePhoto && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <ErrorOutlineIcon
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: theme.palette.warning.main,
                              mr: "4px",
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: theme.palette.warning.main,
                            }}
                          >
                            The photo can't be more than 2MB
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {photo.length === 0 && (
                        <Box
                          sx={{
                            height: "54px",
                            width: "54px",
                            aspectRatio: "1/1",
                            borderRadius: "8px",
                            bgcolor: "#F5F5F5",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderStyle: "dashed",
                            borderColor: "#9E9D9D",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            document.getElementById("input_img").click();
                          }}
                        >
                          <ImageOutlinedIcon
                            sx={{ color: "#9E9D9D", fontSize: "28px" }}
                          />
                        </Box>
                      )}
                      {photo.map((input, index) => (
                        <Box
                          sx={{
                            position: "relative",
                            overflow: "visible",
                            padding: "7px",
                          }}
                          key={index}
                        >
                          <CancelIcon
                            sx={{
                              position: "absolute",
                              fontSize: "20px",
                              top: 1,
                              right: 1,
                              zIndex: +1,
                              cursor: "pointer",
                            }}
                            onClick={() => dispatch(deleteImage(input))}
                          />
                          <Box
                            component="img"
                            src={
                              process.env.REACT_APP_API_IMAGE_BASE_URL +
                              "/" +
                              input
                            }
                            sx={{
                              height: "54px",
                              width: "54px",
                              aspectRatio: "1/1",
                              borderRadius: "8px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              dispatch(clickIsPreviewPhoto());
                              dispatch(changePhotoPreview(input));
                            }}
                          />
                        </Box>
                      ))}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        mb: "7px",
                        ml: "20px",
                        alignItems: "center",
                      }}
                    >
                      {photo.length < 3 && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: theme.palette.primary.main,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            document.getElementById("input_img").click();
                          }}
                        >
                          <input
                            accept="image/*"
                            id="input_img"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => dispatch(inputImage(e))}
                          />
                          {!isUploadImage ? (
                            <>
                              <AddIcon />
                              <Typography
                                sx={{ fontSize: "14px", fontWeight: "600" }}
                              >
                                Add More
                              </Typography>
                            </>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <CircularProgress size={18} />
                              <Typography> uploading...</Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
                {/* //* Video */}
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      pb: `${video.length === 0 ? "16px" : "9px"}`,
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography
                          sx={{ fontSize: "16px", fontWeight: "500" }}
                        >
                          Video
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#9E9D9D",
                          }}
                        >
                          ( max size : 100MB )
                        </Typography>
                      </Box>

                      {isMaxSizeVideo && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <ErrorOutlineIcon
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: theme.palette.warning.main,
                              mr: "4px",
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "8px",
                              fontWeight: "500",
                              color: theme.palette.warning.main,
                            }}
                          >
                            The video is too big
                          </Typography>
                        </Box>
                      )}

                      {errorMessageVideo && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <ErrorOutlineIcon
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: theme.palette.warning.main,
                              mr: "4px",
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontWeight: "500",
                              color: theme.palette.warning.main,
                            }}
                          >
                            {errorMessageVideo}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {video.length === 0 && (
                        <Box
                          sx={{
                            height: "54px",
                            width: "54px",
                            aspectRatio: "1/1",
                            borderRadius: "8px",
                            bgcolor: "#F5F5F5",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderStyle: "dashed",
                            borderColor: "#9E9D9D",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            document.getElementById("inputMenu_video").click();
                          }}
                        >
                          <VideocamOutlinedIcon
                            sx={{ color: "#9E9D9D", fontSize: "28px" }}
                          />
                        </Box>
                      )}
                      {video.map((input, index) => (
                        <Box
                          sx={{
                            position: "relative",
                            overflow: "visible",
                            padding: "7px",
                          }}
                          key={index}
                        >
                          <CancelIcon
                            sx={{
                              position: "absolute",
                              fontSize: "20px",
                              top: 1,
                              right: 1,
                              zIndex: +1,
                              cursor: "pointer",
                            }}
                            onClick={() => dispatch(deleteTayangan(input))}
                          />
                          <Box
                            onClick={() => {
                              dispatch(clickIsPreviewVideo());
                              dispatch(changeVideoPreview(input));
                            }}
                          >
                            <video
                              style={{
                                maxHeight: "54px",
                                maxWidth: "54px",
                                aspectRatio: "1/1",
                                borderRadius: "8px",
                                cursor: "pointer",
                                backgroundColor: "black",
                              }}
                            >
                              <source
                                src={
                                  process.env.REACT_APP_API_IMAGE_BASE_URL +
                                  "/" +
                                  input +
                                  "#t=0.5"
                                }
                              />
                            </video>
                          </Box>
                        </Box>
                      ))}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        mb: "7px",
                        ml: "20px",
                        alignItems: "center",
                      }}
                    >
                      {video.length < 3 && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: theme.palette.primary.main,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            document.getElementById("inputMenu_video").click();
                          }}
                        >
                          <input
                            // accept="video/*"
                            id="inputMenu_video"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => dispatch(inputTayangan(e))}
                          />
                          {!isUploadVideo ? (
                            <>
                              <AddIcon />
                              <Typography
                                sx={{ fontSize: "14px", fontWeight: "600" }}
                              >
                                Add More
                              </Typography>
                            </>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <CircularProgress size={18} />
                              <Typography> uploading...</Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
                {/* //* Pic */}
                <Grid item xs={6}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{ mb: "8px", fontSize: "16px", fontWeight: "500" }}
                    >
                      PIC
                    </Typography>
                    <Star />
                  </Box>
                  <Autocomplete
                    options={picList}
                    getOptionLabel={(option) => option.namaLengkap}
                    value={picChoose}
                    onChange={(_, newValue) => {
                      dispatch(changePicChoose(newValue));
                      dispatch(changePicNumber(newValue.telpon));
                    }}
                    inputValue={picSearch}
                    loading={picLoading}
                    onInputChange={(_, newInputValue) => {
                      dispatch(changePicSearch(newInputValue));
                      picSearchInDebounce(newInputValue);
                    }}
                    renderOption={(props, option) => (
                      <li key={option.idKustomer} {...props}>
                        {option.namaLengkap}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search "
                        sx={{ width: "100%", bgcolor: "#FAFAFA" }}
                      />
                    )}
                  />
                </Grid>
                {/* //* Pic Number */}
                <Grid item xs={6}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{ mb: "8px", fontSize: "16px", fontWeight: "500" }}
                    >
                      Phone Number
                    </Typography>
                    <Star />
                  </Box>
                  <CustomInput
                    disabled
                    sx={{ width: "100%", bgcolor: "#FAFAFA" }}
                    placeholder="Enter phone number"
                    type="number"
                    value={picNumber}
                    onChange={(e) => {
                      dispatch(changePicNumber(e.target.value));
                    }}
                  />
                </Grid>
                {/* //* Address */}
                <Grid item xs={6}>
                  <CustomCard
                    sx={{ p: "8px 16px", cursor: "pointer" }}
                    onClick={() => setIsMapOpen(!isMapOpen)}
                  >
                    <Box
                      sx={{
                        display: "center",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Box
                          sx={{
                            width: "66px",
                            height: "66px",
                            bgcolor: "gray",
                            borderRadius: "8px",
                            position: "relative",
                          }}
                        >
                          <Box
                            sx={{
                              position: "absolute",
                              bgcolor: "white",
                              width: "100%",
                              height: "100%",
                              zIndex: "+2",
                              opacity: 0.5,
                            }}
                          ></Box>
                          <Box
                            sx={{
                              maxHeight: "66px",
                              overflow: "hidden",
                              borderRadius: "8px",
                            }}
                          >
                            <Map
                              defaultCenter={[
                                3.546958571415798, 98.6901696840363,
                              ]}
                              height={130}
                              defaultZoom={17}
                              width={66}
                              style={{
                                borderRadius: "8px",
                              }}
                            ></Map>
                          </Box>
                        </Box>
                        <Box>
                          <Box sx={{ display: "flex" }}>
                            <Typography
                              sx={{ fontWeight: "500", mb: "4px", ml: "8px" }}
                            >
                              Address
                            </Typography>
                            <Star />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 0.4,
                            }}
                          >
                            <LocationOnIcon color="primary" />
                            <Typography>
                              {address ? address : "Click to enter address"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <KeyboardArrowDownIcon />
                    </Box>
                  </CustomCard>
                </Grid>
                {/* //* Rack  */}
                <Grid item xs={6}>
                  <CustomCard
                    sx={{ p: "8px 16px", cursor: "pointer" }}
                    onClick={() => dispatch(changeIsCreateMap())}
                  >
                    <Box
                      sx={{
                        display: "center",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Box
                          sx={{
                            height: "66px",
                            borderRadius: "8px",
                          }}
                        ></Box>
                        <Box sx={{ ml: "8px" }}>
                          <Box sx={{ display: "flex" }}>
                            <Typography sx={{ fontWeight: "500", mb: "4px" }}>
                              Racks Map
                            </Typography>
                          </Box>

                          <Typography
                            sx={{ color: "#9E9D9D", fontSize: "12px" }}
                          >
                            Click to create map
                          </Typography>
                        </Box>
                      </Box>
                      <KeyboardArrowDownIcon />
                    </Box>
                  </CustomCard>
                </Grid>
              </Grid>

              {/* //* Submit */}
              <Box
                sx={{
                  bottom: 1,
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                    Status
                  </Typography>
                  <CustomAntSwitch
                    value={status}
                    onChange={() => dispatch(changeStatus())}
                  />
                </Box>

                {loadingSubmit && <CircularProgress size={16} />}
                <CustomButton
                  variant="contained"
                  disabled={!isSubmitAvailable}
                  onClick={handleSubmit}
                  sx={{
                    width: "140px",
                    height: "36px",
                    // m: "18px 32px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Submit
                  </Typography>
                </CustomButton>
              </Box>
            </Box>
          </Box>

          {/* //* Dialog Address */}
          <Dialog
            open={isMapOpen}
            onClose={() => {
              setIsMapOpen(!isMapOpen);
            }}
          >
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>Input Address</Box>
              <CloseIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setIsMapOpen(false)}
              />
            </DialogTitle>
            <DialogContent dividers>
              <SetupAddress setIsOpen={setIsMapOpen} />
            </DialogContent>
          </Dialog>
          {/* //* Dialog Photo */}
          <Dialog
            open={isPhotoPreview}
            onClose={() => {
              dispatch(clickIsPreviewPhoto());
              dispatch(changePhotoPreview(""));
            }}
          >
            <Box
              component="img"
              src={
                process.env.REACT_APP_API_IMAGE_BASE_URL +
                "/" +
                linkPhotoPreview
              }
              sx={{ width: "600px", height: "600px", aspectRation: "1/1" }}
            />
          </Dialog>
          {/* //* Video Dialoge */}
          <Dialog
            open={isVideoPreview}
            onClose={() => {
              dispatch(clickIsPreviewVideo());
              dispatch(changeVideoPreview(""));
            }}
          >
            <Box sx={{ bgcolor: "black" }}>
              <video
                style={{
                  width: "600px",
                  height: "600px",
                  backgroundColor: "black",
                }}
                controls
              >
                <source
                  src={
                    process.env.REACT_APP_API_IMAGE_BASE_URL +
                    "/" +
                    linkVideoPreview
                  }
                />
              </video>
            </Box>
          </Dialog>
        </>
      ) : (
        <CreateMapMasterHubAdd />
      )}
    </Box>
  );
};
export default MasterHubAdd;
