import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import BackButtonHub from "../../../../../../../components/shared/BackButtonHub";
import { useDispatch, useSelector } from "react-redux";
import {
  changePageProductSupplier,
  setCloseNotifProductSupplier,
} from "../../../../../../../store/Routing/productSupplierRouting";
import {
  changeStatus,
  fetchDetailProdukSupplier,
  getAllDataDetailProdukSupplier,
} from "../../../../../../../store/productSupplier/detailProductSupplier";
import { useEffect } from "react";
import CustomAntSwitch from "../../../../../../../components/shared/CustomAntSwitch";
import CustomButton from "../../../../../../../components/shared/CustomButton";
import { useState } from "react";
import CustomDeleteConfirm from "../../../../../../../components/shared/CustomDeleteConfirm";
import { DeleteProdukSupplier } from "../../../../../../../api/Supplier";
import {
  changeIdProduk,
  changeStatusEdit,
  loadDataEditProduk,
} from "../../../../../../../store/productSupplier/editProductSupplier";

const DetailProduk = () => {
  const dispatch = useDispatch();

  const { idProduk, isLoading, status, datas } = useSelector(
    getAllDataDetailProdukSupplier
  );

  const [deletConfirm, setDeletConfirm] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const editProduk = () => {
    dispatch(changeIdProduk(idProduk));
    dispatch(loadDataEditProduk(datas));
    dispatch(changeStatusEdit(status));
  };

  const handleDelete = () => {
    setDeletConfirm(true);
  };

  const handleDeleteFix = async () => {
    setLoadingDelete(true);
    try {
      await DeleteProdukSupplier(idProduk);
      dispatch(changePageProductSupplier(""));
      setLoadingDelete(false);
      dispatch(
        setCloseNotifProductSupplier({
          key: "deleteProdukSupplier",
          text: "product has been deleted",
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    dispatch(fetchDetailProdukSupplier(idProduk));
  }, [idProduk]);

  return (
    <Box>
      {isLoading === true ? (
        <Grid item xs={12}>
          <Box sx={{ textAlign: "center", p: 4 }}>
            <CircularProgress size={24} />
          </Box>
        </Grid>
      ) : (
        <>
          <Box sx={{ minHeight: "78vh" }}>
            <Grid container columns={12} spacing={2}>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <BackButtonHub
                    onClick={() => dispatch(changePageProductSupplier(""))}
                  />
                  <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                    Product Detail
                  </Typography>
                </Box>
              </Grid>

              {/* name Product */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    bgcolor: "#FAFAFA",
                    height: "60px",
                    borderRadius: "8px 8px 0px 0px",
                    p: "12px",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "12px", color: "#9E9E9E", mb: "5px" }}
                  >
                    Product
                  </Typography>
                  <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                    {datas?.namaProduk}
                  </Typography>
                </Box>
              </Grid>

              {/* mappping */}
              <Grid item xs={12}>
                {datas?.detail?.map((item, idx) => (
                  <Box
                    sx={{
                      bgcolor: "#FAFAFA",
                      height: "60px",
                      borderRadius: "8px 8px 0px 0px",
                      p: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                    key={idx}
                  >
                    <Box>
                      <Typography
                        sx={{ fontSize: "12px", color: "#9E9E9E", mb: "5px" }}
                      >
                        Price
                      </Typography>
                      <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                        {item?.price}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "white",
                        width: "80px",
                        height: "40px",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#000000",
                          fontWeight: 500,
                        }}
                      >
                        {item?.name}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Grid>

              {/* Switch */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>Publish</Typography>
                  <CustomAntSwitch
                    checked={status}
                    onChange={() => dispatch(changeStatus(!status))}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}
          >
            <CustomButton
              variant="contained"
              color="error"
              sx={{ height: "36px", width: "50%" }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                onClick={handleDelete}
              >
                Delete
              </Typography>
            </CustomButton>
            <CustomButton
              variant="contained"
              sx={{ height: "36px", width: "50%" }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                onClick={() => {
                  dispatch(changePageProductSupplier("editProduk"));
                  editProduk();
                }}
              >
                Edit
              </Typography>
            </CustomButton>
          </Box>
        </>
      )}

      {/* dialog Delete */}
      <CustomDeleteConfirm
        isLoading={loadingDelete}
        open={deletConfirm}
        onClose={() => setDeletConfirm(!deletConfirm)}
        handleClickCancel={() => setDeletConfirm(!deletConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Produk"
        body={
          <Box sx={{ textAlign: "center" }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: 600 }}>
                {datas?.namaProduk}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: "52px" }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />
    </Box>
  );
};

export default DetailProduk;
