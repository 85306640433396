import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import BackButtonHub from "../../../../../../../../components/shared/BackButtonHub";
import { useDispatch, useSelector } from "react-redux";
import {
  changeIsEdit,
  changeIsLoadingUpdate,
  changeName,
  changePhotoPreview,
  changePicChoose,
  changePicNumber,
  changePicSearch,
  changePicSearchDebounce,
  changeStatus,
  changeSubsidiaryId,
  changeVideoPreview,
  clickIsPreviewPhoto,
  clickIsPreviewVideo,
  deleteImage,
  deleteTayangan,
  getMasterHubEditAll,
  inputImage,
  inputTayangan,
} from "../../../../../../../../store/hub/masterHub/masterHubEdit";
import {
  AddIcon,
  CancelIcon,
  CloseIcon,
  ErrorOutlineIcon,
  EyeIcon,
  ImageOutlinedIcon,
  KeyboardArrowDownIcon,
  VideocamOutlinedIcon,
} from "../../../../../../../../assets/Icons";
import CustomInput from "../../../../../../../../components/shared/CustomInput";
import { useState } from "react";
import {
  fetchPic,
  getPicList,
  getPicLoading,
} from "../../../../../../../../store/pic/pic";
import { useRef } from "react";
import debounce from "lodash.debounce";
import { useEffect } from "react";
import CustomCard from "../../../../../../../../components/shared/CustomCard";
import { Map } from "pigeon-maps";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SetupAddressMobile from "./AddressMap/SetupAddressMobile";
import CustomButton from "../../../../../../../../components/shared/CustomButton";
import CustomAntSwitch from "../../../../../../../../components/shared/CustomAntSwitch";
import CustomEditConfirm from "../../../../../../../../components/shared/CustomEditConfirm";

import {
  editMasterHub,
  editMasterHubStatus,
} from "../../../../../../../../api/hubs";
import { getSubsidiary } from "../../../../../../../../api/subsidiary";
import { changePageMasterHub } from "../../../../../../../../store/Routing/hubs";
import PreviewRack from "../../../../../../../../components/shared/PreviewRack";

const EditMasterHubMobile = () => {
  const {
    subsidiary,
    subsidiaryId,
    idMasterHub,
    name,
    isLoadingMasterHub,
    photo,
    isMaxSizePhoto,
    video,
    isLoadingVideo,
    isMaxSizeVideo,
    isPhotoPreview,
    isVideoPreview,
    photoPreview: linkPhotoPreview,
    videoPreview: linkVideoPreview,
    picChoose,
    picSearch,
    picSearchDebounce,
    picNumber,
    coordinate,
    address,
    status,
    isLoadingUpdate,
    adressFix,
    coordinateFix,
    mapInputType,
    placeGetByName,
  } = useSelector(getMasterHubEditAll);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [subsidiaryDatas, setSubsidiaryDatas] = useState([]);
  const [isLoadingSubsidiary, setIsLoadingSubsidiary] = useState(false);
  const picList = useSelector(getPicList);
  const picLoading = useSelector(getPicLoading);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [newSubsidiary, setNewSubsidiary] = useState(subsidiary);
  const [isEditConfirm, setIsEditConfirm] = useState(false);
  const [setIsLoadingOnUpdate] = useState(false);

  useEffect(() => {
    const getSubsidiaries = async () => {
      setIsLoadingSubsidiary(true);
      try {
        const {
          data: {
            data: { subsidiaries },
          },
        } = await getSubsidiary();
        setSubsidiaryDatas(subsidiaries);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoadingSubsidiary(false);
      }
    };

    getSubsidiaries();
  }, []);

  const picSearchInDebounce = useRef(
    debounce((value) => {
      dispatch(changePicSearchDebounce(value));
    }, 1000)
  ).current;

  useEffect(() => {
    dispatch(fetchPic(picSearchDebounce));
  }, [picSearchDebounce]);

  useEffect(() => {
    picChoose !== null
      ? dispatch(changePicNumber(picChoose?.telpon))
      : dispatch(changePicNumber(""));
  }, [picChoose]);

  const handlePic = (newValue) => {
    dispatch(changePicChoose(newValue));
  };
  const handleSave = () => {
    setIsEditConfirm(!isEditConfirm);
  };

  const handleSaveFix = () => {
    setIsLoadingOnUpdate(true);
    const { idKustomer } = picChoose;
    const upadeteHub = {
      subsidiaryId: subsidiaryId,
      namaToko: name,
      idKustomer: idKustomer,
      gambar: photo.length !== 0 ? photo.join(",") : "",
      videos: video.length !== 0 ? video.join(",") : "",
      nohp: picNumber,
      alamat: adressFix === "" ? address : adressFix,
      lat: coordinateFix[0].toString(),
      lon: coordinateFix[1].toString(),
      typeInput: mapInputType,
      cityDistrict: placeGetByName?.formatted,
    };

    dispatch(changeIsLoadingUpdate(true));
    const updateMasterHub = async () => {
      try {
        await editMasterHub(idMasterHub, upadeteHub);
        setIsEditConfirm(!isEditConfirm);
        dispatch(changeIsEdit(false));
        dispatch(changePageMasterHub(""));
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(changeIsLoadingUpdate(false));
      }
    };
    updateMasterHub();
  };

  const handleEditStatus = () => {
    if (status) {
      editMasterHubStatus(idMasterHub, "pending");
      dispatch(changeStatus(false));
    } else {
      editMasterHubStatus(idMasterHub, "aktif");
      dispatch(changeStatus(true));
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Grid container columns={12} spacing={3} sx={{ pb: 3 }}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              gap: 1.5,
              alignItems: "center",
              mt: 2,
              mb: 2,
            }}
          >
            <BackButtonHub onClick={() => dispatch(changeIsEdit())} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, color: "#000" }}
              >
                Edit Master Hub
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <EyeIcon />
                <Typography
                  sx={{ fontSize: "12px", fontWeight: 600, color: "#51B15C" }}
                >
                  Preview
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* subsidiary */}
        <Grid item xs={12}>
          <Autocomplete
            options={subsidiaryDatas}
            value={newSubsidiary}
            getOptionLabel={(option) => option.name}
            loading={isLoadingSubsidiary}
            onChange={(_, newValue) => {
              setNewSubsidiary(newValue);
              dispatch(changeSubsidiaryId(newValue?.id));
            }}
            renderInput={(params) => (
              <TextField
                label="Subsidiary"
                variant="filled"
                {...params}
                sx={{
                  bgcolor: "#fafafa",
                  borderRadius: "5px",
                  fontSize: "14px",
                  color: "#9D9D9D",
                }}
              />
            )}
          />
        </Grid>

        {/* Name */}
        <Grid item xs={12}>
          <CustomInput
            label="Name*"
            variant="filled"
            sx={{ width: "100%", bgcolor: "#FAFAFA", fontSize: "14px" }}
            placeholder="Enter hub name"
            value={name}
            onChange={(e) => {
              if (e.target.value.length <= 30) {
                dispatch(changeName(e.target.value));
              }
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: "400",
                color: "#9E9D9D",
                mt: "4px",
              }}
            >
              Enter at least 5 character
            </Typography>
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: "400",
                color: "#9E9D9D",
                mt: "4px",
              }}
            >
              {name.length}/30
            </Typography>
          </Box>
        </Grid>

        {/* Photo */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              pb: `${photo.length === 0 ? "16px" : "9px"}`,
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              Photo
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#9E9D9D",
              }}
            >
              ( max size : 2MB, max file : 3 )
            </Typography>
            {isMaxSizePhoto && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ErrorOutlineIcon
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: theme.palette.warning.main,
                    mr: "4px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: theme.palette.warning.main,
                  }}
                >
                  The photo can't be more than 2MB
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {photo.length === 0 && (
                <Box
                  sx={{
                    height: "54px",
                    width: "54px",
                    aspectRatio: "1/1",
                    borderRadius: "8px",
                    bgcolor: "#F5F5F5",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderStyle: "dashed",
                    borderColor: "#9E9D9D",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    document.getElementById("input_img").click();
                  }}
                >
                  <ImageOutlinedIcon
                    sx={{ color: "#9E9D9D", fontSize: "28px" }}
                  />
                </Box>
              )}
              {photo.map((input, index) => (
                <Box
                  sx={{
                    position: "relative",
                    overflow: "visible",
                    padding: "7px",
                  }}
                  key={index}
                >
                  <CancelIcon
                    sx={{
                      position: "absolute",
                      fontSize: "20px",
                      top: 1,
                      right: 1,
                      zIndex: +1,
                      cursor: "pointer",
                    }}
                    onClick={() => dispatch(deleteImage(input))}
                  />
                  <Box
                    component="img"
                    src={process.env.REACT_APP_API_IMAGE_BASE_URL + "/" + input}
                    sx={{
                      height: "54px",
                      width: "54px",
                      aspectRatio: "1/1",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      dispatch(clickIsPreviewPhoto());
                      dispatch(changePhotoPreview(input));
                    }}
                  />
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                mb: "7px",
                ml: "20px",
                alignItems: "center",
              }}
            >
              {photo.length < 3 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    document.getElementById("input_img").click();
                  }}
                >
                  <input
                    accept="image/*"
                    id="input_img"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => dispatch(inputImage(e))}
                  />
                  {!isLoadingMasterHub ? (
                    <>
                      <AddIcon />
                      <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                        Add More
                      </Typography>
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <CircularProgress size={16} />
                      <Typography> uploading...</Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>

        {/* Video */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              pb: `${video.length === 0 ? "16px" : "9px"}`,
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
              Video
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#9E9D9D",
              }}
            >
              ( max size : 100MB, max file : 3 )
            </Typography>
            {isMaxSizeVideo && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ErrorOutlineIcon
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: theme.palette.warning.main,
                    mr: "4px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "8px",
                    fontWeight: "500",
                    color: theme.palette.warning.main,
                  }}
                >
                  The video is too big
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {video.length === 0 && (
                <Box
                  sx={{
                    height: "54px",
                    width: "54px",
                    aspectRatio: "1/1",
                    borderRadius: "8px",
                    bgcolor: "#F5F5F5",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderStyle: "dashed",
                    borderColor: "#9E9D9D",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    document.getElementById("inputMenu_video").click();
                  }}
                >
                  <VideocamOutlinedIcon
                    sx={{ color: "#9E9D9D", fontSize: "28px" }}
                  />
                </Box>
              )}
              {video.map((input, index) => (
                <Box
                  sx={{
                    position: "relative",
                    overflow: "visible",
                    padding: "7px",
                  }}
                  key={index}
                >
                  <CancelIcon
                    sx={{
                      position: "absolute",
                      fontSize: "20px",
                      top: 1,
                      right: 1,
                      zIndex: +1,
                      cursor: "pointer",
                    }}
                    onClick={() => dispatch(deleteTayangan(input))}
                  />
                  <Box
                    onClick={() => {
                      dispatch(clickIsPreviewVideo());
                      dispatch(changeVideoPreview(input));
                    }}
                  >
                    <video
                      style={{
                        maxHeight: "54px",
                        maxWidth: "54px",
                        aspectRatio: "1/1",
                        borderRadius: "8px",
                        cursor: "pointer",
                        backgroundColor: "black",
                      }}
                    >
                      <source
                        src={
                          process.env.REACT_APP_API_IMAGE_BASE_URL +
                          "/" +
                          input +
                          "#t=0.5"
                        }
                      />
                    </video>
                  </Box>
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                mb: "7px",
                ml: "20px",
                alignItems: "center",
              }}
            >
              {video.length < 3 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    document.getElementById("inputMenu_video").click();
                  }}
                >
                  <input
                    // accept="video/*"
                    id="inputMenu_video"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => dispatch(inputTayangan(e))}
                  />
                  {!isLoadingVideo ? (
                    <>
                      <AddIcon />
                      <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                        Add More
                      </Typography>
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <CircularProgress size={16} />
                      <Typography> uploading...</Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>

        {/*  Pic */}
        <Grid item xs={12}>
          <Autocomplete
            options={picList}
            getOptionLabel={(option) => option.namaLengkap}
            value={picChoose}
            onChange={(_, newValue) => handlePic(newValue)}
            inputValue={picSearch}
            loading={picLoading}
            onInputChange={(_, newInputValue) => {
              dispatch(changePicSearch(newInputValue));
              picSearchInDebounce(newInputValue);
            }}
            renderOption={(props, option) => (
              <li key={option.idKustomer} {...props}>
                {option.namaLengkap}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                variant="filled"
                label="PIC*"
                {...params}
                placeholder="Search "
                sx={{
                  width: "100%",
                  bgcolor: "#FAFAFA",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              />
            )}
          />
        </Grid>

        {/* Pic Number */}
        <Grid item xs={12}>
          <CustomInput
            disabled
            label="Phone Number*"
            variant="filled"
            sx={{
              width: "100%",
              bgcolor: "#FAFAFA",
              fontSize: "14px",
              fontWeight: 500,
            }}
            placeholder="Enter phone number"
            type="number"
            value={picNumber}
            onChange={(e) => {
              dispatch(changePicNumber(e.target.value));
            }}
          />
        </Grid>

        {/*  Address */}
        <Grid item xs={12} onClick={() => setIsMapOpen(!isMapOpen)}>
          <CustomCard
            sx={{ p: "8px 16px", cursor: "pointer" }}
            // onClick={() => setIsMapOpen(!isMapOpen)}
          >
            <Box
              sx={{
                display: "center",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box
                  sx={{
                    width: "66px",
                    height: "66px",
                    bgcolor: "gray",
                    borderRadius: "8px",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      bgcolor: "white",
                      width: "100%",
                      height: "100%",
                      zIndex: "+2",
                      opacity: 0.5,
                    }}
                  ></Box>
                  <Box
                    sx={{
                      maxHeight: "66px",
                      overflow: "hidden",
                      borderRadius: "8px",
                    }}
                  >
                    <Map
                      defaultCenter={coordinate}
                      height={130}
                      defaultZoom={17}
                      width={66}
                      style={{
                        borderRadius: "8px",
                      }}
                    ></Map>
                  </Box>
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: "500", mb: "4px", ml: "8px" }}>
                    Address
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.4,
                    }}
                  >
                    <LocationOnIcon color="primary" />
                    <Typography>{address}</Typography>
                  </Box>
                </Box>
              </Box>
              <KeyboardArrowDownIcon />
            </Box>
          </CustomCard>
        </Grid>

        {/* publish */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              Publish
            </Typography>
            <CustomAntSwitch
              checked={status}
              onChange={(e) => handleEditStatus(e)}
            />
          </Box>
        </Grid>
      </Grid>
      {/* button event */}
      <Box
        sx={{
          width: "100%",
          bgcolor: "#FFFFFF",
          position: "sticky",
          bottom: 0,
          padding: "8px 0px",
          zIndex: 99,
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomButton
            variant="contained"
            color="error"
            sx={{ width: "100%", height: "36px" }}
            // onClick={handleDelete}
          >
            <Typography
              sx={{
                color: "white",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              Delete
            </Typography>
          </CustomButton>
          <CustomButton
            variant="contained"
            sx={{ width: "100%", height: "36px" }}
            onClick={() => handleSave()}
          >
            <Typography
              sx={{
                color: "white",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              Save
            </Typography>
          </CustomButton>
        </Box>
      </Box>

      {/*  Dialog Photo */}
      <Dialog
        open={isPhotoPreview}
        onClose={() => {
          dispatch(clickIsPreviewPhoto());
          dispatch(changePhotoPreview(""));
        }}
      >
        <Box
          component="img"
          src={
            process.env.REACT_APP_API_IMAGE_BASE_URL + "/" + linkPhotoPreview
          }
          sx={{ maxWidth: "300px", maHeight: "300px", aspectRation: "1/1" }}
        />
      </Dialog>
      {/*  Video Dialog */}
      <Dialog
        open={isVideoPreview}
        onClose={() => {
          dispatch(clickIsPreviewVideo());
          dispatch(changeVideoPreview(""));
        }}
      >
        <Box sx={{ bgcolor: "black" }}>
          <video
            style={{
              maxWidth: "400px",
              maxHeight: "300px",
              backgroundColor: "black",
            }}
            controls
          >
            <source
              src={
                process.env.REACT_APP_API_IMAGE_BASE_URL +
                "/" +
                linkVideoPreview
              }
            />
          </video>
        </Box>
      </Dialog>
      {/*  Dialog Address */}
      <Dialog
        open={isMapOpen}
        onClose={() => {
          setIsMapOpen(!isMapOpen);
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>Input Address</Box>
          <CloseIcon
            sx={{ cursor: "pointer" }}
            onClick={() => setIsMapOpen(false)}
          />
        </DialogTitle>
        <DialogContent dividers>
          <SetupAddressMobile setIsOpen={setIsMapOpen} />
        </DialogContent>
      </Dialog>

      <CustomEditConfirm
        open={isEditConfirm}
        onClose={() => setIsEditConfirm(false)}
        handleClickCancel={() => setIsEditConfirm(false)}
        handleClickEdit={handleSaveFix}
        title="Edit Product"
        body={
          <Box sx={{ textAlign: "center", mb: "40px", mt: "8px" }}>
            <Typography>Are you sure you want to save the changes?</Typography>
            {isLoadingUpdate && (
              <CircularProgress size={16} sx={{ mt: "12px" }} />
            )}
          </Box>
        }
      />
    </Box>
  );
};
export default EditMasterHubMobile;
