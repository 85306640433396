import { createSlice } from '@reduxjs/toolkit';

export const detailAuditState = createSlice({
  name: 'detailAudit',
  initialState: {
    id: null,
    hub: '',
  },
  reducers: {
    changeIdDetailAudit: (state, action) => {
      state.id = action.payload;
    },
    changeHubDetailAudit: (state, action) => {
      state.hub = action.payload;
    },
  },
});

export const { changeIdDetailAudit, changeHubDetailAudit } =
  detailAuditState.actions;

export const getIdDetailAudit = (state) => state.detailAudit.id;
export const getHubDetailAudit = (state) => state.detailAudit.hub;

export default detailAuditState.reducer;
