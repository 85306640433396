import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import CustomTable from "../../../../../../../components/shared/CustomTable";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CustomButton from "../../../../../../../components/shared/CustomButton";
import CustomCard from "../../../../../../../components/shared/CustomCard";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as ColumnFilterIcon } from "../../../../../../../assets/colum-filter-icon.svg";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CustomMenu from "../../../../../../../components/shared/CustomMenu";
import CustomDialog from "../../../../../../../components/shared/CustomDialog";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import { TextFields } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import { ReactComponent as NoImage } from "../../../../../../../assets/no-image.svg";
import { ReactComponent as CloseFilledIcon } from "../../../../../../../assets/closeFilledIcon.svg";
import {
  ExitToApp as ExitToAppIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from "@mui/icons-material";
import { fetchCategories } from "../../../../../../../api/products";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import ExportXlsExcel from "../../../../../../../components/ExportXlsExcel";

const CustomTableCells = styled(TableCell)({
  color: "#000000",
  fontWeight: 500,
  fontSize: "0.75rem",
  textTransform: "capitalize",
});

const TableCategoryProduct = (props) => {
  const {
    handleClickRow,
    handleClickName,
    isLoadingData,
    // *---
    page,
    setPage,
    roadHirarki,
    setRoadHirarki,
    setIsCategoryProductAdd,
    setIsCategoryProductEdit,
    setIsCategoryProductDetails,
    //* Table
    tableRowPerPageValues,
    tableRowPerPage,
    setTableRowPerPage,
    tableCurrentPage,
    setTableCurrentPage,
    tableTotalItems,
    setTableTotalItems,
    tableSearch,
    setTableSearch,
    sortTable,
    setSortTable,
    isSortAsc,
    setIsSortAsc,
    tableDatas,
    setTableDatas,
    tableCategoryFilterDatas,
    setTableCategoryFilterDatas,
    // *-------
    isLoadingSubsidiary,
    subsidiaryDatas,
    // *----- type ----
    typeCategory,
    setTypeCategorys,
    typeCategoryLoading,
    setTypeCategoryLoading,
    // *------ filter
    filterCategorySubsidiary,
    setFilterCategorySubsidiary,
    filterCategoryType,
    setFilterCategoryType,
    autoCompleteFilter,
    setAutoCompleteFilter,
    isOpenTableFilter,
    setIsOpenTableFilter,
    subsidiaryFilterValue,
    setSubsidiaryFilterValue,
    typeFilterValue,
    setTypeFilterValue,
    handleClickSaveFilter,
    handleClickResetFilter,
    // * -- Data All
    allData,
    allDataType,
  } = props;
  const theme = useTheme();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [dataTypeSelected, setDataTypeSelected] = useState(["cogs", "sku"]);

  const [beforeSortTable, setBeforeSortTable] = useState(sortTable);

  useEffect(() => {
    if (sortTable !== beforeSortTable) {
      setIsSortAsc(true);
      setBeforeSortTable(sortTable);
    }
  }, [sortTable, beforeSortTable, setIsSortAsc]);

  const [openColumnFilter, setOpenColumnFilter] = useState(null);
  const isOpenColumnFilter = Boolean(openColumnFilter);

  const [openExport, setOpenExport] = useState(null);
  const isOpenExport = Boolean(openExport);

  // !----------------------- API FOR EXPORT  --------------------------
  const [dataForExports, setDataForExports] = useState([]);

  useEffect(() => {
    const categoryDataForExport = async () => {
      try {
        let datas = {
          pageSize: tableTotalItems,
          page: 1,
          name: "asc",
        };
        const {
          data: { data: dataCategories },
        } = await fetchCategories(datas);
        const resultFix = dataCategories.categories.map(
          (
            {
              idKategori,
              namaKategori,
              subsidiary,
              categoryOption,
              productsSumHarga,
              productsCount,
              schedule,
            },
            index
          ) => ({
            number: index + 1,
            name: namaKategori || "-",
            subsidiary: subsidiary ? subsidiary.name : "-",
            type: categoryOption ? categoryOption.nmOpsi : "-",
            cogs: productsSumHarga ? parseInt(productsSumHarga) : "-",
            sku: productsCount || "-",
            schedule: schedule ? dayjs(schedule).format("DD/MMM/YYYY") : "-",
          })
        );

        setDataForExports(resultFix);
      } catch {
        setDataForExports([]);
      } finally {
      }
    };
    if (tableDatas.length) {
      if (openExport) {
        if (dataForExports.length === 0) {
          categoryDataForExport();
        }
      }
    }
  }, [tableDatas, isOpenExport, tableTotalItems, sortTable, dataForExports]);

  // !----------------------- EXPORT  --------------------------
  const handleExportPdf = () => {
    const doc = new jsPDF();
    doc.text("Table Category", 20, 10);
    doc.autoTable({
      theme: "grid",
      columns: [
        { title: "No", dataKey: "number" },
        { title: "Nama", dataKey: "name" },
        { title: "Subsidiary", dataKey: "subsidiary" },
        { title: "COGS", dataKey: "cogs" },
        { title: "SKU", dataKey: "sku" },
        { title: "Schedule", dataKey: "schedule" },
      ],
      body: dataForExports,
    });
    doc.save("category-table.pdf");
    setOpenExport(null);
  };

  const handleExportExcel = () => {
    setOpenExport(null);
  };

  // !----------------------- Habndler  --------------------------

  // *! --------------------- TOOLBAR -----------------------
  const toolbar = (
    <Box sx={{ display: "flex", gap: 2, flexDirection: "row-reverse" }}>
      {/* add */}
      <CustomButton
        sx={{
          bgcolor: "#51B15C",
          color: "#ffffff",
          borderRadius: "8px",
          "&:hover": {
            bgcolor: "#51B15C",
            color: "#ffffff",
          },
          fontSize: "0.875rem",
          px: 2,
        }}
        startIcon={<AddIcon sx={{ width: "14px" }} />}
        onClick={() => {
          setPage("category");
          setIsCategoryProductAdd(true);
        }}
      >
        Add
      </CustomButton>
      <IconButton
        sx={{
          border: "1.5px solid #51B15C",
          borderRadius: "8px",
          p: 1,
        }}
        onClick={(e) => setOpenColumnFilter(e.currentTarget)}
        aria-haspopup="true"
      >
        <ColumnFilterIcon fill="#51B15C" />
      </IconButton>
      <CustomMenu
        open={isOpenColumnFilter}
        openMenu={openColumnFilter}
        setOpenMenu={setOpenColumnFilter}
        sx={{
          ".MuiMenuItem-root": {
            paddingLeft: 0,
            paddingRight: "20px",
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", p: "12px" }}
        >
          <Typography sx={{ fontWeight: "400", fontSize: "12px" }}>
            Max 7
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "12px",
              color: theme.palette.primary.main,
              cursor: "pointer",
            }}
            onClick={() => setDataTypeSelected(["type", "cogs", "sku"])}
          >
            Reset
          </Typography>
        </Box>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem>
          <Checkbox checked={true} />
          Name*
        </MenuItem>
        <MenuItem>
          <Checkbox checked={true} />
          Photo*
        </MenuItem>
        <MenuItem>
          <Checkbox checked={true} />
          Subsidiary*
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes('type')) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== 'type')
              );
            } else {
              setDataTypeSelected((prev) => [...prev, 'type']);
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes('type')} />
          Type
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes("cogs")) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== "cogs")
              );
            } else {
              setDataTypeSelected((prev) => [...prev, "cogs"]);
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes("cogs")} />
          COGS
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes("sku")) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== "sku")
              );
            } else {
              setDataTypeSelected((prev) => [...prev, "sku"]);
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes("sku")} />
          SKU
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes("schedule")) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== "schedule")
              );
            } else {
              setDataTypeSelected((prev) => [...prev, "schedule"]);
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes("schedule")} />
          Schedule
        </MenuItem>
      </CustomMenu>
      {/* Export */}
      <CustomButton
        startIcon={<ExitToAppIcon />}
        endIcon={<ArrowDropDownIcon />}
        onClick={(e) => setOpenExport(e.currentTarget)}
        aria-haspopup="true"
        sx={{
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          bgcolor: "#FFFFFF",
          color: "#000000",
          fontSize: "0.875rem",
          "&:hover": {
            bgcolor: "#FFFFFF",
            color: "#000000",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          },
          px: 2,
          py: 0.8,
        }}
      >
        Export
      </CustomButton>
      <CustomMenu
        open={isOpenExport}
        openMenu={openExport}
        setOpenMenu={setOpenExport}
        PaperProps={{ sx: { minWidth: "121px" } }}
      >
        <MenuItem onClick={handleExportPdf}>PDF</MenuItem>
        <ExportXlsExcel
          sx={{ color: "#000000" }}
          excelData={dataForExports}
          fileName="Category Product "
        >
          <MenuItem>XLS</MenuItem>
        </ExportXlsExcel>
        <CSVLink
          style={{ color: "#000000", textDecoration: "none" }}
          data={dataForExports}
        >
          <MenuItem onClick={handleExportExcel}>CSV</MenuItem>
        </CSVLink>
      </CustomMenu>
      {/* Filter Dialog*/}
      <CustomCard
        sx={{
          p: "10px 20px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          gap: 1,
          cursor: "pointer",
        }}
        onClick={() => setIsOpenTableFilter(true)}
      >
        <FilterAltIcon sx={{ fontSize: "20px" }} />
        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
          Filter
        </Typography>
      </CustomCard>
      {isOpenTableFilter && (
        <CustomDialog
          isOpen={isOpenTableFilter}
          setIsOpen={setIsOpenTableFilter}
          fullWidth
        >
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.125rem",
                  fontWeight: 600,
                  color: "#000000",
                }}
                component="span"
              >
                Filter
              </Typography>
              <IconButton
                aria-label="close"
                onClick={() => setIsOpenTableFilter(!isOpenTableFilter)}
                sx={{
                  color: "#323232",
                  p: 0,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            {/* subsidiary */}
            <Box sx={{ mt: "8px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                  Subsidiary
                </Typography>
                {!autoCompleteFilter.subsidiary && (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#F33A3A",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSubsidiaryFilterValue(subsidiaryDatas);
                      setAutoCompleteFilter({
                        ...autoCompleteFilter,
                        subsidiary: true,
                        subsidiaryOpen: false,
                      });
                    }}
                  >
                    Clear
                  </Typography>
                )}
              </Box>
              {/* Select */}
              <Box>
                <Autocomplete
                  sx={{
                    ".MuiAutocomplete-hasClearIcon": {
                      display: "none",
                    },
                  }}
                  clearIcon={
                    autoCompleteFilter.subsidiary &&
                    !autoCompleteFilter.subsidiaryOpen ? (
                      false
                    ) : (
                      <ClearIcon fontSize="small" />
                    )
                  }
                  multiple={
                    autoCompleteFilter.subsidiary &&
                    !autoCompleteFilter.subsidiaryOpen
                      ? false
                      : true
                  }
                  limitTags={
                    autoCompleteFilter.subsidiary &&
                    !autoCompleteFilter.subsidiaryOpen
                      ? 0
                      : 3
                  }
                  options={
                    autoCompleteFilter.subsidiary &&
                    !autoCompleteFilter.subsidiaryOpen
                      ? allData
                      : subsidiaryDatas
                  }
                  getOptionLabel={(option) => option.name}
                  value={
                    autoCompleteFilter.subsidiary &&
                    !autoCompleteFilter.subsidiaryOpen
                      ? allData[0]
                      : subsidiaryFilterValue
                  }
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Box
                        sx={{
                          bgcolor: "white",
                          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
                          p: "4px 8px",
                          m: "2px",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        {option.name}
                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            setSubsidiaryFilterValue(
                              subsidiaryFilterValue.filter(
                                (item) => item.id !== option.id
                              )
                            )
                          }
                        >
                          <CloseFilledIcon />
                        </Box>
                      </Box>
                    ))
                  }
                  onChange={(event, value) => {
                    setSubsidiaryFilterValue(value);
                    if (value.length === subsidiaryDatas.length) {
                      setAutoCompleteFilter({
                        ...autoCompleteFilter,
                        subsidiary: true,
                      });
                    } else {
                      setAutoCompleteFilter({
                        ...autoCompleteFilter,
                        subsidiary: false,
                      });
                    }
                  }}
                  onOpen={() => {
                    setAutoCompleteFilter({
                      ...autoCompleteFilter,
                      subsidiaryOpen: true,
                    });
                  }}
                  onClose={() => {
                    setAutoCompleteFilter({
                      ...autoCompleteFilter,
                      subsidiaryOpen: false,
                    });
                  }}
                  selectOnFocus
                  disableCloseOnSelect
                  renderOption={(props, option, { selected }) => (
                    <li {...props} sx={{ color: "black" }} key={option.id}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  style={{ width: "100%", marginTop: "12px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder="Enter Subsidiarys"
                      sx={{ bgcolor: "#FAFAFA" }}
                    />
                  )}
                />
              </Box>
            </Box>
            {/* Type */}
            {/* <Box sx={{ mt: '16px', mb: '20px' }}> */}
            {/* <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                  Type
                </Typography>
                {!autoCompleteFilter.type && (
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                      color: '#F33A3A',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setTableCategoryFilterDatas({
                        ...tableCategoryFilterDatas,
                        typeDatas: typeCategory,
                      });
                      setAutoCompleteFilter({
                        ...autoCompleteFilter,
                        type: true,
                        typeOpen: false,
                      });
                    }}
                  >
                    Clear
                  </Typography>
                )}
              </Box> */}
            {/* Select Type*/}
            {/* <Box>
                <Autocomplete
                  sx={{
                    '.MuiAutocomplete-hasClearIcon': {
                      display: 'none',
                    },
                  }}
                  clearIcon={
                    autoCompleteFilter.type && !autoCompleteFilter.typeOpen ? (
                      false
                    ) : (
                      <ClearIcon fontSize="small" />
                    )
                  }
                  multiple={
                    autoCompleteFilter.type && !autoCompleteFilter.typeOpen
                      ? false
                      : true
                  }
                  limitTags={
                    autoCompleteFilter.type && !autoCompleteFilter.typeOpen
                      ? 0
                      : 3
                  }
                  options={
                    autoCompleteFilter.type && !autoCompleteFilter.typeOpen
                      ? allDataType
                      : typeCategory
                  }
                  getOptionLabel={(option) => option.nmOpsi}
                  value={
                    autoCompleteFilter.type && !autoCompleteFilter.typeOpen
                      ? allDataType[0]
                      : typeFilterValue
                  }
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Box
                        sx={{
                          bgcolor: 'white',
                          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                          p: '4px 8px',
                          m: '2px',
                          borderRadius: '8px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        {option.nmOpsi}
                        <Box
                          sx={{ cursor: 'pointer' }}
                          onClick={() =>
                            setTypeFilterValue(
                              typeFilterValue.filter(
                                (item) => item.iDopsi !== option.iDopsi
                              )
                            )
                          }
                        >
                          <CloseFilledIcon />
                        </Box>
                      </Box>
                    ))
                  }
                  onChange={(event, value) => {
                    setTypeFilterValue(value);
                    if (value?.length === typeCategory?.length) {
                      setAutoCompleteFilter({
                        ...autoCompleteFilter,
                        type: true,
                      });
                    } else {
                      setAutoCompleteFilter({
                        ...autoCompleteFilter,
                        type: false,
                      });
                    }
                  }}
                  onOpen={() => {
                    setAutoCompleteFilter({
                      ...autoCompleteFilter,
                      typeOpen: true,
                    });
                  }}
                  onClose={() => {
                    setAutoCompleteFilter({
                      ...autoCompleteFilter,
                      typeOpen: false,
                    });
                  }}
                  selectOnFocus
                  disableCloseOnSelect
                  renderOption={(props, option, { selected }) => (
                    <li {...props} sx={{ color: 'black' }}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.nmOpsi}
                    </li>
                  )}
                  style={{ width: '100%', marginTop: '12px' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder="Enter Type"
                      sx={{ bgcolor: '#FAFAFA' }}
                    />
                  )}
                />
              </Box> */}
            {/* </Box> */}
          </DialogContent>
          {/* Bawah */}
          <Box
            sx={{
              display: "flex",
              bgcolor: "#FAFAFA",
              alignItems: "center",
              justifyContent: "end",
              padding: "16px 24px",
              gap: 3,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  cursor: "pointer",
                  color: theme.palette.primary.main,
                }}
                onClick={handleClickResetFilter}
              >
                Reset
              </Typography>
            </Box>
            <Box>
              <CustomButton
                variant="contained"
                sx={{ width: "100px", height: "42px" }}
                onClick={handleClickSaveFilter}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                  Save
                </Typography>
              </CustomButton>
            </Box>
          </Box>
        </CustomDialog>
      )}
    </Box>
  );

  // *! ------------------------- END -----------------------------------------------------------------------
  // *! ------------------------- END -----------------------------------------------------------------------

  return (
    <Box>
      <CustomTable
        name="Category"
        rowPerPage={tableRowPerPage}
        handleRowPerPage={(event) => {
          setTableRowPerPage(event.target.value);
          setTableCurrentPage(1);
        }}
        searchValue={tableSearch}
        setSearchValue={setTableSearch}
        rowPerPageValues={tableRowPerPageValues}
        currentPage={tableCurrentPage}
        totalValueItems={tableTotalItems}
        isNoData={!isLoadingData && tableDatas.length === 0 ? true : false}
        noDataText="No Category"
        handleInputCurrentPage={(event) => {
          if (
            event.target.value >= Math.ceil(tableTotalItems / tableRowPerPage)
          ) {
            setTableCurrentPage(Math.ceil(tableTotalItems / tableRowPerPage));
          } else if (event.target.value <= 1) {
            setTableCurrentPage(1);
          } else {
            setTableCurrentPage(parseInt(event.target.value));
          }
        }}
        handleBackArrow={() =>
          setTableCurrentPage(
            tableCurrentPage <= 1 ? tableCurrentPage : tableCurrentPage - 1
          )
        }
        handleForwardArrow={() =>
          setTableCurrentPage(
            tableCurrentPage >= Math.ceil(tableTotalItems / tableRowPerPage)
              ? tableCurrentPage
              : tableCurrentPage + 1
          )
        }
        toolbar={toolbar}
      >
        <TableHead>
          <TableRow sx={{ bgcolor: "#FAFAFA" }}>
            <CustomTableCells>No.</CustomTableCells>
            <CustomTableCells>Photo</CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSortTable("name");
                  setIsSortAsc(!isSortAsc);
                  setTableCurrentPage(1);
                }}
              >
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "500", mr: "2px" }}
                >
                  Name
                </Typography>
                {sortTable === "name" ? (
                  isSortAsc ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(90deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(270deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: "12px",
                      transform: "rotate(90deg)",
                      color: "#9E9D9D",
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
            <CustomTableCells>Subsidiary</CustomTableCells>
            {/* {dataTypeSelected.includes('type') && <TableCell>Type</TableCell>} */}
            {dataTypeSelected.includes("cogs") && (
              <CustomTableCells align="right">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSortTable("cogs");
                    setIsSortAsc(!isSortAsc);
                    setTableCurrentPage(1);
                  }}
                >
                  <Typography
                    sx={{ fontSize: "12px", fontWeight: "500", mr: "2px" }}
                  >
                    COGS(Rp)*
                  </Typography>
                  {sortTable === "cogs" ? (
                    isSortAsc ? (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: "12px",
                          transform: "rotate(90deg)",
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: "12px",
                          transform: "rotate(270deg)",
                          color: theme.palette.primary.main,
                        }}
                      />
                    )
                  ) : (
                    <SyncAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(90deg)",
                        color: "#9E9D9D",
                      }}
                    />
                  )}
                </Box>
              </CustomTableCells>
            )}
            {dataTypeSelected.includes("sku") && (
              <CustomTableCells align="right">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSortTable("sku");
                    setIsSortAsc(!isSortAsc);
                    setTableCurrentPage(1);
                  }}
                >
                  <Typography
                    sx={{ fontSize: "12px", fontWeight: "500", mr: "2px" }}
                  >
                    SKU
                  </Typography>
                  {sortTable === "sku" ? (
                    isSortAsc ? (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: "12px",
                          transform: "rotate(90deg)",
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: "12px",
                          transform: "rotate(270deg)",
                          color: theme.palette.primary.main,
                        }}
                      />
                    )
                  ) : (
                    <SyncAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(90deg)",
                        color: "#9E9D9D",
                      }}
                    />
                  )}
                </Box>
              </CustomTableCells>
            )}
            {dataTypeSelected.includes("schedule") && (
              <TableCell>Schedule</TableCell>
            )}
          </TableRow>
        </TableHead>
        {/*  Body */}

        {!isLoadingData ? (
          <TableBody>
            {tableDatas.map((data, index) => (
              <TableRow
                key={index}
                onClick={() =>
                  handleClickRow(
                    data.namaKategori,
                    data.idKategori,
                    data.subsidiary
                  )
                }
                sx={{ cursor: "pointer" }}
              >
                <CustomTableCells>{index + 1}</CustomTableCells>
                <CustomTableCells>
                  {data.gambar ? (
                    <img
                      src={
                        process.env.REACT_APP_API_IMAGE_BASE_URL +
                        "/" +
                        data.gambar.split(",")[0]
                      }
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "8px",
                      }}
                      alt=""
                    />
                  ) : (
                    // <Typography>{data.gambar.split(',')[0]}</Typography>
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#FAFAFA",
                        borderRadius: "2px",
                      }}
                    >
                      <NoImage />
                      <Typography sx={{ color: "#9E9D9D", fontSize: "6px" }}>
                        No photo
                      </Typography>
                    </Box>
                  )}
                </CustomTableCells>
                <CustomTableCells
                  onClick={(e) =>
                    handleClickName(
                      e,
                      data.namaKategori,
                      data.idKategori,
                      data.subsidiary
                    )
                  }
                  sx={{ cursor: "pointer" }}
                >
                  {data.namaKategori}
                </CustomTableCells>
                <CustomTableCells>
                  {data.subsidiary ? data?.subsidiary?.name : "-"}
                </CustomTableCells>
                {/* {dataTypeSelected.includes('type') && (
                  <CustomTableCells>
                    {!data?.categoryOption ? '-' : data?.categoryOption?.nmOpsi}
                  </CustomTableCells>
                )} */}
                {dataTypeSelected.includes("cogs") && (
                  <CustomTableCells align="right">
                    {data.productsSumHarga === null ? 0 : data.productsSumHarga}
                  </CustomTableCells>
                )}

                {dataTypeSelected.includes("sku") && (
                  <CustomTableCells align="right">
                    {data.productsCount === null ? 0 : data.productsCount}
                  </CustomTableCells>
                )}
                {dataTypeSelected.includes("schedule") && (
                  <CustomTableCells align="left">
                    {data.schedule === null
                      ? "-"
                      : dayjs(data.schedule).format("DD/MMM/YYYY")}
                  </CustomTableCells>
                )}
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            {[...Array(tableRowPerPage)].map((_, x) => (
              <TableRow key={x}>
                <TableCell>-</TableCell>
                <TableCell>
                  <Box sx={{ height: "40px" }}></Box>
                </TableCell>

                <TableCell>-</TableCell>
                {dataTypeSelected.map((_, i) => (
                  <TableCell key={i}>-</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </CustomTable>
    </Box>
  );
};
export default TableCategoryProduct;
