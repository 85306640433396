import { TextField } from "@mui/material";
import debounce from "lodash/debounce";
import { useDispatch } from "react-redux";
import { setSearchStockout } from "../../../../../../store/stockout/stockout";

/**
 * Component for searching stock out with input field.
 *
 * @param {string} placeholder - The placeholder for the search input.
 * @param {function} setSearch - The function to set the search value.
 * @returns {React.Element} SearchStockOut component.
 */

export default function SearchStockOut({ placeholder, setSearchInv }) {
  const handleChange = (event) => {
    const { value } = event.target;
    setSearchInv(value);
  };

  return (
    <TextField
      id="search"
      variant="standard"
      fullWidth
      placeholder={placeholder}
      onChange={handleChange}
      sx={{
        color: "red",
        ".MuiInput-root": {
          backgroundColor: "#FAFAFA",
          borderRadius: "8px",
          borderBottom: "1px solid #FAFAFA",
          height: "41px",
        },
        ".MuiInput-root:before": {
          borderBottom: "1px solid transparent",
        },
        ".MuiInput-root:after": {
          borderBottom: "1px solid transparent",
        },
        ".MuiInput-root:hover:not(.Mui-disabled, .Mui-error): before": {
          borderBottom: "1px solid transparent",
        },
        ".MuiInput-input": {
          paddingLeft: "10px",
          color: "#9e9e9e",
          fontFamily: "sans-serif",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "130%",
        },
      }}
    />
  );
}
