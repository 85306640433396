import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import CustomTable from "../../../../../../components/shared/CustomTable";
import { useEffect, useState } from "react";
import { getAllSubHub } from "../../../../../../api/hubs";
import { ReactComponent as ColumnFilterIcon } from "../../../../../../assets/colum-filter-icon.svg";
import { ReactComponent as NoImage } from "../../../../../../assets/no-image.svg";
import AddIcon from "@mui/icons-material/Add";
import {
  ExitToApp as ExitToAppIcon,
  ArrowDropDown as ArrowDropDownIcon,
  FilterAlt as FilterAltIcon,
  ArrowRightAlt as ArrowRightAltIcon,
  SyncAlt as SyncAltIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import CustomButton from "../../../../../../components/shared/CustomButton";
import CustomMenu from "../../../../../../components/shared/CustomMenu";
import { useDispatch } from "react-redux";
import { createSearchParams, useSearchParams } from "react-router-dom";
import ExportXlsExcel from "../../../../../../components/ExportXlsExcel";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import { loadSubHubPack } from "../../../../../../store/hub/hirarkiHub";
import { changeIdSubHub } from "../../../../../../store/hub/subHub/subHubDetails";
import { changeSubHubId } from "../../../../../../store/hub/miniHub/miniHubAdd";
import DialogFilter from "./DialogFilter";

const CustomTableCells = styled(TableCell)({
  color: "#000000",
  fontWeight: 500,
  fontSize: "0.75rem",
  textTransform: "capitalize",
});

const headCells = [
  {
    id: "number",
    numeric: false,
    disablePadding: false,
    title: "No",
    icon: false,
  },
  {
    id: "photo",
    numeric: false,
    disablePadding: false,
    title: "Photo",
    icon: false,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    title: "Name",
    icon: true,
    sortName: "name",
  },
  {
    id: "subsidiary",
    numeric: false,
    disablePadding: false,
    title: "Subsidiary",
  },
  {
    id: "pic",
    numeric: false,
    disablePadding: false,
    title: "PIC",
  },
  {
    id: "phoneNumber",
    numeric: false,
    disablePadding: false,
    title: "Phone Number",
  },
  {
    id: "sku",
    numeric: false,
    disablePadding: false,
    title: "SKU",
    icon: true,
    sortName: "sku",
  },
  {
    id: "cogs",
    numeric: false,
    disablePadding: false,
    title: "COGS(Rp)*",
    icon: true,
    sortName: "cogs",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    title: "Address",
  },
];

const TableList = ({
  subHubDetail,
  setRowSelected,
  totalTableItems,
  hubsData,
  setIsOpenSubHub,
  setIsOpenNewSubHub,
  subsidiaryDatas,
  setFilterSubsidiaryArray,
  rowPerPageTable,
  setRowPerPageTable,
  setCurrentPageTable,
  rowPerPageValues,
  currentPageTable,
  isLoadingSubHub,
  subHubKeyword,
  setSubHubKeyword,
  sortTableSubHub,
  setSortTableSubHub,
  setIsSortAscSubHub,
  isSortAscSubHub,
  setMiniSubSelected,
  setSubHubSelected,
  setSubHubDetail,
  setSubHubDetailSelected,
  setIdSubsidiaryFix,
}) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);
  const [hubsToExport, setHubsToExport] = useState([]);
  const [openExport, setOpenExport] = useState(null);
  const isOpenExport = Boolean(openExport);
  const [isOpenDialogFilter, setIsOpenDialogFilter] = useState(false);
  const [openColumnFilter, setOpenColumnFilter] = useState(null);
  const isOpenColumnFilter = Boolean(openColumnFilter);
  const theme = useTheme();
  const [columnArray, setColumnArray] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState([]);
  const allData = [{ name: "All" }];
  const [subsidiaryFilterValue, setSubsidiaryFilterValue] = useState([]);

  useEffect(() => {
    setColumnArray(headCells);
  }, []);

  const handleSelectedColumn = (id) => {
    if (selectedColumn.includes(id)) {
      setSelectedColumn([...selectedColumn].filter((value) => value !== id));
    } else {
      setSelectedColumn([...selectedColumn, id]);
    }
  };

  const isSelected = (name) => selectedColumn.indexOf(name) !== -1;

  useEffect(() => {
    setSelectedColumn(
      columnArray
        .slice(0, searchParams.get("s") || 6)
        .map((headCell) => headCell.id)
    );
  }, [columnArray, searchParams]);

  // fetch data sub hub
  useEffect(() => {
    const fetchSubHub = async () => {
      let params = {};
      params.page = 1;
      params.pageSize = totalTableItems;
      params.name = "asc";

      try {
        const {
          data: {
            data: { subHubs },
          },
        } = await getAllSubHub(params);
        const bodyTable = subHubs.map(
          (
            { name, subsidinary, picName, picPhone, SKU, COGS, alamat },
            index
          ) => ({
            number: index + 1,
            name: name || "-",
            subsidinary: subsidinary || "-",
            pic: picName || "-",
            phoneNumber: picPhone || "-",
            sku: SKU || "-",
            cogs: COGS || "-",
            address: alamat || "-",
          })
        );
        setHubsToExport(bodyTable);
      } catch (err) {
        console.error(err);
        setHubsToExport([]);
      }
    };

    if (hubsData?.length) {
      if (openExport) {
        if (hubsToExport?.length === 0) {
          fetchSubHub();
        }
      }
    }
  }, [hubsData, hubsToExport, openExport, totalTableItems]);

  // download pdf
  const tempColumns = [...headCells];
  const columnWithoutPhoto = tempColumns.splice(1, 1);

  const downloadPdf = () => {
    const doc = new jsPDF();
    doc.text("Table Master Hub", 20, 10);
    doc.autoTable({
      theme: "grid",
      columns: tempColumns.map((col) => ({
        ...col,
        dataKey: col.id,
      })),
      body: hubsToExport,
    });
    doc.save("table.pdf");
    setOpenExport(null);
  };

  const [autocompleteFilter, setAutocompleteFilter] = useState({
    isSubsidiaryFull: false,
    isSubsidiaryOpen: false,
  });

  const handleSaveFilter = () => {
    const subsidiaryChoose = [];

    subsidiaryFilterValue.map(({ id }) => subsidiaryChoose.push(id));
    setFilterSubsidiaryArray(subsidiaryChoose);
    setIdSubsidiaryFix(subsidiaryChoose);

    setIsOpenDialogFilter(false);
  };

  const handleResetFilter = () => {
    setSubsidiaryFilterValue([]);
    setAutocompleteFilter({
      ...autocompleteFilter,
      isSubsidiaryFull: false,
      isSubsidiaryOpen: false,
    });
  };

  const toolbar = (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        flexDirection: "row-reverse",
        alignItems: "center",
      }}
    >
      <CustomButton
        sx={{
          bgcolor: "#51B15C",
          color: "#ffffff",
          borderRadius: "8px",
          "&:hover": {
            bgcolor: "#51B15C",
            color: "#ffffff",
          },
          fontSize: "0.875rem",
          px: 2,
        }}
        startIcon={<AddIcon sx={{ width: "14px" }} />}
        onClick={() => {
          setSubHubSelected(null);
          setIsOpenNewSubHub(true);
          setSubHubDetail = { setSubHubDetail };
          updatedSearchParams.delete("subhub-overview");
          setSearchParams(updatedSearchParams);
        }}
      >
        Add
      </CustomButton>

      <IconButton
        sx={{
          border: "1.5px solid #51B15C",
          borderRadius: "8px",
          p: 1,
        }}
        onClick={(e) => setOpenColumnFilter(e.currentTarget)}
        aria-haspopup="true"
      >
        <ColumnFilterIcon fill="#51B15C" />
      </IconButton>

      <CustomMenu
        open={isOpenColumnFilter}
        openMenu={openColumnFilter}
        setOpenMenu={setOpenColumnFilter}
        sx={{
          ".MuiMenuItem-root": {
            paddingLeft: 0,
            paddingRight: "20px",
            paddingTop: 0,
            paddingBottom: 0,
            borderRadius: "8px",
          },
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", p: "12px" }}
        >
          <Typography sx={{ fontWeight: "400", fontSize: "12px" }}>
            Max 7
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "12px",
              color: theme.palette.primary.main,
              cursor: "pointer",
            }}
            onClick={() =>
              setSelectedColumn(
                columnArray
                  .slice(0, searchParams.get("s") || 6)
                  .filter((data) => data.id !== "number")
                  .map((headCell) => headCell.id)
              )
            }
          >
            Reset
          </Typography>
        </Box>
        <Divider sx={{ my: 0.5 }} />

        {columnArray
          .filter((data) => data.id !== "number")
          .map((headCell, index) => {
            const isItemSelected = isSelected(headCell.id);
            return (
              <MenuItem key={index}>
                <Checkbox
                  checked={isItemSelected}
                  onChange={() => handleSelectedColumn(headCell.id)}
                  inputProps={{ "aria-label": "controlled" }}
                  disabled={!isItemSelected && selectedColumn.length >= 7}
                />
                {headCell.title}
              </MenuItem>
            );
          })}
      </CustomMenu>

      <CustomButton
        startIcon={<ExitToAppIcon />}
        endIcon={<ArrowDropDownIcon />}
        onClick={(e) => {
          setOpenExport(e.currentTarget);
        }}
        aria-haspopup="true"
        sx={{
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          bgcolor: "#FFFFFF",
          color: "#000000",
          fontSize: "0.875rem",
          "&:hover": {
            bgcolor: "#FFFFFF",
            color: "#000000",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          },
          px: 2,
          py: 0.8,
        }}
      >
        Export
      </CustomButton>

      <CustomMenu
        open={isOpenExport}
        openMenu={openExport}
        setOpenMenu={setOpenExport}
        PaperProps={{ sx: { minWidth: "121px" } }}
      >
        <MenuItem
          onClick={() => {
            setOpenExport(null);
            downloadPdf();
          }}
          sx={{ color: "#000000" }}
        >
          PDF
        </MenuItem>
        <MenuItem>
          <ExportXlsExcel
            sx={{ color: "#000000" }}
            fileName="Master Hub Excel"
            excelData={hubsToExport}
          />
        </MenuItem>
        <CSVLink
          style={{ color: "#000000", textDecoration: "none" }}
          data={hubsToExport}
        >
          <MenuItem onClick={() => setOpenExport(null)}>CSV</MenuItem>
        </CSVLink>
      </CustomMenu>

      <CustomButton
        sx={{
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          bgcolor: "#FFFFFF",
          color: "#000000",
          fontSize: "0.875rem",
          "&:hover": {
            bgcolor: "#FFFFFF",
            color: "#000000",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          },
          px: 2,
          py: 0.8,
        }}
        startIcon={<FilterAltIcon />}
        onClick={() => setIsOpenDialogFilter(true)}
      >
        Filter
      </CustomButton>

      <DialogFilter
        isOpenDialogFilter={isOpenDialogFilter}
        setIsOpenDialogFilter={setIsOpenDialogFilter}
        autocompleteFilter={autocompleteFilter}
        setAutocompleteFilter={setAutocompleteFilter}
        allData={allData}
        subsidiaryDatas={subsidiaryDatas}
        setSubsidiaryFilterValue={setSubsidiaryFilterValue}
        subsidiaryFilterValue={subsidiaryFilterValue}
        setFilterSubsidiaryArray={setFilterSubsidiaryArray}
        handleSaveFilter={handleSaveFilter}
        handleResetFilter={handleResetFilter}
      />
    </Box>
  );

  return (
    <CustomTable
      rowPerPage={rowPerPageTable}
      handleRowPerPage={(event) => {
        setRowPerPageTable(event.target.value);
        setCurrentPageTable(1);
      }}
      rowPerPageValues={rowPerPageValues}
      currentPage={currentPageTable}
      totalValueItems={totalTableItems}
      handleInputCurrentPage={(event) => {
        if (
          event.target.value >= Math.ceil(totalTableItems / rowPerPageTable)
        ) {
          setCurrentPageTable(Math.ceil(totalTableItems / rowPerPageTable));
        } else if (event.target.value <= 1) {
          setCurrentPageTable(1);
        } else {
          setCurrentPageTable(event.target.value);
        }
      }}
      handleBackArrow={() =>
        setCurrentPageTable(
          currentPageTable <= 1 ? currentPageTable : currentPageTable - 1
        )
      }
      handleForwardArrow={() =>
        setCurrentPageTable(
          currentPageTable >= Math.ceil(totalTableItems / rowPerPageTable)
            ? currentPageTable
            : currentPageTable + 1
        )
      }
      name="Sub Hub List"
      toolbar={toolbar}
      isLoadingTable={isLoadingSubHub}
      searchValue={subHubKeyword}
      setSearchValue={setSubHubKeyword}
      isNoData={!isLoadingSubHub && hubsData?.length === 0 ? true : false}
      noDataText="No Data Found"
    >
      <TableHead>
        <TableRow sx={{ bgcolor: "#FAFAFA" }}>
          {columnArray
            .filter((value) => selectedColumn.includes(value.id))
            .map((headCell) => (
              <CustomTableCells
                sx={{
                  ":last-child": {
                    pr: 0.5,
                  },
                  color: headCell.color ? headCell.color : "#000000",
                  fontWeight: 500,
                }}
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.icon === true ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSortTableSubHub(headCell.sortName);
                      setIsSortAscSubHub(!isSortAscSubHub);
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.75rem",
                      }}
                    >
                      {headCell.title}
                    </Typography>

                    {sortTableSubHub === headCell.sortName ? (
                      isSortAscSubHub ? (
                        <ArrowRightAltIcon
                          sx={{
                            fontSize: "12px",
                            transform: "rotate(90deg)",
                            color: theme.palette.primary.main,
                          }}
                        />
                      ) : (
                        <ArrowRightAltIcon
                          sx={{
                            fontSize: "12px",
                            transform: "rotate(270deg)",
                            color: theme.palette.primary.main,
                          }}
                        />
                      )
                    ) : (
                      <SyncAltIcon
                        sx={{
                          fontSize: "12px",
                          transform: "rotate(90deg)",
                          color: "#9E9D9D",
                        }}
                      />
                    )}
                  </Box>
                ) : (
                  headCell.title
                )}
              </CustomTableCells>
            ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {isLoadingSubHub ? (
          <TableRow>
            <TableCell colSpan={selectedColumn?.length}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "74px",
                  gap: 2,
                }}
              >
                <CircularProgress size={24} />
                <Typography> Loading ...</Typography>
              </Box>
            </TableCell>
          </TableRow>
        ) : (
          hubsData?.map((dataTable, index) => {
            const {
              name,
              images,
              idToko,
              id,
              subsidiaryId,
              subsidiary,
              picName,
              picPhone,
              SKU,
              COGS,
              alamat,
            } = dataTable;
            return (
              <TableRow
                key={index}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setSubHubSelected(null);
                  setMiniSubSelected(id);
                  dispatch(changeSubHubId(id));
                  updatedSearchParams.set("minihub-overview", id);
                  setSearchParams(updatedSearchParams);
                }}
              >
                <CustomTableCells>{index + 1}</CustomTableCells>
                {selectedColumn.includes("photo") && (
                  <Tooltip
                    followCursor
                    title="Click to see mini-hub list"
                    arrow
                    placement="bottom"
                  >
                    <CustomTableCells>
                      {images ? (
                        <Box
                          component="img"
                          src={
                            process.env.REACT_APP_API_IMAGE_BASE_URL +
                            "/" +
                            images.split(",")[0]
                          }
                          sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "8px",
                          }}
                        />
                      ) : (
                        <NoImage />
                      )}
                    </CustomTableCells>
                  </Tooltip>
                )}

                {selectedColumn.includes("name") && (
                  <Tooltip
                    followCursor
                    arrow
                    title="Click to see hub detail"
                    placement="bottom"
                  >
                    <CustomTableCells
                      onClick={(event) => {
                        setSubHubSelected(null);
                        dispatch(changeIdSubHub(id));
                        setSubHubDetailSelected(id);
                        // setRowSelected(idToko);
                        // dispatch(changeMasterHub(idToko));
                        // dispatch(
                        //   loadSubHubPack({
                        //     subsidiary: subsidiary,
                        //     idSubsidiary: subsidiaryId,
                        //     subHub: name,
                        //     idSubHub: id,
                        //   })
                        // );
                        updatedSearchParams.set("detail", id);
                        setSearchParams(updatedSearchParams);

                        event.stopPropagation();
                      }}
                    >
                      {name || "-"}
                    </CustomTableCells>
                  </Tooltip>
                )}

                {selectedColumn.includes("subsidiary") && (
                  <CustomTableCells>{subsidiary || "-"}</CustomTableCells>
                )}

                {selectedColumn.includes("pic") && (
                  <CustomTableCells>{name || "-"}</CustomTableCells>
                )}

                {selectedColumn.includes("phoneNumber") && (
                  <CustomTableCells>{picPhone || "-"}</CustomTableCells>
                )}

                {selectedColumn.includes("sku") && (
                  <CustomTableCells>{SKU || "-"}</CustomTableCells>
                )}

                {selectedColumn.includes("cogs") && (
                  <CustomTableCells>{COGS || "-"}</CustomTableCells>
                )}

                {selectedColumn.includes("address") && (
                  <CustomTableCells>{alamat || "-"}</CustomTableCells>
                )}
              </TableRow>
            );
          })
        )}
      </TableBody>

      {/*
      {!isLoadingSubHub ? (
        <TableBody>
          {hubsData?.map((dataTable, index) => {
            const {
              name,
              images,
              idToko,
              id,
              subsidiaryId,
              subsidiary,
              picName,
              picPhone,
              SKU,
              COGS,
              alamat,
            } = dataTable;
            return (
              <TableRow
                key={index}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setSubHubSelected(null);
                  setMiniSubSelected(id);
                  dispatch(changeSubHubId(id));
                  updatedSearchParams.set("minihub-overview", id);
                  setSearchParams(updatedSearchParams);
                }}
              >
                <CustomTableCells>{index + 1}</CustomTableCells>
                <CustomTableCells>
                  {images ? (
                    <Box
                      component="img"
                      src={
                        process.env.REACT_APP_API_IMAGE_BASE_URL +
                        "/" +
                        images.split(",")[0]
                      }
                      sx={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "8px",
                      }}
                    />
                  ) : (
                    <NoImage />
                  )}
                </CustomTableCells>
                {selectedColumn.includes("name") && (
                  <CustomTableCells
                    onClick={(event) => {
                      setSubHubSelected(null);
                      dispatch(changeIdSubHub(id));
                      setSubHubDetailSelected(id);
                      // setRowSelected(idToko);
                      // dispatch(changeMasterHub(idToko));
                      // dispatch(
                      //   loadSubHubPack({
                      //     subsidiary: subsidiary,
                      //     idSubsidiary: subsidiaryId,
                      //     subHub: name,
                      //     idSubHub: id,
                      //   })
                      // );
                      updatedSearchParams.set("detail", id);
                      setSearchParams(updatedSearchParams);

                      event.stopPropagation();
                    }}
                  >
                    {name || '-'}
                    {name || "-"}
                  </CustomTableCells>
                )}

                {selectedColumn.includes("subsidiary") && (
                  <CustomTableCells>{subsidiary || "-"}</CustomTableCells>
                )}

                {selectedColumn.includes('pic') && (
                  <CustomTableCells>{picName || '-'}</CustomTableCells>
                {selectedColumn.includes("pic") && (
                  <CustomTableCells>{name || "-"}</CustomTableCells>
                )}

                {selectedColumn.includes("phoneNumber") && (
                  <CustomTableCells>{picPhone || "-"}</CustomTableCells>
                )}

                {selectedColumn.includes("sku") && (
                  <CustomTableCells>{SKU || "-"}</CustomTableCells>
                )}

                {selectedColumn.includes("cogs") && (
                  <CustomTableCells>{COGS || "-"}</CustomTableCells>
                )}

                {selectedColumn.includes("address") && (
                  <CustomTableCells>{alamat || "-"}</CustomTableCells>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      ) : (
        // <TableBody>
        //   {[...Array(rowPerPageTable)].map((_, x) => (
        //     <TableRow key={x}>
        //       {selectedColumn.map((_, i) => (
        //         <TableCell key={i}>-</TableCell>
        //       ))}
        //     </TableRow>
        //   ))}
        // </TableBody>
      )} */}
    </CustomTable>
  );
};
export default TableList;
