import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import useWindowDimensions from '../../../../../../../../hooks/useWindowDimensions';
import { changePageTransaction } from '../../../../../../../../store/Routing/transactionRouting';
import {
  ArrowBackIosIcon,
  ChevronRightIcon,
  CloseIcon,
  ScanTargetIcon,
} from '../../../../../../../../assets/Icons';
import CameraCapture from '../../../../../../../../components/shared/shared2/CameraCapture';
import CustomButton from '../../../../../../../../components/shared/CustomButton';
import {
  getAllDataAcceptTransactionScan,
  loadNoqrAcceptTransaction,
} from '../../../../../../../../store/transaction/request/acceptDataTransaction';

const ImageCaptureAcceptTransaction = () => {
  const dispatch = useDispatch();
  const { width: widthScreen } = useWindowDimensions();
  const { noqrDataSelect, dataRackDetail, rackId } = useSelector(
    getAllDataAcceptTransactionScan
  );
  const [openPrevirew, setOpenPreview] = useState(false);
  const [url, setUrl] = useState(null);

  const handleCapture = (value) => {
    if (value) {
      setUrl(value);
    }
  };

  useEffect(() => {
    url !== null ? setOpenPreview(true) : setOpenPreview(false);
  }, [url]);

  const handleOk = () => {
    dispatch(loadNoqrAcceptTransaction({ noqrDataSelect, url, rackId }));
    dispatch(changePageTransaction('acceptTransactionMobile'));
  };

  return (
    <Box>
      {/* back button */}
      <Box
        sx={{ py: '17px', display: 'flex', gap: 1, alignItems: 'center' }}
        onClick={() =>
          dispatch(changePageTransaction('acceptTransactionMobile'))
        }
      >
        <Box
          sx={{
            width: '24px',
            height: '24px',
            bgcolor: 'rgba(81, 177, 92, 0.05)',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ArrowBackIosIcon sx={{ fontSize: '14px', color: '#51B15C' }} />
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
            Product Photo
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Typography
              sx={{ fontWeight: 400, fontSize: '10px', color: '#9E9D9D' }}
            >
              {dataRackDetail?.namaHub}
            </Typography>
            <ChevronRightIcon sx={{ fontSize: '14px', color: '#9E9D9D' }} />
            <Typography
              sx={{ fontWeight: 400, fontSize: '10px', color: '#9E9D9D' }}
            >
              {dataRackDetail?.name}
            </Typography>
            <ChevronRightIcon sx={{ fontSize: '14px', color: '#9E9D9D' }} />
            <Typography
              sx={{ fontWeight: 400, fontSize: '10px', color: '#9E9D9D' }}
            >
              _
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          minHeight: '80vh',
          width: '100%',
          left: 0,
          bgcolor: 'black',
        }}
      >
        <Box sx={{ width: '100%', position: 'realtive' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 5,
              width: widthScreen,
              height: '600px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ScanTargetIcon />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 5,
              width: widthScreen,
              height: '600px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '315px',
                height: '370px',
                borderRadius: '25px',
                bgcolor: 'white',
                opacity: 0.2,
              }}
            ></Box>
          </Box>
          <CameraCapture
            width={widthScreen}
            height={600}
            delay={1000}
            onClick={(value) => handleCapture(value)}
          />
        </Box>
      </Box>

      <Dialog open={openPrevirew}>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography>Images</Typography>
            <CloseIcon
              onClick={() => {
                setOpenPreview(false);
                setUrl(null);
                // dispatch(setUrl(null));
              }}
            />
          </Box>
        </DialogTitle>
        <DialogContent sx={{ padding: '12px' }}>
          {url && (
            <Box
              component="img"
              src={url}
              sx={{ width: '300px', height: '300px', borderRadius: '8px' }}
            />
          )}

          <CustomButton
            variant="contained"
            sx={{ width: '100%' }}
            onClick={handleOk}
          >
            Ok
          </CustomButton>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default ImageCaptureAcceptTransaction;
