import React from 'react';
import { Box, Grid, InputAdornment, MenuItem, Typography } from '@mui/material';
import CustomDateRange from '../../../../../../../../components/shared/CustomDateRange';
import CustomInput from '../../../../../../../../components/shared/CustomInput';
import SearchIcon from '@mui/icons-material/Search';
import CustomSelect from '../../../../../../../../components/shared/CustomSelect';
import CustomCard from '../../../../../../../../components/shared/CustomCard';

const DetailMasterHubChart = ({
  masterHubRackDetailSelect,
  setMasterHubRackDetailSelect,
  dateRangeMasterHubRackDetail,
  setDateRangeMasterHubRackDetail,
  saveDateRangeMasterHubRackDetail,
  setSaveDateRangeMasterHubRackDetail,
  miniTableDetail,
  setMiniTableDetail,
  debounceMasterHubRackMiniTableDetail,
}) => {
  return (
    <CustomCard sx={{ p: 2 }}>
      <Grid container columnSpacing={2} alignItems="center">
        <Grid item xs={4}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '1rem',
              color: 'primary.black',
            }}
          >
            Product Peformance
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <CustomSelect
              inputProps={{
                sx: {
                  fontSize: '0.75rem',
                  fontWeight: 500,
                },
              }}
              sx={{
                '& .MuiSelect-icon': {
                  right: '4px',
                },
                '& .MuiSvgIcon-root': {
                  color: '#000',
                  fontSize: '1.25rem',
                },
                '& .MuiOutlinedInput-input': {
                  padding: '5px 6px 5px 10px',
                },
              }}
              value={masterHubRackDetailSelect}
              onChange={(e) => setMasterHubRackDetailSelect(e.target.value)}
            >
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
            </CustomSelect>
            <CustomDateRange
              dateRange={dateRangeMasterHubRackDetail}
              setDateRange={setDateRangeMasterHubRackDetail}
              saveDateRange={saveDateRangeMasterHubRackDetail}
              setSaveDateRange={setSaveDateRangeMasterHubRackDetail}
              sx={{ maxHeight: '34px', pl: '10px', fontSize: '12px' }}
            />
          </Box>
        </Grid>
        <Grid item xs>
          <CustomInput
            value={miniTableDetail}
            onChange={(e) => {
              setMiniTableDetail(e.target.value);
              debounceMasterHubRackMiniTableDetail(e.target.value);
            }}
            sx={{
              '& .MuiOutlinedInput-input': {
                borderRadius: '8px',
                paddingTop: '10px',
                paddingBottom: '10px',
                fontSize: '0.75rem',
              },
              '& .MuiOutlinedInput-root': {
                paddingLeft: '7px',
                backgroundColor: '#FAFAFA',
              },
            }}
            placeholder="Search"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ width: '18px' }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 3 }}>
        <Grid container columnSpacing={3}>
          <Grid item xs={8}>
            <Box>Graphic</Box>
          </Grid>
          <Grid item xs={4}>
            Mini Table
          </Grid>
        </Grid>
      </Box>
    </CustomCard>
  );
};

export default DetailMasterHubChart;
