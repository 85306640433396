import { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import dayjs from 'dayjs';
import BackButton from '../../../../../../components/shared/BackButton';
import CustomInput from '../../../../../../components/shared/CustomInput';
import CustomCard from '../../../../../../components/shared/CustomCard';
import { uploadImage } from '../../../../../../api/storage';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import AddMiniSubCategoryInSubCategory from '../../Product/SubCategoryProductAdd/Desktop/AddMiniSubCategoryInSubCategory';
import {
  addNewMiniSubCategoryMenu,
  addNewSubCategoryMenu,
} from '../../../../../../api/menu';
import CustomButton from '../../../../../../components/shared/CustomButton';

const SubCategoryMenuAddMobile = (props) => {
  const {
    theme,
    today,
    AntSwitch,
    roadHirarki,
    // *--- Notif
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
    // *--- page
    setPage,
    setSubCategoryMenu,
    // *--- Data Value
    subCategoryAddInputDatas,
    setSubCategoryAddInputDatas,
    // *--- submit
    isSubmitAvailable,
    setIsSubmitAvailable,
    // *--- Mini
    miniSub1,
    miniSub2,
    miniSub3,
    setMiniSub1,
    setMiniSub2,
    setMiniSub3,
    indexMiniSubCategory,
    setIndexMiniSubCategory,
    // *--- Handler
    handleBackButton,
    handleAddImage,
    handleDeleteImage,
    handleAdd,
  } = props;
  return (
    <Grid container columns={12} spacing={2}>
      {/* back button */}
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        <Box>
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
            Add subcategory
          </Typography>
          <Box
            sx={{
              display: 'flex',
              color: '#9E9D9D',

              alignItems: 'center',
              mt: '4px',
              gap: 0.25,
            }}
          >
            <Typography sx={{ fontSize: '8px', fontWeight: '100' }}>
              Subsidiary
            </Typography>
            <ChevronRightIcon sx={{ fontSize: '4px' }} />
            <Typography sx={{ fontSize: '8px', fontWeight: '100' }}>
              {roadHirarki.category}
            </Typography>
          </Box>
        </Box>
      </Grid>
      {/* Name */}
      <Grid item xs={12}>
        <CustomInput
          sx={{
            width: '100%',
            borderRadius: '8px',
            bgcolor: '#FAFAFA',
            '.MuiOutlinedInput-input': {
              fontSize: '12px',
              padding: '12px',
            },
          }}
          placeholder="Name*"
          value={subCategoryAddInputDatas.name}
          onChange={(e) => {
            if (e.target.value.length <= 30) {
              setSubCategoryAddInputDatas({
                ...subCategoryAddInputDatas,
                name: e.target.value,
              });
            }
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '8px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            Enter at least 5 character
          </Typography>
          <Typography
            sx={{
              fontSize: '8px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            {subCategoryAddInputDatas.name.length}/30
          </Typography>
        </Box>
      </Grid>
      {/* //* Photo */}
      <Grid item xs={12} sx={{ minHeight: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            pb: `${
              subCategoryAddInputDatas.photo.length === 0 ? '16px' : '9px'
            }`,
          }}
        >
          <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
            Photo
          </Typography>
          <Typography
            sx={{
              fontSize: '8px',
              fontWeight: '500',
              color: '#9E9D9D',
            }}
          >
            ( max size : 2MB )
          </Typography>
          {subCategoryAddInputDatas.isMaxSizePhoto && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ErrorOutlineIcon
                sx={{
                  fontSize: '8px',
                  fontWeight: '500',
                  color: theme.palette.warning.main,
                  mr: '4px',
                }}
              />
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  color: theme.palette.warning.main,
                }}
              >
                The photo can't be more than 2MB
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {subCategoryAddInputDatas.photo.length === 0 && (
              <Box
                sx={{
                  height: '54px',
                  width: '54px',
                  aspectRatio: '1/1',
                  borderRadius: '8px',
                  bgcolor: '#F5F5F5',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderStyle: 'dashed',
                  borderColor: '#9E9D9D',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  document.getElementById('inputSubCat_img').click();
                }}
              >
                <ImageOutlinedIcon
                  sx={{ color: '#9E9D9D', fontSize: '28px' }}
                />
              </Box>
            )}
            {subCategoryAddInputDatas.photo.map((input, index) => (
              <Box
                sx={{
                  position: 'relative',
                  overflow: 'visible',
                  padding: '7px',
                }}
                key={index}
              >
                <CancelIcon
                  sx={{
                    position: 'absolute',
                    fontSize: '20px',
                    top: 1,
                    right: 1,
                    zIndex: +1,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleDeleteImage(input, index)}
                />
                <Box
                  component="img"
                  src={URL.createObjectURL(input)}
                  sx={{
                    height: '54px',
                    width: '54px',
                    aspectRatio: '1/1',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSubCategoryAddInputDatas({
                      ...subCategoryAddInputDatas,
                      indexPreviePhoto: index + 1,
                      isPreviewPhoto: 'true',
                    });
                  }}
                />
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: 'flex',
              mb: '7px',
              ml: '20px',
              alignItems: 'center',
            }}
          >
            {subCategoryAddInputDatas.photo.length < 3 && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.palette.primary.main,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  document.getElementById('inputSubCat_img').click();
                }}
              >
                <input
                  accept="image/*"
                  id="inputSubCat_img"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleAddImage}
                />
                <AddIcon sx={{ fontSize: '16px' }} />
                <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
                  Add More
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      {/* //* DIalog Photo */}
      {subCategoryAddInputDatas.indexPreviePhoto !== 0 && (
        <Dialog
          open={subCategoryAddInputDatas.isPreviewPhoto}
          onClose={() => {
            setSubCategoryAddInputDatas({
              ...subCategoryAddInputDatas,
              isPreviewPhoto: !subCategoryAddInputDatas.isPreviewPhoto,
            });
          }}
        >
          <Box
            component="img"
            src={URL.createObjectURL(
              subCategoryAddInputDatas.photo[
                subCategoryAddInputDatas.indexPreviePhoto - 1
              ]
            )}
            sx={{ aspectRation: '1/1' }}
          />
        </Dialog>
      )}
      {/* //* Description */}
      <Grid item xs={12}>
        <CustomInput
          sx={{
            width: '100%',
            bgcolor: '#FAFAFA',
            '.MuiOutlinedInput-input': {
              fontSize: '12px',
              padding: '12px',
            },
          }}
          placeholder="Description"
          value={subCategoryAddInputDatas.description}
          onChange={(e) => {
            if (e.target.value.length <= 250) {
              setSubCategoryAddInputDatas({
                ...subCategoryAddInputDatas,
                description: e.target.value,
              });
            }
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '8px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            Enter at least 20 character
          </Typography>
          <Typography
            sx={{
              fontSize: '8px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            {subCategoryAddInputDatas.description.length}/250
          </Typography>
        </Box>
      </Grid>
      {/* Schedule */}
      <Grid item xs={12} sx={{}}>
        <Box sx={{ display: 'flex', minWidth: '100%' }}>
          <DesktopDatePicker
            inputFormat="DD/MM/YYYY"
            value={subCategoryAddInputDatas.schedule}
            onChange={(newValue) => {
              if (subCategoryAddInputDatas.scheduleType !== 'now') {
                setSubCategoryAddInputDatas({
                  ...subCategoryAddInputDatas,
                  schedule: newValue,
                });
              } else {
                setSubCategoryAddInputDatas({
                  ...subCategoryAddInputDatas,
                  schedule: today,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  width: '100%',
                  bgcolor: '#FAFAFA',
                  '.MuiOutlinedInput-input': {
                    fontSize: '12px',
                    padding: '12px',
                  },
                }}
              />
            )}
          />
        </Box>
      </Grid>
      {/* STATUS */}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
          Publish
        </Typography>
        <AntSwitch
          checked={subCategoryAddInputDatas.publish}
          onChange={(e) => {
            setSubCategoryAddInputDatas({
              ...subCategoryAddInputDatas,
              publish: !subCategoryAddInputDatas.publish,
            });
          }}
        />
      </Grid>
      {/* SUBMIT */}
      <Grid item xs={12} sx={{ mt: '28px' }}>
        <CustomButton
          // disabled={!isSubmitAvailable}
          onClick={handleAdd}
          variant="contained"
          sx={{
            width: '100%',
          }}
        >
          Add
        </CustomButton>
      </Grid>
    </Grid>
  );
};

export default SubCategoryMenuAddMobile;
