import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeHubChoose,
  changeHubSearch,
  changeHubSearchDebounce,
  changeHubs,
  changeMasterHubId,
  changeMiniHubChoose,
  changeMiniHubId,
  changeMiniHubSearch,
  changeMiniHubSearchDebounce,
  changeSubHubChoose,
  changeSubHubId,
  changeSubHubSearch,
  changeSubHubSearchDebounce,
  getDataMasterHubStock,
  getDataMiniHubStock,
  getDataSubHubStock,
  getHubChooseStock,
  getHubLoadingStock,
  getHubSearchDebounceStock,
  getHubSearchStock,
  getMasterHubIdStock,
  getMasterHubStock,
  getMiniHubChooseStock,
  getMiniHubSearchDebounceStock,
  getMiniHubSearchStock,
  getMiniHubStock,
  getSubHubChooseStock,
  getSubHubIdStock,
  getSubHubSearchDebounceStock,
  getSubHubSearchStock,
  getSubHubStock,
  getSubsidiaryIdStock,
} from '../../../../../store/stock/stock';
import { useEffect } from 'react';
import debounce from 'lodash.debounce';
import { useRef } from 'react';
import CustomButton from '../../../../../components/shared/CustomButton';

const SelectHubStock = ({ setIsSelectHub }) => {
  const dispatch = useDispatch();
  const subsidiaryId = useSelector(getSubsidiaryIdStock);
  const hubLoading = useSelector(getHubLoadingStock);

  const hubChoose = useSelector(getHubChooseStock);
  const hubSearch = useSelector(getHubSearchStock);
  const hubSearchDebounce = useSelector(getHubSearchDebounceStock);

  // hub
  const [selectOpen, setSelectOpen] = useState('hubs');
  const masterHubData = useSelector(getMasterHubStock);
  const suhHubData = useSelector(getSubHubStock);
  const miniHubData = useSelector(getMiniHubStock);
  const idToko = useSelector(getMasterHubIdStock);
  const idSubHub = useSelector(getSubHubIdStock);

  // sub hub
  const subHubChoose = useSelector(getSubHubChooseStock);
  const subHubSearch = useSelector(getSubHubSearchStock);
  const subHubSearchDebounce = useSelector(getSubHubSearchDebounceStock);

  // mini hub
  const miniHubChoose = useSelector(getMiniHubChooseStock);
  const miniHubSearch = useSelector(getMiniHubSearchStock);
  const miniHubSearchDebounce = useSelector(getMiniHubSearchDebounceStock);

  useEffect(() => {
    dispatch(getDataMasterHubStock(subsidiaryId, hubSearchDebounce));
    dispatch(getDataSubHubStock(idToko));
    dispatch(getDataMiniHubStock(idSubHub));
  }, [subsidiaryId, hubSearchDebounce, idToko, idSubHub]);

  const hubSearchInDebounce = useRef(
    debounce((value) => {
      dispatch(changeHubSearchDebounce(value));
    }, 1000)
  ).current;

  const subHubSearchInDebounce = useRef(
    debounce((value) => {
      dispatch(changeSubHubSearchDebounce(value));
    }, 1000)
  ).current;

  const miniHubSearchInDebounce = useRef(
    debounce((value) => {
      dispatch(changeMiniHubSearchDebounce(value));
    }, 1000)
  ).current;

  //! submit button
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const handleSubmit = () => {
    dispatch(changeHubs([hubChoose, subHubChoose, miniHubChoose]));
    setIsSelectHub(false);
  };

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column' }}>
      <Grid container columns={12} spacing={4}>
        {/* master hub */}
        <Grid item xs={4}>
          <Autocomplete
            sx={{ width: '100%' }}
            open={selectOpen === 'hubs' && true}
            onFocus={() => setSelectOpen('hubs')}
            onBlur={() => setSelectOpen('')}
            options={masterHubData}
            value={hubChoose}
            inputValue={hubSearch}
            getOptionLabel={(option) => option.namaToko}
            loading={hubLoading}
            onChange={(_, newValue) => {
              dispatch(changeHubChoose(newValue));
              dispatch(changeMasterHubId(newValue.idToko));
              setSelectOpen('subHubs');
            }}
            onInputChange={(_, newInputValue) => {
              dispatch(changeHubSearch(newInputValue));
              hubSearchInDebounce(newInputValue);
            }}
            renderOption={(props, option) => (
              <li key={option.idToko} {...props}>
                {option.namaToko}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="master hubs"
                sx={{ bgcolor: '#fafafa', borderRadius: '5px' }}
              />
            )}
          />
        </Grid>

        {/* sub hub */}
        <Grid item xs={4}>
          <Autocomplete
            sx={{ width: '100%' }}
            open={selectOpen === 'subHubs' && true}
            onFocus={() => setSelectOpen('subHubs')}
            onBlur={() => setSelectOpen('')}
            options={suhHubData}
            value={subHubChoose}
            inputValue={subHubSearch}
            getOptionLabel={(option) => option.name}
            loading={hubLoading}
            onChange={(_, newValue) => {
              dispatch(changeSubHubChoose(newValue));
              dispatch(changeSubHubId(newValue.id));
              setSelectOpen('miniHubs');
            }}
            onInputChange={(_, newInputValue) => {
              dispatch(changeSubHubSearch(newInputValue));
              subHubSearchInDebounce(newInputValue);
            }}
            renderOption={(props, option) => (
              <li key={option.idToko} {...props}>
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="sub hubs"
                sx={{ bgcolor: '#fafafa', borderRadius: '5px' }}
              />
            )}
          />
        </Grid>

        {/* mini hub */}
        <Grid item xs={4}>
          <Autocomplete
            sx={{ width: '100%' }}
            open={selectOpen === 'miniHubs' && true}
            onFocus={() => setSelectOpen('miniHubs')}
            onBlur={() => setSelectOpen('')}
            options={miniHubData}
            value={miniHubChoose}
            inputValue={miniHubSearch}
            getOptionLabel={(option) => option.name}
            loading={hubLoading}
            onChange={(_, newValue) => {
              dispatch(changeMiniHubChoose(newValue));
              dispatch(changeMiniHubId(newValue.id));
              setSelectOpen('');
            }}
            onInputChange={(_, newInputValue) => {
              dispatch(changeMiniHubSearch(newInputValue));
              miniHubSearchInDebounce(newInputValue);
            }}
            renderOption={(props, option) => (
              <li key={option.idToko} {...props}>
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="mini hubs"
                sx={{ bgcolor: '#fafafa', borderRadius: '5px' }}
              />
            )}
          />
        </Grid>
      </Grid>

      {/* button event */}
      <Box sx={{ textAlign: 'right' }}>
        {loadingSubmit && <CircularProgress size={16} />}
        <CustomButton
          variant="contained"
          // disabled={!isSubmitAvailable}
          onClick={handleSubmit}
          sx={{
            width: '140px',
            height: '36px',
            mt: '28px',
          }}
        >
          <Typography
            sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}
          >
            Next
          </Typography>
        </CustomButton>
      </Box>
    </Box>
  );
};
export default SelectHubStock;
