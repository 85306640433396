import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import {
  ArrowBackIosIcon,
  ArrowForwardIosIcon,
  ArrowRightAltIcon,
  ColumnFilterIcon,
  NoImageIcon,
  SearchIcon,
  SyncAltIcon,
} from '../../../../assets/Icons';
import CustomMenu from '../../CustomMenu';

const CustomTableCells = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '0.75rem',
  textTransform: 'capitalize',
});

const CustomTable3 = (props) => {
  const {
    title,
    totalData,
    datas,
    loading,
    headTables,
    handleChangePageSizeTable,
    handleBackPage,
    handleNextPage,
    handleInputPage,
    handleSortPage,
    handleSearchPage,
    defaultShort,
    adjustFilterType,
    limitAdjustableValue = 7,
    handleClickRow,
    handleClickCell,
    keyCell,
    toolbar,
    children,
  } = props;
  const theme = useTheme();
  const pageSizes = [5, 10, 15, 20];
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [searchFix, setSearchFix] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [shortBased, setShortBased] = useState('');
  const [isShortAsc, setIsShortAsc] = useState(true);
  const [adjustFilter, setAdjustFilter] = useState(null);
  const isAdjustFilter = Boolean(adjustFilter);
  const [adjustFilterList, setAdjustFilterList] = useState([]);
  const [adjustFilterListDefault, setAdjustFilterListDefault] = useState([]);
  const [adjustFIlterListNonAdjustable, setAdjustFlterListNonAdjustable] =
    useState([]);
  const [adjustFilterListValue, setAdjustFilterListValue] = useState([]);
  const [
    adjustFilterListValueNonAdjustable,
    setAdjustFilterListValueNonAdjustable,
  ] = useState([]);

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setShortBased(defaultShort);
    // let collection = adjustFilterType.filter(
    //   (list) => list.adjustable === true
    // );
    // let collectionValue = adjustFilterType
    //   .filter((list) => list.adjustable === true && list.default === true)
    //   .map((value) => {
    //     return value.key;
    //   });
    // let collectionNonAdjustable = adjustFilterType.filter(
    //   (list) => list.adjustable === false
    // );
    // let collectionValueNonAdjustable = adjustFilterType
    //   .filter((list) => list.adjustable === false)
    //   .map((value) => {
    //     return value.key;
    //   });

    // setAdjustFilterList(collection);
    // setAdjustFilterListValue(collectionValue);
    // setAdjustFilterListDefault(collectionValue);
    // setAdjustFlterListNonAdjustable(collectionNonAdjustable);
    // setAdjustFilterListValueNonAdjustable(collectionValueNonAdjustable);
  }, []);

  const debounceSearch = useRef(
    debounce((value) => {
      setSearchFix(value);
    }, 1000)
  ).current;

  useEffect(() => {
    if (search !== searchFix) {
      return setIsLoading(true);
    } else {
      return handleSearch();
    }
  }, [search, searchFix]);

  useEffect(() => {
    if (loading !== isLoading) {
      setIsLoading(loading);
    }
  }, [loading, isLoading]);

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
    handleChangePageSizeTable(e.target.value);
  };

  const handleBack = () => {
    if (currentPage > 1) {
      handleBackPage(currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNext = () => {
    if (currentPage < Math.ceil(totalData / pageSize)) {
      handleNextPage(currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };
  const handleInput = (e) => {
    let search = Number(e.target.value);
    if (search > 1 && search < Math.ceil(totalData / pageSize)) {
      setCurrentPage(search);
      handleInputPage(search);
    }
  };
  const handleSearch = () => {
    handleSearchPage(searchFix);
    setCurrentPage(1);
  };

  const handleSort = (is, key) => {
    if (is) {
      if (key === shortBased) {
        handleSortPage(key, !isShortAsc);
        setIsShortAsc(!isShortAsc);
      } else {
        handleSortPage(key, true);
        setShortBased(key);
        setIsShortAsc(true);
      }
    }
  };

  // * adjustfilter
  const handleAdjustFilterValue = (key) => {
    if (adjustFilterListValue.includes(key)) {
      setAdjustFilterListValue(
        adjustFilterListValue.filter((value) => value !== key)
      );
    } else {
      if (
        adjustFilterListValue.length <
        limitAdjustableValue - adjustFIlterListNonAdjustable.length
      ) {
        setAdjustFilterListValue([...adjustFilterListValue, key]);
      }
    }
  };

  const handleResetAdjustFilter = () => {
    setAdjustFilterListValue(adjustFilterListDefault);
  };

  // * Handle Click
  const handleClickRowTable = (id) => {
    handleClickRow(id);
  };
  const handleClickCellTable = (event, key, id) => {
    if (keyCell === key) {
      handleClickCell(id);
      event.stopPropagation();
    }
  };
  return (
    <Box>
      {/* //! -------------------------- HEADER ------------------------------ */}
      <Grid container columnSpacing={4} alignItems="center">
        <Grid item xs="auto">
          <Typography
            sx={{ color: '#000000', fontWeight: 600, fontSize: '1.25rem' }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs>
          <TextField
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              debounceSearch(e.target.value);
            }}
            placeholder={'Search'}
            size="small"
            sx={{
              bgcolor: '#FAFAFA',
              width: '250px',
              '& .MuiOutlinedInput-root': {
                paddingLeft: '6px',
                fontSize: '0.875rem',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ width: '18px' }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs="auto">
          {toolbar}
        </Grid>
      </Grid>
      {/* //! -------------------------- TABLE ------------------------------ */}
      <Table sx={{ mt: '8px' }}>
        {/* //! -------------------------- TABLE HEAD------------------------------ */}
        <TableHead>
          <TableRow sx={{ bgcolor: '#FAFAFA' }}>
            <CustomTableCells sx={{ width: '32px', maxWidth: '32px' }}>
              No.
            </CustomTableCells>
            {headTables.map((headTable, index) => {
              const { key, name, align, short, maxWidht } = headTable;
              return (
                <CustomTableCells
                  key={index}
                  sx={[
                    maxWidht !== '' && {
                      width: maxWidht,
                      maxWidth: maxWidht,
                    },
                  ]}
                >
                  <Box
                    sx={[
                      {
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: align,
                        gap: 0.2,
                      },
                      short && {
                        cursor: 'pointer',
                      },
                    ]}
                    onClick={() => handleSort(short, key)}
                  >
                    {name}
                    {short && shortBased !== key && (
                      <SyncAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(90deg)',
                          color: '#9E9D9D',
                        }}
                      />
                    )}
                    {short && shortBased === key && isShortAsc && (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(90deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    )}
                    {short && shortBased === key && !isShortAsc && (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(270deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    )}
                  </Box>
                </CustomTableCells>
              );
            })}
            {headTables
              .filter((list) => adjustFilterListValue.includes(list.key))
              .map((headTable, index) => {
                const { key, name, align, short, maxWidht } = headTable;
                return (
                  <CustomTableCells
                    key={index}
                    sx={[
                      maxWidht !== '' && {
                        width: maxWidht,
                        maxWidth: maxWidht,
                      },
                    ]}
                  >
                    <Box
                      sx={[
                        {
                          display: 'flex',
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: align,
                          gap: 0.2,
                        },
                        short && {
                          cursor: 'pointer',
                        },
                      ]}
                      onClick={() => handleSort(short, key)}
                    >
                      {name}
                      {short && shortBased !== key && (
                        <SyncAltIcon
                          sx={{
                            fontSize: '12px',
                            transform: 'rotate(90deg)',
                            color: '#9E9D9D',
                          }}
                        />
                      )}
                      {short && shortBased === key && isShortAsc && (
                        <ArrowRightAltIcon
                          sx={{
                            fontSize: '12px',
                            transform: 'rotate(90deg)',
                            color: theme.palette.primary.main,
                          }}
                        />
                      )}
                      {short && shortBased === key && !isShortAsc && (
                        <ArrowRightAltIcon
                          sx={{
                            fontSize: '12px',
                            transform: 'rotate(270deg)',
                            color: theme.palette.primary.main,
                          }}
                        />
                      )}
                    </Box>
                  </CustomTableCells>
                );
              })}
          </TableRow>
        </TableHead>
        {/* //! -------------------------- TABLE BOODY------------------------------ */}
        {!isLoading && datas.length !== 0 && <TableBody>{children}</TableBody>}
      </Table>
      {/* //! -------------------------- LOADING ------------------------------ */}
      {isLoading && (
        <Box
          sx={{
            height: `${73 * pageSize}px`,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {/* //! -------------------------- No Data ------------------------------ */}
      {!isLoading && datas.length === 0 && (
        <Box
          sx={{
            height: `${73 * pageSize}px`,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography>There is no data</Typography>
        </Box>
      )}
      {/* //! -------------------------- PAGINATION ------------------------------ */}
      <Grid
        container
        direction="row"
        coloumn={12}
        alignItems="center"
        sx={{ mt: '30px' }}
      >
        <Grid item xs={6} sx={{ fontSize: '14px', fontWeight: '500' }}>
          Showing {currentPage * pageSize - (pageSize - 1)} to{' '}
          {currentPage * pageSize >= totalData
            ? totalData
            : currentPage * pageSize}{' '}
          of {totalData} entries
        </Grid>
        <Grid
          container
          item
          direction="row"
          xs={6}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item sx={{ fontSize: '14px', fontWeight: '500' }}>
            Rows Per Page :
            <Select
              value={pageSize}
              variant="filled"
              onChange={(e) => handleChangePageSize(e)}
              sx={{
                fontSize: '14px',
                lineHeight: '-1px',
                '.MuiFilledInput-input': {
                  paddingTop: '7.5px',
                  paddingBottom: '7.5px',
                  paddingLeft: '10px',
                  paddingRight: '0',
                  borderRadius: '5px',
                },
                '&:before': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                '&:after': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                borderRadius: '5px',
                marginLeft: '8px',
              }}
            >
              {pageSizes.map((pageSize, index) => (
                <MenuItem value={pageSize} key={index}>
                  {pageSize}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item sx={{ ml: '35px' }}>
            <Box sx={{ display: 'inline', mr: '10px' }}>
              <IconButton sx={{ p: 0 }} onClick={handleBack}>
                <ArrowBackIosIcon sx={{ color: '#323232', width: '16px' }} />
              </IconButton>
            </Box>
            <Box sx={{ display: 'inline', mr: '20px' }}>
              <OutlinedInput
                variant="outlined"
                type="number"
                value={currentPage}
                onChange={handleInput}
                sx={{
                  border: '1px solid #9E9D9D',
                  width: '36px',
                  borderRadius: '5px',
                  textAlign: 'center',
                  '& .MuiOutlinedInput-input': {
                    padding: '5px 8px 5px 8px',
                    fontSize: '0.875rem',
                    boxShadow: 'none',
                    textAlign: 'center',
                    fontWeight: 600,
                    width: '25px',
                  },
                  '&:before': {
                    borderRadius: '5px',
                    borderBottom: 0,
                  },
                  '&:after': {
                    borderRadius: '5px',
                    borderBottom: 0,
                  },
                  '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'inline',
                fontWeight: '600',
                fontSize: '14px',
                color: '#9E9D9D',
              }}
            >
              of {Math.ceil(totalData / pageSize)}
            </Box>
            <Box sx={{ display: 'inline', ml: '10px' }}>
              <IconButton sx={{ p: 0 }} onClick={handleNext}>
                <ArrowForwardIosIcon sx={{ color: '#323232', width: '16px' }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default CustomTable3;
