import { Box, Grid, Typography } from '@mui/material';
import { forwardRef } from 'react';
import QRCode from 'react-qr-code';
import { GetExcelNumberFormat } from '../../../../../../hooks/getExcelNumber';

const ComponentToPrint = forwardRef((props, ref) => {
  const { data } = props;
  // console.log('cek', data);

  return (
    <div ref={ref}>
      <Box
        sx={{
          py: '72px',
          px: '20px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Grid container columns={3} columnSpacing={3} rowSpacing={8}>
          {data.map((list, index) => {
            return (
              <Grid
                item
                xs={1}
                key={index}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '170px',
                    flexDirection: 'column',
                    gap: 0.5,
                  }}
                >
                  <Box
                    sx={{
                      border: 2,
                      borderColor: '#51B15C',
                      width: '170px',
                      height: '186px',
                      p: '14px',
                      borderRadius: '12px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography sx={{ textAlign: 'center' }}>
                      {GetExcelNumberFormat(Number(list?.posisiX) - 1)}
                      {list?.posisiY}
                    </Typography>
                    <Box
                      sx={{
                        width: '140px',
                        height: '140px',
                        position: 'relative',
                      }}
                    >
                      <QRCode
                        size={144}
                        style={{
                          height: '140px',
                          maxWidth: '140px',
                          width: '100%',
                        }}
                        value={list?.qrCode}
                        viewBox={`0 0 144 144`}
                      />
                      {/* <Box
                      sx={{
                        position: 'absolute',
                        top: '1px',
                        width: '140px',
                        height: '140px',
                        zIndex: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          width: '45px',
                          height: '45px',
                          borderRadius: '50%',
                          bgcolor: 'white',
                        }}
                      ></Box>
                    </Box> */}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </div>
  );
});

export default ComponentToPrint;
