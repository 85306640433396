import { createSlice } from '@reduxjs/toolkit';

export const stockOutRoutingState = createSlice({
  name: 'stockOutRouting',
  initialState: {
    page: '',
  },
  reducers: {
    changePageStockOut: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const { changePageStockOut } = stockOutRoutingState.actions;

export const getAllStockOutRouting = (state) => state.stockOutRouting;

export default stockOutRoutingState.reducer;
