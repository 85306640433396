import { createSlice } from '@reduxjs/toolkit';
import { getDetailAdjustment } from '../../api/adjustment';

export const detailAdjustmentState = createSlice({
  name: 'detailAdjustment',
  initialState: {
    idAdjustment: null,
    isLoading: false,
    dataDetail: null,
  },
  reducers: {
    changeIdDetailAdjustment: (state, action) => {
      state.idAdjustment = action.payload;
    },
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    loadDataDetail: (state, action) => {
      state.dataDetail = action.payload;
    },
  },
});

export const { changeIdDetailAdjustment, changeIsLoading, loadDataDetail } =
  detailAdjustmentState.actions;

export const getIdDetailAdjustment = (state) =>
  state.detailAdjustment.idAdjustment;
export const getIsLoadingAdjustment = (state) =>
  state.detailAdjustment.isLoading;
export const getDetailDataAdjustment = (state) =>
  state.detailAdjustment.dataDetail;

// ! ---------------------- API ----------------------------------------
export const fetchDataDetailAdjustment = (id) => (dispatch) => {
  const fetchData = async () => {
    dispatch(changeIsLoading(true));
    try {
      const {
        data: { data },
      } = await getDetailAdjustment(id);
      dispatch(loadDataDetail(data));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoading(false));
    }
  };
  fetchData();
};

export default detailAdjustmentState.reducer;
