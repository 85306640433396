import { Grid, useMediaQuery } from "@mui/material";
import CustomCard from "../../../../../components/shared/CustomCard";
import TableProductSupplier from "./Component/Table";
import ChartProductSupplier from "./Component/Chart";
import { useDispatch } from "react-redux";
import { useState } from "react";
import dayjs from "dayjs";
import TableListProductSupplier from "./TableList";
import ProdukSupplierMobile from "./MobileView";

const InventoryProductSupplier = () => {
  const matchTablet = useMediaQuery("(min-width:832px)");
  const dispatch = useDispatch();

  // ! grafik & table

  const [timeFrame, setTimeFrame] = useState("daily");

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);
  return (
    <>
      {!matchTablet ? (
        <ProdukSupplierMobile />
      ) : (
        <Grid container columns={12} spacing={3}>
          {/* grafik */}
          <Grid item xs={12}>
            <CustomCard sx={{ mt: 2, p: 2 }}>
              <Grid container columns={12} spacing={4}>
                {/* grafik */}
                <Grid item xs={7}>
                  <ChartProductSupplier
                    {...{
                      timeFrame,
                      setTimeFrame,
                      dateRange,
                      setDateRange,
                      saveDateRange,
                      setSaveDateRange,
                    }}
                  />
                </Grid>

                {/* table */}
                <Grid item xs={5}>
                  <TableProductSupplier />
                </Grid>
              </Grid>
            </CustomCard>
          </Grid>

          {/* table */}
          <Grid item xs={12}>
            <TableListProductSupplier />
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default InventoryProductSupplier;
