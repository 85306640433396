import { Autocomplete, TextField } from '@mui/material';
import debounce from 'lodash.debounce';

import { useState, useEffect, useRef } from 'react';
import { allProductName } from '../../../../../../api/menu';

const ProductSupplierSelect = ({
  handleValue,
  firstValueId,
  firstValue,
  isReset,
  setIsReset,
  isDisableProduct,
  items,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  const [inputDebounce, setInputDebounce] = useState('');

  const inputDebounceChange = useRef(
    debounce((value) => {
      setInputDebounce(value);
    }, 1000)
  ).current;

  const [datas, setDatas] = useState([]);
  const [datasFix, setDatasFix] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [productFix, setProductFix] = useState(null);

  useEffect(() => {
    const result = datas.filter(
      (dataItem) =>
        !items.some(
          (setValueItem) =>
            dataItem.namaProduk === setValueItem.namaProduk &&
            dataItem.idProduk === setValueItem.idProduk
        )
    );

    setDatasFix(result);
  }, [items, datas]);

  useEffect(() => {
    const getProductName = async () => {
      setIsLoading(true);
      let datas = {
        page: 1,
        pageSize: 100,
        search: inputDebounce,
      };

      try {
        const {
          data: { data: dataNames },
        } = await allProductName(datas);
        setDatas(dataNames.products);
      } catch (err) {
        console.error(err);
        setDatas([]);
      } finally {
        setIsLoading(true);
      }
    };

    getProductName();
  }, [inputDebounce]);

  useEffect(() => {
    if (isReset) {
      return setProduct({
        idProduk: firstValueId,
        namaProduk: firstValue,
      });
      setIsReset(false);
    }
  }, [isReset]);

  useEffect(() => {
    if (firstValueId !== 0) {
      return setProduct({
        idProduk: firstValueId,
        namaProduk: firstValue,
      });
    }
  }, []);

  useEffect(() => {
    if (product !== productFix) {
      return handleValue(product.namaProduk, product.idProduk);
      return setProductFix(product);
    }
  }, [product, productFix]);

  return (
    <Autocomplete
      disabled={isDisableProduct}
      sx={{ minWidth: '100%', bgcolor: '#FAFAFA' }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        inputDebounceChange(newInputValue);
      }}
      options={datasFix}
      getOptionLabel={(option) => option.namaProduk}
      value={product}
      onChange={(e, newValue) => setProduct(newValue)}
      loading={isLoading}
      renderInput={(params) => <TextField {...params} placeholder="Product*" />}
      {...props}
    />
  );
};

export default ProductSupplierSelect;
