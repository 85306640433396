import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Switch,
  Dialog,
  styled,
} from '@mui/material';
import { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

import dayjs from 'dayjs';
import { uploadImage } from '../../../../../../api/storage';
import CustomEditConfirm from '../../../../../../components/shared/CustomEditConfirm';
import CustomDeleteConfirm from '../../../../../../components/shared/CustomDeleteConfirm';
import BackButton from '../../../../../../components/shared/BackButton';
import CustomInput from '../../../../../../components/shared/CustomInput';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import CustomButton from '../../../../../../components/shared/CustomButton';

const SubCategoryMenuEditDesktop = (props) => {
  const {
    theme,
    today,
    AntSwitch,
    // *-- Page
    setPage,
    setSubCategoryMenu,
    // *-- hirarki
    roadHirarki,
    setRoadHirarki,
    // *-- data
    subCategoryEditInputDatas,
    setSubCategoryEditInputDatas,
    // *--- Handler
    handleBackButton,
    handleEdit,
    handleDelete,
    handleAddImage,
    handleDeleteImage,
    handlePreviewImage,
    handleClosePreviewImage,
    // *--- Validation
    isSubmitAvailable,
  } = props;
  return (
    <Grid container columns={12} spacing={4} sx={{ pb: '56px' }}>
      {/* //* Back Button */}
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        <Box>
          <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
            Edit Sub Category
          </Typography>
          <Box
            sx={{
              display: 'flex',
              color: '#9E9D9D',

              alignItems: 'center',
              mt: '4px',
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
              {roadHirarki.subsidiary}
            </Typography>
            <ChevronRightIcon sx={{ fontSize: '16px' }} />
            <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
              {roadHirarki.category}
            </Typography>
          </Box>
        </Box>
      </Grid>
      {/* //* Name */}
      <Grid item xs={6}>
        <Typography sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}>
          Name*
        </Typography>
        <CustomInput
          sx={{ width: '100%', bgcolor: '#FAFAFA' }}
          placeholder="Enter Category Name"
          value={subCategoryEditInputDatas.name}
          onChange={(e) => {
            if (e.target.value.length <= 30) {
              setSubCategoryEditInputDatas({
                ...subCategoryEditInputDatas,
                name: e.target.value,
              });
            }
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            Enter at least 5 character
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            {subCategoryEditInputDatas.name.length}/30
          </Typography>
        </Box>
      </Grid>
      {/* //* Photo */}
      <Grid item xs={6} sx={{ minHeight: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            pb: `${
              subCategoryEditInputDatas.photo.length === 0 ? '16px' : '9px'
            }`,
          }}
        >
          <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
            Photo
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '500',
              color: '#9E9D9D',
            }}
          >
            ( max size : 2MB )
          </Typography>
          {subCategoryEditInputDatas.isMaxSizePhoto && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ErrorOutlineIcon
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  color: theme.palette.warning.main,
                  mr: '4px',
                }}
              />
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  color: theme.palette.warning.main,
                }}
              >
                The photo can't be more than 2MB
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {subCategoryEditInputDatas.photoUrls.length === 0 && (
              <Box
                sx={{
                  height: '54px',
                  width: '54px',
                  aspectRatio: '1/1',
                  borderRadius: '8px',
                  bgcolor: '#F5F5F5',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderStyle: 'dashed',
                  borderColor: '#9E9D9D',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  document.getElementById('inputSubCat_img').click();
                }}
              >
                <ImageOutlinedIcon
                  sx={{ color: '#9E9D9D', fontSize: '28px' }}
                />
              </Box>
            )}
            {subCategoryEditInputDatas.photoUrls.map((input, index) => (
              <Box
                sx={{
                  position: 'relative',
                  overflow: 'visible',
                  padding: '7px',
                }}
                key={index}
              >
                <CancelIcon
                  sx={{
                    position: 'absolute',
                    fontSize: '20px',
                    top: 1,
                    right: 1,
                    zIndex: +1,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleDeleteImage(input)}
                />
                <Box
                  component="img"
                  src={process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + input}
                  sx={{
                    height: '54px',
                    width: '54px',
                    aspectRatio: '1/1',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handlePreviewImage(input)}
                />
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: 'flex',
              mb: '7px',
              ml: '20px',
              alignItems: 'center',
            }}
          >
            {subCategoryEditInputDatas.photoUrls.length < 3 && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.palette.primary.main,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  document.getElementById('inputSubCat_img').click();
                }}
              >
                <input
                  accept="image/*"
                  id="inputSubCat_img"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleAddImage}
                />
                <AddIcon />
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                  Add More
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      {/* //* DIalog Photo */}
      {subCategoryEditInputDatas.linkPreviewPhoto !== '' && (
        <Dialog
          open={subCategoryEditInputDatas.isPreviewPhoto}
          onClose={handleClosePreviewImage}
        >
          <Box
            component="img"
            src={
              process.env.REACT_APP_API_IMAGE_BASE_URL +
              '/' +
              subCategoryEditInputDatas.linkPreviewPhoto
            }
            sx={{ width: '600px', height: '600px', aspectRation: '1/1' }}
          />
        </Dialog>
      )}
      {/* //* Description */}
      <Grid item xs={6}>
        <Typography sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}>
          Description
        </Typography>
        <CustomInput
          sx={{ width: '100%', bgcolor: '#FAFAFA' }}
          placeholder="Enter Category Name"
          value={subCategoryEditInputDatas.description}
          onChange={(e) => {
            if (e.target.value.length <= 250) {
              setSubCategoryEditInputDatas({
                ...subCategoryEditInputDatas,
                description: e.target.value,
              });
            }
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            Enter at least 20 character
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            {subCategoryEditInputDatas.description.length}/250
          </Typography>
        </Box>
      </Grid>
      {/* //* Schedule */}
      <Grid item xs={6} sx={{}}>
        <Grid container>
          <Grid item alignItems="center" sx={{ height: '100%', mr: '20px' }}>
            <Typography
              sx={{
                mb: '16px',
                fontSize: '16px',
                fontWeight: '500',
              }}
            >
              Schedule
            </Typography>
          </Grid>
          <Grid item alignItems="center" sx={{ height: '100%' }}>
            <RadioGroup
              row
              value={subCategoryEditInputDatas.scheduleType}
              onChange={(e) => {
                setSubCategoryEditInputDatas({
                  ...subCategoryEditInputDatas,
                  schedule: today,
                  scheduleType: e.target.value,
                });
              }}
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: '16px',
                },
                '.MuiRadio-root': {
                  padding: '0 8px',
                },
                '.MuiTypography-root': {
                  fontSize: '12px',
                },
              }}
            >
              <FormControlLabel value="now" control={<Radio />} label="Now" />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', minWidth: '100%' }}>
          <DesktopDatePicker
            inputFormat="DD/MM/YYYY"
            value={subCategoryEditInputDatas.schedule}
            onChange={(newValue) => {
              if (subCategoryEditInputDatas.scheduleType !== 'now') {
                setSubCategoryEditInputDatas({
                  ...subCategoryEditInputDatas,
                  schedule: newValue,
                });
              } else {
                setSubCategoryEditInputDatas({
                  ...subCategoryEditInputDatas,
                  schedule: today,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              />
            )}
            disabled={
              subCategoryEditInputDatas.scheduleType === 'now' ? true : false
            }
          />
        </Box>
      </Grid>
      {/*//* Submit */}
      <Box
        sx={{
          width: '100%',
          bgcolor: 'white',
          position: 'fixed',
          bottom: '0px',
          right: '0px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '18px 32px 18px 240px',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
            Publish
          </Typography>
          <AntSwitch
            checked={subCategoryEditInputDatas.publish}
            onChange={(e) => {
              setSubCategoryEditInputDatas({
                ...subCategoryEditInputDatas,
                publish: !subCategoryEditInputDatas.publish,
              });
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <CustomButton
            variant="contained"
            // disabled={!isSubmitAvailable}
            onClick={handleDelete}
            color="error"
            sx={{ width: '140px', height: '36px' }}
          >
            <Typography
              sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
            >
              Delete
            </Typography>
          </CustomButton>
          <CustomButton
            variant="contained"
            disabled={!isSubmitAvailable}
            onClick={handleEdit}
            sx={{ width: '140px', height: '36px' }}
          >
            <Typography
              sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
            >
              Save
            </Typography>
          </CustomButton>
        </Box>
      </Box>
    </Grid>
  );
};

export default SubCategoryMenuEditDesktop;
