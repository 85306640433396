import { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

import dayjs from 'dayjs';
import BackButton from '../../../../../../components/shared/BackButton';
import CustomInput from '../../../../../../components/shared/CustomInput';
import CustomCard from '../../../../../../components/shared/CustomCard';
import { uploadImage } from '../../../../../../api/storage';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import AddMiniSubCategoryInSubCategory from '../../Product/SubCategoryProductAdd/Desktop/AddMiniSubCategoryInSubCategory';
import {
  addNewMiniSubCategoryMenu,
  addNewSubCategoryMenu,
} from '../../../../../../api/menu';
import CustomButton from '../../../../../../components/shared/CustomButton';
import SubCategoryMenuAddDesktop from './SubCategoryMenuAddDesktop';
import SubCategoryMenuAddMobile from './SubCategoryMenuAddMobile';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const SubCategoryMenuAdd = (props) => {
  const {
    desktopMode,
    roadHirarki,
    setRoadHirarki,
    // *--- Notif
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
    // *--- page
    setPage,
    setSubCategoryMenu,
    // *--- Data Value
    subCategoryAddInputDatas,
    setSubCategoryAddInputDatas,
  } = props;
  const today = dayjs(new Date());
  const theme = useTheme();

  const [miniSub1, setMiniSub1] = useState({
    id: 1,
    name: '',
    photo: [],
    photoUrls: [],
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviewPhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    available: true,
  });

  const [miniSub2, setMiniSub2] = useState({
    id: 2,
    name: '',
    photo: [],
    photoUrls: [],
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviewPhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    available: true,
  });

  const [miniSub3, setMiniSub3] = useState({
    id: 3,
    name: '',
    photo: [],
    photoUrls: [],
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviewPhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    available: true,
  });
  const [indexMiniSubCategory, setIndexMiniSubCategory] = useState([]);

  useEffect(() => {
    subCategoryAddInputDatas.miniSubCategoryFormat
      .filter(
        (item) =>
          !subCategoryAddInputDatas.miniSubCategoryIndex.includes(item) &&
          !indexMiniSubCategory.includes(item)
      )
      .map((value) => {
        return setIndexMiniSubCategory([...indexMiniSubCategory, value]);
      });
  }, [subCategoryAddInputDatas, indexMiniSubCategory]);

  // !--------------------------------- VALIDATION --------------------------
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);
  useEffect(() => {
    if (subCategoryAddInputDatas.name.length >= 5) {
      if (
        subCategoryAddInputDatas.description === '' ||
        subCategoryAddInputDatas.description.length >= 20
      ) {
        if (subCategoryAddInputDatas.miniSubCategoryIndex.length > 0) {
          if (
            miniSub1.available === true &&
            miniSub2.available === true &&
            miniSub3.available === true
          ) {
            return setIsSubmitAvailable(true);
          } else {
            return setIsSubmitAvailable(false);
          }
        } else {
          return setIsSubmitAvailable(true);
        }
      } else {
        return setIsSubmitAvailable(false);
      }
    } else {
      return setIsSubmitAvailable(false);
    }
  }, [
    subCategoryAddInputDatas,
    setIsSubmitAvailable,
    miniSub1,
    miniSub2,
    miniSub3,
  ]);
  // !--------------------------------- HANDLER --------------------------
  const handleBackButton = () => {
    setSubCategoryMenu('');
  };
  const handleAdd = () => {
    let hasil = {
      idKategori: roadHirarki.categoryId,
      subkategori: subCategoryAddInputDatas.name,
      subStatus: `${subCategoryAddInputDatas.publish ? 'Y' : 'N'}`,
      schedule: dayjs(subCategoryAddInputDatas.schedule.$d).format(
        'YYYY-MM-DD'
      ),
    };

    if (subCategoryAddInputDatas.description !== '') {
      hasil['informasi'] = subCategoryAddInputDatas.description;
    }

    if (subCategoryAddInputDatas.photoUrls.length !== 0) {
      hasil['gbrsub'] = subCategoryAddInputDatas.photoUrls.join(',');
    }

    let miniSubCategories = [{ ...miniSub1 }, { ...miniSub2 }, { ...miniSub3 }];
    addNewSubCategoryMenu(hasil)
      .then((result) => {
        miniSubCategories
          .filter((miniItem) =>
            subCategoryAddInputDatas.miniSubCategoryIndex.includes(miniItem.id)
          )
          .map((miniItem) => {
            let mini = {
              name: miniItem.name,
              idSubkategori: result.data.data.idSubkategori,
              description: miniItem.description,
              schedule: dayjs(miniItem.schedule.$d).format('YYYY-MM-DD'),
              status: 'Y',
            };
            if (miniItem.photoUrls.length !== 0) {
              mini['image'] = miniItem.photoUrls.join(',');
            }
            addNewMiniSubCategoryMenu(mini)
              .then()
              .catch((err) => console.error(err));
          });
        setNotif('addsubcategory');
        setNotifTitle(
          `${result.data.data.subkategori} has been successfully added`
        );
        setPage('subCategory');
        setSubCategoryMenu('');
      })
      .catch((err) => console.error(err));
  };
  const handleAddImage = (e) => {
    if (e.target.files[0].size > 2000000) {
      setSubCategoryAddInputDatas({
        ...subCategoryAddInputDatas,
        isMaxSizePhoto: true,
      });
    } else {
      let datas = {
        image: e.target.files[0],
        resource: 'subCategory',
      };
      uploadImage(datas).then((res) => {
        setSubCategoryAddInputDatas({
          ...subCategoryAddInputDatas,
          photo: [...subCategoryAddInputDatas.photo, e.target.files[0]],
          photoUrls: [
            ...subCategoryAddInputDatas.photoUrls,
            res.data.data.path,
          ],
          isMaxSizePhoto: false,
        });
      });
    }
  };

  const handleDeleteImage = (link, index) => {
    setSubCategoryAddInputDatas({
      ...subCategoryAddInputDatas,
      photo: subCategoryAddInputDatas.photo.filter((data) => data !== link),
      photoUrls: subCategoryAddInputDatas.photoUrls.filter(
        (data, idx) => idx !== index
      ),
      indexPreviePhoto: 0,
    });
  };

  const handlePreviewImage = () => {};

  return (
    <>
      {desktopMode ? (
        <SubCategoryMenuAddDesktop
          {...{ ...props }}
          {...{ isSubmitAvailable, setIsSubmitAvailable }}
          {...{ theme, today, AntSwitch }}
          {...{
            miniSub1,
            miniSub2,
            miniSub3,
            setMiniSub1,
            setMiniSub2,
            setMiniSub3,
            indexMiniSubCategory,
            setIndexMiniSubCategory,
          }}
          {...{
            handleBackButton,
            handleAddImage,
            handleDeleteImage,
            handleAdd,
          }}
        />
      ) : (
        <SubCategoryMenuAddMobile
          {...{ ...props }}
          {...{ isSubmitAvailable, setIsSubmitAvailable }}
          {...{ theme, today, AntSwitch }}
          {...{ roadHirarki }}
          {...{
            miniSub1,
            miniSub2,
            miniSub3,
            setMiniSub1,
            setMiniSub2,
            setMiniSub3,
            indexMiniSubCategory,
            setIndexMiniSubCategory,
          }}
          {...{
            handleBackButton,
            handleAddImage,
            handleDeleteImage,
            handleAdd,
          }}
        />
      )}
    </>
  );
};
export default SubCategoryMenuAdd;
