import { Box, Typography, useMediaQuery } from '@mui/material';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BackButtonHub from '../../../../../../components/shared/BackButtonHub';
import GrafikComparisonPurchase from '../Component';
import TablePurchase from './Table';
import { useEffect, useState } from 'react';
import { getPurchaseRequesrList } from '../../../../../../api/purchase';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailPurchase } from '../../../../../../store/purchase/purchaseAdmin/detailRequestPurchase';
import { changePage } from '../../../../../../store/Routing/purchaseRouting';
import PurchaseRequestMobile from '../PurchaseMobile/RequestPurchase/PurchaseRequestMobile';

const PurchaseRequest = () => {
  const dispatch = useDispatch();

  const matchTablet = useMediaQuery('(min-width:832px)');
  const handleBackButton = () => {
    dispatch(changePage(''));
  };

  return (
    <>
      {!matchTablet ? (
        <PurchaseRequestMobile />
      ) : (
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <BackButtonHub onClick={() => handleBackButton()} />
            <Typography
              sx={{
                color: '#9E9D9D',
                fontSize: '1.25rem',
                fontWeight: 600,
                cursor: 'pointer',
              }}
            >
              Purchase
            </Typography>
            <ArrowForwardIosIcon
              sx={{ color: '#9E9D9D', fontSize: '1rem', fontWeight: 600 }}
            />
            <Typography
              sx={{
                color: 'primary.black',
                fontSize: '1.25rem',
                fontWeight: 600,
              }}
            >
              Request
            </Typography>
          </Box>

          {/* grafik  comparison */}
          <GrafikComparisonPurchase />

          {/* table list */}
          <Box sx={{ mt: 4 }}>
            <TablePurchase />
          </Box>
        </Box>
      )}
    </>
  );
};
export default PurchaseRequest;
