import {
  Box,
  Typography,
  styled,
  TableCell,
  Checkbox,
  Collapse,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Autocomplete,
  TextField,
  InputAdornment,
  CircularProgress,
  TextareaAutosize,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconArrowHide,
  IconArrowShow,
  IconHistory,
} from '../../../../../../assets';
import CustomDialog from '../../../../../../components/shared/CustomDialog';
import { useState } from 'react';
import HistoryCard from '../Component/HistoryCard';
import CustomButton from '../../../../../../components/shared/CustomButton';
import CustomInput from '../../../../../../components/shared/CustomInput';
import { Add } from '@mui/icons-material';
import {
  Canceled,
  ChecklistIcon,
  ChecklistIconApproval,
  CloseIcon,
} from '../../../../../../assets/Icons';
import {
  addProductStockOut,
  checkInvoice,
  editProductStockOut,
  executeStockOut,
  getProductUnitStockOut,
} from '../../../../../../api/stockout';
import { allProductName } from '../../../../../../api/menu';
import debounce from 'lodash.debounce';
import {
  changeConfirmExecution,
  changeIdOrder,
  clearStockOut,
  fetchAllDataInvoice,
  getAllDataStockOut,
} from '../../../../../../store/stockout/stockout';
import ChooseHubInvoice from './Hub';

const CustomTableCells = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '12px',
  textTransform: 'capitalize',
});

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 100%;
  min-width: 360px;
  min-height:40px;
  font-size: 12px;
  font-weight: 400;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
 
`
);

const SearchFound = ({ invoice, search, setSearch, isLoading }) => {
  const dispatch = useDispatch();
  const { idOrder, openConfirmnExecute, hubType, selectedHub } =
    useSelector(getAllDataStockOut);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [idTemp, setIdTemp] = useState(null);
  const [execute, setExecute] = useState([]);
  const [collapseDestination, setCollapseDestination] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openConfirmn, setOpenConfirmn] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [inputDebounce, setInputDebounce] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [openHub, setOpenHub] = useState(false);

  //  product
  const [datas, setDatas] = useState([]);
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  //  changed product
  const [changedProduct, setChangedProduct] = useState(null);
  //  unit product
  const [unit, setUnit] = useState([]);
  const [unitSelect, setUnitSelect] = useState([]);
  const [isLoadingUnit, setIsLoadingUnit] = useState(false);
  const [qty, setQty] = useState('');
  //  exetuion
  const [textareaValue, setTextareaValue] = useState('');
  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  const label = { inputProps: { 'aria-label': 'Checkbox Stock Out' } };

  const handleCheckboxClick = (event, id, data) => {
    event.stopPropagation();
    if (event.target.checked) {
      setExecute((prevExecute) => [...prevExecute, { id: id, data: data }]);
    } else {
      setExecute((prevExecute) => prevExecute.filter((item) => item.id !== id));
    }
  };

  useEffect(() => {
    if (execute?.length === 0) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [execute]);

  const handleShow = (id) => {
    (idTemp === id || idTemp === null || !show) && setShow(!show);
    setIdTemp(id);
  };

  const handleAddProduk = (id) => {
    dispatch(changeIdOrder(id));
    setOpenConfirmn(true);
  };

  // ! validation add product
  useEffect(() => {
    if (openConfirmn === false) {
      setSelectedProduct(null);
      setQty('');
      setUnitSelect([]);
    } else {
    }
  }, [openConfirmn]);

  const inputDebounceChange = useRef(
    debounce((value) => {
      setInputDebounce(value);
    }, 1000)
  ).current;

  // ! api fetch product
  useEffect(() => {
    const getProductName = async () => {
      setIsLoadingProduct(true);
      let datas = {
        page: 1,
        pageSize: 100,
        search: inputDebounce,
      };

      try {
        const {
          data: { data: dataNames },
        } = await allProductName(datas);
        setDatas(dataNames.products);
      } catch (err) {
        console.error(err);
        setDatas([]);
      } finally {
        setIsLoadingProduct(true);
      }
    };

    getProductName();
  }, [inputDebounce]);

  // ! api fetch unit for add product
  const fetchProductUnit = async (idProduct) => {
    try {
      setIsLoadingUnit(true);
      const {
        data: {
          data: { productUnits },
        },
      } = await getProductUnitStockOut(idProduct);
      const units = productUnits?.map((item, idx) => {
        return {
          idProduct: item?.idProduk,
          unit_id: item?.unit?.id,
          unitName: item?.unit.name,
          price: 0,
        };
      });
      setUnit(units);
      setIsLoadingUnit(false);
    } catch (err) {
      console.error(err);
    }
  };

  // ! validation unit datas
  useEffect(() => {
    if (selectedProduct === null) {
      setUnitSelect([]);
      setUnit([]);
    }
  }, [selectedProduct]);

  const handleEnterProduct = async () => {
    const params = {
      id_produk: selectedProduct?.idProduk,
      id_order: idOrder,
      qty: qty,
      unit: unitSelect?.unitName,
    };

    try {
      setIsLoadingProduct(true);
      const data = await addProductStockOut(search, params);
      setSelectedProduct(null);
      setQty('');
      setUnitSelect([]);
      setOpenConfirmn(false);
      setIsLoadingProduct(false);
      setSearch(search);
      dispatch(fetchAllDataInvoice(search));
      setUnit([]);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingProduct(false);
    }
  };

  const handleEditProduct = async (qty, unit, idDetail, idProduk) => {
    const params = {
      id_produk: idProduk,
      id_detail: idDetail,
      qty: Number(qty),
      unit: unit,
    };
    setDisableSubmit(true);
    try {
      const data = await editProductStockOut(search, params);
      dispatch(fetchAllDataInvoice(search));
      setChangedProduct(null);
      setDisableSubmit(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleExecution = async () => {
    const params = {
      id_order: idOrder,
      hub_id: selectedHub,
      hub_type: hubType,
      keterangan: textareaValue,
    };

    try {
      const data = await executeStockOut(search, params);
      dispatch(clearStockOut());
      console.log({ data });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box>
      {openHub ? (
        <ChooseHubInvoice {...{ openHub, setOpenHub, execute, setExecute }} />
      ) : (
        <Box>
          {/* --- Heading --- */}
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              padding: '20px',
            }}
          >
            <Typography
              fontStyle={'normal'}
              fontWeight={600}
              fontSize={'16px'}
              lineHeight={'24px'}
              color={'#000'}
            >
              INVOICE ID : INV-{search}
            </Typography>
            <Box
              bgcolor={'#51B15C1A'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '10px',
                borderRadius: '8px',
              }}
              onClick={() => setIsModalOpen(true)}
            >
              <IconHistory />
              <Typography
                fontStyle={'normal'}
                fontWeight={500}
                fontSize={'14px'}
                lineHeight={'21px'}
                color={'#51b15c'}
                ml={'5px'}
              >
                View History
              </Typography>
            </Box>
          </Box>

          {/* --- Table --- */}
          <Box
            sx={{
              background: '#FAFAFA',
              padding: '20px',
              borderBottom: '1px solid #C8C8CC',
              marginBottom: '15px',
              color: 'black',
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gap: '10px',
                gridTemplateColumns: '5% 30% 57% 8% ',
              }}
            >
              <Box></Box>
              <Typography fontSize={'14px'} fontWeight={'bold'}>
                Treatment
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                }}
              >
                <Typography fontSize={'14px'} fontWeight={'bold'}>
                  Product
                </Typography>
                <Typography fontSize={'14px'} fontWeight={'bold'}>
                  Qty
                </Typography>
              </Box>
              <Box></Box>
            </Box>
          </Box>

          {invoice?.map((item, index) => (
            <Box
              sx={{
                display: 'grid',
                gap: '10px',
                gridTemplateColumns: '5% 30% 57% 8% ',
                borderBottom: '1px solid #C8C8CC',
                padding: '10px',
              }}
            >
              <Box>
                {item?.statusOrder === 'executed' ? (
                  <Box sx={{ marginLeft: '12px' }}>
                    <Box
                      component="img"
                      src={ChecklistIconApproval}
                      sx={{ width: '20px' }}
                    />
                  </Box>
                ) : item?.statusOrder === 'cancel' ? (
                  <Box
                    sx={{
                      marginLeft: '12px',
                    }}
                  >
                    <Box
                      component="img"
                      src={Canceled}
                      sx={{ width: '20px' }}
                    />
                  </Box>
                ) : (
                  <Checkbox
                    disabled={item?.subProduct?.length === 0 ? true : false}
                    {...label}
                    onClick={(e) =>
                      handleCheckboxClick(e, item?.idOrders, item)
                    }
                  />
                )}
              </Box>
              <Box
                sx={{
                  marginTop: '10px',
                }}
              >
                <Typography fontSize={'14px'}>{item?.namaProduk}</Typography>
              </Box>
              <Collapse
                key={index}
                in={show && idTemp === index ? true : false}
                collapsedSize={35}
              >
                {item?.subProduct?.length !== 0 ? (
                  item?.subProduct?.map((details, idx) => (
                    <Box
                      key={idx}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <Typography
                        fontStyle={'normal'}
                        fontSize={'14px'}
                        lineHeight={'150%'}
                        sx={{
                          p: 0.6,
                          marginRight: 'auto',
                        }}
                      >
                        {details?.namaProduk}
                      </Typography>
                      <CustomInput
                        inputProps={{
                          min: 0,
                          sx: {
                            textAlign: 'center',
                            borderRadius: '100px',
                            bgcolor: '#F5F5F5',
                          },
                        }}
                        sx={{
                          pb: '10px',
                          width: '100px',
                          '& .MuiOutlinedInput-input': {
                            padding: '5px 20px 5px 20px',
                            borderRadius: '5px',
                            '&::placeholder': {
                              color: '#111',
                              fontSize: '8px',
                            },
                          },
                          borderRadius: '5px',
                          marginRight: '10px',
                        }}
                        value={
                          changedProduct !== null &&
                          details?.idDetail === Number(changedProduct?.idDetail)
                            ? changedProduct?.qty
                            : details?.jumlah
                        }
                        onChange={(e) => {
                          handleEditProduct(
                            e.target.value,
                            details?.unit,
                            details?.idDetail,
                            details?.idProduk
                          );
                          setChangedProduct({
                            qty: e.target.value,
                            unit: details?.unit,
                            idDetail: details?.idDetail,
                            idProduk: details?.idProduk,
                          });
                        }}
                      />
                      <Select
                        value={details?.unit} //TODO: select unit
                        lineHeight={'150%'}
                        sx={{
                          p: 1,
                          marginRight: '35%',
                          minWidth: '100px',
                          height: '34px',
                          bgcolor: 'white',
                          '.MuiOutlinedInput-input': {
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            paddingLeft: '0px',
                            paddingRight: '0',
                            borderRadius: '5px',
                          },
                        }}
                        onOpen={() => {
                          // setChangedProduct({
                          //   qty: details?.jumlah,
                          //   unit: details?.unit,
                          //   idDetail: details?.idDetail,
                          //   idProduk: details?.idProduk,
                          // });
                          // details?.idDetail ===
                          //   Number(changedProduct?.idDetail) &&
                          fetchProductUnit(details?.idProduk); //TODO : edit unit product
                        }}
                        onChange={(e) => {
                          handleEditProduct(
                            details?.jumlah,
                            e.target.value,
                            details?.idDetail,
                            details?.idProduk
                          );
                          // setChangedProduct({
                          //   qty: details?.jumlah,
                          //   unit: e.target.value,
                          //   idDetail: details?.idDetail,
                          //   idProduk: details?.idProduk,
                          // });
                        }}
                      >
                        <MenuItem
                          value={details?.unit}
                          fontSize={'12px'}
                          sx={{ minWidth: '100px' }}
                        >
                          {details?.unit}
                        </MenuItem>
                        {unit?.map((item, idx) => (
                          <MenuItem
                            value={item?.unitName}
                            fontSize={'12px'}
                            key={idx}
                            sx={{
                              minWidth: '100px',
                              display:
                                details?.unit === item?.unitName && 'none',
                            }}
                          >
                            {item?.unitName}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  ))
                ) : (
                  <Typography
                    fontStyle={'normal'}
                    fontSize={'14px'}
                    lineHeight={'150%'}
                    sx={{
                      p: 0.6,
                      marginRight: 'auto',
                      mb: '10px',
                    }}
                  >
                    {'-'}
                  </Typography>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                  onClick={() => handleAddProduk(item?.idOrders)}
                >
                  <Box
                    sx={{
                      bgcolor: '#51B15C1A',
                      borderRadius: '6px',
                      cursor: 'pointer',
                    }}
                  >
                    <Add
                      sx={{
                        color: '#51B15C',
                        fontSize: '10px',
                        width: '30px',
                        height: '25px',
                        pt: '5px',
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      color: '#51B15C',
                      cursor: 'pointer',
                    }}
                  >
                    Add More
                  </Typography>
                </Box>
              </Collapse>
              <Box
                onClick={() => {
                  handleShow(index);
                  setCollapseDestination(!collapseDestination);
                }}
              >
                <Collapse
                  key={index}
                  in={show && idTemp === index ? true : false}
                  collapsedSize={40}
                >
                  {show && idTemp === index ? (
                    <IconArrowHide />
                  ) : (
                    <IconArrowShow />
                  )}
                </Collapse>
              </Box>
            </Box>
          ))}

          {/* --- Submit --- */}
          <Box
            sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}
          >
            <CustomButton
              variant={'contained'}
              onClick={() => setOpenHub(true)}
              sx={{
                height: '45px',
                width: '190px',
                display: 'flex',
                mb: '5px',
              }}
              disabled={disableSubmit}
            >
              <Typography
                fontStyle={'normal'}
                fontWeight={600}
                fontSize={'14px'}
                lineHeight={'150%'}
                color={'#fff'}
              >
                Execute
              </Typography>
            </CustomButton>
          </Box>

          <CustomDialog
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            title={`INV-${search} History`}
            fullWidth
          >
            <HistoryCard />
          </CustomDialog>

          {/* dialogn Add */}
          <Dialog
            open={openConfirmn}
            onClose={() => setOpenConfirmn(!openConfirmn)}
          >
            <DialogTitle sx={{ boxShadow: '0px 0px 3px 0px #000' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Typography sx={{ fontWeight: 600 }}>Add Product</Typography>
                </Box>
                <CloseIcon onClick={() => setOpenConfirmn(!openConfirmn)} />
              </Box>
            </DialogTitle>
            <DialogContent>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Typography sx={{ fontSize: '15px', fontWeight: 600, mb: 2 }}>
                  Product
                </Typography>
                <Autocomplete
                  sx={{ minWidth: '100%', bgcolor: '#FAFAFA' }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    inputDebounceChange(newInputValue);
                  }}
                  options={datas}
                  getOptionLabel={(option) => option.namaProduk}
                  value={selectedProduct}
                  onChange={(event, newValue) => {
                    setSelectedProduct(newValue);
                    fetchProductUnit(newValue?.idProduk);
                  }}
                  loading={isLoadingProduct}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Product*" />
                  )}
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <Typography sx={{ fontSize: '15px', fontWeight: 600, mb: 2 }}>
                  Qty*
                </Typography>

                <CustomInput
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Box
                          sx={{
                            padding: '0px 10px',
                            // background: "rgba(0, 0, 0, 0.06)",
                            borderRadius: '8px',
                            minWidth: '112px',
                            textAlign: 'center',
                          }}
                        ></Box>
                        <Select
                          sx={{ minWidth: '120px', bgcolor: 'white' }}
                          onChange={(e) => setUnitSelect(e.target.value)}
                        >
                          {unit?.length === 0 ? (
                            <MenuItem fontSize={'12px'}>no data</MenuItem>
                          ) : (
                            unit?.map((item, idx) => (
                              <MenuItem
                                fontSize={'12px'}
                                value={item}
                                key={idx}
                              >
                                {item?.unitName}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  placeholder="Enter Qty"
                  sx={{
                    with: '100%',
                    bgcolor: '#FAFAFA',
                    '& .MuiOutlinedInput-input': {
                      padding: '17px 16px',
                      borderRadius: '10px',
                      '&::placeholder': {
                        color: '#111',
                        fontSize: '16px',
                      },
                    },
                    borderRadius: '5px',
                  }}
                  // placeholder="enter description"
                  // value={item?.unit}

                  onChange={(e) => {
                    // handlePriceChange(idx, e.target.value)
                    setQty(e.target.value);
                  }}
                />
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  gap: 2,
                  mt: 5,
                }}
              >
                <CustomButton
                  variant={'contained'}
                  style={{ height: '40px', width: '100px' }}
                  onClick={handleEnterProduct}
                >
                  {!isLoadingProduct ? (
                    <CircularProgress size={18} sx={{ color: '#FFF' }} />
                  ) : (
                    <Typography
                      fontStyle={'normal'}
                      fontWeight={500}
                      fontSize={'14px'}
                      lineHeight={'150%'}
                      color={'#fff'}
                    >
                      Enter
                    </Typography>
                  )}
                </CustomButton>
              </Box>
            </DialogContent>
          </Dialog>

          {/* dialog execute */}
          <Dialog
            open={openConfirmnExecute}
            onClose={() =>
              dispatch(changeConfirmExecution(!openConfirmnExecute))
            }
          >
            <DialogTitle sx={{ boxShadow: '0px 0px 3px 0px #000' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '100px',
                      background: 'rgba(81, 177, 92, 0.1)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ChecklistIcon />
                  </Box>
                  <Typography sx={{ fontWeight: 600 }}>
                    Confirm Execution
                  </Typography>
                </Box>
                <CloseIcon
                  onClick={() =>
                    dispatch(changeConfirmExecution(!openConfirmnExecute))
                  }
                  sx={{ cursor: 'pointer' }}
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              <Typography
                sx={{ fontSize: '12px', fontWeight: 400, mt: 3, mb: 2 }}
              >
                Are you sure want to execute the item(s) ?
              </Typography>
              <StyledTextarea
                minRows={3}
                placeholder="Enter Notes"
                value={textareaValue}
                onChange={handleTextareaChange}
              />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  gap: 2,
                  mt: 5,
                }}
              >
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => dispatch(changeConfirmExecution(false))}
                >
                  <Typography sx={{ fontSize: '16px', color: '#9E9D9D' }}>
                    Cancel
                  </Typography>
                </Box>
                <CustomButton
                  variant={'contained'}
                  style={{ height: '40px', width: '100px' }}
                  onClick={handleExecution}
                >
                  {!isLoadingProduct ? (
                    <CircularProgress size={18} sx={{ color: '#FFF' }} />
                  ) : (
                    <Typography
                      fontStyle={'normal'}
                      fontWeight={500}
                      fontSize={'14px'}
                      lineHeight={'150%'}
                      color={'#fff'}
                    >
                      Confirm
                    </Typography>
                  )}
                </CustomButton>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </Box>
  );
};

export default SearchFound;
