import { TextField, useTheme } from "@mui/material";

const CustomInput = ({
  sx,
  value,
  setValue,
  error,
  // handleChange,
  ...props
}) => {
  const theme = useTheme();
  return (
    <TextField
      value={value}
      // onChange={(e) => {
      //   setValue(e.target.value);
      //   handleChange && handleChange(value);
      // }}
      error={error}
      {...props}
      sx={{
        ".MuiInputLabel-root": {
          lineHeight: 1.5,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          boxShadow: error && `0 0 4px ${theme.palette.error.main}`,
        },
        "& .Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            boxShadow: error && `0 0 8px ${theme.palette.error.main}`,
          },
        },
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            boxShadow: error && `0 0 8px ${theme.palette.error.main}`,
          },
        },
        ...sx,
      }}
      // {...props}
    />
  );
};
export default CustomInput;
