import { useDispatch, useSelector } from 'react-redux';
import BackButton from '../../../../../../components/shared/BackButton';
import {
  changePage,
  getAllPurchaseRouting,
} from '../../../../../../store/Routing/purchaseRouting';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { ArrowRightAltIcon } from '../../../../../../assets/Icons';

const BackButtonPurchaseDetailFInanceSuppllierBid = () => {
  const dispatch = useDispatch();
  const { idPurchase, subsidiaryName, hubName } = useSelector(
    getAllPurchaseRouting
  );

  const handleBackButton = () => {
    dispatch(changePage(''));
  };
  const desktopMode = useMediaQuery('(min-width:832px)');
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        <Box>
          <Typography
            sx={{ fontSize: desktopMode ? '24px' : '14px', fontWeight: '600' }}
          >
            Detail Purchase
          </Typography>
          <Box
            sx={{
              display: 'flex',
              color: '#9E9D9D',

              alignItems: 'center',
              mt: '4px',
              gap: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: desktopMode ? '16px' : '12px',
                fontWeight: '100',
              }}
            >
              {subsidiaryName || '-'}
            </Typography>
            <ArrowRightAltIcon sx={{ fontSize: '16px' }} />
            <Typography
              sx={{
                fontSize: desktopMode ? '16px' : '12px',
                fontWeight: '100',
              }}
            >
              {hubName || '-'}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          bgcolor: '#6342E81A',
          p: desktopMode ? '8px 12px' : '4px 8px',
          borderRadius: '4px',
        }}
      >
        <Typography
          sx={{
            color: '#6342E8',
            fontWeight: '600',
            fontSize: desktopMode ? '16px' : '12px',
          }}
        >
          Supplier Bid
        </Typography>
      </Box>
    </Box>
  );
};
export default BackButtonPurchaseDetailFInanceSuppllierBid;
