import { Box, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { getAllPurchaseRouting } from '../../../../../../store/Routing/purchaseRouting';
import InventoryPurchase from '..';
import PurchaseRequest from '../Request/PurchaseRequest';
import AddRequestPurchase from '../Request/Add/AddRequestPurchase';
import PurchaseRequestDetails from '../Request/PurchaseRequestDetails/PurchaseDetails';
import RequestDetailFinance from '../RequestDetailFinance/RequestDetailFinance';
import PurchaseAddFinance from '../PurchaseAddFinance/PurchaseAddFinance';
import PurchaseEditFinance from '../PurchaseEditFinance/PurchaseEditFinance';
import PurchaseDetailFinance from '../PurchaseDetailFinance/PurchaseDetailFinance';
import PurchaseDetailSupplier from '../PurchaseDetailSupplier/PurchaseDetailSupplier';
import PurchaseProcessSupplier from '../PurchaseProcessSupplier/PurchaseProcessSupplier';
import DetailPurchase from '../DetailPurchase/detailPurchase';
import NotifPruchase from './NotifPruchase';
import PurchaseQrCode from '../PurchaseQrQode/PurchaseQrCode';
import PurchaseDetailFinanceSupplierBid from '../PurchaseDetailFinanceSupplierBid/PurchaseDetailFinanceSupplierBid';
import PurchaseProcessDetailSupplier from '../PurchaseProcessDetailSupplier/PurchaseProcessDetailSupplier';
import PurchaseProcessScanSupplier from '../PurchaseProcessScanSupplier/PurchaseProcessScanSupplier';
import PurchaseProcessDetailSupplierSubmit from '../PurchaseProcessDetailSupplierSubmit/PurchaseProcessDetailSupplierSubmit';
import PurchaseProsesScanMobile from '../PurchaseMobile/PurchaseProsesScanMobile/PurchaseProsesScanMobile';
import PurchaseProcessedScanMobile from '../PurchaseMobile/PurchaseProsesScanMobile/PurchaseProcessedScanMobile';
import PurchaseFinishScanMobile from '../PurchaseMobile/PurchaseFinishScanMobile/PurchaseFinishScanMobile';
import ScanRackPurchaseMobile from '../PurchaseMobile/ScanRack/ScanRackPurchaseMobile';
import PurchaseFinishedScanMobile from '../PurchaseMobile/PurchaseFinishScanMobile/PurchaseFinishedScanMobile';
import CaptureImagaProductSupplier from '../PurchaseProcessScanSupplier/PurchaseCaptureProductSupplier';
import CaptureImagaProductAccept from '../PurchaseMobile/PurchaseProsesScanMobile/PurchaseCaptureProductAdmin';
import CaptureImagaProductFinish from '../PurchaseMobile/PurchaseFinishScanMobile/PurchaseCaptureFinishProduct';

const RoutingPurchase = () => {
  const { page } = useSelector(getAllPurchaseRouting);

  return (
    <Box>
      <Box>
        <NotifPruchase />
      </Box>
      {page === '' && <InventoryPurchase />}
      {page === 'request' && <PurchaseRequest />}
      {page === 'requestAdd' && <AddRequestPurchase />}
      {page === 'requestDetail' && <PurchaseRequestDetails />}
      {page === 'requestDetailFinance' && <RequestDetailFinance />}
      {page === 'purchaseAddFinance' && <PurchaseAddFinance />}
      {page === 'purchaseDetailFinance' && <PurchaseDetailFinance />}
      {page === 'purchaseEditFinance' && <PurchaseEditFinance />}
      {page === 'purchaseDetailSupplier' && <PurchaseDetailSupplier />}
      {page === 'purchaseProcessSupplier' && <PurchaseProcessSupplier />}
      {page === 'purchaseProcessDetailSupplier' && (
        <PurchaseProcessDetailSupplier />
      )}
      {page === 'purchaseProcessScanSupplier' && (
        <PurchaseProcessScanSupplier />
      )}
      {page === 'captureImagaProductSupplier' && (
        <CaptureImagaProductSupplier />
      )}
      {page === 'purchaseProcessDetailSupplierSubmit' && (
        <PurchaseProcessDetailSupplierSubmit />
      )}
      {page === 'detail' && <DetailPurchase />}
      {page === 'qrCode' && <PurchaseQrCode />}
      {page === 'purchaseDetailFinanceSupplierBid' && (
        <PurchaseDetailFinanceSupplierBid />
      )}
      {page === 'purchaseProsesScanAdminMobile' && <PurchaseProsesScanMobile />}
      {page === 'purchaseProcessedScanAdminMobile' && (
        <PurchaseProcessedScanMobile />
      )}
      {page === 'captureImagaProductAdmin' && <CaptureImagaProductAccept />}
      {page === 'scanRackPurchaseMobile' && <ScanRackPurchaseMobile />}
      {page === 'purchaseFinishScanAdminMobile' && <PurchaseFinishScanMobile />}
      {page === 'captureImagaProductFinish' && <CaptureImagaProductFinish />}
      {page === 'purchaseProsesScanFinishMobile' && (
        <PurchaseFinishedScanMobile />
      )}
    </Box>
  );
};
export default RoutingPurchase;
