import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import BackButtonHub from "../../../../../../../../components/shared/BackButtonHub";
import {
  AddIcon,
  CancelIcon,
  CloseIcon,
  ErrorOutlineIcon,
  EyeIcon,
  ImageOutlinedIcon,
  KeyboardArrowDownIcon,
  VideocamOutlinedIcon,
} from "../../../../../../../../assets/Icons";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CustomInput from "../../../../../../../../components/shared/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import {
  changeImagePreviewEdit,
  changeIsEdit,
  changeIsLoading,
  changeIsLoadingUpdate,
  changeIsMaxSizeImageEdit,
  changePicChooseEdit,
  changePicPhoneEdit,
  changePicSearchDebounceEdit,
  changePicSearchEdit,
  clickIsPreviewImageEdit,
  deleteImage,
  getMiniHubEditAll,
  inputImageEdit,
  inputMiniHubImageEdit,
  deleteTayangan,
  clickIsVideoPreviewEdit,
  inputTayangan,
  changeVideoPreviewEdit,
  changeStatus,
} from "../../../../../../../../store/hub/miniHub/miniHubEdit";
import CustomCard from "../../../../../../../../components/shared/CustomCard";
import { Map } from "pigeon-maps";
import CustomAntSwitch from "../../../../../../../../components/shared/CustomAntSwitch";
import CustomButton from "../../../../../../../../components/shared/CustomButton";
import { useEffect, useState } from "react";
import { getSubsidiary } from "../../../../../../../../api/subsidiary";
import CustomEditConfirm from "../../../../../../../../components/shared/CustomEditConfirm";
import { changeNameMiniHubEdit } from "../../../../../../../../store/hub/miniHub/miniHubEdit";
import { changeSubsidiaryId } from "../../../../../../../../store/hub/masterHub/masterHubEdit";
import { changePageSubHub } from "../../../../../../../../store/Routing/hubs";
import {
  editMiniHub,
  editStatusMiniHub,
} from "../../../../../../../../api/hubs";
import SetupAddressMobile from "../../../MasterHub/Mobile/Edit/AddressMap/SetupAddressMobile";
import {
  fetchPic,
  getPicList,
  getPicLoading,
} from "../../../../../../../../store/pic/pic";
import { useRef } from "react";
import debounce from "lodash.debounce";
import AddressMapMobile from "./AddressMap/AdressMapMobile";

const EditMiniHubMobile = () => {
  const {
    subsidiary,
    subsidiaryId,
    idMasterHub,
    name,
    idMiniHub,
    isLoadingMasterHub,
    image,
    isMaxSizeImage,
    video,
    isLoadingVideo,
    isLoading,
    isMaxSizeVideo,
    isImagePreview,
    isVideoPreview,
    imagePreview: linkImagePreview,
    videoPreview: linkVideoPreview,
    picChoose,
    picSearch,
    pic,
    picSearchDebounce,
    picPhone,
    coordinate,
    address,
    status,
    addressFix,
    coordinateFix,
    isLoadingUpdate,
    mapInputType,
    placeGetByName,
    idSubHub,
  } = useSelector(getMiniHubEditAll);
  const dispatch = useDispatch();
  const theme = useTheme();
  const picList = useSelector(getPicList);
  const [subSidiaryData, setSubsidiaryData] = useState([]);
  const [newSubsidiary, setNewSubsidiary] = useState(subsidiary);
  const [isEditConfirm, setIsEditConfirm] = useState(false);
  const [isLoadingOnUpdate, setIsLoadingOnUpdate] = useState(false);
  const [isLoadingSubsidiary, setIsLoadingSubsidiary] = useState(false);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const picLoading = useSelector(getPicLoading);

  useEffect(() => {
    const getSubsidiaries = async () => {
      setIsLoadingSubsidiary(true);
      try {
        const {
          data: {
            data: { subsidiaries },
          },
        } = await getSubsidiary();
        setSubsidiaryData(subsidiaries);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoadingSubsidiary(false);
      }
    };

    getSubsidiaries();
  }, []);

  useEffect(() => {
    picChoose !== null
      ? dispatch(changePicPhoneEdit(picChoose?.telpon))
      : dispatch(changePicPhoneEdit(""));
  }, [picChoose]);

  const picSearchInDebounce = useRef(
    debounce((value) => {
      dispatch(changePicSearchDebounceEdit(value));
    }, 1000)
  ).current;

  useEffect(() => {
    dispatch(fetchPic(picSearchDebounce));
  }, [picSearchDebounce]);

  const handleSave = () => {
    setIsEditConfirm(!isEditConfirm);
  };

  const handleSaveFix = () => {
    setIsLoadingOnUpdate(true);
    const { idKustomer } = picChoose;
    const upadeteHub = {
      subsidiary: subsidiary,
      subsidiaryId: subsidiaryId,
      name: name,
      subHubId: idSubHub,
      idKustomer: idKustomer,
      idMiniHub: idMiniHub,
      images: image,
      videos: video,
      images: image.length !== 0 ? image.join(",") : "",
      videos: video.length !== 0 ? video.join(",") : "",
      pic: picChoose,
      picPhone: picPhone,
      address: addressFix === "" ? address : addressFix,
      lon: coordinateFix[1].toString(),
      lat: coordinateFix[0].toString(),
      typeInput: mapInputType,
      cityDistrict: placeGetByName?.formatted,
    };

    dispatch(changeIsLoadingUpdate(true));
    const updateMiniHub = async () => {
      try {
        await editMiniHub(idMiniHub, upadeteHub);
        setIsEditConfirm(!isEditConfirm);
        dispatch(changeIsEdit(false));
        dispatch(changePageSubHub("MiniHub"));
        setIsEditConfirm(!isEditConfirm);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(changeIsLoadingUpdate(false));
      }
    };
    updateMiniHub();
  };

  const handleEditStatus = () => {
    if (status) {
      editStatusMiniHub(idMiniHub, "pending");
      dispatch(changeStatus(false));
    } else {
      editStatusMiniHub(idMiniHub, "aktif");
      dispatch(changeStatus(true));
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Grid container columns={12} spacing={3} sx={{ pb: 3 }}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              gap: 1.5,
              alignItems: "center",
              mt: 2,
              mb: 2,
            }}
          >
            <BackButtonHub onClick={() => dispatch(changeIsEdit())} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, color: "#000" }}
              >
                Edit Mini Hub
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <EyeIcon />
                <Typography
                  sx={{ fontSize: "12px", fontWeight: 600, color: "#51B15C" }}
                >
                  Preview
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        {/* subsidiary */}

        <Grid item xs={12}>
          <Autocomplete
            options={subSidiaryData}
            value={newSubsidiary}
            getOptionLabel={(option) => option.name}
            onChange={(_, newValue) => {
              setNewSubsidiary(newValue);
              dispatch(changeSubsidiaryId(newValue?.id));
            }}
            renderInput={(params) => (
              <TextField
                label="Subsidiary"
                variant="filled"
                {...params}
                sx={{
                  bgcolor: "#fafafa",
                  borderRadius: "5px",
                  fontSize: "14px",
                  color: "#9D9D9D",
                }}
              />
            )}
          />
        </Grid>
        {/* Name */}
        <Grid item xs={12}>
          <CustomInput
            label="Name*"
            variant="filled"
            sx={{ width: "100%", bgcolor: "#FAFAFA", fontSize: "14px" }}
            placeholder="Enter hub name"
            value={name}
            onChange={(e) => {
              if (e.target.value.length <= 30) {
                dispatch(changeNameMiniHubEdit(e.target.value));
              }
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: "400",
                color: "#9E9D9D",
                mt: "4px",
              }}
            >
              Enter at least 5 character
            </Typography>
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: "400",
                color: "#9E9D9D",
                mt: "4px",
              }}
            >
              0/30
            </Typography>
          </Box>
        </Grid>

        {/* Photo */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              pb: `${image.length === 0 ? "16px" : "9px"}`,
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              Photo
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#9E9D9D",
              }}
            >
              ( max size : 2MB, max file : 3 )
            </Typography>
            {isMaxSizeImage && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ErrorOutlineIcon
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: theme.palette.warning.main,
                    mr: "4px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: theme.palette.warning.main,
                  }}
                >
                  The photo can't be more than 2MB
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {image.length === 0 && (
                <Box
                  sx={{
                    height: "54px",
                    width: "54px",
                    aspectRatio: "1/1",
                    borderRadius: "8px",
                    bgcolor: "#F5F5F5",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderStyle: "dashed",
                    borderColor: "#9E9D9D",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    document.getElementById("input_img").click();
                  }}
                >
                  <ImageOutlinedIcon
                    sx={{ color: "#9E9D9D", fontSize: "28px" }}
                  />
                </Box>
              )}
              {image.map((input, index) => (
                <Box
                  sx={{
                    position: "relative",
                    overflow: "visible",
                    padding: "7px",
                  }}
                  key={index}
                >
                  <CancelIcon
                    sx={{
                      position: "absolute",
                      fontSize: "20px",
                      top: 1,
                      right: 1,
                      zIndex: +1,
                      cursor: "pointer",
                    }}
                    onClick={() => dispatch(deleteImage(input))}
                  />
                  <Box
                    component="img"
                    src={process.env.REACT_APP_API_IMAGE_BASE_URL + "/" + input}
                    sx={{
                      height: "54px",
                      width: "54px",
                      aspectRatio: "1/1",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      dispatch(clickIsPreviewImageEdit());
                      dispatch(changeImagePreviewEdit(input));
                    }}
                  />
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                mb: "7px",
                ml: "20px",
                alignItems: "center",
              }}
            >
              {image.length < 3 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    document.getElementById("input_img").click();
                  }}
                >
                  <input
                    accept="image/*"
                    id="input_img"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => dispatch(inputMiniHubImageEdit(e))}
                  />
                  {!isLoading ? (
                    <>
                      <AddIcon />
                      <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                        Add More
                      </Typography>
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <CircularProgress size={16} />
                      <Typography> uploading...</Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>

        {/* Video */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              pb: `${video.length === 0 ? "16px" : "9px"}`,
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
              Video
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#9E9D9D",
              }}
            >
              ( max size : 100MB, max file : 3 )
            </Typography>
            {isMaxSizeVideo && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ErrorOutlineIcon
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: theme.palette.warning.main,
                    mr: "4px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "8px",
                    fontWeight: "500",
                    color: theme.palette.warning.main,
                  }}
                >
                  The video is too big
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {video.length === 0 && (
                <Box
                  sx={{
                    height: "54px",
                    width: "54px",
                    aspectRatio: "1/1",
                    borderRadius: "8px",
                    bgcolor: "#F5F5F5",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderStyle: "dashed",
                    borderColor: "#9E9D9D",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    document.getElementById("inputMenu_video").click();
                  }}
                >
                  <VideocamOutlinedIcon
                    sx={{ color: "#9E9D9D", fontSize: "28px" }}
                  />
                </Box>
              )}
              {video.map((input, index) => (
                <Box
                  sx={{
                    position: "relative",
                    overflow: "visible",
                    padding: "7px",
                  }}
                  key={index}
                >
                  <CancelIcon
                    sx={{
                      position: "absolute",
                      fontSize: "20px",
                      top: 1,
                      right: 1,
                      zIndex: +1,
                      cursor: "pointer",
                    }}
                    onClick={() => dispatch(deleteTayangan(input))}
                  />
                  <Box
                    onClick={() => {
                      dispatch(clickIsVideoPreviewEdit());
                      dispatch(changeImagePreviewEdit(input));
                    }}
                  >
                    <video
                      style={{
                        maxHeight: "54px",
                        maxWidth: "54px",
                        aspectRatio: "1/1",
                        borderRadius: "8px",
                        cursor: "pointer",
                        backgroundColor: "black",
                      }}
                    >
                      <source
                        src={
                          process.env.REACT_APP_API_IMAGE_BASE_URL +
                          "/" +
                          input +
                          "#t=0.5"
                        }
                      />
                    </video>
                  </Box>
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                mb: "7px",
                ml: "20px",
                alignItems: "center",
              }}
            >
              {video.length < 3 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    document.getElementById("inputMenu_video").click();
                  }}
                >
                  <input
                    id="inputMenu_video"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => dispatch(inputTayangan(e))}
                  />
                  {!isLoadingVideo ? (
                    <>
                      <AddIcon />
                      <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                        Add More
                      </Typography>
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <CircularProgress size={16} />
                      <Typography> uploading...</Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>

        {/*  Pic */}
        <Grid item xs={12}>
          <Autocomplete
            options={picList}
            getOptionLabel={(option) => option.namaLengkap}
            value={picChoose}
            onChange={(_, newValue) => {
              dispatch(changePicChooseEdit(newValue));
              dispatch(changePicPhoneEdit(newValue.telpon));
            }}
            inputValue={picSearch}
            loading={picLoading}
            onInputChange={(_, newInputValue) => {
              dispatch(changePicSearchEdit(newInputValue));
              picSearchInDebounce(newInputValue);
            }}
            renderOption={(props, option) => (
              <li key={option.idKustomer} {...props}>
                {option.namaLengkap}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                variant="filled"
                label="PIC*"
                {...params}
                placeholder="Search "
                sx={{
                  width: "100%",
                  bgcolor: "#FAFAFA",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              />
            )}
          />
        </Grid>

        {/* Phone Number */}
        <Grid item xs={12}>
          <CustomInput
            sx={{
              width: "100%",
              bgcolor: "#FAFAFA",
              fontSize: "14px",
              fontWeight: 500,
            }}
            disabled
            type="number"
            variant="filled"
            placeholder="enter your number"
            value={picPhone}
            onChange={(e) => {
              dispatch(changePicPhoneEdit(e.target.value));
            }}
          ></CustomInput>
        </Grid>

        {/*  Address */}
        <Grid item xs={12} onClick={() => setIsMapOpen(true)}>
          <CustomCard sx={{ p: "8px 16px", cursor: "pointer" }}>
            <Box
              sx={{
                display: "center",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box
                  sx={{
                    width: "59px",
                    height: "59px",
                    bgcolor: "gray",
                    borderRadius: "8px",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      bgcolor: "white",
                      width: "100%",
                      height: "100%",
                      zIndex: "+2",
                      opacity: 0.5,
                    }}
                  ></Box>
                  <Box
                    sx={{
                      maxHeight: "59px",
                      overflow: "hidden",
                      borderRadius: "8px",
                    }}
                  >
                    <Map
                      defaultCenter={coordinate}
                      height={130}
                      defaultZoom={17}
                      width={59}
                      style={{
                        borderRadius: "8px",
                      }}
                    ></Map>
                  </Box>
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: "500", mb: "4px", ml: "8px" }}>
                    Address
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.4,
                    }}
                  >
                    <LocationOnIcon color="primary" />
                    <Typography>{address}</Typography>
                  </Box>
                </Box>
              </Box>
              <KeyboardArrowDownIcon />
            </Box>
          </CustomCard>
        </Grid>

        {/* publish */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              Publish
            </Typography>
            <CustomAntSwitch
              checked={status}
              onChange={(e) => handleEditStatus(e)}
            />
          </Box>
        </Grid>

        {/*  Dialog Photo */}
        <Dialog
          open={isImagePreview}
          onClose={() => {
            dispatch(clickIsPreviewImageEdit());
            dispatch(changeImagePreviewEdit(""));
          }}
        >
          <Box
            component="img"
            src={
              process.env.REACT_APP_API_IMAGE_BASE_URL + "/" + linkImagePreview
            }
            sx={{ maxWidth: "300px", maHeight: "300px", aspectRation: "1/1" }}
          />
        </Dialog>

        {/*  Video Dialog */}
        <Dialog
          open={isVideoPreview}
          onClose={() => {
            dispatch(clickIsVideoPreviewEdit());
            dispatch(changeVideoPreviewEdit(""));
          }}
        >
          <Box sx={{ bgcolor: "black" }}>
            <video
              style={{
                maxWidth: "400px",
                maxHeight: "300px",
                backgroundColor: "black",
              }}
              controls
            >
              <source
                src={
                  process.env.REACT_APP_API_IMAGE_BASE_URL +
                  "/" +
                  linkVideoPreview
                }
              />
            </video>
          </Box>
        </Dialog>

        {/*  Dialog Address */}
        <Dialog
          open={isMapOpen}
          onClose={() => {
            setIsMapOpen(!isMapOpen);
          }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>Input Address</Box>
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onClick={() => setIsMapOpen(false)}
            />
          </DialogTitle>
          <DialogContent dividers>
            <AddressMapMobile setIsOpen={setIsMapOpen} />
          </DialogContent>
        </Dialog>
      </Grid>

      {/* button event */}
      <Box
        sx={{
          width: "100%",
          background: "#FFFFFF",
          position: "sticky",
          bottom: 0,
          padding: "8px 0px",
          zIndex: 99,
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomButton
            variant="contained"
            color="error"
            sx={{
              width: "100%",
              height: "36px",
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              Delete
            </Typography>
          </CustomButton>
          <CustomButton
            variant="contained"
            sx={{ width: "100%", height: "36px" }}
          >
            <Typography
              sx={{
                color: "white",
                fontSize: "12px",
                fontWeight: "500",
              }}
              onClick={() => handleSave()}
            >
              Save
            </Typography>
          </CustomButton>

          {/*  Dialog Photo */}
          {/* <Dialog
            open={isPhotoPreview}
            onClose={() => {
              dispatch(clickIsPreviewImageEdit());
              dispatch(changeImagePreviewEdit(""));
            }}
          >
            <Box
              component="img"
              src={
                process.env.REACT_APP_API_IMAGE_BASE_URL +
                "/" +
                linkImagePreview
              }
              sx={{ maxWidth: "300px", maHeight: "300px", aspectRation: "1/1" }}
            />
          </Dialog> */}

          <CustomEditConfirm
            open={isEditConfirm}
            onClose={() => setIsEditConfirm(false)}
            handleClickCancel={() => setIsEditConfirm(false)}
            handleClickEdit={handleSaveFix}
            title="Edit Product"
            body={
              <Box sx={{ textAlign: "center", mb: "40px", mt: "8px" }}>
                <Typography>
                  Are you sure you want to save the changes?
                </Typography>
                {isLoadingUpdate && (
                  <CircularProgress size={16} sx={{ mt: "12px" }} />
                )}
              </Box>
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EditMiniHubMobile;
