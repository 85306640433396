import { createSlice } from '@reduxjs/toolkit';

export const stockRoutingState = createSlice({
  name: 'stockRouting',
  initialState: {
    role: '',
    page: '',
    idStock: 0,
    // idRequest: 0,
    keyNotif: '',
    textNotif: '',
    subsidiaryName: '',
    hubName: '',
    // statusPurchase: '',
  },
  reducers: {
    changePageStock: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const { changePageStock } = stockRoutingState.actions;

export const getAllStockRouting = (state) => state.stockRouting;

export default stockRoutingState.reducer;
