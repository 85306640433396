import { alereAxios } from '../utils/api';

export const fetchAllPurchase = (params) =>
  alereAxios.get('purchases/dashboardtable', {
    params: params,
  });

export const fetchChartPurchase = (params) =>
  alereAxios.get('purchases/dashboardtoppurchase', { params: params });

export const createRequestPurchase = (data) =>
  alereAxios.post('purchases', data);

export const getPurchaseRequesrList = (params) =>
  alereAxios.get('purchases/dashboardrequestdetail', {
    params: params,
  });

export const getPurchaseRequestDetail = (id) =>
  alereAxios.get(`purchases/${id}`);

export const getDasboardPurchaseRequest = (params) =>
  alereAxios.get('purchases/dashboardrequest', { params: params });

export const getDataPurchaseRequestList = (params) =>
  alereAxios.get('purchases/dashboardrequestdetail', { params: params });

export const updatePurchaseRequest = (id, data) =>
  alereAxios.put(`purchases/${id}`, data);

export const rejectPurchaseRequest = (id, params) =>
  alereAxios.put(`purchases/status/${id}`, params);

export const editStatusPurchase = (id, data) =>
  alereAxios.put(`purchases/status/${id}`, data);

export const deletePurchase = (id) => alereAxios.delete(`purchases/${id}`);

// product detail
export const getDetailProduct = (id) => alereAxios.get(`products/${id}`);
