import { Grid } from '@mui/material';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import ChartPerfomance from './ChartPerfomance';
import TablePerfomance from './TablePerfomance';

const ProductPerfomance = (props) => {
  const {
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
  } = props;
  return (
    <CustomCard sx={{ p: '10px 20px' }}>
      <Grid container columns={12} spacing={2}>
        <Grid item xs={8}>
          <ChartPerfomance
            {...{
              timeFrame,
              setTimeFrame,
              dateRange,
              setDateRange,
              saveDateRange,
              setSaveDateRange,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TablePerfomance />
        </Grid>
      </Grid>
    </CustomCard>
  );
};
export default ProductPerfomance;
