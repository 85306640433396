import { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

import dayjs from 'dayjs';
import BackButton from '../../../../../../components/shared/BackButton';
import CustomInput from '../../../../../../components/shared/CustomInput';
import CustomCard from '../../../../../../components/shared/CustomCard';
import { uploadImage } from '../../../../../../api/storage';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import AddMiniSubCategoryInSubCategory from '../../Product/SubCategoryProductAdd/Desktop/AddMiniSubCategoryInSubCategory';
import {
  addNewMiniSubCategoryMenu,
  addNewSubCategoryMenu,
} from '../../../../../../api/menu';
import CustomButton from '../../../../../../components/shared/CustomButton';

const SubCategoryMenuAddDesktop = (props) => {
  const {
    theme,
    today,
    AntSwitch,
    // *--- Notif
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
    // *--- page
    setPage,
    setSubCategoryMenu,
    // *--- Data Value
    subCategoryAddInputDatas,
    setSubCategoryAddInputDatas,
    // *--- submit
    isSubmitAvailable,
    setIsSubmitAvailable,
    // *--- Mini
    miniSub1,
    miniSub2,
    miniSub3,
    setMiniSub1,
    setMiniSub2,
    setMiniSub3,
    indexMiniSubCategory,
    setIndexMiniSubCategory,
    // *--- Handler
    handleBackButton,
    handleAddImage,
    handleDeleteImage,
    handleAdd,
  } = props;
  return (
    <Grid container columns={12} spacing={2} sx={{ pb: '56px' }}>
      {/* //* Back */}
      <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        <Typography
          sx={{
            ml: '8px',
            fontSize: '24px',
            fontWeight: '600',
          }}
        >
          Add Subcategory
        </Typography>
      </Grid>
      {/* //* Name */}
      <Grid item xs={6}>
        <Typography sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}>
          Name*
        </Typography>
        <CustomInput
          sx={{ width: '100%', bgcolor: '#FAFAFA' }}
          placeholder="Enter SubCategory Name"
          value={subCategoryAddInputDatas.name}
          onChange={(e) => {
            if (e.target.value.length <= 30) {
              setSubCategoryAddInputDatas({
                ...subCategoryAddInputDatas,
                name: e.target.value,
              });
            }
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            Enter at least 5 character
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            {subCategoryAddInputDatas.name.length}/30
          </Typography>
        </Box>
      </Grid>
      {/* //* Photo */}
      <Grid item xs={6} sx={{ minHeight: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            pb: `${
              subCategoryAddInputDatas.photo.length === 0 ? '16px' : '9px'
            }`,
          }}
        >
          <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
            Photo
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '500',
              color: '#9E9D9D',
            }}
          >
            ( max size : 2MB )
          </Typography>
          {subCategoryAddInputDatas.isMaxSizePhoto && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ErrorOutlineIcon
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  color: theme.palette.warning.main,
                  mr: '4px',
                }}
              />
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  color: theme.palette.warning.main,
                }}
              >
                The photo can't be more than 2MB
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {subCategoryAddInputDatas.photo.length === 0 && (
              <Box
                sx={{
                  height: '54px',
                  width: '54px',
                  aspectRatio: '1/1',
                  borderRadius: '8px',
                  bgcolor: '#F5F5F5',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderStyle: 'dashed',
                  borderColor: '#9E9D9D',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  document.getElementById('inputSubCat_img').click();
                }}
              >
                <ImageOutlinedIcon
                  sx={{ color: '#9E9D9D', fontSize: '28px' }}
                />
              </Box>
            )}
            {subCategoryAddInputDatas.photo.map((input, index) => (
              <Box
                sx={{
                  position: 'relative',
                  overflow: 'visible',
                  padding: '7px',
                }}
                key={index}
              >
                <CancelIcon
                  sx={{
                    position: 'absolute',
                    fontSize: '20px',
                    top: 1,
                    right: 1,
                    zIndex: +1,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleDeleteImage(input, index)}
                />
                <Box
                  component="img"
                  src={URL.createObjectURL(input)}
                  sx={{
                    height: '54px',
                    width: '54px',
                    aspectRatio: '1/1',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSubCategoryAddInputDatas({
                      ...subCategoryAddInputDatas,
                      indexPreviePhoto: index + 1,
                      isPreviewPhoto: 'true',
                    });
                  }}
                />
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: 'flex',
              mb: '7px',
              ml: '20px',
              alignItems: 'center',
            }}
          >
            {subCategoryAddInputDatas.photo.length < 3 && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.palette.primary.main,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  document.getElementById('inputSubCat_img').click();
                }}
              >
                <input
                  accept="image/*"
                  id="inputSubCat_img"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleAddImage}
                />
                <AddIcon />
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                  Add More
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      {/* //* DIalog Photo */}
      {subCategoryAddInputDatas.indexPreviePhoto !== 0 && (
        <Dialog
          open={subCategoryAddInputDatas.isPreviewPhoto}
          onClose={() => {
            setSubCategoryAddInputDatas({
              ...subCategoryAddInputDatas,
              isPreviewPhoto: !subCategoryAddInputDatas.isPreviewPhoto,
            });
          }}
        >
          <Box
            component="img"
            src={URL.createObjectURL(
              subCategoryAddInputDatas.photo[
                subCategoryAddInputDatas.indexPreviePhoto - 1
              ]
            )}
            sx={{ width: '600px', height: '600px', aspectRation: '1/1' }}
          />
        </Dialog>
      )}
      {/* //* Description */}
      <Grid item xs={6}>
        <Typography sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}>
          Description
        </Typography>
        <CustomInput
          sx={{ width: '100%', bgcolor: '#FAFAFA' }}
          placeholder="Enter Category Name"
          value={subCategoryAddInputDatas.description}
          onChange={(e) => {
            if (e.target.value.length <= 250) {
              setSubCategoryAddInputDatas({
                ...subCategoryAddInputDatas,
                description: e.target.value,
              });
            }
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            Enter at least 20 character
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            {subCategoryAddInputDatas.description.length}/250
          </Typography>
        </Box>
      </Grid>
      {/* //* Schedule */}
      <Grid item xs={6} sx={{}}>
        <Grid container>
          <Grid item alignItems="center" sx={{ height: '100%', mr: '20px' }}>
            <Typography
              sx={{
                mb: '16px',
                fontSize: '16px',
                fontWeight: '500',
              }}
            >
              Schedule
            </Typography>
          </Grid>
          <Grid item alignItems="center" sx={{ height: '100%' }}>
            <RadioGroup
              row
              value={subCategoryAddInputDatas.scheduleType}
              onChange={(e) => {
                setSubCategoryAddInputDatas({
                  ...subCategoryAddInputDatas,
                  schedule: today,
                  scheduleType: e.target.value,
                });
              }}
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: '16px',
                },
                '.MuiRadio-root': {
                  padding: '0 8px',
                },
                '.MuiTypography-root': {
                  fontSize: '12px',
                },
              }}
            >
              <FormControlLabel value="now" control={<Radio />} label="Now" />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', minWidth: '100%' }}>
          <DesktopDatePicker
            inputFormat="DD/MM/YYYY"
            value={subCategoryAddInputDatas.schedule}
            onChange={(newValue) => {
              if (subCategoryAddInputDatas.scheduleType !== 'now') {
                setSubCategoryAddInputDatas({
                  ...subCategoryAddInputDatas,
                  schedule: newValue,
                });
              } else {
                setSubCategoryAddInputDatas({
                  ...subCategoryAddInputDatas,
                  schedule: today,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              />
            )}
            disabled={
              subCategoryAddInputDatas.scheduleType === 'now' ? true : false
            }
          />
        </Box>
      </Grid>
      {/* //* Add Mini SUb */}
      {subCategoryAddInputDatas.miniSubCategoryIndex.length === 0 && (
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: theme.palette.primary.main,
            }}
            onClick={() => {
              const value = indexMiniSubCategory[0];
              setSubCategoryAddInputDatas({
                ...subCategoryAddInputDatas,
                miniSubCategoryIndex: [
                  ...subCategoryAddInputDatas.miniSubCategoryIndex,
                  value,
                ],
              });

              setIndexMiniSubCategory(
                indexMiniSubCategory.filter((nilai) => nilai !== value)
              );
            }}
          >
            <AddIcon
              sx={{
                fontSize: '20px',
                cursor: 'pointer',
              }}
            />
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                cursor: 'pointer',
              }}
            >
              Add Mini Sub
            </Typography>
          </Box>
        </Grid>
      )}
      {/* //* Sub */}
      {subCategoryAddInputDatas.miniSubCategoryIndex.length !== 0 && (
        <Grid item xs={12}>
          <CustomCard sx={{ padding: '20px 16px ' }}>
            <Grid container columns={12} rowSpacing={5}>
              {indexMiniSubCategory.length !== 0 && (
                <Grid item xs={12} sx={{ mb: '5px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: theme.palette.primary.main,
                    }}
                    onClick={() => {
                      const value = indexMiniSubCategory[0];
                      setSubCategoryAddInputDatas({
                        ...subCategoryAddInputDatas,
                        miniSubCategoryIndex: [
                          ...subCategoryAddInputDatas.miniSubCategoryIndex,
                          value,
                        ],
                      });
                      setIndexMiniSubCategory(
                        indexMiniSubCategory.filter((nilai) => nilai !== value)
                      );
                    }}
                  >
                    <AddIcon
                      sx={{
                        fontSize: '20px',
                        cursor: 'pointer',
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: '600',
                        cursor: 'pointer',
                      }}
                    >
                      Add Mini Sub
                    </Typography>
                  </Box>
                </Grid>
              )}
              {subCategoryAddInputDatas.miniSubCategoryIndex.map(
                (nilai, index) => (
                  <Grid
                    container
                    item
                    xs={12}
                    columns={12}
                    columnSpacing={4}
                    rowSpacing={2}
                    key={index}
                  >
                    <AddMiniSubCategoryInSubCategory
                      {...{
                        setPage,
                        subCategoryAddInputDatas,
                        setSubCategoryAddInputDatas,
                        indexMiniSubCategory,
                        setIndexMiniSubCategory,
                        isSubmitAvailable,
                        setIsSubmitAvailable,
                      }}
                      miniSub={
                        nilai === 1
                          ? miniSub1
                          : nilai === 2
                          ? miniSub2
                          : miniSub3
                      }
                      setMiniSub={
                        nilai === 1
                          ? setMiniSub1
                          : nilai === 2
                          ? setMiniSub2
                          : setMiniSub3
                      }
                      id={nilai}
                    />
                    {subCategoryAddInputDatas.miniSubCategoryIndex[
                      subCategoryAddInputDatas.miniSubCategoryIndex.length - 1
                    ] !== nilai && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </Grid>
                )
              )}
            </Grid>
          </CustomCard>
        </Grid>
      )}
      {/* //* submit */}
      <Box
        sx={{
          width: '100%',
          bgcolor: 'white',
          position: 'fixed',
          bottom: '0px',
          right: '0px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '18px 32px 18px 240px',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
            Publish
          </Typography>
          <AntSwitch
            checked={subCategoryAddInputDatas.publish}
            onChange={(e) => {
              setSubCategoryAddInputDatas({
                ...subCategoryAddInputDatas,
                publish: !subCategoryAddInputDatas.publish,
              });
            }}
          />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <CustomButton
            variant="contained"
            disabled={!isSubmitAvailable}
            sx={{ width: '140px', height: '36px' }}
            onClick={handleAdd}
          >
            <Typography
              sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
            >
              Submit
            </Typography>
          </CustomButton>
        </Box>
      </Box>
    </Grid>
  );
};

export default SubCategoryMenuAddDesktop;
