import { createSlice } from '@reduxjs/toolkit';

export const supplierRoutingState = createSlice({
  name: 'supplierRouting',
  initialState: {
    role: '',
    page: '',
    keyNotif: '',
    textNotif: '',
    hubName: '',
  },
  reducers: {
    changePageSupplier: (state, action) => {
      state.page = action.payload;
    },
    changeSupplierRoutingState: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setNotifSupplier: (state, action) => {
      const { key, text } = action.payload;
      state.keyNotif = key;
      state.textNotif = text;
    },
    setCloseNotifSupplier: (state) => {
      state.keyNotif = '';
      state.textNotif = '';
    },
  },
});

export const {
  changePageSupplier,
  changeSupplierRoutingState,
  setNotifSupplier,
  setCloseNotifSupplier,
} = supplierRoutingState.actions;

export const getAllSupplierRouting = (state) => state.supplierRouting;

export default supplierRoutingState.reducer;
