import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import BackButtonHub from "../../../../../../../components/shared/BackButtonHub";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useEffect, useRef, useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  changeImagePreviewEdit,
  changeIsCreateMap,
  changeIsEdit,
  changeIsLoadingUpdate,
  changeNameEdit,
  changePicChooseEdit,
  changePicPhoneEdit,
  changePicSearchDebounceEdit,
  changePicSearchEdit,
  changeStatus,
  changeVideoPreviewEdit,
  clickIsPreviewImageEdit,
  clickIsVideoPreviewEdit,
  deleteImage,
  deleteTayangan,
  getDetailDataSubHubEdit,
  getIdSubHubEdit,
  getLoadingDetailSubHubEdit,
  getSubHubEditAll,
  inputImageEdit,
  inputTayangan,
  clearData,
  inputSubHubImageEdit,
} from "../../../../../../../store/hub/subHub/subHubEdit";
import CustomAntSwitch from "../../../../../../../components/shared/CustomAntSwitch";
import CustomButton from "../../../../../../../components/shared/CustomButton";
import CustomCard from "../../../../../../../components/shared/CustomCard";
import CustomInput from "../../../../../../../components/shared/CustomInput";
import {
  fetchPic,
  getPicList,
  getPicLoading,
} from "../../../../../../../store/pic/pic";
import debounce from "lodash.debounce";
import {
  addSubHubRacksMultiple,
  deleteSubHubRacksMultiple,
  editStatusSubHub,
  editSubHub,
  editSubHubRacksMultiple,
} from "../../../../../../../api/hubs";
import CustomEditConfirm from "../../../../../../../components/shared/CustomEditConfirm";
import CreateMapSubHubEdit from "./CreateMapSubHubEdit";
import BackButton from "../../../../../../../components/shared/BackButton";
import HubFamily from "../../HubFamily";
import { Map } from "pigeon-maps";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SetupAddressSubHub from "./AddressMap/SetupAddress";
import { inputNotifHub } from "../../../../../../../store/hub/notifHub";

function EditSubHub({ setSubHubSelected, setSubHubDetailSelected }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const idSubHub = useSelector(getIdSubHubEdit);
  const [isEditConfirm, setIsEditConfirm] = useState(false);
  const loading = useSelector(getLoadingDetailSubHubEdit);
  const dataDetailsSubHub = useSelector(getDetailDataSubHubEdit);
  const picList = useSelector(getPicList);
  const picLoading = useSelector(getPicLoading);

  console.log({ dataDetailsSubHub });

  // import store sub hub edit
  const {
    name,
    subsidiaryId,
    idMasterHub,
    image,
    isUploadImage,
    isMaxSizeImage,
    isImagePreview,
    imagePreview: linkImagePreview,
    isLoadingUpdate,
    isCreateMap,
    video,
    isMaxSizeVideo,
    isVideoPreview,
    videoPreview: linkVideoPreview,
    isUploadVideo,
    picChoose,
    picSearch,
    picSearchDebounce,
    picPhone,
    col,
    row,
    entrance,
    status,
    cellSelectedDetails,
    cellSelectedDetailsNew,
    rackDeletes,
    coordinate,
    address,
  } = useSelector(getSubHubEditAll);
  const [isLoadingOnUpdate, setIsLoadingOnUpdate] = useState(false);

  const handleSaveFix = () => {
    setIsLoadingOnUpdate(true);
    const { idKustomer } = picChoose;
    const upadeteHub = {
      subsidiaryId: subsidiaryId,
      name: name,
      idKustomer: idKustomer,
      images: image.length !== 0 ? image.join(",") : "",
      videos: video.length !== 0 ? video.join(",") : "",
      mapColumn: col,
      mapRow: row,
      nohp: picChoose.telpon,
      address: address,
      entrancePosition: entrance.length !== 0 ? entrance.join(",") : "",
    };
    const deleteRackData = {
      hubRackId: rackDeletes,
    };
    const updateRackData = cellSelectedDetails.map((item) => {
      return {
        id: item.id,
        name: item.name,
        description: item.description,
        columnPosition: item.columnPosition,
      };
    });
    const addRackData = cellSelectedDetailsNew.map((item) => {
      return {
        idToko: idMasterHub,
        subHubId: idSubHub,
        miniHubId: null,
        name: item.name,
        images: "",
        videos: "",
        column: item.column,
        row: item.row,
        columnPosition: item.columnPosition,
        description: item.description,
        width: 0.0,
        height: 0.0,
        length: 0.0,
        weight: 0,
      };
    });
    dispatch(changeIsLoadingUpdate(true));

    const updateSubHub = async () => {
      try {
        await editSubHub(idSubHub, upadeteHub);
      } catch (error) {
        console.error(error);
      } finally {
        if (rackDeletes.length !== 0) {
          deleteRack();
        } else {
          updateRack();
        }
      }
    };
    const deleteRack = async () => {
      try {
        await deleteSubHubRacksMultiple(deleteRackData);
      } catch (error) {
        console.error(error);
      } finally {
        updateRack();
      }
    };
    const updateRack = async () => {
      try {
        await editSubHubRacksMultiple(updateRackData);
      } catch (error) {
        console.error(error);
      } finally {
        if (cellSelectedDetailsNew.length !== 0) {
          addRacks();
        } else {
          dispatch(changeIsLoadingUpdate(false));
          setIsEditConfirm(false);
          dispatch(clearData(false));
          dispatch(
            inputNotifHub({
              key: "editsubhub",
              text: `${name} has been success to be edited`,
            })
          );
        }
      }
    };
    const addRacks = async () => {
      try {
        await addSubHubRacksMultiple(addRackData);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(changeIsLoadingUpdate(false));
        setIsEditConfirm(false);
        dispatch(clearData(false));
        dispatch(
          inputNotifHub({
            key: "editsubhub",
            text: `${name} has been success to be edited`,
          })
        );
      }
    };

    updateSubHub();
  };
  // -------------- Status -------------------

  const [statusHub, setStatusHub] = useState(false);
  useEffect(() => {
    if (!loading) {
      if (dataDetailsSubHub.status === "active") {
        return setStatusHub(true);
      } else {
        return setStatusHub(false);
      }
    }
  }, [loading]);

  const picSearchInDebounce = useRef(
    debounce((value) => {
      dispatch(changePicSearchDebounceEdit(value));
    }, 1000)
  ).current;

  useEffect(() => {
    dispatch(fetchPic(picSearchDebounce));
  }, [picSearchDebounce]);

  const handleBackButton = () => {
    dispatch(changeIsEdit(false));
  };

  const handleEditStatus = () => {
    if (statusHub) {
      editStatusSubHub(dataDetailsSubHub.id, { status: "inactive" });
      setStatusHub(false);
    } else {
      editStatusSubHub(dataDetailsSubHub.id, { status: "active" });
      setStatusHub(true);
    }
  };

  const handleSave = () => {
    setIsEditConfirm(!isEditConfirm);
  };

  const [isMapOpen, setIsMapOpen] = useState(false);

  return (
    <>
      <CustomEditConfirm
        open={isEditConfirm}
        onClose={() => setIsEditConfirm(false)}
        handleClickCancel={() => setIsEditConfirm(false)}
        handleClickEdit={handleSaveFix}
        title="Edit Sub Hub"
        body={
          <Box sx={{ textAlign: "center", mb: "40px", mt: "8px" }}>
            <Typography>Are you sure you want to save the changes?</Typography>
            {isLoadingUpdate && (
              <CircularProgress size={16} sx={{ mt: "12px" }} />
            )}
          </Box>
        }
      />

      {/* Edit Data */}
      {!isCreateMap ? (
        <>
          {/* //* Back Button */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <BackButton handleClick={handleBackButton} />
            <Box>
              <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
                Edit Sub Hub
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  color: "#9E9D9D",
                  alignItems: "center",
                  mt: "4px",
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: "100" }}>
                  lulu
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", mt: "16px", gap: 3 }}>
            {/* hub family */}
            <Box sx={{ width: "200px", bgcolor: "#FAFAFA" }}>
              <HubFamily isEditMaster={true} idEditMaster={idSubHub} />
            </Box>

            <Box sx={{ position: "realtive" }}>
              <Grid container columns={12} spacing={4}>
                {/* //* Name */}
                <Grid item xs={12}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{ mb: "8px", fontSize: "16px", fontWeight: "500" }}
                    >
                      Name
                    </Typography>
                    <Typography sx={{ color: "red" }}>*</Typography>
                  </Box>
                  <CustomInput
                    sx={{ width: "100%", bgcolor: "#FAFAFA" }}
                    placeholder="Enter sub hub name"
                    value={name}
                    onChange={(e) => {
                      if (e.target.value.length <= 30) {
                        dispatch(changeNameEdit(e.target.value));
                      }
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#9E9D9D",
                        mt: "4px",
                      }}
                    >
                      Enter at least 5 character
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#9E9D9D",
                        mt: "4px",
                      }}
                    >
                      {name.length}/30
                    </Typography>
                  </Box>
                </Grid>

                {/* //* Photo */}
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      pb: `${image.length === 0 ? "16px" : "9px"}`,
                    }}
                  >
                    <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                      Photo
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#9E9D9D",
                      }}
                    >
                      ( max size : 2MB )
                    </Typography>
                    {isMaxSizeImage && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ErrorOutlineIcon
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: theme.palette.warning.main,
                            mr: "4px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: theme.palette.warning.main,
                          }}
                        >
                          The photo can't be more than 2MB
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {image.length === 0 && (
                        <Box
                          sx={{
                            height: "54px",
                            width: "54px",
                            aspectRatio: "1/1",
                            borderRadius: "8px",
                            bgcolor: "#F5F5F5",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderStyle: "dashed",
                            borderColor: "#9E9D9D",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            document.getElementById("input_img").click();
                          }}
                        >
                          <ImageOutlinedIcon
                            sx={{ color: "#9E9D9D", fontSize: "28px" }}
                          />
                        </Box>
                      )}
                      {image.map((input, index) => (
                        <Box
                          sx={{
                            position: "relative",
                            overflow: "visible",
                            padding: "7px",
                          }}
                          key={index}
                        >
                          <CancelIcon
                            sx={{
                              position: "absolute",
                              fontSize: "20px",
                              top: 1,
                              right: 1,
                              zIndex: +1,
                              cursor: "pointer",
                            }}
                            onClick={() => dispatch(deleteImage(input))}
                          />
                          <Box
                            component="img"
                            src={
                              process.env.REACT_APP_API_IMAGE_BASE_URL +
                              "/" +
                              input
                            }
                            sx={{
                              height: "54px",
                              width: "54px",
                              aspectRatio: "1/1",
                              borderRadius: "8px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              dispatch(clickIsPreviewImageEdit());
                              dispatch(changeImagePreviewEdit(input));
                            }}
                          />
                        </Box>
                      ))}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        mb: "7px",
                        ml: "20px",
                        alignItems: "center",
                      }}
                    >
                      {image.length < 3 && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: theme.palette.primary.main,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            document.getElementById("input_img").click();
                          }}
                        >
                          <input
                            accept="image/*"
                            id="input_img"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => dispatch(inputSubHubImageEdit(e))}
                          />
                          {!isUploadImage ? (
                            <>
                              <AddIcon />
                              <Typography
                                sx={{ fontSize: "17px", fontWeight: "500" }}
                              >
                                Add More
                              </Typography>
                            </>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <CircularProgress size={18} />
                              <Typography> uploading...</Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>

                {/* //* Video */}
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      pb: `${video.length === 0 ? "16px" : "9px"}`,
                    }}
                  >
                    <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                      Video
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#9E9D9D",
                      }}
                    >
                      ( max size : 100MB )
                    </Typography>
                    {isMaxSizeVideo && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ErrorOutlineIcon
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: theme.palette.warning.main,
                            mr: "4px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: "8px",
                            fontWeight: "500",
                            color: theme.palette.warning.main,
                          }}
                        >
                          The video is too big
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {video.length === 0 && (
                        <Box
                          sx={{
                            height: "54px",
                            width: "54px",
                            aspectRatio: "1/1",
                            borderRadius: "8px",
                            bgcolor: "#F5F5F5",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderStyle: "dashed",
                            borderColor: "#9E9D9D",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            document.getElementById("inputMenu_video").click();
                          }}
                        >
                          <VideocamOutlinedIcon
                            sx={{ color: "#9E9D9D", fontSize: "28px" }}
                          />
                        </Box>
                      )}
                      {video.map((input, index) => (
                        <Box
                          sx={{
                            position: "relative",
                            overflow: "visible",
                            padding: "7px",
                          }}
                          key={index}
                        >
                          <CancelIcon
                            sx={{
                              position: "absolute",
                              fontSize: "20px",
                              top: 1,
                              right: 1,
                              zIndex: +1,
                              cursor: "pointer",
                            }}
                            onClick={() => dispatch(deleteTayangan(input))}
                          />
                          <Box
                            onClick={() => {
                              dispatch(clickIsVideoPreviewEdit());
                              dispatch(changeVideoPreviewEdit(input));
                            }}
                          >
                            <video
                              style={{
                                maxHeight: "54px",
                                maxWidth: "54px",
                                aspectRatio: "1/1",
                                borderRadius: "8px",
                                cursor: "pointer",
                                backgroundColor: "black",
                              }}
                            >
                              <source
                                src={
                                  process.env.REACT_APP_API_IMAGE_BASE_URL +
                                  "/" +
                                  input +
                                  "#t=0.5"
                                }
                              />
                            </video>
                          </Box>
                        </Box>
                      ))}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        mb: "7px",
                        ml: "20px",
                        alignItems: "center",
                      }}
                    >
                      {video.length < 3 && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: theme.palette.primary.main,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            document.getElementById("inputMenu_video").click();
                          }}
                        >
                          <input
                            // accept="video/*"
                            id="inputMenu_video"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => dispatch(inputTayangan(e))}
                          />
                          {!isUploadVideo ? (
                            <>
                              <AddIcon />
                              <Typography
                                sx={{ fontSize: "17px", fontWeight: "500" }}
                              >
                                Add More
                              </Typography>
                            </>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <CircularProgress size={18} />
                              <Typography> uploading...</Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
                {/* //* Pic */}
                <Grid item xs={6}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{ mb: "8px", fontSize: "16px", fontWeight: "500" }}
                    >
                      PIC
                    </Typography>
                    <Typography sx={{ color: "red" }}>*</Typography>
                  </Box>
                  <Autocomplete
                    options={picList}
                    getOptionLabel={(option) => option.namaLengkap}
                    value={picChoose}
                    onChange={(_, newValue) => {
                      dispatch(changePicChooseEdit(newValue));
                      dispatch(changePicPhoneEdit(newValue.telpon));
                    }}
                    inputValue={picSearch}
                    loading={picLoading}
                    onInputChange={(_, newInputValue) => {
                      dispatch(changePicSearchEdit(newInputValue));
                      picSearchInDebounce(newInputValue);
                    }}
                    renderOption={(props, option) => (
                      <li key={option.idKustomer} {...props}>
                        {option.namaLengkap}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search "
                        sx={{ width: "100%", bgcolor: "#FAFAFA" }}
                      />
                    )}
                  />
                </Grid>
                {/* //* Pic Number */}
                <Grid item xs={6}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{ mb: "8px", fontSize: "16px", fontWeight: "500" }}
                    >
                      Phone Number
                    </Typography>
                    <Typography sx={{ color: "red" }}>*</Typography>
                  </Box>
                  <CustomInput
                    disabled
                    sx={{ width: "100%", bgcolor: "#FAFAFA" }}
                    placeholder="Enter phone number"
                    type="number"
                    value={picChoose.telpon || "-"}
                  />
                </Grid>
                {/* //* Address */}
                <Grid item xs={6} onClick={() => setIsMapOpen(!isMapOpen)}>
                  <CustomCard
                    sx={{ p: "8px 16px", cursor: "pointer" }}
                    // onClick={() => setIsMapOpen(!isMapOpen)}
                  >
                    <Box
                      sx={{
                        display: "center",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Box
                          sx={{
                            width: "66px",
                            height: "66px",
                            bgcolor: "gray",
                            borderRadius: "8px",
                            position: "relative",
                          }}
                        >
                          <Box
                            sx={{
                              position: "absolute",
                              bgcolor: "white",
                              width: "100%",
                              height: "100%",
                              zIndex: "+2",
                              opacity: 0.5,
                            }}
                          ></Box>
                          <Box
                            sx={{
                              maxHeight: "66px",
                              overflow: "hidden",
                              borderRadius: "8px",
                            }}
                          >
                            <Map
                              defaultCenter={coordinate}
                              height={130}
                              defaultZoom={17}
                              width={66}
                              style={{
                                borderRadius: "8px",
                              }}
                            ></Map>
                          </Box>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: "500", mb: "4px", ml: "8px" }}
                          >
                            Address
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 0.4,
                            }}
                          >
                            <LocationOnIcon color="primary" />
                            <Typography>{address}</Typography>
                          </Box>
                        </Box>
                      </Box>
                      <KeyboardArrowDownIcon />
                    </Box>
                  </CustomCard>
                </Grid>
                {/* //* Dialog Address */}
                <Dialog
                  open={isMapOpen}
                  onClose={() => {
                    setIsMapOpen(!isMapOpen);
                  }}
                >
                  <DialogTitle
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>Input Address</Box>
                    <CloseIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => setIsMapOpen(false)}
                    />
                  </DialogTitle>
                  <DialogContent dividers>
                    <SetupAddressSubHub setIsOpen={setIsMapOpen} />
                  </DialogContent>
                </Dialog>
                {/* //* Rack  */}
                <Grid item xs={6}>
                  <CustomCard
                    sx={{ p: "8px 16px", cursor: "pointer" }}
                    onClick={() => dispatch(changeIsCreateMap())}
                  >
                    <Box
                      sx={{
                        display: "center",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Box
                          sx={{
                            height: "66px",
                            borderRadius: "8px",
                          }}
                        ></Box>
                        <Box sx={{ ml: "8px" }}>
                          <Box sx={{ display: "flex" }}>
                            <Typography sx={{ fontWeight: "500", mb: "4px" }}>
                              Racks Map
                            </Typography>
                            <Typography sx={{ color: "red" }}>*</Typography>
                          </Box>
                          <Typography
                            sx={{ color: "#9E9D9D", fontSize: "12px" }}
                          >
                            Click to create map
                          </Typography>
                        </Box>
                      </Box>
                      <KeyboardArrowDownIcon />
                    </Box>
                  </CustomCard>
                </Grid>

                <Box
                  sx={{
                    width: "100%",
                    bgcolor: "white",
                    position: "fixed",
                    bottom: "0px",
                    right: "0px",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "18px 32px 18px 240px",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                      Publish
                    </Typography>
                    <CustomAntSwitch
                      checked={status}
                      onChange={handleEditStatus}
                    />
                  </Box>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <CustomButton
                      variant="contained"
                      color="error"
                      sx={{ width: "140px", height: "36px" }}
                      // onClick={handleDelete}
                    >
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        Delete
                      </Typography>
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      sx={{ width: "140px", height: "36px" }}
                      onClick={() => handleSave()}
                    >
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        Save
                      </Typography>
                    </CustomButton>
                  </Box>
                </Box>
              </Grid>
            </Box>

            {/* //* Dialog Photo */}
            <Dialog
              open={isImagePreview}
              onClose={() => {
                dispatch(clickIsPreviewImageEdit());
                dispatch(changeImagePreviewEdit(""));
              }}
            >
              <Box
                component="img"
                src={
                  process.env.REACT_APP_API_IMAGE_BASE_URL +
                  "/" +
                  linkImagePreview
                }
                sx={{ width: "600px", height: "600px", aspectRation: "1/1" }}
              />
            </Dialog>
            {/* //* Video Dialoge */}
            <Dialog
              open={isVideoPreview}
              onClose={() => {
                dispatch(clickIsVideoPreviewEdit());
                dispatch(changeVideoPreviewEdit(""));
              }}
            >
              <Box sx={{ bgcolor: "black" }}>
                <video
                  style={{
                    width: "600px",
                    height: "600px",
                    backgroundColor: "black",
                  }}
                  controls
                >
                  <source
                    src={
                      process.env.REACT_APP_API_IMAGE_BASE_URL +
                      "/" +
                      linkVideoPreview
                    }
                  />
                </video>
              </Box>
            </Dialog>
          </Box>
        </>
      ) : (
        <CreateMapSubHubEdit />
      )}
    </>
  );
}
export default EditSubHub;
