import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  MenuItem,
  Checkbox,
  Divider,
  Typography,
  useTheme,
  Box,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as ColumnFilterIcon } from '../../../../../../assets/colum-filter-icon.svg';

import { useEffect } from 'react';
import CustomButton from '../../../../../../components/shared/CustomButton';
import CustomMenu from '../../../../../../components/shared/CustomMenu';
import CustomTable from '../../../../../../components/shared/CustomTable';
import ImgContoh from '../../../../../../assets/cotohImageSementara.jpg';

const CustomTableCells = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '12px',
  textTransform: 'capitalize',
});

const TableProduct = (props) => {
  const theme = useTheme();
  const { handleClickRowTable, handleClickNameRowTable, page } = props;

  const dataInTables = [
    {
      Name: 'category 1',
      Photo: ImgContoh,
      Subsidiary: 'eletronics',
      type: 'Product',
      COGS: 100,
      SKU: 30,
      schedule: '12/12/12',
    },
    {
      Name: 'category 1',
      Photo: ImgContoh,
      Subsidiary: 'eletronics',
      type: 'Product',
      COGS: 100,
      SKU: 30,
      schedule: '12/12/12',
    },
    {
      Name: 'category 1',
      Photo: ImgContoh,
      Subsidiary: 'eletronics',
      type: 'Product',
      COGS: 100,
      SKU: 30,
      schedule: '12/12/12',
    },
    {
      Name: 'category 1',
      Photo: ImgContoh,
      Subsidiary: 'eletronics',
      type: 'Product',
      COGS: 100,
      SKU: 30,
      schedule: '12/12/12',
    },
    {
      Name: 'category 1',
      Photo: ImgContoh,
      Subsidiary: 'eletronics',
      type: 'Product',
      COGS: 100,
      SKU: 30,
      schedule: '12/12/12',
    },
  ];

  const [rowPerPageTable, setRowPerPageTable] = useState(5);
  const rowPerPageValues = [5, 10, 15, 20, 25];
  const [currentPageTable, setCurrentPageTable] = useState(1);
  const [totalTableItems, setTotalTableItems] = useState(112);

  const [openColumnFilter, setOpenColumnFilter] = useState(null);
  const isOpenColumnFilter = Boolean(openColumnFilter);

  const [categoryTableItems, setCategoryTableItems] = useState([
    'Name',
    'Photo',
    'Subsidiary',
    'type',
    'COGS',
    'SKU',
    'Schedule',
  ]);

  const [adjustColumnItem, setAdjustColumnItem] = useState([
    'Name',
    'Photo',
    'Subsidiary',
    'type',
    'COGS',
    'SKU',
  ]);

  const starItems = ['Name', 'Photo', 'Subsidiary'];
  const rpItems = ['COGS', 'SKU', 'RRP'];

  const toolbar = (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'row-reverse' }}>
      <CustomButton
        sx={{
          bgcolor: '#51B15C',
          color: '#ffffff',
          borderRadius: '8px',
          '&:hover': {
            bgcolor: '#51B15C',
            color: '#ffffff',
          },
          fontSize: '0.875rem',
          px: 2,
        }}
        startIcon={<AddIcon sx={{ width: '14px' }} />}
      >
        Add
      </CustomButton>
      <IconButton
        sx={{
          border: '1.5px solid #51B15C',
          borderRadius: '8px',
          p: 1,
        }}
        onClick={(e) => setOpenColumnFilter(e.currentTarget)}
        aria-haspopup="true"
      >
        <ColumnFilterIcon fill="#51B15C" />
      </IconButton>
      <CustomMenu
        open={isOpenColumnFilter}
        openMenu={openColumnFilter}
        setOpenMenu={setOpenColumnFilter}
        sx={{
          '.MuiMenuItem-root': {
            paddingLeft: 0,
            paddingRight: '20px',
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', p: '12px' }}
        >
          <Typography sx={{ fontWeight: '400', fontSize: '12px' }}>
            Max 7
          </Typography>
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '12px',
              color: theme.palette.primary.main,
              cursor: 'pointer',
            }}
            onClick={() =>
              setAdjustColumnItem([
                'Name',
                'Photo',
                'Subsidiary',
                'type',
                'COGS',
                'SKU',
              ])
            }
          >
            Reset
          </Typography>
        </Box>
        <Divider sx={{ my: 0.5 }} />
        {categoryTableItems.map((categoryTableItem, index) => (
          <MenuItem
            onClick={() => {
              if (!starItems.includes(categoryTableItem)) {
                if (adjustColumnItem.includes(categoryTableItem)) {
                  setAdjustColumnItem(
                    adjustColumnItem.filter(
                      (itemc) => itemc !== categoryTableItem
                    )
                  );
                } else {
                  setAdjustColumnItem((prev) => [...prev, categoryTableItem]);
                }
              }
            }}
          >
            <Checkbox
              checked={
                !starItems.includes(categoryTableItem)
                  ? adjustColumnItem.includes(categoryTableItem)
                  : true
              }
            />
            {categoryTableItem}
            {starItems.includes(categoryTableItem) ? '*' : ''}
          </MenuItem>
        ))}
      </CustomMenu>
    </Box>
  );

  return (
    <Box sx={{ p: '20px' }}>
      <CustomTable
        rowPerPage={rowPerPageTable}
        handleRowPerPage={(event) => {
          setRowPerPageTable(event.target.value);
          setCurrentPageTable(1);
        }}
        rowPerPageValues={rowPerPageValues}
        currentPage={currentPageTable}
        totalValueItems={totalTableItems}
        handleInputCurrentPage={(event) => {
          if (
            event.target.value >= Math.ceil(totalTableItems / rowPerPageTable)
          ) {
            setCurrentPageTable(Math.ceil(totalTableItems / rowPerPageTable));
          } else if (event.target.value <= 1) {
            setCurrentPageTable(1);
          } else {
            setCurrentPageTable(event.target.value);
          }
        }}
        handleBackArrow={() =>
          setCurrentPageTable(
            currentPageTable <= 1 ? currentPageTable : currentPageTable - 1
          )
        }
        handleForwardArrow={() =>
          setCurrentPageTable(
            currentPageTable >= Math.ceil(totalTableItems / rowPerPageTable)
              ? currentPageTable
              : currentPageTable + 1
          )
        }
        name="Category"
        toolbar={toolbar}
      >
        <TableHead sx={{ bgcolor: '#FAFAFA' }}>
          <TableRow>
            <CustomTableCells>No</CustomTableCells>
            {categoryTableItems.map((categoryTableItem, index) => (
              <>
                {adjustColumnItem.includes(categoryTableItem) && (
                  <CustomTableCells
                    align={
                      !rpItems.includes(categoryTableItem) ? 'left' : 'right'
                    }
                  >
                    {categoryTableItem}
                    {!rpItems.includes(categoryTableItem) ? '' : '(RP)*'}
                  </CustomTableCells>
                )}
              </>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataInTables.map((dataTable, index) => {
            return (
              <TableRow
                key={index}
                sx={{ cursor: 'pointer' }}
                onClick={handleClickRowTable}
              >
                <CustomTableCells>{index + 1}</CustomTableCells>
                <CustomTableCells>
                  <img
                    src={dataTable.Photo}
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '8px',
                    }}
                  />
                </CustomTableCells>
                <CustomTableCells onClick={handleClickNameRowTable}>
                  {dataTable.Name}
                </CustomTableCells>

                {adjustColumnItem.includes('Subsidiary') && (
                  <CustomTableCells>{dataTable.Subsidiary}</CustomTableCells>
                )}
                {adjustColumnItem.includes('type') && (
                  <CustomTableCells>{dataTable.type}</CustomTableCells>
                )}
                {adjustColumnItem.includes('COGS') && (
                  <CustomTableCells sx={{ textAlign: 'end' }}>
                    {dataTable.COGS}
                  </CustomTableCells>
                )}
                {adjustColumnItem.includes('SKU') && (
                  <CustomTableCells sx={{ textAlign: 'end' }}>
                    {dataTable.SKU}
                  </CustomTableCells>
                )}
                {adjustColumnItem.includes('Schedule') && (
                  <CustomTableCells>{dataTable.schedule}</CustomTableCells>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </CustomTable>
    </Box>
  );
};
export default TableProduct;
