import { createSlice } from '@reduxjs/toolkit';
import { fetchAllTransaction } from '../../api/transaction';

export const tableTransactionState = createSlice({
  name: 'tableTransaction',
  initialState: {
    value: [],
    isLoading: false,
    totalItem: 0,

    // sorting
    sortAsc: true,
    sortIn: 'date',
  },
  reducers: {
    loadTable: (state, action) => {
      state.value = action.payload;
    },
    loadTotalItem: (state, action) => {
      state.totalItem = action.payload;
    },
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    // ! sorting
    changeSortAsc: (state, action) => {
      state.sortAsc = action.payload;
    },
    changeSortIn: (state, action) => {
      state.sortIn = action.payload;
    },
  },
  clearDataTransactionMobile: (state) => {
    state.value = [];
    state.isLoading = false;
    state.totalItem = 0;

    // sorting
    state.sortAsc = true;
    state.sortIn = 'date';
  },
});

export const {
  loadTable,
  loadTotalItem,
  changeIsLoading,
  changeSortAsc,
  changeSortIn,
  clearDataTransactionMobile,
} = tableTransactionState.actions;

export const getTransactionTable = (state) => state.tableTransaction.value;
export const getTransactionTableIsLoading = (state) =>
  state.tableTransaction.isLoading;
export const getTransactionTableTotalItem = (state) =>
  state.tableTransaction.totalItem;
export const getTransactionTableSortIn = (state) =>
  state.tableTransaction.sortIn;
export const getTransactionTableSortAsc = (state) =>
  state.tableTransaction.sortAsc;

// !--------------- API ------------------------

export const getAllDataTransaction = (datas) => (dispatch) => {
  const fetchApi = async () => {
    dispatch(changeIsLoading(true));
    try {
      const {
        data: { data },
      } = await fetchAllTransaction(datas);
      dispatch(loadTable(data.hub));
      dispatch(loadTotalItem(data?.pageInfo?.total));
    } catch {
      dispatch(loadTable([]));
      dispatch(changeIsLoading(false));
    } finally {
      dispatch(changeIsLoading(false));
    }
  };

  fetchApi();
};

export default tableTransactionState.reducer;
