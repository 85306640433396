import { createSlice } from "@reduxjs/toolkit";
import {
  getDetailSubHub,
  getRackSubHub,
  getRackSubHubPreview,
} from "../../../api/hubs";

export const subHubDetailState = createSlice({
  name: "subHubDetail",
  initialState: {
    isLoading: false,
    isEdit: false,
    idSubHub: null,
    detailsData: {},

    racks: [],
    racksPreview: [],
    racksPositionPreview: [],
    racksPositionPreviewAll: [],
    racksPositionTemporary: [],
  },
  reducers: {
    changeIsEditSubHub: (state, action) => {
      state.isEdit = action.payload;
    },
    changeIdSubHub: (state, action) => {
      state.idSubHub = action.payload;
    },
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    loadDetailDataSubHub: (state, action) => {
      state.detailsData = action.payload;
    },
    loadDetailsDataSubHub: (state, action) => {
      state.detailsData = action.payload;
    },
    loadDataRacks: (state, action) => {
      state.racks = action.payload;
    },
    loadDataRacksPositionPreview: (state, action) => {
      state.racksPositionPreview = action.payload;
      state.racksPositionPreviewAll = action.payload.flat();
    },
    loadDataRacksPreview: (state, action) => {
      state.racksPreview = action.payload;
    },
    hoverRacks: (state, action) => {},
  },
});

export const {
  changeIsEditSubHub,
  changeIdSubHub,
  loadDetailDataSubHub,
  loadDetailsDataSubHub,
  changeIsLoading,
  loadDataRacks,
  loadDataRacksPreview,
  loadDataRacksPositionPreview,
} = subHubDetailState.actions;

export const getIsEditSubHub = (state) => state.subHubDetail.isEdit;
export const getIdSubHub = (state) => state.subHubDetail.idSubHub;
export const getLoadingDetailSubHub = (state) => state.subHubDetail.isLoading;
export const getDetailDataSubHub = (state) => state.subHubDetail.detailsData;
export const getDetailsDataSubHub = (state) => state.subHubDetail.detailsData;
export const getLoadingDetailsDataSubHub = (state) =>
  state.subHubDetail.isLoading;

export const getRacksSubHub = (state) => state.subHubDetail.racks;
export const getRacksPositionPreviewSubHub = (state) =>
  state.subHubDetail.racksPositionPreview;
export const getRacksPositionPreviewAllSubHub = (state) =>
  state.subHubDetail.racksPositionPreviewAll;
export const getRacksPreviewSubHub = (state) => state.subHubDetail.racksPreview;

// API get detail sub hub
export const fetchDetailDataSubHub = (id) => (dispatch) => {
  const fetchData = async () => {
    dispatch(changeIsLoading(true));
    try {
      const {
        data: { data },
      } = await getDetailSubHub(id);

      dispatch(loadDetailDataSubHub(data));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoading(false));
    }
  };
  fetchData();
};

export const fetchSubHubRacks = (id) => (dispatch) => {
  const fetchApi = async () => {
    try {
      const {
        data: {
          data: { data },
        },
      } = await getRackSubHub(id);
      dispatch(loadDataRacks(data));
    } catch (err) {
      dispatch(loadDataRacks([]));
      console.error(err);
    } finally {
    }
  };

  fetchApi();
};

export const fetchSubHubRacksPreview = (id) => (dispatch) => {
  const fetchApi = async () => {
    try {
      const {
        data: {
          data: { data },
        },
      } = await getRackSubHubPreview(id);
      let rackPosition = data.map((item) => {
        return item.columnPosition.split(",");
      });
      dispatch(loadDataRacksPositionPreview(rackPosition));
      dispatch(loadDataRacksPreview(data));
    } catch (err) {
      dispatch(loadDataRacksPositionPreview([]));
      dispatch(loadDataRacksPreview([]));
      console.error(err);
    } finally {
    }
  };

  fetchApi();
};
export default subHubDetailState.reducer;
