import { createSlice } from '@reduxjs/toolkit';

export const rackHubEditState = createSlice({
  name: 'rackHubEdit',
  initialState: {
    idToko: null,
    subHubId: null,
    miniHubId: null,
    idRack: null,
    name: '',
    photo: [],
    video: [],
    description: '',
    width: '',
    height: '',
    length: '',
    weight: '',
    cellPosition: [],
  },
  reducers: {
    setEditRack: (state, action) => {
      let {
        idToko,
        subHubId,
        miniHubId,
        idRack,
        name,
        photo,
        video,
        description,
        width,
        height,
        length,
        weight,
        cellPosition,
        row,
        column,
      } = action.payload;
      state.idToko = idToko;
      state.subHubId = subHubId;
      state.miniHubId = miniHubId;
      state.idRack = idRack;
      state.name = name;
      state.photo = photo;
      state.video = video;
      state.description = description;
      state.width = width;
      state.height = height;
      state.length = length;
      state.weight = weight;
      state.cellPosition = cellPosition;
      state.row = row;
      state.column = column;
    },
    changeValue: (state, action) => {
      let { key, value } = action.payload;
      state[key] = value;
    },
  },
});

export const { setEditRack, changeValue } = rackHubEditState.actions;

export const getAllRackHubEdit = (state) => state.rackHubEdit;

export default rackHubEditState.reducer;
