import { Box, CircularProgress } from '@mui/material';

const LoadingComponent = ({ height, ...props }) => {
  return (
    <Box
      sx={[
        {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        },
        height && {
          height: height,
        },
      ]}
    >
      <CircularProgress {...props} />
    </Box>
  );
};
export default LoadingComponent;
