import debounce from 'lodash.debounce';
import {
  FormControl,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState, useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';

const GlobalSearch = () => {
  const [isMenuItemOpen, setIsMenuItemOpen] = useState(false);
  const [isOnFocus, setIsOnFocus] = useState(false);
  const [inputGlobalSearchValue, setInputGlobalSearchValue] = useState('');
  const [resultSearchOfProduct, setResultSearchOfProduct] = useState([]);
  const [resultSearchOfMenu, setResultSearchOfMenu] = useState([]);
  const [resultSearchOfHub, setResultSearchOfHub] = useState([]);
  const [debounceGlobalSearchValue, setDebounceGlobalSearchValue] =
    useState('');

  const [datas, setDatas] = useState({
    products: [
      {
        name: 'product 1',
        id: 1,
      },
      {
        name: 'product 2',
        id: 2,
      },
      {
        name: 'product 3',
        id: 3,
      },
    ],
    menus: [
      {
        name: 'menu 1',
        id: 1,
      },
      {
        name: 'menu 2',
        id: 2,
      },
      {
        name: 'menu 3',
        id: 3,
      },
    ],
    hubs: [
      {
        name: 'hub 1',
        id: 1,
      },
      {
        name: 'hub 2',
        id: 2,
      },
      {
        name: 'hub 3',
        id: 3,
      },
      {
        name: 'hub 1',
        id: 1,
      },
      {
        name: 'hub 2',
        id: 2,
      },
      {
        name: 'hub 3',
        id: 3,
      },
      {
        name: 'hub 1',
        id: 1,
      },
      {
        name: 'hub 2',
        id: 2,
      },
      {
        name: 'hub 3',
        id: 3,
      },
      {
        name: 'hub 1',
        id: 1,
      },
      {
        name: 'hub 2',
        id: 2,
      },
      {
        name: 'hub 3',
        id: 3,
      },
    ],
  });

  const debouncedOnChange = useRef(
    debounce((valueAfterDebounce) => {
      setDebounceGlobalSearchValue(valueAfterDebounce);
    }, 1000)
  ).current;

  useEffect(() => {
    setResultSearchOfProduct(
      datas.products.filter((menu) =>
        menu.name.includes(debounceGlobalSearchValue)
      )
    );
    setResultSearchOfMenu(
      datas.menus.filter((menu) =>
        menu.name.includes(debounceGlobalSearchValue)
      )
    );
    setResultSearchOfHub(
      datas.hubs.filter((menu) => menu.name.includes(debounceGlobalSearchValue))
    );
  }, [debounceGlobalSearchValue, datas]);

  return (
    <Box sx={{ color: '#000000' }}>
      <FormControl fullWidth sx={{ maxWidth: '900px' }}>
        <TextField
          sx={{
            position: 'relative',
            '& .MuiOutlinedInput-input': {
              padding: '9px 6px 9px 10px',
            },
            '& .MuiOutlinedInput-root': {
              paddingLeft: '6px',
              fontSize: '0.875rem',
            },
          }}
          id="outlined-basic"
          label={isOnFocus && 'Search'}
          variant="outlined"
          value={inputGlobalSearchValue}
          onFocus={() => {
            setIsMenuItemOpen(true);
            setIsOnFocus(true);
          }}
          onBlur={() => {
            setIsMenuItemOpen(false);
            setIsOnFocus(false);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ marginRight: '10px' }} />
                {!isOnFocus && inputGlobalSearchValue === '' && (
                  <Typography>Search</Typography>
                )}
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setInputGlobalSearchValue(e.target.value);
            debouncedOnChange(e.target.value);
          }}
        />
        {isOnFocus && debounceGlobalSearchValue.length !== 0 && (
          <Box fullWidth>
            <Box
              sx={{
                position: 'absolute',
                background: 'white',
                zIndex: '+1',
                width: '100%',
                px: '22px',
                display: 'flex',
                flexDirection: 'column',
                boxShadow:
                  '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                borderRadius: '4px',
                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                maxHeight: '450px',
                overflowY: 'auto',
              }}
              fullWidth
            >
              {resultSearchOfProduct.length > 0 && (
                <Box>
                  <Typography
                    sx={{
                      pt: '28px',
                      pb: '10px',
                      fontWeight: '700',
                      fontSize: '20px',
                    }}
                  >
                    Products
                  </Typography>
                  {resultSearchOfProduct.map((product, index) => (
                    <MenuItem sx={{ width: 'full' }} key={index}>
                      {product.name}
                    </MenuItem>
                  ))}
                </Box>
              )}
              {resultSearchOfMenu.length > 0 && (
                <Box>
                  <Typography
                    sx={{
                      pt: '28px',
                      pb: '10px',
                      fontWeight: '700',
                      fontSize: '20px',
                    }}
                  >
                    Menus
                  </Typography>
                  {resultSearchOfMenu.map((menu, index) => (
                    <MenuItem sx={{ width: 'full' }} key={index}>
                      {menu.name}
                    </MenuItem>
                  ))}
                </Box>
              )}
              {resultSearchOfHub.length > 0 && (
                <Box>
                  <Typography
                    sx={{
                      pt: '28px',
                      pb: '10px',
                      fontWeight: '700',
                      fontSize: '20px',
                    }}
                  >
                    Hubs
                  </Typography>
                  {resultSearchOfHub.map((hub, index) => (
                    <MenuItem sx={{ width: 'full' }} key={index}>
                      {hub.name}
                    </MenuItem>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        )}
      </FormControl>
    </Box>
  );
};
export default GlobalSearch;
