import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { getAllStockRouting } from '../../../../../../store/Routing/stockRouting';
import InventoryStock from '..';
import StockAlert from '../StockAlert';

const RoutingStock = () => {
  const { page } = useSelector(getAllStockRouting);
  return (
    <Box>
      {page === '' && <InventoryStock />}
      {page === 'stockAlert' && <StockAlert />}
    </Box>
  );
};
export default RoutingStock;
