import {
  Box,
  Card,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MobileStepper,
  Typography,
  useTheme,
} from "@mui/material";

import BackButtonHub from "../../../../../../../../components/shared/BackButtonHub";
import {
  ChevronRightIcon,
  CloseIcon,
  KeyboardArrowDownIcon,
  RackIcon,
} from "../../../../../../../../assets/Icons";
import { Map, Marker, ZoomControl } from "pigeon-maps";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CustomCard from "../../../../../../../../components/shared/CustomCard";
import CustomAntSwitch from "../../../../../../../../components/shared/CustomAntSwitch";
import CustomButton from "../../../../../../../../components/shared/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { changePageSubHub } from "../../../../../../../../store/Routing/hubs";
import Frame from "../../../../../../../../assets/Frame.png";
import { useEffect, useState } from "react";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";

import {
  changeIsEditSubHub,
  fetchDetailDataSubHub,
  fetchSubHubRacksPreview,
  getDetailDataSubHub,
  getIdSubHub,
  getIsEditSubHub,
  getLoadingDetailSubHub,
  getRacksPositionPreviewSubHub,
  getRacksPreviewSubHub,
} from "../../../../../../../../store/hub/subHub/subHubDetails";
import { editStatusSubHub } from "../../../../../../../../api/hubs";
import CustomDialog from "../../../../../../../../components/shared/CustomDialog";
import RackMapPreviewMobileSubHub from "./RackMapPreviewSubHub";
import { goToSubHubEdit } from "../../../../../../../../store/hub/subHub/subHubEdit";
import { getAllDataHubToSubHub } from "../../../../../../../../store/hub/subHub/subHub";
import EditSubHubMobile from "../Edit/EditSubHub";
import "react-horizontal-scrolling-menu/dist/styles.css";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const DetailSubHub = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const detail = useSelector(getDetailDataSubHub);
  const [activeStep, setActiveStep] = useState(0);
  const { idMasterHub } = useSelector(getAllDataHubToSubHub);
  const isEdit = useSelector(getIsEditSubHub);
  const idSubHub = useSelector(getIdSubHub);
  const loading = useSelector(getLoadingDetailSubHub);
  const [statusHub, setStatusHub] = useState(false);
  const [isPreviewAddressMap, setIsPreviewAddressMap] = useState(false);
  const [addressMapZoom, setAddressMapZoom] = useState(17);
  const [addressMapCenter, setAddressMapCenter] = useState([
    3.5465944896879704, 98.68976198831375,
  ]);
  const [isPreviewRackMap, setIsPreviewRackMap] = useState(false);
  const rackSelected = useSelector(getRacksPositionPreviewSubHub);
  const racksData = useSelector(getRacksPreviewSubHub);

  const [image, setImage] = useState([]);

  useEffect(() => {
    const img = detail?.images?.split(",");
    setImage(img);
  }, [detail]);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const maxSteps = image?.length === undefined ? 1 : image?.length;

  // ! ------------------------- API ----------------------
  useEffect(() => {
    dispatch(fetchDetailDataSubHub(idSubHub));
    dispatch(fetchSubHubRacksPreview(idSubHub));
  }, [dispatch, idSubHub]);

  // status
  useEffect(() => {
    if (!loading) {
      if (detail?.status === "active") {
        return setStatusHub(true);
      } else {
        return setStatusHub(false);
      }
    }
  }, [loading]);

  const handleEditStatus = () => {
    if (statusHub) {
      editStatusSubHub(detail?.id, { status: "pending" });
      setStatusHub(false);
    } else {
      editStatusSubHub(detail?.id, { status: "active" });
      setStatusHub(true);
    }
  };

  const handleToEdit = () => {
    dispatch(
      goToSubHubEdit({
        idToko: idMasterHub,
        name: detail?.name,
        col: detail?.mapColumn,
        row: detail?.mapRow,
        rackDetails: racksData,
        rackSelected: rackSelected,
        pic: detail?.pic,
        picPhone: detail?.pic.telpon,
        status: statusHub,
        entrance: detail?.entrancePosition
          ? detail?.entrancePosition.split(",")
          : [],
        subsidiary: detail?.store?.subsidiary,
        subsidiaryId: detail?.store?.subsidiaryId,
        // images: detail?.images ? detail?.images.split(",") : [],
        // video: detail?.videos ? detail?.videos.split(",") : [],
        image: detail?.images,
        video: detail?.videos,
        address: detail?.alamat,
        coordinate: [Number(detail?.mapCor?.lat), Number(detail?.mapCor?.long)],
        typeMap: detail?.mapCor !== null ? detail?.mapCor.typeInput : "manual",
        citydistrict:
          detail?.mapCor !== null ? detail?.mapCor.cityDistrict : "",
      })
    );
    dispatch(changeIsEditSubHub(true));
  };

  return (
    <Box>
      {isEdit ? (
        <EditSubHubMobile />
      ) : (
        <Box>
          <Grid container columns={12} spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center ",
                  mt: 2,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <BackButtonHub
                    onClick={() => dispatch(changePageSubHub("subHub"))}
                  />
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {detail?.name} Details
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        color: "#9E9D9D",
                      }}
                    >
                      {detail?.store?.subsidiary?.name}{" "}
                      <ChevronRightIcon style={{ fontSize: "12px" }} />
                      {detail?.namaToko}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{ fontSize: "14px", color: "#51B15C", fontWeight: 600 }}
                  >
                    Hub Racks
                  </Typography>
                  <ChevronRightIcon
                    sx={{ fontSize: "18px", color: "#51B15C" }}
                  />
                </Box>
              </Box>
            </Grid>

            {/*  imageslide */}
            <Grid item xs={12}>
              <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {image?.map((step, index) => (
                  <Box key={index}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        component="img"
                        sx={{
                          height: 156,
                          display: "block",
                          overflow: "hidden",
                          width: "100%",
                        }}
                        src={
                          process.env.REACT_APP_API_IMAGE_BASE_URL + "/" + step
                        }
                        alt={step}
                      />
                    ) : null}
                  </Box>
                ))}
              </AutoPlaySwipeableViews>
              <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            </Grid>

            {/* name */}
            <Grid item xs={12}>
              <Box
                sx={{
                  background: "#FAFAFA",
                  borderRadius: "8px 8px 0px 0px",
                  padding: "16px 12px 14px 14px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    marginBottom: "8px",
                    color: "#9E9E9E",
                  }}
                >
                  Name
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 500, color: "#000" }}
                >
                  {detail?.name || "_"}
                </Typography>
              </Box>
            </Grid>

            {/* pic */}
            <Grid item xs={12}>
              <Box
                sx={{
                  background: "#FAFAFA",
                  borderRadius: "8px 8px 0px 0px",
                  padding: "16px 12px 14px 14px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    marginBottom: "8px",
                    color: "#9E9E9E",
                  }}
                >
                  PIC
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 500, color: "#000" }}
                >
                  {detail?.pic ? detail?.pic?.namaLengkap : "-"}
                </Typography>
              </Box>
            </Grid>

            {/* phone number */}
            <Grid item xs={12}>
              <Box
                sx={{
                  background: "#FAFAFA",
                  borderRadius: "8px 8px 0px 0px",
                  padding: "16px 12px 14px 14px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    marginBottom: "8px",
                    color: "#9E9E9E",
                  }}
                >
                  Phone Number
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 500, color: "#000" }}
                >
                  {detail?.pic?.phoneNumber || "-"}
                </Typography>
              </Box>
            </Grid>

            {/* address */}
            <Grid item xs={12}>
              <CustomCard
                sx={{
                  p: "8px 16px",
                  cursor: "pointer",
                }}
                onClick={() => setIsPreviewAddressMap(true)}
              >
                <Box
                  sx={{
                    display: "center",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Box
                      sx={{
                        width: "46px",
                        height: "46px",
                        bgcolor: "gray",
                        borderRadius: "8px",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          bgcolor: "white",
                          width: "100%",
                          height: "100%",
                          zIndex: "+2",
                          opacity: 0.5,
                        }}
                      ></Box>
                      <Box
                        sx={{
                          maxHeight: "46px",
                          overflow: "hidden",
                          borderRadius: "8px",
                        }}
                      >
                        <Map
                          defaultCenter={[
                            Number(detail?.mapCor?.lat || 0),
                            Number(detail?.mapCor?.long || 0),
                          ]}
                          height={130}
                          width={66}
                          defaultZoom={17}
                          style={{
                            borderRadius: "8px",
                          }}
                        ></Map>
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          mb: "4px",
                          ml: "8px",
                          fontSize: "12px",
                          color: "#9E9E9E",
                        }}
                      >
                        Address
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0.4,
                        }}
                      >
                        <LocationOnIcon color="primary" />
                        <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                          {detail?.address ? detail?.address : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <KeyboardArrowDownIcon />
                </Box>
              </CustomCard>
            </Grid>

            {/* Rack */}
            <Grid item xs={12}>
              <CustomCard
                sx={{
                  p: "8px 16px",
                  cursor: "pointer",
                }}
                onClick={() => setIsPreviewRackMap(!isPreviewRackMap)}
              >
                <Box
                  sx={{
                    display: "center",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "52px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <RackIcon />
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          mb: "4px",
                          fontSize: "12px",
                          color: "#9E9E9E",
                        }}
                      >
                        Racks Map
                      </Typography>
                      <Typography sx={{ color: "#000000", fontSize: "14px" }}>
                        {racksData?.length} Racks
                      </Typography>
                    </Box>
                  </Box>
                  <KeyboardArrowDownIcon />
                </Box>
              </CustomCard>
            </Grid>

            {/*publish */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 500, color: "#000" }}
                >
                  Publish
                </Typography>
                <CustomAntSwitch
                  checked={statusHub}
                  onChange={(e) => handleEditStatus(e)}
                />
              </Box>
            </Grid>

            {/* Button */}
            <Grid item xs={12}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                  }}
                >
                  <CustomButton
                    variant="contained"
                    color="error"
                    sx={{
                      width: "50%",
                      height: "36px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      Delete
                    </Typography>
                  </CustomButton>

                  <CustomButton
                    variant="contained"
                    sx={{
                      width: "50%",
                      height: "36px",
                    }}
                    onClick={handleToEdit}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      Edit
                    </Typography>
                  </CustomButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      {/* dialog */}

      {/*  Dialog Addres Map */}
      <Dialog
        // maxWidth="sm"
        open={isPreviewAddressMap}
        onClose={() => setIsPreviewAddressMap(!isPreviewAddressMap)}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>Address</Typography>
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onClick={() => setIsPreviewAddressMap(!isPreviewAddressMap)}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Map
            height={256}
            width={256}
            center={addressMapCenter}
            zoom={addressMapZoom}
            onBoundsChanged={({ center, zoom }) => {
              setAddressMapCenter(center);
              setAddressMapZoom(zoom);
            }}
          >
            <ZoomControl />
            <Marker
              anchor={[
                Number(detail?.mapCor?.lat),
                Number(detail?.mapCor?.long),
              ]}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LocationOnRoundedIcon
                  color="error"
                  sx={{
                    fontSize: `${30 - (20 - Math.floor(addressMapZoom))}px`,
                  }}
                />
                {addressMapZoom >= 17 && (
                  <Typography
                    color="error"
                    sx={{ fontSize: "12px", textAlign: "center" }}
                  >
                    {detail?.name}
                  </Typography>
                )}
              </Box>
            </Marker>
          </Map>
          <Typography sx={{ mt: "12px" }}>{detail?.address}</Typography>
        </DialogContent>
      </Dialog>

      {/*  Dialog Rack Map */}
      <CustomDialog
        fullWidth
        isOpen={isPreviewRackMap}
        setIsOpen={setIsPreviewRackMap}
      >
        <RackMapPreviewMobileSubHub
          col={detail?.mapColumn}
          row={detail?.mapRow}
          entrance={detail?.entrancePosition}
          setIsOpen={setIsPreviewRackMap}
        />
      </CustomDialog>
    </Box>
  );
};

export default DetailSubHub;
