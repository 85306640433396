import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material';
import CustomDialog from '../../../../../../components/shared/CustomDialog';
import dayjs from 'dayjs';
import {
  Close as CloseIcon,
  Clear as ClearIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSubsidiaryList,
  getSubsidiaryList,
} from '../../../../../../store/subsidiary/subsidiary';
import { useTheme } from '@emotion/react';
import { DateRange } from 'react-date-range';
import CustomButton from '../../../../../../components/shared/CustomButton';
import {
  changeEndDateFilterRequest,
  changeStartDateFilterRequest,
  changeSubsidiaryDetailRequest,
} from '../../../../../../store/purchase/purchaseAdmin/purchaseRequest';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const DialogFilterPurchaseRequest = ({ isFilter, setIsFilter }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const subsidiaryList = useSelector(getSubsidiaryList);
  const [subsidiary, setSubsidiary] = useState('');

  // dates
  const [dates, setDates] = useState(false);
  const [open, setOpen] = useState(false);
  const dateRangeRef = useRef(null);
  const handleOpen = () => {
    setOpen(true);
    setDates(true);
  };
  const handleClose = () => setOpen(false);

  // get subsidiary
  useEffect(() => {
    dispatch(fetchSubsidiaryList());
  }, []);

  const handleChange = (event) => {
    setSubsidiary(event.target.value);
  };

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  const handleSaveFilter = () => {
    dispatch(changeSubsidiaryDetailRequest(subsidiary));
    dispatch(
      changeStartDateFilterRequest(
        dayjs(dateRange[0].startDate).format('YYYY-MM-DD')
      )
    );
    dispatch(
      changeEndDateFilterRequest(
        dayjs(dateRange[0].endDate).format('YYYY-MM-DD')
      )
    );
    setIsFilter(false);
  };

  const handleResetFilter = () => {
    dispatch(changeSubsidiaryDetailRequest(''));
    dispatch(changeStartDateFilterRequest(''));
    dispatch(changeEndDateFilterRequest(''));
    setDates('');
    setSubsidiary('');
    setDates(false);
  };
  return (
    <CustomDialog
      isOpen={isFilter}
      setIsOpen={setIsFilter}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
        },
      }}
    >
      <DialogTitle
        sx={{
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px 12px 0px 0px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 600,
              color: '#000000',
            }}
            component="span"
          >
            Filter
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setIsFilter(!isFilter)}
            sx={{
              color: '#323232',
              p: 0,
            }}
          >
            <CloseIcon sx={{ fontSize: '1.25rem' }} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mt: 3,
        }}
      >
        {/* subsidiary */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Typography
            sx={{ fontSize: '16px', fontWeight: 500, color: '#000000' }}
          >
            Subsidiary
          </Typography>
        </Box>

        {/* list subsidiary */}
        <FormControl sx={{ width: '100%' }}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={subsidiary}
            label="subsidiary"
            onChange={handleChange}
          >
            {subsidiaryList.map((item, idx) => (
              <MenuItem key={idx} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* date */}
        <Box
          sx={{
            mt: '12px',
            width: '100%',
            padding: '14px 16px',
            bgcolor: '#FAFAFA',
            borderRadius: '8px',
          }}
          onClick={handleOpen}
        >
          <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
            {dates ? (
              <>
                {dayjs(dateRange[0].startDate).format('YYYY-MM-DD')} -{' '}
                {dayjs(dateRange[0].endDate).format('YYYY-MM-DD')}
              </>
            ) : (
              'select date'
            )}
          </Typography>
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  maxWidth: '444px',
                  width: 1,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Box ref={dateRangeRef}>
                  <DateRange
                    ranges={dateRange}
                    onChange={(item) => {
                      setDateRange([item.selection]);
                    }}
                    rangeColors={[theme.palette.primary.main]}
                  />
                </Box>
              </Box>
            </Box>
            <Button
              variant="contained"
              fullWidth
              onClick={handleClose}
              sx={{ p: '12px 16px' }}
            >
              <Typography sx={{ fontSize: '0.875rem', lineHeight: 1 }}>
                Submit
              </Typography>
            </Button>
          </Box>
        </Modal>

        {/* button event */}
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}
        >
          <CustomButton
            sx={{
              color: '#51B15C',
              fontWeight: 600,
              fontSize: '0.875rem',
              px: 3,
              py: 1,
            }}
            onClick={handleResetFilter}
          >
            Reset
          </CustomButton>
          <CustomButton
            sx={{
              bgcolor: '#51B15C',
              color: '#ffffff',
              fontWeight: 600,
              fontSize: '0.875rem',
              borderRadius: '8px',
              '&:hover': {
                bgcolor: '#51B15C',
                color: '#ffffff',
              },
              px: 4,
              py: 1,
            }}
            onClick={handleSaveFilter}
          >
            Save
          </CustomButton>
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};
export default DialogFilterPurchaseRequest;
