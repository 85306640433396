import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { Draggable, Map, ZoomControl } from 'pigeon-maps';
import CustomButton from '../../../../../../../../../components/shared/CustomButton';
import PinDropIcon from '@mui/icons-material/PinDrop';
import LocationSearchingRoundedIcon from '@mui/icons-material/LocationSearchingRounded';
import { ReactComponent as CameraLocationToMarker } from '../../../../../../../../../assets/location-to-marker.svg';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeAddress,
  changeCenter,
  changeCoordinate,
  changeMapType,
  changePlaceGetByName,
  fetchPlacesSearchByCoordinate,
  fetchPlacesSearchByName,
  getAddres,
  getCenterMasterHubAdd,
  getCoordinateeMasterHubAdd,
  getLaodingPlaceGetByCoorninate,
  getLoadingPlacesSearchByName,
  getMapTypeMasterHubAdd,
  getPlaceGetByCoorninate,
  getPlaceGetByName,
  getPlacesSearchByName,
  submitAddresMap,
} from '../../../../../../../../../store/hub/masterHub/masterHubAdd';
import { useGeolocated } from 'react-geolocated';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import CustomInput from '../../../../../../../../../components/shared/CustomInput';
import { useEffect } from 'react';
import debounce from 'lodash.debounce';

const SetupAddAddressMobile = ({ setIsOpen }) => {
  const dispatch = useDispatch();

  const mapInputType = useSelector(getMapTypeMasterHubAdd);
  const anchor = useSelector(getCoordinateeMasterHubAdd);
  const center = useSelector(getCenterMasterHubAdd);
  const [anchorByMapFix, setAnchorByMapFix] = useState([]);
  const [zoom, setZoom] = useState(17);

  const [currentCoordinateLat, setCurrentCoordinateLat] = useState(0);
  const [currentCoordinateLong, setCurrentCoordinateLong] = useState(0);

  const [isDetailMarker, setIsDetailMarker] = useState(false);

  const [manualSearch, setManualSearch] = useState('');
  const [manualSearchFix, setManualSearchFix] = useState('');

  const placesSearchByName = useSelector(getPlacesSearchByName);
  const isPlacesSerachByNameLoading = useSelector(getLoadingPlacesSearchByName);
  const placeGetByName = useSelector(getPlaceGetByName);

  const placeGetByCoordinate = useSelector(getPlaceGetByCoorninate);
  const isPlaceGetByCoordinateLoading = useSelector(
    getLaodingPlaceGetByCoorninate
  );

  const desAddress = useSelector(getAddres);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
    });

  const debouncedManualSearch = useRef(
    debounce((value) => {
      setManualSearchFix(value);
    }, 1000)
  ).current;

  const debouncedGetByMap = useRef(
    debounce((value) => {
      setAnchorByMapFix(value);
    }, 2000)
  ).current;

  useEffect(() => {
    if (!manualSearchFix) {
      dispatch(fetchPlacesSearchByName('medan'));
    } else {
      dispatch(fetchPlacesSearchByName(manualSearchFix));
    }
  }, [manualSearchFix]);

  useEffect(() => {
    dispatch(
      fetchPlacesSearchByCoordinate({
        lat: anchorByMapFix[0],
        lon: anchorByMapFix[1],
      })
    );
  }, [anchorByMapFix]);

  function handleGetCurrentCoordinate() {
    if (isGeolocationAvailable) {
      setCurrentCoordinateLat(coords.latitude);
      setCurrentCoordinateLong(coords.longitude);
      dispatch(changeCoordinate([coords.latitude, coords.longitude]));
      dispatch(changeCenter([coords.latitude, coords.longitude]));
      if (mapInputType !== 'manual') {
        debouncedGetByMap([coords.latitude, coords.longitude]);
      }
    }
  }

  const handleResetMarker = () => {
    dispatch(changeCoordinate(center));
    if (mapInputType !== 'manual') {
      debouncedGetByMap(center);
    }
  };

  const handleResetZoom = () => {
    dispatch(changeCenter(anchor));
  };

  const handleClickMarker = () => {
    setIsDetailMarker(true);
  };
  const handleEnter = () => {
    dispatch(submitAddresMap({ address: desAddress, coordinate: anchor }));
    setIsOpen(false);
  };

  // !----------- Validasi -----------
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);

  useEffect(() => {
    if (desAddress) {
      if (anchor.length !== 0) {
        return setIsSubmitAvailable(true);
      } else {
        return setIsSubmitAvailable(false);
      }
    } else {
      return setIsSubmitAvailable(false);
    }
  }, [desAddress, anchor]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItem: 'center',
          gap: 1,
          p: '8px',
          borderRadius: '8px',
          bgcolor: '#F5F5F5',
        }}
      >
        <Box
          onClick={() => dispatch(changeMapType('manual'))}
          sx={[
            {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '270px',
              height: '44px',
              borderRadius: '8px',
              cursor: 'pointer',
            },
            mapInputType === 'manual'
              ? {
                  bgcolor: '#FFFFFF',
                }
              : {
                  bgcolor: '#F5F5F5',
                },
          ]}
        >
          <Typography
            sx={[
              mapInputType === 'manual'
                ? {
                    color: '#51B15C',
                  }
                : {
                    color: 'black',
                  },
            ]}
          >
            Manual input
          </Typography>
        </Box>
        <Box
          onClick={() => dispatch(changeMapType('bymap'))}
          sx={[
            {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: '#FFFFFF',
              width: '270px',
              height: '44px',
              borderRadius: '8px',
              cursor: 'pointer',
            },
            mapInputType === 'bymap'
              ? {
                  bgcolor: '#FFFFFF',
                }
              : {
                  bgcolor: '#F5F5F5',
                },
          ]}
        >
          <Typography
            sx={[
              mapInputType === 'bymap'
                ? {
                    color: '#51B15C',
                  }
                : {
                    color: 'black',
                  },
            ]}
          >
            Select by map
          </Typography>
        </Box>
      </Box>

      {/* //* ISI */}
      <Box>
        {mapInputType === 'manual' ? (
          <Box>
            {/* City n distric */}
            <Box sx={{ mt: '12px' }}>
              <Typography>City & District*</Typography>
              <Autocomplete
                value={placeGetByName}
                onChange={(_, newValue) => {
                  dispatch(changePlaceGetByName(newValue));
                }}
                inputValue={manualSearch}
                onInputChange={(_, newInputValue) => {
                  setManualSearch(newInputValue);
                  debouncedManualSearch(newInputValue);
                }}
                options={placesSearchByName}
                loading={isPlacesSerachByNameLoading}
                getOptionLabel={(option) => option.formatted}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select City"
                    sx={{ width: '100%', bgcolor: '#FAFAFA', mt: '12px' }}
                  />
                )}
              />
            </Box>
            {/* Address */}
            <Box sx={{ mt: '12px' }}>
              <Typography>Address*</Typography>
              <CustomInput
                value={desAddress}
                onChange={(e) => {
                  dispatch(changeAddress(e.target.value));
                }}
                placeholder="Enter address"
                sx={{ width: '100%', bgcolor: '#FAFAFA', mt: '12px' }}
              />
            </Box>
            <Typography sx={{ mt: '12px' }}>Pinpoint</Typography>
            {/* MAP */}
            <Box sx={{ position: 'relative', mt: '12px' }}>
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: '+5',
                  bottom: 4,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    mb: '8px',
                    ml: '8px',
                    p: '4px',
                    borderRadius: '8px',
                  }}
                >
                  <Box
                    onClick={handleGetCurrentCoordinate}
                    sx={{
                      width: '24px',
                      height: '24px',
                      bgcolor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      boxShadow: '8px',
                    }}
                  >
                    <LocationSearchingRoundedIcon
                      sx={{
                        fontSize: '20px',
                        color: '#51B15C',
                      }}
                    />
                  </Box>
                  <Box
                    onClick={handleResetZoom}
                    sx={{
                      width: '24px',
                      height: '24px',
                      bgcolor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      boxShadow: '8px',
                    }}
                  >
                    <CameraLocationToMarker
                      style={{ fontSize: '20px', color: '#51B15C' }}
                    />
                  </Box>
                  <Box
                    onClick={handleResetMarker}
                    sx={{
                      width: '24px',
                      height: '24px',
                      bgcolor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      boxShadow: '8px',
                    }}
                  >
                    <PinDropIcon sx={{ fontSize: '20px', color: '#F64444' }} />
                  </Box>
                </Box>
              </Box>
              <Map
                height={300}
                center={center}
                zoom={zoom}
                onBoundsChanged={({ center, zoom }) => {
                  dispatch(changeCenter(center));
                  setZoom(zoom);
                }}
              >
                <ZoomControl />
                {anchor.length !== 0 && (
                  <Draggable
                    anchor={anchor}
                    onDragEnd={(anchor) => dispatch(changeCoordinate(anchor))}
                  >
                    <LocationOnRoundedIcon
                      color="error"
                      sx={{ fontSize: '30px' }}
                    />
                  </Draggable>
                )}
              </Map>
            </Box>
            {/* Submit */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                mt: '24px',
              }}
            >
              <CustomButton
                disabled={!desAddress || !placeGetByName}
                variant="contained"
                onClick={handleEnter}
                sx={{ width: '100%', height: '48px' }}
              >
                Enter
              </CustomButton>
            </Box>
          </Box>
        ) : (
          <Box>
            {/* MAP */}
            <Box sx={{ position: 'relative', mt: '12px' }}>
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: '+5',
                  bottom: 4,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    mb: '8px',
                    ml: '8px',
                    p: '4px',
                    borderRadius: '8px',
                  }}
                >
                  <Box
                    onClick={handleGetCurrentCoordinate}
                    sx={{
                      width: '24px',
                      height: '24px',
                      bgcolor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      boxShadow: '8px',
                    }}
                  >
                    <LocationSearchingRoundedIcon
                      sx={{
                        fontSize: '20px',
                        color: '#51B15C',
                      }}
                    />
                  </Box>
                  <Box
                    onClick={handleResetZoom}
                    sx={{
                      width: '24px',
                      height: '24px',
                      bgcolor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      boxShadow: '8px',
                    }}
                  >
                    <CameraLocationToMarker
                      style={{ fontSize: '20px', color: '#51B15C' }}
                    />
                  </Box>
                  <Box
                    onClick={handleResetMarker}
                    sx={{
                      width: '24px',
                      height: '24px',
                      bgcolor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      boxShadow: '8px',
                    }}
                  >
                    <PinDropIcon sx={{ fontSize: '20px', color: '#F64444' }} />
                  </Box>
                </Box>
              </Box>

              <Map
                height={300}
                center={center}
                zoom={zoom}
                onBoundsChanged={({ center, zoom }) => {
                  dispatch(changeCenter(center));
                  setZoom(zoom);
                }}
              >
                <ZoomControl />
                {anchor.length !== 0 && (
                  <Draggable
                    anchor={anchor}
                    onDragEnd={(anchor) => {
                      dispatch(changeCoordinate(anchor));
                      debouncedGetByMap(anchor);
                    }}
                  >
                    <LocationOnRoundedIcon
                      color="error"
                      sx={{ fontSize: '40px' }}
                    />
                  </Draggable>
                )}
              </Map>
            </Box>
            {/* City & distric */}
            <Box sx={{ mt: '12px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography>City & District*</Typography>
                {anchor !== anchorByMapFix ||
                  (isPlaceGetByCoordinateLoading && (
                    <CircularProgress size={12} />
                  ))}
              </Box>
              <CustomInput
                placeholder="Select city"
                value={
                  placeGetByCoordinate ? placeGetByCoordinate?.formatted : ''
                }
                disabled={true}
                sx={{
                  width: '100%',
                  bgcolor: placeGetByCoordinate?.formatted
                    ? '#FAFAFA'
                    : '#E4E4E4',
                  mt: '12px',
                }}
              />
            </Box>
            {/* Address */}
            <Box sx={{ mt: '12px' }}>
              <Typography>Address*</Typography>
              <CustomInput
                value={desAddress}
                onChange={(e) => dispatch(changeAddress(e.target.value))}
                placeholder="Enter address"
                sx={{ width: '100%', bgcolor: '#FAFAFA', mt: '12px' }}
              />
            </Box>
            {/* Submit */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                mt: '24px',
              }}
            >
              <CustomButton
                disabled={!desAddress || !placeGetByCoordinate}
                variant="contained"
                onClick={handleEnter}
                sx={{ width: '100%', height: '48px' }}
              >
                Enter
              </CustomButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default SetupAddAddressMobile;
