import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomCard from '../components/shared/CustomCard';
import { fetchCategories } from '../api/products';

const InfiniteScrollTest = () => {
  const [hasMore, setHasMore] = useState(true);
  const [product, setProduct] = useState([]);
  const [page, setPage] = useState(2);

  useEffect(() => {
    const fetchProduct = async () => {
      let data = {};
      data.pageSize = 2;

      try {
        const {
          data: {
            data: { categories, pageInfo },
          },
        } = await fetchCategories(data);
        setProduct(categories);
        setHasMore(pageInfo.currentPage < pageInfo.lastPage ? true : false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchProduct();
  }, []);

  const fetchMoreData = async () => {
    let data = {};
    data.page = page;
    data.pageSize = 2;

    const {
      data: {
        data: { categories },
      },
    } = await fetchCategories(data);

    return categories;
  };

  const nextProduct = async () => {
    const newProduct = await fetchMoreData();
    setProduct([...product, ...newProduct]);

    if (newProduct.length === 0 || newProduct.length < 2) {
      setHasMore(false);
    }
    setPage(page + 1);
  };

  return (
    <Box sx={{ bgcolor: '#ffff', p: 2, height: '100vh' }}>
      <CustomCard sx={{ p: 2 }}>
        <InfiniteScroll
          dataLength={product.length}
          next={nextProduct}
          hasMore={hasMore}
          loader={<Typography>Loading...</Typography>}
          height={80}
          endMessage={<Typography sx={{ textAlign: 'center' }}>All</Typography>}
        >
          {product.map((i, index) => (
            <Box
              sx={{
                height: 30,
                border: '1px solid green',
                m: 1,
                p: 1,
                display: 'flex',
                alignItems: 'center',
              }}
              key={index}
            >
              {i.namaKategori}
            </Box>
          ))}
        </InfiniteScroll>
      </CustomCard>
    </Box>
  );
};

export default InfiniteScrollTest;
