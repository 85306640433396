import { Box, Grid, TextField, Typography } from "@mui/material";
import HubFamily from "../../HubFamily";
import { useSelector } from "react-redux";
import { getSubHubName } from "../../../../../../../store/hub/subHub/subHubAdd";
import FormMiniHubAdd from "./FormMiniHubAdd";
import { useEffect, useRef, useState } from "react";
import { getDetailSubHub } from "../../../../../../../api/hubs";
import { getIdToko } from "../../../../../../../store/hub/miniHub/miniHubAdd";
import BackButtonHub from "../../../../../../../components/shared/BackButtonHub";
import { createSearchParams, useSearchParams } from "react-router-dom";
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  GridOn,
} from "@mui/icons-material";

function AddMiniHub({
  setSubHubSelected,
  setIsOpenAddNewMiniHub,
  setIsOpenNewSubHub,
  setMiniSubSelected,
  setIsOpenNewMiniHub,
  setMiniHubSelected,
}) {
  const name = useSelector(getSubHubName);
  // change params
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);
  const subHubIdRef = useRef(searchParams.get("minihub-overview") || null);

  const [miniHubName, setMiniHubName] = useState("");

  const [detailMiniData, setDetailMiniData] = useState([]);
  const idToko = useSelector(getIdToko);
  useEffect(() => {
    const fetchSubHubDetail = async () => {
      try {
        const {
          data: { data },
        } = await getDetailSubHub(idToko);
        setDetailMiniData(data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchSubHubDetail();
  }, []);

  return (
    <Box sx={{ mt: 3 }}>
      {/* back button & title hub  */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <BackButtonHub
          onClick={() => {
            setIsOpenNewSubHub(false);
            setMiniSubSelected(detailMiniData?.id);
            updatedSearchParams.set("minihub-overview", detailMiniData?.id);
            setSearchParams(updatedSearchParams);
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              color: "primary.black",
              fontSize: "1.125rem",
              fontWeight: 600,
              textTransform: "capitalize",
            }}
          >
            Add mini hub
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              color: "secondary.light",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "0.875rem", fontWeight: 600 }}>
              {detailMiniData?.store?.subsidiary?.name || "-"}
            </Typography>
            <ArrowForwardIosIcon
              sx={{ color: "secondary.light", fontSize: "0.75rem" }}
            />
            <Typography sx={{ fontSize: "0.875rem", fontWeight: 600 }}>
              {detailMiniData?.store?.namaToko || "-"}
            </Typography>
            <ArrowForwardIosIcon
              sx={{ color: "secondary.light", fontSize: "0.75rem" }}
            />
            <Typography sx={{ fontSize: "0.875rem", fontWeight: 600 }}>
              {detailMiniData?.name || "-"}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* bagian aside & add */}
      <Box sx={{ mt: 4 }}>
        <Grid container spacing={4}>
          {/* aside */}
          <Grid item xs="auto">
            <Box sx={{ bgcolor: "#FAFAFA", width: "150px", height: "100%" }}>
              <HubFamily isAddMaster={true} nameAddMaster={name} />
            </Box>
          </Grid>

          {/* form add */}
          <Grid item xs>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 2,
                // height: "100vh",
              }}
            >
              <FormMiniHubAdd
                miniHubName={miniHubName}
                setMiniHubName={setMiniHubName}
                setIsOpenAddNewMiniHub={setIsOpenAddNewMiniHub}
                setMiniSubSelected={setMiniSubSelected}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
export default AddMiniHub;
