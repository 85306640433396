import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Switch,
  Dialog,
  styled,
} from '@mui/material';
import BackButton from '../../../../../../components/shared/BackButton';
import CustomButton from '../../../../../../components/shared/CustomButton';
import dayjs from 'dayjs';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ReactComponent as NoImage } from '../../../../../../assets/no-image.svg';
import { ChartDetails } from '../DesktopComponent';
import { deleteCategoryProduct } from '../../../../../../api/products';
import { useEffect, useState } from 'react';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const CategoryProductDetailsMobile = (props) => {
  const {
    handleBackButton,
    roadHirarki,
    isLoadingDataDetails,
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    detailsDatas,
    handleDelete,
    handleSubmit,
    linkPreviewPhoto,
    setLinkPreviewPhoto,
    isPreviewPhoto,
    setIsPreviewPhoto,
    handlePreviewPhoto,
    status,
    handleEditStatus,
  } = props;

  const theme = useTheme();

  return (
    <Grid container columns={12} spacing={2}>
      {/* back button */}
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        <Box>
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
            {roadHirarki.category} Product
          </Typography>
          <Box
            sx={{
              display: 'flex',
              color: '#9E9D9D',

              alignItems: 'center',
              mt: '4px',
              gap: 0.25,
            }}
          >
            <Typography sx={{ fontSize: '8px', fontWeight: '100' }}>
              {roadHirarki.subsidiary}
            </Typography>
            <ChevronRightIcon sx={{ fontSize: '4px' }} />
            <Typography
              sx={{ fontSize: '8px', fontWeight: '100', color: '#9E9D9D' }}
            >
              {roadHirarki.category}
            </Typography>
          </Box>
        </Box>
      </Grid>
      {!isLoadingDataDetails && (
        <>
          {/* --- */}
          <Grid container item xs={12} columns={12}>
            <Grid item xs={12} sx={{ mb: '32px' }}>
              {/* <ChartDetails
                {...{
                  timeFrame,
                  setTimeFrame,
                  dateRange,
                  setDateRange,
                  saveDateRange,
                  setSaveDateRange,
                }}
              /> */}
            </Grid>
            <Grid container item xs={12} columns={12} spacing={4}>
              {/* purchased */}
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                  Purchased
                </Typography>
                <Box
                  sx={{
                    mt: '12px',
                    width: '100%',
                    padding: '14px 16px',
                    bgcolor: '#FAFAFA',
                    borderRadius: '8px',
                  }}
                >
                  <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
                    {detailsDatas.purchased ? detailsDatas.purchased : '-'}
                  </Typography>
                </Box>
              </Grid>
              {/* sold */}
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                  Sold
                </Typography>
                <Box
                  sx={{
                    mt: '12px',
                    width: '100%',
                    padding: '14px 16px',
                    bgcolor: '#FAFAFA',
                    borderRadius: '8px',
                  }}
                >
                  <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
                    {detailsDatas.sold ? detailsDatas.sold : '-'}
                  </Typography>
                </Box>
              </Grid>
              {/* used */}
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                  Used
                </Typography>
                <Box
                  sx={{
                    mt: '12px',
                    width: '100%',
                    padding: '14px 16px',
                    bgcolor: '#FAFAFA',
                    borderRadius: '8px',
                  }}
                >
                  <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
                    {detailsDatas.used ? detailsDatas.used : '-'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* Type */}
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
              Type
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
                {detailsDatas.type ? detailsDatas.type : '-'}
              </Typography>
            </Box>
          </Grid>
          {/* SKU */}
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
              SKU
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
                {detailsDatas.sku ? detailsDatas.sku : '-'}
              </Typography>
            </Box>
          </Grid>
          {/* Photo */}
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
              Photo
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '0',
                display: 'flex',
                gap: 2,
              }}
            >
              {!detailsDatas.gambar ? (
                <Box
                  sx={{
                    width: '42px',
                    height: '42px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: '#FAFAFA',
                    borderRadius: '2px',
                  }}
                >
                  <NoImage />
                  <Typography sx={{ color: '#9E9D9D', fontSize: '6px' }}>
                    No photo
                  </Typography>
                </Box>
              ) : (
                detailsDatas.gambar.split(',').map((value) => (
                  <>
                    <Box
                      component="img"
                      src={
                        process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + value
                      }
                      sx={{
                        width: '42px',
                        height: '42px',
                        aspectRatio: '1/1',
                        borderRadius: '8px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handlePreviewPhoto(value)}
                    />
                  </>
                ))
              )}
            </Box>
          </Grid>
          {/* Dialog Photo */}
          {linkPreviewPhoto !== '' && (
            <Dialog
              open={isPreviewPhoto}
              onClose={() => {
                setIsPreviewPhoto(!isPreviewPhoto);
                setLinkPreviewPhoto('');
              }}
            >
              <Box
                component="img"
                src={
                  process.env.REACT_APP_API_IMAGE_BASE_URL +
                  '/' +
                  linkPreviewPhoto
                }
                sx={{ aspectRation: '1/1' }}
              />
            </Dialog>
          )}
          {/* Description */}
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
              Description
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
                {detailsDatas.des ? detailsDatas.des : '-'}
              </Typography>
            </Box>
          </Grid>
          {/* Schedule */}
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
              Schedule
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
                {detailsDatas.schedule
                  ? dayjs(detailsDatas.schedule).format('DD/MMM/YYYY')
                  : '-'}
              </Typography>
            </Box>
          </Grid>
        </>
      )}
      {/* STATUS */}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
          Publish
        </Typography>
        <AntSwitch checked={status} onChange={handleEditStatus} />
      </Grid>
      {/* SUBMIT */}
      <Grid
        item
        xs={12}
        sx={{ mt: '28px', display: 'flex', alignItems: 'center', gap: 0.5 }}
      >
        <CustomButton
          // disabled={!isSubmitAvailable}
          onClick={handleDelete}
          variant="contained"
          sx={{
            width: '100%',
            bgcolor: theme.palette.error.main,
          }}
        >
          Delete
        </CustomButton>
        <CustomButton
          // disabled={!isSubmitAvailable}
          onClick={handleSubmit}
          variant="contained"
          sx={{
            width: '100%',
          }}
        >
          Edit
        </CustomButton>
      </Grid>
    </Grid>
  );
};
export default CategoryProductDetailsMobile;
