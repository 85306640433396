import { useDispatch, useSelector } from "react-redux";
import {
  changeIsEditSupplier,
  changeIsPreviewAddress,
  changeStatusPublish,
  dataStateDetailSupplier,
} from "../../../../../../store/supplier/detailSupplier";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import BackButtonHub from "../../../../../../components/shared/BackButtonHub";
import {
  changePageSupplier,
  setNotifSupplier,
} from "../../../../../../store/Routing/supplierRouting";
import {
  CloseIcon,
  KeyboardArrowDownIcon,
} from "../../../../../../assets/Icons";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CustomCard from "../../../../../../components/shared/CustomCard";
import { Map, Marker, ZoomControl } from "pigeon-maps";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import { useState } from "react";
import CustomButton from "../../../../../../components/shared/CustomButton";
import { loadDataToEditSupplier } from "../../../../../../store/supplier/editSupplier";
import EditSupplier from "../Edit";
import CustomDeleteConfirm from "../../../../../../components/shared/CustomDeleteConfirm";
import { deleteSupplier, updateSupplier } from "../../../../../../api/Supplier";
import { useEffect } from "react";

const DetailSupplier = () => {
  const { idSupplier, dataDetail, previewAddress, status, isEditSupplier } =
    useSelector(dataStateDetailSupplier);
  const dispatch = useDispatch();
  const [addressMapZoom, setAddressMapZoom] = useState(17);
  const [addressMapCenter, setAddressMapCenter] = useState([
    3.5465944896879704, 98.68976198831375,
  ]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  // ! handler
  const handleToEdit = () => {
    dispatch(changeIsEditSupplier(!isEditSupplier));
    dispatch(
      loadDataToEditSupplier({
        id: idSupplier,
        name: dataDetail?.namaLengkap,
        code: dataDetail?.suppliersKode || "-",
        email: dataDetail?.email,
        telpon: dataDetail?.phoneNumber,
        joinDate: dataDetail?.tglGabung || "-",
        status: dataDetail?.status,
        lat: dataDetail?.lat,
        lon: dataDetail?.lon,
        typeInput: dataDetail?.typeInput,
        cityDistrict: dataDetail?.cityDistrict,
        address: dataDetail?.alamat,
      })
    );
  };

  const handleEditStatus = async () => {
    const params = {
      status: status ? "active" : "inactive",
    };

    try {
      await updateSupplier(idSupplier, params);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    handleEditStatus();
  }, [status]);

  console.log({ status });

  const handleDeleteFix = async () => {
    setLoadingDelete(true);
    try {
      await deleteSupplier(idSupplier);
      dispatch(
        setNotifSupplier({
          key: "deleteSupplier",
          text: "supplier deleted",
        })
      );
      dispatch(changePageSupplier(""));
      dispatch(changeIsEditSupplier(false));
      setLoadingDelete(false);
      setConfirmDelete(!confirmDelete);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingDelete(false);
    }
  };

  return isEditSupplier ? (
    <EditSupplier />
  ) : (
    <Box>
      <Box sx={{ minHeight: "80vh" }}>
        <Grid container columns={12} spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 3 }}>
              <BackButtonHub onClick={() => dispatch(changePageSupplier(""))} />
              <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                {dataDetail?.namaLengkap} Detail
              </Typography>
            </Box>
          </Grid>
          {/* suppllier name */}
          <Grid item xs={6} sx={{ mt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                Supplier Name
              </Typography>
            </Box>
            <Box
              sx={{
                background: "#F5F5F5",
                padding: "8px 16px",
                borderRadius: "8px 8px 0px 0px",
                color: "#000",
                height: "56px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                {dataDetail?.namaLengkap}
              </Typography>
            </Box>
          </Grid>
          {/* company code */}
          <Grid item xs={6} sx={{ mt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                Supplier Code
              </Typography>
            </Box>
            <Box
              sx={{
                background: "#F5F5F5",
                padding: "8px 16px",
                borderRadius: "8px 8px 0px 0px",
                color: "#000",
                height: "56px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                {dataDetail?.suppliersKode || "-"}
              </Typography>
            </Box>
          </Grid>
          {/* email */}
          <Grid item xs={6} sx={{ mt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                Email
              </Typography>
            </Box>
            <Box
              sx={{
                background: "#F5F5F5",
                padding: "8px 16px",
                borderRadius: "8px 8px 0px 0px",
                color: "#000",
                height: "56px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                {dataDetail?.email.slice(0, 3)}***@gmail.com
              </Typography>
            </Box>
          </Grid>
          {/* telpon */}
          <Grid item xs={6} sx={{ mt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                Phone Number
              </Typography>
            </Box>
            <Box
              sx={{
                background: "#F5F5F5",
                padding: "8px 16px",
                borderRadius: "8px 8px 0px 0px",
                color: "#000",
                height: "56px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                {dataDetail?.phoneNumber || "-"}
              </Typography>
            </Box>
          </Grid>
          {/* join date */}
          <Grid item xs={6} sx={{ mt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                Join Date
              </Typography>
            </Box>
            <Box
              sx={{
                background: "#F5F5F5",
                padding: "8px 16px",
                borderRadius: "8px 8px 0px 0px",
                color: "#000",
                height: "56px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                {dataDetail?.tglGabung || "-"}
              </Typography>
            </Box>
          </Grid>
          {/* address */}
          <Grid item xs={6}>
            <CustomCard
              sx={{ p: "14px 8px", cursor: "pointer" }}
              onClick={() => dispatch(changeIsPreviewAddress(!previewAddress))}
            >
              <Box
                sx={{
                  display: "center",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box
                    sx={{
                      width: "54px",
                      height: "54px",
                      bgcolor: "gray",
                      borderRadius: "8px",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        bgcolor: "white",
                        width: "100%",
                        height: "100%",
                        zIndex: "+2",
                        opacity: 0.5,
                      }}
                    ></Box>
                    <Box
                      sx={{
                        maxHeight: "54px",
                        overflow: "hidden",
                        borderRadius: "8px",
                      }}
                    >
                      <Map
                        defaultCenter={[3.546958571415798, 98.6901696840363]}
                        height={130}
                        defaultZoom={17}
                        width={54}
                        style={{
                          borderRadius: "8px",
                        }}
                      ></Map>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                      Address
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.4,
                      }}
                    >
                      <LocationOnIcon
                        color="primary"
                        sx={{ fontSize: "16px" }}
                      />
                      <Typography sx={{ fontSize: "16px", color: "#9E9D9D" }}>
                        {dataDetail?.alamat || "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <KeyboardArrowDownIcon />
              </Box>
            </CustomCard>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "32px 0px 0px",
          alignItems: "center",
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={status}
              onChange={() => dispatch(changeStatusPublish(!status))}
              color="primary"
            />
          }
          label="Publish"
          labelPlacement="start"
        />
        <Box sx={{ display: "flex", gap: 3 }}>
          <CustomButton
            color="error"
            variant="contained"
            onClick={() => setConfirmDelete(!confirmDelete)}
            sx={{ width: "140px", height: "36px", background: "#F33A3A" }}
          >
            <Typography
              sx={{ color: "white", fontSize: "16px", fontWeight: "500" }}
            >
              Delete
            </Typography>
          </CustomButton>

          <CustomButton
            variant="contained"
            onClick={handleToEdit}
            sx={{ width: "140px", height: "36px" }}
          >
            <Typography
              sx={{ color: "white", fontSize: "16px", fontWeight: "500" }}
            >
              Edit
            </Typography>
          </CustomButton>
        </Box>
      </Box>

      {/* //* Dialog Addres Map */}
      <Dialog
        maxWidth="md"
        open={previewAddress}
        onClose={() => dispatch(changeIsPreviewAddress(!previewAddress))}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>Address</Typography>
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onClick={() => dispatch(changeIsPreviewAddress(!previewAddress))}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Map
            height={300}
            width={560}
            center={addressMapCenter}
            zoom={addressMapZoom}
            onBoundsChanged={({ center, zoom }) => {
              setAddressMapCenter(center);
              setAddressMapZoom(zoom);
            }}
          >
            <ZoomControl />
            <Marker
              anchor={[
                // Number(dataDetail?.mapCor?.lat),
                // Number(dataDetail?.mapCor?.long),
                Number(addressMapCenter[0]),
                Number(addressMapCenter[1]),
              ]}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LocationOnRoundedIcon
                  color="error"
                  sx={{
                    fontSize: `${30 - (20 - Math.floor(addressMapZoom))}px`,
                  }}
                />
                {addressMapZoom >= 17 && (
                  <Typography
                    color="error"
                    sx={{ fontSize: "12px", textAlign: "center" }}
                  >
                    {dataDetail.name}
                  </Typography>
                )}
              </Box>
            </Marker>
          </Map>
          <Typography sx={{ mt: "12px" }}>
            {dataDetail?.alamat || "-"}
          </Typography>
        </DialogContent>
      </Dialog>

      {/* dialog delete */}
      <CustomDeleteConfirm
        isLoading={loadingDelete}
        open={confirmDelete}
        onClose={() => setConfirmDelete(!confirmDelete)}
        handleClickCancel={() => setConfirmDelete(!confirmDelete)}
        handleClickDelete={handleDeleteFix}
        title="Delete Supplier"
        body={
          <Box sx={{ textAlign: "center" }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: 600 }}>
                {dataDetail?.namaLengkap}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: "52px" }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />
    </Box>
  );
};
export default DetailSupplier;
