import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeErrorEmptyMiniHub,
  deleteCellFix,
  editCellFix,
  getCellSelectedMomentCreateMap,
  getIsCellSelectedEditCreateMap,
  hoverCellFix,
  unHoverCellFix,
} from '../../../../../../../../store/hub/miniHub/miniHubAdd';
import CustomCard from '../../../../../../../../components/shared/CustomCard';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { CloseIcon, DeleteIcon } from '../../../../../../../../assets/Icons';
import BottomMenu from '../../../../../../../../components/shared/BottomMenu';

const ListRackMiniHub = (props) => {
  const { name, index } = props;
  const dispatch = useDispatch();
  const isCellEdit = useSelector(getIsCellSelectedEditCreateMap);
  const cellSelectedMoment = useSelector(getCellSelectedMomentCreateMap);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleEdit = () => {
    dispatch(unHoverCellFix());
    dispatch(editCellFix(index));
  };

  const handleDelete = () => {
    setConfirmDelete(true);
  };

  const handleDeleteFix = () => {
    dispatch(unHoverCellFix());
    dispatch(deleteCellFix(index));
  };

  const emptyEdit = () => {
    dispatch(changeErrorEmptyMiniHub('another tab is open'));
  };
  return (
    <>
      <CustomCard
        sx={{ p: '16px 12px', mb: '8px', bgcolor: 'white' }}
        onMouseOver={() => dispatch(hoverCellFix(index))}
        onMouseOut={() => dispatch(unHoverCellFix())}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography sx={{ fontSize: '12px' }}>{name}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ModeEditRoundedIcon
              onClick={
                isCellEdit || cellSelectedMoment.length !== 0
                  ? emptyEdit
                  : handleEdit
              }
              sx={{
                color:
                  isCellEdit || cellSelectedMoment.length !== 0
                    ? '#9E9D9D'
                    : '#FD7900',
                fontSize: '16px',
                cursor: 'pointer',
              }}
            />
            <DeleteRoundedIcon
              onClick={handleDelete}
              sx={{ color: '#F33A3A', fontSize: '16px', cursor: 'pointer' }}
            />
          </Box>
        </Box>
      </CustomCard>

      {/* Dialog */}
      {/* delete */}
      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(!confirmDelete)}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Box
              sx={{
                background: 'rgba(243, 58, 58, 0.1)',
                width: '32px',
                height: '32px',
                borderRadius: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <DeleteIcon sx={{ color: '#F33A3A', fontSize: '16px' }} />
            </Box>
            <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
              Delete
            </Typography>
          </Box>
          <CloseIcon
            onClick={() => setConfirmDelete(!confirmDelete)}
            sx={{ cursor: 'pointer', fontSize: '16px' }}
          />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ fontSize: '12px', fontWeight: 400, mb: '4px' }}>
              Are you sure you want to delete rack ?
            </Typography>
          </Box>
          <Typography
            sx={{ fontSize: '12px', fontWeight: 400, textAlign: 'center' }}
          >
            you can't undo this action.
          </Typography>

          <Box
            sx={{
              mt: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Typography
              sx={{
                color: '#9E9D9D',
                fontSize: '12px',
                fontWeight: 600,
                cursor: 'pointer',
              }}
              onClick={() => setConfirmDelete(!confirmDelete)}
            >
              Cancel
            </Typography>
            <BottomMenu
              isRed={true}
              redText="Delete"
              handleRed={handleDeleteFix}
              sx={{
                position: 'relative',
                padding: 0,
                margin: 0,
                width: '100%',
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ListRackMiniHub;
