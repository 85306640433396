import {
  Grid,
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  styled,
  TableBody,
  Dialog,
  OutlinedInput,
  IconButton,
  useTheme,
} from '@mui/material';
import CustomButton from '../../../../../../components/shared/CustomButton';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllRackhub } from '../../../../../../store/racks/rackHub';
import { getRackDetail, getRackPositionList } from '../../../../../../api/rack';
import { LoadingComponent } from '../../../../../../components/shared/shared2';
import {
  ArrowBackIosIcon,
  ArrowForwardIosIcon,
  ArrowRightAltIcon,
  CircleIcon,
  LocalPrintshopRoundedIcon,
  QrCodeIcon,
  SyncAltIcon,
} from '../../../../../../assets/Icons';
import QRCode from 'react-qr-code';
import useWindowDimensions from '../../../../../../hooks/useWindowDimension';
import { GetExcelNumberFormat } from '../../../../../../hooks/getExcelNumber';
import PerformanceRackItemQr from './PerformanceRackItemQr';
import { LocalPrintshopRounded } from '@mui/icons-material';
import ReactToPrint from 'react-to-print';
import ComponentToPrint from './ComponentToPrint';

const CustomTableCells = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '0.75rem',
  textTransform: 'capitalize',
});

const RackHubItemQr = () => {
  const theme = useTheme();
  const { height: heightScreen } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [datas, setDatas] = useState([]);
  const [datasForPrint, setDatasForPrint] = useState([]);

  const [idCellRack, setIdCellRack] = useState(0);

  const { idRack: id } = useSelector(getAllRackhub);

  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [sortBy, setSortBy] = useState('position');
  const [sortByTempo, setSortByTempo] = useState('position');
  const [isAsc, setIsAsc] = useState(true);

  useEffect(() => {
    let collect = (heightScreen - 380) / 63;
    setPageSize(Math.floor(collect));
  }, [heightScreen]);

  useEffect(() => {
    if (!heightScreen) {
      return setIsLoading(true);
    } else {
      return setIsLoading(false);
    }
  }, [heightScreen]);

  const fetchApi = async (id, page, pageSize, sortBy, isAsc) => {
    setIsLoading(true);
    const collect = {
      id,
      page: page,
      pageSize: pageSize,
    };
    collect[sortBy] = isAsc ? 'asc' : 'desc';
    try {
      const {
        data: {
          data: { hubRackPosition, pageInfo },
        },
      } = await getRackPositionList(collect);
      setDatas(hubRackPosition);
      setTotalData(pageInfo.total);
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchApiForPrint = async (id, totalData) => {
    const collect = {
      id,
      page: 1,
      pageSize: totalData,
    };
    try {
      const {
        data: {
          data: { hubRackPosition },
        },
      } = await getRackPositionList(collect);
      setDatasForPrint(hubRackPosition);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    if (totalData) {
      fetchApiForPrint(id, totalData);
    }
  }, [totalData, id]);

  useEffect(() => {
    fetchApi(id, page, pageSize, sortBy, isAsc);
  }, [id, page, pageSize, sortBy, isAsc]);

  const handlePreviewQr = (id) => {
    setIdCellRack(id);
  };

  const handleClosePreviewQr = () => {
    setIdCellRack(0);
  };

  const handleBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const handleNext = () => {
    if (page < Math.ceil(totalData / pageSize)) {
      setPage(page + 1);
    }
  };

  const handleInput = (e) => {
    let search = Number(e.target.value);
    if (search > 1 && search < Math.ceil(totalData / pageSize)) {
      setPage(search);
    }
  };

  const handleSortBased = (value) => {
    if (value === sortByTempo) {
      setIsAsc(!isAsc);
    } else {
      setSortBy(value);
      setSortByTempo(value);
      setIsAsc(true);
    }
  };

  const color = {
    contained: '#EACD57',
    empty: '#848589',
  };

  const componentRef = useRef();

  return (
    <>
      {isLoading && <LoadingComponent />}
      {!isLoading && !isError && (
        <Grid container columns={2} columnSpacing={3}>
          <Grid item xs={1}>
            <PerformanceRackItemQr />
          </Grid>
          <Grid item xs={1}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: '8px',
              }}
            >
              <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>
                Qr Code List
              </Typography>
              <ReactToPrint
                trigger={() => (
                  <CustomButton
                    variant="contained"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 0.1,
                    }}
                  >
                    <LocalPrintshopRoundedIcon sx={{ color: 'whtie' }} />
                    <Typography sx={{ color: 'whtie' }}>Print</Typography>
                  </CustomButton>
                )}
                content={() => componentRef.current}
              />
            </Box>
            <Box>
              <Box sx={{ display: 'none' }}>
                <ComponentToPrint ref={componentRef} data={datasForPrint} />
              </Box>
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: '#FAFAFA' }}>
                    <CustomTableCells>No</CustomTableCells>
                    <CustomTableCells>
                      <Box
                        sx={[
                          {
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            gap: 0.2,
                            cursor: 'pointer',
                          },
                        ]}
                        onClick={() => handleSortBased('position')}
                      >
                        Position
                        {sortBy !== 'position' && (
                          <SyncAltIcon
                            sx={{
                              fontSize: '12px',
                              transform: 'rotate(90deg)',
                              color: '#9E9D9D',
                            }}
                          />
                        )}
                        {sortBy === 'position' && isAsc && (
                          <ArrowRightAltIcon
                            sx={{
                              fontSize: '12px',
                              transform: 'rotate(90deg)',
                              color: theme.palette.primary.main,
                            }}
                          />
                        )}
                        {sortBy === 'position' && !isAsc && (
                          <ArrowRightAltIcon
                            sx={{
                              fontSize: '12px',
                              transform: 'rotate(270deg)',
                              color: theme.palette.primary.main,
                            }}
                          />
                        )}
                      </Box>
                    </CustomTableCells>
                    <CustomTableCells>
                      <Box
                        sx={[
                          {
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            gap: 0.2,
                            cursor: 'pointer',
                          },
                        ]}
                        onClick={() => handleSortBased('status')}
                      >
                        Status
                        {sortBy !== 'status' && (
                          <SyncAltIcon
                            sx={{
                              fontSize: '12px',
                              transform: 'rotate(90deg)',
                              color: '#9E9D9D',
                            }}
                          />
                        )}
                        {sortBy === 'status' && isAsc && (
                          <ArrowRightAltIcon
                            sx={{
                              fontSize: '12px',
                              transform: 'rotate(90deg)',
                              color: theme.palette.primary.main,
                            }}
                          />
                        )}
                        {sortBy === 'status' && !isAsc && (
                          <ArrowRightAltIcon
                            sx={{
                              fontSize: '12px',
                              transform: 'rotate(270deg)',
                              color: theme.palette.primary.main,
                            }}
                          />
                        )}
                      </Box>
                    </CustomTableCells>
                    <CustomTableCells></CustomTableCells>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {datas.map((list, index) => (
                    <TableRow key={index}>
                      <CustomTableCells>
                        {index + 1 + (page - 1) * pageSize}
                      </CustomTableCells>
                      <CustomTableCells>
                        {GetExcelNumberFormat(Number(list.posisiX) - 1)}
                        {list.posisiY}
                      </CustomTableCells>
                      <CustomTableCells>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <CircleIcon
                            sx={{
                              fontSize: '8px',
                              color: list.status
                                ? color.contained
                                : color.empty,
                            }}
                          />
                          <Typography
                            sx={{
                              fontWeight: '600',
                              color: list.status
                                ? color.contained
                                : color.empty,
                              fontSize: '14px',
                            }}
                          >
                            {list.status ? 'Contained' : 'Empty'}
                          </Typography>
                        </Box>
                      </CustomTableCells>
                      <CustomTableCells>
                        <Box
                          sx={{
                            bgcolor: '#51B15C1A',
                            width: '30px',
                            height: '30px',
                            borderRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={() => handlePreviewQr(list.qrCode)}
                        >
                          <QrCodeIcon />
                        </Box>
                      </CustomTableCells>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* //! -------------------------- PAGINATION ------------------------------ */}
              <Grid
                container
                direction="row"
                coloumn={12}
                alignItems="center"
                sx={{ mt: '30px' }}
              >
                <Grid item xs={6} sx={{ fontSize: '14px', fontWeight: '500' }}>
                  Showing {page * pageSize - (pageSize - 1)} to{' '}
                  {page * pageSize >= totalData ? totalData : page * pageSize}{' '}
                  of {totalData} entries
                </Grid>
                <Grid
                  container
                  item
                  direction="row"
                  xs={6}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item sx={{ ml: '35px' }}>
                    <Box sx={{ display: 'inline', mr: '10px' }}>
                      <IconButton sx={{ p: 0 }} onClick={handleBack}>
                        <ArrowBackIosIcon
                          sx={{ color: '#323232', width: '16px' }}
                        />
                      </IconButton>
                    </Box>
                    <Box sx={{ display: 'inline', mr: '20px' }}>
                      <OutlinedInput
                        variant="outlined"
                        type="number"
                        value={page}
                        onChange={handleInput}
                        sx={{
                          border: '1px solid #9E9D9D',
                          width: '36px',
                          borderRadius: '5px',
                          textAlign: 'center',
                          '& .MuiOutlinedInput-input': {
                            padding: '5px 8px 5px 8px',
                            fontSize: '0.875rem',
                            boxShadow: 'none',
                            textAlign: 'center',
                            fontWeight: 600,
                            width: '25px',
                          },
                          '&:before': {
                            borderRadius: '5px',
                            borderBottom: 0,
                          },
                          '&:after': {
                            borderRadius: '5px',
                            borderBottom: 0,
                          },
                          '&.Mui-focused': {
                            '& .MuiOutlinedInput-notchedOutline': {
                              boxShadow: 'none',
                            },
                          },
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'inline',
                        fontWeight: '600',
                        fontSize: '14px',
                        color: '#9E9D9D',
                      }}
                    >
                      of {Math.ceil(totalData / pageSize)}
                    </Box>
                    <Box sx={{ display: 'inline', ml: '10px' }}>
                      <IconButton sx={{ p: 0 }} onClick={handleNext}>
                        <ArrowForwardIosIcon
                          sx={{ color: '#323232', width: '16px' }}
                        />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      <Dialog open={idCellRack !== 0} onClose={handleClosePreviewQr}>
        <Box sx={{ p: '20px' }}>
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={`${idCellRack}`}
            viewBox={`0 0 256 256`}
          />
        </Box>
      </Dialog>
    </>
  );
};
export default RackHubItemQr;
