import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import CustomTable from "../../../../../../../components/shared/CustomTable";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useEffect } from "react";
import CustomButton from "../../../../../../../components/shared/CustomButton";
import CustomCard from "../../../../../../../components/shared/CustomCard";
import { styled } from "@mui/material/styles";

import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as ColumnFilterIcon } from "../../../../../../../assets/colum-filter-icon.svg";
import { ReactComponent as CloseFilledIcon } from "../../../../../../../assets/closeFilledIcon.svg";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CustomMenu from "../../../../../../../components/shared/CustomMenu";
import CustomDialog from "../../../../../../../components/shared/CustomDialog";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import { TextFields } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  ExitToApp as ExitToAppIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from "@mui/icons-material";
import dayjs from "dayjs";
import ExportXlsExcel from "../../../../../../../components/ExportXlsExcel";

import { ReactComponent as NoImage } from "../../../../../../../assets/no-image.svg";
import { CSVLink } from "react-csv";
import { fetchProducts } from "../../../../../../../api/products";

const CustomTableCells = styled(TableCell)({
  color: "#000000",
  fontWeight: 500,
  fontSize: "0.75rem",
  textTransform: "capitalize",
});

const TableProductProduct = (props) => {
  const {
    page,
    setPage,
    roadHirarki,
    setRoadHirarki,
    isProductProductAdd,
    setIsProductProductAdd,
    isProductProductEdit,
    setIsProductProductEdit,
    setIsProductProductDetails,
    setIsMiniSubProductDetails,
    setIsMiniSubProductAdd,
    isLoadingData,
    // Table
    tableRowPerPageValues,
    tableRowPerPage,
    setTableRowPerPage,
    tableCurrentPage,
    setTableCurrentPage,
    tableTotalItems,
    setTableTotalItems,
    tableSearch,
    setTableSearch,
    sortTable,
    setSortTable,
    isSortAsc,
    setIsSortAsc,
    tableDatas,
    setTableDatas,
    // *------
    handleClickMini,
    handleClickRow,
    handleClickName,
    // *---FILTER--
    allDataCategory,
    allDataSubCategory,
    allDataMiniSubCategory,
    categoryFilterData,
    setCategoryFilterData,
    subCategoryFilterData,
    setSubCategoryFilterData,
    miniCategoryFilterData,
    setMiniCategoryFilterData,
    filterDataAwait,
    setFilterDataAwait,
    categoryFilterChoose,
    setCategoryFilterChoose,
    subCategoryFilterChoose,
    setSubCategoryFilterChoose,
    miniCategoryFilterChoose,
    setMiniCategoryFilterChoose,
    categoryFilterLoading,
    setCategoryFilterLoading,
    subCategoryFilterLoading,
    setSubCategoryFilterLoading,
    miniCategoryFilterLoading,
    setMiniCategoryFilterLoading,
    autoCompleteFilter,
    setAutoCompleteFilter,
    handleClickSaveFilter,
    handleCliskResetFilter,
    isOpenTableFilter,
    setIsOpenTableFilter,
  } = props;
  const theme = useTheme();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [dataTypeSelected, setDataTypeSelected] = useState([
    "category",
    "mini",
    "cogs",
    "rrp",
    "limit",
  ]);

  const [beforeSortTable, setBeforeSortTable] = useState(sortTable);

  useEffect(() => {
    if (sortTable !== beforeSortTable) {
      setIsSortAsc(true);
      setBeforeSortTable(sortTable);
    }
  }, [sortTable, beforeSortTable, setIsSortAsc]);

  const [openColumnFilter, setOpenColumnFilter] = useState(null);
  const isOpenColumnFilter = Boolean(openColumnFilter);

  const [openExport, setOpenExport] = useState(null);
  const isOpenExport = Boolean(openExport);

  // !----------------------- API FOR EXPORT  --------------------------
  const [dataForExports, setDataForExports] = useState([]);

  useEffect(() => {
    const getAllProducts = async () => {
      let params = {};
      params.pageSize = tableTotalItems;
      params.page = 1;

      try {
        const {
          data: { data: dataFetchProducts },
        } = await fetchProducts(params);

        const resultFix = dataFetchProducts.products.map(
          (
            {
              idProduk,
              namaProduk,
              subCategory,
              miniCategory,
              hargaMenual,
              hargaJual,
              warna,
              stokMin,
            },
            index
          ) => ({
            number: index + 1,
            name: namaProduk || "-",
            category:
              miniCategory !== null
                ? miniCategory?.subCategory?.category?.namaKategori
                : subCategory !== null
                ? subCategory?.category?.namaKategori
                : "-",
            miniCategory: miniCategory !== null ? miniCategory.name : "-",
            cogs: hargaMenual ? parseInt(hargaMenual) : "-",
            rrp: hargaJual ? parseInt(hargaJual) : "-",
            limit: stokMin || "-",
            color: warna !== "" ? warna : "-",
          })
        );

        setDataForExports(resultFix);
      } catch {
        setDataForExports([]);
      } finally {
      }
    };
    if (tableDatas.length) {
      if (openExport) {
        if (dataForExports.length === 0) {
          getAllProducts();
        }
      }
    }
  }, [tableDatas, isOpenExport, tableTotalItems, sortTable, dataForExports]);

  // *! --------------------- HANDLE EXPORT PDF -----------------------
  const handleExportPdf = () => {
    const doc = new jsPDF();
    doc.text("Table Product", 20, 10);
    doc.autoTable({
      theme: "grid",
      columns: [
        { title: "No", dataKey: "number" },
        { title: "Nama", dataKey: "name" },
        { title: "Category", dataKey: "category" },
        { title: "Mini-Category", dataKey: "miniCategory" },
        { title: "COGS", dataKey: "cogs" },
        { title: "RRP", dataKey: "rrp" },
        { title: "Limit", dataKey: "limit" },
        { title: "Color", dataKey: "color" },
      ],
      body: dataForExports,
    });
    doc.save("product-table.pdf");
    setOpenExport(null);
  };

  const handleExportExcel = () => {
    setOpenExport(null);
  };

  // !----------------------- Toolbar ----------------------
  const toolbar = (
    <Box sx={{ display: "flex", gap: 2, flexDirection: "row-reverse" }}>
      {/* add */}
      <CustomButton
        sx={{
          bgcolor: "#51B15C",
          color: "#ffffff",
          borderRadius: "8px",
          "&:hover": {
            bgcolor: "#51B15C",
            color: "#ffffff",
          },
          fontSize: "0.875rem",
          px: 2,
        }}
        startIcon={<AddIcon sx={{ width: "14px" }} />}
        onClick={() => {
          setPage("product");
          setIsProductProductAdd(true);
          setRoadHirarki(roadHirarki);
        }}
      >
        Add Product
      </CustomButton>
      {/* add Mini */}
      {roadHirarki.subCategoryId !== 0 && (
        <CustomButton
          sx={{
            bgcolor: "#51B15C",
            color: "#ffffff",
            borderRadius: "8px",
            "&:hover": {
              bgcolor: "#51B15C",
              color: "#ffffff",
            },
            fontSize: "0.875rem",
            px: 2,
          }}
          startIcon={<AddIcon sx={{ width: "14px" }} />}
          onClick={() => {
            setPage("product");
            setIsMiniSubProductAdd(true);
          }}
        >
          Add Mini-Sub
        </CustomButton>
      )}
      <IconButton
        sx={{
          border: "1.5px solid #51B15C",
          borderRadius: "8px",
          p: 1,
        }}
        onClick={(e) => setOpenColumnFilter(e.currentTarget)}
        aria-haspopup="true"
      >
        <ColumnFilterIcon fill="#51B15C" />
      </IconButton>
      <CustomMenu
        open={isOpenColumnFilter}
        openMenu={openColumnFilter}
        setOpenMenu={setOpenColumnFilter}
        sx={{
          ".MuiMenuItem-root": {
            paddingLeft: 0,
            paddingRight: "20px",
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", p: "12px" }}
        >
          <Typography sx={{ fontWeight: "400", fontSize: "12px" }}>
            Max 7
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "12px",
              color: theme.palette.primary.main,
              cursor: "pointer",
            }}
            onClick={() =>
              setDataTypeSelected(["category", "mini", "cogs", "rrp", "limit"])
            }
          >
            Reset
          </Typography>
        </Box>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem>
          <Checkbox checked={true} />
          Name*
        </MenuItem>
        <MenuItem>
          <Checkbox checked={true} />
          Photo*
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes("category")) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== "category")
              );
            } else {
              if (dataTypeSelected.length < 5) {
                setDataTypeSelected((prev) => [...prev, "category"]);
              }
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes("category")} />
          Category
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes("mini")) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== "mini")
              );
            } else {
              if (dataTypeSelected.length < 5) {
                setDataTypeSelected((prev) => [...prev, "mini"]);
              }
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes("mini")} />
          Mini
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes("cogs")) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== "cogs")
              );
            } else {
              if (dataTypeSelected.length < 5) {
                setDataTypeSelected((prev) => [...prev, "cogs"]);
              }
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes("cogs")} />
          COGS
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes("rrp")) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== "rrp")
              );
            } else {
              if (dataTypeSelected.length < 5) {
                setDataTypeSelected((prev) => [...prev, "rrp"]);
              }
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes("rrp")} />
          RRP
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes("limit")) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== "limit")
              );
            } else {
              if (dataTypeSelected.length < 5) {
                setDataTypeSelected((prev) => [...prev, "limit"]);
              }
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes("limit")} />
          Limit
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes('unit')) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== 'unit')
              );
            } else {
              if (dataTypeSelected.length < 5) {
                setDataTypeSelected((prev) => [...prev, 'unit']);
              }
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes('unit')} />
          Unit
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes("color")) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== "color")
              );
            } else {
              if (dataTypeSelected.length < 5) {
                setDataTypeSelected((prev) => [...prev, "color"]);
              }
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes("color")} />
          Color
        </MenuItem>
      </CustomMenu>
      {/* Export */}
      <CustomButton
        startIcon={<ExitToAppIcon />}
        endIcon={<ArrowDropDownIcon />}
        onClick={(e) => setOpenExport(e.currentTarget)}
        aria-haspopup="true"
        sx={{
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          bgcolor: "#FFFFFF",
          color: "#000000",
          fontSize: "0.875rem",
          "&:hover": {
            bgcolor: "#FFFFFF",
            color: "#000000",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          },
          px: 2,
          py: 0.8,
        }}
      >
        Export
      </CustomButton>
      <CustomMenu
        open={isOpenExport}
        openMenu={openExport}
        setOpenMenu={setOpenExport}
        PaperProps={{ sx: { minWidth: "121px" } }}
      >
        <MenuItem onClick={handleExportPdf}>PDF</MenuItem>
        <ExportXlsExcel
          excelData={dataForExports}
          fileName="Product"
          sx={{ color: "#000000" }}
        >
          <MenuItem>XLS</MenuItem>
        </ExportXlsExcel>
        <CSVLink
          style={{ color: "#000000", textDecoration: "none" }}
          data={dataForExports}
        >
          <MenuItem onClick={handleExportExcel}>CSV</MenuItem>
        </CSVLink>
      </CustomMenu>
      {/* Filter Adjust */}
      {roadHirarki.subCategoryId === 0 && (
        <CustomCard
          sx={{
            p: "10px 20px",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            gap: 1,
            cursor: "pointer",
          }}
          onClick={() => setIsOpenTableFilter(true)}
        >
          <FilterAltIcon sx={{ fontSize: "20px" }} />
          <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
            Filter
          </Typography>
        </CustomCard>
      )}
      {/* Filter Dialog */}
      {isOpenTableFilter && (
        <CustomDialog
          isOpen={isOpenTableFilter}
          setIsOpen={setIsOpenTableFilter}
          fullWidth
        >
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.125rem",
                  fontWeight: 600,
                  color: "#000000",
                }}
                component="span"
              >
                Filter
              </Typography>
              <IconButton
                aria-label="close"
                onClick={() => setIsOpenTableFilter(!isOpenTableFilter)}
                sx={{
                  color: "#323232",
                  p: 0,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            {/* Category */}
            <Box sx={{ mt: "8px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                  Category
                </Typography>
                {!autoCompleteFilter.category && (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#F33A3A",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCategoryFilterChoose(categoryFilterData);
                      setAutoCompleteFilter({
                        ...autoCompleteFilter,
                        category: true,
                        categoryOpen: false,
                      });
                    }}
                  >
                    Clear
                  </Typography>
                )}
              </Box>
              <Box>
                <Autocomplete
                  sx={{
                    ".MuiAutocomplete-hasClearIcon": {
                      display: "none",
                    },
                  }}
                  clearIcon={
                    autoCompleteFilter.category &&
                    !autoCompleteFilter.categoryOpen ? (
                      false
                    ) : (
                      <ClearIcon
                        fontSize="small"
                        onClick={() => {
                          setCategoryFilterChoose([]);
                          setAutoCompleteFilter({
                            ...autoCompleteFilter,
                            category: false,
                          });
                          setSubCategoryFilterChoose([]);
                          setMiniCategoryFilterChoose([]);
                        }}
                      />
                    )
                  }
                  multiple={
                    autoCompleteFilter.category &&
                    !autoCompleteFilter.categoryOpen
                      ? false
                      : true
                  }
                  limitTags={
                    autoCompleteFilter.category &&
                    !autoCompleteFilter.categoryOpen
                      ? 0
                      : 2
                  }
                  options={
                    autoCompleteFilter.category &&
                    !autoCompleteFilter.categoryOpen
                      ? allDataCategory
                      : categoryFilterData
                  }
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Box
                        sx={{
                          bgcolor: "white",
                          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
                          p: "4px 8px",
                          m: "2px",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        {option.namaKategori}
                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            setCategoryFilterChoose(
                              categoryFilterChoose.filter(
                                (item) => item.idKategori !== option.idKategori
                              )
                            )
                          }
                        >
                          <CloseFilledIcon />
                        </Box>
                      </Box>
                    ))
                  }
                  getOptionLabel={(option) => option.namaKategori}
                  value={
                    autoCompleteFilter.category &&
                    !autoCompleteFilter.categoryOpen
                      ? allDataCategory[0]
                      : categoryFilterChoose
                  }
                  onChange={(event, value) => {
                    if (
                      categoryFilterChoose.filter(
                        (item) =>
                          item.idKategori === value[value.length - 1].idKategori
                      ).length !== 0
                    ) {
                      setCategoryFilterChoose(
                        categoryFilterChoose.filter(
                          (item) =>
                            item.idKategori !==
                            value[value.length - 1].idKategori
                        )
                      );
                    } else {
                      setCategoryFilterChoose(value);
                    }
                    setSubCategoryFilterChoose([]);
                    setMiniCategoryFilterChoose([]);
                    if (value?.length === categoryFilterData?.length) {
                      setAutoCompleteFilter({
                        ...autoCompleteFilter,
                        category: true,
                      });
                    } else {
                      setAutoCompleteFilter({
                        ...autoCompleteFilter,
                        category: false,
                      });
                    }
                  }}
                  onOpen={() => {
                    setAutoCompleteFilter({
                      ...autoCompleteFilter,
                      categoryOpen: true,
                    });
                  }}
                  onClose={() => {
                    setAutoCompleteFilter({
                      ...autoCompleteFilter,
                      categoryOpen: false,
                    });
                  }}
                  selectOnFocus
                  disableCloseOnSelect
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      sx={{ color: "black" }}
                      key={option.idKategori}
                    >
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={
                          categoryFilterChoose.filter(
                            (item) => item.idKategori === option.idKategori
                          ).length !== 0
                        }
                      />
                      {option.namaKategori}
                    </li>
                  )}
                  style={{ width: "100%", marginTop: "12px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder="Enter Category"
                      sx={{ bgcolor: "#FAFAFA" }}
                    />
                  )}
                />
              </Box>
            </Box>
            {/* SubCategory */}
            <Box sx={{ mt: "8px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                  Subcategory
                </Typography>
                {!autoCompleteFilter.subCategory && (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#F33A3A",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (categoryFilterChoose.length !== 0) {
                        setSubCategoryFilterChoose(subCategoryFilterData);
                        setAutoCompleteFilter({
                          ...autoCompleteFilter,
                          subCategory: true,
                          subCategoryOpen: false,
                        });
                        setMiniCategoryFilterChoose([]);
                      }
                    }}
                  >
                    Clear
                  </Typography>
                )}
              </Box>
              <Box>
                <Autocomplete
                  sx={{
                    ".MuiAutocomplete-hasClearIcon": {
                      display: "none",
                    },
                  }}
                  clearIcon={
                    autoCompleteFilter.subCategory &&
                    !autoCompleteFilter.subCategoryOpen ? (
                      false
                    ) : (
                      <ClearIcon
                        fontSize="small"
                        onClick={() => {
                          setSubCategoryFilterChoose([]);
                          setAutoCompleteFilter({
                            ...autoCompleteFilter,
                            subCategory: false,
                          });
                        }}
                      />
                    )
                  }
                  multiple={
                    autoCompleteFilter.subCategory &&
                    !autoCompleteFilter.subCategoryOpen
                      ? false
                      : true
                  }
                  limitTags={
                    autoCompleteFilter.subCategory &&
                    !autoCompleteFilter.subCategoryOpen
                      ? 0
                      : 2
                  }
                  options={
                    autoCompleteFilter.subCategory &&
                    !autoCompleteFilter.subCategoryOpen
                      ? allDataSubCategory
                      : subCategoryFilterData
                  }
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Box
                        sx={{
                          bgcolor: "white",
                          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
                          p: "4px 8px",
                          m: "2px",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        {option.subkategori}
                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            setSubCategoryFilterChoose(
                              subCategoryFilterChoose.filter(
                                (item) =>
                                  item.idSubkategori !== option.idSubkategori
                              )
                            )
                          }
                        >
                          <CloseFilledIcon />
                        </Box>
                      </Box>
                    ))
                  }
                  getOptionLabel={(option) => option.subkategori}
                  value={
                    autoCompleteFilter.subCategory &&
                    !autoCompleteFilter.subCategoryOpen
                      ? allDataSubCategory[0]
                      : subCategoryFilterChoose
                  }
                  onChange={(event, value) => {
                    if (
                      subCategoryFilterChoose.filter(
                        (item) =>
                          item.idSubkategori ===
                          value[value.length - 1].idSubkategori
                      ).length !== 0
                    ) {
                      setSubCategoryFilterChoose(
                        subCategoryFilterChoose.filter(
                          (item) =>
                            item.idSubkategori !==
                            value[value.length - 1].idSubkategori
                        )
                      );
                      if (
                        subCategoryFilterData.length ===
                        subCategoryFilterChoose.length
                      ) {
                        setAutoCompleteFilter({
                          ...autoCompleteFilter,
                          subCategory: true,
                        });
                      } else {
                        setAutoCompleteFilter({
                          ...autoCompleteFilter,
                          subCategory: false,
                        });
                      }
                    } else {
                      setSubCategoryFilterChoose(value);
                      if (
                        subCategoryFilterData.length ===
                        subCategoryFilterChoose.length
                      ) {
                        setAutoCompleteFilter({
                          ...autoCompleteFilter,
                          subCategory: true,
                        });
                      } else {
                        setAutoCompleteFilter({
                          ...autoCompleteFilter,
                          subCategory: false,
                        });
                      }
                    }
                    setMiniCategoryFilterChoose([]);
                  }}
                  onOpen={() => {
                    setAutoCompleteFilter({
                      ...autoCompleteFilter,
                      subCategoryOpen: true,
                    });
                  }}
                  onClose={() => {
                    setAutoCompleteFilter({
                      ...autoCompleteFilter,
                      subCategoryOpen: false,
                    });
                  }}
                  selectOnFocus
                  disableCloseOnSelect
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      sx={{ color: "black" }}
                      key={option.idSubkategori}
                    >
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={
                          subCategoryFilterChoose.filter(
                            (item) =>
                              item.idSubkategori === option.idSubkategori
                          ).length !== 0
                        }
                      />
                      {option.subkategori}
                    </li>
                  )}
                  style={{ width: "100%", marginTop: "12px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder="Enter SubCategory"
                      sx={{ bgcolor: "#FAFAFA" }}
                    />
                  )}
                />
              </Box>
            </Box>
            {/* Mini SubCategory */}
            <Box sx={{ mt: "8px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                  Mini
                </Typography>
                {!autoCompleteFilter.miniCategory && (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#F33A3A",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (subCategoryFilterChoose.length !== 0) {
                        setMiniCategoryFilterChoose(miniCategoryFilterChoose);
                        setAutoCompleteFilter({
                          ...autoCompleteFilter,
                          miniCategory: true,
                          miniCategoryOpen: false,
                        });
                      }
                    }}
                  >
                    Clear
                  </Typography>
                )}
              </Box>
              <Box>
                <Autocomplete
                  sx={{
                    ".MuiAutocomplete-hasClearIcon": {
                      display: "none",
                    },
                  }}
                  clearIcon={
                    autoCompleteFilter.miniCategory &&
                    !autoCompleteFilter.miniCategoryOpen ? (
                      false
                    ) : (
                      <ClearIcon
                        fontSize="small"
                        onClick={() => {
                          setMiniCategoryFilterChoose([]);
                          setAutoCompleteFilter({
                            ...autoCompleteFilter,
                            miniCategory: false,
                          });
                        }}
                      />
                    )
                  }
                  multiple={
                    autoCompleteFilter.miniCategory &&
                    !autoCompleteFilter.miniCategoryOpen
                      ? false
                      : true
                  }
                  limitTags={
                    autoCompleteFilter.miniCategory &&
                    !autoCompleteFilter.miniCategoryOpen
                      ? 0
                      : 2
                  }
                  options={
                    autoCompleteFilter.miniCategory &&
                    !autoCompleteFilter.miniCategoryOpen
                      ? allDataSubCategory
                      : miniCategoryFilterData
                  }
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Box
                        sx={{
                          bgcolor: "white",
                          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
                          p: "4px 8px",
                          m: "2px",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        {option.name}
                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            setMiniCategoryFilterChoose(
                              miniCategoryFilterChoose.filter(
                                (item) => item.id !== option.id
                              )
                            )
                          }
                        >
                          <CloseFilledIcon />
                        </Box>
                      </Box>
                    ))
                  }
                  getOptionLabel={(option) => option.name}
                  value={
                    autoCompleteFilter.miniCategory &&
                    !autoCompleteFilter.miniCategoryOpen
                      ? allDataMiniSubCategory[0]
                      : miniCategoryFilterChoose
                  }
                  onChange={(event, value) => {
                    // if (
                    //   miniCategoryFilterChoose.filter(
                    //     (item) => item.id === value[value.length - 1].id
                    //   ).length !== 0
                    // ) {
                    //   setMiniCategoryFilterChoose(
                    //     miniCategoryFilterChoose.filter(
                    //       (item) => item.id !== value[value.length - 1].id
                    //     )
                    //   );
                    // } else {
                    //   setMiniCategoryFilterChoose(value);
                    // }
                    setMiniCategoryFilterChoose(value);
                    // if (value?.length === miniCategoryFilterChoose?.length) {
                    //   setAutoCompleteFilter({
                    //     ...autoCompleteFilter,
                    //     miniCategory: true,
                    //   });
                    // } else {
                    //   setAutoCompleteFilter({
                    //     ...autoCompleteFilter,
                    //     miniCategory: false,
                    //   });
                    // }
                  }}
                  onOpen={() => {
                    setAutoCompleteFilter({
                      ...autoCompleteFilter,
                      miniCategoryOpen: true,
                    });
                  }}
                  onClose={() => {
                    setAutoCompleteFilter({
                      ...autoCompleteFilter,
                      miniCategoryOpen: false,
                    });
                  }}
                  selectOnFocus
                  disableCloseOnSelect
                  renderOption={(props, option, { selected }) => (
                    <li {...props} sx={{ color: "black" }} key={option.id}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={
                          miniCategoryFilterChoose.filter(
                            (item) => item.id === option.id
                          ).length !== 0
                        }
                      />
                      {option.name}
                    </li>
                  )}
                  style={{ width: "100%", marginTop: "12px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder="Enter Mini"
                      sx={{ bgcolor: "#FAFAFA" }}
                    />
                  )}
                />
              </Box>
            </Box>
            {/* Bawah */}
            <Box
              sx={{
                display: "flex",
                bgcolor: "#FAFAFA",
                alignItems: "center",
                justifyContent: "end",
                padding: "16px 24px",
                gap: 3,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                    color: theme.palette.primary.main,
                  }}
                  onClick={handleCliskResetFilter}
                >
                  Reset
                </Typography>
              </Box>
              <Box>
                <CustomButton
                  variant="contained"
                  sx={{ width: "100px", height: "42px" }}
                  onClick={handleClickSaveFilter}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                    Save
                  </Typography>
                </CustomButton>
              </Box>
            </Box>
          </DialogContent>
        </CustomDialog>
      )}
    </Box>
  );
  <Box sx={{ display: "flex", gap: 2, flexDirection: "row-reverse" }}>
    {/* add */}
    <CustomButton
      sx={{
        bgcolor: "#51B15C",
        color: "#ffffff",
        borderRadius: "8px",
        "&:hover": {
          bgcolor: "#51B15C",
          color: "#ffffff",
        },
        fontSize: "0.875rem",
        px: 2,
      }}
      startIcon={<AddIcon sx={{ width: "14px" }} />}
      onClick={() => {
        setPage("product");
        setIsProductProductAdd(true);
        setRoadHirarki(roadHirarki);
      }}
    >
      Add Product
    </CustomButton>
    {/* add Mini */}
    {roadHirarki.subCategoryId !== 0 && (
      <CustomButton
        sx={{
          bgcolor: "#51B15C",
          color: "#ffffff",
          borderRadius: "8px",
          "&:hover": {
            bgcolor: "#51B15C",
            color: "#ffffff",
          },
          fontSize: "0.875rem",
          px: 2,
        }}
        startIcon={<AddIcon sx={{ width: "14px" }} />}
        onClick={() => {
          setPage("product");
          setIsMiniSubProductAdd(true);
        }}
      >
        Add Mini-Sub
      </CustomButton>
    )}
    <IconButton
      sx={{
        border: "1.5px solid #51B15C",
        borderRadius: "8px",
        p: 1,
      }}
      onClick={(e) => setOpenColumnFilter(e.currentTarget)}
      aria-haspopup="true"
    >
      <ColumnFilterIcon fill="#51B15C" />
    </IconButton>
    <CustomMenu
      open={isOpenColumnFilter}
      openMenu={openColumnFilter}
      setOpenMenu={setOpenColumnFilter}
      sx={{
        ".MuiMenuItem-root": {
          paddingLeft: 0,
          paddingRight: "20px",
          paddingTop: 0,
          paddingBottom: 0,
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", p: "12px" }}>
        <Typography sx={{ fontWeight: "400", fontSize: "12px" }}>
          Max 7
        </Typography>
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "12px",
            color: theme.palette.primary.main,
            cursor: "pointer",
          }}
          onClick={() =>
            setDataTypeSelected(["category", "mini", "cogs", "rrp", "limit"])
          }
        >
          Reset
        </Typography>
      </Box>
      <Divider sx={{ my: 0.5 }} />
      <MenuItem>
        <Checkbox checked={true} />
        Name*
      </MenuItem>
      <MenuItem>
        <Checkbox checked={true} />
        Photo*
      </MenuItem>
      <MenuItem
        onClick={() => {
          if (dataTypeSelected.includes("category")) {
            setDataTypeSelected(
              dataTypeSelected.filter((item) => item !== "category")
            );
          } else {
            if (dataTypeSelected.length < 5) {
              setDataTypeSelected((prev) => [...prev, "category"]);
            }
          }
        }}
      >
        <Checkbox checked={dataTypeSelected.includes("category")} />
        Category
      </MenuItem>
      <MenuItem
        onClick={() => {
          if (dataTypeSelected.includes("mini")) {
            setDataTypeSelected(
              dataTypeSelected.filter((item) => item !== "mini")
            );
          } else {
            if (dataTypeSelected.length < 5) {
              setDataTypeSelected((prev) => [...prev, "mini"]);
            }
          }
        }}
      >
        <Checkbox checked={dataTypeSelected.includes("mini")} />
        Mini
      </MenuItem>
      <MenuItem
        onClick={() => {
          if (dataTypeSelected.includes("cogs")) {
            setDataTypeSelected(
              dataTypeSelected.filter((item) => item !== "cogs")
            );
          } else {
            if (dataTypeSelected.length < 5) {
              setDataTypeSelected((prev) => [...prev, "cogs"]);
            }
          }
        }}
      >
        <Checkbox checked={dataTypeSelected.includes("cogs")} />
        COGS
      </MenuItem>
      <MenuItem
        onClick={() => {
          if (dataTypeSelected.includes("rrp")) {
            setDataTypeSelected(
              dataTypeSelected.filter((item) => item !== "rrp")
            );
          } else {
            if (dataTypeSelected.length < 5) {
              setDataTypeSelected((prev) => [...prev, "rrp"]);
            }
          }
        }}
      >
        <Checkbox checked={dataTypeSelected.includes("rrp")} />
        RRP
      </MenuItem>
      <MenuItem
        onClick={() => {
          if (dataTypeSelected.includes("limit")) {
            setDataTypeSelected(
              dataTypeSelected.filter((item) => item !== "limit")
            );
          } else {
            if (dataTypeSelected.length < 5) {
              setDataTypeSelected((prev) => [...prev, "limit"]);
            }
          }
        }}
      >
        <Checkbox checked={dataTypeSelected.includes("limit")} />
        Limit
      </MenuItem>
      <MenuItem
        onClick={() => {
          if (dataTypeSelected.includes("unit")) {
            setDataTypeSelected(
              dataTypeSelected.filter((item) => item !== "unit")
            );
          } else {
            if (dataTypeSelected.length < 5) {
              setDataTypeSelected((prev) => [...prev, "unit"]);
            }
          }
        }}
      >
        <Checkbox checked={dataTypeSelected.includes("unit")} />
        Unit
      </MenuItem>
      <MenuItem
        onClick={() => {
          if (dataTypeSelected.includes("color")) {
            setDataTypeSelected(
              dataTypeSelected.filter((item) => item !== "color")
            );
          } else {
            if (dataTypeSelected.length < 5) {
              setDataTypeSelected((prev) => [...prev, "color"]);
            }
          }
        }}
      >
        <Checkbox checked={dataTypeSelected.includes("color")} />
        Color
      </MenuItem>
    </CustomMenu>
    {/* Filter Adjust */}
    <CustomCard
      sx={{
        p: "10px 20px",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        gap: 1,
        cursor: "pointer",
      }}
      onClick={() => setIsOpenTableFilter(true)}
    >
      <FilterAltIcon sx={{ fontSize: "20px" }} />
      <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
        Filter
      </Typography>
    </CustomCard>
    {/* Filter Dialog */}
    {isOpenTableFilter && (
      <CustomDialog
        isOpen={isOpenTableFilter}
        setIsOpen={setIsOpenTableFilter}
        fullWidth
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: "1.125rem",
                fontWeight: 600,
                color: "#000000",
              }}
              component="span"
            >
              Filter
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setIsOpenTableFilter(!isOpenTableFilter)}
              sx={{
                color: "#323232",
                p: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {/* Category */}
          <Box sx={{ mt: "8px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                Category
              </Typography>
              {!autoCompleteFilter.category && (
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#F33A3A",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCategoryFilterChoose(categoryFilterData);
                    setAutoCompleteFilter({
                      ...autoCompleteFilter,
                      category: true,
                      categoryOpen: false,
                    });
                  }}
                >
                  Clear
                </Typography>
              )}
            </Box>
            <Box>
              <Autocomplete
                sx={{
                  ".MuiAutocomplete-hasClearIcon": {
                    display: "none",
                  },
                }}
                clearIcon={
                  autoCompleteFilter.category &&
                  !autoCompleteFilter.categoryOpen ? (
                    false
                  ) : (
                    <ClearIcon
                      fontSize="small"
                      onClick={() => {
                        setCategoryFilterChoose([]);
                        setAutoCompleteFilter({
                          ...autoCompleteFilter,
                          category: false,
                        });
                        setSubCategoryFilterChoose([]);
                        setMiniCategoryFilterChoose([]);
                      }}
                    />
                  )
                }
                multiple={
                  autoCompleteFilter.category &&
                  !autoCompleteFilter.categoryOpen
                    ? false
                    : true
                }
                limitTags={
                  autoCompleteFilter.category &&
                  !autoCompleteFilter.categoryOpen
                    ? 0
                    : 2
                }
                options={
                  autoCompleteFilter.category &&
                  !autoCompleteFilter.categoryOpen
                    ? allDataCategory
                    : categoryFilterData
                }
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Box
                      sx={{
                        bgcolor: "white",
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
                        p: "4px 8px",
                        m: "2px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      {option.namaKategori}
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          setCategoryFilterChoose(
                            categoryFilterChoose.filter(
                              (item) => item.idKategori !== option.idKategori
                            )
                          )
                        }
                      >
                        <CloseFilledIcon />
                      </Box>
                    </Box>
                  ))
                }
                getOptionLabel={(option) => option.namaKategori}
                value={
                  autoCompleteFilter.category &&
                  !autoCompleteFilter.categoryOpen
                    ? allDataCategory[0]
                    : categoryFilterChoose
                }
                onChange={(event, value) => {
                  if (
                    categoryFilterChoose.filter(
                      (item) =>
                        item.idKategori === value[value.length - 1].idKategori
                    ).length !== 0
                  ) {
                    setCategoryFilterChoose(
                      categoryFilterChoose.filter(
                        (item) =>
                          item.idKategori !== value[value.length - 1].idKategori
                      )
                    );
                  } else {
                    setCategoryFilterChoose(value);
                  }
                  setSubCategoryFilterChoose([]);
                  setMiniCategoryFilterChoose([]);
                  if (value?.length === categoryFilterData?.length) {
                    setAutoCompleteFilter({
                      ...autoCompleteFilter,
                      category: true,
                    });
                  } else {
                    setAutoCompleteFilter({
                      ...autoCompleteFilter,
                      category: false,
                    });
                  }
                }}
                onOpen={() => {
                  setAutoCompleteFilter({
                    ...autoCompleteFilter,
                    categoryOpen: true,
                  });
                }}
                onClose={() => {
                  setAutoCompleteFilter({
                    ...autoCompleteFilter,
                    categoryOpen: false,
                  });
                }}
                selectOnFocus
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <li
                    {...props}
                    sx={{ color: "black" }}
                    key={option.idKategori}
                  >
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={
                        categoryFilterChoose.filter(
                          (item) => item.idKategori === option.idKategori
                        ).length !== 0
                      }
                    />
                    {option.namaKategori}
                  </li>
                )}
                style={{ width: "100%", marginTop: "12px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    placeholder="Enter Category"
                    sx={{ bgcolor: "#FAFAFA" }}
                  />
                )}
              />
            </Box>
          </Box>
          {/* SubCategory */}
          <Box sx={{ mt: "8px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                Subcategory
              </Typography>
              {!autoCompleteFilter.subCategory && (
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#F33A3A",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (categoryFilterChoose.length !== 0) {
                      setSubCategoryFilterChoose(subCategoryFilterData);
                      setAutoCompleteFilter({
                        ...autoCompleteFilter,
                        subCategory: true,
                        subCategoryOpen: false,
                      });
                      setMiniCategoryFilterChoose([]);
                    }
                  }}
                >
                  Clear
                </Typography>
              )}
            </Box>
            <Box>
              <Autocomplete
                sx={{
                  ".MuiAutocomplete-hasClearIcon": {
                    display: "none",
                  },
                }}
                clearIcon={
                  autoCompleteFilter.subCategory &&
                  !autoCompleteFilter.subCategoryOpen ? (
                    false
                  ) : (
                    <ClearIcon
                      fontSize="small"
                      onClick={() => {
                        setSubCategoryFilterChoose([]);
                        setAutoCompleteFilter({
                          ...autoCompleteFilter,
                          subCategory: false,
                        });
                      }}
                    />
                  )
                }
                multiple={
                  autoCompleteFilter.subCategory &&
                  !autoCompleteFilter.subCategoryOpen
                    ? false
                    : true
                }
                limitTags={
                  autoCompleteFilter.subCategory &&
                  !autoCompleteFilter.subCategoryOpen
                    ? 0
                    : 2
                }
                options={
                  autoCompleteFilter.subCategory &&
                  !autoCompleteFilter.subCategoryOpen
                    ? allDataSubCategory
                    : subCategoryFilterData
                }
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Box
                      sx={{
                        bgcolor: "white",
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
                        p: "4px 8px",
                        m: "2px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      {option.subkategori}
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          setSubCategoryFilterChoose(
                            subCategoryFilterChoose.filter(
                              (item) =>
                                item.idSubkategori !== option.idSubkategori
                            )
                          )
                        }
                      >
                        <CloseFilledIcon />
                      </Box>
                    </Box>
                  ))
                }
                getOptionLabel={(option) => option.subkategori}
                value={
                  autoCompleteFilter.subCategory &&
                  !autoCompleteFilter.subCategoryOpen
                    ? allDataSubCategory[0]
                    : subCategoryFilterChoose
                }
                onChange={(event, value) => {
                  if (
                    subCategoryFilterChoose.filter(
                      (item) =>
                        item.idSubkategori ===
                        value[value.length - 1].idSubkategori
                    ).length !== 0
                  ) {
                    setSubCategoryFilterChoose(
                      subCategoryFilterChoose.filter(
                        (item) =>
                          item.idSubkategori !==
                          value[value.length - 1].idSubkategori
                      )
                    );
                    if (
                      subCategoryFilterData.length ===
                      subCategoryFilterChoose.length
                    ) {
                      setAutoCompleteFilter({
                        ...autoCompleteFilter,
                        subCategory: true,
                      });
                    } else {
                      setAutoCompleteFilter({
                        ...autoCompleteFilter,
                        subCategory: false,
                      });
                    }
                  } else {
                    setSubCategoryFilterChoose(value);
                    if (
                      subCategoryFilterData.length ===
                      subCategoryFilterChoose.length
                    ) {
                      setAutoCompleteFilter({
                        ...autoCompleteFilter,
                        subCategory: true,
                      });
                    } else {
                      setAutoCompleteFilter({
                        ...autoCompleteFilter,
                        subCategory: false,
                      });
                    }
                  }
                  setMiniCategoryFilterChoose([]);
                }}
                onOpen={() => {
                  setAutoCompleteFilter({
                    ...autoCompleteFilter,
                    subCategoryOpen: true,
                  });
                }}
                onClose={() => {
                  setAutoCompleteFilter({
                    ...autoCompleteFilter,
                    subCategoryOpen: false,
                  });
                }}
                selectOnFocus
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <li
                    {...props}
                    sx={{ color: "black" }}
                    key={option.idSubkategori}
                  >
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={
                        subCategoryFilterChoose.filter(
                          (item) => item.idSubkategori === option.idSubkategori
                        ).length !== 0
                      }
                    />
                    {option.subkategori}
                  </li>
                )}
                style={{ width: "100%", marginTop: "12px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    placeholder="Enter SubCategory"
                    sx={{ bgcolor: "#FAFAFA" }}
                  />
                )}
              />
            </Box>
          </Box>
          {/* Mini SubCategory */}
          <Box sx={{ mt: "8px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                Mini
              </Typography>
              {!autoCompleteFilter.miniCategory && (
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#F33A3A",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (subCategoryFilterChoose.length !== 0) {
                      setMiniCategoryFilterChoose(miniCategoryFilterChoose);
                      setAutoCompleteFilter({
                        ...autoCompleteFilter,
                        miniCategory: true,
                        miniCategoryOpen: false,
                      });
                    }
                  }}
                >
                  Clear
                </Typography>
              )}
            </Box>
            <Box>
              <Autocomplete
                sx={{
                  ".MuiAutocomplete-hasClearIcon": {
                    display: "none",
                  },
                }}
                clearIcon={
                  autoCompleteFilter.miniCategory &&
                  !autoCompleteFilter.miniCategoryOpen ? (
                    false
                  ) : (
                    <ClearIcon
                      fontSize="small"
                      onClick={() => {
                        setMiniCategoryFilterChoose([]);
                        setAutoCompleteFilter({
                          ...autoCompleteFilter,
                          miniCategory: false,
                        });
                      }}
                    />
                  )
                }
                multiple={
                  autoCompleteFilter.miniCategory &&
                  !autoCompleteFilter.miniCategoryOpen
                    ? false
                    : true
                }
                limitTags={
                  autoCompleteFilter.miniCategory &&
                  !autoCompleteFilter.miniCategoryOpen
                    ? 0
                    : 2
                }
                options={
                  autoCompleteFilter.miniCategory &&
                  !autoCompleteFilter.miniCategoryOpen
                    ? allDataSubCategory
                    : miniCategoryFilterData
                }
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Box
                      sx={{
                        bgcolor: "white",
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
                        p: "4px 8px",
                        m: "2px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      {option.name}
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          setMiniCategoryFilterChoose(
                            miniCategoryFilterChoose.filter(
                              (item) => item.id !== option.id
                            )
                          )
                        }
                      >
                        <CloseFilledIcon />
                      </Box>
                    </Box>
                  ))
                }
                getOptionLabel={(option) => option.name}
                value={
                  autoCompleteFilter.miniCategory &&
                  !autoCompleteFilter.miniCategoryOpen
                    ? allDataMiniSubCategory[0]
                    : miniCategoryFilterChoose
                }
                onChange={(event, value) => {
                  // if (
                  //   miniCategoryFilterChoose.filter(
                  //     (item) => item.id === value[value.length - 1].id
                  //   ).length !== 0
                  // ) {
                  //   setMiniCategoryFilterChoose(
                  //     miniCategoryFilterChoose.filter(
                  //       (item) => item.id !== value[value.length - 1].id
                  //     )
                  //   );
                  // } else {
                  //   setMiniCategoryFilterChoose(value);
                  // }
                  setMiniCategoryFilterChoose(value);
                  // if (value?.length === miniCategoryFilterChoose?.length) {
                  //   setAutoCompleteFilter({
                  //     ...autoCompleteFilter,
                  //     miniCategory: true,
                  //   });
                  // } else {
                  //   setAutoCompleteFilter({
                  //     ...autoCompleteFilter,
                  //     miniCategory: false,
                  //   });
                  // }
                }}
                onOpen={() => {
                  setAutoCompleteFilter({
                    ...autoCompleteFilter,
                    miniCategoryOpen: true,
                  });
                }}
                onClose={() => {
                  setAutoCompleteFilter({
                    ...autoCompleteFilter,
                    miniCategoryOpen: false,
                  });
                }}
                selectOnFocus
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <li {...props} sx={{ color: "black" }} key={option.id}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={
                        miniCategoryFilterChoose.filter(
                          (item) => item.id === option.id
                        ).length !== 0
                      }
                    />
                    {option.name}
                  </li>
                )}
                style={{ width: "100%", marginTop: "12px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    placeholder="Enter Mini"
                    sx={{ bgcolor: "#FAFAFA" }}
                  />
                )}
              />
            </Box>
          </Box>
          {/* Bawah */}
          <Box
            sx={{
              display: "flex",
              bgcolor: "#FAFAFA",
              alignItems: "center",
              justifyContent: "end",
              padding: "16px 24px",
              gap: 3,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  cursor: "pointer",
                  color: theme.palette.primary.main,
                }}
                onClick={handleCliskResetFilter}
              >
                Reset
              </Typography>
            </Box>
            <Box>
              <CustomButton
                variant="contained"
                sx={{ width: "100px", height: "42px" }}
                onClick={handleClickSaveFilter}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                  Save
                </Typography>
              </CustomButton>
            </Box>
          </Box>
        </DialogContent>
      </CustomDialog>
    )}
  </Box>;

  // !---------------------------- End -------------------------------

  return (
    <Box>
      <CustomTable
        name="Product"
        rowPerPage={tableRowPerPage}
        handleRowPerPage={(event) => {
          setTableRowPerPage(event.target.value);
          tableCurrentPage(1);
        }}
        searchValue={tableSearch}
        setSearchValue={setTableSearch}
        rowPerPageValues={tableRowPerPageValues}
        currentPage={tableCurrentPage}
        totalValueItems={tableTotalItems}
        isNoData={tableDatas.length === 0 ? true : false}
        noDataText="No Product"
        handleInputCurrentPage={(event) => {
          if (
            event.target.value >= Math.ceil(tableTotalItems / tableRowPerPage)
          ) {
            setTableCurrentPage(Math.ceil(tableTotalItems / tableRowPerPage));
          } else if (event.target.value <= 1) {
            setTableCurrentPage(1);
          } else {
            setTableCurrentPage(parseInt(event.target.value));
          }
        }}
        handleBackArrow={() =>
          setTableCurrentPage(
            tableCurrentPage <= 1 ? tableCurrentPage : tableCurrentPage - 1
          )
        }
        handleForwardArrow={() =>
          setTableCurrentPage(
            tableCurrentPage >= Math.ceil(tableTotalItems / tableRowPerPage)
              ? tableCurrentPage
              : tableCurrentPage + 1
          )
        }
        toolbar={toolbar}
      >
        <TableHead>
          <TableRow sx={{ bgcolor: "#FAFAFA" }}>
            <CustomTableCells>No.</CustomTableCells>
            <CustomTableCells>Photo</CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSortTable("name");
                  setIsSortAsc(!isSortAsc);
                  setTableCurrentPage(1);
                }}
              >
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "500", mr: "2px" }}
                >
                  Name
                </Typography>
                {sortTable === "name" ? (
                  isSortAsc ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(90deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(270deg)",
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: "12px",
                      transform: "rotate(90deg)",
                      color: "#9E9D9D",
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
            {dataTypeSelected.includes("category") && (
              <CustomTableCells align="left">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSortTable("category");
                    setIsSortAsc(!isSortAsc);
                    setTableCurrentPage(1);
                  }}
                >
                  <Typography
                    sx={{ fontSize: "12px", fontWeight: "500", mr: "2px" }}
                  >
                    CAT
                  </Typography>
                  {sortTable === "category" ? (
                    isSortAsc ? (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: "12px",
                          transform: "rotate(90deg)",
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: "12px",
                          transform: "rotate(270deg)",
                          color: theme.palette.primary.main,
                        }}
                      />
                    )
                  ) : (
                    <SyncAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(90deg)",
                        color: "#9E9D9D",
                      }}
                    />
                  )}
                </Box>
              </CustomTableCells>
            )}
            {dataTypeSelected.includes("mini") && (
              <CustomTableCells align="left">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSortTable("mini");
                    setIsSortAsc(!isSortAsc);
                    setTableCurrentPage(1);
                  }}
                >
                  <Typography
                    sx={{ fontSize: "12px", fontWeight: "500", mr: "2px" }}
                  >
                    Mini
                  </Typography>
                  {sortTable === "mini" ? (
                    isSortAsc ? (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: "12px",
                          transform: "rotate(90deg)",
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: "12px",
                          transform: "rotate(270deg)",
                          color: theme.palette.primary.main,
                        }}
                      />
                    )
                  ) : (
                    <SyncAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(90deg)",
                        color: "#9E9D9D",
                      }}
                    />
                  )}
                </Box>
              </CustomTableCells>
            )}
            {dataTypeSelected.includes("cogs") && (
              <CustomTableCells align="right">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSortTable("cogs");
                    setIsSortAsc(!isSortAsc);
                    setTableCurrentPage(1);
                  }}
                >
                  <Typography
                    sx={{ fontSize: "12px", fontWeight: "500", mr: "2px" }}
                  >
                    COGS(Rp)*
                  </Typography>
                  {sortTable === "cogs" ? (
                    isSortAsc ? (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: "12px",
                          transform: "rotate(90deg)",
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: "12px",
                          transform: "rotate(270deg)",
                          color: theme.palette.primary.main,
                        }}
                      />
                    )
                  ) : (
                    <SyncAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(90deg)",
                        color: "#9E9D9D",
                      }}
                    />
                  )}
                </Box>
              </CustomTableCells>
            )}
            {dataTypeSelected.includes("rrp") && (
              <CustomTableCells align="right">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSortTable("rrp");
                    setIsSortAsc(!isSortAsc);
                    setTableCurrentPage(1);
                  }}
                >
                  <Typography
                    sx={{ fontSize: "12px", fontWeight: "500", mr: "2px" }}
                  >
                    RRP(Rp)*
                  </Typography>
                  {sortTable === "rrp" ? (
                    isSortAsc ? (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: "12px",
                          transform: "rotate(90deg)",
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: "12px",
                          transform: "rotate(270deg)",
                          color: theme.palette.primary.main,
                        }}
                      />
                    )
                  ) : (
                    <SyncAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(90deg)",
                        color: "#9E9D9D",
                      }}
                    />
                  )}
                </Box>
              </CustomTableCells>
            )}
            {dataTypeSelected.includes("limit") && (
              <CustomTableCells align="left">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSortTable("limit");
                    setIsSortAsc(!isSortAsc);
                    setTableCurrentPage(1);
                  }}
                >
                  <Typography
                    sx={{ fontSize: "12px", fontWeight: "500", mr: "2px" }}
                  >
                    Limit
                  </Typography>
                  {sortTable === "limit" ? (
                    isSortAsc ? (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: "12px",
                          transform: "rotate(90deg)",
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: "12px",
                          transform: "rotate(270deg)",
                          color: theme.palette.primary.main,
                        }}
                      />
                    )
                  ) : (
                    <SyncAltIcon
                      sx={{
                        fontSize: "12px",
                        transform: "rotate(90deg)",
                        color: "#9E9D9D",
                      }}
                    />
                  )}
                </Box>
              </CustomTableCells>
            )}
            {dataTypeSelected.includes("unit") && <TableCell>Unit</TableCell>}
            {dataTypeSelected.includes("color") && <TableCell>Color</TableCell>}
          </TableRow>
        </TableHead>
        {!isLoadingData ? (
          <TableBody>
            {tableDatas.map((tableData, index) => (
              <TableRow
                key={index}
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  handleClickRow(tableData.namaProduk, tableData.idProduk)
                }
              >
                <CustomTableCells sx={{ width: "40px" }}>
                  {index + 1}
                </CustomTableCells>
                <CustomTableCells sx={{ width: "40px" }}>
                  {tableData.gambar ? (
                    <Box>
                      <img
                        src={
                          process.env.REACT_APP_API_IMAGE_BASE_URL +
                          "/" +
                          tableData.gambar.split(",")[0]
                        }
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "8px",
                        }}
                        alt="pic"
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#FAFAFA",
                        borderRadius: "2px",
                      }}
                    >
                      <NoImage />
                      <Typography sx={{ color: "#9E9D9D", fontSize: "6px" }}>
                        No photo
                      </Typography>
                    </Box>
                  )}
                </CustomTableCells>
                <CustomTableCells>{tableData.namaProduk}</CustomTableCells>
                {dataTypeSelected.includes("category") && (
                  <CustomTableCells>
                    {tableData.miniCategory !== null
                      ? tableData?.miniCategory?.subCategory?.category
                          ?.namaKategori
                      : tableData?.subCategory !== null
                      ? tableData?.subCategory?.category?.namaKategori
                      : "-"}
                  </CustomTableCells>
                )}
                {dataTypeSelected.includes("mini") && (
                  <>
                    {!tableData.miniCategory ? (
                      <CustomTableCells>-</CustomTableCells>
                    ) : (
                      <CustomTableCells
                        onClick={(event) =>
                          handleClickMini(
                            event,
                            tableData.miniCategory.id,
                            tableData.miniCategory.name
                          )
                        }
                      >
                        {tableData.miniCategory.name}
                      </CustomTableCells>
                    )}
                  </>
                )}
                {dataTypeSelected.includes("cogs") && (
                  <CustomTableCells align="right">
                    {!tableData.hargaMenual ? "-" : tableData.hargaMenual}
                  </CustomTableCells>
                )}
                {dataTypeSelected.includes("rrp") && (
                  <CustomTableCells align="right">
                    {!tableData.hargaJual ? "-" : tableData.hargaJual}
                  </CustomTableCells>
                )}
                {dataTypeSelected.includes("limit") && (
                  <CustomTableCells align="right">
                    {!tableData.stokMin ? "-" : tableData.stokMin}
                  </CustomTableCells>
                )}

                {dataTypeSelected.includes("color") && (
                  <CustomTableCells>
                    {!tableData.warna ? "-" : tableData.warna}
                  </CustomTableCells>
                )}
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            {[...Array(tableRowPerPage)].map((_, x) => (
              <TableRow key={x}>
                <TableCell sx={{ width: "40px" }}>-</TableCell>
                <TableCell sx={{ width: "40px" }}>
                  <Box sx={{ height: "40px" }}></Box>
                </TableCell>
                <TableCell>-</TableCell>
                {dataTypeSelected.map((_, i) => (
                  <TableCell key={i}>-</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </CustomTable>
    </Box>
  );
};
export default TableProductProduct;
