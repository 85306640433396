import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Subsidiary from "../../../../../components/shared/Subsidiary";
import { useState } from "react";
import CustomCard from "../../../../../components/shared/CustomCard";
import {
  ChevronRightIcon,
  CloseIcon,
  KeyboardArrowDownIcon,
  StockAlert,
} from "../../../../../assets/Icons";
import SelectHubStock from "./SelectHub";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSubsidiaryIdStock,
  getAllFixHubsStock,
  getMasterHubIdStock,
  getMiniHubIdStock,
  getSubHubIdStock,
  getSubsidiaryIdStock,
} from "../../../../../store/stock/stock";
import { useEffect } from "react";
import ChartPerfomanceStock from "./Component/Chart";
import dayjs from "dayjs";
import TablePerfomanceStock from "./Component/Table";
import TableListStock from "./TableList";
import { getStockAlert } from "../../../../../api/stock";
import { changePageStock } from "../../../../../store/Routing/stockRouting";
import StockMobile from "./StockMobile/index";

const InventoryStock = () => {
  const matchTable = useMediaQuery("(min-width:832px)");
  const dispatch = useDispatch();
  const subsidiaryId = useSelector(getSubsidiaryIdStock);
  const hubId = useSelector(getMasterHubIdStock);
  const subHubId = useSelector(getSubHubIdStock);
  const miniHubId = useSelector(getMiniHubIdStock);
  const [filterSubsidiaryArray, setFilterSubsidiaryArray] = useState([]);
  const [isSelectHub, setIsSelectHub] = useState(false);
  const [dataAlert, setDataAlert] = useState(0);

  const dataHubs = useSelector(getAllFixHubsStock);

  useEffect(() => {
    dispatch(changeSubsidiaryIdStock(filterSubsidiaryArray));
  }, [filterSubsidiaryArray]);

  // ! grafik & table

  const [timeFrame, setTimeFrame] = useState("daily");

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);

  // ! get Stock alert

  const fetchStockAlert = async (params) => {
    try {
      const {
        data: { data },
      } = await getStockAlert(params);
      setDataAlert(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    let params = {};
    params.subsidiaryId = subsidiaryId;
    params.idToko = hubId;
    params.subHubid = subHubId;
    params.miniHubId = miniHubId;
    fetchStockAlert(params);
  }, [subsidiaryId, hubId, subHubId, miniHubId]);

  return !matchTable ? (
    <StockMobile />
  ) : (
    <Box sx={{ mt: 2 }}>
      <Grid container columns={12} spacing={3}>
        {/* subsidiary */}
        <Grid item xs={6}>
          <Subsidiary
            subsidiaryChecked={filterSubsidiaryArray}
            setSubsidiaryChecked={setFilterSubsidiaryArray}
          />
        </Grid>

        {/* hub */}
        <Grid item xs={6}>
          <CustomCard
            sx={{ padding: "16px 14px", cursor: "pointer", height: "74px" }}
            onClick={() => setIsSelectHub(true)}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 500, color: "#000000" }}
                >
                  Hub*
                </Typography>
                <Box
                  sx={{
                    mt: "10px",
                  }}
                >
                  {dataHubs.length === 0 ? (
                    <Typography
                      sx={{
                        color: "#9E9D9D",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      Click to Select hub, sub and mini
                    </Typography>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        color: "black",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Typography sx={{ fontSize: "12px" }}>
                        {dataHubs[0].namaToko}
                      </Typography>
                      {dataHubs[1] !== null ? (
                        <>
                          <ChevronRightIcon sx={{ fontSize: "12px" }} />
                          <Typography sx={{ fontSize: "12px" }}>
                            {dataHubs[1].name}
                          </Typography>
                        </>
                      ) : (
                        "_"
                      )}
                      {dataHubs[2] !== null ? (
                        <>
                          <ChevronRightIcon sx={{ fontSize: "12px" }} />
                          <Typography sx={{ fontSize: "12px" }}>
                            {dataHubs[2].name}
                          </Typography>
                        </>
                      ) : (
                        "_"
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
              <KeyboardArrowDownIcon />
            </Box>
          </CustomCard>

          {/* dialog hub */}
          <Dialog
            // maxWidth
            fullWidth
            // maxWidth="lg"
            open={isSelectHub}
            onClose={() => {
              setIsSelectHub(false);
            }}
          >
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>Select Hub</Box>
              <CloseIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setIsSelectHub(false)}
              />
            </DialogTitle>
            <DialogContent dividers>
              <SelectHubStock setIsSelectHub={setIsSelectHub} />
            </DialogContent>
          </Dialog>
        </Grid>

        <Grid item xs={12}>
          <CustomCard sx={{ mt: 2, p: 2 }}>
            <Grid container columns={12} spacing={4}>
              {/* grafik */}
              <Grid item xs={6}>
                <ChartPerfomanceStock
                  {...{
                    timeFrame,
                    setTimeFrame,
                    dateRange,
                    setDateRange,
                    saveDateRange,
                    setSaveDateRange,
                  }}
                />
              </Grid>

              {/* table */}
              <Grid item xs={6}>
                <TablePerfomanceStock />
              </Grid>
            </Grid>
          </CustomCard>
        </Grid>

        {/* list data table */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 600,
                color: "#000000",
              }}
            >
              Stock s
            </Typography>

            <Box
              sx={{
                width: "183px",
                height: "45px",
                borderRadius: "8px",
                border: "1px solid #F33A3A",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(changePageStock("stockAlert"));
              }}
            >
              <StockAlert sx={{ fontSize: "14px" }} />
              <Typography
                sx={{ color: "#F33A3A", fontSize: "14px", fontWeight: 600 }}
              >
                stock Alert
              </Typography>
              <Box
                sx={{
                  width: "20px",
                  height: "20px",
                  background: "#F33A3A",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#FFFFFF",
                }}
              >
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  {dataAlert}
                </Typography>
              </Box>
              <ChevronRightIcon sx={{ color: "#F33A3A", fontSize: "18px" }} />
            </Box>
          </Box>

          <TableListStock />
        </Grid>
      </Grid>
    </Box>
  );
};
export default InventoryStock;
