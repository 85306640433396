import { Grid, Typography, useTheme } from '@mui/material';
import BackButton from '../../../../../../components/shared/BackButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuPerformance from '../Components/performance/MenuPerformance';
import TableSubCategoryMenu from './Desktop/TableSubCategoryMenu';
import SubCategoryMenuDesktop from './SubCategoryMenuDesktop';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';
import SubCategoryMenuMobile from './SubCategoryMenuMobile';

const SubCategoryMenu = (props) => {
  const {
    desktopMode,
    roadHirarki,
    setRoadHirarki,
    // *--- Notif
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
    // *--- page
    setPage,
    setSubCategoryMenu,
    // *-date & time
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    // *table
    tableRowPerPageValues,
    tableRowPerPage,
    setTableRowPerPage,
    tableCurrentPage,
    setTableCurrentPage,
    tableTotalItems,
    setTableTotalItems,
    tableSearch,
    setTableSearch,
    sortTable,
    setSortTable,
    isSortAsc,
    setIsSortAsc,
    tableDatas,
    setTableDatas,
    tableCategoryFilterDatas,
    setTableCategoryFilterDatas,
    isLoadingData,
  } = props;

  const theme = useTheme();

  const handleBackButton = () => {
    setRoadHirarki({
      subsidiary: '',
      subsidiaryId: 0,
      category: '',
      categoryId: 0,
      subCategory: '',
      subCategoryId: 0,
      product: '',
      productId: 0,
      type: '',
      typeId: 0,
      miniSubCategory: '',
      miniSubCategoryId: 0,
    });
    setPage('category');
    setSubCategoryMenu('');
  };
  const handleClickRow = (name, id) => {
    setRoadHirarki({
      ...roadHirarki,
      subCategory: name,
      subCategoryId: id,
      menu: '',
      menuId: 0,
      type: '',
      typeId: 0,
      miniSubCategory: '',
      miniSubCategoryId: 0,
    });
    setTableCurrentPage(1);
    setPage('menu');
  };
  const handleClickName = (e, name, id) => {
    e.stopPropagation();
    setRoadHirarki({
      ...roadHirarki,
      subCategory: name,
      subCategoryId: id,
      menu: '',
      menuId: 0,
      type: '',
      typeId: 0,
      miniSubCategory: '',
      miniSubCategoryId: 0,
    });
    setSubCategoryMenu('details');
  };
  const handleAdd = () => {
    setSubCategoryMenu('add');
  };

  return (
    <>
      <CustomNotifSuccess
        text={notifTitle}
        open={notif === 'addsubcategory'}
        onClose={() => {
          setNotif('');
          setNotifTitle('');
        }}
      />
      <CustomNotifSuccess
        text={notifTitle}
        open={notif === 'deletesubcategory'}
        onClose={() => {
          setNotif('');
          setNotifTitle('');
        }}
      />
      {desktopMode ? (
        <SubCategoryMenuDesktop
          {...{ ...props }}
          {...{ theme }}
          {...{ handleBackButton, handleClickName, handleClickRow, handleAdd }}
        />
      ) : (
        <SubCategoryMenuMobile
          {...{ ...props }}
          {...{ theme }}
          {...{ handleBackButton, handleClickName, handleClickRow, handleAdd }}
        />
      )}
    </>
  );
};
export default SubCategoryMenu;
