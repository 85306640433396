import { Box, Grid, Typography } from "@mui/material";
import {
  changeColCreateMap,
  changeCreateMapPage,
  changeRowCreateMap,
  getCellCreateMap,
  getColCreateMap,
  getRowCreateMap,
  setUpCell,
} from "../../../../../../../../store/hub/miniHub/miniHubAdd";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../../../../../components/shared/CustomButton";
import CustomInput from "../../../../../../../../components/shared/CustomInput";

const CreateGridMiniHub = () => {
  const dispatch = useDispatch();
  const col = useSelector(getColCreateMap);
  const row = useSelector(getRowCreateMap);
  const cell = useSelector(getCellCreateMap);

  const handleNext = () => {
    dispatch(setUpCell());
    dispatch(changeCreateMapPage("racks"));
  };
  return (
    <Box>
      <Grid container columns={12} spacing={2}>
        {/* coll */}
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: "600", mb: "8px" }}>
            Number of columns
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <CustomInput
              sx={{ bgcolor: "#FAFAFA", width: "100%" }}
              value={col}
              onChange={(e) =>
                dispatch(changeColCreateMap(Number(e.target.value)))
              }
              type="number"
            />
            <CustomButton
              sx={{
                height: "56px",
                bgcolor: "rgba(81, 177, 92, 0.1)",
                color: "#51B15C",
                fontSize: "28px",
              }}
              onClick={() => dispatch(changeColCreateMap(Number(col - 1)))}
            >
              -
            </CustomButton>
            <CustomButton
              sx={{
                height: "56px",
                bgcolor: "rgba(81, 177, 92, 0.1)",
                color: "#51B15C",
                fontSize: "28px",
              }}
              onClick={() => dispatch(changeColCreateMap(Number(col + 1)))}
            >
              +
            </CustomButton>
          </Box>
        </Grid>

        {/* row */}
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: "600", mb: "8px" }}>
            Number of rows
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <CustomInput
              sx={{ bgcolor: "#FAFAFA", width: "100%" }}
              value={row}
              onChange={(e) =>
                dispatch(changeRowCreateMap(Number(e.target.value)))
              }
              type="number"
            />
            <CustomButton
              sx={{
                height: "56px",
                bgcolor: "rgba(81, 177, 92, 0.1)",
                color: "#51B15C",
                fontSize: "28px",
              }}
              onClick={() => dispatch(changeRowCreateMap(Number(row - 1)))}
            >
              -
            </CustomButton>
            <CustomButton
              sx={{
                height: "56px",
                bgcolor: "rgba(81, 177, 92, 0.1)",
                color: "#51B15C",
                fontSize: "28px",
              }}
              onClick={() => dispatch(changeRowCreateMap(Number(row + 1)))}
            >
              +
            </CustomButton>
          </Box>
        </Grid>
      </Grid>

      {/* button submit */}

      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          position: "fixed",
          bottom: "0px",
          right: "0px",
          display: "flex",
          justifyContent: "flex-end",
          padding: "18px 32px 18px 240px",
          alignItems: "center",
        }}
      >
        <CustomButton
          variant="contained"
          onClick={handleNext}
          sx={{ width: "190px", height: "48px" }}
        >
          Next
        </CustomButton>
      </Box>
    </Box>
  );
};
export default CreateGridMiniHub;
