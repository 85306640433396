import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import CustomCard from "../../../../../../../components/shared/CustomCard";
import {
  AddIcon,
  ChevronRightIcon,
  ExportIcon,
  SearchIcon,
  Telephone,
  ToBarChart,
  ToLineChart,
  UserIcon,
} from "../../../../../../../assets/Icons";
import CustomButton from "../../../../../../../components/shared/CustomButton";
import DialogFilterStockMobile from "../../../Stock/StockMobile/DialogFilter";
import { useState } from "react";
import { getAllMiniHub } from "../../../../../../../api/hubs";
import { useEffect } from "react";
import { useRef } from "react";
import debounce from "lodash.debounce";
import BackButtonHub from "../../../../../../../components/shared/BackButtonHub";
import { useDispatch, useSelector } from "react-redux";
import { changePageSubHub } from "../../../../../../../store/Routing/hubs";
import CustomBarChart from "../../../../../../../components/shared/CustomBarChart";
import CustomLineChart from "../../../../../../../components/shared/CustomLineChart";
import CustomTableMobile from "../../../../../../../components/shared/CustomTableMobile";
import { changeIdMiniHub } from "../../../../../../../store/hub/miniHub/miniHubDetails";
import { getAllDataMiniHub } from "../../../../../../../store/hub/miniHub/miniHubData";

// data dummy chart
const datas = {
  labels: ["Hub 1", "Hub 2", "Hub 3", "Hub 4", "Hub 5"],
  data: [
    {
      label: "Equal",
      chart: [30, 110, 140, 100, 240],
    },
    {
      label: "Different",
      chart: [0, 35, 100, 90, 150],
    },
    {
      label: "Missing",
      chart: [40, 70, 160, 120, 200],
    },
  ],
};
const color = ["#6B4BB4", "#EACD57", "#FD7900"];

const MiniHub = () => {
  const dispatch = useDispatch();
  const [miniHubList, setMiniHubList] = useState([]);
  const [Search, setSearch] = useState("");
  const [purchaseKeyword, setPurchaseKeyword] = useState("");
  const [chartType, setChartType] = useState(false);

  // subName
  const { masterHubName, subHubName, isLoadingMiniHub } =
    useSelector(getAllDataMiniHub);

  // Tollbar
  const toolbar = (
    <Box
      sx={{
        display: "flex",
        flexFlow: "row-reverse",
        gap: 1.7,
        alignItems: "center",
        justifyContent: "center",
        textAlign: "right",
      }}
    >
      <CustomButton
        sx={{
          minWidth: "22px",
          width: "22px",
          minHeight: "20px",
          height: "20px",
          borderRadius: "4px",
          bgcolor: "#51B15C",
          color: "#ffffff",
          "&:hover": {
            bgcolor: "#51B15C",
            color: "#ffffff",
          },
          fontSize: "0.875rem",
          display: "flex",
          justifyContent: "center",
        }}
        onClick={() => dispatch(changePageSubHub("AddMiniHub"))}
      >
        <AddIcon sx={{ color: "white", width: "8px", height: "8px" }} />
      </CustomButton>
      <CustomButton
        sx={{
          minWidth: "22px",
          width: "22px",
          minHeight: "22px",
          height: "22px",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "4px",
          bgcolor: "#FFFFFF",
          color: "#000000",
          "&:hover": {
            bgcolor: "#FFFFFF",
            color: "#000000",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <ExportIcon />
      </CustomButton>
    </Box>
  );

  //   search
  const debounceOnChange = useRef(
    debounce((value) => {
      setPurchaseKeyword(value);
    }, 1000)
  ).current;

  const fetchMiniHub = async (params) => {
    try {
      const {
        data: { data },
      } = await getAllMiniHub(params);
      setMiniHubList(data?.subHubs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const params = {};
    params.subHubId = [];
    params.Search = purchaseKeyword;
    params.pageSize = 4;
    fetchMiniHub(params);
  }, [purchaseKeyword]);

  // heandleDetailMiniHub
  const heandleDetailMiniHub = (e, id) => {
    e.stopPropagation();
    dispatch(changeIdMiniHub(id));
    dispatch(changePageSubHub("DetailMiniHub"));
  };

  return (
    <Grid container columns={12} spacing={2} sx={{ mt: 0.5, height: "86vh" }}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          mt: "10px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <BackButtonHub onClick={() => dispatch(changePageSubHub("subHub"))} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600, color: "#9E9D9D" }}
              onClick={() => dispatch(changePageSubHub(""))}
            >
              Master Hub
            </Typography>
            <ChevronRightIcon sx={{ color: "#9E9D9D", fontSize: "14px" }} />

            <Typography
              sx={{ fontSize: "14px", fontWeight: 600, color: "#9E9D9D" }}
              onClick={() => dispatch(changePageSubHub("subHub"))}
            >
              {masterHubName}
            </Typography>
            <ChevronRightIcon sx={{ color: "#9E9D9D", fontSize: "14px" }} />
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600, color: "#000000" }}
            >
              {subHubName}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Master Comparison */}
      <Grid item xs={12}>
        <CustomCard sx={{ p: 2 }}>
          <Typography
            sx={{ color: "#000", fontSize: "10px", fontWeight: 600, mb: 2 }}
          >
            Hub Audit
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "8px",
                color: "#6B4BB4",
                fontWeight: 600,
                mb: 1,
              }}
            >
              SKU
            </Typography>
            <Box
              sx={{
                width: "16px",
                height: "16px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => setChartType(!chartType)}
            >
              {chartType ? (
                <ToLineChart stroke="#51B15C" />
              ) : (
                <ToBarChart stroke="#51B15C" />
              )}
            </Box>
            <Box></Box>
          </Box>
          {chartType ? (
            <CustomBarChart datas={datas} color={color} />
          ) : (
            <CustomLineChart datas={datas} color={color} />
          )}
        </CustomCard>
      </Grid>

      {/* table grafik */}
      <Grid item xs={12}>
        <CustomTableMobile
          rootValue={datas?.labels}
          datas={datas.data}
          color={color}
        />
      </Grid>

      {/* List MiniHub */}
      <Grid item xs={12}>
        <CustomCard sx={{ padding: "16px" }}>
          <Grid container columns={12} spacing={2}>
            <Grid item xs={3}>
              <Typography
                sx={{
                  color: " #000000",
                  display: "inline-flex",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                Mini Hub List
              </Typography>
            </Grid>

            {/* Search */}
            <Grid item xs={6}>
              <TextField
                placeholder="Search"
                size="small"
                value={Search}
                onChange={(event) => {
                  setSearch(event.target.value);
                  debounceOnChange(event.target.value);
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    paddingLeft: "6px",
                    fontSize: "8px",
                  },
                  bgcolor: "#FAFAFA",
                  minHeight: "22px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ width: "12px" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* Toolbar */}
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                pt: "10px",
              }}
            >
              {toolbar}
            </Grid>

            {/* Map */}
            {isLoadingMiniHub ? (
              <Grid item xs={12}>
                <Box sx={{ textAlign: "center", p: 4 }}>
                  <CircularProgress size={24} />
                </Box>
              </Grid>
            ) : miniHubList.length === 0 ? (
              <Grid
                container
                columns={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#9E9D9D",
                  pt: "8px",
                  p: 5,
                }}
              >
                <Typography sx={{ fontSize: "12px" }}>data no found</Typography>
              </Grid>
            ) : (
              miniHubList?.map((item, idx) => (
                <Grid item xs={6} key={idx}>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "10px",
                      borderRadius: "8px",
                      background: "#FAFAFA",
                    }}
                  >
                    <Grid container columns={12} spacing={1}>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            alignContent: "center",
                          }}
                          onClick={(e) => heandleDetailMiniHub(e, item?.id)}
                        >
                          <Box
                            component="img"
                            src={
                              process.env.REACT_APP_API_IMAGE_BASE_URL +
                              "/" +
                              item?.images
                            }
                            sx={{
                              width: "28px",
                              height: "28px",
                              borderRadius: "8px",
                            }}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-around",
                              height: "28px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "8px",
                                display: "flex",
                                fontWeight: 600,
                              }}
                            >
                              {item?.subsidiary}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "10px", fontWeight: 600 }}
                            >
                              {item?.name}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                            alignItems: "center",
                          }}
                        >
                          <Box>
                            <UserIcon />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-around",
                              height: "28px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "8px",
                                display: "flex",
                                fontWeight: 600,
                              }}
                            >
                              {item?.picName}
                            </Typography>
                          </Box>

                          <Box>
                            <Telephone />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-around",
                              height: "28px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "8px",
                                display: "flex",
                                fontWeight: 600,
                              }}
                            >
                              {item?.picPhone}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))
            )}
          </Grid>
        </CustomCard>
      </Grid>
    </Grid>
  );
};

export default MiniHub;
