import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CustomDialog from '../../../../../components/shared/CustomDialog';
import {
  ChevronRightIcon,
  CloseIcon,
  KeyboardArrowDownIcon,
} from '../../../../../assets/Icons';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeCategoryStock,
  changeHubs,
  changeLocationStock,
  changeMasterHubId,
  changeMiniHubId,
  changeSubHubId,
  changeSubsidiaryIdStock,
  getAllFixHubsStock,
  getSubsidiaryIdStock,
} from '../../../../../store/stock/stock';
import Subsidiary from '../../../../../components/shared/Subsidiary';
import debounce from 'lodash.debounce';
import { getAllHubs, getCategory, locationHub } from '../../../../../api/hubs';
import CustomButton from '../../../../../components/shared/CustomButton';
import CustomCard from '../../../../../components/shared/CustomCard';
import SelectHubStock from './SelectHub';

const DialogFilterStock = ({ isFilter, setIsFilter }) => {
  const dispatch = useDispatch();
  const [filterSubsidiaryArray, setFilterSubsidiaryArray] = useState([]);
  const subsidaryId = useSelector(getSubsidiaryIdStock);

  //! hubs
  const [masterHubData, setMasterHub] = useState([]);
  const [hubChoose, setHubChoose] = useState(null);
  const [hubSearch, setHubSearch] = useState('');
  const [hubSearchDebounce, setHubSearchDebounce] = useState('');
  const [hubId, setHubId] = useState();
  const [isSelectHub, setIsSelectHub] = useState(false);
  const dataHubs = useSelector(getAllFixHubsStock);

  // ! location
  const [location, setLocation] = useState([]);
  const [locationChoose, setLocationChoose] = useState('');

  // ! category
  const [category, setCategory] = useState([]);
  const [categoryChoose, setCategoryChoose] = useState('');

  // ! fetch hubs
  const fetchMasterHub = async (params) => {
    try {
      const {
        data: {
          data: { hubs },
        },
      } = await getAllHubs(params);
      setMasterHub(hubs);
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  useEffect(() => {
    let params = {};
    params.subsidiaryId = subsidaryId;
    params.search = hubSearchDebounce;
    params.page = 1;
    params.pageSize = 5;
    fetchMasterHub(params);
  }, [subsidaryId, hubSearchDebounce]);

  // ! fetch location

  const fetchLocation = async (params) => {
    try {
      const {
        data: { data },
      } = await locationHub(params);
      const locations = data?.data.map((item) => ({
        id: item?.id,
        label: item?.name,
      }));
      setLocation(locations);
    } catch (err) {
      console.error(err);
    }
  };
  
  useEffect(() => {
    let params = {};
    params.id = 4;
    params.resource = 'hub';
    fetchLocation(params);
  }, []);

  //! fetch category
  const fetchCategory = async (params) => {
    try {
      const {
        data: { data },
      } = await getCategory(params);
      const cats = data?.categories.map((item) => ({
        id: item?.idKategori,
        label: item?.namaKategori,
      }));
      setCategory(cats);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    let params = {
      subsidiaryId: subsidaryId,
    };
    fetchCategory(params);
  }, [subsidaryId]);

  const hubSearchInDebounce = useRef(
    debounce((value) => {
      setHubSearchDebounce(value);
    }, 1000)
  ).current;

  // ! handler
  const handleClearHub = () => {
    dispatch(changeHubs([]));
  };

  const handleSaveFilter = () => {
    filterSubsidiaryArray.length !== 0 &&
      dispatch(changeSubsidiaryIdStock(filterSubsidiaryArray));
    categoryChoose !== '' &&
      dispatch(changeCategoryStock(categoryChoose?.label));
    locationChoose !== '' &&
      dispatch(changeLocationStock(locationChoose?.label));
    setIsFilter(false);
  };
  const handleResetFilter = () => {
    dispatch(changeSubsidiaryIdStock());
    dispatch(changeMasterHubId([]));
    dispatch(changeSubHubId([]));
    dispatch(changeMiniHubId([]));
    dispatch(changeCategoryStock());
    dispatch(changeLocationStock());
    dispatch(changeHubs([]));
    setCategoryChoose('');
    setLocationChoose('');

    // setIsFilter(false);
  };

  return (
    <CustomDialog
      isOpen={isFilter}
      setIsOpen={setIsFilter}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
        },
      }}
    >
      <DialogTitle
        sx={{
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px 12px 0px 0px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 600,
              color: '#000000',
            }}
            component="span"
          >
            Filter
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setIsFilter(!isFilter)}
            sx={{
              color: '#323232',
              p: 0,
            }}
          >
            <CloseIcon sx={{ fontSize: '1.25rem' }} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        {/* subsidiary */}
        <Box sx={{ display: 'flex', flexFlow: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 2,
              pb: 1,
            }}
          >
            <Typography
              sx={{ fontSize: '16px', fontWeight: 500, color: '#000000' }}
            >
              Status
            </Typography>
            {/* <Typography
              sx={{ fontSize: '14px', color: '#F33A3A' }}
              onClick={() => setFilterSubsidiaryArray([])}
            >
              Clear
            </Typography> */}
          </Box>
          <Subsidiary
            subsidiaryChecked={filterSubsidiaryArray}
            setSubsidiaryChecked={setFilterSubsidiaryArray}
          />
        </Box>

        {/* hub */}
        {/* <Box sx={{ display: 'flex', flexFlow: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 2,
            }}
          >
            <Typography
              sx={{ fontSize: '16px', fontWeight: 500, color: '#000000' }}
            >
              Status
            </Typography>
            <Typography
              sx={{ fontSize: '14px', color: '#F33A3A' }}
              onClick={() => changeSubsidiaryIdStock([])}
            >
              Clear
            </Typography>
          </Box>
          <Autocomplete
            sx={{ width: '100%' }}
            options={masterHubData}
            value={hubChoose}
            inputValue={hubSearch}
            getOptionLabel={(option) => option.namaToko}
            onChange={(_, newValue) => {
              setHubChoose(newValue);
              setHubId(newValue.idToko);
            }}
            onInputChange={(_, newInputValue) => {
              setHubSearch(newInputValue);
              hubSearchInDebounce(newInputValue);
            }}
            renderOption={(props, option) => (
              <li key={option.idToko} {...props}>
                {option.namaToko}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="master hubs"
                sx={{ bgcolor: '#fafafa', borderRadius: '5px' }}
              />
            )}
          />
        </Box> */}

        <Box sx={{ display: 'flex', flexFlow: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 2,
              pb: 1,
              mt: 1,
            }}
          >
            <Typography
              sx={{ fontSize: '16px', fontWeight: 500, color: '#000000' }}
            >
              Hub
            </Typography>
            <Typography
              sx={{ fontSize: '14px', color: '#F33A3A', cursor: 'pointer' }}
              onClick={handleClearHub}
            >
              Clear
            </Typography>
          </Box>
          <CustomCard
            sx={{ padding: '12px 14px', cursor: 'pointer' }}
            onClick={() => setIsSelectHub(true)}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography
                  sx={{ fontSize: '16px', fontWeight: 500, color: '#000000' }}
                >
                  Hub*
                </Typography>
                <Box
                  sx={{
                    mt: '10px',
                  }}
                >
                  {dataHubs.length === 0 ? (
                    <Typography
                      sx={{
                        color: '#9E9D9D',
                        fontSize: '14px',
                        fontWeight: 500,
                      }}
                    >
                      Click to Select hub, sub and mini
                    </Typography>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        color: 'black',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <Typography sx={{ fontSize: '12px' }}>
                        {dataHubs[0].namaToko}
                      </Typography>
                      {dataHubs[1] !== null ? (
                        <>
                          <ChevronRightIcon sx={{ fontSize: '12px' }} />
                          <Typography sx={{ fontSize: '12px' }}>
                            {dataHubs[1].name}
                          </Typography>
                        </>
                      ) : (
                        '_'
                      )}
                      {dataHubs[2] !== null ? (
                        <>
                          <ChevronRightIcon sx={{ fontSize: '12px' }} />
                          <Typography sx={{ fontSize: '12px' }}>
                            {dataHubs[2].name}
                          </Typography>
                        </>
                      ) : (
                        '_'
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
              <KeyboardArrowDownIcon />
            </Box>
          </CustomCard>
        </Box>
        {/* dialog hub */}
        <Dialog
          fullWidth
          open={isSelectHub}
          onClose={() => {
            setIsSelectHub(false);
          }}
        >
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box>Select Hub</Box>
            <CloseIcon
              sx={{ cursor: 'pointer' }}
              onClick={() => setIsSelectHub(false)}
            />
          </DialogTitle>
          <DialogContent dividers>
            <SelectHubStock setIsSelectHub={setIsSelectHub} />
          </DialogContent>
        </Dialog>

        {/* location  */}
        <Box sx={{ display: 'flex', flexFlow: 'column' }}>
          <Box
            sx={{
              p: 2,
              pb: 1,
              mt: 1,
            }}
          >
            <Typography
              sx={{ fontSize: '16px', fontWeight: 500, color: '#000000' }}
            >
              Location
            </Typography>
          </Box>
          <Autocomplete
            sx={{ width: '100%' }}
            options={location}
            value={locationChoose}
            onChange={(_, newValue) => {
              setLocationChoose(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="master hubs"
                sx={{ bgcolor: '#fafafa', borderRadius: '5px' }}
              />
            )}
          />
        </Box>

        {/* category  */}
        <Box sx={{ display: 'flex', flexFlow: 'column' }}>
          <Box
            sx={{
              p: 2,
              pb: 1,
              mt: 1,
            }}
          >
            <Typography
              sx={{ fontSize: '16px', fontWeight: 500, color: '#000000' }}
            >
              Category
            </Typography>
          </Box>
          <Autocomplete
            sx={{ width: '100%' }}
            options={category}
            value={categoryChoose}
            onChange={(_, newValue) => {
              setCategoryChoose(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="master hubs"
                sx={{ bgcolor: '#fafafa', borderRadius: '5px' }}
              />
            )}
          />
        </Box>

        {/* button event */}
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}
        >
          <CustomButton
            sx={{
              color: '#51B15C',
              fontWeight: 600,
              fontSize: '0.875rem',
              px: 3,
              py: 1,
            }}
            onClick={handleResetFilter}
          >
            Reset
          </CustomButton>
          <CustomButton
            sx={{
              bgcolor: '#51B15C',
              color: '#ffffff',
              fontWeight: 600,
              fontSize: '0.875rem',
              borderRadius: '8px',
              '&:hover': {
                bgcolor: '#51B15C',
                color: '#ffffff',
              },
              px: 4,
              py: 1,
            }}
            onClick={handleSaveFilter}
          >
            Save
          </CustomButton>
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};
export default DialogFilterStock;
