import { createSlice } from '@reduxjs/toolkit';

export const detailSupplierState = createSlice({
  name: 'detailSupplier',
  initialState: {
    idSupplier: 0,
    dataDetail: [],
    status: false,
    previewAddress: false,
    isEditSupplier: false,
  },
  reducers: {
    loadDataDetail: (state, action) => {
      const { id, data, status } = action.payload;
      state.idSupplier = id;
      state.dataDetail = data;
      state.status = status === 'active' ? true : false;
    },
    changeIsPreviewAddress: (state, action) => {
      state.previewAddress = action.payload;
    },
    changeStatusPublish: (state, action) => {
      state.status = action.payload;
    },
    changeIsEditSupplier: (state, action) => {
      state.isEditSupplier = action.payload;
    },
  },
});

export const {
  loadDataDetail,
  changeIsPreviewAddress,
  changeStatusPublish,
  changeIsEditSupplier,
} = detailSupplierState.actions;

export const dataStateDetailSupplier = (state) => state.detailSupplier;
export const getIsEditSupplier = (state) => state.detailSupplier.isEditSupplier;

export default detailSupplierState.reducer;
