import { createSlice } from '@reduxjs/toolkit';

export const purchaseAddFinanceState = createSlice({
  name: 'purchaseAddFinance',
  initialState: {
    idSupplier: 0,
    idTransaksi: 0,
    idRequest: 0,
    supplierChoose: null,
    paymentMethodChoose: null,
    deskripsi: '',
    products: [],
  },
  reducers: {
    goToPurchaseAdd: (state, action) => {
      const { products, description, idRequest } = action.payload;

      const collect = products.map((list) => {
        return {
          idProduk: list?.product?.idProduk,
          name: list?.product?.namaProduk,
          qty: list?.qty,
          unit: list?.unit,
          purchasePrice: 0,
        };
      });
      state.products = collect;
      // console.log(collect);
      state.deskripsi = description;
      state.idRequest = idRequest;
    },
    changePrice: (state, action) => {
      const { index, value } = action.payload;
      let collect = state.products;
      collect[index].purchasePrice = value;
      state.products = collect;
    },
    changeValuePruchaseAdd: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});

export const { goToPurchaseAdd, changePrice, changeValuePruchaseAdd } =
  purchaseAddFinanceState.actions;
export const getAllPurchaseAddFinance = (state) => state.purchaseAddFinance;
export default purchaseAddFinanceState.reducer;
