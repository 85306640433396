import React, { useState, useRef } from 'react';
import {
  useTheme,
  OutlinedInput,
  Modal,
  Box,
  Button,
  Typography,
} from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import dayjs from 'dayjs';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const CustomDateRange = ({
  dateRange,
  setDateRange,
  saveDateRange,
  setSaveDateRange,
  fullWidth = true,
  iconSize,
  ...props
}) => {
  const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false);
  const dateRangeRef = useRef(null);
  const theme = useTheme();

  return (
    <>
      <OutlinedInput
        startAdornment={
          <CalendarToday
            style={{
              fontSize: iconSize,
              color: theme.palette.primary.main,
            }}
          />
        }
        readOnly
        value={`${dayjs(saveDateRange[0].startDate).format(
          'DD/MMM/YY'
        )} - ${dayjs(saveDateRange[0].endDate).format('DD/MMM/YY')}`}
        onClick={() => {
          setIsDatePickerModalOpen(true);
        }}
        fullWidth={fullWidth}
        {...props}
      />
      <Modal
        open={isDatePickerModalOpen}
        onClose={() => setIsDatePickerModalOpen(false)}
      >
        <Box sx={{ ...style, width: 400 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              sx={{
                maxWidth: '444px',
                width: 1,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box ref={dateRangeRef}>
                <DateRange
                  ranges={dateRange}
                  onChange={(item) => {
                    setDateRange([item.selection]);
                  }}
                  rangeColors={[theme.palette.primary.main]}
                />
              </Box>
            </Box>
          </Box>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              setSaveDateRange(dateRange);
              setIsDatePickerModalOpen(!isDatePickerModalOpen);
            }}
            sx={{ p: '12px 16px' }}
          >
            <Typography sx={{ fontSize: '0.875rem', lineHeight: 1 }}>
              Submit
            </Typography>
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default CustomDateRange;
