import { Box, Typography } from '@mui/material';
import BackButton from '../../../../../../../components/shared/BackButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePage,
  getAllPurchaseRouting,
} from '../../../../../../../store/Routing/purchaseRouting';
import { ChevronRightIcon } from '../../../../../../../assets/Icons';

const BackButtonQrCodeMobile = ({ roleUser }) => {
  const dispatch = useDispatch();
  const { idPurchase } = useSelector(getAllPurchaseRouting);

  const handleBackButton = () => {
    if (roleUser == 5) {
      dispatch(changePage('purchaseProcessDetailSupplier'));
    } else {
      dispatch(changePage('detail'));
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 600, color: '#9E9D9D' }}
          >
            Purchase Details
          </Typography>
          <ChevronRightIcon sx={{ color: '#9E9D9D' }} />
          <Typography
            sx={{ fontSize: '14px', fontWeight: 600, color: '#000000' }}
          >
            Qr Code
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default BackButtonQrCodeMobile;
