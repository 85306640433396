import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BackButtonHub from "../../../../../../../../components/shared/BackButtonHub";
import {
  AddIcon,
  CancelIcon,
  CloseIcon,
  ErrorOutlineIcon,
  EyeIcon,
  ImageOutlinedIcon,
  KeyboardArrowDownIcon,
  VideocamOutlinedIcon,
} from "../../../../../../../../assets/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  changePageSubHub,
  setNotifMasterHub,
} from "../../../../../../../../store/Routing/hubs";
import {
  fetchSubsidiaryList,
  getSubsidiaryList,
} from "../../../../../../../../store/subsidiary/subsidiary";
import {
  changeImagePreview,
  changeMiniHubName,
  changePicChoose,
  changePicPhone,
  changePicSearch,
  changePicSearchDebounce,
  changeStatus,
  changeSubsidiaryId,
  changeVideoPreview,
  clickIsPreviewImage,
  clickIsVideoPreview,
  deleteMiniHubImage,
  deleteVideoPreview,
  getAddressFix,
  getImagePreviewMiniHubAdd,
  getIsImagePreviewMiniHubAdd,
  getIsMaxSizeVideoMiniHubAdd,
  getIsVideoPreviewMiniHubAdd,
  getMiniHubAddAllState,
  getMiniHubImage,
  getMinihubName,
  getPicChooseMiniHubAdd,
  getPicPhoneMiniHubAdd,
  getPicSearchDebounceMiniHubAdd,
  getPlaceGetByName,
  getSubHubId,
  getSubsidiaryIdMiniHub,
  getVideoMiniHubAdd,
  getVideoPreviewMiniHubAdd,
  getstatusMiniHubAdd,
  inputMiniHubImage,
  inputVideoPreview,
} from "../../../../../../../../store/hub/miniHub/miniHubAdd";
import CustomInput from "../../../../../../../../components/shared/CustomInput";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useTheme } from "@emotion/react";
import { getIsMaxSizeImageEdit } from "../../../../../../../../store/hub/miniHub/miniHubEdit";
import {
  fetchPic,
  getPicList,
  getPicLoading,
} from "../../../../../../../../store/pic/pic";
import debounce from "lodash.debounce";
import { useRef } from "react";
import CustomCard from "../../../../../../../../components/shared/CustomCard";
import { Map } from "pigeon-maps";
import AddressMapAddMiniHubMobile from "./AddressMap/AddressMapAddMiniHubMobile";
import CustomAntSwitch from "../../../../../../../../components/shared/CustomAntSwitch";
import CustomButton from "../../../../../../../../components/shared/CustomButton";
import { addNewMiniHub } from "../../../../../../../../api/hubs";

const AddMiniHubMobile = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const {
    isUploadImage,
    isUploadVideo,
    addressFix,
    coordinateFix,
    mapInputType,
    placeGetByName,
    placeGetByCoordinate,
  } = useSelector(getMiniHubAddAllState);
  const subHubId = useSelector(getSubHubId);
  const subsidiaryList = useSelector(getSubsidiaryList);
  const subsidiaryId = useSelector(getSubsidiaryIdMiniHub);
  const [newSubsidiary, setNewSubsidiary] = useState(null);
  const name = useSelector(getMinihubName);

  //image
  const image = useSelector(getMiniHubImage);
  const maxSizeSizeImage = useSelector(getIsMaxSizeImageEdit);
  const isPhotoPreview = useSelector(getIsImagePreviewMiniHubAdd);
  const linkPhotoPreview = useSelector(getImagePreviewMiniHubAdd);

  //video
  const video = useSelector(getVideoMiniHubAdd);
  const isMaxSizeVideo = useSelector(getIsMaxSizeVideoMiniHubAdd);
  const linkVideoPreview = useSelector(getVideoPreviewMiniHubAdd);
  const isVideoPreview = useSelector(getIsVideoPreviewMiniHubAdd);

  //Pic List
  const picList = useSelector(getPicList);
  const picLoading = useSelector(getPicLoading);
  const picChoose = useSelector(getPicChooseMiniHubAdd);
  const picSearch = useSelector(getPicSearchDebounceMiniHubAdd);
  const picSearchDebounce = useSelector(getPicSearchDebounceMiniHubAdd);
  const picPhoneNumber = useSelector(getPicPhoneMiniHubAdd);

  // address
  const address = useSelector(getAddressFix);

  //status Publick
  const status = useSelector(getstatusMiniHubAdd);

  // Map Adress
  const [isMapOpen, setIsMapOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchSubsidiaryList());
  }, []);

  useEffect(() => {
    dispatch(fetchPic(picSearchDebounce));
  }, [picSearchDebounce]);

  const picSearchInDebounce = useRef(
    debounce((value) => {
      dispatch(changePicSearchDebounce(value));
    }, 1000)
  ).current;

  // Validasi AddMiniHubMobile
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);
  useEffect(() => {
    newSubsidiary !== null &&
    name.length >= 5 &&
    image.length !== 0 &&
    picChoose !== null &&
    picPhoneNumber !== 0 &&
    addressFix !== ""
      ? setIsSubmitAvailable(true)
      : setIsSubmitAvailable(false);
  }, [name, subsidiaryId, image, video, picChoose, picPhoneNumber, addressFix]);

  useEffect(() => {
    picChoose !== null
      ? dispatch(changePicPhone(picChoose.telpon))
      : dispatch(changePicPhone(""));
  }, [picChoose]);

  const handleSubmit = () => {
    const params = {};
    params.subsidiaryId = newSubsidiary?.id;
    params.name = name;
    params.subHubId = subHubId;
    params.idKustomer = picChoose?.idKustomer;
    params.picPhoneNumber = picPhoneNumber;
    params.status = status ? "active" : "inactive";
    params.address = addressFix === "" ? address : addressFix;
    params.lat = coordinateFix[0].toString();
    params.lon = coordinateFix[1].toString();
    params.typeInput = mapInputType;
    params.cityDistrict = getPlaceGetByName?.formatted;

    if (image.length !== 0) {
      params["images"] = image.join(",");
    }
    if (video.length !== 0) {
      params["videos"] = video.join(",");
    }

    if (mapInputType === "manual") {
      const { formatted: citydistric } = placeGetByName;
      params["cityDistrict"] = citydistric;
    } else {
      if (!placeGetByCoordinate?.statusCode) {
        const { formatted: citydistric } = placeGetByCoordinate;
        params["cityDistrict"] = citydistric;
      }
    }

    const createHub = async () => {
      setIsLoadingSubmit(true);
      try {
        const {
          data: { data },
        } = await addNewMiniHub(params);
        setIsLoadingSubmit(false);
        dispatch(changePageSubHub("MiniHub"));
        dispatch(
          setNotifMasterHub({
            key: "addHubs",
            text: "hubs success add",
          })
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingSubmit(false);
      }
    };

    createHub();
  };

  return (
    <Box
      style={{
        position: "relative",
        maxHeight: "100vh",
      }}
    >
      <Grid container columns={12} spacing={3}>
        {/* Back Button AddMiniHub */}
        <Grid item xs={12}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "16px",
            }}
          >
            <BackButtonHub
              onClick={() => dispatch(changePageSubHub("MiniHub"))}
            />
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginLeft: "12px",
              }}
            >
              <Typography
                style={{ fontSize: "14px", fontWeight: 600, color: "#000" }}
              >
                Add Mini Hub
              </Typography>
            </Box>
            <Box style={{ display: "flex", alignItems: "center", gap: 1 }}>
              <EyeIcon />
              <Typography
                style={{ fontSize: "12px", fontWeight: 600, color: "#51B15C" }}
              >
                Preview
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/* Subsidiary AddMiniHub */}
        <Grid item xs={12}>
          <Autocomplete
            options={subsidiaryList}
            value={newSubsidiary}
            getOptionLabel={(option) => option.name}
            onChange={(_, newValue) => {
              setNewSubsidiary(newValue);
              dispatch(changeSubsidiaryId(newValue?.id));
            }}
            renderInput={(params) => (
              <TextField
                label="Subsidiary"
                variant="filled"
                {...params}
                style={{
                  bgcolor: "#fafafa",
                  borderRadius: "5px",
                  fontSize: "14px",
                  color: "#9D9D9D",
                }}
              />
            )}
          />
        </Grid>
        {/* Name AddMiniHub */}
        <Grid item xs={12}>
          <CustomInput
            label="Name"
            variant="filled"
            style={{ width: "100%", bgcolor: "#FAFAFA", fontSize: "14px" }}
            value={name}
            onChange={(event) => {
              if (event.target.value.length <= 30) {
                dispatch(changeMiniHubName(event.target.value));
              }
            }}
          />
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "10px",
                fontWeight: "400",
                color:
                  name.length > 0 && name.length < 5 ? "#F33A3A" : "#9E9D9D",
                mt: "4px",
              }}
            >
              Enter at least 5 character
            </Typography>
            <Typography style={{ fontSize: "10px", fontWeight: "400" }}>
              {name.length}/30
            </Typography>
          </Box>
        </Grid>
        {/* Photo AddMiniHub */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              pb: `${image.length === 0 ? "16px" : "9px"}`,
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              Photo
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#9E9D9D",
              }}
            >
              ( max size : 2MB, max file : 3 )
            </Typography>
            {maxSizeSizeImage && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ErrorOutlineIcon
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: theme.palette.warning.main,
                    mr: "4px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: theme.palette.warning.main,
                  }}
                >
                  The photo can't be more than 2MB
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {image.length === 0 && (
                <Box
                  sx={{
                    height: "54px",
                    width: "54px",
                    aspectRatio: "1/1",
                    borderRadius: "8px",
                    bgcolor: "#F5F5F5",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderStyle: "dashed",
                    borderColor: "#9E9D9D",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    document.getElementById("input_img").click();
                  }}
                >
                  <ImageOutlinedIcon
                    sx={{ color: "#9E9D9D", fontSize: "28px" }}
                  />
                </Box>
              )}
              {image.map((input, index) => (
                <Box
                  sx={{
                    position: "relative",
                    overflow: "visible",
                    padding: "7px",
                  }}
                  key={index}
                >
                  <CancelIcon
                    sx={{
                      position: "absolute",
                      fontSize: "20px",
                      top: 1,
                      right: 1,
                      zIndex: +1,
                      cursor: "pointer",
                    }}
                    onClick={() => dispatch(deleteMiniHubImage(input))}
                  />
                  <Box
                    component="img"
                    src={process.env.REACT_APP_API_IMAGE_BASE_URL + "/" + input}
                    sx={{
                      height: "54px",
                      width: "54px",
                      aspectRatio: "1/1",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      dispatch(clickIsPreviewImage());
                      dispatch(changeImagePreview(input));
                    }}
                  />
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                mb: "7px",
                ml: "20px",
                alignItems: "center",
              }}
            >
              {image.length < 3 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    document.getElementById("input_img").click();
                  }}
                >
                  <input
                    accept="image/*"
                    id="input_img"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => dispatch(inputMiniHubImage(e))}
                  />
                  {!isUploadImage ? (
                    <>
                      <AddIcon />
                      <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                        Add More
                      </Typography>
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <CircularProgress size={16} />
                      <Typography> uploading...</Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        {/* Video AddMinuHub */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              pb: `${video.length === 0 ? "16px" : "9px"}`,
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
              Video
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#9E9D9D",
              }}
            >
              ( max size : 100MB, max file : 3 )
            </Typography>
            {isMaxSizeVideo && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ErrorOutlineIcon
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: theme.palette.warning.main,
                    mr: "4px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "8px",
                    fontWeight: "500",
                    color: theme.palette.warning.main,
                  }}
                >
                  The video is too big
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {video.length === 0 && (
                <Box
                  sx={{
                    height: "54px",
                    width: "54px",
                    aspectRatio: "1/1",
                    borderRadius: "8px",
                    bgcolor: "#F5F5F5",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderStyle: "dashed",
                    borderColor: "#9E9D9D",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    document.getElementById("inputMenu_video").click();
                  }}
                >
                  <VideocamOutlinedIcon
                    sx={{ color: "#9E9D9D", fontSize: "28px" }}
                  />
                </Box>
              )}
              {video.map((input, index) => (
                <Box
                  sx={{
                    position: "relative",
                    overflow: "visible",
                    padding: "7px",
                  }}
                  key={index}
                >
                  <CancelIcon
                    sx={{
                      position: "absolute",
                      fontSize: "20px",
                      top: 1,
                      right: 1,
                      zIndex: +1,
                      cursor: "pointer",
                    }}
                    onClick={() => dispatch(deleteVideoPreview(input))}
                  />
                  <Box
                    onClick={() => {
                      dispatch(clickIsVideoPreview());
                      dispatch(changeVideoPreview(input));
                    }}
                  >
                    <video
                      style={{
                        maxHeight: "54px",
                        maxWidth: "54px",
                        aspectRatio: "1/1",
                        borderRadius: "8px",
                        cursor: "pointer",
                        backgroundColor: "black",
                      }}
                    >
                      <source
                        src={
                          process.env.REACT_APP_API_IMAGE_BASE_URL +
                          "/" +
                          input +
                          "#t=0.5"
                        }
                      />
                    </video>
                  </Box>
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                mb: "7px",
                ml: "20px",
                alignItems: "center",
              }}
            >
              {video.length < 3 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    document.getElementById("inputMenu_video").click();
                  }}
                >
                  <input
                    id="inputMenu_video"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => dispatch(inputVideoPreview(e))}
                  />
                  {!isUploadVideo ? (
                    <>
                      <AddIcon />
                      <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                        Add More
                      </Typography>
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <CircularProgress size={16} />
                      <Typography> uploading...</Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        {/* Pic AddMiniHub */}
        <Grid item xs={12}>
          <Autocomplete
            options={picList}
            getOptionLabel={(option) => option.namaLengkap}
            value={picChoose}
            onChange={(_, newValue) => {
              dispatch(changePicChoose(newValue));
            }}
            inputValue={picSearch}
            loading={picLoading}
            onInputChange={(_, newInputValue) => {
              dispatch(changePicSearch(newInputValue));
              picSearchInDebounce(newInputValue);
            }}
            renderOption={(props, option) => (
              <li key={option.idKustomer} {...props}>
                {option.namaLengkap}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="PIC*"
                variant="filled"
                sx={{ width: "100%", bgcolor: "#FAFAFA" }}
              />
            )}
          />
        </Grid>
        {/* Phone NumberAddMiniHub */}
        <Grid item xs={12}>
          <CustomInput
            disabled
            style={{ width: "100%", bgcolor: "#FAFAFA" }}
            type="number"
            value={picPhoneNumber}
            onChange={(event) => {
              dispatch(changePicPhone(event.target.value));
            }}
            label="Phone Number"
            variant="filled"
          />
        </Grid>
        {/* Adreess AddMiniHub */}
        <Grid item xs={12}>
          <CustomCard
            sx={{ p: "8px 16px", cursor: "pointer" }}
            onClick={() => setIsMapOpen(!isMapOpen)}
          >
            <Box
              sx={{
                display: "center",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box
                  sx={{
                    width: "59px",
                    height: "59px",
                    bgcolor: "gray",
                    borderRadius: "8px",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      bgcolor: "white",
                      width: "100%",
                      height: "100%",
                      zIndex: "+2",
                      opacity: 0.5,
                    }}
                  ></Box>
                  <Box
                    sx={{
                      maxHeight: "59px",
                      overflow: "hidden",
                      borderRadius: "8px",
                    }}
                  >
                    <Map
                      defaultCenter={[3.546958571415798, 98.6901696840363]}
                      height={130}
                      defaultZoom={17}
                      width={66}
                      style={{
                        borderRadius: "8px",
                      }}
                    ></Map>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      mb: "4px",
                      ml: "8px",
                    }}
                  >
                    Address
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.4,
                    }}
                  >
                    <LocationOnIcon color="primary" />
                    <Typography>
                      {address ? address : "Click to enter address"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <KeyboardArrowDownIcon />
            </Box>
          </CustomCard>
        </Grid>
        {/* Status Public */}
        <Grid item xs={12}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <Typography style={{ fontSize: "14px", fontWeight: 500 }}>
              Status
            </Typography>
            <CustomAntSwitch
              value={status}
              onChange={() => dispatch(changeStatus())}
            />
          </Box>
        </Grid>
      </Grid>

      {/* Bottom Submit */}
      <Box
        style={{
          width: "100%",
          background: "#FFF",
          // position: "sticky",
          bottom: 0,
          padding: "8px 0px",
          zIndex: 99,
        }}
      >
        <CustomButton
          variant="contained"
          style={{ width: "100%", height: "36px" }}
          onClick={handleSubmit}
          disabled={!isSubmitAvailable}
        >
          {isLoadingSubmit ? (
            <CircularProgress size={18} style={{ color: "#FFF" }} />
          ) : (
            <Typography
              style={{ fontSize: "12px", color: "white", fontWeight: 500 }}
            >
              Submit
            </Typography>
          )}
        </CustomButton>
      </Box>

      {/* ---------------Dialog------------------ */}
      {/*  Dialog PhotoAddMiniHub */}
      <Dialog
        open={isPhotoPreview}
        onClose={() => {
          dispatch(clickIsPreviewImage());
          dispatch(changeImagePreview(""));
        }}
      >
        <Box
          component="img"
          src={
            process.env.REACT_APP_API_IMAGE_BASE_URL + "/" + linkPhotoPreview
          }
          sx={{ maxWidth: "300px", maxHeight: "300px", aspectRation: "1/1" }}
        />
      </Dialog>

      {/* dialog VideoAddMiniHub */}

      <Dialog
        open={isVideoPreview}
        onClose={() => {
          dispatch(clickIsVideoPreview());
          dispatch(changeVideoPreview(""));
        }}
      >
        <Box sx={{ bgcolor: "black" }}>
          <video
            style={{
              maxWidth: "300px",
              maxHeight: "300px",
              backgroundColor: "black",
            }}
            controls
          >
            <source
              src={
                process.env.REACT_APP_API_IMAGE_BASE_URL +
                "/" +
                linkVideoPreview
              }
            />
          </video>
        </Box>
      </Dialog>

      {/* Dialog AddressAddMiniHub */}
      <Dialog
        open={isMapOpen}
        onClose={() => {
          setIsMapOpen(!isMapOpen);
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>Input Address</Box>
          <CloseIcon
            sx={{ cursor: "pointer" }}
            onClick={() => setIsMapOpen(false)}
          />
        </DialogTitle>
        <DialogContent dividers>
          <AddressMapAddMiniHubMobile setIsOpen={setIsMapOpen} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AddMiniHubMobile;
