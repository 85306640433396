import { createSlice } from '@reduxjs/toolkit';
import { getPurchaseRequestDetail } from '../../api/purchase';

export const DetailPurchaseDashboardState = createSlice({
  name: 'detaildashboard',
  initialState: {
    idPurchase: null,
    isLoadingDetail: false,
    dataDetail: null,
  },
  reducers: {
    changeIdPurchaseDetail: (state, action) => {
      state.idPurchase = action.payload;
    },
    changeIsLoadingDetail: (state, action) => {
      state.isLoadingDetail = action.payload;
    },
    LoadDataDetail: (state, action) => {
      state.dataDetail = action.payload;
    },
  },
});

export const { changeIdPurchaseDetail, changeIsLoadingDetail, LoadDataDetail } =
  DetailPurchaseDashboardState.actions;

export const getIdPurchaseDetail = (state) => state.detaildashboard.idPurchase;
export const getDataDetailPurchaseDashboard = (state) =>
  state.detaildashboard.dataDetail;
export const getLoadingDetailPurchase = (state) =>
  state.detaildashboard.isLoadingDetail;

export const getAllDataPurchaseDetail = (id) => (dispatch) => {
  const fetchData = async () => {
    dispatch(changeIsLoadingDetail(true));
    try {
      const {
        data: { data },
      } = await getPurchaseRequestDetail(id);
      dispatch(LoadDataDetail(data));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoadingDetail(false));
    }
  };

  fetchData();
};

export default DetailPurchaseDashboardState.reducer;
