import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import MasterHub from "../MasterHub/Desktop";
import DetailMasterHub from "../MasterHub/Mobile/Detail/DetailMasterHub";
import { getAllHubsRouting } from "../../../../../../store/Routing/hubs";
import AddMasterHubMobile from "../MasterHub/Mobile/Add/AddMasterHubMobile";
import NotifHub from "./NotifHub";
import SubHubMobile from "../SubHub/Mobile";
import DetailSubHub from "../SubHub/Mobile/Detail/DetailSubHub";
import AddSubHub from "../SubHub/Mobile/AddSubHub/AddSubHub";
import MiniHub from "../MiniHub/Mobile";
import DetailMiniHub from "../MiniHub/Mobile/DetaiMinilHub/DetailMiniHub";
import AddMiniHubMobile from "../MiniHub/Mobile/AddMiniHub/AddMiniHub";

const RoutingHub = () => {
  const { page } = useSelector(getAllHubsRouting);
  return (
    <Box>
      <Box>
        <NotifHub />
      </Box>
      {/* master hub */}
      {page === "" && <MasterHub />}
      {page === "addMasterHub" && <AddMasterHubMobile />}
      {page === "detailMasterHub" && <DetailMasterHub />}

      {/* subhub */}
      {page === "subHub" && <SubHubMobile />}
      {page === "detailSubHub" && <DetailSubHub />}
      {page === "AddSubHub" && <AddSubHub />}

      {/* MiniHub */}
      {page === "MiniHub" && <MiniHub />}
      {page === "DetailMiniHub" && <DetailMiniHub />}
      {page === "AddMiniHub" && <AddMiniHubMobile />}
    </Box>
  );
};

export default RoutingHub;
