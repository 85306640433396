import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import GlobalSearch from '../GlobalSearch';
import { ReactComponent as MRILogo } from '../../assets/logo-mri.svg';
import dayjs from 'dayjs';

const HeaderComponent = ({ featureName }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container columnSpacing={{ xs: 4 }} alignItems="center">
        <Grid
          item
          xs="auto"
          sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}
        >
          <Typography
            sx={{ color: '#000000', fontWeight: 600, fontSize: '1.25rem' }}
          >
            {featureName}
          </Typography>
          <Typography
            sx={{ color: '#9E9D9D', fontWeight: 500, fontSize: '0.75rem' }}
          >
            as at {dayjs().format('DD/MM/YY')},{' '}
            <Typography
              component="span"
              sx={{ color: '#000000', fontSize: '0.75rem', fontWeight: 600 }}
            >
              {dayjs().format('HH:mm')}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs>
          <GlobalSearch />
        </Grid>
        <Grid
          item
          xs="auto"
          //   sx={{
          //     display: 'flex',
          //     flexDirection: 'column',
          //     justifyContent: 'center',
          //     alignItems: 'center',
          //     gap: 0.2,
          //   }}
        >
          {/* <Typography sx={{ fontSize: '1rem', color: '#F1202C' }}>
          MRI.id
        </Typography> */}
          <MRILogo width="90px" />
          <Typography
            sx={{ fontSize: '0.75rem', textAlign: 'center', fontWeight: 600 }}
            color="primary"
          >
            by Alere
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeaderComponent;
