import { Box, Grid, Typography } from '@mui/material';
import BackButtonHub from '../../../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import { changePage } from '../../../../../../../../store/Routing/purchaseRouting';
import CustomCard from '../../../../../../../../components/shared/CustomCard';
import { useEffect, useState } from 'react';
import {
  changeIsEdit,
  getDataDetailPurchase,
  getDetailPurchase,
  getIdPurchase,
} from '../../../../../../../../store/purchase/purchaseAdmin/detailRequestPurchase';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { fetchSubsidiaryList } from '../../../../../../../../store/subsidiary/subsidiary';
import CustomButton from '../../../../../../../../components/shared/CustomButton';
import { goToPurchaseRequestEdit } from '../../../../../../../../store/purchase/purchaseAdmin/editPurchaseRequest';

const PurchaseRequestDetailMobile = () => {
  const dispatch = useDispatch();
  const idPurchase = useSelector(getIdPurchase);
  const dataDetailPurchase = useSelector(getDataDetailPurchase);

  //! get data detail purchase request
  useEffect(() => {
    dispatch(getDetailPurchase(idPurchase));
    dispatch(fetchSubsidiaryList());
  }, [idPurchase]);

  //! handle button

  // handle delete
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const handleDelete = () => {
    setIsDeleteConfirm(true);
  };

  // handle edit
  const handleToEdit = () => {
    dispatch(goToPurchaseRequestEdit(dataDetailPurchase));

    dispatch(changeIsEdit(true));
  };
  return (
    <Box>
      {/* back button */}
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <BackButtonHub
          onClick={() => {
            dispatch(changePage('request'));
          }}
        />
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Purchase request detail
        </Typography>
      </Box>

      <Grid container columns={12} spacing={3} sx={{ mt: 2 }}>
        {/* //* Subsidiary */}
        <Grid item xs={12}>
          <CustomCard sx={{ padding: '16px 8px', borderRadius: '8px ' }}>
            <Typography sx={{ fontSize: '12px', color: '#9E9E9E', mb: '8px' }}>
              Subsidiary
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
              {dataDetailPurchase?.subsidiary?.name}
            </Typography>
          </CustomCard>
        </Grid>

        {/* hub */}
        <Grid item xs={12}>
          <CustomCard sx={{ padding: '16px 14px' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography
                  sx={{ fontSize: '12px', color: '#9E9E9E', mb: '8px' }}
                >
                  Hub*
                </Typography>
                <Box
                  sx={{
                    color: '#9E9D9D',
                    mt: '4px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      color: 'black',
                      alignItems: 'center',
                      gap: 1,
                      mt: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: '12px' }}>
                      {dataDetailPurchase?.hub?.namaToko}
                    </Typography>
                    <ChevronRightIcon sx={{ fontSize: '12px' }} />
                    <Typography sx={{ fontSize: '12px' }}>
                      {dataDetailPurchase?.subHub?.name}
                    </Typography>
                    {dataDetailPurchase !== '' ? (
                      <>
                        <ChevronRightIcon sx={{ fontSize: '12px' }} />
                        <Typography sx={{ fontSize: '12px' }}>
                          {dataDetailPurchase?.miniHub?.name}
                        </Typography>
                      </>
                    ) : (
                      '_'
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </CustomCard>
        </Grid>

        {/* description */}
        <Grid item xs={12}>
          <CustomCard sx={{ padding: '16px 8px', borderRadius: '8px ' }}>
            <Typography sx={{ fontSize: '12px', color: '#9E9E9E', mb: '8px' }}>
              Description*
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
              {dataDetailPurchase?.keterangan}
            </Typography>
          </CustomCard>
        </Grid>

        {/* items */}
        <Grid item xs={12} sx={{ mt: 2 }}>
          <CustomCard>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: '20px',
                boxShadow: '0px 1px 5px #ccc',
              }}
            >
              <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                Item(s)
              </Typography>
            </Box>

            <Box>
              <Grid container columns={12} spacing={4} sx={{ p: '30px 20px' }}>
                {dataDetailPurchase?.details.map((item, index) => (
                  <Grid item xs={12} sx={{ display: 'flex' }}>
                    <Grid container columns={12} gap={2}>
                      {/* product */}
                      <Grid item xs={7}>
                        <CustomCard
                          sx={{ padding: '16px 8px', borderRadius: '8px ' }}
                        >
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#9E9E9E',
                              mb: '8px',
                            }}
                          >
                            Product
                          </Typography>
                          <Typography
                            sx={{ fontSize: '14px', fontWeight: 500 }}
                          >
                            {item?.product?.namaProduk}
                          </Typography>
                        </CustomCard>
                      </Grid>

                      {/* qty */}
                      <Grid item xs={4}>
                        <CustomCard
                          sx={{ padding: '16px 8px', borderRadius: '8px ' }}
                        >
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#9E9E9E',
                              mb: '8px',
                            }}
                          >
                            Qty
                          </Typography>
                          <Typography
                            sx={{ fontSize: '14px', fontWeight: 500 }}
                          >
                            {item?.qty} {item?.unit}
                          </Typography>
                        </CustomCard>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </CustomCard>
        </Grid>
      </Grid>

      {/* button event */}
      <Box
        sx={{
          width: '100%',
          bgcolor: 'white',
          position: 'fixed',
          bottom: '0px',
          right: '0px',
          padding: '20px ',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
          <CustomButton
            variant="contained"
            color="error"
            sx={{ width: '100%', height: '36px' }}
            onClick={handleDelete}
          >
            <Typography
              sx={{
                color: 'white',
                fontSize: '14px',
                fontWeight: 600,
              }}
            >
              Delete
            </Typography>
          </CustomButton>
          <CustomButton
            variant="contained"
            sx={{ width: '100%', height: '36px' }}
            onClick={() => handleToEdit()}
          >
            <Typography
              sx={{
                color: 'white',
                fontSize: '14px',
                fontWeight: 600,
              }}
            >
              Edit
            </Typography>
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};
export default PurchaseRequestDetailMobile;
