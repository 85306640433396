import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Switch,
  Dialog,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

import dayjs from 'dayjs';
import { uploadImage } from '../../../../../../api/storage';
import CustomEditConfirm from '../../../../../../components/shared/CustomEditConfirm';
import CustomDeleteConfirm from '../../../../../../components/shared/CustomDeleteConfirm';
import BackButton from '../../../../../../components/shared/BackButton';
import CustomInput from '../../../../../../components/shared/CustomInput';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import CustomButton from '../../../../../../components/shared/CustomButton';
import {
  deleteSubCategoryMenu,
  updateSubCategoryMenu,
} from '../../../../../../api/menu';
import SubCategoryMenuEditDesktop from './SubCategoryMenuEditDesktop.jsx';
import SubCategoryMenuEditMobile from './SubCategoryMenuEditMobile.jsx';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const SubCategoryMenuEdit = (props) => {
  const {
    desktopMode,
    // * --notif
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
    // *-- Page
    setPage,
    setSubCategoryMenu,
    // *-- hirarki
    roadHirarki,
    setRoadHirarki,
    // *-- data
    subCategoryEditInputDatas,
    setSubCategoryEditInputDatas,
  } = props;
  const today = dayjs(new Date());
  const theme = useTheme();

  // !------------------- Confirmation -------------------
  const [isEditConfirm, setIsEditConfirm] = useState(false);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  // !------------------------- SAVE VALIDATION ----------------------------
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);

  useEffect(() => {
    if (subCategoryEditInputDatas.name.length >= 5) {
      if (
        subCategoryEditInputDatas.description === '' ||
        subCategoryEditInputDatas.description.length >= 20
      ) {
        return setIsSubmitAvailable(true);
      } else {
        return setIsSubmitAvailable(false);
      }
    } else {
      return setIsSubmitAvailable(false);
    }
  }, [subCategoryEditInputDatas.name, subCategoryEditInputDatas.description]);

  // !------------------- Handler -------------------
  const handleBackButton = () => {
    setSubCategoryMenu('details');
  };

  const handleEdit = () => {
    setIsEditConfirm(true);
  };

  const handleEditFix = () => {
    let hasil = {
      idKategori: roadHirarki.categoryId,
      subkategori: subCategoryEditInputDatas.name,
      subStatus: `${subCategoryEditInputDatas.publish ? 'Y' : 'N'}`,
      schedule: dayjs(subCategoryEditInputDatas.schedule.$d).format(
        'YYYY-MM-DD'
      ),
    };

    if (subCategoryEditInputDatas.description !== '') {
      hasil['informasi'] = subCategoryEditInputDatas.description;
    }

    if (subCategoryEditInputDatas.photoUrls.length !== 0) {
      hasil['gbrsub'] = subCategoryEditInputDatas.photoUrls.join(',');
    }

    updateSubCategoryMenu(roadHirarki.subCategoryId, hasil)
      .then((result) => {
        setPage('subCategory');
        setSubCategoryMenu('details');
        setRoadHirarki({
          ...roadHirarki,
          subCategory: result.data.data.subkategori,
          subCategoryId: result.data.data.idSubkategori,
          product: '',
          productId: 0,
          type: '',
          typeId: 0,
          miniSubCategory: '',
          miniSubCategoryId: 0,
        });
        setIsEditConfirm(false);
        setNotif('editsubcategory');
        setNotifTitle(
          `${result.data.data.subkategori} has been successfully edited`
        );
      })
      .catch((err) => console.error(err));
  };

  const handleDelete = () => {
    setIsDeleteConfirm(true);
  };

  const handleDeleteFix = () => {
    deleteSubCategoryMenu(roadHirarki.subCategoryId).then((res) => {
      setIsDeleteConfirm(false);
      setNotifTitle(
        `${res.data.data.subkategori} has been successfully deleted`
      );
      setNotif('deletesubcategory');
      setPage('subCategory');
      setSubCategoryMenu('');
      setRoadHirarki({
        ...roadHirarki,
        subCategory: '',
        subCategoryId: 0,
        product: '',
        productId: 0,
        miniSubCategory: '',
        miniSubCategoryId: 0,
      });
    });
  };

  const handleAddImage = (e) => {
    if (e.target.files[0].size > 2000000) {
      setSubCategoryEditInputDatas({
        ...subCategoryEditInputDatas,
        isMaxSizePhoto: true,
      });
    } else {
      let datas = {
        image: e.target.files[0],
        resource: 'subCategory',
      };
      uploadImage(datas).then((res) => {
        setSubCategoryEditInputDatas({
          ...subCategoryEditInputDatas,
          photoUrls: [
            ...subCategoryEditInputDatas.photoUrls,
            res.data.data.path,
          ],
          isMaxSizePhoto: false,
        });
      });
    }
  };
  const handleDeleteImage = (link) => {
    setSubCategoryEditInputDatas({
      ...subCategoryEditInputDatas,
      photoUrls: subCategoryEditInputDatas.photoUrls.filter(
        (data) => data !== link
      ),
      indexPreviePhoto: 0,
    });
  };

  const handlePreviewImage = (link) => {
    setSubCategoryEditInputDatas({
      ...subCategoryEditInputDatas,
      linkPreviewPhoto: link,
      isPreviewPhoto: 'true',
    });
  };
  const handleClosePreviewImage = () => {
    setSubCategoryEditInputDatas({
      ...subCategoryEditInputDatas,
      isPreviewPhoto: !subCategoryEditInputDatas.isPreviewPhoto,
    });
  };

  return (
    <>
      <CustomEditConfirm
        open={isEditConfirm}
        onClose={() => setIsEditConfirm(false)}
        handleClickCancel={() => setIsEditConfirm(false)}
        handleClickEdit={handleEditFix}
        title="Edit Subcategory"
        body={
          <Box sx={{ textAlign: 'center', mb: '40px', mt: '8px' }}>
            <Typography>Are you sure you want to save the changes?</Typography>
          </Box>
        }
      />
      <CustomDeleteConfirm
        open={isDeleteConfirm}
        onClose={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickCancel={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Category"
        body={
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: '600' }}>
                {subCategoryEditInputDatas.name}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: '52px' }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />
      {desktopMode ? (
        <SubCategoryMenuEditDesktop
          {...{ theme, today, AntSwitch }}
          {...{ ...props }}
          {...{ isSubmitAvailable }}
          {...{
            handleBackButton,
            handleEdit,
            handleDelete,
            handleAddImage,
            handleDeleteImage,
            handlePreviewImage,
            handleClosePreviewImage,
          }}
        />
      ) : (
        <SubCategoryMenuEditMobile
          {...{ theme, today, AntSwitch }}
          {...{ ...props }}
          {...{
            handleBackButton,
            handleEdit,
            handleDelete,
            handleAddImage,
            handleDeleteImage,
            handlePreviewImage,
            handleClosePreviewImage,
          }}
        />
      )}
    </>
  );
};
export default SubCategoryMenuEdit;
