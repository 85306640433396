import { createSlice } from "@reduxjs/toolkit";

export const addProductSupplierState = createSlice({
  name: "addproductSupplier",
  initialState: {
    product: [],
    price: [],
    status: false,
  },
  reducers: {
    changeStatusProductSupplier: (state, action) => {
      state.status = action.payload;
    },

    clearStateProductSupplier: (state) => {
      state.product = [];
      state.price = [];
      state.status = false;
    },
  },
});

export const { changeStatusProductSupplier, clearStateProductSupplier } =
  addProductSupplierState.actions;

export const getAllDataAddProductSupplier = (state) => state.addProductSupplier;

export default addProductSupplierState.reducer;
