import { createSlice } from '@reduxjs/toolkit';

export const transactionRoutingState = createSlice({
  name: 'transactionRouting',
  initialState: {
    role: '',
    page: '',
    keyNotif: '',
    textNotif: '',
    hubName: '',
  },
  reducers: {
    changePageTransaction: (state, action) => {
      state.page = action.payload;
    },
    changeTransactionRoutingState: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setNotifTransaction: (state, action) => {
      const { key, text } = action.payload;
      state.keyNotif = key;
      state.textNotif = text;
    },
    setCloseNotifTransaction: (state) => {
      state.keyNotif = '';
      state.textNotif = '';
    },
  },
});

export const {
  changePageTransaction,
  changeTransactionRoutingState,
  setNotifTransaction,
  setCloseNotifTransaction,
} = transactionRoutingState.actions;

export const getAllTransactionRouting = (state) => state.transactionRouting;

export default transactionRoutingState.reducer;
