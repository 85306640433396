import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import BackButtonHub from '../../../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import { changePageTransaction } from '../../../../../../../../store/Routing/transactionRouting';
import CustomButton from '../../../../../../../../components/shared/CustomButton';
import CustomCard from '../../../../../../../../components/shared/CustomCard';
import {
  changeIsEditTransaction,
  clearDetailTransaction,
  getAllDataDetailTransaction,
  getIdDetailTransactionRequest,
  getIsLoadingTransactionDetail,
  getSpesifikTransactionRequest,
  getStatusCodeTransactionDetail,
  getUserEmployeeStatus,
  getisEditTransaction,
} from '../../../../../../../../store/transaction/request/detailTransactionRequest';
import {
  DeleteOutlined,
  QrScanner,
} from '../../../../../../../../assets/Icons';
import { useEffect } from 'react';
import { goToTransactionRequestEdit } from '../../../../../../../../store/transaction/request/editTransactionRequest';
import EditTransactionMobile from '../Edit/EditTransactionMobile';
import {
  changeLokasiPemberi,
  changePemberi,
  setAllDataDataTransaction,
  setAllDataDataTransactionWithQr,
} from '../../../../../../../../store/transaction/request/sendDataTransaction';
import {
  changeLokasiPemberiAccept,
  changePemberiAccept,
  setAllDataAcceptTransaction,
  setAllDataAcceptTransactionWithQr,
} from '../../../../../../../../store/transaction/request/acceptDataTransaction';
import {
  deleteTransaction,
  rejectTransaction,
} from '../../../../../../../../api/transaction';
import { useState } from 'react';
import { clearDataTransactionMobile } from '../../../../../../../../store/transaction/dataTable';
import { clearDataTransaction } from '../../../../../../../../store/transaction/request/dataTableRequest';

const DetailTransactionRequestMobile = () => {
  const dispatch = useDispatch();
  const dataDetailTransaction = useSelector(getAllDataDetailTransaction);
  const idTransaction = useSelector(getIdDetailTransactionRequest);
  const isLoading = useSelector(getIsLoadingTransactionDetail);
  const isEdit = useSelector(getisEditTransaction);
  const userEmploye = useSelector(getUserEmployeeStatus);
  const role = localStorage.role;
  const status = dataDetailTransaction?.statusOrder;
  const [isLoadingReject, setIsLoadingReject] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const statusCode = useSelector(getStatusCodeTransactionDetail);

  useEffect(() => {
    dispatch(getSpesifikTransactionRequest(idTransaction));
  }, [idTransaction]);

  const handleToEdit = () => {
    dispatch(goToTransactionRequestEdit(dataDetailTransaction));
    dispatch(changeIsEditTransaction(true));
  };

  const handleBack = () => {
    if (status === 'finish' || status === 'sent' || status === 'reject') {
      dispatch(changePageTransaction(''));
      dispatch(changeIsEditTransaction(false));
    } else {
      dispatch(changePageTransaction('request'));
      dispatch(changeIsEditTransaction(false));
    }
  };

  const handleSend = () => {
    const noqrData = dataDetailTransaction?.details
      ?.filter((list) => list.qrcodeType === false)
      .map((data, idx) => data.qrcodeType === false && data);

    dispatch(setAllDataDataTransaction(noqrData));
    dispatch(
      setAllDataDataTransactionWithQr(dataDetailTransaction?.detailsproduk)
    );
    dispatch(changePemberi(dataDetailTransaction?.tabelPemberi));
    dispatch(changeLokasiPemberi(dataDetailTransaction?.lokasiPemberi));
  };

  const handleAccept = () => {
    const noqrData = dataDetailTransaction?.details
      ?.filter((list) => list.qrcodeType === false)
      .map((data, idx) => data.qrcodeType === false && data);

    dispatch(setAllDataAcceptTransaction(noqrData));
    dispatch(
      setAllDataAcceptTransactionWithQr(dataDetailTransaction?.detailsproduk)
    );
    dispatch(changePemberiAccept(dataDetailTransaction?.tabelPemberi));
    dispatch(changeLokasiPemberiAccept(dataDetailTransaction?.lokasiPemberi));
  };

  const handleReject = async () => {
    const params = {
      status: 'reject',
      keterangan: '',
    };
    setIsLoadingReject(true);
    try {
      const data = await rejectTransaction(idTransaction, params);
      dispatch(changePageTransaction('request'));
      setIsLoadingReject(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    try {
      const data = await deleteTransaction(idTransaction);
      dispatch(changePageTransaction('request'));
      setIsLoadingDelete(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '50vh',
          }}
        >
          <CircularProgress />
        </Box>
      ) : isEdit ? (
        <EditTransactionMobile />
      ) : (
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              paddingTop: 2,
              // paddingLeft: 2,
              minHeight: '80vh',
            }}
          >
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              {!isEdit ? (
                <BackButtonHub onClick={handleBack} />
              ) : (
                <BackButtonHub
                  onClick={() => {
                    dispatch(changeIsEditTransaction(false));
                  }}
                />
              )}

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                  {status !== 'finish' ||
                  status !== 'sent' ||
                  status !== 'reject'
                    ? 'Stock request details'
                    : 'Transaction Details'}
                </Typography>
                {dataDetailTransaction?.statusOrder === 'sent' ? (
                  <Box
                    sx={{
                      width: '52px',
                      height: '26px',
                      background: 'rgba(234, 205, 87, 0.1)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '5px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#EACD57',
                      }}
                    >
                      sent
                    </Typography>
                  </Box>
                ) : dataDetailTransaction?.statusOrder === 'finish' ? (
                  <Box
                    sx={{
                      width: '83px',
                      height: '26px',
                      background: 'rgba(81, 177, 92, 0.1)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '5px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#51B15C',
                      }}
                    >
                      Finish
                    </Typography>
                  </Box>
                ) : dataDetailTransaction?.statusOrder === 'reject' ? (
                  <Box
                    sx={{
                      width: '83px',
                      height: '26px',
                      background: 'rgba(243, 58, 58, 0.1)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '5px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#F33A3A',
                      }}
                    >
                      Rejects
                    </Typography>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
            <>
              <Grid
                container
                columns={12}
                spacing={3}
                sx={{ paddingTop: '28px', marginBottom: '100px ' }}
              >
                {/* from */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      background: '#FAFAFA',
                      borderRadius: '8px 8px 0px 0px',
                      padding: '16px 12px 14px 14px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        marginBottom: '8px',
                        color: '#9E9E9E',
                      }}
                    >
                      From
                    </Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                      {dataDetailTransaction?.namaPemberi || '_'}
                    </Typography>
                  </Box>
                </Grid>

                {/* to */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      background: '#FAFAFA',
                      borderRadius: '8px 8px 0px 0px',
                      padding: '16px 12px 14px 14px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        marginBottom: '8px',
                        color: '#9E9E9E',
                      }}
                    >
                      To
                    </Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                      {dataDetailTransaction?.namaTujuan || '_'}
                    </Typography>
                  </Box>
                </Grid>

                {/* items */}
                <Grid item xs={12}>
                  <CustomCard>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: '20px',
                        boxShadow: '0px 1px 5px #ccc',
                      }}
                    >
                      <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                        Item(s)
                      </Typography>
                    </Box>

                    <Box>
                      <Grid
                        container
                        columns={12}
                        spacing={4}
                        sx={{ p: '30px 20px' }}
                      >
                        {statusCode !== null ? (
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontSize: '10px',
                                color: '#9E9D9D',
                                textAlign: 'center',
                              }}
                            >
                              no data transaction
                            </Typography>
                          </Grid>
                        ) : (
                          dataDetailTransaction?.details.map((item, index) => (
                            <Grid
                              item
                              xs={12}
                              sx={{ display: 'flex' }}
                              key={index}
                            >
                              <Grid container columns={12} spacing={3}>
                                {/* product */}
                                <Grid item xs={6}>
                                  <Box
                                    sx={{
                                      background: '#FAFAFA',
                                      borderRadius: '8px 8px 0px 0px',
                                      padding: '16px 12px 14px 14px',
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        marginBottom: '8px',
                                        color: '#9E9E9E',
                                      }}
                                    >
                                      Product*
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: '14px', fontWeight: 500 }}
                                    >
                                      {item?.product?.namaProduk}
                                    </Typography>
                                  </Box>
                                </Grid>
                                {/* qty */}
                                <Grid item xs={6}>
                                  <Box
                                    sx={{
                                      background: '#FAFAFA',
                                      borderRadius: '8px 8px 0px 0px',
                                      padding: '16px 12px 14px 14px',
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        marginBottom: '8px',
                                        color: '#9E9E9E',
                                      }}
                                    >
                                      Qty*
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: '14px', fontWeight: 500 }}
                                    >
                                      {item?.jumlah} {item?.unit}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))
                        )}
                      </Grid>
                    </Box>
                  </CustomCard>
                </Grid>
              </Grid>
            </>
          </Box>

          {/* button event */}

          {(role == 2 || role == 3) && (
            <>
              {dataDetailTransaction?.statusOrder === 'sent' ? (
                <Box
                  sx={{
                    width: '100%',
                    bgcolor: 'white',
                    position: 'absolute',
                    bottom: '0px',
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    <CustomButton
                      variant="contained"
                      sx={{ width: '100%', height: '40px' }}
                      onClick={() => {
                        dispatch(changePageTransaction('scanRackTransaction'));
                        handleAccept();
                      }}
                    >
                      <QrScanner />
                      <Typography
                        sx={{
                          color: 'white',
                          fontSize: '14px',
                          ml: 1,
                          fontWeight: 600,
                        }}
                      >
                        Accept
                      </Typography>
                    </CustomButton>
                  </Box>
                </Box>
              ) : dataDetailTransaction?.statusOrder === 'accept' ? (
                <></>
              ) : dataDetailTransaction?.statusOrder === 'finish' ? (
                <></>
              ) : dataDetailTransaction?.statusOrder === 'reject' ? (
                <></>
              ) : (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                  }}
                >
                  {userEmploye === 'tujuan' ? (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <CustomButton
                        variant="contained"
                        sx={{
                          width: '100%',
                          height: '40px',
                          background: '#F33A3A',
                        }}
                        onClick={() => setConfirmDelete(true)}
                      >
                        <Typography
                          sx={{
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: 600,
                          }}
                        >
                          Delete
                        </Typography>
                      </CustomButton>
                      <CustomButton
                        variant="contained"
                        sx={{ width: '100%', height: '40px' }}
                        onClick={() => handleToEdit()}
                      >
                        <Typography
                          sx={{
                            color: 'white',
                            fontSize: '14px',
                            ml: 1,
                            fontWeight: 600,
                          }}
                        >
                          Edit
                        </Typography>
                      </CustomButton>
                    </Box>
                  ) : (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <CustomButton
                        variant="contained"
                        sx={{
                          width: '100%',
                          height: '40px',
                          background: '#F33A3A',
                        }}
                        onClick={handleReject}
                      >
                        <Typography
                          sx={{
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: 600,
                          }}
                        >
                          {isLoadingReject ? (
                            <CircularProgress size={18} />
                          ) : (
                            'Reject'
                          )}
                        </Typography>
                      </CustomButton>
                      <CustomButton
                        disabled={statusCode !== null ? true : false}
                        variant="contained"
                        sx={{ height: '40px', width: '100%' }}
                        onClick={() => {
                          dispatch(
                            changePageTransaction('sendTransactionMobile')
                          );
                          handleSend();
                        }}
                      >
                        <QrScanner />
                        <Typography
                          sx={{
                            color: 'white',
                            fontSize: '14px',
                            ml: 1,
                            fontWeight: 600,
                          }}
                        >
                          Send
                        </Typography>
                      </CustomButton>
                    </Box>
                  )}
                </Box>
              )}
            </>
          )}
        </Box>
      )}

      {/* dialog delete */}
      <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <DialogTitle
          sx={{
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '12px 12px 0px 0px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box
              sx={{
                background: 'rgba(243, 58, 58, 0.1)',
                width: '44px',
                height: '44px',
                borderRadius: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <DeleteOutlined fill="#F33A3A" />
            </Box>
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              Delete Stock Request
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
            <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
              Are you sure you want to delete{' '}
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
              "{idTransaction}"
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              justifyContent: 'end',
              mt: 5,
            }}
          >
            <Typography
              onClick={() => setConfirmDelete(false)}
              sx={{ color: '#9D9E9E', fontSize: '14px', fontWeight: 600 }}
            >
              Cancel
            </Typography>
            <CustomButton
              variant="contained"
              sx={{
                width: '100px',
                height: '40px',
                background: '#F33A3A',
              }}
              onClick={handleDelete}
            >
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '14px',
                  fontWeight: 600,
                }}
              >
                {isLoadingDelete ? <CircularProgress size={18} /> : 'Delete'}
              </Typography>
            </CustomButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DetailTransactionRequestMobile;
