import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import CustomCard from '../../../../../../components/shared/CustomCard';
import {
  ChevronRightIcon,
  Different,
  Dolar,
  ExportIcon,
  FilterAltIcon,
  SearchIcon,
  StockAlert,
  ToBarChart,
  ToLineChart,
} from '../../../../../../assets/Icons';
import CustomButton from '../../../../../../components/shared/CustomButton';
import { getAllDataStock, getStockAlert } from '../../../../../../api/stock';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import debounce from 'lodash.debounce';
import DialogFilterStockMobile from './DialogFilter';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeTemporarySubsidiary,
  getMasterHubIdStock,
  getMiniHubIdStock,
  getSpesifikSubsidiary,
  getSubHubIdStock,
  getSubsidiaryIdStock,
} from '../../../../../../store/stock/stock';
import { changePageStock } from '../../../../../../store/Routing/stockRouting';
import Subsidiary from '../../../../../../components/shared/Subsidiary';
import CustomBarChart from '../../../../../../components/shared/CustomBarChart';
import CustomLineChart from '../../../../../../components/shared/CustomLineChart';
import CustomTableMobile from '../../../../../../components/shared/CustomTableMobile';

// data dummy chart
const datas = {
  labels: ['Hub 1', 'Hub 2', 'Hub 3', 'Hub 4', 'Hub 5'],
  data: [
    {
      label: 'Equal',
      chart: [30, 110, 140, 100, 240],
    },
    {
      label: 'Different',
      chart: [0, 35, 100, 90, 150],
    },
    {
      label: 'Missing',
      chart: [40, 70, 160, 120, 200],
    },
  ],
};
const color = ['#6B4BB4', '#EACD57', '#FD7900'];

const StockMobile = () => {
  const dispatch = useDispatch();
  const [filterSubsidiaryArray, setFilterSubsidiaryArray] = useState([]);
  const spesifikSubsidiary = useSelector(getSpesifikSubsidiary);
  const subsidiaryId = useSelector(getSubHubIdStock);
  const [isLoading, setIsLoading] = useState(false);
  const [stock, setStock] = useState([]);
  const [stokAlertList, setStockAlertList] = useState(0);
  const [search, setSearch] = useState('');
  const [purchaseKeyword, setPurchaseKeyword] = useState('');
  const [isFilter, setIsFilter] = useState(false);
  const subsidiary = useSelector(getSubsidiaryIdStock);
  const idToko = useSelector(getMasterHubIdStock);
  const subHubId = useSelector(getSubHubIdStock);
  const miniHubId = useSelector(getMiniHubIdStock);
  const [chartType, setChartType] = useState(false);

  const debounceOnChange = useRef(
    debounce((value) => {
      setPurchaseKeyword(value);
    }, 1000)
  ).current;

  const toolbar = (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row-reverse',
        gap: 2,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '22px',
          height: '22px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <ExportIcon />
      </CustomButton>

      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '22px',
          height: '22px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
        }}
        onClick={() => {
          setIsFilter(true);
          dispatch(changeTemporarySubsidiary([]));
        }}
      >
        <FilterAltIcon sx={{ width: '14px' }} />
      </CustomButton>
      <DialogFilterStockMobile {...{ isFilter, setIsFilter }} />
    </Box>
  );

  const fetcDataStock = async (params) => {
    setIsLoading(true);
    try {
      const { data } = await getAllDataStock(params);
      setStock(data?.data.stocks);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const id = subsidiary.map((item, _) => item?.id);

    const params = {};
    params.subsidiary_id = spesifikSubsidiary;
    params.id_toko = [];
    params.subh_hub_id = [];
    params.mini_hub_id = [];
    params.search = purchaseKeyword;
    fetcDataStock(params);
  }, [purchaseKeyword, subsidiary, spesifikSubsidiary]);

  // fathing setStockAlertList
  const fetchStockList = async (params) => {
    try {
      const {
        data: { data },
      } = await getStockAlert(params);

      setStockAlertList(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const params = {};
    params.subsidiaryId = subsidiaryId;
    params.idToko = idToko;
    params.subHubId = subHubId;
    params.miniHubId = miniHubId;
    fetchStockList(params);
  }, [subsidiaryId, idToko, subHubId, miniHubId]);

  return (
    <Box sx={{ mt: 3 }}>
      <Grid container columns={12} spacing={2}>
        {/* subsidiary dan stock request link */}
        <Grid item xs={9}>
          <Box>
            <Subsidiary
              subsidiaryChecked={filterSubsidiaryArray}
              setSubsidiaryChecked={setFilterSubsidiaryArray}
              pad={1}
            />
          </Box>
        </Grid>

        {/* tombol alertstock */}
        <Grid item xs={3}>
          <Box
            sx={{
              width: '100%',
              height: '56px',
              borderRadius: '8px',
              border: '1px solid #F33A3A',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
              cursor: 'pointer',
            }}
            onClick={() => dispatch(changePageStock('stockAlert'))}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '62px',
                height: '39px',
                gap: 1,
                pl: '5px',
              }}
            >
              <StockAlert style={{ fontSize: '16px', color: '#F33A3A' }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#F33A3A',
                  width: '14px',
                  height: '14px',
                  borderRadius: '3px',
                }}
              >
                <Typography
                  sx={{ color: 'white', fontSize: '8px', fontWeight: 600 }}
                >
                  {stokAlertList}
                </Typography>
              </Box>
              <ChevronRightIcon
                style={{ fontSize: '16px', color: '#F33A3A' }}
              />
            </Box>
          </Box>
        </Grid>

        {/* grafik stock */}

        <Grid item xs={12}>
          <CustomCard sx={{ p: 2 }}>
            <Typography
              sx={{ color: '#000', fontSize: '10px', fontWeight: 600, mb: 2 }}
            >
              Hub Stock
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                sx={{
                  fontSize: '8px',
                  color: '#6B4BB4',
                  fontWeight: 600,
                  mb: 1,
                }}
              >
                SKU
              </Typography>
              <Box
                sx={{
                  width: '16px',
                  height: '16px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => setChartType(!chartType)}
              >
                {chartType ? (
                  <ToLineChart stroke="#51B15C" />
                ) : (
                  <ToBarChart stroke="#51B15C" />
                )}
              </Box>
              <Box></Box>
            </Box>
            {chartType ? (
              <CustomBarChart datas={datas} color={color} />
            ) : (
              <CustomLineChart datas={datas} color={color} />
            )}
          </CustomCard>
        </Grid>

        {/* table grafik */}
        <Grid item xs={12}>
          <CustomTableMobile
            rootValue={datas?.labels}
            datas={datas.data}
            color={color}
          />
        </Grid>

        {/* list data */}
        <Grid item xs={12}>
          <CustomCard sx={{ padding: '16px' }}>
            <Grid container columns={12} spacing={2}>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    display: 'inline-flex',
                    fontSize: '12px',
                    fontWeight: 600,
                    padding: '10px',
                  }}
                >
                  Stock
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  placeholder="Search"
                  size="small"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    debounceOnChange(e.target.value);
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      paddingLeft: '6px',
                      fontSize: '8px',
                    },
                    bgcolor: '#FAFAFA',
                    minHeight: '22px',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ width: '12px' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                {toolbar}
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}
                    >
                      <Dolar />

                      <Typography
                        sx={{
                          fontSize: '8px',
                          display: 'flex',
                          fontWeight: 600,
                        }}
                      >
                        100
                      </Typography>
                    </Box>

                    <Box
                      sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}
                    >
                      <Different />

                      <Typography
                        sx={{
                          fontSize: '8px',
                          display: 'flex',
                          fontWeight: 600,
                        }}
                      >
                        35
                      </Typography>
                    </Box>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: '8px',
                      fontWeight: 600,
                    }}
                  >
                    *000
                  </Typography>
                </Box>
              </Grid>

              {isLoading ? (
                <Grid item xs={12}>
                  <Box sx={{ width: '100%', textAlign: 'center', mt: 2 }}>
                    <CircularProgress />
                  </Box>
                </Grid>
              ) : stock?.length === 0 ? (
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'red',
                    }}
                  >
                    No data found
                  </Typography>
                </Grid>
              ) : (
                stock?.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Box
                      key={index}
                      sx={{
                        padding: '10px',
                        borderRadius: '8px',
                        background: '#FAFAFA',
                      }}
                    >
                      <Grid container columns={12} spacing={1}>
                        <Grid item xs={12} sx={{ Height: '147px' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 1,
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              component="img"
                              src={
                                // process.env.REACT_APP_API_IMAGE_BASE_UR +
                                // "/" +
                                // item?.gambar

                                process.env.REACT_APP_API_IMAGE_BASE_URL +
                                '/' +
                                item?.gambar
                              }
                              sx={{
                                minWidth: '28px',
                                width: '28px',
                                height: '28px',
                                borderRadius: '8px',
                              }}
                            />

                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                height: '28px',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '8px',
                                  display: 'flex',
                                  fontWeight: 600,
                                }}
                              >
                                {item?.subsidiary}
                                <ChevronRightIcon
                                  style={{ fontSize: '8px' }}
                                />{' '}
                                ({item?.hub})
                                <ChevronRightIcon
                                  style={{ fontSize: '8px' }}
                                />{' '}
                                ({item?.location})
                              </Typography>
                              <Typography
                                sx={{ fontSize: '10px', fontWeight: 600 }}
                              >
                                {item?.namaProduk}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 1,
                              alignItems: 'center',
                            }}
                          >
                            <Box>
                              <Dolar />
                            </Box>

                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                height: '28px',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '8px',
                                  display: 'flex',
                                  fontWeight: 600,
                                }}
                              >
                                100
                              </Typography>
                            </Box>

                            <Box>
                              <Different />
                            </Box>

                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                height: '28px',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '8px',
                                  display: 'flex',
                                  fontWeight: 600,
                                }}
                              >
                                35
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))
              )}
            </Grid>
          </CustomCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StockMobile;
