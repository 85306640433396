import {
  Box,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ProductPerfomance, SeeProduct } from '../DesktopComponent';
import CustomCard from '../../../../../../components/shared/CustomCard';
import BackButton from '../../../../../../components/shared/BackButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useEffect, useState } from 'react';
import TableSubCategoryProduct from './Desktop/TableSubCategoryProduct';

import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as DollarIcon } from '../../../../../../assets/dollar.svg';
import { ReactComponent as BoxIcon } from '../../../../../../assets/box.svg';
import { ReactComponent as NoImage } from '../../../../../../assets/no-image.svg';

import InfiniteScroll from 'react-infinite-scroll-component';
import {
  fetchCategories,
  fetchSubCategories,
} from '../../../../../../api/products';
import SubCategoryProductMobile from './SubCategoryProductMobile';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';

const SubCategoryProduct = (props) => {
  const {
    isLoadingData,
    // ---
    page,
    setPage,
    roadHirarki,
    setRoadHirarki,
    setIsSubCategoryProductAdd,
    setIsSubCategoryProductEdit,
    setIsSubCategoryProductDetails,
    // *-date & time
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    // Table
    tableRowPerPageValues,
    tableRowPerPage,
    setTableRowPerPage,
    tableCurrentPage,
    setTableCurrentPage,
    tableTotalItems,
    setTableTotalItems,
    tableSearch,
    setTableSearch,
    sortTable,
    setSortTable,
    isSortAsc,
    setIsSortAsc,
    tableDatas,
    setTableDatas,
    // *----Notif ---
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
    // *---
    setIsLoadingData,
    setStatusError,
  } = props;

  const theme = useTheme();

  const getAllSubCategories = async () => {
    setIsLoadingData(true);

    let data = {};
    data.pageSize = tableRowPerPage;
    data.page = tableCurrentPage;
    data.categories = [roadHirarki.categoryId];
    if (tableSearch !== '') {
      data['search'] = tableSearch;
    }
    if (sortTable !== 0) {
      data[sortTable] = isSortAsc ? 'asc' : 'desc';
    }

    try {
      const {
        data: { data: dataFetchSubCategories },
      } = await fetchSubCategories(data);

      setTableDatas(dataFetchSubCategories.subCategories);
      setTableTotalItems(dataFetchSubCategories.pageInfo.total);
    } catch (err) {
      console.error();
      setStatusError(err.response.request.status);
      setTableDatas([]);
      setTableTotalItems(tableTotalItems);
      setIsLoadingData(true);
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    getAllSubCategories();
  }, []);

  useEffect(() => {
    if (roadHirarki.category === '') {
      setPage('category');
    }
    setRoadHirarki({
      subsidiary: roadHirarki.subsidiary,
      subsidiaryId: roadHirarki.subsidiaryId,
      category: roadHirarki.category,
      categoryId: roadHirarki.categoryId,
      subCategory: '',
      subCategoryId: 0,
      product: '',
      productId: 0,
      miniSubCategory: '',
      miniSubCategoryId: 0,
    });
  }, []);

  const [desktopMode, setDesktopMode] = useState(true);

  useEffect(() => {
    if (window.screen.width <= 830) {
      setDesktopMode(false);
    } else {
      setDesktopMode(true);
    }
  }, [window.screen.width]);

  const handleClickRow = (name, id) => {
    setRoadHirarki({
      ...roadHirarki,
      subCategory: name,
      subCategoryId: id,
    });
    setTableCurrentPage(1);
    setPage('product');
  };
  const handleClickName = (event, name, id) => {
    event.stopPropagation();
    setRoadHirarki({
      ...roadHirarki,
      subCategory: name,
      subCategoryId: id,
    });
    setIsSubCategoryProductDetails(true);
  };

  const handleClickAddSub = () => {
    setPage('subCategory');
    setIsSubCategoryProductAdd(true);
    setRoadHirarki(roadHirarki);
  };

  const handleBackButton = () => {
    setRoadHirarki({
      ...roadHirarki,
      subCategory: '',
      subCategoryId: 0,
      product: '',
      productId: 0,
      miniSubCategory: '',
      miniSubCategoryId: 0,
    });
    setPage('category');
  };

  return (
    <>
      <CustomNotifSuccess
        text={notifTitle}
        open={notif === 'addsubcategory'}
        onClose={() => {
          setNotif('');
          setNotifTitle('');
        }}
      />
      <CustomNotifSuccess
        text={notifTitle}
        open={notif === 'deletesubcategory'}
        onClose={() => {
          setNotif('');
          setNotifTitle('');
        }}
      />
      {desktopMode ? (
        <Grid container columns={12} spacing={2}>
          <Grid
            item
            xs={10}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <BackButton handleClick={handleBackButton} />
            <Typography
              sx={{
                ml: '8px',
                fontSize: '24px',
                fontWeight: '600',
                color: '#9E9D9D',
                cursor: 'pointer',
              }}
              onClick={() => {
                setPage('category');
              }}
            >
              Category
            </Typography>
            <ChevronRightIcon sx={{ color: '#9E9D9D' }} />
            <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
              {roadHirarki.category}
            </Typography>
          </Grid>
          {/*to Product All */}
          <Grid item xs={2}>
            <SeeProduct {...{ page, setPage }} />
          </Grid>
          <Grid item xs={12}>
            <ProductPerfomance
              {...{
                timeFrame,
                setTimeFrame,
                dateRange,
                setDateRange,
                saveDateRange,
                setSaveDateRange,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TableSubCategoryProduct
              {...{ handleClickRow, handleClickName, handleClickAddSub }}
              {...{
                page,
                setPage,
                roadHirarki,
                setRoadHirarki,
                setIsSubCategoryProductAdd,
                setIsSubCategoryProductEdit,
                setIsSubCategoryProductDetails,
                isLoadingData,
              }}
              {...{
                tableRowPerPageValues,
                tableRowPerPage,
                setTableRowPerPage,
                tableCurrentPage,
                setTableCurrentPage,
                tableTotalItems,
                setTableTotalItems,
                tableSearch,
                setTableSearch,
                sortTable,
                setSortTable,
                isSortAsc,
                setIsSortAsc,
                tableDatas,
                setTableDatas,
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <SubCategoryProductMobile
          {...{
            handleBackButton,
            setPage,
            roadHirarki,
            tableSearch,
            setTableSearch,
            theme,
            handleClickAddSub,
            tableTotalItems,
            tableDatas,
            handleClickRow,
            handleClickName,
          }}
        />
      )}
    </>
  );
};
export default SubCategoryProduct;
