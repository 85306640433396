import { Box, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllRackhub,
  goToRackHubItem,
} from '../../../../../../store/racks/rackHub';
import { useEffect, useState } from 'react';
import { ZoomInIcon, ZoomOutIcon } from '../../../../../../assets/Icons';
import ListAlphabetExcelFormater from '../../../../../../hooks/ListAlphabetExcelFormater';
import { GetExcelNumberFormat } from '../../../../../../hooks/getExcelNumber';

const RackMapPosition = ({ col, row, cellPosition }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { cell, cellSelected, page } = useSelector(getAllRackhub);
  const [isZoom, setIsZoom] = useState(false);
  const [cellSelectedAll, setCellSelectedAll] = useState([]);

  useEffect(() => {
    const collect = cellSelected.flat();
    const collect2 = collect?.filter((list) => !cellPosition?.includes(list));
    setCellSelectedAll(collect2);
  }, [cellSelected, cellPosition]);

  const color = {
    selected: '#51B15C',
    contained: '#FEDD25',
    full: '#F33A3A',
    empty: '#FFFFFF',
  };

  return (
    <Box sx={{ widht: '100%' }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Box
          sx={{
            bgcolor: '#E4E4E4',
            width: '26px',
            height: '26px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            borderRadius: '8px',
          }}
          onClick={() => setIsZoom(!isZoom)}
        >
          {!isZoom ? <ZoomInIcon /> : <ZoomOutIcon />}
        </Box>
      </Box>
      {!isZoom && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box>
            {cell.map((row, rowIndex) => (
              <Box sx={{ display: 'flex' }} key={rowIndex}>
                {row.map((col, colIndex) => (
                  <Box
                    // onClick={() => dispatch(seeDetailRack())}
                    // onMouseOver={() => {
                    //   if (cellSet.flat().includes(col)) {
                    //     let getPos = cellSelectedDetails.find((value) =>
                    //       value.columnPosition.split(',').includes(col)
                    //     );
                    //     setCellSelected(getPos.columnPosition.split(','));
                    //   } else if (cellSelectedNew.flat().includes(col)) {
                    //     let getPos = cellSelectedDetailsNew.find((value) =>
                    //       value.columnPosition.split(',').includes(col)
                    //     );
                    //     setCellSelected(getPos.columnPosition.split(','));
                    //   }
                    // }}
                    // onMouseOut={() => setCellSelected([])}
                    sx={[
                      {
                        width: '20px',
                        height: '20px',
                        minWidth: '20px',
                        minHeight: '20px',
                      },
                      cellSelectedAll?.includes(col) && {
                        bgcolor: '#E4E4E4',
                      },
                      // entrance.includes(col) && {
                      //   bgcolor: '#171717',
                      // },
                      cellPosition?.includes(col) && {
                        bgcolor: '#51B15C',
                      },
                    ]}
                    key={colIndex}
                    onClick={() => {
                      cellPosition?.includes(col) && setIsZoom(!isZoom);
                    }}
                  ></Box>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {isZoom && (
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ py: '20px', px: '20px', overflow: 'auto' }}>
              {[...Array(row)]
                .map((_, rowIndex) => (
                  <Box
                    sx={{ display: 'flex', maxHeight: '36px' }}
                    key={rowIndex}
                  >
                    <Box
                      sx={{
                        minWidth: '40px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography sx={{ color: '#EACD57' }}>
                        {rowIndex + 1}
                      </Typography>
                    </Box>
                    <Box key={rowIndex} sx={{ display: 'flex' }}>
                      {[...Array(col)].map((_, colIndex) => {
                        const colValue = GetExcelNumberFormat(colIndex);
                        return (
                          <Box
                            key={colIndex}
                            sx={[
                              {
                                minWidth: '36px',
                                height: '36px',
                                borderLeft: 2,
                                borderBottom: 2,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '12px',
                                cursor: 'pointer',
                                '&:hover': {
                                  bgcolor: theme.palette.primary.main,
                                },
                              },
                              colIndex === col - 1 && {
                                borderRight: 2,
                              },
                              rowIndex === row - 1 && {
                                borderTop: 2,
                              },
                            ]}
                            onClick={() => {
                              page === 'detail' && dispatch(goToRackHubItem());
                            }}
                          ></Box>
                        );
                      })}
                    </Box>
                  </Box>
                ))
                .reverse()}
              <Box sx={{ pl: '40px', display: 'flex', pt: '20px' }}>
                {[...Array(col)].map((_, colIndex) => {
                  return (
                    <Box
                      sx={[
                        {
                          minWidth: '36px',
                          minHeight: '36px',
                          display: 'flex',
                          justifyContent: 'center',
                        },
                      ]}
                      key={colIndex}
                    >
                      <Typography sx={{ color: '#4339F2' }}>
                        <ListAlphabetExcelFormater angka={colIndex} />
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              mt: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
            }}
          >
            {/* <Box
              sx={{
                display: 'flex',
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  borderRadius: '4px',
                  bgcolor: color.selected,
                }}
              ></Box>
              <Typography sx={{ fontSize: '12px' }}>Selected</Typography>
            </Box> */}
            <Box
              sx={{
                display: 'flex',
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  borderRadius: '4px',
                  bgcolor: color.contained,
                }}
              ></Box>
              <Typography sx={{ fontSize: '12px' }}>Contained</Typography>
            </Box>
            {/* <Box
              sx={{
                display: 'flex',
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  borderRadius: '4px',
                  bgcolor: color.full,
                }}
              ></Box>
              <Typography sx={{ fontSize: '12px' }}>Full</Typography>
            </Box> */}
            <Box
              sx={{
                display: 'flex',
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  borderRadius: '4px',
                  bgcolor: color.empty,
                  border: 1,
                  borderColor: 'black',
                }}
              ></Box>
              <Typography sx={{ fontSize: '12px' }}>Empty</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default RackMapPosition;
