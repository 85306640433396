import React from 'react';
import { Box } from '@mui/material';
import { Line } from 'react-chartjs-2';

const CustomLineChart = ({
  style,
  datas,
  plugins,
  color,
  customOptions,
  ...props
}) => {
  const data = {
    labels: datas.labels,
    datasets: datas.data.map((item, idx) => ({
      label: item?.label,
      data: item?.chart,
      borderColor: color[idx],
      backgroundColor: color[idx],
      tension: 0.2,
      borderWidth: 1,
    })),
  };
  const options = {
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'end',
        labels: {
          color: '#000',
          font: {
            size: 6,
            weight: 600,
            family: 'Poppins',
          },
          usePointStyle: true,
          pointStyle: 'rect',
        },
      },
    },
    scales: {
      y: {
        ticks: {
          color: '#B8B8B8',
          font: {
            size: 8,
            weight: 600,
            family: 'Poppins',
          },
        },
        title: {
          color: 'yellow',
          font: {
            size: 8,
            weight: 600,
            family: 'Poppins',
          },
        },
      },
      x: {
        ticks: {
          color: '#B8B8B8',
          font: {
            size: 8,
            weight: 600,
            family: 'Poppins',
          },
        },
        title: {
          display: true,
          color: '#B8B8B8',
          font: {
            size: 8,
            weight: 600,
            family: 'Poppins',
          },
        },
      },
    },
    ...customOptions,
  };

  return (
    <Box>
      <Line
        style={style}
        data={data}
        plugins={plugins}
        options={options}
        {...props}
      />
    </Box>
  );
};

export default CustomLineChart;
