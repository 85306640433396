import { createSlice } from '@reduxjs/toolkit';
import { getAllHubs, getAllMiniHub, getAllSubHub } from '../../api/hubs';

export const addAuditState = createSlice({
  name: 'addAudit',
  initialState: {
    table: '',
    idToko: null,
    subHubId: null,
    minHubId: null,
    description: '',
    idAddAudit: null,
    isQrExist: false,

    isLoading: false,
    hub: [],
    subHub: [],
    miniHub: [],

    // daa index add
    hubs: null,
    startAuditing: false,
    scannedData: [],
  },

  reducers: {
    changeTableAddAudit: (state, action) => {
      state.table = action.payload;
    },
    changeIdTokoAddAudit: (state, action) => {
      state.idToko = action.payload;
    },
    changeSubHubIdAddAudit: (state, action) => {
      state.subHubId = action.payload;
    },
    changeMiniHubIdAddAudit: (state, action) => {
      state.minHubId = action.payload;
    },
    changeDescriptionAddAudit: (state, action) => {
      state.description = action.payload;
    },
    changeIdAddAudit: (state, action) => {
      state.idAddAudit = action.payload;
    },
    changeIsQrExist: (state, action) => {
      state.isQrExist = action.payload;
    },

    changeIsLoadingAddAudit: (state, action) => {
      state.isLoading = action.payload;
    },
    loadAllDataHub: (state, action) => {
      state.hub = action.payload;
    },
    loadAllDataSubHub: (state, action) => {
      state.subHub = action.payload;
    },
    loadAllDataMiniHub: (state, action) => {
      state.miniHub = action.payload;
    },

    changeHubs: (state, action) => {
      state.hubs = action.payload;
    },
    changeStartAuditing: (state, action) => {
      state.startAuditing = action.payload;
    },

    changeScannedData: (state, action) => {
      const existingData = state.scannedData.find(
        (item) => item.id === action.payload.id
      );
      if (existingData) {
        state.scannedData = state.scannedData.map((item) =>
          item.id === action.payload.id
            ? [...state.scannedData, action.payload]
            : [...state.scannedData, item]
        );
      } else {
        state.scannedData = [...state.scannedData, action.payload];
      }
    },
  },
});

export const {
  changeTable,
  changeIdTokoAddAudit,
  changeSubHubIdAddAudit,
  changeMiniHubIdAddAudit,
  changeDescriptionAddAudit,
  changeIdAddAudit,
  changeIsQrExist,

  changeIsLoadingAddAudit,
  loadAllDataHub,
  loadAllDataSubHub,
  loadAllDataMiniHub,

  changeHubs,
  changeStartAuditing,
  changeScannedData,
} = addAuditState.actions;

export const getTableAddAudit = (state) => state.addAudit.table;
export const getIdTokoAddAudit = (state) => state.addAudit.idToko;
export const getSubHubIdAddAudit = (state) => state.addAudit.subHubId;
export const getMiniHubIdAddAudit = (state) => state.addAudit.minHubId;
export const getDescriptionAddAudit = (state) => state.addAudit.description;
export const getIdAddAudit = (state) => state.addAudit.idAddAudit;
export const getIsQrExist = (state) => state.addAudit.isQrExist;

export const getDataHubAddAudit = (state) => state.addAudit.hub;
export const getDataSubHubAddAudit = (state) => state.addAudit.subHub;
export const getDataMiniHubAddAudit = (state) => state.addAudit.miniHub;

export const getHubsAddAudit = (state) => state.addAudit.hubs;
export const getStartAuditing = (state) => state.addAudit.startAuditing;
export const getDataScanned = (state) => state.addAudit.scannedData;

// ! -------------------- API -----------------
// get hub
export const getAllMasterHubAddAudit = (params) => (dispatch) => {
  const fetchDataHub = async () => {
    dispatch(changeIsLoadingAddAudit(true));
    try {
      const {
        data: {
          data: { hubs },
        },
      } = await getAllHubs(params);
      let sliceData = hubs.map((hub, _) => {
        return { ...hub, typeHub: 'hub' };
      });

      let fixDatas = sliceData?.map((data, _) => {
        return {
          id: data?.idToko,
          label: data?.namaToko,
          typeHub: data?.typeHub,
        };
      });
      dispatch(loadAllDataHub(fixDatas));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoadingAddAudit(false));
    }
  };
  fetchDataHub();
};

// get sub hub
export const getAllSubHubAddAudit = (params) => (dispatch) => {
  const fetchDataSubHub = async () => {
    dispatch(changeIsLoadingAddAudit(true));
    try {
      const {
        data: {
          data: { subHubs },
        },
      } = await getAllSubHub(params);
      let sliceDataSubHub = subHubs.map((subHub, _) => {
        return { ...subHub, typeHub: 'sub_hub' };
      });

      let fixDatasSubHub = sliceDataSubHub?.map((data, _) => {
        return {
          id: data?.id,
          label: data?.name,
          typeHub: data?.typeHub,
        };
      });
      dispatch(loadAllDataSubHub(fixDatasSubHub));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoadingAddAudit(false));
    }
  };
  fetchDataSubHub();
};

// get mini hub
export const getAllMiniHubAddAudit = (params) => (dispatch) => {
  const fetchDataMiniHub = async () => {
    dispatch(changeIsLoadingAddAudit(true));
    try {
      const {
        data: {
          data: { subHubs },
        },
      } = await getAllMiniHub(params);
      let sliceDataMiniHub = subHubs.map((miniHub, _) => {
        return { ...miniHub, typeHub: 'mini_hub' };
      });

      let fixDatasMiniHub = sliceDataMiniHub?.map((data, _) => {
        return {
          id: data?.id,
          label: data?.name,
          typeHub: data?.typeHub,
        };
      });
      dispatch(loadAllDataMiniHub(fixDatasMiniHub));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoadingAddAudit(false));
    }
  };
  fetchDataMiniHub();
};
export default addAuditState.reducer;
