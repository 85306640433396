import { Box } from '@mui/material';
import { useState } from 'react';
import { CustomTable3 } from '../../../../../../components/shared/shared2';

const headTables = [
  {
    key: 'photo',
    altKey: '',
    name: 'Photo',
    maxWidht: '',
    align: 'start',
    short: false,
  },
  {
    key: 'name',
    altKey: 'name',
    name: 'name',
    maxWidht: '',
    align: 'start',
    short: true,
  },
  {
    key: 'category',
    altKey: '',
    name: 'Category',
    maxWidht: '',
    align: 'start',
    short: true,
  },
  {
    key: 'cogs',
    altKey: '',
    name: 'COGS(Rp)*',
    maxWidht: '',
    align: 'start',
    short: true,
  },
  {
    key: 'rrp',
    altKey: '',
    name: 'RRP(Rp)*',
    maxWidht: '',
    align: 'start',
    short: true,
  },
  {
    key: 'qty',
    altKey: '',
    name: 'Qty',
    maxWidht: '',
    align: 'start',
    short: true,
  },
];

const RackItemTable = () => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [dataTables, setDataTables] = useState([]);
  const [tablePage, setTablePage] = useState(1);
  const [tablePageSize, setTablePageSize] = useState(5);
  const [totalTableData, setTotalTableData] = useState(1);
  const [sortBased, setSortBased] = useState('name');
  const [isSortAsc, setIsSortAsc] = useState(true);
  const [searchTable, setSearchTable] = useState('');

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  return (
    <>
      <CustomTable3
        title="Products List"
        totalData={totalTableData}
        datas={dataTables}
        headTables={headTables}
        loading={isTableLoading}
        handleChangePageSizeTable={(value) => setTablePageSize(value)}
        handleBackPage={(value) => setTablePage(value)}
        handleNextPage={(value) => setTablePage(value)}
        handleInputPage={(value) => setTablePage(value)}
        handleSortPage={(key, isAsc) => {
          setSortBased(key);
          setIsSortAsc(isAsc);
        }}
        handleSearchPage={(value) => {
          setSearchTable(value);
        }}
        defaultShort={sortBased}
        keyCell="code"
      ></CustomTable3>
    </>
  );
};
export default RackItemTable;
