import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchStockOut from "../Component/SearchStockout";
import {
  IconInvoice,
  IconNotFound,
  IconSearch,
} from "../../../../../../assets";
import { SearchNotFound } from "../Mobile/Dashboard";
import SearchFound from "./SearchFound";
import { useDispatch } from "react-redux";
import { setSearchStockout } from "../../../../../../store/stockout/stockout";

const InventoryStockOutDesktop = ({
  setSearch,
  search,
  invoice,
  isLoading,
}) => {
  const [isFound, setIsFound] = useState(false);
  const dispatch = useDispatch();
  const [serachInv, setSearchInv] = useState("");

  useEffect(() => {
    if (invoice.length === 0) {
      setIsFound(true);
    } else {
      setIsFound(false);
    }
  }, [invoice]);

  const handleSearchInv = () => {
    dispatch(setSearchStockout(serachInv));
    setSearch(serachInv);
  };

  return (
    <Box>
      <Grid
        container
        columns={12}
        spacing={2}
        display={"flex"}
        alignItems={"center"}
      >
        <Grid item xs={11} lg={10} md={10}>
          <SearchStockOut
            placeholder={"Enter invoice ID"}
            {...{ setSearchInv }}
          />
        </Grid>

        <Grid item xs={1} lg={2} md={2} textAlign={"right"}>
          <Box
            sx={{
              height: "41px",
              weight: "",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#51B15C",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={handleSearchInv}
          >
            <IconSearch />
            <Typography
              fontStyle={"normal"}
              fontWeight={600}
              fontSize={"14px"}
              lineHeight={"21px"}
              color={"#fff"}
              ml={"5px"}
            >
              Search
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Content */}
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            my: "50px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            mt: "30px",
            background: "#fff",
            boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.05)",
            borderRadius: "10px",
          }}
        >
          {search === null || search === "" ? (
            <SearchNotFound
              title="Please enter invoice ID first"
              icon={<IconInvoice />}
            />
          ) : !isFound ? (
            <SearchFound
              invoice={invoice}
              {...{ setSearch }}
              search={search}
              isLoading={isLoading}
            />
          ) : (
            <SearchNotFound
              title="Oops! The invoce ID might be invalid."
              icon={<IconNotFound />}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default InventoryStockOutDesktop;
