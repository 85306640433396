import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import InventoryStockOut from "..";
import StockoutDetailHistory from "../Mobile/DetailHistory";
import { getAllStockOutRouting } from "../../../../../../store/Routing/stockOutRouting";
import StockoutExecute from "../Mobile/Execute";
import HubInvoice from "../Mobile/Dashboard/Hub";

const RoutingStockout = ({ setValue }) => {
  const { page } = useSelector(getAllStockOutRouting);
  return (
    <Box>
      {page === "" && <InventoryStockOut />}
      {page === "detailHistory" && <StockoutDetailHistory />}
      {/* {page === "execute" && <StockoutExecute />} */}
      {/* {page === 'addDifferenceAdjustment' && (
        <AddDifferenceAdjustment {...{ setValue }} />
      )} */}
      {/* {page === 'addMissingQrAdjustment' && (
        <AddMissingQrAdjustment {...{ setValue }} />
      )} */}

      {page === "execute" && <HubInvoice />}
    </Box>
  );
};
export default RoutingStockout;
