import { Box, Typography, useTheme } from '@mui/material';
import BackButton from '../../../../../../components/shared/BackButton';
import { ChevronRightIcon, QrCodeIcon } from '../../../../../../assets/Icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllRackhub,
  goBackDetailHub,
  goBackDetailRack,
  goBackMainRack,
  goToRackHubItemQr,
} from '../../../../../../store/racks/rackHub';

const BackButtonRack = () => {
  const dispatch = useDispatch();
  const { page } = useSelector(getAllRackhub);
  const handleBackButton = () => {
    switch (page) {
      case 'detail':
        dispatch(goBackMainRack());
        break;
      case 'edit':
        dispatch(goBackDetailRack());
        break;
      case 'itemQr':
        dispatch(goBackDetailRack());
        break;
      case 'item':
        dispatch(goBackDetailRack());
        break;
      default:
        dispatch(goBackDetailHub());
    }
  };

  const handleGoToItemQr = () => {
    dispatch(goToRackHubItemQr());
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        <Box>
          <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
            Racks
          </Typography>
          <Box
            sx={{
              display: 'flex',
              color: '#9E9D9D',

              alignItems: 'center',
              mt: '4px',
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
              Subsidiary
            </Typography>
            <ChevronRightIcon sx={{ fontSize: '16px' }} />
            <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
              Hub Name
            </Typography>
          </Box>
        </Box>
      </Box>
      {page === 'detail' && (
        <Box
          sx={{
            bgcolor: '#51B15C1A',
            width: '44px',
            height: '44px',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={handleGoToItemQr}
        >
          <QrCodeIcon />
        </Box>
      )}
    </Box>
  );
};
export default BackButtonRack;
