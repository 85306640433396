import {
  Box,
  Drawer,
  FilledInput,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  CustomCard2,
  LoadingComponent,
} from '../../../../../../components/shared/shared2';
import CustomInputWithMin from '../../../../../../components/shared/CustomInputWithMin';
import { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import CustomInput from '../../../../../../components/shared/CustomInput';
import CustomButton from '../../../../../../components/shared/CustomButton';
import { createNewSupplier } from '../../../../../../api/Supplier';
import { useDispatch } from 'react-redux';
import { setNotifPurchase } from '../../../../../../store/Routing/purchaseRouting';
import { useEffect } from 'react';
import CustomBottomDrawer from '../../../../../../components/shared/CustomBottomDrawer';
import dayjs from 'dayjs';

const AddSuppllier = ({ onClose, open }) => {
  const dispatch = useDispatch();
  const [companyId, setCompanyId] = useState('');
  const [supplierName, setSupplierName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dateJoin, setDateJoin] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);

  const fetchCreate = async (data) => {
    setIsLoading(true);
    try {
      await createNewSupplier(data);
      dispatch(
        setNotifPurchase({
          key: 'addSupplier',
          text: 'supplier has been success added',
        })
      );
      onClose();
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = () => {
    const data = {
      subsidiary: 1,
      namaSuppliers: supplierName,
      alamat: address,
      telpon: Number(phoneNumber),
      email: email,
      IDPerusahaan: companyId,
      tglGabung: dayjs(dateJoin.$d).format('YYYY-MM-DD'),
    };

    fetchCreate(data);
  };

  useEffect(() => {
    if (
      companyId &&
      supplierName &&
      address &&
      email &&
      phoneNumber &&
      dateJoin
    ) {
      return setIsSubmitAvailable(true);
    } else {
      return setIsSubmitAvailable(false);
    }
  }, [companyId, supplierName, address, email, phoneNumber, dateJoin]);

  const desktopMode = useMediaQuery('(min-width:830px)');

  return (
    <>
      {desktopMode && (
        <CustomCard2 title={'Add Supplier'} onClose={onClose} open={open}>
          {!isLoading && !isError && (
            <Grid container columns={2} spacing={4}>
              <Grid item xs={1}>
                <CustomInputWithMin
                  title={'Company ID'}
                  isMinSeen={false}
                  placeholder={'Enter Company ID'}
                  value={companyId}
                  type="number"
                  onChange={(e) => setCompanyId(e.target.value)}
                />
              </Grid>
              <Grid item xs={1}>
                <CustomInputWithMin
                  title={'Supplier Name*'}
                  isMinSeen={false}
                  placeholder={'Enter Supplier Name'}
                  value={supplierName}
                  onChange={(e) => setSupplierName(e.target.value)}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography
                  sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}
                >
                  Date Join*
                </Typography>
                <DatePicker
                  value={dateJoin}
                  onChange={(newValue) => {
                    setDateJoin(newValue);
                  }}
                  renderInput={(params) => {
                    return (
                      <CustomInput
                        {...params}
                        sx={{ width: '100%', bgcolor: '#FAFAFA' }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <CustomInputWithMin
                  title={'Address'}
                  isMinSeen={false}
                  placeholder={'Enter Address'}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={1}>
                <CustomInputWithMin
                  title={'Email'}
                  isMinSeen={false}
                  placeholder={'Enter Email'}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                />
              </Grid>
              <Grid item xs={1}>
                <CustomInputWithMin
                  title={'Phone Number'}
                  isMinSeen={false}
                  placeholder={'Enter Phone Number'}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Grid>
            </Grid>
          )}
          {isLoading && (
            <Box sx={{ width: '700px' }}>
              <LoadingComponent />
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              width: '100%',
              mt: '22px',
            }}
          >
            <CustomButton
              variant="contained"
              sx={{ width: '190px', height: '48px' }}
              disabled={!isSubmitAvailable}
              onClick={handleAdd}
            >
              Submit
            </CustomButton>
          </Box>
        </CustomCard2>
      )}
      {!desktopMode && (
        <CustomBottomDrawer
          open={open}
          handleClose={onClose}
          title={'Add Supplier'}
        >
          {!isLoading && !isError && (
            <>
              <Grid container columns={1} rowSpacing={2.6}>
                <Grid item xs={1}>
                  <TextField
                    id="filled-basic"
                    label={'Company ID*'}
                    variant="filled"
                    sx={{
                      width: '100%',
                      '.MuiFilledInput-root': {
                        bgcolor: '#FAFAFA',
                        height: '54px',
                      },
                      '.MuiInputLabel-root': {
                        color: '#9E9E9E',
                      },
                    }}
                    type="number"
                    value={companyId}
                    onChange={(e) => setCompanyId(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    id="filled-basic"
                    label={'Supplier Name'}
                    variant="filled"
                    sx={{
                      width: '100%',
                      '.MuiFilledInput-root': {
                        bgcolor: '#FAFAFA',
                        height: '54px',
                      },
                      '.MuiInputLabel-root': {
                        color: '#9E9E9E',
                      },
                    }}
                    value={supplierName}
                    onChange={(e) => setSupplierName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <DatePicker
                    value={dateJoin}
                    onChange={(newValue) => {
                      setDateJoin(newValue);
                    }}
                    renderInput={(params) => {
                      return (
                        <FormControl
                          variant="filled"
                          sx={{
                            width: '100%',
                            '.MuiFilledInput-root': {
                              bgcolor: '#FAFAFA',
                              height: '54px',
                            },
                            '.MuiInputLabel-root': {
                              color: '#9E9E9E',
                            },
                          }}
                        >
                          <InputLabel htmlFor="input-with-icon-adornment">
                            Date
                          </InputLabel>
                          <FilledInput
                            id="input-with-icon-adornment"
                            variant="filled"
                            label="Date"
                            sx={{
                              width: '100%',
                              '.MuiFilledInput-root': {
                                bgcolor: '#FAFAFA',
                                height: '54px',
                              },
                              '.MuiInputLabel-root': {
                                color: '#9E9E9E',
                              },
                            }}
                            {...params}
                          />
                        </FormControl>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    id="filled-basic"
                    label={'Address'}
                    variant="filled"
                    sx={{
                      width: '100%',
                      '.MuiFilledInput-root': {
                        bgcolor: '#FAFAFA',
                        height: '54px',
                      },
                      '.MuiInputLabel-root': {
                        color: '#9E9E9E',
                      },
                    }}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    id="filled-basic"
                    label={'Email'}
                    variant="filled"
                    sx={{
                      width: '100%',
                      '.MuiFilledInput-root': {
                        bgcolor: '#FAFAFA',
                        height: '54px',
                      },
                      '.MuiInputLabel-root': {
                        color: '#9E9E9E',
                      },
                    }}
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    id="filled-basic"
                    label={'Phone Number'}
                    variant="filled"
                    sx={{
                      width: '100%',
                      '.MuiFilledInput-root': {
                        bgcolor: '#FAFAFA',
                        height: '54px',
                      },
                      '.MuiInputLabel-root': {
                        color: '#9E9E9E',
                      },
                    }}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: '24px' }}>
                <CustomButton
                  variant="contained"
                  sx={{ height: '40px', width: '100%' }}
                  disabled={!isSubmitAvailable}
                  onClick={handleAdd}
                >
                  Submit
                </CustomButton>
              </Box>
            </>
          )}
          {isLoading && (
            <Box sx={{ heigt: '200px' }}>
              <LoadingComponent />
            </Box>
          )}
        </CustomBottomDrawer>
      )}
    </>
  );
};
export default AddSuppllier;
