import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { fetchUnits } from '../../../../../../../../api/products';
import { getDetailProduct } from '../../../../../../../../api/purchase';
import { AlertIcon } from '../../../../../../../../assets/Icons';

const QtySelect = ({
  idProduct,
  handleValue,
  firstValueQty,
  firstValueUnit,
  isReset,
  setIsReset,
  isDisableQty,
  isDisableUnit,
}) => {
  const matchTablet = useMediaQuery('(min-width:832px)');

  const [qtyValue, setQtyValue] = useState('');
  const [qtyValueFix, setQtyValueFix] = useState('');

  const [unitSelect, setUnitSelect] = useState('');
  const [unitSelectFix, setUnitSelectFix] = useState('');

  const [isUnitOnFocus, setIsUnitOnFocus] = useState(false);

  const [unitDatas, setUnitDatas] = useState([]);
  const [unitLoading, setUnitLoading] = useState(false);
  const [limitOrder, setLimitOrder] = useState(null);
  const [notifLimit, setNotfLimit] = useState('');

  useEffect(() => {
    const fetchProductUnit = async () => {
      try {
        // setIsLoadingUnit(true);
        const {
          data: { data },
        } = await getDetailProduct(idProduct);
        // console.log({ data: data });
        setUnitDatas(data?.productUnits);
        setQtyValue(data?.orderLimitMin === null ? 0 : data?.orderLimitMin);
        const idMinUnits = data?.productUnits?.find(
          (item) => item.unit.name === data?.stokMinUnit
        );
        setLimitOrder({
          min: data?.orderLimitMin === null ? 0 : data?.orderLimitMin,
          max: data?.orderLimitMax,
          minUnit: { unit: data?.stokMinUnit, id: idMinUnits?.unit.id }, //TODO: validasi disini bsok
          maxUnit: data?.stokMaxUnit,
        }); // TODO : min unit dan max unit nmasih ngambil dari stok karena di limit hanya mencamtumkan id
        // cek.push(data);
        // setIsLoadingUnit(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchProductUnit();
  }, [idProduct]);

  useEffect(() => {
    if (limitOrder?.min !== undefined) {
      if (Number(qtyValue) !== '' && Number(qtyValue) < limitOrder?.min) {
        setNotfLimit((prevState) => 'the product is under min order limit a');
      } else if (Number(qtyValue) < limitOrder?.min) {
        setNotfLimit((prevState) => 'the product is under min order limit b');
      } else if (unitSelect?.id > limitOrder?.minUnit?.id) {
        setNotfLimit((prevState) => 'the product is under min order limit c');
      } else {
        setNotfLimit('');
      }
    } else {
      setNotfLimit('');
    }
  }, [limitOrder, qtyValue, unitSelect]);

  console.log({
    limitOrder,

    test: unitSelect?.id > limitOrder?.minUnit?.id,
  });

  useEffect(() => {
    if (firstValueQty !== 0) {
      setQtyValue(firstValueQty);
      setUnitSelect(firstValueUnit);
    }
  }, []);
  useEffect(() => {
    if (isReset) {
      setQtyValue(firstValueQty);
      setUnitSelect(firstValueUnit);
      setIsReset(false);
    }
  }, [isReset]);

  useEffect(() => {
    if (unitSelect !== unitSelectFix || qtyValue !== qtyValueFix) {
      return handleValue(qtyValue, unitSelect, notifLimit);
      return setUnitSelectFix(unitSelect);
      return setQtyValueFix(qtyValue);
    }
  }, [unitSelect, unitSelectFix, qtyValue, qtyValueFix]);

  console.log({ unitSelect });

  return (
    <>
      {!matchTablet ? (
        <FormControl variant="outlined" sx={{ bgcolor: '#FAFAFA' }}>
          {notifLimit && (
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 400,
                mb: 2,
                color: '#FD7900',
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
              }}
            >
              <AlertIcon />
              {notifLimit}
            </Typography>
          )}

          <OutlinedInput
            placeholder="Qty*"
            type="number"
            value={qtyValue}
            onChange={(e) => setQtyValue(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <FormControl>
                  {unitSelect === '' && (
                    <InputLabel id="unit-label">
                      {limitOrder?.minUnit?.unit}
                    </InputLabel>
                  )}
                  <Select
                    labelId=""
                    value={
                      unitSelect === ''
                        ? limitOrder?.minUnit?.unit
                        : unitSelect?.name
                    }
                    label="Unit"
                    onChange={(e) => {
                      setUnitSelect(e.target.value);
                    }}
                    onFocus={() => setIsUnitOnFocus(true)}
                    onBlur={() => setIsUnitOnFocus(false)}
                    sx={{
                      minWidth: '100px',
                      height: '40px',
                      lineHeight: '40px',
                      bgcolor: 'white',
                      '.MuiOutlinedInput-input': {
                        paddingTop: '12px',
                        paddingBottom: '12px',
                        paddingLeft: '12px',
                        paddingRight: '0',
                        borderRadius: '5px',
                      },
                    }}
                  >
                    {unitDatas.map((unitData, index) => (
                      <MenuItem value={unitData?.unit} key={index}>
                        {unitData?.unit?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
          />
        </FormControl>
      ) : (
        <FormControl
          variant="outlined"
          sx={{ bgcolor: '#FAFAFA', minWidth: '100%' }}
        >
          <OutlinedInput
            disabled={isDisableQty}
            placeholder="Enter Qty"
            type="number"
            // value={qtyValue}
            // onChange={(e) => setQtyValue(e.target.value)}
            value={qtyValue}
            onChange={(e) => setQtyValue(Number(e.target.value))}
            endAdornment={
              <InputAdornment position="end">
                <FormControl>
                  {unitSelect === '' && (
                    <InputLabel id="unit-label">
                      {limitOrder?.minUnit?.unit}
                    </InputLabel>
                  )}
                  <Select
                    disabled={isDisableUnit}
                    labelId=""
                    value={
                      unitSelect === '' ? limitOrder?.minUnit?.unit : unitSelect
                    }
                    label="Unit"
                    onChange={(e) => setUnitSelect(e.target.value)}
                    onFocus={() => setIsUnitOnFocus(true)}
                    onBlur={() => setIsUnitOnFocus(false)}
                    sx={{
                      minWidth: '130px',
                      bgcolor: 'white',
                      '.MuiOutlinedInput-input': {
                        paddingTop: '12px',
                        paddingBottom: '12px',
                        paddingLeft: '12px',
                        paddingRight: '0',
                        borderRadius: '5px',
                      },
                    }}
                  >
                    {unitDatas.map((unitData, index) => (
                      <MenuItem value={unitData?.unit?.name} key={index}>
                        {unitData?.unit?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
          />
        </FormControl>
      )}
    </>
  );
};

export default QtySelect;
