import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import CustomInput from "../../../../../../../components/shared/CustomInput";
import CustomCard from "../../../../../../../components/shared/CustomCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useDispatch, useSelector } from "react-redux";
import {
  changeErrorMessageVideo,
  changeImagePreview,
  changeIsCreateMap,
  changePicChoose,
  changePicPhone,
  changePicSearch,
  changePicSearchDebounce,
  changeStatus,
  changeSubHubName,
  changeVideoPreview,
  clearData,
  clickIsPreviewImage,
  clickIsVideoPreview,
  deleteSubHubImage,
  deleteVideo,
  deleteVideoPreview,
  getAddressFix,
  getCellSelectedDetailsCreateMap,
  getColCreateMap,
  getCoordinateFix,
  getEntranceCreateMap,
  getIdToko,
  getImagePreviewSubHubAdd,
  getIsCreateMap,
  getIsImagePreviewSubHubAdd,
  getIsLoadig,
  getIsMaxSizeImageSubHubAdd,
  getIsMaxSizeVideoSubHubAdd,
  getIsUploadImage,
  getIsVideoPreviewSubHubAdd,
  getPicChooseSubHubAdd,
  getPicPhoneSubHubAdd,
  getPicSearchDebounceSubHubAdd,
  getPicSearchSubHubAdd,
  getRowCreateMap,
  getSubHubAddAllState,
  getSubHubImage,
  getSubHubName,
  getSubsidiaryIdSubHub,
  getVideoPreviewSubHubAdd,
  getVideoSubHubAdd,
  getstatusSubHubAdd,
  inputSubHubImage,
  inputVideoPreview,
} from "../../../../../../../store/hub/subHub/subHubAdd";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
// import CreateMapSubHubAdd from './CreateMapMasterHubAdd';

import CloseIcon from "@mui/icons-material/Close";
import debounce from "lodash.debounce";
import {
  fetchPic,
  getPicList,
  getPicLoading,
} from "../../../../../../../store/pic/pic";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { Map } from "pigeon-maps";
import CustomAntSwitch from "../../../../../../../components/shared/CustomAntSwitch";
import CustomButton from "../../../../../../../components/shared/CustomButton";
import SetupAddressSubHub from "./CreateAddress/SetupAddress";
import CreateMapSubHubAdd from "./CreateMapSubHub";
import {
  addNewMultipleRack,
  addNewSubHub,
  addNewSubHubData,
} from "../../../../../../../api/hubs";
import { inputNotifHub } from "../../../../../../../store/hub/notifHub";

function FormAddSubHub({ setIsOpenNewSubHub, setSubHubSelected }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoadig);
  const isUploadImage = useSelector(getIsUploadImage);
  const subHubName = useSelector(getSubHubName);
  const name = useSelector(getSubHubName);
  const subsidiaryId = useSelector(getSubsidiaryIdSubHub);
  const idToko = useSelector(getIdToko);
  const {
    mapInputType,
    placeGetByName,
    placeGetByCoordinate,
    errorMessageVideo,
  } = useSelector(getSubHubAddAllState);

  // add image

  const subHubPhoto = useSelector(getSubHubImage);
  const isMaxSizeImage = useSelector(getIsMaxSizeImageSubHubAdd);
  const linkPhotoPreview = useSelector(getImagePreviewSubHubAdd);
  const isImagePreview = useSelector(getIsImagePreviewSubHubAdd);

  // add video
  const subHubVideo = useSelector(getVideoSubHubAdd);
  const isMaxSizeVideo = useSelector(getIsMaxSizeVideoSubHubAdd);
  const linkVideoPreview = useSelector(getVideoPreviewSubHubAdd);
  const isVideoPreview = useSelector(getIsVideoPreviewSubHubAdd);

  // add PIC
  const picList = useSelector(getPicList);
  const picLoading = useSelector(getPicLoading);
  const picChoose = useSelector(getPicChooseSubHubAdd);
  const picSearch = useSelector(getPicSearchSubHubAdd);
  const picSearchDebounce = useSelector(getPicSearchDebounceSubHubAdd);
  const picNumber = useSelector(getPicPhoneSubHubAdd);

  // add phone number
  const picPhone = useSelector(getPicPhoneSubHubAdd);

  // address map
  const isCreateMap = useSelector(getIsCreateMap);
  const col = useSelector(getColCreateMap);
  const row = useSelector(getRowCreateMap);
  const entrance = useSelector(getEntranceCreateMap);
  const dataDetailRack = useSelector(getCellSelectedDetailsCreateMap);

  const address = useSelector(getAddressFix);
  const [isMapOpen, setIsMapOpen] = useState(false);

  const status = useSelector(getstatusSubHubAdd);
  const coordinate = useSelector(getCoordinateFix);

  useEffect(() => {
    dispatch(fetchPic(picSearchDebounce));
  }, [picSearchDebounce]);

  const picSearchInDebounce = useRef(
    debounce((value) => {
      dispatch(changePicSearchDebounce(value));
    }, 1000)
  ).current;

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  // submit data
  const handleSubmit = () => {
    let datas = {
      subsidiaryId: subsidiaryId,
      name: name,
      idToko: idToko,
      mapColumn: col + 2,
      mapRow: row + 2,
      idKustomer: picChoose.idKustomer,
      status: status ? "active" : "inactive",
      address: address,
      nohp: picChoose?.telpon,
      lat: coordinate[0],
      lon: coordinate[1],
      typeInput: mapInputType === "manual" ? "manual" : "map",
    };

    if (subHubPhoto.length !== 0) {
      datas["images"] = subHubPhoto.join(",");
    }
    if (subHubVideo.length !== 0) {
      datas["videos"] = subHubVideo.join(",");
    }
    if (entrance.length !== 0) {
      datas["entrancePosition"] = entrance.join(",");
    }
    if (mapInputType === "manual") {
      const { formatted: citydistric } = placeGetByName;
      datas["cityDistrict"] = citydistric;
    } else {
      if (!placeGetByCoordinate?.statusCode) {
        const { formatted: citydistric } = placeGetByCoordinate;
        datas["cityDistrict"] = citydistric;
      }
    }

    const createRacks = async (id, idSubHub, nama) => {
      try {
        let racks = dataDetailRack.map((item) => {
          let value = {
            idToko: id,
            subHubId: idSubHub,
            miniHubId: null,
            name: item.name,
            images: "",
            videos: "",
            column: item.column,
            row: item.row,
            columnPosition: item.columnPosition.join(","),
            description: item.description,
            width: 0,
            height: 0,
            length: 0,
            weight: 0,
          };

          return value;
        });
        const { data } = await addNewMultipleRack(racks);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(
          inputNotifHub({
            key: "addsubhub",
            text: `${nama} has been successfully added`,
          })
        );
        setLoadingSubmit(false);
        dispatch(clearData());
        setIsOpenNewSubHub(false);
        setSubHubSelected(idToko);
      }
    };

    const createHub = async () => {
      setLoadingSubmit(true);
      try {
        const {
          data: { data },
        } = await addNewSubHub(datas);
        createRacks(data.idToko, data.id, data.name);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingSubmit(false);
      }
    };

    createHub();
  };

  return (
    <Box sx={{ mt: "16px" }}>
      {!isCreateMap ? (
        <Box>
          {/* add sub hub */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              minHeight: "70vh",
            }}
          >
            <Grid container columns={12} spacing={4}>
              {/* sub hub name */}
              <Grid item xs={12}>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{ fontSize: "17px", fontWeight: "bold", mb: 2 }}
                  >
                    Name
                  </Typography>
                  <Typography sx={{ color: "red" }}>*</Typography>
                </Box>
                <CustomInput
                  fullWidth
                  sx={{
                    with: "100%",
                    bgcolor: "#fafafa",
                    "& .MuiOutlinedInput-input": {
                      padding: "17px 16px",
                      borderRadius: "10px",
                      "&::placeholder": {
                        color: "#111",
                        fontSize: "16px",
                      },
                    },
                    borderRadius: "5px",
                  }}
                  placeholder="enter sub hub name"
                  value={subHubName}
                  onChange={(e) => {
                    if (e.target.value.length <= 30) {
                      dispatch(changeSubHubName(e.target.value));
                    }
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "17px",
                      mt: "5px",
                      ml: "15px",
                      color: "#9E9D9D",
                    }}
                  >
                    {subHubName.length > 5 ? "" : "Enter at least 5 characters"}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      mt: "5px",
                      ml: "15px",
                      color: "#9E9D9D",
                    }}
                  >
                    {subHubName.length}/30
                  </Typography>
                </Box>
              </Grid>

              {/* image */}
              <Grid item xs={6} sx={{ mt: 1 }}>
                <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
                  <Box sx={{ display: "flex" }}>
                    <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                      Photo
                    </Typography>
                    <Typography sx={{ color: "red" }}>*</Typography>
                  </Box>
                  <Typography sx={{ fontSize: "17px" }}>
                    (max size: 2MB)
                  </Typography>
                  {isMaxSizeImage && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ErrorOutlineIcon
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: theme.palette.warning.main,
                          mr: "4px",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: theme.palette.warning.main,
                        }}
                      >
                        The photo can't be more than 2MB
                      </Typography>
                    </Box>
                  )}
                </Box>

                {/* add & preview */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  {/* preview */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {subHubPhoto.length === 0 && (
                      <Box
                        sx={{
                          height: "54px",
                          width: "54px",
                          aspectRatio: "1/1",
                          borderRadius: "8px",
                          bgcolor: "#F5F5F5",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderStyle: "dashed",
                          borderColor: "#9E9D9D",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          document.getElementById("input_img").click();
                        }}
                      >
                        <ImageOutlinedIcon
                          sx={{ color: "#9E9D9D", fontSize: "25px" }}
                        />
                      </Box>
                    )}
                    {subHubPhoto.map((input, index) => (
                      <Box
                        sx={{
                          position: "relative",
                          overflow: "visible",
                          padding: "7px",
                        }}
                        key={index}
                      >
                        <CancelIcon
                          sx={{
                            position: "absolute",
                            fontSize: "20px",
                            top: 1,
                            right: 1,
                            zIndex: +1,
                            cursor: "pointer",
                          }}
                          onClick={() => dispatch(deleteSubHubImage(input))}
                        />
                        <Box
                          component="img"
                          src={
                            process.env.REACT_APP_API_IMAGE_BASE_URL +
                            "/" +
                            input
                          }
                          sx={{
                            height: "54px",
                            width: "54px",
                            aspectRatio: "1/1",
                            borderRadius: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            dispatch(clickIsPreviewImage());
                            dispatch(changeImagePreview(input));
                          }}
                        />
                      </Box>
                    ))}
                  </Box>

                  {/* add image */}
                  <Box
                    sx={{
                      display: "flex",
                      mb: "7px",
                      ml: "20px",
                      alignItems: "center",
                    }}
                  >
                    {subHubPhoto.length < 3 && (
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: theme.palette.primary.main,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            document.getElementById("input_img").click();
                          }}
                        >
                          <input
                            accept="image/*"
                            id="input_img"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => dispatch(inputSubHubImage(e))}
                          />
                          {!isUploadImage ? (
                            <>
                              <AddIcon />
                              <Typography
                                sx={{ fontSize: "17px", fontWeight: "500" }}
                              >
                                Add More
                              </Typography>
                            </>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <CircularProgress size={18} />
                              <Typography> uploading...</Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>

              {/* video */}
              <Grid item xs={6} sx={{ mt: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    pb: `${subHubVideo.length === 0 ? "16px" : "9px"}`,
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Box sx={{ display: "flex" }}>
                        <Typography
                          sx={{ fontSize: "17px", fontWeight: "bold" }}
                        >
                          Video
                        </Typography>
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Box>
                      <Typography sx={{ fontSize: "17px" }}>
                        (max size: 100MB)
                      </Typography>
                    </Box>

                    {isMaxSizeVideo && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ErrorOutlineIcon
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: theme.palette.warning.main,
                            mr: "4px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: "8px",
                            fontWeight: "500",
                            color: theme.palette.warning.main,
                          }}
                        >
                          The video is too big
                        </Typography>
                      </Box>
                    )}

                    {errorMessageVideo && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ErrorOutlineIcon
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: theme.palette.warning.main,
                            mr: "4px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontWeight: "500",
                            color: theme.palette.warning.main,
                          }}
                        >
                          {errorMessageVideo}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>

                {/* add & preview */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  {/* preview */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {subHubVideo.length === 0 && (
                      <Box
                        sx={{
                          height: "54px",
                          width: "54px",
                          aspectRatio: "1/1",
                          borderRadius: "8px",
                          bgcolor: "#F5F5F5",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderStyle: "dashed",
                          borderColor: "#9E9D9D",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          document.getElementById("input_video").click();
                        }}
                      >
                        <VideocamOutlinedIcon
                          sx={{ color: "#9E9D9D", fontSize: "25px" }}
                        />
                      </Box>
                    )}
                    {subHubVideo.map((input, index) => (
                      <Box
                        sx={{
                          position: "relative",
                          overflow: "visible",
                          padding: "7px",
                        }}
                        key={index}
                      >
                        <CancelIcon
                          sx={{
                            position: "absolute",
                            fontSize: "20px",
                            top: 1,
                            right: 1,
                            zIndex: +100,
                            cursor: "pointer",
                          }}
                          onClick={() => dispatch(deleteVideoPreview(input))}
                        />
                        <Box
                          onClick={() => {
                            dispatch(clickIsVideoPreview());
                            dispatch(changeVideoPreview(input));
                          }}
                        >
                          <video
                            style={{
                              maxHeight: "54px",
                              maxWidth: "54px",
                              aspectRatio: "1/1",
                              borderRadius: "8px",
                              cursor: "pointer",
                              backgroundColor: "black",
                            }}
                          >
                            <source
                              src={
                                process.env.REACT_APP_API_IMAGE_BASE_URL +
                                "/" +
                                input +
                                "#t=0.5"
                              }
                            />
                          </video>
                        </Box>
                      </Box>
                    ))}
                  </Box>

                  {/* add video */}
                  <Box
                    sx={{
                      display: "flex",
                      mb: "7px",
                      ml: "20px",
                      alignItems: "center",
                    }}
                  >
                    {subHubVideo.length < 3 && (
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: theme.palette.primary.main,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            document.getElementById("input_video").click();
                          }}
                        >
                          <input
                            // accept="video/*"
                            id="input_video"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => dispatch(inputVideoPreview(e))}
                          />
                          {!isLoading ? (
                            <>
                              <AddIcon />
                              <Typography
                                sx={{ fontSize: "17px", fontWeight: "500" }}
                              >
                                Add More
                              </Typography>
                            </>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <CircularProgress size={18} />
                              <Typography> uploading...</Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>

              {/* PIC */}
              <Grid item xs={6} sx={{ mt: 1 }}>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{ mb: 2, fontSize: "17px", fontWeight: "bold" }}
                  >
                    PIC
                  </Typography>
                  <Typography sx={{ color: "red" }}>*</Typography>
                </Box>
                <Autocomplete
                  options={picList}
                  value={picChoose}
                  inputValue={picSearch}
                  getOptionLabel={(option) => option.namaLengkap}
                  loading={picLoading}
                  onChange={(_, newValue) => {
                    dispatch(changePicChoose(newValue));
                    dispatch(changePicPhone(newValue.telpon));
                  }}
                  onInputChange={(_, newInputValue) => {
                    dispatch(changePicSearch(newInputValue));
                    picSearchInDebounce(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search"
                      sx={{ bgcolor: "#fafafa", borderRadius: "5px" }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li key={option.idKustomer} {...props}>
                      {option.namaLengkap}
                    </li>
                  )}
                />
              </Grid>

              {/* phone number */}
              <Grid item xs={6} sx={{ mt: 1 }}>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{ mb: 2, fontSize: "17px", fontWeight: "bold" }}
                  >
                    Phone Number
                  </Typography>
                  <Typography sx={{ color: "red" }}>*</Typography>
                </Box>
                <CustomInput
                  disabled
                  sx={{
                    width: "100%",
                    bgcolor: "#fafafa",
                    borderRadius: "5px",
                  }}
                  type="number"
                  placeholder="Enter Phone Number"
                  value={picChoose?.telpon || "-"}
                />
              </Grid>

              {/* address */}
              <Grid item xs={6} sx={{ mt: 1 }}>
                <CustomCard
                  sx={{ p: "8px 16px", cursor: "pointer" }}
                  onClick={() => setIsMapOpen(!isMapOpen)}
                >
                  <Box
                    sx={{
                      display: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Box
                        sx={{
                          width: "66px",
                          height: "66px",
                          bgcolor: "gray",
                          borderRadius: "8px",
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            bgcolor: "white",
                            width: "100%",
                            height: "100%",
                            zIndex: "+2",
                            opacity: 0.5,
                          }}
                        ></Box>
                        <Box
                          sx={{
                            maxHeight: "66px",
                            overflow: "hidden",
                            borderRadius: "8px",
                          }}
                        >
                          <Map
                            defaultCenter={[
                              3.546958571415798, 98.6901696840363,
                            ]}
                            height={130}
                            defaultZoom={17}
                            width={66}
                            style={{
                              borderRadius: "8px",
                            }}
                          ></Map>
                        </Box>
                      </Box>
                      <Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            sx={{ fontWeight: "bold", mb: "4px", ml: "8px" }}
                          >
                            Address
                          </Typography>
                          <Typography sx={{ color: "red" }}>*</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.4,
                          }}
                        >
                          <LocationOnIcon color="primary" />
                          <Typography>
                            {address ? address : "Click to enter address"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <KeyboardArrowDownIcon />
                  </Box>
                </CustomCard>
              </Grid>

              {/* rack */}
              <Grid item xs={6} sx={{ mt: 1 }}>
                <CustomCard
                  sx={{ p: "8px 16px", cursor: "pointer" }}
                  onClick={() => dispatch(changeIsCreateMap())}
                >
                  <Box
                    sx={{
                      display: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Box
                        sx={{
                          height: "66px",
                          borderRadius: "8px",
                        }}
                      ></Box>
                      <Box sx={{ ml: "8px" }}>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              fontSize: "17px",
                              fontWeight: "bold",
                              mb: "10px",
                            }}
                          >
                            Racks Map
                          </Typography>
                          <Typography sx={{ color: "red" }}>*</Typography>
                        </Box>
                        <Typography sx={{ color: "#ccc", fontSize: "16px" }}>
                          Click to create map
                        </Typography>
                      </Box>
                    </Box>
                    <KeyboardArrowDownIcon />
                  </Box>
                </CustomCard>
              </Grid>
            </Grid>

            {/* submit */}
            <Box
              sx={{
                bottom: 1,
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                  Status
                </Typography>
                <CustomAntSwitch
                  value={status}
                  onChange={() => dispatch(changeStatus())}
                />
              </Box>

              {loadingSubmit && <CircularProgress size={16} />}
              <CustomButton
                variant="contained"
                // disabled={!isSubmitAvailable}
                onClick={handleSubmit}
                sx={{
                  width: "140px",
                  height: "36px",
                }}
              >
                {loadingSubmit ? (
                  <CircularProgress size={16} />
                ) : (
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    Submit
                  </Typography>
                )}
              </CustomButton>
            </Box>
          </Box>

          {/* dialog address */}
          <Dialog
            open={isMapOpen}
            onClose={() => {
              setIsMapOpen(!isMapOpen);
            }}
          >
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>Input Address</Box>
              <CloseIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setIsMapOpen(false)}
              />
            </DialogTitle>
            <DialogContent dividers>
              <SetupAddressSubHub setIsOpen={setIsMapOpen} />
            </DialogContent>
          </Dialog>

          {/* dialog image */}
          <Dialog
            open={isImagePreview}
            onClose={() => {
              dispatch(clickIsPreviewImage());
              dispatch(changeImagePreview(""));
            }}
          >
            <Box
              component="img"
              src={
                process.env.REACT_APP_API_IMAGE_BASE_URL +
                "/" +
                linkPhotoPreview
              }
              sx={{ width: "600px", height: "600px", aspectRation: "1/1" }}
            />
          </Dialog>

          {/* dialog video */}
          <Dialog
            open={isVideoPreview}
            onClose={() => {
              dispatch(clickIsVideoPreview());
              dispatch(changeVideoPreview(""));
            }}
          >
            <Box sx={{ bgcolor: "black" }}>
              <video
                style={{
                  width: "600px",
                  height: "600px",
                  backgroundColor: "black",
                }}
                controls
              >
                <source
                  src={
                    process.env.REACT_APP_API_IMAGE_BASE_URL +
                    "/" +
                    linkVideoPreview
                  }
                />
              </video>
            </Box>
          </Dialog>
        </Box>
      ) : (
        <CreateMapSubHubAdd />
      )}
    </Box>
  );
}
export default FormAddSubHub;
