import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import BackButtonHub from '../../../../../../components/shared/BackButtonHub';
import { ChevronRightIcon, CloseIcon } from '../../../../../../assets/Icons';
import CustomCard from '../../../../../../components/shared/CustomCard';
import CustomButton from '../../../../../../components/shared/CustomButton';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSubsidiaryList,
  getSubsidiaryList,
} from '../../../../../../store/subsidiary/subsidiary';
import { getHubsStockOut } from '../../../../../../api/stockout';
import {
  changeConfirmExecution,
  changeHubType,
  changeIdOrder,
  changeSelectedHub,
  getAllDataStockOut,
} from '../../../../../../store/stockout/stockout';

const ChooseHubInvoice = ({ openHub, setOpenHub, execute, setExecute }) => {
  const dispatch = useDispatch();
  const { search, idOrder } = useSelector(getAllDataStockOut);
  const listSubsidiary = useSelector(getSubsidiaryList);
  const [newSubsidiary, setNewSubsidiary] = useState(null);
  const [openAddHub, setOpenAddHub] = useState(false);
  const [selectedTypeHub, setSelectedTypeHub] = useState('');
  const [hubDatas, setHubDatas] = useState([]);
  const [selectedHub, setSelectedHub] = useState(null);

  const handleChange = (event) => {
    setSelectedTypeHub(event.target.value);
  };

  useEffect(() => {
    dispatch(fetchSubsidiaryList());
  }, []);

  const fetchHubs = async () => {
    const idOrders = execute.map((item) => item?.id);
    const params = {
      id_order: idOrders,
      hub_type: selectedTypeHub,
      subsidiary: newSubsidiary?.id,
    };

    try {
      const {
        data: { data: data },
      } = await getHubsStockOut(search, params);
      if (data?.statusCode !== 500) {
        setHubDatas(data);
      } else {
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (selectedTypeHub !== '') {
      fetchHubs();
    } else {
    }
  }, [selectedTypeHub]);

  useEffect(() => {
    if (openAddHub === false) {
      setSelectedTypeHub('');
    }
  }, [openAddHub]);

  const handleEnter = () => {
    const idOrders = execute.map((item) => item?.id);
    dispatch(changeIdOrder(idOrders));
    dispatch(changeHubType(selectedTypeHub));
    dispatch(changeSelectedHub(selectedHub?.id));
    setOpenHub(false);
    setExecute([]);
    dispatch(changeConfirmExecution(true));
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <BackButtonHub
              onClick={() => {
                setExecute([]);
                setOpenHub(!openHub);
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ color: '#9E9D9D', fontSize: '24px' }}>
                Stock Out
              </Typography>
              <ChevronRightIcon />
              <Typography sx={{ color: '#000', fontSize: '24px' }}>
                Execute
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/* 
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Typography sx={{ fontSize: '16px' }}>Supplier</Typography>
            <Typography sx={{ color: '#F33A3A' }}>*</Typography>
          </Box>
          <Autocomplete
            // disabled={isDisableProduct}
            sx={{ minWidth: '100%', bgcolor: '#FAFAFA' }}
            // inputValue={inputValue}
            // onInputChange={(event, newInputValue) => {
            //   setInputValue(newInputValue);
            //   inputDebounceChange(newInputValue);
            // }}
            // options={datasFix}
            // getOptionLabel={(option) => option.namaProduk}
            // value={product}
            // onChange={(e, newValue) => setProduct(newValue)}
            // loading={isLoading}
            renderInput={(params) => (
              <TextField {...params} placeholder="Product*" />
            )}
          />
        </Grid> */}
        {/* subsidiary */}
        <Grid item xs={6}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            value={newSubsidiary}
            options={listSubsidiary}
            getOptionLabel={(option) => option.name}
            onChange={(_, newValue) => setNewSubsidiary(newValue)}
            sx={{ bgcolor: '#FAFAFA' }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Subsidiary"
                sx={{ color: '#9E9E9E' }}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomCard
            sx={{ padding: '10px', cursor: 'pointer' }}
            onClick={() => newSubsidiary !== null && setOpenAddHub(!openAddHub)}
          >
            <Box sx={{ display: 'flex', mb: 1 }}>
              <Typography sx={{ fontSize: '16px' }}>Hub</Typography>
              <Typography sx={{ color: '#F33A3A' }}>*</Typography>
            </Box>
            <Typography sx={{ fontSize: '14px' }}>Hub name</Typography>
          </CustomCard>
        </Grid>
      </Grid>

      {/* dialog select hub */}
      {/* dialogn Add */}
      <Dialog open={openAddHub} onClose={() => setOpenAddHub(!openAddHub)}>
        <DialogTitle sx={{ boxShadow: '0px 0px 3px 0px #000' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>Hub</Typography>
            </Box>
            <CloseIcon onClick={() => setOpenAddHub(!openAddHub)} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container columns={12}>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ fontSize: '16px', color: '#000' }}>
                  Type
                </Typography>
                <Typography sx={{ color: '#F33A3A' }}>*</Typography>
              </Box>
              <Box>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={selectedTypeHub}
                    onChange={handleChange}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <FormControlLabel
                        value="master_hub"
                        control={<Radio />}
                        label="Master Hub"
                      />
                      <FormControlLabel
                        value="sub_hub"
                        control={<Radio />}
                        label="Sub Hub"
                      />
                      <FormControlLabel
                        value="mini_hub"
                        control={<Radio />}
                        label="Mini Hub"
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Typography sx={{ fontSize: '15px', fontWeight: 600, mb: 2 }}>
                Hub
              </Typography>
              <Autocomplete
                sx={{ minWidth: '100%', bgcolor: '#FAFAFA' }}
                // inputValue={inputValue}
                // onInputChange={(event, newInputValue) => {
                //   setInputValue(newInputValue);
                //   inputDebounceChange(newInputValue);
                // }}
                options={hubDatas}
                getOptionLabel={(option) => option.hubName}
                value={selectedHub}
                onChange={(event, newValue) => {
                  setSelectedHub(newValue);
                }}
                // loading={isLoadingProduct}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Product*" />
                )}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              gap: 2,
              mt: 5,
            }}
          >
            <CustomButton
              variant={'contained'}
              style={{ height: '40px', width: '100px' }}
              onClick={handleEnter}
            >
              {/* {!isLoadingProduct ? (
                <CircularProgress size={18} sx={{ color: '#FFF' }} />
              ) : ( */}
              <Typography
                fontStyle={'normal'}
                fontWeight={500}
                fontSize={'14px'}
                lineHeight={'150%'}
                color={'#fff'}
              >
                Enter
              </Typography>
              {/* )} */}
            </CustomButton>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default ChooseHubInvoice;
