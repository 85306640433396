import {
  Box,
  Checkbox,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { ReactComponent as Cogs } from '../../../../../../assets/cogs.svg';
import { ReactComponent as Sales } from '../../../../../../assets/sales.svg';
import { ReactComponent as Used } from '../../../../../../assets/used2.svg';
import { ReactComponent as Order } from '../../../../../../assets/order.svg';

const CustomTableCellHead = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '12 px',
  textTransform: 'capitalize',

  padding: '8px 0px',
});
const CustomTableCellBody = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '12px',
  height: '10px',
  textTransform: 'capitalize',

  padding: '0px',
});
function TableSupplier() {
  return (
    <Box sx={{ py: '16px', pr: '24px', mt: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'end', mb: '4px' }}></Box>

      {/* table */}
      <Table>
        <TableHead>
          <TableRow>
            <CustomTableCellHead></CustomTableCellHead>
            <CustomTableCellHead>
              <Typography sx={{ fontWeight: 'bold', fontSize: '10px' }}>
                Hub
              </Typography>
            </CustomTableCellHead>
            <CustomTableCellHead>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '10px',
                    fontWeight: 'bold',
                    color: '#F1CC00',
                  }}
                >
                  SKU
                </Typography>
                <SyncAltIcon
                  sx={{
                    transform: 'rotate(90deg)',
                    fill: '#9E9D9D',
                    fontSize: '12px',
                  }}
                />
              </Box>
            </CustomTableCellHead>
            <CustomTableCellHead>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '10px',
                    fontWeight: 'bold',
                    color: '#51B15C',
                  }}
                >
                  COGS (Rp)*
                </Typography>
                <SyncAltIcon
                  sx={{
                    transform: 'rotate(90deg)',
                    fill: '#9E9D9D',
                    fontSize: '12px',
                  }}
                />
              </Box>
            </CustomTableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(5)].map((_, index) => (
            <TableRow key={index}>
              <CustomTableCellBody>
                <Checkbox
                  defaultChecked
                  sx={{
                    '&:before': {
                      display: 'block',
                      width: 16,
                      height: 16,
                    },
                  }}
                />
              </CustomTableCellBody>
              <CustomTableCellBody
                sx={{ fontSize: '10px', fontWeight: 'bold' }}
              >
                hub {index + 1}
              </CustomTableCellBody>
              <CustomTableCellBody
                align="center"
                sx={{ fontSize: '10px', fontWeight: 'bold' }}
              >
                34
              </CustomTableCellBody>
              <CustomTableCellBody
                align="center"
                sx={{ fontSize: '10px', fontWeight: 'bold' }}
              >
                43
              </CustomTableCellBody>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* pagination */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 1,
        }}
      >
        <Typography sx={{ fontSize: '10px', fontWeight: 'bold' }}>
          Total data * from *
        </Typography>
        <Typography sx={{ fontSize: '10px', fontWeight: 'bold' }}>
          {' '}
          1 of *{' '}
        </Typography>
      </Box>
    </Box>
  );
}
export default TableSupplier;
