import { Box, Grid, Typography } from '@mui/material';
import { LoadingComponent } from '../../../../../../../components/shared/shared2';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getAllAdminScan } from '../../../../../../../store/purchase/purchaseAdmin/purchaseProcessedAdmin';
import {
  changePage,
  setNotifPurchase,
} from '../../../../../../../store/Routing/purchaseRouting';
import {
  ArrowBackIosIcon,
  CameraIcon,
  ChevronRightIcon,
  QrCodeScannerRoundedIcon,
} from '../../../../../../../assets/Icons';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import ScannedDataAdmin from './ScannedDataAdmin';
import UnScannedDataAdmin from './UnScannedDataAdmin';
import { updateSentPurchaseSupplier } from '../../../../../../../api/rack';
import { getIdPurchaseDetail } from '../../../../../../../store/purchase/detailPurchase';

const PurchaseProcessedScanMobile = () => {
  const dispatch = useDispatch();
  const {
    isExist,
    scanned,
    idScanned,
    allData,
    detail,
    noqrid,
    noqr,
    isSelect,
  } = useSelector(getAllAdminScan);
  const [isScanned, setIsScanned] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const idPurchase = useSelector(getIdPurchaseDetail);
  const [disabledSend, setDisabledSend] = useState(false);

  const handleToScanMore = () => {
    dispatch(changePage('purchaseProsesScanAdminMobile'));
  };

  const fetchUpdatePurchase = async (data) => {
    setIsLoading(true);
    try {
      await updateSentPurchaseSupplier(data);
      dispatch(
        setNotifPurchase({
          key: 'purchaseProcess',
          text: 'purchase has been accepted',
        })
      );
      dispatch(changePage('detail'));
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const a =
      allData?.noqr?.filter((list) => !noqrid.flat().includes(list.idProduk))
        .length !== 0;

    const b =
      allData?.withqr?.filter((list) => !idScanned.includes(list.encript))
        .length !== 0;
    if (a === true && b === true) {
      setDisabledSend(true);
    } else {
      setDisabledSend(false);
    }
  }, [allData, idScanned, noqrid]);

  const handleSend = () => {
    const collect = {
      qrCodeId: idScanned,
      purchaseId: idPurchase,
      photo: noqrid,
      status: 'accepted',
    };

    fetchUpdatePurchase(collect);
  };

  const handleTosendNoqr = () => {
    dispatch(changePage('captureImagaProductAdmin'));
  };

  return (
    <Box sx={{ mt: '16px' }}>
      {!isLoading && !isError && (
        <>
          {/* back button */}
          <Box
            sx={{ py: '17px', display: 'flex', gap: 1, alignItems: 'center' }}
            onClick={() => dispatch(changePage('detail'))}
          >
            <Box
              sx={{
                width: '24px',
                height: '24px',
                bgcolor: '#51B15C0D',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ArrowBackIosIcon sx={{ fontSize: '14px', color: '#51B15C' }} />
            </Box>
            <Typography
              sx={{
                fontSize: '14px',
                color: '#9E9D9D',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Purchase Details <ChevronRightIcon />{' '}
            </Typography>
            <Typography sx={{ fontSize: '14px', color: '#000000' }}>
              Accept
            </Typography>
          </Box>

          <Grid
            container
            columns={2}
            columnSpacing={0.5}
            sx={{
              mt: '16px',
              p: '4px',
              bgcolor: '#F5F5F5',
              borderRadius: '8px',
            }}
          >
            {/* scanned */}
            <Grid item xs={1}>
              <Box
                sx={{
                  py: '8px',
                  borderRadius: '8px',
                  bgcolor: isScanned ? 'white' : '#F5F5F5',
                }}
                onClick={() => setIsScanned(true)}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    textAlign: 'center',
                    fontWeight: isScanned ? '600' : '500',
                    color: isScanned ? '#51B15C' : 'black',
                  }}
                >
                  Complete {scanned.length + noqr.flat().length}
                </Typography>
              </Box>
            </Grid>

            {/* unscanned */}
            <Grid item xs={1}>
              <Box
                sx={{
                  py: '8px',
                  borderRadius: '8px',
                  bgcolor: !isScanned ? 'white' : '#F5F5F5',
                }}
                onClick={() => setIsScanned(false)}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    textAlign: 'center',
                    fontWeight: !isScanned ? '600' : '500',
                    color: !isScanned ? '#51B15C' : 'black',
                  }}
                >
                  Incomplete (
                  {allData?.withqr?.filter(
                    (list) => !idScanned.includes(list.encript)
                  ).length +
                    allData?.noqr?.filter(
                      (list) =>
                        !noqrid.some((item) =>
                          item.id_detail.includes(list.idProduk)
                        )
                    ).length || 0}
                  )
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {/* view result scanned & unscanned */}
          <Box sx={{ minHeight: '70vh', bgcolor: 'white' }}>
            {isScanned ? <ScannedDataAdmin /> : <UnScannedDataAdmin />}
          </Box>

          {/* button */}
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              bgcolor: 'white',
              p: '20px',
              width: '100%',
              display: 'flex',
              gap: 0.5,
            }}
          >
            <CustomButton
              variant="contained"
              sx={{ width: '100%' }}
              onClick={() => handleSend()}
              disabled={disabledSend}
            >
              Accept
            </CustomButton>
            {isSelect ? (
              <Box
                sx={{
                  border: 2,
                  borderRadius: '8px',
                  borderColor: '#51B15C',
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => handleTosendNoqr()}
              >
                <CameraIcon sx={{ color: '#51B15C' }} />
              </Box>
            ) : (
              <Box
                sx={{
                  border: 2,
                  borderRadius: '8px',
                  borderColor: '#51B15C',
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => handleToScanMore()}
              >
                <QrCodeScannerRoundedIcon sx={{ color: '#51B15C' }} />
              </Box>
            )}
          </Box>
        </>
      )}
      {isLoading && <LoadingComponent />}
    </Box>
  );
};
export default PurchaseProcessedScanMobile;
