import {
  Box,
  Checkbox,
  IconButton,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import {
  ArrowBackIosIcon,
  ArrowForwardIosIcon,
  SyncAltIcon,
} from '../../assets/Icons';
import { useState } from 'react';

const CustomTableCellHead = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '12 px',
  textTransform: 'capitalize',

  padding: '8px 0px',
});
const CustomTableCellBody = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '12px',
  height: '10px',
  textTransform: 'capitalize',

  padding: '0px',
});

const CustomTableMobile = ({ rootName, rootValue, datas, color }) => {
  const [currentPageTable, setCurrentPageTable] = useState(1);
  const [checked, setChecked] = useState(true);

  return (
    <Box>
      {/* table */}
      <Table>
        <TableHead>
          <TableRow sx={{ background: '#FAFAFA' }}>
            <CustomTableCellHead></CustomTableCellHead>
            <CustomTableCellHead>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}
              >
                <Typography
                  sx={{ fontWeight: 600, fontSize: '10px', color: '#000' }}
                >
                  {rootName || 'Hub'}
                </Typography>
                <SyncAltIcon
                  sx={{
                    transform: 'rotate(90deg)',
                    fill: '#9E9D9D',
                    fontSize: '10px',
                  }}
                />
              </Box>
            </CustomTableCellHead>
            {datas.length !== 0 &&
              datas.map((item, idx) => (
                <CustomTableCellHead key={idx}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '10px',
                        fontWeight: 600,
                        color: color.length !== 0 ? color[idx] : '#000',
                      }}
                    >
                      {item?.label} (Rp)*
                    </Typography>
                    <SyncAltIcon
                      sx={{
                        transform: 'rotate(90deg)',
                        fill: '#9E9D9D',
                        fontSize: '10px',
                      }}
                    />
                  </Box>
                </CustomTableCellHead>
              ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rootValue.map((cek, index) => (
            <TableRow key={index}>
              <CustomTableCellBody>
                <Checkbox
                  // defaultChecked
                  // checked={checked}
                  sx={{
                    height: '25px',
                    '&:before': {
                      display: 'block',
                      width: 10,
                      height: 10,
                    },
                  }}
                />
              </CustomTableCellBody>
              <CustomTableCellBody sx={{ fontSize: '10px', fontWeight: 400 }}>
                {/* {console.log({ cek })} */}
                {cek}
              </CustomTableCellBody>
              {datas.map((item, idx) => (
                <CustomTableCellBody
                  key={idx}
                  align="center"
                  sx={{ fontSize: '8px', fontWeight: 400 }}
                >
                  {item?.chart[index]}
                </CustomTableCellBody>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* pagination */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 1,
          padding: '0px 12px',
        }}
      >
        <Typography sx={{ fontSize: '10px', fontWeight: 400, color: '#000' }}>
          *000
        </Typography>
        <Box sx={{ display: 'inline', mr: '10px' }}>
          <IconButton
            sx={{ p: 0 }}
            // onClick={handleBackArrow}
          >
            <ArrowBackIosIcon sx={{ color: '#323232', width: '10px' }} />
          </IconButton>
          <Box sx={{ display: 'inline', mr: '10px' }}>
            <OutlinedInput
              variant="outlined"
              type="number"
              value={currentPageTable}
              // onChange={handleInputCurrentPage}
              sx={{
                border: '1px solid #9E9D9D',
                width: '20px',
                borderRadius: '5px',
                textAlign: 'center',
                '& .MuiOutlinedInput-input': {
                  padding: 0,
                  fontSize: '10px',
                  boxShadow: 'none',
                  textAlign: 'center',
                  fontWeight: 600,
                },
                '&:before': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                '&:after': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                '&.Mui-focused': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    boxShadow: 'none',
                  },
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'inline',
              fontWeight: '600',
              fontSize: '10px',
              color: '#9E9D9D',
            }}
          >
            of *00
            {/* {Math.ceil(totalItemTable / pageSizeTable)} */}
          </Box>
          <Box sx={{ display: 'inline', ml: '10px' }}>
            <IconButton
              sx={{ p: 0 }}
              // onClick={handleForwardArrow}
            >
              <ArrowForwardIosIcon sx={{ color: '#323232', width: '10px' }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default CustomTableMobile;
