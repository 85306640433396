import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import BackButtonHub from "../../../../../../components/shared/BackButtonHub";
import { useDispatch, useSelector } from "react-redux";
import { changePageStock } from "../../../../../../store/Routing/stockRouting";
import CustomCard from "../../../../../../components/shared/CustomCard";
import ChartPerfomanceStock from "../Component/Chart";
import TablePerfomanceStock from "../Component/Table";
import { useEffect } from "react";
import { useState } from "react";
import dayjs from "dayjs";
import {
  getMasterHubIdStock,
  getMiniHubIdStock,
  getSubHubIdStock,
  getSubsidiaryIdStock,
} from "../../../../../../store/stock/stock";
import TableListStockAlert from "./TableListStockAlert";
import StockMobile from "../StockMobile";
import StockAlertMobile from "../StockMobile/StockAlertMobile/StockAlertMobile";

const StockAlert = () => {
  const dispatch = useDispatch();
  const subsidiaryId = useSelector(getSubsidiaryIdStock);
  const hubId = useSelector(getMasterHubIdStock);
  const subHubId = useSelector(getSubHubIdStock);
  const miniHubId = useSelector(getMiniHubIdStock);
  const matchTable = useMediaQuery("(min-width:832px)");

  // ! grafik & table

  const [timeFrame, setTimeFrame] = useState("daily");

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);
  return !matchTable ? (
    <>
      <StockAlertMobile />
    </>
  ) : (
    <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <BackButtonHub onClick={() => dispatch(changePageStock(""))} />
          <Typography
            sx={{
              color: "#000000",
              fontSize: "24px",
              fontWeight: 600,
              cursor: "pointer",
            }}
          >
            Stock Alert
          </Typography>
        </Box>
      </Box>

      <Grid container columns={12} spacing={3}>
        {/* grafik */}
        <Grid item xs={12}>
          <CustomCard sx={{ mt: 2, p: 2 }}>
            <Grid container columns={12} spacing={4}>
              {/* grafik */}
              <Grid item xs={6}>
                <ChartPerfomanceStock
                  {...{
                    timeFrame,
                    setTimeFrame,
                    dateRange,
                    setDateRange,
                    saveDateRange,
                    setSaveDateRange,
                  }}
                />
              </Grid>

              {/* table */}
              <Grid item xs={6}>
                <TablePerfomanceStock />
              </Grid>
            </Grid>
          </CustomCard>
        </Grid>

        {/* list data table */}
        <Grid item xs={12}>
          <TableListStockAlert />
        </Grid>
      </Grid>
    </Box>
  );
};
export default StockAlert;
