import { Grid } from '@mui/material';
import CustomCard from '../../../../../../components/shared/CustomCard';
import dayjs from 'dayjs';
import { useState } from 'react';
import ChartPurchase from './ChartGrafik';
import TablePurchase from './Table';
function GrafikComparisonPurchase() {
  const [timeFrame, setTimeFrame] = useState('daily');

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);
  return (
    <CustomCard sx={{ p: '10px 20px', mt: 2 }}>
      <Grid container columns={12} spacing={2}>
        <Grid item xs={8}>
          <ChartPurchase
            {...{
              timeFrame,
              setTimeFrame,
              dateRange,
              setDateRange,
              saveDateRange,
              setSaveDateRange,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TablePurchase />
        </Grid>
      </Grid>
    </CustomCard>
  );
}
export default GrafikComparisonPurchase;
