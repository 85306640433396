import { Box, Typography, useTheme } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const SeeProduct = (props) => {
  const theme = useTheme();
  const { page, setPage } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '58px',
        minHeight: '58px',
        bgcolor: '#51B15C1A',
        borderRadius: '8px',
        cursor: 'pointer',
      }}
      onClick={() => setPage('product')}
    >
      <Typography
        sx={{
          color: theme.palette.primary.main,
          fontSize: '16px',
          fontWeight: '600',
        }}
      >
        All Product
      </Typography>
      <KeyboardArrowRightIcon
        sx={{
          color: theme.palette.primary.main,
          fontSize: '25px',
          fontWeight: '600',
          ml: '10px',
        }}
      />
    </Box>
  );
};
export default SeeProduct;
