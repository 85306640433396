import { Box, Divider, Typography, useTheme } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { formatNumberToCurrency } from '../utils/currency';
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
} from '@mui/icons-material';

const TableCustomerHistories = ({ histories }) => {
  const theme = useTheme();

  const [selectedCustomer, setSelectedCustomer] = useState(
    histories.length > 0 ? histories[0].invoiceId : null
  );

  const [foundCustomer, setFoundCustomer] = useState(null);

  useEffect(() => {
    if (selectedCustomer) {
      setFoundCustomer(
        histories.find((history) => history.invoiceId === selectedCustomer)
      );
    } else {
      setFoundCustomer(null);
    }
  }, [selectedCustomer]);

  return (
    <Box>
      {histories.length === 0 && (
        <Typography sx={{ fontSize: '0.75rem', mt: 2.5 }}>No data</Typography>
      )}
      {histories.length > 0 && (
        <>
          {histories.length > 1 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                overflowX: 'auto',
                '&::-webkit-scrollbar': { display: 'none' },
                mt: 2.5,
              }}
            >
              {histories.map((history, index) => (
                <Box
                  key={history.invoiceId}
                  sx={{
                    px: 1.5,
                    py: 0.75,
                    borderRadius: '8px',
                    bgcolor:
                      selectedCustomer === history.invoiceId &&
                      `${theme.palette.primary.main}1A`,
                  }}
                  onClick={() => setSelectedCustomer(history.invoiceId)}
                >
                  <Typography
                    noWrap
                    sx={{
                      fontSize: '0.75rem',
                      fontWeight:
                        selectedCustomer === history.invoiceId
                          ? 'semiBold'
                          : 'medium',
                      color:
                        selectedCustomer === history.invoiceId
                          ? 'primary.main'
                          : '#9E9D9D',
                    }}
                  >
                    Invoice {index + 1}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}

          {foundCustomer && (
            <Box
              sx={{
                mt: 2.5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2.75,
                maxHeight: '75vh',
                overflowY: 'auto',
              }}
            >
              {/* table details */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 'medium', fontSize: '0.75rem' }}
                  >
                    Cust. Name
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 'semiBold', fontSize: '0.75rem' }}
                  >
                    {foundCustomer.customer || '-'}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 'medium', fontSize: '0.75rem' }}
                  >
                    Start
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 'semiBold', fontSize: '0.75rem' }}
                  >
                    {dayjs(foundCustomer.createdAt).format('HH:mm') || '-'}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 'medium', fontSize: '0.75rem' }}
                  >
                    Finish
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 'semiBold', fontSize: '0.75rem' }}
                  >
                    {dayjs(foundCustomer.updatedAt).format('HH:mm') || '-'}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 'medium', fontSize: '0.75rem' }}
                  >
                    Duration
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 'semiBold', fontSize: '0.75rem' }}
                  >
                    {foundCustomer.updatedAt
                      ? new Date(
                          dayjs(foundCustomer.updatedAt).diff(
                            foundCustomer.createdAt
                          )
                        )
                          .toISOString()
                          .substring(11, 19)
                      : '-'}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 'medium', fontSize: '0.75rem' }}
                  >
                    Payment Method
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 'semiBold', fontSize: '0.75rem' }}
                  >
                    {foundCustomer.payment || '-'}
                  </Typography>
                </Box>
              </Box>
              {/* orders details */}
              <Box
                sx={{
                  bgcolor: '#FAFAFA',
                  borderRadius: '8px',
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'semiBold',
                    fontSize: '0.875rem',
                    mb: 0.5,
                  }}
                >
                  Orders
                </Typography>
                {foundCustomer.invoiceOrders?.map((order, index) => (
                  <Fragment key={order.id}>
                    {index ? <Divider /> : null}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <Typography sx={{ fontSize: '0.75rem' }}>
                          {order.product?.name || '-'}
                        </Typography>
                        {order.statuses &&
                          order.statuses.length > 0 &&
                          (order.statuses.find((status) => status.quantity)
                            .name === 'served' ? (
                            <CheckCircleIcon
                              color="primary"
                              sx={{ fontSize: '0.875rem' }}
                            />
                          ) : order.statuses.find((status) => status.quantity)
                              .name === 'ongoing' &&
                            dayjs().diff(dayjs(order.dateTime), 'm') > 15 ? (
                            <ErrorIcon
                              sx={{ color: '#F33A3A', fontSize: '0.875rem' }}
                            />
                          ) : null)}
                      </Box>

                      <Typography
                        sx={{ fontWeight: 'medium', fontSize: '0.75rem' }}
                      >
                        {formatNumberToCurrency(order.product?.price || 0)}
                      </Typography>
                    </Box>
                  </Fragment>
                ))}
              </Box>
              {/* payment details */}
              <Box
                sx={{
                  bgcolor: '#FAFAFA',
                  borderRadius: '8px',
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'semiBold',
                    fontSize: '0.875rem',
                    mb: 0.5,
                  }}
                >
                  Payment Details
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: '0.75rem' }}>
                      Subtotal
                    </Typography>
                    <Typography
                      sx={{ fontSize: '0.75rem', fontWeight: 'medium' }}
                    >
                      {formatNumberToCurrency(foundCustomer.subtotal || 0)}
                    </Typography>
                  </Box>

                  {foundCustomer.priceDetails?.map((detail) => (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 1,
                      }}
                      key={detail.name}
                    >
                      <Typography sx={{ fontSize: '0.75rem' }}>
                        {detail.name || '-'}
                      </Typography>
                      <Typography
                        sx={{ fontSize: '0.75rem', fontWeight: 'medium' }}
                      >
                        {formatNumberToCurrency(detail.amount || 0)}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Divider />

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'semiBold',
                      fontSize: '0.875rem',
                    }}
                  >
                    Grand Total
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '0.875rem',
                      fontWeight: 'semiBold',
                    }}
                  >
                    {formatNumberToCurrency(foundCustomer.grandTotal || 0)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
export default TableCustomerHistories;
