// import './styles.scss';
import {
  Box,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import './style.css';
import { EditorContent, useEditor } from '@tiptap/react';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import { useEffect, useState } from 'react';
import TextAlign from '@tiptap/extension-text-align';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import CharacterCount from '@tiptap/extension-character-count';
import Placeholder from '@tiptap/extension-placeholder';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';

const MenuBar = ({ editor }) => {
  const [fontSize, setFontSize] = useState('Paragraph');

  const [desktopMode, setDesktopMode] = useState(true);

  useEffect(() => {
    if (window.screen.width <= 830) {
      setDesktopMode(false);
    } else {
      setDesktopMode(true);
    }
  }, [window.screen.width]);

  if (!editor) {
    return null;
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-end"
      sx={{
        paddingX: '20px',
        paddingTop: '5px',
        columnGap: '10px',
        rowGap: '5px',
      }}
    >
      <Box
        sx={{
          paddingY: 0,
          marginY: 0,
          outline: 'none',
          fontSize: '14px',
          display: 'inline',
        }}
      >
        {/* Font Size */}
        <Select
          value={fontSize}
          label="Font Size"
          variant="standard"
          onChange={(event) => setFontSize(event.target.value)}
          sx={{
            minWidth: '100px',
            fontSize: '14px',
            marginY: 0,
            paddingY: 0,
            lineHeight: '-1px',
            borderBottom: 'none',
            '&:before, &.MuiSelect-root': {
              borderBottom: 'none',
            },
          }}
        >
          {/* Title */}
          <MenuItem
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
            className={
              editor.isActive('heading', { level: 2 }) ? 'is-active' : ''
            }
            value={'Sub Title'}
            sx={{ fontSize: '14px', marginY: 0, paddingY: 0 }}
          >
            Title
          </MenuItem>
          {/* Sub Title */}
          <MenuItem
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            }
            className={
              editor.isActive('heading', { level: 3 }) ? 'is-active' : ''
            }
            value={'Title'}
            sx={{ fontSize: '14px', marginY: 0, paddingY: 0 }}
          >
            Sub Title
          </MenuItem>
          {/* Normal */}
          <MenuItem
            onClick={() => editor.chain().focus().setParagraph().run()}
            className={editor.isActive('paragraph') ? 'is-active' : ''}
            value={'Paragraph'}
            sx={{ fontSize: '14px', marginY: 0, paddingY: 0 }}
          >
            Normal
          </MenuItem>
        </Select>
      </Box>
      {/* Align Left */}
      <Box
        sx={{ display: 'inline', cursor: 'pointer' }}
        onClick={() => editor.chain().focus().setTextAlign('left').run()}
        className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
      >
        <Tooltip title="Align Left">
          <FormatAlignLeftIcon
            sx={{ fontSize: desktopMode ? '24px' : '16px' }}
          />
        </Tooltip>
      </Box>
      {/* Align Center */}
      <Box
        sx={{ display: 'inline', cursor: 'pointer' }}
        onClick={() => editor.chain().focus().setTextAlign('center').run()}
        className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
      >
        <Tooltip title="Align Center">
          <FormatAlignCenterIcon
            sx={{ fontSize: desktopMode ? '24px' : '16px' }}
          />
        </Tooltip>
      </Box>
      {/* Align Right */}
      <Box
        sx={{ display: 'inline', cursor: 'pointer' }}
        onClick={() => editor.chain().focus().setTextAlign('right').run()}
        className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
      >
        <Tooltip title="Align Right">
          <FormatAlignRightIcon
            sx={{ fontSize: desktopMode ? '24px' : '16px' }}
          />
        </Tooltip>
      </Box>
      {/* Font Color */}
      <Box sx={{ display: 'inline', paddingBottom: '2px' }}>
        <Tooltip title="Font Color">
          <input
            style={{
              height: desktopMode ? '30px' : '24px',
              width: desktopMode ? '30px' : '24px',
              border: 'none',
              background: 'transparent',
              cursor: 'pointer',
              padding: 'none',
            }}
            type="color"
            onInput={(event) =>
              editor.chain().focus().setColor(event.target.value).run()
            }
            value={editor.getAttributes('textStyle').color}
          />
        </Tooltip>
      </Box>
      {/* BOLD */}
      <Box
        sx={{ display: 'inline', cursor: 'pointer' }}
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
        <Tooltip title="Bold">
          <FormatBoldIcon sx={{ fontSize: desktopMode ? '24px' : '16px' }} />
        </Tooltip>
      </Box>
      {/* ITalic */}
      <Box
        sx={{ display: 'inline', cursor: 'pointer' }}
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? 'is-active' : ''}
      >
        <Tooltip title="Italic">
          <FormatItalicIcon sx={{ fontSize: desktopMode ? '24px' : '16px' }} />
        </Tooltip>
      </Box>
      {/* Underline */}
      <Box
        sx={{ display: 'inline', cursor: 'pointer' }}
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={editor.isActive('underline') ? 'is-active' : ''}
      >
        <Tooltip title="Underline">
          <FormatUnderlinedIcon
            sx={{ fontSize: desktopMode ? '24px' : '16px' }}
          />
        </Tooltip>
      </Box>
      {/* Strike */}
      <Box
        sx={{ display: 'inline', cursor: 'pointer' }}
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className={editor.isActive('strike') ? 'is-active' : ''}
      >
        <Tooltip title="Strikethrough">
          <StrikethroughSIcon
            sx={{ fontSize: desktopMode ? '24px' : '16px' }}
          />
        </Tooltip>
      </Box>
      {/* Bullet List */}
      <Box
        sx={{ display: 'inline', cursor: 'pointer' }}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >
        <Tooltip title="Bullets List">
          <FormatListBulletedIcon
            sx={{ fontSize: desktopMode ? '24px' : '16px' }}
          />
        </Tooltip>
      </Box>
      {/* Numbering List */}
      <Box
        sx={{ display: 'inline', cursor: 'pointer' }}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
        <Tooltip title="Numbering List">
          <FormatListNumberedIcon
            sx={{ fontSize: desktopMode ? '24px' : '16px' }}
          />
        </Tooltip>
      </Box>
      {/* Undo */}
      <Box
        sx={{ display: 'inline', cursor: 'pointer' }}
        onClick={() => editor.chain().focus().undo().run()}
      >
        <Tooltip title="Undo">
          <UndoIcon sx={{ fontSize: desktopMode ? '24px' : '16px' }} />
        </Tooltip>
      </Box>
      {/* Redo */}
      <Box
        sx={{ display: 'inline', cursor: 'pointer' }}
        onClick={() => editor.chain().focus().redo().run()}
      >
        <Tooltip title="Redo">
          <RedoIcon sx={{ fontSize: desktopMode ? '24px' : '16px' }} />
        </Tooltip>
      </Box>
    </Grid>
  );
};

const limit = 500;
const CustomTextFormatingInput = ({
  firstValue,
  setEditorContent,
  isError,
  setIsMinInputText,
  helperText,
  desktopMode,
}) => {
  const editor = useEditor({
    extensions: [
      Placeholder.configure({
        placeholder: 'Fill the description',
      }),
      TextStyle,
      StarterKit,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Color,
      Underline,
      CharacterCount.configure({
        limit,
      }),
    ],
    editorProps: {
      attributes: {
        class: 'focus:outline-none',
      },
    },
    content: firstValue,
    onUpdate({ editor }) {
      setEditorContent(editor.getHTML());
      if (editor.storage.characterCount.characters() < 10) {
        setIsMinInputText(false);
      } else {
        setIsMinInputText(true);
      }
    },
  });

  const theme = useTheme();
  if (!editor) {
    return null;
  }

  return (
    <Box>
      <Box
        sx={{
          border: '1px solid ',
          borderColor: '#F2F2F2',
          borderRadius: '8px',
        }}
      >
        <MenuBar editor={editor} />
        <Box
          sx={{
            background: '#FAFAFA',
            paddingY: !desktopMode ? '12px' : '15px',
            paddingX: !desktopMode ? '16px' : '32px',
            minHeight: '112px',
            borderRadius: '0 0 8px 8px',
            border: `${isError && `1px solid ${theme.palette.error.main}`}`,
          }}
        >
          <EditorContent editor={editor} />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '12px',
          marginTop: '5px',
        }}
      >
        {helperText ? (
          <helperText sx={{ fontSize: !desktopMode ? '8px' : '12px' }}>
            {helperText}
          </helperText>
        ) : (
          <Typography sx={{ fontSize: !desktopMode ? '8px' : '12px' }}>
            Enter at least 10 characters
          </Typography>
        )}

        <Typography sx={{ fontSize: !desktopMode ? '8px' : '12px' }}>
          {editor.storage.characterCount.characters()}/{limit}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomTextFormatingInput;
