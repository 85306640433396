import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.withCredentials = true;

export const alereAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const kanpaiAlereAxios = axios.create({
  baseURL: process.env.REACT_APP_ENAK_API_BASE_URL,
});

const controller = new AbortController();

alereAxios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

    if (config.data) config.data = decamelizeKeys(config.data);
    if (config.params) config.params = decamelizeKeys(config.params);
    config.signal = controller.signal;

    return config;
  },
  (error) => Promise.reject(error)
);

alereAxios.interceptors.response.use(
  (response) => camelizeKeys(response),
  (error) => {
    if (error?.response?.status === 40100 || error?.response?.status === 401) {
      localStorage.clear();
      controller.abort();
      window.location.reload();
      alert('Your session has expired');
    }

    return Promise.reject(error);
  }
);

kanpaiAlereAxios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

    if (config.data) config.data = decamelizeKeys(config.data);
    if (config.params) config.params = decamelizeKeys(config.params);
    config.signal = controller.signal;

    return config;
  },
  (error) => Promise.reject(error)
);

kanpaiAlereAxios.interceptors.response.use(
  (response) => camelizeKeys(response),
  (error) => {
    if (error?.response?.status === 40100 || error?.response?.status === 401) {
      localStorage.clear();
      controller.abort();
      window.location.reload();
      alert('Your session has expired');
    }

    return Promise.reject(error);
  }
);
