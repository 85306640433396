import { alereAxios } from '../utils/api';

// !----------- ALL ------------
export const fetchCategories = (params) =>
  alereAxios.get('categories', {
    params: params,
  });

export const fetchSubCategories = (params) =>
  alereAxios.get('categories/subs', {
    params: params,
  });

export const fetchAllMenus = (params) =>
  alereAxios.get('products', {
    params,
  });
// !----------- List ------------

export const allCategoryMenuName = (data) =>
  alereAxios.get('categories/list', {
    params: data,
  });

export const allSubCategoryMenuName = (data) =>
  alereAxios.get('categories/subs/list', {
    params: data,
  });

export const allMiniSubCategoryMenuName = (data) =>
  alereAxios.get('categories/minis/list', {
    params: data,
  });
export const allProductName = (data) =>
  alereAxios.get('products/list', {
    params: data,
  });

// !----------- ADD NEW ------------

export const addNewCategoryMenu = (data) => alereAxios.post('categories', data);

export const addNewSubCategoryMenu = (data) =>
  alereAxios.post('categories/subs', data);

export const addNewMiniSubCategoryMenu = (data) =>
  alereAxios.post('categories/minis', data);

export const addNewMenuMenu = (data) => alereAxios.post('menus', data);

// !----------- DETAILS ------------
export const fetchCategoryDetails = ({ startDate, endDate, id }) =>
  alereAxios.get(`categories/${id}`, { params: { startDate, endDate } });

export const fetchSubCategoryDetails = ({ startDate, endDate, id }) =>
  alereAxios.get(`categories/subs/${id}`, { params: { startDate, endDate } });

export const fetchMiniSubCategoryDetails = ({ startDate, endDate, id }) =>
  alereAxios.get(`categories/minis/${id}`, { params: { startDate, endDate } });

export const fetchMenuDetails = (id) => alereAxios.get(`menus/${id}`);

// !----------- EDIT  ------------

export const updateCategoryMenu = (id, data) =>
  alereAxios.put(`categories/${id}`, data);

export const updateSubCategoryMenu = (id, data) =>
  alereAxios.put(`categories/subs/${id}`, data);

export const updateMiniSubCategoryMenu = (id, data) =>
  alereAxios.put(`categories/minis/${id}`, data);

export const updateMenuMenu = (id, data) => alereAxios.put(`menus/${id}`, data);

// !----------- EDIT STATUS  ------------

export const updateCategoryMenuStatus = (id, data) =>
  alereAxios.put(`categories/${id}`, { status: data });

export const updateSubCategoryMenuStatus = (id, data) =>
  alereAxios.put(`categories/subs/${id}`, { subStatus: data });

export const updateMiniSubCategoryMenuStatus = (id, data) =>
  alereAxios.put(`categories/minis/${id}`, { status: data });

export const updateMenuMenuStatus = (id, data) =>
  alereAxios.put(`menus/${id}`, { status: data });

// !----------- Delete  ------------

export const deleteCategoryMenu = (id) => alereAxios.delete(`categories/${id}`);

export const deleteSubCategoryMenu = (id) =>
  alereAxios.delete(`categories/subs/${id}`);

export const deleteMiniSubCategoryMenu = (id) =>
  alereAxios.delete(`categories/minis/${id}`);

export const deleteMenuMenu = (id) => alereAxios.delete(`products/${id}`);

// !----------- ADD CATEGORY OPTIONS ------------
export const allTypeCategoryMenu = (data) =>
  alereAxios.get('categories/options', {
    params: data,
  });

export const fetchSubsidiary = () => alereAxios.get('subsidiaries');
