import { Drawer } from '@mui/material';

const CustomBottomDrawer = ({ open, onClose, children, padding = 2 }) => {
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          width: 1,
          p: padding,
        },
      }}
    >
      {children}
    </Drawer>
  );
};
export default CustomBottomDrawer;
