import { createSlice } from "@reduxjs/toolkit";
import { getListDataProductSupplier } from "../../api/Supplier";

export const dataProductSupplierState = createSlice({
  name: "dataProductSupplier",
  initialState: {
    datas: [],
    isLoadingData: false,
    pageInfo: null,
  },
  reducers: {
    loadDataProduct: (state, action) => {
      state.datas = action.payload;
    },
    changeIsLoadingProduct: (state, action) => {
      state.isLoadingData = action.payload;
    },
    changePageInfo: (state, action) => {
      state.pageInfo = action.payload;
    },
  },
});

export const { loadDataProduct, changeIsLoadingProduct, changePageInfo } =
  dataProductSupplierState.actions;

export const getAllDataProductSupplier = (state) => state.dataProductSupplier;

export default dataProductSupplierState.reducer;

// ! ---------------------- API ----------------------------
export const fetchAllProductSupplier = (datas) => (dispatch) => {
  const getData = async () => {
    try {
      dispatch(changeIsLoadingProduct(true));
      const {
        data: { data },
      } = await getListDataProductSupplier(datas);
      dispatch(loadDataProduct(data?.suppliers));
      dispatch(changePageInfo(data?.pageInfo));
      dispatch(changeIsLoadingProduct(false));
    } catch (err) {
      console.error(err);
    }
  };

  getData();
};
