import { useDispatch, useSelector } from 'react-redux';
import {
  changeIsEditTransaction,
  getAllDataDetailTransaction,
  getIdDetailTransactionRequest,
  getIsLoadingTransactionDetail,
  getSpesifikTransactionRequest,
  getUserEmployeeStatus,
  getisEditTransaction,
} from '../../../../../../../store/transaction/request/detailTransactionRequest';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import BackButtonHub from '../../../../../../../components/shared/BackButtonHub';
import { changePageTransaction } from '../../../../../../../store/Routing/transactionRouting';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import { goToTransactionRequestEdit } from '../../../../../../../store/transaction/request/editTransactionRequest';
import EditTransactionRequest from '../Edit/EditTransactionRequest';
import DetailTransactionRequestMobile from '../../TtansactionMobile/Request/Details/DetailTransactionRequestMobile';
import { deleteTransaction } from '../../../../../../../api/transaction';
import { DeleteOutlined } from '../../../../../../../assets/Icons';

const DetailRequestTransaction = () => {
  const matchTablet = useMediaQuery('(min-width:832px');
  const dispatch = useDispatch();
  const idTransaction = useSelector(getIdDetailTransactionRequest);
  const isLoading = useSelector(getIsLoadingTransactionDetail);
  const dataDetailTransaction = useSelector(getAllDataDetailTransaction);
  const isEdit = useSelector(getisEditTransaction);
  const status = dataDetailTransaction?.statusOrder;
  const userEmploye = useSelector(getUserEmployeeStatus);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    dispatch(getSpesifikTransactionRequest(idTransaction));
  }, [idTransaction]);

  const handleToEdit = () => {
    dispatch(goToTransactionRequestEdit(dataDetailTransaction));
    dispatch(changeIsEditTransaction(true));
  };

  const handleBack = () => {
    if (status === 'Baru') {
      dispatch(changePageTransaction('request'));
      dispatch(changeIsEditTransaction(false));
    } else {
      dispatch(changePageTransaction(''));
      dispatch(changeIsEditTransaction(false));
    }
  };

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    try {
      const data = await deleteTransaction(idTransaction);
      dispatch(changePageTransaction('request'));
      setIsLoadingDelete(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {!matchTablet ? (
        <DetailTransactionRequestMobile />
      ) : (
        <Box sx={{ paddingTop: 2, paddingLeft: 3 }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            {!isEdit ? (
              <BackButtonHub onClick={handleBack} />
            ) : (
              <BackButtonHub
                onClick={() => {
                  dispatch(changeIsEditTransaction(false));
                }}
              />
            )}

            <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
              {!isEdit ? 'Stock request details' : 'Stock request edit'}
            </Typography>
          </Box>

          {/* main */}
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '50vh',
              }}
            >
              <CircularProgress />
            </Box>
          ) : !isEdit ? (
            <>
              <Grid
                container
                columns={12}
                spacing={3}
                sx={{ paddingTop: '28px', marginBottom: '100px ' }}
              >
                {/* from */}
                <Grid item xs={6}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 500,
                        marginBottom: '12px',
                      }}
                    >
                      From
                    </Typography>
                    <Box
                      sx={{
                        background: '#FAFAFA',
                        borderRadius: '8px 8px 0px 0px',
                        padding: '16px 12px 14px 14px',
                      }}
                    >
                      <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                        {dataDetailTransaction?.namaTujuan || '_'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                {/* to */}
                <Grid item xs={6}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 500,
                        marginBottom: '12px',
                      }}
                    >
                      To
                    </Typography>
                    <Box
                      sx={{
                        background: '#FAFAFA',
                        borderRadius: '8px 8px 0px 0px',
                        padding: '16px 12px 14px 14px',
                      }}
                    >
                      <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                        {dataDetailTransaction?.namaPemberi || '_'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                {/* items */}
                <Grid item xs={12}>
                  <CustomCard>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: '20px',
                        boxShadow: '0px 1px 5px #ccc',
                      }}
                    >
                      <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
                        Item(s)
                      </Typography>
                    </Box>

                    <Box>
                      <Grid
                        container
                        columns={12}
                        spacing={4}
                        sx={{ p: '30px 20px' }}
                      >
                        {dataDetailTransaction?.details.map((item, index) => (
                          <Grid
                            item
                            xs={12}
                            sx={{ display: 'flex' }}
                            key={index}
                          >
                            <Grid container columns={12} spacing={3}>
                              {/* product */}
                              <Grid item xs={6}>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: 500,
                                      marginBottom: '12px',
                                    }}
                                  >
                                    Product*
                                  </Typography>
                                  <Box
                                    sx={{
                                      background: '#FAFAFA',
                                      borderRadius: '8px 8px 0px 0px',
                                      padding: '16px 12px 14px 14px',
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontSize: '14px', fontWeight: 500 }}
                                    >
                                      {item?.product?.namaProduk}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              {/* qty */}
                              <Grid item xs={6}>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: 500,
                                      marginBottom: '12px',
                                    }}
                                  >
                                    Qty*
                                  </Typography>
                                  <Box
                                    sx={{
                                      background: '#FAFAFA',
                                      borderRadius: '8px 8px 0px 0px',
                                      padding: '16px 12px 14px 14px',
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontSize: '14px', fontWeight: 500 }}
                                    >
                                      {item?.jumlah} {item?.unit}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </CustomCard>
                </Grid>
              </Grid>

              {/* button event */}

              {dataDetailTransaction?.statusOrder === 'finish' ||
              dataDetailTransaction?.statusOrder === 'reject' ||
              dataDetailTransaction?.statusOrder === 'sent' ? (
                <></>
              ) : (
                userEmploye === 'tujuan' && (
                  <Box
                    sx={{
                      width: '100%',
                      bgcolor: 'white',
                      position: 'fixed',
                      bottom: '0px',
                      right: '0px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      padding: '18px 32px 18px 240px',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        sx={{
                          width: '190px',
                          height: '48px',
                          // background: '#F33A3A',
                        }}
                        onClick={() => setConfirmDelete(true)}
                      >
                        <Typography
                          sx={{
                            color: 'white',
                            fontSize: '16px',
                            fontWeight: 600,
                          }}
                        >
                          Delete
                        </Typography>
                      </CustomButton>
                      <CustomButton
                        variant="contained"
                        sx={{ width: '190px', height: '48px' }}
                        onClick={() => handleToEdit()}
                      >
                        <Typography
                          sx={{
                            color: 'white',
                            fontSize: '16px',
                            fontWeight: 600,
                          }}
                        >
                          Edit
                        </Typography>
                      </CustomButton>
                    </Box>
                  </Box>
                )
              )}
            </>
          ) : (
            <EditTransactionRequest />
          )}
        </Box>
      )}

      {/* dialog delete */}
      <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <DialogTitle
          sx={{
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '12px 12px 0px 0px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box
              sx={{
                background: 'rgba(243, 58, 58, 0.1)',
                width: '44px',
                height: '44px',
                borderRadius: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <DeleteOutlined fill="#F33A3A" />
            </Box>
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              Delete Stock Request
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
            <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
              Are you sure you want to delete{' '}
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
              "{idTransaction}"
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              justifyContent: 'end',
              mt: 5,
            }}
          >
            <Typography
              onClick={() => setConfirmDelete(false)}
              sx={{
                color: '#9D9E9E',
                fontSize: '14px',
                fontWeight: 600,
                cursor: 'pointer',
              }}
            >
              Cancel
            </Typography>
            <CustomButton
              variant="contained"
              sx={{
                width: '100px',
                height: '40px',
                background: '#F33A3A',
              }}
              onClick={handleDelete}
            >
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '14px',
                  fontWeight: 600,
                }}
              >
                {isLoadingDelete ? <CircularProgress size={18} /> : 'Delete'}
              </Typography>
            </CustomButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default DetailRequestTransaction;
