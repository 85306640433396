import '@fontsource/poppins';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './context/AuthContext';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/store';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeightBold: 700,
    fontWeightSemiBold: 600,
    fontWeightMedium: 500,
  },
  palette: {
    primary: {
      main: '#51B15C',
      light: '#80C688',
      contrastText: '#fff',
      black: '#000000',
      grey: '#B0C6B5',
      lightGrey: '#fafafa',
      delete: '#F33A3A',
    },
    secondary: {
      main: '#44C4A1',
      light: '#9E9D9D',
    },
    ternary: {
      main: '#0E1D44',
    },
    error: {
      main: '#F26C6D',
      contrastText: '#fff',
    },
    success: {
      main: '#51B15C',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 1,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          borderRadius: 8,
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1)',
          },

          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1)',
            },
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: 0,
              boxShadow: '0px 0px 6px #51B15CFF',
            },
          },
          paddingLeft: 0,
          '& .MuiSvgIcon-root': { marginRight: '4px' },
        },
        input: {
          '&::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          // paddingInline: '8px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          color: '#000',
          '&.Mui-focused': {
            backgroundColor: '#fff',
            padding: '0 5px',
            '&.MuiInputLabel-filled': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'initial',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          left: 0,
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
