import { Box, Typography, Snackbar } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useState, useEffect } from 'react';
import { CloseIcon } from '../../assets/Icons';

const CustomNotifSuccess = ({ open, onClose, text, keys, ...props }) => {
  // *! -------------------------- Responsive -----------------------------
  // *! -------------------------------------------------------------------

  const [desktopMode, setDesktopMode] = useState(true);

  useEffect(() => {
    if (window.screen.width <= 830) {
      setDesktopMode(false);
    } else {
      setDesktopMode(true);
    }
  }, []);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={onClose}
      message="Has been success"
    >
      <Box
        onClose={onClose}
        sx={{
          width: desktopMode ? '100%' : 'auto',
          display: 'flex',
          justifyContent: 'center',
          bgcolor: '#EEF7EF',
          padding: '8px 16px 8px 12px',
          borderRadius: '12px',
          alignItems: 'center',
          ...props.sx,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: desktopMode ? '8px' : '2px',
            borderRadius: '50%',
            bgcolor: '#51B15C1A',
            mr: '24px',
          }}
        >
          {keys === 'nextAdd' ? (
            <CloseIcon sx={{ color: '#FFF', fontSize: '12px' }} />
          ) : (
            <CheckIcon sx={{ color: '#51B15C', fontSize: '12px' }} />
          )}
        </Box>
        <Typography
          sx={{ fontSize: desktopMode ? '16px' : '12px', fontWeight: '500' }}
        >
          {text}
        </Typography>
      </Box>
    </Snackbar>
  );
};

export default CustomNotifSuccess;
