import { Autocomplete, TextField } from '@mui/material';
import debounce from 'lodash.debounce';

import { useState, useEffect, useRef } from 'react';
import { allProductName } from '../../../../../../../api/menu';

const AutoCompleteProduct = ({
  handleValue,
  firstValueId,
  firstValue,
  isReset,
  setIsReset,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [inputDebounce, setInputDebounce] = useState('');

  const inputDebounceChange = useRef(
    debounce((value) => {
      setInputDebounce(value);
    }, 1000)
  ).current;

  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [productFix, setProductFix] = useState(null);

  useEffect(() => {
    const getProductName = async () => {
      setIsLoading(true);
      let datas = {
        page: 1,
        pageSize: 8,
        search: inputDebounce,
      };

      try {
        const {
          data: { data: dataNames },
        } = await allProductName(datas);
        setDatas(dataNames.products);
      } catch (err) {
        console.error(err);
        setDatas([]);
      } finally {
        setIsLoading(true);
      }
    };

    getProductName();
  }, [inputDebounce]);

  useEffect(() => {
    if (isReset) {
      return setProduct({
        idProduk: firstValueId,
        namaProduk: firstValue,
      });
      setIsReset(false);
    }
  }, [isReset]);

  useEffect(() => {
    if (firstValueId !== 0) {
      return setProduct({
        idProduk: firstValueId,
        namaProduk: firstValue,
      });
    }
  }, []);

  useEffect(() => {
    if (product !== productFix) {
      return handleValue(product.namaProduk, product.idProduk);
      return setProductFix(product);
    }
  }, [product, productFix]);

  return (
    <Autocomplete
      sx={{ minWidth: '280px', bgcolor: '#FAFAFA' }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        inputDebounceChange(newInputValue);
      }}
      options={datas}
      getOptionLabel={(option) => option.namaProduk}
      value={product}
      onChange={(e, newValue) => setProduct(newValue)}
      loading={isLoading}
      renderInput={(params) => (
        <TextField {...params} placeholder="Select Product" />
      )}
    />
  );
};

export default AutoCompleteProduct;
