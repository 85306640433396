import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { ReactComponent as ToLineChart } from '../../../../../../assets/to-line-chart.svg';
import { ReactComponent as Sales } from '../../../../../../assets/sales.svg';
import { ReactComponent as ToBarChart } from '../../../../../../assets/to-bar-chart.svg';

import { useState } from 'react';
import CustomDateRange from '../../../../../../components/shared/CustomDateRange';
import CustomLineChart from '../../../../../../components/shared/CustomLineChart';
import CustomBarChart from '../../../../../../components/shared/CustomBarChart';

// data dummy chart
const datas = {
  labels: [
    'jan',
    'feb',
    'mar',
    'apr',
    'mei',
    'jun',
    'jul',
    'augst',
    'sept',
    'oct',
    'nov',
    'dec',
  ],
  data: [
    {
      label: 'Product 1',
      chart: [12, 42, 45, 12, 67, 34, 23, 34, 23, 30, 50, 70],
    },
    {
      label: 'Product 2',
      chart: [92, 32, 45, 23, 34, 32, 76, 56, 87, 35, 50, 40],
    },
    {
      label: 'Product 3',
      chart: [34, 34, 54, 45, 65, 43, 65, 45, 34, 89, 50, 90],
    },
    {
      label: 'Product 4',
      chart: [34, 45, 34, 45, 65, 43, 34, 50, 90, 54, 65, 89],
    },
    {
      label: 'Product 5',
      chart: [45, 65, 34, 34, 54, 43, 34, 89, 50, 65, 45, 90],
    },
  ],
};

const color = ['#51B15C', '#4339F2', '#FEDD25', '#D839F2', '#FD3A29'];

const ChartProductSupplier = (props) => {
  const {
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
  } = props;

  const options = {
    plugins: {
      legend: {
        position: 'bottom',
        align: 'end',
        labels: {
          color: '#000',
          font: {
            size: 12,
            weight: 600,
            family: 'Poppins',
          },
          usePointStyle: true,
          pointStyle: 'rect',
        },
      },
    },
    scales: {
      y: {
        ticks: {
          color: '#B8B8B8',
          font: {
            size: 12,
            weight: 400,
            family: 'Poppins',
          },
        },
        title: {
          color: 'yellow',
          font: {
            size: 12,
            weight: 400,
            family: 'Poppins',
          },
        },
      },
      x: {
        ticks: {
          color: '#B8B8B8',
          font: {
            size: 12,
            weight: 400,
            family: 'Poppins',
          },
        },
        title: {
          display: true,
          color: '#B8B8B8',
          font: {
            size: 12,
            weight: 400,
            family: 'Poppins',
          },
        },
      },
    },
  };

  const [isBarChart, setIsBarChart] = useState(false);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
          Top Purchased Product
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center ' }}>
          {/* Select */}
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={timeFrame}
              onChange={(e) => setTimeFrame(e.target.value)}
              sx={{
                p: 0,
                '.MuiOutlinedInput-input': {
                  paddingTop: '7.5px',
                  paddingBottom: '7.5px',
                  paddingLeft: '10px',
                  paddingRight: '0',
                  borderRadius: '5px',
                },
                fontSize: '12px',
                mr: '12px',
              }}
            >
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
            </Select>
          </FormControl>
          <CustomDateRange
            dateRange={dateRange}
            setDateRange={setDateRange}
            saveDateRange={saveDateRange}
            setSaveDateRange={setSaveDateRange}
            sx={{ maxHeight: '34px', pl: '10px', fontSize: '12px' }}
          />
        </Box>
      </Box>

      {/* grafik */}

      <Box>
        <Grid container columns={3}>
          <Grid item xs={1}>
            <Box
              sx={{
                width: '30px',
                height: '30px',
                borderRadius: '8px',
                cursor: 'pointer',
                ml: 2,
              }}
            >
              <Sales fill="green" />
            </Box>
          </Grid>

          <Grid item xs={1}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  width: '26px',
                  height: '26px',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => setIsBarChart(!isBarChart)}
              >
                {isBarChart ? (
                  <ToLineChart stroke="#51B15C" />
                ) : (
                  <ToBarChart stroke="#51B15C" />
                )}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={1}>
            <Box></Box>
          </Grid>
        </Grid>

        <Box>
          {isBarChart ? (
            <CustomBarChart
              datas={datas}
              color={color}
              customOptions={options}
            />
          ) : (
            <CustomLineChart
              datas={datas}
              color={color}
              customOptions={options}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default ChartProductSupplier;
