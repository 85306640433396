import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Switch,
  Dialog,
  styled,
  Autocomplete,
} from '@mui/material';
import CustomInput from '../../../../../../components/shared/CustomInput';
import BackButton from '../../../../../../components/shared/BackButton';
import dayjs from 'dayjs';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import CustomDialog from '../../../../../../components/shared/CustomDialog';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import CustomCard from '../../../../../../components/shared/CustomCard';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AddSubCategoryInCategory from './Desktop/AddSubCategoryInCategory';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import CustomButton from '../../../../../../components/shared/CustomButton';

const CategoryProductAddMobile = (props) => {
  const {
    handleBackButton,
    categoryAddInputDatas,
    subsidiaryDatas,
    setCategoryAddInputDatas,
    theme,
    today,
    AntSwitch,
    isSubmitAvailable,
    handleAddCategory,
    handleAddImage,
    handleDeleteImage,
    // *----type ----
    typeCategoryOptions,
  } = props;
  return (
    <Grid container columns={12} spacing={2}>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
          Add Category
        </Typography>
      </Grid>
      {/* SUBSIDIARY */}
      <Grid item xs={12}>
        <Autocomplete
          sx={{ bgcolor: '#FAFAFA', borderRadius: '8px' }}
          value={categoryAddInputDatas.subsidiary}
          options={subsidiaryDatas}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => {
            setCategoryAddInputDatas({
              ...categoryAddInputDatas,
              subsidiaryId: parseInt(newValue.id),
              subsidiary: newValue,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Subsidiary*"
              sx={{
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
                width: '100%',
                '.MuiOutlinedInput-input': {
                  fontSize: '12px',
                  padding: '12px',
                },
              }}
            />
          )}
        />
      </Grid>
      {/* NAME */}
      <Grid item xs={12}>
        <CustomInput
          value={categoryAddInputDatas.name}
          onChange={(e) => {
            if (e.target.value.length <= 30) {
              setCategoryAddInputDatas({
                ...categoryAddInputDatas,
                name: e.target.value,
              });
            }
          }}
          placeholder="Name*"
          sx={{
            bgcolor: '#FAFAFA',
            borderRadius: '8px',
            width: '100%',
            '.MuiOutlinedInput-input': {
              fontSize: '12px',
              padding: '12px',
            },
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '8px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            Enter at least 5 character
          </Typography>
          <Typography
            sx={{
              fontSize: '8px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            {categoryAddInputDatas.name.length}/30
          </Typography>
        </Box>
      </Grid>
      {/* Type */}
      {/* <Grid item xs={12}>
        <Autocomplete
          options={typeCategoryOptions}
          getOptionLabel={(option) => option.nmOpsi}
          inputValue={categoryAddInputDatas.typeInput}
          onInputChange={(_, newValue) => {
            setCategoryAddInputDatas({
              ...categoryAddInputDatas,
              typeInput: newValue,
            });
          }}
          value={categoryAddInputDatas.type}
          onChange={(_, newValue) => {
            setCategoryAddInputDatas({
              ...categoryAddInputDatas,
              type: newValue,
              typeId: newValue.iDopsi,
              typeName: newValue.nmOpsi,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
                width: '100%',
                '.MuiOutlinedInput-input': {
                  fontSize: '12px',
                  padding: '12px',
                },
              }}
              placeholder="Select Type"
            />
          )}
          loading={categoryAddInputDatas.typeLoading}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            Enter at least 20 character
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            {categoryAddInputDatas.description.length}/250
          </Typography>
        </Box>
      </Grid> */}
      {/* PHOTO */}
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            pb: `${categoryAddInputDatas.photo.length === 0 ? '16px' : '9px'}`,
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
            Photo
          </Typography>
          <Typography
            sx={{
              fontSize: '8px',
              fontWeight: '500',
              color: '#9E9D9D',
            }}
          >
            ( max size : 2MB )
          </Typography>
          {categoryAddInputDatas.isMaxSizePhoto && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ErrorOutlineIcon
                sx={{
                  fontSize: '8px',
                  fontWeight: '500',
                  color: theme.palette.warning.main,
                  mr: '4px',
                }}
              />
              <Typography
                sx={{
                  fontSize: '8px',
                  fontWeight: '500',
                  color: theme.palette.warning.main,
                }}
              >
                The photo can't be more than 2MB
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {categoryAddInputDatas.photo.length === 0 && (
              <Box
                sx={{
                  height: '54px',
                  width: '54px',
                  aspectRatio: '1/1',
                  borderRadius: '8px',
                  bgcolor: '#F5F5F5',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderStyle: 'dashed',
                  borderColor: '#9E9D9D',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  document.getElementById('input_img').click();
                }}
              >
                <ImageOutlinedIcon
                  sx={{ color: '#9E9D9D', fontSize: '28px' }}
                />
              </Box>
            )}
            {categoryAddInputDatas.photo.map((input, index) => (
              <Box
                sx={{
                  position: 'relative',
                  overflow: 'visible',
                  padding: '7px',
                }}
                key={index}
              >
                <CancelIcon
                  sx={{
                    position: 'absolute',
                    fontSize: '20px',
                    top: 1,
                    right: 1,
                    zIndex: +1,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleDeleteImage(input, index)}
                />
                <Box
                  component="img"
                  src={URL.createObjectURL(input)}
                  sx={{
                    height: '54px',
                    width: '54px',
                    aspectRatio: '1/1',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setCategoryAddInputDatas({
                      ...categoryAddInputDatas,
                      indexPreviePhoto: index + 1,
                      isPreviewPhoto: 'true',
                    });
                  }}
                />
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: 'flex',
              mb: '7px',
              ml: '20px',
              alignItems: 'center',
            }}
          >
            {categoryAddInputDatas.photo.length < 3 && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.palette.primary.main,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  document.getElementById('input_img').click();
                }}
              >
                <input
                  accept="image/*"
                  id="input_img"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleAddImage}
                />
                <AddIcon sx={{ fontSize: '16px' }} />
                <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
                  Add More
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      {/* Dialog Photo */}
      {categoryAddInputDatas.indexPreviePhoto !== 0 && (
        <Dialog
          open={categoryAddInputDatas.isPreviewPhoto}
          onClose={() => {
            setCategoryAddInputDatas({
              ...categoryAddInputDatas,
              isPreviewPhoto: !categoryAddInputDatas.isPreviewPhoto,
            });
          }}
        >
          <Box
            component="img"
            src={URL.createObjectURL(
              categoryAddInputDatas.photo[
                categoryAddInputDatas.indexPreviePhoto - 1
              ]
            )}
            sx={{ width: '100%', aspectRation: '1/1' }}
          />
        </Dialog>
      )}
      {/* description */}
      <Grid item xs={12}>
        <CustomInput
          sx={{
            width: '100%',
            bgcolor: '#FAFAFA',
            '.MuiOutlinedInput-input': {
              fontSize: '12px',
              padding: '12px',
            },
          }}
          placeholder="Description"
          value={categoryAddInputDatas.description}
          onChange={(e) => {
            if (e.target.value.length <= 250) {
              setCategoryAddInputDatas({
                ...categoryAddInputDatas,
                description: e.target.value,
              });
            }
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '8px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            Enter at least 20 character
          </Typography>
          <Typography
            sx={{
              fontSize: '8px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            {categoryAddInputDatas.description.length}/250
          </Typography>
        </Box>
      </Grid>
      {/* Schedule */}
      <Grid item xs={12} sx={{}}>
        <Box sx={{ display: 'flex', minWidth: '100%' }}>
          <DesktopDatePicker
            inputFormat="DD/MM/YYYY"
            value={categoryAddInputDatas.schedule}
            onChange={(newValue) => {
              if (categoryAddInputDatas.scheduleType !== 'now') {
                setCategoryAddInputDatas({
                  ...categoryAddInputDatas,
                  schedule: newValue,
                });
              } else {
                setCategoryAddInputDatas({
                  ...categoryAddInputDatas,
                  schedule: today,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  width: '100%',
                  bgcolor: '#FAFAFA',
                  '.MuiOutlinedInput-input': {
                    fontSize: '12px',
                    padding: '12px',
                  },
                }}
              />
            )}
          />
        </Box>
      </Grid>
      {/* STATUS */}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
          Publish
        </Typography>
        <AntSwitch
          checked={categoryAddInputDatas.publish}
          onChange={(e) => {
            setCategoryAddInputDatas({
              ...categoryAddInputDatas,
              publish: !categoryAddInputDatas.publish,
            });
          }}
        />
      </Grid>
      {/* SUBMIT */}
      <Grid item xs={12} sx={{ mt: '28px' }}>
        <CustomButton
          disabled={!isSubmitAvailable}
          onClick={handleAddCategory}
          variant="contained"
          sx={{
            width: '100%',
          }}
        >
          Submit
        </CustomButton>
      </Grid>
    </Grid>
  );
};
export default CategoryProductAddMobile;
