import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  useTheme,
} from '@mui/material';

const CustomSelect = ({
  inputLabel,
  value,
  children,
  variant,
  isError,
  isRequired,
  helperText,
  isUsingInputLabel = true,
  ...props
}) => {
  const theme = useTheme();
  return (
    <FormControl error={isError} required={isRequired} variant={variant}>
      {isUsingInputLabel ? (
        <InputLabel sx={{ lineHeight: 1.5 }} id="selectInputLabel">
          {inputLabel}
        </InputLabel>
      ) : (
        ''
      )}
      <Select
        value={value}
        sx={{
          '&. MuiOutLinedInput-notchedOutline': {
            boxShadow: isError && `0 0 4px ${theme.palette.error.main}`,
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              boxShadow: isError && `0 0 8px ${theme.palette.error.main}`,
            },
          },
        }}
        {...props}
      >
        {children}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
export default CustomSelect;
