import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import BackButtonHub from '../../../../../../../components/shared/BackButtonHub';
import {
  ChevronRightIcon,
  QrCodeIcon,
  QrScanner,
} from '../../../../../../../assets/Icons';
import { changePage } from '../../../../../../../store/Routing/purchaseRouting';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  changeIdPurchaseDetail,
  getAllDataPurchaseDetail,
  getDataDetailPurchaseDashboard,
  getIdPurchaseDetail,
  getLoadingDetailPurchase,
} from '../../../../../../../store/purchase/detailPurchase';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import toRupiah from '@develoka/angka-rupiah-js';
import { fetchAllDataPurchaseScanAdmin } from '../../../../../../../store/purchase/purchaseAdmin/purchaseProcessedAdmin';
import { fetchAllDataPurchaseFinishScanAdmin } from '../../../../../../../store/purchase/purchaseAdmin/purchaseFinishAdmin';

const color = {
  no: '#C8C8CC',
  yes: '#51B15C',
};

const DetailPurchaseMobile = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getLoadingDetailPurchase);
  const idPurchase = useSelector(getIdPurchaseDetail);
  const datas = useSelector(getDataDetailPurchaseDashboard);
  const roleUser = localStorage.getItem('role');

  //! fetch detail purchase
  useEffect(() => {
    dispatch(getAllDataPurchaseDetail(idPurchase));
  }, [idPurchase]);

  const handleToQrCode = () => {
    dispatch(changePage('qrCode'));
    dispatch(changeIdPurchaseDetail(idPurchase));
  };

  useEffect(() => {
    dispatch(fetchAllDataPurchaseScanAdmin(idPurchase));
    dispatch(fetchAllDataPurchaseFinishScanAdmin(idPurchase));
  }, [idPurchase]);

  const decodeStatus = datas?.statusMapDecode;

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceFix, setTotalPriceFix] = useState(0);

  useEffect(() => {
    const totalss = datas?.details.reduce((accum, next) => {
      return next.discountFormat !== 'percentage'
        ? accum +
            Number(next.purchasePrice) * Number(next.qty) -
            Number(next.discount)
        : accum +
            Number(next.purchasePrice * next.qty) -
            Number((next.discount / 100) * next.purchasePrice * next.qty);
    }, 0);

    setTotalPrice(totalss);
  }, [datas]);

  useEffect(() => {
    if (datas?.discount) {
      if (datas?.discountFormat === 'currency') {
        setTotalPriceFix(totalPrice - datas?.discount);
      } else {
        const disc = (totalPrice / 100) * datas?.discount;
        setTotalPriceFix(totalPrice - disc);
      }
    }
  }, [datas, totalPrice]);

  return (
    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <BackButtonHub onClick={() => dispatch(changePage(''))} />
          <Box>
            <Typography
              sx={{
                color: '#000000',
                fontSize: '14px',
                fontWeight: 600,
                cursor: 'pointer',
              }}
            >
              Purchase Details
            </Typography>

            <Typography
              sx={{
                fontSize: '10px',
                display: 'flex',
                alignItems: 'center',
                color: '#9E9D9D',
              }}
            >
              {datas?.subsidiary.name}
              <ChevronRightIcon sx={{ width: '10px' }} />
              {datas?.hub?.namaToko}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '36px',
            height: '36px',
            borderRadius: '8px',
            bgcolor: '#51B15C1A',
            cursor: 'pointer',
          }}
          onClick={handleToQrCode}
        >
          <QrCodeIcon />
        </Box>
      </Box>

      {/* main */}
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="success" />
        </Box>
      ) : (
        <Grid container columns={12} spacing={3}>
          {/* invoice id */}
          <Grid item xs={12}>
            <CustomCard
              sx={{
                padding: '8px 16px',
                borderRadius: '8px ',
                background: '#FAFAFA',
              }}
            >
              <Typography
                sx={{ fontSize: '12px', color: '#9E9E9E', mb: '8px' }}
              >
                Invoice ID
              </Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                {datas?.nomorfaktur !== '' ? datas?.nomorfaktur : '-'}
              </Typography>
            </CustomCard>
          </Grid>

          {/* date */}
          <Grid item xs={12}>
            <CustomCard
              sx={{
                padding: '8px 16px',
                borderRadius: '8px ',
                background: '#FAFAFA',
              }}
            >
              <Typography
                sx={{ fontSize: '12px', color: '#9E9E9E', mb: '8px' }}
              >
                Dates
              </Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                {dayjs(datas?.tanggal).format('DD/MMM/YY')}
              </Typography>
            </CustomCard>
          </Grid>

          {/* supplier */}
          <Grid item xs={12}>
            <CustomCard
              sx={{
                padding: '8px 16px',
                borderRadius: '8px ',
                background: '#FAFAFA',
              }}
            >
              <Typography
                sx={{ fontSize: '12px', color: '#9E9E9E', mb: '8px' }}
              >
                Supplier
              </Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                {datas?.supplier !== null ? datas?.supplier.namaLengkap : '-'}
              </Typography>
            </CustomCard>
          </Grid>

          {/* Description */}
          <Grid item xs={12}>
            <CustomCard
              sx={{
                padding: '8px 16px',
                borderRadius: '8px ',
                background: '#FAFAFA',
              }}
            >
              <Typography
                sx={{ fontSize: '12px', color: '#9E9E9E', mb: '8px' }}
              >
                Description
              </Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                {datas?.keterangan}
              </Typography>
            </CustomCard>
          </Grid>

          {/* status */}
          <Grid item xs={12}>
            <CustomCard
              sx={{
                padding: '8px 16px',
                borderRadius: '8px ',
                background: '#FAFAFA',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', color: '#9E9E9E', mb: '8px' }}
                >
                  Status
                </Typography>
                {roleUser == 2 &&
                decodeStatus?.sent === true &&
                decodeStatus?.accepted !== true ? (
                  <Box
                    sx={{
                      width: '82px',
                      height: '26px',
                      background: decodeStatus?.sent ? '#51B15C' : '#C8C8CC',
                      borderRadius: '4px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 1,
                      padding: '0px 8px',
                    }}
                    onClick={() =>
                      dispatch(changePage('purchaseProcessedScanAdminMobile'))
                    }
                  >
                    <QrScanner />
                    <Typography sx={{ fontSize: '12px', color: '#FAFAFA' }}>
                      Accept
                    </Typography>
                  </Box>
                ) : roleUser == 2 &&
                  decodeStatus?.accepted === true &&
                  decodeStatus?.finish !== true ? (
                  <Box
                    sx={{
                      width: '82px',
                      height: '26px',
                      background: decodeStatus?.sent ? '#51B15C' : '#C8C8CC',
                      borderRadius: '4px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 1,
                      padding: '0px 8px',
                    }}
                    onClick={() => {
                      dispatch(changePage('scanRackPurchaseMobile'));
                    }}
                  >
                    <QrScanner />
                    <Typography sx={{ fontSize: '12px', color: '#FAFAFA' }}>
                      Finish
                    </Typography>
                  </Box>
                ) : roleUser == 2 && decodeStatus?.finish === true ? (
                  <Box
                    sx={{
                      width: '82px',
                      height: '26px',
                      background: '#C8C8CC',
                      borderRadius: '4px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 1,
                      padding: '0px 8px',
                    }}
                  >
                    <Typography sx={{ fontSize: '12px', color: '#FAFAFA' }}>
                      Finished
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: '82px',
                      height: '26px',
                      background: '#C8C8CC',
                      borderRadius: '4px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 1,
                      padding: '0px 8px',
                    }}
                  >
                    <QrScanner />
                    <Typography sx={{ fontSize: '12px', color: '#FAFAFA' }}>
                      Accept
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  mt: '12px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  {decodeStatus?.unpaid && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '60px',
                        color: decodeStatus?.unpaid ? color.yes : color.no,
                      }}
                    >
                      <Typography sx={{ mb: 1, fontSize: '10px' }}>
                        Unpaid
                      </Typography>
                    </Box>
                  )}
                  {decodeStatus?.paid && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '60px',
                        color: decodeStatus?.paid ? color.yes : color.no,
                      }}
                    >
                      <Typography sx={{ mb: 1, fontSize: '10px' }}>
                        Paid
                      </Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '60px',
                      color: decodeStatus?.sent ? color.yes : color.no,
                    }}
                  >
                    <Typography sx={{ mb: 1, fontSize: '10px' }}>
                      Processed
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '60px',
                      color: decodeStatus?.sent ? color.yes : color.no,
                    }}
                  >
                    <Typography sx={{ mb: 1, fontSize: '10px' }}>
                      Sent
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '60px',
                      color: decodeStatus?.accepted ? color.yes : color.no,
                    }}
                  >
                    <Typography sx={{ mb: 1, fontSize: '10px' }}>
                      Accepted
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '60px',
                      color: decodeStatus?.finish ? color.yes : color.no,
                    }}
                  >
                    <Typography sx={{ mb: 1, fontSize: '10px' }}>
                      Finish
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* bullets */}
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {decodeStatus?.unpaid && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                      <Box
                        sx={{
                          height: '2px',
                          width: '24px',
                          bgcolor: decodeStatus?.unpaid ? color.yes : color.no,
                          visibility: 'hidden',
                        }}
                      ></Box>
                      <Box
                        sx={{
                          width: '12px',
                          height: '12px',
                          bgcolor: decodeStatus?.unpaid ? color.yes : color.no,
                          borderRadius: '50%',
                        }}
                      ></Box>
                      <Box
                        sx={{
                          height: '2px',
                          width: '24px',
                          bgcolor: decodeStatus?.processed
                            ? color.yes
                            : color.no,
                        }}
                      ></Box>
                    </Box>
                  )}
                  {decodeStatus?.paid && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                      <Box
                        sx={{
                          height: '2px',
                          width: '24px',
                          bgcolor: decodeStatus?.paid ? color.yes : color.no,
                          visibility: 'hidden',
                        }}
                      ></Box>
                      <Box
                        sx={{
                          width: '12px',
                          height: '12px',
                          bgcolor: decodeStatus?.paid ? color.yes : color.no,
                          borderRadius: '50%',
                        }}
                      ></Box>
                      <Box
                        sx={{
                          height: '2px',
                          width: '24px',
                          bgcolor: decodeStatus?.processed
                            ? color.yes
                            : color.no,
                        }}
                      ></Box>
                    </Box>
                  )}
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                    <Box
                      sx={{
                        height: '2px',
                        width: '24px',
                        bgcolor: decodeStatus?.processed ? color.yes : color.no,
                      }}
                    ></Box>
                    <Box
                      sx={{
                        width: '12px',
                        height: '12px',
                        bgcolor: decodeStatus?.processed ? color.yes : color.no,
                        borderRadius: '50%',
                      }}
                    ></Box>
                    <Box
                      sx={{
                        height: '2px',
                        width: '24px',
                        bgcolor: decodeStatus?.sent ? color.yes : color.no,
                      }}
                    ></Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                    <Box
                      sx={{
                        height: '2px',
                        width: '24px',
                        bgcolor: decodeStatus?.sent ? color.yes : color.no,
                      }}
                    ></Box>
                    <Box
                      sx={{
                        width: '12px',
                        height: '12px',
                        bgcolor: decodeStatus?.sent ? color.yes : color.no,
                        borderRadius: '50%',
                      }}
                    ></Box>
                    <Box
                      sx={{
                        height: '2px',
                        width: '24px',
                        bgcolor: decodeStatus?.accepted ? color.yes : color.no,
                      }}
                    ></Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                    <Box
                      sx={{
                        height: '2px',
                        width: '24px',
                        bgcolor: decodeStatus?.accepted ? color.yes : color.no,
                      }}
                    ></Box>
                    <Box
                      sx={{
                        width: '12px',
                        height: '12px',
                        bgcolor: decodeStatus?.accepted ? color.yes : color.no,
                        borderRadius: '50%',
                      }}
                    ></Box>
                    <Box
                      sx={{
                        height: '2px',
                        width: '24px',
                        bgcolor: decodeStatus?.finish ? color.yes : color.no,
                      }}
                    ></Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                    <Box
                      sx={{
                        height: '2px',
                        width: '24px',
                        bgcolor: decodeStatus?.finish ? color.yes : color.no,
                      }}
                    ></Box>
                    <Box
                      sx={{
                        width: '12px',
                        height: '12px',
                        bgcolor: decodeStatus?.finish ? color.yes : color.no,
                        borderRadius: '50%',
                      }}
                    ></Box>
                    <Box
                      sx={{
                        height: '2px',
                        width: '24px',
                        bgcolor: decodeStatus?.finish ? color.yes : color.no,
                        visibility: 'hidden',
                      }}
                    ></Box>
                  </Box>
                </Box>
              </Box>
            </CustomCard>
          </Grid>

          {/* items */}

          <Grid item xs={12}>
            <CustomCard
              sx={{
                padding: '8px 16px',
                borderRadius: '8px ',
                background: '#FAFAFA',
              }}
            >
              <Typography
                sx={{ fontSize: '14px', color: '#303030', fontWeight: 600 }}
              >
                item(s)
              </Typography>
              {datas?.details.map((item, index) => {
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 1,
                      marginTop: '12px',
                      marginBottom: '16px',
                    }}
                    key={index}
                  >
                    <Box
                      sx={{
                        flex: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: '45px',
                          height: '45px',
                          borderRadius: '12px',
                        }}
                      >
                        <Box
                          component="img"
                          src={
                            process.env.REACT_APP_API_IMAGE_BASE_URL +
                            '/' +
                            item?.product?.gambar
                          }
                          sx={{
                            width: '45px',
                            height: '45px',
                            borderRadius: '12px',
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        flex: 5,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          position: 'relative',
                          height: '100%',
                          justifyContent: 'space-evenly',
                          flexFlow: 'column',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: '#000000',
                            fontWeight: 400,
                          }}
                        >
                          {item?.product?.namaProduk}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: '#000000',
                            fontWeight: 600,
                          }}
                        >
                          {item?.qty} x
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          position: 'relative',
                          height: '100%',
                          justifyContent: 'space-evenly',
                          flexFlow: 'column',
                        }}
                      >
                        {item?.discount !== 0 && (
                          <Typography
                            sx={{
                              fontSize: '10px',
                              fontWeight: 500,
                              color: '#9E9D9D',
                              textDecoration: 'line-through',
                              textAlign: 'end',
                            }}
                          >
                            {item?.discountFormat !== 'percentage'
                              ? toRupiah(item?.discount, {
                                  floatingPoint: 0,
                                })
                              : `${item?.discount}
                              %`}
                          </Typography>
                        )}
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: 600,
                            color: '#000000',
                            textAlign: 'end',
                          }}
                        >
                          {item?.discountFormat === 'percentage'
                            ? toRupiah(
                                item?.purchasePrice * item?.qty -
                                  (item?.discount / 100) *
                                    item?.purchasePrice *
                                    item?.qty,
                                {
                                  floatingPoint: 0,
                                }
                              )
                            : toRupiah(
                                item?.purchasePrice * item?.qty -
                                  item?.discount,
                                {
                                  floatingPoint: 0,
                                }
                              )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </CustomCard>
          </Grid>
          {/* payment detail */}
          <Grid item xs={12}>
            <CustomCard
              sx={{
                padding: '8px 16px',
                borderRadius: '8px ',
                background: '#FAFAFA',
              }}
            >
              <Typography
                sx={{ fontSize: '14px', fontWeight: 600, color: '#303030' }}
              >
                Payment Detail
              </Typography>
              {/* subtotal */}
              <Box
                sx={{
                  marginTop: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', color: '#000000', fontWeight: 500 }}
                >
                  subtotal
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#000000',
                    fontWeight: 500,
                    textAlign: 'end',
                  }}
                >
                  Rp. {totalPrice || '-'}
                </Typography>
              </Box>

              {/* diskon */}
              <Box
                sx={{
                  marginTop: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', color: '#000000', fontWeight: 500 }}
                >
                  Discount
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#4FD240',
                    fontWeight: 500,
                    textAlign: 'end',
                  }}
                >
                  Rp{datas?.bayar - datas?.totalbayar}
                </Typography>
              </Box>

              <hr />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', color: '#000000', fontWeight: 600 }}
                >
                  Grand Total
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  {datas?.discount ? (
                    <>
                      <Typography
                        sx={{
                          fontWeight: '600',
                          fontSize: '12px',
                          color: '#9E9D9D',
                          textDecoration: 'line-through',
                        }}
                      >
                        Rp. {totalPrice || '-'}
                      </Typography>
                      <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>
                        Rp. {totalPriceFix || '-'}
                      </Typography>
                    </>
                  ) : (
                    <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>
                      Rp. {totalPrice || '-'}
                    </Typography>
                  )}
                </Box>
              </Box>
            </CustomCard>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
export default DetailPurchaseMobile;
