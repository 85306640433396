import { createSlice } from '@reduxjs/toolkit';

export const AddMissingQrAdjustmentState = createSlice({
  name: 'addMissingQrAdjustment',
  initialState: {
    datas: [],
    idAuditAdjustment: null,
    locationHub: '',
  },
  reducers: {
    goToAdjustMissingQr: (state, action) => {
      state.datas = action.payload;
    },
    changeIdAuditAdjustment: (state, action) => {
      state.idAuditAdjustment = action.payload;
    },
    changeLocationHub: (state, action) => {
      state.locationHub = action.payload;
    },
    addNewItemAdjustmentMissingQr: (state, action) => {
      state.datas = [...state.datas, action.payload];
    },
  },
});

export const {
  goToAdjustMissingQr,
  changeIdAuditAdjustment,
  changeLocationHub,
  addNewItemAdjustmentMissingQr,
} = AddMissingQrAdjustmentState.actions;

export const getDataMissing = (state) => state.addMissingQrAdjustment.datas;
export const getIdAudiToMissingAdjustment = (state) =>
  state.addMissingQrAdjustment.idAuditAdjustment;
export const getLocationHub = (state) =>
  state.addMissingQrAdjustment.locationHub;

export default AddMissingQrAdjustmentState.reducer;
