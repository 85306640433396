import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';
import { getAllLoginLog } from '../api/LoginLog';
import { ReactComponent as NoImage } from '../assets/no-image.svg';
import CustomInput from '../components/shared/CustomInput';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: '12px',
}));

const LoginLog = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [logs, setLogs] = useState([]);
  const [search, setSearch] = useState('');
  const [searchFix, setSearchFix] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const pageSizeList = [5, 10, 15, 20];
  const [totalItem, setTotalItem] = useState(0);

  const fetchApi = async (search, page, pageSize) => {
    setIsLoading(true);
    try {
      const {
        data: {
          data: { usersLog, pageInfo },
        },
      } = await getAllLoginLog({
        search: search,
        page: page,
        pageSize: pageSize,
      });
      setLogs(usersLog);
      setTotalItem(pageInfo.total);
    } catch (error) {
      console.error(error);
      setLogs([]);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApi(searchFix, currentPage, pageSize);
  }, [searchFix, currentPage, pageSize]);

  const debouceSearch = useRef(
    debounce((value) => {
      setSearchFix(value);
    }, 1000)
  ).current;

  return (
    <Box sx={{ p: '20px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <CustomInput
          placeholder="Search"
          sx={{ bgcolor: '#F4F4F4', overview: 'hidden', borderRadius: '4px' }}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            debouceSearch(e.target.value);
          }}
        />
        {search !== searchFix && isLoading && <CircularProgress size="24px" />}
      </Box>
      <Table sx={{ mt: '20px' }}>
        <TableHead>
          <TableRow sx={{ bgcolor: '#F4F4F4' }}>
            <TableCell>No</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>IP Address</TableCell>
            <TableCell>Platform</TableCell>
            <TableCell>Browser</TableCell>
            <TableCell>Device</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Activity</TableCell>
            <TableCell align="center">User</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading &&
            logs.map((log, index) => {
              let {
                ipAddress,
                tanggal,
                platform,
                browser,
                device,
                location,
                description,
                status,
                namaLengkap: name,
              } = log;
              return (
                <TableRow key={index}>
                  <CustomTableCell>{index + 1}</CustomTableCell>
                  <CustomTableCell>{tanggal || '-'}</CustomTableCell>
                  <CustomTableCell sx={{ color: status ? 'black' : 'red' }}>
                    {ipAddress || '-'}
                  </CustomTableCell>
                  <CustomTableCell>{platform || '-'}</CustomTableCell>
                  <CustomTableCell>{browser || '-'}</CustomTableCell>
                  <CustomTableCell>{device || '-'}</CustomTableCell>
                  <CustomTableCell>{location || '-'}</CustomTableCell>
                  <CustomTableCell align="left">
                    {description === 'User login' ? (
                      <Typography color="primary" sx={{ fontSize: '12px' }}>
                        Login
                      </Typography>
                    ) : description === 'User logout' ? (
                      <Typography color="error" sx={{ fontSize: '12px' }}>
                        Logout
                      </Typography>
                    ) : (
                      description || '-'
                    )}
                  </CustomTableCell>
                  <CustomTableCell>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <Typography sx={{ fontSize: '12px' }}>
                        {name || '-'}
                      </Typography>
                      <Box
                        sx={{
                          width: '40px',
                          height: '40px',
                          bgcolor: '#F5F5F5',
                          borderRadius: '50%',
                          position: 'relative',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderStyle: 'dashed',
                          borderWidth: '1px',
                          borderColor: '#9E9D9D',
                        }}
                      >
                        <NoImage sx={{ color: '#9E9D9D' }} />
                      </Box>
                    </Box>
                  </CustomTableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {isLoading && (
        <Box
          sx={{
            height: '200px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Grid
        container
        direction="row"
        coloumn={12}
        alignItems="center"
        sx={{ mt: '30px' }}
      >
        <Grid item xs={6} sx={{ fontSize: '14px', fontWeight: '500' }}>
          Showing {currentPage * pageSize - (pageSize - 1)} to{' '}
          {currentPage * pageSize >= totalItem
            ? totalItem
            : currentPage * pageSize}{' '}
          of {totalItem} entries
        </Grid>
        <Grid
          container
          item
          direction="row"
          xs={6}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item sx={{ fontSize: '14px', fontWeight: '500' }}>
            {' '}
            Rows Per Page :
            <Select
              value={pageSize}
              variant="filled"
              onChange={(e) => setPageSize(e.target.value)}
              sx={{
                fontSize: '14px',
                lineHeight: '-1px',
                '.MuiFilledInput-input': {
                  paddingTop: '7.5px',
                  paddingBottom: '7.5px',
                  paddingLeft: '10px',
                  paddingRight: '0',
                  borderRadius: '5px',
                },
                '&:before': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                '&:after': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                borderRadius: '5px',
                marginLeft: '8px',
              }}
            >
              {pageSizeList?.map((pageValue, index) => (
                <MenuItem value={pageValue} key={index}>
                  {pageValue}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item sx={{ ml: '35px' }}>
            <Box sx={{ display: 'inline', mr: '10px' }}>
              <IconButton
                sx={{ p: 0 }}
                onClick={() => {
                  if (currentPage > 1) {
                    setCurrentPage(Number(currentPage) - 1);
                  }
                }}
              >
                <ArrowBackIosIcon sx={{ color: '#323232', width: '16px' }} />
              </IconButton>
            </Box>
            <Box sx={{ display: 'inline', mr: '20px' }}>
              <OutlinedInput
                variant="outlined"
                type="number"
                value={currentPage}
                onChange={(e) => {
                  if (
                    e.target.value > 1 &&
                    e.target.value < Math.ceil(totalItem / pageSize)
                  ) {
                    setCurrentPage(Number(e.target.value));
                  }
                }}
                sx={{
                  border: '1px solid #9E9D9D',
                  width: '36px',
                  borderRadius: '5px',
                  textAlign: 'center',
                  '& .MuiOutlinedInput-input': {
                    padding: '5px 8px 5px 8px',
                    fontSize: '0.875rem',
                    boxShadow: 'none',
                    textAlign: 'center',
                    fontWeight: 600,
                    width: '25px',
                  },
                  '&:before': {
                    borderRadius: '5px',
                    borderBottom: 0,
                  },
                  '&:after': {
                    borderRadius: '5px',
                    borderBottom: 0,
                  },
                  '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'inline',
                fontWeight: '600',
                fontSize: '14px',
                color: '#9E9D9D',
              }}
            >
              of {Math.ceil(totalItem / pageSize)}
            </Box>
            <Box sx={{ display: 'inline', ml: '10px' }}>
              <IconButton
                sx={{ p: 0 }}
                onClick={() => {
                  if (currentPage < Math.ceil(totalItem / pageSize)) {
                    setCurrentPage(Number(currentPage) + 1);
                  }
                }}
              >
                <ArrowForwardIosIcon sx={{ color: '#323232', width: '16px' }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default LoginLog;
