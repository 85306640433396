import { createSlice } from '@reduxjs/toolkit';
import { getAllQrCode } from '../../../api/qrcode';

export const PurchaseFinishScanAdminState = createSlice({
  name: 'purchaseFinishAdmin',
  initialState: {
    rackId: null,
    dataRackDetail: null,
    scanned: [],
    idScanned: [],
    isExist: false,
    isLoading: false,
    totalData: 0,
    allData: [],
    noqrDataSelect: [],
    noqr: [],
    noqrid: [],
    isSelect: false,
    detail: {},
    url: null,
  },
  reducers: {
    changeScanRackIdAdmin: (state, action) => {
      state.rackId = action.payload;
    },
    scanFinishAdmin: (state, action) => {
      const data = action.payload;
      if (!state.idScanned.includes(data.encript)) {
        state.idScanned = [...state.idScanned, data.encript];
        state.scanned = [...state.scanned, data];
        state.isExist = false;
      } else {
        state.isExist = true;
      }
    },
    loadNoqrFinishAdmin: (state, action) => {
      const { noqrDataSelect, url } = action.payload;
      if (
        !state.noqrid.includes(noqrDataSelect.map((item, idx) => item.iDetail))
      ) {
        state.noqrid = [
          ...state.noqrid,
          {
            photo_url: url,
            id_detail: noqrDataSelect.map((item) => item.iDetail),
          },
        ];
        state.noqr = [...state.noqr, noqrDataSelect];
        state.noqrDataSelect = [];
        state.status = true;
      } else {
        state.status = false;
      }
    },
    changeLoadingPurchaseScan: (state, action) => {
      state.isLoading = action.payload;
    },
    setTotalDataPurchaseScan: (state, action) => {
      state.totalData = action.payload;
    },
    setAllDataPurchaseScan: (state, action) => {
      state.allData = action.payload;
    },
    setDetailPurchase: (state, action) => {
      state.detail = action.payload;
    },
    loadSpesifikDataQrRack: (state, action) => {
      state.dataRackDetail = action.payload;
    },
    changeIsSelectFinishAdmin: (state, action) => {
      state.isSelect = action.payload;
    },

    changeNoqrDataSelectFinishAdmin: (state, action) => {
      const { item, id } = action.payload;

      if (
        state.noqrDataSelect.filter((val) => val.iDetail === id).length === 0
      ) {
        state.noqrDataSelect = [...state.noqrDataSelect, item];
      } else {
        state.noqrDataSelect = state.noqrDataSelect.filter(
          (val) => val.iDetail !== id
        );
      }
    },

    changeUrlImageFinishAdmin: (state, action) => {
      state.url = action.payload;
    },
  },
});

export const {
  changeScanRackIdAdmin,
  scanFinishAdmin,
  loadNoqrFinishAdmin,
  changeLoadingPurchaseScan,
  setTotalDataPurchaseScan,
  setAllDataPurchaseScan,
  setDetailPurchase,
  loadSpesifikDataQrRack,
  changeIsSelectFinishAdmin,
  changeNoqrDataSelectFinishAdmin,
  changeUrlImageFinishAdmin,
} = PurchaseFinishScanAdminState.actions;

export const getRackIdFinishScanAdmin = (state) =>
  state.purchaseFinishAdmin.rackId;

export const getAllAdminFinishScan = (state) => state.purchaseFinishAdmin;

export const fetchAllDataPurchaseFinishScanAdmin = (id) => (dispatch) => {
  const totalData = 100;
  const fetchTotalApi = async () => {
    dispatch(changeLoadingPurchaseScan(true));
    const params = {
      page: 1,
      pageSize: totalData,
    };
    try {
      const {
        data: { data },
      } = await getAllQrCode(params, id);
      dispatch(setAllDataPurchaseScan(data));
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  // const fetchApi = async () => {
  //   dispatch(changeLoadingPurchaseScan(true));
  //   const params = {
  //     page: 1,
  //   };
  //   try {
  //     const {
  //       data: {
  //         data: {
  //           pageInfo: { total },
  //         },
  //       },
  //     } = await getAllQrCode(params, id);
  //     totalData = total;
  //   } catch (err) {
  //     console.error(err);
  //   } finally {
  //     fetchTotalApi();
  //   }
  // };

  fetchTotalApi();
};

export default PurchaseFinishScanAdminState.reducer;
