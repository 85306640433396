import { useDispatch, useSelector } from 'react-redux';
import {
  getAllProductSupplierRouting,
  setCloseNotifProductSupplier,
} from '../../../../../../store/Routing/productSupplierRouting';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';

const NotifProductSupplier = () => {
  const dispatch = useDispatch();
  const { keyNotif, textNotif } = useSelector(getAllProductSupplierRouting);

  const keys = [
    'addProductSupllier',
    'editProductSupllier',
    'deleteProductSupllier',
  ];

  return (
    <>
      {keys.map((key, index) => (
        <CustomNotifSuccess
          text={textNotif}
          open={keyNotif === key}
          keys={keyNotif}
          onClose={() => {
            dispatch(setCloseNotifProductSupplier());
          }}
          key={index}
          sx={{
            background: key === 'nextAdd' ? '#A33A3A' : '',
            color: key === 'nextAdd' ? '#FFF' : '',
          }}
        />
      ))}
    </>
  );
};
export default NotifProductSupplier;
