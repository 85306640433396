import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import CustomTable from '../../../../../../../components/shared/CustomTable';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useEffect } from 'react';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import { styled } from '@mui/material/styles';

import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as ColumnFilterIcon } from '../../../../../../../assets/colum-filter-icon.svg';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CustomMenu from '../../../../../../../components/shared/CustomMenu';
import CustomDialog from '../../../../../../../components/shared/CustomDialog';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import { TextFields } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import {
  ExitToApp as ExitToAppIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from '@mui/icons-material';

import { ReactComponent as NoImage } from '../../../../../../../assets/no-image.svg';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
import { fetchSubCategories } from '../../../../../../../api/products';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExportXlsExcel from '../../../../../../../components/ExportXlsExcel';

const allData = ['All'];

const CustomTableCells = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '0.75rem',
  textTransform: 'capitalize',
});

const TableSubCategoryProduct = (props) => {
  const {
    handleClickRow,
    handleClickName,
    handleClickAddSub,
    isLoadingData,
    // *----
    page,
    setPage,
    roadHirarki,
    setRoadHirarki,
    setIsSubCategoryProductAdd,
    setIsSubCategoryProductEdit,
    setIsSubCategoryProductDetails,
    // Table
    tableRowPerPageValues,
    tableRowPerPage,
    setTableRowPerPage,
    tableCurrentPage,
    setTableCurrentPage,
    tableTotalItems,
    setTableTotalItems,
    tableSearch,
    setTableSearch,
    sortTable,
    setSortTable,
    isSortAsc,
    setIsSortAsc,
    tableDatas,
    setTableDatas,
  } = props;

  const theme = useTheme();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [dataTypeSelected, setDataTypeSelected] = useState([
    'category',
    'type',
    'cogs',
    'sku',
  ]);

  const [beforeSortTable, setBeforeSortTable] = useState(sortTable);

  useEffect(() => {
    if (sortTable !== beforeSortTable) {
      setIsSortAsc(true);
      setBeforeSortTable(sortTable);
    }
  }, [sortTable, beforeSortTable, setIsSortAsc]);

  const [isOpenTableFilter, setIsOpenTableFilter] = useState(false);
  const [openColumnFilter, setOpenColumnFilter] = useState(null);
  const isOpenColumnFilter = Boolean(openColumnFilter);

  const [openExport, setOpenExport] = useState(null);
  const isOpenExport = Boolean(openExport);

  // !----------------------- API FOR EXPORT  --------------------------
  const [dataForExports, setDataForExports] = useState([]);

  useEffect(() => {
    const categoryDataForExport = async () => {
      let datas = {
        pageSize: tableTotalItems,
        page: 1,
        name: 'asc',
        categories: [roadHirarki.categoryId],
      };
      try {
        const {
          data: { data: dataFetchSubCategories },
        } = await fetchSubCategories(datas);

        const resultFix = dataFetchSubCategories.subCategories.map(
          (
            {
              idSubkategori,
              subkategori,
              category,
              productsSumHarga,
              productsCount,
              schedule,
            },
            index
          ) => ({
            number: index + 1,
            name: subkategori || '-',
            category: category ? category.namaKategori : '-',
            cogs: productsSumHarga ? parseInt(productsSumHarga) : '-',
            sku: productsCount || '-',
            schedule: schedule ? dayjs(schedule).format('DD/MMM/YYYY') : '-',
          })
        );
        setDataForExports(resultFix);
      } catch {
        setDataForExports([]);
      } finally {
      }
    };
    if (tableDatas.length) {
      if (openExport) {
        if (dataForExports.length === 0) {
          categoryDataForExport();
        }
      }
    }
  }, [tableDatas, isOpenExport, tableTotalItems, sortTable, dataForExports]);

  // *! --------------------- HANDLE EXPORT PDF -----------------------
  const handleExportPdf = () => {
    const doc = new jsPDF();
    doc.text('Table Sub Category', 20, 10);
    doc.autoTable({
      theme: 'grid',
      columns: [
        { title: 'No', dataKey: 'number' },
        { title: 'Nama', dataKey: 'name' },
        { title: 'Category', dataKey: 'category' },
        { title: 'COGS', dataKey: 'cogs' },
        { title: 'SKU', dataKey: 'sku' },
        { title: 'Schedule', dataKey: 'schedule' },
      ],
      body: dataForExports,
    });
    doc.save('category-table.pdf');
    setOpenExport(null);
  };
  const handleExportExcel = () => {
    setOpenExport(null);
  };

  // *! --------------------- TOOLBAR -----------------------
  const toolbar = (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'row-reverse' }}>
      {/* add */}
      <CustomButton
        sx={{
          bgcolor: '#51B15C',
          color: '#ffffff',
          borderRadius: '8px',
          '&:hover': {
            bgcolor: '#51B15C',
            color: '#ffffff',
          },
          fontSize: '0.875rem',
          px: 2,
        }}
        startIcon={<AddIcon sx={{ width: '14px' }} />}
        onClick={handleClickAddSub}
      >
        Add
      </CustomButton>

      {/* Adjust Filter */}
      <IconButton
        sx={{
          border: '1.5px solid #51B15C',
          borderRadius: '8px',
          p: 1,
        }}
        onClick={(e) => setOpenColumnFilter(e.currentTarget)}
        aria-haspopup="true"
      >
        <ColumnFilterIcon fill="#51B15C" />
      </IconButton>
      <CustomMenu
        open={isOpenColumnFilter}
        openMenu={openColumnFilter}
        setOpenMenu={setOpenColumnFilter}
        sx={{
          '.MuiMenuItem-root': {
            paddingLeft: 0,
            paddingRight: '20px',
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', p: '12px' }}
        >
          <Typography sx={{ fontWeight: '400', fontSize: '12px' }}>
            Max 7
          </Typography>
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '12px',
              color: theme.palette.primary.main,
              cursor: 'pointer',
            }}
            onClick={() => setDataTypeSelected(['type', 'cogs', 'sku'])}
          >
            Reset
          </Typography>
        </Box>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem>
          <Checkbox checked={true} />
          Name*
        </MenuItem>
        <MenuItem>
          <Checkbox checked={true} />
          Photo*
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes('category')) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== 'category')
              );
            } else {
              setDataTypeSelected((prev) => [...prev, 'category']);
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes('category')} />
          Category
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes('cogs')) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== 'cogs')
              );
            } else {
              setDataTypeSelected((prev) => [...prev, 'cogs']);
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes('cogs')} />
          COGS
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes('sku')) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== 'sku')
              );
            } else {
              setDataTypeSelected((prev) => [...prev, 'sku']);
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes('sku')} />
          SKU
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (dataTypeSelected.includes('schedule')) {
              setDataTypeSelected(
                dataTypeSelected.filter((item) => item !== 'schedule')
              );
            } else {
              setDataTypeSelected((prev) => [...prev, 'schedule']);
            }
          }}
        >
          <Checkbox checked={dataTypeSelected.includes('schedule')} />
          Schedule
        </MenuItem>
      </CustomMenu>
      {/* Export */}
      <CustomButton
        startIcon={<ExitToAppIcon />}
        endIcon={<ArrowDropDownIcon />}
        onClick={(e) => setOpenExport(e.currentTarget)}
        aria-haspopup="true"
        sx={{
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          fontSize: '0.875rem',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
          px: 2,
          py: 0.8,
        }}
      >
        Export
      </CustomButton>
      <CustomMenu
        open={isOpenExport}
        openMenu={openExport}
        setOpenMenu={setOpenExport}
        PaperProps={{ sx: { minWidth: '121px' } }}
      >
        <MenuItem onClick={handleExportPdf}>PDF</MenuItem>
        <ExportXlsExcel
          excelData={dataForExports}
          fileName="Subcategory Excel"
          sx={{ color: '#000000' }}
        >
          <MenuItem>XLS</MenuItem>
        </ExportXlsExcel>

        <CSVLink
          style={{ color: '#000000', textDecoration: 'none' }}
          data={dataForExports}
        >
          <MenuItem onClick={handleExportExcel}>CSV</MenuItem>
        </CSVLink>
      </CustomMenu>
    </Box>
  );

  // *! ------------------------- END -----------------------------------------------------------------------
  // *! ------------------------- END -----------------------------------------------------------------------

  return (
    <Box>
      <CustomTable
        name="Subcategory"
        rowPerPage={tableRowPerPage}
        handleRowPerPage={(event) => {
          setTableRowPerPage(event.target.value);
          tableCurrentPage(1);
        }}
        searchValue={tableSearch}
        setSearchValue={setTableSearch}
        rowPerPageValues={tableRowPerPageValues}
        currentPage={tableCurrentPage}
        totalValueItems={tableTotalItems}
        isNoData={tableDatas.length === 0 ? true : false}
        noDataText="No Subsidiary"
        handleInputCurrentPage={(event) => {
          if (
            event.target.value >= Math.ceil(tableTotalItems / tableRowPerPage)
          ) {
            setTableCurrentPage(Math.ceil(tableTotalItems / tableRowPerPage));
          } else if (event.target.value <= 1) {
            setTableCurrentPage(1);
          } else {
            setTableCurrentPage(parseInt(event.target.value));
          }
        }}
        handleBackArrow={() =>
          setTableCurrentPage(
            tableCurrentPage <= 1 ? tableCurrentPage : tableCurrentPage - 1
          )
        }
        handleForwardArrow={() =>
          setTableCurrentPage(
            tableCurrentPage >= Math.ceil(tableTotalItems / tableRowPerPage)
              ? tableCurrentPage
              : tableCurrentPage + 1
          )
        }
        toolbar={toolbar}
      >
        <TableHead>
          <TableRow sx={{ bgcolor: '#FAFAFA' }}>
            <CustomTableCells>No.</CustomTableCells>
            <CustomTableCells>Photo</CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('name');
                  setIsSortAsc(!isSortAsc);
                  setTableCurrentPage(1);
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  Name
                </Typography>
                {sortTable === 'name' ? (
                  isSortAsc ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
            {dataTypeSelected.includes('category') && (
              <TableCell>Category</TableCell>
            )}
            {dataTypeSelected.includes('cogs') && (
              <CustomTableCells align="right">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSortTable('cogs');
                    setIsSortAsc(!isSortAsc);
                    setTableCurrentPage(1);
                  }}
                >
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                  >
                    COGS(Rp)*
                  </Typography>
                  {sortTable === 'cogs' ? (
                    isSortAsc ? (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(90deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(270deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    )
                  ) : (
                    <SyncAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: '#9E9D9D',
                      }}
                    />
                  )}
                </Box>
              </CustomTableCells>
            )}
            {dataTypeSelected.includes('sku') && (
              <CustomTableCells align="right">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSortTable('sku');
                    setIsSortAsc(!isSortAsc);
                    setTableCurrentPage(1);
                  }}
                >
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                  >
                    SKU
                  </Typography>
                  {sortTable === 'sku' ? (
                    isSortAsc ? (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(90deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      <ArrowRightAltIcon
                        sx={{
                          fontSize: '12px',
                          transform: 'rotate(270deg)',
                          color: theme.palette.primary.main,
                        }}
                      />
                    )
                  ) : (
                    <SyncAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: '#9E9D9D',
                      }}
                    />
                  )}
                </Box>
              </CustomTableCells>
            )}
            {dataTypeSelected.includes('schedule') && (
              <TableCell>Schedule</TableCell>
            )}
          </TableRow>
        </TableHead>
        {!isLoadingData ? (
          <TableBody>
            {tableDatas.map((tableData, index) => (
              <TableRow
                key={index}
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  handleClickRow(tableData.subkategori, tableData.idSubkategori)
                }
              >
                <CustomTableCells>{index + 1}</CustomTableCells>
                <CustomTableCells>
                  {tableData.gbrsub ? (
                    <img
                      src={
                        process.env.REACT_APP_API_IMAGE_BASE_URL +
                        '/' +
                        tableData.gbrsub.split(',')[0]
                      }
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '8px',
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: '#FAFAFA',
                        borderRadius: '2px',
                      }}
                    >
                      <NoImage />
                      <Typography sx={{ color: '#9E9D9D', fontSize: '6px' }}>
                        No photo
                      </Typography>
                    </Box>
                  )}
                </CustomTableCells>
                <CustomTableCells
                  onClick={(event) =>
                    handleClickName(
                      event,
                      tableData.subkategori,
                      tableData.idSubkategori
                    )
                  }
                  sx={{ cursor: 'pointer' }}
                >
                  {tableData.subkategori}
                </CustomTableCells>
                {dataTypeSelected.includes('category') && (
                  <CustomTableCells>
                    {!tableData.category
                      ? '-'
                      : tableData.category.namaKategori}
                  </CustomTableCells>
                )}
                {dataTypeSelected.includes('cogs') && (
                  <CustomTableCells align="right">
                    {tableData.productsSumHarga
                      ? tableData.productsSumHarga
                      : '-'}
                  </CustomTableCells>
                )}
                {dataTypeSelected.includes('sku') && (
                  <CustomTableCells align="right">
                    {tableData.productsCount ? tableData.productsCount : '-'}
                  </CustomTableCells>
                )}
                {dataTypeSelected.includes('schedule') && (
                  <CustomTableCells align="left">
                    {tableData.schedule
                      ? dayjs(tableData.schedule).format('DD/MMM/YYYY')
                      : '-'}
                  </CustomTableCells>
                )}
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            {[...Array(tableRowPerPage)].map((_, x) => (
              <TableRow key={x}>
                <TableCell>-</TableCell>
                <TableCell>
                  <Box sx={{ height: '40px' }}></Box>
                </TableCell>
                <TableCell>-</TableCell>
                {dataTypeSelected.map((_, i) => (
                  <TableCell key={i}>-</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </CustomTable>
    </Box>
  );
};
export default TableSubCategoryProduct;
