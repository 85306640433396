import { createSlice } from '@reduxjs/toolkit';
import { getHubFamilies } from '../../api/generalAPI';

export const hirarkiHubState = createSlice({
  name: 'hirarkiHub',
  initialState: {
    subsidiary: '',
    idSubsidiary: 0,
    masterHub: '',
    idMasterHub: 0,
    subHub: '',
    idSubHub: 0,
    hubFamily: [],
    hubFamilyLoading: false,
  },
  reducers: {
    changeMasterHub: (state, action) => {
      state.idMasterHub = action.payload;
    },
    clearMasterHub: (state) => {
      state.idMasterHub = 0;
    },
    loadHubFamily: (state, action) => {
      state.hubFamily = action.payload;
    },
    changeHubFamilyLoading: (state, action) => {
      state.hubFamilyLoading = action.payload;
    },
    loadMasterHubPack: (state, action) => {
      let { subsidiary, idSubsidiary, idMasterHub, masterHub } = action.payload;
      state.subsidiary = subsidiary;
      state.idSubsidiary = idSubsidiary;
      state.idMasterHub = idMasterHub;
      state.masterHub = masterHub;
    },
    loadSubHubPack: (state, action) => {
      let { subsidiary, idSubsidiary, idSubHub, subHub } = action.payload;
      state.subsidiary = subsidiary;
      state.idSubsidiary = idSubsidiary;
      state.idSubHub = idSubHub;
      state.subHub = subHub;
    },
  },
});

export const {
  changeMasterHub,
  loadMasterHubPack,
  loadSubHubPack,
  clearMasterHub,
  loadHubFamily,
  changeHubFamilyLoading,
} = hirarkiHubState.actions;

export const getSubsidiaryHub = (state) => state.hirarkiHub.subsidiary;
export const getIdSubsidiaryHub = (state) => state.hirarkiHub.idSubsidiary;
export const getMasterHub = (state) => state.hirarkiHub.masterHub;
export const getIdMasterHub = (state) => state.hirarkiHub.idMasterHub;
export const getHubFamily = (state) => state.hirarkiHub.hubFamily;
export const getHubFamilyLoading = (state) => state.hirarkiHub.hubFamilyLoading;

// !------------ API ------------------
export const fetchFamilyHub = () => (dispatch) => {
  const fetchApi = async () => {
    dispatch(changeHubFamilyLoading(true));
    try {
      const {
        data: { data },
      } = await getHubFamilies();
      dispatch(loadHubFamily(data));
    } catch {
      dispatch(loadHubFamily([]));
    } finally {
      dispatch(changeHubFamilyLoading(false));
    }
  };
  fetchApi();
};

export default hirarkiHubState.reducer;
