import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import BackButtonHub from '../../../../../../../../components/shared/BackButtonHub';
import { useDispatch } from 'react-redux';
import { changePage } from '../../../../../../../../store/Routing/purchaseRouting';
import {
  changeDescription,
  changeSubsidiaryId,
  clearData,
} from '../../../../../../../../store/purchase/addRequestPurchase';
import {
  AddIcon,
  AlertIcon,
  ChevronRightIcon,
  CloseIcon,
  CloseIcons,
  KeyboardArrowDownIcon,
} from '../../../../../../../../assets/Icons';
import DeleteIcon from '@mui/icons-material/Delete';

import CustomCard from '../../../../../../../../components/shared/CustomCard';
import { useEffect, useState } from 'react';
import SelectHubMobile from './SelectHub';
import CustomInput from '../../../../../../../../components/shared/CustomInput';
import ProductSelect from '../../../Request/Add/component/ProductSelect';
import QtySelect from '../../../Request/Add/component/QtySelect';
import BottomMenu from '../../../../../../../../components/shared/BottomMenu';
import { createRequestPurchase } from '../../../../../../../../api/purchase';

const AddRequestPurchseMobile = (props) => {
  const {
    subsidiaryList,
    subsidiaryId,
    dataHubs,
    purchaseDescription,
    tambahItem,
    setTambahItem,
    handleAddItemCardIngredient,
    isDeleteIngredientRow,
    setIsDeleteIngredientRow,
    idToko,
    subHubId,
    miniHubId,
    setLoadingSubmit,
    setNotifPurchase,
    setUnit,
    setMessage,
  } = props;
  const dispatch = useDispatch();

  //! hub
  const [isSelectHub, setIsSelectHub] = useState(false);

  const handleBackButton = () => {
    dispatch(changePage('request'));
  };

  const handleChangeSubsidiary = (e) => {
    dispatch(changeSubsidiaryId(e.target.value));
  };

  const [allowSubmit, setAllowSubmit] = useState(false);
  useEffect(() => {
    if (
      subsidiaryId !== 0 &&
      purchaseDescription !== '' &&
      idToko !== null &&
      tambahItem[0].idProduk !== 0 &&
      tambahItem[0].namaProduk !== '' &&
      tambahItem[0].qty !== 0 &&
      tambahItem[0].unit !== ''
    ) {
      setAllowSubmit(true);
    }
  }, [subsidiaryId, purchaseDescription, idToko, subHubId, tambahItem]);
  //! -------- submit data ----------------------

  const handleSubmit = () => {
    let datas = {
      subsidiaryId: subsidiaryId,
      idToko: idToko,
      keterangan: purchaseDescription,
      subHubId: subHubId,
      miniHubId: miniHubId,
      products: tambahItem,
    };

    const createPurchase = async () => {
      setLoadingSubmit(true);
      try {
        const data = await createRequestPurchase(datas);
        dispatch(
          setNotifPurchase({
            key: 'addRequest',
            text: 'hass been success added',
          })
        );
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingSubmit(false);
      }
    };

    createPurchase();
    dispatch(clearData());
    setTambahItem({ idProduk: '', qty: '', unit: '' });
    setUnit('');
    setMessage('Data berhasil di upload');
    dispatch(changePage('request'));
    handleBackButton();
    // setAddRequestPurchase(false);
    // setPurchaseRequest(true);
  };
  return (
    <Box sx={{ mt: 3, minHeight: '100vh' }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <BackButtonHub
          onClick={() => {
            handleBackButton();
          }}
        />
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Add purchase request
        </Typography>
      </Box>

      <Grid container columns={12} spacing={3} sx={{ mt: 2 }}>
        {/* //* Subsidiary */}
        <Grid item xs={12}>
          {subsidiaryList.length !== 0 && (
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Subsidiary</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={subsidiaryId}
                label="Subsidiary"
                onChange={handleChangeSubsidiary}
              >
                {subsidiaryList?.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>

        {/* hub */}
        <Grid item xs={12}>
          <CustomCard
            sx={{ padding: '16px 14px', cursor: 'pointer' }}
            onClick={() => setIsSelectHub(true)}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                  Hub*
                </Typography>
                <Box
                  sx={{
                    color: '#9E9D9D',
                    mt: '4px',
                  }}
                >
                  {dataHubs.length === 0 ? (
                    <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
                      Click to Select hub, sub and mini
                    </Typography>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        color: 'black',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <Typography sx={{ fontSize: '12px' }}>
                        {dataHubs[0].namaToko}
                      </Typography>
                      {dataHubs[1] !== null ? (
                        <>
                          <ChevronRightIcon sx={{ fontSize: '12px' }} />
                          <Typography sx={{ fontSize: '12px' }}>
                            {dataHubs[1].name}
                          </Typography>
                        </>
                      ) : (
                        '_'
                      )}
                      {dataHubs[2] !== null ? (
                        <>
                          <ChevronRightIcon sx={{ fontSize: '12px' }} />
                          <Typography sx={{ fontSize: '12px' }}>
                            {dataHubs[2].name}
                          </Typography>
                        </>
                      ) : (
                        '_'
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
              <KeyboardArrowDownIcon />
            </Box>
          </CustomCard>

          {/* dialog hub */}
          <Dialog
            // maxWidth
            fullWidth
            // maxWidth="lg"
            open={isSelectHub}
            onClose={() => {
              setIsSelectHub(false);
            }}
          >
            <DialogTitle
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box>Select Hub</Box>
              <CloseIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => setIsSelectHub(false)}
              />
            </DialogTitle>
            <DialogContent dividers>
              <SelectHubMobile setIsSelectHub={setIsSelectHub} />
            </DialogContent>
          </Dialog>
        </Grid>

        {/* description */}
        <Grid item xs={12}>
          <CustomInput
            fullWidth
            sx={{
              with: '100%',
              bgcolor: '#fafafa',
              '& .MuiOutlinedInput-input': {
                padding: '17px 16px',
                borderRadius: '10px',
                '&::placeholder': {
                  color: '#111',
                  fontSize: '14px',
                },
              },
              borderRadius: '5px',
            }}
            placeholder="Description*"
            value={purchaseDescription}
            onChange={(e) => {
              if (e.target.value.length <= 250) {
                dispatch(changeDescription(e.target.value));
              }
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: '10px',
                mt: '5px',
                ml: '15px',
                color: '#9E9D9D',
              }}
            >
              {purchaseDescription.length > 20
                ? ''
                : 'Enter at least 20 characters'}
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                mt: '5px',
                ml: '15px',
                color: '#9E9D9D',
              }}
            >
              {purchaseDescription.length}/250
            </Typography>
          </Box>
        </Grid>

        {/* items */}
        <Grid item xs={12} sx={{ mt: 2 }}>
          <CustomCard>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '15px',
                boxShadow: '0px 1px 5px #ccc',
              }}
            >
              <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                Item(s)
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  color: '#51B15C',
                  gap: 1,
                  cursor: 'pointer',
                }}
                onClick={handleAddItemCardIngredient}
              >
                <AddIcon />
                Add more
              </Typography>
            </Box>

            <Box>
              <Grid container columns={12} spacing={2} sx={{ p: '15px 10px' }}>
                {tambahItem.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Grid container columns={12} gap={2}>
                      {/* product */}
                      <Grid item xs={12}>
                        <ProductSelect
                          firstValueId={item.idProduk}
                          firstValue={item.namaProduk}
                          isReset={isDeleteIngredientRow}
                          setIsReset={setIsDeleteIngredientRow}
                          handleValue={(name, id) => {
                            const memo = tambahItem.map((item, idx) => {
                              if (index === idx) {
                                return {
                                  ...item,
                                  idProduk: id,
                                  namaProduk: name,
                                };
                              } else {
                                return item;
                              }
                            });
                            setTambahItem(memo);
                          }}
                        />
                      </Grid>
                      {/* qty */}
                      <Grid item xs={12}>
                        {/* {tambahItem[index]?.notifLimit !== '' && (
                          <Typography
                            sx={{
                              fontSize: '12px',
                              fontWeight: 400,
                              mb: 2,
                              color: '#FD7900',
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5,
                            }}
                          >
                            <AlertIcon />
                            {tambahItem[index]?.notifLimit}
                          </Typography>
                        )} */}
                        <QtySelect
                          idProduct={tambahItem[index]?.idProduk}
                          firstValueQty={item.qty}
                          firstValueUnit={item.unit}
                          isReset={isDeleteIngredientRow}
                          setIsReset={setIsDeleteIngredientRow}
                          handleValue={(qty, unit, notif) => {
                            const memo = tambahItem.map((item, idx) => {
                              if (index === idx) {
                                return {
                                  ...item,
                                  qty: qty,
                                  unit: unit,
                                  notifLimit: notif,
                                };
                              } else {
                                return item;
                              }
                            });
                            setTambahItem(memo);
                          }}
                        />
                      </Grid>

                      {/* hapus item */}
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          // mt: 4,
                          color: 'red',
                        }}
                      >
                        {tambahItem.length === 1 ? (
                          <></>
                        ) : (
                          <CloseIcons
                            sx={{
                              cursor: 'pointer',
                              width: '20px',
                            }}
                            onClick={() => {
                              setTambahItem(
                                tambahItem.filter((_, idx) => idx !== index)
                              );
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </CustomCard>
        </Grid>

        {/* button event */}

        <BottomMenu
          handleGreen={handleSubmit}
          disableGreen={!allowSubmit}
          isGreen={true}
          greenText={'Submit'}
        />
      </Grid>
    </Box>
  );
};
export default AddRequestPurchseMobile;
