import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import {
  ArrowBackIosIcon,
  ArrowForwardIosIcon,
  ArrowRightAltIcon,
  SyncAltIcon,
} from '../../../../../../../assets/Icons';

const CustomTableCells = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '0.75rem',
  textTransform: 'capitalize',
});

const TablePerformanceRackList = () => {
  const theme = useTheme();
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [sortBy, setSortBy] = useState('sku');
  const [sortByTempo, setSortByTempo] = useState('sku');

  const [isAsc, setIsAsc] = useState(true);

  const handleSortBased = (value) => {
    if (value === sortByTempo) {
      setIsAsc(!isAsc);
    } else {
      setSortBy(value);
      setSortByTempo(value);
      setIsAsc(true);
    }
  };

  const handleBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const handleNext = () => {
    if (page < Math.ceil(totalData / pageSize)) {
      setPage(page + 1);
    }
  };
  const handleInput = (e) => {
    let search = Number(e.target.value);
    if (search > 1 && search < Math.ceil(totalData / pageSize)) {
      setPage(search);
    }
  };

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: '#FAFAFA' }}>
            <CustomTableCells></CustomTableCells>
            <CustomTableCells>
              <Box
                sx={[
                  {
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    gap: 0.2,
                    cursor: 'pointer',
                  },
                ]}
                onClick={() => handleSortBased('position')}
              >
                Position
                {sortBy !== 'position' && (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
                {sortBy === 'position' && isAsc && (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                )}
                {sortBy === 'position' && !isAsc && (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(270deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
            <CustomTableCells>
              <Box
                sx={[
                  {
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    gap: 0.2,
                    cursor: 'pointer',
                    color: '#F1CC00',
                  },
                ]}
                onClick={() => handleSortBased('status')}
              >
                SKU
                {sortBy !== 'sku' && (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
                {sortBy === 'sku' && isAsc && (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                )}
                {sortBy === 'sku' && !isAsc && (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(270deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
            <CustomTableCells>
              <Box
                sx={[
                  {
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    gap: 0.2,
                    cursor: 'pointer',
                    color: '#51B15C',
                  },
                ]}
                onClick={() => handleSortBased('status')}
              >
                COGS(Rp)*
                {sortBy !== 'cogs' && (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
                {sortBy === 'cogs' && isAsc && (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                )}
                {sortBy === 'cogs' && !isAsc && (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(270deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(3)].map((_, index) => (
            <TableRow key={index}>
              <CustomTableCells>
                <Checkbox defaultChecked size="small" />
              </CustomTableCells>
              <CustomTableCells>sad</CustomTableCells>
              <CustomTableCells>asd</CustomTableCells>
              <CustomTableCells>sadsa</CustomTableCells>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* //!-------------------- PAGINATION ------------------------- */}
      {/* //! -------------------------- PAGINATION ------------------------------ */}
      <Grid
        container
        direction="row"
        coloumn={12}
        alignItems="center"
        sx={{ mt: '20px' }}
      >
        <Grid item xs={6} sx={{ fontSize: '14px', fontWeight: '500' }}>
          *000
        </Grid>
        <Grid
          container
          item
          direction="row"
          xs={6}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item sx={{ ml: '35px' }}>
            <Box sx={{ display: 'inline', mr: '10px' }}>
              <IconButton sx={{ p: 0 }} onClick={handleBack}>
                <ArrowBackIosIcon sx={{ color: '#323232', width: '16px' }} />
              </IconButton>
            </Box>
            <Box sx={{ display: 'inline', mr: '20px' }}>
              <OutlinedInput
                variant="outlined"
                type="number"
                value={page}
                onChange={handleInput}
                sx={{
                  border: '1px solid #9E9D9D',
                  width: '36px',
                  borderRadius: '5px',
                  textAlign: 'center',
                  '& .MuiOutlinedInput-input': {
                    padding: '5px 8px 5px 8px',
                    fontSize: '0.875rem',
                    boxShadow: 'none',
                    textAlign: 'center',
                    fontWeight: 600,
                    width: '25px',
                  },
                  '&:before': {
                    borderRadius: '5px',
                    borderBottom: 0,
                  },
                  '&:after': {
                    borderRadius: '5px',
                    borderBottom: 0,
                  },
                  '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'inline',
                fontWeight: '600',
                fontSize: '14px',
                color: '#9E9D9D',
              }}
            >
              of {Math.ceil(totalData / pageSize)}
            </Box>
            <Box sx={{ display: 'inline', ml: '10px' }}>
              <IconButton sx={{ p: 0 }} onClick={handleNext}>
                <ArrowForwardIosIcon sx={{ color: '#323232', width: '16px' }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default TablePerformanceRackList;
