import { Box, Checkbox, Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getSubsidiary } from '../../api/subsidiary';
import CustomCard from '../../components/shared/CustomCard';
import { LocalConvenienceStoreOutlined } from '@mui/icons-material';

const SubsidiaryChoose = (props) => {
  const { value, setValue } = props;

  const theme = useTheme();
  const [subsidiaryDatas, setSubsidiaryDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isSelect, setIsSelect] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);

  const [subSelected, setSubSelected] = useState([]);

  useEffect(() => {
    const sub = async () => {
      setIsLoading(true);
      try {
        const {
          data: {
            data: { subsidiaries },
          },
        } = await getSubsidiary();

        setSubsidiaryDatas(subsidiaries);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    sub();
  }, []);

  useEffect(() => {
    if (isCheckAll) {
      return setSubSelected(subsidiaryDatas);
    } else {
      return setSubSelected([]);
    }
  }, [isCheckAll]);

  useEffect(() => {
    if (subSelected.length === subsidiaryDatas.length) {
      return setIsCheckAll(true);
    } else {
      return setIsCheckAll(false);
    }
  }, [subSelected, subsidiaryDatas]);

  useEffect(() => {
    if (subSelected !== value) {
      setValue(subSelected);
    }
  }, [subSelected, value]);

  const handleClickCheckAll = (event) => {
    setIsCheckAll(event.target.checked);
  };

  const handleSelect = (item) => {
    if (subSelected.filter((v) => v.id === item.id).length !== 0) {
      setSubSelected(subSelected.filter((v) => v.id !== item.id));
    } else {
      setSubSelected([...subSelected, item]);
    }
  };

  const handleUnSelect = (item) => {
    setSubSelected(subSelected.filter((v) => v.id !== item.id));
  };

  return (
    <Box
      fullWIdth
      sx={{
        position: 'relative',
        bgcolor: 'white',
      }}
    >
      <CustomCard
        sx={{
          p: '10px',
          minHeight: '58px',
          position: isSelect ? 'absolute' : 'static',
          width: '100%',
          bgcolor: 'white',
          zIndex: '+1',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              minWidth: '90%',
            }}
          >
            <Checkbox
              checked={isCheckAll}
              onChange={(e) => handleClickCheckAll(e)}
            />
            <Box sx={{ display: 'flex', width: '100%' }}>
              {subSelected.map((item, index) => (
                <Box
                  sx={{
                    mx: '8px',
                    minWidth: '20%',
                    display: 'flex',
                    borderRadius: '4px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: theme.palette.primary.main,
                    p: '8px',
                    cursor: 'pointer',
                  }}
                  key={index}
                  onClick={() => handleUnSelect(item)}
                >
                  <Typography
                    sx={{ fontWeigth: '600', fontSize: '16px', color: 'white' }}
                  >
                    {item.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifySelf: 'end',
              cursor: 'pointer',
              mr: '4px',
            }}
            onClick={() => setIsSelect(!isSelect)}
          >
            {!isSelect ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowDownIcon sx={{ transform: 'rotate(180deg)' }} />
            )}
          </Box>
        </Box>
        {isSelect && (
          <Box sx={{ px: '8px', bgcolor: 'white', py: '16px' }}>
            <Grid container columns={12} spacing={2}>
              {subsidiaryDatas
                .filter((v) => !subSelected.includes(v))
                .map((item, index) => (
                  <Grid item xs={2}>
                    <Box
                      sx={{
                        bgcolor: '#FAFAFA',
                        borderRadius: '4px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        py: '8px',
                        cursor: 'pointer',
                      }}
                      key={index}
                      onClick={() => handleSelect(item)}
                    >
                      <Typography
                        sx={{
                          fontWeigth: '600',
                          fontSize: '16px',
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Box>
        )}
      </CustomCard>
    </Box>
  );
};

export default SubsidiaryChoose;
