import { Box, Typography } from "@mui/material";
import React from "react";

const Star = () => {
  return (
    <Box>
      <Typography sx={{ color: "red" }}>*</Typography>
    </Box>
  );
};

export default Star;
