import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomCard from '../../../../../../components/shared/CustomCard';
import {
  ArrowBackIosIcon,
  ArrowForwardIosIcon,
  ArrowRightAltIcon,
  ExportIcon,
  FilterAltIcon,
  SearchIcon,
  SyncAltIcon,
} from '../../../../../../assets/Icons';
import { getSpesifikDataAudit } from '../../../../../../api/audit';
import { useEffect } from 'react';
import {
  getHubDetailAudit,
  getIdDetailAudit,
} from '../../../../../../store/audit/detailAudit';
import {
  changeIdAuditDetailToAdjustment,
  changeIdQrToAdjustment,
  changeLocationAuditToAdjustment,
  goToAddAdjustment,
} from '../../../../../../store/adjustment/addAdjustment';
import { changePageAdjustment } from '../../../../../../store/Routing/addjustment';

const CustomTableCells = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '12px',
  textTransform: 'capitalize',
});

const TableListDetailAudit = ({ isSelect, setIsSelect, value, setValue }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const id = useSelector(getIdDetailAudit);
  const location = useSelector(getHubDetailAudit);
  const [dataDetailAudit, setDataDetailAudit] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [adjusData, setAdjustData] = useState([]);

  // !search
  const [search, setSearch] = useState('');
  const [purchaseKeyword, setPurchaseKeyword] = useState('');

  // ! table
  const [pages, setPages] = useState();
  const rowPerPageValues = [5, 10, 15, 20, 25];
  const [rowPerPage, setRowPerPage] = useState(rowPerPageValues[0]);
  const [currentPageTable, setCurrentPageTable] = useState(1);

  // ! sorting table
  const [sortTable, setSortTable] = useState('');
  const [asc, setAsc] = useState('asc');
  const [sortAscTable, setSortAscTable] = useState(true);

  const debounceOnChange = useRef(
    debounce((value) => {
      setPurchaseKeyword(value);
    }, 1000)
  ).current;

  // ! pagination
  const handleRowPerPage = (e) => {
    setRowPerPage(e.target.value);
    setCurrentPageTable(1);
  };
  const handleBackArrow = () => {
    setCurrentPageTable(
      currentPageTable <= 1 ? currentPageTable : currentPageTable - 1
    );
  };
  const handleForwardArrow = () => {
    setCurrentPageTable(
      currentPageTable >= Math.ceil(pages?.total / rowPerPage)
        ? currentPageTable
        : currentPageTable + 1
    );
  };
  const handleInputCurrentPage = (event) => {
    if (event.target.value >= Math.ceil(pages?.total / rowPerPage)) {
      setCurrentPageTable(Math.ceil(pages?.total / rowPerPage));
    } else if (event.target.value <= 1) {
      setCurrentPageTable(1);
    } else {
      setCurrentPageTable(event.target.value);
    }
  };

  // ! select & addjust data
  const handleIsSelect = () => {
    setIsSelect(true);
  };
  const handleCancelSelect = () => {
    setIsSelect(false);
    setAdjustData([]);
  };
  const handleToAdjust = () => {
    dispatch(goToAddAdjustment(adjusData));
    setValue(7); 
    dispatch(changePageAdjustment('addDifferenceAdjustment'));
    dispatch(changeIdAuditDetailToAdjustment(id));
    dispatch(changeLocationAuditToAdjustment(location));

    const auditQr = adjusData?.map((item, _) => {
      return item?.auditQrId;
    });
    dispatch(changeIdQrToAdjustment(auditQr));
  };

  // ! ---------------- fetch API ------------------
  const fetchSpesifikDataDetailAudit = async (id, params) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await getSpesifikDataAudit(id, params);
      setDataDetailAudit(data?.audits);
      setPages(data?.pageInfo);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let params = {};
    params.missing = false;
    fetchSpesifikDataDetailAudit(id, params);
  }, []);

  //! -------------- TOOLBAR -------------------
  const [isFilter, setIsFilter] = useState(false);
  const toolbar = (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flexDirection: 'row-reverse',
        alignItems: 'center',
      }}
    >
      {/* export */}
      <CustomCard
        sx={{
          p: '10px 20px',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          cursor: 'pointer',
        }}
        onClick={() => setIsFilter(!isFilter)}
      >
        <ExportIcon sx={{ fontSize: '20px' }} />
        <Typography
          sx={{ fontSize: '14px', fontWeight: 500, color: '#000000' }}
        >
          Export
        </Typography>
      </CustomCard>
      {/* //* Filter */}
      <CustomCard
        sx={{
          p: '10px 20px',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          cursor: 'pointer',
        }}
        onClick={() => setIsFilter(!isFilter)}
      >
        <FilterAltIcon sx={{ fontSize: '20px' }} />
        <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}>
          Filter
        </Typography>
      </CustomCard>
      {/* <DialogFilterStock {...{ isFilter, setIsFilter }} /> */}

      {isSelect ? (
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Box onClick={handleCancelSelect}>
            <Typography
              sx={{
                color: '#F33A3A',
                fontWeight: 600,
                fontSize: '14px',
                cursor: 'pointer',
              }}
            >
              Cancel
            </Typography>
          </Box>
          {adjusData?.length === 0 ? (
            <Box>
              <Typography
                sx={{
                  background: '#C8C8CC',
                  color: '#000000',
                  fontWeight: 600,
                  fontSize: '14px',
                  borderRadius: '8px',
                  padding: '10px 15px',
                }}
              >
                Adjust
              </Typography>
            </Box>
          ) : (
            <Box onClick={handleToAdjust}>
              <Typography
                sx={{
                  background: '#51B15C',
                  color: '#FFFFFF',
                  fontWeight: 600,
                  fontSize: '14px',
                  cursor: 'pointer',
                  borderRadius: '8px',
                  padding: '10px 15px',
                }}
              >
                Adjust
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Box onClick={handleIsSelect}>
          <Typography
            sx={{
              color: '#51B15C',
              fontWeight: 600,
              fontSize: '14px',
              cursor: 'pointer',
            }}
          >
            Select
          </Typography>
        </Box>
      )}
    </Box>
  );

  const handleChange = (event, item, id) => {
    if (adjusData.filter((val) => val.auditQrId === id).length === 0) {
      setAdjustData((prev) => [...prev, item]);
    } else {
      setAdjustData(adjusData.filter((val) => val.auditQrId !== id));
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* search */}
      <Grid container columnSpacing={4} alignItems="center">
        <Grid item xs>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              gap: 2,
            }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
              Audit
            </Typography>
            <TextField
              placeholder={'Search'}
              size="small"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                debounceOnChange(e.target.value);
              }}
              sx={{
                width: '250px',
                '& .MuiOutlinedInput-root': {
                  paddingLeft: '6px',
                  fontSize: '0.875rem',
                },
                bgcolor: '#FAFAFA',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ width: '18px' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>

        <Grid item xs="auto">
          {toolbar}
        </Grid>
      </Grid>
      {/* fetch ddata llist */}
      <Table
        sx={{
          marginTop: '12px',
          mb: 5,
        }}
      >
        <TableHead>
          <TableRow
            sx={{
              background: '#FAFAFA',
              fontWeight: 600,
            }}
          >
            <CustomTableCells>No</CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortProduct');
                  setSortAscTable(!sortAscTable);
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  Product
                </Typography>
                {sortTable === 'sortProduct' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortSystem');
                  setSortAscTable(!sortAscTable);
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  System
                </Typography>
                {sortTable === 'sortSystem' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortActual');
                  setSortAscTable(!sortAscTable);
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  Actual
                </Typography>
                {sortTable === 'sortActual' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortDiference');
                  setSortAscTable(!sortAscTable);
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  Difference
                </Typography>
                {sortTable === 'sortDiference' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
          </TableRow>
        </TableHead>
        {!isLoading && (
          <TableBody>
            {dataDetailAudit?.map((item, index) => (
              <TableRow
              key={index}
                sx={{ cursor: 'pointer' }}
                // onClick={() => dispatch(changePageAudit('detailsAudits'))}
              >
                {isSelect ? (
                  <CustomTableCells>
                    <Checkbox
                      checked={
                        adjusData.filter(
                          (val) => val.auditQrId === item.auditQrId
                        ).length !== 0
                      }
                      onChange={(event) => {
                        handleChange(event, item, item?.auditQrId);
                      }}
                    />
                  </CustomTableCells>
                ) : (
                  <CustomTableCells>
                    {(currentPageTable - 1) * rowPerPage + (index + 1)}
                  </CustomTableCells>
                )}
                <CustomTableCells>{item?.namaProduk}</CustomTableCells>
                <CustomTableCells>
                  {item?.system || '0'} {item?.systemUnit}
                </CustomTableCells>
                <CustomTableCells>
                  {item?.actual} {item?.actualUnit}
                </CustomTableCells>
                <CustomTableCells
                  sx={{ color: item?.difference !== 0 && '#F33A3A' }}
                >
                  {item?.difference} {item?.actualUnit}
                </CustomTableCells>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>

      {!isLoading && dataDetailAudit.length === 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <Typography sx={{ color: '#F33A3A' }}>No data found</Typography>
        </Box>
      )}

      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {/* pagination */}
      <Grid
        container
        direction="row"
        coloumn={12}
        alignItems="center"
        sx={{ mt: '30px' }}
      >
        <Grid item xs={6} sx={{ fontSize: '14px', fontWeight: '500' }}>
          Showing{' '}
          {pages?.currentPage ||
            0 * pages?.perPage ||
            0 - (pages?.perPage || 0 - 1)}{' '}
          to{' '}
          {pages?.currentPage || 0 * pages?.perPage || 0 >= pages?.total || 0
            ? pages?.total || 0
            : pages?.currentPage || 0 * pages?.perPage || 0}{' '}
          of {pages?.total || 0} entries
        </Grid>
        <Grid
          container
          item
          direction="row"
          xs={6}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item sx={{ fontSize: '14px', fontWeight: '500' }}>
            Rows Per Page :
            <Select
              value={rowPerPage}
              variant="filled"
              onChange={handleRowPerPage}
              sx={{
                fontSize: '14px',
                lineHeight: '-1px',
                '.MuiFilledInput-input': {
                  paddingTop: '7.5px',
                  paddingBottom: '7.5px',
                  paddingLeft: '10px',
                  paddingRight: '0',
                  borderRadius: '5px',
                },
                '&:before': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                '&:after': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                borderRadius: '5px',
                marginLeft: '8px',
              }}
            >
              {rowPerPageValues?.map((rowPerPageValue, index) => (
                <MenuItem value={rowPerPageValue} key={index}>
                  {rowPerPageValue}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item sx={{ ml: '35px' }}>
            <Box sx={{ display: 'inline', mr: '10px' }}>
              <IconButton sx={{ p: 0 }} onClick={handleBackArrow}>
                <ArrowBackIosIcon sx={{ color: '#323232', width: '16px' }} />
              </IconButton>
            </Box>
            <Box sx={{ display: 'inline', mr: '20px' }}>
              <OutlinedInput
                variant="outlined"
                type="number"
                value={currentPageTable}
                onChange={handleInputCurrentPage}
                sx={{
                  border: '1px solid #9E9D9D',
                  width: '36px',
                  borderRadius: '5px',
                  textAlign: 'center',
                  '& .MuiOutlinedInput-input': {
                    padding: '5px 8px 5px 8px',
                    fontSize: '0.875rem',
                    boxShadow: 'none',
                    textAlign: 'center',
                    fontWeight: 600,
                    width: '25px',
                  },
                  '&:before': {
                    borderRadius: '5px',
                    borderBottom: 0,
                  },
                  '&:after': {
                    borderRadius: '5px',
                    borderBottom: 0,
                  },
                  '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'inline',
                fontWeight: '600',
                fontSize: '14px',
                color: '#9E9D9D',
              }}
            >
              of {Math.ceil(pages?.total / pages?.perPage)}
            </Box>
            <Box sx={{ display: 'inline', ml: '10px' }}>
              <IconButton sx={{ p: 0 }} onClick={handleForwardArrow}>
                <ArrowForwardIosIcon sx={{ color: '#323232', width: '16px' }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default TableListDetailAudit;
