import {
  Box,
  CircularProgress,
  Dialog,
  Typography,
  useTheme,
} from '@mui/material';
import {
  AddIcon,
  CancelIcon,
  ErrorOutlineIcon,
  ImageOutlinedIcon,
  VideocamOutlinedIcon,
} from '../../assets/Icons';
import { useState } from 'react';
import { deleteFile, uploadImage, uploadVideo } from '../../api/storage';

const VideoAddComponent = ({
  resource, // * nama untuk resource di api
  handleAddVideo, //* fungsi mengmbalikan link ketika add
  data, //* data image itu sendiri
  isEdit = false, //* true -> tidak akan nembak api delete , false -> bakal nembak api delete
  handleDeleteVideo, //* funsi mengmbalikan link ketika delete
}) => {
  const [isMaxSize, setIsMaxSize] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [linkPreview, setLinkPreview] = useState('');

  const theme = useTheme();

  const fetchAddApi = async (datas) => {
    setisLoading(true);
    try {
      const {
        data: { data },
      } = await uploadVideo(datas);
      handleAddVideo(data.path);
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setisLoading(false);
    }
  };

  const fetchDelete = async (link) => {
    setisLoading(true);
    try {
      await deleteFile(link);
      handleDeleteVideo(link);
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setisLoading(false);
    }
  };

  const handleAdd = (e) => {
    if (e.target.files[0].size > 100000000) {
      setIsMaxSize(true);
    } else {
      setIsError(false);
      setIsMaxSize(false);
      let datas = {
        video: e.target.files[0],
        resource: resource,
      };
      fetchAddApi(datas);
    }
  };

  const handleDelete = (link) => {
    if (isEdit) {
      handleDeleteVideo(link);
    } else {
      fetchDelete(link);
    }
  };

  const handlePreview = (link) => {
    setLinkPreview(link);
  };

  const handleClosePreview = () => {
    setLinkPreview('');
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          pb: `${data?.length === 0 ? '16px' : '9px'}`,
        }}
      >
        <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
          Video
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '500',
            color: '#9E9D9D',
          }}
        >
          ( max size : 100MB )
        </Typography>
        {isMaxSize && !isError && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ErrorOutlineIcon
              sx={{
                fontSize: '12px',
                fontWeight: '500',
                color: theme.palette.warning.main,
                mr: '4px',
              }}
            />
            <Typography
              sx={{
                fontSize: '8px',
                fontWeight: '500',
                color: theme.palette.warning.main,
              }}
            >
              The video is too big
            </Typography>
          </Box>
        )}
        {!isMaxSize && isError && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ErrorOutlineIcon
              sx={{
                fontSize: '12px',
                fontWeight: '500',
                color: theme.palette.warning.main,
                mr: '4px',
              }}
            />
            <Typography
              sx={{
                fontSize: '8px',
                fontWeight: '500',
                color: theme.palette.warning.main,
              }}
            >
              Ops, Failed upload video
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {data?.length === 0 && (
            <Box
              sx={{
                height: '54px',
                width: '54px',
                aspectRatio: '1/1',
                borderRadius: '8px',
                bgcolor: '#F5F5F5',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderStyle: 'dashed',
                borderColor: '#9E9D9D',
                cursor: 'pointer',
              }}
              onClick={() => {
                document.getElementById('inputMenu_video').click();
              }}
            >
              <VideocamOutlinedIcon
                sx={{ color: '#9E9D9D', fontSize: '28px' }}
              />
            </Box>
          )}
          {data.map((input, index) => (
            <Box
              sx={{
                position: 'relative',
                overflow: 'visible',
                padding: '7px',
              }}
              key={index}
            >
              <CancelIcon
                sx={{
                  position: 'absolute',
                  fontSize: '20px',
                  top: 1,
                  right: 1,
                  zIndex: +1,
                  cursor: 'pointer',
                }}
                onClick={() => handleDelete(input)}
              />
              <Box onClick={() => handlePreview(input)}>
                <video
                  style={{
                    maxHeight: '54px',
                    maxWidth: '54px',
                    aspectRatio: '1/1',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    backgroundColor: 'black',
                  }}
                >
                  <source
                    src={
                      process.env.REACT_APP_API_IMAGE_BASE_URL +
                      '/' +
                      input +
                      '#t=0.5'
                    }
                  />
                </video>
              </Box>
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            display: 'flex',
            mb: '7px',
            ml: '20px',
            alignItems: 'center',
          }}
        >
          {isLoading && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: theme.palette.primary.main,
                cursor: 'pointer',
              }}
            >
              <CircularProgress size={20} />
            </Box>
          )}
          {data?.length < 3 && !isLoading && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: theme.palette.primary.main,
                cursor: 'pointer',
              }}
              onClick={() => {
                document.getElementById('inputMenu_video').click();
              }}
            >
              <input
                // accept="video/*"
                id="inputMenu_video"
                type="file"
                style={{ display: 'none' }}
                onChange={handleAdd}
              />
              <AddIcon />
              <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                Add More
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Dialog open={linkPreview !== ''} onClose={handleClosePreview}>
        {/* <Box
            component="video"
            src={
              process.env.REACT_APP_API_IMAGE_BASE_URL +
              '/' +
              menuAddInputDatas.linkPreviewVideo
            }
            sx={{ width: '600px', height: '600px', aspectRation: '1/1' }}
          /> */}
        <Box sx={{ bgcolor: 'black' }}>
          <video
            style={{
              width: '600px',
              height: '600px',
              backgroundColor: 'black',
            }}
            controls
          >
            <source
              src={process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + linkPreview}
            />
          </video>
        </Box>
      </Dialog>
    </Box>
  );
};
export default VideoAddComponent;
