import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Switch,
  Dialog,
  styled,
} from '@mui/material';
import { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

import dayjs from 'dayjs';
import { uploadImage } from '../../../../../../api/storage';
import CustomEditConfirm from '../../../../../../components/shared/CustomEditConfirm';
import CustomDeleteConfirm from '../../../../../../components/shared/CustomDeleteConfirm';
import BackButton from '../../../../../../components/shared/BackButton';
import CustomInput from '../../../../../../components/shared/CustomInput';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import CustomButton from '../../../../../../components/shared/CustomButton';

const SubCategoryMenuEditMobile = (props) => {
  const {
    theme,
    today,
    AntSwitch,
    // *-- Page
    setPage,
    setSubCategoryMenu,
    // *-- hirarki
    roadHirarki,
    setRoadHirarki,
    // *-- data
    subCategoryEditInputDatas,
    setSubCategoryEditInputDatas,
    // *--- Handler
    handleBackButton,
    handleEdit,
    handleDelete,
    handleAddImage,
    handleDeleteImage,
    handlePreviewImage,
    handleClosePreviewImage,
  } = props;
  return (
    <Grid container columns={12} spacing={2}>
      {/* Edit Category */}
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
          Edit Category
        </Typography>
      </Grid>
      {/* //* Name */}
      <Grid item xs={12}>
        <CustomInput
          sx={{
            bgcolor: '#FAFAFA',
            borderRadius: '8px',
            width: '100%',
            '.MuiOutlinedInput-input': {
              fontSize: '12px',
              padding: '12px',
            },
          }}
          placeholder="Name*"
          value={subCategoryEditInputDatas.name}
          onChange={(e) => {
            if (e.target.value.length <= 30) {
              setSubCategoryEditInputDatas({
                ...subCategoryEditInputDatas,
                name: e.target.value,
              });
            }
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '8px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            Enter at least 5 character
          </Typography>
          <Typography
            sx={{
              fontSize: '8px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            {subCategoryEditInputDatas.name.length}/30
          </Typography>
        </Box>
      </Grid>
      {/* //* Photo */}
      <Grid item xs={6} sx={{ minHeight: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            pb: `${
              subCategoryEditInputDatas.photo.length === 0 ? '16px' : '9px'
            }`,
          }}
        >
          <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
            Photo
          </Typography>
          <Typography
            sx={{
              fontSize: '8px',
              fontWeight: '500',
              color: '#9E9D9D',
            }}
          >
            ( max size : 2MB )
          </Typography>
          {subCategoryEditInputDatas.isMaxSizePhoto && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ErrorOutlineIcon
                sx={{
                  fontSize: '8px',
                  fontWeight: '500',
                  color: theme.palette.warning.main,
                  mr: '4px',
                }}
              />
              <Typography
                sx={{
                  fontSize: '8px',
                  fontWeight: '500',
                  color: theme.palette.warning.main,
                }}
              >
                The photo can't be more than 2MB
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {subCategoryEditInputDatas.photoUrls.length === 0 && (
              <Box
                sx={{
                  height: '54px',
                  width: '54px',
                  aspectRatio: '1/1',
                  borderRadius: '8px',
                  bgcolor: '#F5F5F5',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderStyle: 'dashed',
                  borderColor: '#9E9D9D',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  document.getElementById('inputSubCat_img').click();
                }}
              >
                <ImageOutlinedIcon
                  sx={{ color: '#9E9D9D', fontSize: '28px' }}
                />
              </Box>
            )}
            {subCategoryEditInputDatas.photoUrls.map((input, index) => (
              <Box
                sx={{
                  position: 'relative',
                  overflow: 'visible',
                  padding: '7px',
                }}
                key={index}
              >
                <CancelIcon
                  sx={{
                    position: 'absolute',
                    fontSize: '20px',
                    top: 1,
                    right: 1,
                    zIndex: +1,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleDeleteImage(input)}
                />
                <Box
                  component="img"
                  src={process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + input}
                  sx={{
                    height: '54px',
                    width: '54px',
                    aspectRatio: '1/1',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handlePreviewImage(input)}
                />
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: 'flex',
              mb: '7px',
              ml: '20px',
              alignItems: 'center',
            }}
          >
            {subCategoryEditInputDatas.photoUrls.length < 3 && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.palette.primary.main,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  document.getElementById('inputSubCat_img').click();
                }}
              >
                <input
                  accept="image/*"
                  id="inputSubCat_img"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleAddImage}
                />
                <AddIcon sx={{ fontSize: '16px' }} />
                <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
                  Add More
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      {/* DIalog Photo */}
      {subCategoryEditInputDatas.linkPreviewPhoto !== '' && (
        <Dialog
          open={subCategoryEditInputDatas.isPreviewPhoto}
          onClose={() => {
            setSubCategoryEditInputDatas({
              ...subCategoryEditInputDatas,
              isPreviewPhoto: !subCategoryEditInputDatas.isPreviewPhoto,
            });
          }}
        >
          <Box
            component="img"
            src={
              process.env.REACT_APP_API_IMAGE_BASE_URL +
              '/' +
              subCategoryEditInputDatas.linkPreviewPhoto
            }
            sx={{ aspectRation: '1/1' }}
          />
        </Dialog>
      )}
      {/*//* Description */}
      <Grid item xs={12}>
        <CustomInput
          sx={{
            width: '100%',
            bgcolor: '#FAFAFA',
            '.MuiOutlinedInput-input': {
              fontSize: '12px',
              padding: '12px',
            },
          }}
          placeholder="Description"
          value={subCategoryEditInputDatas.description}
          onChange={(e) => {
            if (e.target.value.length <= 250) {
              setSubCategoryEditInputDatas({
                ...subCategoryEditInputDatas,
                description: e.target.value,
              });
            }
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '8px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            Enter at least 20 character
          </Typography>
          <Typography
            sx={{
              fontSize: '8px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            {subCategoryEditInputDatas.description.length}/250
          </Typography>
        </Box>
      </Grid>
      {/*//* Schedule */}
      <Grid item xs={12} sx={{}}>
        <Box sx={{ display: 'flex', minWidth: '100%' }}>
          <DesktopDatePicker
            inputFormat="DD/MM/YYYY"
            value={subCategoryEditInputDatas.schedule}
            onChange={(newValue) => {
              if (subCategoryEditInputDatas.scheduleType !== 'now') {
                setSubCategoryEditInputDatas({
                  ...subCategoryEditInputDatas,
                  schedule: newValue,
                });
              } else {
                setSubCategoryEditInputDatas({
                  ...subCategoryEditInputDatas,
                  schedule: today,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  width: '100%',
                  bgcolor: '#FAFAFA',
                  '.MuiOutlinedInput-input': {
                    fontSize: '12px',
                    padding: '12px',
                  },
                }}
              />
            )}
          />
        </Box>
      </Grid>
      {/*//* STATUS */}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
          Publish
        </Typography>
        <AntSwitch
          checked={subCategoryEditInputDatas.publish}
          onChange={(e) => {
            setSubCategoryEditInputDatas({
              ...subCategoryEditInputDatas,
              publish: !subCategoryEditInputDatas.publish,
            });
          }}
        />
      </Grid>
      {/* //* Button */}
      <Grid
        item
        xs={12}
        sx={{ mt: '28px', display: 'flex', alignItems: 'center', gap: 0.5 }}
      >
        <CustomButton
          // disabled={!isSubmitAvailable}
          onClick={handleDelete}
          variant="contained"
          color="error"
          sx={{
            width: '100%',
          }}
        >
          Delete
        </CustomButton>
        <CustomButton
          // disabled={!isSubmitAvailable}
          onClick={handleEdit}
          variant="contained"
          sx={{
            width: '100%',
          }}
        >
          Edit
        </CustomButton>
      </Grid>
    </Grid>
  );
};

export default SubCategoryMenuEditMobile;
