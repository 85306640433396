import { createSlice } from '@reduxjs/toolkit';
import { getAllHubs, getAllMiniHub, getAllSubHub } from '../../api/hubs';

export const auditTableState = createSlice({
  name: 'auditTable',
  initialState: {
    isLoading: false,
    isLoadingFilter: false,
    totalItem: 0,
    hub: [],
    subHub: [],
    miniHub: [],

    //  filter
    hubs: null,
    date: [],
    startDateFilterAudit: '',
    endDateFilterAudit: '',
  },
  reducers: {
    loadTable: (state, action) => {
      state.value = action.payload;
    },
    loadTotalItem: (state, action) => {
      state.totalItem = action.payload;
    },
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    // ! sorting
    changeSortAsc: (state, action) => {
      state.sortAsc = action.payload;
    },
    changeSortIn: (state, action) => {
      state.sortIn = action.payload;
    },

    // ! filter
    changeIsLoadingFilter: (state, action) => {
      state.isLoadingFilter = action.payload;
    },
    changeFilterHubs: (state, action) => {
      state.hubs = action.payload;
    },
    loadAllDataHub: (state, action) => {
      state.hub = action.payload;
    },
    loadAllDataSubHub: (state, action) => {
      state.subHub = action.payload;
    },
    loadAllDataMiniHub: (state, action) => {
      state.miniHub = action.payload;
    },
    changeFilterDate: (state, action) => {
      state.to = action.payload;
    },
    changeStartDateFilterAudit: (state, action) => {
      state.startDateFilterAudit = action.payload;
    },
    changeEndDateFilterAudit: (state, action) => {
      state.endDateFilterAudit = action.payload;
    },
  },
});

export const {
  loadTotalItem,
  changeIsLoading,
  changeIsLoadingFilter,
  loadAllDataHub,
  loadAllDataSubHub,
  loadAllDataMiniHub,
  changeFilterHubs,
  changeFilterDate,
  changeStartDateFilterAudit,
  changeEndDateFilterAudit,
} = auditTableState.actions;

export const getAuditIsLoading = (state) => state.auditTable.isLoading;
export const getAuditTotalItem = (state) => state.auditTable.totalItem;

// ! filter
export const getDataHubAudit = (state) => state.auditTable.hub;
export const getDataSubHubAudit = (state) => state.auditTable.subHub;
export const getDataMiniHubAudit = (state) => state.auditTable.miniHub;
export const getDataFilterHubs = (state) => state.auditTable.hubs;
export const getDataFilterDate = (state) => state.auditTable.date;
export const getStartDateFilterAudit = (state) =>
  state.auditTable.startDateFilterAudit;
export const getEndDateFilterAudit = (state) =>
  state.auditTable.endDateFilterAudit;

// !--------------- API ------------------------

// get hub
export const getAllMasterHub = (params) => (dispatch) => {
  const fetchDataHub = async () => {
    dispatch(changeIsLoadingFilter(true));
    try {
      const {
        data: {
          data: { hubs },
        },
      } = await getAllHubs(params);
      let sliceData = hubs.map((hub, _) => {
        return { ...hub, hubType: 'hub' };
      });

      let fixDatas = sliceData?.map((data, _) => {
        return {
          hubId: data?.idToko,
          label: data?.namaToko,
          hubType: data?.hubType,
        };
      });
      dispatch(loadAllDataHub(fixDatas));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoadingFilter(false));
    }
  };
  fetchDataHub();
};

// get sub hub
export const getAllSubHubData = (params) => (dispatch) => {
  const fetchDataSubHub = async () => {
    dispatch(changeIsLoadingFilter(true));
    try {
      const {
        data: {
          data: { subHubs },
        },
      } = await getAllSubHub(params);
      let sliceDataSubHub = subHubs.map((subHub, _) => {
        return { ...subHub, hubType: 'subhub' };
      });

      let fixDatasSubHub = sliceDataSubHub?.map((data, _) => {
        return {
          hubId: data?.id,
          label: data?.name,
          hubType: data?.hubType,
        };
      });
      dispatch(loadAllDataSubHub(fixDatasSubHub));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoadingFilter(false));
    }
  };
  fetchDataSubHub();
};

// get mini hub
export const getAllMiniHubData = (params) => (dispatch) => {
  const fetchDataMiniHub = async () => {
    dispatch(changeIsLoadingFilter(true));
    try {
      const {
        data: {
          data: { subHubs },
        },
      } = await getAllMiniHub(params);
      let sliceDataMiniHub = subHubs.map((miniHub, _) => {
        return { ...miniHub, hubType: 'minihub' };
      });

      let fixDatasMiniHub = sliceDataMiniHub?.map((data, _) => {
        return {
          hubId: data?.id,
          label: data?.name,
          hubType: data?.hubType,
        };
      });
      dispatch(loadAllDataMiniHub(fixDatasMiniHub));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoadingFilter(false));
    }
  };
  fetchDataMiniHub();
};

export default auditTableState.reducer;
