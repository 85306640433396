import { Button } from '@mui/material';
import React from 'react';

const CustomButton = ({ children, disabled, ...props }) => {
  return (
    <Button disabled={disabled} {...props}>
      {children}
    </Button>
  );
};

export default CustomButton;
