import {
  Autocomplete,
  Box,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import BackButtonHub from '../../../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePageTransaction,
  setNotifTransaction,
} from '../../../../../../../../store/Routing/transactionRouting';
import {
  AddIcon,
  DeleteIcon,
  KeyboardArrowDownIcon,
} from '../../../../../../../../assets/Icons';
import CustomCard from '../../../../../../../../components/shared/CustomCard';
import ProductSelect from '../../../../Purchase/Request/Add/component/ProductSelect';
import QtySelect from '../../../../Purchase/Request/Add/component/QtySelect';
import CustomButton from '../../../../../../../../components/shared/CustomButton';
import { useState } from 'react';
import {
  getAllMasterHub,
  getAllMiniHubData,
  getAllSubHubData,
  getDataHubTransaction,
  getDataMiniHubTransaction,
  getDataSubHubTransaction,
} from '../../../../../../../../store/transaction/request/addTransaction';
import { useEffect } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import PlaceIcon from '@mui/icons-material/Place';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  editDetailTransactionRequest,
  getDestinationTransaction,
  insertDataTransaction,
} from '../../../../../../../../api/transaction';
import { changeIsEditTransaction } from '../../../../../../../../store/transaction/request/detailTransactionRequest';
import { getDataEditTransactionRequest } from '../../../../../../../../store/transaction/request/editTransactionRequest';

const EditTransactionMobile = () => {
  const dispatch = useDispatch();
  const [collapseDestination, setCollapseDestination] = useState(true);
  const dataDetailToEdit = useSelector(getDataEditTransactionRequest);
  const idTransaction = dataDetailToEdit?.idOrders;
  const allDataHubs = useSelector(getDataHubTransaction);
  const allDataSubHubs = useSelector(getDataSubHubTransaction);
  const allDataMiniHubs = useSelector(getDataMiniHubTransaction); // semua data redux di ganti
  const [mixData, setMixData] = useState([]);
  const [fromDataList, setFromDataList] = useState([]);
  const [isLoadingDestination, setIsLoadingDestination] = useState(false);
  const [isloadingSubmitTransaction, setIsloadingSubmitTransaction] =
    useState(false);
  const [detailDestinationHub, setDetailDestinationHub] = useState(false);
  const [dataDetailDestination, setDataDetailDestination] = useState();

  // ! validation
  const [isDisableTo, setIsDisableTo] = useState(false);
  const [isDisableProduct, setIsDisableProduct] = useState(false);
  const [isDisableQty, setIsDisableQty] = useState(false);
  const [isDisableUnit, setIsDisableUnit] = useState(false);
  const [isFieldTo, setIsFieldTo] = useState(true);
  const [isFieldFrom, setIsFieldFrom] = useState(false);
  const [isDeleteIngredientRow, setIsDeleteIngredientRow] = useState(false);
  const [allowNext, setAllowNext] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);

  useEffect(() => {
    dispatch(getAllMasterHub({ pageSize: 1000 }));
    dispatch(getAllSubHubData({ pageSize: 1000 }));
    dispatch(getAllMiniHubData({ pageSize: 1000 }));
  }, []);

  // ! mix data
  useEffect(() => {
    const mixs = [].concat(allDataHubs, allDataSubHubs, allDataMiniHubs);
    setMixData(mixs);
  }, [allDataHubs, allDataSubHubs, allDataMiniHubs]);

  // ! form add
  const [fromChoose, setFromChoose] = useState(null);
  const [toChoose, setToChoose] = useState({
    id: dataDetailToEdit?.lokasiTujuan,
    label: dataDetailToEdit?.namaTujuan,
    typeHub: dataDetailToEdit?.tabelTujuan === 'toko' && 'hub',
  });
  // items
  //   const [tambahItem, setTambahItem] = useState([
  //     {
  //       namaProduk: '',
  //       idProduk: 0,
  //       jumlah: 0,
  //       unit: '',
  //     },
  //   ]);
  const [tambahItem, setTambahItem] = useState(dataDetailToEdit?.details);

  useEffect(() => {
    if (
      toChoose !== null &&
      tambahItem[0].idProduk !== 0 &&
      tambahItem[0].namaProduk !== '' &&
      tambahItem[0].jumlah !== 0 &&
      tambahItem[0].unit !== ''
    ) {
      setAllowNext(true);
    } else {
      setAllowNext(false);
    }
  }, [toChoose, tambahItem]);

  useEffect(() => {
    if (
      toChoose !== null &&
      fromChoose !== null &&
      tambahItem[0].idProduk !== 0 &&
      tambahItem[0].namaProduk !== '' &&
      tambahItem[0].jumlah !== 0 &&
      tambahItem[0].unit !== ''
    ) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [toChoose, tambahItem, fromChoose]);

  const handleAddItemCardIngredient = () => {
    setTambahItem([
      ...tambahItem,
      { namaProduk: '', idProduk: 0, jumlah: 0, unit: '' },
    ]);
  };

  //! Handler
  const handleNext = () => {
    setIsLoadingDestination(true);
    const fetchDestination = async () => {
      let params = {};
      params.to = toChoose?.typeHub;
      params.id = toChoose?.id;
      params.products = tambahItem;

      try {
        const {
          data: { data },
        } = await getDestinationTransaction(params);
        if (data === 'data not found') {
          dispatch(
            setNotifTransaction({
              key: 'nextAdd',
              text: 'data product not available',
            })
          );
        } else {
          setFromDataList(data);
          setIsFieldFrom(true);
          setIsFieldTo(false);
          setIsDisableTo(true);
          setIsDisableProduct(true);
          setIsDisableQty(true);
          setIsDisableUnit(true);
          setCollapseDestination(false);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoadingDestination(false);
      }
    };
    fetchDestination();
  };
  const handleBack = () => {
    setIsFieldFrom(false);
    setIsFieldTo(true);
    setIsDisableTo(false);
    setIsDisableProduct(false);
    setIsDisableQty(false);
    setIsDisableUnit(false);
    setFromChoose(null);
    setCollapseDestination(true);
  };
  const handleChange = (event) => {
    setFromChoose(event.target.value);
  };
  const handleSubmit = () => {
    let datas = {};
    datas.pemberi =
      fromChoose?.hubType === 'sub_hubs'
        ? 'subHub'
        : fromChoose?.hubType === 'mini_hubs'
        ? 'miniHub'
        : fromChoose?.hubType;
    datas.namaPemberi = fromChoose?.hubName;
    datas.lokasiPemberi = Number(fromChoose?.hubId);
    datas.products = tambahItem;
    datas.tujuan =
      toChoose?.typeHub === 'sub_hubs'
        ? 'subHub'
        : toChoose?.typeHub === 'mini_hubs'
        ? 'miniHub'
        : toChoose?.typeHub;
    datas.namaTujuan = toChoose?.label;
    datas.lokasiTujuan = toChoose?.id;

    setIsloadingSubmitTransaction(true);

    const updateData = async () => {
      try {
        await editDetailTransactionRequest(idTransaction, datas);
        setIsloadingSubmitTransaction(false);
        dispatch(
          setNotifTransaction({
            key: 'editTransaction',
            text: 'transaction has been uptodate',
          })
        );
        dispatch(changePageTransaction('request'));
      } catch (err) {
        console.error(err);
      } finally {
        setIsloadingSubmitTransaction(false);
      }
    };

    updateData();
  };
  return (
    <Box
      style={{
        minHeight: '80vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
          pt: 2,
        }}
      >
        <BackButtonHub
          onClick={() => dispatch(changeIsEditTransaction(false))}
        />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
            Edit stock request
          </Typography>
          <DeleteIcon sx={{ color: '#FF0000' }} />
        </Box>
      </Box>

      <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box
          sx={{
            width: '22px',
            height: '22px',
            background: '#51B15C',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontSize: '10px', color: '#FFF', fontWeight: 600 }}>
            {isFieldTo ? (
              '1'
            ) : (
              <CheckIcon sx={{ color: '#FFFFFF', width: '22px' }} />
            )}
          </Typography>
        </Box>
        <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
          Select destination and add items
        </Typography>
      </Box>
      <Box
        sx={{
          borderLeft: '2px solid #E4E4E4',
          mt: 1,
          ml: 1,
          minHeight: '20px',
          padding: '16px 0px 16px 16px',
        }}
      >
        <Collapse in={collapseDestination} collapsedSize={20}>
          {collapseDestination ? (
            <Grid container columns={12} spacing={3}>
              {/* to */}
              <Grid item xs={12}>
                <Autocomplete
                  disabled={isDisableTo}
                  sx={{ width: '100%' }}
                  options={mixData}
                  value={toChoose}
                  getOptionLabel={(option) => option.label}
                  onChange={(_, newValue) => {
                    setToChoose(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="To*"
                      sx={{
                        bgcolor: '#fafafa',
                        borderRadius: '5px',
                        fontSize: '14px',
                        color: '#9D9D9D',
                      }}
                    />
                  )}
                />
              </Grid>

              {/* items */}
              <Grid item xs={12}>
                <CustomCard>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: '20px',
                      boxShadow: '0px 1px 5px #ccc',
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                      Item(s)
                    </Typography>
                    {isFieldTo ? (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                          display: 'flex',
                          alignItems: 'center',
                          color: '#51B15C',
                          gap: 1,
                          cursor: 'pointer',
                        }}
                        onClick={handleAddItemCardIngredient}
                      >
                        <AddIcon />
                        Add more
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 600,
                          display: 'flex',
                          alignItems: 'center',
                          color: '#51B15C',
                          gap: 1,
                        }}
                      >
                        <AddIcon />
                        Add more
                      </Typography>
                    )}
                  </Box>

                  <Box>
                    <Grid
                      container
                      columns={12}
                      spacing={3}
                      sx={{ p: '30px 20px' }}
                    >
                      {tambahItem.map((item, index) => (
                        <Grid item xs={12} sx={{ display: 'flex' }}>
                          <Grid container columns={12} spacing={2}>
                            {/* product */}
                            <Grid item xs={5}>
                              <ProductSelect
                                isDisableProduct={isDisableProduct}
                                firstValueId={item?.Product?.idProduk}
                                firstValue={item?.product?.namaProduk}
                                isReset={isDeleteIngredientRow}
                                setIsReset={setIsDeleteIngredientRow}
                                handleValue={(name, id) => {
                                  const memo = tambahItem.map((item, idx) => {
                                    if (index === idx) {
                                      return {
                                        ...item,
                                        idProduk: id,
                                        namaProduk: name,
                                      };
                                    } else {
                                      return item;
                                    }
                                  });
                                  setTambahItem(memo);
                                }}
                              />
                            </Grid>
                            {/* jumlah */}
                            <Grid item xs={5}>
                              <QtySelect
                                isDisableQty={isDisableQty}
                                isDisableUnit={isDisableUnit}
                                firstValueQty={item.jumlah}
                                firstValueUnit={item.unit}
                                isReset={isDeleteIngredientRow}
                                setIsReset={setIsDeleteIngredientRow}
                                handleValue={(jumlah, unit) => {
                                  const memo = tambahItem.map((item, idx) => {
                                    if (index === idx) {
                                      return {
                                        ...item,
                                        jumlah: jumlah,
                                        unit: unit,
                                      };
                                    } else {
                                      return item;
                                    }
                                  });
                                  setTambahItem(memo);
                                }}
                              />
                            </Grid>

                            {/* hapus item */}
                            {isFieldTo ? (
                              <Grid
                                item
                                xs="auto"
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  color: 'red',
                                }}
                              >
                                {tambahItem.length === 1 ? (
                                  <></>
                                ) : (
                                  <DeleteIcon
                                    sx={{
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      setTambahItem(
                                        tambahItem.filter(
                                          (_, idx) => idx !== index
                                        )
                                      );
                                    }}
                                  />
                                )}
                              </Grid>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </CustomCard>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                  <CustomButton
                    variant="contained"
                    onClick={handleNext}
                    disabled={!allowNext}
                    sx={{ width: '159px', height: '40px' }}
                  >
                    <Typography
                      sx={{
                        color: '#FFFFFF',
                        fontSize: '14px',
                        fontWeight: 500,
                      }}
                    >
                      {isLoadingDestination ? (
                        <CircularProgress
                          sx={{
                            color: '#FFFFFF',
                            width: '12px',
                            fontWeight: 600,
                          }}
                        />
                      ) : (
                        'Next'
                      )}
                    </Typography>
                  </CustomButton>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Collapse>
      </Box>

      {/* from fields */}
      <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box
          sx={{
            width: '22px',
            height: '22px',
            background: isFieldFrom ? '#51B15C' : '#E4E4E4',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '10px',
              color: isFieldFrom ? '#FFF' : '#000',
              fontWeight: 600,
            }}
          >
            2
          </Typography>
        </Box>
        <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
          Select available hub
        </Typography>
      </Box>

      {isFieldFrom && (
        <Box sx={{ ml: 1, padding: '16px 0px 16px 16px' }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fromChoose}
                onChange={handleChange}
                placeholder="Hub"
              >
                {fromDataList?.map((item, index) => {
                  return (
                    <MenuItem value={item}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'center',
                          gap: 2,
                          width: '100%',
                        }}
                      >
                        <Box
                          sx={{
                            width: '54px',
                            height: '54px',
                            borderRadius: '5px',
                            background: '#9D9D9E',
                          }}
                        >
                          image
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            sx={{ fontSize: '14px', fontWeight: 600 }}
                          >
                            {item?.hubName}
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              gap: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexFlow: 'column',
                                gap: 1,
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                <PlaceIcon
                                  sx={{
                                    color: '#51B15C',
                                    width: '15px',
                                    height: '15px',
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    color: '#000000',
                                  }}
                                >
                                  {Number(
                                    (
                                      item?.distance.split(' ', 1) * 1000
                                    ).toFixed(2)
                                  )}{' '}
                                  m
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                <CheckCircleIcon
                                  sx={{
                                    color: '#51B15C',
                                    width: '15px',
                                    height: '15px',
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    color: '#000000',
                                  }}
                                >
                                  {item?.produk?.jumlah?.jumlah} products
                                  available
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <KeyboardArrowDownIcon />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Box
            sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Typography
              onClick={handleBack}
              sx={{ color: '#51B15C', fontSize: '14px', fontWeight: 600 }}
            >
              Back
            </Typography>

            <CustomButton
              variant="contained"
              onClick={handleSubmit}
              disabled={!allowSubmit}
              sx={{ width: '159px', height: '40px' }}
            >
              {isloadingSubmitTransaction ? (
                <CircularProgress sx={{ color: '#FFFFFF', width: '14px' }} />
              ) : (
                <Typography
                  sx={{ color: '#FFFFFF', fontSize: '14px', fontWeight: 600 }}
                >
                  Submit
                </Typography>
              )}
            </CustomButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EditTransactionMobile;
