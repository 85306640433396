import { createSlice } from '@reduxjs/toolkit';

export const pagePurchaseState = createSlice({
  name: 'pagePurchase',
  initialState: {
    value: 'purchase',
  },
  reducers: {
    changePage: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changePage } = pagePurchaseState.actions;

export const getPagePurchase = (state) => state.pagePurchase.value;

export default pagePurchaseState.reducer;
