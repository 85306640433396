import { Box, Typography, useTheme } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import BackButton from '../../../../../../../../components/shared/BackButton';
import { useDispatch, useSelector } from 'react-redux';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import {
  changeCreateMapPage,
  changeIsCreateMap,
  getMasterHubEditAll,
} from '../../../../../../../../store/hub/masterHub/masterHubEdit';
import SelectRacks from './CreateMap/SelectRacks';
import Preview from './CreateMap/Preview';
import CreateGridEditHub from './CreateMap/CreateGrid';

const CreateMapMasterHubEdit = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    createMapPage: mapPage,
    col,
    row,
    cellSelected,
    cellSelectedAll,
  } = useSelector(getMasterHubEditAll);
  const handleBackButton = () => {
    dispatch(changeIsCreateMap(false));
  };

  return (
    <Box>
      {/* //* Back Button */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={() => handleBackButton()} />
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: '600',
            color: '#9E9D9D',
            cursor: 'pointer',
          }}
          onClick={() => handleBackButton()}
        >
          Edit Master Hub
        </Typography>
        <ChevronRightIcon sx={{ color: '#9E9D9D' }} />
        <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
          Create Map
        </Typography>
      </Box>
      {/* //* Alur */}
      <Box
        sx={{
          bgcolor: '#FAFAFA',
          p: '20px',
          mt: '20px',
          mb: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {/* //* Grid */}
        <Box
          sx={{
            width: '31%',
            p: '16px',
            bgcolor: mapPage !== 'grid' ? '#51B15C1A' : 'white',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: 1,
            borderColor:
              mapPage === 'grid' ? theme.palette.primary.main : 'white',
            cursor: mapPage !== 'grid' && 'pointer',
          }}
          onClick={() => dispatch(changeCreateMapPage('grid'))}
        >
          <Box>
            <Typography sx={{ fontWeight: '600', mb: '4px' }}>
              Create Grid
            </Typography>
            <Typography sx={{ fontSize: '12px' }}>
              Enter number of columns and rows
            </Typography>
          </Box>
          <Box
            sx={{
              borderRadius: '50%',
              bgcolor:
                mapPage !== 'grid' ? theme.palette.primary.main : '#FAFAFA',
              width: '30px',
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {mapPage !== 'grid' ? (
              <DoneRoundedIcon sx={{ color: 'white' }} />
            ) : (
              <Typography sx={{ fontWeight: '600' }}>1</Typography>
            )}
          </Box>
        </Box>
        <ChevronRightIcon sx={{}} />
        {/* //* Racks */}
        <Box
          sx={{
            width: '31%',
            p: '16px',
            bgcolor: mapPage === 'preview' ? '#51B15C1A' : 'white',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: 1,
            borderColor:
              mapPage === 'racks' ? theme.palette.primary.main : 'white',
            cursor:
              mapPage !== 'rack' && cellSelected.length !== 0 && 'pointer',
          }}
          onClick={() => {
            cellSelected.length !== 0 && dispatch(changeCreateMapPage('racks'));
            // dispatch(unSeeDetailRack());
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: '600', mb: '4px' }}>
              Select Racks
            </Typography>
            <Typography sx={{ fontSize: '12px' }}>
              Drag the area and enter column
            </Typography>
          </Box>

          <Box
            sx={{
              bgcolor:
                mapPage === 'preview' ? theme.palette.primary.main : '#FAFAFA',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {mapPage === 'preview' ? (
              <DoneRoundedIcon sx={{ color: 'white' }} />
            ) : (
              <Typography sx={{ fontWeight: '600' }}>2</Typography>
            )}
          </Box>
        </Box>
        <ChevronRightIcon sx={{}} />
        {/* //* Preview */}
        <Box
          sx={{
            width: '31%',
            p: '16px',
            bgcolor: 'white',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: 1,
            borderColor:
              mapPage === 'preview' ? theme.palette.primary.main : 'white',
            cursor:
              mapPage !== 'preview' && cellSelected.length !== 0 && 'pointer',
          }}
          onClick={() => {
            cellSelected.length !== 0 &&
              dispatch(changeCreateMapPage('preview'));
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: '600', mb: '4px' }}>
              Preview
            </Typography>
            <Typography sx={{ fontSize: '12px' }}>
              Preview map and finish
            </Typography>
          </Box>
          <Box
            sx={{
              bgcolor: '#FAFAFA',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography sx={{ fontWeight: '600' }}>3</Typography>
          </Box>
        </Box>
      </Box>
      {/* //* isi */}

      {mapPage === 'grid' ? (
        <CreateGridEditHub />
      ) : mapPage === 'racks' ? (
        <SelectRacks />
      ) : (
        mapPage === 'preview' && <Preview />
      )}
    </Box>
  );
};
export default CreateMapMasterHubEdit;
