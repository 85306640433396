import { createSlice } from '@reduxjs/toolkit';
import { getSubsidiary } from '../../api/subsidiary';

export const subsidiaryState = createSlice({
  name: 'subsidiary',
  initialState: {
    isLoading: false,
    list: [],
  },
  reducers: {
    changeLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    loadSubsidiary: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const { changeLoading, loadSubsidiary } = subsidiaryState.actions;

export const getSubsidiaryList = (state) => state.subsidiary.list;

export const getSubsidiaryLoading = (state) => state.subsidiary.isLoading;

export const fetchSubsidiaryList = () => (dispatch) => {
  const fetchApi = async () => {
    dispatch(changeLoading(true));
    try {
      const {
        data: {
          data: { subsidiaries },
        },
      } = await getSubsidiary();
      dispatch(loadSubsidiary(subsidiaries));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeLoading(false));
    }
  };
  fetchApi();
};

export default subsidiaryState.reducer;
