import { createSlice } from '@reduxjs/toolkit';
import { getAllHubs, getAllMiniHub, getAllSubHub } from '../../api/hubs';

export const stockState = createSlice({
  name: 'stock',
  initialState: {
    subsidiaryId: [],

    // Hubs
    masterHub: [],
    masterHubId: [],
    subHub: [],
    subHubId: [],
    miniHub: [],
    miniHubId: [],
    hubs: [],

    hubChoose: null,
    hubSearch: '',
    hubSearchDebounce: '',
    subHubChoose: null,
    subHubSearch: '',
    subHubSearchDebounce: '',
    miniHubChoose: null,
    miniHubSearch: '',
    miniHubSearchDebounce: '',

    category: '',
    location: '',
    temporarySubsidiary: [],
    spesifikSubsidiary: [],
  },
  reducers: {
    changeSubsidiaryIdStock: (state, action) => {
      state.subsidiaryId = action.payload;
    },

    changeSpesifikSubsidiary: (state, action) => {
      state.spesifikSubsidiary = action.payload;
    },
    changeTemporarySubsidiary: (state, action) => {
      state.temporarySubsidiary = action.payload;
    },

    // ------- hubs -------
    changeMasterHub: (state, action) => {
      state.masterHub = action.payload;
    },
    changeMasterHubId: (state, action) => {
      state.masterHubId = action.payload;
    },
    changeSubHub: (state, action) => {
      state.subHub = action.payload;
    },
    changeSubHubId: (state, action) => {
      state.subHubId = action.payload;
    },
    changeMiniHub: (state, action) => {
      state.miniHub = action.payload;
    },
    changeMiniHubId: (state, action) => {
      state.miniHubId = action.payload;
    },

    changeHubs: (state, action) => {
      state.hubs = action.payload;
    },

    // hub select
    changeHubChoose: (state, action) => {
      state.hubChoose = action.payload;
    },
    changeHubSearch: (state, action) => {
      state.hubSearch = action.payload;
    },
    changeHubSearchDebounce: (state, action) => {
      state.hubSearchDebounce = action.payload;
    },
    changeSubHubChoose: (state, action) => {
      state.subHubChoose = action.payload;
    },
    changeSubHubSearch: (state, action) => {
      state.subHubSearch = action.payload;
    },
    changeSubHubSearchDebounce: (state, action) => {
      state.subHubSearchDebounce = action.payload;
    },
    changeMiniHubChoose: (state, action) => {
      state.miniHubChoose = action.payload;
    },
    changeMiniHubSearch: (state, action) => {
      state.miniHubSearch = action.payload;
    },
    changeMiniHubSearchDebounce: (state, action) => {
      state.miniHubSearchDebounce = action.payload;
    },

    changeIsHubOpen: (state, action) => {
      state.isHubOpen = action.payload;
    },

    changeLoadingStock: (state, action) => {
      state.isLoading = action.payload;
    },

    changeCategoryStock: (state, action) => {
      state.category = action.payload;
    },
    changeLocationStock: (state, action) => {
      state.location = action.payload;
    },
  },
});

export const {
  changeSubsidiaryIdStock,
  changeTemporarySubsidiary,
  changeSpesifikSubsidiary,
  // hubs
  changeMasterHub,
  changeMasterHubId,
  changeSubHub,
  changeSubHubId,
  changeMiniHub,
  changeMiniHubId,
  changeHubs,

  changeHubChoose,
  changeHubSearch,
  changeHubSearchDebounce,
  changeSubHubChoose,
  changeSubHubSearch,
  changeSubHubSearchDebounce,
  changeMiniHubChoose,
  changeMiniHubSearch,
  changeMiniHubSearchDebounce,

  changeLoading,
  changeCategoryStock,
  changeLocationStock,
} = stockState.actions;

export const getSubsidiaryIdStock = (state) => state.stock.subsidiaryId;
export const getTemporarySubsidiary = (state) =>
  state.stock.temporarySubsidiary;
export const getSpesifikSubsidiary = (state) => state.stock.spesifikSubsidiary;

export const getHubLoadingStock = (state) => state.stock.hubLoading;
export const getHubChooseStock = (state) => state.stock.hubChoose;
export const getHubSearchStock = (state) => state.stock.hubSearch;
export const getHubSearchDebounceStock = (state) =>
  state.stock.hubSearchDebounce;

export const getSubHubChooseStock = (state) => state.stock.subHubChoose;
export const getSubHubSearchStock = (state) => state.stock.subHubSearch;
export const getSubHubSearchDebounceStock = (state) =>
  state.stock.subHubSearchDebounce;

export const getMiniHubChooseStock = (state) => state.stock.miniHubChoose;
export const getMiniHubSearchStock = (state) => state.stock.miniHubSearch;
export const getMiniHubSearchDebounceStock = (state) =>
  state.stock.miniHubSearchDebounce;

export const getIsHubOpen = (state) => state.stock.isHubOpen;

export const getCategoryStock = (state) => state.stock.category;
export const getLocationStock = (state) => state.stock.location;

// hubs

export const getMasterHubIdStock = (state) => state.stock.masterHubId;
export const getMasterHubStock = (state) => state.stock.masterHub;
export const getSubHubIdStock = (state) => state.stock.subHubId;
export const getSubHubStock = (state) => state.stock.subHub;
export const getMiniHubIdStock = (state) => state.stock.miniHubId;
export const getMiniHubStock = (state) => state.stock.miniHub;
export const getAllFixHubsStock = (state) => state.stock.hubs;

//! ---------------- API ----------------

// hub
export const getDataMasterHubStock = (id, search) => (dispatch) => {
  const fetchMasterHub = async () => {
    let params = {};
    params.subsidiaryId = id;
    params.search = search;
    params.page = 1;
    params.pageSize = 5;

    try {
      const {
        data: {
          data: { hubs },
        },
      } = await getAllHubs(params);
      dispatch(changeMasterHub(hubs));
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  fetchMasterHub();
};

// sub hubb
export const getDataSubHubStock = (id) => (dispatch) => {
  const fetchSubHub = async () => {
    let params = {};
    params.idToko = id;
    params.page = 1;
    params.pageSize = 5;

    try {
      const {
        data: {
          data: { subHubs },
        },
      } = await getAllSubHub(params);
      dispatch(changeSubHub(subHubs));
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  fetchSubHub();
};

// mini hub
export const getDataMiniHubStock = (id) => (dispatch) => {
  const fetchMiniHub = async () => {
    let params = {};
    params.subHubId = id;
    params.page = 1;
    params.pageSize = 5;

    try {
      const {
        data: {
          data: { subHubs },
        },
      } = await getAllMiniHub(params);
      dispatch(changeMiniHub(subHubs));
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  fetchMiniHub();
};
export default stockState.reducer;
