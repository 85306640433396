import { createSlice } from '@reduxjs/toolkit';

export const accountInformation = createSlice({
  name: 'account',
  initialState: {
    value: 0,
    user: null,
  },
  reducers: {
    setState: (state, action) => {
      state.value = action.payload;
    },
    changeUserLogin: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setState, changeUserLogin } = accountInformation.actions;
export const selectAccount = (state) => state.counter.value;
export const getuserLogin = (state) => state.account.user;

export default accountInformation.reducer;
