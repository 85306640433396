import {
  Box,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CustomCard from "../../../../../../components/shared/CustomCard";
import BackButton from "../../../../../../components/shared/BackButton";
import { ProductPerfomance, SeeProduct } from "../DesktopComponent";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TableProductProduct from "./Desktop/TableProductProduct";
import { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as DollarIcon } from "../../../../../../assets/dollar.svg";
import { ReactComponent as BoxIcon } from "../../../../../../assets/box.svg";
import { ReactComponent as NoImage } from "../../../../../../assets/no-image.svg";
import { ReactComponent as SalesIcon } from "../../../../../../assets/sales.svg";

import InfiniteScroll from "react-infinite-scroll-component";
import CustomNotifSuccess from "../../../../../../components/shared/CustomNotifSuccess";
import ProductProductMobile from "./ProductProductMobile";
import {
  allCategoryProductName,
  allMiniSubCategoryProductName,
  allSubCategoryProductName,
  fetchProducts,
  fetchSubCategories,
} from "../../../../../../api/products";

const ProductProduct = (props) => {
  const {
    page,
    setPage,
    roadHirarki,
    setRoadHirarki,
    isProductProductAdd,
    setIsProductProductAdd,
    isProductProductEdit,
    setIsProductProductEdit,
    setIsProductProductDetails,
    setIsMiniSubProductDetails,
    setIsMiniSubProductAdd,
    isLoadingData,
    // *-date & time
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    // Table
    tableRowPerPageValues,
    tableRowPerPage,
    setTableRowPerPage,
    tableCurrentPage,
    setTableCurrentPage,
    tableTotalItems,
    setTableTotalItems,
    tableSearch,
    setTableSearch,
    sortTable,
    setSortTable,
    isSortAsc,
    setIsSortAsc,
    tableDatas,
    setTableDatas,
    // *--------
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
    // *--- filter ---
    filterDataAwait,
    setFilterDataAwait,
    // *---
    setIsLoadingData,
    setStatusError,
  } = props;

  const theme = useTheme();

  const getProducts = async () => {
    setIsLoadingData(true);

    let params = {};
    params.pageSize = tableRowPerPage;
    params.page = tableCurrentPage;

    params.idKategori = [];
    params.idSubkategori = [roadHirarki.subCategoryId];
    params.miniCategoryid = [];

    if (tableSearch !== "") {
      params["search"] = tableSearch;
    }
    if (sortTable !== 0) {
      params[sortTable] = isSortAsc ? "asc" : "desc";
    }

    try {
      const {
        data: { data: dataFetchProducts },
      } = await fetchProducts(params);

      setTableDatas(dataFetchProducts.products);
      setTableTotalItems(dataFetchProducts.pageInfo.total);
    } catch (err) {
      console.error();
      setStatusError(err.response.request.status);
      setTableDatas([]);
      setTableTotalItems(tableTotalItems);
      setIsLoadingData(true);
    } finally {
      setIsLoadingData(false);
    }
  };

  const getAllProducts = async () => {
    setIsLoadingData(true);

    let params = {};
    params.pageSize = tableRowPerPage;
    params.page = tableCurrentPage;

    // params.idKategori = filterDataAwait.categoryId;
    // params.idSubkategori = filterDataAwait.subCategoryId;
    // params.miniCategoryId = filterDataAwait.miniCategoryId;
    if (filterDataAwait.categoryId.length !== 0) {
      params["idKategori"] = filterDataAwait.categoryId;
    }
    if (filterDataAwait.subCategoryId.length !== 0) {
      params["idSubkategori"] = filterDataAwait.subCategoryId;
    }
    if (filterDataAwait.miniSubCategoryId.length !== 0) {
      params["miniCategoryId"] = filterDataAwait.miniSubCategoryId;
    }
    if (tableSearch !== "") {
      params["search"] = tableSearch;
    }
    if (sortTable !== 0) {
      params[sortTable] = isSortAsc ? "asc" : "desc";
    }

    try {
      const {
        data: { data: dataFetchProducts },
      } = await fetchProducts(params);

      setTableDatas(dataFetchProducts.products);
      setTableTotalItems(dataFetchProducts.pageInfo.total);
    } catch (err) {
      console.error();
      setStatusError(err.response.request.status);
      setTableDatas([]);
      setTableTotalItems(tableTotalItems);
      setIsLoadingData(true);
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    if (roadHirarki.subCategoryId) {
      getProducts();
    } else {
      getAllProducts();
    }
  }, [roadHirarki.subCategoryId]);

  // useEffect(() => {
  //   if (roadHirarki.subCategory === '') {
  //     setPage('subCategory');
  //   }
  //   setRoadHirarki({
  //     category: roadHirarki.category,
  //     categoryId: roadHirarki.categoryId,
  //     subCategory: roadHirarki.subCategory,
  //     subCategoryId: roadHirarki.subCategoryId,
  //   });
  // }, []);

  const [desktopMode, setDesktopMode] = useState(true);

  useEffect(() => {
    if (window.screen.width <= 830) {
      setDesktopMode(false);
    } else {
      setDesktopMode(true);
    }
  }, [window.screen.width]);
  // !------------- FILTER -----------------
  const allDataCategory = [{ namaKategori: "All" }];
  const allDataSubCategory = [{ subkategori: "All" }];
  const allDataMiniSubCategory = [{ name: "All" }];

  const [categoryFilterChoose, setCategoryFilterChoose] = useState([]);
  const [subCategoryFilterChoose, setSubCategoryFilterChoose] = useState([]);
  const [miniCategoryFilterChoose, setMiniCategoryFilterChoose] = useState([]);

  const [categoryFilterData, setCategoryFilterData] = useState([]);
  const [subCategoryFilterData, setSubCategoryFilterData] = useState([]);
  const [miniCategoryFilterData, setMiniCategoryFilterData] = useState([]);

  const [categoryFilterLoading, setCategoryFilterLoading] = useState(false);
  const [subCategoryFilterLoading, setSubCategoryFilterLoading] =
    useState(false);
  const [miniCategoryFilterLoading, setMiniCategoryFilterLoading] =
    useState(false);

  const [isOpenTableFilter, setIsOpenTableFilter] = useState(false);

  const [autoCompleteFilter, setAutoCompleteFilter] = useState({
    category: false,
    categoryOpen: false,
    subCategory: false,
    subCategoryOpen: false,
    miniCategory: false,
    miniCategoryOpen: false,
  });

  useEffect(() => {
    const getMiniSubCategoriesName = async () => {
      setMiniCategoryFilterLoading(true);
      let idSub = [];
      subCategoryFilterChoose.map((item) => {
        idSub.push(item.idSubkategori);
      });
      let datas = {};
      datas.idSubkategori = idSub;
      try {
        const {
          data: { data: dataNames },
        } = await allMiniSubCategoryProductName(datas);
        setMiniCategoryFilterData(dataNames);
      } catch (err) {
        console.error();
        setMiniCategoryFilterData([]);
      } finally {
        setMiniCategoryFilterLoading(false);
      }
    };

    const getSubCategoriesName = async () => {
      setSubCategoryFilterLoading(true);
      let idCat = [];
      categoryFilterChoose.map((item) => {
        idCat.push(item.idKategori);
      });
      let datas = {};
      datas.idKategori = idCat;

      try {
        const {
          data: { data: dataNames },
        } = await allSubCategoryProductName(datas);
        setSubCategoryFilterData(dataNames);
      } catch (err) {
        console.error();
        setSubCategoryFilterData([]);
      } finally {
        setSubCategoryFilterLoading(false);
      }
    };

    const getCategoriesName = async () => {
      setCategoryFilterLoading(true);
      let datas = {
        opsiKategori: 1,
      };
      try {
        const {
          data: { data: dataNames },
        } = await allCategoryProductName(datas);
        setCategoryFilterData(dataNames);
      } catch (err) {
        console.error();
        setCategoryFilterData([]);
      } finally {
        setCategoryFilterLoading(false);
      }
    };

    if (!autoCompleteFilter.categoryOpen && categoryFilterChoose.length !== 0) {
      getSubCategoriesName();
      if (
        !autoCompleteFilter.subCategoryOpen &&
        subCategoryFilterChoose.length !== 0
      ) {
        getMiniSubCategoriesName();
      }
    } else {
      getCategoriesName();
    }
  }, [
    autoCompleteFilter.categoryOpen,
    categoryFilterChoose,
    autoCompleteFilter.subCategoryOpen,
    subCategoryFilterChoose,
  ]);

  // !------------- Handler ------------
  const handleClickToCategory = () => {
    setPage("category");
    setRoadHirarki({
      subsidiary: "",
      subsidiaryId: 0,
      category: "",
      categoryId: 0,
      subCategory: "",
      subCategoryId: 0,
      product: "",
      productId: 0,
      miniSubCategory: "",
      miniSubCategoryId: 0,
    });
  };

  const handleClickToSubCategory = () => {
    setPage("subCategory");
    setRoadHirarki({
      ...roadHirarki,
      subCategory: "",
      subCategoryId: 0,
      product: "",
      productId: 0,
      miniSubCategory: "",
      miniSubCategoryId: 0,
    });
  };

  const handleClickRow = (name, id) => {
    setRoadHirarki({ ...roadHirarki, product: name, productId: id });
    setIsProductProductDetails(true);
  };

  const handleClickMini = (event, id, name) => {
    event.stopPropagation();
    setRoadHirarki({
      ...roadHirarki,
      miniSubCategory: name,
      miniSubCategoryId: id,
    });

    setIsMiniSubProductDetails(true);
  };

  const handleClickName = () => {};

  const handleBackButton = () => {
    setTableCurrentPage(1);
    setPage("subCategory");
  };
  const handleClickAdd = () => {
    setIsProductProductAdd(true);
  };
  const handleClickSaveFilter = () => {
    let categoryArr = [];
    let subCategoryArr = [];
    let miniCategoryArr = [];
    categoryFilterChoose.map((item) => {
      categoryArr.push(item.idKategori);
    });
    subCategoryFilterChoose.map((item) => {
      subCategoryArr.push(item.idSubkategori);
    });
    miniCategoryFilterChoose.map((item) => {
      miniCategoryArr.push(item.id);
    });
    setFilterDataAwait({
      categoryId: categoryArr,
      subCategoryId: subCategoryArr,
      miniSubCategoryId: miniCategoryArr,
    });
    setTableCurrentPage(1);
    setIsOpenTableFilter(false);
  };

  const handleCliskResetFilter = () => {
    setCategoryFilterChoose([]);
    setSubCategoryFilterChoose([]);
    setMiniCategoryFilterChoose([]);
    setFilterDataAwait({
      categoryId: [],
      subCategoryId: [],
      miniSubCategoryId: [],
    });
    setTableCurrentPage(1);
    setIsOpenTableFilter(false);
  };

  return (
    <>
      <CustomNotifSuccess
        open={notif === "deleteminisubcategory"}
        text={notifTitle}
        onClose={() => {
          setNotif("");
          setNotifTitle("");
        }}
      />
      <CustomNotifSuccess
        open={notif === "addminisubcategory"}
        text={notifTitle}
        onClose={() => {
          setNotif("");
          setNotifTitle("");
        }}
      />
      <CustomNotifSuccess
        open={notif === "deletesubcategory"}
        text={notifTitle}
        onClose={() => {
          setNotif("");
          setNotifTitle("");
        }}
      />
      <CustomNotifSuccess
        open={notif === "deleteproduct"}
        text={notifTitle}
        onClose={() => {
          setNotif("");
          setNotifTitle("");
        }}
      />
      <CustomNotifSuccess
        text={notifTitle}
        open={notif === "addproduct"}
        onClose={() => {
          setNotif("");
          setNotifTitle("");
        }}
      />
      {desktopMode ? (
        <Grid container columns={12} spacing={2}>
          {/* HIrarki */}
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <BackButton handleClick={handleBackButton} />
            {roadHirarki.category === "" || roadHirarki.subCategory === "" ? (
              <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
                Products
              </Typography>
            ) : (
              <>
                <Typography
                  sx={{
                    ml: "8px",
                    fontSize: "24px",
                    fontWeight: "600",
                    color: "#9E9D9D",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setRoadHirarki({
                      category: "",
                      subCategory: "",
                    });
                    setTableCurrentPage(1);
                    setPage("category");
                  }}
                >
                  Category
                </Typography>
                <ChevronRightIcon sx={{ color: "#9E9D9D" }} />
                <Typography
                  sx={{
                    ml: "8px",
                    fontSize: "24px",
                    fontWeight: "600",
                    color: "#9E9D9D",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setRoadHirarki({
                      ...roadHirarki,
                      subCategory: "",
                      subCategoryId: 0,
                    });
                    setTableCurrentPage(1);
                    setPage("subCategory");
                  }}
                >
                  {roadHirarki.category}
                </Typography>
                <ChevronRightIcon sx={{ color: "#9E9D9D" }} />
                <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
                  {roadHirarki.subCategory}
                </Typography>
              </>
            )}
          </Grid>
          {/*to Product All */}
          {/* <Grid item xs={2}>
        <SeeProduct {...{ page, setPage }} />
      </Grid> */}
          {/* PErfomance */}
          <Grid item xs={12}>
            <ProductPerfomance
              {...{
                timeFrame,
                setTimeFrame,
                dateRange,
                setDateRange,
                saveDateRange,
                setSaveDateRange,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TableProductProduct
              {...{
                page,
                setPage,
                roadHirarki,
                setRoadHirarki,
                isProductProductAdd,
                setIsProductProductAdd,
                isProductProductEdit,
                setIsProductProductEdit,
                setIsProductProductDetails,
                setIsMiniSubProductDetails,
                setIsMiniSubProductAdd,
                isLoadingData,
              }}
              {...{
                tableRowPerPageValues,
                tableRowPerPage,
                setTableRowPerPage,
                tableCurrentPage,
                setTableCurrentPage,
                tableTotalItems,
                setTableTotalItems,
                tableSearch,
                setTableSearch,
                sortTable,
                setSortTable,
                isSortAsc,
                setIsSortAsc,
                tableDatas,
                setTableDatas,
              }}
              {...{
                handleClickMini,
                handleClickRow,
                handleClickName,
              }}
              {...{
                allDataCategory,
                allDataSubCategory,
                allDataMiniSubCategory,

                categoryFilterData,
                setCategoryFilterData,
                subCategoryFilterData,
                setSubCategoryFilterData,
                miniCategoryFilterData,
                setMiniCategoryFilterData,

                categoryFilterChoose,
                setCategoryFilterChoose,
                subCategoryFilterChoose,
                setSubCategoryFilterChoose,
                miniCategoryFilterChoose,
                setMiniCategoryFilterChoose,

                categoryFilterLoading,
                setCategoryFilterLoading,
                subCategoryFilterLoading,
                setSubCategoryFilterLoading,
                miniCategoryFilterLoading,
                setMiniCategoryFilterLoading,

                filterDataAwait,
                setFilterDataAwait,
                autoCompleteFilter,
                setAutoCompleteFilter,

                handleClickSaveFilter,
                handleCliskResetFilter,

                isOpenTableFilter,
                setIsOpenTableFilter,
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <ProductProductMobile
          {...{
            handleBackButton,
            handleClickToCategory,
            handleClickToSubCategory,
            roadHirarki,
            tableSearch,
            setTableSearch,
            theme,
            handleClickAdd,
            tableTotalItems,
            tableDatas,
            handleClickRow,
            handleClickName,
          }}
          {...{
            allDataCategory,
            allDataSubCategory,
            allDataMiniSubCategory,

            categoryFilterData,
            setCategoryFilterData,
            subCategoryFilterData,
            setSubCategoryFilterData,
            miniCategoryFilterData,
            setMiniCategoryFilterData,

            categoryFilterChoose,
            setCategoryFilterChoose,
            subCategoryFilterChoose,
            setSubCategoryFilterChoose,
            miniCategoryFilterChoose,
            setMiniCategoryFilterChoose,

            categoryFilterLoading,
            setCategoryFilterLoading,
            subCategoryFilterLoading,
            setSubCategoryFilterLoading,
            miniCategoryFilterLoading,
            setMiniCategoryFilterLoading,

            filterDataAwait,
            setFilterDataAwait,
            autoCompleteFilter,
            setAutoCompleteFilter,

            handleClickSaveFilter,
            handleCliskResetFilter,

            isOpenTableFilter,
            setIsOpenTableFilter,
          }}
        />
      )}
    </>
  );
};
export default ProductProduct;
