import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircularProgress from '@mui/material/CircularProgress';
import BackButtonHub from '../../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePage,
  changePurchaseRoutingState,
  setHirarkiPurchase,
} from '../../../../../../../store/Routing/purchaseRouting';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import ChartPurchaseMobile from '../Component/Chart';
import TablePurchaseMobile from '../Component/Table';
import CustomCard from '../../../../../../../components/shared/CustomCard';
import debounce from 'lodash.debounce';
import {
  ExportIcon,
  SearchIcon,
  SkuIcon,
  UserIcon,
} from '../../../../../../../assets/Icons';
import AddIcon from '@mui/icons-material/Add';
import { FilterAlt as FilterAltIcon } from '@mui/icons-material';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import { useTheme } from '@emotion/react';
import {
  getAllDataPurchaseRequest,
  getEndDateFilterFilter,
  getIsLoadingPurchase,
  getListPurchaseRequest,
  getStartDateFilterFilter,
  getSubsidiaryDetailRequest,
} from '../../../../../../../store/purchase/purchaseAdmin/purchaseRequest';
import { changeIdPurchase } from '../../../../../../../store/purchase/purchaseAdmin/detailRequestPurchase';
import DialogFilterPurchaseRequest from '../../Request/DialogFilterPurchaseRequest';

const color = {
  lesstwo: '#51B15C',
  lesssix: '#FD7900',
  mostseven: '#F33A3A',
};

const bgColor = {
  lesstwo: 'rgba(81, 177, 92, 0.1)',
  lesssix: 'rgba(253, 121, 0, 0.1)',
  mostseven: 'rgba(243, 58, 58, 0.1)',
};

const PurchaseRequestMobile = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoadingPurchase);
  const role = localStorage.getItem('role');
  const [isFilter, setIsFilter] = useState(false);
  const subsidiary = useSelector(getSubsidiaryDetailRequest);
  const startDateFilter = useSelector(getStartDateFilterFilter);
  const endDateFilter = useSelector(getEndDateFilterFilter);

  // ! ------------------------ parameter for chart ---------------------

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  //! ----------------------- purchase request ------------------
  //! search
  const dataPurchase = useSelector(getAllDataPurchaseRequest);
  const [search, setSearch] = useState('');
  const [purchaseKeyword, setPurchaseKeyword] = useState('');

  const debounceOnChange = useRef(
    debounce((value) => {
      setPurchaseKeyword(value);
    }, 1000)
  ).current;

  //! fetch api request purchase
  useEffect(() => {
    let params = {};
    if (subsidiary !== '' && startDateFilter !== '') {
      params.page = 1;
      params.pageSize = 4;
      params.search = purchaseKeyword;
      params.subsidiary = subsidiary;
      params.startDate = startDateFilter;
      params.endDate = endDateFilter;
    } else if (subsidiary !== '') {
      params.page = 1;
      params.pageSize = 4;
      params.search = purchaseKeyword;
      params.subsidiary = subsidiary;
    } else if (startDateFilter !== '') {
      params.page = 1;
      params.pageSize = 4;
      params.search = purchaseKeyword;
      params.startDate = startDateFilter;
      params.endDate = endDateFilter;
    } else {
      params.page = 1;
      params.pageSize = 4;
      params.search = purchaseKeyword;
    }
    // params.subsidiary = subsidiary;
    dispatch(getListPurchaseRequest(params));
  }, [purchaseKeyword, startDateFilter, endDateFilter, subsidiary]);

  const handleBackButton = () => {
    dispatch(changePage(''));
  };

  const handleAddRequestMobile = () => {
    dispatch(changePage('requestAdd'));
  };

  const handleClickRow = (iDpurchase, subsidiaryName, hubName) => {
    if (Number(role) === 4) {
      dispatch(changePage('requestDetailFinance'));
      dispatch(
        changePurchaseRoutingState({ key: 'idRequest', value: iDpurchase })
      );
      dispatch(
        setHirarkiPurchase({ subsidiary: subsidiaryName, hub: hubName })
      );
    } else {
      dispatch(changePage('requestDetail'));
      dispatch(changeIdPurchase(iDpurchase));
      dispatch(
        setHirarkiPurchase({ subsidiary: subsidiaryName, hub: hubName })
      );
    }
  };

  //!  ----------------------------- Toolbabr ----------------------------
  const toolbar = (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row-reverse',
        gap: 2,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '22px',
          height: '22px',
          bgcolor: '#51B15C',
          color: '#ffffff',
          borderRadius: '4px',
          '&:hover': {
            bgcolor: '#51B15C',
            color: '#ffffff',
          },
        }}
        onClick={() => {
          handleAddRequestMobile();
        }}
      >
        <AddIcon sx={{ width: '14px', alignItems: 'center' }} />
      </CustomButton>

      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '22px',
          height: '22px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
        }}
        onClick={() => {
          // handleAddRequest();
        }}
      >
        <ExportIcon sx={{ width: '14px' }} />
      </CustomButton>

      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '22px',
          height: '22px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
        }}
        onClick={() => setIsFilter(!isFilter)}
      >
        <FilterAltIcon sx={{ width: '14px' }} />
      </CustomButton>
      <DialogFilterPurchaseRequest {...{ isFilter, setIsFilter }} />
    </Box>
  );
  return (
    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      {/* back button */}
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <BackButtonHub onClick={() => handleBackButton()} />
        <Typography
          sx={{
            color: '#9E9D9D',
            fontSize: '14px',
            fontWeight: 600,
            cursor: 'pointer',
          }}
        >
          Purchase
        </Typography>
        <ArrowForwardIosIcon
          sx={{ color: '#9E9D9D', fontSize: '14px', fontWeight: 600 }}
        />
        <Typography
          sx={{
            color: 'primary.black',
            fontSize: '14px',
            fontWeight: 600,
          }}
        >
          Request
        </Typography>
      </Box>

      {/* grafik  comparison */}
      <Grid container columns={12} spacing={2}>
        <Grid item xs={12}>
          <CustomCard sx={{ p: '10px 20px' }}>
            <ChartPurchaseMobile
              {...{
                dateRange,
                setDateRange,
                saveDateRange,
                setSaveDateRange,
              }}
            />
          </CustomCard>
        </Grid>

        {/* table */}
        <Grid item xs={12}>
          <TablePurchaseMobile />
        </Grid>

        {/* purchase request */}
        <Grid item xs={12}>
          <CustomCard sx={{ p: '10px 10px' }}>
            <Grid container columns={12} spacing={2}>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    display: 'inline-flex',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  Purchase Request
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  placeholder={'Search'}
                  size="small"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    debounceOnChange(e.target.value);
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      paddingLeft: '6px',
                      fontSize: '7px',
                    },
                    bgcolor: '#FAFAFA',
                    minHeight: '22px',
                    height: '22px',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ width: '12px' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                {toolbar}
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <UserIcon />
                  <Typography sx={{ fontSize: '8px', ml: '5px' }}>
                    User
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <SkuIcon />
                  <Typography sx={{ fontSize: '8px', ml: '5px' }}>
                    SKU
                  </Typography>
                </Box>
              </Grid>

              {/* datas */}
              {isLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <CircularProgress />
                </Grid>
              ) : dataPurchase.length === 0 ? (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      fontSize: '8px',
                      display: 'flex',
                      color: '#F33A3A',
                      justifyContent: 'center',
                    }}
                  >
                    No Request Purchase
                  </Box>
                </Grid>
              ) : (
                <>
                  {dataPurchase.map((item, idx) => (
                    <Grid item xs={6} key={idx}>
                      <Box
                        sx={{
                          background: '#FAFAFA',
                          borderRadius: '8px',
                          p: '10px',
                        }}
                        onClick={() => {
                          handleClickRow(
                            item?.iDpurchase,
                            item?.subsidiary,
                            item?.location
                          );
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{ fontSize: '12px', fontWeight: 600 }}
                            >
                              {item?.request}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: '52px',
                              height: '17px',
                              background:
                                item?.dateDays <= 2
                                  ? bgColor.lesstwo
                                  : item?.dateDays <= 6
                                  ? bgColor.lesssix
                                  : bgColor.mostseven,
                              borderRadius: '5px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontWeight: 600,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '8px',
                                color:
                                  item?.dateDays <= 2
                                    ? color.lesstwo
                                    : item?.dateDays <= 6
                                    ? color.lesssix
                                    : color.mostseven,
                              }}
                            >
                              {item?.dateDays === 1 ? (
                                'Yesterday'
                              ) : item?.dateDays === 0 ? (
                                'Today'
                              ) : (
                                <> {item?.dateDays} days ago</>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ mt: '3px' }}>
                          <Typography sx={{ fontSize: '8px' }}>
                            {item?.subsidiary}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <UserIcon />
                            <Typography sx={{ fontSize: '8px', ml: '5px' }}>
                              {item?.user}
                            </Typography>
                          </Box>

                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <SkuIcon />
                            <Typography sx={{ fontSize: '8px', ml: '5px' }}>
                              {item?.sku}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
          </CustomCard>
        </Grid>
      </Grid>
    </Box>
  );
};
export default PurchaseRequestMobile;
