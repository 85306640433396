import { useDispatch, useSelector } from 'react-redux';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';
import {
  getAllPurchaseRouting,
  setCloseNotif,
} from '../../../../../../store/Routing/purchaseRouting';

const NotifPruchase = () => {
  const dispatch = useDispatch();
  const { keyNotif, textNotif } = useSelector(getAllPurchaseRouting);

  const keys = [
    'addRequest',
    'editRequest',
    'addPurchasePending',
    'editFinancePurchase',
    'purchaseProcess',
    'rejectPurchaseBySupplier',
  ];

  return (
    <>
      {keys.map((key, index) => (
        <CustomNotifSuccess
          text={textNotif}
          open={keyNotif === key}
          onClose={() => {
            dispatch(setCloseNotif());
          }}
          key={index}
        />
      ))}
    </>
  ); 
};
export default NotifPruchase;
