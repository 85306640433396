import { createSlice } from '@reduxjs/toolkit';
import { getAllSubHub } from '../../../api/hubs';

export const subHubState = createSlice({
  name: 'subHub',
  initialState: {
    // data master hub
    masterHubName: '',
    idMasterHub: '',

    // data sub hub
    subHubData: [],
    isLoadingSubHub: false,
  },
  reducers: {
    changeMasterHubName: (state, action) => {
      state.masterHubName = action.payload;
    },
    changeIdMasterHub: (state, action) => {
      state.idMasterHub = action.payload;
    },
    loadDataSubHub: (state, action) => {
      state.subHubData = action.payload;
    },
    changeIsLoadingSubHub: (state, action) => {
      state.isLoadingSubHub = action.payload;
    },
  },
});

export const {
  changeMasterHubName,
  changeIdMasterHub,
  loadDataSubHub,
  changeIsLoadingSubHub,
} = subHubState.actions;

export const getAllDataHubToSubHub = (state) => state.subHub;
export const getIdToko = (state) => state.subHub.idMasterHub;

export const getAllDataSubHub = (params) => (dispatch) => {
  const fetchSubHub = async () => {
    dispatch(changeIsLoadingSubHub(true));
    try {
      const {
        data: { data },
      } = await getAllSubHub(params);
      dispatch(loadDataSubHub(data?.subHubs));
      dispatch(changeIsLoadingSubHub(false));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(changeIsLoadingSubHub(false));
    }
  };

  fetchSubHub();
};

export default subHubState.reducer;
