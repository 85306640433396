import React, { useEffect, useRef, useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import BackButton from "../../../../../../../../components/shared/BackButton";
import { KeyboardArrowRight as KeyboardArrowRightIcon } from "@mui/icons-material";
import { getDetailHubs } from "../../../../../../../../api/hubs";
import MasterHubRacksChart from "./MasterHubRacksChart";
import debounce from "lodash.debounce";
import MasterHubRacksTable from "./MasterHubRacksTable";
import { useSelector } from "react-redux";
import {
  getIdMasterHub,
  getIdSubsidiaryHub,
  getMasterHub,
  getSubsidiaryHub,
} from "../../../../../../../../store/hub/hirarkiHub";
import GrafikComparisonHub from "../../../Component/GrafikComparison";
import { getAllMasterHubState } from "../../../../../../../../store/racks/masterHubRack/masterHubRack";
import DetailMasterHubRack from "./Detail/DetailMasterHubRack";

const MasterHubRacks = ({
  setMasterHubIdRack,
  setRowSelected,
  masterHubRackSelect,
  setMasterHubRackSelect,
  saveDateRangeMasterHubRack,
  setSaveDateRangeMasterHubRack,
  dateRangeMasterHubRack,
  setDateRangeMasterHubRack,
  masterHubRack,
  setMasterHubRack,
  rowPerPageMasterHubRack,
  setRowPerPageMasterHubRack,
  totalTableItemsMasterHubRack,
  setTotalTableItemsMasterHubRack,
  currentPageTableMasterHubRack,
  setCurrentPageTableMasterHubRack,
  isLoadingMasterHubRack,
  setIsLoadingMasterHubRack,
  masterHubRackKeyword,
  setMasterHubRackKeyword,
  sortTableMasterHubRack,
  setSortTableMasterHubRack,
  isSortAscMasterHubRack,
  setIsSortAscMasterHubRack,
  rowPerPageValues,
  setMasterHubIdRackDetail,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);
  const hubsId = useRef(searchParams.get("masterhub-idrack") || null);

  const { id: idRack } = useSelector(getAllMasterHubState);

  const subsidiary = useSelector(getSubsidiaryHub);
  const subsidiaryId = useSelector(getIdSubsidiaryHub);

  const masterHub = useSelector(getMasterHub);
  const masterHubId = useSelector(getIdMasterHub);

  const [masterHubRackMiniTableSearch, setMasterHubRackMiniTableSearch] =
    useState("");

  const [
    saveMasterHubRackMiniTableSearch,
    setSaveMasterHubRackMiniTableSearch,
  ] = useState("");

  const debounceMasterHubRackMiniTable = useRef(
    debounce((value) => {
      setSaveMasterHubRackMiniTableSearch(value);
    }, 1000)
  ).current;

  const [detailMasterHub, setDetailMasterHub] = useState(null);

  // useEffect(() => {
  //   const fetchDetailMasterHub = async () => {
  //     try {
  //       const {
  //         data: { data },
  //       } = await getDetailHubs(hubsId.current);
  //       setDetailMasterHub(data);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };
  //   fetchDetailMasterHub();
  // }, []);

  return (
    <Box sx={{ mt: 2 }}>
      {idRack === 0 ? (
        <Box>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <BackButton
              handleClick={() => {
                setMasterHubIdRack(null);
                setRowSelected(hubsId.current);
                updatedSearchParams.set("detail", hubsId.current);
                updatedSearchParams.delete("masterhub-idrack");
                setSearchParams(updatedSearchParams);
              }}
            />
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography
                sx={{
                  color: "primary.black",
                  fontSize: "1.125rem",
                  fontWeight: 600,
                }}
              >
                Racks
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "secondary.light",
                    fontSize: "0.875rem",
                    fontWeight: 600,
                  }}
                >
                  {subsidiary || "-"}
                </Typography>
                <KeyboardArrowRightIcon
                  sx={{
                    fontSize: "1rem",
                    color: "secondary.light",
                    fontWeight: 600,
                  }}
                />
                <Typography
                  sx={{
                    color: "secondary.light",
                    fontSize: "0.875rem",
                    fontWeight: 600,
                  }}
                >
                  {masterHub || "-"}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 2 }}>
            {/* <MasterHubRacksChart
            masterHubRackSelect={masterHubRackSelect}
            setMasterHubRackSelect={setMasterHubRackSelect}
            saveDateRange={saveDateRangeMasterHubRack}
            setSaveDateRange={setSaveDateRangeMasterHubRack}
            dateRange={dateRangeMasterHubRack}
            setDateRange={setDateRangeMasterHubRack}
            miniTableSearch={masterHubRackMiniTableSearch}
            setMiniTableSearch={setMasterHubRackMiniTableSearch}
            debounceMiniTableSearch={debounceMasterHubRackMiniTable}
          /> */}
            {/* --data dummy reusable from masterComparison-- */}
            <GrafikComparisonHub />
          </Box>

          <Box sx={{ mt: 2 }}>
            <MasterHubRacksTable
              masterHubRack={masterHubRack}
              setMasterHubRack={setMasterHubRack}
              rowPerPageMasterHubRack={rowPerPageMasterHubRack}
              setRowPerPageMasterHubRack={setRowPerPageMasterHubRack}
              totalTableItemsMasterHubRack={totalTableItemsMasterHubRack}
              setTotalTableItemsMasterHubRack={setTotalTableItemsMasterHubRack}
              currentPageTableMasterHubRack={currentPageTableMasterHubRack}
              setCurrentPageTableMasterHubRack={
                setCurrentPageTableMasterHubRack
              }
              isLoadingMasterHubRack={isLoadingMasterHubRack}
              setIsLoadingMasterHubRack={setIsLoadingMasterHubRack}
              masterHubRackKeyword={masterHubRackKeyword}
              setMasterHubRackKeyword={setMasterHubRackKeyword}
              sortTableMasterHubRack={sortTableMasterHubRack}
              setSortTableMasterHubRack={setSortTableMasterHubRack}
              isSortAscMasterHubRack={isSortAscMasterHubRack}
              setIsSortAscMasterHubRack={setIsSortAscMasterHubRack}
              rowPerPageValues={rowPerPageValues}
              setMasterHubIdRack={setMasterHubIdRack}
              setMasterHubIdRackDetail={setMasterHubIdRackDetail}
            />
          </Box>
        </Box>
      ) : (
        <DetailMasterHubRack />
      )}
    </Box>
  );
};

export default MasterHubRacks;
