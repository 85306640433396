import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../../../../../../components/shared/CustomButton";
import {
  changeCreateMapPage,
  changeIsCreateMap,
  getMasterHubEditAll,
} from "../../../../../../../../../store/hub/masterHub/masterHubEdit";

const CustomTableCells = styled(TableCell)({
  color: "#000000",
  fontWeight: 500,
  fontSize: "12px",
  textTransform: "capitalize",
});

const Preview = () => {
  const dispatch = useDispatch();
  const [cellSelected, setCellSelected] = useState([]);
  const [cellSelectedAll, setCellSelectedAll] = useState([]);
  const {
    cell,
    cellSelected: cellSet,
    cellSelectedDetails,
    cellSelectedNew,
    cellSelectedDetailsNew,
    entrance,
  } = useSelector(getMasterHubEditAll);

  useEffect(() => {
    let dataBefore = cellSet.flat();
    let dataAfter = cellSelectedNew.flat();
    let all = [...dataBefore, ...dataAfter];
    setCellSelectedAll(all.filter((value) => !cellSelected.includes(value)));
  }, [cellSet, cellSelected, cellSelectedNew]);

  const handleEditRack = () => {
    dispatch(changeCreateMapPage("racks"));
  };
  const handleFinishRack = () => {
    dispatch(changeIsCreateMap(false));
  };
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* //!------------ Grid ------------------ */}
        <Box>
          {cell.map((row, rowIndex) => (
            <Box sx={{ display: "flex" }} key={rowIndex}>
              {row.map((col, colIndex) => (
                <Box
                  // onClick={() => dispatch(seeDetailRack())}
                  onMouseOver={() => {
                    if (cellSet.flat().includes(col)) {
                      let getPos = cellSelectedDetails.find((value) =>
                        value.columnPosition.split(",").includes(col)
                      );
                      setCellSelected(getPos.columnPosition.split(","));
                    } else if (cellSelectedNew.flat().includes(col)) {
                      let getPos = cellSelectedDetailsNew.find((value) =>
                        value.columnPosition.split(",").includes(col)
                      );
                      setCellSelected(getPos.columnPosition.split(","));
                    }
                  }}
                  onMouseOut={() => setCellSelected([])}
                  sx={[
                    {
                      width: "36px",
                      height: "36px",
                    },
                    cellSelectedAll.includes(col) && {
                      bgcolor: "#E4E4E4",
                    },
                    entrance.includes(col) && {
                      bgcolor: "#171717",
                    },
                    cellSelected.includes(col) && {
                      bgcolor: "#51B15C",
                    },
                  ]}
                  key={colIndex}
                ></Box>
              ))}
            </Box>
          ))}
        </Box>
        {/* //!------------ Table ------------------ */}
        <Box>
          <Table>
            <TableHead sx={{ bgcolor: "#FAFAFA" }}>
              <TableRow>
                <CustomTableCells>Code</CustomTableCells>
                <CustomTableCells sx={{ color: "#4339F2" }}>
                  Column
                </CustomTableCells>
                <CustomTableCells sx={{ color: "#EACD57" }}>
                  Level
                </CustomTableCells>
              </TableRow>
            </TableHead>
            <TableBody>
              {cellSelectedDetailsNew.map((data, index) => (
                <TableRow
                  key={index}
                  // onClick={() => dispatch(seeDetailRack())}
                  onMouseOver={() => {
                    setCellSelected(data.columnPosition.split(","));
                  }}
                  onMouseOut={() => setCellSelected([])}
                  sx={[
                    data.columnPosition === cellSelected.join(",") && {
                      bgcolor: "#51B15C",
                    },
                  ]}
                >
                  <CustomTableCells
                    sx={[
                      data.columnPosition === cellSelected.join(",") && {
                        color: "white",
                      },
                    ]}
                  >
                    {data.name}
                  </CustomTableCells>
                  <CustomTableCells
                    align="right"
                    sx={[
                      data.columnPosition === cellSelected.join(",") && {
                        color: "white",
                      },
                    ]}
                  >
                    {data.column}
                  </CustomTableCells>
                  <CustomTableCells
                    align="right"
                    sx={[
                      data.columnPosition === cellSelected.join(",") && {
                        color: "white",
                      },
                    ]}
                  >
                    {data.row}
                  </CustomTableCells>
                </TableRow>
              ))}
              {cellSelectedDetails.map((data, index) => (
                <TableRow
                  key={index}
                  // onClick={() => dispatch(seeDetailRack())}
                  onMouseOver={() => {
                    setCellSelected(data.columnPosition.split(","));
                  }}
                  onMouseOut={() => setCellSelected([])}
                  sx={[
                    data.columnPosition === cellSelected.join(",") && {
                      bgcolor: "#51B15C",
                    },
                  ]}
                >
                  <CustomTableCells
                    sx={[
                      data.columnPosition === cellSelected.join(",") && {
                        color: "white",
                      },
                    ]}
                  >
                    {data.name}
                  </CustomTableCells>
                  <CustomTableCells
                    align="right"
                    sx={[
                      data.columnPosition === cellSelected.join(",") && {
                        color: "white",
                      },
                    ]}
                  >
                    {data.column}
                  </CustomTableCells>
                  <CustomTableCells
                    align="right"
                    sx={[
                      data.columnPosition === cellSelected.join(",") && {
                        color: "white",
                      },
                    ]}
                  >
                    {data.row}
                  </CustomTableCells>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          position: "fixed",
          bottom: "0px",
          right: "0px",
          display: "flex",
          justifyContent: "flex-end",
          padding: "18px 32px 18px 240px",
          alignItems: "center",
          gap: 1,
        }}
      >
        <CustomButton
          variant="outlined"
          sx={{ width: "190px", height: "48px" }}
          onClick={handleEditRack}
        >
          Back
        </CustomButton>
        <CustomButton
          variant="contained"
          sx={{ width: "190px", height: "48px" }}
          onClick={handleFinishRack}
        >
          Finish
        </CustomButton>
      </Box>
    </Box>
  );
};
export default Preview;
