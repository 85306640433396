import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import BackButtonHub from '../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePageSupplier,
  setNotifSupplier,
} from '../../../../../../store/Routing/supplierRouting';
import CustomInput from '../../../../../../components/shared/CustomInput';
import {
  changeEmailSupplier,
  changeJoinDate,
  changePhoneNumberSupplier,
  changePublish,
  changeSupplierCode,
  changeSupplierName,
  getAllDataAddSupplier,
} from '../../../../../../store/supplier/addSupplier';
import { useEffect, useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useRef } from 'react';
import debounce from 'lodash.debounce';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CustomCard from '../../../../../../components/shared/CustomCard';
import { Map } from 'pigeon-maps';
import {
  CloseIcon,
  KeyboardArrowDownIcon,
} from '../../../../../../assets/Icons';
import SetupAddressAddSupplier from './SetupAddress';
import CustomButton from '../../../../../../components/shared/CustomButton';
import CustomAntSwitch from '../../../../../../components/shared/CustomAntSwitch';
import dayjs from 'dayjs';
import { createNewSupplier } from '../../../../../../api/Supplier';

const AddSupplier = () => {
  const dispatch = useDispatch();
  const {
    supplierName,
    supplierCode,
    email,
    phoneNumber,
    joinDate,
    address,
    addressFix,
    coordinate,
    mapInputType,
    placeGetByName,
    placeGetByCoordinate,
    publish,
  } = useSelector(getAllDataAddSupplier);

  const [availableCode, setAvailableCode] = useState(false);
  const [availableEmail, setAvailableEmail] = useState(false);
  const [availablePhone, setAvailablePhone] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [isMapOpen, setIsMapOpen] = useState(false);
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  // ! validation
  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    dispatch(changeEmailSupplier(inputEmail));

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setEmailError(!emailPattern.test(inputEmail));
  };

  useEffect(() => {
    if (
      supplierName !== '' &&
      supplierCode !== '' &&
      email !== '' &&
      phoneNumber !== ''
    ) {
      setIsSubmitAvailable(true);
    } else {
      setIsSubmitAvailable(false);
    }
  }, [supplierName, supplierCode, email, phoneNumber]);

  const handleSubmit = async () => {
    const params = {};
    params.nama_suppliers = supplierName;
    params.supplier_kode = supplierCode; //TODO : diganti dari id ke code
    params.email = email;
    params.telpon = phoneNumber;
    params.tgl_gabung = dayjs(joinDate).format('YYYY-MM-DD');
    params.status = publish ? 'active' : 'inactive';
    params.lat = coordinate[0] || '';
    params.lon = coordinate[1] || '';
    params.type_input = mapInputType === 'manual' ? 'manual' : 'map';
    params.address = address || '';

    if (mapInputType === 'manual') {
      const { formatted: citydistric } = placeGetByName || '';
      params['city_district'] = citydistric ? citydistric : '';
    } else {
      if (!placeGetByCoordinate?.statusCode) {
        const { formatted: citydistric } = placeGetByCoordinate || '';
        params['city_district'] = citydistric ? citydistric : '';
      }
    }

    setLoadingSubmit(true);

    try {
      const data = await createNewSupplier(params);
      setLoadingSubmit(false);
      dispatch(
        setNotifSupplier({
          key: 'addSupplier',
          text: 'supplier successful added',
        })
      );
      dispatch(changePageSupplier(''));
    } catch (err) {
      console.error(err);
      const errors = err.response.data.meta.message;
      errors?.email ? setAvailableEmail(true) : setAvailableEmail(false);
      errors?.supplier_kode ? setAvailableCode(true) : setAvailableCode(false);
      errors?.telpon ? setAvailablePhone(true) : setAvailablePhone(false);
    } finally {
      setLoadingSubmit(false);
    }
  };

  return (
    <Box>
      <Box sx={{ minHeight: '80vh' }}>
        <Grid container columns={12} spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 3 }}>
              <BackButtonHub onClick={() => dispatch(changePageSupplier(''))} />
              <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
                Add Supplier
              </Typography>
            </Box>
          </Grid>

          {/* suppllier name */}
          <Grid item xs={6} sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                Supplier Name
              </Typography>
              <Typography
                sx={{ fontSize: '16px', fontWeight: 500, color: '#F33A3A' }}
              >
                *
              </Typography>
            </Box>
            <CustomInput
              fullWidth
              sx={{
                with: '100%',
                bgcolor: '#fafafa',
                '& .MuiOutlinedInput-input': {
                  padding: '17px 16px',
                  borderRadius: '10px',
                  '&::placeholder': {
                    color: '#111',
                    fontSize: '16px',
                  },
                },
                borderRadius: '5px',
              }}
              placeholder="enter description"
              value={supplierName}
              onChange={(e) => dispatch(changeSupplierName(e.target.value))}
            />
          </Grid>

          {/* supplier code */}
          <Grid item xs={6} sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                Supplier Code
              </Typography>
              <Typography
                sx={{ fontSize: '16px', fontWeight: 500, color: '#F33A3A' }}
              >
                *
              </Typography>
            </Box>
            <CustomInput
              fullWidth
              sx={{
                with: '100%',
                bgcolor: '#fafafa',
                '& .MuiOutlinedInput-input': {
                  padding: '17px 16px',
                  borderRadius: '10px',
                  '&::placeholder': {
                    color: '#111',
                    fontSize: '16px',
                  },
                },
                borderRadius: '5px',
              }}
              placeholder="enter supplier id"
              value={supplierCode}
              onChange={(e) => dispatch(changeSupplierCode(e.target.value))}
            />
            {availableCode && (
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: 400,
                  mt: 1,
                  color: '#F33A3A',
                }}
              >
                Supplier code has been taken
              </Typography>
            )}
          </Grid>

          {/* email */}
          <Grid item xs={6} sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                Email
              </Typography>
              <Typography
                sx={{ fontSize: '16px', fontWeight: 500, color: '#F33A3A' }}
              >
                *
              </Typography>
            </Box>
            <CustomInput
              type="email"
              fullWidth
              sx={{
                with: '100%',
                bgcolor: '#fafafa',
                '& .MuiOutlinedInput-input': {
                  padding: '17px 16px',
                  borderRadius: '10px',
                  '&::placeholder': {
                    color: '#111',
                    fontSize: '16px',
                  },
                },
                borderRadius: '5px',
              }}
              placeholder="enter description"
              value={email}
              onChange={(e) => {
                handleEmailChange(e);
                dispatch(changeEmailSupplier(e.target.value));
              }}
              error={emailError}
            />
            {emailError && (
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: 400,
                  color: '#F33A3A',
                  mt: 1,
                }}
              >
                Email not valid
              </Typography>
            )}
            {availableEmail && (
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: 400,
                  mt: 1,
                  color: '#F33A3A',
                }}
              >
                email has been taken
              </Typography>
            )}
          </Grid>

          {/* phone number */}
          <Grid item xs={6} sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                Phone Number
              </Typography>
              <Typography
                sx={{ fontSize: '16px', fontWeight: 500, color: '#F33A3A' }}
              >
                *
              </Typography>
            </Box>
            <CustomInput
              type="number"
              fullWidth
              sx={{
                with: '100%',
                bgcolor: '#fafafa',
                '& .MuiOutlinedInput-input': {
                  padding: '17px 16px',
                  borderRadius: '10px',
                  '&::placeholder': {
                    color: '#111',
                    fontSize: '16px',
                  },
                },
                borderRadius: '5px',
              }}
              placeholder="enter description"
              value={phoneNumber}
              onChange={(e) =>
                dispatch(changePhoneNumberSupplier(e.target.value))
              }
            />
            {availablePhone && (
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: 400,
                  mt: 1,
                  color: '#F33A3A',
                }}
              >
                Phone number has been taken
              </Typography>
            )}
          </Grid>

          {/* join date */}
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                Join Date
              </Typography>
              <Typography
                sx={{ fontSize: '16px', fontWeight: 500, color: '#F33A3A' }}
              >
                *
              </Typography>
            </Box>
            <DesktopDatePicker
              inputFormat="DD/MM/YYYY"
              value={joinDate}
              onChange={(newValue) => {
                dispatch(changeJoinDate(newValue));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: '100%', bgcolor: '#FAFAFA' }}
                />
              )}
            />
          </Grid>

          {/* address */}
          <Grid item xs={6}>
            <CustomCard
              sx={{ p: '14px 8px', cursor: 'pointer' }}
              onClick={() => setIsMapOpen(!isMapOpen)}
            >
              <Box
                sx={{
                  display: 'center',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box
                    sx={{
                      width: '54px',
                      height: '54px',
                      bgcolor: 'gray',
                      borderRadius: '8px',
                      position: 'relative',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        bgcolor: 'white',
                        width: '100%',
                        height: '100%',
                        zIndex: '+2',
                        opacity: 0.5,
                      }}
                    ></Box>
                    <Box
                      sx={{
                        maxHeight: '54px',
                        overflow: 'hidden',
                        borderRadius: '8px',
                      }}
                    >
                      <Map
                        defaultCenter={[3.546958571415798, 98.6901696840363]}
                        height={130}
                        defaultZoom={17}
                        width={54}
                        style={{
                          borderRadius: '8px',
                        }}
                      ></Map>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                      Address
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.4,
                      }}
                    >
                      <LocationOnIcon
                        color="primary"
                        sx={{ fontSize: '16px' }}
                      />
                      <Typography sx={{ fontSize: '16px', color: '#9E9D9D' }}>
                        {addressFix ? addressFix : 'Click to enter address'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <KeyboardArrowDownIcon />
              </Box>
            </CustomCard>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '32px 0px 0px',
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={publish}
              onChange={() => dispatch(changePublish(!publish))}
              color="primary"
            />
          }
          label="Publish"
          labelPlacement="start"
        />
        <CustomButton
          variant="contained"
          disabled={!isSubmitAvailable}
          onClick={handleSubmit}
          sx={{ width: '140px', height: '36px' }}
        >
          {loadingSubmit ? (
            <CircularProgress size={18} sx={{ color: '#FFF' }} />
          ) : (
            <Typography
              sx={{ color: 'white', fontSize: '16px', fontWeight: '500' }}
            >
              Submit
            </Typography>
          )}
        </CustomButton>
      </Box>

      {/* dialog address */}
      <Dialog
        open={isMapOpen}
        onClose={() => {
          setIsMapOpen(!isMapOpen);
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            minWidth: '200px',
          }}
        >
          <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
            Input Address
          </Typography>
          <CloseIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => setIsMapOpen(false)}
          />
        </DialogTitle>
        <DialogContent dividers>
          <SetupAddressAddSupplier setIsOpen={setIsMapOpen} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default AddSupplier;
