import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { height } from '@mui/system';
import { Line } from 'react-chartjs-2';
import CustomCard from '../../../../../../components/shared/CustomCard';
import CustomDateRange from '../../../../../../components/shared/CustomDateRange';

const dataDummy = {
  purchase: [12, 42, 45, 12, 67, 34, 23, 34, 23, 30],
  sold: [92, 32, 45, 23, 34, 32, 76, 56, 87, 35],
  used: [34, 34, 54, 45, 65, 43, 65, 45, 34, 89],
};
const ChartDetails = (props) => {
  const {
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
  } = props;

  const labeDataDummy = dataDummy.purchase.map((_, index) => {
    return `${index}th`;
  });
  return (
    <CustomCard sx={{ p: '12px 20px 10px', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
          Perfomance
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center ' }}>
          {/* Select */}
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={timeFrame}
              onChange={(e) => setTimeFrame(e.target.value)}
              sx={{
                p: 0,
                '.MuiOutlinedInput-input': {
                  paddingTop: '7.5px',
                  paddingBottom: '7.5px',
                  paddingLeft: '10px',
                  paddingRight: '0',
                  borderRadius: '5px',
                },
                fontSize: '12px',
                mr: '12px',
              }}
            >
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
            </Select>
          </FormControl>
          <CustomDateRange
            dateRange={dateRange}
            setDateRange={setDateRange}
            saveDateRange={saveDateRange}
            setSaveDateRange={setSaveDateRange}
            sx={{ maxHeight: '34px', pl: '10px', fontSize: '12px' }}
          />
        </Box>
      </Box>
      <Box sx={{ maxHeight: '250px', minHeight: '200px' }}>
        <Line
          datasetIdKey="id"
          data={{
            labels: labeDataDummy,
            datasets: [
              {
                label: 'Purchase',
                data: dataDummy.purchase,
                fill: false,
                borderColor: '#51B15C',
                backgroundColor: '#51B15C',
                tension: 0.1,
              },
              {
                label: 'Sold',
                data: dataDummy.sold,
                fill: false,
                borderColor: '#FEDD25',
                backgroundColor: '#FEDD25',
                tension: 0.1,
              },
              {
                label: 'Used',
                data: dataDummy.used,
                fill: false,
                borderColor: '#F239A8',
                backgroundColor: '#F239A8',
                tension: 0.1,
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            layout: {
              padding: {},
            },
            plugins: {
              legend: {
                labels: {
                  color: '#B8B8B8',
                  font: {
                    size: 12,
                    weight: 600,
                    family: 'Poppins',
                  },
                  usePointStyle: true,
                  pointStyle: 'rect',
                },
                position: 'bottom',
                align: 'end',
              },
            },
            interaction: {
              intersect: false,
            },
            stacked: false,
            elements: {
              point: {
                radius: 0,
              },
              line: {
                borderWidth: 1.5,
                tension: 0.4,
              },
            },
          }}
        />
      </Box>
    </CustomCard>
  );
};
export default ChartDetails;
