import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import React from "react";
import { CloseIcon } from "../../../../../../../assets/Icons";
import CustomInput from "../../../../../../../components/shared/CustomInput";
import { useState } from "react";
import { useEffect } from "react";
import CustomButton from "../../../../../../../components/shared/CustomButton";

const PriceProdukUnit = ({
  openPrice,
  setOpenPrice,
  isLoadingUnit,
  handleValue,
  unit,
}) => {
  const [detailsUnit, setDetailsUnit] = useState([]);

  useEffect(() => {
    setDetailsUnit(unit);
  }, [unit]);

  const handlePriceChange = (index, newValue) => {
    const updatedData = detailsUnit.map((item, idx) =>
      idx === index ? { ...item, price: newValue } : item
    );
    setDetailsUnit(updatedData);
  };

  const handleEnter = () => {
    handleValue(detailsUnit);
    setOpenPrice(!openPrice);
  };

  return (
    <Dialog open={openPrice} onClose={() => setOpenPrice(!openPrice)}>
      <DialogTitle
        sx={{
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
          padding: "20px 16px",
          borderRadius: "20px, 20px, 0px, 0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
            Price per unit
          </Typography>
          <CloseIcon
            onClick={() => setOpenPrice(!openPrice)}
            sx={{ cursor: "pointer" }}
          />
        </Box>
      </DialogTitle>

      <DialogContent>
        <Grid container columns={12} spacing={2}>
          {isLoadingUnit ? (
            <Grid item xs={12}>
              <Box sx={{ width: "100%", textAlign: "center", mt: 3 }}>
                <CircularProgress />
              </Box>
            </Grid>
          ) : detailsUnit?.length === 0 ? (
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 3,
                  minHeight: "76px",
                }}
              >
                <Typography sx={{ color: "#9E9D9D", fontSize: "12px" }}>
                  No data found
                </Typography>
              </Box>
            </Grid>
          ) : (
            detailsUnit?.map((item, idx) => (
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 2 }}>
                  Price
                </Typography>
                <CustomInput
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ marginLeft: "12px" }}
                      >
                        Rp
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        <Box
                          sx={{
                            padding: "12px 8px",
                            background: "rgba(0, 0, 0, 0.06)",
                            borderRadius: "8px",
                            minWidth: "112px",
                            textAlign: "center",
                          }}
                        >
                          <Typography sx={{ color: "#000" }}>
                            {item?.unitName}
                          </Typography>
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  sx={{
                    with: "100%",
                    bgcolor: "#fafafa",
                    "& .MuiOutlinedInput-input": {
                      padding: "17px 16px",
                      borderRadius: "10px",
                      "&::placeholder": {
                        color: "#111",
                        fontSize: "16px",
                      },
                    },
                    borderRadius: "5px",
                  }}
                  placeholder="enter description"
                  value={item?.price}
                  onChange={(e) => handlePriceChange(idx, e.target.value)}
                />
              </Grid>
            ))
          )}
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 5 }}>
          <CustomButton
            variant="contained"
            color="success"
            fullWidth
            sx={{ padding: "10px 15px", minHeight: "48px" }}
            onClick={handleEnter}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 600,
                color: "#FFF",
                letterSpacing: "1px",
              }}
            >
              Submit
            </Typography>
          </CustomButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PriceProdukUnit;
