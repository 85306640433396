import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCategoryStock,
  getLocationStock,
  getMasterHubIdStock,
  getMiniHubIdStock,
  getSubHubIdStock,
  getSubsidiaryIdStock,
} from '../../../../../../store/stock/stock';
import { useRef } from 'react';
import debounce from 'lodash.debounce';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import {
  ArrowBackIosIcon,
  ArrowForwardIosIcon,
  ArrowRightAltIcon,
  ExportIcon,
  FilterAltIcon,
  NoImage,
  NoImageIcon,
  SearchIcon,
  SyncAltIcon,
} from '../../../../../../assets/Icons';
import CustomCard from '../../../../../../components/shared/CustomCard';
import { getStockAlertList } from '../../../../../../api/stock';
import DialogFilterStock from '../DialogFilter';


const CustomTableCells = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '12px',
  textTransform: 'capitalize',
});
const TableListStockAlert = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const subsidiaryId = useSelector(getSubsidiaryIdStock);
  const hubId = useSelector(getMasterHubIdStock);
  const subHubId = useSelector(getSubHubIdStock);
  const miniHubId = useSelector(getMiniHubIdStock);
  const category = useSelector(getCategoryStock);
  const location = useSelector(getLocationStock);

  const [dataStocksAlert, setDataStocksAlert] = useState();
  const [pages, setPages] = useState();
  const rowPerPageValues = [5, 10, 15, 20, 25];
  const [rowPerPage, setRowPerPage] = useState(rowPerPageValues[0]);
  const [currentPageTable, setCurrentPageTable] = useState(1);

  const [search, setSearch] = useState('');
  const [purchaseKeyword, setPurchaseKeyword] = useState('');

  // ! sorting table

  const [sortTable, setSortTable] = useState('');
  const [asc, setAsc] = useState('asc');
  const [sortAscTable, setSortAscTable] = useState(true);

  const debounceOnChange = useRef(
    debounce((value) => {
      setPurchaseKeyword(value);
    }, 1000)
  ).current;

  // ! fetch data
  const fetchAllDataStockAlert = async (params) => {
    setIsLoading(true);

    try {
      const {
        data: { data },
      } = await getStockAlertList(params);
      setDataStocksAlert(data?.stocks);
      setPages(data?.pageInfo);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let params = {};
    params.subsidiaryId = subsidiaryId;
    params.idToko = hubId;
    params.subHubid = subHubId;
    params.miniHubId = miniHubId;
    params.pageSize = rowPerPage;
    params.page = currentPageTable;
    params.catFilter = category;
    params.locationFilter = location;
    params.search = purchaseKeyword;
    params.name = sortTable !== 'sortName' ? '' : asc;
    params.hub = sortTable !== 'sortHub' ? '' : asc;
    params.cat = sortTable !== 'sortCat' ? '' : asc;
    params.subsidiary = sortTable !== 'sortSubsidiary' ? '' : asc;
    params.qty = sortTable !== 'sortQty' ? '' : asc;
    fetchAllDataStockAlert(params);
  }, [
    subsidiaryId,
    hubId,
    subHubId,
    miniHubId,
    rowPerPage,
    currentPageTable,
    purchaseKeyword,
    category,
    location,
    sortTable,
    asc,
  ]);

  // ! pagination
  const handleRowPerPage = (e) => {
    setRowPerPage(e.target.value);
    setCurrentPageTable(1);
  };
  const handleBackArrow = () => {
    setCurrentPageTable(
      currentPageTable <= 1 ? currentPageTable : currentPageTable - 1
    );
  };
  const handleForwardArrow = () => {
    setCurrentPageTable(
      currentPageTable >= Math.ceil(pages?.total / rowPerPage)
        ? currentPageTable
        : currentPageTable + 1
    );
  };
  const handleInputCurrentPage = (event) => {
    if (event.target.value >= Math.ceil(pages?.total / rowPerPage)) {
      setCurrentPageTable(Math.ceil(pages?.total / rowPerPage));
    } else if (event.target.value <= 1) {
      setCurrentPageTable(1);
    } else {
      setCurrentPageTable(event.target.value);
    }
  };

  //! -------------- TOOLBAR -------------------
  const [isFilter, setIsFilter] = useState(false);
  const toolbar = (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'row-reverse' }}>
      {/* export */}
      <CustomCard
        sx={{
          p: '10px 20px',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          cursor: 'pointer',
        }}
        onClick={() => setIsFilter(!isFilter)}
      >
        <ExportIcon sx={{ fontSize: '20px' }} />
        <Typography
          sx={{ fontSize: '14px', fontWeight: 500, color: '#000000' }}
        >
          Export
        </Typography>
      </CustomCard>
      {/* //* Filter */}
      <CustomCard
        sx={{
          p: '10px 20px',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          cursor: 'pointer',
        }}
        onClick={() => setIsFilter(!isFilter)}
      >
        <FilterAltIcon sx={{ fontSize: '20px' }} />
        <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#000' }}>
          Filter
        </Typography>
      </CustomCard>
      <DialogFilterStock {...{ isFilter, setIsFilter }} />
    </Box>
  );

  return (
    <Box sx={{ p: 2 }}>
      {/* search */}
      <Grid container columnSpacing={4} alignItems="center">
        <Grid item xs>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
              Shortage List
            </Typography>
            <TextField
              placeholder={'Search'}
              size="small"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                debounceOnChange(e.target.value);
              }}
              sx={{
                width: '250px',
                '& .MuiOutlinedInput-root': {
                  paddingLeft: '6px',
                  fontSize: '0.875rem',
                },
                bgcolor: '#FAFAFA',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ width: '18px' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>

        <Grid item xs="auto">
          {toolbar}
        </Grid>
      </Grid>

      {/* fetch ddata llist */}
      <Table
        sx={{
          marginTop: '12px',
          mb: 5,
        }}
      >
        <TableHead>
          <TableRow
            sx={{
              background: '#FAFAFA',
              fontWeight: 600,
            }}
          >
            <CustomTableCells>No</CustomTableCells>
            <CustomTableCells>Photo</CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortName');
                  setSortAscTable(!sortAscTable);
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  Name
                </Typography>
                {sortTable === 'sortName' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>

            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortHub');
                  setSortAscTable(!sortAscTable);
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  Hub
                </Typography>
                {sortTable === 'sortHub' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>

            <CustomTableCells>Location</CustomTableCells>

            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortSubsidiary');
                  setSortAscTable(!sortAscTable);
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  Subsidiary
                </Typography>
                {sortTable === 'sortSubsidiary' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>

            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortCat');
                  setSortAscTable(!sortAscTable);
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  CAT
                </Typography>
                {sortTable === 'sortCat' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>

            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortQty');
                  setSortAscTable(!sortAscTable);
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  Qty
                </Typography>
                {sortTable === 'sortQty' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
          </TableRow>
        </TableHead>
        {!isLoading && (
          <TableBody>
            {dataStocksAlert !== 0 && (
              <>
                {dataStocksAlert?.map((item, index) => (
                  <TableRow>
                    <CustomTableCells>
                      {(currentPageTable - 1) * rowPerPage + (index + 1)}
                    </CustomTableCells>
                    <CustomTableCells>
                      {item?.gambar !== null ? (
                        <Box
                          component="img"
                          src={
                            process.env.REACT_APP_API_IMAGE_BASE_URL +
                            '/' +
                            item?.gambar
                          }
                          sx={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '8px',
                          }}
                        />
                      ) : (
                        <NoImage />
                      )}
                    </CustomTableCells>
                    <CustomTableCells>{item?.namaProduk}</CustomTableCells>
                    <CustomTableCells>{item?.hub}</CustomTableCells>
                    <CustomTableCells>{item?.location}</CustomTableCells>
                    <CustomTableCells>{item?.subsidiary}</CustomTableCells>
                    <CustomTableCells>{item?.cat}</CustomTableCells>
                    <CustomTableCells sx={{ color: '#F33A3A' }}>
                    {item?.qty} {item?.unit}
                    </CustomTableCells>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        )}
      </Table>
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {!isLoading && (!dataStocksAlert || dataStocksAlert.length === 0) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column',
          }}
        >
          <Box
            sx={{
              width: '60px',
              height: '60px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: '#FAFAFA',
              borderRadius: '2px',
            }}
          >
            <NoImage />
          </Box>
          <Typography sx={{ fontSize: '14px', color: '#F33A3A' }}>
            no data found
          </Typography>
        </Box>
      )}

      {/* pagination */}
      <Grid
        container
        direction="row"
        coloumn={12}
        alignItems="center"
        sx={{ mt: '30px' }}
      >
        <Grid item xs={6} sx={{ fontSize: '14px', fontWeight: '500' }}>
          Showing {pages?.currentPage * pages?.perPage - (pages?.perPage - 1)}{' '}
          to{' '}
          {pages?.currentPage * pages?.perPage >= pages?.total
            ? pages?.total
            : pages?.currentPage * pages?.perPage}{' '}
          of {pages?.total} entries
        </Grid>
        <Grid
          container
          item
          direction="row"
          xs={6}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item sx={{ fontSize: '14px', fontWeight: '500' }}>
            Rows Per Page :
            <Select
              value={rowPerPage}
              variant="filled"
              onChange={handleRowPerPage}
              sx={{
                fontSize: '14px',
                lineHeight: '-1px',
                '.MuiFilledInput-input': {
                  paddingTop: '7.5px',
                  paddingBottom: '7.5px',
                  paddingLeft: '10px',
                  paddingRight: '0',
                  borderRadius: '5px',
                },
                '&:before': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                '&:after': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                borderRadius: '5px',
                marginLeft: '8px',
              }}
            >
              {rowPerPageValues?.map((rowPerPageValue, index) => (
                <MenuItem value={rowPerPageValue} key={index}>
                  {rowPerPageValue}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item sx={{ ml: '35px' }}>
            <Box sx={{ display: 'inline', mr: '10px' }}>
              <IconButton sx={{ p: 0 }} onClick={handleBackArrow}>
                <ArrowBackIosIcon sx={{ color: '#323232', width: '16px' }} />
              </IconButton>
            </Box>
            <Box sx={{ display: 'inline', mr: '20px' }}>
              <OutlinedInput
                variant="outlined"
                type="number"
                value={currentPageTable}
                onChange={handleInputCurrentPage}
                sx={{
                  border: '1px solid #9E9D9D',
                  width: '36px',
                  borderRadius: '5px',
                  textAlign: 'center',
                  '& .MuiOutlinedInput-input': {
                    padding: '5px 8px 5px 8px',
                    fontSize: '0.875rem',
                    boxShadow: 'none',
                    textAlign: 'center',
                    fontWeight: 600,
                    width: '25px',
                  },
                  '&:before': {
                    borderRadius: '5px',
                    borderBottom: 0,
                  },
                  '&:after': {
                    borderRadius: '5px',
                    borderBottom: 0,
                  },
                  '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'inline',
                fontWeight: '600',
                fontSize: '14px',
                color: '#9E9D9D',
              }}
            >
              of {Math.ceil(pages?.total / pages?.perPage)}
            </Box>
            <Box sx={{ display: 'inline', ml: '10px' }}>
              <IconButton sx={{ p: 0 }} onClick={handleForwardArrow}>
                <ArrowForwardIosIcon sx={{ color: '#323232', width: '16px' }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default TableListStockAlert;
