import React from 'react';
import { Menu } from '@mui/material';

const CustomMenu = ({ children, openMenu, setOpenMenu, isOpen, ...props }) => {
  return (
    <Menu
      anchorEl={openMenu}
      open={isOpen}
      onClose={() => setOpenMenu(null)}
      {...props}
    >
      {children}
    </Menu>
  );
};

export default CustomMenu;
