import React, { useState, useEffect } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material';
import BackButtonHub from '../../../../../../../../components/shared/BackButtonHub';
import RoadMap from './RoadMap';
import CreateGridComponent from './CreateGridComponent';
import SelectRacksComponent from './SelectRacksComponent';
import PreviewComponent from './PreviewComponent';
import useHub from '../../../../../../../../hooks/useHub';

const CreateMap = ({
  setIsOpenCreateMap,
  setIsOpenMasterHub,
  setRowSelected,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);
  const [isCreateGridOpen, setIsCreateGridOpen] = useState(true);
  const [isSelectRacksOpen, setIsSelectRacksOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const [numberOfColumns, setNumberOfColumns] = useState(50);
  const [numberOfRows, setNumberOfRows] = useState(50);

  const { masterHubData } = useHub();

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <BackButtonHub
          onClick={() => {
            setIsOpenCreateMap(false);
            setRowSelected(masterHubData.masterHubId);
            updatedSearchParams.set('detail', masterHubData.masterHubId);
            setSearchParams(updatedSearchParams);
          }}
        />
        <Box>
          <Box
            sx={{
              display: 'flex',
              gap: 0.5,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '1.125rem',
                color: 'secondary.light',
                fontWeight: 600,
              }}
              onClick={() => {
                setIsOpenCreateMap(false);
                setIsOpenMasterHub(true);
              }}
            >
              Detail Master Hub
            </Typography>
            <KeyboardArrowRightIcon sx={{ color: 'secondary.light' }} />
            <Typography
              sx={{
                color: 'primary.black',
                fontWeight: 600,
                fontSize: '1.125rem',
              }}
            >
              Create Map
            </Typography>
          </Box>

          <Typography
            sx={{
              mt: 1,
              color: 'primary.main',
              fontSize: '1rem',
              fontWeight: 600,
            }}
          >
            {masterHubData?.subsidiary || '-'}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ mt: 4, ml: 1 }}>
        <RoadMap
          isCreateGridOpen={isCreateGridOpen}
          setIsCreateGridOpen={setIsCreateGridOpen}
          isSelectRacksOpen={isSelectRacksOpen}
          setIsSelectRacksOpen={setIsSelectRacksOpen}
          isPreviewOpen={isPreviewOpen}
          setIsPreviewOpen={setIsPreviewOpen}
          numberOfColumns={numberOfColumns}
          numberOfRows={numberOfRows}
        />
      </Box>

      <Box sx={{ mt: 6 }}>
        {isCreateGridOpen ? (
          <CreateGridComponent
            numberOfColumns={numberOfColumns}
            setNumberOfColumns={setNumberOfColumns}
            numberOfRows={numberOfRows}
            setNumberOfRows={setNumberOfRows}
            setIsCreateGridOpen={setIsCreateGridOpen}
            setIsSelectRacksOpen={setIsSelectRacksOpen}
            setIsPreviewOpen={setIsPreviewOpen}
          />
        ) : isSelectRacksOpen ? (
          <SelectRacksComponent
            setNumberOfRows={setNumberOfRows}
            setIsCreateGridOpen={setIsCreateGridOpen}
            setIsSelectRacksOpen={setIsSelectRacksOpen}
            setIsPreviewOpen={setIsPreviewOpen}
            numberOfRows={numberOfRows}
            numberOfColumns={numberOfColumns}
            masterHubData={masterHubData}
          />
        ) : (
          <PreviewComponent
            setNumberOfRows={setNumberOfRows}
            setIsCreateGridOpen={setIsCreateGridOpen}
            setIsSelectRacksOpen={setIsSelectRacksOpen}
            setIsPreviewOpen={setIsPreviewOpen}
            numberOfRows={numberOfRows}
            numberOfColumns={numberOfColumns}
            masterHubData={masterHubData}
          />
        )}
      </Box>
    </Box>
  );
};

export default CreateMap;
