import { Grid, Typography, Box, Switch, Dialog, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ReactComponent as NoImage } from '../../../../../../assets/no-image.svg';

import BackButton from '../../../../../../components/shared/BackButton';
import CustomButton from '../../../../../../components/shared/CustomButton';
import dayjs from 'dayjs';
import { ChartDetails } from '../../Product/DesktopComponent';

const SubCategoryMenuDetailsDesktop = (props) => {
  const {
    AntSwitch,
    // *----
    roadHirarki,
    setRoadHirarki,
    //*--- page
    setPage,
    setSubCategoryMenu,
    // *--- timeFrame
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    // *--- Data
    detailsDatas,
    isLoadingDataDetails,
    // *--- Photo
    isPreviewPhoto,
    setIsPreviewPhoto,
    linkPreviewPhoto,
    setLinkPreviewPhoto,
    // *--- Status
    status,
    // *--- handler
    handleBackButton,
    handleEditStatus,
    handleDelete,
    handleEdit,
    handlePreviewPhoto,
  } = props;
  return (
    <Grid container columns={12} spacing={4} sx={{ pb: '56px' }}>
      {/* //* BackButton */}
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        <Box>
          <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
            {roadHirarki.subCategory} Details
          </Typography>
          <Box
            sx={{
              display: 'flex',
              color: '#9E9D9D',

              alignItems: 'center',
              mt: '4px',
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
              {roadHirarki.subsidiary ? roadHirarki.subsidiary : '-'}
            </Typography>
            <ChevronRightIcon />
            <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
              {roadHirarki.category ? roadHirarki.category : '-'}
            </Typography>
          </Box>
        </Box>
      </Grid>
      {!isLoadingDataDetails && (
        <>
          {/* //* --- Chart and 3 more */}
          <Grid container item xs={12} columns={12} columnSpacing={4}>
            <Grid item xs={8}>
              <ChartDetails
                {...{
                  timeFrame,
                  setTimeFrame,
                  dateRange,
                  setDateRange,
                  saveDateRange,
                  setSaveDateRange,
                }}
              />
            </Grid>
            <Grid container item xs={4} columns={12} spacing={4}>
              {/* purchased */}
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                  Purchased
                </Typography>
                <Box
                  sx={{
                    mt: '12px',
                    width: '100%',
                    padding: '14px 16px',
                    bgcolor: '#FAFAFA',
                    borderRadius: '8px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                    Rp {detailsDatas.purchased ? detailsDatas.purchased : '-'}
                  </Typography>
                </Box>
              </Grid>
              {/* sold */}
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                  Sold
                </Typography>
                <Box
                  sx={{
                    mt: '12px',
                    width: '100%',
                    padding: '14px 16px',
                    bgcolor: '#FAFAFA',
                    borderRadius: '8px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                    Rp {detailsDatas.sold ? detailsDatas.sold : '-'}
                  </Typography>
                </Box>
              </Grid>
              {/* used */}
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                  Used
                </Typography>
                <Box
                  sx={{
                    mt: '12px',
                    width: '100%',
                    padding: '14px 16px',
                    bgcolor: '#FAFAFA',
                    borderRadius: '8px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                    Rp {detailsDatas.used ? detailsDatas.used : '-'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/*//* SKU */}
          <Grid item xs={6}>
            <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
              SKU
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                {detailsDatas.sku ? detailsDatas.sku : '-'}
              </Typography>
            </Box>
          </Grid>
          {/*//* Photo */}
          <Grid item xs={6}>
            <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
              Photo
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '0',
                display: 'flex',
                gap: 2,
              }}
            >
              {!detailsDatas.gbrsub ? (
                <Box
                  sx={{
                    width: '42px',
                    height: '42px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: '#FAFAFA',
                    borderRadius: '2px',
                  }}
                >
                  <NoImage />
                  <Typography sx={{ color: '#9E9D9D', fontSize: '6px' }}>
                    No photo
                  </Typography>
                </Box>
              ) : (
                detailsDatas.gbrsub.split(',').map((item) => (
                  <Box
                    component="img"
                    src={process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + item}
                    onClick={() => handlePreviewPhoto(item)}
                    sx={{
                      width: '42px',
                      height: '42px',
                      aspectRatio: '1/1',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                  />
                ))
              )}
            </Box>
          </Grid>
          {/* //* Dialog Photo */}
          {linkPreviewPhoto !== '' && (
            <Dialog
              open={isPreviewPhoto}
              onClose={() => {
                setIsPreviewPhoto(!isPreviewPhoto);
                setLinkPreviewPhoto('');
              }}
            >
              <Box
                component="img"
                src={
                  process.env.REACT_APP_API_IMAGE_BASE_URL +
                  '/' +
                  linkPreviewPhoto
                }
                sx={{
                  width: '600px',
                  height: '600px',
                  aspectRation: '1/1',
                }}
              />
            </Dialog>
          )}
          {/* //* Description */}
          <Grid item xs={6}>
            <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
              Description
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                {detailsDatas.informasi ? detailsDatas.informasi : '-'}
              </Typography>
            </Box>
          </Grid>
          {/* //* Schedule */}
          <Grid item xs={6}>
            <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
              Schedule
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                {detailsDatas.schedule
                  ? dayjs(detailsDatas.schedule).format('DD/MMM/YYYY')
                  : '-'}
              </Typography>
            </Box>
          </Grid>
          {/* //* Button */}
          <Box
            sx={{
              width: '100%',
              bgcolor: 'white',
              position: 'fixed',
              bottom: '0px',
              right: '0px',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '18px 32px 18px 240px',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Publish
              </Typography>
              <AntSwitch checked={status} onChange={handleEditStatus} />
            </Box>
            <Box sx={{ display: 'flex', gap: '20px' }}>
              <CustomButton
                variant="contained"
                color="error"
                sx={{ width: '140px', height: '36px' }}
                onClick={handleDelete}
              >
                <Typography
                  sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
                >
                  Delete
                </Typography>
              </CustomButton>
              <CustomButton
                variant="contained"
                sx={{ width: '140px', height: '36px' }}
                onClick={handleEdit}
              >
                <Typography
                  sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
                >
                  Edit
                </Typography>
              </CustomButton>
            </Box>
          </Box>
        </>
      )}
    </Grid>
  );
};

export default SubCategoryMenuDetailsDesktop;
