import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowForward as ArrowForwardIcon,
  ArrowUpward as ArrowUpwardIcon,
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import lottie from 'lottie-web';
import { useEffect, useRef, useState } from 'react';
import { Chart, Line } from 'react-chartjs-2';
import { useSearchParams } from 'react-router-dom';
import { fetchTreatmentGraph } from '../api/treatment';
import loadingAnimation from '../assets/animations/chart-grow-up.json';
import { ReactComponent as BarChartIcon } from '../assets/bar-chart.svg';
import { ReactComponent as LineChartIcon } from '../assets/line-chart.svg';
import { ReactComponent as OrderIcon } from '../assets/order.svg';
import { ReactComponent as PriceIcon } from '../assets/price.svg';
import { ReactComponent as SalesIcon } from '../assets/sales.svg';
import { ReactComponent as UsedIcon } from '../assets/used.svg';

const colors = ['#C85178', '#4994B7', '#812089', '#468F36', '#E19F4F'];

const average = (ctx, index) => {
  if (ctx.chart.data.datasets.length > 0) {
    const values = ctx.chart.data.datasets[index].data;
    return values.reduce((a, b) => a + b, 0) / values.length;
  }

  return 0;
};

const getXAxisLabel = (timeframe) => {
  switch (timeframe) {
    case 'daily':
      return 'Day of Month';
    case 'monthly':
      return 'Month';
    case 'weekly':
      return 'Week';
    default:
      return '';
  }
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const ordinalSuffixOf = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return 'st';
  }
  if (j === 2 && k !== 12) {
    return 'nd';
  }
  if (j === 3 && k !== 13) {
    return 'rd';
  }
  return 'th';
};

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const MobileTreatmentDashboard = () => {
  const [searchParams] = useSearchParams();
  const loadingRef = useRef();
  const theme = useTheme();
  const [
    treatmentPerformanceLineChartData,
    setTreatmentPerformanceLineChartData,
  ] = useState({
    datasets: [],
  });
  const [
    treatmentPerformanceBarChartData,
    setTreatmentPerformanceBarChartData,
  ] = useState({
    datasets: [],
  });
  const [isInitialized, setIsInitialized] = useState(false);
  const [treatmentsTimeframe, setTreatmentsTimeframe] = useState('daily');
  const [treatmentChartType, setTreatmentChartType] = useState('bar');
  const [shownTreatmentAttribute, setShownTreatmentAttribute] =
    useState('sales');
  const [treatments, setTreatments] = useState([]);
  const [rawTreatments, setRawTreatments] = useState([]);
  const [treatmentNameQueryKeyword, setTreatmentNameQueryKeyword] =
    useState('');
  const [treatmentsInputStartDate, setTreatmentsInputStartDate] = useState(
    dayjs().subtract(6, 'day')
  );
  const [treatmentsStartDate, setTreatmentsStartDate] = useState(
    dayjs().subtract(6, 'day')
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const [treatmentsInputEndDate, setTreatmentsInputEndDate] = useState(dayjs());
  const [treatmentsEndDate, setTreatmentsEndDate] = useState(dayjs());

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('sales');
  const [selected, setSelected] = useState([]);
  const [
    barChartShownTreatmentAttributes,
    setBarChartShownTreatmentAttributes,
  ] = useState([0, 1]);
  const [isLoading, setIsLoading] = useState(true);

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name *(Rp000)',
    },
    {
      id: 'price',
      numeric: true,
      disablePadding: false,
      label: (
        <Box sx={{ display: 'flex' }}>
          <PriceIcon
            fill={theme.palette.grey['500']}
            height="16px"
            width="16px"
          />
          <Typography component="span" sx={{ ml: 0.25 }}>
            *
          </Typography>
        </Box>
      ),
    },
    {
      id: 'sales',
      numeric: true,
      disablePadding: false,
      label: (
        <Box sx={{ display: 'flex' }}>
          <SalesIcon
            fill={theme.palette.primary.main}
            height="16px"
            width="16px"
          />
          <Typography component="span" sx={{ ml: 0.25 }}>
            *
          </Typography>
        </Box>
      ),
    },
    {
      id: 'order',
      numeric: true,
      disablePadding: false,
      label: <OrderIcon fill="#FFCF56" height="16px" width="16px" />,
    },
    {
      id: 'used',
      numeric: true,
      disablePadding: false,
      label: <UsedIcon fill="#F28AAE" height="16px" width="16px" />,
    },
  ];

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    setIsInitialized(false);

    if (['sales', 'order', 'used'].includes(property)) {
      setShownTreatmentAttribute(property);
    }
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleCheckboxChange = (name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    const bootstrapAsync = async () => {
      setIsLoading(true);

      try {
        const {
          data: { data: fetchedTreatmentGraphs },
        } = await fetchTreatmentGraph({
          startDate: treatmentsStartDate.format('YYYY-MM-DD'),
          endDate: treatmentsEndDate.format('YYYY-MM-DD'),
          timeframe: treatmentsTimeframe,
        });

        setRawTreatments(fetchedTreatmentGraphs);
      } catch (error) {
        console.error(error);
        setRawTreatments([]);
      } finally {
        setIsLoading(false);
      }
    };

    bootstrapAsync();
  }, [treatmentsEndDate, treatmentsStartDate, treatmentsTimeframe]);

  useEffect(() => {
    if (treatmentNameQueryKeyword === '') {
      setTreatments(rawTreatments);
      return;
    }

    setTreatments(
      rawTreatments.filter(
        (treatment) =>
          treatment.name
            .toLowerCase()
            .indexOf(treatmentNameQueryKeyword.toLowerCase()) > -1 ||
          selected.includes(treatment.id)
      )
    );
  }, [rawTreatments, treatmentNameQueryKeyword, selected]);

  useEffect(() => {
    if (treatmentChartType !== 'line') return;

    if (treatments.length > 0) {
      const treatmentPerformanceChartData = {
        labels: treatments[0].details.map((detail) =>
          treatmentsTimeframe === 'monthly'
            ? dayjs(detail.dateTime).format('MMM') +
              ' ' +
              dayjs(detail.dateTime).format('YYYY')
            : treatmentsTimeframe === 'daily'
            ? dayjs(detail.dateTime).format('D') +
              ' ' +
              dayjs(detail.dateTime).format('MMM')
            : dayjs(detail.dateTime).week() +
              ' ' +
              dayjs(detail.dateTime).format('YYYY')
        ),
        datasets: treatments
          .filter((treatment) => selected.includes(treatment.id))
          .reduce((previousGraphs, currentGraph, index) => {
            const color = colors[index];

            return [
              ...previousGraphs,
              {
                label: currentGraph.name,
                data: currentGraph.details.map((detail) => {
                  switch (shownTreatmentAttribute) {
                    case 'sales':
                      return detail.earned;
                    case 'order':
                      return detail.orderCount;
                    case 'used':
                      return detail.usedCount;
                    default:
                      return 0;
                  }
                }),
                backgroundColor: color,
                borderColor: color,
              },
            ];
          }, []),
      };

      setTreatmentPerformanceLineChartData(treatmentPerformanceChartData);
    } else {
      setTreatmentPerformanceLineChartData({ datasets: [] });
    }
  }, [
    selected,
    shownTreatmentAttribute,
    treatmentChartType,
    treatments,
    treatmentsTimeframe,
  ]);

  useEffect(() => {
    if (treatmentChartType !== 'bar') return;

    if (treatments.length > 0) {
      const treatmentPerformanceChartData = {
        labels: treatments
          .filter((treatment) => selected.includes(treatment.id))
          .map((treatment) => treatment.name),
        datasets: [
          {
            label: 'Sales',
            yAxisID: 'y1',
            backgroundColor: theme.palette.primary.main,
            borderRadius: 4,
            type: 'bar',
            data: treatments
              .filter((treatment) => selected.includes(treatment.id))
              .map((treatment) => treatment.totalEarned),
            order: 0,
            hidden: !barChartShownTreatmentAttributes.includes(0),
          },
          {
            label: 'Order',
            data: treatments
              .filter((treatment) => selected.includes(treatment.id))
              .map((treatment) => treatment.totalOrderCount),
            borderRadius: 4,
            type: 'bar',
            yAxisID: 'y2',
            backgroundColor: '#FFCF46',
            order: 1,
            hidden: !barChartShownTreatmentAttributes.includes(1),
          },
          {
            label: 'Used',
            data: treatments
              .filter((treatment) => selected.includes(treatment.id))
              .map((treatment) => treatment.totalUsedCount),
            type: 'line',
            yAxisID: 'y3',
            borderColor: '#F28AAE',
            backgroundColor: '#F28AAE',
            order: -1,
            hidden: !barChartShownTreatmentAttributes.includes(2),
          },
        ],
      };

      setTreatmentPerformanceBarChartData(treatmentPerformanceChartData);
    } else {
      setTreatmentPerformanceBarChartData({ datasets: [] });
    }
  }, [
    barChartShownTreatmentAttributes,
    selected,
    theme.palette.primary.main,
    treatmentChartType,
    treatments,
  ]);

  useEffect(() => {
    if (treatmentNameQueryKeyword !== '') return;

    if (!isInitialized && treatments.length > 0) {
      setSelected(
        treatments
          .sort((treatment1, treatment2) => {
            switch (orderBy) {
              case 'sales':
                return order === 'asc'
                  ? treatment1.totalEarned - treatment2.totalEarned
                  : treatment2.totalEarned - treatment1.totalEarned;
              case 'order':
                return order === 'asc'
                  ? treatment1.totalOrderCount - treatment2.totalOrderCount
                  : treatment2.totalOrderCount - treatment1.totalOrderCount;
              case 'used':
                return order === 'asc'
                  ? treatment1.totalUsedCount - treatment2.totalUsedCount
                  : treatment2.totalUsedCount - treatment1.totalUsedCount;
              case 'price':
                return order === 'asc'
                  ? treatment1.unitPrice - treatment2.unitPrice
                  : treatment2.unitPrice - treatment1.unitPrice;
              case 'name':
                return order === 'asc'
                  ? treatment1.name.localeCompare(treatment2.name)
                  : treatment2.name.localeCompare(treatment1.name);
              default:
                return 0;
            }
          })
          .slice(0, searchParams.get('s') || 5)
          .map((treatment) => treatment.id)
      );
      setIsInitialized(true);
    }
  }, [
    order,
    orderBy,
    searchParams,
    treatmentNameQueryKeyword,
    treatments,
    isInitialized,
  ]);

  useEffect(() => {
    if (loadingRef.current && isLoading) {
      lottie.loadAnimation({
        animationData: loadingAnimation,
        container: loadingRef.current,
      });
    }
  }, [isLoading]);

  return (
    <Box sx={{ mt: 1, bgcolor: 'background.default', p: 2.5, pt: 1.5 }}>
      <Grid container>
        <Grid container item columnSpacing={0.75}>
          <Grid item xs>
            <TextField
              placeholder="Search Here"
              size="small"
              inputProps={{
                sx: {
                  fontSize: '0.75rem',
                  p: 0.6875,
                },
              }}
              fullWidth
              onChange={(e) => {
                if (!isExpanded) setIsExpanded(true);
                setTreatmentNameQueryKeyword(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && e.target.value !== '') {
                  const searchedTreatments = treatments
                    .filter(
                      (treatment) =>
                        treatment.name
                          .toLowerCase()
                          .indexOf(treatmentNameQueryKeyword.toLowerCase()) > -1
                    )
                    .map((treatment) => treatment.id);

                  if (searchedTreatments.length > 0) {
                    setSelected(searchedTreatments);
                  }
                }
              }}
            />
          </Grid>
          {/* {treatmentChartType === 'line' && (
            <Grid item xs="auto">
              <FormControl>
                <Select
                  inputProps={{
                    sx: {
                      fontSize: '0.75rem',
                      width: '1.6rem',
                      display: 'flex',
                    },
                  }}
                  sx={{
                    height: '1.6875rem',
                  }}
                  onChange={(event) => {
                    setShownTreatmentAttribute(event.target.value);
                    setOrder('desc');
                    setOrderBy(event.target.value);
                  }}
                  value={shownTreatmentAttribute}
                  // renderValue={(value) => (
                  //   <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  //     <Box
                  //       component="span"
                  //       sx={{
                  //         bgcolor: 'primary.main',
                  //         display: 'inline-block',
                  //         width: '1rem',
                  //         height: '1rem',
                  //         borderRadius: '50%',
                  //       }}
                  //     />
                  //     <Typography sx={{ ml: 0.5 }} component="span">
                  //       {value}
                  //     </Typography>
                  //   </Box>
                  // )}
                >
                  <MenuItem value="sales">Sales</MenuItem>
                  <MenuItem value="order">Order</MenuItem>
                  <MenuItem value="used">Used</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )} */}
          {treatmentChartType === 'line' && (
            <Grid item xs="auto">
              <FormControl>
                <Select
                  inputProps={{
                    sx: {
                      fontSize: '0.75rem',
                      width: '0.5rem',
                      display: 'flex',
                    },
                  }}
                  sx={{
                    height: '1.6875rem',
                  }}
                  onChange={(event) => {
                    setTreatmentsTimeframe(event.target.value);
                  }}
                  value={treatmentsTimeframe}
                  // renderValue={(value) => (
                  //   <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  //     <Box
                  //       component="span"
                  //       sx={{
                  //         bgcolor: 'primary.main',
                  //         display: 'inline-block',
                  //         width: '1rem',
                  //         height: '1rem',
                  //         borderRadius: '50%',
                  //       }}
                  //     />
                  //     <Typography sx={{ ml: 0.5 }} component="span">
                  //       {value}
                  //     </Typography>
                  //   </Box>
                  // )}
                >
                  <MenuItem value="daily">D</MenuItem>
                  <MenuItem value="weekly">W</MenuItem>
                  <MenuItem value="monthly">M</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs="auto">
            <MobileDatePicker
              value={treatmentsInputStartDate}
              onChange={(newValue) => {
                setTreatmentsInputStartDate(newValue);
              }}
              onAccept={(newValue) => {
                setTreatmentsStartDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    sx: {
                      textAlign: 'center',
                      fontSize: '0.75rem',
                      p: 0.6875,
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      width: '5rem',
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs="auto">
            <ArrowForwardIcon sx={{ display: 'block', mt: 0.5, mx: -0.5 }} />
          </Grid>
          <Grid item xs="auto">
            <MobileDatePicker
              value={treatmentsInputEndDate}
              onChange={(newValue) => {
                setTreatmentsInputEndDate(newValue);
              }}
              onAccept={(newValue) => {
                setTreatmentsEndDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    sx: {
                      textAlign: 'center',
                      fontSize: '0.75rem',
                      p: 0.6875,
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      width: '5rem',
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      {isLoading ? (
        <Box ref={loadingRef} sx={{ aspectRatio: '1' }} />
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {treatmentChartType === 'line' ? (
              <>
                {shownTreatmentAttribute === 'sales' ? (
                  <SalesIcon
                    onClick={() => setShownTreatmentAttribute('order')}
                    width="16px"
                    height="16px"
                    fill={theme.palette.primary.main}
                    style={{
                      borderBottom: '1px solid ' + theme.palette.primary.main,
                      paddingBottom: 1,
                    }}
                  />
                ) : shownTreatmentAttribute === 'order' ? (
                  <OrderIcon
                    onClick={() => setShownTreatmentAttribute('used')}
                    width="16px"
                    height="16px"
                    fill="#FFCF56"
                    style={{
                      borderBottom: '1px solid #FFCF56',
                      paddingBottom: 1,
                      marginLeft: 4,
                    }}
                  />
                ) : (
                  <UsedIcon
                    onClick={() => setShownTreatmentAttribute('sales')}
                    width="16px"
                    height="16px"
                    fill="#F28AAE"
                    style={{
                      borderBottom: '1px solid #F28AAE',
                      paddingBottom: 1,
                      marginLeft: 4,
                    }}
                  />
                )}
                <IconButton
                  color="primary"
                  onClick={() => setTreatmentChartType('bar')}
                >
                  <BarChartIcon
                    width="16px"
                    height="16px"
                    fill={theme.palette.primary.main}
                  />
                </IconButton>
                {shownTreatmentAttribute === 'sales' ? (
                  <SalesIcon
                    width="16px"
                    height="16px"
                    fill={theme.palette.common.white}
                    style={{
                      borderBottom: '1px solid ' + theme.palette.common.white,
                    }}
                  />
                ) : shownTreatmentAttribute === 'order' ? (
                  <OrderIcon
                    onClick={() => setShownTreatmentAttribute('used')}
                    width="16px"
                    height="16px"
                    fill={theme.palette.common.white}
                    style={{
                      borderBottom: '1px solid ' + theme.palette.common.white,
                      paddingBottom: 1,
                      marginRight: 4,
                    }}
                  />
                ) : (
                  <UsedIcon
                    onClick={() => setShownTreatmentAttribute('sales')}
                    width="16px"
                    height="16px"
                    fill={theme.palette.common.white}
                    style={{
                      borderBottom: '1px solid ' + theme.palette.common.white,
                      paddingBottom: 1,
                      marginRight: 4,
                    }}
                  />
                )}
              </>
            ) : (
              <>
                <SalesIcon
                  width="16px"
                  height="16px"
                  fill={theme.palette.primary.main}
                  style={{ marginLeft: 26 }}
                />
                <IconButton
                  color="primary"
                  onClick={() => setTreatmentChartType('line')}
                >
                  <LineChartIcon
                    width="16px"
                    height="16px"
                    fill={theme.palette.primary.main}
                  />
                </IconButton>
                <OrderIcon
                  width="16px"
                  height="16px"
                  fill="#FFCF56"
                  style={{ marginRight: 25 }}
                />
              </>
            )}
          </Box>
          {treatmentChartType === 'line' ? (
            <Line
              style={{ marginTop: '-0.5rem' }}
              data={treatmentPerformanceLineChartData}
              plugins={[
                {
                  afterDraw: function (chart) {
                    const ctx = chart.ctx;
                    const width = chart.width;
                    const height = chart.height;

                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';

                    if (chart.data.datasets.length === 0) {
                      ctx.save();
                      ctx.fillText(
                        'Please Tick Any Treatment from The Table Below',
                        width / 2,
                        height / 2
                      );
                      ctx.restore();
                      return;
                    }

                    if (
                      chart.data.datasets
                        .reduce(
                          (previousData, currentDataset) =>
                            previousData.concat(currentDataset.data),
                          []
                        )
                        .every((datum) => datum === 0)
                    ) {
                      ctx.save();
                      ctx.fillText('The Data is Nil', width / 2, height / 2);
                      ctx.restore();
                    }
                  },
                },
              ]}
              options={{
                aspectRatio: 1,
                plugins: {
                  legend: { display: false },
                  tooltip: {
                    callbacks: {
                      title: (tooltipItems) => {
                        switch (treatmentsTimeframe) {
                          case 'daily':
                            return `${
                              tooltipItems[0].label.split(' ')[0]
                            }${ordinalSuffixOf(
                              tooltipItems[0].label.split(' ')[0]
                            )} ${tooltipItems[0].label.split(' ')[1]}`;
                          case 'weekly':
                            return `${
                              tooltipItems[0].label.split(' ')[0]
                            }${ordinalSuffixOf(
                              tooltipItems[0].label.split(' ')[0]
                            )} Week ${tooltipItems[0].label.split(' ')[1]}`;
                          default:
                            return tooltipItems[0].label;
                        }
                      },
                    },
                  },
                },
                interaction: {
                  intersect: false,
                },
                stacked: false,
                elements: {
                  point: {
                    radius: 0,
                  },
                  line: {
                    borderWidth: 1.5,
                    tension: 0.4,
                  },
                },
                scales: {
                  y: {
                    ticks: {
                      color: '#B8B8B8',
                      font: {
                        size: 12,
                        weight: 600,
                        family: 'Poppins',
                      },
                      callback: function (value) {
                        return shownTreatmentAttribute === 'sales' &&
                          treatmentPerformanceLineChartData.datasets
                            .reduce(
                              (previousData, currentDataset) =>
                                previousData.concat(currentDataset.data),
                              []
                            )
                            .some((datum) => datum > 0)
                          ? value / 1000000
                          : value;
                      },
                    },
                    title: {
                      color: '#B8B8B8',
                      font: {
                        size: 12,
                        weight: 600,
                        family: 'Poppins',
                      },
                      text: shownTreatmentAttribute,
                    },
                  },
                  x: {
                    ticks: {
                      color: '#B8B8B8',
                      font: {
                        size: 12,
                        weight: 600,
                        family: 'Poppins',
                      },
                      callback(value) {
                        const newLabel = this.getLabelForValue(value);
                        return treatmentsTimeframe === 'monthly'
                          ? `${newLabel}`.split(' ')[0]
                          : `${`${newLabel}`.split(' ')[0]}${ordinalSuffixOf(
                              `${newLabel}`.split(' ')[0]
                            )}`;
                      },
                    },
                    title: {
                      display: true,
                      color: '#B8B8B8',
                      font: {
                        size: 12,
                        weight: 600,
                        family: 'Poppins',
                      },
                      text: getXAxisLabel(treatmentsTimeframe),
                    },
                  },
                },
              }}
            />
          ) : (
            <Chart
              style={{ marginTop: '-0.5rem' }}
              type="bar"
              data={treatmentPerformanceBarChartData}
              plugins={[
                {
                  afterDraw: function (chart) {
                    const ctx = chart.ctx;
                    const width = chart.width;
                    const height = chart.height;

                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';

                    if (
                      chart.legend.legendItems
                        .map((item) => item.hidden)
                        .every((hidden) => hidden)
                    ) {
                      ctx.save();
                      ctx.fillText(
                        'Please Tick Any Legend Below',
                        width / 2,
                        height / 2
                      );
                      ctx.restore();
                      return;
                    }

                    if (
                      chart.data.datasets.reduce(
                        (previousData, currentDataset, index) =>
                          previousData.concat(currentDataset.data),
                        []
                      ).length === 0
                    ) {
                      ctx.save();
                      ctx.fillText(
                        'Please Tick Any Treatment from The Table Below',
                        width / 2,
                        height / 2
                      );
                      ctx.restore();
                      return;
                    }

                    if (
                      chart.data.datasets
                        .reduce(
                          (previousData, currentDataset, index) =>
                            chart.isDatasetVisible(index)
                              ? previousData.concat(currentDataset.data)
                              : previousData,
                          []
                        )
                        .every((datum) => datum === 0)
                    ) {
                      ctx.save();
                      ctx.fillText('The Data is Nil', width / 2, height / 2);
                      ctx.restore();
                    }
                  },
                },
              ]}
              options={{
                aspectRatio: 1,
                layout: {
                  padding: {
                    left: 24,
                    right: 24,
                  },
                },
                plugins: {
                  annotation: {
                    annotations: {
                      annotation1: {
                        display:
                          barChartShownTreatmentAttributes.includes(0) &&
                          selected.length > 0,
                        type: 'line',
                        borderColor: theme.palette.primary.main,
                        borderDash: [5, 5],
                        borderDashOffset: 0,
                        borderWidth: 1,
                        label: {
                          backgroundColor: theme.palette.primary.main,
                          enabled: true,
                          content: (ctx) =>
                            (average(ctx, 0) / 1000000).toFixed(2),
                          position: 'start',
                          xAdjust: (ctx) =>
                            -30 -
                            (average(ctx, 0) / 1000000).toFixed(2).length * 5,
                        },
                        yScaleID: 'y1',
                        yMin: (ctx) => average(ctx, 0),
                        xMax: (ctx) =>
                          ctx.chart.data.datasets.length > 0
                            ? ctx.chart.data.datasets[0].data.length - 1
                            : 0,
                        yMax: (ctx) => average(ctx, 0),
                      },
                      annotation2: {
                        display:
                          barChartShownTreatmentAttributes.includes(1) &&
                          selected.length > 0,
                        type: 'line',
                        borderColor: '#FFCF56',
                        borderDash: [5, 5],
                        borderDashOffset: 0,
                        borderWidth: 1,
                        label: {
                          backgroundColor: '#FFCF56',
                          color: 'black',
                          enabled: true,
                          content: (ctx) => average(ctx, 1).toFixed(2),
                          position: 'end',
                          xAdjust: (ctx) =>
                            30 + average(ctx, 1).toFixed(2).length * 5,
                        },
                        yScaleID: 'y2',
                        yMin: (ctx) => average(ctx, 1),
                        xMin: 0,
                        xMax: (ctx) =>
                          ctx.chart.data.datasets.length > 0
                            ? ctx.chart.data.datasets[1].data.length
                            : 0,
                        yMax: (ctx) => average(ctx, 1),
                      },
                    },
                  },
                  legend: {
                    labels: {
                      sort: (legendItem1, legendItem2) => {
                        const orders = ['Sales', 'Order', 'Used'];

                        const item1Index = orders.findIndex(
                          (order) => order === legendItem1.text
                        );
                        const item2Index = orders.findIndex(
                          (order) => order === legendItem2.text
                        );

                        if (item1Index > item2Index) {
                          return 1;
                        } else {
                          return -1;
                        }
                      },
                      color: '#B8B8B8',
                      font: {
                        size: 12,
                        weight: 600,
                        family: 'Poppins',
                      },
                      usePointStyle: true,
                      pointStyle: 'rect',
                    },
                    position: 'bottom',
                    onClick: function (e, legendItem, legend) {
                      const index = legendItem.datasetIndex;
                      const ci = legend.chart;

                      if (ci.isDatasetVisible(index)) {
                        ci.hide(index);
                        legendItem.hidden = true;
                        setBarChartShownTreatmentAttributes([
                          ...barChartShownTreatmentAttributes.filter(
                            (attribute) => attribute !== index
                          ),
                        ]);
                      } else {
                        ci.show(index);
                        legendItem.hidden = false;
                        setBarChartShownTreatmentAttributes([
                          ...barChartShownTreatmentAttributes,
                          index,
                        ]);
                      }
                    },
                  },
                  tooltip: {
                    callbacks: {
                      title: (tooltipItems) => {
                        return tooltipItems[0].label.split(' ')[0];
                      },
                    },
                  },
                },
                interaction: {
                  intersect: false,
                },
                scales: {
                  y1: {
                    display: barChartShownTreatmentAttributes.includes(0),
                    ticks: {
                      color: '#B8B8B8',
                      font: {
                        size: 12,
                        weight: 600,
                        family: 'Poppins',
                      },
                      callback: function (value) {
                        return treatmentPerformanceBarChartData.datasets
                          .find((dataset) => dataset.label === 'Sales')
                          ?.data.every((datum) => datum === 0)
                          ? value
                          : value / 1000000;
                      },
                    },
                  },
                  y2: {
                    display: barChartShownTreatmentAttributes.includes(1),
                    ticks: {
                      color: '#B8B8B8',
                      font: {
                        size: 12,
                        weight: 600,
                        family: 'Poppins',
                      },
                    },
                    position: 'right',
                  },
                  y3: {
                    display: false,
                  },
                  x: {
                    ticks: {
                      color: '#B8B8B8',
                      font: {
                        size: 12,
                        weight: 600,
                        family: 'Poppins',
                      },
                      callback(value) {
                        const newLabel = this.getLabelForValue(value);
                        return `${newLabel}`.split(' ')[0];
                      },
                    },
                  },
                },
              }}
            />
          )}
          <TableContainer sx={{ mt: -1 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ pl: 0, pr: 1.5, py: 0 }}></TableCell>
                  {headCells.map((headCell) => (
                    <TableCell
                      sx={{
                        fontSize: '0.7rem',
                        px: 0,
                        ':last-child': {
                          pr: 0.5,
                        },
                        // ':not(:nth-child(2))': {
                        //   width: '1rem',
                        // },
                      }}
                      key={headCell.id}
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        sx={{
                          '& .MuiTableSortLabel-icon': {
                            width: '0.7em',
                            height: '0.7em',
                          },
                        }}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
       rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(
                  treatments.map((treatment) => ({
                    id: treatment.id,
                    name: treatment.name,
                    price: treatment.unitPrice,
                    order: treatment.totalOrderCount,
                    sales: treatment.totalEarned,
                    used: treatment.totalUsedCount,
                  })),
                  getComparator(order, orderBy)
                ).map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  if (index > 4 && !isExpanded) return null;

                  return (
                    <TableRow
                      sx={{
                        ':nth-of-type(odd)': {
                          bgcolor: 'grey.100',
                        },
                      }}
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell
                        sx={{
                          pl: 0,
                          pr: 1.5,
                          py: 0,
                          border: 0,
                        }}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          sx={{
                            p: 0,
                            '&.Mui-checked': {
                              color:
                                isItemSelected && treatmentChartType === 'line'
                                  ? colors[
                                      selected.findIndex((id) => id === row.id)
                                    ]
                                  : 'primary',
                            },
                          }}
                          onChange={() => handleCheckboxChange(row.id)}
                          disabled={!isItemSelected && selected.length >= 5}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{
                          border: 0,
                        }}
                      >
                        <Typography
                          component="div"
                          sx={{
                            // height: '1.75rem',
                            fontSize: '0.7rem',
                            // overflow: 'hidden',
                            // textOverflow: 'ellipsis',
                            // display: '-webkit-box',
                            // WebkitLineClamp: '2',
                            // WebkitBoxOrient: 'vertical',
                            // WebkitBoxPack: 'center',
                          }}
                        >
                          {row.name}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={[{ fontSize: '0.7rem', border: 0, px: 0.5 }]}
                      >
                        {(row.price / 1000).toLocaleString('id')}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={[
                          { fontSize: '0.7rem', border: 0, px: 0.5 },
                          isItemSelected &&
                            ((treatmentChartType === 'bar' &&
                              barChartShownTreatmentAttributes.includes(0)) ||
                              (treatmentChartType === 'line' &&
                                shownTreatmentAttribute === 'sales')) && {
                              color: 'primary.main',
                              fontWeight: 'bold',
                            },
                        ]}
                      >
                        {(row.sales / 1000).toLocaleString('id')}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={[
                          { fontSize: '0.7rem', border: 0, px: 0.5 },
                          isItemSelected &&
                            ((treatmentChartType === 'bar' &&
                              barChartShownTreatmentAttributes.includes(1)) ||
                              (treatmentChartType === 'line' &&
                                shownTreatmentAttribute === 'order')) && {
                              color: '#FFCF56',
                              fontWeight: 'bold',
                            },
                        ]}
                      >
                        {row.order}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={[
                          { fontSize: '0.7rem', border: 0, px: 0.5 },
                          isItemSelected &&
                            ((treatmentChartType === 'bar' &&
                              barChartShownTreatmentAttributes.includes(2)) ||
                              (treatmentChartType === 'line' &&
                                shownTreatmentAttribute === 'used')) && {
                              color: '#F28AAE',
                              fontWeight: 'bold',
                            },
                        ]}
                      >
                        {row.used}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {isExpanded ? (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={() => setIsExpanded(false)}>
                <ArrowUpwardIcon />
              </IconButton>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={() => setIsExpanded(true)}>
                <ArrowDownwardIcon />
              </IconButton>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default MobileTreatmentDashboard;
