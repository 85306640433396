import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Switch,
  Dialog,
  styled,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CustomCard from '../../../../../../components/shared/CustomCard';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ReactComponent as NoImage } from '../../../../../../assets/no-image.svg';
import { ReactComponent as InstagramLogo } from '../../../../../../assets/instagramLogo.svg';
import { ReactComponent as TiktokLogo } from '../../../../../../assets/tiktokLogo.svg';

import BackButton from '../../../../../../components/shared/BackButton';
import { ChartDetails } from '../../Product/DesktopComponent';
import dayjs from 'dayjs';
import CustomButton from '../../../../../../components/shared/CustomButton';
import {
  deleteMenuMenu,
  updateMenuMenuStatus,
} from '../../../../../../api/menu';
import CustomDeleteConfirm from '../../../../../../components/shared/CustomDeleteConfirm';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const MenuMenuDetails = (props) => {
  const {
    roadHirarki,
    setRoadHirarki,
    // *-- Page
    setPage,
    setMenuMenu,
    // *-- Notif
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
    // *-- data
    detailsDatas,
    isLoadingDataDetails,
    // *-- data edit
    menuEditInputDatas,
    setMenuEditInputDatas,
    // *-- opsi edit
    categoryDataAwaitEdit,
    setCategoryDataAwaitEdit,
    ingredientDataAwaitEdit,
    setIngredientDataAwaitEdit,
    socialMediaDataAwaitEdit,
    setSocialMediaDataAwaitEdit,
    // *-- Date RAnge
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
  } = props;
  const today = dayjs(new Date());

  const [linkPreviewPhoto, setLinkPreviewPhoto] = useState('');
  const [isPreviewPhoto, setIsPreviewPhoto] = useState(false);
  const [linkPreviewVideo, setLinkPreviewVideo] = useState('');
  const [isPreviewVideo, setIsPreviewVideo] = useState(false);

  // !---------------- Road Hirarki ----------------

  // const [hirarkiDetails, setHirarkiDetails] = useState({
  //   subsidiary: '',
  //   subsidiaryId: 0,
  //   category: '',
  //   categoryId: 0,
  //   subCategory: '',
  //   subCategoryId: 0,
  //   menu: '',
  //   menuId: 0,
  //   miniSubCategory: '',
  //   miniSubCategoryId: 0,
  // });
  // useEffect(() => {
  //   if (detailsDatas.miniCategory !== null) {
  //     return setHirarkiDetails({
  //       subsidiary:
  //         detailsDatas.miniCategory?.subCategory?.category?.subsidiary?.name,
  //       subsidiaryId:
  //         detailsDatas.miniCategory?.subCategory?.category?.subsidiary?.id,
  //       category:
  //         detailsDatas.miniCategory?.subCategory?.category?.namaKategori,
  //       categoryId:
  //         detailsDatas.miniCategory?.subCategory?.category?.idKategori,
  //       subCategory: detailsDatas.miniCategory?.subCategory?.subkategori,
  //       subCategoryId: detailsDatas.miniCategory?.subCategory?.idSubkategori,
  //       miniSubCategory: detailsDatas.miniCategory?.name,
  //       miniSubCategoryId: detailsDatas.miniCategory?.id,
  //       menu: detailsDatas.namaProduk,
  //       menuId: detailsDatas.idProduk,
  //     });
  //   } else {
  //     return setHirarkiDetails({
  //       subsidiary: detailsDatas.subCategory?.category?.subsidiary?.name,
  //       subsidiaryId: detailsDatas.subCategory?.category?.subsidiary?.id,
  //       category: detailsDatas.subCategory?.category?.namaKategori,
  //       categoryId: detailsDatas.subCategory?.category?.idKategori,
  //       subCategory: detailsDatas.subCategory?.subkategori,
  //       subCategoryId: detailsDatas.subCategory?.idSubkategori,
  //       miniSubCategory: '',
  //       miniSubCategoryId: 0,
  //       menu: detailsDatas.namaProduk,
  //       menuId: detailsDatas.idProduk,
  //     });
  //   }
  // }, [detailsDatas.miniCategory, detailsDatas.subCategory, setHirarkiDetails]);

  // !---------------- Confirm ----------------
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  // !---------------- Status Product ----------
  const [statusProduct, setStatusProduct] = useState(false);

  useEffect(() => {
    if (!isLoadingDataDetails) {
      if (detailsDatas.status === 'aktif') {
        return setStatusProduct(true);
      } else {
        return setStatusProduct(false);
      }
    }
  }, [isLoadingDataDetails]);

  // !---------------------------- HANDLER -----------------------------------
  const handleBackButton = () => {
    setMenuMenu('');
    setRoadHirarki({ ...roadHirarki, menu: '', menuId: 0 });
  };

  const handleEditStatus = (e) => {
    if (statusProduct) {
      updateMenuMenuStatus(roadHirarki.menuId, 'pending');
      setStatusProduct(false);
    } else {
      updateMenuMenuStatus(roadHirarki.menuId, 'aktif');
      setStatusProduct(true);
    }
  };

  const handleDelete = () => {
    setIsDeleteConfirm(true);
  };
  const handleDeleteFix = () => {
    deleteMenuMenu(roadHirarki.menuId).then((res) => {
      setRoadHirarki({ ...roadHirarki, product: '', menuId: 0 });
      setIsDeleteConfirm(false);
      setMenuMenu('');
      setNotif('deletemenu');
      setNotifTitle(
        `${res.data.data.namaProduk} has been successfully deleted`
      );
    });
  };

  const handleEdit = () => {
    let ingredientsMoment = detailsDatas.ingredients.map((item) => {
      return {
        idProduk: item.idProduk,
        unit: item.unit,
        value: item.value,
      };
    });
    let ingredientsMomentAwait = detailsDatas.ingredients.map((item) => {
      return {
        product: item.product.namaProduk,
        productId: item.idProduk,
        unitQty: item.value,
        unitName: item.unit,
      };
    });
    setMenuEditInputDatas({
      ...menuEditInputDatas,
      id: detailsDatas.idProduk,
      name: detailsDatas.namaProduk,
      metaDescription: detailsDatas.metaDesk ? detailsDatas.metaDesk : '',
      description: detailsDatas.deskripsi,
      scheduleType: 'custom',
      schedule: detailsDatas.schedule ? dayjs(detailsDatas.schedule) : today,
      photoUrls: detailsDatas.gambar ? detailsDatas.gambar.split(',') : [],
      videoUrls: detailsDatas.video ? detailsDatas.video.split(',') : [],
      publish: statusProduct,
      categoryId: detailsDatas.categoryId,
      category: detailsDatas.category,
      categoryChoose: detailsDatas.category,
      subCategoryId: detailsDatas.subCategoryId,
      subCategory: detailsDatas.subCategory,
      subCategoryChoose: detailsDatas.subCategory,
      ingredient: ingredientsMomentAwait,
      instagram:
        detailsDatas.socialMedias.filter((item) => item.name === 'instagram')
          .length !== 0
          ? detailsDatas.socialMedias.find((item) => item.name === 'instagram')
              .link
          : '',
      tiktok:
        detailsDatas.socialMedias.filter((item) => item.name === 'tiktok')
          .length !== 0
          ? detailsDatas.socialMedias.find((item) => item.name === 'tiktok')
              .link
          : '',
    });

    setCategoryDataAwaitEdit({
      ...categoryDataAwaitEdit,
      categoryId: detailsDatas.categoryId,
      category: detailsDatas.category,
      categoryChoose: {
        idKategori: detailsDatas.categoryId,
        namaKategori: detailsDatas.category,
      },
      subCategoryId: detailsDatas.subCategoryId,
      subCategory: detailsDatas.subCategory,
      subCategoryChoose: {
        idSubkategori: detailsDatas.subCategoryId,
        subkategori: detailsDatas.subCategory,
      },
      miniSubCategory: detailsDatas.miniSubCategory,
      miniSubCategoryId: detailsDatas.miniSubCategoryId,
      miniSubCategoryChoose: !detailsDatas.miniSubCategoryId
        ? null
        : {
            id: detailsDatas.miniSubCategoryId,
            name: detailsDatas.miniSubCategory,
          },
    });

    setIngredientDataAwaitEdit(ingredientsMomentAwait);

    setSocialMediaDataAwaitEdit({
      instagram:
        detailsDatas.socialMedias.filter((item) => item.name === 'instagram')
          .length !== 0
          ? detailsDatas.socialMedias.find((item) => item.name === 'instagram')
              .link
          : '',
      tiktok:
        detailsDatas.socialMedias.filter((item) => item.name === 'tiktok')
          .length !== 0
          ? detailsDatas.socialMedias.find((item) => item.name === 'tiktok')
              .link
          : '',
    });

    setMenuMenu('edit');
  };

  const handlePreviewPhoto = (link) => {
    setLinkPreviewPhoto(link);
    setIsPreviewPhoto(true);
  };
  const handleClosePreviewPhoto = () => {
    setIsPreviewPhoto(!isPreviewPhoto);
    setLinkPreviewPhoto('');
  };
  const handlePreviewVideo = (link) => {
    setLinkPreviewVideo(link);
    setIsPreviewVideo(true);
  };
  const handleClosePreviewVideo = () => {
    setIsPreviewVideo(!isPreviewVideo);
    setLinkPreviewVideo('');
  };

  const handleToSocialMedia = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <CustomNotifSuccess
        text={notifTitle}
        open={notif === 'editmenu'}
        onClose={() => {
          setNotif('');
          setNotifTitle('');
        }}
      />
      <CustomDeleteConfirm
        open={isDeleteConfirm}
        onClose={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickCancel={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Menu"
        body={
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: '600' }}>
                {detailsDatas.namaProduk}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: '52px' }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />
      <Grid container columns={12} spacing={4} sx={{ pb: '56px' }}>
        {/* //* BackButton */}
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
        >
          <BackButton handleClick={handleBackButton} />
          {!isLoadingDataDetails && (
            <Box>
              <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
                {roadHirarki?.menu} Details
              </Typography>
              {!isLoadingDataDetails && (
                <Box
                  sx={{
                    display: 'flex',
                    color: '#9E9D9D',

                    alignItems: 'center',
                    mt: '4px',
                    gap: 1,
                  }}
                >
                  <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                    {detailsDatas?.subsidiary}
                  </Typography>
                  <ChevronRightIcon sx={{ fontSize: '16px' }} />
                  <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                    {detailsDatas?.category}
                  </Typography>
                  <ChevronRightIcon sx={{ fontSize: '16px' }} />
                  <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                    {detailsDatas?.subCategory}
                  </Typography>
                  {/* {hirarkiDetails?.miniSubCategory !== '' && (
                    <>
                      <ChevronRightIcon sx={{ fontSize: '16px' }} />
                      <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                        {hirarkiDetails?.miniSubCategory}
                      </Typography>
                    </>
                  )} */}
                </Box>
              )}
            </Box>
          )}
        </Grid>
        {!isLoadingDataDetails && (
          <>
            {/* //*  */}
            <Grid
              container
              item
              xs={12}
              columns={12}
              columnSpacing={4}
              sx={{ mb: '4px' }}
            >
              <Grid item xs={8}>
                <ChartDetails
                  {...{
                    timeFrame,
                    setTimeFrame,
                    dateRange,
                    setDateRange,
                    saveDateRange,
                    setSaveDateRange,
                  }}
                />
              </Grid>
              <Grid container item xs={4} columns={12} spacing={4}>
                {/* //* cogs */}
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                    COGS
                  </Typography>
                  <Box
                    sx={{
                      mt: '12px',
                      width: '100%',
                      padding: '14px 16px',
                      bgcolor: '#FAFAFA',
                      borderRadius: '8px',
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                      Rp{' '}
                      {!detailsDatas.purchased ? '-' : detailsDatas.purchased}
                    </Typography>
                  </Box>
                </Grid>
                {/* //* rrp */}
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                    RRP
                  </Typography>
                  <Box
                    sx={{
                      mt: '12px',
                      width: '100%',
                      padding: '14px 16px',
                      bgcolor: '#FAFAFA',
                      borderRadius: '8px',
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                      Rp{' '}
                      {!detailsDatas.hargaJual ? '-' : detailsDatas.hargaJual}
                    </Typography>
                  </Box>
                </Grid>
                {/* //* used */}
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                    Used
                  </Typography>
                  <Box
                    sx={{
                      mt: '12px',
                      width: '100%',
                      padding: '14px 16px',
                      bgcolor: '#FAFAFA',
                      borderRadius: '8px',
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                      Rp {!detailsDatas.used ? '-' : detailsDatas.used}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {/* //* Social Media */}
            <Grid item xs={4}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Social Media
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  padding: '14px 0px',
                  borderRadius: '8px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 1,
                }}
              >
                {detailsDatas.socialMedias?.filter(
                  (item) => item.name === 'instagram' || item.name === 'tiktok'
                ).length === 0 && '-'}
                {detailsDatas.socialMedias?.filter(
                  (item) => item.name === 'instagram'
                ).length !== 0 && (
                  <CustomCard
                    sx={{
                      p: '12px 12px 12px 8px',
                      height: '54px',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleToSocialMedia(
                        detailsDatas.socialMedias?.filter(
                          (item) => item.name === 'instagram'
                        )[0].link
                      )
                    }
                  >
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <InstagramLogo />
                      <Typography>
                        @
                        {
                          detailsDatas.socialMedias
                            ?.filter((item) => item.name === 'instagram')[0]
                            .link.split('https://www.instagram.com/')[1]
                            .split('/')[0]
                        }
                      </Typography>
                    </Box>
                  </CustomCard>
                )}
                {detailsDatas.socialMedias?.filter(
                  (item) => item.name === 'tiktok'
                ).length !== 0 && (
                  <CustomCard
                    sx={{
                      p: '12px 12px 12px 8px',
                      height: '54px',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleToSocialMedia(
                        detailsDatas.socialMedias?.filter(
                          (item) => item.name === 'tiktok'
                        )[0].link
                      )
                    }
                  >
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <TiktokLogo />
                      <Typography>
                        @
                        {
                          detailsDatas.socialMedias
                            ?.filter((item) => item.name === 'tiktok')[0]
                            .link.split('https://www.tiktok.com/@')[1]
                            .split('/')[0]
                        }
                      </Typography>
                    </Box>
                  </CustomCard>
                )}
              </Box>
            </Grid>
            {/* //* Photo */}
            <Grid item xs={4}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Photo
              </Typography>
              <Box
                sx={{
                  mt: '12px',
                  width: '100%',
                  padding: '0',
                  display: 'flex',
                }}
              >
                {!detailsDatas.gambar ? (
                  <Box
                    sx={{
                      width: '54px',
                      height: '54px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: '#FAFAFA',
                      borderRadius: '2px',
                    }}
                  >
                    <NoImage />
                    <Typography sx={{ color: '#9E9D9D', fontSize: '6px' }}>
                      No photo
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    {detailsDatas.gambar.split(',').map((item, index) => (
                      <Box onClick={() => handlePreviewPhoto(item)} key={index}>
                        <img
                          src={
                            process.env.REACT_APP_API_IMAGE_BASE_URL +
                            '/' +
                            item
                          }
                          style={{
                            width: '54px',
                            height: '54px',
                            borderRadius: '4px',
                            cursor: 'pointer',
                          }}
                          alt="Pic_dtl"
                        />
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </Grid>
            {/* //* Dialog Photo */}
            {linkPreviewPhoto !== '' && (
              <Dialog open={isPreviewPhoto} onClose={handleClosePreviewPhoto}>
                <Box
                  component="img"
                  src={
                    process.env.REACT_APP_API_IMAGE_BASE_URL +
                    '/' +
                    linkPreviewPhoto
                  }
                  sx={{ width: '600px', height: '600px', aspectRation: '1/1' }}
                />
              </Dialog>
            )}
            {/* //* Video */}
            <Grid item xs={4}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Video
              </Typography>
              <Box
                sx={{
                  mt: '12px',
                  width: '100%',
                  padding: '0',
                  display: 'flex',
                }}
              >
                {!detailsDatas.video ? (
                  <Box
                    sx={{
                      width: '54px',
                      height: '54px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: '#FAFAFA',
                      borderRadius: '2px',
                    }}
                  >
                    <NoImage />
                    <Typography sx={{ color: '#9E9D9D', fontSize: '6px' }}>
                      No photo
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    {detailsDatas.video.split(',').map((input, index) => (
                      <Box
                        onClick={() => handlePreviewVideo(input)}
                        key={index}
                      >
                        <video
                          style={{
                            width: '54px',
                            height: '54px',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            backgroundColor: 'black',
                          }}
                        >
                          <source
                            src={
                              process.env.REACT_APP_API_IMAGE_BASE_URL +
                              '/' +
                              input +
                              '#t=0.5'
                            }
                          />
                        </video>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </Grid>
            {/* //* Dialog Video */}
            {linkPreviewVideo !== '' && (
              <Dialog open={isPreviewVideo} onClose={handleClosePreviewVideo}>
                <Box sx={{ bgcolor: 'black' }}>
                  <video
                    style={{
                      width: '600px',
                      height: '600px',
                      backgroundColor: 'black',
                    }}
                    controls
                  >
                    <source
                      src={
                        process.env.REACT_APP_API_IMAGE_BASE_URL +
                        '/' +
                        linkPreviewVideo
                      }
                    />
                  </video>
                </Box>
              </Dialog>
            )}
            {/* //* Meta Description */}
            <Grid item xs={6}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Meta Description
              </Typography>
              <Box
                sx={{
                  mt: '12px',
                  width: '100%',
                  padding: '14px 16px',
                  bgcolor: '#FAFAFA',
                  borderRadius: '8px',
                }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                  {!detailsDatas.metaDesk ? '-' : detailsDatas.metaDesk}
                </Typography>
              </Box>
            </Grid>
            {/* //* Schedule */}
            <Grid item xs={6}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Schedule
              </Typography>
              <Box
                sx={{
                  mt: '12px',
                  width: '100%',
                  padding: '14px 16px',
                  bgcolor: '#FAFAFA',
                  borderRadius: '8px',
                }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                  {!detailsDatas.schedule
                    ? '-'
                    : dayjs(detailsDatas.schedule).format('DD/MMM/YYYY')}
                </Typography>
              </Box>
            </Grid>
            {/* //* Description */}
            <Grid item xs={12}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Description
              </Typography>
              <Box
                sx={{
                  mt: '12px',
                  width: '100%',
                  padding: '14px 16px',
                  bgcolor: '#FAFAFA',
                  borderRadius: '8px',
                }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                  {!detailsDatas.deskripsi ? (
                    '-'
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: detailsDatas.deskripsi,
                      }}
                    />
                  )}
                </Typography>
              </Box>
            </Grid>
            {/* //* Ingredients */}
            <Grid item xs={12}>
              <Typography
                sx={{ fontSize: '16px', fontWeight: '500', mb: '12px' }}
              >
                Ingredients
              </Typography>
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: '#FAFAFA' }}>
                    <TableCell>No</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell>RRP(Rp)*</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailsDatas?.ingredients?.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{data?.product?.namaProduk}</TableCell>
                      <TableCell>
                        {data?.product?.miniCategory !== null
                          ? data?.product?.miniCategory?.subCategory?.category
                              ?.namaKategori
                          : data?.product?.subCategory !== null
                          ? data?.product?.subCategory?.category?.namaKategori
                          : '-'}
                      </TableCell>
                      <TableCell>{data?.value}</TableCell>
                      <TableCell>1</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            {/* //* Button */}
            <Box
              sx={{
                width: '100%',
                bgcolor: 'white',
                position: 'fixed',
                bottom: '0px',
                right: '0px',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '18px 32px 18px 240px',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                  Publish
                </Typography>
                <AntSwitch
                  checked={statusProduct}
                  onChange={handleEditStatus}
                />
              </Box>
              <Box sx={{ display: 'flex', gap: '20px' }}>
                <CustomButton
                  color="error"
                  variant="contained"
                  sx={{ width: '140px', height: '36px' }}
                  onClick={handleDelete}
                >
                  <Typography
                    sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
                  >
                    Delete
                  </Typography>
                </CustomButton>
                <CustomButton
                  variant="contained"
                  sx={{ width: '140px', height: '36px' }}
                  onClick={handleEdit}
                >
                  <Typography
                    sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
                  >
                    Edit
                  </Typography>
                </CustomButton>
              </Box>
            </Box>
          </>
        )}
      </Grid>
    </>
  );
};
export default MenuMenuDetails;
