import { Box } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { CameraIcon } from '../../../../assets/Icons';

const CameraCapture = (props) => {
  const { onClick, width = 300, height = 300, delay = 1000 } = props;

  const webcamRef = useRef(null);
  const videoConstraints = {
    width: width,
    height: height,
    facingMode: 'environment',
  };

  const capturePhoto = useCallback(async () => {
    const imgSrc = webcamRef.current.getScreenshot();
    onClick(imgSrc);
  }, [webcamRef]);

  return (
    <Box>
      <Webcam
        ref={webcamRef}
        audio={false}
        screenshotFormat="image/png"
        videoConstraints={videoConstraints}
        mirrored={true}
      />

      <Box
        sx={{
          position: 'fixed',
          justifyContent: 'center',
          alignItems: 'center',
          bottom: 20,
          left: 0,
          width: '100%',
          display: 'flex',
        }}
      >
        <Box
          sx={{
            width: '42px',
            height: '42px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '16px',
            bgcolor: 'white',
          }}
          onClick={capturePhoto}
        >
          <CameraIcon />
        </Box>
      </Box>
    </Box>
  );
};
export default CameraCapture;
