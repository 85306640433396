import { createSlice } from '@reduxjs/toolkit';
import { checkInvoice } from '../../api/stockout';

export const stockOutState = createSlice({
  name: 'stockout',
  initialState: {
    dataStockout: [],
    invoice: [],
    search: null,
    idOrder: null,
    isLoading: false,
    hubType: '',
    selectedHub: null,
    openConfirmnExecute: false,
  },
  reducers: {
    changeStockout: (state, action) => {
      state.dataStockout = action.payload;
    },
    loadInvoice: (state, action) => {
      state.invoice = action.payload;
    },
    setSearchStockout: (state, action) => {
      state.search = action.payload;
    },
    changeIdOrder: (state, action) => {
      state.idOrder = action.payload;
    },
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    changeHubType: (state, action) => {
      state.hubType = action.payload;
    },
    changeSelectedHub: (state, action) => {
      state.selectedHub = action.payload;
    },
    changeConfirmExecution: (state, action) => {
      state.openConfirmnExecute = action.payload;
    },
    clearStockOut: (state) => {
      state.dataStockout = [];
      state.invoice = [];
      state.search = null;
      state.idOrder = null;
      state.isLoading = false;
      state.hubType = '';
      state.selectedHub = null;
      state.openConfirmnExecute = false;
    },
  },
});

export const {
  changeStockout,
  setSearchStockout,
  changeIdOrder,
  changeIsLoading,
  loadInvoice,
  changeHubType,
  changeSelectedHub,
  changeConfirmExecution,
  clearStockOut,
} = stockOutState.actions;

export const getAllDataStockOut = (state) => state.stockOut;
export const getStockout = (state) => state.stockOut.dataStockout;
export const getSearchStockout = (state) => state.stockOut.search;

export default stockOutState.reducer;
//! ---------------- API ----------------

export const fetchAllDataInvoice = (search) => (dispatch) => {
  const checkInvoiceStockout = async () => {
    // if (search !== null) {
    dispatch(changeIsLoading(true));
    try {
      const {
        data: { data },
      } = await checkInvoice(search);
      dispatch(loadInvoice(data));
      dispatch(changeIsLoading(false));
    } catch {
      dispatch(loadInvoice([]));
    } finally {
      dispatch(changeIsLoading(false));
      // }
    }
  };

  checkInvoiceStockout();
};
