import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import BackButtonHub from '../../../../../../../../components/shared/BackButtonHub';
import { useDispatch, useSelector } from 'react-redux';
import { changeIsEdit } from '../../../../../../../../store/purchase/purchaseAdmin/detailRequestPurchase';
import {
  fetchSubsidiaryList,
  getSubsidiaryList,
} from '../../../../../../../../store/subsidiary/subsidiary';
import {
  changeDescription,
  changeMasterHubId,
  changeMiniHubId,
  changeSubHubId,
  changeSubsidiaryId,
  getDescriptionEditPurchase,
  getMasterHubId,
  getMiniHubId,
  getSubHubId,
  getSubsidiaryIdEditPurchase,
} from '../../../../../../../../store/purchase/purchaseAdmin/editPurchaseRequest';
import { getDataEditPurchaseRequest } from '../../../../../../../../store/purchase/purchaseAdmin/editPurchaseRequest';
import { getAllFixHubs } from '../../../../../../../../store/purchase/purchaseAdmin/editPurchaseRequest';
import { useEffect, useState } from 'react';
import {
  AddIcon,
  ChevronRightIcon,
  CloseIcon,
  CloseIcons,
  KeyboardArrowDownIcon,
} from '../../../../../../../../assets/Icons';
import CustomCard from '../../../../../../../../components/shared/CustomCard';
import SelectHubEditPurchaseMobile from './SelectHubEditPurchaseMobile';
import CustomInput from '../../../../../../../../components/shared/CustomInput';
import CustomButton from '../../../../../../../../components/shared/CustomButton';
import { updatePurchaseRequest } from '../../../../../../../../api/purchase';
import {
  changePage,
  setNotifPurchase,
} from '../../../../../../../../store/Routing/purchaseRouting';
import QtySelect from '../../../Request/Add/component/QtySelect';
import ProductSelect from '../../../Request/Add/component/ProductSelect';

const PurchaseRequestEditMobile = () => {
  const dispatch = useDispatch();
  const subsidiaryList = useSelector(getSubsidiaryList);
  const subsidiaryId = useSelector(getSubsidiaryIdEditPurchase);
  const datas = useSelector(getDataEditPurchaseRequest);
  const dataHubs = useSelector(getAllFixHubs);
  const purchaseDescription = useSelector(getDescriptionEditPurchase);
  const idPurchase = datas?.iDpurchase;
  const idToko = useSelector(getMasterHubId);
  const subHubId = useSelector(getSubHubId);
  const miniHubId = useSelector(getMiniHubId);
  const [isSelectHub, setIsSelectHub] = useState(false);

  //! -----------------------ITEMS -------------------
  const [isDeleteIngredientRow, setIsDeleteIngredientRow] = useState(false);
  const [tambahItem, setTambahItem] = useState([]);

  const handleAddItemCardIngredient = () => {
    setTambahItem([
      ...tambahItem,
      {
        product: { idProduk: 0, namaProduk: '' },
        // idProduk: 0,
        qty: 0,
        unit: '',
      },
    ]);
  };

  useEffect(() => {
    dispatch(fetchSubsidiaryList());
    dispatch(changeSubsidiaryId(datas?.subsidiaryId));
    dispatch(changeDescription(datas?.keterangan));
    setTambahItem(datas?.details);
    dispatch(changeMasterHubId(datas?.idToko));
    dispatch(changeSubHubId(datas?.subHubId));
    dispatch(changeMiniHubId(datas?.miniHubId));
  }, [datas?.subsidiaryId, datas?.keterangan, datas?.details]);

  //! handle save
  const [loadingSave, setLoadingSave] = useState(false);
  const handleSave = () => {
    let datas = {
      status: 'request',
      subsidiaryId: subsidiaryId,
      idToko: idToko,
      keterangan: purchaseDescription,
      subHubId: subHubId,
      miniHubId: miniHubId,
      products: tambahItem,
    };

    const updatePurchase = async () => {
      setLoadingSave(true);
      try {
        await updatePurchaseRequest(idPurchase, datas);
        dispatch(
          setNotifPurchase({
            key: 'editRequest',
            text: 'purchase hass been edited',
          })
        );
        setLoadingSave(false);
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingSave(false);
      }
    };

    updatePurchase();
    dispatch(changeIsEdit(false));
    dispatch(changePage('request'));
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <BackButtonHub
          onClick={() => {
            dispatch(changeIsEdit(false));
          }}
        />
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          Edit purchase request
        </Typography>
      </Box>

      <Grid container columns={12} spacing={2} sx={{ mt: 2 }}>
        {/* //* Subsidiary */}
        <Grid item xs={12}>
          {subsidiaryList.length !== 0 && (
            <FormControl fullWidth>
              <InputLabel id="subsidiary">Subsidiary</InputLabel>
              <Select
                labelId=""
                value={subsidiaryId}
                label="Subsidiary"
                onChange={(e) => {
                  dispatch(changeSubsidiaryId(e.target.value));
                  setIsSelectHub(true);
                }}
              >
                {subsidiaryList?.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>

        {/* hub */}
        <Grid item xs={12} sx={{ mt: 3 }}>
          <Box>
            <Typography
              sx={{
                ml: 2,
                mb: '2px',
                fontSize: '12px',
                fontWeight: 500,
              }}
            >
              Hub*
            </Typography>
            <CustomCard
              sx={{ padding: '14px 14px', cursor: 'pointer' }}
              onClick={() => setIsSelectHub(true)}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    color: '#9E9D9D',
                    mt: '4px',
                  }}
                >
                  {dataHubs.length === 0 ? (
                    <Box
                      sx={{
                        display: 'flex',
                        color: 'black',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <Typography sx={{ fontSize: '12px' }}>
                        {datas?.hub?.namaToko}
                      </Typography>
                      <ChevronRightIcon sx={{ fontSize: '12px' }} />
                      {datas?.subHub !== null ? (
                        <>
                          <ChevronRightIcon sx={{ fontSize: '12px' }} />
                          <Typography sx={{ fontSize: '12px' }}>
                            {datas?.subHub?.name}
                          </Typography>
                        </>
                      ) : (
                        '_'
                      )}
                      {datas?.miniHub !== null ? (
                        <>
                          <ChevronRightIcon sx={{ fontSize: '12px' }} />
                          <Typography sx={{ fontSize: '12px' }}>
                            {datas?.miniHub?.name}
                          </Typography>
                        </>
                      ) : (
                        '_'
                      )}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        color: 'black',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <Typography sx={{ fontSize: '14px' }}>
                        {dataHubs[0].namaToko}
                      </Typography>
                      {dataHubs[1] !== null ? (
                        <>
                          <ChevronRightIcon sx={{ fontSize: '14px' }} />
                          <Typography sx={{ fontSize: '14px' }}>
                            {dataHubs[1].name}
                          </Typography>
                        </>
                      ) : (
                        '_'
                      )}
                      {dataHubs[2] !== null ? (
                        <>
                          <ChevronRightIcon sx={{ fontSize: '14px' }} />
                          <Typography sx={{ fontSize: '14px' }}>
                            {dataHubs[2].name}
                          </Typography>
                        </>
                      ) : (
                        '_'
                      )}
                    </Box>
                  )}
                </Box>

                <KeyboardArrowDownIcon />
              </Box>
            </CustomCard>
          </Box>

          {/* dialog hub */}
          <Dialog
            // maxWidth
            fullWidth
            // maxWidth="lg"
            open={isSelectHub}
            onClose={() => {
              setIsSelectHub(false);
            }}
          >
            <DialogTitle
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box>Select Hub</Box>
              <CloseIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => setIsSelectHub(false)}
              />
            </DialogTitle>
            <DialogContent dividers>
              <SelectHubEditPurchaseMobile setIsSelectHub={setIsSelectHub} />
            </DialogContent>
          </Dialog>
        </Grid>

        {/* name */}
        <Grid item xs={12} sx={{ mt: 3 }}>
          <Box>
            <Typography
              sx={{
                ml: 2,
                mb: '2px',
                fontSize: '12px',
                fontWeight: 500,
              }}
            >
              Description
            </Typography>
            <CustomInput
              fullWidth
              sx={{
                with: '100%',
                bgcolor: '#fafafa',
                '& .MuiOutlinedInput-input': {
                  padding: '17px 16px',
                  borderRadius: '10px',
                  '&::placeholder': {
                    color: '#111',
                    fontSize: '14px',
                  },
                },
                borderRadius: '5px',
              }}
              placeholder="enter description"
              value={purchaseDescription}
              onChange={(e) => {
                if (e.target.value.length <= 250) {
                  dispatch(changeDescription(e.target.value));
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  mt: '5px',
                  ml: '15px',
                  color: '#9E9D9D',
                }}
              >
                {purchaseDescription.length >= 20
                  ? ''
                  : 'Enter at least 20 characters'}
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  mt: '5px',
                  ml: '15px',
                  color: '#9E9D9D',
                }}
              >
                {purchaseDescription.length}/250
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* items */}
      <Grid item xs={12} sx={{ mt: 2 }}>
        <CustomCard>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: '20px',
              boxShadow: '0px 1px 5px #ccc',
            }}
          >
            <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
              Item(s)
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                color: '#51B15C',
                gap: 1,
                cursor: 'pointer',
              }}
              onClick={handleAddItemCardIngredient}
            >
              <AddIcon />
              Add more
            </Typography>
          </Box>

          <Box>
            <Grid container columns={12} spacing={2} sx={{ p: '15px 10px' }}>
              {tambahItem.map((item, index) => (
                <Grid item xs={12} sx={{ display: 'flex' }}>
                  <Grid container columns={12} gap={2}>
                    {/* product */}
                    <Grid item xs={5}>
                      <Box>
                        <Typography
                          sx={{
                            ml: 2,
                            mb: '2px',
                            fontSize: '12px',
                            fontWeight: 500,
                          }}
                        >
                          Product*
                        </Typography>
                        <ProductSelect
                          firstValueId={item?.product?.idProduk}
                          firstValue={item?.product?.namaProduk}
                          isReset={isDeleteIngredientRow}
                          setIsReset={setIsDeleteIngredientRow}
                          handleValue={(name, id) => {
                            const memo = tambahItem.map((item, idx) => {
                              if (index === idx) {
                                return {
                                  ...item,
                                  idProduk: id,
                                  // namaProduk: name,
                                  product: { idProduk: id, namaProduk: name },
                                };
                              } else {
                                return item;
                              }
                            });
                            setTambahItem(memo);
                          }}
                        />
                      </Box>
                    </Grid>

                    {/* qty */}
                    <Grid item xs={5}>
                      <Box>
                        <Typography
                          sx={{
                            ml: 2,
                            mb: '2px',
                            fontSize: '12px',
                            fontWeight: 500,
                          }}
                        >
                          Qty*
                        </Typography>
                        <QtySelect
                          firstValueQty={item.qty}
                          firstValueUnit={item.unit}
                          isReset={isDeleteIngredientRow}
                          setIsReset={setIsDeleteIngredientRow}
                          handleValue={(qty, unit) => {
                            const memo = tambahItem.map((item, idx) => {
                              if (index === idx) {
                                return {
                                  ...item,
                                  qty: qty,
                                  unit: unit,
                                };
                              } else {
                                return item;
                              }
                            });
                            setTambahItem(memo);
                          }}
                        />
                      </Box>
                    </Grid>

                    {/* hapus item */}
                    <Grid
                      item
                      xs="auto"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'red',
                      }}
                    >
                      {tambahItem.length === 1 ? (
                        <></>
                      ) : (
                        <CloseIcons
                          sx={{
                            cursor: 'pointer',
                            width: '20px',
                          }}
                          onClick={() => {
                            setTambahItem(
                              tambahItem.filter((_, idx) => idx !== index)
                            );
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </CustomCard>
      </Grid>

      {/* button event */}
      <Box
        sx={{
          width: '100%',
          bgcolor: 'white',
          position: 'fixed',
          bottom: '0px',
          right: '0px',
          padding: '20px ',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
          <CustomButton
            variant="contained"
            color="error"
            sx={{ width: '100%', height: '36px' }}

            // onClick={handleDelete}
          >
            <Typography
              sx={{
                color: 'white',
                fontSize: '14px',
                fontWeight: 600,
              }}
            >
              Delete
            </Typography>
          </CustomButton>
          <CustomButton
            variant="contained"
            sx={{ width: '100%', height: '36px' }}
            onClick={() => handleSave()}
          >
            <Typography
              sx={{
                color: 'white',
                fontSize: '14px',
                fontWeight: 600,
              }}
            >
              Save
            </Typography>
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};
export default PurchaseRequestEditMobile;
