import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import BackButtonHub from "../../../../../../../components/shared/BackButtonHub";
import {
  ChecklistIcon,
  ChevronRightIcon,
  CloseIcon,
} from "../../../../../../../assets/Icons";
import { useDispatch, useSelector } from "react-redux";
import { changePageStockOut } from "../../../../../../../store/Routing/stockOutRouting";
import CustomButton from "../../../../../../../components/shared/CustomButton";
import {
  fetchSubsidiaryList,
  getSubsidiaryList,
} from "../../../../../../../store/subsidiary/subsidiary";
import { useEffect } from "react";
import CustomCard from "../../../../../../../components/shared/CustomCard";
import {
  changeConfirmExecution,
  changeHubType,
  changeIdOrder,
  changeSelectedHub,
  clearStockOut,
  getAllDataStockOut,
} from "../../../../../../../store/stockout/stockout";
import {
  executeStockOut,
  getHubsStockOut,
} from "../../../../../../../api/stockout";
import styled from "@emotion/styled";
import { allProductName } from "../../../../../../../api/menu";
import { useRef } from "react";
import debounce from "lodash.debounce";

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 100%;
  min-width: 200px;
  min-height:40px;
  font-size: 12px;
  font-weight: 400;
  padding: 12px;
  border-radius: 12px 12px 0px 12px;
 
`
);

const HubInvoice = ({ openHub, setOpenHub, execute, setExecute }) => {
  const dispatch = useDispatch();

  const { search, idOrder, openConfirmnExecute, hubType } =
    useSelector(getAllDataStockOut);

  const listSubsidiary = useSelector(getSubsidiaryList);
  const [newSubsidiary, setNewSubsidiary] = useState(null);
  const [selectedTypeHub, setSelectedTypeHub] = useState("");
  const [openAddHub, setOpenAddHub] = useState(false);
  const [hubDatas, setHubDatas] = useState([]);
  const [selectedHub, setSelectedHub] = useState(null);
  const [inputDebounce, setInputDebounce] = useState("");

  const [datas, setDatas] = useState([]);

  const [isLoadingProduct, setIsLoadingProduct] = useState(false);

  //  exetuion
  const [textareaValue, setTextareaValue] = useState("");
  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  const handleChange = (event) => {
    setSelectedTypeHub(event.target.value);
  };

  useEffect(() => {
    dispatch(fetchSubsidiaryList());
  }, []);

  const fetchHubs = async () => {
    const idOrders = execute.map((item) => item?.id);
    const params = {
      id_order: idOrders,
      hub_type: selectedTypeHub,
      subsidiary: newSubsidiary?.id,
    };

    try {
      const {
        data: { data: data },
      } = await getHubsStockOut(search, params);
      if (data?.statusCode !== 500) {
        setHubDatas(data);
      } else {
      }
    } catch (err) {
      console.error(err);
    }
  };

  const inputDebounceChange = useRef(
    debounce((value) => {
      setInputDebounce(value);
    }, 1000)
  ).current;

  // ! api fetch product
  useEffect(() => {
    const getProductName = async () => {
      setIsLoadingProduct(true);
      let datas = {
        page: 1,
        pageSize: 100,
        search: inputDebounce,
      };

      try {
        const {
          data: { data: dataNames },
        } = await allProductName(datas);
        setDatas(dataNames.products);
      } catch (err) {
        console.error(err);
        setDatas([]);
      } finally {
        setIsLoadingProduct(true);
      }
    };

    getProductName();
  }, [inputDebounce]);

  useEffect(() => {
    if (selectedTypeHub !== "") {
      fetchHubs();
    } else {
    }
  }, [selectedTypeHub]);

  useEffect(() => {
    if (openAddHub === false) {
      setSelectedTypeHub("");
    }
  }, [openAddHub]);

  const handleEnter = () => {
    const idOrders = execute.map((item) => item?.id);
    dispatch(changeIdOrder(idOrders));
    dispatch(changeHubType(selectedTypeHub));
    dispatch(changeSelectedHub(selectedHub?.id));
    // setOpenHub(false);
    setOpenAddHub(false);
    setExecute([]);
    // dispatch(changeConfirmExecution(true));
  };

  const handleExecution = async () => {
    const params = {
      id_order: idOrder,
      hub_id: selectedHub?.id,
      hub_type: hubType,
      keterangan: textareaValue,
    };

    try {
      const data = await executeStockOut(search, params);
      dispatch(clearStockOut());
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box>
      <Box sx={{ position: "relative", minHeight: "66vh" }}>
        <Grid container columns={12} spacing={2}>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <BackButtonHub
                onClick={() => {
                  // dispatch(changePageStockOut(""));
                  setExecute([]);
                  setOpenHub(!openHub);
                }}
              />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ color: "#9E9D9D", fontSize: "14px" }}>
                  Stock Out
                </Typography>
                <ChevronRightIcon sx={{ color: "#9E9D9D", fontSize: "16px" }} />
                <Typography
                  sx={{ color: "#000", fontSize: "14px", fontWeight: 600 }}
                >
                  Execute
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={newSubsidiary}
              options={listSubsidiary}
              getOptionLabel={(option) => option.name}
              onChange={(_, newValue) => setNewSubsidiary(newValue)}
              sx={{ bgcolor: "#FAFAFA" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Subsidiary"
                  sx={{ color: "#9E9E9E" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomCard
              sx={{ padding: "10px", cursor: "pointer" }}
              onClick={() =>
                newSubsidiary !== null && setOpenAddHub(!openAddHub)
              }
            >
              <Box sx={{ display: "flex", mb: 1 }}>
                <Typography sx={{ fontSize: "16px" }}>Hub</Typography>
                <Typography sx={{ color: "#F33A3A" }}>*</Typography>
              </Box>
              <Typography sx={{ fontSize: "14px" }}>Hub name</Typography>
            </CustomCard>
          </Grid>
        </Grid>

        <Dialog open={openAddHub} onClose={() => setOpenAddHub(!openAddHub)}>
          <DialogTitle sx={{ boxShadow: "0px 0px 3px 0px #000" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Typography sx={{ fontWeight: 600 }}>Hub</Typography>
              </Box>
              <CloseIcon onClick={() => setOpenAddHub(!openAddHub)} />
            </Box>
          </DialogTitle>
          <DialogContent>
            <Grid container columns={12}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontSize: "16px", color: "#000" }}>
                    Type
                  </Typography>
                  <Typography sx={{ color: "#F33A3A" }}>*</Typography>
                </Box>
                <Box>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={selectedTypeHub}
                      onChange={handleChange}
                    >
                      <Box sx={{ display: "flex" }}>
                        <FormControlLabel
                          value="master_hub"
                          control={<Radio />}
                          label="Master Hub"
                        />
                        <FormControlLabel
                          value="sub_hub"
                          control={<Radio />}
                          label="Sub Hub"
                        />
                        <FormControlLabel
                          value="mini_hub"
                          control={<Radio />}
                          label="Mini Hub"
                        />
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Typography sx={{ fontSize: "15px", fontWeight: 600, mb: 2 }}>
                  Hub
                </Typography>
                <Autocomplete
                  sx={{ minWidth: "100%", bgcolor: "#FAFAFA" }}
                  options={hubDatas}
                  getOptionLabel={(option) => option.hubName}
                  value={selectedHub}
                  onChange={(event, newValue) => {
                    setSelectedHub(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Product*" />
                  )}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                gap: 2,
                mt: 5,
              }}
            >
              <CustomButton
                variant={"contained"}
                style={{ height: "40px", width: "100px" }}
                onClick={handleEnter}
              >
                {/* {!isLoadingProduct ? (
                <CircularProgress size={18} sx={{ color: '#FFF' }} />
              ) : ( */}
                <Typography
                  fontStyle={"normal"}
                  fontWeight={500}
                  fontSize={"14px"}
                  lineHeight={"150%"}
                  color={"#fff"}
                >
                  Enter
                </Typography>
                {/* )} */}
              </CustomButton>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>

      <CustomButton
        variant={"contained"}
        style={{
          width: "100%",
          bottom: 0,
          padding: "8px 0px",
        }}
        onClick={() => {
          dispatch(changeConfirmExecution(!openConfirmnExecute));
        }}
        fullWidth
      >
        <Typography
          fontStyle={"normal"}
          fontWeight={600}
          fontSize={"14px"}
          lineHeight={"150%"}
          color={"#fff"}
        >
          Confirm
        </Typography>
      </CustomButton>

      {/* dialog execute */}
      <Dialog
        open={openConfirmnExecute}
        onClose={() => dispatch(changeConfirmExecution(!openConfirmnExecute))}
      >
        <DialogTitle sx={{ boxShadow: "0px 0px 3px 0px #000" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "100px",
                  background: "rgba(81, 177, 92, 0.1)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ChecklistIcon />
              </Box>
              <Typography sx={{ fontWeight: 600 }}>
                Confirm Execution
              </Typography>
            </Box>
            <CloseIcon
              onClick={() =>
                dispatch(changeConfirmExecution(!openConfirmnExecute))
              }
              sx={{ cursor: "pointer" }}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontSize: "12px", fontWeight: 400, mt: 3, mb: 2 }}>
            Are you sure want to execute the item(s) ?
          </Typography>
          <StyledTextarea
            minRows={3}
            placeholder="Enter Notes"
            value={textareaValue}
            onChange={handleTextareaChange}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: 2,
              mt: 5,
            }}
          >
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => dispatch(changeConfirmExecution(false))}
            >
              <Typography sx={{ fontSize: "16px", color: "#9E9D9D" }}>
                Cancel
              </Typography>
            </Box>
            <CustomButton
              variant={"contained"}
              style={{ height: "40px", width: "100px" }}
              onClick={handleExecution}
            >
              {!isLoadingProduct ? (
                <CircularProgress size={18} sx={{ color: "#FFF" }} />
              ) : (
                <Typography
                  fontStyle={"normal"}
                  fontWeight={500}
                  fontSize={"14px"}
                  lineHeight={"150%"}
                  color={"#fff"}
                >
                  Confirm
                </Typography>
              )}
            </CustomButton>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default HubInvoice;
