import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CameraCapture from '../../../../../../components/shared/shared2/CameraCapture';
import BackButtonPurchaseProcessScanSupplier from './BackButtonPurchaseProcessScanSupplier';
import {
  CameraIcon,
  CloseIcon,
  ScanTargetIcon,
} from '../../../../../../assets/Icons';
import useWindowDimensions from '../../../../../../hooks/useWindowDimensions';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllSupplierScan,
  loadNoqrSupplier,
} from '../../../../../../store/purchase/purchaseSupplier/purchaseSubmitSupplier';
import { changePage } from '../../../../../../store/Routing/purchaseRouting';
import CustomButton from '../../../../../../components/shared/CustomButton';

const CaptureImagaProductSupplier = () => {
  const dispatch = useDispatch();
  const { width: widthScreen } = useWindowDimensions();
  const { noqrDataSelect, noqrid } = useSelector(getAllSupplierScan);
  const [openPrevirew, setOpenPreview] = useState(false);
  const [url, setUrl] = useState(null);

  // useEffect(() => {
  //   url !== null && dispatch(loadNoqrSupplier({ noqrDataSelect, url }));
  // }, [url]);

  // useEffect(() => {
  //   status === true && changePage('purchaseProcessDetailSupplierSubmit');
  // }, [status]);

  const handleCapture = (value) => {
    // console.log({ value });
    if (value) {
      setUrl(value);
    }
  };

  useEffect(() => {
    url !== null ? setOpenPreview(true) : setOpenPreview(false);
  }, [url]);

  const handleOk = () => {
    dispatch(loadNoqrSupplier({ noqrDataSelect, url }));
    dispatch(changePage('purchaseProcessDetailSupplierSubmit'));
  };

  return (
    <Box>
      <BackButtonPurchaseProcessScanSupplier text={'Photo'} />
      <Box
        sx={{
          position: 'absolute',
          minHeight: '80vh',
          width: '100%',
          left: 0,
          bgcolor: 'black',
        }}
      >
        <Box sx={{ width: '100%', position: 'realtive' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 5,
              width: widthScreen,
              height: '600px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ScanTargetIcon />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 5,
              width: widthScreen,
              height: '600px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '315px',
                height: '370px',
                borderRadius: '25px',
                bgcolor: 'white',
                opacity: 0.2,
              }}
            ></Box>
          </Box>
          <CameraCapture
            width={widthScreen}
            height={600}
            delay={1000}
            onClick={(value) => handleCapture(value)}
          />
        </Box>
      </Box>

      <Dialog open={openPrevirew}>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography>Images</Typography>
            <CloseIcon
              onClick={() => {
                setOpenPreview(false);
                setUrl(null);
                // dispatch(setUrl(null));
              }}
            />
          </Box>
        </DialogTitle>
        <DialogContent sx={{ padding: '12px' }}>
          {url && (
            <Box
              component="img"
              src={url}
              sx={{ width: '300px', height: '300px', borderRadius: '8px' }}
            />
          )}

          <CustomButton
            variant="contained"
            sx={{ width: '100%' }}
            onClick={handleOk}
          >
            Ok
          </CustomButton>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default CaptureImagaProductSupplier;
