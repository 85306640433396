import { Grid } from '@mui/material';
import { useEffect } from 'react';
import SubsidiaryChoose from '../../../../../../components/shared/SubsidiaryChoose';
import AllMenus from '../Components/AllMenus';
import MenuPerformance from '../Components/performance/MenuPerformance';
import CategoryMenuMobile from './CategoryMenuMobile';
import TableCategoryMenu from './Desktop/TableCategoryMenu';

const CategoryMenuDesktop = (props) => {
  const {
    // *---------
    dummyDataTable,
    //*----- grafik ----
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    // *----- Pages --------
    setPage,
    // *----- Table --------
    setCategoryMenu,
    tableRowPerPage,
    setTableRowPerPage,
    tableRowPerPageValues,
    tableCurrentPage,
    setTableCurrentPage,
    tableTotalItems,
    setTableTotalItems,
    tableSearch,
    setTableSearch,
    sortTable,
    setSortTable,
    isSortAsc,
    setIsSortAsc,
    tableDatas,
    setTableDatas,
    tableCategoryFilterDatas,
    setTableCategoryFilterDatas,
    isLoadingData,
    // *--- Subsidiary
    subsidiaryDatas,
    subsidiaryFilterValue,
    setSubsidiaryFilterValue,
    autoCompleteFilter,
    setAutoCompleteFilter,
    handelSaveFilter,
    hadleResetSubsidiary,
    isOpenTableFilter,
    setIsOpenTableFilter,
    // *----- Hnadler --------
    handleAddButton,
    handleFilterButton,
    handleClickRow,
    handleClickName,
    // *---- Subsidiary
    subsidiaryAll,
    setSubsidiaryAll,
    subsidiaryMoment,
    setSubsidiaryMoment,
  } = props;
  return (
    <Grid container columns={12} spacing={2}>
      {/* //* Subsidiary */}
      <Grid item xs={10}>
        <SubsidiaryChoose value={subsidiaryAll} setValue={setSubsidiaryAll} />
      </Grid>
      <Grid item xs={2}>
        <AllMenus {...{ setPage }} />
      </Grid>
      {/* //* Performance */}
      {/* <Grid item xs={12}>
        <MenuPerformance
          {...{
            timeFrame,
            setTimeFrame,
            dateRange,
            setDateRange,
            saveDateRange,
            setSaveDateRange,
          }}
        />
      </Grid> */}
      <Grid item xs={12}>
        <TableCategoryMenu {...{ ...props }} />
      </Grid>
    </Grid>
  );
};
export default CategoryMenuDesktop;
