import { createSlice } from '@reduxjs/toolkit';

export const editAdjustmentState = createSlice({
  name: 'editAdjustment',
  initialState: {
    isEdit: false,
    idAdjustment: null,
    datas: [],

    dataProduct: [],
    location: '',
    type: '',
    notes: '',
  },
  reducers: {
    changeIsEditAdjustment: (state, action) => {
      state.isEdit = action.payload;
    },
    changeIdAdjustment: (state, action) => {
      state.idAdjustment = action.payload;
    },

    goToAdjustmentEdit: (state, action) => {
      state.datas = action.payload;
    },
    goToEditAdjustment: (state, action) => {
      state.dataProduct = action.payload;
    },

    addNewItemAdjustment: (state, action) => {
      state.dataProduct = [...state.dataProduct, action.payload];
    },

    changeEditlocationAdjustment: (state, action) => {
      state.location = action.payload;
    },
    changeEditNotesAdjustment: (state, action) => {
      state.notes = action.payload;
    },
    changeEditTypeAdjustment: (state, action) => {
      state.type = action.payload;
    },
  },
});

export const {
  changeIsEditAdjustment,
  changeIdAdjustment,
  addNewItemAdjustment,
  goToAdjustmentEdit,

  goToEditAdjustment,
  changeEditlocationAdjustment,
  changeEditNotesAdjustment,
  changeEditTypeAdjustment,
} = editAdjustmentState.actions;

export const getIsEditAdjustment = (state) => state.editAdjustment.isEdit;
export const getIdEditAdjustment = (state) => state.editAdjustment.idAdjustment;
export const getDataEditAdjustment = (state) => state.editAdjustment.datas;

export const getDataToEditAdjustment = (state) =>
  state.editAdjustment.dataProduct;
export const getLocationEditAdjustment = (state) =>
  state.editAdjustment.location;
export const getNotesEditAdjustment = (state) => state.editAdjustment.notes;
export const getTypeEditAdjustment = (state) => state.editAdjustment.type;
export default editAdjustmentState.reducer;
