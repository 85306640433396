import { createSlice } from '@reduxjs/toolkit';
import { getPic } from '../../api/generalAPI';

export const picState = createSlice({
  name: 'pic',
  initialState: {
    picList: [],
    isLoading: false,
  },
  reducers: {
    loadPicList: (state, action) => {
      state.picList = action.payload;
    },
    changeLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { loadPicList, changeLoading } = picState.actions;
export const getPicList = (state) => state.pic.picList;
export const getPicLoading = (state) => state.pic.isLoading;

// !------------------------ PIC ------------------------------
export const fetchPic = (searching) => (dispatch) => {
  const fetchApi = async () => {
    dispatch(changeLoading(true));
    try {
      const {
        data: {
          data: { users },
        },
      } = await getPic({
        search: searching,
        page: 1,
        pageSize: 8,
      });
      dispatch(loadPicList(users));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeLoading(false));
    }
  };
  fetchApi();
};

export default picState.reducer;
