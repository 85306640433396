import axios from "axios";
import { alereAxios } from "../utils/api";

//Master Hub

export const getAllHubs = (params) =>
  alereAxios.get("hubs", {
    params,
  });

export const getDetailHubs = (hubsId) => alereAxios.get(`hubs/${hubsId}`);

//Sub Hub
export const getAllSubHub = (params) =>
  alereAxios.get("sub-hubs", {
    params,
  });

// get rack map sub hub
export const getRackSubHub = (id) =>
  alereAxios.get("hubs/racks", { params: { id, resource: "sub_hub" } });
export const getRackSubHubPreview = (id) =>
  alereAxios.get("hubs/racks", {
    params: {
      id,
      resource: "sub_hub",
      previewRack: "y",
      pageSize: 100,
      page: 1,
    },
  });

//Detail SubHub
export const getDetailSubHub = (subHubId) =>
  alereAxios.get(`sub-hubs/${subHubId}`);

//edit master hub
// export const editMasterHub = (data, masterHubId) =>
//   alereAxios.post(`hubs/${masterHubId}`, data);

//delete master hub
// export const deleteMasterHub = (masterHubId) =>
//   alereAxios.delete(`hubs/${masterHubId}`);

//edit subhub
export const editSubHub = (subHubId, data) =>
  alereAxios.put(`sub-hubs/${subHubId}`, data);

// edit status sub hub
export const editStatusSubHub = (subHubId, data) =>
  alereAxios.put(`sub-hubs/${subHubId}`, data);

//delete sub hub
export const deleteSubHub = (subHubId) =>
  alereAxios.delete(`sub-hubs/${subHubId}`);

//add sub hub
export const addNewSubHub = (data) => alereAxios.post("sub-hubs", data);
export const addNewSubHubData = (data) =>
  alereAxios.post("sub-hubs/hub_coordinate", data);

//mini hub
export const getAllMiniHub = (params) =>
  alereAxios.get(`mini-hubs`, { params });

//add new mini hub
export const addNewMiniHub = (data) => alereAxios.post("mini-hubs", data);

//detail mini hub
export const detailMiniHub = (miniHubId) =>
  alereAxios.get(`mini-hubs/${miniHubId}`);

//edit mini hub
export const editMiniHub = (miniHubId, data) =>
  alereAxios.put(`mini-hubs/${miniHubId}`, data);

export const editStatusMiniHub = (miniHubId, data) =>
  alereAxios.put(`mini-hubs/${miniHubId}`, data);

//delete mini hub
export const deleteMiniHub = (miniHubId) =>
  alereAxios.delete(`mini-hubs/${miniHubId}`);

export const getRackMiniHub = (id) =>
  alereAxios.get("hubs/racks", { params: { id, resource: "mini_hub" } });
export const getRackMiniHubPreview = (id) =>
  alereAxios.get("hubs/racks", {
    params: {
      id,
      resource: "mini_hub",
      previewRack: "y",
      pageSize: 100,
      page: 1,
    },
  });

// *----- Rack Master -----
export const addNewRackMasterHub = (data) =>
  alereAxios.post("hubs/racks", data);

export const getRackMasterHub = (id) =>
  alereAxios.get("hubs/racks", { params: { id, resource: "hub" } });

export const getRackMasterHubPreview = (id) =>
  alereAxios.get("hubs/racks", {
    params: { id, resource: "hub", previewRack: "y", pageSize: 100, page: 1 },
  });
export const deleteRackMaster = (id) => alereAxios.delete(`hubs/racks/${id}`);

// *-------- Master Hub --------------

//? --add new master hub --
export const addNewMasterHub = (data) =>
  alereAxios.post("hubs/hub_coordinate", data);

export const addNewMultipleRack = (data) =>
  alereAxios.post("hubs/racks/store-multiple", data);

// ? -- Update master hub
export const editMasterHub = (masterHubId, data) =>
  alereAxios.put(`hubs/${masterHubId}`, data);
export const editMasterHubStatus = (masterHubId, data) =>
  alereAxios.put(`hubs/${masterHubId}`, { status: data });

// ? -- Delete master hub
export const deleteMasterHub = (masterHubId) =>
  alereAxios.delete(`hubs/${masterHubId}`);

// *-------- Addres Master Hub --------------

// * FOr Update MasterHub
export const addMasterHubRacksMultiple = (data) => {
  alereAxios.post("hubs/racks/store-multiple", data);
};
export const editMasterHubRacksMultiple = (data) => {
  alereAxios.put("hubs/racks/update-multiple", data);
};
export const deleteMasterHubRacksMultiple = (data) => {
  alereAxios.delete("hubs/racks/delete-multiple", { params: data });
};
export const updateSpesifikRack = (idRack, data) =>
  alereAxios.put(`hubs/racks/${idRack}`, data);

// sub hub
export const addSubHubRacksMultiple = (data) => {
  alereAxios.post("hubs/racks/store-multiple", data);
};

export const deleteSubHubRacksMultiple = (data) => {
  alereAxios.delete("hubs/racks/delete-multiple", { params: data });
};

export const editSubHubRacksMultiple = (data) => {
  alereAxios.put("hubs/racks/update-multiple", data);
};

// ! rack hub
export const locationHub = (params) => alereAxios.get("hubs/racks", { params });

export const getCategory = (params) => alereAxios.get("categories", { params });
