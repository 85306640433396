import {
  Box,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CustomCard from '../../../../../../components/shared/CustomCard';
import InfiniteScroll from 'react-infinite-scroll-component';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as DollarIcon } from '../../../../../../assets/dollar.svg';
import { ReactComponent as BoxIcon } from '../../../../../../assets/box.svg';
import { ReactComponent as NoImage } from '../../../../../../assets/no-image.svg';
import Subsidiary from '../../../../../../components/shared/Subsidiary';

const CategoryMenuMobile = (props) => {
  const {
    // *---------
    dummyDataTable,
    //*----- grafik ----
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    // *----- Pages --------
    setPage,
    // *----- Table --------
    setCategoryMenu,
    tableRowPerPage,
    setTableRowPerPage,
    tableRowPerPageValues,
    tableCurrentPage,
    setTableCurrentPage,
    tableTotalItems,
    setTableTotalItems,
    tableSearch,
    setTableSearch,
    sortTable,
    setSortTable,
    isSortAsc,
    setIsSortAsc,
    tableDatas,
    setTableDatas,
    tableCategoryFilterDatas,
    setTableCategoryFilterDatas,
    isLoadingData,
    // *----- Hnadler --------
    handleToAllMenu,
    handleAddButton,
    handleFilterButton,
    handleClickRow,
    handleClickName,
  } = props;
  const theme = useTheme();
  return (
    <Grid container columns={12} spacing={2}>
      <Grid item xs={9}>
        <Subsidiary />
      </Grid>
      <Grid item xs={3}>
        <Box
          sx={{
            bgcolor: '#51B15C1A',
            height: '48px',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 0.3,
          }}
          onClick={handleToAllMenu}
        >
          <BoxIcon style={{ height: '12px' }} />
          <ChevronRightIcon sx={{ color: '#51B15C' }} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <CustomCard sx={{ p: '12px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {/* JUDUl */}
              <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
                Category
              </Typography>
              <TextField
                placeholder={'Search'}
                value={tableSearch}
                onChange={(e) => setTableSearch(e.target.value)}
                size="small"
                sx={{
                  width: '100px',
                  height: '20px',
                  '.MuiOutlinedInput-input': {
                    padding: '6px 0px 6px 0px',
                    fontSize: '0.5rem',
                  },
                  '.MuiOutlinedInput-root': {
                    bgcolor: '#FAFAFA',
                    borderRadius: '4px',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ width: '8px', ml: '4px' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'row-reverse',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '4px',
                  bgcolor: theme.palette.primary.main,
                  width: '20px',
                  height: '20px ',
                  cursor: 'pointer',
                }}
                onClick={handleAddButton}
              >
                <AddIcon sx={{ color: 'white', width: '8px', height: '8px' }} />
              </Box>
              <CustomCard
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '4px',
                  width: '20px',
                  height: '20px ',
                }}
              >
                <FilterAltIcon sx={{ width: '12px' }} />
              </CustomCard>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: '16px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <DollarIcon style={{ height: '10px' }} />
                <Typography sx={{ fontSize: '8px' }}>COGS(Rp)*</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <BoxIcon style={{ height: '10px' }} />
                <Typography sx={{ fontSize: '8px' }}>SKU</Typography>
              </Box>
            </Box>
            <Typography sx={{ fontSize: '8px' }}>*000</Typography>
          </Box>
          {/* TABLE DATA */}
          <Box sx={{}}>
            <Box
              // loader={<Skeleton variant="text" sx={{ mt: 1 }} />}
              sx={{
                marginTop: '8px',
                maxHeight: '243px',
                overflow: 'auto',
              }}
            >
              <Grid container columns={12} sx={{}} spacing={'16px'}>
                {/* Data */}
                {tableDatas.map((data, index) => (
                  <Grid item xs={6} key={index}>
                    <Box
                      sx={{
                        bgcolor: '#FAFAFA',
                        p: '8px',
                        borderRadius: '8px',
                        height: '65px',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        handleClickRow(
                          data.subsidiary.name,
                          data.subsidiary.id,
                          data.namaKategori,
                          data.idKategori
                        )
                      }
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* Photo */}
                        <Box
                          onClick={(event) =>
                            handleClickName(
                              event,
                              data.subsidiary.name,
                              data.subsidiary.id,
                              data.namaKategori,
                              data.idKategori
                            )
                          }
                        >
                          {!data.gambar ? (
                            <Box
                              sx={{
                                width: '28px',
                                height: '28px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                bgcolor: '#FAFAFA',
                                borderRadius: '2px',
                              }}
                            >
                              <NoImage />
                              <Typography
                                sx={{ color: '#9E9D9D', fontSize: '4px' }}
                              >
                                No photo
                              </Typography>
                            </Box>
                          ) : (
                            <Box>
                              <Box
                                component="img"
                                src={
                                  process.env.REACT_APP_API_IMAGE_BASE_URL +
                                  '/' +
                                  data.gambar.split(',')[0]
                                }
                                sx={{
                                  width: '28px',
                                  height: '28px',
                                  borderRadius: '2px',
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                        {/* Title */}
                        <Box sx={{ ml: '8px' }}>
                          <Typography
                            sx={{
                              fontSize: '8px',
                              fontWeight: '600',
                              color: '#848589',
                            }}
                          >
                            {!data.subsidiary ? '-' : data.subsidiary.name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '10px',
                              fontWeight: '600',
                              mt: '2px',
                            }}
                            onClick={(event) =>
                              handleClickName(
                                event,
                                data.subsidiary.name,
                                data.subsidiary.id,
                                data.namaKategori,
                                data.idKategori
                              )
                            }
                          >
                            {data.namaKategori}
                          </Typography>
                        </Box>
                      </Box>
                      {/* ITEMSS */}
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          mt: '8px',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                          }}
                        >
                          <DollarIcon style={{ height: '8px' }} />
                          <Typography sx={{ fontSize: '8px' }}>
                            {!data.productsSumHarga
                              ? '-'
                              : data.productsSumHarga}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                          }}
                        >
                          <BoxIcon style={{ height: '8px' }} />
                          <Typography sx={{ fontSize: '8px' }}>
                            {!data.productsCount ? '-' : data.productsCount}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </CustomCard>
      </Grid>
    </Grid>
  );
};
export default CategoryMenuMobile;
