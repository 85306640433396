import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import BackButtonHub from "../../../../../../../components/shared/BackButtonHub";
import { ReactComponent as NoImage } from "../../../../../../../assets/no-image.svg";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import CustomCard from "../../../../../../../components/shared/CustomCard";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  fetchDetailDataMiniHub,
  fetchMiniHubRacksPreview,
  getDetailDataMiniHub,
  getIdMiniHub,
  getLoadingDetailMiniHub,
  getRacksPositionPreviewMiniHub,
  getRacksPreviewMiniHub,
} from "../../../../../../../store/hub/miniHub/miniHubDetails";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import { Map, Marker, ZoomControl } from "pigeon-maps";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EditMiniHub from "../EditMiniHub";
import CustomButton from "../../../../../../../components/shared/CustomButton";
import CustomAntSwitch from "../../../../../../../components/shared/CustomAntSwitch";
import {
  changeIdMiniHubEdit,
  getIdMiniHubEdit,
  getIsMiniHubEdit,
  goToMiniHubEdit,
} from "../../../../../../../store/hub/miniHub/miniHubEdit";
import { editStatusMiniHub } from "../../../../../../../api/hubs";
import CustomDialog from "../../../../../../../components/shared/CustomDialog";
import RackMapPreviewMiniHub from "./RackMapPreview";
import { getNotifHub } from "../../../../../../../store/hub/notifHub";
import { goToRackHub } from "../../../../../../../store/racks/rackHub";

function DetailsMiniHub({ setMiniHubDetailSelected, setMiniSubSelected }) {
  const dispatch = useDispatch();
  const idMiniHub = useSelector(getIdMiniHub);
  const dataDetailsMiniHub = useSelector(getDetailDataMiniHub);
  const loading = useSelector(getLoadingDetailMiniHub);
  const isEdit = useSelector(getIsMiniHubEdit);
  const { key, text: textNotif } = useSelector(getNotifHub);

  // change params
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);
  const subHubIdRef = useRef(searchParams.get("minihub-overview") || null);

  // preview
  const [linkPreviewImage, setLinkPreviewImage] = useState("");
  const [isPreviewImage, setIsPreviewImage] = useState(false);
  const [linkPreviewVideo, setLinkPreviewVideo] = useState("");
  const [isPreviewVideo, setIsPreviewVideo] = useState(false);

  const [isPreviewRackMap, setIsPreviewRackMap] = useState(false);
  const [isPreviewAddressMap, setIsPreviewAddressMap] = useState(false);

  const [addressMapZoom, setAddressMapZoom] = useState(17);
  const [addressMapCenter, setAddressMapCenter] = useState([
    3.5465944896879704, 98.68976198831375,
  ]);

  const rackSelected = useSelector(getRacksPositionPreviewMiniHub);
  const racksData = useSelector(getRacksPreviewMiniHub);
  // get detail data
  useEffect(() => {
    dispatch(fetchDetailDataMiniHub(idMiniHub));
    dispatch(fetchMiniHubRacksPreview(idMiniHub));
    if (key && !isEdit) {
      dispatch(fetchDetailDataMiniHub(idMiniHub));
      dispatch(fetchMiniHubRacksPreview(idMiniHub));
    }
  }, [dispatch, idMiniHub, key, isEdit]);

  // status
  const [statusHub, setStatusHub] = useState(false);
  useEffect(() => {
    if (!loading) {
      if (dataDetailsMiniHub.status === "active") {
        return setStatusHub(true);
      } else {
        return setStatusHub(false);
      }
    }
  }, [loading]);

  const handleEditStatus = () => {
    if (statusHub) {
      editStatusMiniHub(dataDetailsMiniHub.id, { status: "inactive" });
      setStatusHub(false);
    } else {
      editStatusMiniHub(dataDetailsMiniHub.id, { status: "active" });
      setStatusHub(true);
    }
  };

  // show preview image
  const handlePreviewImage = (link) => {
    setLinkPreviewImage(link);
    setIsPreviewImage(true);
  };

  // show preview video
  const handlePreviewVideo = (link) => {
    setLinkPreviewVideo(link);
    setIsPreviewVideo(true);
  };

  const handleClosePreviewVideo = () => {
    setIsPreviewVideo(!isPreviewVideo);
    setLinkPreviewVideo("");
  };

  const handleToRack = () => {
    dispatch(
      goToRackHub({
        path: "mini_hub",
        id: idMiniHub,
        cellSelected: rackSelected,
        col: dataDetailsMiniHub.mapColumn,
        row: dataDetailsMiniHub.mapRow,
      })
    );
  };

  // handle edit data
  const handleToEdit = () => {
    dispatch(
      goToMiniHubEdit({
        name: dataDetailsMiniHub.name,
        image: dataDetailsMiniHub.images,
        video: dataDetailsMiniHub.videos,
        address: dataDetailsMiniHub.address,
        pic: dataDetailsMiniHub.pic,
        pictelpon: dataDetailsMiniHub.pic.telpon,
        status: statusHub,
        col: dataDetailsMiniHub.mapColumn,
        row: dataDetailsMiniHub.mapRow,
        rackDetails: racksData,
        rackSelected: rackSelected,
        entrance: dataDetailsMiniHub.entrancePosition
          ? dataDetailsMiniHub.entrancePosition.split(",")
          : [],
        subsidiaryId: dataDetailsMiniHub?.subHub?.store?.subsidiaryId,
        coordinate: [
          Number(dataDetailsMiniHub?.mapCor?.lat),
          Number(dataDetailsMiniHub?.mapCor?.long),
        ],
        typeMap: dataDetailsMiniHub?.mapCor?.typeInput,
        citydistrict: dataDetailsMiniHub?.mapCor?.cityDistrict,
        idMasterHub: dataDetailsMiniHub?.subHub?.idToko,
        idSubHub: dataDetailsMiniHub.subHubId,
      })
    );
    dispatch(changeIdMiniHubEdit(idMiniHub));
  };

  return (
    <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
      {!isEdit ? (
        <Grid container columns={12} spacing={4}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <BackButtonHub
                onClick={() => {
                  setMiniHubDetailSelected(null);
                  updatedSearchParams.delete("detail");
                  setSearchParams(updatedSearchParams);
                  setMiniSubSelected(dataDetailsMiniHub?.id);
                }}
              />
              <Box>
                <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
                  {dataDetailsMiniHub.name} Details
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    color: "#9E9D9D",
                    alignItems: "center",
                    mt: "4px",
                    gap: 1,
                  }}
                >
                  <Typography sx={{ fontSize: "16px", fontWeight: "100" }}>
                    {dataDetailsMiniHub?.subHub?.store?.subsidiary?.name}
                  </Typography>
                  <ArrowForwardIosIcon
                    sx={{ color: "#9E9D9D", fontSize: "14px", fontWeight: 600 }}
                  />
                  <Typography sx={{ fontSize: "16px", fontWeight: "100" }}>
                    {dataDetailsMiniHub?.subHub?.store?.namaToko}
                  </Typography>
                  <ArrowForwardIosIcon
                    sx={{ color: "#9E9D9D", fontSize: "14px", fontWeight: 600 }}
                  />
                  <Typography sx={{ fontSize: "16px", fontWeight: "100" }}>
                    {dataDetailsMiniHub?.subHub?.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          {!loading ? (
            <>
              {/* name */}
              <Grid item xs={12}>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                    Name
                  </Typography>
                  <Typography sx={{ color: "red" }}>*</Typography>
                </Box>
                <Box
                  sx={{
                    mt: "12px",
                    width: "100%",
                    padding: "14px 16px",
                    bgcolor: "#FAFAFA",
                    borderRadius: "8px",
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                    {dataDetailsMiniHub?.name}
                  </Typography>
                </Box>
              </Grid>

              {/* image */}
              <Grid item xs={6}>
                <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                  Photo
                </Typography>
                <Box
                  sx={{
                    mt: "12px",
                    width: "100%",
                    padding: "0",
                    display: "flex",
                    gap: 2,
                  }}
                >
                  {!dataDetailsMiniHub?.images ? (
                    <Box
                      sx={{
                        width: "60px",
                        height: "60px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#eee",
                        borderRadius: "5px",
                      }}
                    >
                      <NoImage />
                      <Typography
                        sx={{
                          color: "#9E9D9D",
                          fontSize: "8px",
                          fontWeight: "bold",
                        }}
                      >
                        No photo
                      </Typography>
                    </Box>
                  ) : (
                    dataDetailsMiniHub?.images.split(",").map((item, index) => (
                      <Box
                        component="img"
                        src={
                          process.env.REACT_APP_API_IMAGE_BASE_URL + "/" + item
                        }
                        onClick={() => handlePreviewImage(item)}
                        sx={{
                          width: "60px",
                          height: "60px",
                          aspectRatio: "1/1",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                        key={index}
                      />
                    ))
                  )}
                </Box>
                {/* dialog image */}
                {linkPreviewImage !== "" && (
                  <Dialog
                    open={isPreviewImage}
                    onClose={() => {
                      setIsPreviewImage(!isPreviewImage);
                      setLinkPreviewImage("");
                    }}
                  >
                    <Box
                      component="img"
                      src={
                        process.env.REACT_APP_API_IMAGE_BASE_URL +
                        "/" +
                        linkPreviewImage
                      }
                      sx={{
                        width: "600px",
                        height: "600px",
                        aspectRation: "1/1",
                      }}
                    />
                  </Dialog>
                )}
              </Grid>

              {/* video */}
              <Grid item xs={6}>
                <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                  Video
                </Typography>
                <Box
                  sx={{
                    mt: "12px",
                    width: "100%",
                    padding: "0",
                    display: "flex",
                  }}
                >
                  {!dataDetailsMiniHub.videos ? (
                    <Box
                      sx={{
                        width: "60px",
                        height: "60px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#FAFAFA",
                        borderRadius: "2px",
                      }}
                    >
                      <NoImage />
                      <Typography sx={{ color: "#9E9D9D", fontSize: "8px" }}>
                        No photo
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", gap: 2 }}>
                      {dataDetailsMiniHub.videos
                        .split(",")
                        .map((input, index) => (
                          <Box
                            onClick={() => handlePreviewVideo(input)}
                            key={index}
                          >
                            <video
                              style={{
                                width: "60px",
                                height: "60px",
                                borderRadius: "5px",
                                cursor: "pointer",
                                backgroundColor: "black",
                              }}
                            >
                              <source
                                src={
                                  process.env.REACT_APP_API_IMAGE_BASE_URL +
                                  "/" +
                                  input +
                                  "#t=0.5"
                                }
                              />
                            </video>
                          </Box>
                        ))}
                    </Box>
                  )}
                </Box>
                {/*  Dialog Video */}
                {linkPreviewVideo !== "" && (
                  <Dialog
                    open={isPreviewVideo}
                    onClose={handleClosePreviewVideo}
                  >
                    <Box sx={{ bgcolor: "black" }}>
                      <video
                        style={{
                          width: "600px",
                          height: "600px",
                          backgroundColor: "black",
                        }}
                        controls
                      >
                        <source
                          src={
                            process.env.REACT_APP_API_IMAGE_BASE_URL +
                            "/" +
                            linkPreviewVideo
                          }
                        />
                      </video>
                    </Box>
                  </Dialog>
                )}
              </Grid>

              {/* PIC */}
              <Grid item xs={6}>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                    PIC
                  </Typography>
                  <Typography sx={{ color: "red" }}>*</Typography>
                </Box>
                <Box
                  sx={{
                    mt: "12px",
                    width: "100%",
                    padding: "14px 16px",
                    bgcolor: "#FAFAFA",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <PersonIcon />
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    {dataDetailsMiniHub?.pic
                      ? dataDetailsMiniHub?.pic?.namaLengkap
                      : "-"}
                  </Typography>
                </Box>
              </Grid>

              {/*  Phone Number */}
              <Grid item xs={6}>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                    Phone Number
                  </Typography>
                  <Typography sx={{ color: "red" }}>*</Typography>
                </Box>
                <Box
                  sx={{
                    mt: "12px",
                    width: "100%",
                    padding: "14px 16px",
                    bgcolor: "#FAFAFA",
                    borderRadius: "8px",
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    {dataDetailsMiniHub?.pic?.telpon
                      ? dataDetailsMiniHub?.pic?.telpon
                      : "-"}
                  </Typography>
                </Box>
              </Grid>

              {/* address */}
              <Grid item xs={6}>
                <CustomCard
                  sx={{ p: "8px 16px", cursor: "pointer" }}
                  onClick={() => setIsPreviewAddressMap(true)}
                >
                  <Box
                    sx={{
                      display: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Box
                        sx={{
                          width: "60px",
                          height: "60px",
                          bgcolor: "gray",
                          borderRadius: "8px",
                        }}
                      ></Box>
                      <Box>
                        <Typography
                          sx={{ fontWeight: "bold", mb: "4px", ml: "8px" }}
                        >
                          Address
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.4,
                          }}
                        >
                          <LocationOnIcon color="primary" />
                          <Typography>
                            {dataDetailsMiniHub?.address
                              ? dataDetailsMiniHub?.address
                              : "-"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <KeyboardArrowDownIcon />
                  </Box>
                </CustomCard>

                {/* //* Dialog Addres Map */}
                <Dialog
                  maxWidth="md"
                  open={isPreviewAddressMap}
                  onClose={() => setIsPreviewAddressMap(!isPreviewAddressMap)}
                >
                  <DialogTitle>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Address</Typography>
                      <CloseIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          setIsPreviewAddressMap(!isPreviewAddressMap)
                        }
                      />
                    </Box>
                  </DialogTitle>
                  <DialogContent dividers>
                    <Map
                      height={300}
                      width={560}
                      center={addressMapCenter}
                      zoom={addressMapZoom}
                      onBoundsChanged={({ center, zoom }) => {
                        setAddressMapCenter(center);
                        setAddressMapZoom(zoom);
                      }}
                    >
                      <ZoomControl />
                      <Marker
                        anchor={[
                          Number(dataDetailsMiniHub?.mapCor?.lat),
                          Number(dataDetailsMiniHub?.mapCor?.long),
                        ]}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <LocationOnRoundedIcon
                            color="error"
                            sx={{
                              fontSize: `${
                                30 - (20 - Math.floor(addressMapZoom))
                              }px`,
                            }}
                          />
                          {addressMapZoom >= 17 && (
                            <Typography
                              color="error"
                              sx={{ fontSize: "12px", textAlign: "center" }}
                            >
                              {dataDetailsMiniHub.name}
                            </Typography>
                          )}
                        </Box>
                      </Marker>
                      {/* {addressMapZoom > 15 && (
                      <Marker anchor={[3.5464766985100273, 98.6915751613956]}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <LocationOnRoundedIcon
                            color="primary"
                            sx={{
                              fontSize: `${
                                30 - (20 - Math.floor(addressMapZoom))
                              }px`,
                            }}
                          />
                          {addressMapZoom >= 17 && (
                            <Typography
                              color="primary"
                              sx={{ fontSize: '12px', textAlign: 'center' }}
                            >
                              binjai
                            </Typography>
                          )}
                        </Box>
                      </Marker>
                    )} */}
                    </Map>
                    <Typography sx={{ mt: "12px" }}>
                      {dataDetailsMiniHub?.address}
                    </Typography>
                  </DialogContent>
                </Dialog>
              </Grid>

              {/* Rack  */}
              <Grid item xs={6}>
                <CustomCard
                  sx={{ p: "8px 16px", cursor: "pointer" }}
                  onClick={() => setIsPreviewRackMap(!isPreviewRackMap)}
                >
                  <Box
                    sx={{
                      display: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Box
                        sx={{
                          height: "60px",
                          borderRadius: "8px",
                        }}
                      ></Box>
                      <Box sx={{ ml: "8px" }}>
                        <Typography sx={{ fontWeight: "bold", mb: "4px" }}>
                          Racks Map
                        </Typography>
                        <Typography sx={{ color: "#9E9D9D", fontSize: "12px" }}>
                          Click to see map
                        </Typography>
                      </Box>
                    </Box>
                    <KeyboardArrowDownIcon />
                  </Box>
                </CustomCard>

                {/* //* Dialog Rack Map */}
                <CustomDialog
                  maxWidth="md"
                  isOpen={isPreviewRackMap}
                  setIsOpen={setIsPreviewRackMap}
                >
                  <RackMapPreviewMiniHub
                    col={dataDetailsMiniHub.mapColumn}
                    row={dataDetailsMiniHub.mapRow}
                    entrance={dataDetailsMiniHub?.entrancePosition}
                    setIsOpen={setIsPreviewRackMap}
                  />
                </CustomDialog>
              </Grid>

              {/* see sub hub rack */}
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleToRack}
                >
                  <Typography color="primary">See Sub Hub Racks</Typography>
                  <ArrowForwardIosIcon
                    color="primary"
                    sx={{ fontSize: "12px" }}
                  />
                </Box>
              </Grid>

              <Box
                sx={{
                  width: "100%",
                  // bgcolor: "white",
                  position: "fixed",
                  bottom: "0px",
                  right: "0px",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "18px 32px 18px 240px",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                    Publish
                  </Typography>
                  <CustomAntSwitch
                    checked={statusHub}
                    onChange={(e) => handleEditStatus(e)}
                  />
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <CustomButton
                    variant="contained"
                    color="error"
                    sx={{ width: "140px", height: "36px" }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      Delete
                    </Typography>
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    sx={{ width: "140px", height: "36px" }}
                    onClick={() => handleToEdit()}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      Edit
                    </Typography>
                  </CustomButton>
                </Box>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                width: "100%",
                minHeight: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Grid>
      ) : (
        <EditMiniHub />
      )}
    </Box>
  );
}
export default DetailsMiniHub;
