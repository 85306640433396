import { useDispatch, useSelector } from 'react-redux';
import {
  changePage,
  getAllPurchaseRouting,
  setNotifPurchase,
} from '../../../../../../store/Routing/purchaseRouting';
import { useEffect, useState } from 'react';
import {
  getPurchaseRequestDetail,
  rejectPurchaseRequest,
} from '../../../../../../api/purchase';
import {
  FillText,
  LoadingComponent,
} from '../../../../../../components/shared/shared2';
import { Box, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import BottomMenu from '../../../../../../components/shared/BottomMenu';
import BackButtonDetailPurchase from '../PurchaseDetailFinance/BackButtonDetailFinance';
import LineDiv from '../../../../../../components/shared/LineDiv';
import CustomCard from '../../../../../../components/shared/CustomCard';
import { goToPurchaseProcess } from '../../../../../../store/purchase/purchaseSupplier/purchaseProcessSupplier';
import CustomDeleteConfirm from '../../../../../../components/shared/CustomDeleteConfirm';

const PurchaseDetailSupplier = () => {
  const dispatch = useDispatch();
  const { idPurchase, statusPurchase } = useSelector(getAllPurchaseRouting);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [datas, setDatas] = useState(null);

  const fetchApi = async (id) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await getPurchaseRequestDetail(id);
      setDatas(data);
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchReject = async (id) => {
    setIsLoading(true);
    let params = {
      status: 'reject',
    };
    try {
      const data = await rejectPurchaseRequest(id, params);

      dispatch(
        setNotifPurchase({
          key: 'rejectPurchaseBySupplier',
          text: 'Purchase has been success rejected',
        })
      );
      dispatch(changePage(''));
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApi(idPurchase);
  }, [idPurchase]);

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const totalss = datas?.details.reduce((accum, next) => {
      return accum + Number(next.purchasePrice) * Number(next.qty);
    }, 0);

    setTotalPrice(totalss);
  }, [datas]);

  const handleProcess = () => {
    if (statusPurchase === 'supplier bid' || statusPurchase === 'finance bid') {
      dispatch(
        goToPurchaseProcess({
          idPurchase: idPurchase,
          products: datas.details,
          description: datas?.keterangan || '',
          supplier: datas?.supplier?.namaLengkap || '',
          paymentMethod: datas?.paymentMethod?.ket || '',
          idHub: datas?.idToko || 0,
          idsupplier: datas?.idSuppliers,
          idsubsidiary: datas?.subsidiaryId,
          idtransaksi: datas?.idTransaksi,
        })
      );
      dispatch(changePage('purchaseProcessSupplier'));
    }
  };

  const [isRejectConfirm, setIsRejectConfirm] = useState(false);
  const handleReject = () => {
    setIsRejectConfirm(true);
  };
  const handleRejectFix = () => {
    if (statusPurchase === 'finance bid') {
      fetchReject(idPurchase);
      setIsRejectConfirm(false);
    }
  };

  const desktopMode = useMediaQuery('(min-width:832px)');

  return (
    <Box sx={{ mt: '16px' }}>
      <CustomDeleteConfirm
        open={isRejectConfirm}
        onClose={() => setIsRejectConfirm(!isRejectConfirm)}
        handleClickCancel={() => setIsRejectConfirm(!isRejectConfirm)}
        handleClickDelete={handleRejectFix}
        title="Reject Purchase"
        body={
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              Are you sure you want to reject "
              <Typography component="span" sx={{ fontWeight: '600' }}>
                This Purchase
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: '52px' }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
        redText="Reject"
      />
      {!isLoading && !isError && (
        <>
          <BackButtonDetailPurchase />
          {desktopMode && (
            <Grid
              container
              columns={2}
              spacing={4}
              sx={{ mt: '16px', pb: '54px' }}
            >
              <Grid item xs={1}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    mb: '12px',
                  }}
                >
                  Supplier*
                </Typography>
                <LineDiv value={datas?.supplier?.namaLengkap || '-'} />
              </Grid>
              <Grid item xs={1}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    mb: '12px',
                  }}
                >
                  Payment Method*
                </Typography>
                <LineDiv value={datas?.paymentMethod?.transaksi || '-'} />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    mb: '12px',
                  }}
                >
                  Description
                </Typography>
                <LineDiv value={datas?.keterangan || '-'} />
              </Grid>
              <Grid item xs={2}>
                <CustomCard>
                  <CustomCard sx={{ p: '20px' }}>
                    <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
                      Item(s)
                    </Typography>
                  </CustomCard>
                  <Grid container columns={1} rowSpacing={4} sx={{ p: '20px' }}>
                    {datas?.details.map((list, index) => {
                      return (
                        <Grid item xs={1} key={index}>
                          <Grid container columns={5} columnSpacing={4}>
                            <Grid item xs={2}>
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  mb: '12px',
                                }}
                              >
                                Product
                              </Typography>
                              <LineDiv
                                value={list?.product?.namaProduk || '-'}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Box
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  mb: '12px',
                                  display: 'flex',
                                  gap: 0.5,
                                }}
                              >
                                <Typography>Qty </Typography>
                                {/* <Typography sx={{ color: '#51B15C' }}>
                                  (Pcs)
                                </Typography> */}
                              </Box>
                              <LineDiv value={list?.qty} unit={list?.unit} />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  mb: '12px',
                                }}
                              >
                                Price*
                              </Typography>
                              <LineDiv value={list?.purchasePrice || '-'} />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  mb: '12px',
                                }}
                              >
                                Subtotal*
                              </Typography>
                              <LineDiv
                                value={`Rp. ${
                                  Number(list?.purchasePrice) *
                                  Number(list?.qty)
                                }`}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      p: '20px',
                    }}
                  >
                    <Typography sx={{ fontWeight: '600', fontSize: '20px' }}>
                      Grand Total
                    </Typography>
                    <Typography sx={{ fontWeight: '600', fontSize: '20px' }}>
                      Rp. {totalPrice || '-'}
                    </Typography>
                  </Box>
                </CustomCard>
              </Grid>
            </Grid>
          )}
          {!desktopMode && (
            <Box sx={{ minHeight: '70vh' }}>
              <Grid
                container
                columns={1}
                spacing={2}
                sx={{ mt: '16px', pb: '54px' }}
              >
                <Grid item xs={1}>
                  <FillText
                    title={'Supplier*'}
                    value={datas?.supplier?.namaLengkap || '-'}
                  />
                </Grid>
                <Grid item xs={1}>
                  <FillText
                    title="Payment Method*"
                    value={datas?.paymentMethod?.ket || '-'}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FillText
                    title={'Description'}
                    value={datas?.keterangan || '-'}
                  />
                </Grid>
                <Grid item xs={2}>
                  <CustomCard>
                    <CustomCard sx={{ p: '16px' }}>
                      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
                        Item(s)
                      </Typography>
                    </CustomCard>
                    <Grid
                      container
                      columns={1}
                      rowSpacing={2}
                      sx={{ p: '20px 16px' }}
                    >
                      {datas?.details.map((list, index) => {
                        return (
                          <Grid item xs={1} key={index}>
                            <Grid
                              container
                              columns={6}
                              columnSpacing={2}
                              rowSpacing={2}
                            >
                              <Grid item xs={4}>
                                <FillText
                                  title={'Product'}
                                  value={list?.product?.namaProduk || '-'}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <FillText
                                  title={'Qty'}
                                  value={list?.qty || '-'}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <FillText
                                  title={'Price*'}
                                  value={list?.purchasePrice || '-'}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <FillText
                                  title={'Subtotal'}
                                  value={`Rp. ${
                                    Number(list?.purchasePrice) *
                                    Number(list?.qty)
                                  }`}
                                />
                              </Grid>
                            </Grid>
                            <Divider sx={{ mt: '16px' }} />
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: '16px',
                      }}
                    >
                      <Typography sx={{ fontWeight: '600', fontSize: '16px' }}>
                        Grand Total
                      </Typography>
                      <Typography sx={{ fontWeight: '600', fontSize: '16px' }}>
                        Rp. {totalPrice || '-'}
                      </Typography>
                    </Box>
                  </CustomCard>
                </Grid>
              </Grid>
            </Box>
          )}
          <BottomMenu
            isGreen={true}
            isRed={true}
            redText={'Reject'}
            greenText="Process"
            handleGreen={handleProcess}
            handleRed={handleReject}
            disableRed={statusPurchase !== 'finance bid'}
          />
        </>
      )}
      {isLoading && <LoadingComponent />}
    </Box>
  );
};
export default PurchaseDetailSupplier;
