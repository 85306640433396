import { createContext, useState } from 'react';

const HubContext = createContext({});

export const HubProvider = ({ children }) => {
  const [hubFamilies, setHubFamilies] = useState([]);
  const [masterHubPreview, setMasterHubPreview] = useState(null);
  const [masterHubData, setMasterHubData] = useState({
    masterName: null,
    masterHubId: null,
    subsidiary: null,
  });
  const [miniHubData, setMiniHubData] = useState({
    subHubName: null,
    subHubId: null,
    subsidiary: null,
    masterHubName: null,
  });

  return (
    <HubContext.Provider
      value={{
        hubFamilies,
        setHubFamilies,
        masterHubPreview,
        setMasterHubPreview,
        masterHubData,
        setMasterHubData,
        miniHubData,
        setMiniHubData,
      }}
    >
      {children}
    </HubContext.Provider>
  );
};

export default HubContext;
