import React, { useEffect, useState } from "react";
import {
  DialogTitle,
  Box,
  Typography,
  IconButton,
  DialogContent,
  Autocomplete,
  Checkbox,
  TextField,
  CircularProgress,
} from "@mui/material";
import {
  Close as CloseIcon,
  Clear as ClearIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from "@mui/icons-material";
import CustomDialog from "../../../../../../components/shared/CustomDialog";
import CustomButton from "../../../../../../components/shared/CustomButton";
import { ReactComponent as CloseFilledIcon } from "../../../../../../assets/closeFilledIcon.svg";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DialogFilter = ({
  isOpenDialogFilter,
  setIsOpenDialogFilter,
  autocompleteFilter,
  setAutocompleteFilter,
  allData,
  subsidiaryDatas,
  subsidiaryFilterValue,
  setSubsidiaryFilterValue,
  handleSaveFilter,
  handleResetFilter,
  isLoadingSubHub,
}) => {
  return (
    <CustomDialog
      isOpen={isOpenDialogFilter}
      setIsOpen={setIsOpenDialogFilter}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle
        sx={{
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
          borderRadius: "12px 12px 0px 0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 600,
              color: "#000000",
            }}
            component="span"
          >
            Filter
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setIsOpenDialogFilter(!isOpenDialogFilter)}
            sx={{
              color: "#323232",
              p: 0,
            }}
          >
            <CloseIcon sx={{ fontSize: "1.25rem" }} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Typography
            sx={{ fontSize: "1rem", fontWeight: 500, color: "#000000" }}
          >
            Subsidiary
          </Typography>
        </Box>
        <Autocomplete
          sx={{
            ".MuiAutocomplete-hasClearIcon": {
              display: "none",
            },
          }}
          clearIcon={
            autocompleteFilter.isSubsidiaryFull &&
            !autocompleteFilter.isSubsidiaryOpen ? (
              false
            ) : (
              <ClearIcon fontSize="small" />
            )
          }
          multiple={
            autocompleteFilter.isSubsidiaryFull &&
            !autocompleteFilter.isSubsidiaryOpen
              ? false
              : true
          }
          limitTags={
            autocompleteFilter.isSubsidiaryFull &&
            !autocompleteFilter.isSubsidiaryOpen
              ? 0
              : 3
          }
          options={
            autocompleteFilter.isSubsidiaryFull &&
            !autocompleteFilter.isSubsidiaryOpen
              ? allData
              : subsidiaryDatas
          }
          getOptionLabel={(option) => option.name}
          value={
            autocompleteFilter.isSubsidiaryFull &&
            !autocompleteFilter.isSubsidiaryOpen
              ? allData[0]
              : subsidiaryFilterValue
          }
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Box
                sx={{
                  bgcolor: "white",
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
                  p: "4px 8px",
                  m: "2px",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
                key={index}
              >
                {option.name}

                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    setSubsidiaryFilterValue(
                      subsidiaryFilterValue.filter(
                        (item) => item.id !== option.id
                      )
                    )
                  }
                >
                  <CloseFilledIcon />
                </Box>
              </Box>
            ))
          }
          onChange={(event, value) => {
            setSubsidiaryFilterValue(value);
            if (value.length === subsidiaryDatas?.length) {
              setAutocompleteFilter({
                ...autocompleteFilter,
                isSubsidiaryFull: true,
              });
            } else {
              setAutocompleteFilter({
                ...autocompleteFilter,
                isSubsidiaryFull: false,
              });
            }
          }}
          onOpen={() => {
            setAutocompleteFilter({
              ...autocompleteFilter,
              isSubsidiaryOpen: true,
            });
          }}
          onClose={() => {
            setAutocompleteFilter({
              ...autocompleteFilter,
              isSubsidiaryOpen: false,
            });
          }}
          selectOnFocus
          disableCloseOnSelect
          renderOption={(props, option, { selected }) => (
            <li {...props} sx={{ color: "black" }} key={option.id}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          style={{ width: "100%", marginTop: "12px" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              placeholder="Enter Subsidiarys"
              sx={{ bgcolor: "#FAFAFA" }}
            />
          )}
        />

        <Box
          sx={{ display: "flex", justifyContent: "flex-end", mt: 3, gap: 2 }}
        >
          <CustomButton
            sx={{
              color: "#51B15C",
              fontWeight: 600,
              fontSize: "0.875rem",
              px: 3,
              py: 1,
            }}
            onClick={handleResetFilter}
          >
            Reset
          </CustomButton>

          <CustomButton
            sx={{
              bgcolor: "#51B15C",
              color: "#ffffff",
              fontWeight: 600,
              fontSize: "0.875rem",
              borderRadius: "8px",
              "&:hover": {
                bgcolor: "#51B15C",
                color: "#ffffff",
              },
              px: 4,
              py: 1,
            }}
            onClick={handleSaveFilter}
          >
            Save
          </CustomButton>
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};

export default DialogFilter;
