import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BottomMenu from "../../../../../../components/shared/BottomMenu";
import { Add, Delete } from "@mui/icons-material";
import {
  Canceled,
  ChecklistIcon,
  ChecklistIconApproval,
  CloseIcon,
} from "../../../../../../assets/Icons";
import CustomButton from "../../../../../../components/shared/CustomButton";
import { useRef } from "react";
import debounce from "lodash.debounce";
import CustomInput from "../../../../../../components/shared/CustomInput";
import {
  addProductStockOut,
  editProductStockOut,
  getProductUnitStockOut,
} from "../../../../../../api/stockout";
import { useDispatch, useSelector } from "react-redux";
import {
  changeIdOrder,
  fetchAllDataInvoice,
  getAllDataStockOut,
} from "../../../../../../store/stockout/stockout";
import { allProductName } from "../../../../../../api/menu";
import HubInvoice from "../Mobile/Dashboard/Hub";

/**
 * Component untuk menampilkan item accordion untuk kategori stock out.
 *
 * @returns {React.Element} Komponen AccordionStockout.
 */
const AccordionStockout = ({
  invoice,
  execute,
  setExecute,
  search,
  setSearch,
}) => {
  const dispatch = useDispatch();
  const label = { inputProps: { "aria-label": "Checkbox Stock Out" } };

  const [openConfirmn, setOpenConfirmn] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [inputDebounce, setInputDebounce] = useState("");
  const [inputDebounceProduk, setInputDebounceProduk] = useState("");
  const [isLoadingUnit, setIsLoadingUnit] = useState(false);

  const { idOrder } = useSelector(getAllDataStockOut);

  const [datas, setDatas] = useState([]);
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [qty, setQty] = useState("");
  const [unitSelect, setUnitSelect] = useState([]);
  const [unit, setUnit] = useState([]);

  //  changed product
  const [changedProduct, setChangedProduct] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleCheckboxClick = (event, id, data) => {
    event.stopPropagation();
    if (event.target.checked) {
      setExecute((prevExecute) => [...prevExecute, { id: id, data: data }]);
    } else {
      setExecute((prevExecute) => prevExecute.filter((item) => item.id !== id));
    }
  };

  // ! validation add product
  useEffect(() => {
    if (openConfirmn === false) {
      setSelectedProduct(null);
      setQty("");
      setUnitSelect([]);
    } else {
    }
  }, [openConfirmn]);

  const handleAddProduk = (id) => {
    dispatch(changeIdOrder(id));
    setOpenConfirmn(true);
  };

  const inputDebounceChange = useRef(
    debounce((value) => {
      setInputDebounce(value);
    }, 1000)
  ).current;

  // ! api fetch product
  useEffect(() => {
    const getProductName = async () => {
      setIsLoadingProduct(true);
      let datas = {
        page: 1,
        pageSize: 100,
        search: inputDebounce,
      };

      try {
        const {
          data: { data: dataNames },
        } = await allProductName(datas);
        setDatas(dataNames.products);
      } catch (err) {
        console.error(err);
        setDatas([]);
      } finally {
        setIsLoadingProduct(true);
      }
    };

    getProductName();
  }, [inputDebounce]);

  // ! api fetch unit for add product
  const fetchProductUnit = async (idProduct) => {
    try {
      setIsLoadingUnit(true);
      const {
        data: {
          data: { productUnits },
        },
      } = await getProductUnitStockOut(idProduct);
      const units = productUnits?.map((item, idx) => {
        return {
          idProduct: item?.idProduk,
          unit_id: item?.unit?.id,
          unitName: item?.unit.name,
          price: 0,
        };
      });
      setUnit(units);
      setIsLoadingUnit(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleEnterProduct = async () => {
    const params = {
      id_produk: selectedProduct?.idProduk,
      id_order: idOrder,
      qty: qty,
      unit: unitSelect?.unitName,
    };

    try {
      setIsLoadingProduct(true);
      const data = await addProductStockOut(search, params);
      setSelectedProduct(null);
      setQty("");
      setUnitSelect([]);
      setOpenConfirmn(false);
      setIsLoadingProduct(false);
      setSearch(search);
      dispatch(fetchAllDataInvoice(search));
      setUnit([]);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingProduct(false);
    }
  };

  const handleEditProduct = async (data) => {
    const params = {
      id_produk: data?.idProduk,
      id_detail: data?.idDetail,
      qty: data?.qty,
      unit: data?.unit,
    };
    setDisableSubmit(true);
    try {
      const data = await editProductStockOut(search, params);
      dispatch(fetchAllDataInvoice(search));
      setChangedProduct(null);
      setDisableSubmit(false);
      // console.log({ params });
    } catch (err) {
      console.error(err);
    }
  };

  //TODO : sementara execute dibuat popup
  return (
    <Box>
      <Box mt={"25px"}>
        {invoice.map((category, idx) => (
          <Accordion key={idx}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${category?.id_menu?.toLowerCase()}-content`}
              id={`${category?.id_menu?.toLowerCase()}-header`}
            >
              <Grid
                container
                columns={12}
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* Cheked Invoice */}
                <Grid item xs={2}>
                  <Box>
                    {category?.statusOrder === "executed" ? (
                      <Box sx={{ marginLeft: "12px" }}>
                        <Box
                          component="img"
                          src={ChecklistIconApproval}
                          sx={{ width: "20px" }}
                        />
                      </Box>
                    ) : category?.statusOrder === "cancel" ? (
                      <Box
                        sx={{
                          marginLeft: "12px",
                        }}
                      >
                        <Box
                          component="img"
                          src={Canceled}
                          sx={{ width: "20px", pt: "8px" }}
                        />
                      </Box>
                    ) : (
                      <Checkbox
                        disabled={
                          category?.subProduct?.length === 0 ? true : false
                        }
                        {...label}
                        checked={execute.some(
                          (item) => item.id === category?.idOrders
                        )}
                        onClick={(e) =>
                          handleCheckboxClick(e, category?.idOrders, category)
                        }
                      />
                    )}
                  </Box>
                </Grid>

                {/* Nama Invoice */}
                <Grid item xs={10}>
                  <Box>
                    <Typography
                      fontStyle={"normal"}
                      fontWeight={400}
                      fontSize={"12px"}
                      lineHeight={"156%"}
                      color={"#000"}
                    >
                      {category?.namaProduk}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {category?.subProduct?.map((items, idx) => (
                <Grid container columns={12} spacing={2} key={idx}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        pl: "12px",
                      }}
                    >
                      {/* Nama Product */}
                      <Grid xs={4}>
                        <Box>
                          <Typography
                            fontStyle={"normal"}
                            fontWeight={400}
                            fontSize={"12px"}
                            lineHeight={"195%"}
                            color={"#000000"}
                          >
                            {items.namaProduk}
                          </Typography>
                        </Box>
                      </Grid>

                      {/* Qty */}
                      <Grid xs={3}>
                        <Box>
                          <CustomInput
                            inputProps={{
                              min: 0,
                              sx: {
                                textAlign: "center",
                                borderRadius: "100px",
                                bgcolor: "#F5F5F5",
                              },
                            }}
                            sx={{
                              pb: "10px",
                              width: "65px",
                              pr: "10px",

                              "& .MuiOutlinedInput-input": {
                                padding: "5px 20px 5px 20px",
                                borderRadius: "5px",
                                "&::placeholder": {
                                  color: "#111",
                                  fontSize: "8px",
                                },
                              },
                              borderRadius: "5px",
                              marginRight: "10px",
                            }}
                            value={
                              changedProduct !== null &&
                              items?.idDetail ===
                                Number(changedProduct?.idDetail)
                                ? changedProduct?.qty
                                : items?.jumlah
                            }
                            onChange={(e) => {
                              const dataToEdit = {
                                qty: e.target.value,
                                unit: items?.unit,
                                idDetail: items?.idDetail,
                                idProduk: items?.idProduk,
                              };
                              handleEditProduct(dataToEdit);
                              setChangedProduct({
                                qty: e.target.value,
                                unit: items?.unit,
                                idDetail: items?.idDetail,
                                idProduk: items?.idProduk,
                              });
                            }}
                          />
                        </Box>
                      </Grid>

                      {/* Unit */}
                      <Grid xs={5}>
                        <Box>
                          <Select
                            value={items?.unit} //TODO: select unit
                            lineHeight={"100%"}
                            sx={{
                              p: 1,
                              marginRight: "35%",
                              minWidth: "100px",
                              height: "34px",
                              bgcolor: "white",
                              ".MuiOutlinedInput-input": {
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                paddingLeft: "0px",
                                paddingRight: "0",
                                borderRadius: "5px",
                              },
                            }}
                            onOpen={() => {
                              // setChangedProduct({
                              //   qty: details?.jumlah,
                              //   unit: details?.unit,
                              //   idDetail: details?.idDetail,
                              //   idProduk: details?.idProduk,
                              // });
                              // details?.idDetail ===
                              //   Number(changedProduct?.idDetail) &&
                              fetchProductUnit(items?.idProduk); //TODO : edit unit product
                            }}
                            onChange={(e) => {
                              const dataToEdit = {
                                unit: e.target.value,
                                qty: items?.jumlah,
                                idDetail: items?.idDetail,
                                idProduk: items?.idProduk,
                              };
                              handleEditProduct(dataToEdit);
                            }}
                          >
                            <MenuItem
                              value={items?.unit}
                              fontSize={"12px"}
                              sx={{ minWidth: "100px" }}
                            >
                              {items?.unit}
                            </MenuItem>
                            {unit?.map((item, idx) => (
                              <MenuItem
                                value={item?.unitName}
                                fontSize={"12px"}
                                key={idx}
                                sx={{
                                  minWidth: "100px",
                                  display:
                                    items?.unit === item?.unitName && "none",
                                }}
                              >
                                {item?.unitName}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              ))}

              {/* Add Button */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  ml: "10px",
                }}
                onClick={() => handleAddProduk(category?.idOrders)}
              >
                <Box
                  sx={{
                    bgcolor: "#51B15C1A",
                    borderRadius: "6px",
                    cursor: "pointer",
                  }}
                >
                  <Add
                    sx={{
                      color: "#51B15C",
                      fontSize: "10px",
                      width: "25px",
                      height: "18px",
                      pt: "5px",
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#51B15C",
                    cursor: "pointer",
                  }}
                >
                  Add More Product
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      {/*  Add Dialog */}
      <Dialog
        open={openConfirmn}
        onClose={() => setOpenConfirmn(!openConfirmn)}
      >
        <DialogTitle sx={{ boxShadow: "0px 0px 3px 0px #000" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>Add Product</Typography>
            </Box>
            <CloseIcon onClick={() => setOpenConfirmn(!openConfirmn)} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Typography sx={{ fontSize: "15px", fontWeight: 600, mb: 2 }}>
              Product
            </Typography>
            <Autocomplete
              sx={{ minWidth: "100%", bgcolor: "#FAFAFA" }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                inputDebounceChange(newInputValue);
              }}
              options={datas}
              getOptionLabel={(option) => option.namaProduk}
              value={selectedProduct}
              onChange={(event, newValue) => {
                setSelectedProduct(newValue);
                fetchProductUnit(newValue?.idProduk);
              }}
              loading={isLoadingProduct}
              renderInput={(params) => (
                <TextField {...params} placeholder="Product*" />
              )}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }}>
            <Typography sx={{ fontSize: "15px", fontWeight: 600, mb: 2 }}>
              Qty*
            </Typography>

            <CustomInput
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Box
                      sx={{
                        padding: "0px 10px",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    ></Box>
                    <Select
                      sx={{ minWidth: "120px", bgcolor: "white" }}
                      onChange={(e) => setUnitSelect(e.target.value)}
                    >
                      {unit?.length === 0 ? (
                        <MenuItem fontSize={"12px"}>no data</MenuItem>
                      ) : (
                        unit?.map((item, idx) => (
                          <MenuItem fontSize={"12px"} value={item} key={idx}>
                            {item?.unitName}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </InputAdornment>
                ),
              }}
              fullWidth
              placeholder="Enter Qty"
              sx={{
                with: "100%",
                bgcolor: "#FAFAFA",
                "& .MuiOutlinedInput-input": {
                  padding: "17px 16px",
                  borderRadius: "10px",
                  "&::placeholder": {
                    color: "#111",
                    fontSize: "16px",
                  },
                },
                borderRadius: "5px",
              }}
              onChange={(e) => {
                setQty(e.target.value);
              }}
            />
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: 2,
              mt: 5,
            }}
          >
            <CustomButton
              variant={"contained"}
              style={{ height: "40px", width: "100px" }}
              onClick={handleEnterProduct}
            >
              {!isLoadingProduct ? (
                <CircularProgress size={18} sx={{ color: "#FFF" }} />
              ) : (
                <Typography
                  fontStyle={"normal"}
                  fontWeight={500}
                  fontSize={"14px"}
                  lineHeight={"150%"}
                  color={"#fff"}
                >
                  Enter
                </Typography>
              )}
            </CustomButton>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AccordionStockout;
