import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import CustomAntSwitch from "./CustomAntSwitch";
import CustomButton from "./CustomButton";

const BottomMenu = ({
  handleGreen,
  isGreen = false, //* bottom green
  greenText,
  redText,
  handleRed,
  isRed = false,
  isPublish = false,
  publish,
  disableGreen = false,
  disableRed = false,
  handlePublish,
  ...props
}) => {
  const desktopMode = useMediaQuery("(min-width:832px)");
  return (
    <>
      {desktopMode && (
        <Box
          sx={{
            width: "100%",
            bgcolor: "white",
            position: "fixed",
            bottom: "0px",
            right: "0px",
            display: "flex",
            justifyContent: "space-between",
            padding: "18px 32px 18px 240px",
            alignItems: "center",

            ...props.sx,
          }}
        >
          {isPublish ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                Publish
              </Typography>
              <CustomAntSwitch checked={publish} onChange={handlePublish} />
            </Box>
          ) : (
            <Box></Box>
          )}
          <Box sx={{ display: "flex", gap: "20px" }}>
            {isRed && (
              <CustomButton
                color="error"
                variant="contained"
                sx={{ width: "140px", height: "36px" }}
                onClick={handleRed}
                disabled={disableRed}
              >
                <Typography
                  sx={{ color: "white", fontSize: "12px", fontWeight: "500" }}
                >
                  {redText}
                </Typography>
              </CustomButton>
            )}
            {isGreen && (
              <CustomButton
                variant="contained"
                sx={{ width: "140px", height: "36px" }}
                onClick={handleGreen}
                disabled={disableGreen}
              >
                <Typography
                  sx={{ color: "white", fontSize: "16px", fontWeight: 500 }}
                >
                  {greenText}
                </Typography>
              </CustomButton>
            )}
          </Box>
        </Box>
      )}
      {!desktopMode && (
        <Box
          sx={{
            width: "100%",
            bgcolor: "white",
            position: "fixed",
            bottom: "0px",
            right: "0px",
            p: "20px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid
            container
            columns={!isGreen || !isRed ? 1 : 2}
            columnSpacing={1}
          >
            {isRed && (
              <Grid item xs={1}>
                <CustomButton
                  variant="contained"
                  color={"error"}
                  sx={{ width: "100%", height: "40px" }}
                  onClick={handleRed}
                  disabled={disableRed}
                >
                  <Typography
                    sx={{ color: "white", fontSize: "14px", fontWeight: "500" }}
                  >
                    {redText}
                  </Typography>
                </CustomButton>
              </Grid>
            )}
            {isGreen && (
              <Grid item xs={1}>
                <CustomButton
                  variant="contained"
                  sx={{ width: "100%", height: "40px" }}
                  onClick={handleGreen}
                  disabled={disableGreen}
                >
                  <Typography
                    sx={{ color: "white", fontSize: "14px", fontWeight: "500" }}
                  >
                    {greenText}
                  </Typography>
                </CustomButton>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};
export default BottomMenu;
