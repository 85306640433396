import { createSlice } from '@reduxjs/toolkit';
import { getAllQrCode } from '../../../api/qrcode';

export const PurchaseProcessedAdminState = createSlice({
  name: 'purchaseProcessedAdmin',
  initialState: {
    scanned: [],
    idScanned: [],
    isExist: false,
    isLoading: false,
    totalData: 0,
    allData: [],
    noqr: [],
    noqrid: [],
    detail: {},
    isSelect: false,
    noqrDataSelect: [],
    url: null,
  },
  reducers: {
    scanSupplierAdmin: (state, action) => {
      const data = action.payload;
      if (!state.idScanned.includes(data.encript)) {
        state.idScanned = [...state.idScanned, data.encript];
        state.scanned = [...state.scanned, data];
        state.isExist = false;
      } else {
        state.isExist = true;
      }
    },
    loadNoqrFinance: (state, action) => {
      const { noqrDataSelect, url } = action.payload;
      if (
        !state.noqrid.includes(noqrDataSelect.map((item, idx) => item.idProduk))
      ) {
        state.noqrid = [
          ...state.noqrid,
          {
            photo_url: url,
            id_detail: noqrDataSelect.map((item) => item.idProduk),
          },
        ];
        state.noqr = [...state.noqr, noqrDataSelect];
        state.noqrDataSelect = [];
        state.status = true;
      } else {
        state.status = false;
      }
    },
    changeLoadingPurchaseScan: (state, action) => {
      state.isLoading = action.payload;
    },
    setTotalDataPurchaseScan: (state, action) => {
      state.totalData = action.payload;
    },
    setAllDataPurchaseScan: (state, action) => {
      state.allData = action.payload;
    },
    setDetailPurchase: (state, action) => {
      state.detail = action.payload;
    },

    changeIsSelectFinance: (state, action) => {
      state.isSelect = action.payload;
    },

    changeNoqrDataSelectFinance: (state, action) => {
      const { item, id } = action.payload;

      if (
        state.noqrDataSelect.filter((val) => val.idProduk === id).length === 0
      ) {
        state.noqrDataSelect = [...state.noqrDataSelect, item];
      } else {
        state.noqrDataSelect = state.noqrDataSelect.filter(
          (val) => val.idProduk !== id
        );
      }
    },

    changeUrlImageFinance: (state, action) => {
      state.url = action.payload;
    },
  },
});

export const {
  scanSupplierAdmin,
  loadNoqrFinance,
  changeLoadingPurchaseScan,
  setTotalDataPurchaseScan,
  setAllDataPurchaseScan,
  setDetailPurchase,
  changeNoqrDataSelectFinance,
  changeUrlImageFinance,
  changeIsSelectFinance,
} = PurchaseProcessedAdminState.actions;

export const getAllAdminScan = (state) => state.purchaseProcessedAdmin;

export const fetchAllDataPurchaseScanAdmin = (id) => (dispatch) => {
  const totalData = 100;
  const fetchTotalApi = async () => {
    dispatch(changeLoadingPurchaseScan(true));
    const params = {
      page: 1,
      pageSize: totalData,
    };
    try {
      const {
        data: { data },
      } = await getAllQrCode(params, id);
      dispatch(setAllDataPurchaseScan(data));
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  // const fetchApi = async () => {
  //   dispatch(changeLoadingPurchaseScan(true));
  //   const params = {
  //     page: 1,
  //   };
  //   try {
  //     const {
  //       data: {
  //         data: {
  //           pageInfo: { total },
  //         },
  //       },
  //     } = await getAllQrCode(params, id);
  //     totalData = total;
  //   } catch (err) {
  //     console.error(err);
  //   } finally {
  //     fetchTotalApi();
  //   }
  // };

  fetchTotalApi();
};

export default PurchaseProcessedAdminState.reducer;
