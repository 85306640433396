import { Grid, Typography } from '@mui/material';
import BackButton from '../../../../../../components/shared/BackButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuPerformance from '../Components/performance/MenuPerformance';
import TableMenuMenu from './Desktop/TableMenuMenu';
import { useTheme } from '@emotion/react';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';
import MenuMenuDesktop from './MenuMenuDesktop';
import MenuMenuMobile from './MenuMenuMobile';
import { useEffect, useState } from 'react';
import { fetchSubsidiary } from '../../../../../../api/menu';

const MenuMenu = (props) => {
  const {
    desktopMode,
    // *-- Hirarki
    roadHirarki,
    setRoadHirarki,
    // *-- Notif
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
    // *-- Page
    setPage,
    setMenuMenu,
    setMiniSubMenu,
    // *-date & time
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    // *-Tabel
    tableRowPerPageValues,
    tableRowPerPage,
    setTableRowPerPage,
    tableCurrentPage,
    setTableCurrentPage,
    tableTotalItems,
    setTableTotalItems,
    tableSearch,
    setTableSearch,
    sortTable,
    setSortTable,
    isSortAsc,
    setIsSortAsc,
    tableDatas,
    setTableDatas,
    tableCategoryFilterDatas,
    setTableCategoryFilterDatas,
    isLoadingData,
    // *-- Filter
    filterDataAwait,
    setFilterDataAwait,
  } = props;
  const theme = useTheme();

  const handleBackButton = () => {
    if (roadHirarki.subCategoryId !== 0) {
      setPage('subCategory');
      setRoadHirarki({
        ...roadHirarki,
        subCategory: '',
        subCategoryId: 0,
      });
    } else {
      setPage('category');
    }
  };
  const handleClickCategory = () => {
    setRoadHirarki({
      ...roadHirarki,
      category: '',
      categoryId: 0,
      subCategory: '',
      subCategoryId: 0,
    });
    setPage('category');
  };

  const handleClickRow = (name, id) => {
    setRoadHirarki({ ...roadHirarki, menu: name, menuId: id });
    setMenuMenu('details');
  };
  const handleClickMini = (event, id, name) => {
    event.stopPropagation();
    setRoadHirarki({
      ...roadHirarki,
      miniSubCategory: name,
      miniSubCategoryId: id,
    });
    setPage('miniSub');
    setMiniSubMenu('details');
  };

  const handleAddMenu = () => {
    setMenuMenu('add');
  };
  const handleAddMini = () => {
    setPage('miniSub');
    setMiniSubMenu('add');
  };

  const handleToSocialMedia = (e, url) => {
    e.stopPropagation();
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  return (
    <>
      <CustomNotifSuccess
        text={notifTitle}
        open={notif === 'addmini'}
        onClose={() => {
          setNotif('');
          setNotifTitle('');
        }}
      />
      <CustomNotifSuccess
        text={notifTitle}
        open={notif === 'deletemini'}
        onClose={() => {
          setNotif('');
          setNotifTitle('');
        }}
      />
      <CustomNotifSuccess
        text={notifTitle}
        open={notif === 'addmenu'}
        onClose={() => {
          setNotif('');
          setNotifTitle('');
        }}
      />
      <CustomNotifSuccess
        text={notifTitle}
        open={notif === 'deletemenu'}
        onClose={() => {
          setNotif('');
          setNotifTitle('');
        }}
      />
      {desktopMode ? (
        <MenuMenuDesktop
          {...{ ...props }}
          {...{ theme }}
          {...{
            handleBackButton,
            handleClickCategory,
            handleAddMenu,
            handleAddMini,
            handleClickMini,
            handleClickRow,
            handleToSocialMedia,
          }}
        />
      ) : (
        <MenuMenuMobile
          {...{ ...props }}
          {...{ theme }}
          {...{
            handleBackButton,
            handleClickCategory,
            handleAddMenu,
            handleAddMini,
            handleClickMini,
            handleClickRow,
            handleToSocialMedia,
          }}
        />
      )}
    </>
  );
};
export default MenuMenu;
