import React from "react";
import { useEffect } from "react";
import { getSearchStockout } from "../../../../../../store/stockout/stockout";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHistoryStock } from "../../../../../../api/stockout";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { IconNotes, IconUser } from "../../../../../../assets";
import CustomCard from "../../../../../../components/shared/CustomCard";
import CustomDialog from "../../../../../../components/shared/CustomDialog";

const HistoryCard = () => {
  const dispatch = useDispatch();
  const [desktopMode, setDesktopMode] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState([]);

  const search = useSelector(getSearchStockout);

  const openModal = (description) => {
    setModalContent(description);
    setIsModalOpen(true);
  };

  // --- Check Invoice ---
  useEffect(() => {
    const checkInvoiceHistoryStockout = async () => {
      if (search !== null) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getHistoryStock(search);

          setHistory(data);
        } catch {
          setHistory([]);
        } finally {
          setIsLoading(false);
        }
      }
    };
    checkInvoiceHistoryStockout();
  }, [search]);

  useEffect(() => {
    if (window.screen.width <= 830) {
      setDesktopMode(false);
    } else {
      setDesktopMode(true);
    }
  }, [window.screen.width]);

  return (
    <Box>
      {isLoading ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          my={"30px"}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {history &&
            history?.stockoutuse?.map((item, idx) => (
              <CustomCard key={idx} sx={{ padding: "16px", mb: "17px" }}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mb={"7px"}
                >
                  {/* --- Item Title --- */}
                  <Box display={"flex"} alignItems={"center"}>
                    <Typography
                      fontStyle={"normal"}
                      fontWeight={600}
                      fontSize={"12px"}
                      lineHeight={"18px"}
                      color={"#303030"}
                    >
                      {item.title}
                    </Typography>
                    {desktopMode ? (
                      ""
                    ) : (
                      <Typography
                        fontStyle={"normal"}
                        fontWeight={600}
                        fontSize={"12px"}
                        lineHeight={"18px"}
                        color={"#303030"}
                        ml={"4px"}
                      >
                        {(item?.qty !== null) & (item?.qty > 0)
                          ? `(${item?.qty}x)`
                          : ""}
                      </Typography>
                    )}
                  </Box>
                  {/* --- Item Name --- */}
                  <Box display={"flex"} alignItems={"center"}>
                    <IconUser />
                    <Typography
                      fontStyle={"normal"}
                      fontWeight={600}
                      fontSize={"12px"}
                      lineHeight={"18px"}
                      color={"#303030"}
                      ml={"4px"}
                    >
                      {item?.user?.namaLengkap}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  {/* --- Item Description --- */}
                  {desktopMode ? (
                    <Typography
                      fontStyle={"normal"}
                      fontWeight={600}
                      fontSize={"12px"}
                      lineHeight={"18px"}
                      color={"#303030"}
                      ml={"4px"}
                    >
                      {(item?.qty !== null) & (item?.qty > 0)
                        ? `(${item?.qty}x)`
                        : ""}
                    </Typography>
                  ) : (
                    <Box display={"flex"} alignItems={"center"}>
                      <IconButton
                        sx={{ p: "0px" }}
                        onClick={() => openModal(item?.keterangan)}
                      >
                        <IconNotes />
                      </IconButton>
                      <Typography
                        fontStyle={"normal"}
                        fontWeight={500}
                        fontSize={"10px"}
                        lineHeight={"15px"}
                        color={"#51b15c"}
                        ml={"4px"}
                      >
                        Notes
                      </Typography>
                    </Box>
                  )}

                  {/* --- Item Date --- */}
                  <Typography
                    fontStyle={"normal"}
                    fontWeight={400}
                    fontSize={"10px"}
                    lineHeight={"15px"}
                    color={"#9e9d9d"}
                    ml={"4px"}
                  >
                    {item?.createdAtTime}
                  </Typography>
                </Box>
              </CustomCard>
            ))}
        </Box>
      )}
      <CustomDialog
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={"Notes"}
        fullWidth
      >
        <Box p={"20px 22px"}>
          <Typography
            fontStyle={"normal"}
            fontWeight={400}
            fontSize={"12px"}
            lineHeight={"18px"}
            textAlign={"justify"}
            color={"#000"}
          >
            {modalContent}
          </Typography>
        </Box>
      </CustomDialog>
    </Box>
  );
};

export default HistoryCard;
