import { Box, Dialog, Typography } from '@mui/material';
import { useState } from 'react';
import { ReactComponent as NoImage } from '../../assets/no-image.svg';

const PhotoView = ({ data }) => {
  const [isPreview, setIsPreview] = useState(false);
  const [link, setLink] = useState('');
  const handlePreviewPhoto = (link) => {
    setIsPreview(true);
    setLink(link);
  };

  const handleClosePreviewPhoto = () => {
    setLink('');
    setIsPreview(false);
  };
  return (
    <Box>
      <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
        Photo
      </Typography>
      <Box
        sx={{
          mt: '12px',
          width: '100%',
          padding: '0',
          display: 'flex',
        }}
      >
        {data.length === 0 ? (
          <Box
            sx={{
              width: '54px',
              height: '54px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: '#FAFAFA',
              borderRadius: '2px',
            }}
          >
            <NoImage />
            <Typography sx={{ color: '#9E9D9D', fontSize: '6px' }}>
              No photo
            </Typography>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', gap: 2 }}>
            {data.map((item, index) => (
              <Box onClick={() => handlePreviewPhoto(item)} key={index}>
                <img
                  src={process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + item}
                  style={{
                    width: '54px',
                    height: '54px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                  alt="Pic_dtl"
                />
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <Dialog open={isPreview} onClose={handleClosePreviewPhoto}>
        <Box
          component="img"
          src={process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + link}
          sx={{ width: '600px', height: '600px', aspectRation: '1/1' }}
        />
      </Dialog>
    </Box>
  );
};
export default PhotoView;
