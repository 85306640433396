import { createSlice } from "@reduxjs/toolkit";
import { deleteFile, uploadImage, uploadVideo } from "../../../api/storage";
import {
  getPlacesByCoordinate,
  getPlacesByName,
} from "../../../api/AddressMap";

export const miniHubAddState = createSlice({
  name: "miniHubAdd",
  initialState: {
    isLoading: false,
    status: false,
    idToko: null,
    subsidiaryId: null,
    subHubId: "",

    // name
    name: "",

    // image
    image: [],
    isMaxSizeImage: false,
    imagePreview: "",
    isImagePreview: false,
    isUploadImage: false,

    // video
    video: [],
    isMaxSizeVideo: false,
    videoPreview: "",
    isVideoPreview: false,
    isUploadVideo: false,

    // PIC & number
    picChoose: null,
    picSearch: "",
    picSearchDebounce: "",
    picPhone: "",

    // address
    address: "",
    addressFix: "",
    coordinateFix: [],
    mapInputType: "manual",
    placeGetByName: null,
    coordinate: [],
    center: [3.546728899138594, 98.69014558305145],
    placesSearchByName: [],
    placesSearchByNameLoading: false,
    placeGetByCoordinate: null,
    placeGetByCoordinateLoading: false,

    // rack map
    isCreateMap: false,
    createMapPage: "grid",
    col: 5,
    row: 5,
    cell: [],
    isEntrance: false,
    entrance: [],
    cellSelectedAll: [],
    cellSelected: [],
    cellSelectedDetails: [],
    cellSelectedMoment: [],
    cellSelectedNameMoment: "",
    cellSelectedColMoment: 5,
    cellSelectedRowMoment: 5,
    cellSelectedDesMoment: "",
    cellSelectedHover: [],
    cellSelectedEditTemporary: {},
    isCellSelectedEdit: false,
    idDetailHover: 0,
    dataDetailRack: null,
    errorEmpty: "",
    errorMassageVideo: "",
  },
  reducers: {
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    changeMiniHubName: (state, action) => {
      state.name = action.payload;
    },
    changeIdToko: (state, action) => {
      state.idToko = action.payload;
    },
    changeSubsidiaryId: (state, action) => {
      state.subsidiaryId = action.payload;
    },
    changeSubHubId: (state, action) => {
      state.subHubId = action.payload;
    },

    // image

    inputImage: (state, action) => {
      state.image = [...state.image, action.payload];
    },
    deleteImage: (state, action) => {
      state.image = state.image.filter((item) => item !== action.payload);
    },
    changeIsMaxSizeMiniHubImage: (state, action) => {
      state.isMaxSizeImage = action.payload;
    },
    changeImagePreview: (state, action) => {
      state.imagePreview = action.payload;
    },
    clickIsPreviewImage: (state) => {
      state.isImagePreview = !state.isImagePreview;
    },
    changeIsUploadImageHub: (state, action) => {
      state.isUploadImage = action.payload;
    },

    // video
    inputVideo: (state, action) => {
      state.video = [...state.video, action.payload];
    },
    deleteVideo: (state, action) => {
      state.video = state.video.filter((item) => item !== action.payload);
    },
    changeIsMaxSizeVideo: (state, action) => {
      state.isMaxSizeVideo = action.payload;
    },
    changeVideoPreview: (state, action) => {
      state.videoPreview = action.payload;
    },
    clickIsVideoPreview: (state, action) => {
      state.isVideoPreview = !state.isVideoPreview;
    },
    changeIsUploadVideoHub: (state, action) => {
      state.isUploadVideo = action.payload;
    },

    // PIC
    changePicChoose: (state, action) => {
      state.picChoose = action.payload;
    },
    changePicSearch: (state, action) => {
      state.picSearch = action.payload;
    },
    changePicSearchDebounce: (state, action) => {
      state.picSearchDebounce = action.payload;
    },
    changePicPhone: (state, action) => {
      state.picPhone = action.payload;
    },

    // address
    changeMapTypeMiniHub: (state, action) => {
      state.mapInputType = action.payload;
    },
    changePlaceGetByName: (state, action) => {
      let result = action.payload;
      state.placeGetByName = result;
      state.coordinate = [result.lat, result.lon];
      state.center = [result.lat, result.lon];
    },
    changeAddress: (state, action) => {
      let result = action.payload;
      state.address = result;
    },
    submitAddresMap: (state, action) => {
      const { address, coordinate } = action.payload;
      state.addressFix = address;
      state.coordinateFix = coordinate;
    },
    loadPlaceSearchByNameLoading: (state, action) => {
      state.placesSearchByNameLoading = action.payload;
    },
    loadPlaceSearchByName: (state, action) => {
      state.placesSearchByName = action.payload;
    },
    changeIsPlaceGetByCoordinateLoading: (state, action) => {
      let result = action.payload;
      state.placeGetByCoordinateLoading = result;
    },
    changePlaceGetByCoordinate: (state, action) => {
      let result = action.payload;
      state.placeGetByCoordinate = result;
    },
    changeCoordinate: (state, action) => {
      state.coordinate = action.payload;
    },
    changeCenter: (state, action) => {
      state.center = action.payload;
    },

    // Rack map
    changeIsCreateMap: (state) => {
      state.isCreateMap = !state.isCreateMap;
    },
    changeCreateMapPage: (state, action) => {
      state.createMapPage = action.payload;
    },
    setUpCell: (state) => {
      let nilai = [...Array(state.row + 2)].map((_, idxr) => {
        let v = [...Array(state.col + 2)].map((_, idxc) => {
          return `r${idxr + 1}c${idxc + 1}`;
        });
        return v;
      });
      state.cell = nilai;
    },
    changeColCreateMap: (state, action) => {
      if (action.payload <= 50 && action.payload > 0) {
        state.col = action.payload;
      }
    },
    changeRowCreateMap: (state, action) => {
      if (action.payload <= 50 && action.payload > 0) {
        state.row = action.payload;
      }
    },
    changeIsEntrance: (state) => {
      if (state.isEntrance) {
        state.entrance = [];
      }
      state.isEntrance = !state.isEntrance;
    },
    addRowCreateMapTop: (state, action) => {
      if (state.row < 50 && state.row > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row + 2 + action.payload)].map(
          (_, idxr) => {
            let v = [...Array(state.col + 2)].map((_, idxc) => {
              return `r${idxr + 1}c${idxc + 1}`;
            });
            return v;
          }
        );
        state.row = state.row + action.payload;
        state.cell = nilai;
        let dataCell = state.cellSelected.map((item) => {
          let dataChild = item.map((v) => {
            let child = v.split("r")[1].split("c");
            let childValue = `r${Number(child[0]) + action.payload}c${Number(
              child[1]
            )}`;
            return childValue;
          });
          return dataChild;
        });
        let dataCellDetails = state.cellSelectedDetails.map((item) => {
          let child = item.columnPosition.map((v) => {
            let cols = v.split("r")[1].split("c");
            let colsValue = `r${Number(cols[0]) + action.payload}c${Number(
              cols[1]
            )}`;
            return colsValue;
          });
          return {
            ...item,
            columnPosition: child,
          };
        });
        let dataCellMoment = state.cellSelectedMoment.map((item) => {
          let child = item.split("r")[1].split("c");
          let childValue = `r${Number(child[0]) + action.payload}c${Number(
            child[1]
          )}`;
          return childValue;
        });
        state.cellSelectedMoment = dataCellMoment;
        state.cellSelected = dataCell;
        state.cellSelectedDetails = dataCellDetails;
        state.cellSelectedAll = state.cellSelected.flat();
      }
    },
    reduceRowCreateMapTop: (state, action) => {
      if (state.row <= 50 && state.row > 1) {
        if (
          state.cellSelectedAll.filter(
            (item) => item.split("r")[1].split("c")[0] === "2"
          ).length === 0 &&
          state.cellSelectedMoment.filter(
            (item) => item.split("r")[1].split("c")[0] === "2"
          ).length === 0
        ) {
          state.entrance = [];
          let nilai = [...Array(state.row + 2 - action.payload)].map(
            (_, idxr) => {
              let v = [...Array(state.col + 2)].map((_, idxc) => {
                return `r${idxr + 1}c${idxc + 1}`;
              });
              return v;
            }
          );
          state.row = state.row - action.payload;
          state.cell = nilai;
          let dataCell = state.cellSelected.map((item) => {
            let dataChild = item.map((v) => {
              let child = v.split("r")[1].split("c");
              let childValue = `r${Number(child[0]) - action.payload}c${Number(
                child[1]
              )}`;
              return childValue;
            });
            return dataChild;
          });
          let dataCellDetails = state.cellSelectedDetails.map((item) => {
            let child = item.columnPosition.map((v) => {
              let cols = v.split("r")[1].split("c");
              let colsValue = `r${Number(cols[0]) - action.payload}c${Number(
                cols[1]
              )}`;
              return colsValue;
            });
            return {
              ...item,
              columnPosition: child,
            };
          });
          let dataCellMoment = state.cellSelectedMoment.map((item) => {
            let child = item.split("r")[1].split("c");
            let childValue = `r${Number(child[0]) - action.payload}c${Number(
              child[1]
            )}`;
            return childValue;
          });
          state.cellSelectedMoment = dataCellMoment;
          state.cellSelected = dataCell;
          state.cellSelectedDetails = dataCellDetails;
          state.cellSelectedAll = state.cellSelected.flat();
        }
      }
    },
    addRowCreateMapBottom: (state, action) => {
      if (state.row < 50 && state.row > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row + 2 + action.payload)].map(
          (_, idxr) => {
            let v = [...Array(state.col + 2)].map((_, idxc) => {
              return `r${idxr + 1}c${idxc + 1}`;
            });
            return v;
          }
        );

        state.row = state.row + action.payload;
        state.cell = nilai;
      }
    },
    reduceRowCreateMapBottom: (state, action) => {
      if (state.row <= 50 && state.row > 1) {
        let limit = `r${state.row + 2 - action.payload}`;
        if (
          state.cellSelectedAll.filter((item) => item.includes(limit))
            .length === 0 &&
          state.cellSelectedMoment.filter((item) => item.includes(limit))
            .length === 0
        ) {
          state.entrance = [];
          let nilai = [...Array(state.row + 2 - action.payload)].map(
            (_, idxr) => {
              let v = [...Array(state.col + 2)].map((_, idxc) => {
                return `r${idxr + 1}c${idxc + 1}`;
              });
              return v;
            }
          );
          let entranceNew = state.entrance.map((ent) => {
            let valueArr = ent.split("r")[1].split("c");
            return `r${Number(valueArr[0]) - 1}c${Number(valueArr[1])}`;
          });
          state.entrance = entranceNew;
          state.row = state.row - action.payload;
          state.cell = nilai;
        }
      }
    },
    addColCreateMapLeft: (state, action) => {
      if (state.col < 50 && state.col > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row + 2)].map((_, idxr) => {
          let v = [...Array(state.col + 2 + action.payload)].map((_, idxc) => {
            return `r${idxr + 1}c${idxc + 1}`;
          });
          return v;
        });
        state.col = state.col + action.payload;
        state.cell = nilai;
        let dataCell = state.cellSelected.map((item) => {
          let dataChild = item.map((v) => {
            let child = v.split("r")[1].split("c");
            let childValue = `r${Number(child[0])}c${
              Number(child[1]) + action.payload
            }`;
            return childValue;
          });
          return dataChild;
        });
        let dataCellDetails = state.cellSelectedDetails.map((item) => {
          let child = item.columnPosition.map((v) => {
            let cols = v.split("r")[1].split("c");
            let colsValue = `r${Number(cols[0])}c${
              Number(cols[1]) + action.payload
            }`;
            return colsValue;
          });
          return {
            ...item,
            columnPosition: child,
          };
        });
        let dataCellMoment = state.cellSelectedMoment.map((item) => {
          let child = item.split("r")[1].split("c");
          let childValue = `r${Number(child[0])}c${
            Number(child[1]) + action.payload
          }`;
          return childValue;
        });
        state.cellSelectedMoment = dataCellMoment;
        state.cellSelected = dataCell;
        state.cellSelectedDetails = dataCellDetails;
        state.cellSelectedAll = state.cellSelected.flat();
      }
    },
    reduceColCreateMapLeft: (state, action) => {
      if (state.col <= 50 && state.col > 1) {
        if (
          state.cellSelectedAll.filter(
            (item) => item.split("r")[1].split("c")[1] === "2"
          ).length === 0 &&
          state.cellSelectedMoment.filter(
            (item) => item.split("r")[1].split("c")[1] === "2"
          ).length === 0
        ) {
          state.entrance = [];
          let nilai = [...Array(state.row + 2)].map((_, idxr) => {
            let v = [...Array(state.col + 2 - action.payload)].map(
              (_, idxc) => {
                return `r${idxr + 1}c${idxc + 1}`;
              }
            );
            return v;
          });
          state.col = state.col - action.payload;
          state.cell = nilai;
          let dataCell = state.cellSelected.map((item) => {
            let dataChild = item.map((v) => {
              let child = v.split("r")[1].split("c");
              let childValue = `r${Number(child[0])}c${
                Number(child[1]) - action.payload
              }`;
              return childValue;
            });
            return dataChild;
          });
          let dataCellDetails = state.cellSelectedDetails.map((item) => {
            let child = item.columnPosition.map((v) => {
              let cols = v.split("r")[1].split("c");
              let colsValue = `r${Number(cols[0])}c${
                Number(cols[1]) - action.payload
              }`;
              return colsValue;
            });
            return {
              ...item,
              columnPosition: child,
            };
          });
          let dataCellMoment = state.cellSelectedMoment.map((item) => {
            let child = item.split("r")[1].split("c");
            let childValue = `r${Number(child[0])}c${
              Number(child[1]) - action.payload
            }`;
            return childValue;
          });
          state.cellSelectedMoment = dataCellMoment;
          state.cellSelected = dataCell;
          state.cellSelectedDetails = dataCellDetails;
          state.cellSelectedAll = state.cellSelected.flat();
        }
      }
    },
    addColCreateMapRight: (state, action) => {
      if (state.col < 50 && state.col > 0) {
        state.entrance = [];
        let nilai = [...Array(state.row + 2)].map((_, idxr) => {
          let v = [...Array(state.col + 2 + action.payload)].map((_, idxc) => {
            return `r${idxr + 1}c${idxc + 1}`;
          });
          return v;
        });

        state.col = state.col + action.payload;
        state.cell = nilai;
      }
    },
    reduceColCreateMapRight: (state, action) => {
      if (state.col <= 50 && state.col > 1) {
        let limit = `c${state.col + 2 - action.payload}`;
        if (
          state.cellSelectedAll.filter((item) => item.includes(limit))
            .length === 0 &&
          state.cellSelectedMoment.filter((item) => item.includes(limit))
            .length === 0
        ) {
          state.entrance = [];
          let nilai = [...Array(state.row + 2)].map((_, idxr) => {
            let v = [...Array(state.col + 2 - action.payload)].map(
              (_, idxc) => {
                return `r${idxr + 1}c${idxc + 1}`;
              }
            );
            return v;
          });

          state.col = state.col - action.payload;
          state.cell = nilai;
        }
      }
    },
    addEntrance: (state, action) => {
      if (state.isEntrance) {
        if (state.entrance.includes(action.payload)) {
          state.entrance = state.entrance.filter((v) => v !== action.payload);
        } else {
          state.entrance = [...state.entrance, action.payload];
        }
      }
    },
    addCellSelectedMoment: (state, action) => {
      if (state.cellSelectedAll.includes(action.payload)) {
      } else {
        if (state.cellSelectedMoment.includes(action.payload)) {
          state.cellSelectedMoment = state.cellSelectedMoment.filter(
            (v) => v !== action.payload
          );
        } else {
          state.cellSelectedMoment = [
            ...state.cellSelectedMoment,
            action.payload,
          ];
        }
      }
    },
    addCellSelectedMomentHold: (state, action) => {
      if (state.cellSelectedMoment.includes(action.payload)) {
      } else {
        if (state.cellSelectedMoment.includes(action.payload)) {
        } else {
          state.cellSelectedMoment = [
            ...state.cellSelectedMoment,
            action.payload,
          ];
        }
      }
    },
    changeNameCellSelectedMoment: (state, action) => {
      state.cellSelectedNameMoment = action.payload;
    },
    changeColCellSelectedMoment: (state, action) => {
      if (action.payload > 0 && action.payload <= 50) {
        state.cellSelectedColMoment = action.payload;
      }
    },
    changeRowCellSelectedMoment: (state, action) => {
      if (action.payload > 0 && action.payload <= 50) {
        state.cellSelectedRowMoment = action.payload;
      }
    },
    changeDesCellSelectedMoment: (state, action) => {
      state.cellSelectedDesMoment = action.payload;
    },
    submitEditCellFix: (state) => {
      let data = {
        id: state.cellSelectedEditTemporary.id,
        name: state.cellSelectedNameMoment,
        column: state.cellSelectedColMoment,
        row: state.cellSelectedRowMoment,
        description: state.cellSelectedDesMoment,
        columnPosition: state.cellSelectedMoment,
      };
      state.cellSelected = [...state.cellSelected, state.cellSelectedMoment];
      state.cellSelectedDetails = [...state.cellSelectedDetails, data];
      state.cellSelectedNameMoment = "";
      state.cellSelectedColMoment = 5;
      state.cellSelectedRowMoment = 5;
      state.cellSelectedDesMoment = "";
      state.cellSelectedMoment = [];
      state.cellSelectedEditTemporary = {};
      state.isCellSelectedEdit = false;
      state.cellSelectedAll = state.cellSelected.flat();
    },
    cancelEditCellFix: (state) => {
      state.cellSelected = [
        ...state.cellSelected,
        state.cellSelectedEditTemporary.columnPosition,
      ];
      state.cellSelectedDetails = [
        ...state.cellSelectedDetails,
        state.cellSelectedEditTemporary,
      ];
      state.cellSelectedNameMoment = "";
      state.cellSelectedColMoment = 5;
      state.cellSelectedRowMoment = 5;
      state.cellSelectedDesMoment = "";
      state.cellSelectedMoment = [];
      state.cellSelectedEditTemporary = {};
      state.isCellSelectedEdit = false;
      state.cellSelectedAll = state.cellSelected.flat();
    },
    makeCellFix: (state) => {
      let data = {
        id: state.cellSelectedDetails.length + 1,
        name: state.cellSelectedNameMoment,
        column: state.cellSelectedColMoment,
        row: state.cellSelectedRowMoment,
        description: state.cellSelectedDesMoment,
        columnPosition: state.cellSelectedMoment,
      };
      state.cellSelected = [...state.cellSelected, state.cellSelectedMoment];
      state.cellSelectedDetails = [...state.cellSelectedDetails, data];
      state.cellSelectedNameMoment = "";
      state.cellSelectedColMoment = 5;
      state.cellSelectedRowMoment = 5;
      state.cellSelectedDesMoment = "";
      state.cellSelectedMoment = [];
      state.cellSelectedAll = state.cellSelected.flat();
    },
    hoverCellFix: (state, action) => {
      let data = state.cellSelected.find((_, idx) => idx === action.payload);
      let detail = state.cellSelectedDetails.find(
        (_, idx) => idx === action.payload
      );
      state.cellSelectedHover = data;
      state.idDetailHover = detail.id;
    },
    unHoverCellFix: (state) => {
      state.cellSelectedHover = [];
      state.idDetailHover = 0;
    },
    deleteCellFix: (state, action) => {
      state.cellSelected = state.cellSelected.filter(
        (_, idx) => idx !== action.payload
      );
      state.cellSelectedDetails = state.cellSelectedDetails.filter(
        (_, idx) => idx !== action.payload
      );
      state.cellSelectedAll = state.cellSelected.flat();
    },
    editCellFix: (state, action) => {
      let data = state.cellSelectedDetails.find(
        (_, idx) => idx === action.payload
      );
      state.isCellSelectedEdit = true;
      state.cellSelectedEditTemporary = data;
      state.cellSelected = state.cellSelected.filter(
        (_, idx) => idx !== action.payload
      );
      state.cellSelectedDetails = state.cellSelectedDetails.filter(
        (_, idx) => idx !== action.payload
      );
      state.cellSelectedAll = state.cellSelected.flat();

      state.cellSelectedNameMoment = data.name;
      state.cellSelectedColMoment = data.column;
      state.cellSelectedRowMoment = data.row;
      state.cellSelectedDesMoment = data.description;
      state.cellSelectedMoment = data.columnPosition;
    },
    unSeeDetailRack: (state) => {
      state.dataDetailRack = null;
    },
    seeDetailRack: (state) => {
      if (state.idDetailHover !== 0) {
        state.dataDetailRack = state.cellSelectedDetails.find(
          (detail) => detail.id === state.idDetailHover
        );
      }
    },
    hoverRacks: (state, action) => {
      if (state.cellSelectedAll.includes(action.payload)) {
        let data = state.cellSelected.find((item) =>
          item.includes(action.payload)
        );
        let detals = state.cellSelectedDetails.find((item) =>
          item.columnPosition.includes(action.payload)
        );
        state.cellSelectedHover = data;
        state.idDetailHover = detals.id;
      }
    },
    unHoverRacks: (state) => {
      state.cellSelectedHover = [];
      state.idDetailHover = 0;
    },

    // status
    changeStatus: (state) => {
      state.status = !state.status;
    },
    changeErrorEmptyMiniHub: (state, action) => {
      state.errorEmpty = action.payload;
    },

    changeErrorMassageVideo: (state, action) => {
      state.errorMassageVideo = action.payload;
    },

    clearData: (state) => {
      state.isLoading = false;
      state.status = false;
      state.idToko = null;
      state.subsidiaryId = null;

      // name
      state.name = "";

      // image
      state.image = [];
      state.isMaxSizeImage = false;
      state.imagePreview = "";
      state.isImagePreview = false;

      // video
      state.video = [];
      state.isMaxSizeVideo = false;
      state.videoPreview = "";
      state.isVideoPreview = false;

      // PIC & number
      state.picChoose = null;
      state.picSearch = "";
      state.picSearchDebounce = "";
      state.picPhone = "";

      // address
      state.address = "";
      state.addressFix = "";
      state.coordinateFix = [];
      state.mapInputType = "manual";
      state.placeGetByName = null;
      state.coordinate = [];
      state.center = [3.546728899138594, 98.69014558305145];
      state.placesSearchByName = [];
      state.placesSearchByNameLoading = false;
      state.placeGetByCoordinate = null;
      state.placeGetByCoordinateLoading = false;

      // rack map
      state.isCreateMap = false;
      state.createMapPage = "grid";
      state.col = 5;
      state.row = 5;
      state.cell = [];
      state.isEntrance = false;
      state.entrance = [];
      state.cellSelectedAll = [];
      state.cellSelected = [];
      state.cellSelectedDetails = [];
      state.cellSelectedMoment = [];
      state.cellSelectedNameMoment = "";
      state.cellSelectedColMoment = 5;
      state.cellSelectedRowMoment = 5;
      state.cellSelectedDesMoment = "";
      state.cellSelectedHover = [];
      state.cellSelectedEditTemporary = {};
      state.isCellSelectedEdit = false;
      state.idDetailHover = 0;
      state.dataDetailRack = null;
    },
  },
});

// export reducer miniHubAddState
export const {
  changeIsLoading,
  changeIdToko,
  changeStatus,
  changeSubsidiaryId,
  changeSubHubId,

  // name
  changeMiniHubName,

  // image
  inputImage,
  changeIsMaxSizeMiniHubImage,
  changeImagePreview,
  clickIsPreviewImage,
  deleteImage,
  changeIsUploadImageHub,

  // video
  inputVideo,
  changeIsMaxSizeVideo,
  changeVideoPreview,
  clickIsVideoPreview,
  deleteVideo,
  changeIsUploadVideoHub,

  // pic & number
  changePicPhone,
  changePicChoose,
  changePicSearch,
  changePicSearchDebounce,

  // address
  changeMapTypeMiniHub,
  changePlaceGetByName,
  changeAddress,
  submitAddresMap,
  loadPlaceSearchByNameLoading,
  loadPlaceSearchByName,
  changeIsPlaceGetByCoordinateLoading,
  changePlaceGetByCoordinate,
  changeCoordinate,
  changeCenter,

  // rack map
  changeIsCreateMap,
  changeCreateMapPage,
  setUpCell,
  changeColCreateMap,
  changeRowCreateMap,
  changeIsEntrance,
  addRowCreateMapTop,
  reduceRowCreateMapTop,
  addRowCreateMapBottom,
  reduceRowCreateMapBottom,
  addColCreateMapLeft,
  reduceColCreateMapLeft,
  addColCreateMapRight,
  reduceColCreateMapRight,
  addEntrance,
  addCellSelectedMoment,
  addCellSelectedMomentHold,
  changeNameCellSelectedMoment,
  changeColCellSelectedMoment,
  changeRowCellSelectedMoment,
  changeDesCellSelectedMoment,
  submitEditCellFix,
  cancelEditCellFix,
  makeCellFix,
  hoverCellFix,
  unHoverCellFix,
  deleteCellFix,
  editCellFix,
  unSeeDetailRack,
  seeDetailRack,
  hoverRacks,
  unHoverRacks,
  changeErrorEmptyMiniHub,
  clearData,
  changeErrorMassageVideo,
} = miniHubAddState.actions;

export const getMiniHubAddAllState = (state) => state.miniHubAdd;
export const getMapTypeMiniHubAdd = (state) => state.miniHubAdd.mapInputType;
export const getIsLoadigMiniHub = (state) => state.miniHubAdd.isLoading;
export const getMinihubName = (state) => state.miniHubAdd.name;
export const getIdToko = (state) => state.miniHubAdd.idToko;
export const getSubsidiaryIdMiniHub = (state) => state.miniHubAdd.subsidiaryId;
export const getSubHubId = (state) => state.miniHubAdd.subHubId;

// add mini hub

// image
export const getImagePreviewMiniHubAdd = (state) =>
  state.miniHubAdd.imagePreview;
export const getIsImagePreviewMiniHubAdd = (state) =>
  state.miniHubAdd.isImagePreview;
export const inputMiniHubImage = (e) => (dispatch) => {
  const img = e.target.files[0];
  if (img.size > 2000000) {
    dispatch(changeIsMaxSizeMiniHubImage(true));
  } else {
    dispatch(changeIsUploadImageHub(true));
    let datas = {
      image: img,
      resource: "miniHub",
    };
    uploadImage(datas).then((res) => {
      dispatch(inputImage(res.data.data.path));
      dispatch(changeIsMaxSizeMiniHubImage(false));
      dispatch(changeIsUploadImageHub(false));
    });
  }
};
export const getMiniHubImage = (state) => state.miniHubAdd.image;
export const getIsMaxSizeImageMiniHubAdd = (state) =>
  state.miniHubAdd.isMaxSizeImage;
// export const deleteMiniHubImage = (link) => (dispatch) => {
//   deleteFile(link).then(() => {
//     dispatch(deleteImage(link));
//   });
// };
export const deleteMiniHubImage = (link) => (dispatch) => {
  dispatch(deleteImage(link));
};

// video
export const getVideoMiniHubAdd = (state) => state.miniHubAdd.video;
export const getIsMaxSizeVideoMiniHubAdd = (state) =>
  state.miniHubAdd.isMaxSizeVideo;
export const getVideoPreviewMiniHubAdd = (state) =>
  state.miniHubAdd.videoPreview;
export const getIsVideoPreviewMiniHubAdd = (state) =>
  state.miniHubAdd.isVideoPreview;
export const inputVideoPreview = (e) => (dispatch) => {
  const vd = e.target.files[0];
  if (vd.size > 1000000000) {
    dispatch(changeIsMaxSizeVideo(true));
  } else {
    dispatch(changeIsUploadVideoHub(true));
    dispatch(changeIsLoading(true));
    let datas = {
      video: vd,
      resource: "miniHub",
    };
    try {
      uploadVideo(datas)
        .then((res) => {
          dispatch(inputVideo(res.data.data.path));
          dispatch(changeIsMaxSizeVideo(false));
          dispatch(changeIsLoading(false));
          dispatch(changeIsUploadVideoHub(false));
          dispatch(changeErrorMassageVideo(""));
        })
        .catch((error) => {
          dispatch(
            changeErrorMassageVideo("hanya format .mp4 yang di izinkan")
          );
          dispatch(changeIsLoading(false));
        });
    } catch (error) {
      // console.log(error);
    }
  }
};
// export const deleteVideoPreview = (link) => (dispatch) => {
//   deleteFile(link).then(() => {
//     dispatch(deleteVideo(link));
//   });
// };
export const deleteVideoPreview = (link) => (dispatch) => {
  dispatch(deleteVideo(link));
};

// PiC

export const getPicChooseMiniHubAdd = (state) => state.miniHubAdd.picChoose;
export const getPicSearchMiniHubAdd = (state) => state.miniHubAdd.picSearch;
export const getPicSearchDebounceMiniHubAdd = (state) =>
  state.miniHubAdd.picSearchDebounce;
export const getPicPhoneMiniHubAdd = (state) => state.miniHubAdd.picPhone;

// address
export const getIsCreateMap = (state) => state.miniHubAdd.isCreateMap;
export const getAddresMiniHub = (state) => state.miniHubAdd.address;
export const getAddressFix = (state) => state.miniHubAdd.addressFix;
export const getCoordinateeMiniHubAdd = (state) => state.miniHubAdd.coordinate;
export const getCenterMiniHubAdd = (state) => state.miniHubAdd.center;
export const getPlaceGetByName = (state) => state.miniHubAdd.placeGetByName;
export const getPlacesSearchByName = (state) =>
  state.miniHubAdd.placesSearchByName;
export const getLoadingPlacesSearchByName = (state) =>
  state.miniHubAdd.placesSearchByNameLoading;
export const getPlaceGetByCoorninate = (state) =>
  state.miniHubAdd.placeGetByCoordinate;
export const getLaodingPlaceGetByCoorninate = (state) =>
  state.miniHubAdd.placeGetByCoordinateLoading;

// rack map
export const getErrorEmptyMiniHub = (state) => state.miniHubAdd.errorEmpty;
export const getCreateMapPage = (state) => state.miniHubAdd.createMapPage;
export const getColCreateMap = (state) => state.miniHubAdd.col;
export const getRowCreateMap = (state) => state.miniHubAdd.row;
export const getCellCreateMap = (state) => state.miniHubAdd.cell;
export const getIsEntranceCreateMap = (state) => state.miniHubAdd.isEntrance;
export const getEntranceCreateMap = (state) => state.miniHubAdd.entrance;
export const getCellSelectedAllCreateMap = (state) =>
  state.miniHubAdd.cellSelectedAll;
export const getCellSelectedHoverCreateMap = (state) =>
  state.miniHubAdd.cellSelectedHover;
export const getIsCellSelectedEditCreateMap = (state) =>
  state.miniHubAdd.isCellSelectedEdit;
export const getCellSelectedMomentCreateMap = (state) =>
  state.miniHubAdd.cellSelectedMoment;
export const getCellSelectedDetailsCreateMap = (state) =>
  state.miniHubAdd.cellSelectedDetails;
export const getCellSelectedNameMomentCreateMap = (state) =>
  state.miniHubAdd.cellSelectedNameMoment;
export const getCellSelectedColMomentCreateMap = (state) =>
  state.miniHubAdd.cellSelectedColMoment;
export const getCellSelectedRowMomentCreateMap = (state) =>
  state.miniHubAdd.cellSelectedRowMoment;
export const getCellSelectedDesMomentCreateMap = (state) =>
  state.miniHubAdd.cellSelectedDesMoment;
export const getDetailHoverCreateMap = (state) =>
  state.miniHubAdd.idDetailHover;
export const getDetailRackCreateMap = (state) =>
  state.miniHubAdd.dataDetailRack;
export const getCoordinateFix = (state) => state.miniHubAdd.coordinateFix;

// status
export const getstatusMiniHubAdd = (state) => state.miniHubAdd.status;

// Get API

export const fetchPlacesSearchByName = (name) => (dispatch) => {
  const fetchData = async () => {
    dispatch(loadPlaceSearchByNameLoading(true));
    try {
      const { data } = await getPlacesByName(name);
      dispatch(loadPlaceSearchByName(data));
    } catch (error) {
      dispatch(loadPlaceSearchByName([]));
    } finally {
      dispatch(loadPlaceSearchByNameLoading(false));
    }
  };

  fetchData();
};
export const fetchPlacesSearchByCoordinate = (coor) => (dispatch) => {
  const fetchData = async () => {
    dispatch(changeIsPlaceGetByCoordinateLoading(true));
    try {
      const { data } = await getPlacesByCoordinate(coor);
      dispatch(changePlaceGetByCoordinate(data));
    } catch (error) {
      dispatch(changePlaceGetByCoordinate([]));
    } finally {
      dispatch(changeIsPlaceGetByCoordinateLoading(false));
    }
  };

  fetchData();
};

export default miniHubAddState.reducer;
