import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import CustomButton from '../../../../../components/shared/CustomButton';

import CategoryMenu from './CategoryMenu/CategoryMenu';
import CategoryMenuAdd from './CategoryMenuAdd/CategoryMenuAdd';
import CategoryMenuEdit from './CategoryMenuEdit/CategoryMenuEdit';
import CategoryMenuDetails from './CategoryMenuDetails/CategoryMenuDetails';

import SubCategoryMenuAdd from './SubCategoryMenuAdd/SubCategoryMenuAdd';
import SubCategoryMenuEdit from './SubCategoryMenuEdit/SubCategoryMenuEdit';
import SubCategoryMenuDetails from './SubCategoryMenuDetails/SubCategoryMenuDetails';
import SubCategoryMenu from './SubCategoryMenu/SubCategoryMenu';

import MiniSubMenuAdd from './MiniSubMenuAdd/MiniSubMenuAdd';
import MiniSubMenuDetails from './MiniSubMenuDetails/MiniSubMenuDetails';
import MiniSubMenuEdit from './MiniSubMenuEdit/MiniSubMenuEdit';

import MenuMenuAdd from './MenuMenuAdd/MenuMenuAdd';
import MenuMenuEdit from './MenuMenuEdit/MenuMenuEdit';
import MenuMenuDetails from './MenuMenuDetails/MenuMenuDetails';
import MenuMenu from './MenuMenu/MenuMenu';
import {
  allTypeCategoryMenu,
  fetchAllMenus,
  fetchCategories,
  fetchCategoryDetails,
  fetchMenuDetails,
  fetchMiniSubCategoryDetails,
  fetchSubCategories,
  fetchSubCategoryDetails,
  fetchSubsidiary,
} from '../../../../../api/menu';

const dummyDataTable = [
  {
    category: 'Category 1',
    categoryId: 1,
    subsidiary: 'lulu',
    subsidiaryId: 1,
    subCategory: 'subCategory 1',
    mini: 'mini subcategory 1',
    menu: 'menu 1',
    photo:
      'test/cwlJAPMzF3xSTK9EOH2BCA7q445dB5wwCNidtFjV.jpg,test/R2JJDdYYEPNdtlTrYpj0jVUEzIyoJPidQFibYUYd.jpg',
    type: 'kosmetik',
    cogs: 3000,
    rrp: 500,
    sku: 600,
    schedule: '2022-11-15',
    limit: 'limit',
    unit: '3 pcs',
    color: 'brown',
    status: 'Y',
  },
  {
    category: 'Category 2',
    categoryId: 2,
    subsidiary: 'lulu',
    subsidiaryId: 1,
    subCategory: 'subCategory 2',
    mini: 'mini subcategory 2',
    menu: 'menu 2',
    photo:
      'test/cwlJAPMzF3xSTK9EOH2BCA7q445dB5wwCNidtFjV.jpg,test/R2JJDdYYEPNdtlTrYpj0jVUEzIyoJPidQFibYUYd.jpg',
    type: 'kosmetik',
    cogs: 3000,
    rrp: 500,
    sku: 600,
    schedule: '2022-11-15',
    limit: 'limit',
    unit: '3 pcs',
    color: 'brown',
    status: 'Y',
  },
  {
    category: 'Category 3',
    categoryId: 3,
    subsidiary: 'lulu',
    subsidiaryId: 1,
    subCategory: 'subCategory 3',
    mini: 'mini subcategory 3',
    menu: 'menu 3',
    photo:
      'test/cwlJAPMzF3xSTK9EOH2BCA7q445dB5wwCNidtFjV.jpg,test/R2JJDdYYEPNdtlTrYpj0jVUEzIyoJPidQFibYUYd.jpg',
    type: 'kosmetik',
    cogs: 3000,
    rrp: 500,
    sku: 600,
    schedule: '2022-11-15',
    limit: 'limit',
    unit: '3 pcs',
    color: 'brown',
    status: 'Y',
  },
  {
    category: 'Category 4',
    categoryId: 4,
    subsidiary: 'lulu',
    subsidiaryId: 1,
    subCategory: 'subCategory 4',
    mini: 'mini subcategory 4',
    menu: 'menu 4',
    photo: null,
    type: 'kosmetik',
    cogs: 3000,
    rrp: 500,
    sku: 600,
    schedule: '2022-11-15',
    limit: 'limit',
    unit: '3 pcs',
    color: 'brown',
    status: 'N',
  },
  {
    category: 'Category 5',
    categoryId: 5,
    subsidiary: 'lulu',
    subsidiaryId: 1,
    subCategory: 'subCategory 5',
    mini: 'mini subcategory 5',
    menu: 'menu 5',
    photo:
      'test/cwlJAPMzF3xSTK9EOH2BCA7q445dB5wwCNidtFjV.jpg,test/R2JJDdYYEPNdtlTrYpj0jVUEzIyoJPidQFibYUYd.jpg',
    type: 'kosmetik',
    cogs: 3000,
    rrp: 500,
    sku: 600,
    schedule: '2022-11-15',
    limit: 'limit',
    unit: '3 pcs',
    color: 'brown',
    status: 'Y',
  },
];

const InventoryMenu = () => {
  const today = dayjs(new Date());
  // *! -----------------------------  Responsive --------------------------
  const [desktopMode, setDesktopMode] = useState(true);

  useEffect(() => {
    if (window.screen.width <= 830) {
      setDesktopMode(false);
    } else {
      setDesktopMode(true);
    }
  }, [window.screen.width]);

  // *! -----------------------------  Navigasi --------------------------

  const [page, setPage] = useState('category');
  const [categoryMenu, setCategoryMenu] = useState('');
  const [subCategoryMenu, setSubCategoryMenu] = useState('');
  const [menuMenu, setMenuMenu] = useState('');
  const [miniSubMenu, setMiniSubMenu] = useState(' ');

  const [roadHirarki, setRoadHirarki] = useState({
    subsidiary: '',
    subsidiaryId: 0,
    category: '',
    categoryId: 0,
    subCategory: '',
    subCategoryId: 0,
    menu: '',
    menuId: 0,
    type: '',
    typeId: 0,
    miniSubCategory: '',
    miniSubCategoryId: 0,
  });

  // *! -------------------------- NOTIF -----------------------------
  // *! ---------------------------------------------------------------

  const [notif, setNotif] = useState('');
  const [notifTitle, setNotifTitle] = useState('');

  // *! ----------------------------- Chart -----------------------------
  // *! ------------------------------------------------------------------

  const [timeFrame, setTimeFrame] = useState('daily');
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  // *! ----------------------------- Table ------------------------------
  // *! ------------------------------------------------------------------

  const tableRowPerPageValues = [5, 10, 15, 20, 25];
  const [tableRowPerPage, setTableRowPerPage] = useState(5);
  const [tableCurrentPage, setTableCurrentPage] = useState(1);
  const [tableTotalItems, setTableTotalItems] = useState(0);
  const [tableSearch, setTableSearch] = useState('');
  const [tableDatas, setTableDatas] = useState([]);

  const [sortTable, setSortTable] = useState('name');
  const [isSortAsc, setIsSortAsc] = useState(true);

  const [isLoadingData, setIsLoadingData] = useState(false);

  // *! -------------------------------- Fiter CAtegory -------------------------------------
  const [subsidiaryFilterValue, setSubsidiaryFilterValue] = useState([]);
  const [filterCategorySubsidiary, setFilterCategorySubsidiary] = useState([]);

  // *! ----------------------------- 1. Category ------------------------
  // *! ------------------------------------------------------------------

  const [tableCategoryFilterDatas, setTableCategoryFilterDatas] = useState({
    typeDatas: [],
    subsidiaryDatas: ['lulu', 'lunoar'],
  });

  const [categoryAddInputDatas, setCategoryAddInputDatas] = useState({
    name: '',
    subsidiary: { id: 0, name: '' },
    type: { iDopsi: 0, nmOpsi: '' },
    typeInput: '',
    typeId: 0,
    typeName: '',
    typeLoading: false,
    photo: [],
    photoUrls: [],
    gambar: [],
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    subCategoryIndex: [],
    subCategoryFormat: [1, 2, 3],
    publish: true,
  });
  const [categoryEditInputDatas, setCategoryEditInputDatas] = useState({
    name: '',
    subsidiary: { id: 0, name: '' },
    type: { iDopsi: 0, nmOpsi: '' },
    typeInput: '',
    typeId: 0,
    typeName: '',
    typeLoading: false,
    photo: [],
    photoUrls: [],
    gambar: [],
    linkPreviewPhoto: '',
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    subCategoryIndex: [],
    subCategoryFormat: [1, 2, 3],
    publish: true,
  });

  // *--------- subsidiary category -------------
  const [subsidiaryCategory, setSubsidiaryCategory] = useState([]);
  const [subsidiaryCategoryLoading, setSubsidiaryCategoryLoading] = useState(
    []
  );

  useEffect(() => {
    const getAllSubsidiary = async () => {
      setSubsidiaryCategoryLoading(true);
      try {
        const {
          data: {
            data: { subsidiaries: dataFetchSubsidiary },
          },
        } = await fetchSubsidiary();
        setSubsidiaryCategory(dataFetchSubsidiary);
      } catch (err) {
        console.error(err);
        setSubsidiaryCategory([]);
      } finally {
        setSubsidiaryCategoryLoading(false);
      }
    };
    getAllSubsidiary();
  }, []);
  // *--------- type category -------------
  const [typeCategory, setTypeCategory] = useState([]);
  const [typeCategoryLoading, setTypeCategoryLoading] = useState([]);

  useEffect(() => {
    const getAllTypeCategory = async () => {
      setTypeCategoryLoading(true);
      let datas = {};
      datas.pageSize = 100;
      datas.page = 1;
      try {
        const {
          data: {
            data: { categoryOptions: dataType },
          },
        } = await allTypeCategoryMenu(datas);

        setTypeCategory(dataType);
      } catch (err) {
        console.error(err);
        setTypeCategory([]);
      } finally {
        setTypeCategoryLoading(false);
      }
    };
    getAllTypeCategory();
  }, []);

  // *! ----------------------------- 2. Sub Category --------------------
  // *! ------------------------------------------------------------------

  const [subCategoryAddInputDatas, setSubCategoryAddInputDatas] = useState({
    name: '',
    photo: [],
    photoUrls: [],
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    miniSubCategoryIndex: [],
    miniSubCategoryFormat: [1, 2, 3],
    publish: true,
  });

  const [subCategoryEditInputDatas, setSubCategoryEditInputDatas] = useState({
    name: '',
    photo: [],
    photoUrls: [],
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    linkPreviewPhoto: '',
    description: '',
    scheduleType: 'now',
    schedule: today,
    miniSubCategoryIndex: [],
    miniSubCategoryFormat: [1, 2, 3],
    publish: true,
  });

  // *! ----------------------------- 3. Menu -------------------------
  // *! ------------------------------------------------------------------

  const [menuAddInputDatas, setMenuAddInputDatas] = useState({
    name: '',
    photo: [],
    photoUrls: [],
    linkPreviePhoto: '',
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    videoUrls: [],
    linkPrevieVideo: '',
    isMaxSizeVideo: false,
    isPreviewVideo: false,
    isCategoryOpen: false,
    category: '',
    subCategory: '',
    miniSubCategory: '',
    categoryId: 0,
    subCategoryId: 0,
    miniSubCategoryId: 0,
    isIngredientOpen: false,
    ingredient: [],
    isSocialMediaOpen: false,
    instagram: '',
    tiktok: '',
    metaDescription: '',
    scheduleType: 'now',
    schedule: today,
    description: '',
    publish: true,
  });

  const [menuEditInputDatas, setMenuEditInputDatas] = useState({
    name: '',
    photo: [],
    photoUrls: [],
    linkPreviePhoto: '',
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    videoUrls: [],
    linkPrevieVideo: '',
    isMaxSizeVideo: false,
    isPreviewVideo: false,
    isCategoryOpen: false,
    category: '',
    subCategory: '',
    miniSubCategory: '',
    categoryId: 0,
    subCategoryId: 0,
    miniSubCategoryId: 0,
    isIngredientOpen: false,
    ingredient: [],
    isSocialMediaOpen: false,
    instagram: '',
    tiktok: '',
    metaDescription: '',
    scheduleType: 'now',
    schedule: today,
    description: '',
    publish: true,
  });

  // ?-------------- Filter---------------
  const [filterDataAwait, setFilterDataAwait] = useState({
    categoryId: [],
    subCategoryId: [],
    miniSubCategoryId: [],
  });

  // ?-------------- Pemilihan Kategori pada produk ---------------
  const [categoryDataAwait, setCategoryDataAwait] = useState({
    category: '',
    subCategory: '',
    miniSubCategory: '',
    categoryId: '',
    subCategoryId: '',
    miniSubCategoryId: '',
    categoryChoose: null,
    subCategoryChoose: null,
    miniSubCategoryChoose: null,
  });

  const [categoryDataAwaitEdit, setCategoryDataAwaitEdit] = useState({
    category: '',
    subCategory: '',
    miniSubCategory: '',
    categoryId: '',
    subCategoryId: '',
    miniSubCategoryId: '',
    categoryChoose: null,
    subCategoryChoose: null,
    miniSubCategoryChoose: null,
  });

  // ? -------------------- Pemilihan ingredient -----------------------
  const [ingredientDataAwait, setIngredientDataAwait] = useState([
    {
      product: '',
      productId: 0,
      unitQty: 0,
      unitName: '',
    },
  ]);
  const [ingredientDataAwaitEdit, setIngredientDataAwaitEdit] = useState([
    {
      product: '',
      productId: 0,
      unitQty: 0,
      unitName: '',
    },
  ]);

  // ? -------------------- Pemilihan Social Media -----------------------

  const [socialMediaDataAwait, setSocialMediaDataAwait] = useState({
    instagram: '',
    tiktok: '',
  });

  const [socialMediaDataAwaitEdit, setSocialMediaDataAwaitEdit] = useState({
    instagram: '',
    tiktok: '',
  });

  // *! ----------------------------- 4. Min --------------------------
  // *! ---------------------------------------------------------------

  const [miniSubAddInputDatas, setMiniSubAddInputDatas] = useState({
    name: '',
    photo: [],
    photoUrls: [],
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    publish: true,
  });

  const [miniSubEditInputDatas, setMiniSubEditInputDatas] = useState({
    name: '',
    photo: [],
    photoUrls: [],
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    publish: true,
  });

  // *! ------------------------- API Table --------------------------

  useEffect(() => {
    // *-------------- All Product  -------------
    const getAllMenus = async () => {
      setIsLoadingData(true);

      let params = {};
      params.pageSize = tableRowPerPage;
      params.page = tableCurrentPage;
      params.opsiKategori = 6;
      if (filterDataAwait.categoryId.length !== 0) {
        params['idKategori'] = filterDataAwait.categoryId;
      }
      if (filterDataAwait.subCategoryId.length !== 0) {
        params['idSubkategori'] = filterDataAwait.subCategoryId;
      }
      if (filterDataAwait.miniSubCategoryId.length !== 0) {
        params['miniCategoryId'] = filterDataAwait.miniSubCategoryId;
      }
      if (tableSearch !== '') {
        params['search'] = tableSearch;
      }
      if (sortTable !== 0) {
        params[sortTable] = isSortAsc ? 'asc' : 'desc';
      }

      try {
        const {
          data: { data: dataFetchProducts },
        } = await fetchAllMenus(params);

        setTableDatas(dataFetchProducts.products);
        setTableTotalItems(dataFetchProducts.pageInfo.total);
      } catch (err) {
        console.error(err);
        setTableDatas([]);
        setTableTotalItems(tableTotalItems);
        setIsLoadingData(true);
      } finally {
        setIsLoadingData(false);
      }
    };

    // *-------------- Product With Sub --------
    const getMenus = async () => {
      setIsLoadingData(true);

      let params = {};
      params.pageSize = tableRowPerPage;
      params.page = tableCurrentPage;
      params.opsiKategori = 6;
      params.idKategori = [];
      params.idSubkategori = [roadHirarki.subCategoryId];
      params.miniCategoryid = [];

      if (tableSearch !== '') {
        params['search'] = tableSearch;
      }
      if (sortTable !== 0) {
        params[sortTable] = isSortAsc ? 'asc' : 'desc';
      }

      try {
        const {
          data: { data: dataFetchProducts },
        } = await fetchAllMenus(params);

        setTableDatas(dataFetchProducts.products);
        setTableTotalItems(dataFetchProducts.pageInfo.total);
      } catch (err) {
        console.error(err);
        setTableDatas([]);
        setTableTotalItems(tableTotalItems);
      } finally {
        setIsLoadingData(false);
      }
    };

    // *-------------- All Subs Data  -------------
    const getAllSubCategories = async () => {
      setIsLoadingData(true);

      let data = {};
      data.pageSize = tableRowPerPage;
      data.page = tableCurrentPage;
      data.categories = [roadHirarki.categoryId];
      if (tableSearch !== '') {
        data['search'] = tableSearch;
      }
      if (sortTable !== 0) {
        data[sortTable] = isSortAsc ? 'asc' : 'desc';
      }

      try {
        const {
          data: { data: dataFetchSubCategories },
        } = await fetchSubCategories(data);

        setTableDatas(dataFetchSubCategories.subCategories);
        setTableTotalItems(dataFetchSubCategories.pageInfo.total);
      } catch (err) {
        console.error(err);
        setTableDatas([]);
        setTableTotalItems(tableTotalItems);
        setIsLoadingData(true);
      } finally {
        setIsLoadingData(false);
      }
    };

    // *--------- ALL CATEGORY DATA ------
    const getAllCategories = async () => {
      setIsLoadingData(true);
      let data = {};
      data.pageSize = tableRowPerPage;
      data.page = tableCurrentPage;
      data.subsidiaryId = filterCategorySubsidiary;
      data.opsiKategori = [6];

      if (tableSearch !== '') {
        data['search'] = tableSearch;
      }
      if (sortTable !== 0) {
        data[sortTable] = isSortAsc ? 'asc' : 'desc';
      }

      try {
        const {
          data: { data: dataFetchCategories },
        } = await fetchCategories(data);

        setTableDatas(dataFetchCategories.categories);
        setTableTotalItems(dataFetchCategories.pageInfo.total);
      } catch (err) {
        console.error(err);
        setTableDatas([]);
        setTableTotalItems(tableTotalItems);
      } finally {
        setIsLoadingData(false);
      }
    };

    // !--- MOBILE ---
    // *-------------- All Product Mobile -------------
    const getAllMenusMobile = async () => {
      setIsLoadingData(true);

      let params = {};
      params.pageSize = 12;
      params.page = 1;
      params.name = 'asc';
      params.opsiKategory = 6;
      params.idKategori = [];
      params.idSubkategori = [];
      params.miniCategoryid = [];
      // if (filterDataAwait.categoryId.length !== 0) {
      //   params['idKategori'] = filterDataAwait.categoryId;
      // }
      // if (filterDataAwait.subCategoryId.length !== 0) {
      //   params['idSubkategori'] = filterDataAwait.subCategoryId;
      // }
      // if (filterDataAwait.miniSubCategoryId.length !== 0) {
      //   params['miniCategoryId'] = filterDataAwait.miniSubCategoryId;
      // }

      if (tableSearch !== '') {
        params['search'] = tableSearch;
      }

      try {
        const {
          data: { data: dataFetchProducts },
        } = await fetchAllMenus(params);

        setTableDatas(dataFetchProducts.products);
        setTableTotalItems(dataFetchProducts.pageInfo.total);
      } catch (err) {
        console.error(err);
        setTableDatas([]);
        setTableTotalItems(tableTotalItems);
        setIsLoadingData(true);
      } finally {
        setIsLoadingData(false);
      }
    };

    // *-------------- Product with Subs Mobile  -------------
    const getMenusMobile = async () => {
      setIsLoadingData(true);

      let params = {};
      params.pageSize = 12;
      params.page = 1;
      params.opsiKategory = 6;
      params.idKategori = [];
      params.idSubkategori = [roadHirarki.subCategoryId];
      params.miniCategoryid = [];
      params.name = 'asc';

      if (tableSearch !== '') {
        params['search'] = tableSearch;
      }

      try {
        const {
          data: { data: dataFetchProducts },
        } = await fetchAllMenus(params);

        setTableDatas(dataFetchProducts.products);
        setTableTotalItems(dataFetchProducts.pageInfo.total);
      } catch (err) {
        console.error(err);
        setTableDatas([]);
        setTableTotalItems(tableTotalItems);
        setIsLoadingData(true);
      } finally {
        setIsLoadingData(false);
      }
    };

    // *-------------- All Subs Mobile  -------------
    const getAllSubCategoriesMobile = async () => {
      setIsLoadingData(true);

      let data = {};
      data.pageSize = 12;
      data.page = 1;
      data.categories = [roadHirarki.categoryId];
      data.name = 'asc';

      try {
        const {
          data: { data: dataFetchSubCategories },
        } = await fetchSubCategories(data);

        setTableDatas(dataFetchSubCategories.subCategories);
        setTableTotalItems(dataFetchSubCategories.pageInfo.total);
      } catch (err) {
        console.error(err);
        setTableDatas([]);
        setTableTotalItems(tableTotalItems);
        setIsLoadingData(true);
      } finally {
        setIsLoadingData(false);
      }
    };
    // *-------------- All CAtegori Mobile  -------------
    const getAllCategoriesMobile = async () => {
      setIsLoadingData(true);
      let data = {};
      data.pageSize = 12;
      data.page = 1;
      data.opsiKategori = [6];
      data.subsidiaryId = filterCategorySubsidiary;
      data.name = 'asc';
      if (tableSearch !== '') {
        data['search'] = tableSearch;
      }

      try {
        const {
          data: { data: dataFetchCategories },
        } = await fetchCategories(data);

        setTableDatas(dataFetchCategories.categories);
        setTableTotalItems(dataFetchCategories.pageInfo.total);
      } catch (err) {
        console.error(err);
        setTableDatas([]);
        setTableTotalItems(tableTotalItems);
      } finally {
        setIsLoadingData(false);
      }
    };
    if (desktopMode) {
      if (roadHirarki.categoryId !== 0 && roadHirarki.subCategoryId !== 0) {
        getMenus();
      } else if (
        roadHirarki.categoryId !== 0 &&
        subCategoryMenu === '' &&
        roadHirarki.subCategoryId === 0
      ) {
        getAllSubCategories();
      } else {
        if (page === 'menu') {
          getAllMenus();
        } else {
          getAllCategories();
        }
      }
    } else {
      if (roadHirarki.categoryId !== 0 && roadHirarki.subCategoryId !== 0) {
        getMenusMobile();
      } else if (
        roadHirarki.categoryId !== 0 &&
        roadHirarki.subCategoryId === 0
      ) {
        getAllSubCategoriesMobile();
      } else {
        if (page === 'menu') {
          getAllMenusMobile();
        } else {
          getAllCategoriesMobile();
        }
      }
    }
  }, [
    tableRowPerPage,
    tableCurrentPage,
    tableTotalItems,
    roadHirarki,
    roadHirarki.categoryId,
    roadHirarki.subCategoryId,
    filterDataAwait.categoryId,
    filterDataAwait.miniSubCategoryId,
    filterDataAwait.subCategoryId,
    sortTable,
    isSortAsc,
    tableSearch,
    page,
    subCategoryMenu,
    desktopMode,
    filterCategorySubsidiary,
  ]);

  // *! -------------------------- API DETAILS -----------------------
  // *! ---------------------------------------------------------------

  const [detailsDatas, setDetailsDatas] = useState([]);
  const [isLoadingDataDetails, setIsLoadingDataDetails] = useState(false);

  useEffect(() => {
    // *-------------- Detail Product-------------
    const getDetailMenu = async () => {
      setIsLoadingDataDetails(true);
      try {
        const {
          data: { data: dataFetchMenuDetails },
        } = await fetchMenuDetails(roadHirarki.menuId);
        setDetailsDatas(dataFetchMenuDetails);
      } catch (err) {
        console.error(err);
        setDetailsDatas([]);
      } finally {
        setIsLoadingDataDetails(false);
      }
    };

    // *-------------- Detail Mini-------------
    const getDetailMini = async () => {
      setIsLoadingDataDetails(true);
      try {
        const {
          data: { data: dataFetchMiniDetails },
        } = await fetchMiniSubCategoryDetails({
          startDate: '2022-10-25',
          endDate: '2022-12-27',
          id: roadHirarki.miniSubCategoryId,
        });
        setDetailsDatas(dataFetchMiniDetails);
      } catch (err) {
        console.error(err);
        setDetailsDatas([]);
      } finally {
        setIsLoadingDataDetails(false);
      }
    };

    // *-------------- Detail Subs-------------
    const getDetailSubCategory = async () => {
      setIsLoadingDataDetails(true);

      try {
        const {
          data: { data: dataFetchSubCategoriesDetails },
        } = await fetchSubCategoryDetails({
          startDate: '2022-10-25',
          endDate: '2022-12-27',
          id: roadHirarki.subCategoryId,
        });
        setDetailsDatas(dataFetchSubCategoriesDetails);
      } catch (err) {
        console.error(err);
        setDetailsDatas([]);
      } finally {
        setIsLoadingDataDetails(false);
      }
    };

    // *-------------- Detail Category-------------
    const getDetailCategory = async () => {
      setIsLoadingDataDetails(true);

      try {
        const {
          data: { data: dataFetchCategoriesDetails },
        } = await fetchCategoryDetails({
          startDate: '2022-10-25',
          endDate: '2022-12-27',
          id: roadHirarki.categoryId,
        });
        setDetailsDatas(dataFetchCategoriesDetails);
      } catch (err) {
        console.error(err);
        setDetailsDatas([]);
      } finally {
        setIsLoadingDataDetails(false);
      }
    };
    if (page === 'menu' && menuMenu === 'details' && roadHirarki.menuId !== 0) {
      getDetailMenu();
    } else if (
      page === 'miniSub' &&
      miniSubMenu === 'details' &&
      roadHirarki.miniSubCategoryId !== 0
    ) {
      getDetailMini();
    } else if (
      page === 'subCategory' &&
      subCategoryMenu === 'details' &&
      roadHirarki.subCategoryId !== 0
    ) {
      getDetailSubCategory();
    } else if (categoryMenu === 'details' && roadHirarki.categoryId !== 0) {
      getDetailCategory();
    }
  }, [
    roadHirarki.menuId,
    roadHirarki.categoryId,
    roadHirarki.subCategoryId,
    page,
    menuMenu,
    miniSubMenu,
    subCategoryMenu,
    categoryMenu,
    roadHirarki.categoryReset,
  ]);

  return (
    <Box sx={{ mt: '16px' }}>
      {page === 'menu' ? (
        <>
          {menuMenu === 'add' ? (
            <MenuMenuAdd
              {...{ desktopMode, roadHirarki, setRoadHirarki }}
              {...{ notif, setNotif, notifTitle, setNotifTitle }}
              {...{ menuAddInputDatas, setMenuAddInputDatas }}
              {...{
                categoryDataAwait,
                setCategoryDataAwait,
                ingredientDataAwait,
                setIngredientDataAwait,
                socialMediaDataAwait,
                setSocialMediaDataAwait,
              }}
              {...{ setPage, setMenuMenu }}
            />
          ) : menuMenu === 'edit' ? (
            <MenuMenuEdit
              {...{ setPage, setMenuMenu }}
              {...{ desktopMode, roadHirarki, setRoadHirarki }}
              {...{ notif, setNotif, notifTitle, setNotifTitle }}
              {...{ menuEditInputDatas, setMenuEditInputDatas }}
              {...{
                categoryDataAwaitEdit,
                setCategoryDataAwaitEdit,
                ingredientDataAwaitEdit,
                setIngredientDataAwaitEdit,
                socialMediaDataAwaitEdit,
                setSocialMediaDataAwaitEdit,
              }}
            />
          ) : menuMenu === 'details' ? (
            <MenuMenuDetails
              {...{ desktopMode, roadHirarki, setRoadHirarki }}
              {...{ notif, setNotif, notifTitle, setNotifTitle }}
              {...{ menuEditInputDatas, setMenuEditInputDatas }}
              {...{
                categoryDataAwaitEdit,
                setCategoryDataAwaitEdit,
                ingredientDataAwaitEdit,
                setIngredientDataAwaitEdit,
                socialMediaDataAwaitEdit,
                setSocialMediaDataAwaitEdit,
              }}
              {...{ setPage, setMenuMenu }}
              {...{
                timeFrame,
                setTimeFrame,
                dateRange,
                setDateRange,
                saveDateRange,
                setSaveDateRange,
              }}
              {...{ detailsDatas, isLoadingDataDetails }}
            />
          ) : (
            <MenuMenu
              {...{ desktopMode, roadHirarki, setRoadHirarki }}
              {...{ notif, setNotif, notifTitle, setNotifTitle }}
              {...{ setPage, setMenuMenu, setMiniSubMenu }}
              {...{
                timeFrame,
                setTimeFrame,
                dateRange,
                setDateRange,
                saveDateRange,
                setSaveDateRange,
              }}
              {...{
                tableRowPerPageValues,
                tableRowPerPage,
                setTableRowPerPage,
                tableCurrentPage,
                setTableCurrentPage,
                tableTotalItems,
                setTableTotalItems,
                tableSearch,
                setTableSearch,
                sortTable,
                setSortTable,
                isSortAsc,
                setIsSortAsc,
                tableDatas,
                setTableDatas,
                tableCategoryFilterDatas,
                setTableCategoryFilterDatas,
                isLoadingData,
              }}
              {...{ filterDataAwait, setFilterDataAwait }}
            />
          )}
        </>
      ) : page === 'miniSub' ? (
        <>
          {miniSubMenu === 'add' ? (
            <MiniSubMenuAdd
              {...{ desktopMode, roadHirarki, setRoadHirarki }}
              {...{ notif, setNotif, notifTitle, setNotifTitle }}
              {...{ setPage, setMiniSubMenu }}
              {...{ miniSubAddInputDatas, setMiniSubAddInputDatas }}
            />
          ) : miniSubMenu === 'edit' ? (
            <MiniSubMenuEdit
              {...{ desktopMode, roadHirarki, setRoadHirarki }}
              {...{ notif, setNotif, notifTitle, setNotifTitle }}
              {...{ setPage, setMiniSubMenu }}
              {...{ miniSubEditInputDatas, setMiniSubEditInputDatas }}
            />
          ) : (
            <MiniSubMenuDetails
              {...{ desktopMode, roadHirarki, setRoadHirarki }}
              {...{ notif, setNotif, notifTitle, setNotifTitle }}
              {...{ miniSubEditInputDatas, setMiniSubEditInputDatas }}
              {...{ setPage, setMiniSubMenu }}
              {...{
                timeFrame,
                setTimeFrame,
                dateRange,
                setDateRange,
                saveDateRange,
                setSaveDateRange,
              }}
              {...{ detailsDatas, isLoadingDataDetails }}
            />
          )}
        </>
      ) : page === 'subCategory' ? (
        <>
          {subCategoryMenu === 'add' ? (
            <SubCategoryMenuAdd
              {...{ desktopMode, roadHirarki, setRoadHirarki }}
              {...{ setPage, setSubCategoryMenu }}
              {...{ notif, setNotif, notifTitle, setNotifTitle }}
              {...{ subCategoryAddInputDatas, setSubCategoryAddInputDatas }}
            />
          ) : subCategoryMenu === 'edit' ? (
            <SubCategoryMenuEdit
              {...{ dummyDataTable, desktopMode, roadHirarki, setRoadHirarki }}
              {...{ notif, setNotif, notifTitle, setNotifTitle }}
              {...{ subCategoryEditInputDatas, setSubCategoryEditInputDatas }}
              {...{ setPage, setSubCategoryMenu }}
            />
          ) : subCategoryMenu === 'details' ? (
            <SubCategoryMenuDetails
              {...{ dummyDataTable, desktopMode, roadHirarki, setRoadHirarki }}
              {...{ notif, setNotif, notifTitle, setNotifTitle }}
              {...{ subCategoryEditInputDatas, setSubCategoryEditInputDatas }}
              {...{ setPage, setSubCategoryMenu }}
              {...{
                timeFrame,
                setTimeFrame,
                dateRange,
                setDateRange,
                saveDateRange,
                setSaveDateRange,
              }}
              {...{ detailsDatas, isLoadingDataDetails }}
            />
          ) : (
            <SubCategoryMenu
              {...{ desktopMode, roadHirarki, setRoadHirarki }}
              {...{ notif, setNotif, notifTitle, setNotifTitle }}
              {...{ setPage, setSubCategoryMenu }}
              {...{
                timeFrame,
                setTimeFrame,
                dateRange,
                setDateRange,
                saveDateRange,
                setSaveDateRange,
              }}
              {...{
                tableRowPerPageValues,
                tableRowPerPage,
                setTableRowPerPage,
                tableCurrentPage,
                setTableCurrentPage,
                tableTotalItems,
                setTableTotalItems,
                tableSearch,
                setTableSearch,
                sortTable,
                setSortTable,
                isSortAsc,
                setIsSortAsc,
                tableDatas,
                setTableDatas,
                tableCategoryFilterDatas,
                setTableCategoryFilterDatas,
                isLoadingData,
              }}
            />
          )}
        </>
      ) : (
        <>
          {categoryMenu === 'add' ? (
            <CategoryMenuAdd
              {...{ desktopMode, roadHirarki, setRoadHirarki }}
              {...{ setPage, setCategoryMenu }}
              {...{ notif, setNotif, notifTitle, setNotifTitle }}
              {...{ categoryAddInputDatas, setCategoryAddInputDatas }}
              {...{
                typeCategory,
                typeCategoryLoading,
                subsidiaryCategory,
                subsidiaryCategoryLoading,
              }}
            />
          ) : categoryMenu === 'edit' ? (
            <CategoryMenuEdit
              {...{ desktopMode, roadHirarki, setRoadHirarki }}
              {...{ setPage, setCategoryMenu }}
              {...{ notif, setNotif, notifTitle, setNotifTitle }}
              {...{ categoryEditInputDatas, setCategoryEditInputDatas }}
              {...{
                timeFrame,
                setTimeFrame,
                dateRange,
                setDateRange,
                saveDateRange,
                setSaveDateRange,
              }}
              {...{
                typeCategory,
                typeCategoryLoading,
                subsidiaryCategory,
                subsidiaryCategoryLoading,
              }}
            />
          ) : categoryMenu === 'details' ? (
            <CategoryMenuDetails
              {...{ dummyDataTable, desktopMode, roadHirarki, setRoadHirarki }}
              {...{ notif, setNotif, notifTitle, setNotifTitle }}
              {...{ categoryEditInputDatas, setCategoryEditInputDatas }}
              {...{ setPage, setCategoryMenu }}
              {...{
                timeFrame,
                setTimeFrame,
                dateRange,
                setDateRange,
                saveDateRange,
                setSaveDateRange,
              }}
              {...{ detailsDatas, isLoadingDataDetails }}
            />
          ) : (
            <CategoryMenu
              {...{ dummyDataTable, desktopMode, roadHirarki, setRoadHirarki }}
              {...{ notif, setNotif, notifTitle, setNotifTitle }}
              {...{ setPage, setCategoryMenu }}
              {...{
                timeFrame,
                setTimeFrame,
                dateRange,
                setDateRange,
                saveDateRange,
                setSaveDateRange,
              }}
              {...{
                tableRowPerPageValues,
                tableRowPerPage,
                setTableRowPerPage,
                tableCurrentPage,
                setTableCurrentPage,
                tableTotalItems,
                setTableTotalItems,
                tableSearch,
                setTableSearch,
                sortTable,
                setSortTable,
                isSortAsc,
                setIsSortAsc,
                tableDatas,
                setTableDatas,
                tableCategoryFilterDatas,
                setTableCategoryFilterDatas,
                isLoadingData,
              }}
              {...{
                subsidiaryFilterValue,
                setSubsidiaryFilterValue,
                filterCategorySubsidiary,
                setFilterCategorySubsidiary,
              }}
            />
          )}
        </>
      )}
    </Box>
  );
};
export default InventoryMenu;
