import { createSlice } from "@reduxjs/toolkit";

export const hubsRoutingState = createSlice({
  name: "hubsRouting",
  initialState: {
    page: "",
    keyNotif: "",
    textNotif: "",
  },
  reducers: {
    changePageMasterHub: (state, action) => {
      state.page = action.payload;
    },
    changePageSubHub: (state, action) => {
      state.page = action.payload;
    },
    setNotifMasterHub: (state, action) => {
      const { key, text } = action.payload;
      state.keyNotif = key;
      state.textNotif = text;
    },
    setCloseNotifMasterHub: (state, action) => {
      state.keyNotif = "";
      state.textNotif = "";
    },
  },
});
export const {
  changePageMasterHub,
  changePageSubHub,
  setNotifMasterHub,
  setCloseNotifMasterHub,
} = hubsRoutingState.actions;

export const getAllHubsRouting = (state) => state.hubsRouting;

export default hubsRoutingState.reducer;
