import { Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography, Box, useTheme, TextField, Switch, Dialog, styled } from '@mui/material';
import BackButton from '../../../../../../components/shared/BackButton';
import CustomButton from '../../../../../../components/shared/CustomButton';
import dayjs from 'dayjs';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ReactComponent as NoImage } from '../../../../../../assets/no-image.svg';
import { ChartDetails } from '../DesktopComponent';
import { deleteCategoryProduct, updateCategoryProductStatus } from '../../../../../../api/products';
import { useEffect, useState } from 'react';
import CategoryProductDetailsMobile from './CategoryProductDetailsMobile';
import CustomDeleteConfirm from '../../../../../../components/shared/CustomDeleteConfirm';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const CategoryProductDetails = (props) => {
  const {
    page,
    setPage,
    roadHirarki,
    setRoadHirarki,
    // *--------
    isLoadingDataDetails,
    detailsDatas,
    setDetailsDatas,
    // *--------
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    // *-----------
    setIsCategoryProductDetails,
    setIsCategoryProductEdit,
    // *----------
    categoryEditInputDatas,
    setCategoryEditInputDatas,
    // *-----Notif------
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
  } = props;
  const today = dayjs(new Date());
  const theme = useTheme();

  const [linkPreviewPhoto, setLinkPreviewPhoto] = useState('');
  const [isPreviewPhoto, setIsPreviewPhoto] = useState(false);

  const [desktopMode, setDesktopMode] = useState(true);

  useEffect(() => {
    if (window.screen.width <= 830) {
      setDesktopMode(false);
    } else {
      setDesktopMode(true);
    }
  }, [window.screen.width]);

  // !---------------- Confirm ----------------

  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  // !---------------- Status Product ----------
  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (!isLoadingDataDetails) {
      if (detailsDatas.status === 'Y') {
        return setStatus(true);
      } else {
        return setStatus(false);
      }
    }
  }, [isLoadingDataDetails, detailsDatas.status]);

  const handleEditStatus = (e) => {
    if (status) {
      updateCategoryProductStatus(roadHirarki.categoryId, 'N');
      setStatus(false);
    } else {
      updateCategoryProductStatus(roadHirarki.categoryId, 'Y');
      setStatus(true);
    }
  };

  // !------------------------- Handler ----------------------------

  const handleDelete = () => {
    setIsDeleteConfirm(true);
  };

  const handleDeleteFix = () => {
    deleteCategoryProduct(detailsDatas.idKategori)
      .then((result) => {
        setIsDeleteConfirm(false);
        setIsCategoryProductDetails(false);
        setNotifTitle(`${result.data.data.namaKategori} has been successfully deleted`);
        setNotif('deletecategory');
        setPage('category');
        setRoadHirarki({
          subsidiary: '',
          subsidiaryId: 0,
          category: '',
          categoryId: 0,
          subCategory: '',
          subCategoryId: 0,
          product: '',
          productId: 0,
          miniSubCategory: '',
          miniSubCategoryId: 0,
        });
      })
      .catch((err) => console.error());
  };

  const handleSubmit = () => {
    setCategoryEditInputDatas({
      ...categoryEditInputDatas,
      subsidiaryId: parseInt(detailsDatas?.subsidiaryId),
      subsidiary: {
        id: detailsDatas.subsidiaryId,
        name: detailsDatas.subsidiaryName,
      },
      type: { iDopsi: detailsDatas.opsiKategori, nmOpsi: detailsDatas.type },
      categoryId: detailsDatas.idKategori,
      name: detailsDatas.namaKategori,
      description: detailsDatas.des,
      scheduleType: 'custom',
      schedule: detailsDatas.schedule ? dayjs(detailsDatas.schedule) : today,
      photoUrls: detailsDatas.gambar ? detailsDatas.gambar.split(',') : [],
      publish: detailsDatas.status === 'Y' ? true : false,
    });

    setIsCategoryProductDetails(false);
    setIsCategoryProductEdit(true);
  };

  const handleBackButton = () => {
    setRoadHirarki({
      subsidiary: '',
      subsidiaryId: 0,
      category: '',
      categoryId: 0,
      subCategory: '',
      subCategoryId: 0,
      product: '',
      productId: 0,
    });
    setIsCategoryProductDetails(false);
  };

  const handlePreviewPhoto = (link) => {
    setLinkPreviewPhoto(link);
    setIsPreviewPhoto(true);
  };

  return (
    <>
      <CustomNotifSuccess
        text={notifTitle}
        open={notif === 'editcategory'}
        onClose={() => {
          setNotif('');
          setNotifTitle('');
        }}
      />
      <CustomDeleteConfirm
        open={isDeleteConfirm}
        onClose={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickCancel={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Category"
        body={
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: '600' }}>
                {detailsDatas.namaKategori}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: '52px' }}>You can’t undo this action.</Typography>
          </Box>
        }
      />
      {desktopMode ? (
        <Grid container columns={12} spacing={4} sx={{ pb: '56px' }}>
          {/* BackButton */}
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <BackButton
              handleClick={() => {
                setIsCategoryProductDetails(false);
                setRoadHirarki({
                  category: '',
                  categoryId: 0,
                  subCategory: '',
                });
                setPage('category');
              }}
            />

            <Box>
              <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>{roadHirarki.category} Details</Typography>
              <Box
                sx={{
                  display: 'flex',
                  color: '#9E9D9D',

                  alignItems: 'center',
                  mt: '4px',
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>{roadHirarki.subsidiary ? roadHirarki.subsidiary : '-'}</Typography>
              </Box>
            </Box>
          </Grid>
          {!isLoadingDataDetails && (
            <>
              {/* --- */}
              <Grid container item xs={12} columns={12} columnSpacing={4}>
                <Grid item xs={8}>
                  <ChartDetails
                    {...{
                      timeFrame,
                      setTimeFrame,
                      dateRange,
                      setDateRange,
                      saveDateRange,
                      setSaveDateRange,
                    }}
                  />
                </Grid>
                <Grid container item xs={4} columns={12} spacing={4}>
                  {/* purchased */}
                  <Grid item xs={12}>
                    <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>Purchased</Typography>
                    <Box
                      sx={{
                        mt: '12px',
                        width: '100%',
                        padding: '14px 16px',
                        bgcolor: '#FAFAFA',
                        borderRadius: '8px',
                      }}
                    >
                      <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>Rp {detailsDatas.purchased ? detailsDatas.purchased : '-'}</Typography>
                    </Box>
                  </Grid>
                  {/* sold */}
                  <Grid item xs={12}>
                    <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>Sold</Typography>
                    <Box
                      sx={{
                        mt: '12px',
                        width: '100%',
                        padding: '14px 16px',
                        bgcolor: '#FAFAFA',
                        borderRadius: '8px',
                      }}
                    >
                      <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>Rp {detailsDatas.sold ? detailsDatas.sold : '-'}</Typography>
                    </Box>
                  </Grid>
                  {/* used */}
                  <Grid item xs={12}>
                    <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>Used</Typography>
                    <Box
                      sx={{
                        mt: '12px',
                        width: '100%',
                        padding: '14px 16px',
                        bgcolor: '#FAFAFA',
                        borderRadius: '8px',
                      }}
                    >
                      <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>Rp {detailsDatas.used ? detailsDatas.used : '-'}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {/* Type */}
              <Grid item xs={4}>
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>Type</Typography>
                <Box
                  sx={{
                    mt: '12px',
                    width: '100%',
                    padding: '14px 16px',
                    bgcolor: '#FAFAFA',
                    borderRadius: '8px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>{detailsDatas.type ? detailsDatas.type : '-'}</Typography>
                </Box>
              </Grid>
              {/* SKU */}
              <Grid item xs={4}>
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>SKU</Typography>
                <Box
                  sx={{
                    mt: '12px',
                    width: '100%',
                    padding: '14px 16px',
                    bgcolor: '#FAFAFA',
                    borderRadius: '8px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>{detailsDatas.sku ? detailsDatas.sku : '-'}</Typography>
                </Box>
              </Grid>
              {/* Photo */}
              <Grid item xs={4}>
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>Photo</Typography>
                <Box
                  sx={{
                    mt: '12px',
                    width: '100%',
                    padding: '0',
                    display: 'flex',
                    gap: 2,
                  }}
                >
                  {!detailsDatas.gambar ? (
                    <Box
                      sx={{
                        width: '42px',
                        height: '42px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: '#FAFAFA',
                        borderRadius: '2px',
                      }}
                    >
                      <NoImage />
                      <Typography sx={{ color: '#9E9D9D', fontSize: '6px' }}>No photo</Typography>
                    </Box>
                  ) : (
                    detailsDatas.gambar.split(',').map((value, index) => (
                      <>
                        <Box
                          key={index}
                          component="img"
                          src={process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + value}
                          onClick={() => handlePreviewPhoto(value)}
                          sx={{
                            width: '42px',
                            height: '42px',
                            aspectRatio: '1/1',
                            borderRadius: '8px',
                            cursor: 'pointer',
                          }}
                        />
                      </>
                    ))
                  )}
                </Box>
              </Grid>
              {/* Dialog Photo */}
              {linkPreviewPhoto !== '' && (
                <Dialog
                  open={isPreviewPhoto}
                  onClose={() => {
                    setIsPreviewPhoto(!isPreviewPhoto);
                    setLinkPreviewPhoto('');
                  }}
                >
                  <Box
                    component="img"
                    src={process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + linkPreviewPhoto}
                    sx={{
                      width: '600px',
                      height: '600px',
                      aspectRation: '1/1',
                    }}
                  />
                </Dialog>
              )}
              {/* Description */}
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>Description</Typography>
                <Box
                  sx={{
                    mt: '12px',
                    width: '100%',
                    padding: '14px 16px',
                    bgcolor: '#FAFAFA',
                    borderRadius: '8px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>{detailsDatas.des ? detailsDatas.des : '-'}</Typography>
                </Box>
              </Grid>
              {/* Schedule */}
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>Schedule</Typography>
                <Box
                  sx={{
                    mt: '12px',
                    width: '100%',
                    padding: '14px 16px',
                    bgcolor: '#FAFAFA',
                    borderRadius: '8px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>{detailsDatas.schedule ? dayjs(detailsDatas.schedule).format('DD/MMM/YYYY') : '-'}</Typography>
                </Box>
              </Grid>
            </>
          )}
          {/* SUbmit */}
          <Box
            sx={{
              width: '100%',
              bgcolor: 'white',
              position: 'fixed',
              bottom: '0px',
              right: '0px',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '18px 32px 18px 240px',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>Publish</Typography>
              {!isLoadingDataDetails && <AntSwitch checked={status} onChange={handleEditStatus} />}
            </Box>
            <Box sx={{ display: 'flex', gap: '20px' }}>
              <CustomButton variant="contained" color="error" sx={{ width: '140px', height: '36px' }} onClick={handleDelete}>
                <Typography sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}>Delete</Typography>
              </CustomButton>
              <CustomButton variant="contained" sx={{ width: '140px', height: '36px' }} onClick={handleSubmit}>
                <Typography sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}>Edit</Typography>
              </CustomButton>
            </Box>
          </Box>
        </Grid>
      ) : (
        <CategoryProductDetailsMobile
          {...{
            handleBackButton,
            roadHirarki,
            isLoadingDataDetails,
            timeFrame,
            setTimeFrame,
            dateRange,
            setDateRange,
            saveDateRange,
            setSaveDateRange,
            detailsDatas,
            handleDelete,
            handleSubmit,
            linkPreviewPhoto,
            setLinkPreviewPhoto,
            isPreviewPhoto,
            setIsPreviewPhoto,
            handlePreviewPhoto,
            status,
            handleEditStatus,
          }}
        />
      )}
    </>
  );
};
export default CategoryProductDetails;
