import { createSlice } from '@reduxjs/toolkit';

export const auditRoutingState = createSlice({
  name: 'auditRouting',
  initialState: {
    role: '',
    page: '',
    // idAudit: 0,
    // idRequest: 0,
    keyNotif: '',
    textNotif: '',
    // subsidiaryName: '',
    // hubName: '',
    statusAudit: '',
  },
  reducers: {
    changePageAudit: (state, action) => {
      state.page = action.payload;
    },
    changeAuditRoutingState: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setNotifAudit: (state, action) => {
      const { key, text } = action.payload;
      state.keyNotif = key;
      state.textNotif = text;
    },
    setCloseNotif: (state) => {
      state.keyNotif = '';
      state.textNotif = '';
    },
    setHirarkiAudit: (state, action) => {
      const { subsidiary, hub } = action.payload;
      state.subsidiaryName = subsidiary;
      state.hubName = hub;
    },
    changeStatusForAudit: (state, action) => {
      state.statusAudit = action.payload;
    },
  },
});

export const {
  changePageAudit,
  changeAuditRoutingState,
  setNotifAudit,
  setCloseNotif,
  setHirarkiAudit,
  changeStatusForAudit,
} = auditRoutingState.actions;

export const getAllAuditRouting = (state) => state.auditRouting;

export default auditRoutingState.reducer;
