import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "../../../../../../../../components/shared/BackButton";
import { getIdMasterHub } from "../../../../../../../../store/hub/hirarkiHub";
import {
  fetchDetailsDataMasterHub,
  fetchMasterHubRacksPreview,
  getDetailsDataMasterHub,
  getLoadingDetailsDataMasterHub,
  getRacksPositionPreviewMasterHub,
  getRacksPreviewMasterHub,
} from "../../../../../../../../store/hub/masterHub/masterHub";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ReactComponent as NoImage } from "../../../../../../../../assets/no-image.svg";
import CustomCard from "../../../../../../../../components/shared/CustomCard";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { createSearchParams, useSearchParams } from "react-router-dom";
import CustomDialog from "../../../../../../../../components/shared/CustomDialog";
import RackMapPreview from "./RackMapPreview";
import CloseIcon from "@mui/icons-material/Close";
import { Map, Marker, ZoomControl } from "pigeon-maps";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import {
  changeIsEdit,
  getIsMasterHubEdit,
  goToMasterHubEdit,
} from "../../../../../../../../store/hub/masterHub/masterHubEdit";
import MasterHubEdit from "../Edit/MasterHubEdit";
import CustomAntSwitch from "../../../../../../../../components/shared/CustomAntSwitch";
import CustomButton from "../../../../../../../../components/shared/CustomButton";
import {
  deleteMasterHub,
  editMasterHub,
  editMasterHubStatus,
  changeCoordinate,
  changeCenter,
} from "../../../../../../../../api/hubs";
import CustomDeleteConfirm from "../../../../../../../../components/shared/CustomDeleteConfirm";
import CustomNotifSuccess from "../../../../../../../../components/shared/CustomNotifSuccess";
import {
  coordinate,
  getAllMasterHubDetail,
} from "../../../../../../../../store/hub/masterHub/masterHubDetail";
import {
  clearNotifHub,
  getNotifHub,
} from "../../../../../../../../store/hub/notifHub";
import { inputCellSubForRack } from "../../../../../../../../store/racks/masterHubRack/masterHubRack";
import { goToRackHub } from "../../../../../../../../store/racks/rackHub";
import Star from "../../../../../../../../components/shared/Star";
import LocationSearchingRoundedIcon from "@mui/icons-material/LocationSearchingRounded";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { ReactComponent as CameraLocationToMarker } from "../../../../../../../../assets/location-to-marker.svg";

const DetailsMasterHub = ({ setRowSelected, setMasterHubIdRack }) => {
  const idMasterHub = useSelector(getIdMasterHub);
  const detailsData = useSelector(getDetailsDataMasterHub);
  const loading = useSelector(getLoadingDetailsDataMasterHub);
  const isEdit = useSelector(getIsMasterHubEdit);
  const dispatch = useDispatch();
  const { key, text: textNotif } = useSelector(getNotifHub);

  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);

  // *! -------------------------- preview  -----------------------------
  const [linkPreviewPhoto, setLinkPreviewPhoto] = useState("");
  const [isPreviewPhoto, setIsPreviewPhoto] = useState(false);
  const [linkPreviewVideo, setLinkPreviewVideo] = useState("");
  const [isPreviewVideo, setIsPreviewVideo] = useState(false);

  const [isPreviewRackMap, setIsPreviewRackMap] = useState(false);
  const [isPreviewAddressMap, setIsPreviewAddressMap] = useState(false);

  const [addressMapZoom, setAddressMapZoom] = useState(17);
  const [addressMapCenter, setAddressMapCenter] = useState([
    3.5465944896879704, 98.68976198831375,
  ]);

  const rackSelected = useSelector(getRacksPositionPreviewMasterHub);
  const racksData = useSelector(getRacksPreviewMasterHub);

  useEffect(() => {
    dispatch(fetchDetailsDataMasterHub(idMasterHub));
    dispatch(fetchMasterHubRacksPreview(idMasterHub));
    if (key && !isEdit) {
      dispatch(fetchDetailsDataMasterHub(idMasterHub));
      dispatch(fetchMasterHubRacksPreview(idMasterHub));
    }
  }, [dispatch, idMasterHub, key, isEdit]);

  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  // *! -------------------------- Status  -----------------------------
  const [statusHub, setStatusHub] = useState(false);

  useEffect(() => {
    if (!loading) {
      if (detailsData.status === "aktif") {
        return setStatusHub(true);
      } else {
        return setStatusHub(false);
      }
    }
  }, [loading]);

  const handleEditStatus = () => {
    if (statusHub) {
      editMasterHubStatus(detailsData.idToko, "pending");
      setStatusHub(false);
    } else {
      editMasterHubStatus(detailsData.idToko, "aktif");
      setStatusHub(true);
    }
  };

  // *! -------------------------- Handler  -----------------------------

  const handleBackButton = () => {
    setRowSelected(false);
  };

  const handlePreviewPhoto = (link) => {
    setLinkPreviewPhoto(link);
    setIsPreviewPhoto(true);
  };

  const handlePreviewVideo = (link) => {
    setLinkPreviewVideo(link);
    setIsPreviewVideo(true);
  };

  const handleClosePreviewVideo = () => {
    setIsPreviewVideo(!isPreviewVideo);
    setLinkPreviewVideo("");
  };

  const handleToRack = () => {
    // console.log('id', idMasterHub);
    // setMasterHubIdRack(idMasterHub);
    // setRowSelected(null);
    // updatedSearchParams.set('masterhub-idrack', idMasterHub);
    // setSearchParams(updatedSearchParams);
    // dispatch(
    //   inputCellSubForRack({
    //     col: detailsData.mapColumn,
    //     row: detailsData.mapRow,
    //     rackSelected: rackSelected,
    //   })
    // );
    dispatch(
      goToRackHub({
        path: "hub",
        id: idMasterHub,
        cellSelected: rackSelected,
        col: detailsData.mapColumn,
        row: detailsData.mapRow,
      })
    );
  };

  const handleToEdit = () => {
    dispatch(
      goToMasterHubEdit({
        name: detailsData.namaToko,
        col: detailsData.mapColumn,
        row: detailsData.mapRow,
        rackDetails: racksData,
        rackSelected: rackSelected,
        pic: detailsData.pic,
        picNumber: detailsData.nohp,
        status: statusHub,
        entrance: detailsData.entrancePosition
          ? detailsData.entrancePosition.split(",")
          : [],
        subsidiaryId: detailsData.subsidiaryId,
        photo: detailsData.gambar ? detailsData.gambar.split(",") : [],
        video: detailsData.videos ? detailsData.videos.split(",") : [],
        address: detailsData.alamat,
        coordinate: [
          Number(detailsData?.mapCor?.lat),
          Number(detailsData?.mapCor?.long),
        ],
        typeMap: detailsData.mapCor.typeInput,
        citydistrict: detailsData.mapCor.cityDistrict,
      })
    );
  };

  const handleDelete = () => {
    setIsDeleteConfirm(true);
  };

  const handleDeleteFix = () => {
    deleteMasterHub(detailsData.idToko)
      .then((res) => {
        setRowSelected();
        setMasterHubIdRack();
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (!isEdit) {
      setAddressMapCenter([
        Number(detailsData?.mapCor?.lat),
        Number(detailsData?.mapCor?.long),
      ]);
    }
  }, [isEdit, detailsData?.mapCor]);

  // console.log(rackSelected);

  return (
    <Box sx={{ mt: "16px" }}>
      <CustomDeleteConfirm
        open={isDeleteConfirm}
        onClose={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickCancel={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Menu"
        body={
          <Box sx={{ textAlign: "center" }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: "600" }}>
                {detailsData.namaToko}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: "52px" }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />
      {!isEdit ? (
        <Grid container columns={12} spacing={4}>
          <CustomNotifSuccess
            text={textNotif}
            open={key === "editmasterhub"}
            onClose={() => dispatch(clearNotifHub())}
          />
          {/* //* Back Button */}
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <BackButton handleClick={handleBackButton} />
            <Box>
              <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
                {detailsData.namaToko} Details
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  color: "#9E9D9D",
                  alignItems: "center",
                  mt: "4px",
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: "100" }}>
                  {detailsData?.subsidiary
                    ? detailsData?.subsidiary?.name
                    : "-"}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {!loading ? (
            <>
              {/* //* Name */}
              <Grid item xs={12}>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                    Name
                  </Typography>
                  <Star />
                </Box>
                <Box
                  sx={{
                    mt: "12px",
                    width: "100%",
                    padding: "14px 16px",
                    bgcolor: "#FAFAFA",
                    borderRadius: "8px",
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    {detailsData.namaToko}
                  </Typography>
                </Box>
              </Grid>
              {/* //* Photo */}
              <Grid item xs={6}>
                <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                  Photo
                </Typography>
                <Box
                  sx={{
                    mt: "12px",
                    width: "100%",
                    padding: "0",
                    display: "flex",
                    gap: 2,
                  }}
                >
                  {!detailsData.gambar ? (
                    <Box
                      sx={{
                        width: "54px",
                        height: "54px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#FAFAFA",
                        borderRadius: "2px",
                      }}
                    >
                      <NoImage />
                      <Typography sx={{ color: "#9E9D9D", fontSize: "6px" }}>
                        No photo
                      </Typography>
                    </Box>
                  ) : (
                    detailsData.gambar.split(",").map((item, index) => (
                      <Box
                        component="img"
                        src={
                          process.env.REACT_APP_API_IMAGE_BASE_URL + "/" + item
                        }
                        onClick={() => handlePreviewPhoto(item)}
                        sx={{
                          width: "54px",
                          height: "54px",
                          aspectRatio: "1/1",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                        key={index}
                      />
                    ))
                  )}
                </Box>
              </Grid>
              {/* //* Dialog Photo */}
              {linkPreviewPhoto !== "" && (
                <Dialog
                  open={isPreviewPhoto}
                  onClose={() => {
                    setIsPreviewPhoto(!isPreviewPhoto);
                    setLinkPreviewPhoto("");
                  }}
                >
                  <Box
                    component="img"
                    src={
                      process.env.REACT_APP_API_IMAGE_BASE_URL +
                      "/" +
                      linkPreviewPhoto
                    }
                    sx={{
                      width: "600px",
                      height: "600px",
                      aspectRation: "1/1",
                    }}
                  />
                </Dialog>
              )}
              {/* //* Video */}
              <Grid item xs={6}>
                <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                  Video
                </Typography>
                <Box
                  sx={{
                    mt: "12px",
                    width: "100%",
                    padding: "0",
                    display: "flex",
                  }}
                >
                  {!detailsData.videos ? (
                    <Box
                      sx={{
                        width: "54px",
                        height: "54px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#FAFAFA",
                        borderRadius: "2px",
                      }}
                    >
                      <NoImage />
                      <Typography sx={{ color: "#9E9D9D", fontSize: "6px" }}>
                        No photo
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", gap: 2 }}>
                      {detailsData.videos.split(",").map((input, index) => (
                        <Box
                          onClick={() => handlePreviewVideo(input)}
                          key={index}
                        >
                          <video
                            style={{
                              width: "54px",
                              height: "54px",
                              borderRadius: "4px",
                              cursor: "pointer",
                              backgroundColor: "black",
                            }}
                          >
                            <source
                              src={
                                process.env.REACT_APP_API_IMAGE_BASE_URL +
                                "/" +
                                input +
                                "#t=0.5"
                              }
                            />
                          </video>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              </Grid>
              {/* //* Dialog Video */}
              {linkPreviewVideo !== "" && (
                <Dialog open={isPreviewVideo} onClose={handleClosePreviewVideo}>
                  <Box sx={{ bgcolor: "black" }}>
                    <video
                      style={{
                        width: "600px",
                        height: "600px",
                        backgroundColor: "black",
                      }}
                      controls
                    >
                      <source
                        src={
                          process.env.REACT_APP_API_IMAGE_BASE_URL +
                          "/" +
                          linkPreviewVideo
                        }
                      />
                    </video>
                  </Box>
                </Dialog>
              )}
              {/* //* PIC */}
              <Grid item xs={6}>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                    PIC
                  </Typography>
                  <Star />
                </Box>
                <Box
                  sx={{
                    mt: "12px",
                    width: "100%",
                    padding: "14px 16px",
                    bgcolor: "#FAFAFA",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <PersonIcon />
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    {detailsData?.pic ? detailsData?.pic?.namaLengkap : "-"}
                  </Typography>
                </Box>
              </Grid>
              {/* //* Phone Number */}
              <Grid item xs={6}>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                    Phone Number
                  </Typography>
                  <Star />
                </Box>
                <Box
                  sx={{
                    mt: "12px",
                    width: "100%",
                    padding: "14px 16px",
                    bgcolor: "#FAFAFA",
                    borderRadius: "8px",
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    {detailsData?.pic?.telpon ? detailsData?.pic?.telpon : "-"}
                  </Typography>
                </Box>
              </Grid>
              {/* //* Address */}
              <Grid item xs={6}>
                <CustomCard
                  sx={{ p: "8px 16px", cursor: "pointer" }}
                  onClick={() => setIsPreviewAddressMap(true)}
                >
                  <Box
                    sx={{
                      display: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Box
                        sx={{
                          width: "66px",
                          height: "66px",
                          bgcolor: "gray",
                          borderRadius: "8px",
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            bgcolor: "white",
                            width: "100%",
                            height: "100%",
                            zIndex: "+2",
                            opacity: 0.5,
                          }}
                        ></Box>
                        <Box
                          sx={{
                            maxHeight: "66px",
                            overflow: "hidden",
                            borderRadius: "8px",
                          }}
                        >
                          {detailsData?.mapCor && (
                            <Map
                              defaultCenter={[
                                Number(detailsData?.mapCor?.lat),
                                Number(detailsData?.mapCor?.long),
                              ]}
                              height={130}
                              defaultZoom={17}
                              width={66}
                              style={{
                                borderRadius: "8px",
                              }}
                            ></Map>
                          )}
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{ fontWeight: "500", mb: "4px", ml: "8px" }}
                        >
                          Address
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.4,
                          }}
                        >
                          <LocationOnIcon color="primary" />
                          <Typography>
                            {detailsData?.alamat ? detailsData?.alamat : "-"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <KeyboardArrowDownIcon />
                  </Box>
                </CustomCard>
              </Grid>
              {/* //* Rack  */}
              <Grid item xs={6}>
                <CustomCard
                  sx={{ p: "8px 16px", cursor: "pointer" }}
                  onClick={() => setIsPreviewRackMap(!isPreviewRackMap)}
                >
                  <Box
                    sx={{
                      display: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Box
                        sx={{
                          height: "66px",
                          borderRadius: "8px",
                        }}
                      ></Box>
                      <Box sx={{ ml: "8px" }}>
                        <Typography sx={{ fontWeight: "500", mb: "4px" }}>
                          Racks Map
                        </Typography>
                        <Typography sx={{ color: "#9E9D9D", fontSize: "12px" }}>
                          {racksData.length} Racks
                        </Typography>
                      </Box>
                    </Box>
                    <KeyboardArrowDownIcon />
                  </Box>
                </CustomCard>
              </Grid>
              {/* //* See Hub Racks */}
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleToRack}
                >
                  <Typography color="primary">See Hub Racks</Typography>
                  <ArrowForwardIosIcon
                    color="primary"
                    sx={{ fontSize: "12px" }}
                  />
                </Box>
              </Grid>
              {/* //* Dialog Rack Map */}
              <CustomDialog
                maxWidth="md"
                isOpen={isPreviewRackMap}
                setIsOpen={setIsPreviewRackMap}
              >
                <RackMapPreview
                  col={detailsData.mapColumn}
                  row={detailsData.mapRow}
                  entrance={detailsData?.entrancePosition}
                  setIsOpen={setIsPreviewRackMap}
                />
              </CustomDialog>
              {/* //* Dialog Addres Map */}

              <Dialog
                maxWidth="md"
                open={isPreviewAddressMap}
                onClose={() => setIsPreviewAddressMap(!isPreviewAddressMap)}
              >
                <DialogTitle>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Address</Typography>
                    <CloseIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        setIsPreviewAddressMap(!isPreviewAddressMap)
                      }
                    />
                  </Box>
                </DialogTitle>

                <DialogContent dividers>
                  <Box sx={{ position: "relative", mt: "12px" }}>
                    <Box sx={{ position: "absolute", zIndex: "+5", bottom: 4 }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                          alignItems: "center",
                          mb: "8px",
                          ml: "8px",
                          p: "4px",
                          borderRadius: "8px",
                        }}
                      >
                        <Box
                          // onClick={handleGetCurrentCoordinate}
                          sx={{
                            width: "24px",
                            height: "24px",
                            bgcolor: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "50%",
                            cursor: "pointer",
                            boxShadow: "8px",
                          }}
                        />
                        {addressMapZoom >= 17 && (
                          <Typography
                            color="error"
                            sx={{ fontSize: "12px", textAlign: "center" }}
                          >
                            {detailsData.namaToko}
                          </Typography>
                        )}
                        <LocationSearchingRoundedIcon
                          sx={{
                            fontSize: "20px",
                            color: "#51B15C",
                          }}
                        />
                      </Box>
                      <Box
                        // onClick={handleResetZoom}
                        sx={{
                          width: "24px",
                          height: "24px",
                          bgcolor: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          cursor: "pointer",
                          boxShadow: "8px",
                        }}
                      >
                        <CameraLocationToMarker
                          style={{ fontSize: "20px", color: "#51B15C" }}
                        />
                      </Box>
                      <Box
                        // onClick={handleResetMarker}
                        sx={{
                          width: "24px",
                          height: "24px",
                          bgcolor: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          cursor: "pointer",
                          boxShadow: "8px",
                        }}
                      >
                        <PinDropIcon
                          sx={{ fontSize: "20px", color: "#F64444" }}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Map
                    height={300}
                    width={560}
                    center={addressMapCenter}
                    zoom={addressMapZoom}
                    onBoundsChanged={({ center, zoom }) => {
                      setAddressMapCenter(center);
                      setAddressMapZoom(zoom);
                    }}
                  >
                    <ZoomControl />
                    <Marker
                      anchor={[
                        Number(detailsData?.mapCor?.lat),
                        Number(detailsData?.mapCor?.long),
                      ]}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <LocationOnRoundedIcon
                          color="error"
                          sx={{
                            fontSize: `${
                              30 - (20 - Math.floor(addressMapZoom))
                            }px`,
                          }}
                        />
                        {addressMapZoom >= 17 && (
                          <Typography
                            color="error"
                            sx={{ fontSize: "12px", textAlign: "center" }}
                          >
                            {detailsData.namaToko}
                          </Typography>
                        )}
                      </Box>
                    </Marker>
                    {/* {addressMapZoom > 15 && (
                      <Marker anchor={[3.5464766985100273, 98.6915751613956]}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <LocationOnRoundedIcon
                            color="primary"
                            sx={{
                              fontSize: `${
                                30 - (20 - Math.floor(addressMapZoom))
                              }px`,
                            }}
                          />
                          {addressMapZoom >= 17 && (
                            <Typography
                              color="primary"
                              sx={{ fontSize: '12px', textAlign: 'center' }}
                            >
                              binjai
                            </Typography>
                          )}
                        </Box>
                      </Marker>
                    )} */}
                  </Map>
                  <Typography sx={{ mt: "12px" }}>
                    {detailsData?.alamat}
                  </Typography>
                </DialogContent>
              </Dialog>

              <Box
                sx={{
                  width: "100%",
                  // bgcolor: "white",
                  position: "fixed",
                  bottom: "0px",
                  right: "0px",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "18px 32px 18px 240px",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                    Publish
                  </Typography>
                  <CustomAntSwitch
                    checked={statusHub}
                    onChange={(e) => handleEditStatus(e)}
                  />
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <CustomButton
                    variant="contained"
                    color="error"
                    sx={{ width: "140px", height: "36px" }}
                    onClick={handleDelete}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      Delete
                    </Typography>
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    sx={{ width: "140px", height: "36px" }}
                    onClick={() => handleToEdit()}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      Edit
                    </Typography>
                  </CustomButton>
                </Box>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                width: "100%",
                minHeight: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress sx={{ mt: "40px" }} />
            </Box>
          )}
        </Grid>
      ) : (
        <MasterHubEdit />
      )}
    </Box>
  );
};

export default DetailsMasterHub;
