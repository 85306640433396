import {
  Box,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import {
  getRacksPositionPreviewAllSubHub,
  getRacksPositionPreviewSubHub,
  getRacksPreviewSubHub,
} from "../../../../../../../../store/hub/subHub/subHubDetails";
import { CloseIcon } from "../../../../../../../../assets/Icons";
import CustomAntSwitch from "../../../../../../../../components/shared/CustomAntSwitch";

const CustomTableCells = styled(TableCell)({
  color: "#000000",
  fontWeight: 500,
  fontSize: "10px",
  textTransform: "capitalize",
});

const RackMapPreviewMobileSubHub = ({ col, row, entrance, setIsOpen }) => {
  const rackSelect = useSelector(getRacksPositionPreviewSubHub);
  const rackSelectAll = useSelector(getRacksPositionPreviewAllSubHub);
  const racks = useSelector(getRacksPreviewSubHub);
  console.log({ racks });

  const [rackListChoose, setRackListChoose] = useState([]);
  const [racksAll, setRacksAll] = useState([]);
  const [isGrid, setIsGrid] = useState(false);

  useEffect(() => {
    setRacksAll(rackSelectAll.filter((item) => !rackListChoose.includes(item)));
  }, [rackListChoose, rackSelectAll]);

  const handleHoverRack = (cell) => {
    if (rackSelectAll.includes(cell)) {
      setRackListChoose(rackSelect.find((item) => item?.includes(cell)));
    }
  };
  const handleUnHoverRack = (cell) => {
    setRackListChoose([]);
  };

  return (
    <>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>Rack Position</Typography>
        <CloseIcon
          onClick={() => setIsOpen(false)}
          sx={{ cursor: "pointer" }}
        />
      </DialogTitle>

      <DialogContent
        dividers
        sx={{
          minWidth: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        {/* grid view */}
        <Box>
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "end", mb: "20px" }}
          >
            <Typography>Grid</Typography>
            <CustomAntSwitch
              value={isGrid}
              onClick={() => setIsGrid(!isGrid)}
            />
          </Box>

          <Box>
            {[...Array(row)].map((_, rowIndex) => (
              <Box sx={{ display: "flex" }} key={rowIndex}>
                {[...Array(col)].map((_, colIndex) => (
                  <Box
                    // onClick={() => dispatch(seeDetailRack())}
                    onMouseOver={() =>
                      handleHoverRack(`r${rowIndex + 1}c${colIndex + 1}`)
                    }
                    onMouseOut={() =>
                      handleUnHoverRack(`r${rowIndex + 1}c${colIndex + 1}`)
                    }
                    sx={[
                      {
                        width: "18px",
                        height: "18px",
                      },
                      isGrid && { border: 1 },
                      isGrid &&
                        rowIndex === 0 && {
                          bgcolor: "#F5F5F5",
                          border: 0,
                        },
                      isGrid &&
                        rowIndex === row - 1 && {
                          bgcolor: "#F5F5F5",
                          border: 0,
                        },
                      isGrid &&
                        colIndex === 0 && {
                          bgcolor: "#F5F5F5",
                          border: 0,
                        },
                      isGrid &&
                        colIndex === col - 1 && {
                          bgcolor: "#F5F5F5",
                          border: 0,
                        },
                      racksAll?.includes(
                        `r${rowIndex + 1}c${colIndex + 1}`
                      ) && {
                        bgcolor: "#E4E4E4",
                      },
                      entrance &&
                        entrance
                          .split(",")
                          .includes(`r${rowIndex + 1}c${colIndex + 1}`) && {
                          bgcolor: "#171717",
                        },
                      rackListChoose.includes(
                        `r${rowIndex + 1}c${colIndex + 1}`
                      ) && {
                        bgcolor: "#51B15C",
                        transition: "width 0.1s",
                        transitionTimingFunction: "ease-in-out",
                        // cursor: 'pointer',
                      },
                    ]}
                    key={colIndex}
                  ></Box>
                ))}
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
              mt: "8px",
            }}
          >
            <Box
              sx={{
                width: "10px",
                height: "10px",
                borderRadius: "4px",
                bgcolor: "#171717",
              }}
            ></Box>
            <Typography
              sx={{ fontSize: "8px", color: "#171717", fontWeight: "600" }}
            >
              Entrance
            </Typography>
          </Box>
        </Box>

        {/* table */}
        <Box>
          <Table size="small">
            <TableHead sx={{ bgcolor: "#FAFAFA" }}>
              <TableRow>
                <CustomTableCells>Code</CustomTableCells>
                <CustomTableCells sx={{ color: "#4339F2" }}>
                  Column
                </CustomTableCells>
                <CustomTableCells sx={{ color: "#EACD57" }}>
                  Level
                </CustomTableCells>
              </TableRow>
            </TableHead>
            <TableBody>
              {racks.map((data, index) => (
                <TableRow
                  key={index}
                  // onClick={() => dispatch(seeDetailRack())}
                  onMouseOver={() => {
                    setRackListChoose(data.columnPosition.split(","));
                  }}
                  onMouseOut={() => {
                    setRackListChoose([]);
                  }}
                  // onMouseOut={() => dispatch(unHoverCellFix())}
                  sx={[
                    data.columnPosition === rackListChoose.join(",") && {
                      bgcolor: "#51B15C",
                    },
                  ]}
                >
                  <CustomTableCells
                    sx={[
                      data.columnPosition === rackListChoose.join(",") && {
                        color: "white",
                      },
                    ]}
                  >
                    {data.name}
                  </CustomTableCells>
                  <CustomTableCells
                    align="right"
                    sx={[
                      data.columnPosition === rackListChoose.join(",") && {
                        color: "white",
                      },
                    ]}
                  >
                    {data.column}
                  </CustomTableCells>
                  <CustomTableCells
                    align="right"
                    sx={[
                      data.columnPosition === rackListChoose.join(",") && {
                        color: "white",
                      },
                    ]}
                  >
                    {data.row}
                  </CustomTableCells>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
    </>
  );
};
export default RackMapPreviewMobileSubHub;
