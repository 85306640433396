import { useDispatch, useSelector } from 'react-redux';
import BackButton from '../../../../../../components/shared/BackButton';
import {
  changePage,
  getAllPurchaseRouting,
} from '../../../../../../store/Routing/purchaseRouting';
import { Box, Typography, useMediaQuery } from '@mui/material';
import {
  ArrowBackIosIcon,
  ArrowForwardIosIcon,
  ArrowRightAltIcon,
} from '../../../../../../assets/Icons';

const BackButtonPurchaseProcessDetailSupplierSubmit = () => {
  const dispatch = useDispatch();
  const { idPurchase, subsidiaryName, hubName } = useSelector(
    getAllPurchaseRouting
  );

  const handleBackButton = () => {
    dispatch(changePage('purchaseProcessDetailSupplier'));
  };

  const desktopMode = useMediaQuery('(min-width:832px)');
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            gap: 0.5,
          }}
        >
          <Typography
            sx={{
              fontSize: desktopMode ? '24px' : '14px',
              fontWeight: '600',
              color: '#9E9D9D',
            }}
          >
            Purchase Details
          </Typography>
          <ArrowForwardIosIcon
            sx={{ color: '#9E9D9D', fontSize: '10px', fontWeight: '600' }}
          />
          <Typography
            sx={{ fontSize: desktopMode ? '24px' : '14px', fontWeight: '600' }}
          >
            Send
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default BackButtonPurchaseProcessDetailSupplierSubmit;
