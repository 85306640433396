import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactComponent as ToLineChart } from '../../../../../../../assets/to-line-chart.svg';
import { ReactComponent as ToBarChart } from '../../../../../../../assets/to-bar-chart.svg';
import { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import CustomDateRange from '../../../../../../../components/shared/CustomDateRange';
import CustomLineChart from '../../../../../../../components/shared/CustomLineChart';
import { useSelector } from 'react-redux';
import { getAllPurchaseRouting } from '../../../../../../../store/Routing/purchaseRouting';
import CustomBarChart from '../../../../../../../components/shared/CustomBarChart';

// data dummy chart
const datas = {
  labels: [
    'jan',
    'feb',
    'mar',
    'apr',
    'jun',
    'jul',
    'augst',
    'sept',
    'oct',
    'nov',
    'dec',
  ],
  data: [
    {
      label: 'orders',
      chart: [12, 42, 45, 12, 67],
    },
    {
      label: 'sold',
      chart: [92, 32, 45, 23, 34, 32, 76, 56, 87, 35],
    },
    {
      label: 'used',
      chart: [34, 34, 54, 45, 65, 43, 65, 45, 34, 89],
    },
  ],
};

const color = ['#51B15C', '#FEDD25', '#4339F2', '#D839F2', '#FD3A29'];

const ChartTransactionMobile = (props) => {
  const { page } = useSelector(getAllPurchaseRouting);
  const {
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    timeFrame,
    setTimeFrame,
  } = props;

  const [isBarChart, setIsBarChart] = useState(false);

  return (
    <Box>
      {page === 'request' ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontSize: '10px', fontWeight: 600 }}>
            Top Purchased Product
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center ' }}>
            <CustomDateRange
              dateRange={dateRange}
              setDateRange={setDateRange}
              saveDateRange={saveDateRange}
              setSaveDateRange={setSaveDateRange}
              sx={{ maxHeight: '34px', pl: '10px', fontSize: '10px' }}
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontSize: '10px', fontWeight: 600 }}>
            Top Purchased Product
          </Typography>
          {/* Select */}
          <FormControl sx={{ m: 1, minWidth: 100 }}>
            <Select
              value={timeFrame}
              onChange={(e) => setTimeFrame(e.target.value)}
              sx={{
                p: 0,
                '.MuiOutlinedInput-input': {
                  paddingTop: '7.5px',
                  paddingBottom: '7.5px',
                  paddingLeft: '10px',
                  paddingRight: '0',
                  borderRadius: '5px',
                },
                fontSize: '12px',
                mr: '12px',
              }}
            >
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', alignItems: 'center ' }}>
            <CustomDateRange
              dateRange={dateRange}
              setDateRange={setDateRange}
              saveDateRange={saveDateRange}
              setSaveDateRange={setSaveDateRange}
              sx={{ maxHeight: '34px', pl: '10px', fontSize: '10px' }}
            />
          </Box>
        </Box>
      )}

      {/* grafik */}

      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
            mb: 1,
          }}
        >
          <Box>
            <Typography
              sx={{ fontSize: '7px', fontWeight: 700, color: '#51B15C' }}
            >
              COGS (Rp)*
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              sx={{
                width: '16px',
                height: '16px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={() => setIsBarChart(!isBarChart)}
            >
              {isBarChart ? (
                <ToLineChart stroke="#51B15C" />
              ) : (
                <ToBarChart stroke="#51B15C" />
              )}
            </Box>
          </Box>
          {isBarChart ? (
            <Box>
              <Typography
                sx={{ fontSize: '7px', fontWeight: 700, color: '#EACD57' }}
              >
                SKU
              </Typography>
            </Box>
          ) : (
            <Box></Box>
          )}
        </Box>

        <Box sx={{ maxHeight: '200px', height: '200px', minHeight: '200px' }}>
          {isBarChart ? (
            <CustomBarChart datas={datas} color={color} />
          ) : (
            <CustomLineChart datas={datas} color={color} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default ChartTransactionMobile;
