import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@mui/icons-material';

// import { ReactComponent as AssetIcon } from '../assets/asset.svg';
// import { ReactComponent as EquityIcon } from '../assets/equity.svg';
// import { ReactComponent as ExpenseIcon } from '../assets/expense.svg';
// import { ReactComponent as LiabilityIcon } from '../assets/liability.svg';
// import { ReactComponent as RevenueIcon } from '../assets/revenue.svg';
import {
  Box,
  ButtonBase,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Typography,
  AppBar,
  Menu,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { decamelize } from 'humps';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
  fetchBedSummary,
  fetchClinicPerformances,
  fetchIncentives,
  fetchOrderActivities,
  fetchOrderCount,
  fetchOrders,
  fetchPatientStatistics,
  fetchPopularOrders,
  fetchRevenueSummary,
  fetchSalesTotal,
  fetchStaffPerformances,
  fetchStocks,
  fetchTreatmentProcessTimes,
  fetchTreatmentWaitingTimes,
} from '../api/assets';

import moneyImage from '../assets/money.jpg';
import { formatNumberToCurrency } from '../utils/currency';
import useAuth from '../hooks/useAuth';
import {
  kanpaiCustomerStatistic,
  kanpaiDineIn,
  kanpaiLeastPopularMenu,
  kanpaiOperationalHours,
  kanpaiOrderCount,
  kanpaiOrderStatus,
  kanpaiOutletPerformances,
  kanpaiPopularMenu,
  kanpaiRecentOrders,
  kanpaiRevenueSummary,
  kanpaiSalesTotal,
  kanpaiStaffPerformance,
  kanpaiTableSummary,
  kanpaiTakeaway,
} from '../api/kanpai';

const drawerWidth = '13rem';

const CLINIC_PERFORMANCE_PAGE_SIZE = 5;

const headCells = [
  {
    id: 'clinicName',
    numeric: false,
    label: 'Clinic',
  },
  {
    id: 'avgRating',
    numeric: true,
    label: 'Avg. Rating',
  },
  {
    id: 'totalPatients',
    numeric: true,
    label: 'Total Patient',
  },
  {
    id: 'totalSales',
    numeric: true,
    label: 'Total Sales',
  },
];

const headCellsKanpai = [
  {
    id: 'outletName',
    numeric: false,
    label: 'Outlet',
  },
  {
    id: 'avgRating',
    numeric: true,
    label: 'Avg. Rating',
  },
  {
    id: 'totalCustomer',
    numeric: true,
    label: 'Customer',
  },
  {
    id: 'totalSales',
    numeric: true,
    label: 'Sales',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, companyId } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {companyId === 9
          ? headCellsKanpai.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{ borderBottom: 0 }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  sx={{ fontSize: '0.75rem' }}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))
          : headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{ borderBottom: 0 }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  sx={{ fontSize: '0.75rem' }}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
      </TableRow>
    </TableHead>
  );
}

const getXAxisLabel = (timeframe) => {
  switch (timeframe) {
    case 'daily':
      return 'Day of Month';
    case 'monthly':
      return 'Month';
    case 'weekly':
      return 'Week';
    default:
      return '';
  }
};

const ordinalSuffixOf = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return 'st';
  }
  if (j === 2 && k !== 12) {
    return 'nd';
  }
  if (j === 3 && k !== 13) {
    return 'rd';
  }
  return 'th';
};

const average = (ctx, index) => {
  if (ctx.chart.data.datasets.length > 0) {
    const values = ctx.chart.data.datasets[index].data;
    return values.reduce((a, b) => a + b, 0) / values.length;
  }

  return 0;
};

export function Dashboard() {
  const { auth } = useAuth();
  const theme = useTheme();

  const [anchorElSelectCompany, setAnchorElSelectCompany] = useState(null);
  const openSelectCompany = Boolean(anchorElSelectCompany);
  const handleClickSelectCompany = (event) =>
    setAnchorElSelectCompany(event.currentTarget);
  const handleCloseSelectCompany = (event) => setAnchorElSelectCompany(null);
  const handleChooseSelectCompany = (value) => {
    localStorage.setItem('companyId', value);
    window.location.reload();
  };

  const salesPerformanceChartRef = useRef(null);
  const [salesPerformanceChartData, setSalesPerformanceChartData] = useState({
    datasets: [],
  });
  const patientStatisticChartRef = useRef(null);
  const [patientStatisticChartData, setPatientStatisticChartData] = useState({
    datasets: [],
  });
  const staffRatingChartRef = useRef(null);
  const [staffRatingChartData, setStaffRatingChartData] = useState({
    datasets: [],
  });
  const waitingTimeChartRef = useRef(null);
  const [waitingTimeChartData, setWaitingTimeChartData] = useState({
    datasets: [],
  });
  const treatmentTimeChartRef = useRef(null);
  const [treatmentTimeChartData, setTreatmentTimeChartData] = useState({
    datasets: [],
  });

  const [revenueSummary, setRevenueSummary] = useState();
  const [bedSummary, setBedSummary] = useState();
  const [orderActivities, setOrderActivities] = useState([]);
  const [popularOrders, setPopularOrders] = useState([]);
  const [incentives, setIncentives] = useState([]);

  const [popularOrdersInputStartDate, setPopularOrdersInputStartDate] =
    useState(dayjs().subtract(6, 'day'));
  const [popularOrdersStartDate, setPopularOrdersStartDate] = useState(
    dayjs().subtract(6, 'day')
  );
  const [popularOrdersInputEndDate, setPopularOrdersInputEndDate] = useState(
    dayjs()
  );
  const [popularOrdersEndDate, setPopularOrdersEndDate] = useState(dayjs());

  const [operationalInputStartDate, setOperationalInputStartDate] = useState(
    dayjs().subtract(6, 'day')
  );
  const [operationalStartDate, setOperationalStartDate] = useState(
    dayjs().subtract(6, 'day')
  );
  const [operationalInputEndDate, setOperationalInputEndDate] = useState(
    dayjs()
  );
  const [operationalEndDate, setOperationalEndDate] = useState(dayjs());

  const [patientInputStartDate, setPatientInputStartDate] = useState(
    dayjs().subtract(6, 'day')
  );
  const [patientStartDate, setPatientStartDate] = useState(
    dayjs().subtract(6, 'day')
  );
  const [patientInputEndDate, setPatientInputEndDate] = useState(dayjs());
  const [patientEndDate, setPatientEndDate] = useState(dayjs());

  const [sellersStartDate, setSellersStartDate] = useState(
    dayjs().subtract(6, 'day')
  );
  const [sellersInputStartDate, setSellersInputStartDate] = useState(
    dayjs().subtract(6, 'day')
  );
  const [sellersEndDate, setSellersEndDate] = useState(dayjs());
  const [sellersInputEndDate, setSellersInputEndDate] = useState(dayjs());

  const [orderActivitiesInputStartDate, setOrderActivitiesInputStartDate] =
    useState(dayjs().subtract(6, 'day'));
  const [orderActivitiesStartDate, setOrderActivitiesStartDate] = useState(
    dayjs().subtract(6, 'day')
  );
  const [orderActivitiesInputEndDate, setOrderActivitiesInputEndDate] =
    useState(dayjs());
  const [orderActivitiesEndDate, setOrderActivitiesEndDate] = useState(dayjs());

  const [clinicPerformanceInputStartDate, setClinicPerformanceInputStartDate] =
    useState(dayjs().subtract(6, 'day'));
  const [clinicPerformanceStartDate, setClinicPerformanceStartDate] = useState(
    dayjs().subtract(6, 'day')
  );
  const [clinicPerformanceInputEndDate, setClinicPerformanceInputEndDate] =
    useState(dayjs());
  const [clinicPerformanceEndDate, setClinicPerformanceEndDate] = useState(
    dayjs()
  );

  const [salesPerformanceInputStartDate, setSalesPerformanceInputStartDate] =
    useState(dayjs().subtract(6, 'day'));
  const [salesPerformanceStartDate, setSalesPerformanceStartDate] = useState(
    dayjs().subtract(6, 'day')
  );
  const [salesPerformanceInputEndDate, setSalesPerformanceInputEndDate] =
    useState(dayjs());
  const [salesPerformanceEndDate, setSalesPerformanceEndDate] = useState(
    dayjs()
  );
  const [salesPerformanceTimeframe, setSalesPerformanceTimeframe] =
    useState('daily');

  const [stocksInputStartDate, setStocksInputStartDate] = useState(
    dayjs(dayjs().subtract(6, 'day'))
  );
  const [stocksStartDate, setStocksStartDate] = useState(
    dayjs(dayjs().subtract(6, 'day'))
  );
  const [stocksInputEndDate, setStocksInputEndDate] = useState(dayjs());
  const [stocksEndDate, setStocksEndDate] = useState(dayjs());
  const [stocks, setStocks] = useState([]);

  const [ordersData, setOrdersData] = useState();

  const [
    treatmentWaitingTimeChangePercentage,
    setTreatmentWaitingTimeChangePercentage,
  ] = useState(0);
  const [averageWaitingTime, setAverageWaitingTime] = useState(0);

  const [
    treatmentProcessTimeChangePercentage,
    setTreatmentProcessTimeChangePercentage,
  ] = useState(0);
  const [averageProcessTime, setAverageProcessTime] = useState(0);

  const [
    patientStatisticsChangePercentage,
    setPatientStatisticsChangePercentage,
  ] = useState(0);
  const [totalPatient, setTotalPatient] = useState(0);

  const [
    staffPerformancesRatingChangePercentage,
    setStaffPerformancesRatingChangePercentage,
  ] = useState(0);
  const [staffPerformancesAverageRating, seeStaffPerformancesAverageRating] =
    useState(0);

  const [clinicPerformancesData, setClinicPerformancesData] = useState();
  const [clinicPerformanceOrder, setClinicPerformanceOrder] = useState('asc');
  const [clinicPerformanceOrderBy, setClinicPerformanceOrderBy] = useState(
    auth?.companyId ? 'outletName' : 'clinicName'
  );
  const [clinicPerformancePage, setClinicPerformancePage] = useState(1);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        if (auth?.companyId === 9) {
          const {
            data: { data: fetchedRevenueSummary },
          } = await kanpaiRevenueSummary();

          setRevenueSummary(fetchedRevenueSummary);
        } else {
          const {
            data: { data: fetchedRevenueSummary },
          } = await fetchRevenueSummary();

          setRevenueSummary(fetchedRevenueSummary);
        }
      } catch (error) {
        console.error(error);
        setRevenueSummary(null);
      }
    };

    bootstrapAsync();
  }, []);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        if (auth?.companyId === 9) {
          const {
            data: { data: fetchedOutletPerformances },
          } = await kanpaiOutletPerformances({
            startDate: clinicPerformanceStartDate.format('YYYY-MM-DD'),
            endDate: clinicPerformanceEndDate.format('YYYY-MM-DD'),
            page: clinicPerformancePage,
            pageSize: 5,
            direction: clinicPerformanceOrder,
            orderBy: decamelize(clinicPerformanceOrderBy),
          });

          setClinicPerformancesData(fetchedOutletPerformances);
        } else {
          const {
            data: { data: fetchedClinicPerformancesData },
          } = await fetchClinicPerformances({
            startDate: clinicPerformanceStartDate.format('YYYY-MM-DD'),
            endDate: clinicPerformanceEndDate.format('YYYY-MM-DD'),
            page: clinicPerformancePage,
            pageSize: 5,
            direction: clinicPerformanceOrder,
            orderBy: decamelize(clinicPerformanceOrderBy),
          });
          setClinicPerformancesData(fetchedClinicPerformancesData);
        }
      } catch (error) {
        console.error(error);
        setClinicPerformancesData(null);
      }
    };

    bootstrapAsync();
  }, [
    clinicPerformanceStartDate,
    clinicPerformanceEndDate,
    clinicPerformancePage,
    clinicPerformanceOrder,
    clinicPerformanceOrderBy,
  ]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: fetchedOrdersData },
          },
        ] = await Promise.all([
          auth?.companyId === 9
            ? kanpaiRecentOrders({ pageSize: 3 })
            : fetchOrders({ pageSize: 3 }),
        ]);

        if (auth?.companyId === 9) {
          let tempOrders = { ...fetchedOrdersData };
          tempOrders.orders = tempOrders.orders.map(
            ({ outlet, dateTime, ...props }) => ({
              outlet: { name: outlet },
              purchaseDateTime: dateTime,
              ...props,
            })
          );
          setOrdersData(tempOrders);
        } else {
          setOrdersData(fetchedOrdersData);
        }
      } catch (error) {
        console.error(error);
        setOrdersData(null);
      }
    };

    bootstrapAsync();
  }, []);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        if (auth?.companyId === 9) {
          const {
            data: {
              data: { orders: operationalHours },
            },
          } = await kanpaiOperationalHours({
            startDate: popularOrdersStartDate.format('YYYY-MM-DD'),
            endDate: popularOrdersEndDate.format('YYYY-MM-DD'),
          });

          setIncentives(operationalHours);
        } else {
          const {
            data: { data: fetchedIncentives },
          } = await fetchIncentives({
            startDate: sellersStartDate.format('YYYY-MM-DD'),
            endDate: sellersEndDate.format('YYYY-MM-DD'),
          });
          setIncentives(fetchedIncentives);
        }
      } catch (error) {
        console.error(error);
        setIncentives([]);
      }
    };

    bootstrapAsync();
  }, [sellersStartDate, sellersEndDate]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: usedStocks },
          },
        ] = await Promise.all([
          auth?.companyId === 9
            ? kanpaiLeastPopularMenu({
                startDate: popularOrdersStartDate.format('YYYY-MM-DD'),
                endDate: popularOrdersEndDate.format('YYYY-MM-DD'),
              })
            : fetchStocks({
                startDate: stocksStartDate.format('YYYY-MM-DD'),
                endDate: stocksEndDate.format('YYYY-MM-DD'),
              }),
        ]);
        setStocks(
          auth?.companyId === 9
            ? usedStocks?.minProductSales?.products
            : usedStocks.usedStocks
        );
      } catch (error) {
        console.error(error);
        setStocks([]);
      }
    };

    bootstrapAsync();
  }, [stocksStartDate, stocksEndDate]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: fetchedBedSummary },
          },
        ] = await Promise.all([
          auth?.companyId === 9 ? kanpaiTableSummary() : fetchBedSummary(),
        ]);
        setBedSummary(fetchedBedSummary);
      } catch (error) {
        console.error(error);
        setBedSummary(null);
      }
    };

    bootstrapAsync();
  }, []);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: fetchedOrderActivities },
          },
        ] = await Promise.all([
          auth?.companyId === 9
            ? kanpaiOrderStatus({
                startDate: orderActivitiesStartDate.format('YYYY-MM-DD'),
                endDate: orderActivitiesEndDate.format('YYYY-MM-DD'),
              })
            : fetchOrderActivities({
                startDate: orderActivitiesStartDate.format('YYYY-MM-DD'),
                endDate: orderActivitiesEndDate.format('YYYY-MM-DD'),
              }),
        ]);
        setOrderActivities(fetchedOrderActivities);
      } catch (error) {
        console.error(error);
        setOrderActivities([]);
      }
    };

    bootstrapAsync();
  }, [orderActivitiesStartDate, orderActivitiesEndDate]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        if (auth?.companyId === 9) {
          const {
            data: {
              data: {
                maxProductSales: { products },
              },
            },
          } = await kanpaiPopularMenu({
            startDate: popularOrdersStartDate.format('YYYY-MM-DD'),
            endDate: popularOrdersEndDate.format('YYYY-MM-DD'),
          });
          setPopularOrders(products);
        } else {
          const {
            data: {
              data: { treatments },
            },
          } = await fetchPopularOrders({
            startDate: popularOrdersStartDate.format('YYYY-MM-DD'),
            endDate: popularOrdersEndDate.format('YYYY-MM-DD'),
          });
          setPopularOrders(treatments);
        }
      } catch (error) {
        console.error(error);
        setPopularOrders([]);
      }
    };

    bootstrapAsync();
  }, [popularOrdersStartDate, popularOrdersEndDate]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: fetchedSalesTotal },
          },
          {
            data: { data: fetchedOrderCount },
          },
        ] = await Promise.all([
          auth?.companyId === 9
            ? kanpaiSalesTotal({
                startDate: salesPerformanceStartDate.format('YYYY-MM-DD'),
                endDate: salesPerformanceEndDate.format('YYYY-MM-DD'),
                timeframe: salesPerformanceTimeframe,
              })
            : fetchSalesTotal({
                startDate: salesPerformanceStartDate.format('YYYY-MM-DD'),
                endDate: salesPerformanceEndDate.format('YYYY-MM-DD'),
                timeframe: salesPerformanceTimeframe,
              }),
          auth?.companyId === 9
            ? kanpaiOrderCount({
                startDate: salesPerformanceStartDate.format('YYYY-MM-DD'),
                endDate: salesPerformanceEndDate.format('YYYY-MM-DD'),
                timeframe: salesPerformanceTimeframe,
              })
            : fetchOrderCount({
                startDate: salesPerformanceStartDate.format('YYYY-MM-DD'),
                endDate: salesPerformanceEndDate.format('YYYY-MM-DD'),
                timeframe: salesPerformanceTimeframe,
              }),
        ]);

        const chart = salesPerformanceChartRef.current;

        if (chart) {
          const salesPerformanceChartData = {
            labels: fetchedSalesTotal.values.map((value) =>
              salesPerformanceTimeframe === 'monthly'
                ? dayjs(value.xAxisValue).format('MMM') +
                  ' ' +
                  dayjs(value.xAxisValue).format('YYYY')
                : salesPerformanceTimeframe === 'daily'
                ? dayjs(value.xAxisValue).format('D') +
                  ' ' +
                  dayjs(value.xAxisValue).format('MMM')
                : dayjs(value.xAxisValue).week() +
                  ' ' +
                  dayjs(value.xAxisValue).format('YYYY')
            ),
            datasets: [
              {
                label: 'Sales',
                data: fetchedSalesTotal.values.map((value) => value.yAxisValue),
                yAxisID: 'y1',
                backgroundColor: theme.palette.primary.main,
                borderRadius: 4,
              },
              {
                label: 'Orders',
                data: fetchedOrderCount.values.map((value) => value.yAxisValue),
                yAxisID: 'y2',
                backgroundColor: '#FFCF56',
                borderRadius: 4,
              },
            ],
          };

          setSalesPerformanceChartData(salesPerformanceChartData);
        }
      } catch (error) {
        console.error(error);
        setSalesPerformanceChartData({ datasets: [] });
      }
    };

    bootstrapAsync();
  }, [
    salesPerformanceStartDate,
    salesPerformanceEndDate,
    salesPerformanceTimeframe,
    theme.palette.primary.main,
  ]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: fetchedTreatmentWaitingTimes },
          },
        ] = await Promise.all([
          auth?.companyId === 9
            ? kanpaiDineIn({
                startDate: patientStartDate.format('YYYY-MM-DD'),
                endDate: patientEndDate.format('YYYY-MM-DD'),
              })
            : fetchTreatmentWaitingTimes({
                startDate: patientStartDate.format('YYYY-MM-DD'),
                endDate: patientEndDate.format('YYYY-MM-DD'),
              }),
        ]);

        const chart = waitingTimeChartRef.current;

        if (chart) {
          const gradient = chart.ctx.createLinearGradient(
            0,
            chart.chartArea.bottom,
            0,
            chart.chartArea.top
          );
          gradient.addColorStop(0.95, '#84E8F419');
          gradient.addColorStop(0.05, '#84E8F400');

          const waitingTimeChartData = {
            labels: fetchedTreatmentWaitingTimes.map((time) => time.dateTime),
            datasets: [
              {
                label: auth?.companyId === 9 ? 'Orders' : 'Waiting Duration',
                data: fetchedTreatmentWaitingTimes.map((time) =>
                  auth?.companyId === 9
                    ? time.amount
                    : dayjs.duration(time.duration, 'seconds').asMinutes()
                ),
                backgroundColor: gradient,
                fill: true,
              },
            ],
          };

          setWaitingTimeChartData(waitingTimeChartData);
        }

        if (fetchedTreatmentWaitingTimes.length > 0) {
          setTreatmentWaitingTimeChangePercentage(
            auth?.companyId === 9
              ? ((fetchedTreatmentWaitingTimes[
                  fetchedTreatmentWaitingTimes.length - 1
                ].amount -
                  fetchedTreatmentWaitingTimes[0].amount) /
                  fetchedTreatmentWaitingTimes[0].amount) *
                  100
              : ((fetchedTreatmentWaitingTimes[
                  fetchedTreatmentWaitingTimes.length - 1
                ].duration -
                  fetchedTreatmentWaitingTimes[0].duration) /
                  fetchedTreatmentWaitingTimes[0].duration) *
                  100
          );
          setAverageWaitingTime(
            auth?.companyId === 9
              ? fetchedTreatmentWaitingTimes.reduce(
                  (previousCumulativeWaitingTimes, currentWaitingTime) =>
                    previousCumulativeWaitingTimes + currentWaitingTime.amount,
                  0
                ) / fetchedTreatmentWaitingTimes.length
              : dayjs
                  .duration(
                    fetchedTreatmentWaitingTimes.reduce(
                      (previousCumulativeWaitingTimes, currentWaitingTime) =>
                        previousCumulativeWaitingTimes +
                        currentWaitingTime.duration,
                      0
                    ) / fetchedTreatmentWaitingTimes.length,
                    'seconds'
                  )
                  .asMinutes()
          );
        } else {
          setTreatmentWaitingTimeChangePercentage(0);
          setAverageWaitingTime(0);
        }
      } catch (error) {
        console.error(error);
        setWaitingTimeChartData({ datasets: [] });
        setTreatmentWaitingTimeChangePercentage(0);
        setAverageWaitingTime(0);
      }
    };

    bootstrapAsync();
  }, [operationalStartDate, operationalEndDate]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: fetchedTreatmentProcessTimes },
          },
        ] = await Promise.all([
          auth?.companyId === 9
            ? kanpaiTakeaway({
                startDate: patientStartDate.format('YYYY-MM-DD'),
                endDate: patientEndDate.format('YYYY-MM-DD'),
              })
            : fetchTreatmentProcessTimes({
                startDate: patientStartDate.format('YYYY-MM-DD'),
                endDate: patientEndDate.format('YYYY-MM-DD'),
              }),
        ]);

        const chart = treatmentTimeChartRef.current;

        if (chart) {
          const gradient = chart.ctx.createLinearGradient(
            0,
            chart.chartArea.bottom,
            0,
            chart.chartArea.top
          );
          gradient.addColorStop(0.95, '#84E8F419');
          gradient.addColorStop(0.05, '#84E8F400');

          const treatmentTimeChartData = {
            labels: fetchedTreatmentProcessTimes.map((time) => time.dateTime),
            datasets: [
              {
                label: auth?.companyId === 9 ? 'Orders' : 'Treatment Duration',
                data: fetchedTreatmentProcessTimes.map((time) =>
                  auth?.companyId === 9
                    ? time.amount
                    : dayjs.duration(time.duration, 'seconds').asMinutes()
                ),
                backgroundColor: gradient,
                fill: true,
              },
            ],
          };

          setTreatmentTimeChartData(treatmentTimeChartData);
        }

        if (fetchedTreatmentProcessTimes.length > 0) {
          setTreatmentProcessTimeChangePercentage(
            auth?.companyId === 9
              ? ((fetchedTreatmentProcessTimes[
                  fetchedTreatmentProcessTimes.length - 1
                ].amount -
                  fetchedTreatmentProcessTimes[0].amount) /
                  fetchedTreatmentProcessTimes[0].amount) *
                  100
              : ((fetchedTreatmentProcessTimes[
                  fetchedTreatmentProcessTimes.length - 1
                ].duration -
                  fetchedTreatmentProcessTimes[0].duration) /
                  fetchedTreatmentProcessTimes[0].duration) *
                  100
          );
          setAverageProcessTime(
            auth?.companyId === 9
              ? fetchedTreatmentProcessTimes.reduce(
                  (previousCumulativeProcessTimes, currentProcessTime) =>
                    previousCumulativeProcessTimes + currentProcessTime.amount,
                  0
                ) / fetchedTreatmentProcessTimes.length
              : dayjs
                  .duration(
                    fetchedTreatmentProcessTimes.reduce(
                      (previousCumulativeProcessTimes, currentProcessTime) =>
                        previousCumulativeProcessTimes +
                        currentProcessTime.duration,
                      0
                    ) / fetchedTreatmentProcessTimes.length,
                    'seconds'
                  )
                  .asMinutes()
          );
        } else {
          setTreatmentProcessTimeChangePercentage(0);
          setAverageProcessTime(0);
        }
      } catch (error) {
        console.error(error);
        setTreatmentTimeChartData({ datasets: [] });
        setTreatmentProcessTimeChangePercentage(0);
        setAverageProcessTime(0);
      }
    };

    bootstrapAsync();
  }, [operationalStartDate, operationalEndDate]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: fetchedPatientStatistics },
          },
        ] = await Promise.all([
          auth?.companyId === 9
            ? kanpaiCustomerStatistic({
                startDate: patientStartDate.format('YYYY-MM-DD'),
                endDate: patientEndDate.format('YYYY-MM-DD'),
              })
            : fetchPatientStatistics({
                startDate: patientStartDate.format('YYYY-MM-DD'),
                endDate: patientEndDate.format('YYYY-MM-DD'),
              }),
        ]);

        const chart = patientStatisticChartRef.current;

        if (chart) {
          const gradient = chart.ctx.createLinearGradient(
            0,
            chart.chartArea.bottom,
            0,
            chart.chartArea.top
          );
          gradient.addColorStop(0.95, '#84E8F419');
          gradient.addColorStop(0.05, '#84E8F400');

          const patientStatisticChartData = {
            labels:
              auth?.companyId === 9
                ? fetchedPatientStatistics.dailyCustomerCount.map(
                    (count) => count.dateTime
                  )
                : fetchedPatientStatistics.dailyPatientCount.map(
                    (count) => count.dateTime
                  ),
            datasets: [
              {
                label:
                  auth?.companyId === 9 ? 'Customer Count' : 'Patient Count',
                data:
                  auth?.companyId === 9
                    ? fetchedPatientStatistics.dailyCustomerCount.map(
                        (count) => count.customerCount
                      )
                    : fetchedPatientStatistics.dailyPatientCount.map(
                        (count) => count.patientCount
                      ),
                backgroundColor: gradient,
                fill: true,
              },
            ],
          };

          setPatientStatisticChartData(patientStatisticChartData);
        }

        setTotalPatient(
          auth?.companyId === 9
            ? fetchedPatientStatistics.totalCustomer
            : fetchedPatientStatistics.totalPatients
        );

        if (auth?.companyId === 9) {
          if (fetchedPatientStatistics.dailyCustomerCount.length > 0) {
            if (
              fetchedPatientStatistics.dailyCustomerCount[0].customerCount === 0
            ) {
              setPatientStatisticsChangePercentage(
                fetchedPatientStatistics.dailyCustomerCount[
                  fetchedPatientStatistics.dailyCustomerCount.length - 1
                ].customerCount * 100
              );
            } else {
              setPatientStatisticsChangePercentage(
                ((fetchedPatientStatistics.dailyCustomerCount[
                  fetchedPatientStatistics.dailyCustomerCount.length - 1
                ].customerCount -
                  fetchedPatientStatistics.dailyCustomerCount[0]
                    .customerCount) /
                  fetchedPatientStatistics.dailyCustomerCount[0]
                    .customerCount) *
                  100
              );
            }
          } else {
            setPatientStatisticsChangePercentage(0);
          }
        } else {
          if (fetchedPatientStatistics.dailyPatientCount.length > 0) {
            if (
              fetchedPatientStatistics.dailyPatientCount[0].patientCount === 0
            ) {
              setPatientStatisticsChangePercentage(
                fetchedPatientStatistics.dailyPatientCount[
                  fetchedPatientStatistics.dailyPatientCount.length - 1
                ].patientCount * 100
              );
            } else {
              setPatientStatisticsChangePercentage(
                ((fetchedPatientStatistics.dailyPatientCount[
                  fetchedPatientStatistics.dailyPatientCount.length - 1
                ].patientCount -
                  fetchedPatientStatistics.dailyPatientCount[0].patientCount) /
                  fetchedPatientStatistics.dailyPatientCount[0].patientCount) *
                  100
              );
            }
          } else {
            setPatientStatisticsChangePercentage(0);
          }
        }
      } catch (error) {
        console.error(error);
        setPatientStatisticChartData({ datasets: [] });
        setTotalPatient(0);
        setPatientStatisticsChangePercentage(0);
      }
    };

    bootstrapAsync();
  }, [patientStartDate, patientEndDate]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const [
          {
            data: { data: fetchedStaffPerformances },
          },
        ] = await Promise.all([
          auth?.companyId === 9
            ? kanpaiStaffPerformance({
                startDate: patientStartDate.format('YYYY-MM-DD'),
                endDate: patientEndDate.format('YYYY-MM-DD'),
              })
            : fetchStaffPerformances({
                startDate: patientStartDate.format('YYYY-MM-DD'),
                endDate: patientEndDate.format('YYYY-MM-DD'),
              }),
        ]);

        const chart = staffRatingChartRef.current;

        if (chart) {
          const gradient = chart.ctx.createLinearGradient(
            0,
            chart.chartArea.bottom,
            0,
            chart.chartArea.top
          );
          gradient.addColorStop(0.95, '#84E8F419');
          gradient.addColorStop(0.05, '#84E8F400');

          const staffRatingChartData = {
            labels: fetchedStaffPerformances.map(
              (performance) => performance.dateTime
            ),
            datasets: [
              {
                label: 'Staff Rating',
                data: fetchedStaffPerformances.map(
                  (performance) => performance.rating
                ),
                backgroundColor: gradient,
                fill: true,
              },
            ],
          };

          setStaffRatingChartData(staffRatingChartData);
        }

        if (fetchedStaffPerformances.length > 0) {
          if (fetchedStaffPerformances[0].rating === 0) {
            setStaffPerformancesRatingChangePercentage(
              fetchedStaffPerformances[fetchedStaffPerformances.length - 1]
                .rating * 100
            );
            seeStaffPerformancesAverageRating(
              fetchedStaffPerformances.reduce(
                (previousCumulativeTotalPatients, currentOngoingTime) =>
                  previousCumulativeTotalPatients + currentOngoingTime.rating,
                0
              ) / fetchedStaffPerformances.length
            );
          } else {
            setStaffPerformancesRatingChangePercentage(
              ((fetchedStaffPerformances[fetchedStaffPerformances.length - 1]
                .rating -
                fetchedStaffPerformances[0].rating) /
                fetchedStaffPerformances[0].rating) *
                100 || 0
            );
            seeStaffPerformancesAverageRating(
              fetchedStaffPerformances.reduce(
                (previousCumulativeTotalPatients, currentOngoingTime) =>
                  previousCumulativeTotalPatients + currentOngoingTime.rating,
                0
              ) / fetchedStaffPerformances.length
            );
          }
        } else {
          setStaffPerformancesRatingChangePercentage(0);
          seeStaffPerformancesAverageRating(0);
        }
      } catch (error) {
        console.error(error);
        setStaffRatingChartData({ datasets: [] });
        setStaffPerformancesRatingChangePercentage(0);
        seeStaffPerformancesAverageRating(0);
      }
    };

    auth?.companyId !== 9 && bootstrapAsync();
  }, [patientStartDate, patientEndDate]);

  const handleRequestSort = (event, property) => {
    const isAsc =
      clinicPerformanceOrderBy === property && clinicPerformanceOrder === 'asc';
    setClinicPerformanceOrder(isAsc ? 'desc' : 'asc');
    setClinicPerformanceOrderBy(property);
  };

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={(theme) => ({
          width: `calc(100% - ${drawerWidth} - ${theme.spacing(6)})`,
          ml: `calc(${drawerWidth} + ${theme.spacing(3)})`,
          mr: 3,
          borderBottom: 1,
          borderColor: 'divider',
        })}
      >
        <Toolbar disableGutters>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Typography
              noWrap
              component="div"
              sx={{ fontWeight: 'semiBold', color: 'primary.main' }}
            >
              Main
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: auth?.role === 'vip' ? 'pointer' : 'inherit',
              }}
              onClick={auth?.role === 'vip' && handleClickSelectCompany}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box>
                  <Typography
                    component="span"
                    sx={{
                      fontSize: '1rem',
                      color: auth?.companyId === 9 ? 'primary.main' : '#F1202C',
                    }}
                  >
                    {auth?.companyId === 9 ? 'Kanpai' : 'MRI'}
                  </Typography>
                  <Typography
                    component="span"
                    sx={{
                      fontSize: '0.5rem',
                      color: auth?.companyId === 9 ? 'primary.main' : '#F1202C',
                    }}
                  >
                    .id
                  </Typography>
                </Box>
                <Typography sx={{ fontSize: '0.5rem' }} color="primary">
                  by Alere
                </Typography>
              </Box>
              {auth?.role === 'vip' && <ArrowDropDownIcon color="primary" />}
            </Box>
            {auth?.role === 'vip' && (
              <Menu
                anchorEl={anchorElSelectCompany}
                open={openSelectCompany}
                onClose={handleCloseSelectCompany}
              >
                <MenuItem onClick={() => handleChooseSelectCompany(7)}>
                  MRI.id
                </MenuItem>
                <MenuItem onClick={() => handleChooseSelectCompany(9)}>
                  Enak.id
                </MenuItem>
              </Menu>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 3 }}>
        <Toolbar />
        <Grid container columnSpacing={1.75} sx={{ alignItems: 'center' }}>
          <Grid item xs="auto">
            <Box component="img" src={moneyImage} sx={{ ml: 3 }} />
          </Grid>
          <Grid item xs>
            <ButtonBase
              sx={{
                flexDirection: 'column',
                p: 1.25,
                width: 1,
                alignItems: 'initial',
              }}
              onClick={() => {
                setSalesPerformanceStartDate(dayjs().subtract(6, 'day'));
                setSalesPerformanceInputStartDate(dayjs().subtract(6, 'day'));
                setSalesPerformanceEndDate(dayjs());
                setSalesPerformanceInputEndDate(dayjs());
                setSalesPerformanceTimeframe('daily');
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'semiBold',
                  color: 'secondary.main',
                  textAlign: 'initial',
                }}
              >
                Today's Revenue
              </Typography>
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 'semiBold',
                  mt: 1,
                  textAlign: 'initial',
                }}
              >
                {revenueSummary?.todayRevenue &&
                  formatNumberToCurrency(revenueSummary?.todayRevenue)}
              </Typography>
            </ButtonBase>
          </Grid>
          <Grid item xs>
            <ButtonBase
              sx={{
                flexDirection: 'column',
                p: 1.25,
                width: 1,
                alignItems: 'initial',
              }}
              onClick={() => {
                setSalesPerformanceStartDate(dayjs().startOf('month'));
                setSalesPerformanceInputStartDate(dayjs().startOf('month'));
                setSalesPerformanceEndDate(dayjs());
                setSalesPerformanceInputEndDate(dayjs());
                setSalesPerformanceTimeframe('weekly');
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'semiBold',
                  color: 'secondary.main',
                  textAlign: 'initial',
                }}
              >
                This Month Revenue
              </Typography>
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 'semiBold',
                  mt: 1,
                  textAlign: 'initial',
                }}
              >
                {revenueSummary?.currentMonthRevenue &&
                  formatNumberToCurrency(revenueSummary?.currentMonthRevenue)}
              </Typography>
            </ButtonBase>
          </Grid>
          <Grid item xs>
            <ButtonBase
              sx={{
                flexDirection: 'column',
                p: 1.25,
                width: 1,
                alignItems: 'initial',
              }}
              onClick={() => {
                setSalesPerformanceStartDate(dayjs().startOf('year'));
                setSalesPerformanceInputStartDate(dayjs().startOf('year'));
                setSalesPerformanceEndDate(dayjs());
                setSalesPerformanceInputEndDate(dayjs());
                setSalesPerformanceTimeframe('monthly');
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'semiBold',
                  color: 'secondary.main',
                  textAlign: 'initial',
                }}
              >
                This Year Revenue
              </Typography>
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 'semiBold',
                  mt: 1,
                  textAlign: 'initial',
                }}
              >
                {revenueSummary?.currentYearRevenue &&
                  formatNumberToCurrency(revenueSummary?.currentYearRevenue)}
              </Typography>
            </ButtonBase>
          </Grid>
          <Grid item xs>
            <ButtonBase
              sx={{
                flexDirection: 'column',
                p: 1.25,
                width: 1,
                alignItems: 'initial',
              }}
              onClick={() => {
                setSalesPerformanceStartDate(
                  dayjs().subtract(1, 'month').startOf('month')
                );
                setSalesPerformanceInputStartDate(
                  dayjs().subtract(1, 'month').startOf('month')
                );
                setSalesPerformanceEndDate(
                  dayjs().subtract(1, 'month').endOf('month')
                );
                setSalesPerformanceInputEndDate(
                  dayjs().subtract(1, 'month').endOf('month')
                );
                setSalesPerformanceTimeframe('weekly');
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'semiBold',
                  color: 'secondary.main',
                  textAlign: 'initial',
                }}
              >
                Last Month Revenue
              </Typography>
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 'semiBold',
                  mt: 1,
                  textAlign: 'initial',
                }}
              >
                {revenueSummary?.lastMonthRevenue &&
                  formatNumberToCurrency(revenueSummary?.lastMonthRevenue)}
              </Typography>
            </ButtonBase>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 3 }} columnSpacing={3}>
          <Grid container item direction="column" rowSpacing={1} xs={2.5}>
            <Grid item>
              <Box
                sx={{
                  display: 'inline-block',
                  borderBottom: 1,
                  borderColor: 'divider',
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 'semiBold',
                    pb: 0.5,
                  }}
                >
                  {auth?.companyId === 9 ? 'Table' : 'Bed'}
                </Typography>
              </Box>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  sx={{
                    borderRadius: 2.5,
                    boxShadow: (theme) =>
                      `0px 0px 6px ${theme.palette.grey[300]}BF`,
                    p: 1.25,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.5rem',
                      color: 'ternary.main',
                    }}
                  >
                    {auth?.companyId === 9
                      ? bedSummary?.occupied?.count
                      : bedSummary?.occupied}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'medium',
                      fontSize: '0.75rem',
                      color: 'ternary.main',
                      mt: 0.5,
                    }}
                  >
                    Occupied
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  sx={{
                    borderRadius: 2.5,
                    boxShadow: (theme) =>
                      `0px 0px 6px ${theme.palette.grey[300]}BF`,
                    p: 1.25,
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.5rem',
                      color: 'error.main',
                    }}
                  >
                    {auth?.companyId === 9
                      ? bedSummary?.free?.count
                      : bedSummary?.empty}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'medium',
                      fontSize: '0.75rem',
                      color: 'ternary.main',
                      mt: 0.5,
                    }}
                  >
                    Free
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  sx={{
                    borderRadius: 2.5,
                    boxShadow: (theme) =>
                      `0px 0px 6px ${theme.palette.grey[300]}BF`,
                    p: 1.25,
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.5rem',
                      color: 'ternary.main',
                    }}
                  >
                    -
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'medium',
                      fontSize: '0.75rem',
                      color: 'ternary.main',
                      mt: 0.5,
                      textAlign: 'center',
                    }}
                  >
                    Just Finish
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  sx={{
                    borderRadius: 2.5,
                    boxShadow: (theme) =>
                      `0px 0px 6px ${theme.palette.grey[300]}BF`,
                    p: 1.25,
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.5rem',
                      color: 'error.main',
                    }}
                  >
                    {auth?.companyId === 9 ? '-' : bedSummary?.anomaly}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'medium',
                      fontSize: '0.75rem',
                      color: 'ternary.main',
                      mt: 0.5,
                    }}
                  >
                    Anomaly
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={4.75} direction="column" rowSpacing={1}>
            <Grid
              container
              item
              sx={{ alignItems: 'center' }}
              columnSpacing={1}
            >
              <Grid item xs>
                <Typography
                  sx={{
                    fontWeight: 'semiBold',
                  }}
                >
                  {auth?.companyId === 9 ? 'Customer' : 'Patient'}
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <MobileDatePicker
                  value={patientInputStartDate}
                  onChange={(newValue) => {
                    setPatientInputStartDate(newValue);
                  }}
                  onAccept={(newValue) => {
                    setPatientStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        sx: {
                          textAlign: 'center',
                          fontSize: '0.75rem',
                          p: 0.6875,
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          width: '6rem',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs="auto">
                <MobileDatePicker
                  value={patientInputEndDate}
                  onChange={(newValue) => {
                    setPatientInputEndDate(newValue);
                  }}
                  onAccept={(newValue) => {
                    setPatientEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        sx: {
                          textAlign: 'center',
                          fontSize: '0.75rem',
                          p: 0.6875,
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          width: '6rem',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container item columnSpacing={2.5} sx={{ flexGrow: 1 }}>
              <Grid
                item
                xs={6}
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: '1.5rem',
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        patientStatisticsChangePercentage > 0
                          ? 'success.main'
                          : patientStatisticsChangePercentage < 0
                          ? 'error.main'
                          : 'grey.500',

                      fontWeight: 'medium',
                    }}
                  >
                    {`${patientStatisticsChangePercentage.toFixed(2)}%`}
                  </Typography>
                  {patientStatisticsChangePercentage < 0 ? (
                    <ArrowDropDownIcon
                      sx={{
                        color: 'error.main',
                        ml: 0.5,
                      }}
                    />
                  ) : patientStatisticsChangePercentage > 0 ? (
                    <ArrowDropUpIcon
                      sx={{
                        color: 'success.main',
                        ml: 0.5,
                      }}
                    />
                  ) : null}
                </Box>
                <Box sx={{ position: 'relative', height: '3.3125rem' }}>
                  <Line
                    data={patientStatisticChartData}
                    ref={patientStatisticChartRef}
                    options={{
                      maintainAspectRatio: false,
                      plugins: {
                        legend: { display: false },
                        tooltip: {
                          displayColors: false,
                          callbacks: {
                            title: (tooltipItems) =>
                              dayjs(tooltipItems[0].label).format('DD/MMM/YY'),
                          },
                        },
                      },
                      interaction: {
                        intersect: false,
                      },
                      scales: {
                        yAxes: {
                          display: false,
                        },
                        xAxes: {
                          display: false,
                        },
                      },
                      elements: {
                        point: {
                          radius: 0,
                        },
                        line: {
                          tension: 0.4,
                          borderColor: '#84E8F4',
                        },
                      },
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: '1.25rem',
                    fontWeight: 'medium',
                    mt: 1,
                    color: 'ternary.main',
                  }}
                >
                  {totalPatient || 0}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.75rem',
                    fontWeight: 'medium',
                    mt: 1,
                    color: 'grey.500',
                  }}
                >
                  Total {auth?.companyId === 9 ? 'Customer' : 'Patient'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: '1.5rem',
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        staffPerformancesRatingChangePercentage > 0
                          ? 'success.main'
                          : staffPerformancesRatingChangePercentage < 0
                          ? 'error.main'
                          : 'grey.500',

                      fontWeight: 'medium',
                    }}
                  >
                    {`${staffPerformancesRatingChangePercentage.toFixed(2)}%`}
                  </Typography>
                  {staffPerformancesRatingChangePercentage < 0 ? (
                    <ArrowDropDownIcon
                      sx={{
                        color: 'error.main',
                        ml: 0.5,
                      }}
                    />
                  ) : staffPerformancesRatingChangePercentage > 0 ? (
                    <ArrowDropUpIcon
                      sx={{
                        color: 'success.main',
                        ml: 0.5,
                      }}
                    />
                  ) : null}
                </Box>
                <Box sx={{ position: 'relative', height: '3.3125rem' }}>
                  <Line
                    data={staffRatingChartData}
                    ref={staffRatingChartRef}
                    options={{
                      maintainAspectRatio: false,
                      plugins: {
                        legend: { display: false },
                        tooltip: {
                          displayColors: false,
                          callbacks: {
                            title: (tooltipItems) =>
                              dayjs(tooltipItems[0].label).format('DD/MMM/YY'),
                          },
                        },
                      },
                      interaction: {
                        intersect: false,
                      },
                      scales: {
                        yAxes: {
                          display: false,
                        },
                        xAxes: {
                          display: false,
                        },
                      },
                      elements: {
                        point: {
                          radius: 0,
                        },
                        line: {
                          tension: 0.4,
                          borderColor: '#84E8F4',
                        },
                      },
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: '1.25rem',
                    fontWeight: 'medium',
                    mt: 1,
                    color: 'ternary.main',
                  }}
                >
                  {staffPerformancesAverageRating.toFixed(2)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.75rem',
                    fontWeight: 'medium',
                    mt: 1,
                    color: 'grey.500',
                  }}
                >
                  Average Staff Rating
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={4.75} direction="column" rowSpacing={1}>
            <Grid
              container
              item
              sx={{ alignItems: 'center' }}
              columnSpacing={1}
            >
              <Grid item xs>
                <Typography
                  sx={{
                    fontWeight: 'semiBold',
                  }}
                >
                  Operational
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <MobileDatePicker
                  value={operationalInputStartDate}
                  onChange={(newValue) => {
                    setOperationalInputStartDate(newValue);
                  }}
                  onAccept={(newValue) => {
                    setOperationalStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        sx: {
                          textAlign: 'center',
                          fontSize: '0.75rem',
                          p: 0.6875,
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          width: '6rem',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs="auto">
                <MobileDatePicker
                  value={operationalInputEndDate}
                  onChange={(newValue) => {
                    setOperationalInputEndDate(newValue);
                  }}
                  onAccept={(newValue) => {
                    setOperationalEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        sx: {
                          textAlign: 'center',
                          fontSize: '0.75rem',
                          p: 0.6875,
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          width: '6rem',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container item columnSpacing={2.5} sx={{ flexGrow: 1 }}>
              <Grid
                item
                xs={6}
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: '1.5rem',
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        auth?.companyId === 9
                          ? treatmentWaitingTimeChangePercentage < 0
                            ? 'error.main'
                            : treatmentWaitingTimeChangePercentage > 0
                            ? 'success.main'
                            : 'grey.500'
                          : treatmentWaitingTimeChangePercentage < 0
                          ? 'success.main'
                          : treatmentWaitingTimeChangePercentage > 0
                          ? 'error.main'
                          : 'grey.500',

                      fontWeight: 'medium',
                    }}
                  >
                    {`${treatmentWaitingTimeChangePercentage.toFixed(2)}%`}
                  </Typography>
                  {treatmentWaitingTimeChangePercentage < 0 ? (
                    <ArrowDropDownIcon
                      sx={{
                        color:
                          auth?.companyId === 9 ? 'error.main' : 'success.main',
                        ml: 0.5,
                      }}
                    />
                  ) : treatmentWaitingTimeChangePercentage > 0 ? (
                    <ArrowDropUpIcon
                      sx={{
                        color:
                          auth?.companyId === 9 ? 'success.main' : 'error.main',
                        ml: 0.5,
                      }}
                    />
                  ) : null}
                </Box>
                <Box sx={{ position: 'relative', height: '3.3125rem' }}>
                  <Line
                    data={waitingTimeChartData}
                    ref={waitingTimeChartRef}
                    options={{
                      maintainAspectRatio: false,
                      plugins: {
                        legend: { display: false },
                        tooltip: {
                          displayColors: false,
                          callbacks: {
                            title: (tooltipItems) =>
                              dayjs(tooltipItems[0].label).format('DD/MMM/YY'),
                          },
                        },
                      },
                      interaction: {
                        intersect: false,
                      },
                      scales: {
                        yAxes: {
                          display: false,
                        },
                        xAxes: {
                          display: false,
                        },
                      },
                      elements: {
                        point: {
                          radius: 0,
                        },
                        line: {
                          tension: 0.4,
                          borderColor: '#84E8F4',
                        },
                      },
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: '1.25rem',
                    fontWeight: 'medium',
                    mt: 1,
                    color: 'ternary.main',
                  }}
                >
                  {averageWaitingTime.toFixed(2)}{' '}
                  {auth?.companyId !== 9 && 'min(s)'}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.75rem',
                    fontWeight: 'medium',
                    mt: 1,
                    color: 'grey.500',
                  }}
                >
                  Average{' '}
                  {auth?.companyId === 9 ? 'Dine In Orders' : 'Waiting Time'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: '1.5rem',
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        treatmentProcessTimeChangePercentage > 0
                          ? 'success.main'
                          : treatmentProcessTimeChangePercentage < 0
                          ? 'error.main'
                          : 'grey.500',

                      fontWeight: 'medium',
                    }}
                  >
                    {`${treatmentProcessTimeChangePercentage.toFixed(2)}%`}
                  </Typography>
                  {treatmentProcessTimeChangePercentage < 0 ? (
                    <ArrowDropDownIcon
                      sx={{
                        color: 'error.main',
                        ml: 0.5,
                      }}
                    />
                  ) : treatmentProcessTimeChangePercentage > 0 ? (
                    <ArrowDropUpIcon
                      sx={{
                        color: 'success.main',
                        ml: 0.5,
                      }}
                    />
                  ) : null}
                </Box>
                <Box sx={{ position: 'relative', height: '3.3125rem' }}>
                  <Line
                    data={treatmentTimeChartData}
                    ref={treatmentTimeChartRef}
                    options={{
                      maintainAspectRatio: false,
                      plugins: {
                        legend: { display: false },
                        tooltip: {
                          displayColors: false,
                          callbacks: {
                            title: (tooltipItems) =>
                              dayjs(tooltipItems[0].label).format('DD/MMM/YY'),
                          },
                        },
                      },
                      interaction: {
                        intersect: false,
                      },
                      scales: {
                        yAxes: {
                          display: false,
                        },
                        xAxes: {
                          display: false,
                        },
                      },
                      elements: {
                        point: {
                          radius: 0,
                        },
                        line: {
                          tension: 0.4,
                          borderColor: '#84E8F4',
                        },
                      },
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: '1.25rem',
                    fontWeight: 'medium',
                    mt: 1,
                    color: 'ternary.main',
                  }}
                >
                  {averageProcessTime.toFixed(2)}{' '}
                  {auth?.companyId !== 9 && 'min(s)'}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.75rem',
                    fontWeight: 'medium',
                    mt: 1,
                    color: 'grey.500',
                  }}
                >
                  Average{' '}
                  {auth?.companyId === 9 ? 'Takeaway Orders' : 'Treatment Time'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 3 }} columnSpacing={3}>
          <Grid container item xs={6} direction="column" rowSpacing={1.875}>
            <Grid container item columnSpacing={1}>
              <Grid item xs>
                <Box
                  sx={{
                    display: 'inline-block',
                    borderBottom: 1,
                    borderColor: 'divider',
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: 'semiBold',
                      pb: 0.5,
                    }}
                  >
                    Sales Performance
                  </Typography>
                </Box>
              </Grid>
              {/* <Grid
              item
              sx={{ mt: 1.875, display: 'flex', alignItems: 'center' }}
            >
              <Select
                inputProps={{
                  sx: {
                    fontSize: '1rem',
                    textAlign: 'center',
                    p: 0.6875,
                    width: '6rem',
                    display: 'flex',
                  },
                }}
                sx={{
                  height: '1.6875rem',
                }}
                renderValue={(value) => (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      component="span"
                      sx={{
                        bgcolor: 'primary.main',
                        display: 'inline-block',
                        width: '1rem',
                        height: '1rem',
                        borderRadius: '50%',
                      }}
                    />
                    <Typography sx={{ ml: 0.5 }} component="span">
                      {value}
                    </Typography>
                  </Box>
                )}
              >
                <MenuItem value="Sales">Sales</MenuItem>
                <MenuItem value="Expense">Expense</MenuItem>
              </Select>
              <Typography
                sx={{ fontWeight: 'semiBold', fontSize: '0.75rem', ml: 1.25 }}
              >
                vs
              </Typography>
              <Select
                inputProps={{
                  sx: {
                    textAlign: 'center',
                    p: 0.6875,
                    width: '6rem',
                    display: 'flex',
                  },
                }}
                sx={{
                  ml: 1.25,
                  height: '1.6875rem',
                }}
                renderValue={(value) => (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      component="span"
                      sx={{
                        bgcolor: 'primary.light',
                        display: 'inline-block',
                        width: '1rem',
                        height: '1rem',
                        borderRadius: '50%',
                      }}
                    />
                    <Typography sx={{ ml: 0.5 }} component="span">
                      {value}
                    </Typography>
                  </Box>
                )}
              >
                <MenuItem value="Sales">Sales</MenuItem>
                <MenuItem value="Expense">Expense</MenuItem>
              </Select>
            </Grid> */}
              <Grid item xs="auto">
                <Select
                  inputProps={{
                    sx: {
                      fontSize: '0.75rem',
                      width: '3rem',
                      display: 'flex',
                    },
                  }}
                  sx={{
                    height: '1.6875rem',
                  }}
                  onChange={(event) => {
                    setSalesPerformanceTimeframe(event.target.value);
                  }}
                  value={salesPerformanceTimeframe}
                  // renderValue={(value) => (
                  //   <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  //     <Box
                  //       component="span"
                  //       sx={{
                  //         bgcolor: 'primary.main',
                  //         display: 'inline-block',
                  //         width: '1rem',
                  //         height: '1rem',
                  //         borderRadius: '50%',
                  //       }}
                  //     />
                  //     <Typography sx={{ ml: 0.5 }} component="span">
                  //       {value}
                  //     </Typography>
                  //   </Box>
                  // )}
                >
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                </Select>
              </Grid>
              <Grid item xs="auto">
                <MobileDatePicker
                  value={salesPerformanceInputStartDate}
                  onChange={(newValue) => {
                    setSalesPerformanceInputStartDate(newValue);
                  }}
                  onAccept={(newValue) => {
                    setSalesPerformanceStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        sx: {
                          textAlign: 'center',
                          fontSize: '0.75rem',
                          p: 0.6875,
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          width: '6rem',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs="auto">
                <MobileDatePicker
                  value={salesPerformanceInputEndDate}
                  onChange={(newValue) => {
                    setSalesPerformanceInputEndDate(newValue);
                  }}
                  onAccept={(newValue) => {
                    setSalesPerformanceEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        sx: {
                          textAlign: 'center',
                          fontSize: '0.75rem',
                          p: 0.6875,
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          width: '6rem',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <Bar
                data={salesPerformanceChartData}
                ref={salesPerformanceChartRef}
                plugins={[
                  {
                    afterDraw: (chart) => {
                      const ctx = chart.ctx;
                      ctx.save();
                      ctx.font = '600 12px Poppins';
                      ctx.fillStyle = theme.palette.primary.main;
                      const y = 6;

                      ctx.textAlign = 'left';
                      ctx.fillText(
                        'Sales',
                        -1 +
                          4 *
                            (average({ chart }, 0) / 1000000).toFixed(2).length,
                        y
                      );

                      ctx.textAlign = 'center';
                      ctx.fillStyle = '#B8B8B8';
                      ctx.fillText(
                        chart.scales.x.options.title.text,
                        chart.width / 2,
                        chart.height - 29
                      );
                      ctx.fillStyle = '#FFCF56';

                      ctx.fillText(
                        'Orders',
                        chart.width -
                          17 -
                          4 * average({ chart }, 1).toFixed(2).length,
                        y
                      );
                      ctx.restore();
                    },
                  },
                ]}
                options={{
                  maintainAspectRatio: false,
                  layout: {
                    padding: {
                      top: 24,
                      left: 24,
                      right: 24,
                    },
                  },
                  plugins: {
                    annotation: {
                      annotations: {
                        annotation1: {
                          type: 'line',
                          borderColor: theme.palette.primary.main,
                          borderDash: [5, 5],
                          borderDashOffset: 0,
                          borderWidth: 1,
                          label: {
                            backgroundColor: theme.palette.primary.main,
                            enabled: true,
                            content: (ctx) =>
                              (average(ctx, 0) / 1000000).toFixed(2),
                            position: 'start',
                            xAdjust: (ctx) =>
                              -30 -
                              (average(ctx, 0) / 1000000).toFixed(2).length * 5,
                          },
                          yScaleID: 'y1',
                          yMin: (ctx) => average(ctx, 0),
                          xMax: (ctx) =>
                            ctx.chart.data.datasets.length > 0
                              ? ctx.chart.data.datasets[0].data.length - 1
                              : 0,
                          yMax: (ctx) => average(ctx, 0),
                        },
                        annotation2: {
                          type: 'line',
                          borderColor: '#FFCF56',
                          borderDash: [5, 5],
                          borderDashOffset: 0,
                          borderWidth: 1,
                          label: {
                            backgroundColor: '#FFCF56',
                            color: 'black',
                            enabled: true,
                            content: (ctx) => average(ctx, 1).toFixed(2),
                            position: 'end',
                            xAdjust: (ctx) =>
                              30 + average(ctx, 1).toFixed(2).length * 5,
                          },
                          yScaleID: 'y2',
                          yMin: (ctx) => average(ctx, 1),
                          xMin: 0,
                          xMax: (ctx) =>
                            ctx.chart.data.datasets.length > 0
                              ? ctx.chart.data.datasets[1].data.length
                              : 0,
                          yMax: (ctx) => average(ctx, 1),
                        },
                      },
                    },
                    legend: {
                      labels: {
                        color: '#B8B8B8',
                        font: {
                          size: 12,
                          weight: 600,
                          family: 'Poppins',
                        },
                        usePointStyle: true,
                        pointStyle: 'rect',
                      },
                      position: 'bottom',
                      onClick: function (e, legendItem, legend) {
                        const index = legendItem.datasetIndex;
                        const ci = legend.chart;
                        ci.options.scales[`y${index + 1}`].display =
                          !ci.options.scales[`y${index + 1}`].display;
                        ci.options.plugins.annotation.annotations[
                          `annotation${index + 1}`
                        ].display =
                          !ci.options.plugins.annotation.annotations[
                            `annotation${index + 1}`
                          ].display;
                        ci.update();

                        if (ci.isDatasetVisible(index)) {
                          ci.hide(index);
                          legendItem.hidden = true;
                        } else {
                          ci.show(index);
                          legendItem.hidden = false;
                        }
                      },
                    },
                    tooltip: {
                      callbacks: {
                        title: (tooltipItems) => {
                          switch (salesPerformanceTimeframe) {
                            case 'daily':
                              return `${
                                tooltipItems[0].label.split(' ')[0]
                              }${ordinalSuffixOf(
                                tooltipItems[0].label.split(' ')[0]
                              )} ${tooltipItems[0].label.split(' ')[1]}`;
                            case 'weekly':
                              return `${
                                tooltipItems[0].label.split(' ')[0]
                              }${ordinalSuffixOf(
                                tooltipItems[0].label.split(' ')[0]
                              )} Week ${tooltipItems[0].label.split(' ')[1]}`;
                            default:
                              return tooltipItems[0].label;
                          }
                        },
                      },
                    },
                  },
                  interaction: {
                    intersect: false,
                  },
                  scales: {
                    y1: {
                      ticks: {
                        color: '#B8B8B8',
                        font: {
                          size: 12,
                          weight: 600,
                          family: 'Poppins',
                        },
                        callback: function (value) {
                          return value / 1000000;
                        },
                      },
                    },
                    y2: {
                      ticks: {
                        color: '#B8B8B8',
                        font: {
                          size: 12,
                          weight: 600,
                          family: 'Poppins',
                        },
                      },
                      position: 'right',
                    },
                    x: {
                      ticks: {
                        color: '#B8B8B8',
                        font: {
                          size: 12,
                          weight: 600,
                          family: 'Poppins',
                        },
                        callback(value) {
                          const newLabel = this.getLabelForValue(value);
                          return salesPerformanceTimeframe === 'monthly'
                            ? `${newLabel}`.split(' ')[0]
                            : `${`${newLabel}`.split(' ')[0]}${ordinalSuffixOf(
                                `${newLabel}`.split(' ')[0]
                              )}`;
                        },
                      },
                      title: {
                        color: '#B8B8B8',
                        font: {
                          size: 12,
                          weight: 600,
                          family: 'Poppins',
                        },
                        text: getXAxisLabel(salesPerformanceTimeframe),
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={6} direction="column" rowSpacing={2.5}>
            <Grid container item columnSpacing={3}>
              <Grid container item xs={6} rowSpacing={2.25} direction="column">
                <Grid container item columnSpacing={1}>
                  <Grid item xs>
                    <Box
                      sx={{
                        display: 'inline-block',
                        borderBottom: 1,
                        borderColor: 'divider',
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: 'semiBold',
                          pb: 0.5,
                        }}
                      >
                        Top 3 {auth?.companyId === 9 ? 'Menu' : 'Treatments'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs="auto">
                    <MobileDatePicker
                      value={popularOrdersInputStartDate}
                      onChange={(newValue) => {
                        setPopularOrdersInputStartDate(newValue);
                      }}
                      onAccept={(newValue) => {
                        setPopularOrdersStartDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          inputProps={{
                            ...params.inputProps,
                            sx: {
                              textAlign: 'center',
                              fontSize: '0.75rem',
                              p: 0.6875,
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              width: '6rem',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <MobileDatePicker
                      value={popularOrdersInputEndDate}
                      onChange={(newValue) => {
                        setPopularOrdersInputEndDate(newValue);
                      }}
                      onAccept={(newValue) => {
                        setPopularOrdersEndDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          inputProps={{
                            ...params.inputProps,
                            sx: {
                              textAlign: 'center',
                              fontSize: '0.75rem',
                              p: 0.6875,
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              width: '6rem',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={2.5} rowSpacing={2.5}>
                  {popularOrders.map((popularOrder, index) => (
                    <Fragment key={index}>
                      <Grid
                        item
                        xs={8}
                        sx={{ fontSize: '0.75rem', fontWeight: 'medium' }}
                      >
                        {popularOrder.productName || popularOrder.name}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{
                          fontSize: '0.75rem',
                          fontWeight: 'medium',
                          textAlign: 'right',
                        }}
                      >
                        {popularOrder.orderCount || popularOrder.total} sold
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              </Grid>
              <Grid container item xs={6} rowSpacing={1} direction="column">
                <Grid item>
                  <Box
                    sx={{
                      display: 'inline-block',
                      borderBottom: 1,
                      borderColor: 'divider',
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 'semiBold',
                        pb: 0.5,
                      }}
                    >
                      Recent Orders
                    </Typography>
                  </Box>
                </Grid>
                <Grid container item rowSpacing={1.625} direction="column">
                  {ordersData?.orders.map((order, index) => (
                    <Grid container item key={index}>
                      <Grid
                        item
                        xs={8}
                        sx={{ fontWeight: 'medium', fontSize: '0.75rem' }}
                      >
                        {order.productName}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{
                          textAlign: 'right',
                          fontWeight: 'medium',
                          fontSize: '0.75rem',
                        }}
                      >
                        {formatNumberToCurrency(order.price)}
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sx={{
                          fontSize: '0.75rem',
                          fontWeight: 'light',
                          color: 'grey.400',
                        }}
                      >
                        {order.outlet?.name || '-'}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{
                          fontSize: '0.75rem',
                          textAlign: 'right',
                          fontWeight: 'light',
                          color: 'grey.400',
                        }}
                      >
                        {dayjs(order.purchaseDateTime).fromNow()}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item columnSpacing={3}>
              <Grid container item xs={6} rowSpacing={2.25} direction="column">
                <Grid container item columnSpacing={1}>
                  <Grid item xs>
                    <Box
                      sx={{
                        display: 'inline-block',
                        borderBottom: 1,
                        borderColor: 'divider',
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: 'semiBold',
                          pb: 0.5,
                        }}
                      >
                        {auth?.companyId === 9
                          ? 'Bottom 3 Menu'
                          : 'Top 3 Used Stocks'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs="auto">
                    <MobileDatePicker
                      value={stocksInputStartDate}
                      onChange={(newValue) => {
                        setStocksInputStartDate(newValue);
                      }}
                      onAccept={(newValue) => {
                        setStocksStartDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          inputProps={{
                            ...params.inputProps,
                            sx: {
                              textAlign: 'center',
                              fontSize: '0.75rem',
                              p: 0.6875,
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              width: '6rem',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <MobileDatePicker
                      value={stocksInputEndDate}
                      onChange={(newValue) => {
                        setStocksInputEndDate(newValue);
                      }}
                      onAccept={(newValue) => {
                        setStocksEndDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          inputProps={{
                            ...params.inputProps,
                            sx: {
                              textAlign: 'center',
                              fontSize: '0.75rem',
                              p: 0.6875,
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              width: '6rem',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  columnSpacing={2.5}
                  rowSpacing={1}
                  sx={{ flexGrow: 1 }}
                >
                  {stocks.map((stock, index) => (
                    <Fragment key={index}>
                      <Grid
                        item
                        xs={7.5}
                        sx={{ fontSize: '0.75rem', fontWeight: 'medium' }}
                      >
                        {stock.productName || stock.name}
                      </Grid>
                      <Grid
                        item
                        xs={4.5}
                        sx={{
                          fontSize: '0.75rem',
                          fontWeight: 'medium',
                          textAlign: 'right',
                        }}
                      >
                        {stock.productCount || stock.total}{' '}
                        {auth?.companyId === 9 ? 'sold' : 'used'}
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              </Grid>
              <Grid container item xs={6} rowSpacing={2.25} direction="column">
                <Grid container item columnSpacing={1}>
                  <Grid item xs>
                    <Box
                      sx={{
                        display: 'inline-block',
                        borderBottom: 1,
                        borderColor: 'divider',
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: 'semiBold',
                          pb: 0.5,
                        }}
                      >
                        Top 3 {auth?.companyId === 9 ? 'Hours' : 'Sellers'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs="auto">
                    <MobileDatePicker
                      value={sellersInputStartDate}
                      onChange={(newValue) => {
                        setSellersInputStartDate(newValue);
                      }}
                      onAccept={(newValue) => {
                        setSellersStartDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          inputProps={{
                            ...params.inputProps,
                            sx: {
                              textAlign: 'center',
                              fontSize: '0.75rem',
                              p: 0.6875,
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              width: '6rem',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <MobileDatePicker
                      value={sellersInputEndDate}
                      onChange={(newValue) => {
                        setSellersInputEndDate(newValue);
                      }}
                      onAccept={(newValue) => {
                        setSellersEndDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          inputProps={{
                            ...params.inputProps,
                            sx: {
                              textAlign: 'center',
                              fontSize: '0.75rem',
                              p: 0.6875,
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              width: '6rem',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={2.5} rowSpacing={1}>
                  {incentives.map((incentive) => (
                    <Fragment key={incentive.id}>
                      <Grid
                        item
                        xs={7.5}
                        sx={{ fontSize: '0.75rem', fontWeight: 'medium' }}
                      >
                        {incentive.name || incentive.time}
                      </Grid>
                      <Grid
                        item
                        xs={4.5}
                        sx={{
                          fontSize: '0.75rem',
                          fontWeight: 'medium',
                          textAlign: 'right',
                        }}
                      >
                        {auth?.companyId === 9 && incentive.customerCount}
                        {auth?.companyId === 9
                          ? ' invoices'
                          : formatNumberToCurrency(incentive.totalSales)}
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 3 }} columnSpacing={3}>
          <Grid container item direction="column" rowSpacing={1} xs={2.5}>
            <Grid item>
              <Box
                sx={{
                  display: 'inline-block',
                  borderBottom: 1,
                  borderColor: 'divider',
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 'semiBold',
                    pb: 0.5,
                  }}
                >
                  Staff
                </Typography>
              </Box>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  sx={{
                    borderRadius: 2.5,
                    boxShadow: (theme) =>
                      `0px 0px 6px ${theme.palette.grey[300]}BF`,
                    p: 1.25,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.5rem',
                      color: 'ternary.main',
                    }}
                  >
                    -
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'medium',
                      fontSize: '0.75rem',
                      color: 'ternary.main',
                      mt: 0.5,
                    }}
                  >
                    Attends
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  sx={{
                    borderRadius: 2.5,
                    boxShadow: (theme) =>
                      `0px 0px 6px ${theme.palette.grey[300]}BF`,
                    p: 1.25,
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.5rem',
                      color: 'error.main',
                    }}
                  >
                    -
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'medium',
                      fontSize: '0.75rem',
                      color: 'ternary.main',
                      mt: 0.5,
                    }}
                  >
                    Late
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  sx={{
                    borderRadius: 2.5,
                    boxShadow: (theme) =>
                      `0px 0px 6px ${theme.palette.grey[300]}BF`,
                    p: 1.25,
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.5rem',
                      color: 'ternary.main',
                    }}
                  >
                    -
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'medium',
                      fontSize: '0.75rem',
                      color: 'ternary.main',
                      mt: 0.5,
                      textAlign: 'center',
                    }}
                  >
                    Off Duty
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  sx={{
                    borderRadius: 2.5,
                    boxShadow: (theme) =>
                      `0px 0px 6px ${theme.palette.grey[300]}BF`,
                    p: 1.25,
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.5rem',
                      color: 'error.main',
                    }}
                  >
                    -
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'medium',
                      fontSize: '0.75rem',
                      color: 'ternary.main',
                      mt: 0.5,
                    }}
                  >
                    Absence
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={3.5} direction="column" rowSpacing={1}>
            <Grid
              container
              item
              sx={{ alignItems: 'center' }}
              columnSpacing={1}
            >
              <Grid item xs>
                <Typography
                  sx={{
                    fontWeight: 'semiBold',
                  }}
                >
                  Orders Status
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <MobileDatePicker
                  value={orderActivitiesInputStartDate}
                  onChange={(newValue) => {
                    setOrderActivitiesInputStartDate(newValue);
                  }}
                  onAccept={(newValue) => {
                    setOrderActivitiesStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        sx: {
                          textAlign: 'center',
                          fontSize: '0.75rem',
                          p: 0.6875,
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          width: '6rem',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs="auto">
                <MobileDatePicker
                  value={orderActivitiesInputEndDate}
                  onChange={(newValue) => {
                    setOrderActivitiesInputEndDate(newValue);
                  }}
                  onAccept={(newValue) => {
                    setOrderActivitiesEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        sx: {
                          textAlign: 'center',
                          fontSize: '0.75rem',
                          p: 0.6875,
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          width: '6rem',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container item columnSpacing={2.5} rowSpacing={0.75} xs={2.5}>
              <Grid
                item
                xs={9}
                sx={{ fontSize: '0.75rem', fontWeight: 'medium', pb: 0.75 }}
              >
                Status
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  fontSize: '0.75rem',
                  fontWeight: 'medium',
                  textAlign: 'right',
                  pb: 0.75,
                }}
              >
                Quantity
              </Grid>
              {orderActivities.map((orderActivity, index) => (
                <Fragment key={index}>
                  <Grid
                    item
                    xs={9}
                    sx={{ fontSize: '0.75rem', fontWeight: 'medium' }}
                  >
                    {orderActivity.status}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      fontSize: '0.75rem',
                      fontWeight: 'medium',
                      textAlign: 'right',
                    }}
                  >
                    {orderActivity.orderCount}
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Grid>
          <Grid container item direction="column" xs={6}>
            <Grid
              container
              item
              sx={{ alignItems: 'center' }}
              columnSpacing={1}
            >
              <Grid item xs>
                <Typography
                  sx={{
                    fontWeight: 'semiBold',
                  }}
                >
                  {auth?.companyId === 9 ? 'Outlet' : 'Clinic'} Performance
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <MobileDatePicker
                  value={clinicPerformanceInputStartDate}
                  onChange={(newValue) => {
                    setClinicPerformanceInputStartDate(newValue);
                  }}
                  onAccept={(newValue) => {
                    setClinicPerformanceStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        sx: {
                          textAlign: 'center',
                          fontSize: '0.75rem',
                          p: 0.6875,
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          width: '6rem',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs="auto">
                <MobileDatePicker
                  value={clinicPerformanceInputEndDate}
                  onChange={(newValue) => {
                    setClinicPerformanceInputEndDate(newValue);
                  }}
                  onAccept={(newValue) => {
                    setClinicPerformanceEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        sx: {
                          textAlign: 'center',
                          fontSize: '0.75rem',
                          p: 0.6875,
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          width: '6rem',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item sx={{ width: 1 }}>
              <TableContainer>
                <Table size="small">
                  <EnhancedTableHead
                    order={clinicPerformanceOrder}
                    orderBy={clinicPerformanceOrderBy}
                    onRequestSort={handleRequestSort}
                    companyId={auth?.companyId}
                  />
                  <TableBody>
                    {auth?.companyId === 9
                      ? clinicPerformancesData?.outletPerformences.map(
                          (row, index) => {
                            return (
                              <TableRow hover key={index}>
                                <TableCell
                                  sx={{ borderBottom: 0, fontSize: '0.75rem' }}
                                >
                                  {row.outlet}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{ borderBottom: 0, fontSize: '0.75rem' }}
                                >
                                  {row.avgRating.toFixed(2)}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{ borderBottom: 0, fontSize: '0.75rem' }}
                                >
                                  {row.countCustomer}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    borderBottom: 0,
                                    fontSize: '0.75rem',
                                    color: 'primary.main',
                                    fontWeight: 'semiBold',
                                  }}
                                >
                                  {formatNumberToCurrency(row.totalSales)}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )
                      : clinicPerformancesData?.clinicPerformances.map(
                          (row, index) => {
                            return (
                              <TableRow hover key={index}>
                                {/* <TableCell
                            component="th"
                            scope="row"
                            align="right"
                            sx={{ borderBottom: 0, fontSize: '0.75rem' }}
                          >
                            {row.rank}
                          </TableCell> */}
                                <TableCell
                                  sx={{ borderBottom: 0, fontSize: '0.75rem' }}
                                >
                                  {row.clinicName}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{ borderBottom: 0, fontSize: '0.75rem' }}
                                >
                                  {row.avgRating.toFixed(2)}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{ borderBottom: 0, fontSize: '0.75rem' }}
                                >
                                  {row.totalPatients}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    borderBottom: 0,
                                    fontSize: '0.75rem',
                                    color: 'primary.main',
                                    fontWeight: 'semiBold',
                                  }}
                                >
                                  {formatNumberToCurrency(row.totalSales)}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                    {auth?.companyId === 9
                      ? CLINIC_PERFORMANCE_PAGE_SIZE -
                          (clinicPerformancesData?.outletPerformences.length ||
                            0) >
                          0 && (
                          <TableRow
                            sx={{
                              height:
                                29.1667 *
                                (CLINIC_PERFORMANCE_PAGE_SIZE -
                                  (clinicPerformancesData?.outletPerformences
                                    .length || 0)),
                            }}
                          >
                            <TableCell
                              colSpan={4}
                              sx={{
                                borderBottom: 0,
                              }}
                            />
                          </TableRow>
                        )
                      : CLINIC_PERFORMANCE_PAGE_SIZE -
                          (clinicPerformancesData?.clinicPerformances.length ||
                            0) >
                          0 && (
                          <TableRow
                            sx={{
                              height:
                                29.1667 *
                                (CLINIC_PERFORMANCE_PAGE_SIZE -
                                  (clinicPerformancesData?.clinicPerformances
                                    .length || 0)),
                            }}
                          >
                            <TableCell
                              colSpan={4}
                              sx={{
                                borderBottom: 0,
                              }}
                            />
                          </TableRow>
                        )}
                  </TableBody>
                </Table>
              </TableContainer>
              {clinicPerformancesData && (
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 1.625,
                  }}
                >
                  <Pagination
                    count={clinicPerformancesData?.lastPage}
                    size="small"
                    color="primary"
                    shape="rounded"
                    sx={{
                      '& .MuiPagination-ul': {
                        flexWrap: 'initial',
                      },
                    }}
                    onChange={(event, page) => setClinicPerformancePage(page)}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Dashboard;
