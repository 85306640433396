import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { fetchUnits } from '../../../../../../../api/products';

const QtyUnitSelect = ({
  handleValue,
  firstValueQty,
  firstValueUnit,
  isReset,
  setIsReset,
}) => {
  const [qtyValue, setQtyValue] = useState('');
  const [qtyValueFix, setQtyValueFix] = useState('');

  const [unitSelect, setUnitSelect] = useState('');
  const [unitSelectFix, setUnitSelectFix] = useState('');

  const [isUnitOnFocus, setIsUnitOnFocus] = useState(false);

  const [unitDatas, setUnitDatas] = useState([]);
  const [unitLoading, setUnitLoading] = useState(false);

  useEffect(() => {
    const getUnits = async () => {
      setUnitLoading(true);
      try {
        const {
          data: { data: dataFetchUnits },
        } = await fetchUnits({
          pageSize: 100,
          page: 1,
        });

        setUnitDatas(dataFetchUnits.units);
      } catch (err) {
        console.error(err);
        setUnitDatas([]);
      } finally {
        setUnitLoading(false);
      }
    };

    getUnits();
  }, []);

  useEffect(() => {
    if (firstValueQty !== 0) {
      setQtyValue(firstValueQty);
      setUnitSelect(firstValueUnit);
    }
  }, []);
  useEffect(() => {
    if (isReset) {
      setQtyValue(firstValueQty);
      setUnitSelect(firstValueUnit);
      setIsReset(false);
    }
  }, [isReset]);

  useEffect(() => {
    if (unitSelect !== unitSelectFix || qtyValue !== qtyValueFix) {
      return handleValue(qtyValue, unitSelect);
      return setUnitSelectFix(unitSelect);
      return setQtyValueFix(qtyValue);
    }
  }, [unitSelect, unitSelectFix, qtyValue, qtyValueFix]);

  return (
    <FormControl
      variant="outlined"
      sx={{ bgcolor: '#FAFAFA', minWidth: '280px' }}
    >
      <OutlinedInput
        placeholder="Enter Qty"
        type="number"
        value={qtyValue}
        onChange={(e) => setQtyValue(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <FormControl>
              {unitSelect === '' && (
                <InputLabel id="unit-label">Unit</InputLabel>
              )}
              <Select
                labelId=""
                value={unitSelect}
                label="Unit"
                onChange={(e) => setUnitSelect(e.target.value)}
                onFocus={() => setIsUnitOnFocus(true)}
                onBlur={() => setIsUnitOnFocus(false)}
                sx={{
                  minWidth: '130px',
                  bgcolor: 'white',
                  '.MuiOutlinedInput-input': {
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    paddingLeft: '12px',
                    paddingRight: '0',
                    borderRadius: '5px',
                  },
                }}
              >
                {unitDatas.map((unitData, index) => (
                  <MenuItem value={unitData.name} key={index}>
                    {unitData.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </InputAdornment>
        }
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          'aria-label': 'weight',
        }}
      />
    </FormControl>
  );
};

export default QtyUnitSelect;
