import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDataHubToSubHub,
  getAllDataSubHub,
} from "../../store/hub/subHub/subHub";

const CustomTable = ({
  rowPerPage,
  handleRowPerPage,
  rowPerPageValues,
  currentPage,
  totalValueItems,
  handleInputCurrentPage,
  handleForwardArrow,
  handleBackArrow,
  setSearchValue,
  children,
  name,
  toolbar,
  toolbarAddition,
  isNoData,
  noDataText,
  isLoadingMasterHub,
}) => {
  useSelector(getAllDataHubToSubHub);
  const [search, setSearch] = useState("");

  const debounceOnChange = useRef(
    debounce((value) => {
      setSearchValue(value);
    }, 1000)
  ).current;

  return (
    <Box>
      <Grid container columnSpacing={4} alignItems="center">
        <Grid item xs="auto">
          <Typography
            sx={{ color: "#000000", fontWeight: 600, fontSize: "1.25rem" }}
          >
            {name}
          </Typography>
        </Grid>
        <Grid item xs>
          <TextField
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              debounceOnChange(e.target.value);
            }}
            placeholder={"Search Name"}
            size="small"
            sx={{
              width: "250px",
              "& .MuiOutlinedInput-root": {
                paddingLeft: "6px",
                fontSize: "0.875rem",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ width: "18px" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs="auto">
          {/* {tableChildren} */}
          {toolbar}
        </Grid>
      </Grid>
      <Box>{toolbarAddition}</Box>
      <Table sx={{ mt: 1 }}>{children}</Table>
      {isNoData && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "74px",
            }}
          >
            {noDataText}
          </Box>
          <Divider />
        </>
      )}
      <Grid
        container
        direction="row"
        coloumn={12}
        alignItems="center"
        sx={{ mt: "30px" }}
      >
        <Grid item xs={6} sx={{ fontSize: "14px", fontWeight: "500" }}>
          Showing {currentPage * rowPerPage - (rowPerPage - 1)} to{" "}
          {currentPage * rowPerPage >= totalValueItems
            ? totalValueItems
            : currentPage * rowPerPage}{" "}
          of {totalValueItems} entries
        </Grid>
        <Grid
          container
          item
          direction="row"
          xs={6}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item sx={{ fontSize: "14px", fontWeight: "500" }}>
            Rows Per Page :
            <Select
              value={rowPerPage}
              variant="filled"
              onChange={handleRowPerPage}
              sx={{
                fontSize: "14px",
                lineHeight: "-1px",
                ".MuiFilledInput-input": {
                  paddingTop: "7.5px",
                  paddingBottom: "7.5px",
                  paddingLeft: "10px",
                  paddingRight: "0",
                  borderRadius: "5px",
                },
                "&:before": {
                  borderRadius: "5px",
                  borderBottom: 0,
                },
                "&:after": {
                  borderRadius: "5px",
                  borderBottom: 0,
                },
                borderRadius: "5px",
                marginLeft: "8px",
              }}
            >
              {rowPerPageValues?.map((rowPerPageValue, index) => (
                <MenuItem value={rowPerPageValue} key={index}>
                  {rowPerPageValue}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item sx={{ ml: "35px" }}>
            <Box sx={{ display: "inline", mr: "10px" }}>
              <IconButton sx={{ p: 0 }} onClick={handleBackArrow}>
                <ArrowBackIosIcon sx={{ color: "#323232", width: "16px" }} />
              </IconButton>
            </Box>
            <Box sx={{ display: "inline", mr: "20px" }}>
              <OutlinedInput
                variant="outlined"
                type="number"
                value={currentPage}
                onChange={handleInputCurrentPage}
                sx={{
                  border: "1px solid #9E9D9D",
                  width: "36px",
                  borderRadius: "5px",
                  textAlign: "center",
                  "& .MuiOutlinedInput-input": {
                    padding: "5px 8px 5px 8px",
                    fontSize: "0.875rem",
                    boxShadow: "none",
                    textAlign: "center",
                    fontWeight: 600,
                    width: "25px",
                  },
                  "&:before": {
                    borderRadius: "5px",
                    borderBottom: 0,
                  },
                  "&:after": {
                    borderRadius: "5px",
                    borderBottom: 0,
                  },
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      boxShadow: "none",
                    },
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: "inline",
                fontWeight: "600",
                fontSize: "14px",
                color: "#9E9D9D",
              }}
            >
              of {Math.ceil(totalValueItems / rowPerPage)}
            </Box>
            <Box sx={{ display: "inline", ml: "10px" }}>
              <IconButton sx={{ p: 0 }} onClick={handleForwardArrow}>
                <ArrowForwardIosIcon sx={{ color: "#323232", width: "16px" }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default CustomTable;
