import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddIcon,
  ArrowBackIosIcon,
  ArrowForwardIosIcon,
  ArrowRightAltIcon,
  ExportIcon,
  SearchIcon,
  SyncAltIcon,
} from '../../../../../assets/Icons';
import { useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import CustomCard from '../../../../../components/shared/CustomCard';
import CustomButton from '../../../../../components/shared/CustomButton';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { changePageSupplier } from '../../../../../store/Routing/supplierRouting';
import {
  changePageProductSupplier,
  changeProductSupplierRoutingState,
} from '../../../../../store/Routing/productSupplierRouting';
import { useEffect } from 'react';
import {
  fetchListSuppliers,
  getAllStateSuppliers,
} from '../../../../../store/supplier/dataSupplier';
import { loadDataDetail } from '../../../../../store/supplier/detailSupplier';

const CustomTableCells = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '0.75rem',
  textTransform: 'capitalize',
});

const TableListSupplier = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { datas, pageInfo, isLoading } = useSelector(getAllStateSuppliers);
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);

  // !  search
  const [search, setSearch] = useState('');
  const [supplierKeyword, setSupplierKeyword] = useState('');
  // ! pagination
  const pageSizeList = [5, 10, 15, 20, 25];
  const [pageSizeTable, setPageSizeTable] = useState(pageSizeList[0]);
  const [currentPageTable, setCurrentPageTable] = useState(1);
  const totalItemTable = pageInfo?.total;
  // ! sorting
  const sortAscTable = useState(false); //TODO : ubah ke redux
  const [sortTable, setSortTable] = useState('');
  const [asc, setAsc] = useState('asc');

  const debounceOnChange = useRef(
    debounce((value) => {
      setSupplierKeyword(value);
    }, 1000)
  ).current;

  // ! -------------------------------- API -----------------------------

  useEffect(() => {
    const params = {};
    params.pageSize = pageSizeTable;
    params.page = currentPageTable;
    params.search = supplierKeyword;
    dispatch(fetchListSuppliers(params));
  }, [pageSizeTable, currentPageTable, supplierKeyword]);

  //! -------------- TOOLBAR -------------------
  const toolbar = (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'row-reverse' }}>
      <CustomButton
        sx={{
          bgcolor: '#51B15C',
          color: '#ffffff',
          borderRadius: '8px',
          '&:hover': {
            bgcolor: '#51B15C',
            color: '#ffffff',
          },
          fontSize: '0.875rem',
          px: 2,
        }}
        startIcon={<AddIcon sx={{ width: '14px' }} />}
        onClick={() => {
          updatedSearchParams.delete('supplier-overview');
          setSearchParams(updatedSearchParams);
          dispatch(changePageSupplier('addSupplier'));
        }}
      >
        Add
      </CustomButton>

      {/* export */}
      <CustomCard
        sx={{
          p: '10px 20px',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          cursor: 'pointer',
        }}
        // onClick={() => setIsFilter(!isFilter)}
      >
        <ExportIcon sx={{ fontSize: '20px' }} />
        <Typography
          sx={{ fontSize: '14px', fontWeight: 500, color: '#000000' }}
        >
          Export
        </Typography>
      </CustomCard>
    </Box>
  );

  // !----------------- Handler --------------------------

  const handlePageSize = (e) => {
    setPageSizeTable(e.target.value);
  };
  const handleForwardArrow = () => {
    setCurrentPageTable(
      currentPageTable >= Math.ceil(totalItemTable / pageSizeTable)
        ? currentPageTable
        : currentPageTable + 1
    );
  };

  const handleBackArrow = () => {
    setCurrentPageTable(
      currentPageTable <= 1 ? currentPageTable : currentPageTable - 1
    );
  };

  const handleInputCurrentPage = (event) => {
    if (event.target.value >= Math.ceil(totalItemTable / pageSizeTable)) {
      setCurrentPageTable(Math.ceil(totalItemTable / pageSizeTable));
    } else if (event.target.value <= 1) {
      setCurrentPageTable(1);
    } else {
      setCurrentPageTable(event.target.value);
    }
  };

  const handleClickRow = (item) => {
    dispatch(
      loadDataDetail({
        id: item?.idKustomer,
        data: item,
        status: item?.status,
      })
    );
    dispatch(changePageSupplier('detailSupplier'));
  };

  return (
    <Box sx={{ p: 2 }}>
      <Grid container columnSpacing={4} alignItems="center">
        <Grid item xs>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
              Supplier List
            </Typography>
            <TextField
              placeholder={'Search by name'}
              size="small"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                debounceOnChange(e.target.value);
              }}
              sx={{
                width: '250px',
                '& .MuiOutlinedInput-root': {
                  paddingLeft: '6px',
                  fontSize: '0.875rem',
                },
                bgcolor: '#FAFAFA',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ width: '18px' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>

        <Grid item xs="auto">
          {toolbar}
        </Grid>
      </Grid>

      {/* fetch data */}
      <Table sx={{ mt: 1 }}>
        <TableHead>
          <TableRow sx={{ bgcolor: '#FAFAFA' }}>
            <CustomTableCells>No</CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortSupplierName');
                  // dispatch(changeSortAsc(!sortAscTable)); //TODO : ubah ke redux
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  Supplier Name
                </Typography>
                {sortTable === 'sortSupplierName' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('supplierCode');
                  // dispatch(changeSortAsc(!sortAscTable)); //TODO : ubah ke redux
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  Supplier Code
                </Typography>
                {sortTable === 'supplierCode' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  Email
                </Typography>
              </Box>
            </CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  Phone Number
                </Typography>
              </Box>
            </CustomTableCells>
            <CustomTableCells>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSortTable('sortJoinDate');
                  // dispatch(changeSortAsc(!sortAscTable)); //TODO
                  if (sortAscTable === true) {
                    setAsc('asc');
                  } else {
                    setAsc('desc');
                  }
                }}
              >
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '500', mr: '2px' }}
                >
                  Join Date
                </Typography>
                {sortTable === 'sortJoinDate' ? (
                  sortAscTable ? (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(90deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <ArrowRightAltIcon
                      sx={{
                        fontSize: '12px',
                        transform: 'rotate(270deg)',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )
                ) : (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading === true ? (
            <TableRow>
              <TableCell colSpan={6}>
                <Box
                  sx={{
                    height: `${50 * pageSizeTable}px`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          ) : datas?.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative',
                    p: 5,
                    mt: 1,
                  }}
                >
                  <Typography sx={{ color: '#9D9E9E', fontSize: '12px' }}>
                    No data suppulier
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            datas?.map((item, index) => {
              const splitEmail = item?.email.split('@');
              const newEmail =
                splitEmail[0].slice(0, 3) + '***' + '@' + splitEmail[1];
              return (
                <TableRow
                  key={index}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleClickRow(item);
                  }}
                >
                  <CustomTableCells sx={{ width: '40px' }}>
                    {(currentPageTable - 1) * pageSizeTable + (index + 1)}
                  </CustomTableCells>
                  <CustomTableCells>{item.namaLengkap}</CustomTableCells>
                  <CustomTableCells>{item.suppliersKode}</CustomTableCells>
                  <CustomTableCells>{newEmail}</CustomTableCells>
                  <CustomTableCells sx={{ color: '#51B15C' }}>
                    {item.phoneNumber}
                  </CustomTableCells>
                  <CustomTableCells>
                    {item.tglGabung
                      ? dayjs(item.tglGabung).format('DD/MMM/YY')
                      : '-'}
                  </CustomTableCells>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>

      {/* pagination */}
      <Grid
        container
        direction="row"
        coloumn={12}
        alignItems="center"
        sx={{ mt: '10px' }}
      >
        <Grid item xs={6} sx={{ fontSize: '12px', fontWeight: '500' }}>
          Showing {currentPageTable * pageSizeTable - (pageSizeTable - 1)} to{' '}
          {currentPageTable * pageSizeTable >= totalItemTable
            ? totalItemTable
            : currentPageTable * pageSizeTable}{' '}
          of {totalItemTable} entries
        </Grid>
        <Grid
          container
          item
          direction="row"
          xs={6}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item sx={{ fontSize: '12px', fontWeight: '500' }}>
            Rows Per Page :
            <Select
              value={pageSizeTable}
              variant="filled"
              onChange={handlePageSize}
              sx={{
                fontSize: '12px',
                lineHeight: '-1px',
                '.MuiFilledInput-input': {
                  paddingTop: '7.5px',
                  paddingBottom: '7.5px',
                  paddingLeft: '10px',
                  paddingRight: '0',
                  borderRadius: '5px',
                },
                '&:before': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                '&:after': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                borderRadius: '5px',
                marginLeft: '8px',
              }}
            >
              {pageSizeList.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item sx={{ ml: '35px' }}>
            <Box sx={{ display: 'inline', mr: '10px' }}>
              <IconButton sx={{ p: 0 }} onClick={handleBackArrow}>
                <ArrowBackIosIcon sx={{ color: '#323232', width: '14px' }} />
              </IconButton>
              <Box sx={{ display: 'inline', mr: '20px' }}>
                <OutlinedInput
                  variant="outlined"
                  type="number"
                  value={currentPageTable}
                  onChange={handleInputCurrentPage}
                  sx={{
                    border: '1px solid #9E9D9D',
                    width: '36px',
                    borderRadius: '5px',
                    textAlign: 'center',
                    '& .MuiOutlinedInput-input': {
                      padding: '5px 8px 5px 8px',
                      fontSize: '0.875rem',
                      boxShadow: 'none',
                      textAlign: 'center',
                      fontWeight: 600,
                      width: '25px',
                    },
                    '&:before': {
                      borderRadius: '5px',
                      borderBottom: 0,
                    },
                    '&:after': {
                      borderRadius: '5px',
                      borderBottom: 0,
                    },
                    '&.Mui-focused': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        boxShadow: 'none',
                      },
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'inline',
                  fontWeight: '600',
                  fontSize: '12px',
                  color: '#9E9D9D',
                }}
              >
                of {Math.ceil(totalItemTable / pageSizeTable)}
              </Box>
              <Box sx={{ display: 'inline', ml: '10px' }}>
                <IconButton sx={{ p: 0 }} onClick={handleForwardArrow}>
                  <ArrowForwardIosIcon
                    sx={{ color: '#323232', width: '14px' }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default TableListSupplier;
