import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Switch,
  Dialog,
  styled,
} from '@mui/material';
import dayjs from 'dayjs';
import BackButton from '../../../../../../components/shared/BackButton';
import CustomButton from '../../../../../../components/shared/CustomButton.jsx';
import { useEffect, useState } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ReactComponent as NoImage } from '../../../../../../assets/no-image.svg';
import { ChartDetails } from '../DesktopComponent';
import { deleteProductProduct } from '../../../../../../api/products';
import CustomEditConfirm from '../../../../../../components/shared/CustomEditConfirm';
import CustomDeleteConfirm from '../../../../../../components/shared/CustomDeleteConfirm';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const ProductProductDetailsMobile = (props) => {
  const {
    handleBackButton,
    roadHirarki,
    timeFrame,
    setTimeFrame,
    dateRange,
    saveDateRange,
    setDateRange,
    setSaveDateRange,
    detailsDatas,
    handleDelete,
    handleEdit,
    statusProduct,
    handleEditStatus,
    linkPreviewPhoto,
    setLinkPreviewPhoto,
    isPreviewPhoto,
    setIsPreviewPhoto,
    handlePreviewPhoto,
    hirarkiDetails,
    isLoadingDataDetails,
  } = props;

  const theme = useTheme();

  return (
    <Grid container columns={12} spacing={1}>
      {/* back button */}
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        {!isLoadingDataDetails && (
          <Box>
            <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
              {hirarkiDetails?.product} Details
            </Typography>
            <Box
              sx={{
                display: 'flex',
                color: '#9E9D9D',
                alignItems: 'center',
                mt: '4px',
                gap: 0.25,
              }}
            >
              <Typography sx={{ fontSize: '8px', fontWeight: '100' }}>
                {hirarkiDetails?.subsidiary}
              </Typography>
              <ChevronRightIcon sx={{ fontSize: '6px' }} />
              <Typography
                sx={{ fontSize: '8px', fontWeight: '100', color: '#9E9D9D' }}
              >
                {hirarkiDetails?.category}
              </Typography>
              <ChevronRightIcon sx={{ fontSize: '6px' }} />
              <Typography
                sx={{ fontSize: '8px', fontWeight: '100', color: '#9E9D9D' }}
              >
                {hirarkiDetails?.subCategory}
              </Typography>
              {hirarkiDetails?.miniSubCategory !== '' && (
                <>
                  <ChevronRightIcon sx={{ fontSize: '6px' }} />
                  <Typography
                    sx={{
                      fontSize: '8px',
                      fontWeight: '100',
                      color: '#9E9D9D',
                    }}
                  >
                    {hirarkiDetails?.miniSubCategory}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        )}
      </Grid>
      {/* --- */}
      <Grid container item xs={12} columns={12}>
        <Grid item xs={12} sx={{ mb: '32px' }}>
          {/* <ChartDetails
            {...{
              timeFrame,
              setTimeFrame,
              dateRange,
              setDateRange,
              saveDateRange,
              setSaveDateRange,
            }}
          /> */}
        </Grid>
        <Grid container item xs={12} columns={12} spacing={1}>
          {/* cogs */}
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
              COGS
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
                {!detailsDatas.hargaMenual ? '-' : detailsDatas.hargaMenual}
              </Typography>
            </Box>
          </Grid>
          {/* rrp */}
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
              RRP
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
                {!detailsDatas.hargaJual ? '-' : detailsDatas.hargaJual}
              </Typography>
            </Box>
          </Grid>
          {/* limit */}
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
              Limit
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
                {!detailsDatas.stokMin ? '-' : detailsDatas.stokMin}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {/* Weight */}
      <Grid item xs={4}>
        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
          Weight
        </Typography>
        <Box
          sx={{
            mt: '12px',
            width: '100%',
            padding: '14px 16px',
            bgcolor: '#FAFAFA',
            borderRadius: '8px',
          }}
        >
          <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
            {!detailsDatas.berat ? '-' : detailsDatas.berat}
          </Typography>
        </Box>
      </Grid>
      {/* Color */}
      <Grid item xs={12}>
        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
          Color
        </Typography>
        <Box
          sx={{
            mt: '12px',
            width: '100%',
            padding: '14px 16px',
            bgcolor: '#FAFAFA',
            borderRadius: '8px',
          }}
        >
          <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
            {!detailsDatas.warna ? '-' : detailsDatas.warna}
          </Typography>
        </Box>
      </Grid>
      {/* Photo */}
      <Grid item xs={12}>
        <Typography sx={{ fontSize: '12px', fontWeight: '500', mb: '12px' }}>
          Photo
        </Typography>
        {!detailsDatas.gambar ? (
          <Box
            sx={{
              width: '100%',
              padding: '0',
              display: 'flex',
            }}
          >
            <Box
              sx={{
                width: '42px',
                height: '42px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: '#FAFAFA',
                borderRadius: '2px',
              }}
            >
              <NoImage />
              <Typography sx={{ color: '#9E9D9D', fontSize: '6px' }}>
                No photo
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', gap: 1 }}>
            {detailsDatas.gambar.split(',').map((item, index) => (
              <Box onClick={() => handlePreviewPhoto(item)} key={index}>
                <img
                  src={process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + item}
                  style={{
                    width: '42px',
                    height: '42px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                />
              </Box>
            ))}
          </Box>
        )}
      </Grid>
      {/* Dialog Photo */}
      {linkPreviewPhoto !== '' && (
        <Dialog
          open={isPreviewPhoto}
          onClose={() => {
            setIsPreviewPhoto(!isPreviewPhoto);
            setLinkPreviewPhoto('');
          }}
        >
          <Box
            component="img"
            src={
              process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + linkPreviewPhoto
            }
            sx={{ aspectRation: '1/1' }}
          />
        </Dialog>
      )}
      {/* Meta Description */}
      <Grid item xs={12}>
        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
          Meta Description
        </Typography>
        <Box
          sx={{
            mt: '12px',
            width: '100%',
            padding: '14px 16px',
            bgcolor: '#FAFAFA',
            borderRadius: '8px',
          }}
        >
          <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
            {!detailsDatas.metaDesk ? '-' : detailsDatas.metaDesk}
          </Typography>
        </Box>
      </Grid>
      {/* Schedule */}
      <Grid item xs={12}>
        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
          Schedule
        </Typography>
        <Box
          sx={{
            mt: '12px',
            width: '100%',
            padding: '14px 16px',
            bgcolor: '#FAFAFA',
            borderRadius: '8px',
          }}
        >
          <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
            {!detailsDatas.schedule
              ? '-'
              : dayjs(detailsDatas.schedule).format('DD/MMM/YYYY')}
          </Typography>
        </Box>
      </Grid>
      {/* Description */}
      <Grid item xs={12}>
        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
          Description
        </Typography>
        <Box
          sx={{
            mt: '12px',
            width: '100%',
            padding: '14px 16px',
            bgcolor: '#FAFAFA',
            borderRadius: '8px',
          }}
        >
          <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
            {!detailsDatas.deskripsi ? (
              '-'
            ) : (
              <span
                dangerouslySetInnerHTML={{ __html: detailsDatas.deskripsi }}
              />
            )}
          </Typography>
        </Box>
      </Grid>
      {/* STATUS */}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
          Publish
        </Typography>
        <AntSwitch checked={statusProduct} onChange={handleEditStatus} />
      </Grid>
      {/* SUBMIT */}
      <Grid
        item
        xs={12}
        sx={{ mt: '28px', display: 'flex', alignItems: 'center', gap: 0.5 }}
      >
        <CustomButton
          // disabled={!isSubmitAvailable}
          onClick={handleDelete}
          variant="contained"
          color="error"
          sx={{
            width: '100%',
          }}
        >
          Delete
        </CustomButton>
        <CustomButton
          // disabled={!isSubmitAvailable}
          onClick={handleEdit}
          variant="contained"
          sx={{
            width: '100%',
          }}
        >
          Edit
        </CustomButton>
      </Grid>
    </Grid>
  );
};
export default ProductProductDetailsMobile;
