import { Height } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  AddIcon,
  ChevronRightIcon,
  Dolar,
  ExportIcon,
  FilterAltIcon,
  IsUser,
  SearchIcon,
  SkuIcon,
} from '../../../../../../assets/Icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePage,
  changePurchaseRoutingState,
  changeStatusForPurchase,
  setHirarkiPurchase,
} from '../../../../../../store/Routing/purchaseRouting';
import CustomCard from '../../../../../../components/shared/CustomCard';
import ChartPurchaseMobile from './Component/Chart';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import {
  getAllDataChartPurchase,
  getAllDataPurchase,
  getEndDateFilter,
  getPurchaseTableIsLoading,
  getPurchaseTableSortAsc,
  getPurchaseTableSortIn,
  getPurchaseTableStatusFilter,
  getSingleFilter,
  getStartDateFilter,
} from '../../../../../../store/purchase/dataTable';
import TablePurchaseDashboardMobile from './Component/TablePurchase';
import CustomButton from '../../../../../../components/shared/CustomButton';
import debounce from 'lodash.debounce';
import { getPurchaseTable } from '../../../../../../store/purchase/dataTable';
import {
  getListPurchaseRequest,
  getPageInfoPurchaseRequest,
} from '../../../../../../store/purchase/purchaseAdmin/purchaseRequest';
import DialogFilterPurchase from '../DialogFilter';
import { changeIdPurchaseDetail } from '../../../../../../store/purchase/detailPurchase';

const color = {
  processing: '#2191FB',
  finish: '#FF439D',
  accept: '#51B15C',
  sent: '#E8C32C',
  pending: '#FD7900',
  supplier: '#6342E8',
  retur: '#F33A3A',
  returAccept: '#1F10C5',
};

const bgColor = {
  processing: 'rgba(33, 145, 251, 0.1)',
  finish: 'rgba(255, 67, 157, 0.1)',
  accept: 'rgba(81, 177, 92, 0.1)',
  sent: 'rgba(234, 205, 87, 0.1)',
  pending: 'rgba(253, 121, 0, 0.1)',
  supplier: 'rgba(99, 66, 232, 0.1)',
  retur: 'rgba(243, 58, 58, 0.1)',
  returAccept: 'rgba(31, 16, 197, 0.1)',
};

const PurchaseMobile = () => {
  const dispatch = useDispatch();
  const desktopMode = useMediaQuery('(min-width:832px)');
  const [isFilter, setIsFilter] = useState(false);
  const isLoading = useSelector(getPurchaseTableIsLoading);
  const statusFilter = useSelector(getPurchaseTableStatusFilter);
  const [search, setSearch] = useState('');
  const [purchaseKeyword, setPurchaseKeyword] = useState('');
  const datas = useSelector(getPurchaseTable);
  const pageInfo = useSelector(getPageInfoPurchaseRequest);
  const singleFilter = useSelector(getSingleFilter);
  const startDate = useSelector(getStartDateFilter);
  const endDate = useSelector(getEndDateFilter);
  const sortInTable = useSelector(getPurchaseTableSortIn);
  const sortAscTable = useSelector(getPurchaseTableSortAsc);
  const [sortTable, setSortTable] = useState('');
  const [asc, setAsc] = useState('asc');
  const roleUser = localStorage.getItem('role');

  // ---- Grafik ----
  const [timeFrame, setTimeFrame] = useState('daily');

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, 'd')),
      endDate: new Date(dayjs(new Date())),
      key: 'selection',
    },
  ]);

  const startDateFilter = dayjs(saveDateRange[0].startDate).format(
    'YYYY-MM-DD'
  );
  const endDateFilter = dayjs(saveDateRange[0].endDate).format('YYYY-MM-DD');

  useEffect(() => {
    let params = {};
    params.page = 1;
    params.pageSize = 100;
    dispatch(getListPurchaseRequest(params));
  }, []);

  // data chart
  useEffect(() => {
    let datas = {};
    datas.startDate = startDateFilter;
    datas.endDate = endDateFilter;
    datas.timeframe = timeFrame;
    dispatch(getAllDataChartPurchase(datas));
  }, [startDateFilter, endDateFilter, timeFrame]);

  //! ----------------------- purchase  ------------------

  // ! fetch data

  useEffect(() => {
    let datas = {};

    if (singleFilter !== '' && startDate !== '') {
      datas.page = 1;
      datas.pageSize = 4;
      datas.search = purchaseKeyword;
      datas.status = statusFilter;
      datas.invoiceStatus = singleFilter;
      datas.startDate = startDate;
      datas.endDate = endDate;
    } else if (singleFilter !== '') {
      datas.page = 1;
      datas.pageSize = 4;
      datas.search = purchaseKeyword;
      datas.status = statusFilter;
      datas.invoiceStatus = singleFilter;
    } else if (startDate !== '') {
      datas.page = 1;
      datas.pageSize = 4;
      datas.search = purchaseKeyword;
      datas.status = statusFilter;
      datas.startDate = startDate;
      datas.endDate = endDate;
    } else {
      datas.page = 1;
      datas.pageSize = 4;
      datas.search = purchaseKeyword;
      datas.status = statusFilter;
    }
    datas[sortInTable] = sortAscTable === true ? 'asc' : 'desc ';

    dispatch(getAllDataPurchase(datas));
  }, [
    purchaseKeyword,
    statusFilter,
    singleFilter,
    startDate,
    endDate,
    sortInTable,
    sortAscTable,
    sortTable,
    asc,
  ]);

  //! search

  const debounceOnChange = useRef(
    debounce((value) => {
      setPurchaseKeyword(value);
    }, 1000)
  ).current;

  //!  ----------------------------- Toolbabr ----------------------------
  const toolbar = (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row-reverse',
        gap: 2,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '22px',
          height: '22px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <ExportIcon />
      </CustomButton>

      <CustomButton
        sx={{
          minWidth: '22px',
          width: '22px',
          minHeight: '22px',
          height: '22px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          bgcolor: '#FFFFFF',
          color: '#000000',
          '&:hover': {
            bgcolor: '#FFFFFF',
            color: '#000000',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
          },
        }}
        onClick={() => setIsFilter(!isFilter)}
      >
        <FilterAltIcon sx={{ width: '14px' }} />
      </CustomButton>
      <DialogFilterPurchase {...{ isFilter, setIsFilter }} />
    </Box>
  );

  const handleClickRow = (id, subsidaryName, hubName, status) => {
    if (roleUser == 5) {
      if (status === 'finance bid') {
        dispatch(changePurchaseRoutingState({ key: 'idPurchase', value: id }));
        dispatch(changePage('purchaseDetailSupplier'));
        dispatch(
          setHirarkiPurchase({ subsidiary: subsidaryName, hub: hubName })
        );
        dispatch(changeStatusForPurchase(status));
      } else if (
        status === 'processed' ||
        status === 'sent' ||
        (status === 'accepted' && !desktopMode)
      ) {
        dispatch(changePurchaseRoutingState({ key: 'idPurchase', value: id }));
        dispatch(changePage('purchaseProcessDetailSupplier'));
        dispatch(
          setHirarkiPurchase({ subsidiary: subsidaryName, hub: hubName })
        );
        dispatch(changeStatusForPurchase(status));
      } else {
        dispatch(changePage('detail'));
        dispatch(changeIdPurchaseDetail(id));
      }
    } else {
      dispatch(changePage('detail'));
      dispatch(changeIdPurchaseDetail(id));
    }
  };

  return (
    <Box
      sx={{
        mt: 3,
      }}
    >
      {/* to request purchase */}
      <Box
        sx={{
          background: 'rgba(81, 177, 92, 0.1)',
          minHeight: '39px',
          Height: '39px',
          padding: '10px 15px 10px 30px',
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        onClick={() => dispatch(changePage('request'))}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IsUser />
          <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
            Request Purchase
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              width: '14px',
              height: '14px',
              background: '#51B15C',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '3px',
            }}
          >
            <Typography
              sx={{ fontSize: '8px', color: '#FFFFFF', fontWeight: 600 }}
            >
              {pageInfo?.total}
            </Typography>
          </Box>
          <ChevronRightIcon color="success" />
        </Box>
      </Box>

      {/* grafik & table */}
      <Box sx={{ mt: 3 }}>
        {/* grafik */}
        <Grid container columns={12} spacing={2}>
          <Grid item xs={12}>
            <CustomCard sx={{ p: '10px 20px' }}>
              <ChartPurchaseMobile
                {...{
                  timeFrame,
                  setTimeFrame,
                  dateRange,
                  setDateRange,
                  saveDateRange,
                  setSaveDateRange,
                }}
              />
            </CustomCard>
          </Grid>

          {/* table */}
          <Grid item xs={12}>
            <TablePurchaseDashboardMobile />
          </Grid>

          {/* purchase request */}
          <Grid item xs={12}>
            <CustomCard sx={{ p: '10px 10px' }}>
              <Grid container columns={12} spacing={2}>
                <Grid item xs={3}>
                  <Typography
                    sx={{
                      display: 'inline-flex',
                      fontSize: '12px',
                      fontWeight: 600,
                    }}
                  >
                    Purchase
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    placeholder={'Search'}
                    size="small"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      debounceOnChange(e.target.value);
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        paddingLeft: '6px',
                        fontSize: '7px',
                      },
                      bgcolor: '#FAFAFA',
                      minHeight: '22px',
                      height: '22px',
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ width: '12px' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  {toolbar}
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', gap: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Dolar />
                    <Typography sx={{ fontSize: '8px', ml: '5px' }}>
                      Amount(Rp)*
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <SkuIcon />
                    <Typography sx={{ fontSize: '8px', ml: '5px' }}>
                      SKU
                    </Typography>
                  </Box>
                </Grid>

                {/* mapping data */}
                {isLoading ? (
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <CircularProgress />
                  </Grid>
                ) : datas.length === 0 ? (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        fontSize: '8px',
                        display: 'flex',
                        color: '#F33A3A',
                        justifyContent: 'center',
                      }}
                    >
                      No Request Purchase
                    </Box>
                  </Grid>
                ) : (
                  datas.map((item, idx) => (
                    <Grid item xs={6} key={idx}>
                      <Box
                        sx={{
                          background: '#FAFAFA',
                          borderRadius: '8px',
                          p: '10px',
                        }}
                        onClick={() => {
                          handleClickRow(
                            item?.iDpurchase,
                            item?.subsidary,
                            item?.location,
                            item?.status
                          );
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{ fontSize: '12px', fontWeight: 600 }}
                            >
                              {item?.invoiceid !== '' ? item?.invoiceid : '-'}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: '52px',
                              height: '17px',
                              background:
                                item?.status === 'processed'
                                  ? bgColor.processing
                                  : item?.status === 'accepted'
                                  ? bgColor.accept
                                  : item?.status === 'sent'
                                  ? bgColor.sent
                                  : item?.status === 'finish'
                                  ? bgColor.finish
                                  : item?.status === 'finance bid'
                                  ? bgColor.pending
                                  : item?.status === 'supplier bid'
                                  ? bgColor.supplier
                                  : 'black',
                              borderRadius: '5px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontWeight: 600,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '8px',
                                color:
                                  item?.status === 'processed'
                                    ? color.processing
                                    : item?.status === 'accepted'
                                    ? color.accept
                                    : item?.status === 'sent'
                                    ? color.sent
                                    : item?.status === 'finish'
                                    ? color.finish
                                    : item.status === 'finance bid'
                                    ? color.pending
                                    : item?.status === 'supplier bid'
                                    ? color.supplier
                                    : 'black',
                              }}
                            >
                              {item?.status}
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{ mt: '3px' }}>
                          <Typography sx={{ fontSize: '8px' }}>
                            {item?.date}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Dolar />
                            <Typography sx={{ fontSize: '8px', ml: '5px' }}>
                              {item?.amount}
                            </Typography>
                          </Box>

                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <SkuIcon />
                            <Typography sx={{ fontSize: '8px', ml: '5px' }}>
                              {item?.sku}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))
                )}
              </Grid>
            </CustomCard>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default PurchaseMobile;
