import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { IconBack } from "../../../../../../../assets";
import { changePageStockOut } from "../../../../../../../store/Routing/stockOutRouting";
import { useDispatch, useSelector } from "react-redux";
import { getSearchStockout } from "../../../../../../../store/stockout/stockout";
import HistoryCard from "../../Component/HistoryCard";

const StockoutDetailHistory = () => {
  const dispatch = useDispatch();

  const search = useSelector(getSearchStockout);

  const handleBack = () => {
    dispatch(changePageStockOut(""));
  };

  const [loginHistory, setLoginHystory] = useState(false);

  return (
    <Box mt={"20px"}>
      {/* --- Navigations --- */}
      <Box display={"flex"} alignItems={"center"} mb={"30px"}>
        <IconButton sx={{ p: "0px" }} onClick={handleBack}>
          <IconBack />
        </IconButton>
        <Typography
          fontStyle={"normal"}
          fontWeight={600}
          fontSize={"14px"}
          lineHeight={"16px"}
          letterSpacing={"0.1em"}
          color={"#000"}
          ml={"10px"}
        >
          INV-{search} History
        </Typography>
      </Box>

      <HistoryCard />
    </Box>
  );
};

export default StockoutDetailHistory;
