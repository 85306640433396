import { createSlice } from "@reduxjs/toolkit";

export const productSupplierRoutingState = createSlice({
  name: "productSupplierRouting",
  initialState: {
    role: "",
    page: "",
    keyNotif: "",
    textNotif: "",
    hubName: "",
  },
  reducers: {
    changePageProductSupplier: (state, action) => {
      state.page = action.payload;
    },
    changeProductSupplierRoutingState: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setNotifProductSupplier: (state, action) => {
      const { key, text } = action.payload;
      state.keyNotif = key;
      state.textNotif = text;
    },
    setCloseNotifProductSupplier: (state) => {
      state.keyNotif = "";
      state.textNotif = "";
    },
  },
});

export const {
  changePageProductSupplier,
  changeProductSupplierRoutingState,
  setNotifProductSupplier,
  setCloseNotifProductSupplier,
} = productSupplierRoutingState.actions;

export const getAllProductSupplierRouting = (state) =>
  state.productSupplierRouting;

export default productSupplierRoutingState.reducer;
