import { Box, Typography } from "@mui/material";
import React from "react";

/**
 * Component to display a search not found message.
 *
 * @param {string} title - The title string to be displayed.
 * @returns {React.Element} SearchNotFound component.
 */

const SearchNotFound = ({ title, icon }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      py={"60px"}
    >
      {icon}
      <Typography
        fontFamily={"sans-serif"}
        fontStyle={"normal"}
        fontWeight={400}
        fontSize={"12px"}
        lineHeight={"150%"}
        color={"#9e9d9d"}
        mt={"20px"}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default SearchNotFound;
