import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Switch,
  Dialog,
  styled,
  Autocomplete,
} from '@mui/material';
import CustomInput from '../../../../../../components/shared/CustomInput';
import BackButton from '../../../../../../components/shared/BackButton';
import dayjs from 'dayjs';
import AddSubCategoryInCategory from './Desktop/AddSubCategoryInCategory';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import CustomDialog from '../../../../../../components/shared/CustomDialog';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import CustomCard from '../../../../../../components/shared/CustomCard';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import CustomButton from '../../../../../../components/shared/CustomButton';
import { deleteFile, uploadImage } from '../../../../../../api/storage';
import {
  addNewCategoryMenu,
  addNewSubCategoryMenu,
} from '../../../../../../api/menu';
import CategoryMenuAddMobile from './CategoryMenuAddMobile';
import CategoryMenuAddDesktop from './CategoryMenuAddDesktop';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const CategoryMenuAdd = (props) => {
  const {
    desktopMode,
    roadHirarki,
    setRoadHirarki,
    // *--- Page
    setPage,
    setCategoryMenu,
    //*- Data
    categoryAddInputDatas,
    setCategoryAddInputDatas,
    // *--- Notif ---
    notif,
    notifTitle,
    setNotif,
    setNotifTitle,
    //*- Category Data
    typeCategory,
    typeCategoryLoading,
    subsidiaryCategory,
    subsidiaryCategoryLoading,
  } = props;
  const today = dayjs(new Date());
  const theme = useTheme();

  const [sub1, setSub1] = useState({
    id: 1,
    name: '',
    photo: [],
    photoUrls: [],
    photoUrl: '',
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    available: true,
  });
  const [sub2, setSub2] = useState({
    id: 2,
    name: '',
    photo: [],
    photoUrls: [],
    photoUrl: '',
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    available: true,
  });
  const [sub3, setSub3] = useState({
    id: 3,
    name: '',
    photo: [],
    photoUrls: [],
    photoUrl: '',
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviePhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    available: true,
  });

  const [indexSubCategory, setIndexSubCategory] = useState([]);
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);

  // *-------- use effect untuk add sub -----------
  useEffect(() => {
    categoryAddInputDatas.subCategoryFormat
      .filter(
        (item) =>
          !categoryAddInputDatas.subCategoryIndex.includes(item) &&
          !indexSubCategory.includes(item)
      )
      .map((value) => {
        return setIndexSubCategory([...indexSubCategory, value]);
      });
  }, [categoryAddInputDatas, indexSubCategory, sub1, sub2, sub3]);

  // *---------Validasi submit ----------
  useEffect(() => {
    if (
      categoryAddInputDatas.subsidiaryId &&
      categoryAddInputDatas.name.length >= 5
    ) {
      if (
        categoryAddInputDatas.description === '' ||
        categoryAddInputDatas.description.length >= 20
      ) {
        if (categoryAddInputDatas.subCategoryIndex.length > 0) {
          if (
            sub1.available === true &&
            sub2.available === true &&
            sub3.available === true
          ) {
            return setIsSubmitAvailable(true);
          } else {
            return setIsSubmitAvailable(false);
          }
        } else {
          return setIsSubmitAvailable(true);
        }
      } else {
        return setIsSubmitAvailable(false);
      }
    } else {
      return setIsSubmitAvailable(false);
    }
  }, [categoryAddInputDatas, sub1, sub2, sub3]);

  useEffect(() => {
    categoryAddInputDatas.subCategoryFormat
      .filter(
        (item) =>
          !categoryAddInputDatas.subCategoryIndex.includes(item) &&
          !indexSubCategory.includes(item)
      )
      .map((value) => {
        return setIndexSubCategory([...indexSubCategory, value]);
      });
  }, [categoryAddInputDatas, indexSubCategory, sub1, sub2, sub3]);

  // !------------- HANDLER --------------
  const handleAdd = () => {
    let hasil = {
      namaKategori: categoryAddInputDatas.name,
      status: `${categoryAddInputDatas.publish ? 'Y' : 'N'}`,
      subsidiaryId: parseInt(categoryAddInputDatas.subsidiaryId),
      // opsiKategori: parseInt(categoryAddInputDatas.typeId),
      opsiKategori: 6,
      schedule: dayjs(categoryAddInputDatas.schedule.$d).format('YYYY-MM-DD'),
    };
    if (categoryAddInputDatas.description !== '') {
      hasil['des'] = categoryAddInputDatas.description;
    }
    if (categoryAddInputDatas.photoUrls.length !== 0) {
      hasil['gambar'] = categoryAddInputDatas.photoUrls.join(',');
    }
    let subCategories = [{ ...sub1 }, { ...sub2 }, { ...sub3 }];
    addNewCategoryMenu(hasil)
      .then((result) => {
        subCategories
          .filter((subItem) =>
            categoryAddInputDatas.subCategoryIndex.includes(subItem.id)
          )
          .map((subItem) => {
            let sub = {
              idKategori: result.data.data.idKategori,
              subkategori: subItem.name,
              subStatus: 'Y',
              schedule: dayjs(subItem.schedule.$d).format('YYYY-MM-DD'),
            };
            if (subItem.description !== '') {
              sub.informasi = subItem.description;
            }
            if (subItem.photoUrls.length !== 0) {
              sub['gbrsub'] = subItem.photoUrls.join(',');
            }

            addNewSubCategoryMenu(sub)
              .then()
              .catch((err) => console.error(err));
          });
        setNotif('addcategory');
        setNotifTitle(
          `${result.data.data.namaKategori} has been successfully added`
        );
        setRoadHirarki({
          subsidiary: '',
          subsidiaryId: 0,
          category: '',
          categoryId: 0,
          subCategory: '',
          subCategoryId: 0,
          menu: '',
          menuId: 0,
          type: '',
          typeId: 0,
          miniSubCategory: '',
          miniSubCategoryId: 0,
        });
        setPage('category');
        setCategoryMenu('');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleBackButton = () => {
    setCategoryMenu('');
  };

  const handleAddImage = (e) => {
    if (e.target.files[0].size > 2000000) {
      setCategoryAddInputDatas({
        ...categoryAddInputDatas,
        isMaxSizePhoto: true,
      });
    } else {
      let datas = {
        image: e.target.files[0],
        resource: 'category',
      };
      uploadImage(datas).then((res) => {
        setCategoryAddInputDatas({
          ...categoryAddInputDatas,
          photo: [...categoryAddInputDatas.photo, e.target.files[0]],
          photoUrls: [...categoryAddInputDatas.photoUrls, res.data.data.path],
          isMaxSizePhoto: false,
        });
      });
    }
  };

  const handleDeleteImage = (input, index) => {
    deleteFile(categoryAddInputDatas.photoUrls[index]).then((results) =>
      setCategoryAddInputDatas({
        ...categoryAddInputDatas,
        photo: categoryAddInputDatas.photo.filter((data) => data !== input),
        photoUrls: categoryAddInputDatas.photoUrls.filter(
          (data, idx) => idx !== index
        ),
        indexPreviePhoto: 0,
      })
    );
  };
  const handlePreviewImage = () => {};
  const handleClosePreviewImage = () => {};

  const handleAddSub = () => {
    const value = indexSubCategory[0];
    setCategoryAddInputDatas({
      ...categoryAddInputDatas,
      subCategoryIndex: [...categoryAddInputDatas.subCategoryIndex, value],
    });
    setIndexSubCategory(indexSubCategory.filter((nilai) => nilai !== value));
  };

  const handleDeleteSub = () => {};

  return (
    <>
      {desktopMode ? (
        <CategoryMenuAddDesktop
          {...{ ...props }}
          {...{
            today,
            theme,
            AntSwitch,
          }}
          {...{
            handleBackButton,
            handleAddImage,
            handleDeleteImage,
            handleAddSub,
            handleAdd,
          }}
          {...{
            indexSubCategory,
            setIndexSubCategory,
            sub1,
            setSub1,
            sub2,
            setSub2,
            sub3,
            setSub3,
          }}
          {...{ isSubmitAvailable, setIsSubmitAvailable }}
        />
      ) : (
        <CategoryMenuAddMobile
          {...{ ...props }}
          {...{
            today,
            theme,
            AntSwitch,
          }}
          {...{
            handleBackButton,
            handleAddImage,
            handleDeleteImage,
            handleAddSub,
            handleAdd,
          }}
          {...{
            indexSubCategory,
            setIndexSubCategory,
            sub1,
            setSub1,
            sub2,
            setSub2,
            sub3,
            setSub3,
          }}
          {...{ isSubmitAvailable, setIsSubmitAvailable }}
        />
      )}
    </>
  );
};
export default CategoryMenuAdd;
