import { useEffect, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Dialog,
  Divider,
} from '@mui/material';
import dayjs from 'dayjs';

import AddIcon from '@mui/icons-material/Add';
import CustomInput from '../../../../../../../components/shared/CustomInput';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { uploadImage } from '../../../../../../../api/storage';

const AddSubCategoryInCategory = (props) => {
  const {
    page,
    setPage,
    categoryAddInputDatas,
    setCategoryAddInputDatas,
    indexSubCategory,
    setIndexSubCategory,
    isSubmitAvailable,
    setIsSubmitAvailable,
    id,
    sub,
    setSub,
  } = props;
  const today = dayjs(new Date());

  const theme = useTheme();

  useEffect(() => {
    if (sub.name.length >= 5) {
      if (sub.description === '' || sub.description.length >= 20) {
        return setSub({ ...sub, available: true });
      } else {
        return setSub({ ...sub, available: false });
      }
    } else {
      return setSub({ ...sub, available: false });
    }
  }, [sub.name, sub.description]);

  // !-------------- handler ---------------
  const handleAddImage = (e) => {
    if (e.target.files[0].size > 2000000) {
      setSub({
        ...sub,
        isMaxSizePhoto: true,
      });
    } else {
      let datas = {
        image: e.target.files[0],
        resource: 'subCategory',
      };
      uploadImage(datas).then((res) => {
        setSub({
          ...sub,
          photo: [...sub.photo, e.target.files[0]],
          photoUrls: [...sub.photoUrls, res.data.data.path],
          isMaxSizePhoto: false,
        });
      });
    }
  };

  const handleDeleteImage = (file, index) => {
    setSub({
      ...sub,
      photo: sub.photo.filter((data) => data !== file),
      photoUrls: sub.photoUrls.filter((data, idx) => idx !== index),
      indexPreviePhoto: 0,
    });
  };

  return (
    <>
      <Grid item xs={6}>
        <Typography sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}>
          Sub Category Name*
        </Typography>
        <CustomInput
          sx={{ width: '100%', bgcolor: '#FAFAFA' }}
          placeholder="Enter sub category name"
          value={sub.name}
          onChange={(e) => {
            setSub({ ...sub, name: e.target.value });
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            Enter at least 5 character
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            {sub.name.length}/30
          </Typography>
        </Box>
      </Grid>
      {/* Photo */}
      <Grid item xs={6} sx={{}}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            pb: `${sub.photo.length === 0 ? '16px' : '9px'}`,
            alignItems: 'ceneter',
            position: 'relative',
          }}
        >
          <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
            Photo
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '500',
              color: '#9E9D9D',
            }}
          >
            ( max size : 2MB )
          </Typography>
          {sub.isMaxSizePhoto && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ErrorOutlineIcon
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  color: theme.palette.warning.main,
                  mr: '4px',
                }}
              />
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  color: theme.palette.warning.main,
                }}
              >
                The photo can't be more than 2MB
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              right: '0',
              bgcolor: '#F33A3A',
              width: '32px',
              height: '32px',
              borderRadius: '8px',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              setSub({
                id: parseInt(id),
                name: '',
                photo: [],
                isMaxSizePhoto: false,
                isPreviewPhoto: false,
                indexPreviePhoto: 0,
                description: '',
                scheduleType: 'now',
                schedule: today,
                available: true,
              });
              setCategoryAddInputDatas({
                ...categoryAddInputDatas,
                subCategoryIndex: categoryAddInputDatas.subCategoryIndex.filter(
                  (ids) => ids !== parseInt(id)
                ),
              });
              setIndexSubCategory([...indexSubCategory, parseInt(id)]);
            }}
          >
            <DeleteIcon sx={{ color: 'white', fontSize: '16px' }} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {sub.photo.length === 0 && (
              <Box
                sx={{
                  height: '54px',
                  width: '54px',
                  aspectRatio: '1/1',
                  borderRadius: '8px',
                  bgcolor: '#F5F5F5',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderStyle: 'dashed',
                  borderColor: '#9E9D9D',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  document.getElementById(`img_sub${id}`).click();
                }}
              >
                <ImageOutlinedIcon
                  sx={{ color: '#9E9D9D', fontSize: '28px' }}
                />
              </Box>
            )}
            {sub.photo.map((input, index) => (
              <Box
                sx={{
                  position: 'relative',
                  overflow: 'visible',
                  padding: '7px',
                }}
                key={index}
              >
                <CancelIcon
                  sx={{
                    position: 'absolute',
                    fontSize: '20px',
                    top: 1,
                    right: 1,
                    zIndex: +1,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleDeleteImage(input, index)}
                />
                <Box
                  component="img"
                  src={URL.createObjectURL(input)}
                  sx={{
                    height: '54px',
                    width: '54px',
                    aspectRatio: '1/1',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSub({
                      ...sub,
                      indexPreviePhoto: index + 1,
                      isPreviewPhoto: 'true',
                    });
                  }}
                />
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: 'flex',
              mb: '7px',
              ml: '20px',
              alignItems: 'center',
            }}
          >
            {sub.photo.length < 3 && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.palette.primary.main,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  document.getElementById(`img_sub${id}`).click();
                }}
              >
                <input
                  accept="image/*"
                  id={`img_sub${id}`}
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleAddImage}
                />
                <AddIcon />
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                  Add More
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      {/* DIalog Photo */}
      {sub.indexPreviePhoto !== 0 && (
        <Dialog
          open={sub.isPreviewPhoto}
          onClose={() => {
            setSub({
              ...sub,
              isPreviewPhoto: !sub.isPreviewPhoto,
            });
          }}
        >
          <Box
            component="img"
            src={URL.createObjectURL(sub.photo[sub.indexPreviePhoto - 1])}
            sx={{ width: '600px', height: '600px', aspectRation: '1/1' }}
          />
        </Dialog>
      )}
      {/* Description */}
      <Grid item xs={6}>
        <Typography sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}>
          Description
        </Typography>
        <CustomInput
          sx={{ width: '100%', bgcolor: '#FAFAFA' }}
          placeholder="Enter description"
          value={sub.description}
          onChange={(e) => {
            if (e.target.value.length <= 250) {
              setSub({
                ...sub,
                description: e.target.value,
              });
            }
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            Enter at least 20 character
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            {sub.description.length}/250
          </Typography>
        </Box>
      </Grid>
      {/* Schedule */}
      <Grid item xs={6}>
        <Grid container>
          <Grid item alignItems="center" sx={{ height: '100%', mr: '20px' }}>
            <Typography
              sx={{
                mb: '16px',
                fontSize: '16px',
                fontWeight: '500',
              }}
            >
              Schedule
            </Typography>
          </Grid>
          <Grid item alignItems="center" sx={{ height: '100%' }}>
            <RadioGroup
              row
              value={sub.scheduleType}
              onChange={(e) => {
                setSub({
                  ...sub,
                  schedule: today,
                  scheduleType: e.target.value,
                });
              }}
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: '16px',
                },
                '.MuiRadio-root': {
                  padding: '0 8px',
                },
                '.MuiTypography-root': {
                  fontSize: '12px',
                },
              }}
            >
              <FormControlLabel value="now" control={<Radio />} label="Now" />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', minWidth: '100%' }}>
          <DesktopDatePicker
            inputFormat="DD/MM/YYYY"
            value={sub.schedule}
            onChange={(newValue) => {
              if (sub.scheduleType !== 'now') {
                setSub({
                  ...sub,
                  schedule: newValue,
                });
              } else {
                setSub({
                  ...sub,
                  schedule: today,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              />
            )}
            disabled={sub.scheduleType === 'now' ? true : false}
          />
        </Box>
      </Grid>
      <Divider />
    </>
  );
};
export default AddSubCategoryInCategory;
