import { createSlice } from '@reduxjs/toolkit';

export const rackHubState = createSlice({
  name: 'rackHub',
  initialState: {
    path: '', //* sub, subHub, miniHub
    page: '', //* '', detail, edit, item, itemQr
    id: 0,
    idRack: 0,
    cell: [],
    cellSelected: [],
  },
  reducers: {
    goToRackHub: (state, action) => {
      const { row, col, cellSelected, id, path } = action.payload;
      let newCell = [...Array(row)].map((_, idxr) => {
        let v = [...Array(col)].map((_, idxc) => {
          return `r${idxr + 1}c${idxc + 1}`;
        });
        return v;
      });
      state.cellSelected = cellSelected;
      state.cell = newCell;
      state.id = id;
      state.path = path;
    },
    goToRackHubDetail: (state, action) => {
      state.page = 'detail';
      state.idRack = action.payload;
    },
    goToRackHubEdit: (state) => {
      state.page = 'edit';
    },
    goToRackHubItemQr: (state) => {
      state.page = 'itemQr';
    },
    goToRackHubItem: (state) => {
      state.page = 'item';
    },
    goBackDetailHub: (state) => {
      state.page = '';
      state.idRack = 0;
      state.path = '';
    },
    goBackMainRack: (state) => {
      state.page = '';
      state.idRack = 0;
    },
    goBackDetailRack: (state) => {
      state.page = 'detail';
    },
  },
});

export const {
  goToRackHub,
  goToRackHubDetail,
  goToRackHubEdit,
  goBackDetailHub,
  goBackMainRack,
  goBackDetailRack,
  goToRackHubItemQr,
  goToRackHubItem,
} = rackHubState.actions;
export const getAllRackhub = (state) => state.rackHub;

export default rackHubState.reducer;
