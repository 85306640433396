import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import BackButtonHub from '../../../../../../../../components/shared/BackButtonHub';
import {
  ChevronRightIcon,
  QrCodeScannerRoundedIcon,
} from '../../../../../../../../assets/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { changePageTransaction } from '../../../../../../../../store/Routing/transactionRouting';
import CustomButton from '../../../../../../../../components/shared/CustomButton';
import { useState } from 'react';
import ScannedDataTransaction from './ScannedDataTransaction';
import UnscannedDataTransaction from './UnscannedDataTransaction';
import { getAllDataTransactionScan } from '../../../../../../../../store/transaction/request/sendDataTransaction';
import { getIdDetailTransactionRequest } from '../../../../../../../../store/transaction/request/detailTransactionRequest';
import { updateTransactionData } from '../../../../../../../../api/transaction';

const SendTransactionMobile = () => {
  const dispatch = useDispatch();

  const [isScanned, setIsScanned] = useState(true);
  const {
    isExist,
    scanned,
    idScanned,
    isSelect,
    noqrDataSelect,
    allData,
    rackIdNoQr,
    noqr,
    allDataWithQr,
    noqrid,
  } = useSelector(getAllDataTransactionScan);
  const idTransaction = useSelector(getIdDetailTransactionRequest);

  const handleToScanMore = () => {
    if (
      allData.filter((list) => !idScanned.includes(list.encript)).length !== 0
    ) {
      dispatch(changePageTransaction('transactionProsesScan'));
    } else {
    }
  };

  const handleSend = async () => {
    const qr = scanned?.flat().map((item, idx) => item?.encript);

    const params = {
      status: 'sent',
      produk_qr: qr,
      photo: noqrid,
    };
    // TODO : encrypt di ubah sementara ke idProduk

    try {
      const data = await updateTransactionData(idTransaction, params);
      dispatch(changePageTransaction(''));
      console.log({ data });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box sx={{ paddingTop: 2, paddingLeft: 2 }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <BackButtonHub
          onClick={() => {
            dispatch(changePageTransaction('detailRequestTransaction'));
          }}
        />
        <Box
          sx={{
            display: 'flex',
            gap: 0,
            alignItems: 'center',
            color: '#9E9D9D',
          }}
        >
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
            Stock request details
          </Typography>
          <ChevronRightIcon />
          <Typography
            sx={{ fontSize: '14px', fontWeight: 600, color: '#000000' }}
          >
            Send
          </Typography>
        </Box>
      </Box>

      {/* head */}
      <Grid
        container
        columns={2}
        columnSpacing={0.5}
        sx={{
          mt: '16px',
          p: '4px',
          bgcolor: '#F5F5F5',
          borderRadius: '8px',
        }}
      >
        <Grid item xs={1}>
          <Box
            sx={{
              py: '8px',
              borderRadius: '8px',
              bgcolor: isScanned ? 'white' : '#F5F5F5',
            }}
            onClick={() => setIsScanned(true)}
          >
            <Typography
              sx={{
                fontSize: '14px',
                textAlign: 'center',
                fontWeight: isScanned ? '600' : '500',
                color: isScanned ? '#51B15C' : 'black',
              }}
            >
              Scanned {noqr.flat().length + scanned.length}
              {/* {scanned.length + noqr.flat().length} */}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box
            sx={{
              py: '8px',
              borderRadius: '8px',
              bgcolor: !isScanned ? 'white' : '#F5F5F5',
            }}
            onClick={() => setIsScanned(false)}
          >
            <Typography
              sx={{
                fontSize: '14px',
                textAlign: 'center',
                fontWeight: !isScanned ? '600' : '500',
                color: !isScanned ? '#51B15C' : 'black',
              }}
            >
              Unscanned{' '}
              {/* {noqr.flat().length +
                allDataWithQr.length -
                scanned.flat().length || 0} */}
              {/* {allDataWithQr.length -
                scanned.flat().length +
                allData?.filter(
                  (list) =>
                    list.qrcodeType === false &&
                    !noqrid?.some((item) =>
                      item.id_detail.includes(list.iddetailSti)
                    )
                ).length || 0} */}
              {allDataWithQr[0]?.jumlah -
                scanned?.flat().length +
                allData?.filter(
                  (list) =>
                    list.qrcodeType === false &&
                    !noqrid?.some((item) =>
                      item.id_detail.includes(list.iddetailSti)
                    )
                ).length || 0}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* data scan */}
      <Box sx={{ minHeight: '70vh', bgcolor: 'white' }}>
        {isScanned ? <ScannedDataTransaction /> : <UnscannedDataTransaction />}
      </Box>

      {/* button event */}

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          bgcolor: 'white',
          p: '20px',
          width: '100%',
          display: 'flex',
          gap: 0.5,
        }}
      >
        <CustomButton
          variant="contained"
          sx={{ width: '100%' }}
          onClick={() => handleSend()}
          disabled={
            allData.filter(
              (list) =>
                list.qrcodeType === true && !idScanned.includes(list.idProduk)
            ).length +
              allData?.filter(
                (list) =>
                  list.qrcodeType === false &&
                  !noqrid?.some((item) =>
                    item.id_detail.includes(list.iddetailSti)
                  )
              ).length !==
            0
          }
        >
          Send
        </CustomButton>
        {isSelect ? (
          <Box
            sx={{
              border: 2,
              borderRadius: '8px',
              borderColor: '#51B15C',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() =>
              dispatch(changePageTransaction('scanRackSendTransaction'))
            } //TODO : scan no qr
          >
            <QrCodeScannerRoundedIcon sx={{ color: '#51B15C' }} />
          </Box>
        ) : (
          <Box
            sx={{
              border: 2,
              borderRadius: '8px',
              borderColor: '#51B15C',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => handleToScanMore()}
          >
            <QrCodeScannerRoundedIcon
              sx={{
                color:
                  allData.filter((list) => !idScanned.includes(list.idProduk))
                    .length === 0
                    ? '#9D9E9E'
                    : '#51B15C',
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SendTransactionMobile;
