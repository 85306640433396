import { alereAxios } from '../utils/api';

export const fetchCategories = (params) =>
  alereAxios.get('categories', {
    params: params,
  });

export const fetchSubCategories = (params) =>
  alereAxios.get('categories/subs', {
    params: params,
  });

export const fetchProducts = (params) =>
  alereAxios.get('products', {
    params: params,
  });

export const fetchAllProducts = ({ params, data }) =>
  alereAxios.get(
    'products',
    {
      params,
    },
    data
  );

// !----------- DETAILS ------------

export const fetchCategoryDetails = ({ startDate, endDate, id }) =>
  alereAxios.get(`categories/${id}`, { params: { startDate, endDate } });

export const fetchSubCategoryDetails = ({ startDate, endDate, id }) =>
  alereAxios.get(`categories/subs/${id}`, { params: { startDate, endDate } });

export const fetchMiniSubCategoryDetails = ({ startDate, endDate, id }) =>
  alereAxios.get(`categories/minis/${id}`, { params: { startDate, endDate } });

export const fetchProductDetails = (id) => alereAxios.get(`products/${id}`);

export const fetchSubCategoriesDetails = (id) =>
  alereAxios.get(`categories/subs/${id}`);

export const addImageCategory = (params) =>
  alereAxios.post('storages/images', {
    params: params,
    // headers: {
    //   'Content-Type': 'multipart/form-data',
    // },
  });

export const fetchSubsidiary = () => alereAxios.get('subsidiaries');

// !----------- ADD NEW ------------

export const addNewCategoryProduct = (data) =>
  alereAxios.post('categories', data);

export const addNewSubCategoryProduct = (data) =>
  alereAxios.post('categories/subs', data);

export const addNewMiniSubCategoryProduct = (data) =>
  alereAxios.post('categories/minis', data);

export const addNewProductProduct = (data) => alereAxios.post('products', data);

// !----------- EDIT  ------------

export const updateCategoryProduct = (id, data) =>
  alereAxios.put(`categories/${id}`, data);

export const updateSubCategoryProduct = (id, data) =>
  alereAxios.put(`categories/subs/${id}`, data);

export const updateMiniSubCategoryProduct = (id, data) =>
  alereAxios.put(`categories/minis/${id}`, data);

export const updateProductProduct = (id, data) =>
  alereAxios.put(`products/${id}`, data);

// !----------- EDIT STATUS  ------------

export const updateCategoryProductStatus = (id, data) =>
  alereAxios.put(`categories/${id}`, { status: data });

export const updateSubCategoryProductStatus = (id, data) =>
  alereAxios.put(`categories/subs/${id}`, { subStatus: data });

export const updateMiniSubCategoryProductStatus = (id, data) =>
  alereAxios.put(`categories/minis/${id}`, { status: data });

export const updateProductProductStatus = (id, data) =>
  alereAxios.put(`products/${id}`, { status: data });

// !----------- Delete  ------------

export const deleteCategoryProduct = (id) =>
  alereAxios.delete(`categories/${id}`);

export const deleteSubCategoryProduct = (id) =>
  alereAxios.delete(`categories/subs/${id}`);

export const deleteMiniSubCategoryProduct = (id) =>
  alereAxios.delete(`categories/minis/${id}`);

export const deleteProductProduct = (id) => alereAxios.delete(`products/${id}`);

// !----------- ADD PRODUCT OPTIONS ------------

export const fetchUnits = ({ pageSize, page }) =>
  alereAxios.get('units', {
    params: { pageSize, page },
  });

export const allCategoryProductName = (data) =>
  alereAxios.get('categories/list', {
    params: data,
  });

export const allSubCategoryProductName = (data) =>
  alereAxios.get('categories/subs/list', {
    params: data,
  });

export const allMiniSubCategoryProductName = (data) =>
  alereAxios.get('categories/minis/list', {
    params: data,
  });

// !----------- ADD CATEGORY OPTIONS ------------

export const allTypeCategoryProduct = (data) =>
  alereAxios.get('categories/options', {
    params: data,
  });

// ----------------- LIST PRODUCT ------------
export const getAllListProduct = (data) =>
  alereAxios.get('products/list', { params: data });
