import { createSlice } from '@reduxjs/toolkit';
import { fetchAllTransaction } from '../../../api/transaction';
import { getAllHubs, getAllMiniHub, getAllSubHub } from '../../../api/hubs';

export const transactionRequestState = createSlice({
  name: 'transactionRequest',
  initialState: {
    value: [],
    isLoading: false,
    isLoadingFilter: false,
    totalItem: 0,
    hub: [],
    subHub: [],
    miniHub: [],

    // sorting
    sortAsc: true,
    sortIn: 'date',

    //  filter
    from: [],
    to: [],
    status: ['sent', 'accept', 'remaining', 'finish', 'reject'],
  },
  reducers: {
    loadTableRequest: (state, action) => {
      state.value = action.payload;
    },
    loadTotalItemRequest: (state, action) => {
      state.totalItem = action.payload;
    },
    changeIsLoadingRequest: (state, action) => {
      state.isLoading = action.payload;
    },

    // ! sorting
    changeSortAscRequest: (state, action) => {
      state.sortAsc = action.payload;
    },
    changeSortInRequest: (state, action) => {
      state.sortIn = action.payload;
    },

    // ! filter
    changeIsLoadingFilter: (state, action) => {
      state.isLoadingFilter = action.payload;
    },
    loadAllDataHub: (state, action) => {
      state.hub = action.payload;
    },
    loadAllDataSubHub: (state, action) => {
      state.subHub = action.payload;
    },
    loadAllDataMiniHub: (state, action) => {
      state.miniHub = action.payload;
    },
    changeFilterFrom: (state, action) => {
      state.from = action.payload;
    },
    changeFilterTo: (state, action) => {
      state.to = action.payload;
    },
    changeFilterStatus: (state, action) => {
      state.status = action.payload;
    },
    clearDataTransaction: (state) => {
      state.value = [];
      state.isLoading = false;
      state.isLoadingFilter = false;
      state.totalItem = 0;
      state.hub = [];
      state.subHub = [];
      state.miniHub = [];

      // sorting
      state.sortAsc = true;
      state.sortIn = 'date';

      //  filter
      state.from = [];
      state.to = [];
      state.status = ['sent', 'accept', 'remaining', 'finish', 'reject'];
    },
  },
});

export const {
  loadTableRequest,
  loadTotalItemRequest,
  changeIsLoadingRequest,
  changeSortAscRequest,
  changeSortInRequest,
  changeIsLoadingFilter,
  loadAllDataHub,
  loadAllDataSubHub,
  loadAllDataMiniHub,
  changeFilterFrom,
  changeFilterTo,
  changeFilterStatus,
  clearDataTransaction,
} = transactionRequestState.actions;

export const getTransactionRequest = (state) => state.transactionRequest.value;
export const getTransactionRequestIsLoading = (state) =>
  state.transactionRequest.isLoading;
export const getTransactionRequestTotalItem = (state) =>
  state.transactionRequest.totalItem;
export const getTransactionRequestSortIn = (state) =>
  state.transactionRequest.sortIn;
export const getTransactionRequestSortAsc = (state) =>
  state.transactionRequest.sortAsc;

// ! filter
export const getDataHubTransaction = (state) => state.transactionRequest.hub;
export const getDataSubHubTransaction = (state) =>
  state.transactionRequest.subHub;
export const getDataMiniHubTransaction = (state) =>
  state.transactionRequest.miniHub;
export const getDataFilterFrom = (state) => state.transactionRequest.from;
export const getDataFilterTo = (state) => state.transactionRequest.to;
export const getDataFilterStatus = (state) => state.transactionRequest.status;

// !--------------- API ------------------------

export const getAllNewDataTransaction = (datas) => (dispatch) => {
  const fetchApi = async () => {
    dispatch(changeIsLoadingRequest(true));
    try {
      const {
        data: { data },
      } = await fetchAllTransaction(datas);
      dispatch(loadTableRequest(data.hub));
      dispatch(loadTotalItemRequest(data?.pageInfo?.total));
    } catch {
      dispatch(loadTableRequest([]));
      dispatch(changeIsLoadingRequest(false));
    } finally {
      dispatch(changeIsLoadingRequest(false));
    }
  };

  fetchApi();
};

// get hub
export const getAllMasterHub = (params) => (dispatch) => {
  const fetchDataHub = async () => {
    dispatch(changeIsLoadingFilter(true));
    try {
      const {
        data: {
          data: { hubs },
        },
      } = await getAllHubs(params);
      // let sliceData = hubs.map((hub, _) => {
      //   return { ...hub, typeHub: 'hub' };
      // });

      let fixDatas = hubs?.map((data, _) => {
        return {
          // id: data?.idToko,
          label: data?.namaToko,
          // typeHub: data?.typeHub,
        };
      });
      dispatch(loadAllDataHub(fixDatas));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoadingFilter(false));
    }
  };
  fetchDataHub();
};

// get sub hub
export const getAllSubHubData = (params) => (dispatch) => {
  const fetchDataSubHub = async () => {
    dispatch(changeIsLoadingFilter(true));
    try {
      const {
        data: {
          data: { subHubs },
        },
      } = await getAllSubHub(params);
      // let sliceDataSubHub = subHubs.map((subHub, _) => {
      //   return { ...subHub, typeHub: 'subhub' };
      // });

      let fixDatasSubHub = subHubs?.map((data, _) => {
        return {
          // id: data?.id,
          label: data?.name,
          // typeHub: data?.typeHub,
        };
      });
      dispatch(loadAllDataSubHub(fixDatasSubHub));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoadingFilter(false));
    }
  };
  fetchDataSubHub();
};

// get mini hub
export const getAllMiniHubData = (params) => (dispatch) => {
  const fetchDataMiniHub = async () => {
    dispatch(changeIsLoadingFilter(true));
    try {
      const {
        data: {
          data: { subHubs },
        },
      } = await getAllMiniHub(params);
      // let sliceDataMiniHub = subHubs.map((miniHub, _) => {
      //   return { ...miniHub, typeHub: 'minihub' };
      // });

      let fixDatasMiniHub = subHubs?.map((data, _) => {
        return {
          // id: data?.id,
          label: data?.name,
          // typeHub: data?.typeHub,
        };
      });
      dispatch(loadAllDataMiniHub(fixDatasMiniHub));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoadingFilter(false));
    }
  };
  fetchDataMiniHub();
};

export default transactionRequestState.reducer;
