import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import BackButtonHub from '../../../../../../components/shared/BackButtonHub';
import {
  changePageProductSupplier,
  setNotifProductSupplier,
} from '../../../../../../store/Routing/productSupplierRouting';
import { useDispatch, useSelector } from 'react-redux';
import { AddCirleIcon, DeleteOutlined } from '../../../../../../assets/Icons';
import CustomAntSwitch from '../../../../../../components/shared/CustomAntSwitch';
import CustomButton from '../../../../../../components/shared/CustomButton';
import {
  changeStatusProductSupplier,
  clearStateProductSupplier,
  getAllDataAddProductSupplier,
} from '../../../../../../store/productSupplier/addProductSupplier';
import { useEffect, useState } from 'react';
import ProductSupplierSelect from './ProductSelect';
import CustomCard from '../../../../../../components/shared/CustomCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PriceProductUnit from './PriceProductUnit';
import {
  createProductSupplier,
  getProductUnit,
} from '../../../../../../api/Supplier';

const AddProductSupplier = () => {
  const dispatch = useDispatch();
  const { status } = useSelector(getAllDataAddProductSupplier);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [isDeleteIngredientRow, setIsDeleteIngredientRow] = useState(false);
  const [tambahItem, setTambahItem] = useState([
    {
      namaProduk: '',
      idProduk: 0,
      detail: [],
    },
  ]);
  const [openPrice, setOpenPrice] = useState(false);
  const [emptyProduct, setEmptyProduct] = useState('');
  const [unit, setUnit] = useState([]);
  const [isLoadingUnit, setIsLoadingUnit] = useState(false);

  const handleAddItemCardIngredient = () => {
    setTambahItem([...tambahItem, { namaProduk: '', idProduk: 0, detail: [] }]);
  };

  // validasi submit
  const [allowSubmit, setAllowSubmit] = useState(false);
  useEffect(() => {
    if (
      tambahItem[0].idProduk !== 0 &&
      tambahItem[0].namaProduk !== '' &&
      tambahItem[0].detail.length !== 0
    ) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [tambahItem]);

  // ! ------------------- API ----------------------------------
  // get product unit
  const fetchProductUnit = async (idProduct) => {
    try {
      setIsLoadingUnit(true);
      const {
        data: {
          data: { productUnits },
        },
      } = await getProductUnit(idProduct);
      const units = productUnits?.map((item, idx) => {
        return {
          idProduct: item?.idProduk,
          unit_id: item?.unit?.id,
          unitName: item?.unit.name,
          price: 0,
        };
      });
      setUnit(units);
      setIsLoadingUnit(false);
    } catch (err) {
      console.error(err);
    }
  };

  // submit
  const handleSubmit = async () => {
    const params = {};
    params.status_publish = status ? 1 : 0;
    params.data = tambahItem;

    try {
      setLoadingSubmit(true);
      await createProductSupplier(params);
      dispatch(
        setNotifProductSupplier({
          key: 'addProductSupllier',
          text: 'add product success',
        })
      );
      dispatch(changePageProductSupplier(''));
      dispatch(clearStateProductSupplier());
      setLoadingSubmit(false);
      console.log({ params });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Grid container columns={12} spacing={3}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: 2,
            }}
          >
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <BackButtonHub
                onClick={() => dispatch(changePageProductSupplier(''))}
              />
              <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
                Add Product
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                background: 'rgba(81, 177, 92, 0.1)',
                borderRadius: '8px',
                padding: '15px',
                cursor: 'pointer',
              }}
              onClick={handleAddItemCardIngredient}
            >
              <AddCirleIcon width="28" height="28" />
              <Typography
                sx={{ fontSize: '16px', fontWeight: 600, color: '#51B15C' }}
              >
                Add more product
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box>
            <Grid container columns={12} spacing={4} sx={{ p: '30px 20px' }}>
              {tambahItem.map((item, index) => (
                <Grid key={index} item xs={12} sx={{ display: 'flex' }}>
                  <Grid container columns={12} gap={4}>
                    {/* product */}
                    <Grid item xs={5}>
                      <Box sx={{ display: 'flex' }}>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 500,
                            mb: 2,
                          }}
                        >
                          Product Name
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 500,
                            mb: 2,
                            color: '#F33A3A',
                          }}
                        >
                          *
                        </Typography>
                      </Box>
                      <ProductSupplierSelect
                        items={tambahItem}
                        firstValueId={item?.idProduk}
                        firstValue={item?.namaProduk}
                        isReset={isDeleteIngredientRow}
                        setIsReset={setIsDeleteIngredientRow}
                        handleValue={(name, id) => {
                          const memo = tambahItem.map((item, idx) => {
                            if (item?.idProduk !== id && index === idx) {
                              return {
                                ...item,
                                idProduk: id,
                                namaProduk: name,
                              };
                            } else {
                              return item;
                            }
                          });
                          setTambahItem(memo);
                        }}
                        // onClose={() =>
                        //   handleAutocompleteClose(
                        //     item?.idProduk,
                        //     item?.namaProduk
                        //   )
                        // }
                        // disableClearable
                        // clearOnEscape
                        clearText="Clear"
                        //TODO : lanjut validasi
                      />
                      {emptyProduct && item?.idProduk === 0 && (
                        <Typography
                          sx={{ fontSize: '12px', color: '#F33A3A', mt: 1 }}
                        >
                          {emptyProduct}
                        </Typography>
                      )}
                    </Grid>

                    {/* price */}
                    <Grid item xs={5}>
                      <Box sx={{ display: 'flex' }}>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 500,
                            mb: 2,
                          }}
                        >
                          Price per unit
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 500,
                            mb: 2,
                            color: '#F33A3A',
                          }}
                        >
                          *
                        </Typography>
                      </Box>
                      <CustomCard
                        sx={{
                          padding: '16px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (item?.idProduk === 0) {
                            setEmptyProduct('harap isi product dulu');
                            setTimeout(function () {
                              setEmptyProduct('');
                            }, 3000);
                          } else {
                            setOpenPrice(!openPrice);
                            fetchProductUnit(item?.idProduk);
                          }
                        }}
                      >
                        {item?.detail?.length === 0 ? (
                          <Typography
                            sx={{ color: '#9E9D9D', fontSize: '14px' }}
                          >
                            Click to set Price
                          </Typography>
                        ) : (
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <Typography
                              sx={{ color: '#000', fontSize: '14px' }}
                            >
                              Rp {item?.detail[0]?.price} /
                              {item?.detail[0]?.unitName}
                            </Typography>
                            <Typography
                              sx={{ color: '#9E9D9D', fontSize: '14px' }}
                            >
                              +{item?.detail?.length - 1} more...
                            </Typography>
                          </Box>
                        )}
                        <ExpandMoreIcon />
                      </CustomCard>
                      <PriceProductUnit
                        openPrice={openPrice}
                        setOpenPrice={setOpenPrice}
                        unit={unit}
                        isLoadingUnit={isLoadingUnit}
                        handleValue={(detail) => {
                          const memo = tambahItem.map((item, idx) => {
                            if (item?.idProduk === detail[0]?.idProduct) {
                              return {
                                ...item,
                                detail: detail,
                              };
                            } else {
                              return item;
                            }
                          });
                          setTambahItem(memo);
                        }}
                      />
                    </Grid>

                    {/* hapus item */}
                    <Grid item xs="auto">
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mt: 4,
                          background:
                            tambahItem?.length > 1 ? '#FEEBEB' : '#F5F5F5',
                          borderRadius: '8px',
                          width: '54px',
                          height: '54px',
                          maxWidth: '54px',
                          maxHeight: '54px',
                          textAlign: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          tambahItem?.length > 1 &&
                            setTambahItem(
                              tambahItem.filter((_, idx) => idx !== index)
                            );
                        }}
                      >
                        <DeleteOutlined
                          fill={tambahItem?.length > 1 ? '#F33A3A' : '#9E9D9D'}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* button event */}
      <Box sx={{ position: 'fixed', bottom: 1, p: '18px 32px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
            Status
          </Typography>
          <CustomAntSwitch
            value={status}
            onChange={() => dispatch(changeStatusProductSupplier(!status))}
          />
        </Box>
      </Box>
      <Box sx={{ position: 'fixed', bottom: 1, right: 1 }}>
        <CustomButton
          variant="contained"
          disabled={!allowSubmit}
          onClick={handleSubmit}
          sx={{ width: '140px', height: '36px', m: '18px 32px' }}
        >
          {loadingSubmit ? (
            <CircularProgress size={16} sx={{ color: '#FFF' }} />
          ) : (
            <Typography
              sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
            >
              Submit
            </Typography>
          )}
        </CustomButton>
      </Box>
    </>
  );
};
export default AddProductSupplier;
