import { createContext, useState } from 'react';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(
    localStorage.getItem('accessToken')
      ? {
          accessToken: localStorage.getItem('accessToken'),
          companyId: parseInt(localStorage.getItem('companyId')),
          role: localStorage.getItem('userRole'),
          email: localStorage.getItem('email'),
        }
      : null
  );

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
