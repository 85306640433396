import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import CustomCard from "../../../../../../components/shared/CustomCard";
import {
  AddIcon,
  Dolarss,
  ExportIcon,
  FilterAltIcon,
  NoImage,
  SearchIcon,
} from "../../../../../../assets/Icons";
import CustomButton from "../../../../../../components/shared/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllProductSupplier,
  getAllDataProductSupplier,
} from "../../../../../../store/productSupplier/dataProductSupplier";
import debounce from "lodash.debounce";
import { useEffect } from "react";
import dayjs from "dayjs";
import ChartProdukSupplier from "./Component/Chart";
import TableProduk from "./Component/TableProduk";
import { changePageProductSupplier } from "../../../../../../store/Routing/productSupplierRouting";
import { changeIdProdukSupplier } from "../../../../../../store/productSupplier/detailProductSupplier";

const ProdukSupplierMobile = () => {
  const dispatch = useDispatch();

  const { datas, isLoadingData } = useSelector(getAllDataProductSupplier);

  const [search, setSearch] = useState("");
  const [purchaseKeyword, setPurchaseKeyword] = useState("");

  const debounceOnChange = useRef(
    debounce((value) => {
      setPurchaseKeyword(value);
    }, 1000)
  ).current;

  useEffect(() => {
    const params = {};
    params.search = purchaseKeyword;
    params.PageSize = 4;

    dispatch(fetchAllProductSupplier(params));
  }, [purchaseKeyword]);

  // ---- Grafik ----
  const [timeFrame, setTimeFrame] = useState("daily");

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);

  const [saveDateRange, setSaveDateRange] = useState([
    {
      startDate: new Date(dayjs(new Date()).subtract(7, "d")),
      endDate: new Date(dayjs(new Date())),
      key: "selection",
    },
  ]);

  const toolbar = (
    <Box
      sx={{
        display: "flex",
        flexFlow: "row-reverse",
        gap: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CustomButton
        sx={{
          minWidth: "22px",
          width: "22px",
          minHeight: "20px",
          height: "20px",
          bgcolor: "#51B15C",
          color: "#ffffff",
          borderRadius: "4px",
          "&:hover": {
            bgcolor: "#51B15C",
            color: "#ffffff",
          },
          fontSize: "0.875rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <AddIcon
          sx={{ width: "14px", color: "#FFFFFF" }}
          onClick={() => {
            dispatch(changePageProductSupplier("addProduk"));
          }}
        />
      </CustomButton>
      <CustomButton
        sx={{
          minWidth: "22px",
          width: "22px",
          minHeight: "22px",
          height: "22px",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "4px",
          bgcolor: "#FFFFFF",
          color: "#000000",
          "&:hover": {
            bgcolor: "#FFFFFF",
            color: "#000000",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <ExportIcon />
      </CustomButton>

      <CustomButton
        sx={{
          minWidth: "22px",
          width: "22px",
          minHeight: "22px",
          height: "22px",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "4px",
          bgcolor: "#FFFFFF",
          color: "#000000",
          "&:hover": {
            bgcolor: "#FFFFFF",
            color: "#000000",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <FilterAltIcon sx={{ width: "14px" }} />
      </CustomButton>
    </Box>
  );

  return (
    <Grid container columns={12} spacing={2} sx={{ mt: 0.5 }}>
      {/* Grafik */}
      <Grid item xs={12}>
        <CustomCard sx={{ p: "10px 24px " }}>
          <Grid container columns={12} spacing={2}>
            <Grid item xs={12}>
              <ChartProdukSupplier
                {...{
                  dateRange,
                  setDateRange,
                  saveDateRange,
                  setSaveDateRange,
                  timeFrame,
                  setTimeFrame,
                }}
              />
            </Grid>
          </Grid>
        </CustomCard>
      </Grid>

      {/* Table produkSupplier Mobile */}
      <Grid item xs={12}>
        <TableProduk />
      </Grid>

      {/* List Product */}
      <Grid item xs={12}>
        <CustomCard sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1.5,
              }}
            >
              <Box>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: 600, color: "#000" }}
                >
                  Product
                </Typography>
              </Box>
              <Box>
                <TextField
                  placeholder={"Search"}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    debounceOnChange(e.target.value);
                  }}
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      paddingLeft: "6px",
                      fontSize: "7px",
                    },
                    bgcolor: "#FAFAFA",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ width: "12px" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>

            {/* toolbar */}
            <Box>{toolbar}</Box>
          </Box>

          <Grid container columns={12} spacing={2}>
            <Grid item xs={12} sx={{ display: "flex", mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    color: "#000000",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Dolarss fill="#51B15C" />
                    <Typography sx={{ fontSize: "8px" }}>
                      Price unit 1 (Rp)*
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Dolarss fill="#2704FF" />
                    <Typography sx={{ fontSize: "8px" }}>
                      Price unit 2 (Rp)*
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Dolarss fill="#FEA725" />
                    <Typography sx={{ fontSize: "8px" }}>
                      Price unit 3 (Rp)*
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "8px" }}>*000</Typography>
                </Box>
              </Box>
            </Grid>

            {/* mapping data */}
            {isLoadingData === true ? (
              <Grid item xs={12}>
                <Box sx={{ textAlign: "center", p: 4 }}>
                  <CircularProgress size={24} />
                </Box>
              </Grid>
            ) : datas?.length === 0 ? (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#9E9D9D",
                  pt: "8px",
                  p: 5,
                }}
              >
                <Typography sx={{ fontSize: "12px" }}>
                  No data product
                </Typography>
              </Grid>
            ) : (
              datas.map((item, idx) => (
                <Grid item xs={6} key={idx}>
                  <Box
                    sx={{
                      padding: "10px",
                      borderRadius: "8px",
                      background: "#FAFAFA",
                      flexDirection: "flex",
                    }}
                    onClick={() => {
                      dispatch(changeIdProdukSupplier(item.id));
                      dispatch(changePageProductSupplier("detailProduk"));
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                      }}
                    >
                      {item.gambar ? (
                        <Box
                          component="img"
                          src={
                            process.env.REACT_APP_API_IMAGE_BASE_URL +
                            "/" +
                            item.gambar
                          }
                          sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "8px",
                          }}
                        />
                      ) : (
                        <NoImage />
                      )}

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "28px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontWeight: 600,
                          }}
                        >
                          {item?.namaProduk}
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ display: "flex", gap: 1, pt: "8px" }}>
                      {item?.detail.slice(0, 3).map((item, index) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5,
                          }}
                        >
                          <Dolarss
                            fill={
                              index === 0
                                ? "#51B15C"
                                : index === 1
                                ? "#2704FF"
                                : "#FEA725"
                            }
                          />
                          <Typography sx={{ fontSize: "8px", fontWeight: 400 }}>
                            {(item.price !== 0 && item.price) || "-"}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Grid>
              ))
            )}
          </Grid>
        </CustomCard>
      </Grid>
    </Grid>
  );
};

export default ProdukSupplierMobile;
