import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { getPlacesByCoordinate, getPlacesByName } from '../../api/AddressMap';

export const AddSupplierState = createSlice({
  name: 'addSupplier',
  initialState: {
    supplierName: '',
    supplierCode: '',
    email: '',
    phoneNumber: '',
    joinDate: dayjs(new Date()),

    // address map
    address: '',
    addressFix: '',
    coordinateFix: [],
    mapInputType: 'manual',
    placeGetByName: null,
    coordinate: [],
    center: [3.546728899138594, 98.69014558305145],
    placesSearchByName: [],
    placesSearchByNameLoading: false,
    placeGetByCoordinate: null,
    placeGetByCoordinateLoading: false,

    publish: false,
  },

  reducers: {
    changeSupplierName: (state, action) => {
      state.supplierName = action.payload;
    },
    changeSupplierCode: (state, action) => {
      state.supplierCode = action.payload;
    },
    changeEmailSupplier: (state, action) => {
      state.email = action.payload;
    },
    changePhoneNumberSupplier: (state, action) => {
      state.phoneNumber = action.payload;
    },
    changeJoinDate: (state, action) => {
      state.joinDate = action.payload;
    },
    // ------------- Address ----------
    changeMapTypeSupplier: (state, action) => {
      state.mapInputType = action.payload;
    },
    changePlaceGetByName: (state, action) => {
      let result = action.payload;
      state.placeGetByName = result;
      state.coordinate = [result.lat, result.lon];
      state.center = [result.lat, result.lon];
    },
    changeCoordinate: (state, action) => {
      state.coordinate = action.payload;
    },
    changeCenter: (state, action) => {
      state.center = action.payload;
    },
    changeAddress: (state, action) => {
      let result = action.payload;
      state.address = result;
    },
    submitAddresMap: (state, action) => {
      const { address, coordinate } = action.payload;
      state.addressFix = address;
      state.coordinateFix = coordinate;
    },
    loadPlaceSearchByName: (state, action) => {
      state.placesSearchByName = action.payload;
    },
    loadPlaceSearchByNameLoading: (state, action) => {
      state.placesSearchByNameLoading = action.payload;
    },
    changeIsPlaceGetByCoordinateLoading: (state, action) => {
      let result = action.payload;
      state.placeGetByCoordinateLoading = result;
    },
    changePlaceGetByCoordinate: (state, action) => {
      let result = action.payload;
      state.placeGetByCoordinate = result;
    },
    changePublish: (state, action) => {
      state.publish = action.payload;
    },
  },
});
export const {
  changeSupplierName,
  changeSupplierCode,
  changeEmailSupplier,
  changePhoneNumberSupplier,
  changeJoinDate,
  changeAddressSupplier,

  // address
  changeMapTypeSupplier,
  changePlaceGetByName,
  changeAddress,
  changeCoordinate,
  changeCenter,
  submitAddresMap,
  loadPlaceSearchByName,
  loadPlaceSearchByNameLoading,
  changeIsPlaceGetByCoordinateLoading,
  changePlaceGetByCoordinate,
  changePublish,
} = AddSupplierState.actions;

export const getAllDataAddSupplier = (state) => state.addSupplier;

export const fetchPlacesSearchByName = (name) => (dispatch) => {
  const fetchData = async () => {
    dispatch(loadPlaceSearchByNameLoading(true));
    try {
      const { data } = await getPlacesByName(name);
      dispatch(loadPlaceSearchByName(data));
    } catch (error) {
      dispatch(loadPlaceSearchByName([]));
    } finally {
      dispatch(loadPlaceSearchByNameLoading(false));
    }
  };

  fetchData();
};

export const fetchPlacesSearchByCoordinate = (coor) => (dispatch) => {
  const fetchData = async () => {
    dispatch(changeIsPlaceGetByCoordinateLoading(true));
    try {
      const { data } = await getPlacesByCoordinate(coor);
      dispatch(changePlaceGetByCoordinate(data));
    } catch (error) {
      dispatch(changePlaceGetByCoordinate([]));
    } finally {
      dispatch(changeIsPlaceGetByCoordinateLoading(false));
    }
  };

  fetchData();
};

export default AddSupplierState.reducer;
