import { alereAxios } from '../utils/api';

export const getSupplierForAutocomplete = (search) =>
  alereAxios.get('suppliers', { params: { pageSize: 10, page: 1, search } });

export const getListSupplier = (params) =>
  alereAxios.get('suppliers', { params });

export const getRecomendationSupplier = (id) =>
  alereAxios.get(`purchases/recommendation-suppliers/${id}`);

export const createNewSupplier = (data) => alereAxios.post('suppliers', data);
export const updateSupplier = (idKustomer, params) =>
  alereAxios.put(`suppliers/${idKustomer}`, params);
export const deleteSupplier = (idSupplier) =>
  alereAxios.delete(`suppliers/${idSupplier}`);

export const getProductUnit = (id) =>
  alereAxios.get(`suppliers/list-unit/${id}`);

export const createProductSupplier = (params) =>
  alereAxios.post('suppliers/product', params);

export const getListDataProductSupplier = (params) =>
  alereAxios.get('suppliers/show-all-product-supplier', { params });

export const getDetailProductSupplier = (id) =>
  alereAxios.get(`suppliers/show-spesific-product-supplier/${id}`);

export const geteditProdukSupplier = (id, data) =>
  alereAxios.put(`suppliers/product-update/${id}`, data);

export const DeleteProdukSupplier = (id) =>
  alereAxios.delete(`suppliers/delete-supplier-produk/${id}`);
