import { Box, Typography } from '@mui/material';
import { NoImage } from '..';

const NoImageIcon = ({ ...props }) => {
  return (
    <Box
      sx={{
        width: '40px',
        height: '40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: '#FAFAFA',
        borderRadius: '2px',
      }}
      {...props}
    >
      <NoImage />
      <Typography sx={{ color: '#9E9D9D', fontSize: '6px' }}>
        No photo
      </Typography>
    </Box>
  );
};
export default NoImageIcon;
