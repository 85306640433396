import React, { useState, useEffect } from "react";
import CustomCard from "./CustomCard";
import {
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getSubsidiary } from "../../api/subsidiary";

const Subsidiary = ({ subsidiaryChecked, setSubsidiaryChecked, pad }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [subsidiary, setSubsidiary] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);

  const handleSubsidiaryChecked = (id, value) => {
    let tempSubsidiaryGroups = [...subsidiary];
    let tempSubsidiaryGroup = tempSubsidiaryGroups.find(
      (item) => item.id === value.id
    );

    if (subsidiaryChecked.includes(id)) {
      setSubsidiaryChecked(
        [...subsidiaryChecked].filter((value) => value !== parseInt(id))
      );

      tempSubsidiaryGroup.isChecked = false;
      setSubsidiary(tempSubsidiaryGroups);
    } else {
      setSubsidiaryChecked([...subsidiaryChecked, parseInt(id)]);
      tempSubsidiaryGroup.isChecked = true;
      setSubsidiary(tempSubsidiaryGroups);
    }
  };

  const handleSubsidiaryCheckedAll = () => {
    setIsCheckAll(!isCheckAll);
    setSubsidiaryChecked(subsidiary.map((subsidiaryList) => subsidiaryList.id));

    let tempSubsidiaryGroups = [...subsidiary];
    setSubsidiary(
      tempSubsidiaryGroups.map(({ id, name }) => ({
        isChecked: true,
        id,
        name,
      }))
    );
    setIsExpanded(false);

    if (isCheckAll) {
      setSubsidiaryChecked([]);
      setSubsidiary(
        tempSubsidiaryGroups.map(({ id, name }) => ({
          isChecked: false,
          id,
          name,
        }))
      );
    }
  };

  useEffect(() => {
    const fetchSubsidiary = async () => {
      try {
        const {
          data: {
            data: { subsidiaries },
          },
        } = await getSubsidiary();

        setSubsidiary(
          subsidiaries.map((subsidiary) => ({
            isChecked: false,
            ...subsidiary,
          }))
        );
      } catch (err) {
        console.error(err);
      }
    };
    fetchSubsidiary();
  }, []);

  const temp = [
    ...subsidiary
      .filter((item) => item.isChecked === true)
      .map(({ id, name, isChecked }) => ({ id, name, isChecked })),
  ];

  const tempSelectedSubsidiary = [
    ...subsidiary.filter((item) => item.isChecked === true).slice(0, 4),
  ];

  const tempSubsidiary = [
    ...subsidiary
      .filter((item) => item.isChecked === true)
      .slice(tempSelectedSubsidiary.length - temp.length),
  ];

  return (
    <CustomCard sx={{ p: pad >= 0 ? pad : 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2.5 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
          <Box sx={{ display: "flex", gap: 2.5 }}>
            <FormControl component="fieldset">
              <FormControlLabel
                value="end"
                control={<Checkbox />}
                label="All"
                labelPlacement="end"
                onChange={handleSubsidiaryCheckedAll}
                checked={isCheckAll}
              />
            </FormControl>

            <Box
              sx={{
                display: "flex",
                gap: 2.5,
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {tempSelectedSubsidiary
                .filter((item) => item.isChecked === true)
                .map((item, index) => {
                  return (
                    <Box
                      sx={[
                        {
                          bgcolor: "#51B15C",
                          boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)",
                          borderRadius: "6px",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          width: "140px",
                          justifyContent: "center",
                          height: "40px",
                          fontSize: "0.75rem",
                          fontWeight: 600,
                        },
                        isCheckAll && {
                          bgcolor: "#F5F5F5",
                          borderRadius: "6px",
                          color: "#000000",
                        },
                        subsidiaryChecked.includes(item.id) && {
                          bgcolor: "#51B15C",
                          boxShadow: "0px 0px 5.5534px rgba(0, 0, 0, 0.1)",
                          borderRadius: "6px",
                          color: "#FFFFFF",
                        },
                      ]}
                      key={index}
                      onClick={() => {
                        handleSubsidiaryChecked(item.id, item);
                      }}
                    >
                      {item.name}
                    </Box>
                  );
                })}
            </Box>
          </Box>

          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Box>
        {isExpanded && (
          <Box
            sx={{
              display: "flex",
              gap: 2.5,
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {temp.length > 4 &&
              tempSubsidiary
                .filter((item) => item.isChecked === true)
                .map((item, index) => {
                  return (
                    <Box
                      sx={[
                        {
                          bgcolor: "#51B15C",
                          boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)",
                          borderRadius: "6px",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          width: "140px",
                          justifyContent: "center",
                          height: "40px",
                          fontSize: "0.75rem",
                          fontWeight: 600,
                        },
                        isCheckAll && {
                          bgcolor: "#F5F5F5",
                          borderRadius: "6px",
                          color: "#000000",
                        },
                        subsidiaryChecked.includes(item.id) && {
                          bgcolor: "#51B15C",
                          boxShadow: "0px 0px 5.5534px rgba(0, 0, 0, 0.1)",
                          borderRadius: "6px",
                          color: "#FFFFFF",
                        },
                      ]}
                      key={index}
                      onClick={() => {
                        handleSubsidiaryChecked(item.id, item);
                      }}
                    >
                      {item.name}
                    </Box>
                  );
                })}
            {subsidiary
              .filter((subsidiary) => subsidiary.isChecked === false)
              .map((item, index) => {
                return (
                  <Box
                    sx={[
                      {
                        bgcolor: "#F5F5F5",
                        borderRadius: "6px",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        width: "140px",
                        justifyContent: "center",
                        height: "40px",
                        fontSize: "0.75rem",
                        fontWeight: 600,
                      },
                    ]}
                    key={index}
                    onClick={() => {
                      handleSubsidiaryChecked(item.id, item);
                    }}
                  >
                    {item.name}
                  </Box>
                );
              })}
          </Box>
        )}
      </Box>
    </CustomCard>
  );
};

export default Subsidiary;
