import { useEffect, useState } from "react";
import { getRackDetail } from "../../../../../../api/rack";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRackhub,
  goBackMainRack,
  goToRackHubEdit,
} from "../../../../../../store/racks/rackHub";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { LoadingComponent } from "../../../../../../components/shared/shared2";
import LineDiv from "../../../../../../components/shared/LineDiv";
import CustomCard from "../../../../../../components/shared/CustomCard";
import {
  CloseIcon,
  DeleteIcon,
  KeyboardArrowDownIcon,
} from "../../../../../../assets/Icons";
import PhotoView from "../../../../../../components/shared/PhotoView";
import VideoView from "../../../../../../components/shared/VideoView";
import BottomMenu from "../../../../../../components/shared/BottomMenu";
import { setEditRack } from "../../../../../../store/racks/rackHubEdit";
import RackMapPosition from "../components/RackMapPosition";
import { GetExcelNumberFormat } from "../../../../../../hooks/getExcelNumber";
import { deleteRackMaster } from "../../../../../../api/hubs";

const RackHubDetail = () => {
  const dispatch = useDispatch();
  const { idRack: id } = useSelector(getAllRackhub);
  const [datas, setDatas] = useState(null);
  const [dataCellContained, setDataCellContained] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const fetchApi = async (id) => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await getRackDetail(id);
      setDatas(Object.assign({}, data[0]));
      const cellDataContained = data?.rackPosition
        ?.filter((list) => list.contain)
        .map((list) => {
          let collect1 = GetExcelNumberFormat(list.posisiX);
          let collect = `${collect1}${list.posisiY}`;
          return collect;
        });
      setDataCellContained(cellDataContained);
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchApi(id);
  }, [id]);

  const handleEdit = () => {
    const collect = {
      idToko: datas.idToko,
      subHubId: datas.subHubId,
      miniHubId: datas.miniHubId,
      idRack: datas.id,
      name: datas.name,
      photo: datas.images ? datas.images.split(",") : [],
      video: datas.videos ? datas.videos.split(",") : [],
      description: datas.description,
      width: datas.width,
      height: datas.height,
      length: datas.length,
      weight: datas.weight,
      cellPosition: datas.columnPosition
        ? datas?.columnPosition.split(",")
        : [],
      row: datas.row,
      column: datas.column,
    };
    dispatch(setEditRack(collect));
    dispatch(goToRackHubEdit());
  };

  const handleDelete = async () => {
    try {
      await deleteRackMaster(datas?.id);
      dispatch(goBackMainRack());
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box>
      {isLoading && <LoadingComponent />}
      {!isLoading && !isError && (
        <Grid container columns={12} columnSpacing={4}>
          <Grid item xs={5}>
            <Box
              sx={{
                bgcolor: "#FAFAFA",
                borderRadius: "12px",
                px: "12px",
                py: "16px",
                overflow: "auto",
              }}
            >
              <RackMapPosition
                col={datas.column}
                row={datas.row}
                cellPosition={datas?.columnPosition?.split(",")}
                cellContained={dataCellContained}
              />
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Grid container columns={2} rowSpacing={2}>
              {/* //* Code */}
              <Grid item xs={2}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    mb: "12px",
                  }}
                >
                  Code
                </Typography>
                <LineDiv value={datas?.name} />
              </Grid>
              {/* //* Card */}
              <Grid item xs={2}>
                <CustomCard
                  sx={{
                    p: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{}}>
                    <Typography>Size</Typography>
                    <Typography sx={{ mt: "4px" }}>
                      {datas?.width} cm - {datas?.height} cm - {datas?.length}{" "}
                      cm - {datas?.weight} kg
                    </Typography>
                  </Box>
                  <Box>
                    <KeyboardArrowDownIcon />
                  </Box>
                </CustomCard>
              </Grid>
              {/* //* Preview Photo n Video */}
              <Grid item xs={2} container columns={2}>
                <Grid item xs={1}>
                  <PhotoView
                    data={
                      datas?.images !== null ? datas?.images.split(",") : []
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <VideoView
                    data={
                      datas?.videos !== null ? datas?.videos.split(",") : []
                    }
                  />
                </Grid>
              </Grid>
              {/* //* Description */}
              <Grid item xs={2}>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "500", mb: "12px" }}
                >
                  Description
                </Typography>
                <LineDiv value={datas?.description} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <BottomMenu
        isPublish={false}
        isGreen={true}
        greenText="Edit"
        isRed={true}
        redText="Delete"
        handleGreen={handleEdit}
        handleRed={() => setConfirmDelete(!confirmDelete)}
      />

      {/* Dialog */}
      {/* delete */}
      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(!confirmDelete)}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box
              sx={{
                background: "rgba(243, 58, 58, 0.1)",
                width: "50px",
                height: "50px",
                borderRadius: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DeleteIcon sx={{ color: "#F33A3A" }} />
            </Box>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Delete
            </Typography>
          </Box>
          <CloseIcon
            onClick={() => setConfirmDelete(!confirmDelete)}
            sx={{ cursor: "pointer" }}
          />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
              Are you sure you want to delete &nbsp;
            </Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
              "{datas?.name}" ?
            </Typography>
          </Box>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 400, textAlign: "center" }}
          >
            you can't undo this action.
          </Typography>

          <Box
            sx={{
              mt: 5,
              display: "flex",
              alignItems: "center",
              gap: 2,
              justifyContent: "flex-end",
            }}
          >
            <Typography
              sx={{
                color: "#9E9D9D",
                fontSize: "14px",
                fontWeight: 600,
                cursor: "pointer",
              }}
              onClick={() => setConfirmDelete(!confirmDelete)}
            >
              Cancel
            </Typography>
            <BottomMenu
              isRed={true}
              redText="Delete"
              handleRed={handleDelete}
              sx={{
                position: "relative",
                padding: 0,
                margin: 0,
                width: "50%",
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default RackHubDetail;
