import { Box, Typography } from '@mui/material';
import { useState } from 'react';

import { getAllMasterHubState } from '../../../../../../../../../store/racks/masterHubRack/masterHubRack';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import ListAlphabetExcelFormater from '../../../../../../../../../hooks/ListAlphabetExcelFormater';
import {
  ZoomInIcon,
  ZoomOutIcon,
} from '../../../../../../../../../assets/Icons';

const Racks = ({ col, row, rackSelected }) => {
  const [isZoom, setIsZoom] = useState(false);
  const [cellSelectedAll, setCellSelectedAll] = useState([]);
  const {
    id: idRack,
    cell,
    rackSelected: rackSelectedPack,
    page,
  } = useSelector(getAllMasterHubState);

  useEffect(() => {
    let collection = rackSelectedPack.flat();
    let collectionNew = collection.filter(
      (item) => !rackSelected.includes(item)
    );
    setCellSelectedAll(collectionNew);
  }, [rackSelectedPack, rackSelected]);

  const color = {
    selected: '#51B15C',
    contained: '#FEDD25',
    full: '#F33A3A',
    empty: '#FFFFFF',
  };

  return (
    <Box
      sx={{
        bgcolor: '#FAFAFA',
        borderRadius: '12px',
        px: '12px',
        py: '16px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Box
          sx={{
            bgcolor: '#E4E4E4',
            width: '26px',
            height: '26px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            borderRadius: '8px',
          }}
          onClick={() => setIsZoom(!isZoom)}
        >
          {!isZoom ? <ZoomInIcon /> : <ZoomOutIcon />}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!isZoom ? (
          <Box>
            {cell.map((row, rowIndex) => (
              <Box sx={{ display: 'flex' }} key={rowIndex}>
                {row.map((col, colIndex) => (
                  <Box
                    // onClick={() => dispatch(seeDetailRack())}
                    // onMouseOver={() => {
                    //   if (cellSet.flat().includes(col)) {
                    //     let getPos = cellSelectedDetails.find((value) =>
                    //       value.columnPosition.split(',').includes(col)
                    //     );
                    //     setCellSelected(getPos.columnPosition.split(','));
                    //   } else if (cellSelectedNew.flat().includes(col)) {
                    //     let getPos = cellSelectedDetailsNew.find((value) =>
                    //       value.columnPosition.split(',').includes(col)
                    //     );
                    //     setCellSelected(getPos.columnPosition.split(','));
                    //   }
                    // }}
                    // onMouseOut={() => setCellSelected([])}
                    sx={[
                      {
                        width: '36px',
                        height: '36px',
                      },
                      cellSelectedAll.includes(col) && {
                        bgcolor: '#E4E4E4',
                      },
                      // entrance.includes(col) && {
                      //   bgcolor: '#171717',
                      // },
                      rackSelected.includes(col) && {
                        bgcolor: '#51B15C',
                      },
                    ]}
                    key={colIndex}
                  ></Box>
                ))}
              </Box>
            ))}
          </Box>
        ) : (
          <Box>
            <Box>
              <Box sx={{ py: '20px' }}>
                {[...Array(row)]
                  .map((row, rowIndex) => (
                    <Box
                      sx={{ display: 'flex', maxHeight: '36px' }}
                      key={rowIndex}
                    >
                      <Box
                        sx={{
                          minWidth: '40px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography sx={{ color: '#EACD57' }}>
                          {rowIndex + 1}
                        </Typography>
                      </Box>
                      <Box key={rowIndex} sx={{ display: 'flex' }}>
                        {[...Array(col)].map((_, colIndex) => (
                          <Box
                            key={colIndex}
                            sx={[
                              {
                                minWidth: '36px',
                                height: '36px',
                                borderLeft: 2,
                                borderBottom: 2,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '12px',
                              },
                              colIndex === col - 1 && {
                                borderRight: 2,
                              },
                              rowIndex === Number(row) - 1 && {
                                borderTop: 2,
                              },
                            ]}
                          ></Box>
                        ))}
                      </Box>
                    </Box>
                  ))
                  .reverse()}
                <Box sx={{ pl: '40px', display: 'flex', pt: '20px' }}>
                  {[...Array(col)].map((_, colIndex) => (
                    <Box
                      sx={[
                        {
                          minWidth: '36px',
                          minHeight: '36px',
                          display: 'flex',
                          justifyContent: 'center',
                        },
                      ]}
                      key={colIndex}
                    >
                      <Typography sx={{ color: '#4339F2' }}>
                        <ListAlphabetExcelFormater angka={colIndex} />
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                mt: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 0.5,
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '4px',
                    bgcolor: color.selected,
                  }}
                ></Box>
                <Typography sx={{ fontSize: '12px' }}>Selected</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: 0.5,
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '4px',
                    bgcolor: color.contained,
                  }}
                ></Box>
                <Typography sx={{ fontSize: '12px' }}>Contained</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: 0.5,
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '4px',
                    bgcolor: color.full,
                  }}
                ></Box>
                <Typography sx={{ fontSize: '12px' }}>Full</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: 0.5,
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '4px',
                    bgcolor: color.empty,
                    border: 1,
                    borderColor: 'black',
                  }}
                ></Box>
                <Typography sx={{ fontSize: '12px' }}>Empty</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default Racks;
