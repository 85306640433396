import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { useRef, useState } from 'react';
import {
  ArrowBackIosIcon,
  ArrowForwardIosIcon,
  ArrowRightAltIcon,
  LocalPrintshopRoundedIcon,
  SearchIcon,
  SyncAltIcon,
} from '../../../../../../assets/Icons';
import CustomButton from '../../../../../../components/shared/CustomButton';
import ReactToPrint from 'react-to-print';
import ComponentToPrint from './ComponentToPrint';
import { getAllQrCode } from '../../../../../../api/qrcode';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAllPurchaseRouting } from '../../../../../../store/Routing/purchaseRouting';
import dayjs from 'dayjs';

const CustomTableCells = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '0.75rem',
  textTransform: 'capitalize',
});

const TablePurchaseQrCode = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [search, setSearch] = useState('');
  const [searchFix, setSearchFix] = useState('');
  const { idPurchase: id } = useSelector(getAllPurchaseRouting);

  const [sortBy, setSortBy] = useState('product');
  const [sortByTempo, setSortByTempo] = useState('product');
  const [isAsc, setIsAsc] = useState(true);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalData, setTotalData] = useState(34);

  const pageSizes = [5, 10, 15, 20, 25];

  const [datas, setDatas] = useState([]);

  const theme = useTheme();

  const [lastPage, setLastpage] = useState(0);
  const [isLoadingPrint, setIsLoadingPrint] = useState(false);
  const [isErrorPrint, setIsErrorPrint] = useState(false);
  const [datasForPrint, setDatasForPrint] = useState([]);

  const fetchTotalPrint = async (id) => {
    setIsLoadingPrint(true);
    const params = {
      page: 1,
      pageSize: totalData,
    };
    try {
      const {
        data: {
          data: { purchases },
        },
      } = await getAllQrCode(params, id);

      setDatasForPrint(purchases);
    } catch (error) {
      console.error(error);
      setIsErrorPrint(true);
    } finally {
      setIsLoadingPrint(false);
    }
  };

  const fetchApi = async (id, page, pageSize, sortBy, isAsc, search) => {
    setIsLoading(true);
    const params = {
      page: page,
      pageSize: pageSize,
      search: search,
    };
    params[sortBy] = isAsc ? 'asc' : 'desc';
    try {
      const {
        data: {
          data: {
            pageInfo: { total, lastPage },
            purchases,
          },
        },
      } = await getAllQrCode(params, id);

      setTotalData(total);
      setDatas(purchases);
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApi(id, page, pageSize, sortBy, isAsc, searchFix);
  }, [id, page, pageSize, sortBy, isAsc, searchFix]);

  useEffect(() => {
    if (datas.length !== 0) {
      fetchTotalPrint(id);
    }
  }, [id, datas]);

  const debounceSearch = useRef(
    debounce((value) => {
      setSearchFix(value);
    }, 1000)
  ).current;

  const handleSort = (value) => {
    if (value === sortByTempo) {
      setIsAsc(!isAsc);
    } else {
      setSortBy(value);
      setSortByTempo(value);
      setIsAsc(true);
    }
  };

  const handleChangePageSize = (e) => {
    setPageSize(Number(e.target.value));
  };

  const handleBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const handleNext = () => {
    if (page < Math.ceil(totalData / pageSize)) {
      setPage(page + 1);
    }
  };
  const handleInput = (e) => {
    let search = Number(e.target.value);
    if (search > 1 && search < Math.ceil(totalData / pageSize)) {
      setPage(search);
    }
  };

  const componentRef = useRef();

  return (
    <Box>
      <Grid container columnSpacing={4} alignItems="center">
        <Grid item xs="auto">
          <Typography
            sx={{ color: '#000000', fontWeight: 600, fontSize: '1.25rem' }}
          >
            QR Code List
          </Typography>
        </Grid>
        <Grid item xs>
          <TextField
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              debounceSearch(e.target.value);
            }}
            placeholder={'Search'}
            size="small"
            sx={{
              bgcolor: '#FAFAFA',
              width: '250px',
              '& .MuiOutlinedInput-root': {
                paddingLeft: '6px',
                fontSize: '0.875rem',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ width: '18px' }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs="auto">
          <ReactToPrint
            trigger={() => (
              <CustomButton
                variant="contained"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 0.1,
                }}
              >
                <LocalPrintshopRoundedIcon sx={{ color: 'whtie' }} />
                <Typography sx={{ color: 'whtie' }}>Print</Typography>
              </CustomButton>
            )}
            content={() => componentRef.current}
          />
          <Box sx={{ display: 'none' }}>
            <ComponentToPrint ref={componentRef} data={datasForPrint} />
          </Box>
          {/* <CustomButton variant="contained" onClick={handlePrint}>
            Print
          </CustomButton> */}
        </Grid>
      </Grid>
      {/* //! -------------------------- TABLE ------------------------------ */}
      <Table sx={{ mt: '8px' }}>
        <TableHead>
          <TableRow sx={{ bgcolor: '#FAFAFA' }}>
            <CustomTableCells sx={{ width: '32px', maxWidth: '32px' }}>
              No.
            </CustomTableCells>
            <CustomTableCells sx={[]}>
              <Box
                sx={[
                  {
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'start',
                    gap: 0.2,
                  },
                  {
                    cursor: 'pointer',
                  },
                ]}
                onClick={() => handleSort('product')}
              >
                Product
                {sortBy !== 'product' && (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
                {sortBy === 'product' && isAsc && (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                )}
                {sortBy === 'product' && !isAsc && (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(270deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
            <CustomTableCells sx={[]}>
              <Box
                sx={[
                  {
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'start',
                    gap: 0.2,
                  },
                  {
                    cursor: 'pointer',
                  },
                ]}
                onClick={() => handleSort('status')}
              >
                Status
                {sortBy !== 'status' && (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
                {sortBy === 'status' && isAsc && (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                )}
                {sortBy === 'status' && !isAsc && (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(270deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
            <CustomTableCells sx={[]}>
              <Box
                sx={[
                  {
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'start',
                    gap: 0.2,
                  },
                  {
                    cursor: 'pointer',
                  },
                ]}
                onClick={() => handleSort('datetime')}
              >
                Datetime
                {sortBy !== 'datetime' && (
                  <SyncAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: '#9E9D9D',
                    }}
                  />
                )}
                {sortBy === 'datetime' && isAsc && (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(90deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                )}
                {sortBy === 'datetime' && !isAsc && (
                  <ArrowRightAltIcon
                    sx={{
                      fontSize: '12px',
                      transform: 'rotate(270deg)',
                      color: theme.palette.primary.main,
                    }}
                  />
                )}
              </Box>
            </CustomTableCells>
          </TableRow>
        </TableHead>
        {!isLoading && !isError && (
          <TableBody>
            {datas.map((list, index) => {
              return (
                <TableRow key={index} sx={{ cursor: 'pointer' }}>
                  <CustomTableCells sx={{ width: '32px', maxWidth: '32px' }}>
                    {index + 1 + (page * pageSize - pageSize)}
                  </CustomTableCells>
                  <CustomTableCells>{list?.namaProduk || '-'}</CustomTableCells>
                  <CustomTableCells>{list?.status || '-'}</CustomTableCells>
                  <CustomTableCells>
                    {list?.updatedAt
                      ? dayjs(list?.updatedAt).format('DD/MMM/YY')
                      : '-'}
                  </CustomTableCells>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
      {/* //! -------------------------- LOADING ------------------------------ */}
      {isLoading && (
        <Box
          sx={{
            height: `${73 * pageSize}px`,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {/* //! -------------------------- PAGINATION ------------------------------ */}
      <Grid
        container
        direction="row"
        coloumn={12}
        alignItems="center"
        sx={{ mt: '30px' }}
      >
        <Grid item xs={6} sx={{ fontSize: '14px', fontWeight: '500' }}>
          Showing {page * pageSize - (pageSize - 1)} to{' '}
          {page * pageSize >= totalData ? totalData : page * pageSize} of{' '}
          {totalData} entries
        </Grid>
        <Grid
          container
          item
          direction="row"
          xs={6}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item sx={{ fontSize: '14px', fontWeight: '500' }}>
            Rows Per Page :
            <Select
              value={pageSize}
              variant="filled"
              onChange={(e) => handleChangePageSize(e)}
              sx={{
                fontSize: '14px',
                lineHeight: '-1px',
                '.MuiFilledInput-input': {
                  paddingTop: '7.5px',
                  paddingBottom: '7.5px',
                  paddingLeft: '10px',
                  paddingRight: '0',
                  borderRadius: '5px',
                },
                '&:before': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                '&:after': {
                  borderRadius: '5px',
                  borderBottom: 0,
                },
                borderRadius: '5px',
                marginLeft: '8px',
              }}
            >
              {pageSizes.map((pageSize, index) => (
                <MenuItem value={pageSize} key={index}>
                  {pageSize}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item sx={{ ml: '35px' }}>
            <Box sx={{ display: 'inline', mr: '10px' }}>
              <IconButton sx={{ p: 0 }} onClick={handleBack}>
                <ArrowBackIosIcon sx={{ color: '#323232', width: '16px' }} />
              </IconButton>
            </Box>
            <Box sx={{ display: 'inline', mr: '20px' }}>
              <OutlinedInput
                variant="outlined"
                type="number"
                value={page}
                onChange={handleInput}
                sx={{
                  border: '1px solid #9E9D9D',
                  width: '36px',
                  borderRadius: '5px',
                  textAlign: 'center',
                  '& .MuiOutlinedInput-input': {
                    padding: '5px 8px 5px 8px',
                    fontSize: '0.875rem',
                    boxShadow: 'none',
                    textAlign: 'center',
                    fontWeight: 600,
                    width: '25px',
                  },
                  '&:before': {
                    borderRadius: '5px',
                    borderBottom: 0,
                  },
                  '&:after': {
                    borderRadius: '5px',
                    borderBottom: 0,
                  },
                  '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      boxShadow: 'none',
                    },
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'inline',
                fontWeight: '600',
                fontSize: '14px',
                color: '#9E9D9D',
              }}
            >
              of {Math.ceil(totalData / pageSize)}
            </Box>
            <Box sx={{ display: 'inline', ml: '10px' }}>
              <IconButton sx={{ p: 0 }} onClick={handleNext}>
                <ArrowForwardIosIcon sx={{ color: '#323232', width: '16px' }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default TablePurchaseQrCode;
