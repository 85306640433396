import { createSlice } from '@reduxjs/toolkit';
import { getAllQrCode } from '../../../api/qrcode';

export const acceptDdataTransacitonState = createSlice({
  name: 'acceptDataTransaction',
  initialState: {
    rackId: null,
    dataRackDetail: null,
    scanned: [],
    idScanned: [],
    isExist: false,
    isLoading: false,
    totalData: 0,
    allData: [],
    allDataWithQr: [],
    noqrDataSelect: [],
    detail: {},
    pemberi: '',
    noqr: [],
    noqrid: [],
    lokasiPemberi: '',
    isSelect: false,
    status: false,
    rackIdNoQr: '',
  },
  reducers: {
    loadNoqrAcceptTransaction: (state, action) => {
      const { noqrDataSelect, url, rackId } = action.payload;
      if (
        !state.noqrid.includes(noqrDataSelect.map((item, idx) => item.idProduk))
      ) {
        state.noqrid = [
          ...state.noqrid,
          {
            id_rack: rackId,
            photo_url: url,
            id_detail: noqrDataSelect.map((item) => item.iddetailSti),
          },
        ];
        state.noqr = [...state.noqr, noqrDataSelect];
        state.noqrDataSelect = [];
        state.rackIdNoQr = rackId;
        state.status = true;
      } else {
        state.status = false;
      }
    },
    changeScanRackIdAdmin: (state, action) => {
      state.rackId = action.payload;
    },
    loadSpesifikDataQrRack: (state, action) => {
      state.dataRackDetail = action.payload;
    },
    // scanDataAcceptTransaction: (state, action) => {
    //   const data = action.payload;
    //   if (!state.idScanned.includes(data.encript)) {
    //     state.idScanned = [...state.idScanned, data.encript];
    //     state.scanned = [...state.scanned, data];
    //     state.isExist = false;
    //   } else {
    //     state.isExist = true;
    //   }
    // },

    scanDataAcceptTransaction: (state, action) => {
      const data = action.payload;
      if (!state.idScanned.includes(data.encript)) {
        state.idScanned = [
          ...state.idScanned,
          { idProduk: data.idProduk, encript: data.encript },
        ];
        state.scanned = [...state.scanned, data];
        state.isExist = false;
      } else {
        state.isExist = true;
      }
    },
    changeLoadingDataTransaction: (state, action) => {
      state.isLoading = action.payload;
    },
    setTotalDataDataTransaction: (state, action) => {
      state.totalData = action.payload;
    },
    setAllDataAcceptTransaction: (state, action) => {
      state.allData = action.payload;
    },
    setAllDataAcceptTransactionWithQr: (state, action) => {
      const datas = action.payload;
      const seenData = new Set();

      const uniqueData = [];

      datas.forEach((item) => {
        const key = `${item.id}-${item.name}`;

        if (!seenData.has(key)) {
          seenData.add(key);
          uniqueData.push(item);
        }
      });
      state.allDataWithQr = uniqueData;
    },
    setDetailTransaction: (state, action) => {
      state.detail = action.payload;
    },
    changePemberiAccept: (state, action) => {
      state.pemberi = action.payload;
    },
    changeLokasiPemberiAccept: (state, action) => {
      state.lokasiPemberi = action.payload;
    },

    changeNoqrDataSelectAcceptTransaction: (state, action) => {
      const { item, id } = action.payload;

      if (
        state.noqrDataSelect.filter((val) => val.idProduk === id).length === 0
      ) {
        state.noqrDataSelect = [...state.noqrDataSelect, item];
      } else {
        state.noqrDataSelect = state.noqrDataSelect.filter(
          (val) => val.idProduk !== id
        );
      }
    },
    changeIsSelectAcceptTransaction: (state, action) => {
      state.isSelect = action.payload;
    },
  },
});

export const {
  loadNoqrAcceptTransaction,
  changeScanRackIdAdmin,
  loadSpesifikDataQrRack,
  scanDataAcceptTransaction,
  changeLoadingDataTransaction,
  setTotalDataDataTransaction,
  setAllDataAcceptTransaction,
  setAllDataAcceptTransactionWithQr,
  setDetailTransaction,
  changePemberiAccept,
  changeLokasiPemberiAccept,
  changeNoqrDataSelectAcceptTransaction,
  changeIsSelectAcceptTransaction,
} = acceptDdataTransacitonState.actions;

export const getAllDataAcceptTransactionScan = (state) =>
  state.acceptDataTransaction;

export default acceptDdataTransacitonState.reducer;
