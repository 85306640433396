import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Switch,
  Dialog,
  styled,
  DialogTitle,
  DialogContent,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import CustomInput from "../../../../../../components/shared/CustomInput";
import BackButton from "../../../../../../components/shared/BackButton";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect, useState } from "react";
import CustomDialog from "../../../../../../components/shared/CustomDialog";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import CustomCard from "../../../../../../components/shared/CustomCard";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import useGetDifferent2Arrays from "../../../../../../hooks/useGetDifferent2Arrays";
import CustomButton from "../../../../../../components/shared/CustomButton";
import CustomTextFormatingInput from "../../../../../../components/shared/CustomTextFormatingInput/CustomTextFormatingInput";
import CustomSelect from "../../../../../../components/shared/CustomSelect";
import {
  allTypeCategoryProduct,
  deleteCategoryProduct,
  updateCategoryProduct,
} from "../../../../../../api/products";
import CategoryProductEditMobile from "./CategoryProductEditMobile";
import { uploadImage } from "../../../../../../api/storage";
import CustomDeleteConfirm from "../../../../../../components/shared/CustomDeleteConfirm";
import CustomEditConfirm from "../../../../../../components/shared/CustomEditConfirm";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const CategoryProductEdit = (props) => {
  const {
    page,
    setPage,
    roadHirarki,
    setRoadHirarki,
    // *------
    isCategoryProductAdd,
    setIsCategoryProductAdd,
    isCategoryProductEdit,
    setIsCategoryProductEdit,
    isCategoryProductDetails,
    setIsCategoryProductDetails,
    // *-------
    // categoryEditInputDatas,
    // setCategoryEditInputDatas,
    // *----
    isLoadingDataDetails,
    detailsDatas,
    setDetailsDatas,
    // *------
    isLoadingSubsidiary,
    subsidiaryDatas,
    categoryEditInputDatas,
    setCategoryEditInputDatas,
    // *----- notif ---------
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
  } = props;

  const theme = useTheme();
  const today = dayjs(new Date());
  // !------------------------- SAVE VALIDATION ----------------------------
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);

  useEffect(() => {
    if (categoryEditInputDatas.name.length >= 5) {
      if (
        categoryEditInputDatas.description === "" ||
        categoryEditInputDatas.description.length >= 20
      ) {
        return setIsSubmitAvailable(true);
      } else {
        return setIsSubmitAvailable(false);
      }
    } else {
      return setIsSubmitAvailable(false);
    }
  }, [categoryEditInputDatas.name, categoryEditInputDatas.description]);

  // !------------------------- Type --------------------------
  const [typeCategoryOptions, setTypeCategoryOptions] = useState([]);

  useEffect(() => {
    const getAllTypeCategory = async () => {
      setCategoryEditInputDatas({
        ...categoryEditInputDatas,
        typeLoading: true,
      });
      let datas = {};
      datas.pageSize = 100;
      datas.page = 1;

      try {
        const {
          data: {
            data: { categoryOptions: dataType },
          },
        } = await allTypeCategoryProduct(datas);

        setTypeCategoryOptions(dataType);
      } catch (err) {
        console.error();
        setTypeCategoryOptions([]);
      } finally {
        setCategoryEditInputDatas({
          ...categoryEditInputDatas,
          typeLoading: false,
        });
      }
    };

    getAllTypeCategory();
  }, []);
  // !------------------- Confirmation -------------------
  const [isEditConfirm, setIsEditConfirm] = useState(false);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  // !------------------- responsive -------------------
  const [desktopMode, setDesktopMode] = useState(true);

  useEffect(() => {
    if (window.screen.width <= 830) {
      setDesktopMode(false);
    } else {
      setDesktopMode(true);
    }
  }, [window.screen.width]);

  const handleDelete = () => {
    setIsDeleteConfirm(true);
  };

  const handleDeleteFix = () => {
    deleteCategoryProduct(categoryEditInputDatas.categoryId)
      .then((result) => {
        setIsDeleteConfirm(false);
        setIsCategoryProductDetails(false);
        setIsCategoryProductEdit(false);
        setNotifTitle(
          `${result.data.data.namaKategori} has been successfully deleted`
        );
        setNotif("deletecategory");
        setPage("category");
        setRoadHirarki({
          subsidiary: "",
          subsidiaryId: 0,
          category: "",
          categoryId: 0,
          subCategory: "",
          subCategoryId: 0,
          product: "",
          productId: 0,
          miniSubCategory: "",
          miniSubCategoryId: 0,
        });
      })
      .catch((err) => console.error(err));
  };

  const handleEditCategory = () => {
    setIsEditConfirm(!isEditConfirm);
  };

  const handleEditCategoryFix = () => {
    let data = {
      namaKategori: categoryEditInputDatas.name,
      status: "Y",
      subsidiaryId: categoryEditInputDatas.subsidiaryId,
      schedule: dayjs(categoryEditInputDatas.schedule.$d).format("YYYY-MM-DD"),
      opsiKategori: 1,
    };
    if (categoryEditInputDatas.description !== "") {
      data["des"] = categoryEditInputDatas.description;
    }
    if (categoryEditInputDatas.photoUrls.length !== 0) {
      data["gambar"] = categoryEditInputDatas.photoUrls.join(",");
    }

    updateCategoryProduct(roadHirarki.categoryId, data)
      .then((result) => {
        setRoadHirarki({
          ...roadHirarki,
          category: result.data.data.namaKategori,
          categoryId: result.data.data.idKategori,
          subsidiary: result.data.data.subsidiary.name,
          subsidiaryId: result.data.data.subsidiary.id,
        });
        setIsCategoryProductEdit(false);
        setIsCategoryProductDetails(true);
        setNotif("editcategory");
        setNotifTitle(
          `${result.data.data.namaKategori} has been successfully edited`
        );
      })
      .catch((err) => console.error(err));
  };

  const handleBackButton = () => {
    setIsCategoryProductEdit(false);
    setIsCategoryProductDetails(true);
  };

  const handleAddImage = (e) => {
    if (e.target.files[0].size > 2000000) {
      setCategoryEditInputDatas({
        ...categoryEditInputDatas,
        isMaxSizePhoto: true,
      });
    } else {
      let datas = {
        image: e.target.files[0],
        resource: "category",
      };
      uploadImage(datas).then((res) => {
        setCategoryEditInputDatas({
          ...categoryEditInputDatas,
          photoUrls: [...categoryEditInputDatas.photoUrls, res.data.data.path],
          isMaxSizePhoto: false,
        });
      });
    }
  };

  const handleDeleteImage = (link) => {
    setCategoryEditInputDatas({
      ...categoryEditInputDatas,
      photoUrls: categoryEditInputDatas.photoUrls.filter(
        (data) => data !== link
      ),
      indexPreviePhoto: 0,
    });
  };

  const handlePreviewImage = (link) => {
    setCategoryEditInputDatas({
      ...categoryEditInputDatas,
      linkPreviewPhoto: link,
      isPreviewPhoto: "true",
    });
  };

  return (
    <>
      <CustomEditConfirm
        open={isEditConfirm}
        onClose={() => setIsEditConfirm(false)}
        handleClickCancel={() => setIsEditConfirm(false)}
        handleClickEdit={handleEditCategoryFix}
        title="Edit Category"
        body={
          <Box sx={{ textAlign: "center", mb: "40px", mt: "8px" }}>
            <Typography>Are you sure you want to save the changes?</Typography>
          </Box>
        }
      />
      <CustomDeleteConfirm
        open={isDeleteConfirm}
        onClose={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickCancel={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Category"
        body={
          <Box sx={{ textAlign: "center" }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: "600" }}>
                {detailsDatas.namaKategori}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: "52px" }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />
      {desktopMode ? (
        <Grid container columns={12} spacing={4} sx={{ pb: "56px" }}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <BackButton handleClick={handleBackButton} />
            <Box>
              <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
                Edit Category
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{ mb: "16px", fontSize: "16px", fontWeight: "500" }}
              >
                Subsidiary
              </Typography>
              <Typography sx={{ color: "red" }}>*</Typography>
            </Box>

            <FormControl>
              <RadioGroup
                row
                value={categoryEditInputDatas.subsidiaryId}
                onChange={(e) => {
                  setCategoryEditInputDatas({
                    ...categoryEditInputDatas,
                    subsidiaryId: e.target.value,
                  });
                }}
              >
                {!isLoadingSubsidiary && (
                  <>
                    {subsidiaryDatas.map((data, index) => (
                      <FormControlLabel
                        value={data.id}
                        control={<Radio />}
                        label={data.name}
                        key={index}
                      />
                    ))}
                  </>
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
          {/* Name */}
          <Grid item xs={6}>
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{ mb: "16px", fontSize: "16px", fontWeight: "500" }}
              >
                Name
              </Typography>
              <Typography sx={{ color: "red" }}>*</Typography>
            </Box>
            <CustomInput
              sx={{ width: "100%", bgcolor: "#FAFAFA" }}
              placeholder="Enter category name"
              value={categoryEditInputDatas.name}
              onChange={(e) => {
                if (e.target.value.length <= 30) {
                  setCategoryEditInputDatas({
                    ...categoryEditInputDatas,
                    name: e.target.value,
                  });
                }
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#9E9D9D",
                  mt: "4px",
                }}
              >
                Enter at least 5 character
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#9E9D9D",
                  mt: "4px",
                }}
              >
                {categoryEditInputDatas.name.length}/30
              </Typography>
            </Box>
          </Grid>
          {/* photo */}
          <Grid item xs={6} sx={{ minHeight: "100%" }}>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                pb: `${
                  categoryEditInputDatas.photo.length === 0 ? "16px" : "9px"
                }`,
              }}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                Photo
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#9E9D9D",
                }}
              >
                ( max size : 2MB )
              </Typography>
            </Box>
            {categoryEditInputDatas.isMaxSizePhoto && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ErrorOutlineIcon
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: theme.palette.warning.main,
                    mr: "4px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: theme.palette.warning.main,
                  }}
                >
                  The photo can't be more than 2MB
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {categoryEditInputDatas.photoUrls.length === 0 && (
                  <Box
                    sx={{
                      height: "54px",
                      width: "54px",
                      aspectRatio: "1/1",
                      borderRadius: "8px",
                      bgcolor: "#F5F5F5",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderStyle: "dashed",
                      borderColor: "#9E9D9D",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      document.getElementById("input_img").click();
                    }}
                  >
                    <ImageOutlinedIcon
                      sx={{ color: "#9E9D9D", fontSize: "28px" }}
                    />
                  </Box>
                )}
                {categoryEditInputDatas.photoUrls.map((input, index) => (
                  <Box
                    sx={{
                      position: "relative",
                      overflow: "visible",
                      padding: "7px",
                    }}
                    key={index}
                  >
                    <CancelIcon
                      sx={{
                        position: "absolute",
                        fontSize: "20px",
                        top: 1,
                        right: 1,
                        zIndex: +1,
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteImage(input)}
                    />
                    <Box
                      component="img"
                      src={
                        process.env.REACT_APP_API_IMAGE_BASE_URL + "/" + input
                      }
                      sx={{
                        height: "54px",
                        width: "54px",
                        aspectRatio: "1/1",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => handlePreviewImage(input)}
                    />
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  mb: "7px",
                  ml: "20px",
                  alignItems: "center",
                }}
              >
                {categoryEditInputDatas.photoUrls.length < 3 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      document.getElementById("input_img").click();
                    }}
                  >
                    <input
                      accept="image/*"
                      id="input_img"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleAddImage}
                    />
                    <AddIcon />
                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                      Add More
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          {/* dialog photo */}
          {categoryEditInputDatas.linkPreviewPhoto !== "" && (
            <Dialog
              open={categoryEditInputDatas.isPreviewPhoto}
              onClose={() => {
                setCategoryEditInputDatas({
                  ...categoryEditInputDatas,
                  isPreviewPhoto: !categoryEditInputDatas.isPreviewPhoto,
                });
              }}
            >
              <Box
                component="img"
                src={
                  process.env.REACT_APP_API_IMAGE_BASE_URL +
                  "/" +
                  categoryEditInputDatas.linkPreviewPhoto
                }
                sx={{ width: "600px", height: "600px", aspectRation: "1/1" }}
              />
            </Dialog>
          )}

          {/* Type */}
          {/* <Grid item xs={6}>
            <Typography
              sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}
            >
              Type
            </Typography>

            <Autocomplete
              options={typeCategoryOptions}
              getOptionLabel={(option) => option.nmOpsi}
              inputValue={categoryEditInputDatas.typeInput}
              onInputChange={(_, newValue) => {
                setCategoryEditInputDatas({
                  ...categoryEditInputDatas,
                  typeInput: newValue,
                });
              }}
              value={categoryEditInputDatas.type}
              onChange={(_, newValue) => {
                setCategoryEditInputDatas({
                  ...categoryEditInputDatas,
                  type: newValue,
                  typeId: newValue.iDopsi,
                  typeName: newValue.nmOpsi,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ bgcolor: '#FAFAFA' }}
                  placeholder="Select Type"
                />
              )}
              loading={categoryEditInputDatas.typeLoading}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 20 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {categoryEditInputDatas.description.length}/250
              </Typography>
            </Box>
          </Grid> */}
          {/* Description */}
          <Grid item xs={6}>
            <Typography
              sx={{ mb: "16px", fontSize: "16px", fontWeight: "500" }}
            >
              Description
            </Typography>
            <CustomInput
              sx={{ width: "100%", bgcolor: "#FAFAFA" }}
              placeholder="Enter description"
              value={categoryEditInputDatas.description}
              onChange={(e) => {
                if (e.target.value.length <= 250) {
                  setCategoryEditInputDatas({
                    ...categoryEditInputDatas,
                    description: e.target.value,
                  });
                }
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#9E9D9D",
                  mt: "4px",
                }}
              >
                Enter at least 20 character
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#9E9D9D",
                  mt: "4px",
                }}
              >
                {categoryEditInputDatas.description.length}/250
              </Typography>
            </Box>
          </Grid>
          {/* Schedule */}
          <Grid item xs={6} sx={{}}>
            <Grid container>
              <Grid
                item
                alignItems="center"
                sx={{ height: "100%", mr: "20px" }}
              >
                <Typography
                  sx={{
                    mb: "16px",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Schedule
                </Typography>
              </Grid>
              <Grid item alignItems="center" sx={{ height: "100%" }}>
                <RadioGroup
                  row
                  value={categoryEditInputDatas.scheduleType}
                  onChange={(e) => {
                    setCategoryEditInputDatas({
                      ...categoryEditInputDatas,
                      schedule: today,
                      scheduleType: e.target.value,
                    });
                  }}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "16px",
                    },
                    ".MuiRadio-root": {
                      padding: "0 8px",
                    },
                    ".MuiTypography-root": {
                      fontSize: "12px",
                    },
                  }}
                >
                  <FormControlLabel
                    value="now"
                    control={<Radio />}
                    label="Now"
                  />
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="Custom"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", minWidth: "100%" }}>
              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                value={categoryEditInputDatas.schedule}
                onChange={(newValue) => {
                  if (categoryEditInputDatas.scheduleType !== "now") {
                    setCategoryEditInputDatas({
                      ...categoryEditInputDatas,
                      schedule: newValue,
                    });
                  } else {
                    setCategoryEditInputDatas({
                      ...categoryEditInputDatas,
                      schedule: today,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%", bgcolor: "#FAFAFA" }}
                  />
                )}
                disabled={
                  categoryEditInputDatas.scheduleType === "now" ? true : false
                }
              />
            </Box>
          </Grid>

          {/* Footer */}
          <Box
            sx={{
              width: "100%",
              bgcolor: "white",
              position: "fixed",
              bottom: "0px",
              right: "0px",
              display: "flex",
              justifyContent: "space-between",
              padding: "18px 32px 18px 240px",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                Publish
              </Typography>
              <AntSwitch
                checked={categoryEditInputDatas.publish}
                onChange={(e) => {
                  setCategoryEditInputDatas({
                    ...categoryEditInputDatas,
                    publish: !categoryEditInputDatas.publish,
                  });
                }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <CustomButton
                variant="contained"
                // disabled={!isSubmitAvailable}
                onClick={handleDelete}
                color="error"
                sx={{ width: "140px", height: "36px" }}
              >
                <Typography
                  sx={{ color: "white", fontSize: "12px", fontWeight: "500" }}
                >
                  Delete
                </Typography>
              </CustomButton>
              <CustomButton
                variant="contained"
                disabled={!isSubmitAvailable}
                onClick={handleEditCategory}
                sx={{ width: "140px", height: "36px" }}
              >
                <Typography
                  sx={{ color: "white", fontSize: "12px", fontWeight: "500" }}
                >
                  Save
                </Typography>
              </CustomButton>
            </Box>
          </Box>
        </Grid>
      ) : (
        <CategoryProductEditMobile
          {...{
            handleBackButton,
            subsidiaryDatas,
            categoryEditInputDatas,
            setCategoryEditInputDatas,
            theme,
            AntSwitch,
            today,
            handleDelete,
            handleEditCategory,
            handleAddImage,
            handleDeleteImage,
            handlePreviewImage,
            isSubmitAvailable,
          }}
          {...{
            typeCategoryOptions,
          }}
        />
      )}
    </>
  );
};
export default CategoryProductEdit;
