import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import NotifSupplier from "./NotifSupplier";
import InventorySupplier from "..";
import { getAllSupplierRouting } from "../../../../../../store/Routing/supplierRouting";
import AddSupplier from "../Add";
import DetailSupplier from "../Detail";

const RoutingSupplier = () => {
  const { page } = useSelector(getAllSupplierRouting);

  return (
    <Box>
      <Box>
        <NotifSupplier />
      </Box>
      {page === "" && <InventorySupplier />}
      {page === "addSupplier" && <AddSupplier />}
      {page === "detailSupplier" && <DetailSupplier />}
    </Box>
  );
};
export default RoutingSupplier;
