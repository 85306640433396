import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import BackButtonHub from "../../../../../../../components/shared/BackButtonHub";
import { useDispatch, useSelector } from "react-redux";
import {
  changePageProductSupplier,
  setCloseNotifProductSupplier,
} from "../../../../../../../store/Routing/productSupplierRouting";
import CustomInput from "../../../../../../../components/shared/CustomInput";
import {
  changeIsLoading,
  changeStatusEdit,
  clearDataEdit,
  editDataEditProduk,
  getDataEditProduk,
} from "../../../../../../../store/productSupplier/editProductSupplier";
import CustomAntSwitch from "../../../../../../../components/shared/CustomAntSwitch";
import CustomButton from "../../../../../../../components/shared/CustomButton";
import {
  DeleteProdukSupplier,
  geteditProdukSupplier,
} from "../../../../../../../api/Supplier";
import { fetchDetailProdukSupplier } from "../../../../../../../store/productSupplier/detailProductSupplier";
import { useEffect, useState } from "react";
import CustomDeleteConfirm from "../../../../../../../components/shared/CustomDeleteConfirm";

const EditProduk = () => {
  const dispatch = useDispatch();

  const { datas, status, isLoading, idProduk } = useSelector(getDataEditProduk);

  const [deletConfirm, setDeletConfirm] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleEdit = () => {
    const { id, detail } = datas;
    const params = {
      status_publish: status ? 1 : 0,
      detail: detail,
    };
    const updatePrice = async () => {
      dispatch(changeIsLoading(true));
      try {
        const {
          data: { data },
        } = await geteditProdukSupplier(id, params);
        dispatch(clearDataEdit());
        dispatch(changeIsLoading(false));
        dispatch(changePageProductSupplier(""));
      } catch (error) {
        console.log(error);
      }
    };
    updatePrice();
  };

  const handleDelete = () => {
    setDeletConfirm(true);
  };

  const handleDeleteFix = async () => {
    setLoadingDelete(true);
    try {
      await DeleteProdukSupplier(idProduk);
      dispatch(changePageProductSupplier(""));
      setLoadingDelete(false);
      dispatch(
        setCloseNotifProductSupplier({
          key: "deleteProdukSupplier",
          text: "product has been deleted",
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    dispatch(fetchDetailProdukSupplier(idProduk));
  }, [idProduk]);

  return (
    <Box>
      <Box sx={{ minHeight: "80vh" }}>
        <Grid container columns={12} spacing={2}>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <BackButtonHub
                onClick={() =>
                  dispatch(changePageProductSupplier("detailProduk"))
                }
              />
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                Edit Product
              </Typography>
            </Box>
          </Grid>

          {/* name Product */}
          <Grid item xs={12}>
            <Box
              sx={{
                bgcolor: "#FAFAFA",
                height: "60px",
                borderRadius: "8px 8px 0px 0px",
                p: "12px",
              }}
            >
              <Typography
                sx={{ fontSize: "12px", color: "#9E9E9E", mb: "5px" }}
              >
                Product
              </Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                {datas?.namaProduk}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            {datas?.detail?.map((item, idx) => (
              <Box key={idx} sx={{ mb: 3 }}>
                <CustomInput
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <Box>
                        <Typography
                          sx={{
                            marginLeft: "12px",
                            pb: "8px",
                            color: "#9E9E9E",
                            fontSize: "12px",
                          }}
                        >
                          Price
                        </Typography>
                        <InputAdornment
                          position="start"
                          sx={{ marginLeft: "12px" }}
                        >
                          <Typography sx={{ fontSize: "12px" }}>Rp</Typography>
                        </InputAdornment>
                      </Box>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        <Box
                          sx={{
                            padding: "12px 8px",
                            borderRadius: "8px",
                            minWidth: "100px",
                            textAlign: "center",
                            bgcolor: "white",
                          }}
                        >
                          <Typography sx={{ color: "#000" }}>
                            {item?.name}
                          </Typography>
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "100%",
                    bgcolor: "#fafafa",
                    "& .MuiOutlinedInput-input": {
                      padding: "17px 16px",
                      borderRadius: "10px",
                      "&::placeholder": {
                        color: "#111",
                        fontSize: "16px",
                      },
                    },
                    borderRadius: "5px",
                  }}
                  value={item?.price}
                  onChange={(e) => {
                    const newDetails = datas?.detail?.map((detail, detailIdx) =>
                      detailIdx === idx
                        ? { ...detail, price: e.target.value }
                        : detail
                    );
                    dispatch(editDataEditProduk(newDetails));
                  }}
                />
              </Box>
            ))}
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>Publish</Typography>
              <CustomAntSwitch
                checked={status}
                onChange={() => dispatch(changeStatusEdit(!status))}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Bottom */}
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
        <CustomButton
          variant="contained"
          color="error"
          sx={{ height: "36px", width: "50%" }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "12px",
              fontWeight: "500",
            }}
            onClick={handleDelete}
          >
            Delete
          </Typography>
        </CustomButton>

        <CustomButton
          variant="contained"
          sx={{ height: "36px", width: "50%" }}
          onClick={handleEdit}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            {isLoading ? (
              <CircularProgress size={18} sx={{ color: "#FFF" }} />
            ) : (
              "Save"
            )}
          </Typography>
        </CustomButton>
      </Box>

      {/* dialog Delete */}
      <CustomDeleteConfirm
        isLoading={loadingDelete}
        open={deletConfirm}
        onClose={() => setDeletConfirm(!deletConfirm)}
        handleClickCancel={() => setDeletConfirm(!deletConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Produk"
        body={
          <Box sx={{ textAlign: "center" }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: 600 }}>
                {datas?.namaProduk}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: "52px" }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />
    </Box>
  );
};

export default EditProduk;
