import { createSlice } from '@reduxjs/toolkit';

export const masterHubRackState = createSlice({
  name: 'masterHubRack',
  initialState: {
    id: 0,
    cell: [],
    page: '',
    rackSelected: [],
  },
  reducers: {
    inputIdMasterRack: (state, action) => {
      state.id = Number(action.payload);
    },
    inputCellSubForRack: (state, action) => {
      const { row, col, rackSelected } = action.payload;
      let newCell = [...Array(row)].map((_, idxr) => {
        let v = [...Array(col)].map((_, idxc) => {
          return `r${idxr + 1}c${idxc + 1}`;
        });
        return v;
      });
      state.rackSelected = rackSelected;
      state.cell = newCell;
    },
    changePageMasterHubRack: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const {
  inputIdMasterRack,
  inputCellSubForRack,
  changePageMasterHubRack,
} = masterHubRackState.actions;

export const getAllMasterHubState = (state) => state.masterHubRack;

export default masterHubRackState.reducer;
