import { alereAxios } from "../utils/api";

export const getAllDataStock = (params) =>
  alereAxios.get("products/stocks", { params });

export const getStockAlert = (params) =>
  alereAxios.get("products/stocks-alert", { params });

export const getStockAlertList = (params) =>
  alereAxios.get("products/stocks-alert-list", { params });
