import { Box } from '@mui/material';

const CustomTab = ({ tabs, value, setValue, px = 1, py = 0.5, sx }) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        borderRadius: '5px',
        p: 0.5,
        bgcolor: '#F5F5F5',
        ...sx,
      }}
    >
      {tabs.map((tab) => (
        <Box
          key={tab.id}
          sx={{
            py: py,
            px: px,
            borderRadius: '5px',
            textAlign: 'center',
            fontWeight: 'semiBold',
            fontSize: '0.625rem',
            color: value === tab.id && 'primary.main',
            bgcolor: value === tab.id && '#fff',
            cursor: 'pointer',
            textTransform: 'capitalize',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => {
            setValue(tab.id);
          }}
        >
          {tab.name}
        </Box>
      ))}
    </Box>
  );
};

export default CustomTab;
