import { createSlice } from '@reduxjs/toolkit';
import { fetchAllPurchase, fetchChartPurchase } from '../../api/purchase';

export const tablePurchaseState = createSlice({
  name: 'tablePurchase',
  initialState: {
    // chart purchase

    isLoadingChart: false,
    chart: [],

    value: [],
    isLoading: false,
    currentPage: 1,
    pageSize: 5,
    totalPage: 2,
    totalItem: 0,
    statusError: 0,
    sortIn: 'date',
    sortAsc: true,
    statusFilterList: [
      'finance bid',
      'supplier bid',
      'processed',
      'sent',
      'accepted',
      'retur',
      'finish',
      'reject',
    ],
    statusFilter: [
      'finance bid',
      'supplier bid',
      'processed',
      'sent',
      'accepted',
      'finish',
      'retur',
      'reject',
    ],
    singleFilter: '',
    paymentFilter: '',
    startDateFilter: '',
    endDateFilter: '',
    statusFilterAwait: [
      'finance bid',
      'supplier bid',
      'processed',
      'sent',
      'accepted',
      'retur',
      'finish',
      'reject',
    ],
  },
  reducers: {
    loadChart: (state, action) => {
      state.chart = action.payload;
    },
    changeIsloadingChart: (state, action) => {
      state.isLoadingChart = action.payload;
    },
    loadTable: (state, action) => {
      state.value = action.payload;
    },
    loadTotalItem: (state, action) => {
      state.totalItem = action.payload;
    },
    loadStatusError: (state, action) => {
      state.statusError = action.payload;
    },
    nextPage: (state) => {
      if (state.currentPage < Math.ceil(state.totalItem / state.pageSize)) {
        state.currentPage += 1;
      }
    },
    backPage: (state) => {
      if (state.currentPage > 1) {
        state.currentPage -= 1;
      }
    },
    inputPage: (state, action) => {
      if (action.payload >= Math.ceil(state.totalItem / state.pageSize)) {
        state.currentPage = Math.ceil(state.totalItem / state.pageSize);
      } else if (action.payload <= 1) {
        state.currentPage = 1;
      } else {
        state.currentPage = action.payload;
      }
    },
    changePageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    changeSortIn: (state, action) => {
      state.sortIn = action.payload;
    },
    changeSortAsc: (state, action) => {
      state.sortAsc = action.payload;
    },
    inputStatusFilter: (state, action) => {
      if (state.statusFilter.includes(action.payload)) {
        state.statusFilter = state.statusFilter.filter(
          (item) => item !== action.payload
        );
        state.statusFilterAwait = state.statusFilter.filter(
          (item) => item !== action.payload
        );
      } else {
        state.statusFilter = [...state.statusFilter, action.payload];
        state.statusFilterAwait = [...state.statusFilter, action.payload];
      }
    },
    changeSingleFilter: (state, action) => {
      state.singleFilter = action.payload;
    },
    changePaymentFilter: (state, action) => {
      state.paymentFilter = action.payload;
    },
    changeStartDateFilter: (state, action) => {
      state.startDateFilter = action.payload;
    },
    changeEndDateFilter: (state, action) => {
      state.endDateFilter = action.payload;
    },
  },
});

export const {
  loadChart,
  changeIsloadingChart,
  loadTable,
  loadTotalItem,
  changeStatusPurchase,
  changePage,
  nextPage,
  backPage,
  inputPage,
  changePageSize,
  changeIsLoading,
  loadStatusError,
  changeSortIn,
  changeSortAsc,
  inputStatusFilter,
  changeSingleFilter,
  changePaymentFilter,
  changeStartDateFilter,
  changeEndDateFilter,
} = tablePurchaseState.actions;

export const getLoadDataChart = (state) => state.tablePurchase.chart;
export const getIsLoadingChart = (state) => state.tablePurchase.isLoadingChart;
export const getPurchaseTable = (state) => state.tablePurchase.value;
export const getStatusDashboardPurchase = (state) => state.tablePurchase.status;
export const getPurchaseTableCurentPage = (state) =>
  state.tablePurchase.currentPage;
export const getPurchaseTablePageSize = (state) => state.tablePurchase.pageSize;
export const getPurchaseTableTotalItem = (state) =>
  state.tablePurchase.totalItem;
export const getPurchaseTableIsLoading = (state) =>
  state.tablePurchase.isLoading;
export const getPurchaseTableSortIn = (state) => state.tablePurchase.sortIn;
export const getPurchaseTableSortAsc = (state) => state.tablePurchase.sortAsc;
export const getPurchaseTableStatusFilter = (state) =>
  state.tablePurchase.statusFilter;
export const getSingleFilter = (state) => state.tablePurchase.singleFilter;
export const getPaymentFilter = (state) => state.tablePurchase.paymentFilter;
export const getStartDateFilter = (state) =>
  state.tablePurchase.startDateFilter;
export const getEndDateFilter = (state) => state.tablePurchase.endDateFilter;

// !--------------- API ------------------------

export const getAllDataPurchase = (datas) => (dispatch) => {
  const fetchApi = async () => {
    dispatch(changeIsLoading(true));
    try {
      const {
        data: { data },
      } = await fetchAllPurchase(datas);
      dispatch(loadTable(data.purchases));
      dispatch(loadTotalItem(data?.pageInfo?.total));
    } catch {
      dispatch(loadTable([]));
      dispatch(changeIsLoading(false));
    } finally {
      dispatch(changeIsLoading(false));
    }
  };

  fetchApi();
};

export const getAllDataChartPurchase = (datas) => (dispatch) => {
  const fetchChart = async () => {
    dispatch(changeIsloadingChart(true));
    try {
      const {
        data: { data },
      } = await fetchChartPurchase(datas);
      dispatch(loadChart(data));
      dispatch(changeIsloadingChart(false));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsloadingChart(false));
    }
  };

  fetchChart();
};

export default tablePurchaseState.reducer;
