import {
  Box,
  Grid,
  Typography,
  Switch,
  Dialog,
  styled,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  deleteCategoryMenu,
  updateCategoryMenuStatus,
} from '../../../../../../api/menu';
import BackButton from '../../../../../../components/shared/BackButton';
import CustomDeleteConfirm from '../../../../../../components/shared/CustomDeleteConfirm';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';
import CategoryMenuDetailsDesktop from './CategoryMenuDetailsDesktop';
import CategoryMenuDetailsMobile from './CategoryMenuDetailsMobile';
import dayjs from 'dayjs';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const CategoryMenuDetails = (props) => {
  const {
    // *--- respon ---
    dummyDataTable,
    desktopMode,
    roadHirarki,
    setRoadHirarki,
    // *--- Data ---
    detailsDatas,
    isLoadingDataDetails,
    // *--- Edit ---
    categoryEditInputDatas,
    setCategoryEditInputDatas,
    // *--- Notif ---
    notif,
    notifTitle,
    setNotif,
    setNotifTitle,
    // *---------
    setPage,
    setCategoryMenu,
    // *----- Time --
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
  } = props;
  const theme = useTheme();
  const today = dayjs(new Date());

  const handleBackButton = () => {
    setCategoryMenu('');
    setRoadHirarki({
      ...roadHirarki,
      subsidiary: '',
      subsidiaryId: 0,
      category: '',
      categoryId: 0,
    });
  };

  // !----------------- STATUS ---------------
  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (!isLoadingDataDetails) {
      if (detailsDatas.status === 'Y') {
        return setStatus(true);
      } else {
        return setStatus(false);
      }
    }
  }, [isLoadingDataDetails, detailsDatas.status]);

  const handleEditStatus = () => {
    if (status) {
      updateCategoryMenuStatus(roadHirarki.categoryId, 'N');
      setStatus(false);
    } else {
      updateCategoryMenuStatus(roadHirarki.categoryId, 'Y');
      setStatus(true);
    }
  };

  // !------------------ PHOTO ----------------
  const [linkPreviewPhoto, setLinkPreviewPhoto] = useState('');
  const [isPreviewPhoto, setIsPreviewPhoto] = useState(false);

  // !---------------- Confirm ----------------
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  // !----------------- HANDLER ---------------
  const handlePreviewPhoto = (link) => {
    setLinkPreviewPhoto(link);
    setIsPreviewPhoto(true);
  };
  const handleClosePreviewPhoto = () => {
    setIsPreviewPhoto(!isPreviewPhoto);
    setLinkPreviewPhoto('');
  };
  const handleDelete = () => {
    setIsDeleteConfirm(!isDeleteConfirm);
  };
  const handleEdit = () => {
    setCategoryEditInputDatas({
      ...categoryEditInputDatas,
      subsidiaryId: parseInt(detailsDatas.subsidiaryId),
      subsidiary: {
        id: detailsDatas.subsidiaryId,
        name: detailsDatas.subsidiaryName,
      },
      type: { iDopsi: detailsDatas.opsiKategori, nmOpsi: detailsDatas.type },
      categoryId: detailsDatas.idKategori,
      name: detailsDatas.namaKategori,
      description: detailsDatas.des,
      scheduleType: 'custom',
      schedule: detailsDatas.schedule ? dayjs(detailsDatas.schedule) : today,
      photoUrls: detailsDatas.gambar.split(','),
      publish: status,
    });
    setCategoryMenu('edit');
  };
  const handleDeleteFix = () => {
    deleteCategoryMenu(roadHirarki.categoryId)
      .then((result) => {
        setNotifTitle(
          `${result.data.data.namaKategori} has been successfully deleted`
        );
        setNotif('deletecategory');
        setCategoryMenu('');
        setRoadHirarki({
          subsidiary: '',
          subsidiaryId: 0,
          category: '',
          categoryId: 0,
          subCategory: '',
          subCategoryId: 0,
          product: '',
          productId: 0,
          miniSubCategory: '',
          miniSubCategoryId: 0,
        });
      })
      .catch((err) => console.error(err));
  };
  const handleEditFIx = () => {};

  return (
    <>
      <CustomNotifSuccess
        text={notifTitle}
        open={notif === 'editcategory'}
        onClose={() => {
          setNotif('');
          setNotifTitle('');
        }}
      />
      <CustomDeleteConfirm
        open={isDeleteConfirm}
        onClose={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickCancel={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Category"
        body={
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: '600' }}>
                {roadHirarki.category}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: '52px' }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />
      {desktopMode ? (
        <CategoryMenuDetailsDesktop
          {...{ ...props }}
          {...{ AntSwitch, status }}
          {...{ linkPreviewPhoto, isPreviewPhoto }}
          {...{
            handleEditStatus,
            handleBackButton,
            handlePreviewPhoto,
            handleClosePreviewPhoto,
            handleDelete,
            handleEdit,
          }}
        />
      ) : (
        <CategoryMenuDetailsMobile
          {...{ ...props }}
          {...{ dayjs, theme }}
          {...{ AntSwitch, status }}
          {...{ linkPreviewPhoto, isPreviewPhoto }}
          {...{
            handleEditStatus,
            handleBackButton,
            handlePreviewPhoto,
            handleClosePreviewPhoto,
            handleDelete,
            handleEdit,
          }}
        />
      )}
    </>
  );
};
export default CategoryMenuDetails;
