import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  DataNotFound,
  FlashIcon,
  ScanTargetIcon,
} from '../../../../../../../../assets/Icons';
import { useDispatch, useSelector } from 'react-redux';
import useWindowDimensions from '../../../../../../../../hooks/useWindowDimensions';
import { ScanQrTool } from '../../../../../../../../components/shared/shared2';
import CustomBottomDrawer from '../../../../../../../../components/shared/CustomBottomDrawer';
import BackButtonHub from '../../../../../../../../components/shared/BackButtonHub';
import { changePageTransaction } from '../../../../../../../../store/Routing/transactionRouting';
import { useEffect } from 'react';
import { getDetailQrCodeTransaction } from '../../../../../../../../api/qrcode';
import {
  getAllDataAcceptTransactionScan,
  scanDataAcceptTransaction,
} from '../../../../../../../../store/transaction/request/acceptDataTransaction';
import { getIdDetailTransactionRequest } from '../../../../../../../../store/transaction/request/detailTransactionRequest';

const ProccessScanAccept = () => {
  const dispatch = useDispatch();
  const { width: widthScreen } = useWindowDimensions();
  const [isTorchLightSuport, setIsTorchLightSuport] = useState(true);
  const [isQrNotExist, setIsQrNotExist] = useState(false);
  const [qrValue, setQrValue] = useState('');
  const [isLoadingScan, setIsLoadingScan] = useState(false);
  const { pemberi, lokasiPemberi, isExist, scanned, idScanned, allData } =
    useSelector(getAllDataAcceptTransactionScan);
  const idTransaction = useSelector(getIdDetailTransactionRequest);

  const fetchScan = async (params) => {
    setIsLoadingScan(true);
    try {
      const {
        data: { data },
      } = await getDetailQrCodeTransaction(params);
      if (data) {
        dispatch(scanDataAcceptTransaction(data?.flat()));
        dispatch(changePageTransaction('acceptTransactionMobile'));
      } else {
        setIsQrNotExist(true);
      }
    } catch (error) {
      console.log(error);
      setIsQrNotExist(true);
    } finally {
      setIsLoadingScan(false);
      setScanRes('');
    }
  };

  const [scanRes, setScanRes] = useState('');
  const handleScan = (value) => {
    if (value) {
      setScanRes(value);
    }
  };

  useEffect(() => {
    const params = {
      qrEncriptId: scanRes,
      idOrders: idTransaction,
      status: 'accept',
    };
    if (scanRes) {
      fetchScan(params);
    }
  }, [scanRes, idTransaction]);

  return (
    <Box sx={{}}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', p: 2 }}>
        <BackButtonHub
          onClick={() => {
            dispatch(changePageTransaction('acceptTransactionMobile'));
          }}
        />
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Scan</Typography>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          minHeight: '80vh',
          width: '100%',
          left: 0,
          bgcolor: 'black',
        }}
      >
        <Box sx={{ width: '100%', position: 'realtive' }}>
          {isLoadingScan && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                zIndex: 10,
                width: widthScreen,
                height: '600px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'white',
                opacity: 0.5,
              }}
            >
              {/* <LoadingComponent /> */}
              <CircularProgress />
            </Box>
          )}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 5,
              width: widthScreen,
              height: '600px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ScanTargetIcon />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 5,
              width: widthScreen,
              height: '600px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '315px',
                height: '370px',
                borderRadius: '25px',
                bgcolor: 'white',
                opacity: 0.2,
              }}
            ></Box>
          </Box>
          <ScanQrTool
            width={widthScreen}
            height={600}
            onChange={(value) => handleScan(value)}
            delay={1000}
          />
        </Box>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          justifyContent: 'center',
          alignItems: 'center',
          bottom: 20,
          left: 0,
          width: '100%',
          display: 'flex',
        }}
      >
        <Box
          sx={{
            width: '42px',
            height: '42px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '16px',
            bgcolor: 'white',
          }}
          //   onClick={handleTorchlight}
        >
          <FlashIcon />
        </Box>
      </Box>
      <CustomBottomDrawer
        open={isQrNotExist}
        title={'Opps'}
        handleClose={() => setIsQrNotExist(false)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
          <Box
            sx={{
              width: '45px',
              height: '45px',
              bgcolor: '#F33A3A1A',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DataNotFound />
          </Box>
          <Typography>The product isn’t in the list.</Typography>
        </Box>
        <Divider sx={{ my: '20px' }} />
        <Box></Box>
      </CustomBottomDrawer>
    </Box>
  );
};

export default ProccessScanAccept;
