import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { ReactComponent as EditIcon } from "../../assets/editIcon.svg";
import CustomButton from "./CustomButton";
const CustomEditConfirm = ({
  open,
  onClose,
  handleClickEdit,
  handleClickCancel,
  title,
  body,
  isLoading,
}) => {
  return (
    <Dialog open={open} onClose={onClose} sx={{ borderRadius: "12px" }}>
      <DialogTitle>
        <Box sx={{ display: "center", alignItems: "center" }}>
          <Box
            sx={{
              bgcolor: "#FD79001A",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mr: "20px",
            }}
          >
            <EditIcon />
          </Box>
          <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
            {title}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {body}
        <Box
          sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
        >
          <Typography
            sx={{
              color: "#9E9D9D",
              fontSize: "12px",
              fontWeight: "600",
              mr: "20px",
              cursor: "pointer",
            }}
            onClick={handleClickCancel}
          >
            Cancel
          </Typography>
          <CustomButton
            variant="contained"
            sx={{
              width: "100px",
              height: "40px",
              fontSize: "12px",
              fontWeight: "600",
              display: "flex",
            }}
            onClick={handleClickEdit}
          >
            {isLoading ? (
              <CircularProgress size={16} sx={{ color: "#FFF" }} />
            ) : (
              "Save"
            )}
          </CustomButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default CustomEditConfirm;
