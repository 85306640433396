import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  getAccordionSummaryUtilityClass,
} from "@mui/material";
import React, { useState } from "react";
import BackButtonHub from "../../../../../../components/shared/BackButtonHub";
import { useDispatch, useSelector } from "react-redux";
import {
  changePageProductSupplier,
  setNotifProductSupplier,
} from "../../../../../../store/Routing/productSupplierRouting";
import {
  changeStatus,
  fetchDetailProdukSupplier,
  getAllDataDetailProdukSupplier,
} from "../../../../../../store/productSupplier/detailProductSupplier";
import { useEffect } from "react";
import CustomButton from "../../../../../../components/shared/CustomButton";
import CustomAntSwitch from "../../../../../../components/shared/CustomAntSwitch";
import CustomDeleteConfirm from "../../../../../../components/shared/CustomDeleteConfirm";
import {
  changeIdProduk,
  changeStatusEdit,
  loadDataEditProduk,
} from "../../../../../../store/productSupplier/editProductSupplier";
import { DeleteProdukSupplier } from "../../../../../../api/Supplier";

export const DetailSupplier = () => {
  const dispatch = useDispatch();
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const { idProduk, isLoading, datas, status } = useSelector(
    getAllDataDetailProdukSupplier
  );

  useEffect(() => {
    dispatch(fetchDetailProdukSupplier(idProduk));
  }, [idProduk]);

  const handleToEdit = () => {
    dispatch(loadDataEditProduk(datas));
    dispatch(changeStatusEdit(status));
    dispatch(changeIdProduk(idProduk));
  };

  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  const handleDelete = () => {
    setIsDeleteConfirm(true);
  };

  const handleDeleteFix = async () => {
    setIsLoadingDelete(true);
    try {
      await DeleteProdukSupplier(idProduk);
      dispatch(changePageProductSupplier(""));
      setIsLoadingDelete(false);
      dispatch(
        setNotifProductSupplier({
          key: "deleteProductSupllier",
          text: "product has been deleted",
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        minHeight: "83vh",
      }}
    >
      {isLoading === true ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 10,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container columns={12} spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center", mt: 2 }}>
              <BackButtonHub
                onClick={() => dispatch(changePageProductSupplier(""))}
              />
              <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                Product Detail
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Grid xs={2}>
                <Box>
                  <Typography
                    sx={{ fontSize: "16px", color: "#000000", fontWeight: 600 }}
                  >
                    Product detail
                  </Typography>
                </Box>
              </Grid>

              <Grid xs={10}>
                <Box
                  sx={{
                    height: "60px",
                    width: "80%",
                    padding: "14px 16px",
                    bgcolor: "#FAFAFA",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 500, color: "#000000" }}
                  >
                    {datas?.namaProduk}
                  </Typography>
                </Box>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Grid xs={2}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#000000",
                      fontWeight: 600,
                    }}
                  >
                    Price
                  </Typography>
                </Box>
              </Grid>

              {/* Maping */}
              <Grid xs={10}>
                {datas?.detail?.map((item, idx) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "80%",
                      height: "60px",
                      padding: "14px 16px",
                      bgcolor: "#FAFAFA",
                      mb: 2,
                    }}
                    key={idx}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#000000",
                      }}
                    >
                      Rp {item?.price}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "white",
                        width: "80px",
                        height: "40px",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#000000",
                          fontWeight: 500,
                        }}
                      >
                        {item?.name}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}

      <Box
        sx={{
          bottom: 1,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* Status */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            mb: "12px",
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
            Publish
          </Typography>
          <CustomAntSwitch
            checked={status}
            onChange={() => dispatch(changeStatus(!status))}
          />
        </Box>

        {/* Botton  */}
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomButton
            variant="contained"
            color="error"
            sx={{ width: "140px", height: "36px" }}
            onClick={handleDelete}
          >
            <Typography
              sx={{
                color: "white",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              Delete
            </Typography>
          </CustomButton>
          <CustomButton
            variant="contained"
            sx={{ width: "140px", height: "36px" }}
            onClick={() => {
              dispatch(changePageProductSupplier("editSupplier"));
              handleToEdit();
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              Edit
            </Typography>
          </CustomButton>
        </Box>
      </Box>

      {/* dialog delete */}
      <CustomDeleteConfirm
        isLoading={isLoadingDelete}
        open={isDeleteConfirm}
        onClose={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickCancel={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Produk"
        body={
          <Box sx={{ textAlign: "center" }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: 600 }}>
                {datas?.namaProduk}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: "52px" }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />
    </Box>
  );
};
