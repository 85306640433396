import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import BackButtonHub from "../../../../../../../components/shared/BackButtonHub";
import { AddCirleIcon } from "../../../../../../../assets/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  changePageProductSupplier,
  setNotifProductSupplier,
} from "../../../../../../../store/Routing/productSupplierRouting";
import ProductSupplierSelect from "../../add/ProductSelect";
import { useState } from "react";
import CustomCard from "../../../../../../../components/shared/CustomCard";
import {
  createProductSupplier,
  getProductUnit,
} from "../../../../../../../api/Supplier";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PriceProdukUnit from "./PriceProdukUnit";
import { DeleteOutline } from "@mui/icons-material";
import {
  changeStatusProductSupplier,
  clearStateProductSupplier,
  getAllDataAddProductSupplier,
} from "../../../../../../../store/productSupplier/addProductSupplier";
import CustomButton from "../../../../../../../components/shared/CustomButton";
import { useEffect } from "react";
import CustomAntSwitch from "../../../../../../../components/shared/CustomAntSwitch";

function AddPoroduk() {
  const dispatch = useDispatch();

  const [isDeleteIngredientRow, setIsDeleteIngredientRow] = useState(false);

  const [tambahItem, setTambahItem] = useState([
    {
      namaProduk: "",
      idProduk: 0,
      detail: [],
    },
  ]);

  const handleAddItem = () => {
    setTambahItem([...tambahItem, { namaProduk: "", idProduk: 0, detail: [] }]);
  };

  const [emptyProduct, setEmptyProduct] = useState("");
  const [openPrice, setOpenPrice] = useState(false);
  const [unit, setUnit] = useState([]);
  const [isLoadingUnit, setIsLoadingUnit] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { status } = useSelector(getAllDataAddProductSupplier);

  const fetchProductUnit = async (idProduct) => {
    try {
      setIsLoadingUnit(true);
      const {
        data: {
          data: { productUnits },
        },
      } = await getProductUnit(idProduct);
      const units = productUnits?.map((item, idx) => {
        return {
          idProduct: item?.idProduk,
          unit_id: item?.unit?.id,
          unitName: item?.unit.name,
          price: 0,
        };
      });
      setUnit(units);
      setIsLoadingUnit(false);
    } catch (err) {
      console.error(err);
    }
  };

  // validasi submit
  const [allowSubmit, setAllowSubmit] = useState(false);
  useEffect(() => {
    if (
      tambahItem[0].idProduk !== 0 &&
      tambahItem[0].namaProduk !== "" &&
      tambahItem[0].detail.length !== 0
    ) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [tambahItem]);

  // submit
  const handleSubmit = async () => {
    const params = {};
    params.status_publish = status ? 1 : 0;
    params.data = tambahItem;

    try {
      setLoadingSubmit(true);
      await createProductSupplier(params);
      dispatch(
        setNotifProductSupplier({
          key: "addProductSupllier",
          text: "add product success",
        })
      );
      dispatch(changePageProductSupplier(""));
      dispatch(clearStateProductSupplier());
      setLoadingSubmit(false);
      console.log({ params });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box>
      <Box sx={{ minHeight: "78vh" }}>
        <Grid container columns={12} spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                mt: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,

                  alignItems: "center",
                }}
              >
                <BackButtonHub
                  onClick={() => {
                    dispatch(changePageProductSupplier(""));
                  }}
                />
                <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                  Add Produk
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  background: "rgba(81, 177, 92, 0.1)",
                  borderRadius: "8px",
                  padding: "8px",
                  cursor: "pointer",
                  gap: 1,
                }}
                onClick={handleAddItem}
              >
                <AddCirleIcon width="20" height="20" />
                <Typography
                  sx={{ fontSize: "12px", fontWeight: 600, color: "#51B15C" }}
                >
                  Add more
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Grid container columns={12} spacing={2}>
                {tambahItem.map((item, idx) => (
                  <>
                    {/* Produk List */}
                    <Grid item xs={12} key={idx}>
                      <ProductSupplierSelect
                        items={tambahItem}
                        firstValueId={item?.idProduk}
                        firstValue={item?.namaProduk}
                        isReset={isDeleteIngredientRow}
                        setIsReset={setIsDeleteIngredientRow}
                        handleValue={(name, id) => {
                          const memo = tambahItem.map((item, idx) => {
                            if (item?.idProduk !== id && idx === idx) {
                              return {
                                ...item,
                                idProduk: id,
                                namaProduk: name,
                              };
                            } else {
                              return item;
                            }
                          });
                          setTambahItem(memo);
                        }}
                      />

                      {emptyProduct && item?.idProduk === 0 && (
                        <Typography
                          sx={{ fontSize: "12px", color: "#F33A3A", mt: 1 }}
                        >
                          {emptyProduct}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <CustomCard
                        sx={{
                          padding: "16px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (item?.idProduk === 0) {
                            setEmptyProduct("harap isi product dulu");
                            setTimeout(function () {
                              setEmptyProduct("");
                            }, 3000);
                          } else {
                            setOpenPrice(!openPrice);
                            fetchProductUnit(item?.idProduk);
                          }
                        }}
                      >
                        {item?.detail?.length === 0 ? (
                          <Box>
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  mb: "10px",
                                }}
                              >
                                price per unit
                              </Typography>
                              <Typography sx={{ color: "red" }}>*</Typography>
                            </Box>
                            <Typography
                              sx={{ color: "#9E9D9D", fontSize: "12px" }}
                            >
                              Click to set Price
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <Typography
                              sx={{ color: "#000", fontSize: "14px" }}
                            >
                              Rp {item?.detail[0]?.price} /
                              {item?.detail[0]?.unitName}
                            </Typography>
                            <Typography
                              sx={{ color: "#9E9D9D", fontSize: "14px" }}
                            >
                              +{item?.detail?.length - 1} more...
                            </Typography>
                          </Box>
                        )}
                        <ExpandMoreIcon />
                      </CustomCard>

                      {/* Price */}
                      <Grid xs={12}>
                        <PriceProdukUnit
                          openPrice={openPrice}
                          setOpenPrice={setOpenPrice}
                          unit={unit}
                          isLoadingUnit={isLoadingUnit}
                          handleValue={(detail) => {
                            const memo = tambahItem.map((item, idx) => {
                              if (item?.idProduk === detail[0]?.idProduct) {
                                return {
                                  ...item,
                                  detail: detail,
                                };
                              } else {
                                return item;
                              }
                            });
                            setTambahItem(memo);
                          }}
                        />
                      </Grid>
                    </Grid>

                    {/* delete */}
                    {tambahItem?.length > 1 && (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "#F33A3A1A",
                            borderRadius: "8px",
                            height: "40px",
                          }}
                          onClick={() => {
                            tambahItem.length > 1 &&
                              setTambahItem(
                                tambahItem.filter((_, index) => index !== idx)
                              );
                          }}
                        >
                          <DeleteOutline
                            sx={{ color: "#F33A3A", fontSize: "15px" }}
                          />
                          <Typography
                            sx={{ color: "#F33A3A", fontSize: "12px" }}
                          >
                            Remove Product
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                  </>
                ))}
              </Grid>
            </Box>
          </Grid>

          {/* button status */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                publish
              </Typography>
              <Box>
                <CustomAntSwitch
                  value={status}
                  onChange={() =>
                    dispatch(changeStatusProductSupplier(!status))
                  }
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Button Submit */}
      <Box
        sx={{
          width: "100%",
          bgcolor: "#FFFFFF",
          bottom: 0,
          padding: "8px 0px",
        }}
      >
        <CustomButton
          variant="contained"
          sx={{ width: "100%", height: "36px" }}
          disabled={!allowSubmit}
          onClick={handleSubmit}
        >
          {loadingSubmit ? (
            <CircularProgress size={16} sx={{ color: "#FFF" }} />
          ) : (
            <Typography
              sx={{ color: "white", fontSize: "12px", fontWeight: "500" }}
            >
              Submit
            </Typography>
          )}
        </CustomButton>
      </Box>
    </Box>
  );
}

export default AddPoroduk;
