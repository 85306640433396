import { Grid, Typography, Box, Switch, Dialog, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ReactComponent as NoImage } from '../../../../../../assets/no-image.svg';

import BackButton from '../../../../../../components/shared/BackButton';
import CustomButton from '../../../../../../components/shared/CustomButton';
import dayjs from 'dayjs';
import { ChartDetails } from '../../Product/DesktopComponent';
import {
  deleteSubCategoryMenu,
  updateSubCategoryMenuStatus,
} from '../../../../../../api/menu';
import CustomDeleteConfirm from '../../../../../../components/shared/CustomDeleteConfirm';
import SubCategoryMenuDetailsDesktop from './SubCategoryMenuDetailsDesktop';
import SubCategoryMenuDetailsMobile from './SubCategoryMenuDetailsMobile';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const SubCategoryMenuDetails = (props) => {
  const {
    desktopMode,
    roadHirarki,
    setRoadHirarki,
    //*--- page
    setPage,
    setSubCategoryMenu,
    // *--- Notif
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
    // *--- timeFrame
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    // *--- Data for edit
    subCategoryEditInputDatas,
    setSubCategoryEditInputDatas,
    // *--- Data
    detailsDatas,
    isLoadingDataDetails,
  } = props;
  const today = dayjs(new Date());
  // !---------------- Status Product ----------

  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (!isLoadingDataDetails) {
      if (detailsDatas.subStatus === 'Y') {
        return setStatus(true);
      } else {
        return setStatus(false);
      }
    }
  }, [isLoadingDataDetails]);

  const handleEditStatus = (e) => {
    if (status) {
      updateSubCategoryMenuStatus(roadHirarki.subCategoryId, 'N');
      setStatus(false);
    } else {
      updateSubCategoryMenuStatus(roadHirarki.subCategoryId, 'Y');
      setStatus(true);
    }
  };

  // !---------------- Confirm ----------------
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  // *! -------------------------- preview photo -----------------------------
  const [linkPreviewPhoto, setLinkPreviewPhoto] = useState('');
  const [isPreviewPhoto, setIsPreviewPhoto] = useState(false);

  // *! -------------------------- HANDLER -----------------------------
  const handleBackButton = () => {
    setPage('subCategory');
    setSubCategoryMenu('');
    setRoadHirarki({
      ...roadHirarki,
      subCategory: '',
      subCategoryId: 0,
      product: '',
      productId: 0,
      type: '',
      typeId: 0,
      miniSubCategory: '',
      miniSubCategoryId: 0,
    });
  };
  const handleDelete = () => {
    setIsDeleteConfirm(true);
  };
  const handleDeleteFix = () => {
    deleteSubCategoryMenu(detailsDatas.idSubkategori).then((res) => {
      setIsDeleteConfirm(false);
      setNotifTitle(
        `${res.data.data.subkategori} has been successfully deleted`
      );
      setNotif('deletesubcategory');
      setSubCategoryMenu('');
      setPage('subCategory');
      setRoadHirarki({
        ...roadHirarki,
        subCategory: '',
        subCategoryId: 0,
        product: '',
        productId: 0,
        miniSubCategory: '',
        miniSubCategoryId: 0,
      });
    });
  };
  const handleEdit = () => {
    setSubCategoryEditInputDatas({
      ...subCategoryEditInputDatas,
      name: detailsDatas.subkategori,
      description: detailsDatas.informasi,
      scheduleType: 'custom',
      schedule: detailsDatas.schedule ? dayjs(detailsDatas.schedule) : today,
      photoUrls: detailsDatas.gbrsub.split(','),
      publish: detailsDatas.subStatus === 'Y' ? true : false,
    });
    setSubCategoryMenu('edit');
  };
  const handlePreviewPhoto = (link) => {
    setLinkPreviewPhoto(link);
    setIsPreviewPhoto(true);
  };

  // *! ----------------------------- END -------------------------------
  // *! ----------------------------- END -------------------------------

  return (
    <>
      <CustomNotifSuccess
        text={notifTitle}
        open={notif === 'editsubcategory'}
        onClose={() => {
          setNotif('');
          setNotifTitle('');
        }}
      />
      <CustomDeleteConfirm
        open={isDeleteConfirm}
        onClose={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickCancel={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Subcategory"
        body={
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: '600' }}>
                {detailsDatas.subkategori}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: '52px' }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />
      {desktopMode ? (
        <SubCategoryMenuDetailsDesktop
          {...{ ...props }}
          {...{ AntSwitch }}
          {...{
            isPreviewPhoto,
            setIsPreviewPhoto,
            linkPreviewPhoto,
            setLinkPreviewPhoto,
          }}
          {...{ status }}
          {...{
            handleBackButton,
            handleEditStatus,
            handleDelete,
            handleEdit,
            handlePreviewPhoto,
          }}
        />
      ) : (
        <SubCategoryMenuDetailsMobile
          {...{ ...props }}
          {...{ AntSwitch }}
          {...{
            isPreviewPhoto,
            setIsPreviewPhoto,
            linkPreviewPhoto,
            setLinkPreviewPhoto,
          }}
          {...{ status }}
          {...{
            handleBackButton,
            handleEditStatus,
            handleDelete,
            handleEdit,
            handlePreviewPhoto,
          }}
        />
      )}
    </>
  );
};
export default SubCategoryMenuDetails;
