import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import { set } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../../../../../../components/shared/CustomButton";
import {
  changeCreateMapPage,
  changeIsCreateMap,
  getCellCreateMap,
  getCellSelectedAllCreateMap,
  getCellSelectedDetailsCreateMap,
  getCellSelectedHoverCreateMap,
  getDetailHoverCreateMap,
  getDetailRackCreateMap,
  getEntranceCreateMap,
  hoverCellFix,
  hoverRacks,
  seeDetailRack,
  unHoverCellFix,
  unHoverRacks,
  unSeeDetailRack,
} from "../../../../../../../../../store/hub/masterHub/masterHubAdd";
import DetailRackPreview from "./DetailRackPreview";

const CustomTableCells = styled(TableCell)({
  color: "#000000",
  fontWeight: 500,
  fontSize: "12px",
  textTransform: "capitalize",
});

const Preview = () => {
  const dispatch = useDispatch();
  const cell = useSelector(getCellCreateMap);
  const entrance = useSelector(getEntranceCreateMap);
  const cellSelectedAll = useSelector(getCellSelectedAllCreateMap);
  const hoverCell = useSelector(getCellSelectedHoverCreateMap);
  const cellSelectedDetails = useSelector(getCellSelectedDetailsCreateMap);
  const idDeteailHover = useSelector(getDetailHoverCreateMap);
  const [cellSelected, setCellSelected] = useState([]);
  const dataDetailRack = useSelector(getDetailRackCreateMap);

  useEffect(() => {
    let allDats = cellSelectedAll;
    setCellSelected(allDats.flat().filter((item) => !hoverCell.includes(item)));
  }, [cellSelectedAll, hoverCell]);

  const handleBack = () => {
    dispatch(changeCreateMapPage("racks"));
    dispatch(unSeeDetailRack());
  };
  const handleNext = () => {
    dispatch(changeIsCreateMap());
  };

  return (
    <Box sx={{ pb: "80px" }}>
      {!dataDetailRack ? (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* //!------------ Grid ------------------ */}
          <Box>
            {cell.map((row, rowIndex) => (
              <Box sx={{ display: "flex" }} key={rowIndex}>
                {row.map((col, colIndex) => (
                  <Box
                    onClick={() => dispatch(seeDetailRack())}
                    onMouseOver={() => dispatch(hoverRacks(col))}
                    onMouseOut={() => dispatch(unHoverRacks())}
                    sx={[
                      {
                        width: "36px",
                        height: "36px",
                      },
                      cellSelected.includes(col) && {
                        bgcolor: "#E4E4E4",
                      },
                      entrance.includes(col) && {
                        bgcolor: "#171717",
                      },
                      hoverCell.includes(col) && {
                        bgcolor: "#51B15C",
                        transition: "width 0.1s",
                        transitionTimingFunction: "ease-in-out",
                        cursor: "pointer",
                      },
                    ]}
                    key={colIndex}
                  ></Box>
                ))}
              </Box>
            ))}
          </Box>
          {/* //!------------ Table ------------------ */}
          <Box>
            <Table>
              <TableHead sx={{ bgcolor: "#FAFAFA" }}>
                <TableRow>
                  <CustomTableCells>Code</CustomTableCells>
                  <CustomTableCells sx={{ color: "#4339F2" }}>
                    Column
                  </CustomTableCells>
                  <CustomTableCells sx={{ color: "#EACD57" }}>
                    Level
                  </CustomTableCells>
                </TableRow>
              </TableHead>
              <TableBody>
                {cellSelectedDetails.map((data, index) => (
                  <TableRow
                    key={index}
                    onClick={() => dispatch(seeDetailRack())}
                    onMouseOver={() => dispatch(hoverCellFix(index))}
                    onMouseOut={() => dispatch(unHoverCellFix())}
                    sx={[
                      data.id === idDeteailHover && {
                        bgcolor: "#51B15C",
                        cursor: "pointer",
                      },
                    ]}
                  >
                    <CustomTableCells
                      sx={[
                        data.id === idDeteailHover && {
                          color: "white",
                        },
                      ]}
                    >
                      {data.name}
                    </CustomTableCells>
                    <CustomTableCells
                      align="right"
                      sx={[
                        data.id === idDeteailHover && {
                          color: "white",
                        },
                      ]}
                    >
                      {data.column}
                    </CustomTableCells>
                    <CustomTableCells
                      align="right"
                      sx={[
                        data.id === idDeteailHover && {
                          color: "white",
                        },
                      ]}
                    >
                      {data.row}
                    </CustomTableCells>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      ) : (
        <DetailRackPreview />
      )}
      {/* //!------------ Submit ------------------ */}
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          position: "fixed",
          bottom: "0px",
          right: "0px",
          display: "flex",
          justifyContent: "flex-end",
          padding: "18px 32px 18px 240px",
          alignItems: "center",
          gap: 1,
        }}
      >
        <CustomButton
          variant="outlined"
          sx={{ width: "190px", height: "48px" }}
          onClick={handleBack}
        >
          Back
        </CustomButton>
        <CustomButton
          variant="contained"
          sx={{ width: "190px", height: "48px" }}
          onClick={handleNext}
        >
          Finish
        </CustomButton>
      </Box>
    </Box>
  );
};
export default Preview;
