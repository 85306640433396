import { Box, Typography } from '@mui/material';
import BackButtonRack from './components/BackButtonRack';
import CustomTable2 from '../../../../../components/shared/shared2/CustomTable2';
import { getAllRack } from '../../../../../api/rack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllRackhub,
  goToRackHubDetail,
} from '../../../../../store/racks/rackHub';
import RackRouter from './RackRouter';

const headTables = [
  {
    key: 'photo',
    altKey: '',
    name: 'Photo',
    maxWidht: '12px',
    align: 'start',
    short: false,
  },
  {
    key: 'code',
    altKey: 'name',
    name: 'Code*',
    maxWidht: '',
    align: 'start',
    short: true,
  },
  {
    key: 'row',
    altKey: '',
    name: 'Level',
    maxWidht: '',
    align: 'start',
    short: true,
  },
  {
    key: 'column',
    altKey: '',
    name: 'Column',
    maxWidht: '',
    align: 'start',
    short: true,
  },
  {
    key: 'sku',
    altKey: '',
    name: 'SKU',
    maxWidht: '',
    align: 'start',
    short: true,
  },
  {
    key: 'cogs',
    altKey: '',
    name: 'COGS(Rp)*',
    maxWidht: '',
    align: 'start',
    short: true,
  },
  {
    key: 'width',
    altKey: '',
    name: 'width',
    maxWidht: '',
    align: 'end',
    short: false,
  },
  {
    key: 'lenght',
    altKey: '',
    name: 'length',
    maxWidht: '',
    align: 'end',
    short: false,
  },
  {
    key: 'weight',
    altKey: '',
    name: 'weight',
    maxWidht: '',
    align: 'end',
    short: false,
  },
  {
    key: 'height',
    altKey: '',
    name: 'height',
    maxWidht: '',
    align: 'end',
    short: false,
  },
];

const adjustFilterType = [
  { key: 'photo', name: 'Photo*', adjustable: false, default: true },
  { key: 'code', name: 'Code*', adjustable: false, default: true },
  { key: 'row', name: 'Level', adjustable: true, default: true },
  { key: 'column', name: 'Column', adjustable: true, default: true },
  { key: 'sku', name: 'sku', adjustable: true, default: true },
  { key: 'cogs', name: 'cogs', adjustable: true, default: true },
  { key: 'width', name: 'width', adjustable: true, default: false },
  { key: 'length', name: 'length', adjustable: true, default: false },
  { key: 'weight', name: 'weight', adjustable: true, default: false },
  { key: 'height', name: 'height', adjustable: true, default: false },
];

function RackHub() {
  return (
    <Box sx={{ mt: '16px' }}>
      <BackButtonRack />
      <Box sx={{ mt: '12px' }}>
        <RackRouter />
      </Box>
    </Box>
  );
}
export default RackHub;
