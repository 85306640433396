import { createSlice } from "@reduxjs/toolkit";

import { getDetailProductSupplier } from "../../api/Supplier";

export const detailProdukSupplierState = createSlice({
  name: "detailProdukSupplier",
  initialState: {
    idProduk: 0,
    isLoading: false,
    datas: [],
    status: false,
  },
  reducers: {
    changeIdProdukSupplier: (state, action) => {
      state.idProduk = action.payload;
    },
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    loadDataDetailProduk: (state, action) => {
      state.datas = action.payload;
    },
    changeStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const {
  changeIdProdukSupplier,
  changeIsLoading,
  loadDataDetailProduk,
  changeStatus,
} = detailProdukSupplierState.actions;

export const getAllDataDetailProdukSupplier = (state) =>
  state.detailProdukSupplier;

export default detailProdukSupplierState.reducer;

// ! ------------------------ API --------------------------

export const fetchDetailProdukSupplier = (id) => (dispatch) => {
  const fetchData = async () => {
    try {
      dispatch(changeIsLoading(true));
      const {
        data: { data },
      } = await getDetailProductSupplier(id);
      dispatch(changeStatus(data?.statusPublish));
      dispatch(loadDataDetailProduk(data));
      dispatch(changeIsLoading(false));
    } catch (err) {
      console.error(err);
    }
  };

  fetchData();
};
