const useGetExcelNumberFormat = (input) => {
  let num = input;
  let letters = '';
  while (num >= 0) {
    letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters;
    num = Math.floor(num / 26) - 1;
  }
  return letters;
};
export default useGetExcelNumberFormat;
