import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Switch,
  Dialog,
  styled,
} from '@mui/material';
import BackButton from '../../../../../../components/shared/BackButton';
import CustomButton from '../../../../../../components/shared/CustomButton';
import dayjs from 'dayjs';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ReactComponent as NoImage } from '../../../../../../assets/no-image.svg';
import { useEffect, useState } from 'react';
import {
  deleteMiniSubCategoryMenu,
  updateMiniSubCategoryMenuStatus,
} from '../../../../../../api/menu';
import CustomDeleteConfirm from '../../../../../../components/shared/CustomDeleteConfirm';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const MiniSubMenuDetails = (props) => {
  const {
    // *--- destop
    desktopMode,
    roadHirarki,
    setRoadHirarki,
    // *--- Nottif
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
    // *--- page
    setPage,
    setMiniSubMenu,
    // *--- Time Frame
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    // *--- data
    detailsDatas,
    isLoadingDataDetails,
    // *--- Edit
    miniSubEditInputDatas,
    setMiniSubEditInputDatas,
  } = props;
  const today = dayjs(new Date());
  const theme = useTheme();

  // !---------------- Status Product ----------

  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (!isLoadingDataDetails) {
      if (detailsDatas.status === 'Y') {
        return setStatus(true);
      } else {
        return setStatus(false);
      }
    }
  }, [isLoadingDataDetails]);

  const handleEditStatus = (e) => {
    if (status) {
      updateMiniSubCategoryMenuStatus(roadHirarki.miniSubCategoryId, 'N');
      setStatus(false);
    } else {
      updateMiniSubCategoryMenuStatus(roadHirarki.miniSubCategoryId, 'Y');
      setStatus(true);
    }
  };

  // !---------------- Confirm ----------------
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  // *! -------------------------- preview photo -----------------------------
  const [linkPreviewPhoto, setLinkPreviewPhoto] = useState('');
  const [isPreviewPhoto, setIsPreviewPhoto] = useState(false);

  // ! ------------- Handler ---------------
  const handleBackButton = () => {
    setRoadHirarki({
      ...roadHirarki,
      miniSubCategory: '',
      miniSubCategoryId: 0,
    });
    setPage('menu');
  };

  const handleEdit = () => {
    setMiniSubEditInputDatas({
      ...miniSubEditInputDatas,
      id: detailsDatas.id,
      name: detailsDatas.subCategoryName,
      description: detailsDatas.description ? detailsDatas.description : '',
      scheduleType: 'custom',
      schedule: detailsDatas.schedule ? dayjs(detailsDatas.schedule) : today,
      photoUrls: detailsDatas.image ? detailsDatas.image.split(',') : [],
      publish: status,
      subsidiary: detailsDatas.subsidiaryName,
      category: detailsDatas.categoryName,
      subCategory: detailsDatas.subCategoryName,
    });

    setMiniSubMenu('edit');
  };

  const handleDelete = () => {
    setIsDeleteConfirm(true);
  };
  const handleDeleteFix = () => {
    deleteMiniSubCategoryMenu(detailsDatas.id).then((res) => {
      setNotifTitle(`${res.data.data.name} has been successfully deleted`);
      setNotif('deletemini');
      setPage('menu');
      setRoadHirarki({
        ...roadHirarki,
        miniSubCategory: '',
        miniSubCategoryId: 0,
      });
    });
  };

  const handlePreviewImage = (link) => {
    setLinkPreviewPhoto(link);
    setIsPreviewPhoto(true);
  };

  const handleClosePreviewImage = () => {};

  return (
    <>
      <CustomNotifSuccess
        open={notif === 'editmini'}
        text={notifTitle}
        onClose={() => {
          setNotif('');
          setNotifTitle('');
        }}
      />
      <CustomDeleteConfirm
        open={isDeleteConfirm}
        onClose={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickCancel={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Mini-Subcategory"
        body={
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: '600' }}>
                {detailsDatas.name}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: '52px' }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />
      <Grid container columns={12} spacing={4} sx={{ pb: '56px' }}>
        {!isLoadingDataDetails && (
          <>
            {/* //* BackButton */}
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
            >
              <BackButton handleClick={handleBackButton} />
              <Box>
                <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
                  {roadHirarki.miniSubCategory} Details
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    color: '#9E9D9D',
                    alignItems: 'center',
                    mt: '4px',
                    gap: 1,
                  }}
                >
                  {!isLoadingDataDetails && (
                    <>
                      <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                        {detailsDatas.subsidiaryName}
                      </Typography>
                      <ChevronRightIcon sx={{ color: '#9E9D9D' }} />
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: '100',
                        }}
                      >
                        {detailsDatas.categoryName}
                      </Typography>
                      <ChevronRightIcon sx={{ color: '#9E9D9D' }} />
                      <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                        {detailsDatas.subCategoryName}
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
            {/* //* --- */}
            <Grid container item xs={12} columns={12} columnSpacing={4}>
              <Grid item xs={8}>
                {/* <ChartDetails
              {...{
                timeFrame,
                setTimeFrame,
                dateRange,
                setDateRange,
                saveDateRange,
                setSaveDateRange,
              }}
            /> */}
              </Grid>
              <Grid container item xs={4} columns={12} spacing={4}>
                {/*//* purchased */}
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                    Purchased
                  </Typography>
                  <Box
                    sx={{
                      mt: '12px',
                      width: '100%',
                      padding: '14px 16px',
                      bgcolor: '#FAFAFA',
                      borderRadius: '8px',
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                      Rp {detailsDatas.purchased ? detailsDatas.purchased : '-'}
                    </Typography>
                  </Box>
                </Grid>
                {/*//* sold */}
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                    Sold
                  </Typography>
                  <Box
                    sx={{
                      mt: '12px',
                      width: '100%',
                      padding: '14px 16px',
                      bgcolor: '#FAFAFA',
                      borderRadius: '8px',
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                      Rp {detailsDatas.sold ? detailsDatas.sold : '-'}
                    </Typography>
                  </Box>
                </Grid>
                {/*//* used */}
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                    Used
                  </Typography>
                  <Box
                    sx={{
                      mt: '12px',
                      width: '100%',
                      padding: '14px 16px',
                      bgcolor: '#FAFAFA',
                      borderRadius: '8px',
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                      Rp {detailsDatas.used ? detailsDatas.used : '-'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {/* //* SKU */}
            <Grid item xs={6}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                SKU
              </Typography>
              <Box
                sx={{
                  mt: '12px',
                  width: '100%',
                  padding: '14px 16px',
                  bgcolor: '#FAFAFA',
                  borderRadius: '8px',
                }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                  {detailsDatas.sku ? detailsDatas.sku : '-'}
                </Typography>
              </Box>
            </Grid>
            {/* //* Photo */}
            <Grid item xs={6}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Photo
              </Typography>
              <Box
                sx={{
                  mt: '12px',
                  width: '100%',
                  padding: '0',
                  display: 'flex',
                  gap: 1,
                }}
              >
                {!detailsDatas.image ? (
                  <Box
                    sx={{
                      width: '42px',
                      height: '42px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: '#FAFAFA',
                      borderRadius: '2px',
                    }}
                  >
                    <NoImage />
                    <Typography sx={{ color: '#9E9D9D', fontSize: '6px' }}>
                      No photo
                    </Typography>
                  </Box>
                ) : (
                  detailsDatas.image.split(',').map((item) => (
                    <Box
                      component="img"
                      src={
                        process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + item
                      }
                      onClick={() => handlePreviewImage(item)}
                      sx={{
                        width: '42px',
                        height: '42px',
                        aspectRatio: '1/1',
                        borderRadius: '8px',
                        cursor: 'pointer',
                      }}
                    />
                  ))
                )}
              </Box>
            </Grid>
            {/* //* Dialog Photo */}
            {linkPreviewPhoto !== '' && (
              <Dialog
                open={isPreviewPhoto}
                onClose={() => {
                  setIsPreviewPhoto(!isPreviewPhoto);
                  setLinkPreviewPhoto('');
                }}
              >
                <Box
                  component="img"
                  src={
                    process.env.REACT_APP_API_IMAGE_BASE_URL +
                    '/' +
                    linkPreviewPhoto
                  }
                  sx={{
                    width: '600px',
                    height: '600px',
                    aspectRation: '1/1',
                  }}
                />
              </Dialog>
            )}
            {/* //* Descripsi */}
            <Grid item xs={6}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Description
              </Typography>
              <Box
                sx={{
                  mt: '12px',
                  width: '100%',
                  padding: '14px 16px',
                  bgcolor: '#FAFAFA',
                  borderRadius: '8px',
                }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                  {detailsDatas.description ? detailsDatas.description : '-'}
                </Typography>
              </Box>
            </Grid>
            {/* //* Schedule */}
            <Grid item xs={6}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Schedule
              </Typography>
              <Box
                sx={{
                  mt: '12px',
                  width: '100%',
                  padding: '14px 16px',
                  bgcolor: '#FAFAFA',
                  borderRadius: '8px',
                }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                  {detailsDatas.schedule
                    ? dayjs(detailsDatas.schedule).format('DD/MMM/YYYY')
                    : '-'}
                </Typography>
              </Box>
            </Grid>
            {/* //*  Footer */}
            <Box
              sx={{
                width: '100%',
                bgcolor: 'white',
                position: 'fixed',
                bottom: '0px',
                right: '0px',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '18px 32px 18px 240px',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                  Publish
                </Typography>
                <AntSwitch checked={status} onChange={handleEditStatus} />
              </Box>
              <Box sx={{ display: 'flex', gap: '20px' }}>
                <CustomButton
                  variant="contained"
                  color="error"
                  sx={{ width: '140px', height: '36px' }}
                  onClick={handleDelete}
                >
                  <Typography
                    sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
                  >
                    Delete
                  </Typography>
                </CustomButton>
                <CustomButton
                  variant="contained"
                  sx={{ width: '140px', height: '36px' }}
                  onClick={handleEdit}
                >
                  <Typography
                    sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
                  >
                    Edit
                  </Typography>
                </CustomButton>
              </Box>
            </Box>
          </>
        )}
      </Grid>
    </>
  );
};
export default MiniSubMenuDetails;
