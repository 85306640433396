import { Grid, Typography, useTheme } from '@mui/material';
import BackButton from '../../../../../../components/shared/BackButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuPerformance from '../Components/performance/MenuPerformance';
import TableSubCategoryMenu from './Desktop/TableSubCategoryMenu';

const SubCategoryMenuDesktop = (props) => {
  const {
    theme,
    // *-------
    roadHirarki,
    setRoadHirarki,
    // *--- page
    setPage,
    setSubCategoryMenu,
    // *-date & time
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    // *--- Handler
    handleBackButton,
    handleClickName,
    handleClickRow,
    handleAdd,
  } = props;
  return (
    <Grid container columns={12} spacing={2}>
      {/* //* Back Button  */}
      <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        <Typography
          sx={{
            ml: '8px',
            fontSize: '24px',
            fontWeight: '600',
            color: '#9E9D9D',
            cursor: 'pointer',
          }}
          onClick={handleBackButton}
        >
          Category
        </Typography>
        <ChevronRightIcon sx={{ color: '#9E9D9D' }} />
        <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
          {roadHirarki.category}
        </Typography>
      </Grid>
      {/* //* Performance */}
      {/* <Grid item xs={12}>
        <MenuPerformance
          {...{
            timeFrame,
            setTimeFrame,
            dateRange,
            setDateRange,
            saveDateRange,
            setSaveDateRange,
          }}
        />
      </Grid> */}
      <Grid item xs={12}>
        <TableSubCategoryMenu
          {...{ ...props }}
          {...{ theme }}
          {...{ handleAdd, handleClickName, handleClickRow }}
        />
      </Grid>
    </Grid>
  );
};

export default SubCategoryMenuDesktop;
