import {
  Box,
  Checkbox,
  InputAdornment,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { ReactComponent as Sales } from '../../../../../../../assets/sales.svg';
import { ReactComponent as Order } from '../../../../../../../assets/order.svg';
import { ReactComponent as Cogs } from '../../../../../../../assets/cogs.svg';
import { ReactComponent as Used } from '../../../../../../../assets/used2.svg';
import SearchIcon from '@mui/icons-material/Search';

const CustomTableCellHead = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '12 px',
  textTransform: 'capitalize',

  padding: '8px 0px',
});
const CustomTableCellBody = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '12px',
  height: '10px',
  textTransform: 'capitalize',

  padding: '0px',
});

const TablePerfomance = () => {
  return (
    <Box sx={{ py: '16px', pr: '24px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'end', mb: '4px' }}>
        <TextField
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ ml: '12px' }} />
              </InputAdornment>
            ),
          }}
          sx={{ bgcolor: '#FAFAFA' }}
        />
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <CustomTableCellHead></CustomTableCellHead>
            <CustomTableCellHead>Name</CustomTableCellHead>
            <CustomTableCellHead>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Cogs style={{ width: '16px', fill: '#404040' }} />
                <Typography sx={{ fontSize: '12px' }}>(Rp*)</Typography>
                <SyncAltIcon
                  sx={{
                    transform: 'rotate(90deg)',
                    fill: '#9E9D9D',
                    fontSize: '12px',
                  }}
                />
              </Box>
            </CustomTableCellHead>
            <CustomTableCellHead>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Sales style={{ width: '16px', fill: '#51B15C' }} />
                <Typography sx={{ fontSize: '12px' }}>(Rp*)</Typography>
                <SyncAltIcon
                  sx={{
                    transform: 'rotate(90deg)',
                    fill: '#9E9D9D',
                    fontSize: '12px',
                  }}
                />
              </Box>
            </CustomTableCellHead>
            <CustomTableCellHead>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Order style={{ width: '16px', fill: '#FEDD25' }} />
                <SyncAltIcon
                  sx={{
                    transform: 'rotate(90deg)',
                    fill: '#9E9D9D',
                    fontSize: '12px',
                  }}
                />
              </Box>
            </CustomTableCellHead>
            <CustomTableCellHead>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Used style={{ width: '16px', fill: '#F239A8' }} />
                <SyncAltIcon
                  sx={{
                    transform: 'rotate(90deg)',
                    fill: '#9E9D9D',
                    fontSize: '12px',
                  }}
                />
              </Box>
            </CustomTableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(5)].map((_, index) => (
            <TableRow>
              <CustomTableCellBody>
                <Checkbox
                  defaultChecked
                  sx={{
                    '&:before': {
                      display: 'block',
                      width: 16,
                      height: 16,
                    },
                  }}
                />
              </CustomTableCellBody>
              <CustomTableCellBody>Sub {index + 1}</CustomTableCellBody>
              <CustomTableCellBody align="right">34</CustomTableCellBody>
              <CustomTableCellBody align="right">43</CustomTableCellBody>
              <CustomTableCellBody align="right">23</CustomTableCellBody>
              <CustomTableCellBody align="right">89</CustomTableCellBody>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
export default TablePerfomance;
