import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import BackButtonHub from "../../../../../../components/shared/BackButtonHub";
import { useDispatch, useSelector } from "react-redux";
import { changePage } from "../../../../../../store/Routing/purchaseRouting";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  CalendarTodayIcon,
  CircleIcon,
  QrCodeIcon,
} from "../../../../../../assets/Icons";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  getAllDataPurchaseDetail,
  getDataDetailPurchaseDashboard,
  getIdPurchaseDetail,
  getLoadingDetailPurchase,
} from "../../../../../../store/purchase/detailPurchase";
import DetailPurchaseMobile from "../PurchaseMobile/Detail/DetailPurchase";
import CustomAntSwitch from "../../../../../../components/shared/CustomAntSwitch";
import { editStatusPurchase } from "../../../../../../api/purchase";
import Star from "../../../../../../components/shared/Star";

const color = {
  no: "#C8C8CC",
  yes: "#51B15C",
};
const DetailPurchase = () => {
  const matchTablet = useMediaQuery("(min-width:832px");
  const roleUser = localStorage.getItem("role");
  const dispatch = useDispatch();
  const idPurchase = useSelector(getIdPurchaseDetail);
  const datas = useSelector(getDataDetailPurchaseDashboard);
  const isLoading = useSelector(getLoadingDetailPurchase);
  const [isSuplier, setIsSuplier] = useState(false);

  useEffect(() => {
    dispatch(getAllDataPurchaseDetail(idPurchase));
    roleUser == 5 && setIsSuplier(true);
  }, [idPurchase, roleUser]);

  const handleBackButton = () => {
    dispatch(changePage(""));
  };

  const decodeStatus = datas?.statusMapDecode;

  const handleToQrCode = () => {
    dispatch(changePage("qrCode"));
  };

  // status
  const [statusPurchase, setStatusPurchase] = useState(false);
  useEffect(() => {
    if (decodeStatus?.paid === true) {
      return setStatusPurchase(true);
    } else {
      return setStatusPurchase(false);
    }
  }, [decodeStatus]);

  const handleEditStatus = () => {
    if (statusPurchase) {
      editStatusPurchase(idPurchase, { status: "unpaid" });
      setStatusPurchase(false);
      dispatch(getAllDataPurchaseDetail(idPurchase));
    } else {
      editStatusPurchase(idPurchase, { status: "paid" });
      setStatusPurchase(true);
      dispatch(getAllDataPurchaseDetail(idPurchase));
    }
  };

  return !matchTablet ? (
    <DetailPurchaseMobile />
  ) : (
    <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <BackButtonHub onClick={() => handleBackButton()} />
          <Typography
            sx={{
              color: "#000000",
              fontSize: "1.25rem",
              fontWeight: 600,
              cursor: "pointer",
            }}
          >
            Purchase Details
          </Typography>
        </Box>
        {datas?.status !== "reject" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "44px",
              height: "44px",
              borderRadius: "8px",
              bgcolor: "#51B15C1A",
              cursor: "pointer",
            }}
            onClick={handleToQrCode}
          >
            <QrCodeIcon />
          </Box>
        )}
      </Box>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="success" />
        </Box>
      ) : (
        <Grid container columns={12} spacing={4} sx={{ mt: 1 }}>
          {/* invoice id */}
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
              Invoice ID
            </Typography>
            <Box
              sx={{
                mt: "12px",
                width: "100%",
                padding: "14px 16px",
                bgcolor: "#FAFAFA",
                borderRadius: "8px",
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                {datas?.nomorfaktur !== "" ? datas?.nomorfaktur : "-"}
              </Typography>
            </Box>
          </Grid>

          {/* date */}
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
              Date
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: "12px",
                width: "100%",
                padding: "10px 12px",
                bgcolor: "#FAFAFA",
                borderRadius: "8px",
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                {dayjs(datas?.tanggal).format("DD/MMM/YY")}
              </Typography>
              <CalendarTodayIcon sx={{ width: "20px", m: 0, p: 0 }} />
            </Box>
          </Grid>

          {/* supplier */}
          <Grid item xs={6}>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                Supplier
              </Typography>
              <Star />
            </Box>
            <Box
              sx={{
                mt: "12px",
                width: "100%",
                padding: "14px 16px",
                bgcolor: "#FAFAFA",
                borderRadius: "8px",
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                {datas?.supplier !== null ? datas?.supplier.namaLengkap : "-"}
              </Typography>
            </Box>
          </Grid>

          {/* status */}
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
              Status
            </Typography>
            <Box
              sx={{
                mt: "12px",
                width: "100%",
              }}
            >
              {datas?.status === "reject" ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "84px",
                    color: "#FF0000",
                  }}
                >
                  <Typography sx={{ mb: 1 }}>Rejected</Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    mb: 2,
                  }}
                >
                  {decodeStatus?.unpaid && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "84px",
                        color: decodeStatus?.unpaid ? color.yes : color.no,
                      }}
                    >
                      <Typography sx={{ mb: 1 }}>Unpaid</Typography>
                    </Box>
                  )}
                  {decodeStatus?.paid && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "84px",
                        color: decodeStatus?.paid ? color.yes : color.no,
                      }}
                    >
                      <Typography sx={{ mb: 1 }}>Paid</Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "84px",
                      color: decodeStatus?.processed ? color.yes : color.no,
                    }}
                  >
                    <Typography sx={{ mb: 1 }}>Processed</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "84px",
                      color: decodeStatus?.sent ? color.yes : color.no,
                    }}
                  >
                    <Typography sx={{ mb: 1 }}>Sent</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "84px",
                      color: decodeStatus?.accepted ? color.yes : color.no,
                    }}
                  >
                    <Typography sx={{ mb: 1 }}>Accepted</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "84px",
                      color: decodeStatus?.finish ? color.yes : color.no,
                    }}
                  >
                    <Typography sx={{ mb: 1 }}>Finish</Typography>
                  </Box>
                </Box>
              )}
            </Box>

            {/* bullets */}
            <Box>
              {datas?.status !== "reject" && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>
                    {decodeStatus?.unpaid && (
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0 }}
                      >
                        <Box
                          sx={{
                            height: "2px",
                            width: "34px",
                            bgcolor: decodeStatus?.unpaid
                              ? color.yes
                              : color.no,
                            visibility: "hidden",
                          }}
                        ></Box>
                        <Box
                          sx={{
                            width: "16px",
                            height: "16px",
                            bgcolor: decodeStatus?.unpaid
                              ? color.yes
                              : color.no,
                            borderRadius: "50%",
                          }}
                        ></Box>
                        <Box
                          sx={{
                            height: "2px",
                            width: "23px",
                            bgcolor: decodeStatus?.processed
                              ? color.yes
                              : color.no,
                          }}
                        ></Box>
                      </Box>
                    )}
                    {decodeStatus?.paid && (
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0 }}
                      >
                        <Box
                          sx={{
                            height: "2px",
                            width: "34px",
                            bgcolor: decodeStatus?.paid ? color.yes : color.no,
                            visibility: "hidden",
                          }}
                        ></Box>
                        <Box
                          sx={{
                            width: "16px",
                            height: "16px",
                            bgcolor: decodeStatus?.paid ? color.yes : color.no,
                            borderRadius: "50%",
                          }}
                        ></Box>
                        <Box
                          sx={{
                            height: "2px",
                            width: "34px",
                            bgcolor: decodeStatus?.processed
                              ? color.yes
                              : color.no,
                          }}
                        ></Box>
                      </Box>
                    )}
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>
                    <Box
                      sx={{
                        height: "2px",
                        width: "34px",
                        bgcolor: decodeStatus?.processed ? color.yes : color.no,
                      }}
                    ></Box>
                    <Box
                      sx={{
                        width: "16px",
                        height: "16px",
                        bgcolor: decodeStatus?.processed ? color.yes : color.no,
                        borderRadius: "50%",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        height: "2px",
                        width: "34px",
                        bgcolor: decodeStatus?.processed ? color.yes : color.no,
                      }}
                    ></Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>
                    <Box
                      sx={{
                        height: "2px",
                        width: "34px",
                        bgcolor: decodeStatus?.sent ? color.yes : color.no,
                      }}
                    ></Box>
                    <Box
                      sx={{
                        width: "16px",
                        height: "16px",
                        bgcolor: decodeStatus?.sent ? color.yes : color.no,
                        borderRadius: "50%",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        height: "2px",
                        width: "34px",
                        bgcolor: decodeStatus?.sent ? color.yes : color.no,
                      }}
                    ></Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>
                    <Box
                      sx={{
                        height: "2px",
                        width: "34px",
                        bgcolor: decodeStatus?.accepted ? color.yes : color.no,
                      }}
                    ></Box>
                    <Box
                      sx={{
                        width: "16px",
                        height: "16px",
                        bgcolor: decodeStatus?.accepted ? color.yes : color.no,
                        borderRadius: "50%",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        height: "2px",
                        width: "34px",
                        bgcolor: decodeStatus?.accepted ? color.yes : color.no,
                      }}
                    ></Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>
                    <Box
                      sx={{
                        height: "2px",
                        width: "34px",
                        bgcolor: decodeStatus?.finish ? color.yes : color.no,
                      }}
                    ></Box>
                    <Box
                      sx={{
                        width: "16px",
                        height: "16px",
                        bgcolor: decodeStatus?.finish ? color.yes : color.no,
                        borderRadius: "50%",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        height: "2px",
                        width: "34px",
                        bgcolor: decodeStatus?.finish ? color.yes : color.no,
                        visibility: "hidden",
                      }}
                    ></Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>

          {/* description */}
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
              Description
            </Typography>
            <Box
              sx={{
                mt: "12px",
                width: "100%",
                padding: "14px 16px",
                bgcolor: "#FAFAFA",
                borderRadius: "8px",
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                {datas?.keterangan}
              </Typography>
            </Box>
          </Grid>

          {/* table list */}
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell align="left">Location</TableCell>
                    <TableCell align="left">RRP(Rp)*</TableCell>
                    <TableCell align="left">Qty</TableCell>
                    <TableCell align="left">Used</TableCell>
                    <TableCell align="left">Disc(Rp)*</TableCell>
                    <TableCell align="left">Subtotal(Rp)*</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {datas?.details?.map((item, idx) => (
                    <TableRow key={idx}>
                      <TableCell component="th" scope="row">
                        {idx + 1}
                      </TableCell>
                      <TableCell align="left">
                        {item?.product?.namaProduk}
                      </TableCell>
                      <TableCell align="left">location</TableCell>
                      <TableCell align="center">{item.purchasePrice}</TableCell>
                      <TableCell align="center">{item.qty}</TableCell>
                      <TableCell align="center">used</TableCell>
                      <TableCell align="center">{item.discount}</TableCell>
                      <TableCell align="center">
                        {item.purchasePrice * item.qty - item.discount}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">
                      <Typography>Total discount (from invoice)</Typography>
                    </TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">totals</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">
                      <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                        Grand Total
                      </Typography>
                    </TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                        Rp {datas?.totalbayar}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
      {roleUser == 5 && (
        <Box
          sx={{
            width: "100%",
            bgcolor: "white",
            position: "fixed",
            bottom: "0px",
            right: "0px",
            display: "flex",
            justifyContent: "space-between",
            padding: "18px 32px 18px 240px",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
              Paid
            </Typography>
            <CustomAntSwitch
              disabled={!isSuplier}
              checked={statusPurchase}
              onChange={(e) => handleEditStatus(e)}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default DetailPurchase;
