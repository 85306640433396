import { Box, Grid, useTheme } from '@mui/material';
import { useState } from 'react';
import { ToBarChart, ToLineChart } from '../../../../../../../assets/Icons';
import { Chart } from 'react-chartjs-2';
import CustomLineChart from '../../../../../../../components/shared/CustomLineChart';

const barDummyData = {
  orders: [12, 42, 45, 12, 67, 34, 23, 34, 23, 30],
  sold: [92, 32, 45, 23, 34, 32, 76, 56, 87, 35],
  used: [34, 34, 54, 45, 65, 43, 65, 45, 34, 89],
};
const lineDummyData = {
  sub1: [12, 42, 45, 12, 67, 34, 23, 34, 23, 30],
  sub2: [92, 32, 45, 23, 34, 32, 76, 56, 87, 35],
  sub3: [34, 34, 54, 45, 65, 43, 65, 45, 34, 89],
  sub4: [43, 23, 45, 89, 86, 56, 86, 54, 43, 32],
  sub5: [32, 78, 45, 34, 78, 45, 90, 67, 45, 34],
};

const colors = ['#C85178', '#4994B7', '#812089', '#468F36', '#E19F4F'];

const ChartPerformanceRackList = () => {
  const theme = useTheme();
  const [isBarChart, setIsBarChart] = useState(true);

  const labeDataDummy = barDummyData.orders.map((_, index) => {
    return `cat ${index}`;
  });

  const barChartData = {
    labels: labeDataDummy,
    datasets: [
      {
        label: 'Sold',
        // yAxisID: 'y1',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        type: 'bar',
        data: barDummyData.sold,
        order: 0,
        // hidden: !stockPerfomanceListChartShowed.includes(0),
      },
      {
        label: 'Orders',
        data: barDummyData.orders,
        borderRadius: 4,
        type: 'bar',
        // yAxisID: 'y2',
        backgroundColor: '#FFCF46',
        order: 1,
        // hidden: !stockPerfomanceListChartShowed.includes(1),
      },
      {
        label: 'Used',
        data: barDummyData.used,
        borderRadius: 4,
        type: 'line',
        // yAxisID: 'y3',
        backgroundColor: '#F28AAE',
        borderColor: '#F28AAE',
        order: -1,
        // hidden: !stockPerfomanceListChartShowed.includes(2),
      },
    ],
  };
  const lineChartData = {
    labels: labeDataDummy,
    datasets: [
      {
        label: 'Sub 1',
        // yAxisID: 'y1',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        type: 'line',
        data: lineDummyData.sub1,
        borderColor: colors[1],
        backgroundColor: colors[1],

        // order: 0,
        // hidden: !stockPerfomanceListChartShowed.includes(0),
      },
      {
        label: 'Sub 2',
        // yAxisID: 'y1',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        type: 'line',
        data: lineDummyData.sub2,
        borderColor: colors[2],
        backgroundColor: colors[2],
        // order: 0,
        // hidden: !stockPerfomanceListChartShowed.includes(0),
      },
      {
        label: 'Sub 3',
        // yAxisID: 'y1',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        type: 'line',
        data: lineDummyData.sub3,
        borderColor: colors[3],
        backgroundColor: colors[3],

        // order: 0,
        // hidden: !stockPerfomanceListChartShowed.includes(0),
      },
      {
        label: 'Sub 4',
        // yAxisID: 'y1',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        type: 'line',
        data: lineDummyData.sub4,
        borderColor: colors[4],
        backgroundColor: colors[4],

        // order: 0,
        // hidden: !stockPerfomanceListChartShowed.includes(0),
      },
      {
        label: 'Sub 5',
        // yAxisID: 'y1',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        type: 'line',
        data: lineDummyData.sub5,
        borderColor: colors[5],
        backgroundColor: colors[5],
        // order: 0,
        // hidden: !stockPerfomanceListChartShowed.includes(0),
      },
    ],
  };
  return (
    <Box>
      <Box>
        <Grid container columns={3}>
          <Grid item xs={1}>
            <Box></Box>
          </Grid>
          <Grid item xs={1}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  bgcolor: theme.palette.primary.main,
                  width: '26px',
                  height: '26px',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => setIsBarChart(!isBarChart)}
              >
                {isBarChart ? <ToLineChart /> : <ToBarChart />}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box></Box>
          </Grid>
        </Grid>
        <Box sx={{ maxHeight: '300px', height: '300px', minHeight: '300px' }}>
          {isBarChart ? (
            <Chart
              type="bar"
              data={barChartData}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                layout: {
                  padding: {
                    left: 10,
                    right: 10,
                  },
                },
                plugins: {
                  annotation: {
                    annotations: {},
                  },
                  legend: {
                    position: 'bottom',
                    align: 'end',
                  },
                },
                scales: {
                  y1: {
                    position: 'left',
                    // display: stockPerfomanceListChartShowed.includes(0),
                  },
                  y2: {
                    ticks: {
                      color: '#B8B8B8',
                      font: {
                        size: 12,
                        weight: 600,
                        family: 'Poppins',
                      },
                    },
                    position: 'right',
                  },
                  y3: { display: false },
                  x: {
                    ticks: {
                      color: '#B8B8B8',
                      font: {
                        size: 12,
                        weight: 600,
                        family: 'Poppins',
                      },
                      callback(value) {
                        const newLabel = this.getLabelForValue(value);
                        return `${newLabel}`.split(' ')[0];
                      },
                    },
                  },
                },
              }}
            />
          ) : (
            <CustomLineChart
              style={{ height: '300px' }}
              data={lineChartData}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                // aspectRation: 1,
                layout: {
                  padding: {},
                },
                plugins: {
                  legend: {
                    labels: {
                      color: '#B8B8B8',
                      font: {
                        size: 12,
                        weight: 600,
                        family: 'Poppins',
                      },
                      usePointStyle: true,
                      pointStyle: 'rect',
                    },
                    position: 'bottom',
                    align: 'end',
                  },
                },
                interaction: {
                  intersect: false,
                },
                stacked: false,
                elements: {
                  point: {
                    radius: 0,
                  },
                  line: {
                    borderWidth: 1.5,
                    tension: 0.4,
                  },
                },
                scales: {
                  y: {
                    ticks: {
                      color: '#B8B8B8',
                      font: {
                        size: 12,
                        weight: 600,
                        family: 'Poppins',
                      },
                    },
                    title: {
                      color: '#B8B8B8',
                      font: {
                        size: 12,
                        weight: 600,
                        family: 'Poppins',
                      },
                    },
                  },
                  x: {
                    ticks: {
                      color: '#B8B8B8',
                      font: {
                        size: 12,
                        weight: 600,
                        family: 'Poppins',
                      },
                    },
                    title: {
                      display: true,
                      color: '#B8B8B8',
                      font: {
                        size: 12,
                        weight: 600,
                        family: 'Poppins',
                      },
                    },
                  },
                },
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default ChartPerformanceRackList;
