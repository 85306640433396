import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { fetchSubsidiary } from '../../../../../../api/menu';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';
import Subsidiary from '../../../../../../components/shared/Subsidiary';
import MenuPerformance from '../Components/performance/MenuPerformance';
import CategoryMenuDesktop from './CategoryMenuDesktop';
import CategoryMenuMobile from './CategoryMenuMobile';
import TableCategoryMenu from './Desktop/TableCategoryMenu';

const CategoryMenu = (props) => {
  const {
    desktopMode,
    dummyDataTable,
    roadHirarki,
    setRoadHirarki,
    // *------------
    setPage,
    setCategoryMenu,
    // *--- Notif ---
    notif,
    notifTitle,
    setNotif,
    setNotifTitle,
    // *-date & time
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    // *-TAble
    tableRowPerPageValues,
    tableRowPerPage,
    setTableRowPerPage,
    tableCurrentPage,
    setTableCurrentPage,
    tableTotalItems,
    setTableTotalItems,
    tableSearch,
    setTableSearch,
    sortTable,
    setSortTable,
    isSortAsc,
    setIsSortAsc,
    tableDatas,
    setTableDatas,
    tableCategoryFilterDatas,
    setTableCategoryFilterDatas,
    isLoadingData,
    // *--- subsidiary ---
    subsidiaryFilterValue,
    setSubsidiaryFilterValue,
    filterCategorySubsidiary,
    setFilterCategorySubsidiary,
  } = props;

  const [isLoadingSubsidiary, setIsLoadingSubsidiary] = useState(false);
  const [subsidiaryDatas, setSubsidiaryDatas] = useState([]);

  const [subsidiaryAll, setSubsidiaryAll] = useState([]);
  const [subsidiaryMoment, setSubsidiaryMoment] = useState([]);

  // !------------ subsidiary datas ----------

  useEffect(() => {
    const getAllSubsidiary = async () => {
      setIsLoadingSubsidiary(true);
      try {
        const {
          data: {
            data: { subsidiaries: dataFetchSubsidiary },
          },
        } = await fetchSubsidiary();

        setSubsidiaryDatas(dataFetchSubsidiary);
      } catch (err) {
        console.error();
        setSubsidiaryDatas([]);
      } finally {
        setIsLoadingSubsidiary(false);
      }
    };
    getAllSubsidiary();
  }, []);

  // !-----------------------------------Subsidiary -----------------------------------------
  useEffect(() => {
    if (subsidiaryAll !== subsidiaryMoment) {
      let subsidiaryChoose = [];

      subsidiaryAll.map((item, index) => {
        subsidiaryChoose.push(item.id);
      });

      setFilterCategorySubsidiary(subsidiaryChoose);
      setSubsidiaryFilterValue(subsidiaryAll);
      setSubsidiaryMoment(subsidiaryAll);
    }
  }, [subsidiaryAll, subsidiaryMoment]);

  // !---------------- HANDLER ---------------
  const handleToAllMenu = () => {
    setPage('menu');
  };
  const handleAddButton = () => {
    setCategoryMenu('add');
  };
  const handleFilterButton = () => {};
  const handleClickRow = (subsidiary, category, categoryId) => {
    setPage('subCategory');
    setRoadHirarki({
      ...roadHirarki,
      subsidiary: subsidiary.name,
      subsidiaryId: subsidiary.id,
      category: category,
      categoryId: categoryId,
    });
  };
  const handleClickName = (event, subsidiary, category, categoryId) => {
    event.stopPropagation();
    setCategoryMenu('details');
    setRoadHirarki({
      ...roadHirarki,
      subsidiary: subsidiary.name,
      subsidiaryId: subsidiary.id,
      category: category,
      categoryId: categoryId,
    });
  };

  // *! ---- Filter ---

  const [isOpenTableFilter, setIsOpenTableFilter] = useState(false);

  const [autoCompleteFilter, setAutoCompleteFilter] = useState({
    subsidiary: false,
    subsidiaryOpen: false,
    type: false,
    typeOpen: false,
  });

  const handelSaveFilter = () => {
    let subsidiaryChoose = [];
    let typeChoose = [];

    subsidiaryFilterValue.map((item, index) => {
      subsidiaryChoose.push(item.id);
    });
    setFilterCategorySubsidiary(subsidiaryChoose);
    setIsOpenTableFilter(false);
  };

  const hadleResetSubsidiary = () => {
    setSubsidiaryFilterValue(subsidiaryDatas);
    setAutoCompleteFilter({
      ...autoCompleteFilter,
      subsidiary: true,
      subsidiaryOpen: false,
    });
  };

  return (
    <>
      <CustomNotifSuccess
        text={notifTitle}
        open={notif === 'deletecategory'}
        onClose={() => {
          setNotif('');
          setNotifTitle('');
        }}
      />
      <CustomNotifSuccess
        text={notifTitle}
        open={notif === 'addcategory'}
        onClose={() => {
          setNotif('');
          setNotifTitle('');
        }}
      />
      {desktopMode ? (
        <CategoryMenuDesktop
          {...{ ...props }}
          {...{
            subsidiaryAll,
            setSubsidiaryAll,
            subsidiaryMoment,
            setSubsidiaryMoment,
          }}
          {...{
            handleToAllMenu,
            handleAddButton,
            handleFilterButton,
            handleClickRow,
            handleClickName,
          }}
          {...{
            subsidiaryDatas,
            subsidiaryFilterValue,
            setSubsidiaryFilterValue,
            autoCompleteFilter,
            setAutoCompleteFilter,
            isOpenTableFilter,
            setIsOpenTableFilter,
            handelSaveFilter,
            hadleResetSubsidiary,
          }}
        />
      ) : (
        <CategoryMenuMobile
          {...{ ...props }}
          {...{
            handleToAllMenu,
            handleAddButton,
            handleFilterButton,
            handleClickRow,
            handleClickName,
          }}
        />
      )}
    </>
  );
};
export default CategoryMenu;
