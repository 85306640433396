import { Box, useTheme } from '@mui/system';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';

const BackButton = (props) => {
  const theme = useTheme();
  const { handleClick } = props;
  const desktopMode = useMediaQuery('(min-width:832px)');
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: theme.palette.primary.main,
        width: desktopMode ? '36px' : '28px',
        height: desktopMode ? '36px' : '28px',
        borderRadius: '50%',
        cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      <KeyboardArrowLeftIcon sx={{ color: 'white' }} />
    </Box>
  );
};
export default BackButton;
