import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  TextField,
  Dialog,
  Autocomplete,
  useTheme,
  styled,
  Switch,
} from '@mui/material';
import CustomInput from '../../../../../../components/shared/CustomInput';
import BackButton from '../../../../../../components/shared/BackButton';
import dayjs from 'dayjs';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import CustomCard from '../../../../../../components/shared/CustomCard';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import CustomButton from '../../../../../../components/shared/CustomButton';
import { useEffect, useState } from 'react';
import CustomDeleteConfirm from '../../../../../../components/shared/CustomDeleteConfirm';
import { uploadImage } from '../../../../../../api/storage';
import CustomEditConfirm from '../../../../../../components/shared/CustomEditConfirm';
import {
  deleteCategoryMenu,
  updateCategoryMenu,
} from '../../../../../../api/menu';
import CategoryMenuEditDesktop from './CategoryMenuEditDesktop';
import CategoryMenuEditMobile from './CategoryMenuEditMobile';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const CategoryMenuEdit = (props) => {
  const {
    desktopMode,
    roadHirarki,
    setRoadHirarki,
    // *--- Page
    setPage,
    setCategoryMenu,
    // *--- Notif ---
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
    // *--- Data Value ---
    categoryEditInputDatas,
    setCategoryEditInputDatas,
    //*- Category Data
    typeCategory,
    typeCategoryLoading,
    subsidiaryCategory,
    subsidiaryCategoryLoading,
  } = props;
  const today = dayjs(new Date());
  const theme = useTheme();

  // !---------------- Confirm ----------------
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [isEditConfirm, setIsEditConfirm] = useState(false);

  // !------------------------- SAVE VALIDATION ----------------------------
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);

  useEffect(() => {
    if (categoryEditInputDatas.name.length >= 5) {
      if (
        categoryEditInputDatas.description === '' ||
        categoryEditInputDatas.description.length >= 20
      ) {
        return setIsSubmitAvailable(true);
      } else {
        return setIsSubmitAvailable(false);
      }
    } else {
      return setIsSubmitAvailable(false);
    }
  }, [categoryEditInputDatas.name, categoryEditInputDatas.description]);

  // !--------------------- HANDLER -----------------------

  const handleBackButton = () => {
    setCategoryMenu('details');
  };

  const handleEdit = () => {
    setIsEditConfirm(!isEditConfirm);
  };
  const handleEditFix = () => {
    let data = {
      namaKategori: categoryEditInputDatas.name,
      status: 'Y',
      subsidiaryId: categoryEditInputDatas.subsidiaryId,
      schedule: dayjs(categoryEditInputDatas.schedule.$d).format('YYYY-MM-DD'),
      opsiKategori: parseInt(categoryEditInputDatas.type.iDopsi),
    };
    if (categoryEditInputDatas.description !== '') {
      data['des'] = categoryEditInputDatas.description;
    }
    if (categoryEditInputDatas.photoUrls.length !== 0) {
      data['gambar'] = categoryEditInputDatas.photoUrls.join(',');
    }

    updateCategoryMenu(roadHirarki.categoryId, data)
      .then((result) => {
        setRoadHirarki({
          ...roadHirarki,
          subsidiary: result.data.data.subsidiary.name,
          subsidiaryId: result.data.data.subsidiary.id,
          category: result.data.data.namaKategori,
          categoryId: result.data.data.idKategori,
        });
        setPage('Category');
        setCategoryMenu('details');
        setNotif('editcategory');
        setNotifTitle(
          `${result.data.data.namaKategori} has been successfully edited`
        );
      })
      .catch((err) => console.error(err));
  };

  const handleDelete = () => {
    setIsDeleteConfirm(true);
  };
  const handleDeleteFix = () => {
    deleteCategoryMenu(roadHirarki.categoryId)
      .then((result) => {
        setNotifTitle(
          `${result.data.data.namaKategori} has been successfully deleted`
        );
        setNotif('deletecategory');
        setCategoryMenu('');
        setRoadHirarki({
          subsidiary: '',
          subsidiaryId: 0,
          category: '',
          categoryId: 0,
          subCategory: '',
          subCategoryId: 0,
          product: '',
          productId: 0,
          miniSubCategory: '',
          miniSubCategoryId: 0,
        });
      })
      .catch((err) => console.error(err));
  };

  const handleAddImage = (e) => {
    if (e.target.files[0].size > 2000000) {
      setCategoryEditInputDatas({
        ...categoryEditInputDatas,
        isMaxSizePhoto: true,
      });
    } else {
      let datas = {
        image: e.target.files[0],
        resource: 'category',
      };
      uploadImage(datas).then((res) => {
        setCategoryEditInputDatas({
          ...categoryEditInputDatas,
          photoUrls: [...categoryEditInputDatas.photoUrls, res.data.data.path],
          isMaxSizePhoto: false,
        });
      });
    }
  };

  const handleDeleteImage = (link) => {
    setCategoryEditInputDatas({
      ...categoryEditInputDatas,
      photoUrls: categoryEditInputDatas.photoUrls.filter(
        (data) => data !== link
      ),
      indexPreviePhoto: 0,
    });
  };

  const handlePreviewImage = (link) => {
    setCategoryEditInputDatas({
      ...categoryEditInputDatas,
      linkPreviewPhoto: link,
      isPreviewPhoto: 'true',
    });
  };

  const handleClosePreviewImage = () => {
    setCategoryEditInputDatas({
      ...categoryEditInputDatas,
      isPreviewPhoto: !categoryEditInputDatas.isPreviewPhoto,
    });
  };

  return (
    <>
      <CustomEditConfirm
        open={isEditConfirm}
        onClose={() => setIsEditConfirm(false)}
        handleClickCancel={() => setIsEditConfirm(false)}
        handleClickEdit={handleEditFix}
        title="Edit Category"
        body={
          <Box sx={{ textAlign: 'center', mb: '40px', mt: '8px' }}>
            <Typography>Are you sure you want to save the changes?</Typography>
          </Box>
        }
      />
      <CustomDeleteConfirm
        open={isDeleteConfirm}
        onClose={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickCancel={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Category"
        body={
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: '600' }}>
                {roadHirarki.category}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: '52px' }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />

      {desktopMode ? (
        <CategoryMenuEditDesktop
          {...{ ...props }}
          {...{ isSubmitAvailable, setIsSubmitAvailable }}
          {...{ today, theme, AntSwitch }}
          {...{
            handleBackButton,
            handleDelete,
            handleEdit,
            handleAddImage,
            handleDeleteImage,
            handlePreviewImage,
            handleClosePreviewImage,
          }}
        />
      ) : (
        <CategoryMenuEditMobile
          {...{ ...props }}
          {...{ today, theme, AntSwitch }}
          {...{
            handleBackButton,
            handleDelete,
            handleEdit,
            handleAddImage,
            handleDeleteImage,
            handlePreviewImage,
            handleClosePreviewImage,
          }}
        />
      )}
    </>
  );
};
export default CategoryMenuEdit;
