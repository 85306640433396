import { createSlice } from "@reduxjs/toolkit";

export const dataMiniHubState = createSlice({
  name: "dataMiniHub",
  initialState: {
    masterHubName: "",
    subHubName: "",
    isLoadingMiniHub: false,
    idToko: "",
  },
  reducers: {
    changeMasterHubName: (state, action) => {
      state.masterHubName = action.payload;
    },
    changeSubHubName: (state, action) => {
      state.subHubName = action.payload;
    },
    changeIsLoading: (state, action) => {
      state.isLoadingMiniHub = action.payload;
    },
    changeIdToko: (state, action) => {
      state.idToko = action.payload;
    },
  },
});

export const {
  changeMasterHubName,
  changeSubHubName,
  changeIsLoading,
  changeIdToko,
} = dataMiniHubState.actions;

export const getAllDataMiniHub = (state) => state.dataMiniHub;

export default dataMiniHubState.reducer;
