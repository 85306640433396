import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';
import { useGeolocated } from 'react-geolocated';
import { useDispatch, useSelector } from 'react-redux';
import PinDropIcon from '@mui/icons-material/PinDrop';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import LocationSearchingRoundedIcon from '@mui/icons-material/LocationSearchingRounded';
import { Draggable, Map, Marker, ZoomControl } from 'pigeon-maps';
import {
  changeAddress,
  changeCenter,
  changeCoordinate,
  changeMapType,
  changePlaceGetByName,
  getSubHubEditAll,
  submitAddresMap,
} from '../../../../../../../../store/hub/subHub/subHubEdit';
import CustomInput from '../../../../../../../../components/shared/CustomInput';
import { ReactComponent as CameraLocationToMarker } from '../../../../../../../../assets/location-to-marker.svg';
import CustomButton from '../../../../../../../../components/shared/CustomButton';

const SetupAddressSubHub = ({ setIsOpen }) => {
  const dispatch = useDispatch();
  const {
    mapInputType,
    placeGetByName,
    placesSearchByName,
    placesSearchByNameLoading: isPlacesSerachByNameLoading,
    address: desAddress,
    center,
    coordinate: anchor,
    placeGetByCoordinate,
    placeGetByCoordinateLoading: isPlaceGetByCoordinateLoading,
  } = useSelector(getSubHubEditAll);

  const [anchorByMapFix, setAnchorByMapFix] = useState([]);

  const [currentCoordinateLat, setCurrentCoordinateLat] = useState(0);
  const [currentCoordinateLong, setCurrentCoordinateLong] = useState(0);

  const [manualSearch, setManualSearch] = useState('');
  const [manualSearchFix, setManualSearchFix] = useState('');

  const [isDetailMarker, setIsDetailMarker] = useState(false);

  const [zoom, setZoom] = useState(17);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
    });

  const debouncedManualSearch = useRef(
    debounce((value) => {
      setManualSearchFix(value);
    }, 1000)
  ).current;

  const debouncedGetByMap = useRef(
    debounce((value) => {
      setAnchorByMapFix(value);
    }, 2000)
  ).current;

  function handleGetCurrentCoordinate() {
    if (isGeolocationAvailable) {
      setCurrentCoordinateLat(coords.latitude);
      setCurrentCoordinateLong(coords.longitude);
      dispatch(changeCoordinate([coords.latitude, coords.longitude]));
      dispatch(changeCenter([coords.latitude, coords.longitude]));
      if (mapInputType !== 'manual') {
        debouncedGetByMap([coords.latitude, coords.longitude]);
      }
    }
  }

  const handleResetMarker = () => {
    dispatch(changeCoordinate(center));
    if (mapInputType !== 'manual') {
      debouncedGetByMap(center);
    }
  };

  const handleResetZoom = () => {
    dispatch(changeCenter(anchor));
  };

  const handleClickMarker = () => {
    setIsDetailMarker(true);
  };
  const handleEnter = () => {
    dispatch(submitAddresMap({ address: desAddress, coordinate: anchor }));
    setIsOpen(false);
  };

  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItem: 'center',
          gap: 1,
          p: '8px',
          borderRadius: '8px',
          bgcolor: '#F5F5F5',
        }}
      >
        <Box
          onClick={() => dispatch(changeMapType('manual'))}
          sx={[
            {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '270px',
              height: '44px',
              borderRadius: '8px',
              cursor: 'pointer',
            },
            mapInputType === 'manual'
              ? {
                  bgcolor: '#FFFFFF',
                }
              : {
                  bgcolor: '#F5F5F5',
                },
          ]}
        >
          <Typography
            sx={[
              mapInputType === 'manual'
                ? {
                    color: '#51B15C',
                  }
                : {
                    color: 'black',
                  },
            ]}
          >
            Manual input
          </Typography>
        </Box>
        <Box
          onClick={() => dispatch(changeMapType('map'))}
          sx={[
            {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: '#FFFFFF',
              width: '270px',
              height: '44px',
              borderRadius: '8px',
              cursor: 'pointer',
            },
            mapInputType === 'map'
              ? {
                  bgcolor: '#FFFFFF',
                }
              : {
                  bgcolor: '#F5F5F5',
                },
          ]}
        >
          <Typography
            sx={[
              mapInputType === 'map'
                ? {
                    color: '#51B15C',
                  }
                : {
                    color: 'black',
                  },
            ]}
          >
            Select by map
          </Typography>
        </Box>
      </Box>

      {/* ISI */}

      <Box>
        {mapInputType === 'manual' ? (
          <Box>
            {/* //* City n distric */}
            <Box sx={{ mt: '12px' }}>
              <Typography>City & District*</Typography>
              <Autocomplete
                value={placeGetByName}
                onChange={(_, newValue) => {
                  dispatch(changePlaceGetByName(newValue));
                }}
                inputValue={manualSearch}
                onInputChange={(_, newInputValue) => {
                  setManualSearch(newInputValue);
                  debouncedManualSearch(newInputValue);
                }}
                options={placesSearchByName}
                loading={isPlacesSerachByNameLoading}
                getOptionLabel={(option) => option.formatted}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select City"
                    sx={{ width: '100%', bgcolor: '#FAFAFA', mt: '12px' }}
                  />
                )}
              />
            </Box>

            {/* //* Address */}
            <Box sx={{ mt: '12px' }}>
              <Typography>Address*</Typography>
              <CustomInput
                value={desAddress}
                onChange={(e) => dispatch(changeAddress(e.target.value))}
                placeholder="Enter address"
                sx={{ width: '100%', bgcolor: '#FAFAFA', mt: '12px' }}
              />
            </Box>
            <Typography sx={{ mt: '12px' }}>Pinpoint</Typography>

            {/* //* MAP */}
            <Box sx={{ position: 'relative', mt: '12px' }}>
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: '+5',
                  bottom: 4,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    mb: '8px',
                    ml: '8px',
                    p: '4px',
                    borderRadius: '8px',
                  }}
                >
                  <Box
                    onClick={handleGetCurrentCoordinate}
                    sx={{
                      width: '24px',
                      height: '24px',
                      bgcolor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      boxShadow: '8px',
                    }}
                  >
                    <LocationSearchingRoundedIcon
                      sx={{
                        fontSize: '20px',
                        color: '#51B15C',
                      }}
                    />
                  </Box>
                  <Box
                    onClick={handleResetZoom}
                    sx={{
                      width: '24px',
                      height: '24px',
                      bgcolor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      boxShadow: '8px',
                    }}
                  >
                    <CameraLocationToMarker
                      style={{ fontSize: '20px', color: '#51B15C' }}
                    />
                  </Box>
                  <Box
                    onClick={handleResetMarker}
                    sx={{
                      width: '24px',
                      height: '24px',
                      bgcolor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      boxShadow: '8px',
                    }}
                  >
                    <PinDropIcon sx={{ fontSize: '20px', color: '#F64444' }} />
                  </Box>
                </Box>
              </Box>
              <Map
                height={300}
                center={center}
                zoom={zoom}
                onBoundsChanged={({ center, zoom }) => {
                  dispatch(changeCenter(center));
                  setZoom(zoom);
                }}
              >
                <ZoomControl />
                {anchor.length !== 0 && (
                  <Draggable
                    anchor={anchor}
                    onDragEnd={(anchor) => dispatch(changeCoordinate(anchor))}
                  >
                    <LocationOnRoundedIcon
                      color="error"
                      sx={{ fontSize: '30px' }}
                    />
                  </Draggable>
                )}
              </Map>
            </Box>

            {/* //* Submit */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                mt: '24px',
              }}
            >
              <CustomButton
                disabled={!desAddress || !placeGetByName}
                variant="contained"
                onClick={handleEnter}
                sx={{ width: '198px', height: '48px' }}
              >
                Enter
              </CustomButton>
            </Box>
          </Box>
        ) : (
          <Box>
            {/* //* MAP */}
            <Box sx={{ position: 'relative', mt: '12px' }}>
              {/* {isDetailMarker && (
                <Box
                  sx={{
                    position: 'absolute',
                    bgcolor: 'white',
                    right: 1,
                    zIndex: '+5',
                    mt: '8px',
                    mr: '8px',
                    p: '4px',
                    borderRadius: '8px',
                    width: '132px',
                    height: '44px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography color="primary" sx={{ fontSize: '12px' }}>
                    Hub Lulu
                  </Typography>
                </Box>
              )} */}
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: '+5',
                  bottom: 4,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    mb: '8px',
                    ml: '8px',
                    p: '4px',
                    borderRadius: '8px',
                  }}
                >
                  <Box
                    onClick={handleGetCurrentCoordinate}
                    sx={{
                      width: '24px',
                      height: '24px',
                      bgcolor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      boxShadow: '8px',
                    }}
                  >
                    <LocationSearchingRoundedIcon
                      sx={{
                        fontSize: '20px',
                        color: '#51B15C',
                      }}
                    />
                  </Box>
                  <Box
                    onClick={handleResetZoom}
                    sx={{
                      width: '24px',
                      height: '24px',
                      bgcolor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      boxShadow: '8px',
                    }}
                  >
                    <CameraLocationToMarker
                      style={{ fontSize: '20px', color: '#51B15C' }}
                    />
                  </Box>
                  <Box
                    onClick={handleResetMarker}
                    sx={{
                      width: '24px',
                      height: '24px',
                      bgcolor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      boxShadow: '8px',
                    }}
                  >
                    <PinDropIcon sx={{ fontSize: '20px', color: '#F64444' }} />
                  </Box>
                </Box>
              </Box>

              <Map
                height={300}
                center={center}
                zoom={zoom}
                onBoundsChanged={({ center, zoom }) => {
                  dispatch(changeCenter(center));
                  setZoom(zoom);
                }}
              >
                {/* {zoom > 15 && (
                  <Marker anchor={[3.5465302399564416, 98.68839406187627]}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <LocationOnRoundedIcon
                        color="primary"
                        sx={{
                          fontSize: `${30 - (20 - Math.floor(zoom))}px`,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleClickMarker()}
                      />
                      {zoom >= 17 && (
                        <Typography
                          color="primary"
                          sx={{ fontSize: '12px', textAlign: 'center' }}
                        >
                          hub lulu
                        </Typography>
                      )}
                    </Box>
                  </Marker>
                )} */}

                <ZoomControl />
                {anchor.length !== 0 && (
                  <Draggable
                    anchor={anchor}
                    onDragEnd={(anchor) => {
                      dispatch(changeCoordinate(anchor));
                      debouncedGetByMap(anchor);
                    }}
                  >
                    <LocationOnRoundedIcon
                      color="error"
                      sx={{ fontSize: '40px' }}
                    />
                  </Draggable>
                )}

                {/* <Marker anchor={[3.5468407802842656, 98.69077586320272]}>
              <LocationOnRoundedIcon
                color="primary"
                sx={{ fontSize: '30px' }}
              />
            </Marker> */}
              </Map>
            </Box>

            {/* //* City n distric */}
            <Box sx={{ mt: '12px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography>City & District*</Typography>
                {anchor !== anchorByMapFix ||
                  (isPlaceGetByCoordinateLoading && (
                    <CircularProgress size={12} />
                  ))}
              </Box>
              <CustomInput
                placeholder="Select city"
                value={
                  placeGetByCoordinate ? placeGetByCoordinate?.formatted : ''
                }
                disabled={true}
                sx={{
                  width: '100%',
                  bgcolor: placeGetByCoordinate?.formatted
                    ? '#FAFAFA'
                    : '#E4E4E4',
                  mt: '12px',
                }}
              />
              {/* <Box
            sx={{
              width: '100%',
              bgcolor: '#FAFAFA',
              mt: '12px',
              p: '20px 12px',
              borderRadius: '8px',
            }}
          >
            {placeGetByCoordinate ? (
              <Typography>{placeGetByCoordinate?.formatted}</Typography>
            ) : (
              <Typography sx={{ color: '#9E9D9D' }}>
                Selected by map
              </Typography>
            )}
          </Box> */}
            </Box>

            {/* //* Address */}
            <Box sx={{ mt: '12px' }}>
              <Typography>Address*</Typography>
              <CustomInput
                value={desAddress}
                onChange={(e) => dispatch(changeAddress(e.target.value))}
                placeholder="Enter address"
                sx={{ width: '100%', bgcolor: '#FAFAFA', mt: '12px' }}
              />
            </Box>

            {/* //* Submit */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                mt: '24px',
              }}
            >
              <CustomButton
                disabled={!desAddress || !placeGetByCoordinate}
                variant="contained"
                onClick={handleEnter}
                sx={{ width: '198px', height: '48px' }}
              >
                Enter
              </CustomButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default SetupAddressSubHub;
