import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import RackHubMain from './Main/RackHubMain';
import { getAllRackhub } from '../../../../../store/racks/rackHub';
import RackHubDetail from './Detail/RackHubDetail';
import RackHubEdit from './Edit/RackHubEdit';
import RackHubItemQr from './RackHubItemQr/RackHubItemQr';
import RackHubItem from './RackHubItem/RackHubItem';

const RackRouter = () => {
  const { page } = useSelector(getAllRackhub);
  return (
    <Box>
      {page === '' && <RackHubMain />}
      {page === 'detail' && <RackHubDetail />}
      {page === 'edit' && <RackHubEdit />}
      {page === 'itemQr' && <RackHubItemQr />}
      {page === 'item' && <RackHubItem />}
    </Box>
  );
};
export default RackRouter;
