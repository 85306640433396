import {
  Autocomplete,
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CustomCard from '../../../../../../components/shared/CustomCard';
import BackButton from '../../../../../../components/shared/BackButton';
import { ProductPerfomance, SeeProduct } from '../DesktopComponent';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TableProductProduct from './Desktop/TableProductProduct';
import { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as DollarIcon } from '../../../../../../assets/dollar.svg';
import { ReactComponent as BoxIcon } from '../../../../../../assets/box.svg';
import { ReactComponent as NoImage } from '../../../../../../assets/no-image.svg';
import { ReactComponent as SalesIcon } from '../../../../../../assets/sales.svg';

import InfiniteScroll from 'react-infinite-scroll-component';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';
import CustomBottomDrawer from '../../../../../../components/shared/CustomBottomDrawer';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import { ReactComponent as CloseFilledIcon } from '../../../../../../assets/closeFilledIcon.svg';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CustomButton from '../../../../../../components/shared/CustomButton';

const ProductProductMobile = (props) => {
  const {
    handleBackButton,
    handleClickToCategory,
    handleClickToSubCategory,
    roadHirarki,
    tableSearch,
    setTableSearch,
    theme,
    handleClickAdd,
    tableTotalItems,
    tableDatas,
    handleClickRow,
    handleClickName,
    // *---Filter ---
    allDataCategory,
    allDataSubCategory,
    allDataMiniSubCategory,

    categoryFilterData,
    setCategoryFilterData,
    subCategoryFilterData,
    setSubCategoryFilterData,
    miniCategoryFilterData,
    setMiniCategoryFilterData,

    categoryFilterChoose,
    setCategoryFilterChoose,
    subCategoryFilterChoose,
    setSubCategoryFilterChoose,
    miniCategoryFilterChoose,
    setMiniCategoryFilterChoose,

    categoryFilterLoading,
    setCategoryFilterLoading,
    subCategoryFilterLoading,
    setSubCategoryFilterLoading,
    miniCategoryFilterLoading,
    setMiniCategoryFilterLoading,

    filterDataAwait,
    setFilterDataAwait,
    autoCompleteFilter,
    setAutoCompleteFilter,

    handleClickSaveFilter,
    handleCliskResetFilter,

    isOpenTableFilter,
    setIsOpenTableFilter,
  } = props;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <Grid container columns={12} spacing={2}>
      <CustomBottomDrawer
        open={isOpenTableFilter}
        onClose={() => setIsOpenTableFilter(false)}
        handleClose={() => setIsOpenTableFilter(false)}
        title="Filter"
        additionalOption="Reset"
        handleClickAdditionalOption={handleCliskResetFilter}
      >
        {/* Cattegory Filter */}
        <Box>
          <Autocomplete
            sx={{
              '.MuiAutocomplete-hasClearIcon': {
                display: 'none',
              },
            }}
            clearIcon={
              autoCompleteFilter.category &&
              !autoCompleteFilter.categoryOpen ? (
                false
              ) : (
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    setCategoryFilterChoose([]);
                    setAutoCompleteFilter({
                      ...autoCompleteFilter,
                      category: false,
                    });
                    setSubCategoryFilterChoose([]);
                    setMiniCategoryFilterChoose([]);
                  }}
                />
              )
            }
            multiple={
              autoCompleteFilter.category && !autoCompleteFilter.categoryOpen
                ? false
                : true
            }
            limitTags={
              autoCompleteFilter.category && !autoCompleteFilter.categoryOpen
                ? 0
                : 1
            }
            options={
              autoCompleteFilter.category && !autoCompleteFilter.categoryOpen
                ? allDataCategory
                : categoryFilterData
            }
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Box
                  sx={{
                    bgcolor: 'white',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                    p: '4px 8px',
                    m: '2px',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  {option.namaKategori}
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      setCategoryFilterChoose(
                        categoryFilterChoose.filter(
                          (item) => item.idKategori !== option.idKategori
                        )
                      )
                    }
                  >
                    <CloseFilledIcon />
                  </Box>
                </Box>
              ))
            }
            getOptionLabel={(option) => option.namaKategori}
            value={
              autoCompleteFilter.category && !autoCompleteFilter.categoryOpen
                ? allDataCategory[0]
                : categoryFilterChoose
            }
            onChange={(event, value) => {
              if (
                categoryFilterChoose.filter(
                  (item) =>
                    item.idKategori === value[value.length - 1].idKategori
                ).length !== 0
              ) {
                setCategoryFilterChoose(
                  categoryFilterChoose.filter(
                    (item) =>
                      item.idKategori !== value[value.length - 1].idKategori
                  )
                );
              } else {
                setCategoryFilterChoose(value);
              }
              setSubCategoryFilterChoose([]);
              setMiniCategoryFilterChoose([]);
              if (value?.length === categoryFilterData?.length) {
                setAutoCompleteFilter({
                  ...autoCompleteFilter,
                  category: true,
                });
              } else {
                setAutoCompleteFilter({
                  ...autoCompleteFilter,
                  category: false,
                });
              }
            }}
            onOpen={() => {
              setAutoCompleteFilter({
                ...autoCompleteFilter,
                categoryOpen: true,
              });
            }}
            onClose={() => {
              setAutoCompleteFilter({
                ...autoCompleteFilter,
                categoryOpen: false,
              });
            }}
            selectOnFocus
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <li {...props} sx={{ color: 'black' }} key={option.idKategori}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={
                    categoryFilterChoose.filter(
                      (item) => item.idKategori === option.idKategori
                    ).length !== 0
                  }
                />
                {option.namaKategori}
              </li>
            )}
            style={{ width: '100%', marginTop: '12px' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                placeholder="Enter Category"
                sx={{ bgcolor: '#FAFAFA' }}
              />
            )}
          />
        </Box>
        {/* Sub Cateegory Filte */}
        <Box>
          <Autocomplete
            sx={{
              '.MuiAutocomplete-hasClearIcon': {
                display: 'none',
              },
            }}
            clearIcon={
              autoCompleteFilter.subCategory &&
              !autoCompleteFilter.subCategoryOpen ? (
                false
              ) : (
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    setSubCategoryFilterChoose([]);
                    setAutoCompleteFilter({
                      ...autoCompleteFilter,
                      subCategory: false,
                    });
                    setMiniCategoryFilterChoose([]);
                  }}
                />
              )
            }
            multiple={
              autoCompleteFilter.subCategory &&
              !autoCompleteFilter.subCategoryOpen
                ? false
                : true
            }
            limitTags={
              autoCompleteFilter.subCategory &&
              !autoCompleteFilter.subCategoryOpen
                ? 0
                : 1
            }
            options={
              autoCompleteFilter.subCategory &&
              !autoCompleteFilter.subCategoryOpen
                ? allDataSubCategory
                : subCategoryFilterData
            }
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Box
                  sx={{
                    bgcolor: 'white',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                    p: '4px 8px',
                    m: '2px',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  {option.subkategori}
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      setSubCategoryFilterChoose(
                        subCategoryFilterChoose.filter(
                          (item) => item.idSubkategori !== option.idSubkategori
                        )
                      )
                    }
                  >
                    <CloseFilledIcon />
                  </Box>
                </Box>
              ))
            }
            getOptionLabel={(option) => option.subkategori}
            value={
              autoCompleteFilter.subCategory &&
              !autoCompleteFilter.subCategoryOpen
                ? allDataSubCategory[0]
                : subCategoryFilterChoose
            }
            onChange={(event, value) => {
              if (
                subCategoryFilterChoose.filter(
                  (item) =>
                    item.idSubkategori === value[value.length - 1].idSubkategori
                ).length !== 0
              ) {
                setSubCategoryFilterChoose(
                  subCategoryFilterChoose.filter(
                    (item) =>
                      item.idSubkategori !==
                      value[value.length - 1].idSubkategori
                  )
                );
                if (
                  subCategoryFilterData.length ===
                  subCategoryFilterChoose.length
                ) {
                  setAutoCompleteFilter({
                    ...autoCompleteFilter,
                    subCategory: true,
                  });
                } else {
                  setAutoCompleteFilter({
                    ...autoCompleteFilter,
                    subCategory: false,
                  });
                }
              } else {
                setSubCategoryFilterChoose(value);
                if (
                  subCategoryFilterData.length ===
                  subCategoryFilterChoose.length
                ) {
                  setAutoCompleteFilter({
                    ...autoCompleteFilter,
                    subCategory: true,
                  });
                } else {
                  setAutoCompleteFilter({
                    ...autoCompleteFilter,
                    subCategory: false,
                  });
                }
              }
              setMiniCategoryFilterChoose([]);
            }}
            onOpen={() => {
              setAutoCompleteFilter({
                ...autoCompleteFilter,
                subCategoryOpen: true,
              });
            }}
            onClose={() => {
              setAutoCompleteFilter({
                ...autoCompleteFilter,
                subCategoryOpen: false,
              });
            }}
            selectOnFocus
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <li {...props} sx={{ color: 'black' }} key={option.idSubkategori}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={
                    subCategoryFilterChoose.filter(
                      (item) => item.idSubkategori === option.idSubkategori
                    ).length !== 0
                  }
                />
                {option.subkategori}
              </li>
            )}
            style={{ width: '100%', marginTop: '12px' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                placeholder="Enter SubCategory"
                sx={{ bgcolor: '#FAFAFA' }}
              />
            )}
          />
        </Box>
        {/* Mini Caategory Fillterr */}
        <Box>
          <Autocomplete
            sx={{
              '.MuiAutocomplete-hasClearIcon': {
                display: 'none',
              },
            }}
            clearIcon={
              autoCompleteFilter.miniCategory &&
              !autoCompleteFilter.miniCategoryOpen ? (
                false
              ) : (
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    setMiniCategoryFilterChoose([]);
                    setAutoCompleteFilter({
                      ...autoCompleteFilter,
                      miniCategory: false,
                    });
                  }}
                />
              )
            }
            multiple={
              autoCompleteFilter.miniCategory &&
              !autoCompleteFilter.miniCategoryOpen
                ? false
                : true
            }
            limitTags={
              autoCompleteFilter.miniCategory &&
              !autoCompleteFilter.miniCategoryOpen
                ? 0
                : 1
            }
            options={
              autoCompleteFilter.miniCategory &&
              !autoCompleteFilter.miniCategoryOpen
                ? allDataSubCategory
                : miniCategoryFilterData
            }
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Box
                  sx={{
                    bgcolor: 'white',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                    p: '4px 8px',
                    m: '2px',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  {option.name}
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      setMiniCategoryFilterChoose(
                        miniCategoryFilterChoose.filter(
                          (item) => item.id !== option.id
                        )
                      )
                    }
                  >
                    <CloseFilledIcon />
                  </Box>
                </Box>
              ))
            }
            getOptionLabel={(option) => option.name}
            value={
              autoCompleteFilter.miniCategory &&
              !autoCompleteFilter.miniCategoryOpen
                ? allDataMiniSubCategory[0]
                : miniCategoryFilterChoose
            }
            onChange={(event, value) => {
              // if (
              //   miniCategoryFilterChoose.filter(
              //     (item) => item.id === value[value.length - 1].id
              //   ).length !== 0
              // ) {
              //   setMiniCategoryFilterChoose(
              //     miniCategoryFilterChoose.filter(
              //       (item) => item.id !== value[value.length - 1].id
              //     )
              //   );
              // } else {
              //   setMiniCategoryFilterChoose(value);
              // }
              setMiniCategoryFilterChoose(value);
              // if (value?.length === miniCategoryFilterChoose?.length) {
              //   setAutoCompleteFilter({
              //     ...autoCompleteFilter,
              //     miniCategory: true,
              //   });
              // } else {
              //   setAutoCompleteFilter({
              //     ...autoCompleteFilter,
              //     miniCategory: false,
              //   });
              // }
            }}
            onOpen={() => {
              setAutoCompleteFilter({
                ...autoCompleteFilter,
                miniCategoryOpen: true,
              });
            }}
            onClose={() => {
              setAutoCompleteFilter({
                ...autoCompleteFilter,
                miniCategoryOpen: false,
              });
            }}
            selectOnFocus
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <li {...props} sx={{ color: 'black' }} key={option.id}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={
                    miniCategoryFilterChoose.filter(
                      (item) => item.id === option.id
                    ).length !== 0
                  }
                />
                {option.name}
              </li>
            )}
            style={{ width: '100%', marginTop: '12px' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                placeholder="Enter Mini"
                sx={{ bgcolor: '#FAFAFA' }}
              />
            )}
          />
        </Box>
        <Box>
          <CustomButton
            variant="contained"
            sx={{ width: '100%', height: '42px', mt: '20px' }}
            onClick={handleClickSaveFilter}
          >
            <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
              Save
            </Typography>
          </CustomButton>
        </Box>
      </CustomBottomDrawer>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
      >
        <BackButton handleClick={handleBackButton} />
        {roadHirarki.category === '' || roadHirarki.subCategory === '' ? (
          <Typography sx={{ ml: '4px', fontSize: '12px', fontWeight: '600' }}>
            Products
          </Typography>
        ) : (
          <>
            <Typography
              sx={{
                ml: '4px',
                fontSize: '12px',
                fontWeight: '600',
                color: '#9E9D9D',
                cursor: 'pointer',
              }}
              onClick={handleClickToCategory}
            >
              Category
            </Typography>
            {roadHirarki.category && (
              <>
                <ChevronRightIcon sx={{ color: '#9E9D9D' }} />
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '600', color: '#9E9D9D' }}
                  onClick={handleClickToSubCategory}
                >
                  {roadHirarki.category}
                </Typography>
              </>
            )}
            {roadHirarki.subCategory && (
              <>
                <ChevronRightIcon sx={{ color: '#9E9D9D' }} />
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '600' }}
                  onClick={handleClickToSubCategory}
                >
                  {roadHirarki.subCategory}
                </Typography>
              </>
            )}
          </>
        )}
      </Grid>
      {/* TAble */}
      <Grid item xs={12}>
        <CustomCard sx={{ p: '12px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {/* JUDUl */}
              <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
                Product
              </Typography>
              <TextField
                placeholder={'Search'}
                size="small"
                sx={{
                  width: '100px',
                  height: '20px',
                  '.MuiOutlinedInput-input': {
                    padding: '6px 0px 6px 0px',
                    fontSize: '0.5rem',
                  },
                  '.MuiOutlinedInput-root': {
                    bgcolor: '#FAFAFA',
                    borderRadius: '4px',
                  },
                }}
                value={tableSearch}
                onChange={(e) => setTableSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ width: '8px', ml: '4px' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'row-reverse',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '4px',
                  bgcolor: theme.palette.primary.main,
                  width: '20px',
                  height: '20px ',
                  cursor: 'pointer',
                }}
                onClick={handleClickAdd}
              >
                <AddIcon sx={{ color: 'white', width: '8px', height: '8px' }} />
              </Box>
              <CustomCard
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '4px',
                  width: '20px',
                  height: '20px ',
                }}
                onClick={() => setIsOpenTableFilter(true)}
              >
                <FilterAltIcon sx={{ width: '12px' }} />
              </CustomCard>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: '16px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <DollarIcon style={{ height: '10px' }} />
                <Typography sx={{ fontSize: '8px' }}>COGS(Rp)*</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <SalesIcon style={{ height: '10px', fill: '#51B15C' }} />
                <Typography sx={{ fontSize: '8px' }}>SKU</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <ErrorOutlineIcon
                  sx={{
                    fontSize: '10px',
                    fontWeight: '500',
                    color: theme.palette.warning.main,
                  }}
                />
                <Typography sx={{ fontSize: '8px' }}>Limit</Typography>
              </Box>
            </Box>
            <Typography sx={{ fontSize: '8px' }}>*000</Typography>
          </Box>
          <InfiniteScroll
            dataLength={tableTotalItems}
            next={() => {}}
            hasMore={true}
            loader={<Skeleton variant="text" sx={{ mt: 1 }} />}
            style={{
              marginTop: '8px',
              maxHeight: '243px',
              overflow: 'auto',
            }}
          >
            <Grid container columns={12} sx={{}} spacing={'16px'}>
              {/* ITEMSS */}
              {tableDatas.map((data, index) => (
                <Grid
                  item
                  xs={6}
                  key={index}
                  onClick={(e) =>
                    handleClickRow(data.namaProduk, data.idProduk)
                  }
                >
                  <Box
                    sx={{
                      bgcolor: '#FAFAFA',
                      p: '8px',
                      borderRadius: '8px',
                      height: '65px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      // handleClickRow(data.namaKategori, data.idKategori);
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {/* Photo */}
                      {data.gambar ? (
                        <Box>
                          <img
                            src={
                              process.env.REACT_APP_API_IMAGE_BASE_URL +
                              '/' +
                              data.gambar.split(',')[0]
                            }
                            style={{
                              width: '28px',
                              height: '28px',
                              borderRadius: '2px',
                            }}
                          />
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            width: '28px',
                            height: '28px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            bgcolor: '#FAFAFA',
                            borderRadius: '2px',
                          }}
                          onClick={(event) =>
                            handleClickRow(data.namaProduk, data.idProduk)
                          }
                        >
                          <NoImage />
                          <Typography
                            sx={{ color: '#9E9D9D', fontSize: '4px' }}
                          >
                            No photo
                          </Typography>
                        </Box>
                      )}
                      {/* Title */}
                      <Box sx={{ ml: '8px' }}>
                        <Box sx={{ display: 'flex', gap: 0.25 }}>
                          {data.miniCategory && (
                            <>
                              <Typography
                                sx={{
                                  fontSize: '8px',
                                  fontWeight: '600',
                                  color: '#848589',
                                  maxWidth: '45px',
                                }}
                                noWrap
                              >
                                {
                                  data.miniCategory.subCategory.category
                                    .namaKategori
                                }
                              </Typography>
                              <ChevronRightIcon
                                sx={{
                                  fontSize: '8px',
                                  fontWeight: '600',
                                  color: '#848589',
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: '8px',
                                  fontWeight: '600',
                                  color: '#848589',
                                  maxWidth: '45px',
                                }}
                                noWrap
                              >
                                {data.miniCategory.subCategory.subkategori}
                              </Typography>
                            </>
                          )}
                          {data.miniCategory === null && (
                            <>
                              <Typography
                                sx={{
                                  fontSize: '8px',
                                  fontWeight: '600',
                                  color: '#848589',
                                  maxWidth: '45px',
                                }}
                                noWrap
                              >
                                {data.subCategory.category.namaKategori}
                              </Typography>
                              <ChevronRightIcon
                                sx={{
                                  fontSize: '8px',
                                  fontWeight: '600',
                                  color: '#848589',
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: '8px',
                                  fontWeight: '600',
                                  color: '#848589',
                                  maxWidth: '45px',
                                }}
                                noWrap
                              >
                                {data.subCategory.subkategori}
                              </Typography>
                            </>
                          )}
                        </Box>
                        <Typography
                          sx={{
                            fontSize: '10px',
                            fontWeight: '600',
                            mt: '2px',
                          }}
                          onClick={(event) =>
                            handleClickName(
                              event,
                              data.subkategori,
                              data.idSubkategori
                            )
                          }
                        >
                          {data.namaProduk}
                        </Typography>
                      </Box>
                    </Box>
                    {/* ITEMSS */}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        mt: '8px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5,
                        }}
                      >
                        <DollarIcon style={{ height: '8px' }} />
                        <Typography sx={{ fontSize: '8px' }}>
                          {data.hargaMenual ? data.hargaMenual : '-'}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5,
                        }}
                      >
                        <SalesIcon style={{ height: '8px', fill: '#51B15C' }} />
                        <Typography sx={{ fontSize: '8px' }}>
                          {data.hargaJual ? data.hargaJual : '-'}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5,
                        }}
                      >
                        <ErrorOutlineIcon
                          sx={{
                            fontSize: '8px',
                            fontWeight: '500',
                            color: theme.palette.warning.main,
                          }}
                        />
                        <Typography sx={{ fontSize: '8px' }}>
                          {data.stokMin ? data.stokMin : '-'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
        </CustomCard>
      </Grid>
    </Grid>
  );
};
export default ProductProductMobile;
