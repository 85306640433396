import { Box, Grid, Typography } from '@mui/material';
import BackButtonPurchaseProcessDetailSupplierSubmit from './BackButtonPurchaseProcessDetailSupplierSubmit';
import { useState } from 'react';
import CustomButton from '../../../../../../components/shared/CustomButton';
import {
  CameraIcon,
  QrCodeScannerRoundedIcon,
} from '../../../../../../assets/Icons';
import ScannedData from './ScannedData';
import UnscannedData from './UnscannedData';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePage,
  getAllPurchaseRouting,
  setNotifPurchase,
} from '../../../../../../store/Routing/purchaseRouting';
import {
  getAllSupplierScan,
  loadNoqrSupplier,
} from '../../../../../../store/purchase/purchaseSupplier/purchaseSubmitSupplier';
import { updatePurchaseRequest } from '../../../../../../api/purchase';
import { LoadingComponent } from '../../../../../../components/shared/shared2';
import { updateSentPurchaseSupplier } from '../../../../../../api/rack';
import { useEffect } from 'react';

const PurchaseProcessDetailSupplierSubmit = () => {
  const dispatch = useDispatch();
  const {
    isExist,
    scanned,
    idScanned,
    allData,
    detail,
    isSelect,
    noqrDataSelect,
    noqr,
    noqrid,
    url,
    status,
  } = useSelector(getAllSupplierScan);
  const { idPurchase } = useSelector(getAllPurchaseRouting);
  const [isScanned, setIsScanned] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [disabledSend, setDisabledSend] = useState(false);

  const handleToScanMore = () => {
    dispatch(changePage('purchaseProcessScanSupplier'));
  };

  const fetchUpdatePurchase = async (data) => {
    setIsLoading(true);
    try {
      await updateSentPurchaseSupplier(data);
      dispatch(
        setNotifPurchase({
          key: 'purchaseProcess',
          text: 'purchase has been success sent',
        })
      );
      dispatch(changePage('purchaseProcessDetailSupplier'));
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSend = () => {
    const collect = {
      qrCodeId: idScanned,
      purchaseId: idPurchase,
      photo: noqrid,
      status: 'sent',
    };

    fetchUpdatePurchase(collect);
    console.log({ collect });
  };

  useEffect(() => {
    const a =
      allData?.noqr?.filter((list) => !noqrid.flat().includes(list.idProduk))
        .length !== 0;

    const b =
      allData?.withqr?.filter((list) => !idScanned.includes(list.encript))
        .length !== 0;
    if (a === true && b === true) {
      setDisabledSend(true);
    } else {
      setDisabledSend(false);
    }
  }, [allData, idScanned, noqrid]);

  const handleTosendNoqr = () => {
    dispatch(changePage('captureImagaProductSupplier'));
  };

  return (
    <Box sx={{ mt: '16px' }}>
      {!isLoading && !isError && (
        <>
          <BackButtonPurchaseProcessDetailSupplierSubmit />
          <Grid
            container
            columns={2}
            columnSpacing={0.5}
            sx={{
              mt: '16px',
              p: '4px',
              bgcolor: '#F5F5F5',
              borderRadius: '8px',
            }}
          >
            <Grid item xs={1}>
              <Box
                sx={{
                  py: '8px',
                  borderRadius: '8px',
                  bgcolor: isScanned ? 'white' : '#F5F5F5',
                }}
                onClick={() => setIsScanned(true)}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    textAlign: 'center',
                    fontWeight: isScanned ? '600' : '500',
                    color: isScanned ? '#51B15C' : 'black',
                  }}
                >
                  Complete {scanned.length + noqr.flat().length}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={{
                  py: '8px',
                  borderRadius: '8px',
                  bgcolor: !isScanned ? 'white' : '#F5F5F5',
                }}
                onClick={() => setIsScanned(false)}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    textAlign: 'center',
                    fontWeight: !isScanned ? '600' : '500',
                    color: !isScanned ? '#51B15C' : 'black',
                  }}
                >
                  Incomplete{' '}
                  {allData?.withqr?.filter(
                    (list) => !idScanned.includes(list.encript)
                  ).length +
                    allData?.noqr?.filter(
                      (list) =>
                        !noqrid.some((item) =>
                          item.id_detail.includes(list.idProduk)
                        )
                    ).length || 0}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ minHeight: '70vh', bgcolor: 'white' }}>
            {isScanned ? <ScannedData /> : <UnscannedData />}
          </Box>
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              bgcolor: 'white',
              p: '20px',
              width: '100%',
              display: 'flex',
              gap: 0.5,
            }}
          >
            <CustomButton
              variant="contained"
              sx={{ width: '100%' }}
              onClick={() => handleSend()}
              disabled={disabledSend}
            >
              Send
            </CustomButton>
            {isSelect ? (
              <Box
                sx={{
                  border: 2,
                  borderRadius: '8px',
                  borderColor: '#51B15C',
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => handleTosendNoqr()}
              >
                <CameraIcon sx={{ color: '#51B15C' }} />
              </Box>
            ) : (
              <Box
                sx={{
                  border: 2,
                  borderRadius: '8px',
                  borderColor: '#51B15C',
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => handleToScanMore()}
              >
                <QrCodeScannerRoundedIcon sx={{ color: '#51B15C' }} />
              </Box>
            )}
          </Box>
        </>
      )}
      {isLoading && <LoadingComponent />}
    </Box>
  );
};
export default PurchaseProcessDetailSupplierSubmit;
