import { Box, Typography } from '@mui/material';

const LineDiv = ({ value, unit, ...props }) => {
  return (
    <Box
      sx={{
        minHeight: '54px',
        borderRadius: '8px',
        width: '100%',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        padding: '14px 16px',
        bgcolor: '#FAFAFA',
        gap: 1,
      }}
      {...props}
    >
      <Typography>{value || '-'}</Typography>
      <Typography>{unit || ''}</Typography>
    </Box>
  );
};
export default LineDiv;
