import { createSlice } from '@reduxjs/toolkit';
import { getPurchaseRequestDetail } from '../../../api/purchase';

export const GetDetailPurchaseRequest = createSlice({
  name: 'purchaseRequestDetail',
  initialState: {
    isLoading: false,
    isEdit: false,
    data: null,
    idPurchase: 0,
  },
  reducers: {
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    changeIsEdit: (state, action) => {
      state.isEdit = action.payload;
    },
    loadDataDetail: (state, action) => {
      state.data = action.payload;
    },
    changeIdPurchase: (state, action) => {
      state.idPurchase = action.payload;
    },
  },
});

export const {
  loadDataDetail,
  changeIsLoading,
  changeIsEdit,
  changeIdPurchase,
} = GetDetailPurchaseRequest.actions;

export const getIsLoadingPurchaseDetail = (state) =>
  state.purchaseRequestDetail.isLoading;
export const getIsEditPurchaseRequest = (state) =>
  state.purchaseRequestDetail.isEdit;
export const getDataDetailPurchase = (state) =>
  state.purchaseRequestDetail.data;
export const getIdPurchase = (state) => state.purchaseRequestDetail.idPurchase;

// -------- GET API ----------------
export const getDetailPurchase = (id) => (dispatch) => {
  const fetchPurchaseDetail = async () => {
    dispatch(changeIsLoading(true));
    try {
      const {
        data: { data },
      } = await getPurchaseRequestDetail(id);
      dispatch(loadDataDetail(data));
      dispatch(changeIsLoading(false));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(changeIsLoading(false));
    }
  };
  fetchPurchaseDetail();
};

export default GetDetailPurchaseRequest.reducer;
