export const hidePhoneNumber = (value) => {
  value = value.split('');

  for (let i = 0; i < value.length; i++) {
    if (i < value.length - 4) {
      value[i] = 'x';
    }
  }

  return value.join('');
};

export const kanpaiOrderStatusColor = (value) => {
  switch (value) {
    case 1:
      return 'primary.main';

    case 2:
      return 'primary.main';

    case 3:
      return '#FD7900';

    case 4:
      return '#FD7900';

    case 5:
      return '#F33A3A';

    case 6:
      return '#F33A3A';

    default:
      return '#404040';
  }
};
