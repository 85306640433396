import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Switch,
  Dialog,
  styled,
} from '@mui/material';
import CustomInput from '../../../../../../components/shared/CustomInput.jsx';
import BackButton from '../../../../../../components/shared/BackButton';
import dayjs from 'dayjs';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import CustomDialog from '../../../../../../components/shared/CustomDialog';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import CustomCard from '../../../../../../components/shared/CustomCard';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import useGetDifferent2Arrays from '../../../../../../hooks/useGetDifferent2Arrays';
import CustomButton from '../../../../../../components/shared/CustomButton';
import {
  deleteSubCategoryProduct,
  updateSubCategoryProduct,
} from '../../../../../../api/products.js';
import { uploadImage } from '../../../../../../api/storage.js';
import CustomEditConfirm from '../../../../../../components/shared/CustomEditConfirm.jsx';
import CustomDeleteConfirm from '../../../../../../components/shared/CustomDeleteConfirm.jsx';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const SubCategoryProductEdit = (props) => {
  const {
    page,
    setPage,
    roadHirarki,
    setRoadHirarki,
    setIsSubCategoryProductAdd,
    setIsSubCategoryProductEdit,
    isSubCategoryProductDetails,
    setIsSubCategoryProductDetails,
    // data
    // subCategoryEditInputDatas,
    // setSubCategoryEditInputDatas,
    // *-------
    subCategoryEditInputDatas,
    setSubCategoryEditInputDatas,
    // *---- notif ----
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
  } = props;
  const today = dayjs(new Date());

  const theme = useTheme();

  // !------------------------- SAVE VALIDATION ----------------------------
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);

  useEffect(() => {
    if (subCategoryEditInputDatas.name.length >= 5) {
      if (
        subCategoryEditInputDatas.description === '' ||
        subCategoryEditInputDatas.description.length >= 20
      ) {
        return setIsSubmitAvailable(true);
      } else {
        return setIsSubmitAvailable(false);
      }
    } else {
      return setIsSubmitAvailable(false);
    }
  }, [subCategoryEditInputDatas.name, subCategoryEditInputDatas.description]);

  // !------------------------- --------------  ----------------------------

  const [desktopMode, setDesktopMode] = useState(true);

  useEffect(() => {
    if (window.screen.width <= 830) {
      setDesktopMode(false);
    } else {
      setDesktopMode(true);
    }
  }, [window.screen.width]);

  // !------------------- Confirmation -------------------
  const [isEditConfirm, setIsEditConfirm] = useState(false);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  // ! ------------- Handler --------

  const handleEditSubCategory = () => {
    setIsEditConfirm(!isEditConfirm);
  };

  const handleEditFix = () => {
    let hasil = {
      idKategori: roadHirarki.categoryId,
      subkategori: subCategoryEditInputDatas.name,
      subStatus: `${subCategoryEditInputDatas.publish ? 'Y' : 'N'}`,
      schedule: dayjs(subCategoryEditInputDatas.schedule.$d).format(
        'YYYY-MM-DD'
      ),
    };

    if (subCategoryEditInputDatas.description !== '') {
      hasil['informasi'] = subCategoryEditInputDatas.description;
    }

    if (subCategoryEditInputDatas.photoUrls.length !== 0) {
      hasil['gbrsub'] = subCategoryEditInputDatas.photoUrls.join(',');
    }

    updateSubCategoryProduct(roadHirarki.subCategoryId, hasil)
      .then((result) => {
        setRoadHirarki({
          ...roadHirarki,
          subCategory: result.data.data.subkategori,
          subCategoryId: result.data.data.idSubkategori,
        });
        setIsEditConfirm(false);
        setIsSubCategoryProductEdit(false);
        setIsSubCategoryProductDetails(true);
        setNotif('editsubcategory');
        setNotifTitle(
          `${result.data.data.subkategori} has been successfully edited`
        );
      })
      .catch((err) => console.error());
  };

  const handleDelete = () => {
    setIsDeleteConfirm(true);
  };

  const handleDeleteFix = () => {
    deleteSubCategoryProduct(roadHirarki.subCategoryId).then((res) => {
      setIsDeleteConfirm(false);
      setIsSubCategoryProductEdit(false);
      setNotifTitle(
        `${res.data.data.subkategori} has been successfully deleted`
      );
      setNotif('deletesubcategory');
      setPage('subCategory');
      setRoadHirarki({
        ...roadHirarki,
        subCategory: '',
        subCategoryId: 0,
        product: '',
        productId: 0,
        miniSubCategory: '',
        miniSubCategoryId: 0,
      });
    });
  };

  const handleBackButton = () => {
    setPage('subCategory');
    setIsSubCategoryProductAdd(false);
    setIsSubCategoryProductEdit(false);
  };

  const handleAddImage = (e) => {
    if (e.target.files[0].size > 2000000) {
      setSubCategoryEditInputDatas({
        ...subCategoryEditInputDatas,
        isMaxSizePhoto: true,
      });
    } else {
      let datas = {
        image: e.target.files[0],
        resource: 'subCategory',
      };
      uploadImage(datas).then((res) => {
        setSubCategoryEditInputDatas({
          ...subCategoryEditInputDatas,
          photoUrls: [
            ...subCategoryEditInputDatas.photoUrls,
            res.data.data.path,
          ],
          isMaxSizePhoto: false,
        });
      });
    }
  };

  const handleDeleteImage = (link) => {
    setSubCategoryEditInputDatas({
      ...subCategoryEditInputDatas,
      photoUrls: subCategoryEditInputDatas.photoUrls.filter(
        (data) => data !== link
      ),
      indexPreviePhoto: 0,
    });
  };

  const handlePreviewImage = (link) => {
    setSubCategoryEditInputDatas({
      ...subCategoryEditInputDatas,
      linkPreviewPhoto: link,
      isPreviewPhoto: 'true',
    });
  };

  return (
    <>
      <CustomEditConfirm
        open={isEditConfirm}
        onClose={() => setIsEditConfirm(false)}
        handleClickCancel={() => setIsEditConfirm(false)}
        handleClickEdit={handleEditFix}
        title="Edit Subcategory"
        body={
          <Box sx={{ textAlign: 'center', mb: '40px', mt: '8px' }}>
            <Typography>Are you sure you want to save the changes?</Typography>
          </Box>
        }
      />
      <CustomDeleteConfirm
        open={isDeleteConfirm}
        onClose={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickCancel={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Category"
        body={
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: '600' }}>
                {subCategoryEditInputDatas.name}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: '52px' }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />

      {desktopMode ? (
        <Grid container columns={12} spacing={4} sx={{ pb: '56px' }}>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <BackButton handleClick={handleBackButton} />
            <Box>
              <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
                Edit Sub Category
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  color: '#9E9D9D',

                  alignItems: 'center',
                  mt: '4px',
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                  Subsidiary
                </Typography>
                <ChevronRightIcon sx={{ fontSize: '16px' }} />
                <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                  {roadHirarki.category}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {/* Name */}
          <Grid item xs={6}>
            <Typography
              sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}
            >
              Name*
            </Typography>
            <CustomInput
              sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              placeholder="Enter sub category name"
              value={subCategoryEditInputDatas.name}
              onChange={(e) => {
                if (e.target.value.length <= 30) {
                  setSubCategoryEditInputDatas({
                    ...subCategoryEditInputDatas,
                    name: e.target.value,
                  });
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 5 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {subCategoryEditInputDatas.name.length}/30
              </Typography>
            </Box>
          </Grid>
          {/* Photo */}
          <Grid item xs={6} sx={{ minHeight: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                pb: `${
                  subCategoryEditInputDatas.photo.length === 0 ? '16px' : '9px'
                }`,
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Photo
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  color: '#9E9D9D',
                }}
              >
                ( max size : 2MB )
              </Typography>
              {subCategoryEditInputDatas.isMaxSizePhoto && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ErrorOutlineIcon
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                      mr: '4px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                    }}
                  >
                    The photo can't be more than 2MB
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {subCategoryEditInputDatas.photoUrls.length === 0 && (
                  <Box
                    sx={{
                      height: '54px',
                      width: '54px',
                      aspectRatio: '1/1',
                      borderRadius: '8px',
                      bgcolor: '#F5F5F5',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderStyle: 'dashed',
                      borderColor: '#9E9D9D',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('inputSubCat_img').click();
                    }}
                  >
                    <ImageOutlinedIcon
                      sx={{ color: '#9E9D9D', fontSize: '28px' }}
                    />
                  </Box>
                )}
                {subCategoryEditInputDatas.photoUrls.map((input, index) => (
                  <Box
                    sx={{
                      position: 'relative',
                      overflow: 'visible',
                      padding: '7px',
                    }}
                    key={index}
                  >
                    <CancelIcon
                      sx={{
                        position: 'absolute',
                        fontSize: '20px',
                        top: 1,
                        right: 1,
                        zIndex: +1,
                        cursor: 'pointer',
                      }}
                      onClick={() => handleDeleteImage(input)}
                    />
                    <Box
                      component="img"
                      src={
                        process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + input
                      }
                      sx={{
                        height: '54px',
                        width: '54px',
                        aspectRatio: '1/1',
                        borderRadius: '8px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handlePreviewImage(input)}
                    />
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  mb: '7px',
                  ml: '20px',
                  alignItems: 'center',
                }}
              >
                {subCategoryEditInputDatas.photoUrls.length < 3 && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('inputSubCat_img').click();
                    }}
                  >
                    <input
                      accept="image/*"
                      id="inputSubCat_img"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleAddImage}
                    />
                    <AddIcon />
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                      Add More
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          {/* DIalog Photo */}
          {subCategoryEditInputDatas.linkPreviewPhoto !== '' && (
            <Dialog
              open={subCategoryEditInputDatas.isPreviewPhoto}
              onClose={() => {
                setSubCategoryEditInputDatas({
                  ...subCategoryEditInputDatas,
                  isPreviewPhoto: !subCategoryEditInputDatas.isPreviewPhoto,
                });
              }}
            >
              <Box
                component="img"
                src={
                  process.env.REACT_APP_API_IMAGE_BASE_URL +
                  '/' +
                  subCategoryEditInputDatas.linkPreviewPhoto
                }
                sx={{ width: '600px', height: '600px', aspectRation: '1/1' }}
              />
            </Dialog>
          )}
          {/* Description */}
          <Grid item xs={6}>
            <Typography
              sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}
            >
              Description
            </Typography>
            <CustomInput
              sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              placeholder="Enter description"
              value={subCategoryEditInputDatas.description}
              onChange={(e) => {
                if (e.target.value.length <= 250) {
                  setSubCategoryEditInputDatas({
                    ...subCategoryEditInputDatas,
                    description: e.target.value,
                  });
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 20 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {subCategoryEditInputDatas.description.length}/250
              </Typography>
            </Box>
          </Grid>
          {/* Schedule */}
          <Grid item xs={6} sx={{}}>
            <Grid container>
              <Grid
                item
                alignItems="center"
                sx={{ height: '100%', mr: '20px' }}
              >
                <Typography
                  sx={{
                    mb: '16px',
                    fontSize: '16px',
                    fontWeight: '500',
                  }}
                >
                  Schedule
                </Typography>
              </Grid>
              <Grid item alignItems="center" sx={{ height: '100%' }}>
                <RadioGroup
                  row
                  value={subCategoryEditInputDatas.scheduleType}
                  onChange={(e) => {
                    setSubCategoryEditInputDatas({
                      ...subCategoryEditInputDatas,
                      schedule: today,
                      scheduleType: e.target.value,
                    });
                  }}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '16px',
                    },
                    '.MuiRadio-root': {
                      padding: '0 8px',
                    },
                    '.MuiTypography-root': {
                      fontSize: '12px',
                    },
                  }}
                >
                  <FormControlLabel
                    value="now"
                    control={<Radio />}
                    label="Now"
                  />
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="Custom"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', minWidth: '100%' }}>
              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                value={subCategoryEditInputDatas.schedule}
                onChange={(newValue) => {
                  if (subCategoryEditInputDatas.scheduleType !== 'now') {
                    setSubCategoryEditInputDatas({
                      ...subCategoryEditInputDatas,
                      schedule: newValue,
                    });
                  } else {
                    setSubCategoryEditInputDatas({
                      ...subCategoryEditInputDatas,
                      schedule: today,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: '100%', bgcolor: '#FAFAFA' }}
                  />
                )}
                disabled={
                  subCategoryEditInputDatas.scheduleType === 'now'
                    ? true
                    : false
                }
              />
            </Box>
          </Grid>
          {/* Submit */}
          <Box
            sx={{
              width: '100%',
              bgcolor: 'white',
              position: 'fixed',
              bottom: '0px',
              right: '0px',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '18px 32px 18px 240px',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Publish
              </Typography>
              <AntSwitch
                checked={subCategoryEditInputDatas.publish}
                onChange={(e) => {
                  setSubCategoryEditInputDatas({
                    ...subCategoryEditInputDatas,
                    publish: !subCategoryEditInputDatas.publish,
                  });
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <CustomButton
                variant="contained"
                // disabled={!isSubmitAvailable}
                onClick={handleDelete}
                color="error"
                sx={{ width: '140px', height: '36px' }}
              >
                <Typography
                  sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
                >
                  Delete
                </Typography>
              </CustomButton>
              <CustomButton
                variant="contained"
                disabled={!isSubmitAvailable}
                onClick={handleEditSubCategory}
                sx={{ width: '140px', height: '36px' }}
              >
                <Typography
                  sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
                >
                  Save
                </Typography>
              </CustomButton>
            </Box>
          </Box>
        </Grid>
      ) : (
        <Grid container columns={12} spacing={2}>
          {/* Edit Category */}
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <BackButton handleClick={handleBackButton} />
            <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
              Edit Category
            </Typography>
          </Grid>
          {/* Name */}
          <Grid item xs={12}>
            <CustomInput
              placeholder="Name*"
              value={subCategoryEditInputDatas.name}
              onChange={(e) => {
                if (e.target.value.length <= 30) {
                  setSubCategoryEditInputDatas({
                    ...subCategoryEditInputDatas,
                    name: e.target.value,
                  });
                }
              }}
              sx={{
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
                width: '100%',
                fontSize: '12px',
                '.MuiOutlinedInput-input': {
                  fontSize: '12px',
                  padding: '12px',
                },
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '8px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 5 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '8px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {subCategoryEditInputDatas.name.length}/30
              </Typography>
            </Box>
          </Grid>
          {/* Photo */}
          <Grid item xs={6} sx={{ minHeight: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                pb: `${
                  subCategoryEditInputDatas.photo.length === 0 ? '16px' : '9px'
                }`,
                alignItems: 'center',
              }}
            >
              <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                Photo
              </Typography>
              <Typography
                sx={{
                  fontSize: '8px',
                  fontWeight: '500',
                  color: '#9E9D9D',
                }}
              >
                ( max size : 2MB )
              </Typography>
              {subCategoryEditInputDatas.isMaxSizePhoto && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ErrorOutlineIcon
                    sx={{
                      fontSize: '8px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                      mr: '4px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '8px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                    }}
                  >
                    The photo can't be more than 2MB
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {subCategoryEditInputDatas.photoUrls.length === 0 && (
                  <Box
                    sx={{
                      height: '54px',
                      width: '54px',
                      aspectRatio: '1/1',
                      borderRadius: '8px',
                      bgcolor: '#F5F5F5',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderStyle: 'dashed',
                      borderColor: '#9E9D9D',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('inputSubCat_img').click();
                    }}
                  >
                    <ImageOutlinedIcon
                      sx={{ color: '#9E9D9D', fontSize: '28px' }}
                    />
                  </Box>
                )}
                {subCategoryEditInputDatas.photoUrls.map((input, index) => (
                  <Box
                    sx={{
                      position: 'relative',
                      overflow: 'visible',
                      padding: '7px',
                    }}
                    key={index}
                  >
                    <CancelIcon
                      sx={{
                        position: 'absolute',
                        fontSize: '20px',
                        top: 1,
                        right: 1,
                        zIndex: +1,
                        cursor: 'pointer',
                      }}
                      onClick={() => handleDeleteImage(input)}
                    />
                    <Box
                      component="img"
                      src={
                        process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + input
                      }
                      sx={{
                        height: '54px',
                        width: '54px',
                        aspectRatio: '1/1',
                        borderRadius: '8px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handlePreviewImage(input)}
                    />
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  mb: '7px',
                  ml: '20px',
                  alignItems: 'center',
                }}
              >
                {subCategoryEditInputDatas.photoUrls.length < 3 && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('inputSubCat_img').click();
                    }}
                  >
                    <input
                      accept="image/*"
                      id="inputSubCat_img"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleAddImage}
                    />
                    <AddIcon />
                    <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
                      Add More
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          {/* DIalog Photo */}
          {subCategoryEditInputDatas.linkPreviewPhoto !== '' && (
            <Dialog
              open={subCategoryEditInputDatas.isPreviewPhoto}
              onClose={() => {
                setSubCategoryEditInputDatas({
                  ...subCategoryEditInputDatas,
                  isPreviewPhoto: !subCategoryEditInputDatas.isPreviewPhoto,
                });
              }}
            >
              <Box
                component="img"
                src={
                  process.env.REACT_APP_API_IMAGE_BASE_URL +
                  '/' +
                  subCategoryEditInputDatas.linkPreviewPhoto
                }
                sx={{ aspectRation: '1/1' }}
              />
            </Dialog>
          )}
          {/* Description */}
          <Grid item xs={12}>
            <CustomInput
              sx={{
                width: '100%',
                bgcolor: '#FAFAFA',
                fontSize: '12px',
                '.MuiOutlinedInput-input': {
                  fontSize: '12px',
                  padding: '12px',
                },
              }}
              placeholder="Description"
              value={subCategoryEditInputDatas.description}
              onChange={(e) => {
                if (e.target.value.length <= 250) {
                  setSubCategoryEditInputDatas({
                    ...subCategoryEditInputDatas,
                    description: e.target.value,
                  });
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '8px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 20 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '8px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {subCategoryEditInputDatas.description.length}/250
              </Typography>
            </Box>
          </Grid>
          {/* Schedule */}
          <Grid item xs={12} sx={{}}>
            <Box sx={{ display: 'flex', minWidth: '100%' }}>
              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                value={subCategoryEditInputDatas.schedule}
                onChange={(newValue) => {
                  if (subCategoryEditInputDatas.scheduleType !== 'now') {
                    setSubCategoryEditInputDatas({
                      ...subCategoryEditInputDatas,
                      schedule: newValue,
                    });
                  } else {
                    setSubCategoryEditInputDatas({
                      ...subCategoryEditInputDatas,
                      schedule: today,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: '100%',
                      bgcolor: '#FAFAFA',
                      '.MuiOutlinedInput-input': {
                        fontSize: '12px',
                        padding: '12px',
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
          {/* STATUS */}
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
              Publish
            </Typography>
            <AntSwitch
              checked={subCategoryEditInputDatas.publish}
              onChange={(e) => {
                setSubCategoryEditInputDatas({
                  ...subCategoryEditInputDatas,
                  publish: !subCategoryEditInputDatas.publish,
                });
              }}
            />
          </Grid>
          {/* SUBMIT */}
          <Grid
            item
            xs={12}
            sx={{ mt: '28px', display: 'flex', alignItems: 'center', gap: 0.5 }}
          >
            <CustomButton
              // disabled={!isSubmitAvailable}
              onClick={handleDelete}
              variant="contained"
              color="error"
              sx={{
                width: '100%',
              }}
            >
              Delete
            </CustomButton>
            <CustomButton
              disabled={!isSubmitAvailable}
              onClick={handleEditSubCategory}
              variant="contained"
              sx={{
                width: '100%',
              }}
            >
              Save
            </CustomButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default SubCategoryProductEdit;
