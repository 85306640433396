import { useDispatch, useSelector } from 'react-redux';
import BackButton from '../../../../../../components/shared/BackButton';
import {
  changePage,
  getAllPurchaseRouting,
} from '../../../../../../store/Routing/purchaseRouting';
import { Box, Typography } from '@mui/material';
import { ArrowRightAltIcon } from '../../../../../../assets/Icons';

const BackButtonPurchaseQrCode = () => {
  const dispatch = useDispatch();
  const { idPurchase } = useSelector(getAllPurchaseRouting);

  const handleBackButton = () => {
    dispatch(changePage('detail'));
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        <Box>
          <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
            Purchase request detail
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default BackButtonPurchaseQrCode;
