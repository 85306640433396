import { Box, Drawer, Grid, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CustomBottomDrawer = ({
  title,
  additionalOption,
  handleClickAdditionalOption,
  handleClose,
  children,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Drawer
      anchor="bottom"
      sx={{
        '.MuiPaper-root': {
          borderRadius: '20px 20px 0 0',
          p: '16px',
        },
      }}
      {...props}
    >
      <Grid
        container
        sx={{
          mb: '22px',
        }}
        columns={12}
        alignItems="center"
      >
        <Grid
          item
          xs={8}
          align="center"
          sx={{ display: 'flex', justifyContent: 'start' }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '600',
            }}
            align="center"
          >
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={additionalOption ? 2 : 4}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
          onClick={handleClose}
        >
          <Box>
            <CloseIcon />
          </Box>
        </Grid>

        {additionalOption && (
          <Grid item xs={2} onClick={handleClickAdditionalOption}>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '600',
                color: theme.palette.primary.main,
                cursor: 'pointer',
              }}
              align="right"
            >
              {additionalOption}
            </Typography>
          </Grid>
        )}
      </Grid>
      {children}
    </Drawer>
  );
};
export default CustomBottomDrawer;
