import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import BackButtonHub from '../../../../../../components/shared/BackButtonHub';
import {
  changePageProductSupplier,
  setNotifProductSupplier,
} from '../../../../../../store/Routing/productSupplierRouting';
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from '../../../../../../components/shared/CustomInput';
import {
  changeIsLoading,
  changeStatusEdit,
  clearDataEdit,
  editDataEditProduk,
  getDataEditProduk,
  loadDataEditProduk,
} from '../../../../../../store/productSupplier/editProductSupplier';
import CustomButton from '../../../../../../components/shared/CustomButton';
import CustomAntSwitch from '../../../../../../components/shared/CustomAntSwitch';
import { DataSaverOffOutlined } from '@mui/icons-material';
import {
  DeleteProdukSupplier,
  geteditProdukSupplier,
} from '../../../../../../api/Supplier';
import CustomDeleteConfirm from '../../../../../../components/shared/CustomDeleteConfirm';

const EditSupplier = () => {
  const dispatch = useDispatch();
  const { datas, status, isLoading, idProduk } = useSelector(getDataEditProduk);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  const handleEdit = () => {
    const { id, detail } = datas;
    const params = {
      status_publish: status ? 1 : 0,
      detail: detail,
    };
    const updatePrice = async () => {
      dispatch(changeIsLoading(true));
      try {
        const {
          data: { data },
        } = await geteditProdukSupplier(id, params);
        dispatch(clearDataEdit());
        dispatch(changeIsLoading(false));
        dispatch(changePageProductSupplier(''));
      } catch (error) {
        console.log(error);
      }
    };
    updatePrice();
  };

  const handleDeleteFix = async () => {
    setIsLoadingDelete(true);
    try {
      await DeleteProdukSupplier(idProduk);
      dispatch(changePageProductSupplier(''));
      setIsLoadingDelete(false);
      dispatch(
        setNotifProductSupplier({
          key: 'deleteProductSupllier',
          text: 'product has been deleted',
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        minHeight: '83vh',
      }}
    >
      <Grid container columns={12} spacing={3}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              mt: 2,
            }}
          >
            <BackButtonHub
              onClick={() =>
                dispatch(changePageProductSupplier('detailSupplier'))
              }
            />
            <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
              Edit Produk
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={2}>
              <Box sx={{ display: 'flex' }}>
                <Typography
                  sx={{ fontSize: '16px', color: '#000000', fontWeight: 600 }}
                >
                  Produk Name
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    mb: 2,
                    color: '#F33A3A',
                  }}
                >
                  *
                </Typography>
              </Box>
            </Grid>
            <Grid xs={10}>
              <Box
                sx={{
                  height: '60px',
                  width: '80%',
                  padding: '14px 16px',
                  bgcolor: '#FAFAFA',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, color: '#000000' }}
                >
                  {datas?.namaProduk}
                </Typography>
              </Box>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={2}>
              <Box sx={{ display: 'flex' }}>
                <Typography
                  sx={{ fontSize: '16px', color: '#000000', fontWeight: 600 }}
                >
                  Price
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    mb: 2,
                    color: '#F33A3A',
                  }}
                >
                  *
                </Typography>
              </Box>
            </Grid>

            <Grid xs={10} sx={{ mt: 3 }}>
              {datas?.detail?.map((item, idx) => (
                <Box sx={{ mb: 2 }} key={idx}>
                  <CustomInput
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ marginLeft: '12px' }}
                        >
                          Rp
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          <Box
                            sx={{
                              padding: '12px 8px',
                              borderRadius: '8px',
                              minWidth: '100px',
                              textAlign: 'center',
                              bgcolor: 'white',
                            }}
                          >
                            <Typography sx={{ color: '#000' }}>
                              {item?.name}
                            </Typography>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: '80%',
                      bgcolor: '#fafafa',
                      '& .MuiOutlinedInput-input': {
                        padding: '17px 16px',
                        borderRadius: '10px',
                        '&::placeholder': {
                          color: '#111',
                          fontSize: '16px',
                        },
                      },
                      borderRadius: '5px',
                    }}
                    placeholder="enter description"
                    value={item?.price}
                    onChange={(e) => {
                      const newDetails = datas?.detail?.map(
                        (detail, detailIdx) =>
                          detailIdx === idx
                            ? { ...detail, price: e.target.value }
                            : detail
                      );

                      dispatch(editDataEditProduk(newDetails));
                    }}
                  />
                </Box>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          bottom: 1,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {/* Status */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            mb: '12px',
          }}
        >
          <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
            Publish
          </Typography>
          <CustomAntSwitch
            checked={status}
            onChange={() => dispatch(changeStatusEdit(!status))}
          />
        </Box>

        {/* Botton  */}
        <Box sx={{ display: 'flex', gap: 1 }}>
          <CustomButton
            variant="contained"
            color="error"
            sx={{ width: '140px', height: '36px' }}
            onClick={() => setIsDeleteConfirm(true)}
          >
            <Typography
              sx={{
                color: 'white',
                fontSize: '12px',
                fontWeight: '500',
              }}
            >
              Delete
            </Typography>
          </CustomButton>
          <CustomButton
            variant="contained"
            sx={{ width: '140px', height: '36px' }}
            onClick={handleEdit}
          >
            <Typography
              sx={{
                color: 'white',
                fontSize: '12px',
                fontWeight: '500',
              }}
            >
              {isLoading ? (
                <CircularProgress size={18} sx={{ color: '#FFF' }} />
              ) : (
                'Save'
              )}
            </Typography>
          </CustomButton>
        </Box>
      </Box>

      {/* dialog delete */}
      <CustomDeleteConfirm
        isLoading={isLoadingDelete}
        open={isDeleteConfirm}
        onClose={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickCancel={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Produk"
        body={
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: 600 }}>
                {datas?.namaProduk}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: '52px' }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />
    </Box>
  );
};

export default EditSupplier;
