import { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import dayjs from 'dayjs';
import BackButton from '../../../../../../components/shared/BackButton';
import CustomInput from '../../../../../../components/shared/CustomInput';
import CustomCard from '../../../../../../components/shared/CustomCard';
import { uploadImage } from '../../../../../../api/storage';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import AddMiniSubCategoryInSubCategory from '../../Product/SubCategoryProductAdd/Desktop/AddMiniSubCategoryInSubCategory';
import {
  addNewMiniSubCategoryMenu,
  addNewSubCategoryMenu,
} from '../../../../../../api/menu';
import CustomButton from '../../../../../../components/shared/CustomButton';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const MiniSubMenuAdd = (props) => {
  const {
    // *--- Desktop Mode
    desktopMode,
    roadHirarki,
    setRoadHirarki,
    // *---  NOTIF
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
    // *--- page
    setPage,
    setMiniSubMenu,
    // *--- Data
    miniSubAddInputDatas,
    setMiniSubAddInputDatas,
  } = props;

  const today = dayjs(new Date());
  const theme = useTheme();

  // !------------------------- SAVE VALIDATION ----------------------------
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);

  useEffect(() => {
    if (miniSubAddInputDatas.name.length >= 5) {
      if (
        miniSubAddInputDatas.description === '' ||
        miniSubAddInputDatas.description.length >= 20
      ) {
        return setIsSubmitAvailable(true);
      } else {
        return setIsSubmitAvailable(false);
      }
    } else {
      return setIsSubmitAvailable(false);
    }
  }, [miniSubAddInputDatas.name, miniSubAddInputDatas.description]);

  // !--------------------------- Handler -------------------
  const handleBackButton = () => {};

  const handleAdd = () => {
    let data = {
      idSubkategori: roadHirarki.subCategoryId,
      name: miniSubAddInputDatas.name,
      status: `${miniSubAddInputDatas.publish ? 'Y' : 'N'}`,
      schedule: dayjs(miniSubAddInputDatas.schedule.$d).format('YYYY-MM-DD'),
    };

    if (miniSubAddInputDatas.description !== '') {
      data['description'] = miniSubAddInputDatas.description;
    }

    if (miniSubAddInputDatas.photoUrls.length !== 0) {
      data['image'] = miniSubAddInputDatas.photoUrls.join(',');
    }

    addNewMiniSubCategoryMenu(data)
      .then((res) => {
        setNotifTitle(`${res.data.data.name} has been successfully added`);
        setNotif('addmini');
        setPage('menu');
      })
      .catch((err) => console.error());
  };

  const handleAddImage = (e) => {
    if (e.target.files[0].size > 2000000) {
      setMiniSubAddInputDatas({
        ...miniSubAddInputDatas,
        isMaxSizePhoto: true,
      });
    } else {
      let datas = {
        image: e.target.files[0],
        resource: 'miniSubCategory',
      };
      uploadImage(datas).then((res) => {
        setMiniSubAddInputDatas({
          ...miniSubAddInputDatas,
          photo: [...miniSubAddInputDatas.photo, e.target.files[0]],
          photoUrls: [...miniSubAddInputDatas.photoUrls, res.data.data.path],
          isMaxSizePhoto: false,
        });
      });
    }
  };

  const handleDeleteImage = (input, index) => {
    setMiniSubAddInputDatas({
      ...miniSubAddInputDatas,
      photo: miniSubAddInputDatas.photo.filter((data) => data !== input),
      photoUrls: miniSubAddInputDatas.photoUrls.filter(
        (data, idx) => idx !== index
      ),
      indexPreviePhoto: 0,
    });
  };
  const handlePreviewImage = () => {};
  const handleClosePreviewImage = () => {};

  return (
    <Grid container columns={12} spacing={4} sx={{ pb: '56px' }}>
      {/* //* Title */}
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        <Box>
          <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
            Add mini-sub
          </Typography>
          <Box
            sx={{
              display: 'flex',
              color: '#9E9D9D',

              alignItems: 'center',
              mt: '4px',
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
              {roadHirarki.subsidiary ? roadHirarki.subsidiary : '-'}
            </Typography>
            <ChevronRightIcon sx={{ fontSize: '16px' }} />
            <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
              {roadHirarki.category}
            </Typography>
            <ChevronRightIcon sx={{ fontSize: '16px' }} />
            <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
              {roadHirarki.subCategory}
            </Typography>
          </Box>
        </Box>
      </Grid>
      {/* //* Name */}
      <Grid item xs={6}>
        <Typography sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}>
          Name*
        </Typography>
        <CustomInput
          sx={{ width: '100%', bgcolor: '#FAFAFA' }}
          placeholder="Enter Mini-Category Name"
          value={miniSubAddInputDatas.name}
          onChange={(e) => {
            if (e.target.value.length <= 30) {
              setMiniSubAddInputDatas({
                ...miniSubAddInputDatas,
                name: e.target.value,
              });
            }
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            Enter at least 5 character
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            {miniSubAddInputDatas.name.length}/30
          </Typography>
        </Box>
      </Grid>
      {/* //*Photo */}
      <Grid item xs={6} sx={{ minHeight: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            pb: `${miniSubAddInputDatas.photo.length === 0 ? '16px' : '9px'}`,
          }}
        >
          <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
            Photo
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '500',
              color: '#9E9D9D',
            }}
          >
            ( max size : 2MB )
          </Typography>
          {miniSubAddInputDatas.isMaxSizePhoto && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ErrorOutlineIcon
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  color: theme.palette.warning.main,
                  mr: '4px',
                }}
              />
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  color: theme.palette.warning.main,
                }}
              >
                The photo can't be more than 2MB
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {miniSubAddInputDatas.photo.length === 0 && (
              <Box
                sx={{
                  height: '54px',
                  width: '54px',
                  aspectRatio: '1/1',
                  borderRadius: '8px',
                  bgcolor: '#F5F5F5',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderStyle: 'dashed',
                  borderColor: '#9E9D9D',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  document.getElementById('inputSubCat_img').click();
                }}
              >
                <ImageOutlinedIcon
                  sx={{ color: '#9E9D9D', fontSize: '28px' }}
                />
              </Box>
            )}
            {miniSubAddInputDatas.photo.map((input, index) => (
              <Box
                sx={{
                  position: 'relative',
                  overflow: 'visible',
                  padding: '7px',
                }}
                key={index}
              >
                <CancelIcon
                  sx={{
                    position: 'absolute',
                    fontSize: '20px',
                    top: 1,
                    right: 1,
                    zIndex: +1,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleDeleteImage(input, index)}
                />
                <Box
                  component="img"
                  src={URL.createObjectURL(input)}
                  sx={{
                    height: '54px',
                    width: '54px',
                    aspectRatio: '1/1',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setMiniSubAddInputDatas({
                      ...miniSubAddInputDatas,
                      indexPreviePhoto: index + 1,
                      isPreviewPhoto: 'true',
                    });
                  }}
                />
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: 'flex',
              mb: '7px',
              ml: '20px',
              alignItems: 'center',
            }}
          >
            {miniSubAddInputDatas.photo.length < 3 && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.palette.primary.main,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  document.getElementById('inputSubCat_img').click();
                }}
              >
                <input
                  accept="image/*"
                  id="inputSubCat_img"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleAddImage}
                />
                <AddIcon />
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                  Add More
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      {/* //* DIalog Photo */}
      {miniSubAddInputDatas.indexPreviePhoto !== 0 && (
        <Dialog
          open={miniSubAddInputDatas.isPreviewPhoto}
          onClose={() => {
            setMiniSubAddInputDatas({
              ...miniSubAddInputDatas,
              isPreviewPhoto: !miniSubAddInputDatas.isPreviewPhoto,
            });
          }}
        >
          <Box
            component="img"
            src={URL.createObjectURL(
              miniSubAddInputDatas.photo[
                miniSubAddInputDatas.indexPreviePhoto - 1
              ]
            )}
            sx={{ width: '600px', height: '600px', aspectRation: '1/1' }}
          />
        </Dialog>
      )}
      {/* //* Description */}
      <Grid item xs={6}>
        <Typography sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}>
          Description
        </Typography>
        <CustomInput
          sx={{ width: '100%', bgcolor: '#FAFAFA' }}
          placeholder="Enter description"
          value={miniSubAddInputDatas.description}
          onChange={(e) => {
            if (e.target.value.length <= 250) {
              setMiniSubAddInputDatas({
                ...miniSubAddInputDatas,
                description: e.target.value,
              });
            }
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            Enter at least 20 character
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#9E9D9D',
              mt: '4px',
            }}
          >
            {miniSubAddInputDatas.description.length}/250
          </Typography>
        </Box>
      </Grid>
      {/* //* Schedule */}
      <Grid item xs={6} sx={{}}>
        <Grid container>
          <Grid item alignItems="center" sx={{ height: '100%', mr: '20px' }}>
            <Typography
              sx={{
                mb: '16px',
                fontSize: '16px',
                fontWeight: '500',
              }}
            >
              Schedule
            </Typography>
          </Grid>
          <Grid item alignItems="center" sx={{ height: '100%' }}>
            <RadioGroup
              row
              value={miniSubAddInputDatas.scheduleType}
              onChange={(e) => {
                setMiniSubAddInputDatas({
                  ...miniSubAddInputDatas,
                  schedule: today,
                  scheduleType: e.target.value,
                });
              }}
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: '16px',
                },
                '.MuiRadio-root': {
                  padding: '0 8px',
                },
                '.MuiTypography-root': {
                  fontSize: '12px',
                },
              }}
            >
              <FormControlLabel value="now" control={<Radio />} label="Now" />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', minWidth: '100%' }}>
          <DesktopDatePicker
            inputFormat="DD/MM/YYYY"
            value={miniSubAddInputDatas.schedule}
            onChange={(newValue) => {
              if (miniSubAddInputDatas.scheduleType !== 'now') {
                setMiniSubAddInputDatas({
                  ...miniSubAddInputDatas,
                  schedule: newValue,
                });
              } else {
                setMiniSubAddInputDatas({
                  ...miniSubAddInputDatas,
                  schedule: today,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              />
            )}
            disabled={
              miniSubAddInputDatas.scheduleType === 'now' ? true : false
            }
          />
        </Box>
      </Grid>
      {/* //* footer */}
      <Box
        sx={{
          width: '100%',
          bgcolor: 'white',
          position: 'fixed',
          bottom: '0px',
          right: '0px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '18px 32px 18px 240px',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
            Publish
          </Typography>
          <AntSwitch
            checked={miniSubAddInputDatas.publish}
            onChange={(e) => {
              setMiniSubAddInputDatas({
                ...miniSubAddInputDatas,
                publish: !miniSubAddInputDatas.publish,
              });
            }}
          />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <CustomButton
            variant="contained"
            disabled={!isSubmitAvailable}
            onClick={handleAdd}
            sx={{ width: '140px', height: '36px' }}
          >
            <Typography
              sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
            >
              Submit
            </Typography>
          </CustomButton>
        </Box>
      </Box>
    </Grid>
  );
};
export default MiniSubMenuAdd;
