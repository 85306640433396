import { Box, Checkbox, Divider, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { NoImage } from '../../../../../../../../assets/Icons';
import {
  changeIsSelectAcceptTransaction,
  changeNoqrDataSelectAcceptTransaction,
  getAllDataAcceptTransactionScan,
} from '../../../../../../../../store/transaction/request/acceptDataTransaction';

const UnscannedDataAcceptTransaction = () => {
  const {
    isExist,
    scanned,
    idScanned,
    allData,
    allDataWithQr,
    noqrDataSelect,
    noqrid,
  } = useSelector(getAllDataAcceptTransactionScan);

  const dispatch = useDispatch();

  const handleChange = (event, item, id) => {
    dispatch(changeNoqrDataSelectAcceptTransaction({ item, id }));
  };

  useEffect(() => {
    noqrDataSelect?.length > 0
      ? dispatch(changeIsSelectAcceptTransaction(true))
      : dispatch(changeIsSelectAcceptTransaction(false));
  }, [noqrDataSelect]);

  return (
    <>
      <Grid container columns={1} sx={{ pb: '54px' }}>
        {/* with qr */}
        <Grid item xs={1}>
          <Typography
            sx={{
              fontSize: '12px',
              color: '#9E9D9D',
              background: '#FAFAFA',
              mt: 3,
              padding: '12px',
            }}
          >
            QR Code
          </Typography>
        </Grid>

        {allDataWithQr.length === 0 ? (
          <Grid item xs={1} sx={{ textAlign: 'center', padding: '12px' }}>
            <Typography sx={{ fontSize: '10px', color: '#9E9D9D' }}>
              no data found
            </Typography>
          </Grid>
        ) : (
          allDataWithQr.map((list, index) =>
            list?.jumlah - scanned?.length !== 0 ? (
              <Grid item xs={1} key={index}>
                <Box
                  sx={{
                    p: '14px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                    {list?.product?.gambar ? (
                      <Box
                        sx={{
                          borderRadius: '8px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          bgcolor: '#F5F5F5',
                          width: '45px',
                          height: '45px',
                        }}
                      >
                        <img
                          src={
                            process.env.REACT_APP_API_IMAGE_BASE_URL +
                            '/' +
                            list?.product?.gambar
                          }
                          style={{
                            width: '45px',
                            height: '45px',
                            borderRadius: '8px',
                          }}
                          alt="gbr"
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          borderRadius: '8px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          bgcolor: '#F5F5F5',
                          width: '45px',
                          height: '45px',
                        }}
                      >
                        <NoImage />
                      </Box>
                    )}
                    <Box>
                      <Typography sx={{ fontSize: '12px' }}>
                        {list?.product?.namaProduk}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      sx={{ fontSize: '12px', fontWeight: '600', mt: '4px' }}
                    >
                      {list?.jumlah - scanned?.length} {list?.unit}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
              </Grid>
            ) : (
              <Grid item xs={1} sx={{ textAlign: 'center', padding: '12px' }}>
                <Typography sx={{ fontSize: '10px', color: '#9E9D9D' }}>
                  no data found
                </Typography>
              </Grid>
            )
          )
        )}

        {/* no qr */}
        <Grid item xs={1}>
          <Typography
            sx={{
              fontSize: '12px',
              color: '#9E9D9D',
              background: '#FAFAFA',
              mt: 3,
              padding: '12px',
            }}
          >
            Non QR Code
          </Typography>
        </Grid>
        {allData.filter(
          (list) =>
            list.qrcodeType === false &&
            !noqrid?.some((item) => item.id_detail.includes(list.iddetailSti))
        ).length === 0 ? (
          <Grid item xs={1} sx={{ textAlign: 'center', padding: '12px' }}>
            <Typography sx={{ fontSize: '10px', color: '#9E9D9D' }}>
              no data found
            </Typography>
          </Grid>
        ) : (
          allData
            .filter(
              (list) =>
                list.qrcodeType === false &&
                !noqrid?.some((item) =>
                  item.id_detail.includes(list.iddetailSti)
                )
            )
            ?.map((list, index) => (
              <Grid item xs={1} key={index}>
                <Box
                  sx={{
                    padding: '14px 0px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      // justifyContent: 'center',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Checkbox
                      checked={
                        noqrDataSelect?.filter(
                          (val) => val.idProduk === list.idProduk
                        ).length !== 0
                      }
                      onChange={(event) => {
                        handleChange(event, list, list?.idProduk);
                      }}
                    />

                    {list?.gambar ? (
                      <Box
                        sx={{
                          borderRadius: '8px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          bgcolor: '#F5F5F5',
                          width: '45px',
                          height: '45px',
                          gap: 2,
                        }}
                      >
                        <img
                          src={
                            process.env.REACT_APP_API_IMAGE_BASE_URL +
                            '/' +
                            list?.gambar
                          }
                          style={{
                            width: '45px',
                            height: '45px',
                            borderRadius: '8px',
                          }}
                          alt="gbr"
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          borderRadius: '8px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          bgcolor: '#F5F5F5',
                          width: '45px',
                          height: '45px',
                          gap: 2,
                        }}
                      >
                        <NoImage />
                      </Box>
                    )}

                    <Box>
                      <Typography sx={{ fontSize: '12px' }}>
                        {list?.product?.namaProduk}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{}}>
                    <Typography
                      sx={{ fontSize: '12px', fontWeight: '600', mt: '4px' }}
                    >
                      {list?.jumlah} {list?.unit}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
              </Grid>
            ))
        )}
      </Grid>
    </>
  );
};
export default UnscannedDataAcceptTransaction;
