import { createSlice } from '@reduxjs/toolkit';
import { getPlacesByCoordinate, getPlacesByName } from '../../api/AddressMap';

export const editSupplierState = createSlice({
  name: 'editSupplier',
  initialState: {
    idSupplier: '',
    supplierName: '',
    supplierNameOld: '',
    supplierCode: '',
    email: '',
    phoneNumber: '',
    joinDate: '',

    oldEmail: '',
    oldPhoneNumber: '',
    oldCode: '',

    // address map
    address: '',
    addressFix: '',
    coordinateFix: [],
    mapInputType: 'manual',
    placeGetByName: null,
    coordinate: [],
    center: [3.546728899138594, 98.69014558305145],
    placesSearchByName: [],
    placesSearchByNameLoading: false,
    placeGetByCoordinate: null,
    placeGetByCoordinateLoading: false,
    cityDistrict: '',

    publish: false,
  },

  reducers: {
    loadDataToEditSupplier: (state, action) => {
      const {
        id,
        name,
        code,
        email,
        telpon,
        joinDate,
        status,
        lat,
        lon,
        typeInput,
        cityDistrict,
        address,
      } = action.payload;
      state.idSupplier = id;
      state.supplierName = name;
      state.supplierNameOld = name;
      state.supplierCode = code;
      state.email = email;
      state.phoneNumber = telpon;
      state.oldEmail = email;
      state.oldPhoneNumber = telpon;
      state.oldCode = code;
      state.joinDate = joinDate;
      state.publish = status === 'active' ? true : false;
      state.address = address || '';
      state.cityDistrict = cityDistrict || '';
      state.mapInputType = typeInput;
      state.coordinate = [lat, lon];
    },
    changeSupplierNameEdit: (state, action) => {
      state.supplierName = action.payload;
    },
    changeSupplierCodeEdit: (state, action) => {
      state.supplierCode = action.payload;
    },
    changeEmailSupplierEdit: (state, action) => {
      state.email = action.payload;
    },
    changePhoneNumberSupplierEdit: (state, action) => {
      state.phoneNumber = action.payload;
    },
    changeJoinDateEdit: (state, action) => {
      state.joinDate = action.payload;
    },
    // ------------- Address ----------
    changeMapTypeSupplierEdit: (state, action) => {
      state.mapInputType = action.payload;
    },
    changePlaceGetByNameEdit: (state, action) => {
      let result = action.payload;
      state.placeGetByName = result;
      state.coordinate = [result.lat, result.lon];
      state.center = [result.lat, result.lon];
    },
    changeCoordinateEdit: (state, action) => {
      state.coordinate = action.payload;
    },
    changeCenterEdit: (state, action) => {
      state.center = action.payload;
    },
    changeAddressEdit: (state, action) => {
      let result = action.payload;
      state.address = result;
    },
    submitAddresMap: (state, action) => {
      const { address, coordinate } = action.payload;
      state.addressFix = address;
      state.coordinateFix = coordinate;
    },
    loadPlaceSearchByName: (state, action) => {
      state.placesSearchByName = action.payload;
    },
    loadPlaceSearchByNameLoading: (state, action) => {
      state.placesSearchByNameLoading = action.payload;
    },
    changeIsPlaceGetByCoordinateLoadingEdit: (state, action) => {
      let result = action.payload;
      state.placeGetByCoordinateLoading = result;
    },
    changePlaceGetByCoordinateEdit: (state, action) => {
      let result = action.payload;
      state.placeGetByCoordinate = result;
    },
    changePublishEdit: (state, action) => {
      state.publish = action.payload;
    },
  },
});

export const {
  loadDataToEditSupplier,
  changeSupplierNameEdit,
  changeSupplierCodeEdit,
  changeEmailSupplierEdit,
  changePhoneNumberSupplierEdit,
  changeJoinDateEdit,
  changeAddressSupplierEdit,

  // address
  changeMapTypeSupplierEdit,
  changePlaceGetByNameEdit,
  changeAddressEdit,
  changeCoordinateEdit,
  changeCenterEdit,
  submitAddresMap,
  loadPlaceSearchByName,
  loadPlaceSearchByNameLoading,
  changeIsPlaceGetByCoordinateLoadingEdit,
  changePlaceGetByCoordinateEdit,
  changePublishEdit,
} = editSupplierState.actions;

export const getDataStateEditSupplier = (state) => state.editSupplier;

export const fetchPlacesSearchByName = (name) => (dispatch) => {
  const fetchData = async () => {
    dispatch(loadPlaceSearchByNameLoading(true));
    try {
      const { data } = await getPlacesByName(name);
      dispatch(loadPlaceSearchByName(data));
    } catch (error) {
      dispatch(loadPlaceSearchByName([]));
    } finally {
      dispatch(loadPlaceSearchByNameLoading(false));
    }
  };

  fetchData();
};

export const fetchPlacesSearchByCoordinate = (coor) => (dispatch) => {
  const fetchData = async () => {
    dispatch(changeIsPlaceGetByCoordinateLoadingEdit(true));
    try {
      const { data } = await getPlacesByCoordinate(coor);
      dispatch(changePlaceGetByCoordinateEdit(data));
    } catch (error) {
      dispatch(changePlaceGetByCoordinateEdit([]));
    } finally {
      dispatch(changeIsPlaceGetByCoordinateLoadingEdit(false));
    }
  };

  fetchData();
};

export default editSupplierState.reducer;
