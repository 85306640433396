import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { getAllProductSupplierRouting } from "../../../../../../store/Routing/productSupplierRouting";
import NotifProductSupplier from "./NotifSupplier";
import InventoryProductSupplier from "..";
import AddProductSupplier from "../add";
import { DetailSupplier } from "../detail/DetailSupplier";
import EditSupplier from "../edit/EditSupplier";
import AddPoroduk from "../MobileView/add";
import DetailProduk from "../MobileView/detail";
import EditProduk from "../MobileView/edit";

const RoutingProductSupplier = () => {
  const { page } = useSelector(getAllProductSupplierRouting);

  return (
    <Box>
      <Box>
        <NotifProductSupplier />
      </Box>
      {page === "" && <InventoryProductSupplier />}
      {page === "addProductSupplier" && <AddProductSupplier />}

      {/* detail Supplier */}
      {page === "detailSupplier" && <DetailSupplier />}
      {page === "editSupplier" && <EditSupplier />}

      {/* mobileView */}
      {page === "addProduk" && <AddPoroduk />}
      {page === "detailProduk" && <DetailProduk />}
      {page === "editProduk" && <EditProduk />}
    </Box>
  );
};
export default RoutingProductSupplier;
