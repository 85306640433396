import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  ListItemIcon,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { ReactComponent as EntranceIcon } from '../../../../../../../../assets/entrance.svg';
import CustomButton from '../../../../../../../../components/shared/CustomButton';
import {
  addRowCreateMapTop,
  changeCreateMapPage,
  changeIsEntrance,
  getCellCreateMap,
  getCellSelectedAllCreateMap,
  getCellSelectedDetailsCreateMap,
  getCellSelectedHoverCreateMap,
  getCellSelectedMomentCreateMap,
  getEntranceCreateMap,
  getIsCellSelectedEditCreateMap,
  getIsEntranceCreateMap,
  addRowCreateMapBottom,
  reduceRowCreateMapBottom,
  reduceRowCreateMapTop,
  addColCreateMapLeft,
  reduceColCreateMapLeft,
  addColCreateMapRight,
  reduceColCreateMapRight,
  addEntrance,
  addCellSelectedMomentHold,
  addCellSelectedMoment,
  changeErrorEmptyMiniHub,
  getErrorEmptyMiniHub,
} from '../../../../../../../../store/hub/miniHub/miniHubAdd';
import CustomMenu from '../../../../../../../../components/shared/CustomMenu';
import EditRackMiniHub from './EditRackMiniHub';
import InsertRackMiniHub from './InsertRack';
import ListRackMiniHub from './ListRack';

const SelectRackMiniHub = () => {
  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(changeCreateMapPage('grid'));
  };
  const handleNext = () => {
    dispatch(changeCreateMapPage('preview'));
  };

  const [openInsert, setOpenInsert] = useState(null);
  const isOpenInsert = Boolean(openInsert);

  const [openRows, setOpenRows] = useState(null);
  const isOpenRows = Boolean(openRows);

  const [openCols, setOpenCols] = useState(null);
  const isOpenCols = Boolean(openCols);

  const idOpenRows = isOpenRows ? 'row-popover' : undefined;
  const idOpenCols = isOpenCols ? 'col-popover' : undefined;

  // ------------------ Mapping Rack -----------------
  const cell = useSelector(getCellCreateMap);
  const isEntrance = useSelector(getIsEntranceCreateMap);
  const entrance = useSelector(getEntranceCreateMap);
  const cellSelectedAll = useSelector(getCellSelectedAllCreateMap);
  const hoverCell = useSelector(getCellSelectedHoverCreateMap);
  const isCellEdit = useSelector(getIsCellSelectedEditCreateMap);
  const cellSelectedMoment = useSelector(getCellSelectedMomentCreateMap);
  const cellSelectedDetails = useSelector(getCellSelectedDetailsCreateMap);

  const [cellSelected, setCellSelected] = useState([]);
  const errorEmpty = useSelector(getErrorEmptyMiniHub);

  useEffect(() => {
    let allDats = cellSelectedAll;
    setCellSelected(allDats.flat().filter((item) => !hoverCell.includes(item)));
  }, [cellSelectedAll, hoverCell]);

  const [isHoldingMouse, setIsHoldingMouse] = useState(false);

  // submit validation
  const [isAvailableNext, setIsAvailableNext] = useState(false);
  useEffect(() => {
    if (cellSelected.length !== 0) {
      return setIsAvailableNext(true);
    } else {
      return setIsAvailableNext(false);
    }
  }, [cellSelected]);

  useEffect(() => {
    cellSelectedMoment.length === 0 && dispatch(changeErrorEmptyMiniHub(''));
  }, [cellSelectedMoment]);

  return (
    <Box>
      <Grid container columns={12} spacing={2}>
        {/* Tool */}
        <Grid item xs={2}>
          <CustomButton
            sx={{ border: '1px solid #51B15C', color: '#51B15C' }}
            endIcon={<ArrowDropDownRoundedIcon />}
            onClick={(e) => setOpenInsert(e.currentTarget)}
          >
            Edit
          </CustomButton>
          <CustomMenu
            open={isOpenInsert}
            openMenu={openInsert}
            setOpenMenu={setOpenInsert}
            sx={{
              '.MuiMenuItem-root': {
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: 0,
                paddingBottom: 0,
                borderRadius: '8px',
              },
            }}
          >
            <MenuItem>
              <ListItemIcon
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
                onClick={() => {
                  dispatch(changeIsEntrance());
                }}
              >
                <EntranceIcon fontSize="small" />
                <Typography
                  sx={{ fontSize: '0.75rem', color: 'primary.black' }}
                >
                  Insert entrance
                </Typography>
              </ListItemIcon>
            </MenuItem>
            <MenuItem
              sx={{
                mt: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: '0.75rem',
              }}
              onClick={(e) => setOpenRows(e.currentTarget)}
              aria-describedby={idOpenRows}
            >
              <Typography sx={{ fontSize: '0.75rem', color: 'primary.black' }}>
                Rows
              </Typography>

              <ArrowRightRoundedIcon sx={{ padding: 0 }} />
            </MenuItem>
            <Popover
              id={idOpenRows}
              open={isOpenRows}
              anchorEl={openRows}
              onClose={() => setOpenRows(null)}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Box sx={{ py: 0.5, borderRadius: '8px' }}>
                <MenuItem
                  sx={{ fontSize: '0.75rem' }}
                  onClick={() => dispatch(addRowCreateMapTop(Number(1)))}
                >
                  Insert 1 row above
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: '0.75rem' }}
                  onClick={() => dispatch(reduceRowCreateMapTop(Number(1)))}
                >
                  Delete 1 row above
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: '0.75rem' }}
                  onClick={() => dispatch(addRowCreateMapBottom(Number(1)))}
                >
                  Insert 1 row below
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: '0.75rem' }}
                  onClick={() => dispatch(reduceRowCreateMapBottom(Number(1)))}
                >
                  Delete 1 row below
                </MenuItem>
              </Box>
            </Popover>
            <MenuItem
              sx={{
                mt: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: '0.75rem',
              }}
              onClick={(e) => setOpenCols(e.currentTarget)}
              aria-describedby={idOpenCols}
            >
              <Typography sx={{ fontSize: '0.75rem', color: 'primary.black' }}>
                Columns
              </Typography>
              <ArrowRightRoundedIcon sx={{ padding: 0 }} />
            </MenuItem>
            <Popover
              id={idOpenCols}
              open={isOpenCols}
              anchorEl={openCols}
              onClose={() => setOpenCols(null)}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Box sx={{ py: 0.5, borderRadius: '8px' }}>
                <MenuItem
                  sx={{ fontSize: '0.75rem' }}
                  onClick={() => dispatch(addColCreateMapLeft(Number(1)))}
                >
                  Insert 1 column left
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: '0.75rem' }}
                  onClick={() => dispatch(reduceColCreateMapLeft(Number(1)))}
                >
                  Delete 1 column left
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: '0.75rem' }}
                  onClick={() => dispatch(addColCreateMapRight(Number(1)))}
                >
                  Insert 1 column right
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: '0.75rem' }}
                  onClick={() => dispatch(reduceColCreateMapRight(Number(1)))}
                >
                  Delete 1 column right
                </MenuItem>
              </Box>
            </Popover>
          </CustomMenu>
        </Grid>

        {/* Box mapping */}
        <Grid item xs={6}>
          <Box>
            {cell.map((row, rowIndex) => (
              <Box sx={{ display: 'flex' }} key={rowIndex}>
                {row.map((col, colIndex) => (
                  <Box
                    onClick={() => {
                      if (
                        rowIndex === 0 ||
                        rowIndex === cell.length - 1 ||
                        colIndex === 0 ||
                        colIndex === row.length - 1
                      ) {
                        dispatch(addEntrance(col));
                      } else {
                        dispatch(addCellSelectedMoment(col));
                      }
                    }}
                    onMouseDown={() => {
                      setIsHoldingMouse(true);
                    }}
                    onMouseOver={() => {
                      if (isHoldingMouse) {
                        if (
                          rowIndex === 0 ||
                          rowIndex === cell.length - 1 ||
                          colIndex === 0 ||
                          colIndex === row.length - 1
                        ) {
                        } else if (!cellSelectedAll.includes(col)) {
                          dispatch(addCellSelectedMomentHold(col));
                        }
                      }
                    }}
                    onMouseUp={() => {
                      setIsHoldingMouse(false);
                    }}
                    sx={[
                      {
                        width: '36px',
                        height: '36px',
                        border: 1,
                      },
                      isHoldingMouse
                        ? {
                            cursor: 'grabbing',
                          }
                        : {
                            cursor: 'pointer',
                          },
                      cellSelectedMoment.includes(col) && {
                        bgcolor: '#51B15C',
                      },
                      hoverCell.includes(col) && {
                        bgcolor: '#51B15C',
                        transition: 'width 0.1s',
                        transitionTimingFunction: 'ease-in-out',
                      },
                      cellSelected.includes(col) && {
                        bgcolor: '#9E9D9D',
                      },
                      // *  active entrance
                      isEntrance &&
                        rowIndex === 0 && {
                          bgcolor: '#F5F5F5',
                          border: 0,
                        },
                      isEntrance &&
                        rowIndex === cell.length - 1 && {
                          bgcolor: '#F5F5F5',
                          border: 0,
                        },
                      isEntrance &&
                        colIndex === 0 && {
                          bgcolor: '#F5F5F5',
                          border: 0,
                        },
                      isEntrance &&
                        colIndex === row.length - 1 && {
                          bgcolor: '#F5F5F5',
                          border: 0,
                        },
                      // *  active entrance and selected
                      isEntrance &&
                        rowIndex === 0 &&
                        entrance.includes(col) && {
                          bgcolor: '#E4E4E4',
                          border: 0,
                        },
                      isEntrance &&
                        rowIndex === cell.length - 1 &&
                        entrance.includes(col) && {
                          bgcolor: '#E4E4E4',
                          border: 0,
                        },
                      isEntrance &&
                        colIndex === 0 &&
                        entrance.includes(col) && {
                          bgcolor: '#E4E4E4',
                          border: 0,
                        },
                      isEntrance &&
                        colIndex === row.length - 1 &&
                        entrance.includes(col) && {
                          bgcolor: '#E4E4E4',
                          border: 0,
                        },
                      // * non active entrance
                      !isEntrance &&
                        rowIndex === 0 && {
                          display: 'none',
                        },
                      !isEntrance &&
                        rowIndex === cell.length - 1 && {
                          display: 'none',
                        },
                      !isEntrance &&
                        colIndex === 0 && {
                          display: 'none',
                        },
                      !isEntrance &&
                        colIndex === row.length - 1 && {
                          display: 'none',
                        },
                    ]}
                    key={colIndex}
                  ></Box>
                ))}
              </Box>
            ))}
          </Box>
        </Grid>

        {/* select rack */}
        <Grid item xs={3}>
          {errorEmpty && (
            <Typography
              sx={{
                color: '#F33A3A',
                fontSize: '12px',
                p: 2,
                background: 'rgba(243, 58, 58, 0.1)',
                borderRadius: '4px',
                mb: 1,
              }}
            >
              {errorEmpty}
            </Typography>
          )}
          {isCellEdit ? (
            <EditRackMiniHub />
          ) : (
            !isCellEdit &&
            cellSelectedMoment.length !== 0 && <InsertRackMiniHub />
          )}
          {cellSelectedDetails.length !== 0 && (
            <Box sx={{ mt: '20px', pb: '100px' }}>
              {cellSelectedDetails.map((item, idx) => (
                <ListRackMiniHub name={item.name} key={idx} index={idx} />
              ))}
            </Box>
          )}
        </Grid>
      </Grid>

      {/* Submit */}
      <Box
        sx={{
          width: '100%',
          bgcolor: 'white',
          position: 'fixed',
          bottom: '0px',
          right: '0px',
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '18px 32px 18px 240px',
          alignItems: 'center',
          gap: 1,
          zIndex: '+2',
        }}
      >
        <CustomButton
          variant="outlined"
          sx={{ width: '190px', height: '48px' }}
          onClick={handleBack}
        >
          Back
        </CustomButton>
        <CustomButton
          variant="contained"
          sx={{ width: '190px', height: '48px' }}
          onClick={handleNext}
          disabled={!isAvailableNext}
        >
          Next
        </CustomButton>
      </Box>
    </Box>
  );
};
export default SelectRackMiniHub;
