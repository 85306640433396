import { useDispatch, useSelector } from 'react-redux';
import useWindowDimensions from '../../../../../../../../hooks/useWindowDimensions';
import { useState } from 'react';
import { useEffect } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { changePageTransaction } from '../../../../../../../../store/Routing/transactionRouting';
import {
  ArrowBackIosIcon,
  DataNotFound,
  FlashIcon,
  ScanTargetIcon,
} from '../../../../../../../../assets/Icons';
import {
  LoadingComponent,
  ScanQrTool,
} from '../../../../../../../../components/shared/shared2';
import CustomBottomDrawer from '../../../../../../../../components/shared/CustomBottomDrawer';
import { getIdDetailTransactionRequest } from '../../../../../../../../store/transaction/request/detailTransactionRequest';
import {
  getAllDataTransactionScan,
  scanDataTransaction,
} from '../../../../../../../../store/transaction/request/sendDataTransaction';
import { getDetailQrCodeTransaction } from '../../../../../../../../api/qrcode';

const TransactionProsesScan = () => {
  const dispatch = useDispatch();
  const { width: widthScreen } = useWindowDimensions();
  const [isQrNotExist, setIsQrNotExist] = useState(false);
  const [isLoadingScan, setIsLoadingScan] = useState(false);
  const idTransaction = useSelector(getIdDetailTransactionRequest);
  const { pemberi, lokasiPemberi } = useSelector(getAllDataTransactionScan);

  const fetchScan = async (params) => {
    setIsLoadingScan(true);
    try {
      const {
        data: { data },
      } = await getDetailQrCodeTransaction(params);
      if (data) {
        dispatch(scanDataTransaction(data[0]));
        dispatch(changePageTransaction('sendTransactionMobile'));
      } else {
        setIsQrNotExist(true);
      }
    } catch (error) {
      console.log(error);
      setIsQrNotExist(true);
    } finally {
      setIsLoadingScan(false);
      setScanRes('');
    }
  };

  const [scanRes, setScanRes] = useState('');
  const handleScan = (value) => {
    if (value) {
      setScanRes(value);
    }
  };

  useEffect(() => {
    const params = {
      qrEncriptId: scanRes,
      idOrders: idTransaction,
      status: 'sent',
    };
    if (scanRes) {
      fetchScan(params);
    }
  }, [scanRes, idTransaction]);

  return (
    <Box>
      {/* back button */}
      <Box
        sx={{ py: '17px', display: 'flex', gap: 1, alignItems: 'center' }}
        onClick={() => dispatch(changePageTransaction('sendTransactionMobile'))}
      >
        <Box
          sx={{
            width: '24px',
            height: '24px',
            bgcolor: '#51B15C0D',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ArrowBackIosIcon sx={{ fontSize: '14px', color: '#51B15C' }} />
        </Box>
        <Typography sx={{ fontWeight: '600' }}>Scan</Typography>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          minHeight: '80vh',
          width: '100%',
          left: 0,
          bgcolor: 'black',
        }}
      >
        <Box sx={{ width: '100%', position: 'realtive' }}>
          {isLoadingScan && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                zIndex: 10,
                width: widthScreen,
                height: '600px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'white',
                opacity: 0.5,
              }}
            >
              <LoadingComponent />
            </Box>
          )}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 5,
              width: widthScreen,
              height: '600px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ScanTargetIcon />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 5,
              width: widthScreen,
              height: '600px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '315px',
                height: '370px',
                borderRadius: '25px',
                bgcolor: 'white',
                opacity: 0.2,
              }}
            ></Box>
          </Box>
          <ScanQrTool
            width={widthScreen}
            height={600}
            onChange={(value) => handleScan(value)}
            delay={1000}
          />
        </Box>
      </Box>

      <Box
        sx={{
          position: 'fixed',
          justifyContent: 'center',
          alignItems: 'center',
          bottom: 20,
          left: 0,
          width: '100%',
          display: 'flex',
        }}
      >
        <Box
          sx={{
            width: '42px',
            height: '42px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '16px',
            bgcolor: 'white',
          }}
          // onClick={handleTorchlight}
        >
          <FlashIcon />
        </Box>
      </Box>
      <CustomBottomDrawer
        open={isQrNotExist}
        title={'Opps'}
        handleClose={() => setIsQrNotExist(false)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
          <Box
            sx={{
              width: '45px',
              height: '45px',
              bgcolor: '#F33A3A1A',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DataNotFound />
          </Box>
          <Typography>The product isn’t in the list.</Typography>
        </Box>
        <Divider sx={{ my: '20px' }} />
        <Box></Box>
      </CustomBottomDrawer>
    </Box>
  );
};
export default TransactionProsesScan;
