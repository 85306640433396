import { Box, Divider, Grid, Typography } from '@mui/material';
import { NoImage } from '../../../../../../assets/Icons';
import { useSelector } from 'react-redux';
import { getAllSupplierScan } from '../../../../../../store/purchase/purchaseSupplier/purchaseSubmitSupplier';
import { useState } from 'react';
import { getAllQrCode } from '../../../../../../api/qrcode';
import { getAllPurchaseRouting } from '../../../../../../store/Routing/purchaseRouting';

const ScannedData = () => {
  const { isExist, scanned, allData, noqr } = useSelector(getAllSupplierScan);

  return (
    <Grid container columns={1}>
      {/* qr code */}
      <Grid item xs={1}>
        <Typography
          sx={{
            fontSize: '12px',
            color: '#9E9D9D',
            background: '#FAFAFA',
            mt: 3,
            padding: '12px',
          }}
        >
          QR Code
        </Typography>
      </Grid>
      {scanned?.length === 0 ? (
        <Grid item xs={1} sx={{ textAlign: 'center', padding: '12px' }}>
          <Typography sx={{ fontSize: '10px', color: '#9E9D9D' }}>
            no data found
          </Typography>
        </Grid>
      ) : (
        scanned?.map((list, index) => (
          <Grid item xs={1} key={index}>
            <Box
              sx={{
                p: '14px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                {list?.gambar ? (
                  <Box
                    sx={{
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: '#F5F5F5',
                      width: '45px',
                      height: '45px',
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_API_IMAGE_BASE_URL +
                        '/' +
                        list?.gambar
                      }
                      style={{
                        width: '45px',
                        height: '45px',
                        borderRadius: '8px',
                      }}
                      alt="gbr"
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: '#F5F5F5',
                      width: '45px',
                      height: '45px',
                    }}
                  >
                    <NoImage />
                  </Box>
                )}
                <Box>
                  <Typography sx={{ fontSize: '12px' }}>
                    {list?.namaProduk}
                  </Typography>
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: '600', mt: '4px' }}
                  >
                    1 {list?.unit}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{}}>
                {/* <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                Rp.1.000.000
              </Typography> */}
              </Box>
            </Box>
            <Divider />
          </Grid>
        ))
      )}

      {/* no qr */}
      <Grid item xs={1}>
        <Typography
          sx={{
            fontSize: '12px',
            color: '#9E9D9D',
            background: '#FAFAFA',
            mt: 3,
            padding: '12px',
          }}
        >
          Non QR Code
        </Typography>
      </Grid>

      {noqr?.flat().length === 0 ? (
        <Grid item xs={1} sx={{ textAlign: 'center', padding: '12px' }}>
          <Typography sx={{ fontSize: '10px', color: '#9E9D9D' }}>
            no data found
          </Typography>
        </Grid>
      ) : (
        noqr?.flat().map((list, index) => (
          <Grid item xs={1} key={index}>
            <Box
              sx={{
                padding: '14px 0px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  // justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                {list?.gambar ? (
                  <Box
                    sx={{
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: '#F5F5F5',
                      width: '45px',
                      height: '45px',
                      gap: 2,
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_API_IMAGE_BASE_URL +
                        '/' +
                        list?.gambar
                      }
                      style={{
                        width: '45px',
                        height: '45px',
                        borderRadius: '8px',
                      }}
                      alt="gbr"
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: '#F5F5F5',
                      width: '45px',
                      height: '45px',
                      gap: 2,
                    }}
                  >
                    <NoImage />
                  </Box>
                )}

                <Box>
                  <Typography sx={{ fontSize: '12px' }}>
                    {list?.namaProduk}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{}}>
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '600', mt: '4px' }}
                >
                  {list?.qty} {list?.unit}
                </Typography>
              </Box>
            </Box>
            <Divider />
          </Grid>
        ))
      )}
    </Grid>
  );
};
export default ScannedData;
