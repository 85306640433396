import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Switch,
  Dialog,
  styled,
} from '@mui/material';
import dayjs from 'dayjs';
import BackButton from '../../../../../../components/shared/BackButton';
import CustomButton from '../../../../../../components/shared/CustomButton.jsx';
import { useEffect, useState } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ReactComponent as NoImage } from '../../../../../../assets/no-image.svg';
import { ChartDetails } from '../DesktopComponent';
import {
  deleteProductProduct,
  updateProductProductStatus,
} from '../../../../../../api/products';
import CustomEditConfirm from '../../../../../../components/shared/CustomEditConfirm';
import CustomDeleteConfirm from '../../../../../../components/shared/CustomDeleteConfirm';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';
import ProductProductDetailsMobile from './ProductProductDetailsMobile';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const ProductProductDetails = (props) => {
  const {
    page,
    setPage,
    roadHirarki,
    setRoadHirarki,
    // *---------
    setIsProductProductDetails,
    setIsProductProductEdit,
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    // *---------
    detailsDatas,
    isLoadingDataDetails,
    // *--------
    productEditInputDatas,
    setProductEditInputDatas,
    categoryDataAwaitEdit,
    setCategoryDataAwaitEdit,
    unitDataAwaitEdit,
    setUnitDataAwaitEdit,
    variatnDataAwaitEdit,
    setVariantDataAwaitEdit,
    stockLimitDataAwaitEdit,
    setStockLimitDataAwaitEdit,
    // *----------
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
  } = props;
  const today = dayjs(new Date());
  const theme = useTheme();

  const [desktopMode, setDesktopMode] = useState(true);

  useEffect(() => {
    if (window.screen.width <= 830) {
      setDesktopMode(false);
    } else {
      setDesktopMode(true);
    }
  }, [window.screen.width]);

  const [linkPreviewPhoto, setLinkPreviewPhoto] = useState('');
  const [isPreviewPhoto, setIsPreviewPhoto] = useState(false);
  // !------------------- Has Mini Or no ---------------
  const [hirarkiDetails, setHirarkiDetails] = useState({
    subsidiary: '',
    subsidiaryId: 0,
    category: '',
    categoryId: 0,
    subCategory: '',
    subCategoryId: 0,
    product: '',
    productId: 0,
    miniSubCategory: '',
    miniSubCategoryId: 0,
  });

  useEffect(() => {
    if (detailsDatas.miniCategory !== null) {
      return setHirarkiDetails({
        subsidiary:
          detailsDatas.miniCategory?.subCategory?.category?.subsidiary?.name,
        subsidiaryId:
          detailsDatas.miniCategory?.subCategory?.category?.subsidiary?.id,
        category:
          detailsDatas.miniCategory?.subCategory?.category?.namaKategori,
        categoryId:
          detailsDatas.miniCategory?.subCategory?.category?.idKategori,
        subCategory: detailsDatas.miniCategory?.subCategory?.subkategori,
        subCategoryId: detailsDatas.miniCategory?.subCategory?.idSubkategori,
        miniSubCategory: detailsDatas.miniCategory?.name,
        miniSubCategoryId: detailsDatas.miniCategory?.id,
        product: detailsDatas.namaProduk,
        productId: detailsDatas.idProduk,
      });
    } else {
      return setHirarkiDetails({
        subsidiary: detailsDatas.subCategory?.category?.subsidiary?.name,
        subsidiaryId: detailsDatas.subCategory?.category?.subsidiary?.id,
        category: detailsDatas.subCategory?.category?.namaKategori,
        categoryId: detailsDatas.subCategory?.category?.idKategori,
        subCategory: detailsDatas.subCategory?.subkategori,
        subCategoryId: detailsDatas.subCategory?.idSubkategori,
        miniSubCategory: '',
        miniSubCategoryId: 0,
        product: detailsDatas.namaProduk,
        productId: detailsDatas.idProduk,
      });
    }
  }, [detailsDatas.miniCategory, detailsDatas.subCategory, setHirarkiDetails]);

  // !---------------- Status Product ----------
  const [statusProduct, setStatusProduct] = useState(false);

  useEffect(() => {
    if (!isLoadingDataDetails) {
      if (detailsDatas.status === 'aktif') {
        return setStatusProduct(true);
      } else {
        return setStatusProduct(false);
      }
    }
  }, [isLoadingDataDetails]);

  // !---------------- Confirm ----------------

  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  // !---------------- handler ----------------

  const handleEditStatus = (e) => {
    if (statusProduct) {
      updateProductProductStatus(roadHirarki.productId, 'pending');
      setStatusProduct(false);
    } else {
      updateProductProductStatus(roadHirarki.productId, 'aktif');
      setStatusProduct(true);
    }
  };

  const handleEdit = () => {
    let datas = {
      name: detailsDatas.namaProduk,
      metaDescription: detailsDatas.metaDesk ? detailsDatas.metaDesk : '',
      description: detailsDatas.deskripsi,
      scheduleType: 'custom',
      schedule: detailsDatas.schedule ? dayjs(detailsDatas.schedule) : today,
      color: detailsDatas.warna,
      size: detailsDatas.berat,
      categoryId: hirarkiDetails.categoryId,
      category: hirarkiDetails.category,
      categoryChoose: hirarkiDetails.category,
      subCategoryId: hirarkiDetails.subCategoryId,
      subCategory: hirarkiDetails.subCategory,
      subCategoryChoose: hirarkiDetails.subCategory,
      miniSubCategory: hirarkiDetails.miniSubCategory,
      miniSubCategoryId: hirarkiDetails.miniSubCategoryId,
      unitValueSubmited: detailsDatas.productUnits,
      minStock: detailsDatas.stokMinUnit,
      minStockValue: detailsDatas.stokMin,
      maxStock: detailsDatas.stokMaxUnit,
      maxStockValue: detailsDatas.stokMax,
      photoUrls: !detailsDatas.gambar ? [] : detailsDatas.gambar.split(','),
      publish: detailsDatas.status === 'aktif' ? true : false,
    };

    let units = {};

    detailsDatas.productUnits.map((item, index) => {
      if (index === 0) {
        units['unitValue1'] = item.value;
        units['unitValue1name'] = item.unit.name;
        units['unitValue1id'] = item.unit.id;
      } else if (index === 1) {
        units['unitValue2'] = item.value;
        units['unitValue2name'] = item.unit.name;
        units['unitValue2id'] = item.unit.id;
      } else if (index === 2) {
        units['unitValue3'] = item.value;
        units['unitValue3name'] = item.unit.name;
        units['unitValue3id'] = item.unit.id;
      } else if (index === 3) {
        units['unitValue4'] = item.valu;
        units['unitValue4name'] = item.unit.name;
        units['unitValue4id'] = item.unit.id;
      }
    });

    setProductEditInputDatas({ ...productEditInputDatas, ...datas, ...units });
    setUnitDataAwaitEdit({ ...unitDataAwaitEdit, ...units });

    setVariantDataAwaitEdit({
      color: detailsDatas.warna,
      size: detailsDatas.berat,
    });

    setCategoryDataAwaitEdit({
      ...categoryDataAwaitEdit,
      categoryId: hirarkiDetails.categoryId,
      category: hirarkiDetails.category,
      categoryChoose: {
        idKategori: hirarkiDetails.categoryId,
        namaKategori: hirarkiDetails.category,
      },
      subCategoryId: hirarkiDetails.idSubkategori,
      subCategory: hirarkiDetails.subCategory,
      subCategoryChoose: {
        idSubkategori: hirarkiDetails.idSubkategori,
        subkategori: hirarkiDetails.subCategory,
      },
      miniSubCategory: hirarkiDetails.miniSubCategory,
      miniSubCategoryId: hirarkiDetails.miniSubCategoryId,
      miniSubCategoryChoose:
        hirarkiDetails.miniSubCategoryId === 0
          ? null
          : {
              id: hirarkiDetails.miniSubCategoryId,
              name: hirarkiDetails.miniSubCategory,
            },
    });

    setStockLimitDataAwaitEdit({
      ...stockLimitDataAwaitEdit,
      minStock: detailsDatas.stokMinUnit,
      minStockValue: detailsDatas.stokMin,
      maxStock: detailsDatas.stokMaxUnit,
      maxStockValue: detailsDatas.stokMax,
    });

    setIsProductProductDetails(false);
    setIsProductProductEdit(true);
  };

  const handleDelete = () => {
    setIsDeleteConfirm(true);
  };

  const handleDeleteFix = () => {
    deleteProductProduct(roadHirarki.productId).then((res) => {
      setRoadHirarki({ ...roadHirarki, product: '', productId: 0 });
      setIsDeleteConfirm(false);
      setIsProductProductDetails(false);
      setNotif('deleteproduct');
      setNotifTitle(
        `${res.data.data.namaProduk} has been successfully deleted`
      );
    });
  };

  const handleBackButton = () => {
    setRoadHirarki({ ...roadHirarki, product: '', productId: 0 });
    setIsProductProductDetails(false);
  };

  const handlePreviewPhoto = (link) => {
    setLinkPreviewPhoto(link);
    setIsPreviewPhoto(true);
  };

  return (
    <>
      <CustomNotifSuccess
        text={notifTitle}
        open={notif === 'editproduct'}
        onClose={() => {
          setNotif('');
          setNotifTitle('');
        }}
      />
      <CustomDeleteConfirm
        open={isDeleteConfirm}
        onClose={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickCancel={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Product"
        body={
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: '600' }}>
                {detailsDatas.namaProduk}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: '52px' }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />
      {desktopMode ? (
        <Grid container columns={12} spacing={4} sx={{ pb: '56px' }}>
          {/* BackButton */}
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <BackButton handleClick={handleBackButton} />
            {!isLoadingDataDetails && (
              <Box>
                <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
                  {hirarkiDetails?.product} Details
                </Typography>
                {!isLoadingDataDetails && (
                  <Box
                    sx={{
                      display: 'flex',
                      color: '#9E9D9D',

                      alignItems: 'center',
                      mt: '4px',
                      gap: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                      {hirarkiDetails?.subsidiary}
                    </Typography>
                    <ChevronRightIcon sx={{ fontSize: '16px' }} />
                    <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                      {hirarkiDetails?.category}
                    </Typography>
                    <ChevronRightIcon sx={{ fontSize: '16px' }} />
                    <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                      {hirarkiDetails?.subCategory}
                    </Typography>
                    {hirarkiDetails?.miniSubCategory !== '' && (
                      <>
                        <ChevronRightIcon sx={{ fontSize: '16px' }} />
                        <Typography
                          sx={{ fontSize: '16px', fontWeight: '100' }}
                        >
                          {hirarkiDetails?.miniSubCategory}
                        </Typography>
                      </>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Grid>
          {/* --- */}
          <Grid container item xs={12} columns={12} columnSpacing={4}>
            <Grid item xs={8}>
              <ChartDetails
                {...{
                  timeFrame,
                  setTimeFrame,
                  dateRange,
                  setDateRange,
                  saveDateRange,
                  setSaveDateRange,
                }}
              />
            </Grid>
            <Grid container item xs={4} columns={12} spacing={4}>
              {/* cogs */}
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                  COGS
                </Typography>
                <Box
                  sx={{
                    mt: '12px',
                    width: '100%',
                    padding: '14px 16px',
                    bgcolor: '#FAFAFA',
                    borderRadius: '8px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                    Rp{' '}
                    {!detailsDatas.hargaMenual ? '-' : detailsDatas.hargaMenual}
                  </Typography>
                </Box>
              </Grid>
              {/* rrp */}
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                  RRP
                </Typography>
                <Box
                  sx={{
                    mt: '12px',
                    width: '100%',
                    padding: '14px 16px',
                    bgcolor: '#FAFAFA',
                    borderRadius: '8px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                    Rp {!detailsDatas.hargaJual ? '-' : detailsDatas.hargaJual}
                  </Typography>
                </Box>
              </Grid>
              {/* limit */}
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                  Limit
                </Typography>
                <Box
                  sx={{
                    mt: '12px',
                    width: '100%',
                    padding: '14px 16px',
                    bgcolor: '#FAFAFA',
                    borderRadius: '8px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                    {!detailsDatas.stokMin ? '-' : detailsDatas.stokMin}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* Weight */}
          <Grid item xs={4}>
            <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
              Weight
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                {!detailsDatas.berat ? '-' : detailsDatas.berat}
              </Typography>
            </Box>
          </Grid>
          {/* Color */}
          <Grid item xs={4}>
            <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
              Color
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                {!detailsDatas.warna ? '-' : detailsDatas.warna}
              </Typography>
            </Box>
          </Grid>
          {/* Photo */}
          <Grid item xs={4}>
            <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
              Photo
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '0',
                display: 'flex',
              }}
            >
              {!detailsDatas.gambar ? (
                <Box
                  sx={{
                    width: '42px',
                    height: '42px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: '#FAFAFA',
                    borderRadius: '2px',
                  }}
                >
                  <NoImage />
                  <Typography sx={{ color: '#9E9D9D', fontSize: '6px' }}>
                    No photo
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ display: 'flex', gap: 2 }}>
                  {detailsDatas.gambar.split(',').map((item, index) => (
                    <Box onClick={() => handlePreviewPhoto(item)} key={index}>
                      <img
                        src={
                          process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + item
                        }
                        style={{
                          width: '42px',
                          height: '42px',
                          borderRadius: '4px',
                          cursor: 'pointer',
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Grid>
          {/* Dialog Photo */}
          {linkPreviewPhoto !== '' && (
            <Dialog
              open={isPreviewPhoto}
              onClose={() => {
                setIsPreviewPhoto(!isPreviewPhoto);
                setLinkPreviewPhoto('');
              }}
            >
              <Box
                component="img"
                src={
                  process.env.REACT_APP_API_IMAGE_BASE_URL +
                  '/' +
                  linkPreviewPhoto
                }
                sx={{ width: '600px', height: '600px', aspectRation: '1/1' }}
              />
            </Dialog>
          )}
          {/* Meta Description */}
          <Grid item xs={6}>
            <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
              Meta Description
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                {!detailsDatas.metaDesk ? '-' : detailsDatas.metaDesk}
              </Typography>
            </Box>
          </Grid>
          {/* Schedule */}
          <Grid item xs={6}>
            <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
              Schedule
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                {!detailsDatas.schedule
                  ? '-'
                  : dayjs(detailsDatas.schedule).format('DD/MMM/YYYY')}
              </Typography>
            </Box>
          </Grid>
          {/* Description */}
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
              Description
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                {!detailsDatas.deskripsi ? (
                  '-'
                ) : (
                  <span
                    dangerouslySetInnerHTML={{ __html: detailsDatas.deskripsi }}
                  />
                )}
              </Typography>
            </Box>
          </Grid>
          {/* Submit  */}
          <Box
            sx={{
              width: '100%',
              bgcolor: 'white',
              position: 'fixed',
              bottom: '0px',
              right: '0px',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '18px 32px 18px 240px',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Publish
              </Typography>
              <AntSwitch checked={statusProduct} onChange={handleEditStatus} />
            </Box>
            <Box sx={{ display: 'flex', gap: '20px' }}>
              <CustomButton
                variant="contained"
                color="error"
                sx={{ width: '140px', height: '36px' }}
                onClick={handleDelete}
              >
                <Typography
                  sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
                >
                  Delete
                </Typography>
              </CustomButton>
              <CustomButton
                variant="contained"
                sx={{ width: '140px', height: '36px' }}
                onClick={handleEdit}
              >
                <Typography
                  sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
                >
                  Edit
                </Typography>
              </CustomButton>
            </Box>
          </Box>
        </Grid>
      ) : (
        <ProductProductDetailsMobile
          {...{
            handleBackButton,
            roadHirarki,
            timeFrame,
            setTimeFrame,
            dateRange,
            saveDateRange,
            setDateRange,
            setSaveDateRange,
            detailsDatas,
            handleDelete,
            handleEdit,
            statusProduct,
            handleEditStatus,
            linkPreviewPhoto,
            setLinkPreviewPhoto,
            isPreviewPhoto,
            setIsPreviewPhoto,
            handlePreviewPhoto,
            hirarkiDetails,
            isLoadingDataDetails,
          }}
        />
      )}
    </>
  );
};
export default ProductProductDetails;
