import { Box, Button, Divider, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePage,
  getAllPurchaseRouting,
} from '../../../../../../store/Routing/purchaseRouting';
import BackButtonPurchaseProcessScanSupplier from './BackButtonPurchaseProcessScanSupplier';
import {
  LoadingComponent,
  ScanQrTool,
} from '../../../../../../components/shared/shared2';
import { useState } from 'react';
import useWindowDimensions from '../../../../../../hooks/useWindowDimensions';
import {
  DataNotFound,
  FlashIcon,
  ScanTargetIcon,
} from '../../../../../../assets/Icons';
import { useEffect } from 'react';
import CustomBottomDrawer from '../../../../../../components/shared/CustomBottomDrawer';
import { getDetailQrCode } from '../../../../../../api/qrcode';
import {
  getAllSupplierScan,
  scanSupplier,
} from '../../../../../../store/purchase/purchaseSupplier/purchaseSubmitSupplier';

const PurchaseProcessScanSupplier = () => {
  const dispatch = useDispatch();
  const [qrValue, setQrValue] = useState('');
  const { width: widthScreen } = useWindowDimensions();
  const [isQrNotExist, setIsQrNotExist] = useState(false);
  const { idPurchase } = useSelector(getAllPurchaseRouting);
  const { isExist } = useSelector(getAllSupplierScan);

  const [isTorchLightSuport, setIsTorchLightSuport] = useState(true);

  // useEffect(() => {
  //   if (qrValue === 'cek2') {
  //     return dispatch(changePage('purchaseProcessDetailSupplierSubmit'));
  //   } else if (qrValue && qrValue !== 'cek2') {
  //     return setIsQrNotExist(true);
  //   }
  // }, [qrValue]);
  // const SUPPORTS_MEDIA_DEVICES = 'mediaDevices' in navigator;

  // const toch = navigator.mediaDevices.enumerateDevices().then((devices) => {
  //   const cameras = devices.filter((device) => device.kind === 'videoinput');
  //   if (cameras.length === 0) {
  //     setIsTorchLightSuport(true);
  //   }
  //   const camera = cameras[cameras.length - 1];
  //   navigator.mediaDevices
  //     .getUserMedia({
  //       video: {
  //         deviceId: camera.deviceId,
  //         facingMode: ['user', 'environment'],
  //         height: { ideal: 1080 },
  //         width: { ideal: 1920 },
  //       },
  //     })
  //     .then((stream) => {
  //       const track = stream.getVideoTracks()[0];

  //       //Create image capture object and get camera capabilities
  //       const imageCapture = new ImageCapture(track);
  //       const photoCapabilities = imageCapture
  //         .getPhotoCapabilities()
  //         .then(() => {
  //           track.applyConstraints({
  //             advanced: [{ torch: true }],
  //           });
  //         });

  //       photoCapabilities();
  //     });
  // });

  const [isLoadingScan, setIsLoadingScan] = useState(false);

  const fetchScan = async (link) => {
    setIsLoadingScan(true);
    try {
      const {
        data: { data },
      } = await getDetailQrCode(link, idPurchase);
      if (data) {
        dispatch(scanSupplier(data));
        dispatch(changePage('purchaseProcessDetailSupplierSubmit'));
      } else {
        setIsQrNotExist(true);
      }
    } catch (error) {
      console.log(error);
      setIsQrNotExist(true);
    } finally {
      setIsLoadingScan(false);
      setScanRes('');
    }
  };

  const handleTorchlight = () => {
    // if (SUPPORTS_MEDIA_DEVICES) {
    //   toch();
    // }
  };

  const [scanRes, setScanRes] = useState('');
  const handleScan = (value) => {
    if (value) {
      setScanRes(value);
    }
    // if (value) {
    //   if (value === 'cek2') {
    //     dispatch(changePage('purchaseProcessDetailSupplierSubmit'));
    //   } else if (value !== 'cek2') {
    //     setIsQrNotExist(true);
    //   }
    // }
  };

  useEffect(() => {
    if (scanRes) {
      fetchScan(scanRes);
    }
  }, [scanRes]);

  return (
    <Box sx={{}}>
      <BackButtonPurchaseProcessScanSupplier />
      <Box
        sx={{
          position: 'absolute',
          minHeight: '80vh',
          width: '100%',
          left: 0,
          bgcolor: 'black',
        }}
      >
        <Box sx={{ width: '100%', position: 'realtive' }}>
          {isLoadingScan && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                zIndex: 10,
                width: widthScreen,
                height: '600px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'white',
                opacity: 0.5,
              }}
            >
              <LoadingComponent />
            </Box>
          )}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 5,
              width: widthScreen,
              height: '600px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ScanTargetIcon />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 5,
              width: widthScreen,
              height: '600px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '315px',
                height: '370px',
                borderRadius: '25px',
                bgcolor: 'white',
                opacity: 0.2,
              }}
            ></Box>
          </Box>
          <ScanQrTool
            width={widthScreen}
            height={600}
            onChange={(value) => handleScan(value)}
            delay={1000}
          />
        </Box>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          justifyContent: 'center',
          alignItems: 'center',
          bottom: 20,
          left: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '42px',
            height: '42px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '16px',
            bgcolor: 'white',
          }}
          onClick={handleTorchlight}
        >
          <FlashIcon />
        </Box>
      </Box>
      <CustomBottomDrawer
        open={isQrNotExist}
        title={'Opps'}
        handleClose={() => setIsQrNotExist(false)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
          <Box
            sx={{
              width: '45px',
              height: '45px',
              bgcolor: '#F33A3A1A',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DataNotFound />
          </Box>
          <Typography>The product isn’t in the list.</Typography>
        </Box>
        <Divider sx={{ my: '20px' }} />
        <Box></Box>
      </CustomBottomDrawer>
    </Box>
  );
};
export default PurchaseProcessScanSupplier;
