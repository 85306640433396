import {
  Box,
  Divider,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import BackButtonProcessSupplier from './BackButtonProcessSupplier';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeDiscount,
  changeDiscountFormat,
  changePrice,
  changeProduct,
  changeQty,
  changeValuePruchasProcess,
  getAllPurchaseProcessSupplier,
} from '../../../../../../store/purchase/purchaseSupplier/purchaseProcessSupplier';
import LineDiv from '../../../../../../components/shared/LineDiv';
import CustomCard from '../../../../../../components/shared/CustomCard';

import CustomInputWithMin from '../../../../../../components/shared/CustomInputWithMin';
import CustomInput from '../../../../../../components/shared/CustomInput';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import BottomMenu from '../../../../../../components/shared/BottomMenu';
import { updatePurchaseRequest } from '../../../../../../api/purchase';
import {
  FillText,
  LoadingComponent,
} from '../../../../../../components/shared/shared2';
import {
  changePage,
  setNotifPurchase,
} from '../../../../../../store/Routing/purchaseRouting';
import { DeleteIcon } from '../../../../../../assets/Icons';

const PurchaseProcessSupplier = () => {
  const dispatch = useDispatch();
  const {
    idPurchase,
    deskripsi,
    supplier,
    paymentMethod,
    products,
    productsTempo,
    invoice,
    invoiceDate,
    discount,
    discountFormat,
    idHub,
    idsubsidiary,
    idtransaksi,
    idsupplier,
  } = useSelector(getAllPurchaseProcessSupplier);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceFix, setTotalPriceFix] = useState(0);

  const today = new dayjs();

  useEffect(() => {
    dispatch(
      changeValuePruchasProcess({
        key: 'invoiceDate',
        value: today,
      })
    );
  }, []);

  useEffect(() => {
    const totalss = products.reduce((accum, next) => {
      return next.discountFormat !== 'percentage'
        ? accum +
            Number(next.purchasePrice) * Number(next.qty) -
            Number(next.discount)
        : accum +
            Number(next.purchasePrice * next.qty) -
            Number((next.discount / 100) * next.purchasePrice * next.qty);
    }, 0);

    setTotalPrice(totalss);
  }, [products]);

  useEffect(() => {
    if (discount) {
      if (discountFormat === 'currency') {
        setTotalPriceFix(totalPrice - discount);
      } else {
        const disc = (totalPrice / 100) * discount;
        setTotalPriceFix(totalPrice - disc);
      }
    }
  }, [discount, discountFormat, totalPrice]);

  const fetchUpdatePurchase = async (id, data) => {
    setIsLoading(true);
    try {
      const saveData = await updatePurchaseRequest(id, data);

      dispatch(
        setNotifPurchase({
          key: 'purchaseProcess',
          text: 'purchase has been success processed',
        })
      );
      dispatch(changePage(''));
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    const collectProduct = products.map((list) => {
      return {
        idProduk: list.idProduk,
        purchasePrice: list.purchasePrice,
        qty: list.qty,
        unit: list.unit,
        discount: list.discount,
        discountFormat: list?.discountFormat,
      };
    });
    const collect = {
      nomorfaktur: invoice,
      tgglfaktur: dayjs(invoiceDate.$d).format('YYYY-MM-DD'),
      products: collectProduct,
      idToko: idHub,
      idSuppliers: idsupplier,
      subsidiaryId: idsubsidiary,
      idtransaksi: idtransaksi,
      subHubId: null,
      miniHubId: null,
      keterangan: deskripsi,
      discount: discount !== undefined ? discount : 0,
      discountFormat: discountFormat,
    };
    if (discount) {
      collect['discount'] = Number(discount);
      collect['discountFormat'] = discountFormat;
    }

    // console.log({ test: products[0]?.purchasePrice });
    // console.log({ cek: productsTempo[0]?.purchasePrice });
    // const tempo = productsTempo?.map((item, index) =>
    //     if(item?.purchasePrice === products[index]?.purchasePrice){
    //       console.log('yes');
    //     }
    //   )
    // );
    // console.log({ tempo });

    if (JSON.stringify(products) === JSON.stringify(productsTempo)) {
      collect['status'] = 'processed';
      return fetchUpdatePurchase(idPurchase, collect);
    } else {
      collect['status'] = 'supplier bid';
      return fetchUpdatePurchase(idPurchase, collect);
    }
  };

  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);

  useEffect(() => {
    if (invoice && invoiceDate) {
      const collect = products.map((list) => {
        return list.purchasePrice;
      });
      if (collect.includes(0) || collect.includes('')) {
        return setIsSubmitAvailable(false);
      } else {
        return setIsSubmitAvailable(true);
      }
    } else {
      return setIsSubmitAvailable(false);
    }
  }, [invoice, invoiceDate, products]);

  const desktopMode = useMediaQuery('(min-width:832px)');

  return (
    <Box sx={{ mt: '16px' }}>
      {!isLoading && !isError && (
        <>
          <BackButtonProcessSupplier />
          {desktopMode && (
            <Grid
              container
              columns={6}
              spacing={4}
              sx={{ mt: '16px', pb: '54px' }}
            >
              <Grid item xs={2}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    mb: '12px',
                  }}
                >
                  Invoice ID*
                </Typography>
                <CustomInputWithMin
                  isMinSeen={false}
                  placeholder="Enter invoice"
                  isWithTitle={false}
                  value={invoice || ''}
                  onChange={(e) =>
                    dispatch(
                      changeValuePruchasProcess({
                        key: 'invoice',
                        value: e.target.value,
                      })
                    )
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    mb: '12px',
                  }}
                >
                  Date*
                </Typography>
                <DatePicker
                  value={invoiceDate}
                  onChange={(newValue) => {
                    dispatch(
                      changeValuePruchasProcess({
                        key: 'invoiceDate',
                        value: newValue,
                      })
                    );
                  }}
                  renderInput={(params) => (
                    <CustomInput
                      {...params}
                      sx={{ width: '100%', bgcolor: '#FAFAFA' }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    mb: '12px',
                  }}
                >
                  Discount
                </Typography>
                <OutlinedInput
                  value={discount || ''}
                  sx={{ width: '100%', bgcolor: '#FAFAFA' }}
                  placeholder="Enter amount"
                  type="number"
                  onChange={(e) =>
                    dispatch(
                      changeValuePruchasProcess({
                        key: 'discount',
                        value: e.target.value,
                      })
                    )
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <FormControl>
                        {/* {unitSelect === '' && (
                        <InputLabel id="unit-label">Unit</InputLabel>
                      )} */}
                        <Select
                          value={discountFormat}
                          onChange={(e) =>
                            dispatch(
                              changeValuePruchasProcess({
                                key: 'discountFormat',
                                value: e.target.value,
                              })
                            )
                          }
                          sx={{
                            minWidth: '100px',
                            bgcolor: 'white',
                            '.MuiOutlinedInput-input': {
                              paddingTop: '12px',
                              paddingBottom: '12px',
                              paddingLeft: '12px',
                              paddingRight: '0',
                              borderRadius: '5px',
                            },
                          }}
                        >
                          <MenuItem value="percentage">%</MenuItem>
                          <MenuItem value="currency">IDR</MenuItem>
                        </Select>
                      </FormControl>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    mb: '12px',
                  }}
                >
                  Supplier*
                </Typography>
                <LineDiv value={supplier || '-'} />
              </Grid>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    mb: '12px',
                  }}
                >
                  Payment Method*
                </Typography>
                <LineDiv value={paymentMethod || '-'} />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    mb: '12px',
                  }}
                >
                  Description
                </Typography>
                <LineDiv value={deskripsi || '-'} />
              </Grid>
              <Grid item xs={6}>
                <CustomCard>
                  <CustomCard sx={{ p: '20px' }}>
                    <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
                      Item(s)
                    </Typography>
                  </CustomCard>
                  <Grid container columns={1} rowSpacing={4} sx={{ p: '20px' }}>
                    {products.map((list, idx) => {
                      return (
                        <Grid item xs={1} key={idx}>
                          <Grid container columns={7} columnSpacing={4}>
                            <Grid item xs={2.5}>
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  mb: '12px',
                                }}
                              >
                                Product
                              </Typography>
                              <LineDiv value={list?.name || '-'} />
                            </Grid>
                            <Grid item xs={1}>
                              <Box
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  mb: '12px',
                                  display: 'flex',
                                  gap: 0.5,
                                }}
                              >
                                <Typography>Qty </Typography>
                                {/* <Typography sx={{ color: '#51B15C' }}>
                                  (Pcs)
                                </Typography> */}
                              </Box>
                              <CustomInputWithMin
                                isMinSeen={false}
                                placeholder="Enter Qty"
                                isWithTitle={false}
                                type="number"
                                value={list?.qty || 0}
                                onChange={(e) =>
                                  dispatch(
                                    changeQty({
                                      index: idx,
                                      value: Number(e.target.value), //TODO : qty
                                    })
                                  )
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      <Box
                                        sx={{
                                          background: '#FFFFFF',
                                          boxShadow:
                                            '0px 0px 6px rgba(0, 0, 0, .3)',
                                          borderRadius: '8px',
                                          padding: '4px 12px',
                                        }}
                                      >
                                        {list?.unit}
                                      </Box>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Box
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  display: 'flex',
                                  gap: 1.5,
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <Typography>Disc </Typography>
                                <Select
                                  size="small"
                                  value={list?.discountFormat}
                                  sx={{
                                    padding: '0px',
                                    mb: '5px',
                                    background: 'transparent',
                                    color: '#51B15C',
                                    height: '25px',
                                    minHeight: '25px',
                                  }}
                                  onChange={(e) =>
                                    dispatch(
                                      changeDiscountFormat({
                                        index: idx,
                                        value: e.target.value,
                                      })
                                    )
                                  }
                                >
                                  <MenuItem value="percentage">%</MenuItem>
                                  <MenuItem value="currency">Rp</MenuItem>
                                </Select>
                              </Box>
                              <CustomInputWithMin
                                isMinSeen={false}
                                placeholder="Enter Disc"
                                isWithTitle={false}
                                type="number"
                                value={list?.discount}
                                onChange={(e) =>
                                  dispatch(
                                    changeDiscount({
                                      index: idx,
                                      value: Number(e.target.value),
                                    })
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  mb: '12px',
                                }}
                              >
                                Price*
                              </Typography>
                              <CustomInputWithMin
                                isMinSeen={false}
                                placeholder="Enter Qty"
                                isWithTitle={false}
                                type="number"
                                value={list?.purchasePrice || 0}
                                onChange={(e) =>
                                  dispatch(
                                    changePrice({
                                      index: idx,
                                      value: Number(e.target.value),
                                    })
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  mb: '12px',
                                }}
                              >
                                Subtotal*
                              </Typography>
                              <LineDiv
                                value={`Rp. ${
                                  list?.discountFormat !== 'percentage'
                                    ? Number(list?.purchasePrice) *
                                        Number(list?.qty) -
                                      Number(list?.discount)
                                    : Number(list?.purchasePrice * list?.qty) -
                                      Number(list?.discount / 100) *
                                        Number(list?.purchasePrice * list?.qty)
                                }`}
                              />
                            </Grid>
                            <Grid item xs={0.5}>
                              <Box
                                sx={{
                                  maxHeight: '36px',
                                  maxWidth: '36px',
                                  background: '#F33A3A',
                                  borderRadius: '8px',
                                  padding: '16px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  mt: '36px',
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  dispatch(changeProduct(list?.idProduk))
                                }
                              >
                                <DeleteIcon sx={{ color: '#FFF' }} />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      p: '20px',
                    }}
                  >
                    <Typography sx={{ fontWeight: '600', fontSize: '20px' }}>
                      Grand Total
                    </Typography>

                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      {discount ? (
                        <>
                          <Typography
                            sx={{
                              fontWeight: '600',
                              fontSize: '14px',
                              color: '#9E9D9D',
                              textDecoration: 'line-through',
                            }}
                          >
                            Rp. {totalPrice || '-'}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: '600', fontSize: '20px' }}
                          >
                            Rp. {totalPriceFix || '-'}
                          </Typography>
                        </>
                      ) : (
                        <Typography
                          sx={{ fontWeight: '600', fontSize: '20px' }}
                        >
                          Rp. {totalPrice || '-'}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </CustomCard>
              </Grid>
            </Grid>
          )}
          {!desktopMode && (
            <Box>
              <Grid
                container
                columns={7}
                spacing={2}
                sx={{ mt: '16px', pb: '54px' }}
              >
                <Grid item xs={7}>
                  <TextField
                    id="filled-basic"
                    label="Invoice ID*"
                    variant="filled"
                    sx={{
                      width: '100%',
                      '.MuiFilledInput-root': {
                        bgcolor: '#FAFAFA',
                        height: '54px',
                      },
                      '.MuiInputLabel-root': {
                        color: '#9E9E9E',
                      },
                    }}
                    value={invoice || ''}
                    onChange={(e) =>
                      dispatch(
                        changeValuePruchasProcess({
                          key: 'invoice',
                          value: e.target.value,
                        })
                      )
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <DatePicker
                    label="Date*"
                    value={invoiceDate}
                    onChange={(newValue) => {
                      dispatch(
                        changeValuePruchasProcess({
                          key: 'invoiceDate',
                          value: newValue,
                        })
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        id="filled-basic"
                        label="Date*"
                        variant="filled"
                        sx={{
                          width: '100%',
                          '.MuiFilledInput-root': {
                            bgcolor: '#FAFAFA',
                            height: '54px',
                          },
                          '.MuiInputLabel-root': {
                            color: '#9E9E9E',
                          },
                        }}
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    variant="filled"
                    sx={{
                      width: '100%',
                      '.MuiFilledInput-root': {
                        bgcolor: '#FAFAFA',
                        height: '54px',
                      },
                      '.MuiInputLabel-root': {
                        color: '#9E9E9E',
                      },
                    }}
                  >
                    <InputLabel htmlFor="input-with-icon-adornment">
                      Disc
                    </InputLabel>
                    <FilledInput
                      id="input-with-icon-adornment"
                      label="Disc"
                      variant="filled"
                      sx={{
                        width: '100%',
                        '.MuiFilledInput-root': {
                          bgcolor: '#FAFAFA',
                          height: '54px',
                        },
                        '.MuiInputLabel-root': {
                          color: '#9E9E9E',
                        },
                      }}
                      type="number"
                      onChange={(e) =>
                        dispatch(
                          changeValuePruchasProcess({
                            key: 'discount',
                            value: e.target.value,
                          })
                        )
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <FormControl>
                            {/* {unitSelect === '' && (
                        <InputLabel id="unit-label">Unit</InputLabel>
                      )} */}
                            <Select
                              value={discountFormat}
                              onChange={(e) =>
                                dispatch(
                                  changeValuePruchasProcess({
                                    key: 'discountFormat',
                                    value: e.target.value,
                                  })
                                )
                              }
                              sx={{
                                minWidth: '15px',
                                bgcolor: 'white',
                                '.MuiOutlinedInput-input': {
                                  paddingTop: '12px',
                                  paddingBottom: '12px',
                                  paddingLeft: '6px',
                                  paddingRight: '0',
                                  borderRadius: '5px',
                                },
                                '.MuiSelect-icon': {
                                  fontSize: '12px',
                                  maxWidth: '12px',
                                },
                                '.MuiSvgIcon-root': {
                                  margin: '0px',
                                },
                              }}
                            >
                              <MenuItem
                                value="percentage"
                                sx={{ fontSize: '12px' }}
                              >
                                %
                              </MenuItem>
                              <MenuItem value="currency">IDR</MenuItem>
                            </Select>
                          </FormControl>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={7}>
                  <FillText title={'Supplier*'} value={supplier || '-'} />
                </Grid>
                <Grid item xs={7}>
                  <FillText
                    title={'Payment Method*'}
                    value={paymentMethod || '-'}
                  />
                </Grid>
                <Grid item xs={7}>
                  <FillText title={'Description'} value={deskripsi || '-'} />
                </Grid>
                <Grid item xs={7}>
                  <CustomCard>
                    <CustomCard sx={{ p: '16px' }}>
                      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
                        Item(s)
                      </Typography>
                    </CustomCard>
                    <Grid
                      container
                      columns={1}
                      rowSpacing={2}
                      sx={{ p: '20px 16px' }}
                    >
                      {products.map((list, idx) => {
                        return (
                          <Grid item xs={1} key={idx}>
                            <Grid
                              container
                              columns={6}
                              columnSpacing={2}
                              rowSpacing={2}
                            >
                              <Grid item xs={4}>
                                <FillText
                                  title={'Product'}
                                  value={list?.name || '-'}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <FillText
                                  title={'Qty'}
                                  value={list?.qty || '-'}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField
                                  id="filled-basic"
                                  label="Invoice ID*"
                                  variant="filled"
                                  sx={{
                                    width: '100%',
                                    '.MuiFilledInput-root': {
                                      bgcolor: '#FAFAFA',
                                      height: '54px',
                                    },
                                    '.MuiInputLabel-root': {
                                      color: '#9E9E9E',
                                    },
                                  }}
                                  type="number"
                                  value={list?.purchasePrice || ''}
                                  onChange={(e) =>
                                    dispatch(
                                      changePrice({
                                        index: idx,
                                        value: Number(e.target.value),
                                      })
                                    )
                                  }
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <FillText
                                  title={'Subtotal'}
                                  value={`Rp. ${
                                    Number(list?.purchasePrice) *
                                    Number(list?.qty)
                                  }`}
                                />
                              </Grid>
                            </Grid>
                            <Divider sx={{ mt: '16px' }} />
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: '16px',
                      }}
                    >
                      <Typography sx={{ fontWeight: '600', fontSize: '16px' }}>
                        Grand Total
                      </Typography>

                      <Box
                        sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                      >
                        {discount ? (
                          <>
                            <Typography
                              sx={{
                                fontWeight: '600',
                                fontSize: '12px',
                                color: '#9E9D9D',
                                textDecoration: 'line-through',
                              }}
                            >
                              Rp. {totalPrice || '-'}
                            </Typography>
                            <Typography
                              sx={{ fontWeight: '600', fontSize: '16px' }}
                            >
                              Rp. {totalPriceFix || '-'}
                            </Typography>
                          </>
                        ) : (
                          <Typography
                            sx={{ fontWeight: '600', fontSize: '16px' }}
                          >
                            Rp. {totalPrice || '-'}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </CustomCard>
                </Grid>
              </Grid>
            </Box>
          )}
          <BottomMenu
            isGreen={true}
            greenText="submit"
            handleGreen={handleSubmit}
            disableGreen={!isSubmitAvailable}
          />
        </>
      )}
      {isLoading && <LoadingComponent />}
    </Box>
  );
};
export default PurchaseProcessSupplier;
