import { Grid, Typography, Box, Switch, Dialog, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ReactComponent as NoImage } from '../../../../../../assets/no-image.svg';

import BackButton from '../../../../../../components/shared/BackButton';
import CustomButton from '../../../../../../components/shared/CustomButton';
import dayjs from 'dayjs';
import { ChartDetails } from '../../Product/DesktopComponent';

const SubCategoryMenuDetailsMobile = (props) => {
  const {
    AntSwitch,
    // *----
    roadHirarki,
    setRoadHirarki,
    //*--- page
    setPage,
    setSubCategoryMenu,
    // *--- timeFrame
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    // *--- Data
    detailsDatas,
    isLoadingDataDetails,
    // *--- Photo
    isPreviewPhoto,
    setIsPreviewPhoto,
    linkPreviewPhoto,
    setLinkPreviewPhoto,
    // *--- Status
    status,
    // *--- handler
    handleBackButton,
    handleEditStatus,
    handleDelete,
    handleEdit,
    handlePreviewPhoto,
  } = props;
  return (
    <Grid container columns={12} spacing={2}>
      {/* //* back button */}
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        <Box>
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
            {roadHirarki.category} Product
          </Typography>
          <Box
            sx={{
              display: 'flex',
              color: '#9E9D9D',

              alignItems: 'center',
              mt: '4px',
              gap: 0.25,
            }}
          >
            <Typography sx={{ fontSize: '8px', fontWeight: '100' }}>
              {roadHirarki.subsidiary}
            </Typography>
            <ChevronRightIcon sx={{ fontSize: '4px' }} />
            <Typography
              sx={{ fontSize: '8px', fontWeight: '100', color: '#9E9D9D' }}
            >
              {roadHirarki.category}
            </Typography>
            <ChevronRightIcon sx={{ fontSize: '4px' }} />
            <Typography
              sx={{ fontSize: '8px', fontWeight: '100', color: '#9E9D9D' }}
            >
              {roadHirarki.subCategory}
            </Typography>
          </Box>
        </Box>
      </Grid>
      {/* //* -- chart and 3 more -- */}
      <Grid container item xs={12} columns={12}>
        <Grid item xs={12} sx={{ mb: '32px' }}>
          <ChartDetails
            {...{
              timeFrame,
              setTimeFrame,
              dateRange,
              setDateRange,
              saveDateRange,
              setSaveDateRange,
            }}
          />
        </Grid>
        <Grid container item xs={12} columns={12} spacing={4}>
          {/* //* purchased */}
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
              Purchased
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
                Rp {detailsDatas.purchased ? detailsDatas.purchased : '-'}
              </Typography>
            </Box>
          </Grid>
          {/* //* sold */}
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
              Sold
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
                Rp {detailsDatas.sold ? detailsDatas.sold : '-'}
              </Typography>
            </Box>
          </Grid>
          {/* //* used */}
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
              Used
            </Typography>
            <Box
              sx={{
                mt: '12px',
                width: '100%',
                padding: '14px 16px',
                bgcolor: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
                Rp {detailsDatas.used ? detailsDatas.used : '-'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {/* //* SKU */}
      <Grid item xs={12}>
        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
          SKU
        </Typography>
        <Box
          sx={{
            mt: '12px',
            width: '100%',
            padding: '14px 16px',
            bgcolor: '#FAFAFA',
            borderRadius: '8px',
          }}
        >
          <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
            {detailsDatas.sku ? detailsDatas.sku : '-'}
          </Typography>
        </Box>
      </Grid>
      {/* //* Photo */}
      <Grid item xs={12}>
        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
          Photo
        </Typography>
        <Box
          sx={{
            mt: '12px',
            width: '100%',
            padding: '0',
            display: 'flex',
            gap: 1,
          }}
        >
          {!detailsDatas.gbrsub ? (
            <Box
              sx={{
                width: '42px',
                height: '42px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: '#FAFAFA',
                borderRadius: '2px',
              }}
            >
              <NoImage />
              <Typography sx={{ color: '#9E9D9D', fontSize: '6px' }}>
                No photo
              </Typography>
            </Box>
          ) : (
            detailsDatas.gbrsub.split(',').map((item) => (
              <Box
                component="img"
                src={process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + item}
                onClick={() => handlePreviewPhoto(item)}
                sx={{
                  width: '42px',
                  height: '42px',
                  aspectRatio: '1/1',
                  borderRadius: '8px',
                  cursor: 'pointer',
                }}
              />
            ))
          )}
        </Box>
      </Grid>
      {/* //* Dialog Photo */}
      {linkPreviewPhoto !== '' && (
        <Dialog
          open={isPreviewPhoto}
          onClose={() => {
            setIsPreviewPhoto(!isPreviewPhoto);
            setLinkPreviewPhoto('');
          }}
        >
          <Box
            component="img"
            src={
              process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + linkPreviewPhoto
            }
            sx={{
              aspectRation: '1/1',
            }}
          />
        </Dialog>
      )}
      {/* //* Description */}
      <Grid item xs={12}>
        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
          Description
        </Typography>
        <Box
          sx={{
            mt: '12px',
            width: '100%',
            padding: '14px 16px',
            bgcolor: '#FAFAFA',
            borderRadius: '8px',
          }}
        >
          <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
            {detailsDatas.informasi ? detailsDatas.informasi : '-'}
          </Typography>
        </Box>
      </Grid>
      {/* //* Schedule */}
      <Grid item xs={12}>
        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
          Schedule
        </Typography>
        <Box
          sx={{
            mt: '12px',
            width: '100%',
            padding: '14px 16px',
            bgcolor: '#FAFAFA',
            borderRadius: '8px',
          }}
        >
          <Typography sx={{ fontSize: '8px', fontWeight: '500' }}>
            {detailsDatas.schedule
              ? dayjs(detailsDatas.schedule).format('DD/MMM/YYYY')
              : '-'}
          </Typography>
        </Box>
      </Grid>
      {/* //* STATUS */}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
          Publish
        </Typography>
        <AntSwitch checked={status} onChange={handleEditStatus} />
      </Grid>
      {/* //* SUBMIT */}
      <Grid
        item
        xs={12}
        sx={{ mt: '28px', display: 'flex', alignItems: 'center', gap: 0.5 }}
      >
        <CustomButton
          // disabled={!isSubmitAvailable}
          onClick={handleDelete}
          variant="contained"
          color="error"
          sx={{
            width: '100%',
          }}
        >
          Delete
        </CustomButton>
        <CustomButton
          // disabled={!isSubmitAvailable}
          onClick={handleEdit}
          variant="contained"
          sx={{
            width: '100%',
          }}
        >
          Edit
        </CustomButton>
      </Grid>
    </Grid>
  );
};

export default SubCategoryMenuDetailsMobile;
