import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Switch,
  Dialog,
  styled,
} from '@mui/material';
import CustomInput from '../../../../../../components/shared/CustomInput';
import BackButton from '../../../../../../components/shared/BackButton';
import dayjs from 'dayjs';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import CustomDialog from '../../../../../../components/shared/CustomDialog';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import CustomCard from '../../../../../../components/shared/CustomCard';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import useGetDifferent2Arrays from '../../../../../../hooks/useGetDifferent2Arrays';
import CustomButton from '../../../../../../components/shared/CustomButton';
import AddMiniSubCategoryInSubCategory from './Desktop/AddMiniSubCategoryInSubCategory';
import {
  addNewMiniSubCategoryProduct,
  addNewSubCategoryProduct,
  updateSubCategoryProduct,
} from '../../../../../../api/products';
import { uploadImage } from '../../../../../../api/storage';
import { async } from 'q';
import { useDispatch } from 'react-redux';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const SubCategoryProductAdd = (props) => {
  const {
    page,
    setPage,
    roadHirarki,
    setRoadHirarki,
    setIsSubCategoryProductAdd,
    setIsSubCategoryProductEdit,
    // data
    subCategoryAddInputDatas,
    setSubCategoryAddInputDatas,
    // *----Notif ---
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
  } = props;
  const today = dayjs(new Date());
  const theme = useTheme();
  const dispatch = useDispatch();

  const [desktopMode, setDesktopMode] = useState(true);

  useEffect(() => {
    if (window.screen.width <= 830) {
      setDesktopMode(false);
    } else {
      setDesktopMode(true);
    }
  }, [window.screen.width]);

  // !------------------------- SAVE VALIDATION ----------------------------
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);

  const [miniSub1, setMiniSub1] = useState({
    id: 1,
    name: '',
    photo: [],
    photoUrls: [],
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviewPhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    available: true,
  });

  const [miniSub2, setMiniSub2] = useState({
    id: 2,
    name: '',
    photo: [],
    photoUrls: [],
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviewPhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    available: true,
  });

  const [miniSub3, setMiniSub3] = useState({
    id: 3,
    name: '',
    photo: [],
    photoUrls: [],
    isMaxSizePhoto: false,
    isPreviewPhoto: false,
    indexPreviewPhoto: 0,
    description: '',
    scheduleType: 'now',
    schedule: today,
    available: true,
  });

  const [indexMiniSubCategory, setIndexMiniSubCategory] = useState([]);

  useEffect(() => {
    if (subCategoryAddInputDatas.name.length >= 5) {
      if (
        subCategoryAddInputDatas.description === '' ||
        subCategoryAddInputDatas.description.length >= 20
      ) {
        if (subCategoryAddInputDatas.miniSubCategoryIndex.length > 0) {
          if (
            miniSub1.available === true &&
            miniSub2.available === true &&
            miniSub3.available === true
          ) {
            return setIsSubmitAvailable(true);
          } else {
            return setIsSubmitAvailable(false);
          }
        } else {
          return setIsSubmitAvailable(true);
        }
      } else {
        return setIsSubmitAvailable(false);
      }
    } else {
      return setIsSubmitAvailable(false);
    }
  }, [
    subCategoryAddInputDatas,
    setIsSubmitAvailable,
    miniSub1,
    miniSub2,
    miniSub3,
  ]);

  useEffect(() => {
    subCategoryAddInputDatas.miniSubCategoryFormat
      .filter(
        (item) =>
          !subCategoryAddInputDatas.miniSubCategoryIndex.includes(item) &&
          !indexMiniSubCategory.includes(item)
      )
      .map((value) => {
        return setIndexMiniSubCategory([...indexMiniSubCategory, value]);
      });
  }, [subCategoryAddInputDatas, indexMiniSubCategory]);

  // !------------------ HANDLE --------------------
  const handleBackButton = () => {
    setPage('subCategory');
    setIsSubCategoryProductAdd(false);
    setIsSubCategoryProductEdit(false);
  };

  const handleAddNewSubCategory = () => {
    let hasil = {
      idKategori: roadHirarki.categoryId,
      subkategori: subCategoryAddInputDatas.name,
      subStatus: `${subCategoryAddInputDatas.publish ? 'Y' : 'N'}`,
      schedule: dayjs(subCategoryAddInputDatas.schedule.$d).format(
        'YYYY-MM-DD'
      ),
    };

    if (subCategoryAddInputDatas.description !== '') {
      hasil['informasi'] = subCategoryAddInputDatas.description;
    }

    if (subCategoryAddInputDatas.photoUrls.length !== 0) {
      hasil['gbrsub'] = subCategoryAddInputDatas.photoUrls.join(',');
    }

    let miniSubCategories = [{ ...miniSub1 }, { ...miniSub2 }, { ...miniSub3 }];

    const fetchNewMini = async (data) => {
      try {
        await addNewMiniSubCategoryProduct(data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchNewSub = async () => {
      try {
        const {
          data: { data },
        } = await addNewSubCategoryProduct(hasil);
        const id = data.idSubkategori;

        miniSubCategories
          .filter((miniItem) =>
            subCategoryAddInputDatas.miniSubCategoryIndex.includes(miniItem.id)
          )
          .map((miniItem) => {
            let mini = {
              name: miniItem.name,
              idSubkategori: id,
              description: miniItem.description,
              schedule: dayjs(miniItem.schedule.$d).format('YYYY-MM-DD'),
              status: 'Y',
            };
            if (miniItem.photoUrls.length !== 0) {
              mini['image'] = miniItem.photoUrls.join(',');
            }
            fetchNewMini(mini);
          });

        console.error(data);
        setNotif('addsubcategory');
        setNotifTitle(`${data.subkategori} has been successfully added`);
        setIsSubCategoryProductAdd(false);
        setPage('subCategory');
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(updateSubCategoryProduct());
      }
    };

    fetchNewSub();
    // addNewSubCategoryProduct(hasil)
    //   .then((result) => {
    //     if (
    //       miniSubCategories.filter(((item) => item.name !== '').length !== 0)
    //     ) {
    //       dispatch(updateSubCategoryProduct());
    //       miniSubCategories
    //         .filter((miniItem) =>
    //           subCategoryAddInputDatas.miniSubCategoryIndex.includes(
    //             miniItem.id
    //           )
    //         )
    //         .map((miniItem) => {
    //           let mini = {
    //             name: miniItem.name,
    //             idSubkategori: result.data.data.idSubkategori,
    //             description: miniItem.description,
    //             schedule: dayjs(miniItem.schedule.$d).format('YYYY-MM-DD'),
    //             status: 'Y',
    //           };
    //           if (miniItem.photoUrls.length !== 0) {
    //             mini['image'] = miniItem.photoUrls.join(',');
    //           }
    //           addNewMiniSubCategoryProduct(mini)
    //             .then()
    //             .catch((err) => console.error());
    //         });
    //     }
    //     setNotif('addsubcategory');
    //     setNotifTitle(
    //       `${result.data.data.subkategori} has been successfully added`
    //     );
    //     setIsSubCategoryProductAdd(false);
    //     setPage('subCategory');
    //   })
    //   .catch((err) => console.error());
  };

  const handleAddImage = (e) => {
    if (e.target.files[0].size > 2000000) {
      setSubCategoryAddInputDatas({
        ...subCategoryAddInputDatas,
        isMaxSizePhoto: true,
      });
    } else {
      let datas = {
        image: e.target.files[0],
        resource: 'subCategory',
      };
      uploadImage(datas).then((res) => {
        setSubCategoryAddInputDatas({
          ...subCategoryAddInputDatas,
          photo: [...subCategoryAddInputDatas.photo, e.target.files[0]],
          photoUrls: [
            ...subCategoryAddInputDatas.photoUrls,
            res.data.data.path,
          ],
          isMaxSizePhoto: false,
        });
      });
    }
  };

  const handleDeleteImage = (input, index) => {
    setSubCategoryAddInputDatas({
      ...subCategoryAddInputDatas,
      photo: subCategoryAddInputDatas.photo.filter((data) => data !== input),
      photoUrls: subCategoryAddInputDatas.photoUrls.filter(
        (data, idx) => idx !== index
      ),
      indexPreviePhoto: 0,
    });
  };

  return (
    <>
      {desktopMode ? (
        <Grid container columns={12} spacing={4} sx={{ pb: '56px' }}>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <BackButton handleClick={handleBackButton} />
            <Box>
              <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
                Add Sub Category
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  color: '#9E9D9D',

                  alignItems: 'center',
                  mt: '4px',
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                  Subsidiary
                </Typography>
                <ChevronRightIcon sx={{ fontSize: '16px' }} />
                <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                  {roadHirarki.category}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {/* Name */}
          <Grid item xs={6}>
            <Typography
              sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}
            >
              Name*
            </Typography>
            <CustomInput
              sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              placeholder="Enter sub category name"
              value={subCategoryAddInputDatas.name}
              onChange={(e) => {
                if (e.target.value.length <= 30) {
                  setSubCategoryAddInputDatas({
                    ...subCategoryAddInputDatas,
                    name: e.target.value,
                  });
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 5 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {subCategoryAddInputDatas.name.length}/30
              </Typography>
            </Box>
          </Grid>
          {/* Photo */}
          <Grid item xs={6} sx={{ minHeight: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                pb: `${
                  subCategoryAddInputDatas.photo.length === 0 ? '16px' : '9px'
                }`,
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Photo
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  color: '#9E9D9D',
                }}
              >
                ( max size : 2MB )
              </Typography>
              {subCategoryAddInputDatas.isMaxSizePhoto && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ErrorOutlineIcon
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                      mr: '4px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                    }}
                  >
                    The photo can't be more than 2MB
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {subCategoryAddInputDatas.photo.length === 0 && (
                  <Box
                    sx={{
                      height: '54px',
                      width: '54px',
                      aspectRatio: '1/1',
                      borderRadius: '8px',
                      bgcolor: '#F5F5F5',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderStyle: 'dashed',
                      borderColor: '#9E9D9D',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('inputSubCat_img').click();
                    }}
                  >
                    <ImageOutlinedIcon
                      sx={{ color: '#9E9D9D', fontSize: '28px' }}
                    />
                  </Box>
                )}
                {subCategoryAddInputDatas.photo.map((input, index) => (
                  <Box
                    sx={{
                      position: 'relative',
                      overflow: 'visible',
                      padding: '7px',
                    }}
                    key={index}
                  >
                    <CancelIcon
                      sx={{
                        position: 'absolute',
                        fontSize: '20px',
                        top: 1,
                        right: 1,
                        zIndex: +1,
                        cursor: 'pointer',
                      }}
                      onClick={() => handleDeleteImage(input, index)}
                    />
                    <Box
                      component="img"
                      src={URL.createObjectURL(input)}
                      sx={{
                        height: '54px',
                        width: '54px',
                        aspectRatio: '1/1',
                        borderRadius: '8px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setSubCategoryAddInputDatas({
                          ...subCategoryAddInputDatas,
                          indexPreviePhoto: index + 1,
                          isPreviewPhoto: 'true',
                        });
                      }}
                    />
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  mb: '7px',
                  ml: '20px',
                  alignItems: 'center',
                }}
              >
                {subCategoryAddInputDatas.photo.length < 3 && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('inputSubCat_img').click();
                    }}
                  >
                    <input
                      accept="image/*"
                      id="inputSubCat_img"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleAddImage}
                    />
                    <AddIcon />
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                      Add More
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          {/* DIalog Photo */}
          {subCategoryAddInputDatas.indexPreviePhoto !== 0 && (
            <Dialog
              open={subCategoryAddInputDatas.isPreviewPhoto}
              onClose={() => {
                setSubCategoryAddInputDatas({
                  ...subCategoryAddInputDatas,
                  isPreviewPhoto: !subCategoryAddInputDatas.isPreviewPhoto,
                });
              }}
            >
              <Box
                component="img"
                src={URL.createObjectURL(
                  subCategoryAddInputDatas.photo[
                    subCategoryAddInputDatas.indexPreviePhoto - 1
                  ]
                )}
                sx={{ width: '600px', height: '600px', aspectRation: '1/1' }}
              />
            </Dialog>
          )}
          {/* Description */}
          <Grid item xs={6}>
            <Typography
              sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}
            >
              Description
            </Typography>
            <CustomInput
              sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              placeholder="Enter description"
              value={subCategoryAddInputDatas.description}
              onChange={(e) => {
                if (e.target.value.length <= 250) {
                  setSubCategoryAddInputDatas({
                    ...subCategoryAddInputDatas,
                    description: e.target.value,
                  });
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 20 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {subCategoryAddInputDatas.description.length}/250
              </Typography>
            </Box>
          </Grid>
          {/* Schedule */}
          <Grid item xs={6} sx={{}}>
            <Grid container>
              <Grid
                item
                alignItems="center"
                sx={{ height: '100%', mr: '20px' }}
              >
                <Typography
                  sx={{
                    mb: '16px',
                    fontSize: '16px',
                    fontWeight: '500',
                  }}
                >
                  Schedule
                </Typography>
              </Grid>
              <Grid item alignItems="center" sx={{ height: '100%' }}>
                <RadioGroup
                  row
                  value={subCategoryAddInputDatas.scheduleType}
                  onChange={(e) => {
                    setSubCategoryAddInputDatas({
                      ...subCategoryAddInputDatas,
                      schedule: today,
                      scheduleType: e.target.value,
                    });
                  }}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '16px',
                    },
                    '.MuiRadio-root': {
                      padding: '0 8px',
                    },
                    '.MuiTypography-root': {
                      fontSize: '12px',
                    },
                  }}
                >
                  <FormControlLabel
                    value="now"
                    control={<Radio />}
                    label="Now"
                  />
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="Custom"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', minWidth: '100%' }}>
              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                value={subCategoryAddInputDatas.schedule}
                onChange={(newValue) => {
                  if (subCategoryAddInputDatas.scheduleType !== 'now') {
                    setSubCategoryAddInputDatas({
                      ...subCategoryAddInputDatas,
                      schedule: newValue,
                    });
                  } else {
                    setSubCategoryAddInputDatas({
                      ...subCategoryAddInputDatas,
                      schedule: today,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: '100%', bgcolor: '#FAFAFA' }}
                  />
                )}
                disabled={
                  subCategoryAddInputDatas.scheduleType === 'now' ? true : false
                }
              />
            </Box>
          </Grid>
          {/* Add Mini SUb */}
          {subCategoryAddInputDatas.miniSubCategoryIndex.length === 0 && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.palette.primary.main,
                }}
                onClick={() => {
                  const value = indexMiniSubCategory[0];
                  setSubCategoryAddInputDatas({
                    ...subCategoryAddInputDatas,
                    miniSubCategoryIndex: [
                      ...subCategoryAddInputDatas.miniSubCategoryIndex,
                      value,
                    ],
                  });

                  setIndexMiniSubCategory(
                    indexMiniSubCategory.filter((nilai) => nilai !== value)
                  );
                }}
              >
                <AddIcon
                  sx={{
                    fontSize: '20px',
                    cursor: 'pointer',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    cursor: 'pointer',
                  }}
                >
                  Add Mini Sub
                </Typography>
              </Box>
            </Grid>
          )}
          {/* Sub */}
          {subCategoryAddInputDatas.miniSubCategoryIndex.length !== 0 && (
            <Grid item xs={12}>
              <CustomCard sx={{ padding: '20px 16px ' }}>
                <Grid container columns={12} rowSpacing={5}>
                  {indexMiniSubCategory.length !== 0 && (
                    <Grid item xs={12} sx={{ mb: '5px' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: theme.palette.primary.main,
                        }}
                        onClick={() => {
                          const value = indexMiniSubCategory[0];
                          setSubCategoryAddInputDatas({
                            ...subCategoryAddInputDatas,
                            miniSubCategoryIndex: [
                              ...subCategoryAddInputDatas.miniSubCategoryIndex,
                              value,
                            ],
                          });
                          setIndexMiniSubCategory(
                            indexMiniSubCategory.filter(
                              (nilai) => nilai !== value
                            )
                          );
                        }}
                      >
                        <AddIcon
                          sx={{
                            fontSize: '20px',
                            cursor: 'pointer',
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: '600',
                            cursor: 'pointer',
                          }}
                        >
                          Add Mini Sub
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {subCategoryAddInputDatas.miniSubCategoryIndex.map(
                    (nilai, index) => (
                      <Grid
                        container
                        item
                        xs={12}
                        columns={12}
                        columnSpacing={4}
                        rowSpacing={2}
                        key={index}
                      >
                        <AddMiniSubCategoryInSubCategory
                          {...{
                            page,
                            setPage,
                            subCategoryAddInputDatas,
                            setSubCategoryAddInputDatas,
                            indexMiniSubCategory,
                            setIndexMiniSubCategory,
                            isSubmitAvailable,
                            setIsSubmitAvailable,
                          }}
                          miniSub={
                            nilai === 1
                              ? miniSub1
                              : nilai === 2
                              ? miniSub2
                              : miniSub3
                          }
                          setMiniSub={
                            nilai === 1
                              ? setMiniSub1
                              : nilai === 2
                              ? setMiniSub2
                              : setMiniSub3
                          }
                          id={nilai.toString()}
                        />
                        {subCategoryAddInputDatas.miniSubCategoryIndex[
                          subCategoryAddInputDatas.miniSubCategoryIndex.length -
                            1
                        ] !== nilai && (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                      </Grid>
                    )
                  )}
                </Grid>
              </CustomCard>
            </Grid>
          )}
          {/* footer */}
          <Box
            sx={{
              width: '100%',
              bgcolor: 'white',
              position: 'fixed',
              bottom: '0px',
              right: '0px',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '18px 32px 18px 240px',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Publish
              </Typography>
              <AntSwitch
                checked={subCategoryAddInputDatas.publish}
                onChange={(e) => {
                  setSubCategoryAddInputDatas({
                    ...subCategoryAddInputDatas,
                    publish: !subCategoryAddInputDatas.publish,
                  });
                }}
              />
            </Box>
            <Box sx={{ display: 'flex' }}>
              <CustomButton
                variant="contained"
                disabled={!isSubmitAvailable}
                sx={{ width: '140px', height: '36px' }}
                onClick={handleAddNewSubCategory}
              >
                <Typography
                  sx={{ color: 'white', fontSize: '12px', fontWeight: '500' }}
                >
                  Submit
                </Typography>
              </CustomButton>
            </Box>
          </Box>
        </Grid>
      ) : (
        <Grid container columns={12} spacing={2}>
          {/* back button */}
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <BackButton handleClick={handleBackButton} />
            <Box>
              <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
                Add subcategory
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  color: '#9E9D9D',

                  alignItems: 'center',
                  mt: '4px',
                  gap: 0.25,
                }}
              >
                <Typography sx={{ fontSize: '8px', fontWeight: '100' }}>
                  Subsidiary
                </Typography>
                <ChevronRightIcon sx={{ fontSize: '4px' }} />
                <Typography sx={{ fontSize: '8px', fontWeight: '100' }}>
                  {roadHirarki.category}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {/* Name */}
          <Grid item xs={12}>
            <CustomInput
              sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              placeholder="Name*"
              value={subCategoryAddInputDatas.name}
              onChange={(e) => {
                if (e.target.value.length <= 30) {
                  setSubCategoryAddInputDatas({
                    ...subCategoryAddInputDatas,
                    name: e.target.value,
                  });
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 5 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {subCategoryAddInputDatas.name.length}/30
              </Typography>
            </Box>
          </Grid>
          {/* Photo */}
          <Grid item xs={12} sx={{ minHeight: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                pb: `${
                  subCategoryAddInputDatas.photo.length === 0 ? '16px' : '9px'
                }`,
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                Photo
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  color: '#9E9D9D',
                }}
              >
                ( max size : 2MB )
              </Typography>
              {subCategoryAddInputDatas.isMaxSizePhoto && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ErrorOutlineIcon
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                      mr: '4px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      color: theme.palette.warning.main,
                    }}
                  >
                    The photo can't be more than 2MB
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {subCategoryAddInputDatas.photo.length === 0 && (
                  <Box
                    sx={{
                      height: '54px',
                      width: '54px',
                      aspectRatio: '1/1',
                      borderRadius: '8px',
                      bgcolor: '#F5F5F5',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderStyle: 'dashed',
                      borderColor: '#9E9D9D',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('inputSubCat_img').click();
                    }}
                  >
                    <ImageOutlinedIcon
                      sx={{ color: '#9E9D9D', fontSize: '28px' }}
                    />
                  </Box>
                )}
                {subCategoryAddInputDatas.photo.map((input, index) => (
                  <Box
                    sx={{
                      position: 'relative',
                      overflow: 'visible',
                      padding: '7px',
                    }}
                    key={index}
                  >
                    <CancelIcon
                      sx={{
                        position: 'absolute',
                        fontSize: '20px',
                        top: 1,
                        right: 1,
                        zIndex: +1,
                        cursor: 'pointer',
                      }}
                      onClick={() => handleDeleteImage(input, index)}
                    />
                    <Box
                      component="img"
                      src={URL.createObjectURL(input)}
                      sx={{
                        height: '54px',
                        width: '54px',
                        aspectRatio: '1/1',
                        borderRadius: '8px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setSubCategoryAddInputDatas({
                          ...subCategoryAddInputDatas,
                          indexPreviePhoto: index + 1,
                          isPreviewPhoto: 'true',
                        });
                      }}
                    />
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  mb: '7px',
                  ml: '20px',
                  alignItems: 'center',
                }}
              >
                {subCategoryAddInputDatas.photo.length < 3 && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      document.getElementById('inputSubCat_img').click();
                    }}
                  >
                    <input
                      accept="image/*"
                      id="inputSubCat_img"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleAddImage}
                    />
                    <AddIcon />
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                      Add More
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          {/* DIalog Photo */}
          {subCategoryAddInputDatas.indexPreviePhoto !== 0 && (
            <Dialog
              open={subCategoryAddInputDatas.isPreviewPhoto}
              onClose={() => {
                setSubCategoryAddInputDatas({
                  ...subCategoryAddInputDatas,
                  isPreviewPhoto: !subCategoryAddInputDatas.isPreviewPhoto,
                });
              }}
            >
              <Box
                component="img"
                src={URL.createObjectURL(
                  subCategoryAddInputDatas.photo[
                    subCategoryAddInputDatas.indexPreviePhoto - 1
                  ]
                )}
                sx={{ aspectRation: '1/1' }}
              />
            </Dialog>
          )}
          {/* Description */}
          <Grid item xs={12}>
            <CustomInput
              sx={{ width: '100%', bgcolor: '#FAFAFA' }}
              placeholder="Description"
              value={subCategoryAddInputDatas.description}
              onChange={(e) => {
                if (e.target.value.length <= 250) {
                  setSubCategoryAddInputDatas({
                    ...subCategoryAddInputDatas,
                    description: e.target.value,
                  });
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                Enter at least 20 character
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#9E9D9D',
                  mt: '4px',
                }}
              >
                {subCategoryAddInputDatas.description.length}/250
              </Typography>
            </Box>
          </Grid>
          {/* Schedule */}
          <Grid item xs={12} sx={{}}>
            <Box sx={{ display: 'flex', minWidth: '100%' }}>
              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                value={subCategoryAddInputDatas.schedule}
                onChange={(newValue) => {
                  if (subCategoryAddInputDatas.scheduleType !== 'now') {
                    setSubCategoryAddInputDatas({
                      ...subCategoryAddInputDatas,
                      schedule: newValue,
                    });
                  } else {
                    setSubCategoryAddInputDatas({
                      ...subCategoryAddInputDatas,
                      schedule: today,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: '100%', bgcolor: '#FAFAFA' }}
                  />
                )}
              />
            </Box>
          </Grid>
          {/* STATUS */}
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
              Publish
            </Typography>
            <AntSwitch
              checked={subCategoryAddInputDatas.publish}
              onChange={(e) => {
                setSubCategoryAddInputDatas({
                  ...subCategoryAddInputDatas,
                  publish: !subCategoryAddInputDatas.publish,
                });
              }}
            />
          </Grid>
          {/* SUBMIT */}
          <Grid item xs={12} sx={{ mt: '28px' }}>
            <CustomButton
              disabled={!isSubmitAvailable}
              onClick={handleAddNewSubCategory}
              variant="contained"
              sx={{
                width: '100%',
              }}
            >
              Add
            </CustomButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default SubCategoryProductAdd;
