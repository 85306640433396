import { useDispatch, useSelector } from 'react-redux';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';
import {
  getAllTransactionRouting,
  setCloseNotifTransaction,
} from '../../../../../../store/Routing/transactionRouting';

const NotifTransaction = () => {
  const dispatch = useDispatch();
  const { keyNotif, textNotif } = useSelector(getAllTransactionRouting);

  const keys = [
    'addTransaction',
    'editTransaction',
    'deleteTransaction',
    'nextAdd',
  ];

  return (
    <>
      {keys.map((key, index) => (
        <CustomNotifSuccess
          text={textNotif}
          open={keyNotif === key}
          keys={keyNotif}
          onClose={() => {
            dispatch(setCloseNotifTransaction());
          }}
          key={index}
          sx={{
            background: key === 'nextAdd' ? '#A33A3A' : '',
            color: key === 'nextAdd' ? '#FFF' : '',
          }}
        />
      ))}
    </>
  );
};
export default NotifTransaction;
