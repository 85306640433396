import { createSlice } from '@reduxjs/toolkit';
import { getAllHubs, getAllMiniHub, getAllSubHub } from '../../../api/hubs';

export const EditPurchaseRequestState = createSlice({
  name: 'editPurchaseRequest',
  initialState: {
    subsidiaryId: 0,
    datas: null,

    // Hubs
    masterHub: [],
    masterHubId: null,
    subHub: [],
    subHubId: null,
    miniHub: [],
    miniHubId: null,
    hubs: [],

    hubChoose: null,
    hubSearch: '',
    hubSearchDebounce: '',
    subHubChoose: null,
    subHubSearch: '',
    subHubSearchDebounce: '',
    miniHubChoose: null,
    miniHubSearch: '',
    miniHubSearchDebounce: '',

    description: '',
  },
  reducers: {
    changeSubsidiaryId: (state, action) => {
      state.subsidiaryId = action.payload;
    },
    goToPurchaseRequestEdit: (state, action) => {
      state.datas = action.payload;
    },

    // ------- hubs -------
    changeMasterHub: (state, action) => {
      state.masterHub = action.payload;
    },
    changeMasterHubId: (state, action) => {
      state.masterHubId = action.payload;
    },
    changeSubHub: (state, action) => {
      state.subHub = action.payload;
    },
    changeSubHubId: (state, action) => {
      state.subHubId = action.payload;
    },
    changeMiniHub: (state, action) => {
      state.miniHub = action.payload;
    },
    changeMiniHubId: (state, action) => {
      state.miniHubId = action.payload;
    },

    changePurchaseName: (state, action) => {
      state.name = action.payload;
    },
    changePurchaseQty: (state, action) => {
      state.qty = action.payload;
    },

    changeHubs: (state, action) => {
      state.hubs = action.payload;
    },

    // hub select
    changeHubChoose: (state, action) => {
      state.hubChoose = action.payload;
    },
    changeHubSearch: (state, action) => {
      state.hubSearch = action.payload;
    },
    changeHubSearchDebounce: (state, action) => {
      state.hubSearchDebounce = action.payload;
    },
    changeSubHubChoose: (state, action) => {
      state.subHubChoose = action.payload;
    },
    changeSubHubSearch: (state, action) => {
      state.subHubSearch = action.payload;
    },
    changeSubHubSearchDebounce: (state, action) => {
      state.subHubSearchDebounce = action.payload;
    },
    changeMiniHubChoose: (state, action) => {
      state.miniHubChoose = action.payload;
    },
    changeMiniHubSearch: (state, action) => {
      state.miniHubSearch = action.payload;
    },
    changeMiniHubSearchDebounce: (state, action) => {
      state.miniHubSearchDebounce = action.payload;
    },

    changeIsHubOpen: (state, action) => {
      state.isHubOpen = action.payload;
    },

    changeDescription: (state, action) => {
      state.description = action.payload;
    },
  },
});
export const {
  goToPurchaseRequestEdit,
  changeSubsidiaryId,

  // hub
  changeMasterHub,
  changeMasterHubId,
  changeSubHub,
  changeSubHubId,
  changeMiniHub,
  changeMiniHubId,
  changeHubs,

  changeHubChoose,
  changeHubSearch,
  changeHubSearchDebounce,
  changeSubHubChoose,
  changeSubHubSearch,
  changeSubHubSearchDebounce,
  changeMiniHubChoose,
  changeMiniHubSearch,
  changeMiniHubSearchDebounce,

  changeLoading,
  changeDescription,
} = EditPurchaseRequestState.actions;

export const getDataEditPurchaseRequest = (state) =>
  state.editPurchaseRequest.datas;
export const getSubsidiaryIdEditPurchase = (state) =>
  state.editPurchaseRequest.subsidiaryId;

// -------------- hub -----------------------
export const getMasterHubId = (state) => state.editPurchaseRequest.masterHubId;
export const getMasterHubPurchase = (state) =>
  state.editPurchaseRequest.masterHub;
export const getSubHubId = (state) => state.editPurchaseRequest.subHubId;
export const getSubHubPurchase = (state) => state.editPurchaseRequest.subHub;
export const getMiniHubId = (state) => state.editPurchaseRequest.miniHubId;
export const getMiniHubPurchase = (state) => state.editPurchaseRequest.miniHub;
export const getAllFixHubs = (state) => state.editPurchaseRequest.hubs;

export const getHubLoading = (state) => state.editPurchaseRequest.hubLoading;
export const getHubChoosePurchase = (state) =>
  state.editPurchaseRequest.hubChoose;
export const getHubSearchPurchase = (state) =>
  state.editPurchaseRequest.hubSearch;
export const getHubSearchDebouncePurchase = (state) =>
  state.editPurchaseRequest.hubSearchDebounce;

export const getSubHubChoosePurchase = (state) =>
  state.editPurchaseRequest.subHubChoose;
export const getSubHubSearchPurchase = (state) =>
  state.editPurchaseRequest.subHubSearch;
export const getSubHubSearchDebouncePurchase = (state) =>
  state.editPurchaseRequest.subHubSearchDebounce;

export const getMiniHubChoosePurchase = (state) =>
  state.editPurchaseRequest.miniHubChoose;
export const getMiniHubSearchPurchase = (state) =>
  state.editPurchaseRequest.miniHubSearch;
export const getMiniHubSearchDebouncePurchase = (state) =>
  state.editPurchaseRequest.miniHubSearchDebounce;

// description
export const getDescriptionEditPurchase = (state) =>
  state.editPurchaseRequest.description;

// ---------------- API ----------------

// hub
export const getDataMasterHubPurchase = (id, search) => (dispatch) => {
  const fetchMasterHub = async () => {
    let params = {};
    params.subsidiaryId = [id];
    params.search = search;
    params.page = 1;
    params.pageSize = 5;

    try {
      const {
        data: {
          data: { hubs },
        },
      } = await getAllHubs(params);
      dispatch(changeMasterHub(hubs));
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  fetchMasterHub();
};

export const getDataSubHubPurchase = (id) => (dispatch) => {
  const fetchSubHub = async () => {
    let params = {};
    params.idToko = id;
    params.page = 1;
    params.pageSize = 5;

    try {
      const {
        data: {
          data: { subHubs },
        },
      } = await getAllSubHub(params);
      dispatch(changeSubHub(subHubs));
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  fetchSubHub();
};

export const getDataMiniHubPurchase = (id) => (dispatch) => {
  const fetchMiniHub = async () => {
    let params = {};
    params.subHubId = id;
    params.page = 1;
    params.pageSize = 5;

    try {
      const {
        data: {
          data: { subHubs },
        },
      } = await getAllMiniHub(params);
      dispatch(changeMiniHub(subHubs));
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  fetchMiniHub();
};

export default EditPurchaseRequestState.reducer;
