import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import InventoryStockOutDesktop from "./StockoutDesktop";
import InventoryStockOutMobile from "./Mobile";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllDataInvoice,
  getAllDataStockOut,
} from "../../../../../store/stockout/stockout";

const InventoryStockOut = () => {
  const dispatch = useDispatch();
  const [desktopMode, setDesktopMode] = useState(true);
  const [search, setSearch] = useState(null);
  const { invoice, isLoading } = useSelector(getAllDataStockOut);

  // --- Windows Screen ---
  useEffect(() => {
    if (window.screen.width <= 830) {
      setDesktopMode(false);
    } else {
      setDesktopMode(true);
    }
  }, [window.screen.width]);

  // --- Check Invoice ---
  useEffect(() => {
    dispatch(fetchAllDataInvoice(search));
  }, [search]);

  return (
    <Box mt={"30px"}>
      {desktopMode ? (
        <InventoryStockOutDesktop
          setSearch={setSearch}
          search={search}
          invoice={invoice}
          isLoading={isLoading}
        />
      ) : (
        <InventoryStockOutMobile
          setSearch={setSearch}
          search={search}
          invoice={invoice}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
};

export default InventoryStockOut;
