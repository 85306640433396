import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { getDetailProduct } from '../../../../../../../../api/purchase';

const QtySelect = ({
  idProduct,
  handleValue,
  firstValueQty,
  firstValueUnit,
  isReset,
  setIsReset,
  isDisableQty,
  isDisableUnit,
}) => {
  const matchTablet = useMediaQuery('(min-width:832px)');

  const [qtyValue, setQtyValue] = useState('');
  const [qtyValueFix, setQtyValueFix] = useState('');

  const [unitSelect, setUnitSelect] = useState('');
  const [unitSelectFix, setUnitSelectFix] = useState('');

  const [isUnitOnFocus, setIsUnitOnFocus] = useState(false);

  const [unitDatas, setUnitDatas] = useState([]);
  const [unitLoading, setUnitLoading] = useState(false);
  const [limitOrder, setLimitOrder] = useState(null);
  const [notifLimit, setNotfLimit] = useState('');

  useEffect(() => {
    const fetchProductUnit = async () => {
      try {
        // setIsLoadingUnit(true);
        const {
          data: { data },
        } = await getDetailProduct(idProduct);
        setUnitDatas(data?.productUnits);
        // setQtyValue(data?.orderLimitMin === null ? 0 : data?.orderLimitMin);
        setLimitOrder({
          min: data?.orderLimitMin,
          max: data?.orderLimitMax,
          minUnit: data?.stokMinUnit,
          maxUnit: data?.stokMaxUnit,
        }); // TODO : min unit dan max unit nmasih ngambil dari stok karena di limit hanya mencamtumkan id
      } catch (err) {
        console.error(err);
      }
    };

    fetchProductUnit();
  }, [idProduct]);

  // useEffect(() => {
  //   if (limitOrder?.min !== undefined) {
  //     if (qtyValue !== '' && qtyValue < limitOrder?.min) {
  //       setNotfLimit('the product is under min order limit');
  //     } else if (qtyValue < limitOrder?.min) {
  //       setNotfLimit('the product is under min order limit');
  //     } else if (unitSelect !== limitOrder?.minUnit) {
  //       setNotfLimit('the product is under min order limit');
  //     } else {
  //       setNotfLimit('');
  //     }
  //   } else {
  //     setNotfLimit('');
  //   }
  // }, [limitOrder, qtyValue, unitSelect]);

  useEffect(() => {
    if (firstValueQty !== 0) {
      setQtyValue(firstValueQty);
      setUnitSelect(firstValueUnit);
    }
  }, []);
  useEffect(() => {
    if (isReset) {
      setQtyValue(firstValueQty);
      setUnitSelect(firstValueUnit);
      setIsReset(false);
    }
  }, [isReset]);

  useEffect(() => {
    if (unitSelect !== unitSelectFix || qtyValue !== qtyValueFix) {
      return handleValue(qtyValue, unitSelect, notifLimit);
      return setUnitSelectFix(unitSelect);
      return setQtyValueFix(qtyValue);
    }
  }, [unitSelect, unitSelectFix, qtyValue, qtyValueFix]);

  return (
    <>
      {!matchTablet ? (
        <FormControl variant="outlined" sx={{ bgcolor: '#FAFAFA' }}>
          <OutlinedInput
            placeholder="Qty*"
            type="number"
            value={qtyValue}
            onChange={(e) => setQtyValue(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <FormControl>
                  {unitSelect === '' && (
                    <InputLabel id="unit-label">Unit</InputLabel>
                  )}
                  <Select
                    labelId=""
                    value={unitSelect}
                    label="Unit"
                    onChange={(e) => setUnitSelect(e.target.value)}
                    onFocus={() => setIsUnitOnFocus(true)}
                    onBlur={() => setIsUnitOnFocus(false)}
                    sx={{
                      minWidth: '80px',
                      height: '40px',
                      lineHeight: '40px',
                      bgcolor: 'white',
                      '.MuiOutlinedInput-input': {
                        paddingTop: '12px',
                        paddingBottom: '12px',
                        paddingLeft: '12px',
                        paddingRight: '0',
                        borderRadius: '5px',
                      },
                    }}
                  >
                    {unitDatas.map((unitData, index) => (
                      <MenuItem value={unitData.name} key={index}>
                        {unitData.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
          />
        </FormControl>
      ) : (
        <FormControl
          variant="outlined"
          sx={{ bgcolor: '#FAFAFA', minWidth: '100%' }}
        >
          <OutlinedInput
            disabled={isDisableQty}
            placeholder="Enter Qty"
            type="number"
            // value={qtyValue}
            // onChange={(e) => setQtyValue(e.target.value)}
            value={qtyValue}
            onChange={(e) => setQtyValue(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <FormControl>
                  {unitSelect === '' && (
                    <InputLabel id="unit-label">
                      {/* {limitOrder?.minUnit} */}
                    </InputLabel>
                  )}
                  <Select
                    disabled={isDisableUnit}
                    labelId=""
                    value={unitSelect}
                    label="Unit"
                    onChange={(e) => setUnitSelect(e.target.value)}
                    onFocus={() => setIsUnitOnFocus(true)}
                    onBlur={() => setIsUnitOnFocus(false)}
                    sx={{
                      minWidth: '130px',
                      bgcolor: 'white',
                      '.MuiOutlinedInput-input': {
                        paddingTop: '12px',
                        paddingBottom: '12px',
                        paddingLeft: '12px',
                        paddingRight: '0',
                        borderRadius: '5px',
                      },
                    }}
                  >
                    {unitDatas.map((unitData, index) => (
                      <MenuItem value={unitData?.unit?.name} key={index}>
                        {unitData?.unit?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
          />
        </FormControl>
      )}
    </>
  );
};

export default QtySelect;
