import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as DollarIcon } from '../../../../../../assets/dollar.svg';
import { ReactComponent as BoxIcon } from '../../../../../../assets/box.svg';
import { ReactComponent as NoImage } from '../../../../../../assets/no-image.svg';
import { ReactComponent as SalesIcon } from '../../../../../../assets/sales.svg';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import BackButton from '../../../../../../components/shared/BackButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuPerformance from '../Components/performance/MenuPerformance';
import TableMenuMenu from './Desktop/TableMenuMenu';
import { useTheme } from '@emotion/react';
import CustomNotifSuccess from '../../../../../../components/shared/CustomNotifSuccess';
import CustomCard from '../../../../../../components/shared/CustomCard';
import { Box } from '@mui/system';

const MenuMenuMobile = (props) => {
  const {
    theme,
    // *-- Hirarki
    roadHirarki,
    setRoadHirarki,
    // *-- Notif
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
    // *-- Page
    setPage,
    setMenuMenu,
    // *-date & time
    timeFrame,
    setTimeFrame,
    dateRange,
    setDateRange,
    saveDateRange,
    setSaveDateRange,
    // *-Tabel
    tableRowPerPageValues,
    tableRowPerPage,
    setTableRowPerPage,
    tableCurrentPage,
    setTableCurrentPage,
    tableTotalItems,
    setTableTotalItems,
    tableSearch,
    setTableSearch,
    sortTable,
    setSortTable,
    isSortAsc,
    setIsSortAsc,
    tableDatas,
    setTableDatas,
    tableCategoryFilterDatas,
    setTableCategoryFilterDatas,
    isLoadingData,
    // * -- Hanlder
    handleBackButton,
    handleClickCategory,
    handleAddMenu,
    handleAddMini,
    handleClickMini,
    handleClickRow,
  } = props;

  return (
    <Grid container columns={12} spacing={2}>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
      >
        <BackButton handleClick={handleBackButton} />
        {roadHirarki.category === '' || roadHirarki.subCategory === '' ? (
          <Typography sx={{ ml: '4px', fontSize: '12px', fontWeight: '600' }}>
            Menus
          </Typography>
        ) : (
          <>
            <Typography
              sx={{
                ml: '4px',
                fontSize: '12px',
                fontWeight: '600',
                color: '#9E9D9D',
                cursor: 'pointer',
              }}
              onClick={handleClickCategory}
            >
              Category
            </Typography>
            {roadHirarki.category && (
              <>
                <ChevronRightIcon sx={{ color: '#9E9D9D' }} />
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '600', color: '#9E9D9D' }}
                  onClick={handleClickCategory}
                >
                  {roadHirarki.category}
                </Typography>
              </>
            )}
            {roadHirarki.subCategory && (
              <>
                <ChevronRightIcon sx={{ color: '#9E9D9D' }} />
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '600' }}
                  onClick={handleBackButton}
                >
                  {roadHirarki.subCategory}
                </Typography>
              </>
            )}
          </>
        )}
      </Grid>
      {/* //* Table */}
      <Grid item xs={12}>
        <CustomCard sx={{ p: '12px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {/* //* JUDUl */}
              <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
                Product
              </Typography>
              <TextField
                placeholder={'Search'}
                size="small"
                sx={{
                  width: '100px',
                  height: '20px',
                  '.MuiOutlinedInput-input': {
                    padding: '6px 0px 6px 0px',
                    fontSize: '0.5rem',
                  },
                  '.MuiOutlinedInput-root': {
                    bgcolor: '#FAFAFA',
                    borderRadius: '4px',
                  },
                }}
                value={tableSearch}
                onChange={(e) => setTableSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ width: '8px', ml: '4px' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'row-reverse',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '4px',
                  bgcolor: theme.palette.primary.main,
                  width: '20px',
                  height: '20px ',
                  cursor: 'pointer',
                }}
                onClick={handleAddMenu}
              >
                <AddIcon sx={{ color: 'white', width: '8px', height: '8px' }} />
              </Box>
              <CustomCard
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '4px',
                  width: '20px',
                  height: '20px ',
                }}
                // onClick={() => setIsOpenTableFilter(true)}
              >
                <FilterAltIcon sx={{ width: '12px' }} />
              </CustomCard>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: '16px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <DollarIcon style={{ height: '10px' }} />
                <Typography sx={{ fontSize: '8px' }}>COGS(Rp)*</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <SalesIcon style={{ height: '10px', fill: '#51B15C' }} />
                <Typography sx={{ fontSize: '8px' }}>SKU</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <ErrorOutlineIcon
                  sx={{
                    fontSize: '10px',
                    fontWeight: '500',
                    color: theme.palette.warning.main,
                  }}
                />
                <Typography sx={{ fontSize: '8px' }}>Limit</Typography>
              </Box>
            </Box>
            <Typography sx={{ fontSize: '8px' }}>*000</Typography>
          </Box>
        </CustomCard>
      </Grid>
    </Grid>
  );
};

export default MenuMenuMobile;
