import { KeyboardArrowRight as KeyboardArrowRightIcon, Logout as LogoutIcon } from '@mui/icons-material';
import { ReactComponent as DashboardIcon } from '../assets/dashboard.svg';
import { ReactComponent as TreatmentIcon } from '../assets/used.svg';
import { ReactComponent as SalesIcon } from '../assets/sales.svg';
// import { ReactComponent as AssetIcon } from '../assets/asset.svg';
// import { ReactComponent as EquityIcon } from '../assets/equity.svg';
// import { ReactComponent as ExpenseIcon } from '../assets/expense.svg';
// import { ReactComponent as LiabilityIcon } from '../assets/liability.svg';
// import { ReactComponent as RevenueIcon } from '../assets/revenue.svg';
import { Box, Divider, Drawer, IconButton, List, ListItem, Badge, ListItemButton, ListItemIcon, ListItemText, Typography, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import logoImage from '../assets/logo.png';
import useAuth from '../hooks/useAuth';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ReactComponent as AssetsLogo } from '../assets/logo-assets.svg';
import MobileHeaderComponent from './MobileHeaderComponent';

const drawerWidth = 240;

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  minHeight: '100vh',
  width: '100vw',
  paddingBottom: theme.spacing(1),
  backgroundColor: theme.palette.grey[200],
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  gap: 30,
}));

const MobileLayout = () => {
  const { auth, setAuth } = useAuth();
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const handleLogout = () => {
    setAuth(null);
    localStorage.clear();
  };

  const handleChangeCompany = ({ companyId }) => {
    switch (companyId) {
      case 7:
        localStorage.setItem('companyId', 9);
        break;
      case 9:
        localStorage.setItem('companyId', 7);
        break;
      default:
        localStorage.setItem('companyId', 7);
        break;
    }

    window.location.reload();
  };

  const [isOpenAssetsList, setIsOpenAssetsList] = useState(false);
  const [isOpenCurrentAssetsList, setIsOpenCurrentAssetsList] = useState(false);
  const [isOpenNonCurrentAssetsList, setIsOpenNonCurrentAssetsList] = useState(false);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <MobileHeaderComponent open={open} setOpen={setOpen} />
        {/* <CssBaseline /> */}

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="temporary"
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
        >
          <DrawerHeader>
            <Box
              sx={{
                height: '1.5rem',
                width: '1.5rem',
                borderRadius: 1,
              }}
              component="img"
              src={logoImage}
            />
            {/* <Typography
              color="primary"
              sx={{
                fontSize: '1.5rem',
                fontWeight: 500,
                flexGrow: 1,
              }}
            >
              Alere
            </Typography> */}
            <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={() => auth?.role === 'vip' && handleChangeCompany({ companyId: auth?.companyId })}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <Box>
                  <Typography
                    component="span"
                    sx={{
                      fontSize: '0.875rem',
                      color: auth?.companyId === 9 ? '#EACB01' : '#F1202C',
                    }}
                  >
                    {auth?.companyId === 9 ? 'KANPAI' : 'MRI'}
                  </Typography>
                  <Typography
                    component="span"
                    sx={{
                      fontSize: '0.5rem',
                      color: auth?.companyId === 9 ? '#EACB01' : '#F1202C',
                    }}
                  >
                    .id
                  </Typography>
                </Box>
                <Typography sx={{ fontSize: '0.5rem' }} color="primary">
                  by Alere
                </Typography>
              </Box>
              {(auth?.role === 'vip' || auth?.email === 'investment@alere.id') && (
                <IconButton edge="end" sx={{ color: '#C8C8CC' }}>
                  <KeyboardArrowRightIcon />
                </IconButton>
              )}
            </Box>
          </DrawerHeader>
          <Divider />
          <List onClick={() => setOpen(!open)}>
            <ListItem disablePadding>
              <ListItemButton sx={{ px: 2, py: 1.5 }} component={Link} to="dashboard">
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <DashboardIcon fill={location?.pathname === '/dashboard' ? '#51B15C' : '#B0C6B5'} width="20px" height="20px" />
                </ListItemIcon>
                <ListItemText
                  primary="Dashboard"
                  sx={{
                    ml: '1.5rem',
                  }}
                  primaryTypographyProps={{
                    sx: {
                      fontWeight: 'semiBold',
                      color: location?.pathname === '/dashboard' ? 'primary.main' : 'primary.grey',
                      fontSize: '1.125rem',
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
            {auth?.role !== 'investment' && (
              <>
                {auth?.companyId !== 9 && (
                  <>
                    <ListItem disablePadding>
                      <ListItemButton sx={{ px: 2, py: 1.5 }} component={Link} to="treatment-dashboard">
                        <ListItemIcon sx={{ minWidth: 0 }}>
                          <TreatmentIcon fill={location?.pathname === '/treatment-dashboard' ? '#51B15C' : '#B0C6B5'} width="20px" height="20px" />
                        </ListItemIcon>
                        <Badge
                          badgeContent="New"
                          componentsProps={{
                            badge: {
                              sx: {
                                fontSize: '0.5rem',
                                width: '1.75rem',
                                height: '0.75rem',
                                bgcolor: '#F28AAE',
                                color: 'common.white',
                              },
                            },
                          }}
                        >
                          <ListItemText
                            primary="Treatment(s)"
                            sx={{
                              ml: '1.5rem',
                            }}
                            primaryTypographyProps={{
                              sx: {
                                fontWeight: 'semiBold',
                                color: location?.pathname === '/treatment-dashboard' ? 'primary.main' : 'primary.grey',
                                fontSize: '1.125rem',
                              },
                            }}
                          />
                        </Badge>
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton sx={{ px: 2, py: 1.5 }} component={Link} to="sales-dashboard">
                        <ListItemIcon sx={{ minWidth: 0 }}>
                          <SalesIcon fill={location?.pathname === '/sales-dashboard' ? '#51B15C' : '#B0C6B5'} width="20px" height="20px" />
                        </ListItemIcon>
                        <Badge
                          badgeContent="New"
                          componentsProps={{
                            badge: {
                              sx: {
                                fontSize: '0.5rem',
                                width: '1.75rem',
                                height: '0.75rem',
                                bgcolor: '#F28AAE',
                                color: 'common.white',
                              },
                            },
                          }}
                        >
                          <ListItemText
                            primary="Sales"
                            sx={{
                              ml: '1.5rem',
                            }}
                            primaryTypographyProps={{
                              sx: {
                                fontWeight: 'semiBold',
                                color: location?.pathname === '/sales-dashboard' ? 'primary.main' : 'primary.grey',
                                fontSize: '1.125rem',
                              },
                            }}
                          />
                        </Badge>
                      </ListItemButton>
                    </ListItem>
                  </>
                )}

                <ListItem disablePadding>
                  <ListItemButton
                    sx={{ px: 2, py: 1.5 }}
                    onClick={(event) => {
                      event.stopPropagation();
                      setIsOpenAssetsList(!isOpenAssetsList);
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 0 }}>
                      <AssetsLogo fill={'#B0C6B5'} width="20px" height="20px" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Assets"
                      sx={{
                        ml: '1rem',
                      }}
                      primaryTypographyProps={{
                        sx: {
                          fontWeight: 600,
                          color: 'primary.grey',
                          fontSize: '0.875rem',
                          ml: '2rem',
                        },
                      }}
                    />
                    {isOpenAssetsList ? (
                      <ExpandLess
                        sx={{
                          color: 'primary.grey',
                        }}
                      />
                    ) : (
                      <ExpandMore
                        sx={{
                          color: 'primary.grey',
                        }}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
                <Collapse in={isOpenAssetsList} timeout="auto" unmountOnExit>
                  {/* <List component="div"> */}
                  <ListItem sx={{ py: 0, pr: 0 }}>
                    <ListItemButton
                      sx={{ px: 2, py: 1.5 }}
                      onClick={(event) => {
                        event.stopPropagation();
                        setIsOpenCurrentAssetsList(!isOpenCurrentAssetsList);
                      }}
                    >
                      <ListItemText
                        primary="Current Assets"
                        primaryTypographyProps={{
                          sx: {
                            fontWeight: 600,
                            color: 'primary.grey',
                            fontSize: '0.875rem',
                          },
                        }}
                      />
                      {isOpenCurrentAssetsList ? (
                        <ExpandLess
                          sx={{
                            color: 'primary.grey',
                          }}
                        />
                      ) : (
                        <ExpandMore
                          sx={{
                            color: 'primary.grey',
                          }}
                        />
                      )}
                    </ListItemButton>
                  </ListItem>

                  <Collapse in={isOpenCurrentAssetsList} timeout="auto" unmountOnExit>
                    <ListItem sx={{ py: 0, pr: 0, pl: 4 }}>
                      <ListItemButton component={Link} to="/assets/inventory">
                        <ListItemText
                          primary="Inventory"
                          primaryTypographyProps={{
                            sx: {
                              fontWeight: 500,
                              color: location?.pathname === '/assets/inventory' ? '#51B15C' : 'primary.grey',
                              fontSize: '0.875rem',
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Collapse>
                  {/* </List> */}

                  {/* <List component="div"> */}
                  <ListItem sx={{ py: 0, pr: 0 }}>
                    <ListItemButton
                      sx={{ px: 2, py: 1.5 }}
                      onClick={(event) => {
                        event.stopPropagation();
                        setIsOpenNonCurrentAssetsList(!isOpenNonCurrentAssetsList);
                      }}
                    >
                      <ListItemText
                        primary="Noncurrent Assets"
                        sx={
                          {
                            // ml: 4.3,
                          }
                        }
                        primaryTypographyProps={{
                          sx: {
                            fontWeight: 600,
                            color: 'primary.grey',
                            fontSize: '0.875rem',
                          },
                        }}
                      />
                      {isOpenNonCurrentAssetsList ? (
                        <ExpandLess
                          sx={{
                            color: 'primary.grey',
                          }}
                        />
                      ) : (
                        <ExpandMore
                          sx={{
                            color: 'primary.grey',
                          }}
                        />
                      )}
                    </ListItemButton>
                  </ListItem>
                  {/* </List> */}
                </Collapse>
              </>
            )}
          </List>
          <Divider />
          <Box
            sx={{
              position: 'fixed',
              bottom: 10,
              width: drawerWidth,
              display: 'flex',
              gap: 2,
              // justifyContent: 'space-between',
              justifyContent: 'flex-end',
              px: 2,
              py: 1.5,
              alignItems: 'center',
            }}
          >
            {/* <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Typography
                color="primary"
                sx={{ fontSize: '1.25rem', fontWeight: 'semibold' }}
              >
                {auth?.name || 'Name'}
              </Typography>
            </Box> */}

            <IconButton onClick={handleLogout}>
              <LogoutIcon color="primary" />
            </IconButton>
          </Box>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <Outlet />
        </Main>
      </Box>
    </>
  );
};

export default MobileLayout;
