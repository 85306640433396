import { createSlice } from '@reduxjs/toolkit';
import { getAllQrCode } from '../../../api/qrcode';

export const sendDdataTransacitonState = createSlice({
  name: 'sendDataTransaction',
  initialState: {
    rackId: null,
    dataRackDetail: null,
    scanned: [],
    idScanned: [],
    isExist: false,
    isLoading: false,
    totalData: 0,
    allData: [],
    allDataWithQr: [],
    detail: {},
    pemberi: '',
    lokasiPemberi: '',
    noqrid: [],
    noqrDataSelect: [],
    noqr: [],
    rackIdNoQr: '',
    isSelect: false,
  },
  reducers: {
    loadNoqrTransaction: (state, action) => {
      const { noqrDataSelect, url, rackId } = action.payload;
      if (
        !state.noqrid.includes(noqrDataSelect.map((item, idx) => item.idProduk))
      ) {
        state.noqrid = [
          ...state.noqrid,
          {
            id_rack: rackId,
            photo_url: url,
            id_detail: noqrDataSelect.map((item) => item.iddetailSti),
          },
        ];
        state.noqr = [...state.noqr, noqrDataSelect];
        state.noqrDataSelect = [];
        state.rackIdNoQr = rackId;
        state.status = true;
      } else {
        state.status = false;
      }
    },
    changeScanRackIdTransactionSend: (state, action) => {
      state.rackId = action.payload;
    },
    loadSpesifikDataQrRackTransactionSend: (state, action) => {
      state.dataRackDetail = action.payload;
    },
    // scanDataTransaction: (state, action) => {
    //   const data = action.payload;
    //   if (!state.idScanned.includes(data.encript)) {
    //     state.idScanned = [...state.idScanned, data.encript];
    //     state.scanned = [...state.scanned, data];
    //     state.isExist = false;
    //   } else {
    //     state.isExist = true;
    //   }
    // },

    // scanDataTransaction: (state, action) => {
    //   const data = action.payload;
    //   if (!state.idScanned.includes(data.idProduk)) {
    //     state.idScanned = [...state.idScanned, data.idProduk];
    //     state.scanned = [...state.scanned, data];
    //     state.isExist = false;
    //   } else {
    //     state.isExist = true;
    //   }
    // },

    scanDataTransaction: (state, action) => {
      const data = action.payload;
      if (!state.idScanned.includes(data.encript)) {
        state.idScanned = [
          ...state.idScanned,
          { idProduk: data.idProduk, encript: data.encript },
        ];
        state.scanned = [...state.scanned, data];
        state.isExist = false;
      } else {
        state.isExist = true;
      }
    },

    changeLoadingDataTransaction: (state, action) => {
      state.isLoading = action.payload;
    },
    setTotalDataDataTransaction: (state, action) => {
      state.totalData = action.payload;
    },
    setAllDataDataTransaction: (state, action) => {
      state.allData = action.payload;
    },
    setAllDataDataTransactionWithQr: (state, action) => {
      const datas = action.payload;
      const seenData = new Set();

      const uniqueData = [];

      datas.forEach((item) => {
        const key = `${item.id}-${item.name}`;

        if (!seenData.has(key)) {
          seenData.add(key);
          uniqueData.push(item);
        }
      });
      state.allDataWithQr = uniqueData;
    },
    setDetailTransaction: (state, action) => {
      state.detail = action.payload;
    },
    changePemberi: (state, action) => {
      state.pemberi = action.payload;
    },
    changeLokasiPemberi: (state, action) => {
      state.lokasiPemberi = action.payload;
    },

    changeNoqrDataSelectTransaction: (state, action) => {
      const { item, id } = action.payload;

      if (
        state.noqrDataSelect.filter((val) => val.idProduk === id).length === 0
      ) {
        state.noqrDataSelect = [...state.noqrDataSelect, item];
      } else {
        state.noqrDataSelect = state.noqrDataSelect.filter(
          (val) => val.idProduk !== id
        );
      }
    },

    changeIsSelectTransaction: (state, action) => {
      state.isSelect = action.payload;
    },
  },
});

export const {
  loadNoqrTransaction,
  changeScanRackIdTransactionSend,
  loadSpesifikDataQrRackTransactionSend,
  scanDataTransaction,
  changeLoadingDataTransaction,
  setTotalDataDataTransaction,
  setAllDataDataTransaction,
  setDetailTransaction,
  changePemberi,
  changeLokasiPemberi,
  changeNoqrDataSelectTransaction,
  changeIsSelectTransaction,
  setAllDataDataTransactionWithQr,
} = sendDdataTransacitonState.actions;

export const getAllDataTransactionScan = (state) => state.sendDataTransaction;

// export const fetchAllDataDataTransactionAdmin = (id) => (dispatch) => {
//   const totalData = 100;
//   const fetchTotalApi = async () => {
//     dispatch(changeLoadingDataTransaction(true));
//     const params = {
//       page: 1,
//       pageSize: totalData,
//     };
//     try {
//       const {
//         data: {
//           data: { transactions },
//         },
//       } = await getAllQrCode(params, id);
//       dispatch(setAllDataTransaction(transactions));
//     } catch (err) {
//       console.error(err);
//     } finally {
//     }
//   };

//   const fetchApi = async () => {
//     dispatch(changeLoadingDataTransaction(true));
//     const params = {
//       page: 1,
//     };
//     try {
//       const {
//         data: {
//           data: {
//             pageInfo: { total },
//           },
//         },
//       } = await getAllQrCode(params, id);
//       totalData = total;
//     } catch (err) {
//       console.error(err);
//     } finally {
//       fetchTotalApi();
//     }
//   };

//   fetchApi();
// };

export default sendDdataTransacitonState.reducer;
