import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Switch,
  Dialog,
  styled,
  DialogTitle,
  DialogContent,
  MenuItem,
  Input,
  InputAdornment,
  OutlinedInput,
  Select,
  Autocomplete,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';

import dayjs from 'dayjs';
import BackButton from '../../../../../../components/shared/BackButton';
import CustomInput from '../../../../../../components/shared/CustomInput';
import {
  deleteFile,
  uploadImage,
  uploadVideo,
} from '../../../../../../api/storage';
import CustomCard from '../../../../../../components/shared/CustomCard';
import { useEffect, useRef, useState } from 'react';
import {
  addNewMenuMenu,
  allCategoryMenuName,
  allMiniSubCategoryMenuName,
  allSubCategoryMenuName,
  deleteMenuMenu,
  updateMenuMenu,
} from '../../../../../../api/menu';
import CustomButton from '../../../../../../components/shared/CustomButton';
import { ReactComponent as InstagramLogo } from '../../../../../../assets/instagramLogo.svg';
import { ReactComponent as TiktokLogo } from '../../../../../../assets/tiktokLogo.svg';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import CustomTextFormatingInput from '../../../../../../components/shared/CustomTextFormatingInput/CustomTextFormatingInput';
import QtyUnitSelect from '../MenuMenuAdd/components/QtyUnitSelect';
import AutoCompleteProduct from '../MenuMenuAdd/components/AutoCompleteProduct';
import CustomEditConfirm from '../../../../../../components/shared/CustomEditConfirm.jsx';
import CustomDeleteConfirm from '../../../../../../components/shared/CustomDeleteConfirm.jsx';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const MenuMenuEdit = (props) => {
  const {
    desktopMode,
    roadHirarki,
    setRoadHirarki,
    // *-- notif
    notif,
    setNotif,
    notifTitle,
    setNotifTitle,
    // *-- page
    setPage,
    setMenuMenu,
    // *-- data
    menuEditInputDatas,
    setMenuEditInputDatas,
    // *-- data await
    categoryDataAwaitEdit,
    setCategoryDataAwaitEdit,
    ingredientDataAwaitEdit,
    setIngredientDataAwaitEdit,
    socialMediaDataAwaitEdit,
    setSocialMediaDataAwaitEdit,
  } = props;

  const theme = useTheme();
  const today = dayjs(new Date());
  // !-------------------- Confirm -----------------------

  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [isEditConfirm, setIsEditConfirm] = useState(false);

  // !------------------------- FETCH LIST CATEGORY ------------------------------

  const [selectOpen, setSelectOpen] = useState('');

  const [dataCategoriesList, setDataCategoriesList] = useState([]);
  const [dataCategoriesLoading, setDataCategoriesLoading] = useState(false);

  const [dataSubCategoriesList, setDataSubCategoriesList] = useState([]);
  const [dataSubCategoriesLoading, setDataSubCategoriesLoading] =
    useState(false);

  const [dataMiniCategoriesList, setDataMiniCategoriesList] = useState([]);
  const [dataMiniCategoriesLoading, setDataMiniCategoriesLoading] =
    useState(false);

  useEffect(() => {
    const getCategoriesName = async () => {
      setDataCategoriesLoading(true);
      let datas = {
        opsiKategori: 6,
      };

      try {
        const {
          data: { data: dataNames },
        } = await allCategoryMenuName(datas);
        setDataCategoriesList(dataNames);
      } catch (err) {
        console.error(err);
        setDataCategoriesList([]);
      } finally {
        setDataCategoriesLoading(true);
      }
    };

    getCategoriesName();
  }, []);

  useEffect(() => {
    const getSubCategoriesName = async () => {
      setDataSubCategoriesLoading(true);
      let datas = {
        idKategori: [categoryDataAwaitEdit?.categoryId],
      };

      try {
        const {
          data: { data: dataNames },
        } = await allSubCategoryMenuName(datas);
        setDataSubCategoriesList(dataNames);
      } catch (err) {
        console.error(err);
        setDataSubCategoriesList([]);
      } finally {
        setDataSubCategoriesLoading(true);
      }
    };
    const getMiniCategoriesName = async () => {
      setDataMiniCategoriesLoading(true);
      let datas = {
        idSubkategori: [categoryDataAwaitEdit?.subCategoryId],
      };

      try {
        const {
          data: { data: dataNames },
        } = await allMiniSubCategoryMenuName(datas);
        setDataMiniCategoriesList(dataNames);
      } catch (err) {
        console.error(err);
        setDataMiniCategoriesList([]);
      } finally {
        setDataMiniCategoriesLoading(true);
      }
    };

    if (categoryDataAwaitEdit?.categoryId !== 0) {
      getSubCategoriesName();
      if (categoryDataAwaitEdit?.subCategoryId !== 0) {
        getMiniCategoriesName();
      }
    }
  }, [categoryDataAwaitEdit?.categoryId, categoryDataAwaitEdit?.subCategoryId]);

  // !------------------------- Formating Input ------------------------------
  const [descriptionProduct, setDescriptionProduct] = useState(
    menuEditInputDatas.description
  );
  const [isMinDescriptionProduct, setIsMinDescriptionProduct] = useState(true);

  // useEffect(() => {
  //   if (menuEditInputDatas.description.length >= 10) {
  //     setIsMinDescriptionProduct(true);
  //   } else {
  //     setIsMinDescriptionProduct(false);
  //   }
  // }, []);

  // !------------------------- SAVE VALIDATION ----------------------------
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);

  useEffect(() => {
    if (menuEditInputDatas.name.length >= 5) {
      if (isMinDescriptionProduct) {
        if (
          menuEditInputDatas.categoryId === 0 ||
          menuEditInputDatas.subCategoryId === 0
        ) {
          return setIsSubmitAvailable(false);
        } else {
          if (menuEditInputDatas.ingredient.length === 0) {
            return setIsSubmitAvailable(false);
          } else {
            if (
              menuEditInputDatas.metaDescription.length > 0 &&
              menuEditInputDatas.metaDescription.length < 20
            ) {
              return setIsSubmitAvailable(false);
            } else {
              return setIsSubmitAvailable(true);
            }
          }
        }
      } else {
        return setIsSubmitAvailable(false);
      }
    } else {
      return setIsSubmitAvailable(false);
    }
  }, [
    isMinDescriptionProduct,
    menuEditInputDatas.name,
    menuEditInputDatas.categoryId,
    menuEditInputDatas.subCategoryId,
    menuEditInputDatas.ingredient,
    menuEditInputDatas.metaDescription,
  ]);

  // !------------------------- HANDLER ------------------------------

  const handleBackButton = () => {
    setMenuMenu('details');
  };

  const handleEdit = () => {
    setIsEditConfirm(!isEditConfirm);
  };
  const handleEditFix = () => {
    let socialMedia = [
      {
        name: 'instagram',
        link: menuEditInputDatas.instagram,
      },
      {
        name: 'tiktok',
        link: menuEditInputDatas.tiktok,
      },
    ];
    let ingredient = menuEditInputDatas.ingredient.map((item) => {
      return {
        idProduk: item.productId,
        unit: item.unitName,
        value: item.unitQty,
      };
    });
    let data = {
      idSubkategori: menuEditInputDatas.subCategoryId,
      namaProduk: menuEditInputDatas.name,
      deskripsi: descriptionProduct,
      status: menuEditInputDatas.publish ? 'aktif' : 'pending',
      schedule: dayjs(menuEditInputDatas.schedule.$d).format('YYYY-MM-DD'),
      ingredient: ingredient,
    };
    if (menuEditInputDatas.metaDescription !== '') {
      data['metaDesk'] = menuEditInputDatas.metaDescription;
    }
    if (menuEditInputDatas.miniSubCategoryId !== 0) {
      data['miniCategoryId'] = menuEditInputDatas.miniSubCategoryId;
    }
    if (menuEditInputDatas.photoUrls.length !== 0) {
      data['gambar'] = menuEditInputDatas.photoUrls.join(',');
    }
    if (menuEditInputDatas.videoUrls.length !== 0) {
      data['video'] = menuEditInputDatas.videoUrls.join(',');
    }
    if (
      menuEditInputDatas.instagram !== '' ||
      menuEditInputDatas.tiktok !== ''
    ) {
      data['socialMedia'] = socialMedia.filter((item) => item.link !== '');
    }
    updateMenuMenu(roadHirarki.menuId, data)
      .then((res) => {
        setRoadHirarki({
          ...roadHirarki,
          menu: res.data.data.namaProduk,
          menuId: res.data.data.idProduk,
        });
        setNotif('editmenu');
        setNotifTitle(
          `${res.data.data.namaProduk} has been successfully edited`
        );
        setMenuMenu('details');
      })
      .catch();
  };

  const handleDelete = () => {
    setIsDeleteConfirm(!isDeleteConfirm);
  };
  const handleDeleteFix = () => {
    deleteMenuMenu(roadHirarki.menuId).then((res) => {
      setRoadHirarki({ ...roadHirarki, product: '', menuId: 0 });
      setIsDeleteConfirm(false);
      setMenuMenu('');
      setNotif('deletemenu');
      setNotifTitle(
        `${res.data.data.namaProduk} has been successfully deleted`
      );
    });
  };

  const handleAddImage = (e) => {
    if (e.target.files[0].size > 2000000) {
      setMenuEditInputDatas({
        ...menuEditInputDatas,
        isMaxSizePhoto: true,
      });
    } else {
      let datas = {
        image: e.target.files[0],
        resource: 'menu',
      };
      uploadImage(datas).then((res) => {
        setMenuEditInputDatas({
          ...menuEditInputDatas,
          photoUrls: [...menuEditInputDatas.photoUrls, res.data.data.path],
          isMaxSizePhoto: false,
        });
      });
    }
  };
  const handleDeleteImage = (link) => {
    setMenuEditInputDatas({
      ...menuEditInputDatas,
      photoUrls: menuEditInputDatas.photoUrls.filter((item) => item !== link),
    });
  };
  const handlePreviewImage = (link) => {
    setMenuEditInputDatas({
      ...menuEditInputDatas,
      linkPreviewPhoto: link,
      isPreviewPhoto: true,
    });
  };
  const handleClosePreviewImage = () => {
    setMenuEditInputDatas({
      ...menuEditInputDatas,
      isPreviewPhoto: !menuEditInputDatas.isPreviewPhoto,
      linkPreviewPhoto: '',
    });
  };

  const handleAddVideo = (e) => {
    if (e.target.files[0].size > 100000000) {
      setMenuEditInputDatas({
        ...menuEditInputDatas,
        isMaxSizeVideo: true,
      });
    } else {
      let datas = {
        video: e.target.files[0],
        resource: 'menu',
      };
      uploadVideo(datas)
        .then((res) => {
          setMenuEditInputDatas({
            ...menuEditInputDatas,
            videoUrls: [...menuEditInputDatas.videoUrls, res.data.data.path],
            isMaxSizeVideo: false,
          });
        })
        .catch((err) => console.error(err));
    }
  };

  const handleDeleteVideo = (link) => {
    deleteFile(link).then((res) => {
      setMenuEditInputDatas({
        ...menuEditInputDatas,
        videoUrls: menuEditInputDatas.videoUrls.filter((item) => item !== link),
      });
    });
  };

  const handlePreviewVideo = (link) => {
    setMenuEditInputDatas({
      ...menuEditInputDatas,
      linkPreviewVideo: link,
      isPreviewVideo: true,
    });
  };

  const handleClosePreviewVideo = () => {
    setMenuEditInputDatas({
      ...menuEditInputDatas,
      linkPreviewVideo: '',
      isPreviewVideo: false,
    });
  };

  const handleClickCardCategory = () => {
    setMenuEditInputDatas({
      ...menuEditInputDatas,
      isCategoryOpen: !menuEditInputDatas.isCategoryOpen,
    });
  };

  const handleSaveCategoryCard = () => {
    setMenuEditInputDatas({
      ...menuEditInputDatas,
      category: categoryDataAwaitEdit.category,
      subCategory: categoryDataAwaitEdit.subCategory,
      miniSubCategory: categoryDataAwaitEdit.miniSubCategory,
      categoryId: categoryDataAwaitEdit.categoryId,
      subCategoryId: categoryDataAwaitEdit.subCategoryId,
      miniSubCategoryId: categoryDataAwaitEdit.miniSubCategoryId,
      isCategoryOpen: false,
    });
  };

  const handleClickCardIngredient = () => {
    setMenuEditInputDatas({
      ...menuEditInputDatas,
      isIngredientOpen: !menuEditInputDatas.isIngredientOpen,
    });
  };

  const [isDeleteIngredientRow, setIsDeleteIngredientRow] = useState(false);

  const handleSaveCardIngredient = () => {
    setMenuEditInputDatas({
      ...menuEditInputDatas,
      ingredient: ingredientDataAwaitEdit,
      isIngredientOpen: !menuEditInputDatas.isIngredientOpen,
    });
  };

  const handleAddItemCardIngredient = () => {
    setIngredientDataAwaitEdit([
      ...ingredientDataAwaitEdit,
      { product: '', productId: 0, unitQty: 0, unitName: '' },
    ]);
  };

  const handleClickCardSocialMedia = () => {
    setMenuEditInputDatas({
      ...menuEditInputDatas,
      isSocialMediaOpen: !menuEditInputDatas.isSocialMediaOpen,
    });
  };

  const handleSaveCardSocialMedia = () => {
    setMenuEditInputDatas({
      ...menuEditInputDatas,
      instagram: socialMediaDataAwaitEdit.instagram,
      tiktok: socialMediaDataAwaitEdit.tiktok,
      isSocialMediaOpen: false,
    });
  };
  return (
    <>
      <CustomEditConfirm
        open={isEditConfirm}
        onClose={() => setIsEditConfirm(false)}
        handleClickCancel={() => setIsEditConfirm(false)}
        handleClickEdit={handleEditFix}
        title="Edit Product"
        body={
          <Box sx={{ textAlign: 'center', mb: '40px', mt: '8px' }}>
            <Typography>Are you sure you want to save the changes?</Typography>
          </Box>
        }
      />
      <CustomDeleteConfirm
        open={isDeleteConfirm}
        onClose={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickCancel={() => setIsDeleteConfirm(!isDeleteConfirm)}
        handleClickDelete={handleDeleteFix}
        title="Delete Product"
        body={
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              Are you sure you want to delete "
              <Typography component="span" sx={{ fontWeight: '600' }}>
                {menuEditInputDatas.name}
              </Typography>
              " ?
            </Typography>
            <Typography sx={{ mb: '52px' }}>
              You can’t undo this action.
            </Typography>
          </Box>
        }
      />
      <Grid container columns={12} spacing={4} sx={{ pb: '56px' }}>
        {/* //* BackButton */}
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
        >
          <BackButton handleClick={handleBackButton} />
          <Box>
            <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>
              Edit Menu
            </Typography>
            {roadHirarki?.category !== '' &&
              roadHirarki?.subCategory !== '' && (
                <Box
                  sx={{
                    display: 'flex',
                    color: '#9E9D9D',

                    alignItems: 'center',
                    mt: '4px',
                    gap: 1,
                  }}
                >
                  <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                    {roadHirarki?.subsidiary}
                  </Typography>
                  <ChevronRightIcon sx={{ fontSize: '16px' }} />
                  <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                    {roadHirarki?.category}
                  </Typography>
                  <ChevronRightIcon sx={{ fontSize: '16px' }} />
                  <Typography sx={{ fontSize: '16px', fontWeight: '100' }}>
                    {roadHirarki?.subCategory}
                  </Typography>
                </Box>
              )}
          </Box>
        </Grid>
        {/* //* Name */}
        <Grid item xs={6}>
          <Typography sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}>
            Menu Name*
          </Typography>
          <CustomInput
            sx={{ width: '100%', bgcolor: '#FAFAFA' }}
            placeholder="Enter menu name"
            value={menuEditInputDatas.name}
            onChange={(e) => {
              if (e.target.value.length <= 30) {
                setMenuEditInputDatas({
                  ...menuEditInputDatas,
                  name: e.target.value,
                });
              }
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '400',
                color: '#9E9D9D',
                mt: '4px',
              }}
            >
              Enter at least 5 character
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '400',
                color: '#9E9D9D',
                mt: '4px',
              }}
            >
              {menuEditInputDatas.name.length}/30
            </Typography>
          </Box>
        </Grid>
        {/* //* Photo */}
        <Grid item xs={3}>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              pb: `${menuEditInputDatas.photo.length === 0 ? '16px' : '9px'}`,
            }}
          >
            <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
              Photo
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#9E9D9D',
              }}
            >
              ( max size : 2MB )
            </Typography>
            {menuEditInputDatas.isMaxSizePhoto && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ErrorOutlineIcon
                  sx={{
                    fontSize: '12px',
                    fontWeight: '500',
                    color: theme.palette.warning.main,
                    mr: '4px',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '8px',
                    fontWeight: '500',
                    color: theme.palette.warning.main,
                  }}
                >
                  The photo is too big
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {menuEditInputDatas.photoUrls.length === 0 && (
                <Box
                  sx={{
                    height: '54px',
                    width: '54px',
                    aspectRatio: '1/1',
                    borderRadius: '8px',
                    bgcolor: '#F5F5F5',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderStyle: 'dashed',
                    borderColor: '#9E9D9D',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    document.getElementById('inputProduct_img').click();
                  }}
                >
                  <ImageOutlinedIcon
                    sx={{ color: '#9E9D9D', fontSize: '28px' }}
                  />
                </Box>
              )}
              {menuEditInputDatas.photoUrls.map((input, index) => (
                <Box
                  sx={{
                    position: 'relative',
                    overflow: 'visible',
                    padding: '7px',
                  }}
                  key={index}
                >
                  <CancelIcon
                    sx={{
                      position: 'absolute',
                      fontSize: '20px',
                      top: 1,
                      right: 1,
                      zIndex: +1,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleDeleteImage(input)}
                  />
                  <Box onClick={() => handlePreviewImage(input)}>
                    <img
                      src={
                        process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + input
                      }
                      style={{
                        height: '54px',
                        width: '54px',
                        aspectRatio: '1/1',
                        borderRadius: '8px',
                        cursor: 'pointer',
                      }}
                      alt="pic"
                    />
                  </Box>
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: 'flex',
                mb: '7px',
                ml: '20px',
                alignItems: 'center',
              }}
            >
              {menuEditInputDatas.photoUrls.length < 3 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    document.getElementById('inputProduct_img').click();
                  }}
                >
                  <input
                    accept="image/*"
                    id="inputProduct_img"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleAddImage}
                  />
                  <AddIcon />
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    Add More
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        {/* //* Photo Dialoge */}
        {menuEditInputDatas.linkPreviewPhoto !== '' && (
          <Dialog
            open={menuEditInputDatas.isPreviewPhoto}
            onClose={handleClosePreviewImage}
          >
            <Box
              component="img"
              src={
                process.env.REACT_APP_API_IMAGE_BASE_URL +
                '/' +
                menuEditInputDatas.linkPreviewPhoto
              }
              sx={{ width: '600px', height: '600px', aspectRation: '1/1' }}
            />
          </Dialog>
        )}
        {/* //* Video */}
        <Grid item xs={3}>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              pb: `${
                menuEditInputDatas.videoUrls.length === 0 ? '16px' : '9px'
              }`,
            }}
          >
            <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
              Video
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#9E9D9D',
              }}
            >
              ( max size : 100MB )
            </Typography>
            {menuEditInputDatas.isMaxSizeVideo && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ErrorOutlineIcon
                  sx={{
                    fontSize: '12px',
                    fontWeight: '500',
                    color: theme.palette.warning.main,
                    mr: '4px',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '8px',
                    fontWeight: '500',
                    color: theme.palette.warning.main,
                  }}
                >
                  The video is too big
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {menuEditInputDatas.videoUrls.length === 0 && (
                <Box
                  sx={{
                    height: '54px',
                    width: '54px',
                    aspectRatio: '1/1',
                    borderRadius: '8px',
                    bgcolor: '#F5F5F5',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderStyle: 'dashed',
                    borderColor: '#9E9D9D',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    document.getElementById('inputMenu_video').click();
                  }}
                >
                  <VideocamOutlinedIcon
                    sx={{ color: '#9E9D9D', fontSize: '28px' }}
                  />
                </Box>
              )}
              {menuEditInputDatas.videoUrls.map((input, index) => (
                <Box
                  sx={{
                    position: 'relative',
                    overflow: 'visible',
                    padding: '7px',
                  }}
                  key={index}
                >
                  <CancelIcon
                    sx={{
                      position: 'absolute',
                      fontSize: '20px',
                      top: 1,
                      right: 1,
                      zIndex: +1,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleDeleteVideo(input)}
                  />
                  <Box onClick={() => handlePreviewVideo(input)}>
                    <video
                      style={{
                        maxHeight: '54px',
                        maxWidth: '54px',
                        aspectRatio: '1/1',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        backgroundColor: 'black',
                      }}
                    >
                      <source
                        src={
                          process.env.REACT_APP_API_IMAGE_BASE_URL +
                          '/' +
                          input +
                          '#t=0.5'
                        }
                      />
                    </video>
                  </Box>
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: 'flex',
                mb: '7px',
                ml: '20px',
                alignItems: 'center',
              }}
            >
              {menuEditInputDatas.videoUrls.length < 3 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    document.getElementById('inputMenu_video').click();
                  }}
                >
                  <input
                    // accept="video/*"
                    id="inputMenu_video"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleAddVideo}
                  />
                  <AddIcon />
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    Add More
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        {/* //* Video Dialoge */}
        {menuEditInputDatas.linkPreviewVideo !== '' && (
          <Dialog
            open={menuEditInputDatas.isPreviewVideo}
            onClose={handleClosePreviewVideo}
          >
            {/* <Box
        component="video"
        src={
          process.env.REACT_APP_API_IMAGE_BASE_URL +
          '/' +
          menuEditInputDatas.linkPreviewVideo
        }
        sx={{ width: '600px', height: '600px', aspectRation: '1/1' }}
      /> */}
            <Box sx={{ bgcolor: 'black' }}>
              <video
                style={{
                  width: '600px',
                  height: '600px',
                  backgroundColor: 'black',
                }}
                controls
              >
                <source
                  src={
                    process.env.REACT_APP_API_IMAGE_BASE_URL +
                    '/' +
                    menuEditInputDatas.linkPreviewVideo
                  }
                />
              </video>
            </Box>
          </Dialog>
        )}
        {/* //* pilihan category */}
        <Grid item xs={4}>
          <CustomCard
            sx={{ padding: '16px 14px', cursor: 'pointer' }}
            onClick={handleClickCardCategory}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Box sx={{ fontSize: '16px', fontWeight: '500' }}>
                <Typography>Category*</Typography>
                <Box
                  sx={{
                    color: '#9E9D9D',
                    mt: '4px',
                  }}
                >
                  {menuEditInputDatas.category === '' &&
                  menuEditInputDatas.subCategory === '' ? (
                    <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                      Click to Select category
                    </Typography>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        color: 'black',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '12px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '25%',
                        }}
                      >
                        {menuEditInputDatas.category}
                      </Typography>
                      <ChevronRightIcon sx={{ fontSize: '12px' }} />
                      <Typography
                        sx={{
                          fontSize: '12px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '25%',
                        }}
                      >
                        {menuEditInputDatas.subCategory}
                      </Typography>
                      {menuEditInputDatas.miniSubCategory !== '' && (
                        <>
                          <ChevronRightIcon sx={{ fontSize: '12px' }} />
                          <Typography
                            sx={{
                              fontSize: '12px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '25%',
                            }}
                          >
                            {menuEditInputDatas.miniSubCategory}
                          </Typography>
                        </>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
              <KeyboardArrowDownIcon />
            </Box>
          </CustomCard>
        </Grid>
        {/* //* pilihan ingredient */}
        <Grid item xs={4}>
          <CustomCard
            sx={{ padding: '16px 14px', cursor: 'pointer' }}
            onClick={handleClickCardIngredient}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ fontSize: '16px', fontWeight: '500' }}>
                <Typography>Ingredient*</Typography>
                <Box
                  sx={{
                    color: '#9E9D9D',
                    mt: '4px',
                  }}
                >
                  {menuEditInputDatas.ingredient.length === 0 ? (
                    <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                      Click to Select ingredient
                    </Typography>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        color: 'black',
                        alignItems: 'center',
                        gap: 0.5,
                        padding: '0px',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '12px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {menuEditInputDatas.ingredient[0].product}
                      </Typography>

                      <Typography sx={{ fontSize: '12px' }}>
                        - {menuEditInputDatas.ingredient[0].unitQty}{' '}
                        {menuEditInputDatas.ingredient[0].unitName}
                      </Typography>
                      {menuEditInputDatas.ingredient.length > 1 && (
                        <Typography
                          sx={{
                            fontSize: '11px',
                            color: '#9E9D9D',
                          }}
                        >
                          ( + {menuEditInputDatas.ingredient.length - 1} more )
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
              <KeyboardArrowDownIcon />
            </Box>
          </CustomCard>
        </Grid>
        {/* //* pilihan social media */}
        <Grid item xs={4}>
          <CustomCard
            sx={{ padding: '16px 14px', cursor: 'pointer' }}
            onClick={handleClickCardSocialMedia}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ fontSize: '16px', fontWeight: '500' }}>
                <Typography>Social Media</Typography>
                <Box
                  sx={{
                    color: '#9E9D9D',
                    mt: '4px',
                  }}
                >
                  {menuEditInputDatas.tiktok === '' &&
                  menuEditInputDatas.instagram === '' ? (
                    <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                      Click to fill social media link
                    </Typography>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        color: 'black',
                        alignItems: 'center',
                      }}
                    >
                      <Typography sx={{ fontSize: '12px', mr: '2px' }}>
                        Instagram
                      </Typography>
                      {menuEditInputDatas.instagram !== '' &&
                        menuEditInputDatas.tiktok !== '' && (
                          <Typography sx={{ fontSize: '12px', mr: '4px' }}>
                            ,
                          </Typography>
                        )}
                      {menuEditInputDatas.tiktok !== '' && (
                        <Typography sx={{ fontSize: '12px' }}>
                          {' '}
                          TikTok
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
              <KeyboardArrowDownIcon />
            </Box>
          </CustomCard>
        </Grid>
        {/* //* ------ dialog ------ */}
        {/* //* pilihan category dialog */}
        <Dialog
          maxWidth="md"
          open={menuEditInputDatas.isCategoryOpen}
          onClose={handleClickCardCategory}
        >
          <DialogTitle
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography>{menuEditInputDatas.name} Category</Typography>
            <CloseIcon
              onClick={handleClickCardCategory}
              sx={{ cursor: 'pointer' }}
            />
          </DialogTitle>
          <DialogContent dividers sx={{ minWidth: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                alignItem: 'center',
                gap: 2,
                justifyContent: 'space-between',
              }}
            >
              {/* //? -- Category Choose -- */}
              <Box>
                <Typography
                  sx={{ mb: '12px', fontSize: '16px', fontWeight: '500' }}
                >
                  Category*
                </Typography>
                <Autocomplete
                  sx={{ minWidth: '260px', bgcolor: '#FAFAFA' }}
                  open={selectOpen === 'category' && true}
                  onFocus={() => setSelectOpen('category')}
                  onBlur={() => setSelectOpen('')}
                  loading={dataCategoriesLoading}
                  options={dataCategoriesList}
                  getOptionLabel={(option) => option.namaKategori}
                  value={categoryDataAwaitEdit.categoryChoose}
                  onChange={(event, newValue) => {
                    setCategoryDataAwaitEdit({
                      ...categoryDataAwaitEdit,
                      categoryChoose: newValue,
                      categoryId: newValue.idKategori,
                      category: newValue.namaKategori,
                      subCategoryChoose: null,
                      subCategoryId: 0,
                      subCategory: '',
                      miniSubCategoryChoose: null,
                      miniSubCategoryId: 0,
                      miniSubCategory: '',
                    });
                    setSelectOpen('subCategory');
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Categories" />
                  )}
                />
              </Box>
              {/* //? -- SubCategory Choose -- */}
              <Box>
                <Typography
                  sx={{ mb: '12px', fontSize: '16px', fontWeight: '500' }}
                >
                  Subcategory*
                </Typography>
                <Autocomplete
                  sx={{ minWidth: '260px', bgcolor: '#FAFAFA' }}
                  open={selectOpen === 'subCategory' && true}
                  onFocus={() => setSelectOpen('subCategory')}
                  onBlur={() => setSelectOpen('')}
                  loading={dataSubCategoriesLoading}
                  options={dataSubCategoriesList}
                  getOptionLabel={(option) => option.subkategori}
                  value={categoryDataAwaitEdit.subCategoryChoose}
                  onChange={(event, newValue) => {
                    setCategoryDataAwaitEdit({
                      ...categoryDataAwaitEdit,
                      subCategoryChoose: newValue,
                      subCategoryId: newValue.idSubkategori,
                      subCategory: newValue.subkategori,
                      miniSubCategoryChoose: { name: '' },
                      miniSubCategoryId: 0,
                      miniSubCategory: '',
                    });
                    setSelectOpen('miniCategory');
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Subcategory" />
                  )}
                />
              </Box>
              {/* //? -- MiniCategory Choose -- */}
              <Box>
                <Typography
                  sx={{ mb: '12px', fontSize: '16px', fontWeight: '500' }}
                >
                  Mini
                </Typography>
                <Autocomplete
                  sx={{ minWidth: '260px', bgcolor: '#FAFAFA' }}
                  open={selectOpen === 'miniCategory' && true}
                  onFocus={() => setSelectOpen('miniCategory')}
                  onBlur={() => setSelectOpen('')}
                  loading={dataMiniCategoriesLoading}
                  options={dataMiniCategoriesList}
                  getOptionLabel={(option) => option.name}
                  value={categoryDataAwaitEdit.miniSubCategoryChoose}
                  onChange={(event, newValue) => {
                    setCategoryDataAwaitEdit({
                      ...categoryDataAwaitEdit,
                      miniSubCategoryChoose: newValue,
                      miniSubCategoryId: newValue.id,
                      miniSubCategory: newValue.name,
                    });
                    setSelectOpen('');
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Mini-Category" />
                  )}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'end', mt: '20px' }}>
              <CustomButton
                variant="contained"
                disabled={
                  categoryDataAwaitEdit.categoryChoose === null ||
                  categoryDataAwaitEdit.subCategoryChoose == null
                    ? true
                    : false
                }
                sx={{
                  bgcolor: theme.palette.primary.main,
                  height: '48px',
                  width: '190px',
                }}
                onClick={handleSaveCategoryCard}
              >
                <Typography sx={{ color: 'white' }}>Enter</Typography>
              </CustomButton>
            </Box>
          </DialogContent>
        </Dialog>
        {/* //* pilihan ingredient dialiog */}
        <Dialog
          open={menuEditInputDatas.isIngredientOpen}
          onClose={handleClickCardIngredient}
          maxWidth="md"
        >
          <DialogTitle
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography>{menuEditInputDatas.name} Ingredient</Typography>
            <CloseIcon
              onClick={handleClickCardIngredient}
              sx={{ cursor: 'pointer' }}
            />
          </DialogTitle>
          <DialogContent dividers sx={{ minWidth: '100%' }}>
            <Box>
              {ingredientDataAwaitEdit.map((item, index) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: '8px',
                    gap: 2,
                  }}
                >
                  <Box>
                    <AutoCompleteProduct
                      firstValueId={item.productId}
                      firstValue={item.product}
                      isReset={isDeleteIngredientRow}
                      setIsReset={setIsDeleteIngredientRow}
                      handleValue={(name, id) => {
                        const memo = ingredientDataAwaitEdit.map(
                          (item, idx) => {
                            if (index === idx) {
                              return {
                                ...item,
                                productId: id,
                                product: name,
                              };
                            } else {
                              return item;
                            }
                          }
                        );
                        setIngredientDataAwaitEdit(memo);
                      }}
                    />
                  </Box>
                  <Box>
                    <QtyUnitSelect
                      firstValueQty={item.unitQty}
                      firstValueUnit={item.unitName}
                      isReset={isDeleteIngredientRow}
                      setIsReset={setIsDeleteIngredientRow}
                      handleValue={(qty, unit) => {
                        const memo = ingredientDataAwaitEdit.map(
                          (item, idx) => {
                            if (index === idx) {
                              return {
                                ...item,
                                unitQty: qty,
                                unitName: unit,
                              };
                            } else {
                              return item;
                            }
                          }
                        );
                        setIngredientDataAwaitEdit(memo);
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      bgcolor: '#F33A3A',
                      width: '32px',
                      height: '32px',
                      borderRadius: '8px',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setIngredientDataAwaitEdit(
                        ingredientDataAwaitEdit.filter(
                          (_, ind) => ind !== index
                        )
                      );
                      setIsDeleteIngredientRow(true);
                    }}
                  >
                    <DeleteIcon sx={{ color: 'white', fontSize: '16px' }} />
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                mt: '20px',
                alignItems: 'center',
                gap: 3,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={handleAddItemCardIngredient}
              >
                <AddIcon color="primary" />
                <Typography color="primary">Add More</Typography>
              </Box>
              <CustomButton
                variant="contained"
                sx={{
                  bgcolor: theme.palette.primary.main,
                  height: '48px',
                  width: '190px',
                }}
                disabled={
                  ingredientDataAwaitEdit.filter(
                    (item) =>
                      item.product === '' ||
                      item.unitQty === 0 ||
                      item.unitName === ''
                  ).length !== 0
                }
                onClick={handleSaveCardIngredient}
              >
                <Typography sx={{ color: 'white' }}>Enter</Typography>
              </CustomButton>
            </Box>
          </DialogContent>
        </Dialog>
        {/* //* pilihan social media dialog */}
        <Dialog
          open={menuEditInputDatas.isSocialMediaOpen}
          onClose={handleClickCardSocialMedia}
          maxWidth="md"
        >
          <DialogTitle
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography>{menuEditInputDatas.name} Social Media</Typography>
            <CloseIcon
              onClick={handleClickCardSocialMedia}
              sx={{ cursor: 'pointer' }}
            />
          </DialogTitle>
          <DialogContent dividers sx={{ minWidth: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CustomCard
                sx={{
                  width: '54px',
                  height: '54px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <InstagramLogo />
              </CustomCard>
              <CustomInput
                placeholder="https://www.instagram.com/"
                sx={{ bgcolor: '#FAFAFA', minWidth: '340px' }}
                value={socialMediaDataAwaitEdit.instagram}
                onChange={(e) =>
                  setSocialMediaDataAwaitEdit({
                    ...socialMediaDataAwaitEdit,
                    instagram: e.target.value,
                  })
                }
              />
            </Box>
            <Box
              sx={{ mt: '20px', display: 'flex', alignItems: 'center', gap: 1 }}
            >
              <CustomCard
                sx={{
                  width: '54px',
                  height: '54px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <TiktokLogo />
              </CustomCard>
              <CustomInput
                placeholder="https://www.tiktok.com/"
                sx={{ bgcolor: '#FAFAFA', minWidth: '340px' }}
                value={socialMediaDataAwaitEdit.tiktok}
                onChange={(e) =>
                  setSocialMediaDataAwaitEdit({
                    ...socialMediaDataAwaitEdit,
                    tiktok: e.target.value,
                  })
                }
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                mt: '20px',
                alignItems: 'center',
              }}
            >
              <CustomButton
                variant="contained"
                sx={{
                  bgcolor: theme.palette.primary.main,
                  height: '48px',
                  width: '190px',
                }}
                onClick={handleSaveCardSocialMedia}
              >
                <Typography sx={{ color: 'white' }}>Enter</Typography>
              </CustomButton>
            </Box>
          </DialogContent>
        </Dialog>
        {/* //* Meta description*/}
        <Grid item xs={6}>
          <Typography sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}>
            Meta Description
          </Typography>
          <CustomInput
            sx={{ width: '100%', bgcolor: '#FAFAFA' }}
            placeholder="Enter meta description"
            value={menuEditInputDatas.metaDescription}
            onChange={(e) => {
              if (e.target.value.length <= 250) {
                setMenuEditInputDatas({
                  ...menuEditInputDatas,
                  metaDescription: e.target.value,
                });
              }
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '400',
                color: '#9E9D9D',
                mt: '4px',
              }}
            >
              Enter at least 20 character
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '400',
                color: '#9E9D9D',
                mt: '4px',
              }}
            >
              {menuEditInputDatas.metaDescription.length}/250
            </Typography>
          </Box>
        </Grid>
        {/* //* Schedule*/}
        <Grid item xs={6}>
          <Grid container>
            <Grid item alignItems="center" sx={{ height: '100%', mr: '20px' }}>
              <Typography
                sx={{
                  mb: '16px',
                  fontSize: '16px',
                  fontWeight: '500',
                }}
              >
                Schedule
              </Typography>
            </Grid>
            <Grid item alignItems="center" sx={{ height: '100%' }}>
              <RadioGroup
                row
                value={menuEditInputDatas.scheduleType}
                onChange={(e) => {
                  setMenuEditInputDatas({
                    ...menuEditInputDatas,
                    schedule: today,
                    scheduleType: e.target.value,
                  });
                }}
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: '16px',
                  },
                  '.MuiRadio-root': {
                    padding: '0 8px',
                  },
                  '.MuiTypography-root': {
                    fontSize: '12px',
                  },
                }}
              >
                <FormControlLabel value="now" control={<Radio />} label="Now" />
                <FormControlLabel
                  value="custom"
                  control={<Radio />}
                  label="Custom"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', minWidth: '100%' }}>
            <DesktopDatePicker
              inputFormat="DD/MM/YYYY"
              value={menuEditInputDatas.schedule}
              onChange={(newValue) => {
                if (menuEditInputDatas.scheduleType !== 'now') {
                  setMenuEditInputDatas({
                    ...menuEditInputDatas,
                    schedule: newValue,
                  });
                } else {
                  setMenuEditInputDatas({
                    ...menuEditInputDatas,
                    schedule: today,
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: '100%', bgcolor: '#FAFAFA' }}
                />
              )}
              disabled={
                menuEditInputDatas.scheduleType === 'now' ? true : false
              }
            />
          </Box>
        </Grid>
        {/* //* Description*/}
        <Grid item xs={12}>
          <Typography sx={{ mb: '16px', fontSize: '16px', fontWeight: '500' }}>
            Description*
          </Typography>
          <CustomTextFormatingInput
            setEditorContent={setDescriptionProduct}
            isError={false}
            helperText=""
            firstValue={menuEditInputDatas.description}
            setIsMinInputText={setIsMinDescriptionProduct}
            desktopMode={desktopMode}
          />
        </Grid>
        {/* //* Button */}
        <Box
          sx={{
            width: '100%',
            bgcolor: 'white',
            position: 'fixed',
            bottom: '0px',
            right: '0px',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '18px 32px 18px 240px',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
              Publish
            </Typography>
            <AntSwitch
              checked={menuEditInputDatas.publish}
              onChange={(e) => {
                setMenuEditInputDatas({
                  ...menuEditInputDatas,
                  publish: !menuEditInputDatas.publish,
                });
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 0.5 }}>
            <CustomButton
              // disabled={!isSubmitAvailable}
              onClick={handleDelete}
              variant="contained"
              color="error"
              sx={{
                width: '140px',
                height: '36px',
              }}
            >
              Delete
            </CustomButton>
            <CustomButton
              disabled={!isSubmitAvailable}
              onClick={handleEdit}
              variant="contained"
              sx={{
                width: '140px',
                height: '36px',
              }}
            >
              Save
            </CustomButton>
          </Box>
        </Box>
      </Grid>
    </>
  );
};
export default MenuMenuEdit;
