import {
  Box,
  Checkbox,
  InputAdornment,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { ReactComponent as Sales } from '../../../../../../assets/sales.svg';
import { ReactComponent as Order } from '../../../../../../assets/order.svg';
import { ReactComponent as Cogs } from '../../../../../../assets/cogs.svg';
import { ReactComponent as Used } from '../../../../../../assets/used2.svg';
import SearchIcon from '@mui/icons-material/Search';

const CustomTableCellHead = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '12 px',
  textTransform: 'capitalize',

  padding: '8px 0px',
});
const CustomTableCellBody = styled(TableCell)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '12px',
  height: '10px',
  textTransform: 'capitalize',

  padding: '0px',
});

const DataExample = [
  { name: 'product 1', rp: 100, sold: 35, order: '14', used: '8' },
  { name: 'product 2', rp: 200, sold: 42, order: '14', used: '8' },
  { name: 'product 3', rp: 300, sold: 45, order: '14', used: '8' },
  { name: 'product 4', rp: 400, sold: 16, order: '14', used: '8' },
  { name: 'product 5', rp: 500, sold: null, order: '14', used: '8' },
  // { name: 'product 6', rp: 600, sold: 150, order: '14', used: '8' },
  // { name: 'product 7', rp: 700, sold: 44, order: '14', used: '8' },
  // { name: 'product 8', rp: 800, sold: 36, order: '14', used: '8' },
  // { name: 'product 9', rp: 900, sold: 65, order: '14', used: '8' },
];

const TablePerfomanceStock = () => {
  return (
    <Box sx={{ py: '16px', pr: '24px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <TextField
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ ml: '12px' }} />
              </InputAdornment>
            ),
          }}
          size="small"
          sx={{ bgcolor: '#FAFAFA', mb: 1 }}
        />
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <CustomTableCellHead></CustomTableCellHead>
            <CustomTableCellHead>Name</CustomTableCellHead>
            <CustomTableCellHead>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Cogs style={{ width: '16px', fill: '#404040' }} />
                <Typography sx={{ fontSize: '12px' }}>(Rp*)</Typography>
                <SyncAltIcon
                  sx={{
                    transform: 'rotate(90deg)',
                    fill: '#9E9D9D',
                    fontSize: '12px',
                  }}
                />
              </Box>
            </CustomTableCellHead>
            <CustomTableCellHead>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Sales style={{ width: '16px', fill: '#51B15C' }} />
                <Typography sx={{ fontSize: '12px' }}>(Rp*)</Typography>
                <SyncAltIcon
                  sx={{
                    transform: 'rotate(90deg)',
                    fill: '#9E9D9D',
                    fontSize: '12px',
                  }}
                />
              </Box>
            </CustomTableCellHead>
            <CustomTableCellHead>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Order style={{ width: '16px', fill: '#FEDD25' }} />
                <SyncAltIcon
                  sx={{
                    transform: 'rotate(90deg)',
                    fill: '#9E9D9D',
                    fontSize: '12px',
                  }}
                />
              </Box>
            </CustomTableCellHead>
            <CustomTableCellHead>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Used style={{ width: '16px', fill: '#F239A8' }} />
                <SyncAltIcon
                  sx={{
                    transform: 'rotate(90deg)',
                    fill: '#9E9D9D',
                    fontSize: '12px',
                  }}
                />
              </Box>
            </CustomTableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {DataExample.map((item, index) => (
            <TableRow key={index}>
              <CustomTableCellBody>
                <Checkbox
                  // defaultChecked
                  sx={{
                    '&:before': {
                      display: 'block',
                      width: 16,
                      height: 16,
                    },
                  }}
                />
              </CustomTableCellBody>
              <CustomTableCellBody>{item?.name}</CustomTableCellBody>
              <CustomTableCellBody align="right">
                {item?.rp}
              </CustomTableCellBody>
              <CustomTableCellBody align="right">
                {item?.sold}
              </CustomTableCellBody>
              <CustomTableCellBody align="right">
                {item?.order}
              </CustomTableCellBody>
              <CustomTableCellBody align="right">
                {item?.used}
              </CustomTableCellBody>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
export default TablePerfomanceStock;
