import { createSlice } from '@reduxjs/toolkit';
import { getDetailTransactionRequest } from '../../../api/transaction';

export const detailTransactionRequestState = createSlice({
  name: 'detailTransactionRequest',
  initialState: {
    idTransactionRequest: null,
    isLoading: false,
    dataTransaction: null,
    isEditTransaction: false,
    userEmploye: 'tujuan',
    statusCode: null,
  },
  reducers: {
    changeIdTransactionRequest: (state, action) => {
      state.idTransactionRequest = action.payload;
    },
    changeIsloading: (state, action) => {
      state.isLoading = action.payload;
    },
    loadDataTransaction: (state, action) => {
      state.dataTransaction = action.payload;
    },
    changeIsEditTransaction: (state, action) => {
      state.isEditTransaction = action.payload;
    },
    changeUserEmployee: (state, action) => {
      state.userEmploye = action.payload;
    },
    changeStatusCode: (state, action) => {
      state.statusCode = action.payload;
    },
    clearDetailTransaction: (state) => {
      state.idTransactionRequest = null;
      state.isLoading = false;
      state.dataTransaction = null;
      state.isEditTransaction = false;
      state.userEmploye = 'tujuan';
      state.statusCode = null;
    },
  },
});

export const {
  changeIdTransactionRequest,
  changeIsloading,
  loadDataTransaction,
  changeIsEditTransaction,
  changeUserEmployee,
  changeStatusCode,
  clearDetailTransaction,
} = detailTransactionRequestState.actions;

export const getIdDetailTransactionRequest = (state) =>
  state.detailTransactionRequest.idTransactionRequest;
export const getIsLoadingTransactionDetail = (state) =>
  state.detailTransactionRequest.isLoading;
export const getAllDataDetailTransaction = (state) =>
  state.detailTransactionRequest.dataTransaction;
export const getisEditTransaction = (state) =>
  state.detailTransactionRequest.isEditTransaction;
export const getUserEmployeeStatus = (state) =>
  state.detailTransactionRequest.userEmploye;
export const getStatusCodeTransactionDetail = (state) =>
  state.detailTransactionRequest.statusCode;

// ! --------------------- API ---------------------
export const getSpesifikTransactionRequest = (id) => (dispatch) => {
  const fetchDataDetail = async () => {
    dispatch(changeIsloading(true));

    try {
      const {
        data: { data },
      } = await getDetailTransactionRequest(id);
      dispatch(loadDataTransaction(data));
      dispatch(changeIsloading(false));
    } catch (err) {
      console.error(err);
      dispatch(loadDataTransaction(null));
      dispatch(changeStatusCode(err.response.status));
    } finally {
      dispatch(changeIsloading(false));
    }
  };
  fetchDataDetail();
};

export default detailTransactionRequestState.reducer;
