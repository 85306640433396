import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MobileStepper,
  Step,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import BackButtonHub from "../../../../../../../../components/shared/BackButtonHub";
import {
  ChevronRightIcon,
  CloseIcon,
  DeleteIcon,
  KeyboardArrowDownIcon,
  RackIcon,
} from "../../../../../../../../assets/Icons";
import { useDispatch, useSelector } from "react-redux";
import { changePageSubHub } from "../../../../../../../../store/Routing/hubs";
import CustomCard from "../../../../../../../../components/shared/CustomCard";
import { Map, Marker, ZoomControl } from "pigeon-maps";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CustomAntSwitch from "../../../../../../../../components/shared/CustomAntSwitch";
import CustomButton from "../../../../../../../../components/shared/CustomButton";
import {
  fetchDetailDataMiniHub,
  getDetailDataMiniHub,
  getIdMiniHub,
  getLoadingDetailMiniHub,
  fetchMiniHubRacksPreview,
  getRacksPositionPreviewMiniHub,
  getRacksPreviewMiniHub,
} from "../../../../../../../../store/hub/miniHub/miniHubDetails";
import { useState } from "react";
import {
  deleteMiniHub,
  editStatusMiniHub,
} from "../../../../../../../../api/hubs";

import {
  changeIsEdit,
  getIsMiniHubEdit,
  goToMiniHubEdit,
} from "../../../../../../../../store/hub/miniHub/miniHubEdit";
import EditMiniHubMobile from "../Edit/EditMiniHubMobile";
import CustomDialog from "../../../../../../../../components/shared/CustomDialog";

import RackMapPreviewMiniHubMobile from "./RackMapPreview";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { getAllDataMiniHub } from "../../../../../../../../store/hub/miniHub/miniHubData";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const DetailMiniHub = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const id = useSelector(getIdMiniHub);
  const data = useSelector(getDetailDataMiniHub);

  const isLoading = useSelector(getLoadingDetailMiniHub);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const isEdit = useSelector(getIsMiniHubEdit);
  const [activeStep, setActiveStep] = useState(0);
  const racksData = useSelector(getRacksPreviewMiniHub);

  const [isPreviewAddressMap, setIsPreviewAddressMap] = useState(false);
  const [isPreviewRackMap, setIsPreviewRackMap] = useState(false);

  const rackSelected = useSelector(getRacksPositionPreviewMiniHub);
  const { masterHubName, subHubName } = useSelector(getAllDataMiniHub);

  const [addressMapZoom, setAddressMapZoom] = useState(17);
  const [addressMapCenter, setAddressMapCenter] = useState([
    3.5465944896879704, 98.68976198831375,
  ]);

  const [statusHub, setStatusHub] = useState(false);

  const [image, setImage] = useState([]);
  const maxSteps = image?.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  useEffect(() => {
    const image = data?.images?.split(",");
    setImage(image);
  }, [data]);

  //   hendleDetailMiniHub
  const hendleDetailMiniHub = () => {
    dispatch(changePageSubHub("MiniHub"));
  };

  // Heandler
  const handleStatus = () => {
    if (statusHub) {
      editStatusMiniHub(data.idToko, "pending");
      setStatusHub(false);
    } else {
      editStatusMiniHub(data.idToko, "aktif");
      setStatusHub(true);
    }
  };

  // heandle Delete
  const heandleDelete = async () => {
    setIsLoadingDelete(true);
    try {
      await deleteMiniHub(id);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  useEffect(() => {
    dispatch(fetchDetailDataMiniHub(id));
  }, [id]);

  useEffect(() => {
    dispatch(fetchDetailDataMiniHub(id));
    dispatch(fetchMiniHubRacksPreview(id));
  }, [dispatch, id]);

  const handleToEdit = () => {
    dispatch(
      goToMiniHubEdit({
        // idMasterHub: data?.idToko,
        idMiniHub: data?.id,
        name: data?.name,
        col: data?.mapColumn,
        row: data?.mapRow,
        rackDetails: racksData,
        rackSelected: rackSelected,
        idSubHub: data?.subHubId,
        pic: data?.pic,
        picPhone: data?.pic?.telpon,
        status: statusHub,
        entrance: data?.entrancePosition
          ? data?.entrancePosition.split(",")
          : [],
        subsidiary: data?.subHub?.store?.subsidiary,
        subsidiaryId: data?.subHub?.store?.subsidiaryId,
        image: data?.images,
        video: data?.videos,
        address: data?.address,
        coordinate: [Number(data?.mapCor?.lat), Number(data?.mapCor?.long)],
        typeMap:
          data?.mapCor?.typeInput === null ? data?.mapCor?.typeInput : "manual",
        citydistrict: data?.mapCor?.cityDistrict,
      })
    );
  };

  return isEdit ? (
    <EditMiniHubMobile />
  ) : (
    <Box>
      <Grid container columns={12} spacing={2}>
        {/* back button */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
              mb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
              <BackButtonHub onClick={hendleDetailMiniHub} />
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 600, color: "#000" }}
                >
                  {data?.name || "-"} Details
                </Typography>

                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{ fontSize: "10px", fontWeight: 600, color: "#9E9D9D" }}
                  >
                    {data?.subHub?.store?.subsidiary?.name}
                  </Typography>
                  <ChevronRightIcon
                    sx={{ fontSize: "10px", color: "#9E9D9D" }}
                  />
                  <Typography
                    sx={{ fontSize: "10px", fontWeight: 600, color: "#9E9D9D" }}
                    onClick={() => dispatch(changePageSubHub(""))}
                  >
                    {masterHubName}
                  </Typography>
                  <ChevronRightIcon
                    sx={{ fontSize: "10px", color: "#9E9D9D" }}
                  />

                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: 600,
                      color: "#9E9D9D",
                    }}
                    onClick={() => dispatch(changePageSubHub("subHub"))}
                  >
                    {subHubName}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Typography
                sx={{ fontSize: "12px", fontWeight: 600, color: "#51B15C" }}
              >
                Hub Racks
              </Typography>
              <ChevronRightIcon sx={{ color: "#51B15C", fontSize: "12" }} />
            </Box>
          </Box>
        </Grid>

        {/*  imageslide */}
        <Grid item xs={12}>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {image?.map((step, index) => (
              <Box key={index}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box
                    component="img"
                    sx={{
                      height: 156,
                      display: "block",
                      overflow: "hidden",
                      width: "100%",
                    }}
                    src={process.env.REACT_APP_API_IMAGE_BASE_URL + "/" + step}
                    alt={step}
                  />
                ) : null}
              </Box>
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          />
        </Grid>

        {/* Name */}
        <Grid item xs={12}>
          <Box
            sx={{
              background: "#FAFAFA",
              borderRadius: "8px 8px 0px 0px",
              padding: "16px 12px 14px 14px",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 500,
                marginBottom: "8px",
                color: "#9E9E9E",
              }}
            >
              Name
            </Typography>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500, color: "#000" }}
            >
              {data?.name || "_"}
            </Typography>
          </Box>
        </Grid>

        {/* PIC  */}
        <Grid item xs={12}>
          <Box
            sx={{
              background: "#FAFAFA",
              borderRadius: "8px 8px 0px 0px",
              padding: "16px 12px 14px 14px",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 500,
                marginBottom: "8px",
                color: "#9E9E9E",
              }}
            >
              PIC
            </Typography>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500, color: "#000" }}
            >
              {data?.pic?.namaLengkap || "_"}
            </Typography>
          </Box>
        </Grid>

        {/* Phone Number  */}
        <Grid item xs={12}>
          <Box
            sx={{
              background: "#FAFAFA",
              borderRadius: "8px 8px 0px 0px",
              padding: "16px 12px 14px 14px",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 500,
                marginBottom: "8px",
                color: "#9E9E9E",
              }}
            >
              Phone Number
            </Typography>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500, color: "#000" }}
            >
              {data?.pic?.telpon || "_"}
            </Typography>
          </Box>
        </Grid>

        {/* address */}
        <Grid item xs={12}>
          <CustomCard
            sx={{ p: "8px 16px", cursor: "pointer" }}
            onClick={() => setIsPreviewAddressMap(true)}
          >
            <Box
              sx={{
                display: "center",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box
                  sx={{
                    width: "59px",
                    height: "59px",
                    bgcolor: "gray",
                    borderRadius: "8px",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      bgcolor: "white",
                      width: "100%",
                      height: "100%",
                      zIndex: "+2",
                      opacity: 0.5,
                    }}
                  ></Box>
                  <Box
                    sx={{
                      maxHeight: "59px",
                      overflow: "hidden",
                      borderRadius: "8px",
                    }}
                  >
                    {data?.mapCor && (
                      <Map
                        height={130}
                        defaultZoom={17}
                        width={66}
                        style={{
                          borderRadius: "8px",
                        }}
                      ></Map>
                    )}
                  </Box>
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: "500", mb: "4px", ml: "8px" }}>
                    Address
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.4,
                    }}
                  >
                    <LocationOnIcon color="primary" />
                    <Typography>
                      {data?.address ? data?.address : "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <KeyboardArrowDownIcon />
            </Box>
          </CustomCard>
        </Grid>

        {/* //* Dialog Addres Map */}
        <Dialog
          maxWidth="sm"
          open={isPreviewAddressMap}
          onClose={() => setIsPreviewAddressMap(!isPreviewAddressMap)}
        >
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography>Address</Typography>
              <CloseIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setIsPreviewAddressMap(!isPreviewAddressMap)}
              />
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Map
              height={300}
              width={250}
              center={addressMapCenter}
              zoom={addressMapZoom}
              onBoundsChanged={({ center, zoom }) => {
                setAddressMapCenter(center);
                setAddressMapZoom(zoom);
              }}
            >
              <ZoomControl />
              <Marker
                anchor={[Number(data?.mapCor?.lat), Number(data?.mapCor?.long)]}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {addressMapZoom >= 17 && (
                    <Typography
                      color="error"
                      sx={{ fontSize: "12px", textAlign: "center" }}
                    ></Typography>
                  )}
                </Box>
              </Marker>
            </Map>
            <Typography sx={{ mt: "12px" }}>{data?.alamat}</Typography>
          </DialogContent>
        </Dialog>

        {/*  Rack  */}
        <Grid item xs={12}>
          <CustomCard
            sx={{ p: "8px 16px", cursor: "pointer" }}
            onClick={() => setIsPreviewRackMap(!isPreviewRackMap)}
          >
            <Box
              sx={{
                display: "center",
                alignItems: "center",
                justifyContent: "space-between",
                height: "59px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <RackIcon />
                <Box>
                  <Typography sx={{ fontWeight: "500", mb: "4px" }}>
                    Racks Map
                  </Typography>
                  <Typography sx={{ color: "#9E9D9D", fontSize: "12px" }}>
                    {racksData?.length} Racks
                  </Typography>
                </Box>
              </Box>
              <KeyboardArrowDownIcon />
            </Box>
          </CustomCard>
        </Grid>

        {/*  Dialog Rack Map */}
        <CustomDialog
          fullWidth
          isOpen={isPreviewRackMap}
          setIsOpen={setIsPreviewRackMap}
        >
          <RackMapPreviewMiniHubMobile
            col={data?.mapColumn}
            row={data?.mapRow}
            entrance={data?.entrancePosition}
            setIsOpen={setIsPreviewRackMap}
          />
        </CustomDialog>

        {/* Tomol Switch */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              justifyContent: "space-between",
              mb: "12px",
            }}
          >
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500, color: "#000" }}
            >
              Publish
            </Typography>
            <CustomAntSwitch
              checked={statusHub}
              onChange={(e) => handleStatus(e)}
            />
          </Box>
        </Grid>

        {/* Button edit delete */}
        <Grid item xs={12}>
          <Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <CustomButton
                variant="contained"
                color="error"
                sx={{ width: "100%", height: "36px" }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                  onClick={() => setIsDelete(true)}
                >
                  Delete
                </Typography>
              </CustomButton>
              <CustomButton
                variant="contained"
                sx={{ width: "100%", height: "36px" }}
                onClick={handleToEdit}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  Edit
                </Typography>
              </CustomButton>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Dialog Delete */}
      <Dialog fullWidth open={isDelete} onClose={() => setIsDelete(!isDelete)}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box
              sx={{
                width: "44px",
                height: "40px",
                background: "rgba(243, 58, 58, 0.1)",
                borderRadius: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DeleteIcon sx={{ color: "#F33A3A" }} />
            </Box>
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, color: "#000" }}
            >
              Delete Hub
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography
              sx={{ fontWeight: 400, color: "#000", fontSize: "14px" }}
            >
              are you sure you want to delete
            </Typography>
            <Typography
              sx={{ fontWeight: 600, color: "#000", fontSize: "14px" }}
            >
              " {data?.idToko} "
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 2,
              mt: 5,
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ color: "#9E9D9D", fontSize: "14px", fontWeight: 600 }}
              onClick={() => setIsDelete(!isDelete)}
            >
              Cancel
            </Typography>

            <CustomButton
              variant="contained"
              color="error"
              sx={{ width: "100px", height: "36px", borderRadius: "8px" }}
              onClick={heandleDelete}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                {isLoadingDelete ? (
                  <CircularProgress size={20} sx={{ color: "#FFF" }} />
                ) : (
                  "Delete"
                )}
              </Typography>
            </CustomButton>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DetailMiniHub;
