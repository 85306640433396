import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { IconHistory } from "../../../../../../../assets";
import AccordionStockout from "../../Component/AccordionStockout";
import CustomButton from "../../../../../../../components/shared/CustomButton";
import { changePageStockOut } from "../../../../../../../store/Routing/stockOutRouting";
import { useDispatch, useSelector } from "react-redux";
import {
  changeStockout,
  getSearchStockout,
} from "../../../../../../../store/stockout/stockout";
import { useEffect } from "react";
import { setTotalUnscanned } from "../../../../../../../store/stockout/totalUnscanned";
import { ChecklistIcon, CloseIcon } from "../../../../../../../assets/Icons";
import { executeInvoice } from "../../../../../../../api/stockout";
import HubInvoice from "./Hub";

/**
 * Component to display search results for found items.
 *
 * @returns {React.Element} SearchFound component.
 */

const SearchFound = ({ invoice, search, setSearch }) => {
  const dispatch = useDispatch();
  const [execute, setExecute] = useState([]);
  const [totalSelected, setTotalSelected] = useState(null);
  const [openConfirmn, setOpenConfirmn] = useState(false);

  const [openHub, setOpenHub] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  // --- handle detail ---
  const handleDetail = () => {
    dispatch(changePageStockOut("detailHistory"));
  };

  useEffect(() => {
    if (execute?.length === 0) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [execute]);

  // --- handle execute ---
  // const handleExecute = async () => {
  //   const params = {
  //     id_order: [4],
  //     hub_id: 2,
  //     hub_type: "master_hub",
  //   };
  //   try {
  //     const data = await executeInvoice(1, params);
  //     setOpenConfirmn(false);
  //     dispatch(changePageStockOut(""));
  //   } catch (err) {
  //     console.error(err);
  //   }
  //   // dispatch(changePageStockOut("execute"));
  //   dispatch(changeStockout(execute));
  // };

  useEffect(() => {
    let totalProduk = 0;

    execute.forEach((item) => {
      totalProduk += item?.data?.produk?.length;
    });

    setTotalSelected(totalProduk);
    dispatch(setTotalUnscanned(totalProduk));
  }, [execute, dispatch]);

  return (
    <Box p={"20px"}>
      {openHub ? (
        <HubInvoice {...{ openHub, setOpenHub, execute, setExecute }} />
      ) : (
        <>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              fontStyle={"normal"}
              fontWeight={600}
              fontSize={"14px"}
              lineHeight={"21px"}
              color={"#000"}
            >
              INV-{search}
            </Typography>
            <Box
              onClick={() => handleDetail()}
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            >
              <IconHistory />
              <Typography
                fontStyle={"normal"}
                fontWeight={500}
                fontSize={"12px"}
                lineHeight={"150%"}
                color={"#51b15c"}
                ml={"5px"}
              >
                View History
              </Typography>
            </Box>
          </Box>

          <AccordionStockout
            invoice={invoice}
            execute={execute}
            search={search}
            setSearch={setSearch}
            setExecute={setExecute}
          />

          <CustomButton
            variant={"contained"}
            onClick={() =>
              // dispatch(changePageStockOut("execute"));
              setOpenHub(true)
            }
            style={{ marginTop: "25px", height: "40px" }}
            disabled={disableSubmit}
            fullWidth
          >
            <Typography
              fontStyle={"normal"}
              fontWeight={600}
              fontSize={"14px"}
              lineHeight={"150%"}
              color={"#fff"}
            >
              Execute
            </Typography>
          </CustomButton>
        </>
      )}

      {/* dialogn conjfirm */}
      {/* <Dialog
        open={openConfirmn}
        onClose={() => setOpenConfirmn(!openConfirmn)}
      >
        <DialogTitle sx={{ boxShadow: "0px 0px 3px 0px #000" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "100px",
                  background: "rgba(81, 177, 92, 0.1)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ChecklistIcon />
              </Box>
              <Typography>Confirm Execution</Typography>
            </Box>
            <CloseIcon onClick={() => setOpenConfirmn(!openConfirmn)} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontSize: "12px", mt: 3 }}>
            Are you sure you want to execute the item (s) ?
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: 2,
              mt: 5,
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
              }}
              onClick={() => setOpenConfirmn(false)}
            >
              Cancel
            </Typography>
            <CustomButton
              variant={"contained"}
              onClick={handleExecute}
              style={{ height: "40px" }}
            >
              <Typography
                fontStyle={"normal"}
                fontWeight={500}
                fontSize={"14px"}
                lineHeight={"150%"}
                color={"#fff"}
              >
                Confirm
              </Typography>
            </CustomButton>
          </Box>
        </DialogContent>
      </Dialog> */}
    </Box>
  );
};

export default SearchFound;
