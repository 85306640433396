import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSubsidiaryList,
  getSubsidiaryList,
} from '../../../../../../../store/subsidiary/subsidiary';

import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import CustomButton from '../../../../../../../components/shared/CustomButton';
import { getAllHubs } from '../../../../../../../api/hubs';
import ClearIcon from '@mui/icons-material/Clear';
import {
  changeHubChoose,
  changeHubSearch,
  changeHubSearchDebounce,
  changeHubs,
  changeMasterHubId,
  changeMiniHubChoose,
  changeMiniHubId,
  changeMiniHubSearch,
  changeMiniHubSearchDebounce,
  changeSubHubChoose,
  changeSubHubId,
  changeSubHubSearch,
  changeSubHubSearchDebounce,
  getDataMasterHubPurchase,
  getDataMiniHubPurchase,
  getDataSubHubPurchase,
  getHubChoosePurchase,
  getHubLoading,
  getHubSearchDebouncePurchase,
  getHubSearchPurchase,
  getMasterHubId,
  getMasterHubPurchase,
  getMiniHubChoosePurchase,
  getMiniHubId,
  getMiniHubPurchase,
  getMiniHubSearchDebouncePurchase,
  getMiniHubSearchPurchase,
  getSubHubChoosePurchase,
  getSubHubId,
  getSubHubPurchase,
  getSubHubSearchDebouncePurchase,
  getSubHubSearchPurchase,
  getSubsidiaryIdEditPurchase,
} from '../../../../../../../store/purchase/purchaseAdmin/editPurchaseRequest';

const SelectHubEditPurchase = ({ setIsSelectHub }) => {
  const dispatch = useDispatch();
  const subsidiaryId = useSelector(getSubsidiaryIdEditPurchase);
  const hubLoading = useSelector(getHubLoading);

  const hubChoose = useSelector(getHubChoosePurchase);
  const hubSearch = useSelector(getHubSearchPurchase);
  const hubSearchDebounce = useSelector(getHubSearchDebouncePurchase);

  const subHubChoose = useSelector(getSubHubChoosePurchase);
  const subHubSearch = useSelector(getSubHubSearchPurchase);
  const subHubSearchDebounce = useSelector(getSubHubSearchDebouncePurchase);

  const miniHubChoose = useSelector(getMiniHubChoosePurchase);
  const miniHubSearch = useSelector(getMiniHubSearchPurchase);
  const miniHubSearchDebounce = useSelector(getMiniHubSearchDebouncePurchase);

  // hub
  const [selectOpen, setSelectOpen] = useState('hubs');
  const masterHubData = useSelector(getMasterHubPurchase);
  const suhHubData = useSelector(getSubHubPurchase);
  const miniHubData = useSelector(getMiniHubPurchase);
  const idToko = useSelector(getMasterHubId);
  const idSubHub = useSelector(getSubHubId);

  useEffect(() => {
    dispatch(getDataMasterHubPurchase(subsidiaryId, hubSearchDebounce));
    dispatch(getDataSubHubPurchase(idToko));
    dispatch(getDataMiniHubPurchase(idSubHub));
  }, [subsidiaryId, hubSearchDebounce, idToko, idSubHub]);

  const hubSearchInDebounce = useRef(
    debounce((value) => {
      dispatch(changeHubSearchDebounce(value));
    }, 1000)
  ).current;

  const subHubSearchInDebounce = useRef(
    debounce((value) => {
      dispatch(changeSubHubSearchDebounce(value));
    }, 1000)
  ).current;

  const miniHubSearchInDebounce = useRef(
    debounce((value) => {
      dispatch(changeMiniHubSearchDebounce(value));
    }, 1000)
  ).current;

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleSubmit = () => {
    dispatch(changeHubs([hubChoose, subHubChoose, miniHubChoose]));

    miniHubChoose === null && dispatch(changeMiniHubId(null));

    setIsSelectHub(false);
  };

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column' }}>
      <Grid container columns={12} spacing={4}>
        {/* master hub */}
        <Grid item xs={4}>
          <Autocomplete
            sx={{ width: '100%' }}
            open={selectOpen === 'hubs' && true}
            onFocus={() => setSelectOpen('hubs')}
            onBlur={() => setSelectOpen('')}
            options={masterHubData}
            value={hubChoose}
            inputValue={hubSearch}
            getOptionLabel={(option) => option.namaToko}
            loading={hubLoading}
            onChange={(_, newValue) => {
              dispatch(changeHubChoose(newValue));
              dispatch(changeMasterHubId(newValue.idToko));
              setSelectOpen('subHubs');
            }}
            onInputChange={(_, newInputValue) => {
              dispatch(changeHubSearch(newInputValue));
              hubSearchInDebounce(newInputValue);
            }}
            renderOption={(props, option) => (
              <li key={option.idToko} {...props}>
                {option.namaToko}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="master hubs"
                sx={{ bgcolor: '#fafafa', borderRadius: '5px' }}
              />
            )}
            // clearIcon={
            //   <ClearIcon
            //     fontSize="small"
            //     onClick={() => {
            //       dispatch(changeMasterHubId(0));
            //       dispatch(getSubHubPurchase([]));
            //       dispatch(changeSubHubChoose(null));
            //       dispatch(changeSubHubSearch(''));
            //     }}
            //   />
            // }
          />
        </Grid>

        {/* sub hub */}
        <Grid item xs={4}>
          <Autocomplete
            sx={{ width: '100%' }}
            open={selectOpen === 'subHubs' && true}
            onFocus={() => setSelectOpen('subHubs')}
            onBlur={() => setSelectOpen('')}
            options={suhHubData}
            value={subHubChoose}
            inputValue={subHubSearch}
            getOptionLabel={(option) => option.name}
            loading={hubLoading}
            onChange={(_, newValue) => {
              dispatch(changeSubHubChoose(newValue));
              dispatch(changeSubHubId(newValue.id));
              setSelectOpen('miniHubs');
            }}
            onInputChange={(_, newInputValue) => {
              dispatch(changeSubHubSearch(newInputValue));
              subHubSearchInDebounce(newInputValue);
            }}
            renderOption={(props, option) => (
              <li key={option.idToko} {...props}>
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="sub hubs"
                sx={{ bgcolor: '#fafafa', borderRadius: '5px' }}
              />
            )}
          />
        </Grid>

        {/* mini hub */}
        <Grid item xs={4}>
          <Autocomplete
            sx={{ width: '100%' }}
            open={selectOpen === 'miniHubs' && true}
            onFocus={() => setSelectOpen('miniHubs')}
            onBlur={() => setSelectOpen('')}
            options={miniHubData}
            value={miniHubChoose}
            inputValue={miniHubSearch}
            getOptionLabel={(option) => option.name}
            loading={hubLoading}
            onChange={(_, newValue) => {
              dispatch(changeMiniHubChoose(newValue));
              dispatch(changeMiniHubId(newValue.id));

              setSelectOpen('');
            }}
            onInputChange={(_, newInputValue) => {
              dispatch(changeMiniHubSearch(newInputValue));
              miniHubSearchInDebounce(newInputValue);
            }}
            renderOption={(props, option) => (
              <li key={option.idToko} {...props}>
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="mini hubs"
                sx={{ bgcolor: '#fafafa', borderRadius: '5px' }}
              />
            )}
          />
        </Grid>
      </Grid>

      {/* button event */}
      <Box sx={{ textAlign: 'right' }}>
        {loadingSubmit && <CircularProgress size={16} />}
        <CustomButton
          variant="contained"
          // disabled={!isSubmitAvailable}
          onClick={handleSubmit}
          sx={{
            width: '140px',
            height: '36px',
            mt: '28px',
          }}
        >
          <Typography
            sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}
          >
            Next
          </Typography>
        </CustomButton>
      </Box>
    </Box>
  );
};
export default SelectHubEditPurchase;
