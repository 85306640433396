import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
  useTheme,
  TextField,
  Switch,
  Dialog,
  styled,
  DialogTitle,
  DialogContent,
  MenuItem,
  Input,
  InputAdornment,
  OutlinedInput,
  Select,
  Autocomplete,
} from "@mui/material";
import CustomInput from "../../../../../../components/shared/CustomInput";
import BackButton from "../../../../../../components/shared/BackButton";
import dayjs from "dayjs";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect, useState } from "react";
import CustomDialog from "../../../../../../components/shared/CustomDialog";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import CustomCard from "../../../../../../components/shared/CustomCard";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import useGetDifferent2Arrays from "../../../../../../hooks/useGetDifferent2Arrays";
import CustomButton from "../../../../../../components/shared/CustomButton";
import CustomTextFormatingInput from "../../../../../../components/shared/CustomTextFormatingInput/CustomTextFormatingInput";
import CustomSelect from "../../../../../../components/shared/CustomSelect";
import CustomAutocompleteDebounce from "../../../../../../components/shared/CustomAutocompleteDebounce";
import { addNewProductProduct } from "../../../../../../api/products";
import CustomBottomDrawer from "../../../../../../components/shared/CustomBottomDrawer";
import ClearIcon from "@mui/icons-material/Clear";

const ProductProductAddMobile = (props) => {
  const {
    handleBackButton,
    roadHirarki,
    productAddInputDatas,
    setProductAddInputDatas,
    theme,
    selectOpen,
    setSelectOpen,
    categoryOption,
    setCategoryDataAwait,
    categoryDataAwait,
    setSubCategoryOptionLoading,
    setCatSubMiniProduct,
    catSubMiniProduct,
    categoryOptionLoading,
    subCategoryOption,
    setMiniSubCategoryOptionLoading,
    subCategoryOptionLoading,
    miniSubCategoryOption,
    miniSubCategoryOptionLoading,
    unitDataAwait,
    setUnitDataAwait,
    unitDatas,
    variatnDataAwait,
    setVariantDataAwait,
    setDescriptionProduct,
    setIsMinDescriptionProduct,
    AntSwitch,
    handleAddNewProduct,
    stockLimitDataAwait,
    setStockLimitDataAwait,
    handleClickStockLimit,
    isSubmitAvailable,
  } = props;
  return (
    <Grid container columns={12} spacing={2}>
      {/* back button */}
      <Grid item xs={12} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <BackButton handleClick={handleBackButton} />
        <Box>
          <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
            Add Product
          </Typography>
          <Box
            sx={{
              display: "flex",
              color: "#9E9D9D",

              alignItems: "center",
              mt: "4px",
              gap: 0.25,
            }}
          >
            <Typography sx={{ fontSize: "8px", fontWeight: "100" }}>
              Subsidiary
            </Typography>
            <ChevronRightIcon sx={{ fontSize: "4px" }} />
            <Typography
              sx={{ fontSize: "8px", fontWeight: "100", color: "#9E9D9D" }}
            >
              {roadHirarki.category}
            </Typography>
            <ChevronRightIcon sx={{ fontSize: "4px" }} />
            <Typography sx={{ fontSize: "8px", fontWeight: "100" }}>
              {roadHirarki.subCategory}
            </Typography>
          </Box>
        </Box>
      </Grid>
      {/* Name */}
      <Grid item xs={12}>
        <CustomInput
          sx={{
            width: "100%",
            bgcolor: "#FAFAFA",
            fontSize: "12px",
            ".MuiOutlinedInput-input": {
              fontSize: "12px",
              padding: "12px",
            },
          }}
          placeholder="Name*"
          value={productAddInputDatas.name}
          onChange={(e) => {
            if (e.target.value.length <= 30) {
              setProductAddInputDatas({
                ...productAddInputDatas,
                name: e.target.value,
              });
            }
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "8px",
              fontWeight: "400",
              color: "#9E9D9D",
              mt: "4px",
            }}
          >
            Enter at least 5 character
          </Typography>
          <Typography
            sx={{
              fontSize: "8px",
              fontWeight: "400",
              color: "#9E9D9D",
              mt: "4px",
            }}
          >
            {productAddInputDatas.name.length}/30
          </Typography>
        </Box>
      </Grid>
      {/* Photo */}
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            pb: `${productAddInputDatas.photo.length === 0 ? "16px" : "9px"}`,
          }}
        >
          <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
            Photo
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "500",
              color: "#9E9D9D",
            }}
          >
            ( max size : 2MB )
          </Typography>
          {productAddInputDatas.isMaxSizePhoto && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ErrorOutlineIcon
                sx={{
                  fontSize: "8px",
                  fontWeight: "500",
                  color: theme.palette.warning.main,
                  mr: "4px",
                }}
              />
              <Typography
                sx={{
                  fontSize: "8px",
                  fontWeight: "500",
                  color: theme.palette.warning.main,
                }}
              >
                The photo can't be more than 2MB
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {productAddInputDatas.photo.length === 0 && (
              <Box
                sx={{
                  height: "54px",
                  width: "54px",
                  aspectRatio: "1/1",
                  borderRadius: "8px",
                  bgcolor: "#F5F5F5",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderStyle: "dashed",
                  borderColor: "#9E9D9D",
                  cursor: "pointer",
                }}
                onClick={() => {
                  document.getElementById("inputProduct_img").click();
                }}
              >
                <ImageOutlinedIcon
                  sx={{ color: "#9E9D9D", fontSize: "28px" }}
                />
              </Box>
            )}
            {productAddInputDatas.photo.map((input, index) => (
              <Box
                sx={{
                  position: "relative",
                  overflow: "visible",
                  padding: "7px",
                }}
                key={index}
              >
                <CancelIcon
                  sx={{
                    position: "absolute",
                    fontSize: "20px",
                    top: 1,
                    right: 1,
                    zIndex: +1,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setProductAddInputDatas({
                      ...productAddInputDatas,
                      photo: productAddInputDatas.photo.filter(
                        (data) => data !== input
                      ),
                      indexPreviePhoto: 0,
                    });
                  }}
                />
                <Box
                  component="img"
                  src={URL.createObjectURL(input)}
                  sx={{
                    height: "54px",
                    width: "54px",
                    aspectRatio: "1/1",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setProductAddInputDatas({
                      ...productAddInputDatas,
                      indexPreviePhoto: index + 1,
                      isPreviewPhoto: "true",
                    });
                  }}
                />
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              mb: "7px",
              ml: "20px",
              alignItems: "center",
            }}
          >
            {productAddInputDatas.photo.length < 3 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: theme.palette.primary.main,
                  cursor: "pointer",
                }}
                onClick={() => {
                  document.getElementById("inputProduct_img").click();
                }}
              >
                <input
                  accept="image/*"
                  id="inputProduct_img"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    if (e.target.files[0].size > 2000000) {
                      setProductAddInputDatas({
                        ...productAddInputDatas,
                        isMaxSizePhoto: true,
                      });
                    } else {
                      setProductAddInputDatas({
                        ...productAddInputDatas,
                        photo: [
                          ...productAddInputDatas.photo,
                          e.target.files[0],
                        ],
                        isMaxSizePhoto: false,
                      });
                    }
                  }}
                />
                <AddIcon sx={{ fontSize: "18px" }} />
                <Typography sx={{ fontSize: "8px", fontWeight: "600" }}>
                  Add More
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      {/*  Dialoge Photo */}
      {productAddInputDatas.indexPreviePhoto !== 0 && (
        <Dialog
          open={productAddInputDatas.isPreviewPhoto}
          onClose={() => {
            setProductAddInputDatas({
              ...productAddInputDatas,
              isPreviewPhoto: !productAddInputDatas.isPreviewPhoto,
            });
          }}
        >
          <Box
            component="img"
            src={URL.createObjectURL(
              productAddInputDatas.photo[
                productAddInputDatas.indexPreviePhoto - 1
              ]
            )}
            sx={{ aspectRation: "1/1" }}
          />
        </Dialog>
      )}
      {/* Category , UNIT, Variant */}
      <Grid container item xs={12} columns={12} spacing={2}>
        {/* Category */}
        <Grid item xs={12}>
          <CustomCard
            sx={{ padding: "16px 14px", cursor: "pointer" }}
            onClick={() =>
              setProductAddInputDatas({
                ...productAddInputDatas,
                isCategoryOpen: !productAddInputDatas.isCategoryOpen,
              })
            }
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ fontSize: "16px", fontWeight: "500" }}>
                <Typography sx={{ fontSize: "12px" }}>Category</Typography>
                <Box
                  sx={{
                    color: "#9E9D9D",
                    mt: "4px",
                  }}
                >
                  {productAddInputDatas.category === "" &&
                  productAddInputDatas.subCategory === "" ? (
                    <Typography sx={{ fontSize: "8px", fontWeight: "500" }}>
                      Click to Select category
                    </Typography>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        color: "black",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Typography sx={{ fontSize: "8px" }}>
                        {productAddInputDatas.category}
                      </Typography>
                      <ChevronRightIcon sx={{ fontSize: "8px" }} />
                      <Typography sx={{ fontSize: "8px" }}>
                        {productAddInputDatas.subCategory}
                      </Typography>
                      {productAddInputDatas.miniSubCategory !== "" && (
                        <>
                          <ChevronRightIcon sx={{ fontSize: "8px" }} />
                          <Typography sx={{ fontSize: "8px" }}>
                            {productAddInputDatas.miniSubCategory}
                          </Typography>
                        </>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
              <KeyboardArrowDownIcon />
            </Box>
          </CustomCard>
        </Grid>
        {/* UNit */}
        <Grid item xs={12}>
          <CustomCard
            sx={{ padding: "16px 14px", cursor: "pointer" }}
            onClick={() =>
              setProductAddInputDatas({
                ...productAddInputDatas,
                isUnitOpen: !productAddInputDatas.isUnitOpen,
              })
            }
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ fontSize: "16px", fontWeight: "500" }}>
                <Typography sx={{ fontSize: "12px" }}>Unit*</Typography>
                <Box
                  sx={{
                    color: "#9E9D9D",
                    mt: "4px",
                  }}
                >
                  {productAddInputDatas.unitValue1 === "" &&
                  productAddInputDatas.unitValue1name === "" ? (
                    <>
                      {productAddInputDatas.unitValueSubmited.length !== 0 ? (
                        <Box sx={{ display: "flex", gap: 2 }}>
                          {productAddInputDatas.unitValueSubmited.map(
                            (value, index) => (
                              <Typography
                                sx={{ fontSize: "8px", fontWeight: "500" }}
                                key={index}
                              >
                                {value}
                              </Typography>
                            )
                          )}
                        </Box>
                      ) : (
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            sx={{ fontSize: "8px", fontWeight: "500" }}
                          >
                            Click to Select Unit
                          </Typography>
                        </Box>
                      )}
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        color: "black",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Typography sx={{ fontSize: "12px" }}>
                        {productAddInputDatas.unitValue1}{" "}
                        {productAddInputDatas.unitValue1name}
                      </Typography>
                      {productAddInputDatas.unitValue2name !== "" && (
                        <Typography sx={{ fontSize: "12px" }}>
                          - {productAddInputDatas.unitValue2}{" "}
                          {productAddInputDatas.unitValue2name}
                        </Typography>
                      )}
                      {productAddInputDatas.unitValue3name !== "" && (
                        <Typography sx={{ fontSize: "12px" }}>
                          - {productAddInputDatas.unitValue3}{" "}
                          {productAddInputDatas.unitValue3name}
                        </Typography>
                      )}
                      {productAddInputDatas.unitValue4name !== "" && (
                        <Typography sx={{ fontSize: "12px" }}>
                          - {productAddInputDatas.unitValue4}{" "}
                          {productAddInputDatas.unitValue4name}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
              <KeyboardArrowDownIcon />
            </Box>
          </CustomCard>
        </Grid>
        {/* VAriant */}
        <Grid item xs={12}>
          <CustomCard
            sx={{ padding: "16px 14px", cursor: "pointer" }}
            onClick={() =>
              setProductAddInputDatas({
                ...productAddInputDatas,
                isVariantOpen: !productAddInputDatas.isVariantOpen,
              })
            }
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ fontSize: "16px", fontWeight: "500" }}>
                <Typography sx={{ fontSize: "12px" }}>Variant</Typography>
                <Box
                  sx={{
                    color: "#9E9D9D",
                    mt: "4px",
                  }}
                >
                  {productAddInputDatas.color === "" &&
                  productAddInputDatas.size === 0 ? (
                    <Typography sx={{ fontSize: "8px", fontWeight: "500" }}>
                      Click to select variant (color,size)
                    </Typography>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        color: "black",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      {productAddInputDatas.color !== "" &&
                      productAddInputDatas.size !== "" ? (
                        <Typography sx={{ fontSize: "8px" }}>
                          {productAddInputDatas.color},{" "}
                          {productAddInputDatas.size}
                        </Typography>
                      ) : productAddInputDatas.color !== "" &&
                        productAddInputDatas.size === "" ? (
                        <Typography sx={{ fontSize: "8px" }}>
                          {productAddInputDatas.color}
                        </Typography>
                      ) : (
                        <Typography sx={{ fontSize: "8px" }}>
                          {productAddInputDatas.size}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
              <KeyboardArrowDownIcon />
            </Box>
          </CustomCard>
        </Grid>
        {/* limit stock */}
        <Grid item xs={12}>
          <CustomCard
            sx={{ padding: "16px 14px", cursor: "pointer" }}
            onClick={() =>
              setProductAddInputDatas({
                ...productAddInputDatas,
                isLimitStockOpen: !productAddInputDatas.isCategoryOpen,
              })
            }
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ fontSize: "12px", fontWeight: "500" }}>
                <Typography sx={{ fontSize: "12px" }}>Stock Limit*</Typography>
                <Box
                  sx={{
                    color: "#9E9D9D",
                    mt: "4px",
                  }}
                >
                  {productAddInputDatas.minStockValue === "" &&
                  productAddInputDatas.maxStockValue === "" ? (
                    <Typography sx={{ fontSize: "8px", fontWeight: "500" }}>
                      Click to determine min and max stock
                    </Typography>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        color: "black",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Typography sx={{ fontSize: "8px" }}>
                        {productAddInputDatas.minStockValue}{" "}
                        {productAddInputDatas.minStock}
                      </Typography>
                      <Typography sx={{ fontSize: "8px" }}>
                        - {productAddInputDatas.maxStockValue}{" "}
                        {productAddInputDatas.maxStock}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <KeyboardArrowDownIcon />
            </Box>
          </CustomCard>
        </Grid>
      </Grid>
      {/* Dialog choosen */}
      {/*------------------- Drawer Category------------------- */}
      <CustomBottomDrawer
        open={productAddInputDatas.isCategoryOpen}
        onClose={() =>
          setProductAddInputDatas({
            ...productAddInputDatas,
            isCategoryOpen: !productAddInputDatas.isCategoryOpen,
          })
        }
        handleClose={() =>
          setProductAddInputDatas({
            ...productAddInputDatas,
            isCategoryOpen: !productAddInputDatas.isCategoryOpen,
          })
        }
        title="Category"
      >
        <Box
          sx={{
            display: "flex",
            alignItem: "center",
            flexDirection: "column",
            gap: 2,
            justifyContent: "space-between",
          }}
        >
          {/* Category Choose */}
          <Box>
            <Autocomplete
              sx={{ minWidth: "260px", bgcolor: "#FAFAFA" }}
              open={selectOpen === "category" && true}
              onFocus={() => setSelectOpen("category")}
              onBlur={() => setSelectOpen("")}
              options={categoryOption}
              inputValue={productAddInputDatas.categoryInput}
              onInputChange={(event, newInputValue) => {
                setProductAddInputDatas({
                  ...productAddInputDatas,
                  categoryInput: newInputValue,
                });
              }}
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    setProductAddInputDatas({
                      ...productAddInputDatas,
                      categoryInput: "",
                    });
                    setCategoryDataAwait({
                      ...categoryDataAwait,
                      categoryChoose: null,
                      categoryId: 0,
                      category: "",
                      subCategoryChoose: null,
                      subCategoryId: 0,
                      subCategory: "",
                      miniSubCategoryChoose: null,
                      miniSubCategoryId: 0,
                      miniSubCategory: "",
                    });
                  }}
                />
              }
              value={categoryDataAwait.categoryChoose}
              onChange={(event, newValue) => {
                setCategoryDataAwait({
                  ...categoryDataAwait,
                  categoryChoose: newValue,
                  categoryId: newValue.idKategori,
                  category: newValue.namaKategori,
                  subCategoryChoose: null,
                  subCategoryId: 0,
                  subCategory: "",
                  miniSubCategoryChoose: null,
                  miniSubCategoryId: 0,
                  miniSubCategory: "",
                });
                setSelectOpen("subCategory");
                setSubCategoryOptionLoading(true);
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                  key={option.idKategori}
                >
                  {option.namaKategori}
                </Box>
              )}
              getOptionLabel={(option) => option.namaKategori}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category*"
                  placeholder="Select Categories"
                  variant="filled"
                  sx={{
                    bgcolor: "#FAFAFA",
                    ".MuiFilledInput-root:before": {
                      borderBottom: "none",
                    },
                    ".MuiFilledInput-root": {
                      bgcolor: "#FAFAFA",
                    },
                  }}
                  onFocus={() => {
                    setCatSubMiniProduct({
                      ...catSubMiniProduct,
                      categoryOpen: true,
                    });
                  }}
                  onBlur={() => {
                    setCatSubMiniProduct({
                      ...catSubMiniProduct,
                      categoryOpen: false,
                    });
                  }}
                />
              )}
              loading={categoryOptionLoading}
            />
          </Box>
          {/* Sub Category Choose */}
          <Box>
            <Autocomplete
              sx={{ minWidth: "260px", bgcolor: "#FAFAFA" }}
              open={selectOpen === "subCategory" && true}
              onFocus={() => setSelectOpen("subCategory")}
              onBlur={() => setSelectOpen("")}
              options={subCategoryOption}
              inputValue={productAddInputDatas.subCategoryInput}
              onInputChange={(event, newInputValue) => {
                setProductAddInputDatas({
                  ...productAddInputDatas,
                  subCategoryInput: newInputValue,
                });
              }}
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    setProductAddInputDatas({
                      ...productAddInputDatas,
                      subCategoryInput: "",
                    });
                    setCategoryDataAwait({
                      ...categoryDataAwait,
                      subCategoryChoose: null,
                      subCategoryId: 0,
                      subCategory: "",
                      miniSubCategoryChoose: null,
                      miniSubCategoryId: 0,
                      miniSubCategory: "",
                    });
                  }}
                />
              }
              value={categoryDataAwait.subCategoryChoose}
              onChange={(event, newValue) => {
                setCategoryDataAwait({
                  ...categoryDataAwait,
                  subCategoryChoose: newValue,
                  subCategoryId: newValue.idSubkategori,
                  subCategory: newValue.subkategori,
                  miniSubCategoryChoose: null,
                  miniSubCategoryId: 0,
                  miniSubCategory: "",
                });
                setSelectOpen("mini");
                setMiniSubCategoryOptionLoading(true);
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                  key={option.isSubkategori}
                >
                  {option.subkategori}
                </Box>
              )}
              getOptionLabel={(option) => option.subkategori}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sub Category*"
                  placeholder="Select Subcategory"
                  variant="filled"
                  sx={{
                    bgcolor: "#FAFAFA",
                    ".MuiFilledInput-root:before": {
                      borderBottom: "none",
                    },
                    ".MuiFilledInput-root": {
                      bgcolor: "#FAFAFA",
                    },
                  }}
                  onFocus={() => {
                    setCatSubMiniProduct({
                      ...catSubMiniProduct,
                      subCategoryOpen: true,
                    });
                  }}
                  onBlur={() => {
                    setCatSubMiniProduct({
                      ...catSubMiniProduct,
                      subCategoryOpen: false,
                    });
                  }}
                />
              )}
              loading={subCategoryOptionLoading}
            />
          </Box>
          {/* Mini Sub Category Choose */}
          <Box>
            <Autocomplete
              sx={{ minWidth: "260px" }}
              open={selectOpen === "mini" && true}
              onFocus={() => setSelectOpen("mini")}
              onBlur={() => setSelectOpen("")}
              options={miniSubCategoryOption}
              inputValue={productAddInputDatas.miniSubCategoryInput}
              onInputChange={(event, newInputValue) => {
                setProductAddInputDatas({
                  ...productAddInputDatas,
                  miniSubCategoryInput: newInputValue,
                });
              }}
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    setProductAddInputDatas({
                      ...productAddInputDatas,
                      miniSubCategoryInput: "",
                    });
                    setCategoryDataAwait({
                      ...categoryDataAwait,
                      miniSubCategoryChoose: null,
                      miniSubCategoryId: 0,
                      miniSubCategory: "",
                    });
                  }}
                />
              }
              value={categoryDataAwait.miniSubCategoryChoose}
              onChange={(event, newValue) => {
                setCategoryDataAwait({
                  ...categoryDataAwait,
                  miniSubCategoryChoose: newValue,
                  miniSubCategoryId: newValue.id,
                  miniSubCategory: newValue.name,
                });
                setSelectOpen("");
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                  key={option.id}
                >
                  {option.name}
                </Box>
              )}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Mini"
                  variant="filled"
                  sx={{
                    bgcolor: "#FAFAFA",
                    ".MuiFilledInput-root:before": {
                      borderBottom: "none",
                    },
                    ".MuiFilledInput-root": {
                      bgcolor: "#FAFAFA",
                    },
                  }}
                  onFocus={() => {
                    setCatSubMiniProduct({
                      ...catSubMiniProduct,
                      miniSubCategoryOpen: true,
                    });
                  }}
                  onBlur={() => {
                    setCatSubMiniProduct({
                      ...catSubMiniProduct,
                      miniSubCategoryOpen: false,
                    });
                  }}
                />
              )}
              loading={miniSubCategoryOptionLoading}
            />
          </Box>
        </Box>
        <Box sx={{ mt: "28px" }}>
          <CustomButton
            variant="contained"
            disabled={
              categoryDataAwait.categoryChoose === null ||
              categoryDataAwait.subCategoryChoose == null
                ? true
                : false
            }
            sx={{
              bgcolor: theme.palette.primary.main,
              height: "48px",
              width: "100%",
            }}
            onClick={() => {
              setProductAddInputDatas({
                ...productAddInputDatas,
                category: categoryDataAwait.category,
                subCategory: categoryDataAwait.subCategory,
                miniSubCategory: categoryDataAwait.miniSUbCategory,
                categoryId: categoryDataAwait.categoryId,
                subCategoryId: categoryDataAwait.subCategoryId,
                miniSubCategoryId: categoryDataAwait.miniSUbCategoryId,
                isCategoryOpen: false,
              });
            }}
          >
            <Typography sx={{ color: "white" }}>Enter</Typography>
          </CustomButton>
        </Box>
      </CustomBottomDrawer>
      {/*------------------- Drawer  unit------------------- */}
      <CustomBottomDrawer
        open={productAddInputDatas.isUnitOpen}
        onClose={() => {
          setProductAddInputDatas({
            ...productAddInputDatas,
            isUnitOpen: !productAddInputDatas.isUnitOpen,
          });
        }}
        handleClose={() => {
          setProductAddInputDatas({
            ...productAddInputDatas,
            isUnitOpen: !productAddInputDatas.isUnitOpen,
          });
        }}
        title="Unit"
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {/* unit select 1 */}
          <FormControl variant="outlined" sx={{ mt: "12px" }}>
            <OutlinedInput
              sx={{ bgcolor: "#FAFAFA", p: "4px 8px 4px 4px" }}
              placeholder="Enter Qty"
              type="number"
              value={unitDataAwait.unitValue1}
              onChange={(e) =>
                setUnitDataAwait({
                  ...unitDataAwait,
                  unitValue1: e.target.value,
                })
              }
              endAdornment={
                <InputAdornment position="end">
                  <Select
                    value={
                      unitDataAwait.unitValue1name === ""
                        ? "unit"
                        : unitDataAwait.unitValue1name
                    }
                    onChange={(e) =>
                      setUnitDataAwait({
                        ...unitDataAwait,
                        unitValue1name: e.target.value,
                        unitValue1Id: unitDatas.find(
                          (item) => item.name === e.target.value
                        ).id,
                        unitValue2: "",
                        unitValue2name: "",
                        unitValue2Id: 0,
                        unitValue3: "",
                        unitValue3name: "",
                        unitValue3Id: 0,
                        unitValue4: "",
                        unitValue4name: "",
                        unitValue4Id: 0,
                        unitValue1: "",
                        unitValue2: "",
                        unitValue3: "",
                        unitValue4: "",
                      })
                    }
                    label="Age"
                    sx={{
                      minWidth: "130px",
                      bgcolor: "white",
                      ".MuiOutlinedInput-input": {
                        paddingTop: "12px",
                        paddingBottom: "12px",
                        paddingLeft: "12px",
                        paddingRight: "0",
                        borderRadius: "5px",
                      },
                    }}
                  >
                    {unitDataAwait.unitValue1name === "" && (
                      <MenuItem value="unit">Unit</MenuItem>
                    )}
                    {unitDatas.map((unitData, index) => (
                      <MenuItem value={unitData.name} key={index}>
                        {unitData.name}
                      </MenuItem>
                    ))}
                  </Select>
                </InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </FormControl>
          {/* unit select 2 */}
          {unitDataAwait.unitValue1name !== "" && (
            <FormControl variant="outlined" sx={{ mt: "12px" }}>
              <OutlinedInput
                sx={{ bgcolor: "#FAFAFA", p: "4px 8px 4px 4px" }}
                placeholder="Enter Qty"
                type="number"
                value={unitDataAwait.unitValue2}
                onChange={(e) =>
                  setUnitDataAwait({
                    ...unitDataAwait,
                    unitValue2: e.target.value,
                  })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <Select
                      value={unitDataAwait.unitValue2name}
                      onChange={(e) =>
                        setUnitDataAwait({
                          ...unitDataAwait,
                          unitValue2name: e.target.value,
                          unitValue2Id: unitDatas.find(
                            (item) => item.name === e.target.value
                          ).id,
                          unitValue3: "",
                          unitValue3name: "",
                          unitValue3Id: 0,
                          unitValue4: "",
                          unitValue4name: "",
                          unitValue4Id: 0,
                          unitValue2: "",
                          unitValue3: "",
                          unitValue4: "",
                        })
                      }
                      label="Age"
                      sx={{
                        minWidth: "130px",
                        bgcolor: "white",
                        ".MuiOutlinedInput-input": {
                          paddingTop: "12px",
                          paddingBottom: "12px",
                          paddingLeft: "12px",
                          paddingRight: "0",
                          borderRadius: "5px",
                        },
                      }}
                    >
                      {unitDatas.map((unitData, index) => (
                        <MenuItem value={unitData.name} key={index}>
                          {unitData.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
              />
            </FormControl>
          )}
          {/* unit select 3 */}
          {unitDataAwait.unitValue2name !== "" && (
            <FormControl variant="outlined" sx={{ mt: "12px" }}>
              <OutlinedInput
                sx={{ bgcolor: "#FAFAFA", p: "4px 8px 4px 4px" }}
                placeholder="Enter Qty"
                type="number"
                value={unitDataAwait.unitValue3}
                onChange={(e) =>
                  setUnitDataAwait({
                    ...unitDataAwait,
                    unitValue3: e.target.value,
                  })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <Select
                      value={unitDataAwait.unitValue3name}
                      onChange={(e) =>
                        setUnitDataAwait({
                          ...unitDataAwait,
                          unitValue3name: e.target.value,
                          unitValue3Id: unitDatas.find(
                            (item) => item.name === e.target.value
                          ).id,
                          unitValue4: "",
                          unitValue4name: "",
                          unitValue4Id: 0,
                          unitValue3: "",
                          unitValue4: "",
                        })
                      }
                      label="Age"
                      sx={{
                        minWidth: "130px",
                        bgcolor: "white",
                        ".MuiOutlinedInput-input": {
                          paddingTop: "12px",
                          paddingBottom: "12px",
                          paddingLeft: "12px",
                          paddingRight: "0",
                          borderRadius: "5px",
                        },
                      }}
                    >
                      {unitDatas.map((unitData, index) => (
                        <MenuItem value={unitData.name} key={index}>
                          {unitData.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
              />
            </FormControl>
          )}
          {/* Unit select 4 */}
          {unitDataAwait.unitValue3name !== "" && (
            <FormControl variant="outlined" sx={{ mt: "12px" }}>
              <OutlinedInput
                sx={{ bgcolor: "#FAFAFA", p: "4px 8px 4px 4px" }}
                placeholder="Enter Qty"
                type="number"
                value={unitDataAwait.unitValue4}
                onChange={(e) =>
                  setUnitDataAwait({
                    ...unitDataAwait,
                    unitValue4: e.target.value,
                  })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <Select
                      value={unitDataAwait.unitValue4name}
                      onChange={(e) =>
                        setUnitDataAwait({
                          ...unitDataAwait,
                          unitValue4name: e.target.value,
                          unitValue4Id: unitDatas.find(
                            (item) => item.name === e.target.value
                          ).id,
                          unitValue4: "",
                        })
                      }
                      label="Age"
                      sx={{
                        minWidth: "130px",
                        bgcolor: "white",
                        ".MuiOutlinedInput-input": {
                          paddingTop: "12px",
                          paddingBottom: "12px",
                          paddingLeft: "12px",
                          paddingRight: "0",
                          borderRadius: "5px",
                        },
                      }}
                    >
                      {unitDatas.map((unitData, index) => (
                        <MenuItem value={unitData.name} key={index}>
                          {unitData.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
              />
            </FormControl>
          )}
        </Box>
        <Box sx={{ mt: "28px" }}>
          <CustomButton
            variant="contained"
            sx={{
              bgcolor: theme.palette.primary.main,
              height: "48px",
              width: "100%",
            }}
            disabled={!unitDataAwait.unitValueSubmitedCheck}
            onClick={() => {
              let units = [
                {
                  unitId: parseInt(unitDataAwait.unitValue1Id),
                  value: parseInt(unitDataAwait.unitValue1),
                },
                {
                  unitId: parseInt(unitDataAwait.unitValue2Id),
                  value: parseInt(unitDataAwait.unitValue2),
                },
                {
                  unitId: parseInt(unitDataAwait.unitValue3Id),
                  value: parseInt(unitDataAwait.unitValue3),
                },
                {
                  unitId: parseInt(unitDataAwait.unitValue4Id),
                  value: parseInt(unitDataAwait.unitValue4),
                },
              ];
              let unitFix = units.filter((item) => item.unitId !== 0);
              setProductAddInputDatas({
                ...productAddInputDatas,
                unitValueSubmited: unitFix,
                unitValue1: parseInt(unitDataAwait.unitValue1),
                unitValue1Id: parseInt(unitDataAwait.unitValue1Id),
                unitValue1name: unitDataAwait.unitValue1name,
                unitValue2: parseInt(unitDataAwait.unitValue2),
                unitValue2Id: parseInt(unitDataAwait.unitValue2Id),
                unitValue2name: unitDataAwait.unitValue2name,
                unitValue3: parseInt(unitDataAwait.unitValue3),
                unitValue3Id: parseInt(unitDataAwait.unitValue3Id),
                unitValue3name: unitDataAwait.unitValue3name,
                unitValue4: parseInt(unitDataAwait.unitValue4),
                unitValue4Id: parseInt(unitDataAwait.unitValue4Id),
                unitValue4name: unitDataAwait.unitValue4name,
                isUnitOpen: false,
              });
            }}
          >
            <Typography sx={{ color: "white" }}>Enter</Typography>
          </CustomButton>
        </Box>
      </CustomBottomDrawer>
      {/*------------------- Drawer variant -------------------*/}
      <CustomBottomDrawer
        open={productAddInputDatas.isVariantOpen}
        onClose={() => {
          setProductAddInputDatas({
            ...productAddInputDatas,
            isVariantOpen: !productAddInputDatas.isVariantOpen,
          });
        }}
        handleClose={() => {
          setProductAddInputDatas({
            ...productAddInputDatas,
            isVariantOpen: !productAddInputDatas.isVariantOpen,
          });
        }}
        title={`${productAddInputDatas.name} Variant`}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            flexDirection: "column",
          }}
        >
          <TextField
            label="Color"
            variant="filled"
            fullWidth
            sx={{
              width: "100%",
              bgcolor: "#FAFAFA",
              ".MuiFilledInput-root:before": {
                borderBottom: "none",
              },
              ".MuiFilledInput-root": {
                bgcolor: "#FAFAFA",
                width: "100%",
              },
            }}
            value={variatnDataAwait.color}
            onChange={(e) =>
              setVariantDataAwait({
                ...variatnDataAwait,
                color: e.target.value,
              })
            }
          />

          <TextField
            label="Size"
            variant="filled"
            fullWidth
            sx={{
              width: "100%",
              bgcolor: "#FAFAFA",
              ".MuiFilledInput-root:before": {
                borderBottom: "none",
              },
              ".MuiFilledInput-root": {
                bgcolor: "#FAFAFA",
                width: "100%",
              },
            }}
            value={variatnDataAwait.size}
            type="number"
            onChange={(e) =>
              setVariantDataAwait({
                ...variatnDataAwait,
                size: e.target.value,
              })
            }
          />
        </Box>
        <Box sx={{ mt: "28px" }}>
          <CustomButton
            variant="contained"
            sx={{
              bgcolor: theme.palette.primary.main,
              height: "48px",
              width: "100%",
            }}
            onClick={() => {
              setProductAddInputDatas({
                ...productAddInputDatas,
                color: variatnDataAwait.color,
                size: variatnDataAwait.size,
                isVariantOpen: false,
              });
            }}
          >
            <Typography sx={{ color: "white" }}>Enter</Typography>
          </CustomButton>
        </Box>
      </CustomBottomDrawer>
      {/*------------------- Drawer Limit Stock -------------------*/}
      <CustomBottomDrawer
        open={productAddInputDatas.isLimitStockOpen}
        onClose={() => {
          setProductAddInputDatas({
            ...productAddInputDatas,
            isLimitStockOpen: !productAddInputDatas.isLimitStockOpen,
          });
        }}
        handleClose={() => {
          setProductAddInputDatas({
            ...productAddInputDatas,
            isLimitStockOpen: !productAddInputDatas.isLimitStockOpen,
          });
        }}
        title={`${productAddInputDatas.name} Stock Limit`}
      >
        <Grid container columns={12} spacing={4}>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: "500" }}>Min. Stock*</Typography>
            <FormControl variant="outlined" sx={{ mt: "12px" }}>
              <OutlinedInput
                sx={{ bgcolor: "#FAFAFA", p: "4px 8px 4px 4px" }}
                placeholder="Enter Qty"
                type="number"
                value={stockLimitDataAwait.minStockValue}
                onChange={(e) =>
                  setStockLimitDataAwait({
                    ...stockLimitDataAwait,
                    minStockValue: e.target.value,
                  })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <Select
                      value={stockLimitDataAwait.minStock}
                      onChange={(e) =>
                        setStockLimitDataAwait({
                          ...stockLimitDataAwait,
                          minStock: e.target.value,
                        })
                      }
                      label="Age"
                      sx={{
                        minWidth: "130px",
                        bgcolor: "white",
                        ".MuiOutlinedInput-input": {
                          paddingTop: "12px",
                          paddingBottom: "12px",
                          paddingLeft: "12px",
                          paddingRight: "0",
                          borderRadius: "5px",
                        },
                      }}
                    >
                      {/* {unitDataAwait.unitValue1name === '' && (
                          <MenuItem value="unit">Unit</MenuItem>
                        )} */}
                      {unitDatas.map((unitData, index) => (
                        <MenuItem value={unitData.name} key={index}>
                          {unitData.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: "500" }}>Max. Stock*</Typography>
            <FormControl variant="outlined" sx={{ mt: "12px" }}>
              <OutlinedInput
                sx={{ bgcolor: "#FAFAFA", p: "4px 8px 4px 4px" }}
                placeholder="Enter Qty"
                type="number"
                value={stockLimitDataAwait.maxStockValue}
                onChange={(e) =>
                  setStockLimitDataAwait({
                    ...stockLimitDataAwait,
                    maxStockValue: e.target.value,
                  })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <Select
                      value={stockLimitDataAwait.maxStock}
                      onChange={(e) =>
                        setStockLimitDataAwait({
                          ...stockLimitDataAwait,
                          maxStock: e.target.value,
                        })
                      }
                      label="Age"
                      sx={{
                        minWidth: "130px",
                        bgcolor: "white",
                        ".MuiOutlinedInput-input": {
                          paddingTop: "12px",
                          paddingBottom: "12px",
                          paddingLeft: "12px",
                          paddingRight: "0",
                          borderRadius: "5px",
                        },
                      }}
                    >
                      {/* {unitDataAwait.unitValue1name === '' && (
                          <MenuItem value="unit">Unit</MenuItem>
                        )} */}
                      {unitDatas.map((unitData, index) => (
                        <MenuItem value={unitData.name} key={index}>
                          {unitData.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ mt: "28px" }}>
          <CustomButton
            variant="contained"
            sx={{
              bgcolor: theme.palette.primary.main,
              height: "48px",
              width: "100%",
            }}
            disabled={!stockLimitDataAwait.isCheck}
            onClick={handleClickStockLimit}
          >
            <Typography sx={{ color: "white" }}>Enter</Typography>
          </CustomButton>
        </Box>
      </CustomBottomDrawer>
      {/* Meta Description */}
      <Grid item xs={12}>
        <CustomInput
          sx={{
            width: "100%",
            bgcolor: "#FAFAFA",
            fontSize: "12px",
            ".MuiOutlinedInput-input": {
              fontSize: "12px",
              padding: "12px",
            },
          }}
          placeholder="Meta Description"
          value={productAddInputDatas.metaDescription}
          onChange={(e) => {
            if (e.target.value.length <= 250) {
              setProductAddInputDatas({
                ...productAddInputDatas,
                metaDescription: e.target.value,
              });
            }
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "8px",
              fontWeight: "400",
              color: "#9E9D9D",
              mt: "4px",
            }}
          >
            Enter at least 20 character
          </Typography>
          <Typography
            sx={{
              fontSize: "8px",
              fontWeight: "400",
              color: "#9E9D9D",
              mt: "4px",
            }}
          >
            {productAddInputDatas.metaDescription.length}/250
          </Typography>
        </Box>
      </Grid>
      {/* Descripiton Mobile */}
      <Grid item xs={12}>
        <CustomTextFormatingInput
          setEditorContent={setDescriptionProduct}
          isError={false}
          helperText=""
          setIsMinInputText={setIsMinDescriptionProduct}
        />
      </Grid>
      {/* STATUS */}
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
          Publish
        </Typography>
        <AntSwitch
          checked={productAddInputDatas.publish}
          onChange={(e) => {
            setProductAddInputDatas({
              ...productAddInputDatas,
              publish: !productAddInputDatas.publish,
            });
          }}
        />
      </Grid>
      {/* SUBMIT */}
      <Grid item xs={12} sx={{ mt: "28px" }}>
        <CustomButton
          disabled={!isSubmitAvailable}
          onClick={handleAddNewProduct}
          variant="contained"
          sx={{}}
        >
          Add
        </CustomButton>
      </Grid>
    </Grid>
  );
};
export default ProductProductAddMobile;
