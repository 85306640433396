import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFamilyHub,
  fetchFamilyMasterHub,
  getHubFamily,
  getHubFamilyLoading,
} from "../../../../../store/hub/hirarkiHub";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import { useState } from "react";

const HubFamily = (props) => {
  const {
    isAddMaster = false,
    nameAddMaster,
    isEditMaster = false,
    idEditMaster,
  } = props;

  const dispatch = useDispatch();
  const hubFamily = useSelector(getHubFamily);
  const hubFamilyLoading = useSelector(getHubFamilyLoading);

  const [masterOpenSubs, setMasterOpenSubs] = useState([]);
  const [subOpenMinis, setSubOpenMinis] = useState([]);

  useEffect(() => {
    dispatch(fetchFamilyHub());
  }, []);

  //   !----------------- HANDLER ---------------
  const handleClickMaster = (id) => {
    if (masterOpenSubs.includes(id)) {
      setMasterOpenSubs(masterOpenSubs.filter((item) => item !== id));
    } else {
      setMasterOpenSubs([...masterOpenSubs, id]);
    }
  };
  const handleClickSub = (id) => {
    if (subOpenMinis.includes(id)) {
      setSubOpenMinis(subOpenMinis.filter((item) => item !== id));
    } else {
      setSubOpenMinis([...subOpenMinis, id]);
    }
  };

  return (
    <Box sx={{ p: "12px 12px 52px 0px" }}>
      {!hubFamilyLoading && (
        <Box sx={{ overflow: "hidden" }}>
          {hubFamily.map((master, indexMaster) => (
            <Box key={indexMaster}>
              <Box
                sx={{
                  whiteSpace: "nowrap",
                  display: "flex",
                  gap: 0.4,
                  alignItems: "center",
                  cursor:
                    master.subHubs.length !== 0 ? "pointer" : "context-menu",
                }}
                onClick={() => {
                  if (master.subHubs.length !== 0) {
                    handleClickMaster(master.idToko);
                  }
                }}
              >
                <ArrowRightRoundedIcon
                  sx={{
                    visibility:
                      master.subHubs.length !== 0 ? "visible" : "hidden",
                    transform: masterOpenSubs.includes(master.idToko)
                      ? "rotate(90deg)"
                      : "rotate(0)",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    color:
                      isEditMaster && idEditMaster === master.idToko
                        ? "#51B15C"
                        : "#000000 ",
                  }}
                >
                  {master.namaToko}
                </Typography>
              </Box>
              {master.subHubs.length !== 0 &&
                masterOpenSubs.includes(master.idToko) && (
                  <Box sx={{ pl: "12px" }}>
                    {master.subHubs.map((subHub, indexSub) => (
                      <Box key={indexSub} sx={{ pl: "8px" }}>
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            display: "flex",
                            gap: 0.4,
                            alignItems: "center",
                            cursor:
                              subHub.miniHubs.length !== 0
                                ? "pointer"
                                : "context-menu",
                          }}
                          onClick={() => {
                            if (subHub.miniHubs.length !== 0) {
                              handleClickSub(subHub.id);
                            }
                          }}
                        >
                          <ArrowRightRoundedIcon
                            sx={{
                              visibility:
                                subHub.miniHubs.length !== 0
                                  ? "visible"
                                  : "hidden",
                              transform: subOpenMinis.includes(subHub.id)
                                ? "rotate(90deg)"
                                : "rotate(0)",
                            }}
                          />
                          <Typography sx={{ fontSize: "12px" }}>
                            {subHub.name}
                          </Typography>
                        </Box>
                        {subHub.miniHubs.length !== 0 &&
                          subOpenMinis.includes(subHub.id) && (
                            <Box sx={{ pl: "12px" }}>
                              {subHub.miniHubs.map((miniHub, indexMini) => (
                                <Box key={indexMini} sx={{ pl: "8px" }}>
                                  <Box
                                    sx={{
                                      whiteSpace: "nowrap",
                                      display: "flex",
                                      gap: 0.4,
                                      alignItems: "center",
                                    }}
                                  >
                                    <ArrowRightRoundedIcon
                                      sx={{ visibility: "hidden" }}
                                    />
                                    <Typography sx={{ fontSize: "12px" }}>
                                      {miniHub.name}
                                    </Typography>
                                  </Box>
                                </Box>
                              ))}
                            </Box>
                          )}
                      </Box>
                    ))}
                  </Box>
                )}
            </Box>
          ))}
          <Box>
            {isAddMaster && (
              <Box
                sx={{
                  whiteSpace: "nowrap",
                  display: "flex",
                  gap: 0.4,
                  alignItems: "center",
                }}
              >
                <ArrowRightRoundedIcon sx={{ visibility: "hidden" }} />
                <Typography sx={{ fontSize: "12px" }} color="primary">
                  {nameAddMaster ? nameAddMaster : "Master"}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default HubFamily;
