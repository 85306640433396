import {
  Autocomplete,
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CustomCard from '../../../../../../components/shared/CustomCard';
import Subsidiary from '../../../../../../components/shared/Subsidiary';
import { ProductPerfomance, SeeProduct } from '../DesktopComponent';
import TableProduct from '../DesktopComponent/TableProduct';
import TableCategoryProduct from './Desktop/TableCategoryProduct';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as DollarIcon } from '../../../../../../assets/dollar.svg';
import { ReactComponent as BoxIcon } from '../../../../../../assets/box.svg';
import { ReactComponent as NoImage } from '../../../../../../assets/no-image.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomBottomDrawer from '../../../../../../components/shared/CustomBottomDrawer';

import ClearIcon from '@mui/icons-material/Clear';
import { ReactComponent as CloseFilledIcon } from '../../../../../../assets/closeFilledIcon.svg';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CustomButton from '../../../../../../components/shared/CustomButton';

const CategoryProductMobile = (props) => {
  const {
    handleToAllProduct,
    tableSearch,
    setTableSearch,
    theme,
    setIsCategoryProductAdd,
    tableTotalItems,
    nextData,
    isHasMorePage,
    tableDatas,
    handleClickRow,
    handleClickName,
    // *-------
    isLoadingSubsidiary,
    subsidiaryDatas,
    // *----- type ----
    typeCategory,
    setTypeCategorys,
    typeCategoryLoading,
    setTypeCategoryLoading,
    // *------ filter
    filterCategorySubsidiary,
    setFilterCategorySubsidiary,
    filterCategoryType,
    setFilterCategoryType,
    autoCompleteFilter,
    setAutoCompleteFilter,
    isOpenTableFilter,
    setIsOpenTableFilter,
    subsidiaryFilterValue,
    setSubsidiaryFilterValue,
    typeFilterValue,
    setTypeFilterValue,
    handleClickSaveFilter,
    handleClickResetFilter,
    // * -- Data All
    allData,
    allDataType,
  } = props;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Grid container columns={12} spacing={2}>
      <CustomBottomDrawer
        open={isOpenTableFilter}
        onClose={() => setIsOpenTableFilter(false)}
        handleClose={() => setIsOpenTableFilter(false)}
        title="Filter"
        additionalOption="Reset"
        handleClickAdditionalOption={handleClickResetFilter}
      >
        {/* Subsidiary FIlter */}
        <Box>
          <Autocomplete
            sx={{
              '.MuiAutocomplete-hasClearIcon': {
                display: 'none',
              },
            }}
            clearIcon={
              autoCompleteFilter.subsidiary &&
              !autoCompleteFilter.subsidiaryOpen ? (
                false
              ) : (
                <ClearIcon fontSize="small" />
              )
            }
            multiple={
              autoCompleteFilter.subsidiary &&
              !autoCompleteFilter.subsidiaryOpen
                ? false
                : true
            }
            limitTags={
              autoCompleteFilter.subsidiary &&
              !autoCompleteFilter.subsidiaryOpen
                ? 0
                : 3
            }
            options={
              autoCompleteFilter.subsidiary &&
              !autoCompleteFilter.subsidiaryOpen
                ? allData
                : subsidiaryDatas
            }
            getOptionLabel={(option) => option.name}
            value={
              autoCompleteFilter.subsidiary &&
              !autoCompleteFilter.subsidiaryOpen
                ? allData[0]
                : subsidiaryFilterValue
            }
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Box
                  sx={{
                    bgcolor: 'white',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                    p: '4px 8px',
                    m: '2px',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  {option.name}
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      setSubsidiaryFilterValue(
                        subsidiaryFilterValue.filter(
                          (item) => item.id !== option.id
                        )
                      )
                    }
                  >
                    <CloseFilledIcon />
                  </Box>
                </Box>
              ))
            }
            onChange={(event, value) => {
              setSubsidiaryFilterValue(value);
              if (value.length === subsidiaryDatas.length) {
                setAutoCompleteFilter({
                  ...autoCompleteFilter,
                  subsidiary: true,
                });
              } else {
                setAutoCompleteFilter({
                  ...autoCompleteFilter,
                  subsidiary: false,
                });
              }
            }}
            onOpen={() => {
              setAutoCompleteFilter({
                ...autoCompleteFilter,
                subsidiaryOpen: true,
              });
            }}
            onClose={() => {
              setAutoCompleteFilter({
                ...autoCompleteFilter,
                subsidiaryOpen: false,
              });
            }}
            selectOnFocus
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <li {...props} sx={{ color: 'black' }}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            style={{ width: '100%', marginTop: '12px' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                placeholder="Enter Subsidiarys"
                sx={{ bgcolor: '#FAFAFA' }}
              />
            )}
          />
        </Box>
        {/* Type Filter */}
        <Box>
          <Autocomplete
            sx={{
              '.MuiAutocomplete-hasClearIcon': {
                display: 'none',
              },
            }}
            clearIcon={
              autoCompleteFilter.type && !autoCompleteFilter.typeOpen ? (
                false
              ) : (
                <ClearIcon fontSize="small" />
              )
            }
            multiple={
              autoCompleteFilter.type && !autoCompleteFilter.typeOpen
                ? false
                : true
            }
            limitTags={
              autoCompleteFilter.type && !autoCompleteFilter.typeOpen ? 0 : 3
            }
            options={
              autoCompleteFilter.type && !autoCompleteFilter.typeOpen
                ? allDataType
                : typeCategory
            }
            getOptionLabel={(option) => option.nmOpsi}
            value={
              autoCompleteFilter.type && !autoCompleteFilter.typeOpen
                ? allDataType[0]
                : typeFilterValue
            }
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Box
                  sx={{
                    bgcolor: 'white',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                    p: '4px 8px',
                    m: '2px',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  {option.nmOpsi}
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      setTypeFilterValue(
                        typeFilterValue.filter(
                          (item) => item.iDopsi !== option.iDopsi
                        )
                      )
                    }
                  >
                    <CloseFilledIcon />
                  </Box>
                </Box>
              ))
            }
            onChange={(event, value) => {
              setTypeFilterValue(value);
              if (value?.length === typeCategory?.length) {
                setAutoCompleteFilter({
                  ...autoCompleteFilter,
                  type: true,
                });
              } else {
                setAutoCompleteFilter({
                  ...autoCompleteFilter,
                  type: false,
                });
              }
            }}
            onOpen={() => {
              setAutoCompleteFilter({
                ...autoCompleteFilter,
                typeOpen: true,
              });
            }}
            onClose={() => {
              setAutoCompleteFilter({
                ...autoCompleteFilter,
                typeOpen: false,
              });
            }}
            selectOnFocus
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <li {...props} sx={{ color: 'black' }}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.nmOpsi}
              </li>
            )}
            style={{ width: '100%', marginTop: '12px' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                placeholder="Enter Type"
                sx={{ bgcolor: '#FAFAFA' }}
              />
            )}
          />
        </Box>
        {/* Save */}
        <Box>
          <CustomButton
            variant="contained"
            sx={{ width: '100%', height: '42px', mt: '20px' }}
            onClick={handleClickSaveFilter}
          >
            <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
              Save
            </Typography>
          </CustomButton>
        </Box>
      </CustomBottomDrawer>
      <Grid item xs={9}>
        <Subsidiary />
      </Grid>
      <Grid item xs={3}>
        <Box
          sx={{
            bgcolor: '#51B15C1A',
            height: '48px',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 0.3,
          }}
          onClick={handleToAllProduct}
        >
          <BoxIcon style={{ height: '12px' }} />
          <ChevronRightIcon sx={{ color: '#51B15C' }} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <CustomCard sx={{ p: '12px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {/* JUDUl */}
              <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
                Category
              </Typography>
              <TextField
                placeholder={'Search'}
                value={tableSearch}
                onChange={(e) => setTableSearch(e.target.value)}
                size="small"
                sx={{
                  width: '100px',
                  height: '20px',
                  '.MuiOutlinedInput-input': {
                    padding: '6px 0px 6px 0px',
                    fontSize: '0.5rem',
                  },
                  '.MuiOutlinedInput-root': {
                    bgcolor: '#FAFAFA',
                    borderRadius: '4px',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ width: '8px', ml: '4px' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'row-reverse',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '4px',
                  bgcolor: theme.palette.primary.main,
                  width: '20px',
                  height: '20px ',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsCategoryProductAdd(true);
                }}
              >
                <AddIcon sx={{ color: 'white', width: '8px', height: '8px' }} />
              </Box>
              <CustomCard
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '4px',
                  width: '20px',
                  height: '20px ',
                }}
                onClick={() => setIsOpenTableFilter(true)}
              >
                <FilterAltIcon sx={{ width: '12px' }} />
              </CustomCard>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: '16px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <DollarIcon style={{ height: '10px' }} />
                <Typography sx={{ fontSize: '8px' }}>COGS(Rp)*</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <BoxIcon style={{ height: '10px' }} />
                <Typography sx={{ fontSize: '8px' }}>SKU</Typography>
              </Box>
            </Box>
            <Typography sx={{ fontSize: '8px' }}>*000</Typography>
          </Box>

          <Box sx={{}}>
            <InfiniteScroll
              dataLength={tableDatas.length}
              next={nextData}
              hasMore={isHasMorePage}
              loader={<Skeleton variant="text" sx={{ mt: 3 }} />}
              height={'245px'}
            >
              <Grid container columns={12} sx={{}} spacing={'16px'}>
                {/* ITEMSS */}
                {tableDatas.map((data, index) => (
                  <Grid item xs={6} key={index}>
                    <Box
                      sx={{
                        bgcolor: '#FAFAFA',
                        p: '8px',
                        borderRadius: '8px',
                        height: '65px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleClickRow(
                          data.namaKategori,
                          data.idKategori,
                          data.subsidiary
                        );
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* Photo */}
                        <Box
                          onClick={(event) =>
                            handleClickName(
                              event,
                              data.namaKategori,
                              data.idKategori,
                              data.subsidiary
                            )
                          }
                        >
                          {!data.gambar ? (
                            <Box
                              sx={{
                                width: '28px',
                                height: '28px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                bgcolor: '#FAFAFA',
                                borderRadius: '2px',
                              }}
                            >
                              <NoImage />
                              <Typography
                                sx={{ color: '#9E9D9D', fontSize: '4px' }}
                              >
                                No photo
                              </Typography>
                            </Box>
                          ) : (
                            <Box>
                              <img
                                src={
                                  process.env.REACT_APP_API_IMAGE_BASE_URL +
                                  '/' +
                                  data.gambar.split(',')[0]
                                }
                                style={{
                                  width: '28px',
                                  height: '28px',
                                  borderRadius: '2px',
                                }}
                                alt=""
                              />
                            </Box>
                          )}
                        </Box>
                        {/* Title */}
                        <Box sx={{ ml: '8px' }}>
                          <Typography
                            sx={{
                              fontSize: '8px',
                              fontWeight: '600',
                              color: '#848589',
                            }}
                          >
                            {!data.subsidiary ? '-' : data.subsidiary.name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '10px',
                              fontWeight: '600',
                              mt: '2px',
                            }}
                            onClick={(event) =>
                              handleClickName(
                                event,
                                data.namaKategori,
                                data.idKategori,
                                data.subsidiary
                              )
                            }
                          >
                            {data.namaKategori}
                          </Typography>
                        </Box>
                      </Box>
                      {/* ITEMSS */}
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          mt: '8px',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                          }}
                        >
                          <DollarIcon style={{ height: '8px' }} />
                          <Typography sx={{ fontSize: '8px' }}>
                            {!data.productsSumHarga
                              ? '-'
                              : data.productsSumHarga}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                          }}
                        >
                          <BoxIcon style={{ height: '8px' }} />
                          <Typography sx={{ fontSize: '8px' }}>
                            {!data.productsCoun ? '-' : data.productsCount}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          </Box>
        </CustomCard>
      </Grid>
    </Grid>
  );
};
export default CategoryProductMobile;
