import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Subsidiary from "../../../../../../../components/shared/Subsidiary";
import { useRef, useState } from "react";
import CustomBarChart from "../../../../../../../components/shared/CustomBarChart";
import CustomLineChart from "../../../../../../../components/shared/CustomLineChart";
import CustomTableMobile from "../../../../../../../components/shared/CustomTableMobile";
import {
  AddIcon,
  CloseIcon,
  ExportIcon,
  FilterAltIcon,
  SearchIcon,
  Telephone,
  ToBarChart,
  ToLineChart,
  UserIcon,
} from "../../../../../../../assets/Icons";
import CustomCard from "../../../../../../../components/shared/CustomCard";
import CustomButton from "../../../../../../../components/shared/CustomButton";
import debounce from "lodash.debounce";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllDataMasteHub,
  getAllDataMasterHub,
  getIsloadingMasterHub,
  getPageInfoMasterHub,
} from "../../../../../../../store/hub/masterHub/masterHub";
import CustomDialog from "../../../../../../../components/shared/CustomDialog";
import { getSubsidiary } from "../../../../../../../api/subsidiary";
import { changeIdMasterHubs } from "../../../../../../../store/hub/masterHub/masterHubDetail";
import {
  changePageMasterHub,
  changePageSubHub,
} from "../../../../../../../store/Routing/hubs";
import {
  changeIdMasterHub,
  changeMasterHubName,
} from "../../../../../../../store/hub/subHub/subHub";

// data dummy chart
const datas = {
  labels: ["Hub 1", "Hub 2", "Hub 3", "Hub 4", "Hub 5"],
  data: [
    {
      label: "Equal",
      chart: [30, 110, 140, 100, 240],
    },
    {
      label: "Different",
      chart: [0, 35, 100, 90, 150],
    },
    {
      label: "Missing",
      chart: [40, 70, 160, 120, 200],
    },
  ],
};
const color = ["#6B4BB4", "#EACD57", "#FD7900"];

const MasterHubMobile = () => {
  const dispatch = useDispatch();
  const dataHubs = useSelector(getAllDataMasterHub);
  const isLoadingHub = useSelector(getIsloadingMasterHub);
  const pageInfo = useSelector(getPageInfoMasterHub);

  const [chartType, setChartType] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  // ! --------- Subsidiary ---------
  const [filterSubsidiaryArray, setFilterSubsidiaryArray] = useState([]);
  const [dataSubsidiary, setDataSubsidiary] = useState([]);
  const [temporarySubsidiary, setTemporarySubsidiary] = useState([]);
  const [spesifikSubsidiary, setSpesifikSubsidiary] = useState([]);

  // !search
  const [search, setSearch] = useState("");
  const [purchaseKeyword, setPurchaseKeyword] = useState("");
  const debounceOnChange = useRef(
    debounce((value) => {
      setPurchaseKeyword(value);
    }, 1000)
  ).current;

  // ! ------------------- API -------------------------------------
  useEffect(() => {
    const params = {};
    params.page = 1;
    params.page_size = 4;
    params.search = purchaseKeyword;
    params.subsidiary_id =
      spesifikSubsidiary.length !== 0
        ? spesifikSubsidiary
        : filterSubsidiaryArray;
    dispatch(fetchAllDataMasteHub(params));
  }, [purchaseKeyword, filterSubsidiaryArray, spesifikSubsidiary]);

  // subsidiary

  useEffect(() => {
    const fetchSubsidiary = async () => {
      try {
        const {
          data: { data },
        } = await getSubsidiary();
        setDataSubsidiary(data?.subsidiaries);
      } catch (err) {
        console.error(err);
      }
    };
    fetchSubsidiary();
  }, []);

  // ! ------------------- Toolbar ---------------------------------
  const toolbar = (
    <Box
      sx={{
        display: "flex",
        flexFlow: "row-reverse",
        gap: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CustomButton
        sx={{
          minWidth: "22px",
          width: "22px",
          minHeight: "20px",
          height: "20px",
          bgcolor: "#51B15C",
          color: "#ffffff",
          borderRadius: "4px",
          "&:hover": {
            bgcolor: "#51B15C",
            color: "#ffffff",
          },
          fontSize: "0.875rem",
          display: "flex",
          justifyContent: "center",
        }}
        // startIcon={}
        onClick={() => dispatch(changePageMasterHub("addMasterHub"))}
      >
        <AddIcon sx={{ width: "14px", color: "#FFFFFF" }} />
      </CustomButton>
      <CustomButton
        sx={{
          minWidth: "22px",
          width: "22px",
          minHeight: "22px",
          height: "22px",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "4px",
          bgcolor: "#FFFFFF",
          color: "#000000",
          "&:hover": {
            bgcolor: "#FFFFFF",
            color: "#000000",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <ExportIcon />
      </CustomButton>

      <CustomButton
        sx={{
          minWidth: "22px",
          width: "22px",
          minHeight: "22px",
          height: "22px",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "4px",
          bgcolor: "#FFFFFF",
          color: "#000000",
          "&:hover": {
            bgcolor: "#FFFFFF",
            color: "#000000",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
          },
        }}
        onClick={() => setIsFilter(!isFilter)}
      >
        <FilterAltIcon sx={{ width: "14px" }} />
      </CustomButton>
    </Box>
  );

  // ! ------------------ Handler -----------------------------------
  const isOptionEqualToId = (option, value) => {
    return option.id === value?.id;
  };

  const handleSave = () => {
    const idSubsidiary = temporarySubsidiary?.map((item, _) => item?.id);
    setSpesifikSubsidiary(idSubsidiary);
    setIsFilter(false);
  };

  const handleToDetail = (event, id) => {
    event.stopPropagation();
    dispatch(changeIdMasterHubs(id));
    dispatch(changePageMasterHub("detailMasterHub"));
  };

  const handleToSubHub = (namaToko, idToko) => {
    dispatch(changePageSubHub("subHub"));
    dispatch(changeMasterHubName(namaToko));
    dispatch(changeIdMasterHub(idToko));
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container columns={12} spacing={2}>
        {/* subsidiary */}
        <Grid item xs={12}>
          <Subsidiary
            subsidiaryChecked={filterSubsidiaryArray}
            setSubsidiaryChecked={setFilterSubsidiaryArray}
            pad={1.5}
          />
        </Grid>

        {/* grafik */}
        <Grid item xs={12}>
          <CustomCard sx={{ p: 2 }}>
            <Typography
              sx={{ color: "#000", fontSize: "10px", fontWeight: 600, mb: 2 }}
            >
              Hub Audit
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: "8px",
                  color: "#6B4BB4",
                  fontWeight: 600,
                  mb: 1,
                }}
              >
                SKU
              </Typography>
              <Box
                sx={{
                  width: "16px",
                  height: "16px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => setChartType(!chartType)}
              >
                {chartType ? (
                  <ToLineChart stroke="#51B15C" />
                ) : (
                  <ToBarChart stroke="#51B15C" />
                )}
              </Box>
              <Box></Box>
            </Box>
            {chartType ? (
              <CustomBarChart datas={datas} color={color} />
            ) : (
              <CustomLineChart datas={datas} color={color} />
            )}
          </CustomCard>
        </Grid>

        {/* table grafik */}
        <Grid item xs={12}>
          <CustomTableMobile
            rootValue={datas?.labels}
            datas={datas.data}
            color={color}
          />
        </Grid>

        {/* list data */}
        <Grid item xs={12}>
          <CustomCard sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1.5,
                }}
              >
                <Box>
                  <Typography
                    sx={{ fontSize: "12px", fontWeight: 600, color: "#000" }}
                  >
                    Master List
                  </Typography>
                </Box>
                <Box>
                  <TextField
                    placeholder={"Search"}
                    size="small"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      debounceOnChange(e.target.value);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        paddingLeft: "6px",
                        fontSize: "7px",
                      },
                      bgcolor: "#FAFAFA",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ width: "12px" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <Box>{toolbar}</Box>
            </Box>
            {/* mapping data */}
            <Grid container columns={12} spacing={2} sx={{ mt: 2 }}>
              {isLoadingHub ? (
                <Grid item xs={12}>
                  <Box sx={{ width: "100%", textAlign: "center", mt: 4 }}>
                    <CircularProgress size={30} />
                  </Box>
                </Grid>
              ) : dataHubs?.length === 0 ? (
                <Grid item xs={12}>
                  <Box
                    sx={{ width: "100%", textAlign: "center", mt: 4, mb: 2 }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        color: "#9E9D9D",
                      }}
                    >
                      No data found
                    </Typography>
                  </Box>
                </Grid>
              ) : (
                dataHubs?.map((item, idx) => (
                  <Grid item xs={6} key={idx}>
                    <Box
                      sx={{
                        background: "#FAFAFA",
                        borderRadius: "8px",
                        padding: "10px",
                      }}
                      onClick={() =>
                        handleToSubHub(item?.namaToko, item?.idToko)
                      }
                    >
                      <Box
                        sx={{ display: "flex", gap: 1.5, alignItems: "center" }}
                      >
                        <Box
                          component="img"
                          src={
                            process.env.REACT_APP_API_IMAGE_BASE_URL +
                            "/" +
                            item?.gambar.split(",")[0]
                          }
                          sx={{
                            width: "28px",
                            height: "28px",
                            borderRadius: "8px",
                          }}
                          onClick={(event) =>
                            handleToDetail(event, item?.idToko)
                          }
                        />
                        <Box
                          sx={{
                            height: "28px",
                            display: "flex",
                            justifyContent: "space-around",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#848589",
                              fontSize: "8px",
                              fontWeight: 600,
                            }}
                          >
                            {item?.subsidiary}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#000",
                              fontSize: "10px",
                              fontWeight: 600,
                            }}
                          >
                            {item?.namaToko}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5,
                          }}
                        >
                          <UserIcon />
                          <Typography
                            sx={{
                              fontSize: "8px",
                              fontWeight: 400,
                              color: "#000",
                            }}
                          >
                            {item?.picName.split(" ")[0]}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5,
                          }}
                        >
                          <Telephone />
                          <Typography
                            sx={{
                              fontSize: "8px",
                              fontWeight: 400,
                              color: "#000",
                            }}
                          >
                            {item?.picPhone}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))
              )}
            </Grid>
          </CustomCard>
        </Grid>
      </Grid>

      {/* dialong Filter */}
      <CustomDialog
        isOpen={isFilter}
        setIsOpen={setIsFilter}
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "12px",
          },
        }}
      >
        <DialogTitle
          sx={{
            borderRadius: "12px 12px 0px 0px",
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CloseIcon onClick={() => setIsFilter(!isFilter)} />
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, color: "#000" }}
            >
              Filter
            </Typography>
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, color: "#51B15C" }}
              onClick={() => {
                setTemporarySubsidiary([]);
                setSpesifikSubsidiary([]);
              }}
            >
              Reset
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            multiple
            value={temporarySubsidiary}
            onChange={(_, newValue) => {
              setTemporarySubsidiary(newValue);
            }}
            options={dataSubsidiary}
            getOptionLabel={(option) => option.name}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Subsidiary"
                placeholder="All"
              />
            )}
            isOptionEqualToValue={isOptionEqualToId}
          />

          <CustomButton
            variant="contained"
            sx={{ width: "100%", height: "40px", mt: 3 }}
            onClick={handleSave}
          >
            <Typography
              sx={{ color: "white", fontSize: "14px", fontWeight: 600 }}
            >
              Save
            </Typography>
          </CustomButton>
        </DialogContent>
      </CustomDialog>
    </Box>
  );
};
export default MasterHubMobile;
