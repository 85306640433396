import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const CustomDialog = ({
  isOpen,
  setIsOpen,
  children,
  title,
  styleDialogContent,
  isIconExist = false,
  icon,
  iconBgColor,
  ...props
}) => {
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(!isOpen)} {...props}>
      {title && (
        <DialogTitle
          sx={{
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '12px 12px 0px 0px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 1,
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {isIconExist && (
                <Box
                  sx={{
                    backgroundColor: iconBgColor,
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {icon}
                </Box>
              )}

              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: 600,
                  color: '#000000',
                }}
                component="span"
              >
                {title}
              </Typography>
            </Box>

            <IconButton
              aria-label="close"
              onClick={() => setIsOpen(!isOpen)}
              sx={{
                color: '#323232',
                p: 0,
              }}
            >
              <CloseIcon sx={{ fontSize: '1.25rem' }} />
            </IconButton>
          </Box>
        </DialogTitle>
      )}

      {children}
    </Dialog>
  );
};
export default CustomDialog;
