import { Box, Dialog, Typography } from '@mui/material';
import { useState } from 'react';
import { ReactComponent as NoImage } from '../../assets/no-image.svg';

const VideoView = ({ data }) => {
  const [isPreview, setIsPreview] = useState(false);
  const [link, setIsLink] = useState('');

  const handlePreview = () => {};
  const handleClosePreview = () => {};
  return (
    <Box>
      <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
        Video
      </Typography>
      <Box
        sx={{
          mt: '12px',
          width: '100%',
          padding: '0',
          display: 'flex',
        }}
      >
        {data.length === 0 ? (
          <Box
            sx={{
              width: '54px',
              height: '54px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: '#FAFAFA',
              borderRadius: '2px',
            }}
          >
            <NoImage />
            <Typography sx={{ color: '#9E9D9D', fontSize: '6px' }}>
              No Video
            </Typography>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', gap: 2 }}>
            {data?.map((input, index) => (
              <Box onClick={() => handlePreview(input)} key={index}>
                <video
                  style={{
                    width: '54px',
                    height: '54px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    backgroundColor: 'black',
                  }}
                >
                  <source
                    src={
                      process.env.REACT_APP_API_IMAGE_BASE_URL +
                      '/' +
                      input +
                      '#t=0.5'
                    }
                  />
                </video>
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <Dialog open={isPreview} onClose={handleClosePreview}>
        <Box sx={{ bgcolor: 'black' }}>
          <video
            style={{
              width: '600px',
              height: '600px',
              backgroundColor: 'black',
            }}
            controls
          >
            <source
              src={process.env.REACT_APP_API_IMAGE_BASE_URL + '/' + link}
            />
          </video>
        </Box>
      </Dialog>
    </Box>
  );
};
export default VideoView;
